import React, { PureComponent } from "react";
import { Input, Icon, Button, Card, Divider, Row, Col, Modal } from "antd";
import * as jsPDF from 'jspdf'
import * as Utils from '../../util/utils';
import "../CreatePassword/index.less";

const confirm = Modal.confirm;
class Confirm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadPdf: false,
      showModal: false
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  downloadAsPdf = () => {
    let mnemonic = Utils.getFromLocalStorage('encryptedMnemonic');
    var doc = new jsPDF()
    doc.setFontSize(10);
    doc.text(mnemonic, 10, 10)
    doc.save('paramid-restore.pdf')
    this.setState({
      downloadPdf: true
    })
    return true;
  }

  getGeneratedPhrase = () => {
    let mnemonic = Utils.getFromLocalStorage('encryptedMnemonic');
    let mnemonicArr = mnemonic.split(/(\s+)/).filter(e => e.trim().length > 0);
    return mnemonicArr.map((value, index) => (
      <Col md={6} xs={12} className="password__phrase">
        <Input addonBefore={index + 1} type="text" value={value} disabled />
      </Col>
    ));
  };

  addProfile = () => {
    if (!this.state.downloadPdf) {
      this.setState({
        showModal: true
      })
    }
    else {
      this.props.history.push({ pathname: '/addprofile' })
    }
  }

  showHeadsUp = () => {
    let that = this;
    confirm({
      title: 'Heads up!',
      content: 'Are you sure , You want to proceed without taking backup?',
      onOk() {
        that.props.history.push({ pathname: '/addprofile' })
      },
      onCancel() {
        that.setState({
          showModal: false
        })
      },
    })
  }

  render() {

    return (
      <Card
        className="password phraseCard"
        title={<h2 className="gx-mb-0">12 Phrase Password</h2>}
      >
        <div className="password__inner">
          <p className=" gx-mb-4 password__description ">
            This set of 12 phrase allows you to recover your wallet in case of
            loss or damage. Write or print this on paper and keep it safe. You
            will not be able to recover your account if something goes wrong.
          </p>

          <Row>{this.getGeneratedPhrase()}</Row>
        </div>

        <Divider style={{ marginBottom: '1.4rem' }} />

        <Button className="password__nextBtn" style={{ marginRight: 0 }} onClick={this.addProfile}>
          NEXT
          <Icon type="arrow-right" />
        </Button>
        <Button className="password__nextBtn" style={{ marginRight: '1.5rem' }} onClick={this.downloadAsPdf}>
          Download 12 Phrase
          <Icon type="download" />
        </Button>
        {
          this.state.showModal
            ?
            this.showHeadsUp()
            :
            null
        }
      </Card>
    );
  }
}
export default Confirm;
