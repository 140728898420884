class SupplierDelivery {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    /**
        * Ref No.: SUPPLIER_DELIVERY_001
        * Graph Name: On Time Delivery
    */
    onTimeDelivery() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "onTimeDelivery")
    }

    /**
        * Ref No.: SUPPLIER_DELIVERY_002
        * Graph Name: Rejection Rate
    */
    rejectionRate() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "rejectionRate")
    }

    /**
        * Ref No.: SUPPLIER_DELIVERY_003
        * Graph Name: Defect Rate
    */
    defectRate() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "defectRate")
    }

    /**
        * Ref No.: SUPPLIER_DELIVERY_004
        * Graph Name: Fill Rate
    */
    fillRate() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "fillRate")
    }

    /**
        * Ref No.: SUPPLIER_DELIVERY_005
        * Graph Name: Supplier Delivery
    */
    supplierDelivery() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "supplierDelivery")
    }

    /**
        * Ref No.: SUPPLIER_DELIVERY_006
        * Graph Name: Reorder Items
        * type: rejection/defect
    */
    reorderItems(type) {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "reorderItems", type);

    }

    rejectionItemList() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "rejectionItemList")
    }

    defectItemList() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "defectItemList")
    }

    supplierDefectRate() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "supplierDefectRate")
    }
    topSupplierWithShortSupply() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "topSupplierWithShortSupply")
    }

    /**
        * Ref No.: SUPPLIER_DELIVERY_007
        * Graph Name: Upcoming Delivery
    */
    upcomingDelivery() {
        return this.mongoDBConnector.invoke("reports", "supplierDelivery", "upcomingDelivery")
    }

}

export default SupplierDelivery;