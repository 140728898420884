import ParserUtils from "./ParserUtils"
import * as Utils from '../util/utils';
import ECIES from '../util/ecies';
import Console from '../logger/index';

class InvoiceParser {

    // static createPersonData = (personData, personKey, jsonLd) => {
    //     let person;
    //     if (personData) {
    //         person = {
    //             '@type': "Person"
    //         }
    //         person = ParserUtils.getCorrectData(personData['identifier'], 'identifier', person);
    //         person = ParserUtils.getCorrectData(personData.name, 'name', person);
    //     }
    //     jsonLd[personKey] = person;
    //     return jsonLd;
    // }

    static paymentDue = (paymentPrice, paymentCurrency, key, jsonLd) => {
        let paymentDue = { "@type": "PriceSpecification" };
        paymentDue = ParserUtils.getCorrectData(paymentPrice, 'price', paymentDue);
        paymentDue = ParserUtils.getCorrectData(paymentCurrency, 'priceCurrency', paymentDue);
        jsonLd[key] = paymentDue;
        return jsonLd;
    }

    static addOn = (data) => {
        // let addOn = {};
        // addOn = ParserUtils.getCorrectData(data.currency, "priceCurrency", addOn);
        // addOn = ParserUtils.getCorrectData(parseInt(data.sgst) + parseInt(data.cgst), "price", addOn);
        // addOn = ParserUtils.getCorrectData("Tax", "name", addOn);
        // addOn["@type"] = "Offer";
        // return addOn;

        let addOnArray = [{
            "@type": "Offer",
            "priceCurrency": data.currency,
            "price": data.sgst,
            "name": 'SGST'
        }, {
            "@type": "Offer",
            "priceCurrency": data.currency,
            "price": data.cgst,
            "name": 'CGST'
        }, {
            "@type": "Offer",
            "priceCurrency": data.currency,
            "price": data.igst,
            "name": 'IGST'
        }, {
            "@type": "Offer",
            "priceCurrency": data.currency,
            "price": data.discount,
            "name": 'discount'
        }]
        return addOnArray;

    }

    static addOffers = (data, unitprice, quantity) => {
        let offer = {};
        offer = ParserUtils.getCorrectData(data.currency, "priceCurrency", offer);
        offer = ParserUtils.getCorrectData(unitprice, "price", offer);
        offer = ParserUtils.getCorrectData(data.availability, "availability", offer);
        offer["@type"] = "Offer";
        offer["addOn"] = InvoiceParser.addOn(data);
        return offer;
    }

    static getOrderedItem = (data) => {
        let item = data.data;
        let selfParamId = Utils.getParamId();
        let orderItem = [];
        let itemProperties;
        try {
            itemProperties = JSON.parse(data.additionalColumns)
        } catch (e) {
            itemProperties = data.additionalColumns;
        }
        for (let index = 0; index < item.length; index++) {
            let orderedItemObj = {}, orderObj = {}
            orderObj["@type"] = "Product";
            orderObj["name"] = item[index].item;
            orderObj["category"] = item[index].type;
            orderObj["productId"] = item[index].productId
            orderObj["materials"] = item[index].materials;
            orderObj["offers"] = InvoiceParser.addOffers(item[index], item[index].unitPrice, item[index].quantity);
            orderObj["partOfInvoice"] = item[index].linkedReceipts;
            if (itemProperties && Array.isArray(itemProperties) && item[index].additionalProperties) {
                orderObj["additionalProperties"] = Object.keys(item[index].additionalProperties)
                    .filter(key => itemProperties.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = item[index].additionalProperties[key];
                        return obj;
                    }, {});
            }
            orderedItemObj["@type"] = "OrderItem";
            orderedItemObj["orderItemNumber"] = item[index]['internalId'];
            if (item[index]["mappingInfo"]) {
                orderedItemObj["mappingInfo"] = item[index]["mappingInfo"];
            }
            orderedItemObj["truckType"] = item[index].truckType;
            orderedItemObj["invoice"] = item[index].invoice;
            orderedItemObj["orderQuantity"] = item[index].logistics ? item[index].load : item[index].quantity;
            orderedItemObj["status"] = item[index].status;
            orderedItemObj["orderedItem"] = orderObj
            orderedItemObj["logistics"] = item[index].logistics;
            orderItem.push(orderedItemObj);
        }

        console.log('parser orderItem', orderItem)

        return orderItem;
    }

    static getReferencesOrder = (data, date, jsonLd) => {
        // let referencesOrder = [];
        let additionalColumns = data.additionalColumns;
        let referenceOrderObj = {}
        // for(let index =0; index<data.length;index++){
        referenceOrderObj["@type"] = "Order";
        referenceOrderObj["discount"] = data.globalDiscount;
        referenceOrderObj["additionalColumns"] = JSON.stringify(additionalColumns);
        referenceOrderObj["discountCode"] = "";
        // referenceOrderObj["discountCurrency"] = data.currency;
        //    referenceOrderObj["isGift"] = false;
        referenceOrderObj["orderDate"] = date;
        referenceOrderObj["orderedItem"] = InvoiceParser.getOrderedItem(data)
        //    referencesOrder.push(referenceOrderObj);
        // } 
        jsonLd["referencesOrder"] = referenceOrderObj;
        return jsonLd;
    }

    static createContract = (data, firstStep = '0') => {
        let jsonLd = {
            "@context": "http://schema.org/",
            "@type": "Invoice",
            "additionalProperty": firstStep,
        }
        // if (data.dynamicFormData && Object.keys(data.dynamicFormData).length != 0) {
        jsonLd["additionalProperties"] = JSON.stringify(data.dynamicFormData);
        // }
        jsonLd = ParserUtils.getCorrectData(data.broker, 'broker', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.customer, 'customer', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.provider, 'provider', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.id, 'accountId', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.invoiceNumber, 'identifier', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.billingPeriod, 'billingPeriod', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.category, 'category', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.txnType, 'transactionType', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.confirmationNumber, 'confirmationNumber', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.paymentDueDate, 'paymentDueDate', jsonLd);
        jsonLd = InvoiceParser.paymentDue(data.price, data.currency, 'minimumPaymentDue', jsonLd);
        jsonLd = InvoiceParser.paymentDue(data.price, data.currency, 'totalPaymentDue', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.paymentStatus, 'paymentStatus', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.paymentMethod, 'paymentMethod', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.paymentMethodId, 'paymentMethodId', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.scheduledPaymentDate, 'scheduledPaymentDate', jsonLd);
        jsonLd = InvoiceParser.getReferencesOrder(data, data.date, jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.terms, 'description', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.customNote, 'disambiguatingDescription', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.latLng, 'latLng', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.address, 'address', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.isAmountLess, 'isAmountLess', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.allowExceedingFields, 'allowExceedingFields', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.shareCatalogue, 'shareCatalogue', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.expiryDate, 'expiryDate', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.expectedDeliveryDate, 'expectedDeliveryDate', jsonLd);
        jsonLd = ParserUtils.getCorrectData(data.paymentTerms, 'paymentTerms', jsonLd);
        jsonLd["@subType"] = data.subType;
        if (data.purchaseOrderNumber) {
            jsonLd = ParserUtils.getCorrectData(data.purchaseOrderNumber, 'purchaseOrderNumber', jsonLd);
        }
        if (data.contractNumber) {
            jsonLd = ParserUtils.getCorrectData(data.contractNumber, 'contractNumber', jsonLd);
        }
        if (data.invoiceNumber) {
            jsonLd = ParserUtils.getCorrectData(data.invoiceNumber, 'invoiceNumber', jsonLd);
        }
        if (data.paymentNumber) {
            jsonLd = ParserUtils.getCorrectData(data.paymentNumber, 'paymentNumber', jsonLd);
        }
        if (data.gRNNumber) {
            jsonLd = ParserUtils.getCorrectData(data.gRNNumber, 'gRNNumber', jsonLd);
        }
        if (data.noteNumber) {
            jsonLd = ParserUtils.getCorrectData(data.noteNumber, 'noteNumber', jsonLd);
        }
        if (data.returnRequestNumber) {
            jsonLd = ParserUtils.getCorrectData(data.returnRequestNumber, 'returnRequestNumber', jsonLd);
        }
        if (data.returnOrderNumber) {
            jsonLd = ParserUtils.getCorrectData(data.returnOrderNumber, 'returnOrderNumber', jsonLd);
        }
        if (data.acceptNoteNumber) {
            jsonLd = ParserUtils.getCorrectData(data.acceptNoteNumber, 'acceptNoteNumber', jsonLd);
        }
        if (data.partPurchaseOrderNumber) {
            jsonLd = ParserUtils.getCorrectData(data.partPurchaseOrderNumber, 'partPurchaseOrderNumber', jsonLd);
        }
        if (data.rateCardNumber) {
            jsonLd = ParserUtils.getCorrectData(data.rateCardNumber, 'rateCardNumber', jsonLd);
        }
        if (data.transportOrderNumber) {
            jsonLd = ParserUtils.getCorrectData(data.transportOrderNumber, 'transportOrderNumber', jsonLd);
        }
        if (data.eLRNumber) {
            jsonLd = ParserUtils.getCorrectData(data.eLRNumber, 'eLRNumber', jsonLd);
        }
        if (data.draftInvoiceNumber) {
            jsonLd = ParserUtils.getCorrectData(data.draftInvoiceNumber, 'draftInvoiceNumber', jsonLd);
        }
        if (data.ePoDNumber) {
            jsonLd = ParserUtils.getCorrectData(data.ePoDNumber, 'ePoDNumber', jsonLd);
        }
        if (data.creditNoteId) {
            jsonLd = ParserUtils.getCorrectData(data.creditNoteId, 'creditNoteId', jsonLd);
        }
        if(data.qCreditNoteNumber) {
            jsonLd = ParserUtils.getCorrectData(data.qCreditNoteNumber, 'qCreditNoteNumber', jsonLd);
        }
        if(data.debitAdviceNumber) {
            jsonLd = ParserUtils.getCorrectData(data.debitAdviceNumber, 'debitAdviceNumber', jsonLd);
        }
        if(data.debitadviceId) {
            jsonLd = ParserUtils.getCorrectData(data.debitadviceId, 'debitadviceId', jsonLd);
        }
        jsonLd.createdByBuyer = false;
        jsonLd = JSON.stringify(jsonLd)
        return jsonLd;
    }

    static getLinkMaterials = (data) => {
        let materialArr = [];
        for (let index = 0; index < data.length; index++) {
            let materialObj = {};
            materialObj["@id"] = data[index].id
            materialObj["@invoiceId"] = data[index].invoiceId
            materialArr.push(materialObj);
        }
        return materialArr;
    }

    static getLinkItems = (item) => {
        let items = [];
        for (let index = 0; index < item.length; index++) {
            let itemObj = {};
            itemObj["@id"] = item[index].id
            itemObj["materials"] = InvoiceParser.getLinkMaterials(item[index].materials)
            items.push(itemObj)
        }
        return items;
    }

    static linkPurchases(linkedData) {
        let linkPurchaseOrder = [], extendedKnowledge = {};
        for (let item in linkedData) {
            let resMaterial = {};
            for (let rawMaterial in linkedData[item]) {
                let rawMaterialReceiptInfo = linkedData[item][rawMaterial];
                if (!resMaterial[rawMaterialReceiptInfo.receiptId]) {
                    resMaterial[rawMaterialReceiptInfo.receiptId] = [];
                }
                resMaterial[rawMaterialReceiptInfo.receiptId].push({
                    "@id": rawMaterialReceiptInfo.materialId,
                    "invoiceId": rawMaterialReceiptInfo.docInternalId
                })
            }
            console.log(resMaterial);
            for (let index in resMaterial) {
                linkPurchaseOrder.push({
                    item: {
                        "@id": item,
                        material: resMaterial[index]
                    }
                })
            }
            console.log(linkPurchaseOrder);
        }
        extendedKnowledge["linkedItems"] = linkPurchaseOrder;
        return extendedKnowledge;
    }
}

export default InvoiceParser;
