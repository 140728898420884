import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, HashRouter, Switch } from "react-router-dom";

import "./assets/vendors/style";
import "./styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import Acceptinvitation from './containers/AcceptInvitation';
import App from "./containers/App/index";
import ConfigCheck from '../src/components/config-loader';
import Analytics from './analytics';
import * as Config from './config.json';

export const store = configureStore();

class NextApp extends React.Component {
  constructor(props) {
    super(props);
    Analytics.getInstance().initMotomo(Config['analytics'].playgroundSiteID, Config['analytics'].url);
  }
  render() {
    return (
      <Provider store={store}>
        <HashRouter history={history}>
          {
            this.props.showLoader ?
              <Route path="/" render={(routeProps) => (
                <ConfigCheck {...this.props} />
              )} />
              :
              <Switch>
                <Route path="/" component={App} />
              </Switch>

          }
          {/* <Route path="/" component={ Router } /> */}
        </HashRouter>
        {/* <HashRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </HashRouter> */}
      </Provider>
    )
  }
}

// const NextApp = () =>
//   <Provider store={store}>
//     <HashRouter history={history}>
//       <Switch>
//         <Route path="/" component={App} />
//       </Switch>
//     </HashRouter>
//   </Provider>;

export default NextApp;
