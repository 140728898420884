import React from 'react';
import { Input, Select, InputNumber, Radio, DatePicker } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';

const { Option } = Select;

class MetaInfoUtils {

    static getOptions = (defaultValue, type) => {
        let tempOptions = defaultValue.split(',');
        return tempOptions.map((data, index) => {
            data = data.trim();
            switch (type) {
                case "radio":
                    return <Radio.Button value={data} key={index}>{data}</Radio.Button>
                case "dropdown":
                    return <Option key={index} value={data}>{data}</Option>
            }
        });
    }

    static getLabel = (data) => {
        // if (data.isRequired) {
        //     return <span><span style={{ color: 'red' }}>* </span>{data.name}</span>
        // }
        return data.name;
    }

    static getInputBox = (data, index, onChangeDynamicFormData, style) => {
        let dataType = data.dataType;
        if (!style) {
            style = {
                width: '15rem'
            }
        }
        switch (dataType) {
            case "input":
                return <Input style={style} defaultValue={data.defaultValue} onChange={(e) => { onChangeDynamicFormData(e.target.value, data.name) }} key={index} />
            case "textArea":
                return <TextArea style={style} defaultValue={data.defaultValue} onChange={(e) => { onChangeDynamicFormData(e.target.value, data.name) }} key={index} />
            case "number":
                return <InputNumber style={style} defaultValue={data.defaultValue} onChange={(e) => { onChangeDynamicFormData(e, data.name) }} key={index} />
            case "radio":
                return <Radio.Group defaultValue={data.defaultVal} onChange={(e) => { onChangeDynamicFormData(e.target.value, data.name) }}>
                    {MetaInfoUtils.getOptions(data.defaultValue, dataType)}
                </Radio.Group>
            case "dropdown":
                return <Select style={style} defaultValue={data.defaultVal} onChange={(e) => { onChangeDynamicFormData(e, data.name) }} >
                    {MetaInfoUtils.getOptions(data.defaultValue, dataType)}
                </Select>
            case "date":
                return <DatePicker defaultValue={moment(data.defaultValue)} onChange={(date, dateString) => { onChangeDynamicFormData(dateString, data.name) }} />
            case "phone":
                return <PhoneInput
                    placeholder=''
                    value={data.defaultValue}
                    inputProps={{
                        enableSearch: true,
                        autoFormat: true,
                    }}
                    style={style}
                    onChange={(phone) => { onChangeDynamicFormData(phone, data.name) }}
                />
        }
    }
}

export default MetaInfoUtils;
