import ParamConnector from '../../param-connector';
import * as Utils from '../utils.js';

class QueryConsole {

    static executeQuery(query) {
        return ParamConnector.getInstance().getDB()().executeQuery.executeQuery(query);
    }
}

export default QueryConsole;