class Returns {
    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getAllReturnsByDocId = (step, docId) => {
        return this.mongoDBConnector.invoke('returns', "getAllReturnsByDocId", step, docId)
    }

    getAllReturnsBySeller = (sellerAddress, step) => {
        return this.mongoDBConnector.invoke('returns', "getAllReturnsBySeller", sellerAddress, step)
    }

    getAllReturnsByBuyer = (buyerAddress, step) => {
        return this.mongoDBConnector.invoke('returns', "getAllReturnsByBuyer", buyerAddress, step)
    }

    getReturn = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getReturn", requestId)
    }

    getReturnSummary = (requestId, predicates) => {
        return this.mongoDBConnector.invoke('returns', "getReturnSummary", requestId, predicates)
    }
    getReturnSummaryInBulk = (requestIds, predicates) => {
        return this.mongoDBConnector.invoke('returns', "getReturnSummaryInBulk", requestIds, predicates)
    }

    getAllReceiptsByParent = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getAllReceiptsByParent", requestId)
    }

    getAllReceiptsByParentV1 = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getAllReceiptsByParentV1", requestId)
    }

    getAllReceiptsByStep = (from, to, step, type, isClosed) => {
        return this.mongoDBConnector.invoke('returns', "getAllReceiptsByStep", from, to, step, type, isClosed)
    }

    getItemQuantitySummary = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getItemQuantitySummary", requestId)
    }

    getRootReceiptId = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getRootReceiptId", requestId)
    }
    getReceiptInfoByNode = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getReceiptInfoByNode", requestId)
    }
    getReceiptInternalId = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getReceiptInternalId", requestId)
    }

    getValidationData = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getValidationData", requestId)
    }
    getParentDocId = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getParentDocId", requestId)
    }
    getSellerAndBuyer = (requestId) => {
        return this.mongoDBConnector.invoke('returns', "getSellerAndBuyer", requestId);
    }


    getAllReceiptsByTypeForExport(type, step){
        return this.mongoDBConnector.invoke('returns', "getAllReceiptsByTypeForExport", type, step);
    }

    addReturns = (returnsId, parentDocId, sellerAddress, buyerAddress, jsonLd, metaData, txnMode, status, step) => {
        return this.mongoDBConnector.invoke('returns', "addReturns", returnsId, parentDocId, sellerAddress, buyerAddress, jsonLd, metaData, txnMode, status, step)
    }

    doesExist = (returnId) => {
        return this.mongoDBConnector.invoke('returns', "doesExist", returnId)
    }

    updateStatus = (returnId, newStatus, step) => {
        return this.mongoDBConnector.invoke('returns', "updateStatus", returnId, newStatus, step)
    }

    appendStatus = (returnId, status) => {
        return this.mongoDBConnector.invoke('returns', "appendStatus", returnId, status)
    }

    writeStatus = (returnId, status, step) => {
        return this.mongoDBConnector.invoke('returns', "writeStatus", returnId, status, step)
    }
}
export default Returns;