class ThirdPartyUtils {
    static getCorrectNumber = (stringData) => isNaN(Number(stringData)) ? 0 : Number(stringData);

    static getDataAfterSplit = (stringData) => stringData && stringData.split("-")[1] ? stringData.split("-")[1] : stringData;

    static calculateOtherCharges = (otherCharges) => {
        return otherCharges.reduce((a, b) => {
            return a + b;
        }, 0);
    }

    static getDiscountAndOtherCharges = (addOnArray) => {
        let discount = 0;
        let otherCharges = 0;
        for (let i in addOnArray) {
            if (addOnArray[i].name === 'Discount Amount')
                discount = ThirdPartyUtils.getCorrectNumber(addOnArray[i].price);
            if (addOnArray[i].name === 'Other Charges')
                otherCharges = ThirdPartyUtils.getCorrectNumber(addOnArray[i].price);
        }
        return { discount, otherCharges };
    }

    static getReceiptInvoiceSum = (jsonLd) => {
        let orderedItems = jsonLd.referencesOrder.orderedItem;
        if (!orderedItems) {
            orderedItems = [];
        }
        if (!Array.isArray(orderedItems)) {
            orderedItems = [orderedItems]
        }
        let map = new Map();
        let total = 0;
        let itemsTotal = 0;
        for (let index = 0; index < orderedItems.length; index++) {
            let item = orderedItems[index]; //orderQuantity
            let quantity = ThirdPartyUtils.getCorrectNumber(item["orderQuantity"]);
            let price = parseFloat(item.orderedItem.offers.price);
            let taxes = item.orderedItem.offers.addOn;
            let { discount, otherCharges } = ThirdPartyUtils.getDiscountAndOtherCharges(item.orderedItem.offers.addOn);
            let taxableAmount = (quantity * price) - discount + otherCharges;
            total += taxableAmount;
            itemsTotal += taxableAmount;
            for (let taxIndex in taxes) {
                let tax = taxes[taxIndex];
                let value = map.get(tax["name"])
                let taxPer = parseFloat(tax.price);
                if (!value || isNaN(value)) {
                    value = 0;
                }
                if (!taxPer || isNaN(taxPer)) {
                    taxPer = 0;
                }
                if (tax.description === '%') {
                    value = value + ((taxPer / 100) * taxableAmount);
                } else if (tax.description === 'value') {
                    value = value + taxableAmount;
                } else {
                    value = 0;
                }
                map.set(tax.name, value);
            }
        }
        let keys = [...map.keys()];
        let objects = [];
        let totalTax = 0;
        for (let keyIndex in keys) {
            let key = keys[keyIndex] ? keys[keyIndex].toLowerCase() : "";
            let amount = map.get(keys[keyIndex])
            if (!amount || isNaN(amount)) {
                amount = 0;
            }
            if ((key.includes("cess") || key.includes("gst") || key.includes("tax")) && key !== 'gst') {
                totalTax += amount;
                total += amount;
            }
            objects.push({
                name: keys[keyIndex],
                price: amount,
                description: "value"
            })
        }
        objects.push({
            name: "subTotal",
            price: itemsTotal,
            description: "value"
        })
        objects.push({
            name: "totalTax",
            price: totalTax,
            description: "value"
        })
        //debugger;
        return { objects, total };
    }

    static isHex = (inputString) => {
        const regExp = /^[0-9a-fA-F]+$/;
        return regExp.test(inputString);
    }

    static getSchemaError = (error) => {
        let exception = { isSchemaError: true };
        exception["ErrorDetails"] = [];
        for (let errorIndex in error) {
            exception["ErrorDetails"].push({ ErrorMessage: `${error[errorIndex].dataPath} ${error[errorIndex].message}`, ErrorCode: "007" });
        }
        return exception;
    }

}

export default ThirdPartyUtils;