import React from "react";
import { Route, Switch } from "react-router-dom";
import { message, notification } from 'antd';
// import Components from "./components/index";
// import CustomViews from "./customViews/index";
// import Extensions from "./extensions/index";
// import ExtraComponents from "./extraComponents/index";
// import InBuiltApps from "./inBuiltApps/index";
// import SocialApps from "./socialApps/index";
// import Documents from "./documents/index";
// import BaseComponent from "../routes/main/BaseComponent";
import Main from "./main/index";
import Dashboard from "./Dashboard/index";
import * as Utils from '../util/utils';

class App extends React.Component {

  render() {
    const { match } = this.props
    let mainContainerClass = 'gx-main-content-wrapper';
    let themeType = Utils.getFromLocalStorage('theme');
    if (themeType === 'THEME_TYPE_DARK') {
      mainContainerClass = `gx-main-content-wrapper dark-theme`
    }
    return (
      <div className={mainContainerClass}>
        {/* <div className="global-breadcrumb" style={{ transform: "translate(0%, -50%)" }}>{this.getBreadCrumb()}</div> */}

        <Switch>
          <Route path={`${match.url}main`} component={Main} />
          <Route path={`${match.url}dashboard`} component={Dashboard} />
          {/* <Route path={`${match.url}components`} component={Components} />
          <Route path={`${match.url}custom-views`} component={CustomViews} />
          <Route path={`${match.url}extensions`} component={Extensions} />
          <Route path={`${match.url}extra-components`} component={ExtraComponents} />
          <Route path={`${match.url}in-built-apps`} component={InBuiltApps} />
          <Route path={`${match.url}social-apps`} component={SocialApps} />
          <Route path={`${match.url}documents`} component={Documents} /> */}
        </Switch>
      </div>
    )
  }
}

export default App;
