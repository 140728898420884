// import fetch from "node-fetch";
// import { keyStoreBaseURL } from '../../constants/AppConstants';
import Analytics from '../../analytics';
import * as Config from '../../config.json';
import EventManager from '../../event-manager/event-manager';
const aesctr = require('../aes-ctr');
const crypto = require("crypto")
const fetch = require("node-fetch");
// const rsa = require('../rsa');
const jwt = require("jsonwebtoken")
const Utils = require('../utils')
let { keyStoreBaseURL, appKey } = require("../../constants/AppConstants")


class KeyStore {

    static sendOTP(account, vendorId) {
        // if (!account.startsWith('+91')) {
        //     account = '+91' + account;
        // }
        let sendOTPData = {
            account: account,
            vendorid: vendorId
        }
        return fetch(`${keyStoreBaseURL.Url}/v1/send-otp`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "no-cors",
                "app-key": appKey
            },
            body: JSON.stringify(sendOTPData)
        }).then(resData => {
            if (resData.status === 200)
                return resData.json()
            return resData.text().then(text => {
                let error = JSON.parse(text).message
                Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].sendOTP, Config['analytics'].response, 0);
                throw new Error(error)
            })
        }).then(rData => {
            return rData;
        });
    }

    static isValidToken() {
        let tokenObject = jwt.decode(Utils.getFromLocalStorage('otpToken'));
        if (!tokenObject) {
            return false;
        }
        let expiry = tokenObject.exp
        if ((new Date(expiry * 1000).getTime() < new Date().getTime())) {
            return false;
        }
        return true;
    }

    static getValidToken(token) {
        return new Promise((resolve, reject) => {
            let tokenObject = jwt.decode(token);
            if (!tokenObject) {
                return reject("Unable to get the token, please relogin.")
            }
            let expiry = tokenObject.exp
            if (!(new Date(expiry * 1000).getTime() < new Date().getTime())) {
                return resolve(token);
            }
            return resolve();
        }).then(tokenString => {
            if (tokenString) {
                return tokenString;
            }
            return KeyStore.refreshToken(token);
        })
    }
    static refreshToken(token) {
        return fetch(`${keyStoreBaseURL.Url}/v1/refresh-token`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
                "app-key": appKey
            },
            body: JSON.stringify({
                "refreshToken": Utils.getFromLocalStorage('otpRefreshToken')
            })
        }).then(res => {
            if (res.status !== 200) {
                EventManager.getInstance().emitEvent("sessionExpired", res.status)
                throw new Error(res.statusText)
            }
            return res.json()
            // return Promise.reject(res.statusText)
        }).then(rData => {
            Utils.setLocalStorage('otpToken', rData.metadata.token)
            Utils.setLocalStorage('otpRefreshToken', rData.metadata.refreshToken)
            return rData.metadata.token
        })
    }
    static verifyOTP(account, otp, vendorId) {
        // otp = rsa.encrypt(otp);
        // if (!account.startsWith('+91')) {
        //     account = '+91' + account;
        // }
        let otpData = {
            account: account,
            otp: otp,
            vendorid: vendorId
        }

        return fetch(`${keyStoreBaseURL.Url}/v1/verify-otp`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                "app-key": appKey
            },
            body: JSON.stringify(otpData)
        }).then(resData => {
            if (resData.status === 200)
                return resData.json()
            return resData.text().then(text => {
                let error = JSON.parse(text).message
                Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].verifyOTP, Config['analytics'].response, 0);
                throw new Error("Please enter valid OTP");
            })
        }).then(rData => {
            let response = []
            if (rData && rData.data) {
                let key = crypto.createHash('sha256').update(otpData.otp).digest('hex');
                rData.data.forEach(element => {
                    let account = aesctr.decrypt(element.account, key)
                    response.push(JSON.parse(account));
                });
                Utils.setLocalStorage("otpToken", rData.metadata.token.toString());
                Utils.setLocalStorage("otpRefreshToken", rData.metadata.refreshToken.toString());
            }
            else {
                console.log(rData);
            }
            return response;
        })
    }

    static lockAccount(primaryAccont, lockAccount, otp) {
        // otp = rsa.encrypt(otp);
        let lockJSONData = {
            account: primaryAccont,
            lockAccount: lockAccount,
            otp: otp
        }

        return fetch(`${keyStoreBaseURL.Url}/v1/lock-account`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(lockJSONData)
        }).then(resData => {
            return resData.json()
        }).then(rData => {
            console.log(rData);
        });
    }

    static unlockAccount(primaryAccont, lockedAccount, otp) {
        // otp = rsa.encrypt(otp);
        let unlockData = {
            account: primaryAccont,
            lockAccount: lockedAccount,
            otp: otp
        }
        return fetch(`${keyStoreBaseURL.Url}/v1/unlock-account`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(unlockData)
        }).then(resData => {
            return resData.json()
        }).then(rData => {
            console.log(rData);
        });
    }

    static addAccount(primaryAccont, passphrase, admin) {
        // otp = rsa.encrypt(otp);
        let accountData = {
            account: primaryAccont,
            passphrase: passphrase
        }
        return fetch(`${keyStoreBaseURL.Url}/v1/add-account`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                "Authorization": admin
            },
            body: JSON.stringify(accountData)
        }).then(resData => {
            if (resData.status === 200)
                return resData.json()
            return Promise.reject(resData.statusText)
        }).then(rData => {
            console.log(rData);
        });
    }

    static getAccount(paramId) {
        let accountData = {
            account: paramId
        }
        return fetch(`${keyStoreBaseURL.Url}/v1/accounts`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(accountData)
        }).then(resData => {
            if (resData.status === 200)
                return resData.json()
            return Promise.reject(resData.statusText);
        }).then(rData => {
            console.log(rData);
        });
    }
}
export default KeyStore;
// export default KeyStore;

// KeyStore.sendOTP("+919788537952");
// KeyStore.verifyOTP("+917302233176", "HuCfptqK");
// KeyStore.lockAccount("+919788537952", "+919788537952", "MiMWjumr");
// KeyStore.unlockAccount("+919788537952", "+919788537952", "MiMWjumr");
// KeyStore.addAccount("+919788537952", "+919738891288", "2PtBl1Rq")
