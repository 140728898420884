import Charts from '../../charts'
import { purchase } from '../../channel-config.json'
import RedisClientSocket from '../../client-lib/redis-client';
import Console from '../../../../logger'
import EventUtils from '../utils/eventUtils';

class PurchaseSupplierDelivery {

    constructor(paramID) {
        this.paramID = paramID;
    }

    publishAllSupplierDelivery() {
        this.getOnTimeDelivery().then(result => {
            return this.getRejectionRate();
        }).then(res => {
            return this.getDefectRate()
        }).then(res => {
            return this.getFillRate();
        }).then(res => {
            return this.supplierDelivery();
        }).then(res => {
            return this.getReorderItemsRejection();
        }).then(res => {
            return this.getReorderItemsDefect();
        }).then(res => {
            return this.topSupplierWithShortSupply();
        }).then(res => {
            return this.getUpcomingDelivery()
        }).then(res => {
            return this.getSupplierDefectRate()
        }).catch(err => {
            Console.error(err);
        })
    }

    getOnTimeDelivery(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.onTimeDelivery}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.onTimeDelivery()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    topSupplierWithShortSupply(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.topSupplierWithShortSupply}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.topSupplierWithShortSupply()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getDefectRate(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.defectRate}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.defectRate()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }
    supplierDelivery(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.supplierDelivery}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn', 'vendor'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.supplierDelivery()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getRejectionRate(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.rejectionRate}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.rejectionRate()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getFillRate(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.fillRate}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.fillRate()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getReorderItemsRejection(type = `rejection`, isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.reorderItems_rejection}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.reorderItems(type)
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getReorderItemsDefect(type = 'defect', isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.reorderItems_defect}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.reorderItems(type)
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getUpcomingDelivery(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.upcomingDelivery}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.upcomingDelivery()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getSupplierDefectRate(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.supplierDelivery.supplierDefectRate}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().supplierDelivery.supplierDefectRate()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }
}

export default PurchaseSupplierDelivery;