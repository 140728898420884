import Charts from '../../charts'
import { sales } from '../../channel-config.json'
import RedisClientSocket from '../../client-lib/redis-client';
import Console from '../../../../logger'
import EventUtils from '../utils/eventUtils';

class SalesCustomerInsights {

    constructor(paramID) {
        this.paramID = paramID;
    }

    publishAllCustomerInsights() {
        this.getRepeatCustomers().then(result => {
            return this.getReorderRatio();
        }).then(res => {
            return this.getFillRate()
        }).then(res => {
            return this.getOverview();
        }).then(res => {
            return this.getContractDetails();
        }).then(res => {
            return this.getTopCustomers()
        }).then(res => {
            return this.getNewCustomers();
        }).then(res => {
            return this.getCustomerShipment();
        }).then(() => {
            return this.getActiveCustomers()
        }).catch(err => {
            Console.error(err);
        })
    }

    getRepeatCustomers(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.repeatCustomers}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.repeatCustomers()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getReorderRatio(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.reorderRatio}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.reorderRatio()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getFillRate(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.fillRate}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.fillRate()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getOverview(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.overview}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn', 'returns'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.overview()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getContractDetails(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.contractDetails}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.contractDetails()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getTopCustomers(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.topCustomers}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.topCustomers()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getActiveCustomers(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.activeCustomers}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.activeCustomers()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getCustomerShipment(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.customerShipment}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.customerShipment()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getNewCustomers(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.newCustomers}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.getNewCustomers()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getNewAndReturnCustomers(isFromEvent) {
        let channelName = `${this.paramID}/${sales.customerInsights.newAndReturnCustomers}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().customerInsights.getNewAndReturnCustomers()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }
}

export default SalesCustomerInsights;