import ParamConnector from '../../../../src/param-connector'
import * as NetworkUtils from '../../../../src/util/utils';
import ECIES from '../../../../src/util/ecies';
import Console from '../../../logger';
import NetworkDataFormat from '../../nosql/Utils/network-data-format';
import { ReceiptEvents } from '../../../../src/param-network/utils/event-names';
import RestoreGrn from './grn';
import * as DBUtils from '../../nosql/Utils/utils';

class RestoreReceipts {

    static restoreReceipt(receiptDataArray) {
        let receiptFinalData = [];
        let outerPromise, outerPromiseArray = [];
        for (let receiptData of receiptDataArray) {
            let receiptId = receiptData.receiptId,
                contractAddress = receiptData.contractAddress,
                status = receiptData.status,
                step = receiptData.step,
                receiptKey = receiptData.receiptKey,
                pId = receiptData.pId,
                event = receiptData.event;

            let receiptsInstance = ParamConnector.getInstance().getDB().receipts;
            let receiptNote = "", receipt;
            let receiptIdInfo = NetworkUtils.getTransactionData(receiptId);
            outerPromise = receiptsInstance.doesExist(receiptId).then(doesExist => {
                if (doesExist && Number(step) >= Number(doesExist.step) && Number(status) >= Number(doesExist.status) && event != "onReceipStatusUpdateV1") {
                    Console.log("Receipt already exists ", receiptId);
                    Console.log("Trying to update status for ", receiptId);
                    let restoreLinkedInvoicePromise = Promise.resolve();
                    return receiptsInstance.updateStatus(receiptId, status, step).then(() => {
                        return restoreLinkedInvoicePromise;
                    });
                }
                let paramNetworkReceiptManager = ParamConnector.getInstance().getNetwork().getReceiptManager(receiptIdInfo.address);
                return paramNetworkReceiptManager.getReceipt(receiptIdInfo.id).then(receiptResponse => {
                    if (!receiptResponse) {
                        return;
                    }
                    return DBUtils.getFromIpfsHash(receiptResponse[2]).then(_receipt => {
                        receiptResponse[2] = _receipt;
                        return receiptResponse;
                    })
                }).then(res => {
                    receipt = res;
                    let privateKey = receiptKey || NetworkUtils.getFromLocalStorage("privateKey");
                    pId = NetworkUtils.getTransactionId(receipt[5], contractAddress);

                    if (receipt[5] === "0x0000000000000000000000000000000000000000000000000000000000000000") {
                        pId = null;
                    }
                    if (receipt[6] === "2") {
                        try {
                            let temp = ECIES.decrypt(privateKey, receipt[2]);
                            receipt[2] = temp;
                        } catch (error) {
                            receipt[2] = { noAccess: true }
                            receipt[2] = JSON.stringify(receipt[2]);
                        }
                    }

                    return paramNetworkReceiptManager.getTransactions(receiptIdInfo.id);
                }).then(txnIds => {
                    let promiseArray = [];
                    for (let index in txnIds) {
                        promiseArray.push(
                            // eslint-disable-next-line no-loop-func
                            paramNetworkReceiptManager.getTransaction(txnIds[index]).then(txnData => {
                                if (txnData[6]) {
                                    receiptNote = txnData[6];
                                }
                            })
                        )
                    }
                    return Promise.all(promiseArray);
                }).then(() => {
                    receiptFinalData.push(
                        {
                            receiptId: receiptId,
                            jsonLd: receipt[2],
                            status: receipt[3],
                            parentDocId: pId,
                            step: receipt[4],
                            receiptNote: receiptNote
                        }
                    );
                });
            });
            outerPromiseArray.push(outerPromise);
        }
        return Promise.all(outerPromiseArray).then(() => {
            return ParamConnector.getInstance().getDB().receipts.addReceipts(receiptFinalData);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring receipts [Reason] ${e} [Module] : Sync/receipts/restoreReceipt`);
        });
    }

    static restoreLinkedInvoices(receiptId, contractAddress, isEncrypted, receiptKey) {
        let receiptsInstance = ParamConnector.getInstance().getDB().receipts;
        let linkedInvoicesInstance = ParamConnector.getInstance().getDB().linkedInvoices;
        let receiptIdInfo = NetworkUtils.getTransactionData(receiptId);
        let receiptManager = ParamConnector.getInstance().getNetwork().getReceiptManager(contractAddress);
        let promise;
        let promiseArray = [];
        return receiptManager.getExtendedKnowledgeByReceiptId(receiptIdInfo.id).then(ekIds => {
            if (ekIds.length === 0) {
                return;
            }
            Console.log(`[MongoDB] Extended knowledge is present`);
            for (let index in ekIds) {
                let privateKey = receiptKey || NetworkUtils.getFromLocalStorage("privateKey");
                promise = receiptManager.getExtendedKnowledge(ekIds[index].toString()).then(extdKnowledge => {
                    if (isEncrypted) {
                        extdKnowledge[2] = ECIES.decrypt(privateKey, extdKnowledge[2]);
                    }
                    Console.log(`[MongoDB] Got extended knowledge ${ekIds[index].toString()} from the network.`);
                    return linkedInvoicesInstance.addLinkedInvoice(receiptId, extdKnowledge[2]);
                }).catch(e => {
                    Console.error(`[MongoDB] Unable to add extended knowledge for receipt ${receiptId} and ${ekIds[index].toString()}`);
                });
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray)
        });
    }

    /**
     * 
     * @param {*} contractAddress 
     * @param {*} typeFunction getAllEventsBySeller or getAllEventsByBuyer
     * @param {*} owner 
     * @param {*} callback 
     */
    static restoreAllReceiptsByType(contractAddress, typeFunction, owner, callback, batchSize) {
        if (!batchSize) {
            batchSize = 10;
        }

        return ParamConnector.getInstance().getNetwork().getReceiptManager(contractAddress)[typeFunction](owner).then(receipts => {
            Console.info(`[MongoDB] Got e-purchases metainfo from network.`, receipts);
            callback.onProgress("invoices", 1)
            Console.info(`[MongoDB] Trying to fetch missing invoices from network.`);
            let map = new Map();
            let filteredReceipts = [];
            for (let index in receipts) {
                if (!map.get(receipts[index].args.rId)) {
                    filteredReceipts.push(receipts[index]);
                    map.set(receipts[index].args.rId, "1");
                }
            }
            return RestoreReceipts._restoreAllReceiptsAsBatch(filteredReceipts, contractAddress, 0, batchSize);
        }).then(() => {
            Console.info(`[MongoDB] documents restored for type function ${typeFunction} successfully.`);
            callback.onProgress("invoices", 2)
        }).catch(error => {
            Console.error(`[MongoDB] Error in restoring receipts for type function ${typeFunction} [Reason] ${error} [Module] : Sync/receipts/restoreAllReceiptsByType`);
        });
    }

    static _restoreAllReceiptsAsBatch(receipts, contractAddress, batchStartIndex, batchSize) {
        let receiptsLength = receipts.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(receiptsLength, endIndex);
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts ${receiptsLength}`)
        let receiptPromise;
        let receiptPromiseArray = [];

        let receiptDataArray = [];

        for (let index = startIndex; index < endIndex; index++) {
            let networkReceiptId = receipts[index].args.rId;
            let status = receipts[index].args.status;
            let step = receipts[index].args.step;
            let receiptId = NetworkUtils.getTransactionId(networkReceiptId, contractAddress);
            receiptDataArray.push({
                receiptId: receiptId,
                contractAddress: contractAddress,
                status: status,
                step: step,
                event: receipts[index].event
            })
            receiptPromise = RestoreGrn.restoreAllGrn(contractAddress, networkReceiptId);
            receiptPromiseArray.push(receiptPromise);
        }

        return Promise.all(receiptPromiseArray)
            .then(() => {
                return this.restoreReceipt(receiptDataArray);
            }).then(result => {
                Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts ${receiptsLength}`)


                if (endIndex === receiptsLength) {
                    return;
                }
                return RestoreReceipts._restoreAllReceiptsAsBatch(receipts, contractAddress, batchStartIndex + 1, batchSize);
            }).catch(() => {
                Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts ${receiptsLength}`)
                if (endIndex === receiptsLength) {
                    return;
                }
                return RestoreReceipts._restoreAllReceiptsAsBatch(receipts, contractAddress, batchStartIndex + 1, batchSize);
            });
    }

    static restoreAllReceiptEvents(contractAddress, typeFunction, owner, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("receipt", `Trying to get all the events from ${contractAddress}`);
        }
        if (!batchSize) {
            batchSize = 10;
        }
        let receiptManager = ParamConnector.getInstance().getNetwork().getReceiptManager(contractAddress);
        return receiptManager[typeFunction](owner).then(receiptIds => {
            return RestoreReceipts._restoreAllReceiptEventsAsBatch(receiptIds, contractAddress, owner, 0, batchSize);
        }).then(() => {
            Console.info(`[MongoDB] document events restored for type function ${typeFunction} successfully.`);
        }).catch(error => {
            Console.error(`[MongoDB] Error in restoring receipts for type function ${typeFunction} [Reason] ${error} [Module] : Sync/receipts/restoreAllReceiptsByType`);
        });
    }

    static _restoreAllReceiptEventsAsBatch(receipts, contractAddress, owner, batchStartIndex, batchSize) {
        let receiptsLength = receipts.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(receiptsLength, endIndex);
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts events ${receiptsLength}`)
        let receiptManager = ParamConnector.getInstance().getNetwork().getReceiptManager(contractAddress);
        let promiseArray = [];
        for (let index = startIndex; index < endIndex; index++) {
            let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
            promiseArray.push(receiptManager.getAllEventsByReceiptId(receipts[index]).then(receiptEvents => {
                return RestoreReceipts.restoreReceiptIdEvents(transactionsDB, contractAddress, receiptEvents, owner);
            }))
        }

        return Promise.all(promiseArray).then(result => {
            Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts events ${receiptsLength}`);
            if (endIndex === receiptsLength) {
                return;
            }
            return RestoreReceipts._restoreAllReceiptEventsAsBatch(receipts, contractAddress, owner, batchStartIndex + 1, batchSize);
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts events ${receiptsLength}`)
            if (endIndex === receiptsLength) {
                return;
            }
            return RestoreReceipts._restoreAllReceiptEventsAsBatch(receipts, contractAddress, owner, batchStartIndex + 1, batchSize);
        });
    }

    static restoreReceiptIdEvents(transactionsDB, contractAddress, receiptEvents, owner) {
        let transactionsData = []
        for (let index in receiptEvents) {
            let event = receiptEvents[index];
            let label = ReceiptEvents[event.event].label;
            event.args.rId = NetworkUtils.getTransactionId(event.args.rId, contractAddress);
            let id = event.event === "onDocumentUpdate" ? NetworkUtils.getTransactionId(event.args.docId, contractAddress) : event.args.rId;
            let metaData = NetworkDataFormat.getMetaInfo(id, event, label);
            metaData.step = event.args.step;
            metaData.buyer = event.args.buyer;
            metaData.status = event.args.status || event.args.docStatus;
            metaData.seller = event.args.seller;
            metaData.docType = event.args.docType;
            transactionsData.push(
                {
                    id,
                    owner,
                    metaData
                }
            );
        }
        return transactionsDB.addTransactions(transactionsData).then(() => {
            Console.log('[MongoDB] Events Restored')
        }).catch(error => {
            Console.error(`Unable to restore events, Reason: ${error}`)
        });
    }

}
export default RestoreReceipts;
