import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import NetworkBridge from '.';
import ECIES from '../ecies';
import * as DBUtils from '../../database/nosql/Utils/utils';
import GraphQL from '../../param-libs/graph-ql';

class ELRManager {
    static addeLR(docId, jsonLd, txnMode = '1', sync) {
        let paramReceiptManager = GraphQL.getInstance().receipts
        let txnTypeString;
        let transactionData = Utils.getTransactionData(docId);
        return NetworkBridge.getReceiptManager().encryptJsonLd(jsonLd, docId, paramReceiptManager).then(res => {
            let inputObject = {}
            if (txnMode !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            txnTypeString = Utils.getTxnTypeForGraphQL(txnMode)
            return paramReceiptManager["addeLR"](transactionData.id, inputObject, txnTypeString);//transactionData.id
        }).then(res => {
            let signedGrnData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedGrnData)
        })
    }

    static attachANSync(docId, jsonLd, buyerId, txnMode = '1') {
        return ELRManager.attachAN(docId, jsonLd, buyerId, txnMode, true);
    }

    static updateAN(anId, parentId, jsonLd, txnMode, buyerId, sync) {
        let config, paramReceiptManager, receiptData;
        let selfParamId = Utils.getParamId();
        let transactionData = Utils.getTransactionData(parentId);
        let grnTransactionData = Utils.getTransactionData(anId);
        let privateKey = Utils.getPrivateKey();
        return Utils.getConfig("ParamContract").then(res => {
            let promise = Promise.resolve({ receiptJson: jsonLd });
            config = res;
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
            if (txnMode === "2") {
                // Check Once
                if (selfParamId === buyerId) {
                    promise = NetworkBridge.getReceiptManager().encryptJsonLd(jsonLd, parentId, paramReceiptManager);
                }
                else {
                    promise = paramReceiptManager.getReceipt(transactionData.id).then(res => {
                        receiptData = res.jsonLd;
                        return NetworkBridge.getReceiptManager().getReceiptRootIdFromNetwork(transactionData.id, paramReceiptManager);
                    }).then(rootId => {
                        return paramReceiptManager.getSubscriberDetailsForId(selfParamId, rootId);
                    }).then(subscriberPayload => {
                        const receiverPublicKey = receiptData.substr(-128);
                        const senderPublicKey = receiptData.substr(0, 128);
                        const encryptedRandomStr = ECIES.getEncryptedRandomString(receiptData.slice(128, -128));
                        const randomStr = ECIES.decrypt(privateKey, subscriberPayload[1]);
                        return { receiptJson: ECIES.encryptForSubscriber(receiverPublicKey, jsonLd, randomStr, encryptedRandomStr, senderPublicKey) };
                    })
                }
            }
            return promise;
        }).then(res => {
            return Utils.sendToIPFS(res.receiptJson);
        }).then(ipfsHash => {
            let options = Utils.getNetworkOptions();
            if (sync) {
                return paramReceiptManager["updateGRNSync"](grnTransactionData.id, ipfsHash, txnMode, options);
            }
            return paramReceiptManager["updateGRN"](grnTransactionData.id, ipfsHash, txnMode, options);
        })
    }

    static getAllAcceptNotesByDocId(docId, docStep) {
        return ParamConnector.getInstance().getDB().acceptNote.getAllAcceptNotesByDocId(docId, docStep);
    }

    static getReceipt(docId) {
        return ParamConnector.getInstance().getDB().acceptNote.getAcceptNote(docId);
    }

    static getSummary(docId, predicates) {
        return ParamConnector.getInstance().getDB().acceptNote.getSummary(docId, predicates);
    }

    static getValidationData(docId) {
        return ParamConnector.getInstance().getDB().acceptNote.getValidationData(docId);
    }

    static getAllReceiptsByParentV1(docId) {
        return ParamConnector.getInstance().getDB().acceptNote.getAllReceiptsByParentV1(docId);
    }

    static getItemQuantitySummary(docId) {
        return ParamConnector.getInstance().getDB().acceptNote.getItemQuantitySummary(docId);
    }

}
export default ELRManager;