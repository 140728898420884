import './index.less';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Spin, Icon } from 'antd';

const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />;

class ConfigLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillUpdate() {
        if (this.props.urlData) {
            let urlData = this.props.urlData.split('?')[1];
            this.props.history.push(`/acceptinvitation?${urlData}`);
        }
    }

    render() {
        return (
            <div className="configloader">
                <Spin indicator={antIcon} /><br /><br />
                <p>{this.props.status}</p>
            </div>
        )
    }
}
export default withRouter(ConfigLoader);

