import Database from '../../index'
import * as utils from '../../../util/utils';
import NetworkDataFormat from '../Utils/network-data-format';
import Console from '../../../logger/index';
import RestoreSubscribers from '../sync/subscribers';
import ECIES from '../../../util/ecies';

class SubscriberDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerEvents(contractAddress, paramId) {
        let subscriberContractObject = this.dbInstance.getReceiptManager(contractAddress)
        subscriberContractObject.registerOnSubscriber(this._onSubscriberAdded.bind(this), { address: paramId });
        subscriberContractObject.registerOnConsent(this._onConsent.bind(this), { address: paramId });
    }

    registerOnSubscriber(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnSubscriber", callback);
        console.info(`[GraphDB] register event`);
    }

    unRegisterOnSubscriber(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnSubscriber", callback);
    }

    registerOnConsent(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnConsent", callback);
        console.info(`[GraphDB] register event`);
    }

    unRegisterOnConsent(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnConsent", callback);
    }

    registerOnSubscriberAdded(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onSubscriberAdded", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnSubscriberAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onSubscriberAdded", callback);
    }

    _onSubscriberAdded(error, dataJSON) {
        Console.info(`[GraphDB] Got _onSubscriberAdded event`);
        if (error) {
            return;
        }
        let subsId = dataJSON.args.sId;
        let self = utils.getParamId(), receiptKey = utils.getFromLocalStorage("privateKey"), promise;
        let dbReceiptId = utils.getTransactionId(dataJSON.args.rId, dataJSON.args.docContractAddress);
        if (dataJSON.args.subscriber !== self) {
            promise = this.dbInstance.receipts.doesExist(dbReceiptId).then(res => {
                if (!res) {
                    return;
                }
                return this.dbInstance.subscribers.addSubscriberForParticipant(dbReceiptId, dataJSON.args.subscriber);
            })
        }
        else {
            promise = this.dbInstance.getSubscriberManager(dataJSON.args.contractAddress).getSubscriber(subsId).then(subscriberData => {
                if (subscriberData[1] !== "") {
                    receiptKey = ECIES.decrypt(utils.getFromLocalStorage("privateKey"), subscriberData[1]);
                }
                return RestoreSubscribers.restoreAllReceiptsInTree(dataJSON.args.rId, dataJSON.args.docContractAddress, dataJSON.args.subscriber, receiptKey);
            })
        }

        return promise.then(() => {
            Console.info(`[GraphDB] Add subscriber for ${dataJSON.args.rId}`);
            this._emitOnSubscriberAdded(dataJSON.args.rId, dataJSON.args.subscriber);
        }).catch(e => {
            Console.error(`Unable to add subscriber, Reason: ${e}`);
        })
        // .finally(() => {
        //     let owner = dataJSON.args.seller === self ? dataJSON.args.buyer : dataJSON.args.seller;
        //     return this.dbInstance.notifications.addNotification(dataJSON.args.rId, "onSubscriberAdded", "subscriber", [owner, dataJSON.args.subscriber]).then(() => {
        //         this.dbInstance.emitEvent("notification", dataJSON.args.rId, null);
        //     }).catch(error => {
        //         Console.error(`Unable to add subscriber info for ${dataJSON.args.rId}, Reason: ${error}`)
        //     })
        // })
    }

    _emitOnSubscriberAdded(rId, subscriberInfo) {
        if (!this.dbInstance) {
            return;
        }
        this.dbInstance.emitEvent("OnSubscriber", rId, subscriberInfo);
    }

    _onConsent(error, dataJSON) {
        if (error) {
            return;
        }
        let type = dataJSON.args._type.toString();
        dataJSON.args.rId = utils.getTransactionId(dataJSON.args.rId, dataJSON.args.docContractAddress);

        let consent = {};
        if (type === "1") {
            type = "seller";
        } else {
            type = "buyer";
        }
        let that = this;
        consent[`${type}`] = { "primaryKey": dataJSON.args.rId + "", "addres": dataJSON.args.owner }
        //TODO discuss with shubham and vikram
        return this.dbInstance.receipts.doesExist(dataJSON.args.rId).then(doesExist => {
            if (doesExist) {
                return this.dbInstance.subscribers.addConsent(dataJSON.args.rId, consent).then(data => {
                    let consent = {
                        id: `${dataJSON.args.rId}`,
                        type: type,
                        value: [dataJSON.args.owner]
                    };
                    console.info(`[GraphDB] Add consent for ${dataJSON.args.rId}`);
                    that._emitOnConsent(dataJSON.args.rId, consent);
                }).catch(e => {
                    console.info(`[GraphDB] Unable to add consent, reason ${e}`);
                }).finally(() => {
                    let docId = dataJSON.args.rId;
                    let metaData = NetworkDataFormat.getMetaInfo(docId, dataJSON, "On conent");
                    metaData.type = type;
                    return this.dbInstance.transactionsDB.addTransaction(docId, dataJSON.args.owner, metaData).catch(error => {
                        Console.error(`Unable to add subscriber info for ${docId}, Reason: ${error}`)
                    })
                })
            }
            return;
        })
    }

    _emitOnConsent(rId, consent) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.emitEvent("OnConsent", rId, consent);
    }
}
export default SubscriberDBEvents;