import JsonToJsonLd from "./json-jsonld";

class PRMToJsonLd {

    static convert(prmJSON) {
        let prmData = JSON.parse(atob(prmJSON.data));
        let jsonLd = [];
        for (let i = 0; i < prmData.length; i++) {
            try {
                jsonLd.push(JsonToJsonLd.convert(JSON.parse(prmData[i])));
            }
            catch (error) {
                throw new Error("Unable to Parse! Wrong format!");
            }
        }
        return jsonLd;
    }

}

export default PRMToJsonLd;