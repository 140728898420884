import React from 'react';
import { Row, Col, Button, Card, Icon, message, Modal, Tag, Select, Radio, Input, Form, Tabs, Switch, Checkbox, Divider } from 'antd';
import { Link } from 'react-router-dom';
import ReactJson from 'react-json-view';
import Auxiliary from "util/Auxiliary";
import BaseComponent from '../BaseComponent/index';
import NetworkBridge from '../../../util/network-bridge';
import ParamConnector from '../../../param-connector/index';
import CreateModalForm from '../../components/ModalForm';
import CircularProgress from '../../../components/CircularProgress';
import * as Utils from '../../../util/utils';
import * as APP_CONSTANTS from '../../../constants/AppConstants';
import * as ConfigObj from '../../../param-network/config1.json';
import Console from '../../../logger/index';
import Web3Utils from 'web3-utils';
import * as IpfsConfig from '../../../param-network/ipfs-config.json'
import Plugins from './plugins';
import Customizations from './customizations'
import ProxyConfig from './proxy-config';
import BetaSetting from './betaSetting';
import ReceiptSetting from './receiptSettings'
import Analytics from '../../../analytics';
import { setThemeType, onNavStyleChange } from "../../../appRedux/actions/Setting";
import { connect } from "react-redux";
import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE,
    THEME_TYPE_DARK,
    THEME_TYPE_LITE,
    THEME_TYPE_SEMI_DARK
} from "../../../constants/ThemeSetting";
import {
    SHOW_SALES,
    HIDE_SALES,
    SHOW_LOGISTICS,
    HIDE_LOGISTICS,
    SHOW_PURCHASES,
    HIDE_PURCHASES
} from "../../../constants/ActionTypes"
import Customizer from '../../../containers/Customizer';

import './setting.less';

const { Option } = Select;
const { TabPane } = Tabs;
const loaderIcon = <Icon type="loading" className="progressIcon" style={{ fontSize: "16px", verticalAlign: "inherit" }} spin />;
const checkIcon = <Icon type="check-circle" className="progressIcon" style={{ fontSize: '16px', color: "#9013fe", verticalAlign: "inherit" }} theme="filled" />;
const errorIcon = <Icon type="close-circle" className="progressIcon" style={{ fontSize: '16px', color: "red", verticalAlign: "inherit" }} theme="filled" />
const fetchingText = ["Fetching meta info from the network.", "Checking meta info from local MongoDB.", "Writing missing records into the MongoDB.", "Successfully updated MongoDB."];

class Setting extends BaseComponent {

    constructor(props) {
        super(props);
        Analytics.getInstance().trackPageview();
        this.configObj = ConfigObj.privateNodes;
        this.ipfsConfig = IpfsConfig.ipfsNodes;
        this.paramNetwork = ParamConnector.getInstance(Utils.getConfigObject());
        this.connection = this.paramNetwork.getConnection();
        this.contractAddressNames = ['ParamContract', 'ParamContactImpl', 'ParamCatalogueImpl', 'ParamInventoryImpl', 'TemplateRepositoryImpl', 'TemplateConsensusImpl', 'ParamVendors'];
        // this.isConnected();

    }

    componentDidMount() {
        super.componentDidMount();
        Console.log(Utils.getFromLocalStorage("proxySettings"));
        console.log('ipfsEndpoint', Utils.getFromLocalStorage('ipfsEndPoint'))
        if (!this.state.networkDetailsFetched) {
            // this.isConnected();
            // this.fetchNetworkDetails();
        }
        this.checkProtectedMode();
    }

    checkProtectedMode = () => {
        Utils.getPublic(Utils.getParamId()).then(publicKey => {
            if (publicKey) {
                this.setState({ protectedModeActivated: true, showLoader: false, isConnected: true })
            }
        })
    }
    // static getDerivedStateFromProps(props,state){
    // }

    // componentDidUpdate(prevProps, prevState){
    //     console.log('####', prevState.isConnected)
    //     this.isConnected();
    //     this.fetchNetworkDetails();
    // }
    initStates() {
        let orgId = Utils.getFromLocalStorage('organizationId'), selectedServer = 0;
        let currentMode = Utils.getFromLocalStorage('receiptMode')
        let currentTheme = Utils.getFromLocalStorage('theme')
        for (let index in this.configObj) {
            if (orgId === this.configObj[index].publicAddress[0]) {
                selectedServer = index;
            }
        }
        let reactjsonTheme;
        if (currentTheme === 'THEME_TYPE_DARK') {
            reactjsonTheme = 'monokai';
        }
        this.state = {
            showLoader: true,
            loader: false,
            finished: false,
            contactIcon: loaderIcon,
            itemsIcon: loaderIcon,
            receiptIcon: loaderIcon,
            templateIcon: loaderIcon,
            syncInProgress: false,
            progressText: '',
            organizationId: orgId,
            selectedServer: selectedServer,
            peerInfoResponse: [],
            isConnected: false,
            networkSelectionType: "default",
            currentConnection: Utils.getFromLocalStorage('url'),
            ipfsEndpoint: Utils.getFromLocalStorage('ipfsEndPoint'),
            showIpfs: false,
            receiptMode: currentMode ? currentMode : 'comfortable',
            selectedTheme: currentTheme ? currentTheme : THEME_TYPE_LITE,
            reactjsonTheme,
        }
    }
    renderCustomizations = () => {

        return (
            <Card className="setting__card" title={<h2 className="setting__nomargin">Customization</h2>}>
                <div>
                    <Checkbox checked={this.state.showSales} onChange={this.onSalesCheck}>Sales</Checkbox>
                    <Checkbox checked={this.state.showPurchases} onChange={this.onPurchasesCheck}>Purchases</Checkbox>
                    <Checkbox checked={this.state.showLogistics} onChange={this.onLogisticsCheck}>Logistics</Checkbox>
                    <Divider />
                    <Row>
                        <Col xl={4} lg={12} md={12} sm={8} xs={24}>
                            <h4>Draft invoice</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24}>
                            <Switch onChange={this.onEnabled} />
                        </Col>
                    </Row>
                </div>
            </Card>
        )
    }
    peerList = () => {
        var listArr = [];
        let peerInfoResponse = this.state.peerInfoResponse
        for (var i = 0; i < peerInfoResponse.length; i++) {
            listArr.push(
                <li key={i} style={{ marginTop: "1rem" }}>
                    <p style={{ marginBottom: '1rem' }}><b>Network :</b> {peerInfoResponse[i].name ? peerInfoResponse[i].name : "-"}</p>
                    <p style={{ marginBottom: '1rem' }}><b>RemoteAddress :</b> {peerInfoResponse[i].network.remoteAddress ? peerInfoResponse[i].network.remoteAddress
                        : "-"}</p>
                </li>
            )
        }
        return listArr;
    }

    changeNetworkSelectionType = e => {
        // Console.debug('radio checked', e.target.value);
        this.setState({
            networkSelectionType: e.target.value,
        });
    };

    setModalFormRef = element => {
        this.modalFormRef = element;
    }

    renderConnectionUrl = () => {
        let connectedNetworkDetails = {
            publicAddress: Utils.getFromLocalStorage("organizationId"),
            rpcURL: Utils.getFromLocalStorage("url"),
            privateUrl: Utils.getFromLocalStorage("privateUrl"),
            versionControl: Utils.getFromLocalStorage("versionControl"),
            configObj: Utils.getFromLocalStorage("configObj")
        };

        const ModalForm = Form.create({ name: 'newForm' })(CreateModalForm)
        return (
            <div>

                <Radio.Group onChange={this.changeNetworkSelectionType} value={this.state.networkSelectionType} style={{ marginBottom: "2em" }}>
                    <Radio value={"default"}>Select Organisation ID</Radio>
                    <Radio value={"manual"}>Enter manually</Radio>
                </Radio.Group>
                {
                    this.state.networkSelectionType === "default" ?
                        <>
                            <Select
                                defaultValue={this.state.selectedServer}
                                placeholder="Please select organization Id"
                                onChange={this.handleSelectChange}
                            >
                                {
                                    this.configObj.map((item, index) => (
                                        <Option
                                            value={index}
                                        >
                                            {item.publicAddress[0]}
                                        </Option>
                                    ))
                                }
                            </Select >
                            <div style={{ marginTop: "10px" }}><p className="networkText">Network connected to : {this.state.currentConnection}</p>
                            </div>
                        </>
                        :
                        <div>
                            <ModalForm formName="ManualConnectionForm" ref={this.setModalFormRef} modalFormData={connectedNetworkDetails} />
                        </div>
                }
            </div>
        )
    }

    handleSelectChange = (index) => {
        let selectedNetworkObj = this.configObj[index];
        this.setState({
            selectedServer: index,
            currentConnection: selectedNetworkObj.rpcURL

        })
    }

    editNetworkChange = () => {
        let selectedIndex = this.state.selectedServer;
        let selectedNetworkObj = this.configObj[selectedIndex];
        let error;
        if (this.state.networkSelectionType === "manual") {

            this.modalFormRef.validateFieldsAndScroll((err, values) => {
                error = err;
                if (!error) {
                    Console.debug("modalformvalues", values)
                    values.publicAddress = Utils.getArrayOfData(values.publicAddress);
                    selectedNetworkObj = values;
                }
            });
            if (error) {
                Console.error(error);
                message.error("Please provide mandatory fields");
                return
            }
        }
        Utils.setLocalStorage('organizationId', selectedNetworkObj.publicAddress[0]);
        Utils.setLocalStorage('url', selectedNetworkObj.rpcURL);
        Utils.setLocalStorage('privateUrl', selectedNetworkObj.privateUrl);
        Utils.setLocalStorage('versionControl', selectedNetworkObj.versionControl);
        Utils.setLocalStorage('configObj', selectedNetworkObj.configObj);
        NetworkBridge.getProfileManager().resetConnection();
        this.setState({ editNetwork: false })
    }

    showEditIpfsConfig = () => {
        this.setState({ editIpfsConfig: true })
    }

    handleIPFSChange = (value) => {
        this.setState({ selectedIpfsEndPoint: value })
    }

    saveIpfsConfig = () => {
        const { selectedIpfsEndPoint } = this.state
        this.setState({ editIpfsConfig: false, ipfsEndpoint: selectedIpfsEndPoint }, () => {
            Utils.setLocalStorage('ipfsEndPoint', selectedIpfsEndPoint)
        })
    }

    getNetworkSetting = () => {
        let currentIpfsConfig;
        if (!this.state.ipfsEndpoint) {
            currentIpfsConfig = this.ipfsConfig[0]
            Utils.setLocalStorage('ipfsEndPoint', currentIpfsConfig.url)
        } else {
            for (let index in this.ipfsConfig) {
                if (this.ipfsConfig[index].url === this.state.ipfsEndpoint) {
                    currentIpfsConfig = this.ipfsConfig[index];
                }
            }
        }

        return (
            <div>
                {/* <Row>
                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Network:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <p>{this.state.currentConnection}&nbsp;</p>
                    </Col>
                </Row> */}
                <Row>
                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Version:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <p>{APP_CONSTANTS.AppVersion}</p>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Name:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <p>{this.connectionNode}</p>
                    </Col>
                </Row> */}
                <Row>
                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Connection:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <p>Connected</p>
                        {/* <p>{this.connectionStatus}</p> */}
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Peers:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <div className="fieldContainer">
                            <div className="labelsecondary">
                                <span>
                                    {
                                        !this.state.showPeer ?
                                            <Tag color="green" onClick={() => this.setState({ showPeer: !this.state.showPeer })}>View Peer Info</Tag>
                                            :
                                            <Tag color="red" onClick={() => this.setState({ showPeer: !this.state.showPeer })}>Hide Peer Info</Tag>
                                    }
                                    {
                                        this.state.showPeer ?
                                            <div className="setting__network_peerlist">
                                                <ul>
                                                    {
                                                        this.state.peerInfoResponse.length > 0 ?
                                                            this.peerList()
                                                            :
                                                            <p>No peers found currently.</p>
                                                    }
                                                </ul>
                                            </div>
                                            : null
                                    }
                                    {this.connectionPeer}
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ overflowX: 'auto' }}>
                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Node Info:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <div className="fieldContainer">
                            <div className="labelsecondary">
                                {
                                    !this.state.showJson ?
                                        <Tag color="green" onClick={() => this.setState({ showJson: !this.state.showJson })}>View NodeInfo</Tag>
                                        :
                                        <Tag color="red" onClick={() => this.setState({ showJson: !this.state.showJson })}>Hide NodeInfo</Tag>
                                }
                                <br />
                                {
                                    this.state.showJson ?
                                        <div className="fieldContainer setting__network_jsonview">
                                            <ReactJson width="400" collapsed="1" src={this.state.nodeInfoResponse} theme={this.state.reactjsonTheme} />
                                            <br />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </Col>
                </Row> */}
                <Row>
                    {/* <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Contract address:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <div>
                            <div className="fieldContainer">
                                <div className="labelsecondary">
                                    {
                                        !this.state.showContract ?
                                            <Tag color="green" onClick={() => this.setState({ showContract: !this.state.showContract })}>View Info</Tag>
                                            :

                                            <Tag color="red" onClick={() => this.setState({ showContract: !this.state.showContract })}>Hide Info</Tag>
                                    }
                                    {
                                        this.state.showContract &&
                                        <ul className="setting__contract_address">
                                            {
                                                this.state.networkDetails.map((item, index) => (
                                                    <li>{this.contractAddressNames[index]}: <span>{item}</span></li>
                                                ))
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col> */}

                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Protected Mode:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <p>{<Tag onClick={this.activateProtectedMode}>{this.state.protectedModeActivated ? "Activated" : "Activate"}</Tag>}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xl={4} lg={4} md={8} sm={8} xs={24} align='right'>
                        <p>Ipfs Config:</p>
                    </Col>
                    <Col xl={20} lg={20} md={16} sm={12} xs={24}>
                        <div>
                            <span>
                                {
                                    this.state.showIpfs ?
                                        <Tag color="red" onClick={() => this.setState({ showIpfs: !this.state.showIpfs })}>Hide</Tag>
                                        :
                                        <Tag color="green" onClick={() => this.setState({ showIpfs: !this.state.showIpfs })}>View</Tag>
                                }

                            </span>
                            <span>
                                <Icon type="edit" onClick={this.showEditIpfsConfig} className="iconStyle" />
                            </span>
                            {
                                this.state.showIpfs &&
                                <div className="fieldContainer setting__network_jsonview">
                                    <ReactJson width="400" collapsed="1" src={currentIpfsConfig} theme={this.state.reactjsonTheme} />
                                    <br />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div >
        )
    }
    static backgroundSync() {
        this.handleFullSync()
    }
    handleFullSync = () => {
        this.setState({ syncInProgress: true });

        let reactState = this;
        NetworkBridge.getSyncManager().syncAllData({
            onProgress: function (type, status) {
                Console.debug('statusText', status, fetchingText[status], type)
                let modalText = type.charAt(0).toUpperCase() + type.slice(1);
                reactState.setState({ progressText: modalText + ": " + fetchingText[status] });

                if (status === 3) {
                    switch (type) {
                        case "contact":
                            reactState.setState({ contactIcon: checkIcon });
                            break;

                        case "items":
                            reactState.setState({ itemsIcon: checkIcon });
                            break;

                        case "invoices":
                            reactState.setState({ receiptIcon: checkIcon });
                            break;
                        case "consensus":
                            reactState.setState({ templateIcon: checkIcon });
                            break;
                        default:
                    }
                    if (type === "consensus") {
                        message.success("Synced Data successfully");
                        reactState.setState({ finished: true, loader: false });
                    }
                }
            },
            onProgressText: function (type, statusText) {
                Console.debug('onProgressText', statusText)
            },
            onError: function (e) {
                message.error("error in syncing");
                Console.error("Error in syncing", e)
                reactState.setState({ finished: true, loader: false });
                reactState.state.contactIcon === loaderIcon && reactState.setState({ contactIcon: errorIcon });
                reactState.state.itemsIcon === loaderIcon && reactState.setState({ itemsIcon: errorIcon });
                reactState.state.receiptIcon === loaderIcon && reactState.setState({ receiptIcon: errorIcon });
                reactState.state.templateIcon === loaderIcon && reactState.setState({ templateIcon: errorIcon });
            }
        })
    }

    handleModalOk = () => {
        this.setState({
            loader: false,
            finished: false,
            contactIcon: loaderIcon,
            itemsIcon: loaderIcon,
            receiptIcon: loaderIcon,
            templateIcon: loaderIcon,
            syncInProgress: false
        });
    }

    isConnected = () => {
        this.paramNetwork = ParamConnector.getInstance(Utils.getConfigObject());
        this.connection = this.paramNetwork.getConnection();
        // this.paramNetwork.setNetworkCallback({
        //     onNetworkStateChange: (isOnline) => {
        //         if (isOnline) {
        //             this.connectionStatus = "Connected";
        //             this.setState({ isConnected: isOnline })
        //             return this.getPeers();
        //         }
        //         this.connectionStatus = "OFFLine";
        //     }
        // })
    }

    activateProtectedMode = () => {
        if (this.state.protectedModeActivated) {
            return;
        }
        Utils.setPublic(Utils.getPublicKey()).then(res => {
            this.setState({ protectedModeActivated: true });
            message.success("Protected mode activated Successfully")
        }).catch(err => {
            Console.error(err)
            message.error("Error in activating protected mode");
        })
    }

    fetchNetworkDetails = () => {
        this.setState({ networkDetailsFetched: true });
        let selfParamId = Utils.getParamId();
        let promiseArray = [];
        for (let index in this.contractAddressNames) {
            promiseArray.push(
                Utils.getLatestContractAddressFromName(this.contractAddressNames[index])
            );
        }
        promiseArray.push(Utils.getPublic(selfParamId));
        Promise.all(promiseArray).then(res => {
            let protectedModeActivated = res.splice(-1)[0];
            this.setState({ networkDetails: res, protectedModeActivated }, () => {
                this.isConnected();
            });
        }).catch(err => {
            Console.error(err);
            this.setState({ showLoader: false });
            message.error("error in fetching network details");
        }).finally(() => {
            this.setState({ showLoader: false })
        })
    }

    getPeers = () => {
        this.getNodeInfo().then(data => {
            this.connectionNode = data;
            return this.getPeerCount();
        }).then(peerData => {
            this.connectionPeer = peerData;
            return this.getPeerInfo()
        }).then((result) => {
            this.setState({ peerInfoResponse: result.peerInfoResponse });
            return this.getNodeInfoResponse();
        }).then(result => {
            this.setState({ nodeInfoResponse: result.nodeInfoResponse.result });
        }).finally(() => {
            this.setState({ showLoader: false })
        }).catch(error => {
            Console.error(error);
            // message.error('Failed to fetch node info')
        })
    }

    getPeerCount() {
        let self = this;
        return new Promise((resolve, reject) => {
            self.connection.currentProvider.send({
                method: "net_peerCount",
                jsonrpc: "2.0",
                id: new Date().getTime()
            }, function (error, nodeInfoResponse) {
                if (error) {
                    Console.error('Failed to fetch node info', error);
                    message.error('Failed to fetch node info')
                    return reject(error);
                }
                if (nodeInfoResponse && nodeInfoResponse.result) {
                    nodeInfoResponse = nodeInfoResponse.result
                }
                resolve(Web3Utils.toBN(nodeInfoResponse).toString());
            })
        })
    }

    getNodeInfo() {
        let self = this;
        return new Promise((resolve, reject) => {
            self.connection.currentProvider.send({
                method: "web3_clientVersion",
                jsonrpc: "2.0",
                id: new Date().getTime()
            }, function (error, nodeInfoResponse) {
                if (error) {
                    Console.error('Failed to fetch node info', error);
                    message.error('Failed to fetch node info')
                    return reject(error);
                }
                if (nodeInfoResponse && nodeInfoResponse.result) {
                    nodeInfoResponse = nodeInfoResponse.result
                }
                resolve(nodeInfoResponse);
            })
        })
    }

    getNodeInfoResponse() {
        let self = this;

        return new Promise((resolve, reject) => {
            self.connection.currentProvider.send({
                method: "admin_nodeInfo",
                jsonrpc: "2.0",
                id: new Date().getTime()
            }, function (error, nodeInfoResponse) {
                if (error) {
                    Console.error('Failed to fetch node info', error);
                    message.error('Failed to fetch node info')
                    return reject(error);
                }
                resolve({ nodeInfoResponse });
            })
        })
    }

    getPeerInfo() {
        let self = this;
        return new Promise((resolve, reject) => {
            self.connection.currentProvider.send({
                method: "admin_peers",
                jsonrpc: "2.0",
                id: new Date().getTime()
            }, function (error, result) {
                if (error) {
                    Console.error('Failed to fetch peer info', error);
                    message.error('Failed to fetch peer info')
                    return reject(error);
                }
                let peerInfoResponse = []
                if (result.result) {
                    for (var i in result.result) {
                        peerInfoResponse.push(result.result[i]);
                    }
                }
                resolve({ peerInfoResponse: peerInfoResponse });
            })
        })
    }

    setEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    saveReceiptMode = () => {
        Utils.setLocalStorage('receiptMode', this.state.receiptMode)
        message.success('Display setting saved successfully')
        if (this.state.receiptMode !== 'compact') {
            this.props.onNavStyleChange(NAV_STYLE_FIXED)
        } else {
            Utils.setLocalStorage('workflowView', 'list')
            this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR)
        }
    }
    onReceiptModeChange = (e) => {
        console.log('radio checked', e.target.value);
        this.setState({
            receiptMode: e.target.value,
        });
    }

    saveThemeChange = () => {
        Utils.setLocalStorage('theme', this.state.selectedTheme)
        this.props.setThemeType(this.state.selectedTheme)
        message.success('Theme applied successfully')
        if (this.state.selectedTheme === THEME_TYPE_DARK) {
            document.body.classList.add('dark-theme');
        } else if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
        }
    }


    onThemeTypeChange = (e) => {
        // this.props.setThemeType(e.target.value);
        console.log('Theme checked', e.target.value);
        this.setState({
            selectedTheme: e.target.value,
        });
    };

    renderDisplaySetting = () => {
        const { receiptMode, selectedTheme } = this.state
        return (
            <Row>
                <Col md={12} xs={24}>
                    <Card className="setting__card" title={<h2 className="setting__nomargin">Display Settings</h2>}
                        extra={<Button className="gx-mb-0" type="primary" onClick={this.saveReceiptMode}>Save</Button>}>
                        <Radio.Group onChange={this.onReceiptModeChange} value={receiptMode}>
                            <Radio value="default">Default</Radio>
                            <Radio value="comfortable">Comfortable</Radio>
                            <Radio value="compact">Compact</Radio>
                        </Radio.Group>
                    </Card>
                </Col>
                <Col md={12} xs={24}>
                    <Card className="setting__card" title={<h2 className="setting__nomargin">Theme Settings</h2>}
                        extra={
                            <Button className="gx-mb-0" type="primary" onClick={this.saveThemeChange}>Save</Button>
                        }
                    >
                        <Radio.Group value={selectedTheme} onChange={this.onThemeTypeChange}>
                            <Radio value={THEME_TYPE_LITE}>Lite</Radio>
                            {/* <Radio value={THEME_TYPE_SEMI_DARK}>Semi Dark</Radio> */}
                            <Radio value={THEME_TYPE_DARK}>Dark</Radio>
                        </Radio.Group>
                    </Card>
                </Col>
            </Row>
        )
    }


    renderGeneralSettings = () => {
        return (
            <>
                <Plugins />
                <Customizations />
                {this.renderDisplaySetting()}
                <ProxyConfig />
                <Card className="setting__card" title={<h2 className="setting__nomargin">Network</h2>} extra={<div style={{ marginRight: '1rem' }}> <Button type="primary" className="setting__nomargin" onClick={this.handleFullSync}>SYNC</Button><Icon style={{ marginLeft: "10px" }} onClick={() => { this.setState({ editNetwork: true }) }} type='edit' className="iconStyle" /> </div>} >
                    {
                        this.state.isConnected ?
                            this.getNetworkSetting()
                            :
                            <div><p>No Network Connection</p></div>
                    }
                    {
                        this.state.syncInProgress &&
                        <Modal
                            centered
                            title="Syncing data from ⦃param⦄.network"
                            visible={this.state.syncInProgress}
                            closable={false}
                            width="400px"
                            footer={
                                this.state.finished ?
                                    <Button onClick={this.handleModalOk} type="primary">OK</Button>
                                    :
                                    this.state.progressText
                            }
                        >
                            <Row>
                                <Col xl={2} lg={2} md={2}>{this.state.contactIcon}</Col>
                                <Col xl={22} lg={22} md={22}><p>Contacts</p></Col>
                            </Row>
                            <Row>
                                <Col xl={2} lg={2} md={2}>{this.state.itemsIcon}</Col>
                                <Col xl={22} lg={22} md={22}><p>Items</p></Col>
                            </Row>
                            <Row>
                                <Col xl={2} lg={2} md={2}>{this.state.receiptIcon}</Col>
                                <Col xl={22} lg={22} md={22}><p>Receipts</p></Col>
                            </Row>
                            <Row>
                                <Col xl={2} lg={2} md={2}>{this.state.templateIcon}</Col>
                                <Col xl={22} lg={22} md={22}><p>Workflows</p></Col>
                            </Row>
                        </Modal>
                    }
                    {
                        this.state.editNetwork &&
                        <Modal
                            centered
                            title="Configure Network"
                            visible={this.state.editNetwork}
                            closable={true}
                            width="600px"
                            onOk={this.editNetworkChange}
                            onCancel={() => { this.setState({ editNetwork: false }) }}
                        >
                            {this.renderConnectionUrl()}
                        </Modal>
                    }
                    {
                        this.state.editIpfsConfig &&
                        <Modal centered
                            title="Configure IPFS"
                            visible={this.state.editIpfsConfig}
                            closable={true}
                            onCancel={() => { this.setState({ editIpfsConfig: false }) }}
                            footer={
                                <Button onClick={this.saveIpfsConfig}>Save</Button>
                            }
                        >
                            <div>
                                <p>Please Select Ipfs Endpoint</p>
                                <Select defaultValue={this.state.ipfsEndpoint}
                                    placeholder="Please select the url"
                                    onChange={this.handleIPFSChange}
                                >
                                    {
                                        this.ipfsConfig.map((item, index) => (
                                            <Option
                                                value={item.url}
                                            >
                                                {item.url}
                                            </Option>
                                        ))
                                    }
                                </Select >
                                <br />
                                <div>Connected to {this.state.ipfsEndpoint}</div>
                            </div>
                        </Modal>
                    }

                </Card>
            </>
        )
    }


    renderMainContent() {
        console.log('Toggles', this.state.showSales, this.state.showPurchases, this.state.showLogistics)
        return (
            <div className="tabCardContainer setting__tabcontainer">  {/* style={{ height: '95%' }} */}
                {/* <Card title={<h2 style={{ margin: 0 }}>Settings</h2>} style={{ height: '100%' }}> */}
                {this.state.showTabPaneLoader && <CircularProgress />}
                <Tabs defaultActiveKey="0" onChange={this.handleTabChange}>
                    <TabPane tab="General Settings" key="0" className="setting__tabcontainer__tabpane">
                        {this.renderGeneralSettings()}
                    </TabPane>
                    <TabPane tab="Receipt Settings" key="1" className="setting__tabcontainer__tabpane">
                        <ReceiptSetting />
                    </TabPane>
                    <TabPane tab="Beta Settings" key="2" className="setting__tabcontainer__tabpane">
                        <BetaSetting />
                    </TabPane>
                </Tabs>
                {/* <Customizer /> */}
                {/* </Card> */}
            </div>
        )
    }
}
// export default Setting;
const mapStateToProps = ({ settings }) => {
    const { themeType, navStyle, navCollapsed, width, locale, salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility } = settings;
    return { themeType, navStyle, navCollapsed, width, locale, salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility }
};
export default connect(mapStateToProps, { setThemeType, onNavStyleChange })(Setting);