import React from 'react';
import { Input, Form } from 'antd';

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
        this.input.select();
      }
    });
  }

  save = () => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      for (let key in values) {
        if (!isNaN(values[key])) {
          values[key] = Number(values[key]);
        }
      }
      handleSave({ ...record, ...values });
    });
  }

  validateItemField = (rule, value, callback) => {
    // console.log('rule',rule)
    // console.log('value',value)
    // console.log(' this.form',  this.form)
    let form = this.form;
    let key = rule.field;
    if (!value) {
      if (key === 'quantity' || key === 'unitprice' || key === 'sgst' || key === 'cgst' || key === 'igst') {
        form.setFieldsValue({
          [key]: 0
        })
        callback()
      }
    } else {
      callback()
    }
  }

  render() {
    const { editing } = this.state;
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      type,
      handleSave,
      ...restProps
    } = this.props;

    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{
                        required: true,
                        message: `${title} is required.`,
                      },
                      { validator: this.validateItemField }
                      ],
                      initialValue: record[dataIndex] || 0,
                    })(
                      <Input type={type && type}
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                        onBlur={this.save}
                        style={{ width: "8rem" }}
                      />
                    )}
                  </FormItem>
                ) : (
                    <div
                      className="editable-cell-value-wrap"
                      style={{ paddingRight: 24 }}
                      onClick={this.toggleEdit}
                    >
                      {restProps.children}
                    </div>
                  )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

export { EditableFormRow, EditableCell };