import ParserUtils from '../../../../parsers/ParserUtils';
import * as Utils from '../../../../util/utils';
import JsonToJsonLd from './json-jsonld';
import ThirdPartyUtils from '../../utils/IIIrd-party-utils';
const parse = require('csv-parse');

class CSVToJsonLd {
    static parseCSVStringToJSON(csvString) {
        return new Promise((resolve, reject) => {
            parse(csvString.trim(), {
                relax_column_count: true,
                columns: true
            }, (err, data) => {
                if (err) {
                    return reject(err);
                }
                resolve(CSVToJsonLd._convertJsonToJsonLd(data));
            });
        })
    }

    static _convertJsonToJsonLd(records) {
        console.log(records);
        return records;
    }

    static getPersonData(legalName, tradeName, locality, city, stateCode, state, gstin, pinCode, email, phone, key, jsonLd) {
        let personJsonLd = {
            "@context": "http://schema.org/",
            "@type": "Person",
            "name": tradeName,
            "taxId": gstin,
            "address": {
                "@type": "PostalAddress",
                "streetAddress": locality,
                "addressLocality": city,
                "addressRegion": stateCode,
                "postalCode": pinCode,
                "state": state
            },
            "email": email,
            "telephone": phone
        }
        jsonLd[key] = personJsonLd;
        return jsonLd;
    }

    static getCategory(type, categoryName, jsonLd) {
        let category = {
            "@type": "Thing",
            "identifier": type,
            "name": categoryName
        }
        jsonLd["category"] = category;
        return jsonLd;
    }

    static getInvoiceInfo(irn, qrCode, signedIrn, status, uploaded, lastChecked, addOnsJsonLd) {
        let invoiceInfo = {};
        invoiceInfo = ParserUtils.getCorrectData(irn, "irn", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(qrCode, "qrCode", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(signedIrn, "signedIrn", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(status, "status", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(uploaded, "uploaded", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(lastChecked, "lastChecked", invoiceInfo);
        addOnsJsonLd["invoiceInfo"] = invoiceInfo;
        return addOnsJsonLd;
    }

    static getAddOns(invoiceDetails, jsonLd) {
        let addOns = {
            "@type": "thing",
            "ecomGstin": invoiceDetails["ECOM_GSTIN"],
            "ecomPos": invoiceDetails["ECOM_POS"],
            "reverseCharge": invoiceDetails["Reverse Charge"],
            "invoiceReferenceNumber": invoiceDetails["Invoice Reference  Number"],
            "isInvoiceCancelled": invoiceDetails["Is Invoice cancelled"],
            "projectReference": invoiceDetails["Project_Reference"],
            "contractReference": invoiceDetails["Contract_Reference"],
            "externalReference": invoiceDetails["External_Reference"],
            // "tenderOfLotReference": invoiceDetails["tenderOfLotReference"],
            "precedingInvoiceNumber": invoiceDetails["Preceeding_Invoice_Number"],
            "receiptAdviceReference": invoiceDetails["Receipt_Advice_Reference"],
            "payerFinancialAccount": invoiceDetails["Payer_Financial_Account"],
            "creditTransfer": invoiceDetails["Credit_Transfer"],
            "financialInstitutionBranch": invoiceDetails["Financial_Institution_Branch"],
            "directDebit": invoiceDetails["Direct_Debit"],
            "creditDays": invoiceDetails["CreditDays"],
            "paymentInstruction": invoiceDetails["Payment_Instruction"],
            "venderPOReferenceNumber": invoiceDetails["Vendor PO Reference number"],
            "venderPOReferenceDate": invoiceDetails["Vendor PO Reference date"],
            "tenderOfLotReference": invoiceDetails["Tender_or_Lot_Reference"],
            "payeeName": invoiceDetails["Payee_Name"],
            "modeOfPayment": invoiceDetails["Mode of Payment"],
            "paymentTerms": invoiceDetails["Payment_Terms"],
        }
        addOns = CSVToJsonLd.getInvoiceInfo(invoiceDetails["Invoice Reference  Number"], invoiceDetails["QR Code"], invoiceDetails["Signed Irn"], invoiceDetails["Is Invoice cancelled"], invoiceDetails["Uploaded"], invoiceDetails["Last Checked"], addOns);
        jsonLd["addOns"] = addOns;
        return jsonLd;
    }

    static orderedItemAddOn(data) {
        let taxKeys = ["SGST Value:SGST", "CGST Value:CGST", "IGST Value:IGST", "Discount Amount:Discount Amount", "Cess Value:Cess Value", "State Cess Amt:State Cess Amt", "Other Charges:Other Charges", "CesNonAdval:Cess Non AdVal"];
        let addOnArray = [];
        for (let index = 0; index < taxKeys.length; index++) {
            let taxNameMapping = taxKeys[index].split(':');
            let addOnObj = {
                "@type": "Offer",
                "price": data[taxNameMapping[0]] ? parseInt(data[taxNameMapping[0]]) : 0,
                "description": "%",
                "name": taxNameMapping[1]
            }
            addOnArray.push(addOnObj);
        }
        return addOnArray;
    }

    static addOffers(data, unitPrice, quantity) {
        let offer = {};
        offer = ParserUtils.getCorrectData(data.currency, "priceCurrency", offer);
        offer = ParserUtils.getCorrectData(Utils.getPrice(parseFloat(unitPrice)), "price", offer);
        offer = ParserUtils.getCorrectData(data.availability, "availability", offer);
        offer["@type"] = "Offer";
        offer["addOn"] = CSVToJsonLd.orderedItemAddOn(data);
        return offer;
    }

    static getOrderedItem(item, referencesOrder) {
        let orderItem = [];
        for (let index = 0; index < item.length; index++) {
            let orderedItemObj = {}, orderObj = {};
            orderObj["@type"] = "Product";
            orderObj["slno"] = item[index]["SLNO"];
            orderObj["name"] = item[index]["Item Description"];
            orderObj["hsnCode"] = item[index]["HSN code"];
            orderObj["unit"] = item[index]["UQC"]
            orderObj["offers"] = CSVToJsonLd.addOffers(item[index], item[index]["Rate"], item[index]["Quantity"]);
            orderObj["isService"] = item[index]["IsServc"]
            orderedItemObj["@type"] = "OrderItem";
            orderedItemObj["orderQuantity"] = item[index]["Quantity"];
            orderedItemObj["orderedItem"] = orderObj;
            orderItem.push(orderedItemObj);
        }
        referencesOrder["orderedItem"] = orderItem;
        return referencesOrder;
    }

    static getReferencesOrder(itemList, date, jsonLd) {
        let referencesOrder = {
            "@type": "Order",
            "orderDate": date
        }
        referencesOrder = CSVToJsonLd.getOrderedItem(itemList, referencesOrder);
        jsonLd["referencesOrder"] = referencesOrder;
        return jsonLd;
    }


    static generateJsonLd(invoiceDetails, itemsDetails) {
        let jsonLd = {
            "@context": "http://schema.org/",
            "@type": "Invoice",
            "invoiceDate": invoiceDetails["Invoice Date"],
            "identifier": invoiceDetails["InvoiceType Code"],
            "@id": invoiceDetails["Invoice number"]
        }
        jsonLd = CSVToJsonLd.getPersonData(invoiceDetails["Supplier_Legal_Name"], invoiceDetails["Supplier_Trading_name"], invoiceDetails["Supplier_Address1"], invoiceDetails["Supplier_City"], invoiceDetails["Supplier_StateCd"], invoiceDetails["Supplier_State"], invoiceDetails["Supplier_GSTIN"], invoiceDetails["Supplier_Pincode"], invoiceDetails["Supplier_Email"], invoiceDetails["Supplier_Phone"], "provider", jsonLd);
        jsonLd = CSVToJsonLd.getPersonData(invoiceDetails["Buyer name"], invoiceDetails["Buyer name"], invoiceDetails["Address1"], invoiceDetails["Place"], invoiceDetails["State code"], invoiceDetails["State"], invoiceDetails["GSTIN"], invoiceDetails["Pincode"], invoiceDetails["E-Mail ID"], invoiceDetails["Supplier_Phone"], "customer", jsonLd);
        jsonLd = CSVToJsonLd.getPersonData(invoiceDetails["ShippingTo_Name"], invoiceDetails["ShippingTo_Name"], invoiceDetails["ShippingTo_Address1"], invoiceDetails["ShippingTo_Place"], invoiceDetails["ShippingTo_StateCd"],invoiceDetails["ShippingTo_State"], invoiceDetails["ShippingTo_GSTIN"], invoiceDetails["Supplier_Pincode"], "", "", "consignee", jsonLd);
        jsonLd = CSVToJsonLd.getPersonData(invoiceDetails["ShippingFrom_Company_Name"], invoiceDetails["ShippingFrom_Company_Name"], invoiceDetails["ShippingFrom_Address1"], invoiceDetails["ShippingFrom_City"], invoiceDetails["ShippingFrom_StateCd"], invoiceDetails["ShippingFrom_State"], invoiceDetails["ShippingFrom_GSTIN"], invoiceDetails["ShippingFrom_Pincode"], "", "", "consignor", jsonLd);
        jsonLd = CSVToJsonLd.getCategory(invoiceDetails["InvoiceType Code"], invoiceDetails["Invoice Type"], jsonLd);
        jsonLd = CSVToJsonLd.getAddOns(invoiceDetails, jsonLd);
        jsonLd = CSVToJsonLd.getReferencesOrder(itemsDetails, invoiceDetails["Invoice Date"], jsonLd);
        let { erpTaxInvoiceInfo, total } = ThirdPartyUtils.getReceiptInvoiceSum(jsonLd);
        jsonLd = JsonToJsonLd.paymentDue(total, "INR", "totalPaymentDue", jsonLd);
        jsonLd = JsonToJsonLd.paymentDue(total, "INR", "minimumPaymentDue", jsonLd);
        console.log("CONVERTED JSONLD====>>>>>>", jsonLd);
        return jsonLd;
    }

    static convert(csvString) {
        let csvArray = csvString.split("\n");
        let invoiceDetails = csvArray.slice(0, 2).join('\n');
        let invoiceItems = csvArray.slice(2).join('\n');
        let promiseArray = [];
        promiseArray.push(CSVToJsonLd.parseCSVStringToJSON(invoiceDetails));
        promiseArray.push(CSVToJsonLd.parseCSVStringToJSON(invoiceItems));
        return Promise.all(promiseArray).then((res) => {
            return CSVToJsonLd.generateJsonLd(res[0][0], res[1]);
        })
    }
}

export default CSVToJsonLd;