import React, { PureComponent } from "react";
import CircularProgress from "../../../components/CircularProgress";
import loader from '../../../assets/images/Loader-Param.gif';
import ParamConnector from "../../../param-connector";
import * as Utils from '../../../util/utils';
import Console from '../../../logger';
import ParamDashBoardConnector from '../../../param-libs/param-dashboard/index'
import * as Config from "../../../config.json"
import EventManager from "../../../event-manager/event-manager";
import Analytics from '../../../analytics';

export default class BaseComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.config = Config['analytics'];
    if (this.initStates)
      this.initStates();
  }

  // initStates() {

  // }

  getParamInstance() {

  }

  getConnection() {

  }

  isGraphDBAvailable() {

  }

  resetStates() {
    //TODO
  }

  getDB() {
    let config = Utils.getConfigObject()
    let paramId = Utils.getParamId();
    if (!config || !paramId) {
      return null;
    }
    config["walletAccount"] = paramId;

    ParamConnector.getInstance(config).setUserParamId(paramId);

    return ParamConnector.getInstance(config).getDB();
  }

  componentDidMount() {
    Console.log("Base componentDidMount")
    let config = Utils.getConfigObject()
    // {
    //   url: "ws://34.224.243.54:8546",
    //   privateUrl: "http://34.224.243.54:9081"
    // }
    let autoRefresh = Utils.getFromLocalStorage('autoRefresh') ? Utils.getFromLocalStorage('autoRefresh') : Config["events-sync-configuration"].autoRefresh
    let that = this;
    let paramId = Utils.getParamId();
    config["walletAccount"] = paramId;
    let paramConnector = ParamConnector.getInstance(config)
    paramConnector.setUserParamId(paramId);
    paramConnector.setCallback({

      onReady: function () {
        ParamDashBoardConnector.getInstance().registerEvents(paramId);
        //if (Utils.getFromLocalStorage('autoRefresh') === true)
        that.registerEvents()
        if (!that.onDBReady) {
          return;
        }
        that.onDBReady();
        // message.success("Component is ready")
      },

      // onNetworkStateChange: function(type, isConnected){
      //   //debugger;
      // },

    })
  }

  registerEvents() {
    if (!this.getRegisterEvents) {
      return;
    }
    this.regEvents = this.getRegisterEvents();
    let that = this;
    EventManager.getInstance().onceEvent("sessionExpired", () => {
      localStorage.clear();
      Analytics.getInstance().trackEvent(this.config.auth, this.config.sessionExpired)
      that.props.history.push('/otplogin')
      console.log("event emitted - session", this.props)
    })
    if (!this.regEvents) {
      return;
    }
    if (!Array.isArray(this.regEvents)) {
      let events = [];
      events.push(this.regEvents);
      this.regEvents = events;
      events = undefined;
    }
    let eventFunction;
    for (let index in this.regEvents) {
      let eventObject = this.regEvents[index];
      if (!eventObject || !(eventFunction = eventObject['registerEvent'])) {
        continue;
      }
      let dbEventObject = this.getDB();
      if (eventObject['eventCategory']) {
        let eventObjectCategory = eventObject['eventCategory'] + "DBEvents";
        dbEventObject = dbEventObject[eventObjectCategory];
        // eventFunction = `${eventObject.eventCategory}DBEvents`;
      }
      dbEventObject[eventFunction](eventObject.callback);
    }
  }

  getRegisterEvents(callback) {

  }

  componentWillUnmount() {
    Console.log("Base componentWillUnmount")
    // try {
    //   this.unRegiserEvent();
    // } catch (e) {
    //   Console.error("error in unmounting base component", e)
    // }
  }

  unRegiserEvent() {
    if (!this.getRegisterEvents || !this.regEvents) {
      return;
    }
    if (!Array.isArray(this.regEvents)) {
      return;
    }
    let eventFunction;
    for (let index in this.regEvents) {
      let eventObject = this.regEvents[index];
      if (!eventObject || !(eventFunction = eventObject['unRegisterEvent'])) {
        continue;
      }

      let dbEventObject = this.getDB();
      if (eventObject['eventCategory']) {
        let eventObjectCategory = eventObject['eventCategory'] + "DBEvents";
        dbEventObject = dbEventObject[eventObjectCategory];
        // eventFunction = `${eventObject.eventCategory}DBEvents`;
      }
      dbEventObject[eventFunction](eventObject.callback);
      // this.getDB()[eventFunction](eventObject.callback);
    }
  }

  showLoader() {
    Console.log('Inside showLoader')
    return (
      // <CircularProgress />
      <div className='loader' style={{ width: '10rem' }}>
        <img src={loader} />
      </div>
    )
  }

  render() {
    return this.state.showLoader ? this.showLoader() : this.renderMainContent();
  }

}