class FileErrors {
    static EMPTY_FILE = { error: "Unable to import", code: 20001 };
    static MAX_FILE_SIZE = { error: "Unable to import", code: 20002 };
    static INVALID_FILE_FORMAT = { error: "Invalid file format, Please provide valid file.", code: 20003 };
    static INVALID_SCHEMA = { error: "Invalid file schema. Please provide file with valid schema.", code: 20004 }
    // static EMPTY_FILE = {error: "Unable to import", code:10001};

    // static INVALID_FILE_FORMAT = {error: "Unable to import", code:10001};

    // static NO_CALL_BACK_ON_ERROR = {error: "Unable to import", code:10001};
    // static NO_CALL_BACK_ON_ERROR = {error: "Unable to import", code:10001};
    // static NO_CALL_BACK_ON_ERROR = {error: "Unable to import", code:10001};
    // static NO_CALL_BACK_ON_ERROR = {error: "Unable to import", code:10001};

}

export default FileErrors;