import Console from '../../../logger/index';
import Database from '../../../database/index';
import { gql } from '@apollo/client';
import RestoreReturns from '../sync/returns';
import * as NetworkUtils from '../../../../src/util/utils';
import NetworkBridge from '../../../util/network-bridge';

class ReturnsEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient
        this.dbInstance = Database.getInstance().getDB()
    }

    registerEvents(paramId) {
        let returnOptions = {
            query: gql`
               subscription onReturn($paramId: [String]){
                    onReturn(ReceiptOwnerID: $paramId){
                        contractAddress,
                        buyerId,
                        sellerId,
                        returnID,
                        status,
                        step,
                        parentId,
                        parentContractAddress
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(returnOptions).subscribe({
            next(data) {
                that.onRequesStatusUpdate(data, "onReturn")
            },
            error(error) {
                Console.error(`Unable to get create contact details form network, Reson: ${error}`);
            },
        });
        Console.log(`Registered Events for Returns - ${paramId}`)
    }

    _emitReturnEvent(dataJSON) {
        let eventName;
        let status = dataJSON.args.status.toString();
        let step = dataJSON.args.step.toString();
        switch (status) {
            case "0":
                if (step === "0") {
                    eventName = "onReturnRequestCreated";
                } else {
                    eventName = "onOrderRequestCreated";
                }
                break;
            case "1":
                if (step === "0") {
                    eventName = "onReturnRequestAccepted";
                } else {
                    eventName = "onOrderRequestAccepted";
                    this.onOrderRequestAccepted(dataJSON)
                }
                break;
            case "2":
                if (step === "0") {
                    eventName = "onReturnRequestRejected";
                } else {
                    eventName = "onOrderRequestRejected";
                }
                break;
            default:
                eventName = "onRequestStatusUpdate";
                break;
        }
        return this.dbInstance.emitEvent(eventName, dataJSON.args.id, NetworkUtils.getTransactionData(dataJSON.args.parentId).id);
    }

    onOrderRequestAccepted(dataJSON) {
        //operation will contiue in background
        const returnsDbId = NetworkUtils.getTransactionId(dataJSON.args.id, dataJSON.args.contractAddress);
        this.dbInstance.returns.getReturn(returnsDbId).then(res => {
            if (res) {
                let selfParamId = NetworkUtils.getParamId();
                let otherParamId;
                if (selfParamId === res.provider.identifier) {
                    otherParamId = res.customer.identifier;
                } else if (selfParamId === res.customer.identifier) {
                    otherParamId = res.provider.identifier;
                }
                if (otherParamId) {
                    return NetworkBridge.getItemManager().updateInventories(res, "Returns", otherParamId);
                }
            }
        })
    }

    onRequesStatusUpdate(subscriptionDataJSON) {
        Console.debug(subscriptionDataJSON)
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onReturn
        let transactionData = NetworkUtils.getTransactionData(dataJSON.args.returnID)
        dataJSON.args.id = transactionData.id
        if (dataJSON.args.id === "0x0000000000000000000000000000000000000000000000000000000000000000") {
            dataJSON.args.id = dataJSON.args.parentId;
        }
        dataJSON.args.id = NetworkUtils.getTransactionId(dataJSON.args.id, dataJSON.args.contractAddress);
        return RestoreReturns.restoreReturns(dataJSON.args.id, dataJSON.args.contractAddress, "", dataJSON.args.parentContractAddress).then((res) => {
            Console.log(`[GraphDB] Updated ${dataJSON.args.id} with status ${dataJSON.args.step}`);
            return this._emitReturnEvent(dataJSON)
        }).catch(e => {
            Console.error(`[Error] Unable to update return status for ${dataJSON.args.id}, Reason: ${e.toString()}`);
        })
    }
}

export default ReturnsEvents;