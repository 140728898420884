import schema from '../schema/jsonLd';
const Ajv = require('ajv')
const ajv = new Ajv({ allErrors: true });

class JSONValidator {

    static isValidJSONLd(data) {
        const valid = ajv.compile(schema.validate);
        const isValid = valid(data);
        console.log("Validation", valid.errors);
        return { isValid: true, errors: valid.errors };
    }
}

export default JSONValidator;