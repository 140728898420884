import RedisClientSocket from '../../client-lib/redis-client';
import * as networkUtils from '../../../../util/utils';

class EventUtils {
    static checkCanRefresh(channel, types, fromEvents) {
        let redisConnection = RedisClientSocket.getInstance();
        let realTimeUpdates = []
        for (let index in types) {
            realTimeUpdates.push(networkUtils.getRealTimeUpdate(types[index]));
        }
        if (fromEvents) {
            redisConnection.updateCanRefresh(channel, true)
        }
        if (fromEvents && !realTimeUpdates.includes(true)) {
            return false;
        }
        if (!redisConnection.canRefresh(channel)) {
            return false;
        }
        return true;
    }
}
export default EventUtils;