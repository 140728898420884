import React from "react";
import NotificationItem from "./NotificationItem";
import Auxiliary from "../../util/Auxiliary";
import BaseComponent from '../../routes/main/BaseComponent/index'
import NetworkBridge from "../../util/network-bridge";
import Console from '../../logger/index';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import * as Utils from '../../util/utils';

class AppNotification extends BaseComponent {
  constructor(props) {
    super(props)
  }

  initStates() {
    this.state = {
      showLoader: false,
      // numberOfNotifications: this.props.count,
      // notifications:this.props.data
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.setState({
      data: this.props.data
    })
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      data: newProps.data
    })
  }

  goToDocument = (notificationData) => {
    if (!notificationData.docId) {
      return;
    }
    if (notificationData.type === 'template-consensus') {
      this.props.history.push(`/main/operations/stepform/id=${notificationData.docId}`)
      this.props.onClose()
    }
    else if (notificationData.type === 'receipt') {
      let encodedId = Utils.encodeString(notificationData.docId)
      this.props.history.push(`/main/finance/purchases/receipt/${encodedId}`)
      this.props.onClose()
    }
    else if (notificationData.type === 'subscriber') {
      this.props.history.push(`/main/subscribed/default/id=${notificationData.docId}`)
      this.props.onClose()
    }
    else if (notificationData.type === 'template') {
      this.props.history.push(`/main/operations/invitations/id=${notificationData.docId}`)
      this.props.onClose()
    }
  }

  deleteNotification = (notificationData) => {
    if (!notificationData || !notificationData.notificationId) {
      return;
    }
    NetworkBridge.getNotificationManager().deleteNotification(notificationData.notificationId).then((res) => {
      message.success("Notification Deleted");
      this.props.getNotificationData(true);
    }).catch(err => {
      Console.error(err);
    })
  }

  markNotificationAsRead = (notificationData) => {
    if (!notificationData || !notificationData.notificationId) {
      return;
    }
    NetworkBridge.getNotificationManager().markAsRead(notificationData.notificationId).then((res) => {
      message.success("Marked as read");
      this.props.getNotificationData(true);
    }).catch(err => {
      Console.error(err);
    })
  }

  renderMainContent() {
    // console.log('App notification compo',this.props.data, this.props.count);
    let notificationData = this.state.data;
    return (
      <Auxiliary>
        {/* <CustomScrollbars className="gx-popover-scroll"> */}
        <ul className="gx-sub-popover" style={{ margin: 0 }}>
          {notificationData && notificationData.length > 0 &&
            notificationData.map((notification, index) => {
              return <NotificationItem key={index}
                notification={notification} onClick={() => this.goToDocument(notification)} onClickDelete={() => this.deleteNotification(notification)} onClickRead={() => { this.markNotificationAsRead(notification) }} markAsRead={notification.markAsRead} />

            }
            )
          }
        </ul>
        {/* </CustomScrollbars> */}
      </Auxiliary>
    )
  }
};

export default withRouter(AppNotification);

