import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import { CatalogueEvents } from '../utils/event-names';
import Web3Utils from 'web3-utils';
import BlockInfo from '../utils/block-info';

/**
 * CatalogueManager is an implementation of decentralized catalogue storage, using this class user can able to save catalogue into ParamNetwork. 
 */
class CatalogueManager {

    /**
     * Default constructor for initialising or establishing the catalogues.
     * @param {ParamNetwork} paramNetwork Object
     * 
     */
    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const catManager = require('./catalogue-manager.json')
        this.catalogueManagerContract = Web3_1_0.getContract(this.connection, catManager.abi, contractAddress ? contractAddress : catManager.address);
        this.to = contractAddress;
    }

    /**
     * initEvents is a event listener used for listening to the real time changes whenever a catalogue is added or updated.
     * @param {JSON} options - {"address":"0x"}
     * @example 
     * Usage:
     *  catalogueManager
     *   .initEvents({address:'0x'})
     *   .then((result)=>{
     *       //TODO 
     *   }) 
     */

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }
        let receiver, buyer, seller;
        if (options) {
            let address = Web3Utils.toChecksumAddress(options.address)
            options = { owner: address };
            buyer = { buyerId: address };
            seller = { sellerId: address };
        }

        this.watchAddCatalogueEvents(options);
        this.watchUpdateCatalogueEvent(options);
        this.watchCatalogueStatusUpdatedEventForBuyer(buyer);
        this.watchCatalogueStatusUpdatedEventForSeller(seller);
        this.watchCatalogueUpdatedEventForSubscriber(options);
        this.watchCatalogueSubscriberAddedEventForSubscriber(options);
        this.watchCatalogueSubscriberAddedEventForOwner(options);
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        }).catch(err => {
            console.error("error in emitting event", err)
        })
    }

    watchAddCatalogueEvents(options) {

        if (typeof this.catalogueAddEvent === 'undefined' || !this.catalogueAddEvent) {
            let filter = {
                filter: options
            };
            this.catalogueAddEvent = Web3_1_0.getEvent(this, this.catalogueManagerContract, "onCatalogueAdd", filter);
        }
        let that = this;
        this.catalogueAddEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onCatalogueAdd", event);
            }
            // that.emitEvent("onCatalogueAdd", event);
        })
            .on('error', function (error) {
                that.events.emit("onCatalogueAdd", error);
                that.catalogueAddEvent.unsubscribe();
                that.catalogueAddEvent = undefined;
                that.watchAddCatalogueEvents(options);
            });
    }

    watchUpdateCatalogueEvent(options) {
        if (typeof this.catalogueUpdateEvent === 'undefined' || !this.catalogueUpdateEvent) {
            let filter = {
                filter: options
            };
            // this.catalogueUpdateEvent = this.catalogueManagerContract.events.onCatalogueUpdateV1(filter);
            this.catalogueUpdateEvent = Web3_1_0.getEvent(this, this.catalogueManagerContract, "onCatalogueUpdateV1", filter);
        }
        let that = this;
        this.catalogueUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onCatalogueUpdateV1", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onCatalogueUpdateV1", error);
                that.catalogueUpdateEvent.unsubscribe();
                that.catalogueUpdateEvent = undefined;
                that.watchUpdateCatalogueEvent(options)
            });

    }

    watchCatalogueUpdatedEventForSubscriber(options) {
        if (typeof this.catalogueUpdateForSubscriberEvent === 'undefined' || !this.catalogueUpdateForSubscriberEvent) {
            this.catalogueUpdateForSubscriberEvent = Web3_1_0.getEvent(this, this.catalogueManagerContract, "onCatalogueUpdateForSubscriber");
        }
        let that = this;
        this.catalogueUpdateForSubscriberEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            for (let index = 0; index < transInfo.subscribers.length; index++) {
                const element = transInfo.subscribers[index];
                if (options.owner.toLowerCase() === element.toLowerCase()) {
                    that.emitEvent("onCatalogueUpdateForSubscriber", event);
                }
            }
        })
            .on('error', function (error) {
                that.events.emit("onCatalogueUpdateForSubscriber", error);
                that.catalogueUpdateForSubscriberEvent.unsubscribe();
                that.catalogueUpdateForSubscriberEvent = undefined;
                that.watchCatalogueStatusUpdatedEventForSeller(options)
            });
    }

    watchCatalogueStatusUpdatedEventForBuyer(options) {
        if (typeof this.catalogueLinkUpdateEvent === 'undefined' || !this.catalogueLinkUpdateEvent) {
            let filter = {
                filter: options
            };
            this.catalogueLinkUpdateEvent = Web3_1_0.getEvent(this, this.catalogueManagerContract, "onCatalogueStatusUpdated", filter);
        }
        let that = this;
        this.catalogueLinkUpdateEvent.on('data', function (event) {
            that.emitEvent("onCatalogueStatusUpdated", event);
        })
            .on('error', function (error) {
                that.events.emit("onCatalogueStatusUpdated", error);
                that.catalogueLinkUpdateEvent.unsubscribe();
                that.catalogueLinkUpdateEvent = undefined;
                that.watchCatalogueStatusUpdatedEventForBuyer(options)
            });
    }

    watchCatalogueStatusUpdatedEventForSeller(options) {
        if (typeof this.catalogueLinkUpdateEvent === 'undefined' || !this.catalogueLinkUpdateEvent) {
            let filter = {
                filter: options
            };
            this.catalogueLinkUpdateEvent = Web3_1_0.getEvent(this, this.catalogueManagerContract, "onCatalogueStatusUpdated", filter);
        }
        let that = this;
        this.catalogueLinkUpdateEvent.on('data', function (event) {
            that.emitEvent("onCatalogueStatusUpdated", event);
        })
            .on('error', function (error) {
                that.events.emit("onCatalogueStatusUpdated", error);
                that.catalogueLinkUpdateEvent.unsubscribe();
                that.catalogueLinkUpdateEvent = undefined;
                that.watchCatalogueStatusUpdatedEventForSeller(options)
            });
    }

    watchCatalogueSubscriberAddedEventForSubscriber(options) {
        if (typeof this.catalogueSubscriberAddedEvent === 'undefined' || !this.catalogueSubscriberAddedEvent) {
            let filter = {
                filter: options
            };
            this.catalogueSubscriberAddedEvent = Web3_1_0.getEvent(this, this.catalogueManagerContract, "onCatalogueSubscriberAdded", filter);
        }
        let that = this;
        this.catalogueSubscriberAddedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.subscriber.toLowerCase()) {
                that.emitEvent("onCatalogueSubscriberAdded", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onCatalogueSubscriberAdded", error);
                that.catalogueSubscriberAddedEvent.unsubscribe();
                that.catalogueSubscriberAddedEvent = undefined;
                that.watchCatalogueSubscriberAddedEventForSubscriber(options)
            });
    }

    watchCatalogueSubscriberAddedEventForOwner(options) {
        if (typeof this.catalogueSubscriberAddedEvent === 'undefined' || !this.catalogueSubscriberAddedEvent) {
            let filter = {
                filter: options
            };
            this.catalogueSubscriberAddedEvent = Web3_1_0.getEvent(this, this.catalogueManagerContract, "onCatalogueSubscriberAdded", filter);
        }
        let that = this;
        this.catalogueSubscriberAddedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onCatalogueSubscriberAdded", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onCatalogueSubscriberAdded", error);
                that.catalogueSubscriberAddedEvent.unsubscribe();
                that.catalogueSubscriberAddedEvent = undefined;
                that.watchCatalogueSubscriberAddedEventForOwner(options)
            });
    }

    addCatalogue(catalogueInfo, catalogueType = "1", catalogueTxnMode, catalogueKey, inventoryContractAddress, metaData, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "addCatalogue", options, catalogueInfo, catalogueType, catalogueTxnMode, catalogueKey, inventoryContractAddress, metaData);
    }

    addCatalogSync(catalogueInfo, catalogueType = "1", catalogueTxnMode, catalogueKey, inventoryContractAddress, metaData, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "addCatalogue", options, catalogueInfo, catalogueType, catalogueTxnMode, catalogueKey, inventoryContractAddress, metaData).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then(logs => {
            if (logs.length == 0) {
                return Promise.reject();
            }
            return logs[0].topics[1];
        });
    }

    updateCatalogue(id, catalogueInfo, catalogueType = "1", metaData, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "updateCatalogue", options, id, catalogueInfo, catalogueType, metaData);
    }

    updateCatalogueSync(id, catalogueInfo, catalogueType = "1", metaData, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "updateCatalogue", options, id, catalogueInfo, catalogueType, metaData).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then(logs => {
            return id;
            // if(logs.length == 0){
            //     return Promise.reject();
            // }
            // return logs[0].topics[0];
        });;
    }

    shareKey(cataloguesBytesId, subscriber, shareKey, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "shareKey", options, cataloguesBytesId, subscriber, shareKey);

    }

    updateInventory(id, inventoryData, updateType, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "updateInventory", options, id, inventoryData, updateType);
    }

    acceptItems(buyerId, sellerId, buyerItemsIds, sellerItemsIds, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "acceptItems", options, buyerId, sellerId, buyerItemsIds, sellerItemsIds);
    }

    rejectItems(buyerId, sellerId, buyerItemsIds, sellerItemsIds, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "rejectItems", options, buyerId, sellerId, buyerItemsIds, sellerItemsIds);
    }

    addSubscriberForCatalogue(cataloguesBytesId, subscriber, shareKey, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "addSubscriberForCatalogue", options, cataloguesBytesId, subscriber, shareKey);
    }

    addSubscriberForCatalogueSync(cataloguesBytesId, subscriber, shareKey, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "addSubscriberForCatalogue", options, cataloguesBytesId, subscriber, shareKey).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then(logs => {
            if (logs.length == 0) {
                return Promise.reject();
            }
            return logs[0].topics[1];
        });
    }
    setContractInfoId(contractId, options) {
        return Web3_1_0.send(this, this.catalogueManagerContract, "setContractInfoId", options, contractId);
    }

    getAllUpdatedItemsForBuyer(owner) {
        let events = ["onCatalogueStatusUpdated"];
        let promiseArray = [];
        let filter = {
            filter: {
                "buyerId": owner
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.catalogueManagerContract, events[index], filter);
            promiseArray.push(promise);
        }

        const promise = new Promise((resolve, reject) => {
            Web3_1_0.upgradeEventsData(promiseArray, this.connection).then(data => {
                let finalRes = [];
                data.forEach(element => {
                    let res = {};
                    res.buyerId = element.args.buyerId;
                    res.sellerId = element.args.sellerId;
                    res.status = element.args.status;
                    if (element.args.sellerItemsIds.length == element.args.buyerItemsIds.length) {
                        for (let index = 0; index < element.args.sellerItemsIds.length; index++) {
                            res.buyerItemId = element.args.buyerItemsIds[index];
                            res.sellerItemId = element.args.sellerItemsIds[index];
                            finalRes.push(res);
                        }
                    }
                });
                resolve(finalRes);
            });
        });
        return promise;
    }

    getAllUpdatedItemsForSeller(owner) {
        let events = ["onCatalogueStatusUpdated"];
        let promiseArray = [];
        let filter = {
            filter: {
                "sellerId": owner
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.catalogueManagerContract, events[index], filter);
            promiseArray.push(promise);
        }

        const promise = new Promise((resolve, reject) => {
            Web3_1_0.upgradeEventsData(promiseArray, this.connection).then(data => {
                let finalRes = [];
                data.forEach(element => {
                    let res = {};
                    res.buyerId = element.args.buyerId;
                    res.sellerId = element.args.sellerId;
                    res.status = element.args.status;
                    if (element.args.sellerItemsIds.length == element.args.buyerItemsIds.length) {
                        for (let index = 0; index < element.args.sellerItemsIds.length; index++) {
                            res.buyerItemId = element.args.buyerItemsIds[index];
                            res.sellerItemId = element.args.sellerItemsIds[index];
                            finalRes.push(res);
                        }
                    }
                });
                resolve(finalRes);
            });
        });
        return promise;
    }

    getCatalogue(index) {
        index = ParamUtils.getId(index);
        return Web3_1_0.call(this, this.catalogueManagerContract, "getCatalogue", null, index);
    }

    getInventory(index) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getInventory", null, index);
    }

    getCatalogueForSubscriber(cataloguesBytesId, paramId) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getCatalogueForSubscriber", null, cataloguesBytesId, paramId);
    }

    getInventoryForSubscriber(cataloguesBytesId, paramId) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getInventoryForSubscriber", null, cataloguesBytesId, paramId);
    }

    getSubscriber(sId) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getSubscriber", null, sId);
    }

    getAllSubscribers(cataloguesBytesId) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getAllSubscribers", null, cataloguesBytesId);
    }

    getAllCatalogues(owner) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getAllCatalogues", null, owner);
    }

    getTotalCatalogues(owner) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getTotalCatalogues", null, owner);
    }

    getAllSubscribedCatalogues(ownerId, subscriberId) {
        return Web3_1_0.call(this, this.catalogueManagerContract, "getAllSubscribedCatalogues", null, ownerId, subscriberId);
    }

    getAllEventsByCatalogueId(catalogueId) {
        let events = Object.keys(CatalogueEvents);
        let promiseArray = [];
        let filter = {
            filter: {
                "catalogueId": catalogueId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.catalogueManagerContract, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection);
    }

    registerOnCatalogueAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onCatalogueAdd"]) {
            this.events.removeAllListeners("onCatalogueAdd");
        }
        this.events.addListener("onCatalogueAdd", callback);
    }

    unRegisterOnCatalogueAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onCatalogueAdd", callback);
    }

    registerOnCatalogueUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onCatalogueUpdateV1"]) {
            this.events.removeAllListeners("onCatalogueUpdateV1");
        }
        this.events.addListener("onCatalogueUpdateV1", callback);
    }

    unRegisterOnCatalogueUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onCatalogueUpdateV1", callback);
    }

    registerOnCatalogueUpdateForSubscriber(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onCatalogueUpdateForSubscriber"]) {
            this.events.removeAllListeners("onCatalogueUpdateForSubscriber");
        }
        this.events.addListener("onCatalogueUpdateForSubscriber", callback);
    }

    unRegisterOnCatalogueUpdateForSubscriber(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onCatalogueUpdateForSubscriber", callback);
    }

    registerOnCatalogueSubscriberAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onCatalogueSubscriberAdded"]) {
            this.events.removeAllListeners("onCatalogueSubscriberAdded");
        }
        this.events.addListener("onCatalogueSubscriberAdded", callback);
    }

    unRegisterOnCatalogueSubscriberAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onCatalogueSubscriberAdded", callback);
    }

    registerOnCatalogueStatusUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onCatalogueStatusUpdated"]) {
            this.events.removeAllListeners("onCatalogueStatusUpdated");
        }
        this.events.addListener("onCatalogueStatusUpdated", callback);
    }

    unRegisterOnCatalogueStatusUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onCatalogueStatusUpdated", callback);
    }

    parseNetworkLog(event, data) {
        let abi = this.catalogueManagerContract._jsonInterface;
        let abiInputs;
        for (let index in abi) {
            let abiObj = abi[index];
            if (abiObj.type === 'event' && abiObj.name === event) {
                abiInputs = abiObj;
                break;
            }
        }
        if (!abiInputs) {
            return null;
        }
        var Web3EthAbi = require('web3-eth-abi');

        let eventId = Web3EthAbi.encodeEventSignature(abiInputs);
        for (let eventIndex in data.logs) {
            if (data.logs[eventIndex].topics && eventId === data.logs[eventIndex].topics[0]) {
                // return this.connection.eth.abi.decodeLog(abiInputs.inputs, data.logs[eventIndex].data, data.logs[eventIndex].topics.splice(1));
                return Web3EthAbi.decodeLog(abiInputs.inputs, data.logs[eventIndex].data, data.logs[eventIndex].topics.splice(1));
            }
        }
        return null;
    }
}
export default CatalogueManager;