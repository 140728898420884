import Database from '../../../database/index';
import ParamConnector from '../../../param-connector'
import * as NetworkUtils from '../../../util/utils';
import ECIES from '../../../util/ecies';

import FormatData from '../../../database/nosql/Utils/formatData';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import { TemplateRepoEvents } from '../../../param-network/utils/event-names';
import TemplateRepo from './template-repo';
import RestoreReceipts from './receipts';
import TemplateConsensusEvents from '../subscriptions/template-cons';
import GraphQL from '..';
import Console from '../../../logger';

class RestoreTemplateConsensus {

    static restoreAllTemplateConsensus(contractAddress, owner) {
        let templateConManager = GraphQL.getInstance().templateConsensus;
        return templateConManager.getAllSubsribeTemplateConsensus(owner).then(templates => {
            let promiseArray = [];
            for (let index in templates) {
                let transactionData = NetworkUtils.getTransactionData(templates[index])
                let templateConsId = transactionData.id
                contractAddress = transactionData.address
                let templateConsPromise = RestoreTemplateConsensus.restoreTemplateConsensus(templateConsId, contractAddress);
                templateConsPromise.then(result => {

                })
                promiseArray.push(templateConsPromise);
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreAllTemplateConsensus`);
        });
    }

    static restoreSubscribers(templateConsId, stepConsId, subscribersIds, contractAddress) {
        // let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let templateConManager = GraphQL.getInstance().templateConsensus;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;

        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let promise;
        let promiseArray = [];
        for (let index in subscribersIds) {
            let subsTxId = NetworkUtils.getTransactionId(subscribersIds[index], contractAddress)
            promise = templateConManager.getSubscriber(subsTxId).then(data => {
                return templateConsensusDB.addStepConsensusSubscriber(dbTemplateConsId, dbStepConsId, data.subscriber, data.stepKey).then(() => {
                    return RestoreTemplateConsensus.addSubsriberForTemplate(templateConsId, data.subscriber, contractAddress, stepConsId);
                })
            }).catch(error => {
                Console.log(error);
            });
            promiseArray.push(promise);
        }
        return Promise.all(promiseArray).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreSubscribers`);
        });
    }

    static addStepConsensus(templateConsId, stepConsId, contractAddress) {
        // let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let templateConManager = GraphQL.getInstance().templateConsensus;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;

        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        return templateConManager.getStepConsensus(dbStepConsId).then(dataJSON => {
            let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
            let owner = dataJSON.owner;
            let jsonLd = dataJSON.jsonLd;
            let metaData = dataJSON.metaData;
            let primaryOwner = dataJSON.primaryOwner;
            let secondaryOwner = dataJSON.secondaryOwner;
            let transactionType = dataJSON.transactionType;
            let subscriberDetailPromiseArray = [];

            let privateKey = NetworkUtils.getPrivateKey();

            let subscribers = dataJSON.subscribers;
            for (let subsId in subscribers) {
                let subsTxId = NetworkUtils.getTransactionId(subscribers[subsId], contractAddress)
                let subscriberDetailPromise = templateConManager.getSubscriber(subsTxId);
                subscriberDetailPromiseArray.push(subscriberDetailPromise);
            }
            return Promise.all(subscriberDetailPromiseArray).then(subscriberDetailArray => {
                let subscribersPromises = RestoreTemplateConsensus.restoreSubscribers(templateConsId, stepConsId, dataJSON.subscribers, contractAddress);
                if (transactionType === '2') {
                    let selfParamId = NetworkUtils.getParamId();
                    let isSubscriber = false;
                    let stepKey;
                    for (let index in subscriberDetailArray) {
                        if (subscriberDetailArray[index].subscriber === selfParamId) {
                            stepKey = ECIES.decrypt(privateKey, subscriberDetailArray[index].stepKey);
                            try {
                                jsonLd = ECIES.decrypt(stepKey, jsonLd);
                            } catch (e) {
                                Console.error("Error in syncing step consensus", dbStepConsId, owner, jsonLd, metaData, primaryOwner, dbTemplateConsId, secondaryOwner, transactionType, subscribers)
                            }
                            isSubscriber = true;
                            break;
                        }
                    }
                    if (!isSubscriber) {
                        jsonLd = { restrictAccess: true };
                        jsonLd = JSON.stringify(jsonLd);
                    }
                }
                return templateConsensusDB.addStepConsensus(dbStepConsId, owner, jsonLd, metaData, primaryOwner, dbTemplateConsId, secondaryOwner, transactionType, subscriberDetailArray).then(result => {
                    return subscribersPromises;
                });
            })

        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addStepConsensus`);
        });
    }

    static hardRestoreStepConsensus(templateConsId, stepConsId, contractAddress, isExists) {
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        // let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let templateConManager = GraphQL.getInstance().templateConsensus;
        if (!isExists) {
            return RestoreTemplateConsensus.addStepConsensus(templateConsId, stepConsId, contractAddress).then(data => {
                return templateConManager.isStepCompleted(dbStepConsId);
            }).then(status => {
                let templateConsDBId = NetworkUtils.getTransactionId(templateConsId, contractAddress)
                return templateConsensusDB.updateStepConsensusStatus(templateConsDBId, dbStepConsId, status);
            });
        }
        return RestoreTemplateConsensus.updateStepConsensusStatus(templateConsId, stepConsId, contractAddress, true);
    }

    static hardRestoreTemplateConsensus(templateConsId, contractAddress) {
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let templateConsDetails, sendTemplateConsDetails;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        // let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let templateConManager = GraphQL.getInstance().templateConsensus;

        return templateConManager.getTemplateConsensusV2(dbTemplateConsId).then(res => {
            sendTemplateConsDetails = JSON.stringify(res);
            templateConsDetails = res;
            let dbTemplateId = NetworkUtils.getTransactionId(templateConsDetails.templateId, templateConsDetails.templateContractAddress);
            let stepSigners = [];
            for (let index in templateConsDetails.stepSigners) {
                stepSigners.push(NetworkUtils.getTransactionId(templateConsDetails.stepSigners[index], contractAddress));
            }
            let docs = (templateConsDetails.docIds && templateConsDetails.docIds.length > 0) ? templateConsDetails.docIds : templateConsDetails.docId
            let docIds = NetworkUtils.getArrayOfData(docs);
            docIds = docIds.map((docId) => {
                return NetworkUtils.getTransactionId(docId, templateConsDetails.docContractAddress);
            })
            // let docId = NetworkUtils.getTransactionId(templateConsDetails[2], templateConsDetails[1]);
            let docContractAddress = templateConsDetails.docContractAddress;
            let owner = templateConsDetails.owner;
            let currentStep = templateConsDetails.currentStep;
            let isCompleted = templateConsDetails.isCompleted;
            let transactionType = templateConsDetails.transactionType;
            let docStep = templateConsDetails.docStep;
            let mergeId;
            if (templateConsDetails.mergeId !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                mergeId = templateConsDetails.mergeId;
            }
            return templateConsensusDB.addTemplateConsensus(dbTemplateConsId, owner, docIds, docContractAddress, dbTemplateId, currentStep, stepSigners, isCompleted, transactionType, mergeId, docStep);
        }).then(() => {
            return JSON.parse(sendTemplateConsDetails);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consensus [Reason] ${e} [Module] : Sync/template-cons/hardRestoreTemplateConsensus`);
            return JSON.parse(sendTemplateConsDetails);
        });
    }

    static restoreTemplateConsensus(templateConsId, contractAddress, assignEvent) {
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let isExists;
        let promise;
        return templateConsensusDB.doesTemplateConsExists(dbTemplateConsId).then(res => {
            isExists = res;
            if (!isExists) {
                promise = RestoreTemplateConsensus.hardRestoreTemplateConsensus(templateConsId, contractAddress);
            }
            else {
                // let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
                let templateConManager = GraphQL.getInstance().templateConsensus
                promise = templateConManager.getTemplateConsensusV2(dbTemplateConsId);
            }
            return promise.then(templateCons => {
                let dbtemplateId = NetworkUtils.getTransactionId(templateCons.templateId, templateCons.templateContractAddress);
                let restoreTemplateConsPromises = [];

                //Restore template if not exists.
                restoreTemplateConsPromises.push(TemplateRepo.restoreNotExists(dbtemplateId));

                //Restore Document
                let docIds = NetworkUtils.getArrayOfData(templateCons.docIds);
                if (assignEvent === "onAssign") {
                    for (let index in docIds) {
                        const docId = NetworkUtils.getTransactionId(docIds[index], templateCons.docContractAddress);
                        const restoreReceiptObj = [{
                            receiptId: docId,
                            contractAddress: templateCons.docContractAddress,
                            event: "onAssign"
                        }]
                        restoreTemplateConsPromises.push(RestoreReceipts.restoreReceipt(restoreReceiptObj));
                    }
                }
                if (!isExists) {
                    let stepSigners = NetworkUtils.getArrayOfData(templateCons.stepSigners);

                    for (let index in stepSigners) {
                        restoreTemplateConsPromises.push(RestoreTemplateConsensus.hardRestoreStepConsensus(templateConsId, stepSigners[index], contractAddress, false));
                    }
                }
                return Promise.all(restoreTemplateConsPromises);
            })
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreTemplateConsensus`);
        });
    }

    static addSubsriberForStepCons(templateConsId, subscribers, contractAddress, stepConsId, secondaryOwner) {
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);

        let templateRepoDB = Database.getInstance().getDB().templateRepoDB;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateIdByConsId(dbTemplateConsId).then(templateId => {
            return templateConsensusDB.addStepConsensusSubscriberOnAssign(dbTemplateConsId, dbStepConsId, subscribers, secondaryOwner).then(() => {
                return templateRepoDB.addSubscribers(templateId, subscribers);
            })
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addSubsriberForStepCons`);
        });
    }

    static addSubsriberForTemplate(templateConsId, subscribers, contractAddress, stepConsId, assignEvent) {
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let templateRepoDB = Database.getInstance().getDB().templateRepoDB;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateIdByConsId(dbTemplateConsId).then(templateId => {
            let promiseArray = [];
            promiseArray.push(templateRepoDB.addSubscribers(templateId, subscribers));
            if (assignEvent) {
                promiseArray.push(templateConsensusDB.addStepConsensusSubscriber(dbTemplateConsId, dbStepConsId, subscribers, ""));
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addSubsriberForTemplate`);
        });
    }

    static addSubsriber(templateConsId, contractAddress, subscribers, stepConsId, secondaryOwner, assignEvent) {
        return RestoreTemplateConsensus.restoreTemplateConsensus(templateConsId, contractAddress, assignEvent).then(() => {
            if (assignEvent) {
                return RestoreTemplateConsensus.addSubsriberForStepCons(templateConsId, subscribers, contractAddress, stepConsId, secondaryOwner);
            }
            return RestoreTemplateConsensus.addSubsriberForTemplate(templateConsId, subscribers, contractAddress, stepConsId);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addSubsriber`);
        });
    }

    static restoreAllTransactions(templateConsId, contractAddress) {
        // ?????? getAllEventsByConsId, TemplateConsensusEvents
        // let templateConsManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        templateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress)
        let templateConManager = GraphQL.getInstance().templateConsensus;
        return templateConManager.getAllEventsByConsId(templateConsId).then(transactions => {
            let promiseArray = []
            for (let index in transactions) {
                let txn = transactions[index];
                let templateConsensusEvents = new TemplateConsensusEvents()
                if (!templateConsensusEvents[`write${txn.type}`]) {
                    Console.error("Unable to write transaction, No method found.")
                    continue;
                }
                let eventMetaData = NetworkUtils.extractEventData(txn)
                let dataJSON = NetworkUtils.getSubscriptionDataFromEventData(txn)
                dataJSON = { ...dataJSON, ...eventMetaData }
                promiseArray.push(templateConsensusEvents[`write${txn.type}`](dataJSON));
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreAllTransactions`);
        });
    }

    static updateTemplateCompleted(templateConsId, contractAddress, isCompleted) {
        let txId = NetworkUtils.getTransactionId(templateConsId, contractAddress);;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.updateTemplateConsensusStatus(txId, isCompleted).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/updateTemplateCompleted`);
        });
    }

    static restoreStepConsensus(templateConsId, stepConsId, contractAddress, isCompleted) {
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);

        return templateConsensusDB.doesStepConsExists(dbTemplateConsId, dbStepConsId).then(isExists => {
            if (isExists) {
                return RestoreTemplateConsensus.updateStepConsensusStatus(templateConsId, stepConsId, contractAddress, true);
            }
            return RestoreTemplateConsensus.hardRestoreTemplateConsensus(templateConsId, contractAddress);
        })
    }

    static updateStepConsensusStatus(templateConsId, stepId, contractAddress, isRestoreStep) {
        let templateConManager = GraphQL.getInstance().templateConsensus;
        let txId = NetworkUtils.getTransactionId(stepId, contractAddress);
        templateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);

        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let promise;
        if (isRestoreStep) {
            promise = templateConManager.getStepConsensus(txId).then(dataJSON => {
                let stepConsensusJSONLd = dataJSON.jsonLd;
                let metaData = dataJSON.metaData;
                let secondaryOwner = dataJSON.secondaryOwner;
                let transactionType = dataJSON.transactionType.toString();
                let owner = dataJSON.owner;
                let subscribers = dataJSON.subscribers;
                let subscriberDetailPromiseArray = [];
                let privateKey = NetworkUtils.getPrivateKey();

                for (let subsId in subscribers) {
                    let subsTxId = NetworkUtils.getTransactionId(subscribers[subsId].toString(), contractAddress)
                    let subscriberDetailPromise = templateConManager.getSubscriber(subsTxId);
                    subscriberDetailPromiseArray.push(subscriberDetailPromise);
                }
                return Promise.all(subscriberDetailPromiseArray).then(subscriberDetailArray => {

                    if (transactionType === '2') {
                        let selfParamId = NetworkUtils.getParamId();
                        let isSubscriber = false;
                        let stepKey;
                        for (let index in subscriberDetailArray) {
                            if (subscriberDetailArray[index].subscriber === selfParamId) {
                                stepKey = ECIES.decrypt(privateKey, subscriberDetailArray[index].stepKey);
                                stepConsensusJSONLd = ECIES.decrypt(stepKey, stepConsensusJSONLd);
                                isSubscriber = true;
                                break;
                            }
                        }
                        if (!isSubscriber) {
                            stepConsensusJSONLd = { restrictAccess: true };
                            stepConsensusJSONLd = JSON.stringify(stepConsensusJSONLd);
                        }
                    }
                    return templateConsensusDB.updateStepConsensus(txId, stepConsensusJSONLd, metaData, secondaryOwner, subscriberDetailArray, transactionType, owner, templateConsId);
                })
            }).then(data => {
                return templateConManager.isStepCompleted(txId);
            }).then(status => {
                return templateConsensusDB.updateStepConsensusStatus(templateConsId, txId, status);
            });
        } else {
            promise = RestoreTemplateConsensus.restoreStepConsensus(templateConsId, txId, contractAddress);
        }
        return promise;
    }

    static restoreAllTemplateConsensusEvents(contractAddress, owner, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("Template consensus", `Trying to get all the events from ${contractAddress}`);
        }
        let templateConsManager = GraphQL.getInstance().templateConsensus;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let templateConsIds;
        return templateConsManager.getAllSubsribeTemplateConsensus(owner).then(templates => {
            templateConsIds = templates;
            let promiseArray = [];
            for (let index in templates) {
                let transactionData = NetworkUtils.getTransactionData(templates[index])
                contractAddress = transactionData.address;
                let templateConsId = transactionData.id
                promiseArray.push(RestoreTemplateConsensus.restoreAllTransactions(templateConsId, contractAddress));
            }
            return Promise.all(promiseArray)
            // }).then(() => {
            //     let promiseArray = [];
            //     for (let mergeConsIndex in templateConsIds) {
            //         promiseArray.push(templateConsensusDB.getMergeIdByTemplateConsId(NetworkUtils.getTransactionId(templateConsIds[mergeConsIndex], contractAddress)));
            //     }
            //     return Promise.all(promiseArray)
            // }).then((res) => {
            //     let uniqueMergeIds = [], checkExisting = {};
            //     for (let index in res) {
            //         if (!checkExisting[res[index]]) {
            //             checkExisting[res[index]] = true;
            //             uniqueMergeIds.push(res[index]);
            //         }
            //     }
            //     return TemplateConsensusRestore.restoreAllMergeTransactions(uniqueMergeIds, contractAddress);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreAllTemplateConsensusEvents`);
        });
    }
}
export default RestoreTemplateConsensus;