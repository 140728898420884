import * as Utils from '../../../util/utils';
const ipfsAPI = require('ipfs-api');
export function getCurrentDateTime() {
    return Date.now();
}
export function getDateAndTime(timeNano) {
    let date = new Date(Number(timeNano));
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function getTimeStamp(dateTime) {
    dateTime = dateTime.split('/');
    return new Date(`${dateTime[1]}/${dateTime[0]}/${dateTime[2]}`).getTime();
}

export function getDaysFromMilliseconds(milliseconds) {
    return (milliseconds / (60 * 60 * 24 * 1000));
}

export function getMonthFromMilliseconds(milliseconds) {
    let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let d1 = new Date(milliseconds),
        // d = d1.getDate(),
        m = d1.getMonth()
    // y = d1.getFullYear();

    return monthName[m];
}

export function getDaysDuration(interval, limit, days) {
    let ceil = Math.ceil(days / interval);
    let floor = Math.floor(days / interval);
    let range;
    if (ceil > limit) {
        range = `>${limit}`
    }
    else {
        range = `${floor * interval}-${ceil * interval}`;
    }
    return range;
}

export function getFromIpfsHash(ipfsHash) {
    const ipfsURI = 'ipfs://'
    if (!ipfsHash || !ipfsHash.startsWith(ipfsURI)) {
        return Promise.resolve(ipfsHash)
    }
    const ipfsURL = Utils.getIpfsURL();
    const ipfs = ipfsAPI(ipfsURL);

    ipfsHash = ipfsHash.substring(ipfsURI.length);
    return new Promise((resolve, reject) => {
        ipfs.files.get(ipfsHash, function (err, files) {
            if (err) {
                return reject(err);
            }
            files.forEach((file) => {
                return resolve(file.content.toString('utf8'));
            })
        })
    })
}

export function parseBlockKeys(blockKeys) {
    blockKeys = Utils.getArrayOfData(blockKeys);
    return blockKeys.reduce((res, val) => {
        res[val] = 0;
        return res;
    }, {})
}