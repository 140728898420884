
import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import Web3Utils from 'web3-utils';
import { TemplateConsEvents, TemplateConsMerge } from '../utils/event-names';
import BlockInfo from '../utils/block-info';

class TemplateConsManager {
    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const templateConsManager = require('./template-consensus-manager.json');
        this.contactManagerABI = templateConsManager.abi;
        this.paramNetwork = _paramNetwork;
        this.contractAddress = contractAddress;
        this.templateConsensusManager = Web3_1_0.getContract(this.connection, templateConsManager.abi, contractAddress ? contractAddress : templateConsManager.address);
        this.to = contractAddress;
        // this.templateConsensusManager = new this.connection.eth.Contract(templateConsManager.abi, contractAddress);
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }

    initEvents(options) {

        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        if (options) {
            options = {
                owner: options.address
            };
        }

        this.watchAttachTemplateEvent(options);
        this.watchAssignStepEvent(options);
        this.watchSignStepEvent(options);
        this.watchCompleteTemplateEvent(options);
        this.watchAddSubscriberEvent(options);
        this.watchTemplateConsensusMergedEvent(options);
        this.watchTemplateConsensusResetEvent(options);

        this.watchAttachTemplateForDocIdsEvent(options);
        this.watchAddSubscriberForDocIdsEvent(options);
    }

    watchAttachTemplateEvent(options) {
        if (typeof this.templateAttachedEvent === 'undefined' || !this.templateAttachedEvent) {
            let filter = {
                filter: {
                    creator: options.owner
                }
            };
            this.templateAttachedEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onTemplateAttached", filter);
        }
        let that = this;
        this.templateAttachedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);

            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            if (!event.args || !event.args.creator) {
                return;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.creator.toLowerCase()) {
                that.emitEvent("onTemplateAttached", event);
            }
        }).on('error', function (error) {
            that.events.emit("onTemplateAttached", error);
            that.templateAttachedEvent.unsubscribe();
            that.templateAttachedEvent = undefined;
            that.watchAttachTemplateEvent(options);
        });
    }

    watchAttachTemplateForDocIdsEvent(options) {
        if (typeof this.templateAttachedForDocIdsEvent === 'undefined' || !this.templateAttachedForDocIdsEvent) {
            let filter = {
                filter: {
                    creator: options.owner
                }
            };
            this.templateAttachedForDocIdsEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onTemplateAttachedForDocIds", filter);
        }
        let that = this;
        this.templateAttachedForDocIdsEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);

            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            if (!event.args || !event.args.creator) {
                return;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.creator.toLowerCase()) {
                that.emitEvent("onTemplateAttachedForDocIds", event);
            }
        }).on('error', function (error) {
            that.events.emit("onTemplateAttachedForDocIds", error);
            that.templateAttachedForDocIdsEvent.unsubscribe();
            that.templateAttachedForDocIdsEvent = undefined;
            that.watchAttachTemplateForDocIdsEvent(options);
        });
    }

    watchAssignStepEvent(options) {
        if (typeof this.stepAssignedEvent === 'undefined' || !this.stepAssignedEvent) {
            this.stepAssignedEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onStepAssinged");
        }
        let that = this;
        this.stepAssignedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);

            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }

            if (!event.args || !event.args.subscribers) {
                return;
            }

            let transInfo = event.args.subscribers;
            for (let index in transInfo) {
                if (options.owner.toLowerCase() === (transInfo[index]).toLowerCase()) {
                    that.emitEvent("onStepAssinged", event);
                    break;
                }
            }
        }).on('error', function (error) {
            that.events.emit("onStepAssinged", error);
            that.stepAssignedEvent.unsubscribe();
            that.stepAssignedEvent = undefined;
            that.watchAssignStepEvent(options);
        });
    }

    watchSignStepEvent(options) {
        if (typeof this.stepSignedEvent === 'undefined' || !this.stepSignedEvent) {
            this.stepSignedEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onStepSigned");
        }
        let that = this;
        this.stepSignedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);

            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }

            if (!event.args || !event.args.subscribers) {
                return;
            }

            if (options.owner.toLowerCase() === event.args.owner.toLowerCase()) {
                that.emitEvent("onStepSigned", event);
                return;
            }

            let transInfo = event.args.subscribers;
            for (let index in transInfo) {
                if (options.owner.toLowerCase() === (transInfo[index]).toLowerCase()) {
                    that.emitEvent("onStepSigned", event);
                    break;
                }
            }
        }).on('error', function (error) {
            that.events.emit("onStepSigned", error);
            that.stepSignedEvent.unsubscribe();
            that.stepSignedEvent = undefined;
            that.watchSignStepEvent(options);
        });
    }

    watchCompleteTemplateEvent(options) {
        if (typeof this.templateCompletedEvent === 'undefined' || !this.templateCompletedEvent) {
            this.templateCompletedEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onTemplateCompleted");
        }
        let that = this;
        this.templateCompletedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);

            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }

            if (!event.args || !event.args.subscribers) {
                return;
            }

            let transInfo = event.args.subscribers;
            for (let index in transInfo) {
                if (options.owner.toLowerCase() === (transInfo[index]).toLowerCase()) {
                    that.emitEvent("onTemplateCompleted", event);
                    break;
                }
            }
        }).on('error', function (error) {
            that.events.emit("onTemplateCompleted", error);
            that.templateCompletedEvent.unsubscribe();
            that.templateCompletedEvent = undefined;
            that.watchCompleteTemplateEvent(options);
        });
    }

    watchAddSubscriberEvent(options) {
        if (typeof this.subscriberAddedEvent === 'undefined' || !this.subscriberAddedEvent) {
            this.subscriberAddedEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onSubscriberAdded");
        }
        let that = this;
        this.subscriberAddedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }

            if (!event.args || !event.args.subscribers) {
                return;
            }

            let transInfo = event.args.subscribers;
            for (let index in transInfo) {
                if (options.owner.toLowerCase() === (transInfo[index]).toLowerCase()) {
                    that.emitEvent("onSubscriberAdded", event);
                    break;
                }
            }
        }).on('error', function (error) {
            that.events.emit("onSubscriberAdded", error);
            that.subscriberAddedEvent.unsubscribe();
            that.subscriberAddedEvent = undefined;
            that.watchAddSubscriberEvent(options);
        });
    }

    watchAddSubscriberForDocIdsEvent(options) {
        if (typeof this.subscriberAddedForDocIdsEvent === 'undefined' || !this.subscriberAddedForDocIdsEvent) {
            this.subscriberAddedForDocIdsEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onSubscriberAddedForDocIds");
        }
        let that = this;
        this.subscriberAddedForDocIdsEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }

            if (!event.args || !event.args.subscribers) {
                return;
            }

            let transInfo = event.args.subscribers;
            for (let index in transInfo) {
                if (options.owner.toLowerCase() === (transInfo[index]).toLowerCase()) {
                    that.emitEvent("onSubscriberAddedForDocIds", event);
                    break;
                }
            }
        }).on('error', function (error) {
            that.events.emit("onSubscriberAddedForDocIds", error);
            that.subscriberAddedForDocIdsEvent.unsubscribe();
            that.subscriberAddedForDocIdsEvent = undefined;
            that.watchAddSubscriberForDocIdsEvent(options);
        });
    }

    watchTemplateConsensusMergedEvent(options) {
        if (typeof this.templateConsensusMergeEvent === 'undefined' || !this.templateConsensusMergeEvent) {
            this.templateConsensusMergeEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onTemplateConsensusMerged");
        }
        let that = this;
        this.templateConsensusMergeEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);

            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }

            if (!event.args || !event.args.subscribers) {
                return;
            }

            let transInfo = event.args.subscribers;
            for (let index in transInfo) {
                if (options.owner.toLowerCase() === (transInfo[index]).toLowerCase()) {
                    that.emitEvent("onTemplateConsensusMerged", event);
                    break;
                }
            }
        }).on('error', function (error) {
            that.events.emit("onTemplateConsensusMerged", error);
            that.templateConsensusMergeEvent.unsubscribe();
            that.templateConsensusMergeEvent = undefined;
            that.watchTemplateConsensusMergedEvent(options);
        });
    }

    watchTemplateConsensusResetEvent(options) {
        if (typeof this.templateConsensusResetEvent === 'undefined' || !this.templateConsensusResetEvent) {
            let filter = {
                filter: {
                    creator: options.owner
                }
            };
            // this.templateConsensusResetEvent = this.templateConsensusManager.events.onTemplateConsensusReset();
            this.templateConsensusResetEvent = Web3_1_0.getEvent(this, this.templateConsensusManager, "onTemplateConsensusReset");
        }
        let that = this;
        this.templateConsensusResetEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);

            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }

            if (!event.args || !event.args.subscribers) {
                return;
            }

            let transInfo = event.args.subscribers;
            for (let index in transInfo) {
                if (options.owner.toLowerCase() === (transInfo[index]).toLowerCase()) {
                    that.emitEvent("onTemplateConsensusReset", event);
                    break;
                }
            }
        }).on('error', function (error) {
            that.events.emit("onTemplateConsensusReset", error);
            that.templateConsensusResetEvent.unsubscribe();
            that.templateConsensusResetEvent = undefined;
            that.watchTemplateConsensusResetEvent(options);
        });
    }

    mergeConsensus(templateConsIds, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "mergeConsensus", options, templateConsIds);
    }

    mergeConsensusSync(templateConsIds, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "mergeConsensus", options, templateConsIds).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    attachTemplate(docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "attachTemplate", options, docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType);
    }

    attachTemplateSync(docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "attachTemplate", options, docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[1];
        })
    }

    attachTemplateForAll(docInfoAddress, docId, docStep = 1, templateAddress, templateId, stepSigners, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "attachTemplateForAll", options, docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType)
    }

    attachTemplateForAllSync(docInfoAddress, docId, docStep = 1, templateAddress, templateId, stepSigners, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "attachTemplateForAll", options, docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[1];
        })
    }

    attachTemplateForAllV2(docInfoAddress, docId, docStep = 1, templateAddress, templateId, stepSigners, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "attachTemplateForAllV2", options, docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType)
    }

    attachTemplateForAllV2Sync(docInfoAddress, docId, docStep = 1, templateAddress, templateId, stepSigners, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "attachTemplateForAllV2", options, docInfoAddress, docId, docStep, templateAddress, templateId, stepSigners, txnType).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[1];
        })
    }

    signStep(stepConsId, jsonLd, metaData, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "signStep", options, stepConsId, jsonLd, metaData, txnType);
    }

    signStepSync(stepConsId, jsonLd, metaData, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "signStep", options, stepConsId, jsonLd, metaData, txnType).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })
    }

    shareStepKey(stepConsId, subscriber, stepKey, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "shareStepKey", options, stepConsId, subscriber, stepKey);
    }

    assignStep(stepConsId, stepCons, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.templateConsensusManager, "assignStep", options, stepConsId, stepCons);
    }

    assignStepSync(stepConsId, stepCons, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.templateConsensusManager, "assignStep", options, stepConsId, stepCons).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })
    }

    mergeSign(mergeConsId, jsonLd, metaData, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "mergeSign", options, mergeConsId, jsonLd, metaData, txnType);
    }

    mergeSignSync(mergeConsId, jsonLd, metaData, txnType, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "mergeSign", options, mergeConsId, jsonLd, metaData, txnType).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })
    }

    mergeAssign(mergeConsId, stepCons, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "mergeAssign", options, mergeConsId, stepCons);
    }

    mergeAssignSync(mergeConsId, stepCons, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "mergeAssign", options, mergeConsId, stepCons).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })
    }

    resetTemplateCons(templateConsId, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "resetTemplateCons", options, templateConsId);
    }

    resetTemplateConsSync(templateConsId, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "resetTemplateCons", options, templateConsId).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })
    }

    resetMergeTemplateCons(mergeConsId, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "resetMergeTemplateCons", options, mergeConsId);
    }

    resetMergeTemplateConsSync(mergeConsId, options) {
        return Web3_1_0.send(this, this.templateConsensusManager, "resetMergeTemplateCons", options, mergeConsId).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })

    }

    getAllTemplateConsByMergeId(mergeConsId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getAllTemplateConsByMergeId", null, mergeConsId);
    }

    getTemplateConsensus(templateConsId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getTemplateConsensus", null, templateConsId);
    }

    getTemplateConsensusV2(templateConsId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getTemplateConsensusV2", null, templateConsId);
    }

    getAllTemplateConsensus(address) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getAllTemplateConsensus", null, address);
    }

    getAllEventsByConsId(consId) {
        let events = Object.keys(TemplateConsEvents);
        let promiseArray = [];
        let filter = {
            filter: {
                "templateConsId": consId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.templateConsensusManager, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection);
    }

    getAllMergeEventsByMergeId(mergeId) {
        let filter = {
            filter: {
                "mergeId": mergeId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }
        let promise = Web3_1_0.getPastEvents(this, this.templateConsensusManager, "onTemplateConsensusMerged", filter);

        return promise.then(result => {
            if (!result[0])
                return Promise.resolve();
            return Web3_1_0.upgradeEventData(result[0], this.connection)
        })
    }

    getTemplateConsensusByDocId(docId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getTemplateConsensusByDocId", null, docId);
    }

    getStepSubscribers(stepConsId, _address) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getStepSubscribers", null, stepConsId, _address);
    }

    getSubscriber(sId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getSubscriber", null, sId);
    }

    getStepConsensus(stepConsId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getStepConsensus", null, stepConsId);
    }

    isTemplateCompleted(templateConsId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "isTemplateCompleted", null, templateConsId);

    }

    isStepCompleted(stepConsId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "isStepCompleted", null, stepConsId);

    }

    getAllSubsribeTemplateConsensus(paramid) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getAllSubsribeTemplateConsensus", null, paramid);
    }

    getTransactions(templateConsId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getTransactions", null, templateConsId);
    }

    getTransaction(transactionId) {
        return Web3_1_0.call(this, this.templateConsensusManager, "getTransaction", null, transactionId);
    }

    registerOnTemplateAttached(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateAttached", callback);
    }

    registerOnTemplateAttachedForDocIds(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateAttachedForDocIds", callback);
    }

    registerOnStepAssinged(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onStepAssinged", callback);
    }

    registerOnStepSigned(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onStepSigned", callback);
    }

    registerOnTemplateCompleted(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateCompleted", callback);
    }

    registerOnSubscriberAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onSubscriberAdded", callback);
    }

    registerOnSubscriberAddedForDocIds(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onSubscriberAddedForDocIds", callback);
    }

    registerOnTemplateConsensusMerged(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateConsensusMerged", callback);
    }

    registerOnTemplateConsensusReset(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateConsensusReset", callback);
    }


    unRegisterOnTemplateAttached(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onTemplateAttached", callback);
    }

    unRegisterOnTemplateAttachedForDocIds(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onTemplateAttachedForDocIds", callback);
    }

    unRegisterOnStepAssinged(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onStepAssinged", callback);
    }

    unRegisterOnStepSigned(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onStepSigned", callback);
    }

    unRegisterOnTemplateCompleted(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onTemplateCompleted", callback);
    }

    unRegisterOnSubscriberAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onSubscriberAdded", callback);
    }

    unRegisterOnSubscriberAddedForDocIds(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onSubscriberAddedForDocIds", callback);
    }

    unRegisterOnTemplateConsensusMerged(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onTemplateConsensusMerged", callback);
    }

    unRegisterOnTemplateConsensusReset(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onTemplateConsensusReset", callback);
    }
}
export default TemplateConsManager;