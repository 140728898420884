
// Donot chart(COntract & uncontracted)
export const chartConfig = [
    {
        '@type': 'contractedDonutChart',
        height: 250,
        width: 250,
        forceFit: true,
        radius: 0.8,
        padding: 'auto',

        angleField: 'value',
        colorField: 'type',
        legend: { position: 'bottom-center' },
        color: ['#5695FF', '#8EBAFF'],
        statistic: {
            totalLabel: 'Total'
        }
    },
    // Purchases(Column chart)
    {
        '@type': 'purchasesColumnChart',
        width: 250,
        height: 250,
        padding: 'auto',
        forceFit: true,

        xField: 'month',
        yField: 'value',
        meta: {
            month: {
                alias: '',
            },
            value: {
                alias: 'Volume in $',
            },
        },
        label: {
            visible: true,
            type: 'point',
        },
        point: {
            visible: true,
        }
    },
    // (Bar chart) Top purchases
    {
        '@type': 'topPurchasesBarChart',
        height: 250,
        width: 250,
        forceFit: true,
        xField: 'value',
        yField: 'type',
    },
    // contract Due, GRN Pending, Top Dispute qty(Column chart)
    {
        '@type': 'contractDueColumnChart',
        width: 250,
        height: 250,
        forceFit: true,

        padding: 'auto',
        yField: '',
        xField: '',
    },
    // Dipute(PIe chart)
    {
        '@type': 'disputePieChart',
        width: 250,
        height: 250,
        forceFit: true,
        radius: 0.8,

        angleField: 'value',
        colorField: 'type',
        legend: { position: 'bottom-center' },
        label: {
            visible: true,
            type: 'outer',
            offset: 20,
        },
    },
    // Avg Purchase cycle(Radar chart)
    {
        '@type': 'avgPurchaseCycleRadarChart',
        width: 250,
        height: 250,
        padding: 'auto',
        forceFit: true,

        angleField: 'type',
        radiusField: 'value',
        seriesField: 'month',
        radiusAxis: {
            grid: {
                line: {
                    type: 'line',
                },
            },
        },
        line: {
            visible: true,
        },
        point: {
            visible: true,
            shape: 'circle',
        },
        legend: {
            visible: true,
            position: 'bottom-center',
        },
    }
]