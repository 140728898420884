class Receipts {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    /**
     *
     * @param {*} participant address
     * @param {*} options : (type) Object
     *                      Keys 'type' and 'state'
     *                          type : sales - the function will return all the receipt ids sent by the participant.
     *                          type : purchases - the function will return all the receipt ids received by the participant.
     *                          type : undefined -  gives all the receipts based on the participant
     *                          state : "0","1","2","3","4"
     *                          state : undefined - gives all the receipts with different states.
     *
     */
    getAllLatestReceipts(participant, options, fromDate, toDate) {
        return this.mongoDBConnector.invoke('receipts', "getAllLatestReceipts", participant, options, fromDate, toDate);
    }

    /**
     *
     * @param {*} participant address
     * @param {*} options : (type) Object
     *                      Keys 'type' and 'state'
     *                          type : sales - the function will return all the receipt ids sent by the participant.
     *                          type : purchases - the function will return all the receipt ids received by the participant.
     *                          type : undefined -  gives all the receipts based on the participant
     *                          state : "0","1","2","3","4"
     *                          state : undefined - gives all the receipts with different states.
     *
     */
    getAllReceipts(participant, options, fromDate, toDate) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceipts", participant, options, fromDate, toDate);


    }

    getAllReceiptsByCurrency(participant, options, fromDate, toDate, currency) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptsByCurrency", participant, options, fromDate, toDate, currency);


    }


    getInvoicesByDisputeKey(owner, disputeKey, type) {
        return this.mongoDBConnector.invoke('receipts', "getInvoicesByDisputeKey", owner, disputeKey, type);


    }

    getAllLatestReceiptsByStatus(participant, options, fromDate, toDate) {
        return this.mongoDBConnector.invoke('receipts', "getAllLatestReceiptsByStatus", participant, options, fromDate, toDate);


    }

    //isClosed:0 - false
    //isClosed:1 - true
    getAllReceiptsByStep(from, to, step, type, isLogistics) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptsByStep", from, to, step, type, isLogistics);
    }

    getAllReceiptsByParent(parentDocId) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptsByParent", parentDocId);
    }

    getAllReceiptsByParentV1(parentDocId) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptsByParentV1", parentDocId);
    }

    getAllReceiptsByParentAndSubType(parentDocId, subType) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptsByParentAndSubType", parentDocId, subType);
    }

    getAllCurrencies() {
        return this.mongoDBConnector.invoke('receipts', "getAllCurrencies");
    }

    getParentDocId(childDocId) {
        return this.mongoDBConnector.invoke('receipts', "getParentDocId", childDocId);
    }

    getInvoiceFromPoAndInvoiceInternalId(poId, invoiceInternalId) {
        return this.mongoDBConnector.invoke('receipts', "getInvoiceFromPoAndInvoiceInternalId", poId, invoiceInternalId);
    }

    getParentDocInternalId(childDocId) {
        return this.mongoDBConnector.invoke('receipts', "getParentDocInternalId", childDocId);
    }
    getSellerAndBuyer(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getSellerAndBuyer", receiptId);
    }

    /**
     * 
     * @param {
        return this.mongoDBConnector.invoke('receipts', "receipts", participant, options, fromDate, toDate);
     * *} receiptId 
     * @param {
        return this.mongoDBConnector.invoke('receipts', "receipts", participant, options, fromDate, toDate);
     * *} predicates Array -> possible values include :
     *                          status
     *                          providerName
     *                          customerName
     *                          amount
     *                          currency
     *                          date
     *                          isTemplateCompleted
     *                          docInternalId
     *                          docStep
     *                          additionalProperty
     *                          parentDocInternalId
     *                          step
     *                          disputeInfo
     *                          @subType
     *                          customerParamId
     *                          providerParamId
     *                          paymentTerms
     *                          transactionType
     */
    getSummary(receiptId, predicates) {
        return this.mongoDBConnector.invoke('receipts', "getSummary", receiptId, predicates);
    }

    getSummaryInBulk(receiptIds, predicates) {
        return this.mongoDBConnector.invoke('receipts', "getSummaryInBulk", receiptIds, predicates);
    }

    getAllItemsSummaryForReceipt(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getAllItemsSummaryForReceipt", receiptId);


    }

    getValidationData(docId, step) {
        return this.mongoDBConnector.invoke('receipts', "getValidationData", docId, step);


    }

    getItemQuantitySummary(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getItemQuantitySummary", receiptId);


    }

    getReceiptInternalId(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getReceiptInternalId", receiptId);
    }

    getReceiptInfoByNode(receiptId, node) {
        return this.mongoDBConnector.invoke('receipts', "getReceiptInfoByNode", receiptId, node);
    }

    isDocumentRejected(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "isDocumentRejected", receiptId);
    }

    getRootReceiptId(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getRootReceiptId", receiptId);
    }

    // receiptId -> root id 
    getAllReceiptIdsInTree(receiptId, receiptArray) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptIdsInTree", receiptId, receiptArray);
    }

    getAllReceiptIdsInPath(source, destination, receiptArray) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptIdsInPath", source, destination, receiptArray);
    }

    getRootReceiptIdFromGrn(grnId) {
        return this.mongoDBConnector.invoke('receipts', "getRootReceiptIdFromGrn", grnId);


    }

    getReceiptV2(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getReceiptV2", receiptId);


    }

    getReceipt(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getReceipt", receiptId);


    }

    getReceiptsByItem(itemId, step, filter, filterType) {
        return this.mongoDBConnector.invoke('receipts', "getReceiptsByItem", itemId, step, filter, filterType);
    }

    getReceiptMetaData(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getReceiptMetaData", receiptId);


    }

    doesExist(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "doesExist", receiptId);


    }

    doesChildExist(parentDocId, internalChildId, childStep) {
        return this.mongoDBConnector.invoke('receipts', "doesChildExist", parentDocId, internalChildId, childStep);


    }

    getNoAccessStatus(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "getNoAccessStatus", receiptId);


    }

    getTransactions(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "receipgetTransactionsts", receiptId);
    }

    getAllReceiptsByTypeForExport(type, step) {
        return this.mongoDBConnector.invoke('receipts', "getAllReceiptsByTypeForExport", type, step);
    }

    addReceipt(receiptId, jsonLd, status, parentDocId, step, grnManager) {
        return this.mongoDBConnector.invoke('receipts', "addReceipt", receiptId, jsonLd, status, parentDocId, step, grnManager);
    }

    addReceipts(receiptArray) {
        return this.mongoDBConnector.invoke('receipts', "addReceipts", receiptArray);
    }

    extractItemMapping(jsonLd, promiseArray) {
        return this.mongoDBConnector.invoke('receipts', "extractItemMapping", jsonLd, promiseArray);

    }


    adjustInventory(jsonLd, action) {
        return this.mongoDBConnector.invoke('receipts', "adjustInventory", jsonLd, action);


    }

    editDisputedInfoAndState(docId) {
        return this.mongoDBConnector.invoke('receipts', "editDisputedInfoAndState", docId);


    }

    addCurrency(currency, docId) {
        return this.mongoDBConnector.invoke('receipts', "addCurrency", currency, docId);


    }

    updateReceipt(receiptId, jsonLd, status, parentDocId, step, grnManager) {
        return this.mongoDBConnector.invoke('receipts', "updateReceipt", receiptId, jsonLd, status, parentDocId, step, grnManager);


    }
    writeNote(receiptId, note, status) {
        return this.mongoDBConnector.invoke('receipts', "writeNote", receiptId, note, status);
    }

    updateStatus(receiptId, newStatus, step) {
        return this.mongoDBConnector.invoke('receipts', "updateStatus", receiptId, newStatus, step);


    }

    appendStatus(receiptId, status) {
        return this.mongoDBConnector.invoke('receipts', "appendStatus", receiptId, status);


    }

    writeStatus(receiptId, status, step) {
        return this.mongoDBConnector.invoke('receipts', "writeStatus", receiptId, status, step);

    }

    deleteNoAccess(receiptId) {
        return this.mongoDBConnector.invoke('receipts', "deleteNoAccess", receiptId);

    }



    insertGrnId(docId, grnId) {
        return this.mongoDBConnector.invoke('receipts', "insertGrnId", docId, grnId);


    }

    doesGrnExistByDocId(docId, grnId) {
        return this.mongoDBConnector.invoke('receipts', "doesGrnExistByDocId", docId, grnId);


    }

    detachGrn(docIds, grnId) {
        return this.mongoDBConnector.invoke('receipts', "detachGrn", docIds, grnId);


    }

    getChildByInvoiceNumber(docId, invoiceNumber) {
        return this.mongoDBConnector.invoke('receipts', "getChildByInvoiceNumber", docId, invoiceNumber);


    }

    updateDisputeInfo(docId, disputeInfo) {
        return this.mongoDBConnector.invoke('receipts', "updateDisputeInfo", docId, disputeInfo);


    }

    attachReturn(parentDocId, returnsId) {
        return this.mongoDBConnector.invoke('receipts', "attachReturn", parentDocId, returnsId);


    }

    updateInvoiceReferencesOrderForGRN(docId, referencesOrder) {
        return this.mongoDBConnector.invoke('receipts', "updateInvoiceReferencesOrderForGRN", docId, referencesOrder);
    }
    updateMasterPOForPartPO(partPOId, docId, partPOJsonLd) {
        return this.mongoDBConnector.invoke('receipts', "updateMasterPOForPartPO", partPOId, docId, partPOJsonLd);
    }

    //Get all invoices attached to a credit note
    getAttachedInvoices(creditNoteId) {
        return this.mongoDBConnector.invoke('receipts', 'getAttachedInvoices', creditNoteId);
    }
}
export default Receipts;
