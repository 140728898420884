import React, { Component } from 'react';
import * as Utils from '../../../../util/utils';
import { Modal, message } from 'antd';
import moment from 'moment';
import { alignedInfoDialogue } from '../../../../constants/AppConstants';
import NetworkBridge from '../../../../util/network-bridge';
import Console from '../../../../logger';
import JSONConverter from '../../../../IIIrd-party/india/e-invoice/json-converter';
import eInvoice from '../../../../IIIrd-party/india/e-invoice';
import InvoiceParser from '../../../../parsers/InvoiceParser';
import * as Config from '../../../../config.json'
import { json } from 'd3';
const { confirm } = Modal;

class CreateFormHelper extends Component {

    static parsePayloadData(formValues, generateIRN, namespace, setStateFromChild) {
        return CreateFormHelper.getParsedJsonLd(formValues, namespace, setStateFromChild).then(res => {
            let { payload, jsonLd, unApprovedItems } = res;
            const type = namespace.state.heading;
            let templateObj = {};
            if (namespace.state.workflowDetails && namespace.state.workflowTxnType) {
                templateObj = {
                    "templateCons": namespace.state.workflowDetails,
                    "step": namespace.state.selectedState,
                    "txnType": namespace.state.workflowTxnType,
                }
            }
            if (generateIRN) {
                CreateFormHelper.sendTransaction(type, generateIRN, payload, JSON.stringify(jsonLd), templateObj, namespace);
                return;
            }
            if (!jsonLd.customer.contactId) {
                jsonLd = JSON.stringify(jsonLd);
                CreateFormHelper.validateWithContract(type, generateIRN, payload, jsonLd, unApprovedItems, templateObj, namespace);
            }
            else {
                NetworkBridge.getContactManager().getContact(jsonLd.customer.contactId).then(res => {
                    let promise = Promise.resolve(res);
                    if (!res) {
                        promise = NetworkBridge.getVendorManager().getContact(jsonLd.customer.contactId);
                    }
                    return promise.then(res => {
                        console.debug('getContact', res);
                        jsonLd.customer = res;
                        jsonLd = JSON.stringify(jsonLd);
                        CreateFormHelper.validateWithContract(type, generateIRN, payload, jsonLd, unApprovedItems, templateObj, namespace);
                    })
                }).catch(err => {
                    message.error("Error in fetching contacts");
                    console.log(err);
                })
            }

        })
    };

    static getParsedJsonLd(formValues, namespace, setStateFromChild) {
        let payload = CreateFormHelper.createPayload(formValues, namespace);
        Console.log('payload', payload);
        let selectedItems = namespace.state.dataSource;
        let itemsCount = {}
        for (let item in selectedItems) {
            if (!itemsCount[selectedItems[item]["internalId"]]) {
                itemsCount[selectedItems[item]["internalId"]] = false;
            }
            else {
                setStateFromChild({ showLoader: false });
                message.error("Can not add same item multiple times", 1);
                return Promise.reject("");
            }
        }
        let internalDocId = formValues[`${namespace.state.heading === "GRN" ? "invoice" : Utils.stringToCamelCase(namespace.state.heading)}Number`];
        if (!payload.date || !namespace.state.buyer || !namespace.state.buyer.identifier ||
            !internalDocId || (!payload.expiryDate && namespace.state.heading === "Contract")) {
            message.error('Please fill required fields');
            setStateFromChild({ showLoader: false });
            return Promise.reject("");
        }
        if (payload.data.length === 0) {
            message.error('Please add an item');
            setStateFromChild({ showLoader: false });
            return Promise.reject("");
        }
        let flag = 0, unApprovedItems = false, blockZeroQuantity = false, quantityExceedsInventory;
        for (let i = 0; i < payload.data.length; i++) {
            if (payload.data[i].item === "" || payload.data[i].type === '---' || payload.data[i].item === null) {
                flag = 1;
            }
            if (!payload.data[i].status || payload.data[i].status === "0") {
                unApprovedItems = true;
            }
            if (namespace.state.logistics ? payload.data[i].load.toString() === "0" : payload.data[i].quantity.toString() === "0" && namespace.state.allowExceedingFields < 2) {
                blockZeroQuantity = true;
            }
            // if (namespace.state.heading === "Invoice" && !namespace.state.fromDetailsPage && Number(payload.data[i].quantity) >= Number(namespace.state.itemIdToQuantity[payload.data[i].internalId])) {
            //     quantityExceedsInventory = payload.data[i].internalId;
            // }
        }

        if (flag === 1 || blockZeroQuantity) {
            setStateFromChild({
                showLoader: false,
            })
            if (flag === 1)
                message.error('Please select the item and provide the quantity and price', 1);
            else
                message.error("One or more items has zero(0) quantity, please update quantity");
            return Promise.reject("");
        }
        setStateFromChild({ quantityExceedsInventory })
        return CreateFormHelper.getItemMapping(payload, internalDocId, unApprovedItems, namespace, setStateFromChild);
    }

    static validateWithContract(type, generateIRN, payload, jsonLd, unApprovedItems, templateObj, namespace) {
        let shouldValidate = Config.customSettings
        if (namespace.state.createText === "Edit" || !shouldValidate) {
            return namespace.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
        }
        jsonLd = JSON.parse(jsonLd);
        let alignedInfo = Utils.checkForAlignedWithContract(namespace.state.dataToValidate, jsonLd.referencesOrder.orderedItem);
        if (namespace.state.isContractAmountLess) {
            let allowExceedingFields = Number(namespace.state.parentReceiptData.allowExceedingFields);
            if (allowExceedingFields === alignedInfo || allowExceedingFields === 3)
                alignedInfo = 0;
        }
        jsonLd['alignedInfo'] = alignedInfo;
        jsonLd = JSON.stringify(jsonLd);
        let that = namespace;
        if (namespace.state.parentDocId === "" && namespace.state.heading !== "Contract" && namespace.state.heading !== "Rate Card") {
            let parentTabName = Utils.camelCaseToString(Utils.getParentTab(namespace.state.heading.toLowerCase()));
            confirm({
                title: `No ${parentTabName} Selected`,
                content: `Do you want to ${namespace.state.createText} this ${namespace.state.heading} without ${parentTabName}`,
                okText: 'Confirm',
                onOk() {
                    if (that.state.quantityExceedsInventory && that.state.heading === "Invoice" && !that.state.fromDetailsPage) {
                        confirm({
                            title: "Heads UP",
                            content: "Some of the items added in Invoice exceeds the available Inventory",
                            okText: 'Review',
                            cancelText: 'Continue Anyway',
                            onOk() {
                                let encodedId = Utils.encodeString(that.state.quantityExceedsInventory)
                                that.props.history.push(`/main/finance/items/itemDetails/${encodedId}`);
                                return;
                            },
                            onCancel() {
                                return that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                            },
                        });
                        return;
                    }
                    else {
                        return that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                    }

                },
                onCancel() { namespace.stopInnerSpinner() },
            });
            return;
        }
        if (namespace.state.isUpdateGRN && namespace.state.grnData.invoiceNumber !== payload.invoiceNumber) {
            confirm({
                title: "Heads Up",
                content: `Do you Want to unlink GRN from invoice ${namespace.state.grnData.invoiceNumber} & link it to ${payload.invoiceNumber}`,
                okText: 'Confirm',
                onOk() {
                    if (!namespace.state.doesInvoiceExist) {
                        confirm({
                            title: "Heads Up",
                            content: `Selected invoice ${payload.invoiceNumber} doesn't exist, are you sure to continue anyway?`,
                            okText: 'Continue Anyway',
                            onOk() {
                                that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                            },
                            onCancel() { namespace.stopInnerSpinner() }
                        })
                        return;
                    }
                    that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                },
                onCancel() { namespace.stopInnerSpinner() }
            })
            return;
        }

        if (alignedInfo === 2 && !(namespace.state.heading === "GRN" || namespace.state.heading === "Grn" || namespace.state.heading === "Invoice")) {
            message.error("Quantity can't be greater than Parent Document");
            namespace.stopInnerSpinner();
            return;
        }

        if (type === "Payment") {
            if (!namespace.state.amountToBePaid) {
                let totalAmountToBePaid = Number(namespace.state.totalInvoiceValue) + namespace.state.noteValuesInfo.creditNote + namespace.state.noteValuesInfo.debitNote - namespace.state.amountReceived;
                confirm({
                    title: "Heads Up",
                    content: `Do you Want to make full payment of ${totalAmountToBePaid}`,
                    okText: 'Confirm',
                    onOk() {
                        that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                    },
                    onCancel() { namespace.stopInnerSpinner() }
                })
                return;
            } else if (namespace.state.amountToBePaid <= 0) {
                message.error("Please enter amount greater than zero");
                namespace.stopInnerSpinner();
                return;
            } else {
                const totalAmountToBePaid = Number(namespace.state.totalInvoiceValue) + namespace.state.noteValuesInfo.creditNote + namespace.state.noteValuesInfo.debitNote - namespace.state.amountReceived;
                const amountToBePaid = namespace.state.amountToBePaid;
                if (amountToBePaid > totalAmountToBePaid) {
                    message.error("Payment amount is greater than total invoice value");
                    namespace.stopInnerSpinner();
                    return;
                }
            }
        }
        if (alignedInfo === 0) {
            if (unApprovedItems && type === "Purchase Order" || type === "Transport Order") {
                confirm({
                    title: "Heads Up",
                    content: `Some of the selected items are unapproved, do you still want to continue?`,
                    okText: 'Confirm',
                    onOk() {
                        that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                    },
                    onCancel() { namespace.stopInnerSpinner() },
                });
                return;
            }
            namespace.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
            return;
        }
        if (namespace.state.heading === "GRN" || namespace.state.heading === "Grn") {
            if (alignedInfo === 1) {
                message.error("Amount can't exceed than Purchase Order for GRNs");
                namespace.stopInnerSpinner();
                return;
            }
        }
        confirm({
            title: alignedInfoDialogue[Utils.stringToCamelCase(namespace.state.heading).toLowerCase()][alignedInfo - 1].title,
            content: alignedInfoDialogue[Utils.stringToCamelCase(namespace.state.heading).toLowerCase()][alignedInfo - 1].info,
            okText: 'Confirm',
            onOk() {
                if (unApprovedItems && type === "Purchase Order") {
                    confirm({
                        title: "Heads Up",
                        content: `Some of the selected items are unapproved, do you still want to continue?`,
                        okText: 'Confirm',
                        onOk() {
                            that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                        },
                        onCancel() { namespace.stopInnerSpinner() },
                    });
                    return;
                }
                that.sendTransaction(type, generateIRN, payload, jsonLd, templateObj);
                return;
            },
            onCancel() { namespace.stopInnerSpinner() },
        });

    }

    static createPayload = (formValues, namespace) => {
        let provider = {};
        // provider = Utils.getMyProfileJsonLd();
        let profile = Utils.getFromLocalStorage('profile');

        provider = JSON.parse(profile);
        let payload = {};
        payload["customer"] = namespace.state.buyer;
        payload["provider"] = provider;
        payload["qCreditNoteNumber"] = formValues.qCreditNoteNumber;
        payload["data"] = namespace.state.dataSource;
        payload['globalDiscount'] = namespace.state.globalDiscount;
        payload["txnType"] = namespace.state.txnType.toString();
        payload["price"] = namespace.state.totalAmount;
        payload["date"] = formValues.date.unix() * 1000;
        payload["customNote"] = namespace.state.customNote;
        payload["terms"] = namespace.state.terms;
        // payload["privateFor"] = namespace.state.selectedContract;
        payload['currency'] = namespace.props.currency;
        payload['invoiceNumber'] = formValues.invoiceNumber;
        payload['draftInvoiceNumber'] = formValues.draftInvoiceNumber;
        payload['debitAdviceNumber'] = formValues.debitAdviceNumber;
        payload['creditNoteId'] = formValues.creditNoteId;
        payload['debitadviceId'] = formValues.debitadviceId;
        payload['purchaseOrderNumber'] = formValues.purchaseOrderNumber;
        payload['contractNumber'] = formValues.contractNumber;
        payload['paymentNumber'] = formValues.paymentNumber;
        payload['rateCardNumber'] = formValues.rateCardNumber;
        payload['transportOrderNumber'] = formValues.transportOrderNumber;
        payload['gRNNumber'] = formValues.gRNNumber;
        payload['returnRequestNumber'] = formValues.returnRequestNumber;
        payload['returnOrderNumber'] = formValues.returnOrderNumber;
        payload['acceptNoteNumber'] = formValues.acceptNoteNumber;
        payload['eLRNumber'] = formValues.eLRNumber;
        payload['ePoDNumber'] = formValues.ePoDNumber;
        payload['partPurchaseOrderNumber'] = formValues.partPurchaseOrderNumber;
        payload['latLng'] = namespace.state.latLng;
        payload['address'] = namespace.state.address;
        payload["dynamicFormData"] = namespace.state.dynamicFormData;
        payload["dynamicFormDataType"] = namespace.state.dynamicFormDataType;
        payload["expiryDate"] = formValues.expiryDate;
        payload["paymentTerms"] = formValues.paymentTerms;
        payload["expectedDeliveryDate"] = formValues.expectedDeliveryDate && formValues.expectedDeliveryDate.unix() * 1000;
        payload["isAmountLess"] = namespace.state.isAmountLess;
        payload["allowExceedingFields"] = namespace.state.allowExceedingFields;
        payload["shareCatalogue"] = namespace.state.shareCatalogue;
        payload["linkedReceipts"] = namespace.state.linkedReceipts;
        payload["fileAttachments"] = formValues.fileAttachments;
        payload["step"] = formValues.step
        payload["status"] = formValues.status
        payload["subType"] = namespace.state.logistics && "logistics"
        if (namespace.state.parentDocId) {
            try {
                payload["additionalColumns"] = JSON.parse(namespace.state.parentReceiptData.referencesOrder.additionalColumns);
            } catch (e) {
                payload["additionalColumns"] = [];
            }
        }
        else {
            payload["additionalColumns"] = Utils.getFromLocalStorage("itemProperties") || [];
        }
        return payload;
    }


    static getItemMapping(payload, internalDocId, unApprovedItems, namespace, setStateFromChild) {

        let firstStep = {
            'Contract': "0",
            'Rate Card': "0",
            'Transport Order': "1",
            'Purchase Order': "1",
            'Invoices': "2",
            'Invoice': "2"
        }
        let itemManager = NetworkBridge.getItemManager();
        let itemData = payload.data;
        let promiseArray = [], unMappedItems = false, itemsToMap = [];
        let selfParamId = Utils.getParamId();
        let docOwner = namespace.state.parentDocId ? namespace.state.parentReceiptData.docOwner : selfParamId;
        let providerParamId = docOwner;
        let customerParamId = selfParamId;

        const checkItemMapping = () => {
            return false //(providerParamId !== customerParamId && namespace.state.parentDocId);
        }
        setStateFromChild({ showInnerSpinner: true });
        let promise = Promise.resolve();
        if (namespace.state.isUpdateGRN) {
            promise = NetworkBridge.getReceiptManager().getInvoiceFromPoAndInvoiceInternalId(namespace.state.grnData.poId, payload.invoiceNumber);
        }
        return promise.then(res => {
            setStateFromChild({ doesInvoiceExist: res });
            return NetworkBridge.getReceiptManager().doesChildExist(namespace.state.parentDocId, internalDocId, firstStep[namespace.state.heading])
        }).then(res => {
            if (!res) {
                //No need to do mapping in self document
                if (checkItemMapping()) {
                    for (let index in itemData) {
                        if (!itemData[index].mappingInfo) {
                            promiseArray.push(
                                itemManager.doesItemMappingExist(itemData[index].internalId, providerParamId)
                            )
                        }
                    }
                    return Promise.all(promiseArray).then(itemMapping => {
                        for (let index in itemMapping) {
                            if (!itemMapping[index]) {
                                itemsToMap.push(itemData[index]);
                                unMappedItems = true;
                            }
                            else {
                                for (let index in itemData) {
                                    if (itemData[index].internalId === itemMapping[index].mappings['sellerItemId']) {
                                        itemData[index].mappingInfo = itemMapping[index].mappings;
                                    }
                                }
                            }
                        }
                        if (unMappedItems) {
                            setStateFromChild({
                                linkItemDrawer: true,
                                selectedItemToMap: itemsToMap,
                                showInnerSpinner: false,
                                providerParamId,
                                customerParamId
                            });
                            return Promise.reject();
                        }
                        setStateFromChild({ showInnerSpinner: false });
                        return { payload, jsonLd: JSON.parse(InvoiceParser.createContract(payload, firstStep[namespace.state.heading])), unApprovedItems };
                    })
                }
                return { payload, jsonLd: JSON.parse(InvoiceParser.createContract(payload, firstStep[namespace.state.heading])), unApprovedItems };
            }
            message.error(`Given ${namespace.state.heading} Number already exists for the parent Doc`);
            setStateFromChild({ showInnerSpinner: false });
            return Promise.reject("")
        })

    }

    static sendTransaction = (currentTab, generateIRN, payload, jsonLd, templateObj, namespace) => {
        let promise = Promise.resolve();
        const paramReceiptManager = NetworkBridge.getReceiptManager()
        const paramGRNManager = NetworkBridge.getGRNManager();
        const paramQualityManager = NetworkBridge.getQualityManager();
        const paramReturnsManager = NetworkBridge.getReturnsManager();
        const paramAcceptNoteManager = NetworkBridge.getAcceptNoteManager();
        const paramPartPOManager = NetworkBridge.getPartPOManager();
        const paramELRManager = NetworkBridge.getELRManager();
        let selfParamId = Utils.getParamId(), isInward;
        const { parentReceiptData, txnType, dynamicFormDataType,
            invitationId, grnManager, amountToBePaid, totalInvoiceValue, noteValuesInfo,
            amountReceived, isUpdate, currentDocId, shareCatalogue, type, irnObj, irnGenerated,
            isPartPO, parentPOId } = namespace.state;
        const parentDocId = namespace.state.toID ? namespace.state.toID : namespace.state.parentDocId
        jsonLd = JSON.parse(jsonLd)
        jsonLd.customer = Utils.convertToProfileFormat(jsonLd.customer)
        jsonLd.docOwner = selfParamId;
        jsonLd.fileAttachments = payload.fileAttachments
        jsonLd = JSON.stringify(jsonLd);

        const alterRoles = (jsonLd) => {
            let sendJsonld = JSON.parse(jsonLd);
            let temp = sendJsonld.customer;
            sendJsonld.customer = sendJsonld.provider;
            sendJsonld.provider = temp;
            sendJsonld.createdByBuyer = true;
            sendJsonld = JSON.stringify(sendJsonld);
            return sendJsonld;
        }

        switch (currentTab) {
            case "Rate Card":
            case 'Contract':
                let isMandatory = true;
                let customer = payload.customer;
                let provider = payload.provider;
                let fromPurchase = false;
                if (invitationId) {
                    jsonLd = JSON.parse(jsonLd)
                    jsonLd["@subType"] = "evalRequest";
                    jsonLd["invitationId"] = invitationId;
                    jsonLd = JSON.stringify(jsonLd);
                }
                if (type === 'purchases' || type === "lpurchases") {
                    isMandatory = false;
                    customer = payload.provider;
                    provider = payload.customer;
                    fromPurchase = true;
                    jsonLd = alterRoles(jsonLd);
                }
                if (namespace.state.createText !== 'Edit') {
                    promise = paramReceiptManager.initProposalV1(customer.identifier, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), isMandatory, shareCatalogue, customer.publicKey, provider.identifier, fromPurchase, templateObj)
                } else {
                    promise = paramReceiptManager.updateInitProposal(parentDocId, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), isMandatory, shareCatalogue, customer.publicKey, provider.identifier, customer.identifier, fromPurchase, templateObj)
                }
                break;
            case 'Transport Order':
                jsonLd = JSON.parse(jsonLd);
                jsonLd.grnManager = grnManager.identifier;
                if (!parentDocId) {
                    jsonLd.reverseMapping = true;
                } else if (parentReceiptData.docOwner === selfParamId) {
                    jsonLd.reverseMapping = true;
                }
                jsonLd = JSON.stringify(jsonLd);
                if (type === "sales") {
                    isInward = true;
                }
                else {
                    jsonLd = alterRoles(jsonLd);
                }
                // if (moment().isAfter(expiryDate, 'day')) {
                //     message.error("Contract expired!");
                //     promise = Promise.reject("Contract expired");
                //     break;
                // }
                if (namespace.state.createText !== 'Edit') {
                    promise = paramReceiptManager.createLogisticsPO(parentDocId, payload.customer.identifier, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), templateObj, grnManager, isInward)
                } else {
                    promise = paramReceiptManager.updatePO(parentDocId, payload.customer.identifier, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), templateObj, grnManager, isInward)
                }
                break;
            case 'Purchase Order':
                jsonLd = JSON.parse(jsonLd);
                jsonLd.shareCatalogue = parentReceiptData ? parentReceiptData.shareCatalogue : "0";
                jsonLd.grnManager = grnManager.identifier;
                if (!parentDocId) {
                    jsonLd.reverseMapping = true;
                } else if (parentReceiptData.docOwner === selfParamId) {
                    jsonLd.reverseMapping = true;
                }
                jsonLd = JSON.stringify(jsonLd);
                if (type === "sales" || type === "lsales") {
                    isInward = true;
                }
                else {
                    jsonLd = alterRoles(jsonLd);
                }
                // if (moment().isAfter(expiryDate, 'day')) {
                //     message.error("Contract expired!");
                //     promise = Promise.reject("Contract expired");
                //     break;
                // }
                if (namespace.state.createText !== 'Edit') {
                    promise = paramReceiptManager.createPoV1(parentDocId, payload.customer.identifier, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), templateObj, grnManager, isInward)
                } else {
                    promise = paramReceiptManager.updatePO(parentDocId, payload.customer.identifier, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), templateObj, grnManager, isInward)
                }
                break;

            case 'Invoices':
            case 'Invoice':
                if (generateIRN) {
                    namespace.setState({
                        showQRLoader: true
                    })
                    jsonLd = JSON.parse(jsonLd);
                    jsonLd['displayReceiptId'] = jsonLd.invoiceNumber;
                    jsonLd['invoiceDate'] = payload.date;
                    jsonLd = JSON.stringify(jsonLd);
                    let jsonForIRN = JSONConverter.convertToEinvoiceSchema(jsonLd);
                    return eInvoice.generateIRN(jsonForIRN).then(decryptedData => {
                        console.log(decryptedData);
                        const irnObj = {
                            "QRCode": decryptedData.SignedQRCode,
                            "Irn": decryptedData.Irn,
                            "SignedInvoice": decryptedData.SignedInvoice
                        }
                        namespace.setState({
                            irnObj,
                            showLoader: false,
                            irnGenerated: true,
                            irn: decryptedData.Irn
                        })
                    }).catch(exception => {
                        namespace.setState({ showLoader: false })
                        Console.error("create Invoice ", exception)
                        message.error(`Could not generate IRN`);
                    }).finally(() => {
                        namespace.setState({
                            showQRLoader: false,
                            showInnerSpinner: false
                        })
                    })
                }
                else {
                    // if (jsonLd) {
                    //     jsonLd = jsonLd
                    // }
                    if (parentDocId) {
                        jsonLd = JSON.parse(jsonLd);
                        jsonLd.reverseMapping = parentReceiptData.reverseMapping;
                        jsonLd = JSON.stringify(jsonLd);
                    }
                    if (type === "purchases") {
                        isInward = true;
                        jsonLd = alterRoles(jsonLd);
                    }
                    if (irnGenerated) {
                        jsonLd = JSON.parse(jsonLd);
                        jsonLd.IRN = irnObj
                        jsonLd = JSON.stringify(jsonLd);
                    }
                    if (namespace.state.createText !== 'Edit') {
                        promise = paramReceiptManager.createInvoiceV1(parentDocId, payload.customer.identifier, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), templateObj, isInward);
                    } else {
                        promise = paramReceiptManager.updateInvoice(parentDocId, payload.customer.identifier, jsonLd, JSON.stringify(dynamicFormDataType), txnType.toString(), templateObj, isInward);
                    }
                    break;
                }
            case "Payment":
                const amountDue = amountToBePaid ||
                    Number(totalInvoiceValue) + noteValuesInfo.creditNote + noteValuesInfo.debitNote - amountReceived;
                jsonLd = JSON.parse(jsonLd);
                jsonLd.invoiceNumber = parentReceiptData.invoiceNumber;
                jsonLd.minimumPaymentDue.price = amountDue;
                jsonLd.minimumPaymentDue.priceCurrency = namespace.props.currency;
                jsonLd.totalPaymentDue = jsonLd.minimumPaymentDue;
                jsonLd = JSON.stringify(jsonLd);
                if (type === "sales") {
                    isInward = true;
                }
                else {
                    jsonLd = alterRoles(jsonLd);
                }
                if (namespace.state.createText !== 'Edit') {
                    promise = paramReceiptManager.makePaymentV1(parentDocId, jsonLd, JSON.stringify(dynamicFormDataType), txnType, templateObj, isInward, payload.customer.identifier, payload.provider.identifier);
                } else {
                    promise = paramReceiptManager.updatePayment(parentDocId, jsonLd, JSON.stringify(dynamicFormDataType), txnType, templateObj, isInward, payload.customer.identifier, payload.provider.identifier);
                }
                break;
            case "GRN":
                jsonLd = alterRoles(jsonLd);
                jsonLd = JSON.parse(jsonLd);
                jsonLd.customer = parentReceiptData.customer;
                jsonLd['@subType'] = "GRN";
                let orderedItem = jsonLd.referencesOrder.orderedItem;
                for (let index in orderedItem) {
                    delete orderedItem[index].buyerItemId;
                    delete orderedItem[index].sellerItemId;
                    delete orderedItem[index].orderItemMapping;
                    delete orderedItem[index].orderItemNumberLink;
                }
                jsonLd.reverseMapping = parentReceiptData.reverseMapping;
                jsonLd.formData = JSON.stringify(dynamicFormDataType);
                jsonLd = JSON.stringify(jsonLd);
                let parentIdForGRN = parentDocId;
                if (parentReceiptData.step === "2" && parentReceiptData.parentDocId) {
                    parentIdForGRN = parentReceiptData.parentDocId;
                }
                if (isUpdate) {
                    promise = paramGRNManager.updateGRN(currentDocId, parentIdForGRN, jsonLd, txnType, parentReceiptData.customer.identifier);
                } else {
                    promise = paramGRNManager.addGRN(parentIdForGRN, jsonLd, txnType, parentReceiptData.customer.identifier);
                }
                break;
            case "Return Request":
                jsonLd = alterRoles(jsonLd);
                jsonLd = JSON.parse(jsonLd);
                jsonLd['@subType'] = "returnRequest";
                jsonLd.invoiceNumber = parentReceiptData.invoiceNumber;
                const buyerParamId = jsonLd.customer.identifier;
                const sellerParamId = jsonLd.provider.identifier;
                jsonLd = JSON.stringify(jsonLd);
                console.log(jsonLd);
                promise = paramReturnsManager.initReturnRequest(parentDocId, buyerParamId, sellerParamId, jsonLd, "", txnType)
                break;
            case "Return Order":
                jsonLd = JSON.parse(jsonLd);
                jsonLd['@subType'] = "returnOrder";
                jsonLd.invoiceNumber = parentReceiptData.invoiceNumber;
                jsonLd = JSON.stringify(jsonLd);
                promise = paramReturnsManager.issueReturnOrder(parentReceiptData.parentDocId, parentDocId, jsonLd, "", txnType)
                break;
            case "ePoD":
                jsonLd = alterRoles(jsonLd);
                jsonLd = JSON.parse(jsonLd);
                jsonLd.customer = parentReceiptData.customer;
                jsonLd['@subType'] = "ePoD";
                jsonLd['poId'] = parentDocId
                jsonLd = JSON.stringify(jsonLd);
                if (isUpdate) {
                    promise = paramGRNManager.updateGRN(currentDocId, parentDocId, jsonLd, txnType, parentReceiptData.customer.identifier);
                } else {
                    promise = paramGRNManager.addGRN(parentDocId, jsonLd, txnType, parentReceiptData.customer.identifier);
                }
                break;
            case "eLR":
            case "elr":
            case "ELR":
                jsonLd = JSON.parse(jsonLd);
                jsonLd['@subType'] = "elr";
                jsonLd = JSON.stringify(jsonLd);
                promise = paramELRManager.addeLR(parentDocId, jsonLd, txnType)
                break;
            case "Accept Note":
                jsonLd = JSON.parse(jsonLd);
                jsonLd['@subType'] = "acceptNote";
                jsonLd = JSON.stringify(jsonLd);
                promise = paramAcceptNoteManager.attachAN(parentDocId, jsonLd, txnType)
                break;
            case "Draft Invoice":
                jsonLd = JSON.parse(jsonLd)
                jsonLd['@subType'] = "draftInvoice";
                jsonLd = JSON.stringify(jsonLd);
                promise = paramAcceptNoteManager.addCreditNote(parentDocId, jsonLd, txnType)
                break
            case "Part Purchase Order":
                jsonLd = JSON.parse(jsonLd);
                jsonLd['@subType'] = "partPurchaseOrder";
                jsonLd = JSON.stringify(jsonLd);
                promise = paramPartPOManager.attachPartPO(parentPOId, jsonLd, txnType)
                break;

            // Add new case for stateGraph
            case "QCreditNote":
                let owner = Utils.getParamId();
                jsonLd = JSON.parse(jsonLd)
                jsonLd.step = "1"
                jsonLd.status = "1"
                jsonLd["@subType"] = "quality"
                if (parentDocId) {
                    jsonLd['parentDocId'] = parentDocId
                }
                let subState = Config.subState.qCreditNoteGenerated
                let currentProvider = jsonLd && jsonLd.provider && jsonLd.provider.identifier ? jsonLd.provider.identifier : "";
                let currentCustomer = jsonLd && jsonLd.customer && jsonLd.customer.identifier ? jsonLd.customer.identifier : "";
                let customerID = ((owner === currentProvider) ? currentCustomer : currentProvider);
                let state = 1
                let id = `${Utils.generateByte64Id(jsonLd.qCreditNoteNumber)}-0xe253D59a6efeB75b7744A47CD46De4749eA0E450`//parentReceiptData && parentReceiptData['_id'] ? parentReceiptData['_id'] : ""
                console.log("Credit Note JSON LD", jsonLd)
                //to support Q-Module workflow
                return paramQualityManager.stateGraphV1(id, state, subState, customerID, owner, jsonLd, jsonLd['parentDocId'])
            default:
                break;
        }

        return promise;
    }
}

export default CreateFormHelper;