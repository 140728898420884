import React from "react";
import { Col, Row, Card, message } from "antd";
import BaseComponent from "../BaseComponent";
import LandingAsset from 'assets/images/landing.png';
import FinanceAsset from 'assets/images/finance.png';
import OperationsAsset from 'assets/images/operations.png';
import NetworkBridge from '../../../util/network-bridge';
import * as Utils from '../../../util/utils';
import Console from '../../../logger/index';
import "./index.less";
import DocumentTheme from "../Setting/document-theme";
import * as Config from "../../../config.json";
import Analytics from '../../../analytics';
import AccountListing from './accountListing';
import ClientBanner from "./ClientBanner/index"


class LandingPage extends BaseComponent {

  constructor(props) {
    super(props);
    Analytics.getInstance().trackPageview();
  }

  initStates() {
    this.state = {
      showLoader: false,
      accounts: Utils.getFromLocalStorage("accounts")
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }
  backgroundSync = () => {
    let promise = NetworkBridge.getSyncManager().syncAllData({
      onProgress: function (type, status) {
        console.debug('statusText', status, type)
      },
      onProgressText: function (type, statusText) {
        console.debug('onProgressText', statusText)
      },
      onError: function (e) {
        message.error("error in syncing");
        console.error("Error in syncing", e)
      }
    })
    Promise.resolve(promise)
  }
  onDBReady = () => {
    Console.log("Public Key", Utils.getFromLocalStorage("publicKey"))
    Console.log("Private Key", Utils.getFromLocalStorage("privateKey"))
    Console.log("ParamId", Utils.getFromLocalStorage("param_id"))
    DocumentTheme.getInstance().onReady(this.getUICallback());
    if (this.props.location && this.props.location.search === '?init') {
      this.restoreAllVendorTemplates();
      Utils.setPublic(Utils.getPublicKey()).then(res => {
        Console.debug(res);
        message.success('Added profile');
      }).catch(err => {
        Console.error(err);
        message.error('Error Adding Profile');
      }).finally(() => {
        let deeplinkingUrl = Utils.getFromLocalStorage('deeplinkingurl')
        let deeplinkingData = Utils.getFromLocalStorage('deeplinkingData')
        if (deeplinkingUrl) {
          this.props.history.push({ pathname: deeplinkingUrl, state: { data: deeplinkingData } })
        }
      })
    }
    Config["auto-sync-configuration"].autoSync ? this.backgroundSync() : console.log("Auto-Sync is turned off at Config")
  }
  getUICallback = () => {
    return {
      onStarted: () => { },
      onProgress: () => { },
      onCompleted: () => { },
      onError: () => { },
      onFinished: (themeMap) => {
        console.log(themeMap);
        message.success("Document themes restored")
      }
    }
  }

  restoreAllVendorTemplates = () => {
    let vendorManager = NetworkBridge.getVendorManager();
    let vendorContractAddress = vendorManager.getContractAddress();
    let paramId = Utils.getParamId();
    vendorManager.restoreAllVendorTemplate(paramId, vendorContractAddress).then(res => {
      message.success('Successfully restored invitation template');
    }).catch(err => {
      message.error(`Unable to restore default invitation template ${err.message}`)
    })
  }

  renderMainContent() {
    const title = <h2>Select Account</h2>
    return (
      <div className="welcomeScreen">
        <Card>
          {/* <Row>
            <Col md={6} xs={24} className="welcomeScreen__verticalAlignImg">
              <img src={LandingAsset} alt="" />
            </Col>
            <Col md={18} xs={24}>
              <h1>⦃param⦄ Turbocharge Commerce Across Enterprise</h1>
              <p> An Intelligent multi enterprise workflow automation platform.
              End to end processing of all Workflow documents all the way from purchase orders, to invoices to receipts.
                    </p>
            </Col>
          </Row> */}
          <ClientBanner
            imageURL={Config["bosch-image-url"]}
            clientText="TRAVIS 2.0"
          />
        </Card>

        {
          //Account Listing in Home Page
          this.state.accounts.length > 1
            ?
            <Row>
              <Col md={24} xs={24}>
                <Card title={title}>
                  <AccountListing />
                </Card>
              </Col>
            </Row>
            :
            <Row gutter={16}>
              <Col className="gutter-row" md={12} xs={24}>
                <div className="gutter-box welcomeScreen__cards welcomeScreen__finance">
                  <Card>
                    <h1 style={{ color: '#535353' }}>Finance</h1>
                    <p style={{ color: '#545454', whiteSpace: 'normal' }}>View your entire financing to do at one place. Never miss any bills, payment deadline.
                    Check out our instant reconciliation with all your vendor books.
                            Improve your overall cash efficiency using ⦃param⦄</p>
                    <img className="welcomeScreen__right" src={FinanceAsset} alt="" />
                  </Card>
                </div>
              </Col>
              <Col className="gutter-row" md={12} xs={24}>
                <div className="gutter-box welcomeScreen__cards welcomeScreen__operation">
                  <Card>
                    <h1 style={{ color: '#535353' }}>Operations</h1>
                    <p style={{ color: '#545454', whiteSpace: 'normal' }}>Pull up your tasks, process and any other operational activities you’ve lined up for today.
                    In fact it’s not just for today—you can go back in time or forward, now its more powerful,
                              with context, filters, and a bunch of actions you can perform</p>
                    <img className="welcomeScreen__right" src={OperationsAsset} alt="" />
                  </Card>
                </div>
              </Col>
            </Row>
        }
      </div>
    );

  }
}
export default LandingPage;