import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import ParamReceipt from './receipt';
import TemplateRepo from './template-repo';
import ECIES from '../ecies';
import Subscriber from './subscribers';
import GraphQL from '../../param-libs/graph-ql';
import Console from '../../logger';
import { json } from 'd3-fetch';
class TemplateCons {

    static attachWorkflow(docId, templateId, stepSigners, step, txnType, receiptMetaData, rootDetails) {
        if (!stepSigners || stepSigners.length === 0) {
            throw new Error("Unable to validate inputs.");
        }
        let docInfo = Utils.getTransactionData(docId);
        let templateInfo = Utils.getTransactionData(templateId);
        let templateConsManager = GraphQL.getInstance().templateConsensus;
        let txnTypeString = Utils.getTxnTypeForGraphQL(txnType);
        return templateConsManager.attachTemplateForAll(docInfo.address, docInfo.id, parseInt(step), templateInfo.address, templateInfo.id, stepSigners, txnTypeString).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        }).then(() => {
            return TemplateCons.formatStepSigners(stepSigners);
        }).then(res => {
            stepSigners = res;
            return TemplateCons.addSubscriberForCons(docId, stepSigners, receiptMetaData, rootDetails);
        })
        // // txnType = "1";
        // if (!stepSigners || stepSigners.length === 0) {
        //     throw new Error("Unable to validate inputs.");
        // }
        // return Utils.getConfig("TemplateConsensusImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let docInfo = Utils.getTransactionData(docId);
        //     let templateInfo = Utils.getTransactionData(templateId);
        //     let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(config.address);
        //     return templateConsManager.attachTemplateForAll(docInfo.address, docInfo.id, step, templateInfo.address, templateInfo.id, stepSigners, txnType, options).then(data => {
        //         console.log("attach template hash", data);
        //         return ParamReceipt.assignTemplateReceipt(data, docInfo.id, config.address, step);
        //     }).catch(e => {
        //         return e;
        //     })
        // })
        //     .then(() => {
        //         return TemplateCons.formatStepSigners(stepSigners);
        //     }).then(res => {
        //         stepSigners = res;
        //         return TemplateCons.addSubscriberForCons(docId, stepSigners);
        //     })
    }

    static attachWorkflowV2(docIds, templateId, stepSigners, step, txnType, receiptsMetaData) {
        if (!stepSigners || stepSigners.length === 0) {
            throw new Error("Unable to validate inputs.");
        }
        let docContractAddressMap = new Set();
        let receiptIds = docIds.map((value) => {
            let docTransactionData = Utils.getTransactionData(value);
            docContractAddressMap.add(docTransactionData.address);
            return docTransactionData.id;
        })
        if (docContractAddressMap.size > 1) {
            return Promise.reject("All selected documents must belong to same contract");
        }
        let templateConsManager = GraphQL.getInstance().templateConsensus;
        let templateInfo = Utils.getTransactionData(templateId);
        docContractAddressMap = [...docContractAddressMap];
        return templateConsManager.attachTemplateForAllV2(docContractAddressMap[0], receiptIds, step, templateInfo.address, templateInfo.id, stepSigners, txnType).then(data => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(data))
            return GraphQL.getInstance().sendRawTxn(signedData)
        }).then(() => {
            return TemplateCons.formatStepSigners(stepSigners);
        }).then(res => {
            stepSigners = res;
            const addSubsForMultipleDocs = (index) => {
                return TemplateCons.addSubscriberForCons(docIds[index], stepSigners, receiptsMetaData[index]).then(res => {
                    if (index === docIds.length - 1) {
                        return res;
                    }
                    index++;
                    return addSubsForMultipleDocs(index);
                })
            }
            return addSubsForMultipleDocs(0);
        })

        // return Utils.getConfig("TemplateConsensusImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let templateInfo = Utils.getTransactionData(templateId);
        //     let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(config.address);
        //     docContractAddressMap = [...docContractAddressMap];
        //     return templateConsManager.attachTemplateForAllV2(docContractAddressMap[0], receiptIds, step, templateInfo.address, templateInfo.id, stepSigners, txnType, options).then(data => {
        //         console.log("attach template for all hash", data);
        //         const assignTemplateReceipt = (index) => {
        //             return ParamReceipt.assignTemplateReceipt(data, docIds[index], config.address, step).then(res => {
        //                 if (index === docIds.length - 1) {
        //                     return res;
        //                 }
        //                 index++;
        //                 return assignTemplateReceipt(index);
        //             })
        //         }

        //         return assignTemplateReceipt(0);
        //     })
        // }).then(() => {
        //     return TemplateCons.formatStepSigners(stepSigners);
        // }).then(res => {
        //     stepSigners = res;
        //     const addSubsForMultipleDocs = (index) => {
        //         return TemplateCons.addSubscriberForCons(docIds[index], stepSigners).then(res => {
        //             if (index === docIds.length - 1) {
        //                 return res;
        //             }
        //             index++;
        //             return addSubsForMultipleDocs(index);
        //         })
        //     }
        //     return addSubsForMultipleDocs(0);
        // })
    }

    static addSubscriberForCons(docId, stepSigners, receiptMetaData, rootDetails) {
        let addSubscriberForCons = (index) => {
            return Subscriber.addSubscriberForCons("", docId, receiptMetaData.transactionType, stepSigners[index].paramId, stepSigners[index].publicKey, receiptMetaData.seller, receiptMetaData.buyer, "addSubscriberForCons", rootDetails).then(res => {
                console.log("addSubsHash", res);
                if (index === stepSigners.length - 1) {
                    return res;
                }
                index++;
                return addSubscriberForCons(index);
            }).catch(err => {
                Console.error("Error in adding subscriber", err);
            })
        }
        return addSubscriberForCons(0);
    }

    static formatStepSigners(stepSigners) {
        stepSigners = new Set(stepSigners);
        stepSigners = [...stepSigners];
        let promiseArray = [];
        for (let index in stepSigners) {
            promiseArray.push(
                Utils.getPublic(stepSigners[index]).then(subsPublicKeys => {
                    stepSigners[index] = {
                        paramId: stepSigners[index],
                        publicKey: subsPublicKeys
                    }
                    return stepSigners[index];
                })
            );
        }
        return Promise.all(promiseArray);
    }

    static signStep(templateConsId, currentStep, jsonLd, metaData, mergeConsensusId) {
        let templateConsIdArray = Utils.getArrayOfData(templateConsId);
        let promise = Promise.resolve({ templateConsIdArray });
        if (mergeConsensusId) {
            promise = TemplateCons.getAllTemplateConsIdsByMergeId(mergeConsensusId);
        }
        return promise.then(templateConsIdArray => {
            templateConsIdArray = templateConsIdArray.templateConsensus ? templateConsIdArray.templateConsensus : templateConsIdArray.templateConsIdArray;
            const signByCurrentStep = (index) => {
                return TemplateCons.signStepByCurrentStep(templateConsIdArray[index], currentStep, jsonLd, metaData).then(() => {
                    if (index === templateConsIdArray.length - 1) {
                        return;
                    }
                    index++;
                    return signByCurrentStep(index);
                })
            }
            return signByCurrentStep(0);
        })
    }

    static signStepByCurrentStep(templateConsId, currentStep, jsonLd, metaData) {
        let templateConsManager = GraphQL.getInstance().templateConsensus;
        delete jsonLd.status;
        jsonLd = typeof (jsonLd) !== 'string' ? JSON.stringify(jsonLd) : jsonLd;
        let paramID = Utils.getParamId();
        return GraphQL.getInstance().templateConsensus.getMissingNonce(paramID).then(res => {
            if(!res){
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return TemplateCons.getTemplateConsInfo(templateConsId)
        }).then(consensusData => {
            let stepSignersInfo = consensusData.stepSignersInfo;
            let txnType = consensusData.transactionType;
            currentStep = Number(currentStep);
            let stepConsId = consensusData['stepConsNumber' + String(currentStep + 1)];
            let subscribers = [];
            let stepInfo = Utils.getTransactionData(stepConsId);
            let txnTypeString = Utils.getTxnTypeForGraphQL(txnType);
            if (txnType === "2") {
                let privateKey = Utils.getPrivateKey();
                let publicKey = Utils.getPublicKey();
                let encryptedJsonLd = ECIES.encrypt(privateKey, publicKey, jsonLd);
                let stepKey = ECIES.getReceiptKey(privateKey, encryptedJsonLd);
                subscribers = Utils.getArrayOfData(stepSignersInfo[stepConsId].subscribers);

                //pending status
                jsonLd = JSON.parse(jsonLd);
                jsonLd.status = 1;
                jsonLd = JSON.stringify(jsonLd);
                let dbInstance = ParamConnector.getInstance().getDB();
                let templateConsensusDB = dbInstance.templateConsensusDB;
                templateConsensusDB.updateStepConsensus(stepConsId, jsonLd, metaData, "", subscribers, txnType, "", templateConsId)
                    .then(() => {
                        dbInstance.emitEvent("onStepSigned", templateConsId)
                    }).catch(err => {
                        console.log("error", err);
                    })
                jsonLd = JSON.parse(jsonLd);
                delete jsonLd.status;
                jsonLd = JSON.stringify(jsonLd);

                const shareStepKeys = (index) => {
                    return Utils.getPublic(subscribers[index].subscriber).then(subscriberPublicKey => {
                        let subscriberStepKey = ECIES.encrypt(privateKey, subscriberPublicKey, stepKey);
                        return TemplateCons.shareStepKey(stepConsId, subscribers[index].subscriber, subscriberStepKey);
                    }).then(() => {
                        if (index === subscribers.length - 1) {
                            return;
                        }
                        index++;
                        return shareStepKeys(index);
                    })
                }
                return shareStepKeys(0).then(() => {
                    return templateConsManager.signStep(stepInfo.id, encryptedJsonLd, metaData, txnType);
                }).then(res => {
                    //throw "err";
                    let signedData = Utils.codeSignPaylaod(JSON.parse(res))
                    return GraphQL.getInstance().sendRawTxn(signedData)
                }).catch(err => {
                    //failed status
                    jsonLd = typeof (jsonLd) === 'string' ? JSON.parse(jsonLd) : jsonLd;
                    jsonLd.status = 0;
                    jsonLd = JSON.stringify(jsonLd);
                    let dbInstance = ParamConnector.getInstance().getDB();
                    let templateConsensusDB = dbInstance.templateConsensusDB;
                    templateConsensusDB.updateStepConsensus(stepConsId, jsonLd, metaData, "", subscribers, txnType, "", templateConsId)
                        .then(() => {
                            dbInstance.emitEvent("onStepSigned", templateConsId)
                        }).catch(err => {
                            console.log("error", err);
                        })
                    console.error("Error in sharing stepKey", err);
                })
            }

            return templateConsManager.signStep(stepInfo.id, jsonLd, metaData, txnTypeString).then(res => {
                let signedData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedData)
            })

        })

        // let config;
        // return Utils.getConfig("TemplateConsensusImpl").then(res => {
        //     config = res;
        //     return TemplateCons.getTemplateConsInfo(templateConsId)
        // }).then(consensusData => {
        //     let options = Utils.getNetworkOptions();
        //     let stepSignersInfo = consensusData.stepSignersInfo;
        //     let txnType = consensusData.transactionType;
        //     currentStep = Number(currentStep);
        //     let stepConsId = consensusData['stepConsNumber' + String(currentStep + 1)];
        //     let subscribers = [];
        //     let stepInfo = Utils.getTransactionData(stepConsId);
        //     let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(config.address);
        //     if (txnType === "2") {
        //         let privateKey = Utils.getPrivateKey();
        //         let publicKey = Utils.getPublicKey();
        //         let encryptedJsonLd = ECIES.encrypt(privateKey, publicKey, jsonLd);
        //         let stepKey = ECIES.getReceiptKey(privateKey, encryptedJsonLd);
        //         subscribers = Utils.getArrayOfData(stepSignersInfo[stepConsId].subscribers);
        //         const shareStepKeys = (index) => {
        //             return Utils.getPublic(subscribers[index].subscriber).then(subscriberPublicKey => {
        //                 let subscriberStepKey = ECIES.encrypt(privateKey, subscriberPublicKey, stepKey);
        //                 return TemplateCons.shareStepKey(stepInfo.id, subscribers[index].subscriber, subscriberStepKey);
        //             }).then(() => {
        //                 if (index === subscribers.length - 1) {
        //                     return;
        //                 }
        //                 index++;
        //                 return shareStepKeys(index);
        //             })
        //         }
        //         return shareStepKeys(0).then(() => {
        //             return templateConsManager.signStep(stepInfo.id, encryptedJsonLd, metaData, txnType, options);
        //         }).catch(err => {
        //             console.error("Error in sharing stepKey", err);
        //         })
        //     }
        //     return templateConsManager.signStep(stepInfo.id, jsonLd, metaData, txnType, options);
        // })
    }

    static assignStep(templateConsId, stepToAssign, stepSigner, mergeConsensusId) {
        let templateConsIdArray = Utils.getArrayOfData(templateConsId);
        let promise = Promise.resolve({ templateConsIdArray });
        if (mergeConsensusId) {
            promise = TemplateCons.getAllTemplateConsIdsByMergeId(mergeConsensusId);
        }
        return promise.then(async templateConsIdArray => {
            templateConsIdArray = templateConsIdArray.templateConsensus ? templateConsIdArray.templateConsensus : templateConsIdArray.templateConsIdArray;
            const assignByCurrentStep = (index) => {
                return TemplateCons.assignStepByCurrentStep(templateConsIdArray[index], stepToAssign, stepSigner).then(() => {
                    if (index === templateConsIdArray.length - 1) {
                        return;
                    }
                    index++;
                    return assignByCurrentStep(index);
                })
            }
            return assignByCurrentStep(0);
        })
    }

    static assignStepByCurrentStep(templateConsId, stepToAssign, stepSigner) {
        return TemplateCons.getTemplateConsensusSummary(templateConsId).then(consensusData => {
            stepToAssign = Number(stepToAssign);
            let stepConsId = consensusData['stepConsNumber' + String(stepToAssign + 1)];
            let stepInfo = Utils.getTransactionData(stepConsId);
            let templateConsManager = GraphQL.getInstance().templateConsensus;
            return templateConsManager.assignStep(stepInfo.id, stepSigner);
        }).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // let config;
        // return Utils.getConfig("TemplateConsensusImpl").then(res => {
        //     config = res;
        //     return TemplateCons.getTemplateConsensusSummary(templateConsId)
        // }).then(consensusData => {
        //     let options = Utils.getNetworkOptions();
        //     stepToAssign = Number(stepToAssign);
        //     let stepConsId = consensusData['stepConsNumber' + String(stepToAssign + 1)];
        //     let stepInfo = Utils.getTransactionData(stepConsId);
        //     let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(config.address);
        //     return templateConsManager.assignStep(stepInfo.id, stepSigner, options);
        // })

    }

    static resetTemplateCons(templateConsId, mergeConsensusId) {
        let templateConsIdArray = Utils.getArrayOfData(templateConsId);
        let promise = Promise.resolve({ templateConsIdArray });
        if (mergeConsensusId) {
            promise = TemplateCons.getAllTemplateConsIdsByMergeId(mergeConsensusId);
        }
        return promise.then(async templateConsIdArray => {
            templateConsIdArray = templateConsIdArray.templateConsensus ? templateConsIdArray.templateConsensus : templateConsIdArray.templateConsIdArray;
            const resetCurrentTemplate = (index) => {
                return TemplateCons.resetTemplate(templateConsIdArray[index]).then(() => {
                    if (index === templateConsIdArray.length - 1) {
                        return;
                    }
                    index++;
                    return resetCurrentTemplate(index);
                })
            }
            return resetCurrentTemplate(0);
        })
    }

    static resetTemplate(templateConsId) {
        let callFunction = "resetTemplateCons";
        let callId = Utils.getTransactionData(templateConsId).id;
        let templateConsManager = GraphQL.getInstance().templateConsensus;
        return templateConsManager[callFunction](callId).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("TemplateConsensusImpl").then(config => {
        //     let callFunction = "resetTemplateCons";
        //     let callId = Utils.getTransactionData(templateConsId).id;
        //     let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(config.address);
        //     let options = Utils.getNetworkOptions();
        //     return templateConsManager[callFunction](callId, options);
        // })
    }

    static mergeConsensus(templateConsIds, contractAddress) {
        let templateConsManager = GraphQL.getInstance().templateConsensus;
        return templateConsManager.mergeConsensus(templateConsIds).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("TemplateConsensusImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(contractAddress);
        //     return templateConsManager.mergeConsensus(templateConsIds, options);
        // })
    }

    static shareStepKey(stepConsId, subscriber, stepKey) {
        let templateConsManager = GraphQL.getInstance().templateConsensus;
        return templateConsManager.shareStepKey(stepConsId, subscriber, stepKey).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("TemplateConsensusImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(config.address);
        //     return templateConsManager.shareStepKey(stepConsId, subscriber, stepKey, options);
        // })
    }

    static getTemplateByReceiptId(docId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateConsensusByDocId(docId);
    }

    static getTemplateConsensusFullData(docId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateConsensusFullData(docId);
    }

    static getAllTemplateConsIds(templateId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getAllTemplateConsIds(templateId)
    }

    static getAllTemplateConsIdsByState(templateId, state) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getAllTemplateConsIdsByState(templateId, state);
    }

    static getTemplateConsInfo(templateConsId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateConsensusDetails(templateConsId);
    }

    static getAllTemplateConsIdsByMergeId(mergeId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getAllTemplateConsIdsByMergeId(mergeId);
    }

    static getTemplateConsensusSummary(templateConsId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateConsensusSummary(templateConsId);
    }

    static getAllStepConsByTemplateConsId(templateConsId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getAllStepConsByTemplateConsId(templateConsId)
    }
    static getStepsConsById(templateConsId, stepConsId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getStepsConsById(templateConsId, stepConsId)
    }

    static getStepSubscribers(stepConsId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getStepSubscribers(stepConsId);
    }

    static getSubscriber(sId) {
        return Utils.getConfig("TemplateConsensusImpl").then(config => {
            // let templateConsManager = ParamConnector.getInstance(config).getNetwork().getTemplateConsManager(config.address);
            let templateConsManager = GraphQL.getInstance().templateConsensus;
            return templateConsManager.getSubscriber(sId);
        })
    }

    static getTransactions(templateConsId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTransactions(templateConsId);
    }

    static getTimeline(templateConsId) {
        // TemplateCons.test(templateConsId);
        let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
        let templateObject;
        return TemplateCons.getTemplateConsensusSummary(templateConsId).then(consInfo => {
            templateObject = consInfo;
            return transactionsDB.getTemplateConsTransactions(templateConsId)
        }).then(transactions => {
            transactions.sort((a, b) => (a.blockNumber - b.blockNumber));
            templateObject["transactions"] = transactions;
            return TemplateRepo.getTemplate(templateObject.templateId);
        }).then(_template => {
            templateObject["template"] = _template;
            let currentStep = 0;
            for (let index in templateObject.transactions) {
                let title = TemplateCons.getTitle(templateObject.transactions[index].currentStep, templateObject, templateObject.transactions[index]);
                if (title) {
                    templateObject.transactions[index].title = title.title;
                    templateObject.transactions[index]["wname"] = title.name;
                }
                if (templateObject.transactions[index].event === "onStepSigned") {
                    currentStep = Number(templateObject.transactions[index].currentStep);
                }
                if (templateObject.transactions[index].event === "onTemplateConsensusReset") {
                    currentStep = 0;
                }
                templateObject.transactions[index].status = "1";
                if (title)
                    templateObject.transactions[index]["index"] = title.index;
            }
            let steps = templateObject.template.stepInfos;
            let object;
            steps.sort(function (step1, step2) {
                if (!step1 || !step2) {
                    return 1;
                }
                return parseInt(step1.stepNo) - parseInt(step2.stepNo)
            });
            if (templateObject.isTemplateCompleted === false) {
                templateObject.transactions.push({
                    title: steps[currentStep].step,
                    status: '2',
                    transInfo: "Waiting for step consent",
                    wname: `${steps[currentStep].step}`,
                    index: steps[currentStep].stepNo
                })
            }

            for (let index = currentStep + 1; index < steps.length; index++) {
                templateObject.transactions.push({
                    title: steps[index].step,
                    status: "0",
                    transInfo: "Waiting for complete previous step"
                })
            }
            if (templateObject.isTemplateCompleted === false) {
                object = {
                    title: "Workflow Completed",
                    wname: templateObject.template.title,
                    status: "0",
                    index: 0,
                    transInfo: "Waiting for complete all the steps in the workflow."
                }
                templateObject.transactions.push(object);
            }
            return templateObject.transactions;
        });
    }

    static getInternalTemplateConsensus(templateConsId) {
        let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
        return transactionsDB.getTemplateConsTransactions(templateConsId);
    }

    static getTemplateMetaDataByReceiptid(receiptId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateMetaDataByReceiptid(receiptId);
    }
    static getAttachedTemplateDetailByReceiptId(receiptId) {
        let templateConsensusDB = ParamConnector.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getAttachedTemplateDetailByReceiptId(receiptId);
    }

    static getTitle(id, templateInfo, eventInfo) {
        let event = eventInfo.event;
        let appendText = "Completed";
        if (!id || id === "0") {
            switch (event) {
                case "onTemplateAttached":
                case "onTemplateAttachedForDocIds":
                    return { title: "Workflow Attached", name: templateInfo.template.title + " Attached", index: 0 };
                case "onSubscriberAdded":
                case "onSubscriberAddedForDocIds":
                    return { title: "Subscriber Added", name: templateInfo.template.title + " Attached", index: 0 };
                case "onTemplateCompleted":
                    return { title: "Workflow Completed", name: templateInfo.template.title + "Completed", index: 0 };
                case "onStepAssinged":
                    return { title: 'Step Assigned', name: "Step Assigned", index: 0 };
                case "onTemplateConsensusReset":
                    return { title: 'Template Reset', name: "Template Reset", index: 0 };
                case "onTemplateConsensusMerged":
                    return { title: 'Template Merged', name: "Template Merged", index: 0 };
                default:
                    return;
            }
        }
        if (event === "onTemplateCompleted") {
            return { title: "Workflow Completed", name: templateInfo.template.title + "Completed" };
        }
        let steps = templateInfo.template.stepInfos
        if (!Array.isArray(templateInfo.template.stepInfos)) {
            steps = [templateInfo.template.stepInfos];
        }

        for (let index in steps) {
            if (steps[index].stepNo == id || steps[index]["@id"] === id) {
                return { title: `${steps[index].step} ${appendText}`, name: steps[index].step, index: steps[index].stepNo };
            }
        }
        return null
    }
}

export default TemplateCons;