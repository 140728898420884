import ParserUtils from "../../../../parsers/ParserUtils";
import * as Utils from '../../../../util/utils'
import ThirdPartyUtils from "../../utils/IIIrd-party-utils";
class JsonToJsonLd {

    static getPersonData(personDetails, key, jsonLd) {
        if (!personDetails) {
            jsonLd[key] = {
                "@context": "http://schema.org/",
                "@type": "Person"
            }
            return jsonLd;
        }
        let personJsonLd = {
            "@context": "http://schema.org/",
            "@type": "Person",
            "name": personDetails.TrdNm || personDetails.LglNm,
            "taxId": personDetails.Gstin,
            "address": {
                "@type": "PostalAddress",
                "streetAddress": personDetails.Addr1,
                "addressLocality": personDetails.Loc,
                "addressRegion": personDetails.Stcd,
                "postalCode": personDetails.Pin,
                "state": personDetails.State
            },
        }
        personJsonLd = ParserUtils.getCorrectData(personDetails.Ph, "telephone", personJsonLd);
        personJsonLd = ParserUtils.getCorrectData(personDetails.Em, "email", personJsonLd);
        jsonLd[key] = personJsonLd;
        return jsonLd;
    }

    static getCategory(type, jsonLd) {
        let categoryName;
        switch (type) {
            case "B2B":
                categoryName = "Businees To Business";
                break;
            case "B2G":
                categoryName = "Business To Government";
                break;
            case "EXP":
                categoryName = "Exports";
                break;
            default:
                categoryName = "Businees To Business";
                break;
        }
        let category = {
            "@type": "Thing",
            "identifier": type,
            "name": categoryName
        }
        jsonLd["category"] = category;
        return jsonLd;
    }

    static getInvoiceInfo(irn, qrCode, signedIrn, status, uploaded, lastChecked, addOnsJsonLd) {
        let invoiceInfo = {};
        invoiceInfo = ParserUtils.getCorrectData(irn, "irn", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(qrCode, "qrCode", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(signedIrn, "signedIrn", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(status, "status", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(uploaded, "uploaded", invoiceInfo);
        invoiceInfo = ParserUtils.getCorrectData(lastChecked, "lastChecked", invoiceInfo);
        addOnsJsonLd["invoiceInfo"] = invoiceInfo;
        return addOnsJsonLd;
    }

    static getAddOns(eInvoiceJson, jsonLd) {
        let addOns = {
            "@type": "thing"
        }
        addOns = JsonToJsonLd.getInvoiceInfo(eInvoiceJson.Irn, "", "", eInvoiceJson.Status, "", "", addOns); //Could not find correct key DocStatus
        addOns = ParserUtils.getCorrectData(eInvoiceJson.TranDtls.EcmGstin, "ecomGstin", addOns);
        addOns = ParserUtils.getCorrectData(eInvoiceJson.TranDtls.EcmTrn, "ecomPos", addOns);
        addOns = ParserUtils.getCorrectData(eInvoiceJson.TranDtls.RegRev, "reverseCharge", addOns);
        addOns = ParserUtils.getCorrectData(eInvoiceJson.Irn, "invoiceReferenceNumber", addOns);
        addOns = ParserUtils.getCorrectData(eInvoiceJson.status, "isInvoiceCancelled", addOns); //Could not find correct key
        if (eInvoiceJson.RefDtls) {
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.ProjRef, "projectReference", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.ContrRef, "contractReference", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.ExtRef, "externalReference", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.TendRef, "tenderOfLotReference", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.PrecInvNo, "precedingInvoiceNumber", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.RecAdvRef, "receiptAdviceReference", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.PORef, "venderPOReferenceNumber", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.RefDtls.PORefDate, "venderPOReferenceDate", addOns);
        }
        if (eInvoiceJson.PayDtls) {
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.AcctDet, "payerFinancialAccount", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.CrTrn, "creditTransfer", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.FinInsBr, "financialInstitutionBranch", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.DirDr, "directDebit", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.CrDay, "creditDays", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.PayInstr, "paymentInstruction", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.Terms, "paymentTerms", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.Mode, "modeOfPayment", addOns);
            addOns = ParserUtils.getCorrectData(eInvoiceJson.PayDtls.Nam, "payeeName", addOns);
        }
        jsonLd["addOns"] = addOns;
        return jsonLd;
    }

    static orderedItemAddOn(data) {
        let taxKeys = ["SgstRt:SGST", "CgstRt:CGST", "IgstRt:IGST", "Discount:Discount Amount", "CesRt:Cess Value", "StateCes:State Cess Amt", "OthChrg:Other Charges", "CesNonAdval:Cess Non AdVal"];
        let addOnArray = [];
        for (let index = 0; index < taxKeys.length; index++) {
            let taxNameMapping = taxKeys[index].split(':');
            let price;
            if (data[taxNameMapping[0]]) {
                price = parseInt(data[taxNameMapping[0]]);
            } else if (data["taxKeys"]) {
                price = data["taxKeys"][taxNameMapping[0]] ? data["taxKeys"][taxNameMapping[0]] : 0;
            }
            let addOnObj = {
                "@type": "Offer",
                "price": price,
                "description": "%",
                "name": taxNameMapping[1]
            }
            addOnArray.push(addOnObj);
        }
        return addOnArray;
    }

    static addOffers(data, unitprice, quantity) {
        let offer = {};
        offer = ParserUtils.getCorrectData(data.currency, "priceCurrency", offer);
        offer = ParserUtils.getCorrectData(Utils.getPrice(parseFloat(unitprice)), "price", offer);
        offer = ParserUtils.getCorrectData(data.availability, "availability", offer);
        offer["@type"] = "Offer";
        offer["addOn"] = JsonToJsonLd.orderedItemAddOn(data);
        return offer;
    }

    static getOrderedItem(item, referencesOrder) {
        let orderItem = [];
        for (let index = 0; index < item.length; index++) {
            let orderedItemObj = {}, orderObj = {};
            orderObj["@type"] = "Product";
            orderObj["slno"] = item[index].Slno;
            orderObj["isService"] = item[index].IsServc;
            orderObj["name"] = item[index].PrdNm;
            orderObj["hsnCode"] = item[index].HsnCd;
            orderObj["offers"] = JsonToJsonLd.addOffers(item[index], item[index].UnitPrice, item[index].Qty);
            orderObj["unit"] = item[index].Unit;
            orderedItemObj["@type"] = "OrderItem";
            orderedItemObj["orderQuantity"] = item[index].Qty;
            orderedItemObj["orderedItem"] = orderObj;
            orderItem.push(orderedItemObj);
        }
        referencesOrder["orderedItem"] = orderItem;
        return referencesOrder;
    }

    static getReferencesOrder(itemList, date, jsonLd) {
        let referencesOrder = {
            "@type": "Order",
            "orderDate": date
        }
        referencesOrder = JsonToJsonLd.getOrderedItem(itemList, referencesOrder);
        jsonLd["referencesOrder"] = referencesOrder;
        return jsonLd;
    }

    static paymentDue(paymentPrice, paymentCurrency, key, jsonLd) {
        let paymentDue = { "@type": "PriceSpecification" };
        paymentDue = ParserUtils.getCorrectData(paymentPrice, 'price', paymentDue);
        paymentDue = ParserUtils.getCorrectData(paymentCurrency, 'priceCurrency', paymentDue);
        jsonLd[key] = paymentDue;
        return jsonLd;
    }

    static convert(eInvoiceJson) {
        let jsonLd = {
            "@context": "http://schema.org/",
            "@type": "Invoice",
            "invoiceDate": eInvoiceJson.DocDtls.Dt,
            "identifier": eInvoiceJson.DocDtls.Typ,
            "@id": eInvoiceJson.DocDtls.No
        }
        jsonLd = JsonToJsonLd.getPersonData(eInvoiceJson.SellerDtls, "provider", jsonLd);
        jsonLd = JsonToJsonLd.getPersonData(eInvoiceJson.BuyerDtls, "customer", jsonLd);
        jsonLd = JsonToJsonLd.getPersonData(eInvoiceJson.DispDtls, "consignor", jsonLd);
        jsonLd = JsonToJsonLd.getPersonData(eInvoiceJson.ShipDtls, "consignee", jsonLd);
        jsonLd = JsonToJsonLd.getCategory(eInvoiceJson.TranDtls.SupTyp, jsonLd);
        jsonLd = JsonToJsonLd.getAddOns(eInvoiceJson, jsonLd);
        jsonLd = JsonToJsonLd.getReferencesOrder(eInvoiceJson.ItemList, eInvoiceJson.DocDtls.Dt, jsonLd);
        let { erpTaxInvoiceInfo, total } = ThirdPartyUtils.getReceiptInvoiceSum(jsonLd);
        jsonLd = JsonToJsonLd.paymentDue(total, "INR", "minimumPaymentDue", jsonLd);
        jsonLd = JsonToJsonLd.paymentDue(total, "INR", "totalPaymentDue", jsonLd);
        jsonLd.addOns["erpTaxInvoiceInfo"] = erpTaxInvoiceInfo;
        console.log("JSONLD===>>>>>>>>>>", jsonLd);
        return jsonLd;
    }
}

export default JsonToJsonLd;