import * as Utils from '../../../util/utils';

class Auth {
    static getClientId() {
        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        return irpConfig.clientId;
    }

    static getClientSecKey() {
        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        return irpConfig.clientSecretKey;
    }

    static getClientObject() {
        return {
            "client_id": Auth.getClientId(),
            "client_secret": Auth.getClientSecKey(),
            "Content-Type": "application/json",
            // "Accept": "application/json",
        }
    }

    static getDateTimeInMilliSeconds(dateString) {
        return new Date(dateString).getTime();
    }

    static save(authObj) {
        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        Utils.setLocalStorage("irpConfig", {...irpConfig, 'userName': authObj.UserName, 'clientId': authObj.ClientId});
        Utils.setLocalStorage("authToken", authObj.AuthToken);
        Utils.setLocalStorage("sek", authObj.Sek);
        Utils.setLocalStorage("tokenExpiry", Auth.getDateTimeInMilliSeconds(authObj.TokenExpiry));
    }

    static getTaxNo() {
        let profile = JSON.parse(Utils.getFromLocalStorage('profile'));
        return profile.taxId;
    }

    static getFullClientObject() {
        let clientObj = Auth.getClientObject();
        const irpConfig = Utils.getFromLocalStorage("irpConfig");
        clientObj['Gstin'] = Auth.getTaxNo();
        clientObj['user_name'] = irpConfig.userName;
        clientObj['AuthToken'] = Utils.getFromLocalStorage("authToken");
        return clientObj;
    }

    static isSessionExpired() {
        let currentDateTime = new Date().getTime();
        let tokenExpiry = Number(Utils.getFromLocalStorage("tokenExpiry"));
        return !Auth.getSek() || !Auth.getAuthToken() || Auth.getAuthToken().length === 0 || Auth.getSek().length === 0 || currentDateTime > tokenExpiry;
    }

    static getUserName() {
        const irpConfig = Utils.getFromLocalStorage("irpConfig");
        return irpConfig.userName;
    }

    static getAuthToken() {
        return Utils.getFromLocalStorage("AuthToken");;
    }

    static getPassword() {
        const irpConfig = Utils.getFromLocalStorage("irpConfig");
        return irpConfig.password;
    }

    static getSek() {
        return Utils.getFromLocalStorage("sek");
    }

    static setSek(sek) {
        Utils.setLocalStorage("sek", sek);
    }

    static setAuthToken(token) {
        Utils.setLocalStorage("AuthToken", token);
    }
}
export default Auth;