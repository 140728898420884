import Web3Utils from 'web3-utils';
class Events {
    constructor() {
        this.keyMap = new Map();
    }
    on(key, callback) {
        this.keyMap.set(key, callback);
        return this;
    }
    call(key, ...args) {
        if (!this.keyMap.has(key)) {
            return
        }
        if (args && Array.isArray(args) && args.length) {
            args = args[0];
        }
        
        args = this.convert(args);

        this.keyMap.get(key)(args);
    }
    
    convert(args){
        if(!args){
            return null;
        }
        for (let eventsIndex in args) {
            if (Web3Utils.isAddress(args[eventsIndex])) {
                args[eventsIndex] = Web3Utils.toChecksumAddress(args[eventsIndex])
            }

            if (Web3Utils.isBN(args[eventsIndex]) 
            || Web3Utils.isBigNumber(args[eventsIndex])) {
                args[eventsIndex] = args[eventsIndex].toString();
            }
        }
        return args
    }
    unsubscribe(){

    }
}
export default Events;