class Progress{
    static ON_FILE_VALIDATION = 1;
    static ON_FILE_LOADED = 5;

    static ON_FILE_PARSING = 10;

    // static ON_COMPLETED = 100;

    static ON_FILE_COMPLETED = 110;


    static getFileProgress(percentage){
        let onePer = (100+Progress.ON_FILE_PARSING) / 100;
        return onePer * percentage;
    }
    static getTotalPer(filePer){
        
    }
    // static ON_FILE_VALIDATION = 1;
    // static ON_FILE_VALIDATION = 1;
    // static ON_FILE_VALIDATION = 1;
    // static ON_FILE_VALIDATION = 1;
    // static ON_FILE_VALIDATION = 1;
    // static ON_FILE_VALIDATION = 1;
    // static ON_FILE_VALIDATION = 1;
    // static ON_FILE_VALIDATION = 1;
}
export default Progress;