import IRNProvider from "./india";

class InvoiceProvider {
    constructor(){
        this.map = new Map();
    }
    static getInvoiceProvider(){
        if(!InvoiceProvider.instance){
            InvoiceProvider.instance = new InvoiceProvider();
        }
        return InvoiceProvider.instance;
    }

    getInvoiceManager(config){
        if(!config || !config.provider){
            config = {"provider":"IND"};
        }
        if(!this.map[config.provider]){
            this.map[config.provider] = new IRNProvider();
        }
        return this.map[config.provider];
    }
}
export default InvoiceProvider;