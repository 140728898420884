import * as Utils from '../../util/utils';

class ProxyUtils {
    static getProxyObject() {
        let proxyObject = Utils.getFromLocalStorage('proxySettings');
        if (!proxyObject) {
            return null;
        }
        proxyObject = JSON.parse(proxyObject);
        if(!proxyObject["paramProxyIP"] || !proxyObject["paramProxyPort"]) {
            return null;
        }
        return proxyObject;
    }
    static isProxySet(){
        let proxyObject = ProxyUtils.getProxyObject();
        return (!proxyObject)?false:proxyObject;

    }
    static getProxyBaseURL(url){
        let proxyObject = ProxyUtils.isProxySet()
        if(!proxyObject || !proxyObject.paramProxyIP ||!proxyObject.paramProxyPort){
            return url
        }
        let host = proxyObject.paramProxyIP.startsWith("http")?proxyObject.paramProxyIP:"http://"+proxyObject.paramProxyIP
        let port = proxyObject["paramProxyPort"]?proxyObject["paramProxyPort"]:"80"
        return host+":"+port
    }

    static getAxiosHeaders(headers, baseURL, relURL){
        let proxyObject = ProxyUtils.isProxySet()
        if(!proxyObject){
            return headers;
        }
        if(!headers){
            headers = {}
        }
        if (proxyObject && proxyObject.proxyUserName) {
            let basicAuth = `${proxyObject.proxyUserName}:${proxyObject.password?proxyObject.proxyUserName:""}`;
            
            headers["proxyauthorization"] = `Basic ${btoa(basicAuth)}`
            // return headers;
        }
        // headers["Host"] = proxyObject.applicationProxyIP+":"+proxyObject.applicationProxyPort;

        if(baseURL && !baseURL.endsWith("/")){
            baseURL += "/"
        }
        if(relURL && relURL.startsWith("/")){
            relURL = relURL.substr(1);
        }
        if(relURL){
            baseURL += relURL
        }
        headers["host-url"] = baseURL;
        return headers;
    }

    static getRelURL(url){
        let proxyObject = ProxyUtils.isProxySet()
        if(!proxyObject || !proxyObject.paramProxyIP ||!proxyObject.paramProxyPort){
            return url
        }
        return "/"
    }
}

export default ProxyUtils;