import ECIES from '../../../../src/util/ecies';
import * as NetworkUtils from '../../../../src/util/utils';
import Console from '../../../logger';
import NetworkBridge from '../../../../src/util/network-bridge';
import * as DBUtils from '../../../database/nosql/Utils/utils';
import ParamConnector from '../../../../src/param-connector';
import GraphQL from '..';

class RestoreGrn {
    static restoreGrn(grnId, contractAddress) {
        let grnDBInstance = ParamConnector.getInstance().getDB().grn, docId, grnDetails, isParticipant = true;
        let selfParamId = NetworkUtils.getParamId();

        let receiptManager = GraphQL.getInstance().receipts;
        let privateKey = NetworkUtils.getFromLocalStorage("privateKey");
        let receiptDetails;
        grnId = NetworkUtils.getTransactionId(grnId, contractAddress);

        return receiptManager.getDocument(grnId).then(grnDataJson => {
            grnDetails = grnDataJson;
            docId = grnDetails.rId // NetworkUtils.getTransactionId(grnDetails.rId, contractAddress);

            return receiptManager.getReceipt(docId) //grnDetails.rId
        }).then(res => {
            receiptDetails = res;
            let promise = Promise.resolve(grnDetails);
            if (grnDetails.txnMode === "2") {
                if (receiptDetails.sellerId === selfParamId || receiptDetails.buyerId === selfParamId) {
                    grnDetails.jsonLd = ECIES.decrypt(privateKey, grnDetails.jsonLd);
                }
                else if (grnDetails.owner === selfParamId) {
                    //GRN Manager
                    promise = NetworkBridge.getReceiptManager().getReceiptRootIdFromNetwork(docId, receiptManager).then(rootId => { //grnDetails.rId
                        return receiptManager.getSubscriberDetailsForId(selfParamId, rootId);
                    }).then(subscriberPayload => {
                        const randomStr = ECIES.decrypt(privateKey, subscriberPayload['payload']);
                        grnDetails.jsonLd = ECIES.decrypt(randomStr, grnDetails.jsonLd);
                        return grnDetails;
                    })
                }
                else {
                    isParticipant = false;
                }
            }
            return Promise.all([grnDBInstance.doesExistByDocId(docId, grnId), promise]);
        }).then(([doesExist, grnDetails]) => {
            if (!isParticipant) {
                return { buyerId: receiptDetails.buyerId, docId: docId };
            }
            if (doesExist) {
                if (grnDetails.docStatus === "1") {
                    return grnDBInstance.updateStatus(grnId, grnDetails.docStatus);
                }
                return grnDBInstance.updateGrn(grnId, docId, grnDetails.jsonLd, grnDetails.docStatus, grnDetails.owner, grnDetails.buyerId);
            }
            return grnDBInstance.addGrn(grnId, docId, grnDetails.jsonLd, grnDetails.docStatus, grnDetails.owner, grnDetails.buyerId);
        }).then(() => {
            Console.log(`Inserted GRN ${grnId}`)
            return { buyerId: receiptDetails.buyerId, docId: docId };
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring grns [Reason] ${e} [Module] : Sync/grn/RestoreGrn`);
        });
    }

    static restoreAllGrn(contractAddress, docId, callback, benchmarkRound, batchSize = 10) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        let receiptManager = GraphQL.getInstance().receipts
        let _docId = NetworkUtils.getTransactionId(docId, contractAddress)
        return receiptManager.getAllGRNsByDocId(_docId).then(grnIds => {
            Console.log(`[GraphDB] Got grnIDs from ${contractAddress} metainfo from network.`);
            let grnPromises = [];
            for (let index = 0; index < grnIds.length; index++) {
                let grnId = grnIds[index].toString();
                let grnPromise = RestoreGrn.restoreGrn(grnId, contractAddress);
                grnPromises.push(grnPromise);
            }
            return Promise.all(grnPromises);
        })
    }

    static _restoreAllGrnsAsBatch() {

    }

    static restoreAcceptNote(acceptNoteId, contractAddress, acceptNoteType) {
        let acceptNoteBDInstance = ParamConnector.getInstance().getDB().acceptNote;
        let receiptManager = GraphQL.getInstance().receipts
        let docId;
        let dbAcceptNoteId = NetworkUtils.getTransactionId(acceptNoteId, contractAddress);
        return receiptManager.getDocument(dbAcceptNoteId).then(acceptNote => {
            let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
            if (acceptNote.txnMode === "2") {
                acceptNote.jsonLd = ECIES.decrypt(privateKey, acceptNote.jsonLd);
            }
            docId = acceptNote.rId //NetworkUtils.getTransactionId(acceptNote.rId, contractAddress);
            return acceptNoteBDInstance.attachAcceptNote(dbAcceptNoteId, docId, acceptNote.jsonLd, acceptNote.docStatus, acceptNote.owner);
        }).then(() => {
            return { docId: docId }
        })
    }
    static restoreAllAcceptNotes(contractAddress, docId, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        return ParamConnector.getInstance().getNetwork().getReceiptManager(contractAddress).getAllANsByDocId(docId).then(grnIds => {
            Console.log(`[GraphDB] Got grnIDs from ${contractAddress} metainfo from network.`);
            let grnPromises = [];
            for (let index = 0; index < grnIds.length; index++) {
                let grnId = grnIds[index].toString();
                let grnPromise = RestoreGrn.restoreAcceptNote(grnId, contractAddress);
                grnPromises.push(grnPromise);
            }
            return Promise.all(grnPromises);
        });
    }
    static restoreeLR(eLRId, contractAddress, acceptNoteType) {
        let acceptNoteBDInstance = ParamConnector.getInstance().getDB().acceptNote;
        let receiptManager = GraphQL.getInstance().receipts
        let docId;
        let dbAcceptNoteId = NetworkUtils.getTransactionId(eLRId, contractAddress);
        return receiptManager.getDocument(dbAcceptNoteId).then(acceptNote => {
            let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
            if (acceptNote.txnMode === "2") {
                acceptNote.jsonLd = ECIES.decrypt(privateKey, acceptNote.jsonLd);
            }
            docId = acceptNote.rId //NetworkUtils.getTransactionId(acceptNote.rId, contractAddress);
            console.log("ELR>>>>", acceptNote);
            setTimeout(() => { acceptNoteBDInstance.attachAcceptNote(dbAcceptNoteId, docId, acceptNote.jsonLd, acceptNote.docStatus, acceptNote.owner); }, 5000)
        }).then(() => {
            return { docId: docId }
        })
    }

    static restoreCreditNote(creditNoteId, contractAddress, acceptNoteType) {
        let acceptNoteBDInstance = ParamConnector.getInstance().getDB().acceptNote;
        let receiptManager = GraphQL.getInstance().receipts
        let docId;
        let dbAcceptNoteId = NetworkUtils.getTransactionId(creditNoteId, contractAddress);
        return receiptManager.getDocument(dbAcceptNoteId).then(acceptNote => {
            let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
            if (acceptNote.txnMode === "2") {
                acceptNote.jsonLd = ECIES.decrypt(privateKey, acceptNote.jsonLd);
            }
            docId = acceptNote.rId //NetworkUtils.getTransactionId(acceptNote.rId, contractAddress);
            return acceptNoteBDInstance.attachAcceptNote(dbAcceptNoteId, docId, acceptNote.jsonLd, acceptNote.docStatus, acceptNote.owner);
        }).then(() => {
            return { docId: docId }
        })
    }

    static restorePartPO(partPOId, contractAddress, partPOType) {
        let dbInstance = ParamConnector.getInstance().getDB();
        let receiptManager = GraphQL.getInstance().receipts
        let docId;
        let dbPartPOId = NetworkUtils.getTransactionId(partPOId, contractAddress);
        return receiptManager.getDocument(dbPartPOId).then(partPurchaseOrder => {
            let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
            if (partPurchaseOrder.txnMode === "2") {
                partPurchaseOrder.jsonLd = ECIES.decrypt(privateKey, partPurchaseOrder.jsonLd);
            }
            docId = partPurchaseOrder.rId //NetworkUtils.getTransactionId(partPurchaseOrder.rId, contractAddress);
            return Promise.all([
                dbInstance.partPurchaseOrder.attachPartPO(dbPartPOId, docId, partPurchaseOrder.jsonLd, partPurchaseOrder.docStatus, partPurchaseOrder.owner),
                dbInstance.receipts.updateMasterPOForPartPO(partPOId, docId, partPurchaseOrder.jsonLd)
            ])
        }).then(() => {
            return { docId: docId }
        })
    }

    // static restoreAllGrnEvents(contractAddress, docId, owner, callback) {
    //     debugger;
    //     if (callback && callback.onProgressText) {
    //         callback.onProgressText("receipt", `Trying to get all the events from ${contractAddress}`);
    //     }
    //     let receiptManager = ParamConnector.getInstance().getNetwork().getReceiptManager(contractAddress);
    //     return receiptManager[typeFunction](owner).then(receiptIds => {
    //         let promiseArray = [];
    //         for (let receiptIndex in receiptIds) {
    //             let transactionsDB = GraphDB.getInstance().transactionsDB;
    //             promiseArray.push(receiptManager.getAllEventsByReceiptId(receiptIds[receiptIndex])
    //                 .then(receiptEvents => {
    //                     return RestoreReceipts.restoreReceiptIdEvents(transactionsDB, contractAddress, receiptEvents)
    //                 }))
    //         }
    //         return Promise.all(promiseArray);
    //     })
    // }
    // static restoreReceiptIdEvents(transactionsDB, contractAddress, receiptEvents) {
    //     let addTransactionsPromiseArray = [];
    //     for (let index in receiptEvents) {
    //         let event = receiptEvents[index];
    //         let label = ReceiptEvents[event.event].label;
    //         event.args.rId = NetworkUtils.getTransactionId(event.args.rId, contractAddress);
    //         let metaData = NetworkDataFormat.getMetaInfo(event.args.rId, event, label);
    //         metaData.step = event.args.step;
    //         metaData.buyer = event.args.buyer;
    //         metaData.status = event.args.status
    //         metaData.seller = event.args.seller;
    //         let owner = event.args.owner;
    //         let transactionPromise = transactionsDB.addTransaction(event.args.rId, owner, metaData).catch(error => {
    //             Console.error(`Unable to restore catalogue event for ${event.args.catalogueId}, Reason: ${error}`)
    //         })
    //         addTransactionsPromiseArray.push(transactionPromise);
    //     }
    //     return Promise.all(addTransactionsPromiseArray);
    // }

    // static restoreAllContactEvents(contractAddress, owner, callback) {
    //     if (callback && callback.onProgressText) {
    //         callback.onProgressText("contact", `Trying to get all the events from ${contractAddress}`);
    //     }
    //     let contactManager = ParamConnector.getInstance().getNetwork().getContactManager(contractAddress);
    //     return contactManager.getAllContacts(owner).then(contactIds => {
    //         let promiseArray = [];
    //         let transactionsDB = GraphDB.getInstance().transactionsDB;
    //         let selfParamId = NetworkUtils.getParamId();
    //         for (let contactIndex in contactIds) {
    //             promiseArray.push(contactManager.getAllEventsByContactId(contactIds[contactIndex]).then(contactEvents => {
    //                 return RestoreGrn.restoreContactIdEvents(transactionsDB, selfParamId, contractAddress, contactEvents);
    //             }))
    //         }
    //         return Promise.all(promiseArray);
    //     })
    // }
    // static restoreContactIdEvents(transactionsDB, selfParamId, contractAddress, contactEvents) {
    //     let addTransactionsPromiseArray = [];
    //     for (let index in contactEvents) {
    //         let event = contactEvents[index];
    //         let label = ContactEvents[event.event].label;
    //         event.args.contactId = NetworkUtils.getTransactionId(event.args.contactId, contractAddress);
    //         let metaData = NetworkDataFormat.getMetaInfo(event.args.contactId, event, label);
    //         let owner = event.args.owner;
    //         if (!owner) {
    //             if (selfParamId === event.args.sender || selfParamId === event.args.receiver) {
    //                 owner = selfParamId;
    //             }
    //         }
    //         let transactionPromise = transactionsDB.addTransaction(event.args.contactId, owner, metaData).catch(error => {
    //             Console.error(`Unable to restore contact event for ${event.args.contactId}, Reason: ${error}`)
    //         })
    //         addTransactionsPromiseArray.push(transactionPromise);
    //     }
    //     return Promise.all(addTransactionsPromiseArray);
    // }
}
export default RestoreGrn;