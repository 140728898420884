import React, { Component } from "react";
import { Form, Input, Radio, Select, Divider } from 'antd';
import { ModalFormFields } from '../../../constants/FormFields';
import { stepFormFields } from '../../../constants/AppConstants';

const { Option } = Select;
const { TextArea } = Input;

class CreateModalForm extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formItem: []
        }

    }
    getModalFormValues = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                return values;
            }
            return false;
        })
    }

    addFormItems = () => {
        const { form } = this.props;
        const { getFieldValue } = form;
        let formFields = getFieldValue('formFields');
        let initialFormField = stepFormFields.slice();
        let addedIndex = parseInt(formFields.length / initialFormField.length);
        formFields = [...formFields, ...initialFormField];
        form.setFieldsValue({
            formFields,
            addedIndex
        })
    }

    handleFileInputChange = (e, formField) => {
        const { form } = this.props;
        form.setFieldsValue({
            [formField.id]: e.target.files[0]
        })
    }

    handleInputFieldChange = (e, formField) => {
        switch (formField.dataType.input) {
            case "Input":
                return e.target.value;
            case "TextArea":
                return e.target.value;
            case "Radio":
                return e.target.value;//to be tested;
            case "Select":
                return e;//to be tested;
            default:
                return e.target.value;
        }
    }

    getInputField = (formField) => {
        let dataType = formField.dataType;
        const { readOnly } = this.props;
        switch (dataType.input) {
            case "Input":
                return <Input type={dataType.type} disabled={readOnly || formField.disabled} />;
            case "TextArea":
                return <TextArea rows={dataType.rows} disabled={readOnly || formField.disabled} />;
            case "Radio":
                let radioGroupButton = [];
                for (let index in dataType.options) {
                    radioGroupButton.push(
                        <Radio.Button value={dataType.options[index].key}>{dataType.options[index].label}</Radio.Button>
                    )
                }
                return <Radio.Group disabled={readOnly || formField.disabled} >{radioGroupButton}</Radio.Group>
            case "Select":
                let options = [];
                for (let index in dataType.options) {
                    options.push(<Option value={dataType.options[index].key}>{dataType.options[index].label}</Option>)
                }
                return <Select style={{ width: 273 }}>
                    {options}
                </Select>;
            default:
                return <Input type={dataType.type} disabled={readOnly || formField.disabled} />;
        }
    }

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                md: { span: 8 },
                lg: { span: 6 },
                xl: { span: 7 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 20 },
                md: { span: 12 },
                lg: { span: 14 },
                xl: { span: 14 },
                sm: { span: 14 },
            },
        };
        let { modalFormData, formName, formFields, form } = this.props;
        const { getFieldValue, getFieldDecorator } = form;

        formFields = formFields || ModalFormFields[formName];
        getFieldDecorator('formFields', { initialValue: ModalFormFields[formName] });
        getFieldDecorator('addedIndex', { initialValue: 0 });
        formFields = getFieldValue('formFields') || formFields;
        const formItems = formFields.map((formField, index) => {
            if (formField.label === "_divider") {
                return <Divider dashed={true} style={{ margin: '2em 0' }} />
            }
            return <Form.Item label={formField.label} style={{ marginRight: 0, marginLeft: 0 }}>
                {getFieldDecorator(formField.id, {
                    rules: [{
                        required: formField.required,
                        message: `${formField.label} is required!`
                    }],
                    // getValueFromEvent: (e) => this.handleInputFieldChange(e, formField),
                    initialValue: modalFormData && modalFormData[formField.id]
                })(this.getInputField(formField))
                }
            </Form.Item>

        })
        return (
            <Form ref={this.formRef} {...formItemLayout}>
                {formItems}
            </Form>
        )
    }
}

export default CreateModalForm;