import Database from '../../index'
import * as NetworkUtils from '../../../util/utils';
import Console from '../../../logger/index';
import RestoreItems from '../sync/items';
class InventoryDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerOnInventoryHistoryUpdated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnInventoryHistoryUpdated", callback);
    }

    unRegisterOnInventoryHistoryUpdated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnInventoryHistoryUpdated", callback);
    }

    registerOnInventoryUpdated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnInventoryUpdated", callback);
    }

    unRegisterOnInventoryUpdated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnInventoryUpdated", callback);
    }

    registerOnInventoryUpdateForSubscriber(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnInventoryUpdateForSubscriber", callback);
    }
    unRegisterOnInventoryUpdateForSubscriber(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.removeListener("OnInventoryUpdateForSubscriber", callback);

    }
    _onInventoryUpdated(error, dataJSON) {
        if (error)
            return;
        let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.catalogueContractAddress);
        let oldInventoryData;
        Console.log(`[GraphDB] Got Inventory event for ${itemId}`);
        return this.dbInstance.items.getInventoryByItemId(itemId).then(res => {
            oldInventoryData = res;
            return RestoreItems.restoreInventory(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, dataJSON.args.catalogueContractAddress);
        }).then(() => {
            Console.log(`[GraphDB] Added inventory info to graph ${itemId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnInventoryUpdated", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add inventory ${e.toString()}`);
        })
            .finally(() => {
                return this.dbInstance.items.getSharedKey(itemId).then(res => {
                    return RestoreItems.restoreInventoryUpdateEvent(dataJSON, oldInventoryData, this.dbInstance.transactionsDB, res);
                })
            })
    }
    _onInventoryHistoryUpdated(error, dataJSON) {
        if (error)
            return;
        let inventoryId = NetworkUtils.getTransactionId(dataJSON.args.inventoryId, dataJSON.args.contractAddress);
        return RestoreItems.restoreInventoryUpdatableHistory(dataJSON.args.contractAddress, dataJSON.args.inventoryId, dataJSON.args.historyIndex, dataJSON.args.metaData).then(() => {
            Console.log(`[GraphDB] Updated inventroy history ${inventoryId}`);
            this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnInventoryHistoryUpdated", inventoryId, null);
        }).catch(e => {
            Console.error(`Unable to Updated inventroy history  ${e.toString()}`);
        })
    }
    _onInventoryUpdateForSubscriber(error, dataJSON) {
        if (error)
            return;
        let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress);
        Console.log(`[GraphDB] Got InventorySubscriber event for ${itemId}`);
        return RestoreItems.restoreInventory(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, dataJSON.args.catalogueContractAddress, true).then(() => {
            Console.log(`[GraphDB] Added inventory info to graph ${itemId}`);
            this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnInventoryUpdateForSubscriber", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add inventory ${e.toString()}`);
        })
    }
}

export default InventoryDBEvents;