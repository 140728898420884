import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import { TemplateRepoEvents } from '../utils/event-names';
import BlockInfo from '../utils/block-info';

class TemplateRepoManager {

    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const templateRepoManager = require('./template-repository-manager.json');

        this.paramNetwork = _paramNetwork;
        this.contractAddress = contractAddress;
        this.templateRepositoryManager = Web3_1_0.getContract(this.connection, templateRepoManager.abi, contractAddress ? contractAddress : templateRepoManager.address);
        this.to = contractAddress;
        // this.templateRepositoryManager = new this.connection.eth.Contract(templateRepoManager.abi, contractAddress);
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        if (options) {
            options = {
                owner: options.address
            };
        }

        this.watchTemplateAddEvent(options);
        this.watchEditTemplateEvent(options);
        this.watchUpdateTemplateStepEvent(options);
        this.watchAddStepEvent(options);
        this.watchEditStepEvent(options);
        this.watchShareTemplateEvent(options);
    }

    watchTemplateAddEvent(options) {
        if (typeof this.templateAddEvent === 'undefined' || !this.templateAddEvent) {
            let filter = {
                filter: options
            };
            // this.templateAddEvent = this.templateRepositoryManager.events.onTemplateAdded(filter);
            this.templateAddEvent = Web3_1_0.getEvent(this, this.templateRepositoryManager, "onTemplateAdded", filter);
        }
        let that = this;
        this.templateAddEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onTemplateAdded", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onTemplateAdded", error);
                that.templateAddEvent.unsubscribe();
                that.templateAddEvent = undefined;
                that.watchTemplateAddEvent(options);
            });
    }

    watchEditTemplateEvent(options) {
        if (typeof this.templateEditEvent === 'undefined' || !this.templateEditEvent) {
            let filter = {
                filter: options
            };
            // this.templateEditEvent = this.templateRepositoryManager.events.onTemplateEdited(filter);
            this.templateEditEvent = Web3_1_0.getEvent(this, this.templateRepositoryManager, "onTemplateEdited", filter);
        }
        let that = this;
        this.templateEditEvent.on('data', function (event) {
            that.emitEvent("onTemplateEdited", event);
        })
            .on('error', function (error) {
                that.events.emit("onTemplateEdited", error);
                that.templateEditEvent.unsubscribe();
                that.templateEditEvent = undefined;
                that.watchEditTemplateEvent(options);
            });
    }

    watchUpdateTemplateStepEvent(options) {
        if (typeof this.templateUpdateEvent === 'undefined' || !this.templateUpdateEvent) {
            let filter = {
                filter: options
            };
            // this.templateUpdateEvent = this.templateRepositoryManager.events.onTemplateStepUpdated(filter);
            this.templateUpdateEvent = Web3_1_0.getEvent(this, this.templateRepositoryManager, "onTemplateStepUpdated", filter);
        }
        let that = this;
        this.templateUpdateEvent.on('data', function (event) {
            that.emitEvent("onTemplateStepUpdated", event);
        })
            .on('error', function (error) {
                that.events.emit("onTemplateStepUpdated", error);
                that.templateUpdateEvent.unsubscribe();
                that.templateUpdateEvent = undefined;
                that.watchUpdateTemplateStepEvent(options);
            });
    }

    watchAddStepEvent(options) {
        if (typeof this.stepAddEvent === 'undefined' || !this.stepAddEvent) {
            let filter = {
                filter: options
            };
            // this.stepAddEvent = this.templateRepositoryManager.events.onStepAdded(filter);
            this.stepAddEvent = Web3_1_0.getEvent(this, this.templateRepositoryManager, "onStepAdded", filter);
        }
        let that = this;
        this.stepAddEvent.on('data', function (event) {
            that.emitEvent("onStepAdded", event);
        })
            .on('error', function (error) {
                that.events.emit("onStepAdded", error);
                that.stepAddEvent.unsubscribe();
                that.stepAddEvent = undefined;
                that.watchAddStepEvent(options);
            });
    }

    watchEditStepEvent(options) {
        if (typeof this.stepEditEvent === 'undefined' || !this.stepEditEvent) {
            let filter = {
                filter: options
            };
            // this.stepEditEvent = this.templateRepositoryManager.events.onStepEdited(filter);
            this.stepEditEvent = Web3_1_0.getEvent(this, this.templateRepositoryManager, "onStepEdited", filter);
        }
        let that = this;
        this.stepEditEvent.on('data', function (event) {
            that.emitEvent("onStepEdited", event);
        })
            .on('error', function (error) {
                that.events.emit("onStepEdited", error);
                that.stepEditEvent.unsubscribe();
                that.stepEditEvent = undefined;
                that.watchEditStepEvent(options);
            });
    }

    watchShareTemplateEvent(options) {
        if (typeof this.templateShareEvent === 'undefined' || !this.templateShareEvent) {
            let filter = {
                filter: options
            };
            // this.templateShareEvent = this.templateRepositoryManager.events.onTemplateShared();
            this.templateShareEvent = Web3_1_0.getEvent(this, this.templateRepositoryManager, "onTemplateShared", filter);
        }
        let that = this;
        this.templateShareEvent.on('data', function (event) {
            let subscribers = event.returnValues.subscribers;
            for (let index in subscribers) {
                if (subscribers[index] === options.owner || options.owner === event.returnValues.owner) {
                    that.emitEvent("onTemplateShared", event);
                    break;
                }
            }
        })
            .on('error', function (error) {
                that.events.emit("onTemplateShared", error);
                that.templateShareEvent.unsubscribe();
                that.templateShareEvent = undefined;
                that.watchShareTemplateEvent(options);
            });
    }

    createTemplate(name, title, stepIds, metaData, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "createTemplate", options, name, title, stepIds, metaData);
    }

    createTemplateSync(name, title, stepIds, metaData, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "createTemplate", options, name, title, stepIds, metaData).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })
    }

    editTemplate(templateId, name, title, metaData, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "editTemplate", options, templateId, name, title, metaData);
    }

    editTemplate(templateId, name, title, metaData, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "editTemplate", options, templateId, name, title, metaData).then(txHash => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then(logs => {
            return templateId;
        });
    }

    createStep(stepJson, metaData, options) {
        const that = this;
        return Web3_1_0.send(this, this.templateRepositoryManager, "createStep", options, stepJson, metaData).then(data => {
            return that._getStepIdForTxHash(data);
        });
    }

    createStepSync(stepJson, metaData, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "createStep", options, stepJson, metaData).then(txHash => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then(logs => {
            if (logs.length == 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        });
    }

    _getStepIdForTxHash(txHash) {
        let noOfRetry = 0;
        return new Promise((resolve, reject) => {
            let that = this;
            setTimeout(() => {
                
                that.connection.eth.getTransactionReceipt(txHash, (err, data) => {
                    noOfRetry++
                    if (err) {
                        reject(err)
                    }
                    if (data) {
                        if (data.logs && data.logs.length >= 0 && data.logs[0].topics[2]) {
                            return resolve(data.logs[0].topics[2]);
                        }
                        return reject(data);
                    }
                    if (noOfRetry > 10) {
                        return reject("timeout execution of step")
                    }
                    that._getStepIdForTxHash(txHash, resolve, reject);
                })
            }, 2000)
        });
    }

    editStep(stepId, stepJson, metaData, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "editStep", options, stepId, stepJson, metaData);
    }

    editStepSync(stepId, stepJson, metaData, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "editStep", options, stepId, stepJson, metaData).then(txHash => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then(logs => {
            if (logs.length == 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        });
    }

    addStepsToTemplate(templateId, stepIds, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "addStepsToTemplate", options, templateId, stepIds);
    }

    updateStepsToTemplate(templateId, stepIds, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "updateStepsToTemplate", options, templateId, stepIds);
    }

    shareTemplate(templateId, subscribers, options) {
        return Web3_1_0.send(this, this.templateRepositoryManager, "shareTemplate", options, templateId, subscribers);
    }

    getAllTemplates(owner) {
        return Web3_1_0.call(this, this.templateRepositoryManager, "getAllTemplates", null, owner);
    }

    getAllSteps(owner) {
        return Web3_1_0.call(this, this.templateRepositoryManager, "getAllSteps", null, owner);
    }

    getTemplate(templateId) {
        return Web3_1_0.call(this, this.templateRepositoryManager, "getTemplate", null, templateId);
    }

    getStepById(stepId) {
        return Web3_1_0.call(this, this.templateRepositoryManager, "getStepById", null, stepId);
    }


    getAllEventsByRepoId(templateId, address) {
        let events = ["onTemplateAdded", "onTemplateEdited", "onTemplateStepUpdated"];
        // let "onStepAdded", "onStepEdited"
        let filter = {
            filter: {
                "templateId": templateId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }
        return this.getAllEvents(events, filter);
    }

    getAllEventsByStepId(stepId, address) {
        let events = ["onStepAdded", "onStepEdited"];
        let filter = {
            filter: {
                "stepId": stepId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }
        return this.getAllEvents(events, filter);
    }

    getAllEvents(events, filter) {
        let promiseArray = [];
        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.templateRepositoryManager, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection);
    }

    registerOnTemplateAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateAdded", callback);
    }

    registerOnTemplateEdited(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        this.events.addListener("onTemplateEdited", callback);
    }

    registerOnStepUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateStepUpdated", callback);
    }

    registerOnStepAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onStepAdded", callback);
    }

    registerOnStepEdited(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onStepEdited", callback);
    }

    registerOnTemplateShared(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onTemplateShared", callback);
    }

    unRegisterOnTemplateAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onTemplateAdded", callback);
    }

    unRegisterOnTemplateEdited(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onTemplateEdited", callback);
    }

    unRegisterOnTemplateStepUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onTemplateStepUpdated", callback);
    }

    unRegisterOnStepAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onStepAdded", callback);
    }

    unRegisterOnStepEdited(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onStepEdited", callback);
    }

    unRegisterOnTemplateShared(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onTemplateShared", callback);
    }
}
export default TemplateRepoManager;