import React, { Component } from 'react';
import * as maintanance from '../maintanance.json';
import { Result } from 'antd';
import { ReactComponent as Maintenance } from '../assets/images/MAINTAIN 1.svg'

class ServerMaintanance extends Component {

    constructor(props) {
        super(props);
        this.maintanance = maintanance['isUnderMaintanance']
    }

    render() {
        return (
            // <div>
            //     <Maintenance />
            // </div>
            <div style={{ marginTop: 170 }}>
                <Result
                status="warning"
                title="Server Under Maintenance"
                />
            </div>
        );
    }

}

export default ServerMaintanance;