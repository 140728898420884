import Database from '../../index'
import * as NetworkUtils from '../../../util/utils';
import NetworkDataFormat from '../Utils/network-data-format';
import Console from '../../../logger/index';
import RestoreItems from '../sync/items';
import * as utils from '../../../util/utils';

class CatalogueDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerOnCatalogueAdded(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnCatalogue", callback);
    }

    unRegisterOnCatalogueAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnCatalogue", callback);
    }
    registerOnCatalogueUpdated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnCatalogueUpdated", callback);
    }

    unRegisterOnCatalogueUpdated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnCatalogueUpdated", callback);
    }
    registerOnCatalogueUpdateForSubscriber(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnCatalogueUpdateForSubscriber", callback);
    }

    unRegisterOnCatalogueUpdateForSubscriber(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnCatalogueUpdateForSubscriber", callback);
    }


    registerOnCatalogueStatusUpdated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnCatalogueStatusUpdated", callback);
    }
    unRegisterOnCatalogueStatusUpdated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.removeListener("OnCatalogueStatusUpdated", callback);
    }

    registerOnCatalogueSubscriberAdded(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnCatalogueSubscriberAdded", callback);
    }

    unRegisterOnCatalogueSubscriberAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnCatalogueSubscriberAdded", callback);
    }

    _onCatalogueAdded(error, dataJSON) {
        if (error)
            return;
        let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress);
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        return RestoreItems.restoreItem(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, undefined, true).then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            // this.dbInstance.updateLastSync();
            return this._emitOnCatalogueAdded(itemId);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        })
        // .finally(() => {
        //     let metaData = NetworkDataFormat.getMetaInfo(itemId, dataJSON, "Catalogue added");
        //     return this.dbInstance.transactionsDB.addTransaction(itemId, dataJSON.args.owner, metaData).catch(error => {
        //         Console.error(`Unable to edit transaction for ${itemId}, Reason: ${error.toString()}`)
        //     })
        // })
    }

    _onCatalogueUpdated(error, dataJSON) {
        if (error)
            return;
        let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress);
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        let functionName = dataJSON.args.status === '0' ? "restoreItem" : "restoreInventory";
        return RestoreItems[functionName](dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, undefined, true, "updateItem").then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnCatalogueUpdated", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        }).finally(() => {
            let metaData = NetworkDataFormat.getMetaInfo(itemId, dataJSON, "Catalogue added");
            if (dataJSON.args.status === "1") {
                metaData.updateType = dataJSON.args.updateType;
            }
            return this.dbInstance.transactionsDB.addTransaction(itemId, dataJSON.args.owner, metaData).catch(error => {
                Console.error(`Unable to edit transaction for ${itemId}, Reason: ${error.toString()}`)
            })
        })
    }
    _onCatalogueUpdateForSubscriber(error, dataJSON) {
        if (error)
            return;
        let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress);
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        return RestoreItems.updateItemForSubscriber(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner).then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnCatalogueUpdateForSubscriber", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        }).finally(() => {
            let metaData = NetworkDataFormat.getMetaInfo(itemId, dataJSON, "Catalogue added");
            return this.dbInstance.transactionsDB.addTransaction(itemId, dataJSON.args.owner, metaData).catch(error => {
                Console.error(`Unable to edit transaction for ${itemId}, Reason: ${error.toString()}`)
            })
        })
    }
    _onCatalogueStatusUpdated(error, dataJSON) {
        if (error)
            return;
        let promise, promiseArray = [];
        let paramId = utils.getParamId();
        // let linkedItemIds = dataJSON.args.owner === paramId ? dataJSON.args.linkedItemIds : dataJSON.args.ownerItemCatalogueIds;
        for (let index in dataJSON.args.buyerItemsIds) {
            let buyerItemId = utils.getTransactionId(dataJSON.args.buyerItemsIds[index], dataJSON.args.contractAddress);
            let sellerItemId = utils.getTransactionId(dataJSON.args.sellerItemsIds[index], dataJSON.args.contractAddress);
            promise = this.dbInstance.items.updateMappedItemStatus(buyerItemId, sellerItemId, dataJSON.args.buyerId, dataJSON.args.sellerId, dataJSON.args.status);
            promiseArray.push(promise);
        }
        return Promise.all(promiseArray).then(() => {
            // Console.log(`[GraphDB] Linking catalogue info to graph ${dataJSON.args.catalogueId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnCatalogueStatusUpdated", undefined, null);
        }).catch(e => {
            Console.error(`Unable to map catalogue ${e.toString()}`);
        });
    }
    _onCatalogueSubscriberAdded(error, dataJSON) {
        if (error)
            return;
        let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress);
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        if (dataJSON.args.owner === utils.getParamId()) {
            return this.dbInstance.items.addSubscriber(itemId, dataJSON.args.subscriber);
        }
        return RestoreItems.restoreItemForSubscriber(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.sId, dataJSON.args.owner).then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnCatalogueSubscriberAdded", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        }).finally(() => {
            let metaData = NetworkDataFormat.getMetaInfo(itemId, dataJSON, "Catalogue added");
            return this.dbInstance.transactionsDB.addTransaction(itemId, dataJSON.args.owner, metaData).catch(error => {
                Console.error(`Unable to edit transaction for ${itemId}, Reason: ${error.toString()}`)
            })
        })
    }

    _emitOnCatalogueAdded(itemId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.items.getItem(`${itemId}`).then(itemResponse => {
            this.dbInstance.emitEvent("OnCatalogue", itemResponse);
            console.info(`[GraphDB] emiting OnCatalogue event.`);
        }).catch(e => {
            this.dbInstance.emitEvent("OnCatalogue", null, e);
            Console.error(`[GraphDB] emiting OnCatalogue event error ${e}.`);
        });
    }
}

export default CatalogueDBEvents;