import Database from '../../index'
import * as NetworkUtils from '../../../util/utils';
import RestoreVendorManagement from '../sync/vendorManagement';
import Console from '../../../logger/index';

class VendorDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerEvents(contractAddress, paramId) {

        let vendorManagementParamObj = this.dbInstance.getVendorManager(contractAddress);

        vendorManagementParamObj.registerOnInvited(this._onInvited.bind(this), { address: paramId });
        vendorManagementParamObj.registerOnAddInvited(this._onAddInvited.bind(this), { address: paramId });
        vendorManagementParamObj.registerOnInvitationTemplateAdded(this._onInvitationTemplateAdded.bind(this), { address: paramId });
        vendorManagementParamObj.registerOnPublicInvitationTemplateAdded(this._onPublicInvitationTemplateAdded.bind(this), { address: paramId });
        vendorManagementParamObj.registerOnEvalRequestAdded(this._onEvalRequestAdded.bind(this), { address: paramId });
        vendorManagementParamObj.registerOnInviteUpdated(this._onInviteUpdated.bind(this), { addres: paramId });

        Console.log("Registered Events for _onSubscriberAdded", contractAddress);
    }

    registerOnAddInvited(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onAddInvited", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnAddInvited(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onAddInvited", callback);
    }

    registerOnInvited(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onInvited", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnInvited(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onInvited", callback);
    }

    registerOnVendorInvited(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onVendorInvited", callback);
        Console.info(`[GraphDB] register event`);
    }

    registerOnInviteUpdated(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onInviteUpdated", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnInviteUpdated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onInviteUpdated", callback);
    }

    unRegisterOnVendorInvited(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onVendorInvited", callback);
    }

    registerOnBuyerInvited(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onBuyerInvited", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnBuyerInvited(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onBuyerInvited", callback);
    }

    registerOnEvalRequestAdded(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onEvalRequestAdded", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnEvalRequestAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onEvalRequestAdded", callback);
    }

    registerOnInvitationTemplateAdded(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onInvitationTemplateAdded", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnInvitationTemplateAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onInvitationTemplateAdded", callback);
    }

    registerOnPublicInvitationTemplateAdded(callback, options) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents(options);
        }
        this.dbInstance.events.addListener("onPublicInvitationTemplateAdded", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnPublicInvitationTemplateAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onPublicInvitationTemplateAdded", callback);
    }

    _onAddInvited(error, dataJSON) {
        Console.info(`[GraphDB] Got _onAddInvited event`);
        if (error) {
            Console.error(`Unable to get Version details form network, Reson: ${error}`);
            return;
        }
        return RestoreVendorManagement.restoreInvitation(dataJSON.args.invitationId, dataJSON.args.templateType, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onAddInvited", dataJSON.args.invitationId, null);
        }).then(() => {
            dataJSON.args.invitationId = NetworkUtils.getTransactionId(dataJSON.args.invitationId, dataJSON.args.contractAddress);
            let owner = dataJSON.args.templateType === '0' ? dataJSON.args.buyer : dataJSON.args.vendor;
            return this.dbInstance.notifications.addNotification(dataJSON.args.invitationId, "onBuyerInvited", "invitation", owner).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.invitationId, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.invitationId}, reason: ${error.toString()}`);
        });
    }

    _onInvited(error, dataJSON) {
        Console.info(`[GraphDB] Got _onInvited event`);
        if (error) {
            Console.error(`Unable to get Version details form network, Reson: ${error}`);
            return;
        }
        return RestoreVendorManagement.restoreInvitation(dataJSON.args.invitationId, dataJSON.args.templateType, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onInvited", dataJSON.args.invitationId, null);
        }).then(() => {
            dataJSON.args.invitationId = NetworkUtils.getTransactionId(dataJSON.args.invitationId, dataJSON.args.contractAddress);
            let owner = dataJSON.args.templateType === '0' ? dataJSON.args.buyer : dataJSON.args.vendor;
            return this.dbInstance.notifications.addNotification(dataJSON.args.invitationId, "onBuyerInvited", "invitation", owner).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.invitationId, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.invitationId}, reason: ${error.toString()}`);
        });
    }

    _onInviteUpdated(error, dataJSON) {
        Console.info(`[GraphDB] Got _onInviteUpdated event`);
        if (error) {
            Console.error(`Unable to get Version details form network, Reson: ${error}`);
            return;
        }
        return RestoreVendorManagement.restoreInvitation(dataJSON.args.invitationId, dataJSON.args.templateType, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onInviteUpdated", dataJSON.args.invitationId, null);
        }).then(() => {
            dataJSON.args.invitationId = NetworkUtils.getTransactionId(dataJSON.args.invitationId, dataJSON.args.contractAddress);
            let owner = dataJSON.args.templateType === '0' ? dataJSON.args.buyer : dataJSON.args.vendor;
            return this.dbInstance.notifications.addNotification(dataJSON.args.invitationId, "onInviteUpdated", "invitation", owner).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.invitationId, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.invitationId}, reason: ${error.toString()}`);
        });
    }

    _onInvitationTemplateAdded(error, dataJSON) {
        Console.info(`[GraphDB] Got _onInvitationTemplateAdded event`);
        if (error) {
            Console.error(`Unable to get Version details form network, Reson: ${error}`);
            return;
        }
        return RestoreVendorManagement.restoreVendorTemplate(dataJSON.args.templateId, dataJSON.args.owner, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onInvitationTemplateAdded", dataJSON.args.templateId, null);
        }).then(() => {
            dataJSON.args.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.contractAddress);
            return this.dbInstance.notifications.addNotification(dataJSON.args.templateId, "onInvitationTemplateAdded", "vendorTemplate", dataJSON.args.owner).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.templateId, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.templateId}, reason: ${error.toString()}`);
        });
    }

    _onPublicInvitationTemplateAdded(error, dataJSON) {
        Console.info(`[GraphDB] Got _onPublicInvitationTemplateAdded event`);

        if (error) {
            Console.error(`Unable to get Version details form network, Reson: ${error}`);
            return;
        }
        return RestoreVendorManagement.restoreVendorTemplate(dataJSON.args.templateId, NetworkUtils.getParamId(), dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onInvitationTemplateAdded", dataJSON.args.templateId, null);
        }).then(() => {
            dataJSON.args.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.contractAddress);
            return this.dbInstance.notifications.addNotification(dataJSON.args.templateId, "onInvitationTemplateAdded", "vendorTemplate", dataJSON.args.owner).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.templateId, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.templateId}, reason: ${error.toString()}`);
        });
    }

    _onEvalRequestAdded(error, dataJSON) {
        Console.info(`[GraphDB] Got _onEvalRequestAdded event`);

        if (error) {
            Console.error(`Unable to get Version details form network, Reson: ${error}`);
            return;
        }
        return RestoreVendorManagement.restoreEvalRequest(dataJSON.args.evalId, dataJSON.args.invitationId, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onEvalRequestAdded", dataJSON.args.evalId, null);
        }).then(() => {
            dataJSON.args.evalId = NetworkUtils.getTransactionId(dataJSON.args.evalId, dataJSON.args.contractAddress);
            dataJSON.args.invitationId = NetworkUtils.getTransactionId(dataJSON.args.invitationId, dataJSON.args.contractAddress);
            return this.dbInstance.notifications.addNotification(dataJSON.args.evalId, "onEvalRequestAdded", "evalRequest", dataJSON.args.vendor).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.evalId, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.evalId}, reason: ${error.toString()}`);
        });
    }
}

export default VendorDBEvents;