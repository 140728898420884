import { SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH, TOGGLE_PURCHASE_VISIBILITY, TOGGLE_SALES_VISIBILITY, TOGGLE_LSALES_VISIBILITY, TOGGLE_LPURCHASES_VISIBILITY, TOGGLE_DRAFT_INVOICE_VISIBILITY, TOGGLE_VIEW_WORKFLOW_VISIBILITY, TOGGLE_REPORTS_VISIBILITY, TOGGLE_OPERATIONS_VISIBILITY, TOGGLE_SUBDOCUMENTS_VISIBILITY, TOGGLE_CATALOGUE_VISIBILITY, TOGGLE_CONTACTS_VISIBILITY, TOGGLE_CREDIT_NOTE_TEMPLATE_VISIBILITY } from "../../constants/ActionTypes";
import { LAYOUT_TYPE, NAV_STYLE, THEME_COLOR_SELECTION, THEME_TYPE } from "../../constants/ThemeSetting";


export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeType(themeType) {
  return { type: THEME_TYPE, themeType };
}

export function setThemeColorSelection(colorSelection) {
  return { type: THEME_COLOR_SELECTION, colorSelection };
}

export function onNavStyleChange(navStyle) {
  return { type: NAV_STYLE, navStyle };
}

export function onLayoutTypeChange(layoutType) {
  return { type: LAYOUT_TYPE, layoutType };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export function setSalesVisibility(salesVisibility) {
  return {
    type: TOGGLE_SALES_VISIBILITY,
    salesVisibility
  };
}
export function setPurchasesVisibility(purchasesVisibility) {
  return {
    type: TOGGLE_PURCHASE_VISIBILITY,
    purchasesVisibility
  };
}
export function setLSalesVisibility(lSalesVisibility) {
  return {
    type: TOGGLE_LSALES_VISIBILITY,
    lSalesVisibility
  };
}
export function setLPurchasesVisibility(lPurchasesVisibility) {
  return {
    type: TOGGLE_LPURCHASES_VISIBILITY,
    lPurchasesVisibility
  };
}
export function setDraftInvoicesVisibility(draftInvoicesVisibility) {
  return {
    type: TOGGLE_DRAFT_INVOICE_VISIBILITY,
    draftInvoicesVisibility
  };
}
export function setViewWorkflowVisibility(viewWorkflowVisibility) {
  return {
    type: TOGGLE_VIEW_WORKFLOW_VISIBILITY,
    viewWorkflowVisibility
  };
}
export function setReportsVisibility(reportsVisibility) {
  return {
    type: TOGGLE_REPORTS_VISIBILITY,
    reportsVisibility
  };
}
export function setSubscribedDocumentsVisibility(subscribedDocumentsVisibility) {
  return {
    type: TOGGLE_SUBDOCUMENTS_VISIBILITY,
    subscribedDocumentsVisibility
  };
}
export function setOperationsVisibility(operationsVisibility) {
  return {
    type: TOGGLE_OPERATIONS_VISIBILITY,
    operationsVisibility
  };
}

export function setCatalogueVisibility(catalogueVisibility) {
  return {
    type: TOGGLE_CATALOGUE_VISIBILITY,
    catalogueVisibility
  };
}
export function setContactsVisibility(contactsVisibility) {
  return {
    type: TOGGLE_CONTACTS_VISIBILITY,
    contactsVisibility
  };
}
export function setCreditNoteTemplateVisibility(creditNoteTemplateVisibility) {
  return {
    type: TOGGLE_CREDIT_NOTE_TEMPLATE_VISIBILITY,
    creditNoteTemplateVisibility
  };
}