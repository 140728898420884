import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import RestoreVendorManagement from '../../param-libs/graph-ql/sync/vendor';
import ECIES from '../ecies';
import GraphQL from '../../param-libs/graph-ql';

class VendorManager {

    static addContact(address, templateId, initiatorJsonLd, receiverJsonLd, txnMode, type) {
        let vendorManager, functionName;
        vendorManager = GraphQL.getInstance().vendorManagement;
        functionName = "addBuyerContact";
        if (type === 'vendor') {
            functionName = "addVendorContact"
        }
        return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode).then((res) => {
            return vendorManager[functionName](address, templateId, res.initiatorJsonLd, res.receiverJsonLd, txnMode);
        }).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     vendorManager = paramNetwork.getVendorManager(config.address);
        //     functionName = "addBuyerContact";
        //     if (type === 'vendor') {
        //         functionName = "addVendorContact"
        //     }
        //     return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode);
        // }).then(res => {
        //     let options = Utils.getNetworkOptions();
        //     return vendorManager[functionName](address, templateId, res.initiatorJsonLd, res.receiverJsonLd, txnMode, options);
        // })
    }

    static invite(address, templateId, initiatorJsonLd, receiverJsonLd, txnMode, type) {
        let vendorManager, functionName;
        vendorManager = GraphQL.getInstance().vendorManagement;
        functionName = "inviteBuyer";
        if (type === 'vendor') {
            functionName = "inviteVendor";
        }
        return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode).then((res) => {
            return vendorManager[functionName](address, templateId, res.initiatorJsonLd, res.receiverJsonLd, txnMode);
        }).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     vendorManager = paramNetwork.getVendorManager(config.address);
        //     functionName = "inviteBuyer";
        //     if (type === 'vendor') {
        //         functionName = "inviteVendor";
        //     }
        //     return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode);
        // }).then(res => {
        //     let options = Utils.getNetworkOptions();
        //     return vendorManager[functionName](address, templateId, res.initiatorJsonLd, res.receiverJsonLd, txnMode, options);
        // })
    }

    static draft(templateId, initiatorJsonLd, receiverJsonLd, txnMode, type) {
        let vendorManager, functionName;
        vendorManager = GraphQL.getInstance().vendorManagement;
        functionName = "draftBuyer";
        if (type === 'vendor') {
            functionName = "draftVendor";
        }
        return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode).then((res) => {
            return vendorManager[functionName](templateId, res.initiatorJsonLd, res.receiverJsonLd, txnMode);
        }).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     vendorManager = paramNetwork.getVendorManager(config.address);
        //     functionName = "draftBuyer";
        //     if (type === 'vendor') {
        //         functionName = "draftVendor";
        //     }
        //     return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode);
        // }).then(res => {
        //     let options = Utils.getNetworkOptions();
        //     return vendorManager[functionName](templateId, res.initiatorJsonLd, res.receiverJsonLd, txnMode, options);
        // })
    }

    static createInvitationTemplate(name, jsonLd, metaData, txnMode, templateType) {
        let vendorManager = GraphQL.getInstance().vendorManagement;
        let templateTypeString = templateType === "1" ? "VENDOR" : "BUYER";
        let txnModeString = Utils.getTxnTypeForGraphQL(txnMode);
        jsonLd = JSON.parse(jsonLd);
        delete Object.assign(jsonLd, { ["type"]: jsonLd["@type"] })["@type"];
        let inputObject = {}
        inputObject["Vendor"] = jsonLd
        return vendorManager.createInvitationTemplate(name, inputObject, metaData, txnModeString, templateTypeString).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let vendorManager = paramNetwork.getVendorManager(config.address);
        //     return vendorManager["createInvitationTemplate"](name, jsonLd, metaData, txnMode, templateType, options);
        // })
    }

    static getShareKey(invitationId) {
        return Utils.getConfig("ParamVendors").then(config => {
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let vendorManager = paramNetwork.getVendorManager(config.address);
            return vendorManager["getShareKey"](invitationId, options);
        })
    }

    static acceptBuyerInvite(invitationId, initiatorJsonLd, receiverJsonLd, shareKey, txnMode) {
        let vendorManager = GraphQL.getInstance().vendorManagement
        return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode, true).then(res => {
            return vendorManager["acceptBuyerInvite"](invitationId, res.initiatorJsonLd, res.receiverJsonLd, shareKey, txnMode);
        }).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
    }

    static acceptVendorInvite(invitationId, initiatorJsonLd, receiverJsonLd, shareKey, txnMode) {
        let vendorManager = GraphQL.getInstance().vendorManagement
        return VendorManager.encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode, true).then(res => {
            return vendorManager["acceptVendorInvite"](invitationId, res.initiatorJsonLd, res.receiverJsonLd, shareKey, txnMode);
        }).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
    }

    static rejectBuyerInvite(invitationId) {
        let vendorManager = GraphQL.getInstance().vendorManagement
        return vendorManager['rejectBuyerInvite'](invitationId).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let vendorManager = paramNetwork.getVendorManager(config.address);
        //     return vendorManager["rejectBuyerInvite"](invitationId, options);
        // })
    }

    static rejectVendorInvite(invitationId) {
        let vendorManager = GraphQL.getInstance().vendorManagement
        return vendorManager['rejectVendorInvite'](invitationId).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let vendorManager = paramNetwork.getVendorManager(config.address);
        //     return vendorManager["rejectVendorInvite"](invitationId, options);
        // })
    }

    static approve(type, invitationId) {
        let vendorManager = GraphQL.getInstance().vendorManagement
        return vendorManager["approve"](type, invitationId).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let vendorManager = paramNetwork.getVendorManager(config.address);
        //     if (type === 'supplier') {
        //         return vendorManager["approveVendor"](invitationId, options);
        //     }
        //     return vendorManager["approveBuyer"](invitationId, options);
        // })
    }

    static reject(type, invitationId) {
        let vendorManager = GraphQL.getInstance().vendorManagement
        return vendorManager["reject"](type, invitationId).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let vendorManager = paramNetwork.getVendorManager(config.address);
        //     if (type === 'supplier') {
        //         return vendorManager["rejectVendor"](invitationId, options);
        //     }
        //     return vendorManager["rejectBuyer"](invitationId, options);
        // })
    }

    static evalRequest(invitationId, contractAddress, documentId) {
        let vendorManager = GraphQL.getInstance().vendorManagement
        return vendorManager["evalRequest"](invitationId, contractAddress, documentId).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamVendors").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let vendorManager = paramNetwork.getVendorManager(config.address);
        //     return vendorManager["evalRequest"](invitationId, contractAddress, documentId, options);
        // })
    }

    static getAllInvitationTemplate(owner, templateType) {
        return ParamConnector.getInstance().getDB().vendorManagement.getAllInvitationTemplate(owner, templateType)
    }

    static getInvitaionTemplate(templateId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getInvitaionTemplate(templateId)
    }
    static getInvitaionTemplateSummary(templateId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getInvitaionTemplateSummary(templateId)
    }
    static getAllContacts(owner) {
        return ParamConnector.getInstance().getDB().vendorManagement.getAllContacts(owner)
    }

    static getAllBuyers(owner) {
        return ParamConnector.getInstance().getDB().vendorManagement.getAllBuyers(owner)
    }

    static getAllVendors(owner) {
        return ParamConnector.getInstance().getDB().vendorManagement.getAllVendors(owner)
    }

    static getContact(invitationId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getContact(invitationId)
    }

    static getContactSummary(invitationId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getContactSummary(invitationId);
    }
    static getContactSummaryByParamId(paramId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getContactSummaryByParamId(paramId);
    }
    static getContactByParamId(paramId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getContactByParamId(paramId);
    }

    static getAllEvalRequestsByInvitation(invitationId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getAllEvalRequestsByInvitation(invitationId);
    }

    static getEvalRequest(evalRequestId) {
        return ParamConnector.getInstance().getDB().vendorManagement.getEvalRequest(evalRequestId);
    }

    static restoreInvitation(invitationId, invitationType, contractAddress) {
        return RestoreVendorManagement.restoreInvitation(invitationId, invitationType, contractAddress);
    }

    static encryptJsonLd(initiatorJsonLd, receiverJsonLd, txnMode, reverse) {
        if (txnMode === "2") {
            let publicKey
            if (reverse) {
                initiatorJsonLd = JSON.parse(initiatorJsonLd);
                publicKey = initiatorJsonLd.publicKey;
                initiatorJsonLd = JSON.stringify(initiatorJsonLd);
            }
            else {
                receiverJsonLd = JSON.parse(receiverJsonLd);
                publicKey = receiverJsonLd.publicKey;
                receiverJsonLd = JSON.stringify(receiverJsonLd);
            }
            let privateKey = Utils.getPrivateKey();
            if (!publicKey) {
                return Promise.reject("Can't add protected mode, public key of receiver not found");
            }
            receiverJsonLd = ECIES.encrypt(privateKey, publicKey, receiverJsonLd);
            initiatorJsonLd = ECIES.encrypt(privateKey, publicKey, initiatorJsonLd);
        }
        return Promise.resolve({ initiatorJsonLd, receiverJsonLd })
    }

    static getContractAddress() {
        let addressJSON = require('../../param-network/vendor-manager/vendor-manager.json')
        return addressJSON.address;
    }

    static restoreAllVendorTemplate(owner, contractAddress) {
        return RestoreVendorManagement.restoreAllVendorTemplates(owner, contractAddress)
    }

    static getContactNameByParamId(owner) {
        return ParamConnector.getInstance().getDB().vendorManagement.getContactNameByParamId(owner);
    }

}
export default VendorManager;