import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import ECIES from '../ecies';
import NetworkUtils from './network-utils';
import ItemParser from '../../parsers/ItemParser';
import Console from '../../logger';
import GraphQL from '../../param-libs/graph-ql';

class Catalogue {
    static addCatalogue(info, type = "1", catalogueTxnMode = "1", inventoryData = "", inventoryDataTxnMode = "1") {
        let internalId = JSON.parse(info).internalId
        let catalogueId = Utils.generateByte64Id(`${internalId}`)
        let encryptedPayload, contractAddress;
        let itemManager = GraphQL.getInstance().items
        return Catalogue.encryptCatalogueInfo(info, inventoryData, catalogueTxnMode, inventoryDataTxnMode, undefined, itemManager)
            .then(res => {
                encryptedPayload = res;
                let inputObject = {}
                if (catalogueTxnMode === "1") {
                    inputObject.catalogue = JSON.parse(res.encryptedCatalogueInfo)
                } else {
                    inputObject.data = res.encryptedCatalogueInfo
                }
                let catalogueTxnModeString = Utils.getTxnTypeForGraphQL(catalogueTxnMode)
                let catalogueType = Utils.getItemTypeForGraphQL(type)
                return itemManager["addCatalogueV1"](catalogueId, inputObject, catalogueType, catalogueTxnModeString, res.shareKey, "MetaData");
            }).then(res => {
                let catalogueParseData = JSON.parse(res)
                contractAddress = catalogueParseData.signedParams.To
                let signedCatalougeData = Utils.codeSignPaylaod(catalogueParseData)
                return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
            }).then(res => {
                let inputObject = {}
                if (inventoryDataTxnMode === "1") {
                    inputObject.inventory = JSON.parse(encryptedPayload.encryptedInventoryInfo)
                } else {
                    inputObject.data = encryptedPayload.encryptedInventoryInfo
                }
                let inventoryDataTxnModeString = Utils.getTxnTypeForGraphQL(inventoryDataTxnMode)
                let metaData = JSON.stringify({
                    adjustedBy: "Self",
                    _event: "Inventory Created",
                    _docType: "0"
                })
                return itemManager["addInventory"](catalogueId, inputObject, encryptedPayload.shareKey, inventoryDataTxnModeString, metaData);
            }).then(res => {
                let signedInventoryData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedInventoryData)
            }).then(() => {
                return Utils.getTransactionId(catalogueId, contractAddress)
            })

    }

    static addMultipleCatalogues(catalogueTxnMode = "2", inventoryDataTxnMode = "2", catalogueArray, finalResultObject, importDocumentCallback, currentProgress) {
        const noOfDocuments = catalogueArray.length;
        const fragments = (100 - currentProgress) / noOfDocuments;
        const addMultipleCatalogue = (index) => {
            const catalogueInfo = catalogueArray[index];
            const catalogueData = JSON.parse(catalogueInfo.catalogueData);
            const type = catalogueData.type;
            const internalId = catalogueData.internalId;
            importDocumentCallback.onProgress(currentProgress, `Trying to import item ${internalId}`);

            return Catalogue.addCatalogue(catalogueInfo.catalogueData, type, catalogueTxnMode, catalogueInfo.inventoryData, inventoryDataTxnMode).then(res => {
                currentProgress += fragments
                importDocumentCallback.onProgress(currentProgress, `Successfully imported item ${internalId}`);
                finalResultObject.uploadDocumentsArray.push(internalId);
                finalResultObject.uploadDocuments += 1;
                if (index === catalogueArray.length - 1) {
                    return res;
                }
                index++;
                return addMultipleCatalogue(index);
            }).catch(err => {
                console.error(`Some error occured while importing item ${internalId} Reason: ${err}`)
                finalResultObject.errorUploadingDocumentsArray.push(internalId);
                importDocumentCallback.onError(internalId, "Some error occured");
            })
        }
        return addMultipleCatalogue(0);
    }

    static addCatalogueSync(info, type = "1", catalogueTxnMode = "1", inventoryData = "", inventoryDataTxnMode = "1") {
        let catalogueManager, encryptedPayload, options, catalogueConfig, inventoryConfig;
        return Promise.all([
            Utils.getConfig("ParamCatalogueImpl"),
            Utils.getConfig("ParamInventoryImpl")
        ]).then((res) => {
            catalogueConfig = res[0];
            inventoryConfig = res[1];
            let paramNetwork = ParamConnector.getInstance(catalogueConfig).getNetwork();
            catalogueManager = paramNetwork.getCatalogueManager(catalogueConfig.address);
            return Catalogue.encryptCatalogueInfo(info, inventoryData, catalogueTxnMode, inventoryDataTxnMode, undefined, catalogueManager);
        }).then(res => {
            encryptedPayload = res;
            options = Utils.getNetworkOptions();
            return catalogueManager["addCatalogSync"](res.encryptedCatalogueInfo, type, catalogueTxnMode, res.shareKey, inventoryConfig.address, "MetaData", options);
        })
    }

    static updateCatalogue(catalogueId, info, type, inventoryData = "", catalogueTxnMode, inventoryDataTxnMode, isUpdateInventory, inventoryId) {
        let encryptedPayload, options;
        let transactionData = Utils.getTransactionData(catalogueId);
        let itemManager = GraphQL.getInstance().items
        return Catalogue.encryptCatalogueInfo(info, inventoryData, catalogueTxnMode, inventoryDataTxnMode, catalogueId, itemManager)
            .then(res => {
                encryptedPayload = res;
                let inputObject = {}
                if (catalogueTxnMode === "1") {
                    inputObject.catalogue = JSON.parse(res.encryptedCatalogueInfo)
                } else {
                    inputObject.data = res.encryptedCatalogueInfo
                }
                let catalogueTxnModeString = Utils.getTxnTypeForGraphQL(catalogueTxnMode)
                let catalogueType = Utils.getItemTypeForGraphQL(type)
                return itemManager["updateCatalogue"](transactionData.id, inputObject, catalogueType, catalogueTxnModeString, "metadata");
            }).then(res => {
                let signedCatalougeData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
            }).then((res) => {
                if (isUpdateInventory) {
                    return Catalogue.updateInventoryAfterCatalouge(catalogueId, transactionData, encryptedPayload.encryptedInventoryInfo, options, inventoryDataTxnMode, inventoryId)
                }
                return res;
            });
    }

    static updateInventoryAfterCatalouge(catalogueId, transactionData, encryptedInventoryInfo, options, inventoryDataTxnMode = "2", inventoryId) {
        let itemManager = GraphQL.getInstance().items
        let metaData = JSON.stringify({
            _event: "Manually Updated",
            adjustedBy: "Self",
            _docType: "1"
        })
        let inputObject = {}
        if (inventoryDataTxnMode === "1") {
            inputObject.inventory = JSON.parse(encryptedInventoryInfo)
        } else {
            inputObject.data = encryptedInventoryInfo
        }
        inputObject.data = encryptedInventoryInfo
        let inventoryDataTxnModeString = Utils.getTxnTypeForGraphQL(inventoryDataTxnMode);
        return itemManager["updateInventory"](catalogueId, inputObject, inventoryDataTxnModeString, metaData, inventoryId) //temp -> transactionData.id
            .then(res => {
                let signedCatalougeData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
            }).catch((err) => {
                return Promise.reject(err);
            })
    }

    static updateInventory(catalogueId, inventoryData, catalogueTxnMode, inventoryDataTxnMode, metaData, inventoryId) {
        if (!metaData) {
            metaData = JSON.stringify({
                _event: "Manually Updated",
                adjustedBy: "Self",
                _docType: "1"
            })
        }
        // let transactionData = Utils.getTransactionData(catalogueId);
        let itemManager = GraphQL.getInstance().items
        // return Promise.all([Utils.getConfig("ParamInventoryImpl"), Catalogue.getInventoryIdFromItemId(catalogueId)]).then(([config, inventoryId]) => {
        // let inventoryTransactionData = Utils.getTransactionData(inventoryId);
        // let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        // inventoryManager = paramNetwork.getInventoryManager(inventoryTransactionData.address);
        // let catalogueManager = ParamConnector.getInstance(config).getNetwork().getCatalogueManager(transactionData.address)

        return Catalogue.encryptCatalogueInfo("", inventoryData, catalogueTxnMode, inventoryDataTxnMode, catalogueId, itemManager)
            .then(res => {
                let inputObject = {}
                if (inventoryDataTxnMode === "1") {
                    inputObject.inventory = JSON.parse(res.encryptedInventoryInfo)
                } else {
                    inputObject.data = res.encryptedInventoryInfo
                }
                inputObject.data = res.encryptedInventoryInfo
                let inventoryDataTxnModeString = Utils.getTxnTypeForGraphQL(inventoryDataTxnMode);
                return itemManager["updateInventory"](catalogueId, inputObject, inventoryDataTxnModeString, metaData, inventoryId); //transactionData.id
            }).then(res => {
                let signedCatalougeData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
            })
    }

    static updateInventoryWithId(catalogueId, quantity, catalogueTxnType, inventoryTxnType, inventoryId) {
        return Catalogue.getInventoryByItemId(catalogueId).then(inventory => {
            inventory.inventoryLevel.currentValue = quantity;
            inventory = JSON.stringify(inventory);
            return Catalogue.updateInventory(catalogueId, inventory, catalogueTxnType, inventoryTxnType, "", inventoryId);
        })
    }

    static updateInventories(receipt, fromPage, address) {
        let promiseArray = [], itemIdPromise;
        let operation, selfParamId = Utils.getParamId(), otherRole;
        let orderedItem = receipt.referencesOrder.orderedItem;
        if (receipt.customer.identifier === selfParamId) {
            otherRole = receipt.provider.name;
        } else if (receipt.provider.identifier === selfParamId) {
            otherRole = receipt.customer.name;
        }
        let metaData = {
            adjustedBy: `adjusted with ${fromPage} by ${otherRole}`,
            _event: `${fromPage} Created`, _documentId: receipt['@id'],
            _docType: fromPage === "Invoice" ? "2" : "3"
        };
        metaData = JSON.stringify(metaData);
        // address = receipt.provider.identifier;
        // if(receipt.reverseMapping === "true"){
        //     address = receipt.customer.identifier;
        // }
        switch (fromPage) {
            case "Invoice":
                operation = "minus";
                break;
            case "GRN":
                operation = "plus";
                break;
            case "Returns":
                if (receipt.provider.identifier === selfParamId) {
                    operation = "plus";
                }
                else if (receipt.customer.identifier === selfParamId) {
                    operation = "minus";
                }
                break;
            default:
                operation = "plus";
                break;
        }
        for (let index in orderedItem) {
            //if it's PO or self document take the existing orderItemNumber else take from the mapping
            //as in case of self document item mapping is not needed and in case of PO existing Item
            //will be the same at seller's end
            if ((fromPage === "Invoice" && receipt.reverseMapping !== true) ||
                (fromPage === "Returns" && receipt.reverseMapping !== true) ||
                (fromPage === "GRN" && receipt.reverseMapping === true) ||
                receipt.customer.identifier === receipt.provider.identifier
            ) {
                itemIdPromise = Promise.resolve(orderedItem[index].orderItemNumber)
            }
            else {
                itemIdPromise = Catalogue.getMappedItemByItemId(orderedItem[index].orderItemNumber, address).then(res => {
                    if (res.count === 0) {
                        return Promise.reject(`No mapping found for ${orderedItem[index].orderItemNumber}`);
                    }
                    return res.mappingId[0];
                })
            }
            promiseArray.push(
                itemIdPromise.then(itemId => {
                    return Catalogue.getCatalogue(itemId)
                }).then(catalogue => {
                    catalogue.inventory.inventoryLevel.currentValue = operation === "plus" ?
                        Number(catalogue.inventory.inventoryLevel.currentValue) + Number(orderedItem[index].orderQuantity) :
                        Number(catalogue.inventory.inventoryLevel.currentValue) - Number(orderedItem[index].orderQuantity);
                    return catalogue;
                })
            )
        }
        if (promiseArray.length === 0) {
            return Promise.resolve();
        }
        return Promise.all(promiseArray).then(res => {
            let updateInventory = (index) => {
                let catalogue = res[index];
                let inventoryId = catalogue.inventory['@id']
                delete catalogue.inventory.inventoryShareKey;
                delete catalogue.inventory.indexedInfo;
                delete catalogue.inventory['@id'];
                return Catalogue.updateInventory(catalogue['@id'], JSON.stringify(catalogue.inventory), catalogue.catalogueTxnType, catalogue.inventoryTxnType, metaData, inventoryId).then(() => {
                    if (index === res.length - 1) {
                        return res;
                    }
                    index++;
                    return updateInventory(index);
                })
            }
            return updateInventory(0);
        })
    }

    static addSubscriberForCatalogue(catalogueId, subscriber, subscriberPublicKey) {
        let catalogueManager = GraphQL.getInstance().items
        let shareKey;
        let transactionData = Utils.getTransactionData(catalogueId);
        return Catalogue.getSharedKey(catalogueId).then(res => {
            let privateKey = Utils.getPrivateKey();
            shareKey = ECIES.encrypt(privateKey, subscriberPublicKey, res.itemShareKey);
            return catalogueManager["addSubscriberForCatalogue"](transactionData.id, subscriber, shareKey);
        }).then(res => {
            let signedCatalougeData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
        }).then((txnHash) => {
            return catalogueManager["addSubscriberForInventory"](transactionData.id, subscriber, shareKey);
        }).then(res => {
            let signedCatalougeData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
        })
    }


    static addSubscriberForMultipleCatalogues(catalogudIds, subscriber, subscriberPublicKey) {
        let shareCatalogue = (index) => {
            return Catalogue.addSubscriberForCatalogue(catalogudIds[index], subscriber, subscriberPublicKey).then(res => {
                if (index == catalogudIds.length - 1) {
                    return res;
                }
                index++;
                return shareCatalogue(index);
            })
        }
        return shareCatalogue(0);
    }

    /* static linkCatalogues(ownerCatalogueId, linkingCatalogueIds) {
        return Utils.getConfig("ParamCatalogueImpl").then(config => {
            // let options = Utils.getNetworkOptions();
            // let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            // paramNetwork.setConfig(config);
            // let catalogueManager = paramNetwork.getCatalogueManager();
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let catalogueManager = paramNetwork.getCatalogueManager(config.address);
            return catalogueManager["linkCatalogues"](ownerCatalogueId, linkingCatalogueIds, options);
        })
    } */


    static updateInventoryHistory(inventoryId, historyIndex, metaData) {
        let inventoryManager = GraphQL.getInstance().items;
        let transactionData = Utils.getTransactionData(inventoryId);
        return inventoryManager['updateInventoryHistory'](transactionData.id, historyIndex.toString(), metaData).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })

        // let inventoryManager;
        // let transactionData = Utils.getTransactionData(inventoryId);
        // return Utils.getConfig("ParamInventoryImpl").then((config) => {
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     inventoryManager = paramNetwork.getInventoryManager(transactionData.address);
        //     let options = Utils.getNetworkOptions();
        //     return inventoryManager["updateInventoryHistory"](transactionData.id, historyIndex, metaData, options);
        // })
    }

    static updateInventoryHistories(inventoryId, updateData) {
        let historyIndices = Object.keys(updateData);
        let updateHistory = (index) => {
            let updateObj = updateData[historyIndices[index]];
            let metaData = {
                lotNo: updateObj.lotNo,
                pickAwayLocation: updateObj.pickAwayLocation
            }
            metaData = JSON.stringify(metaData)
            return Catalogue.updateInventoryHistory(inventoryId, updateObj.inventoryHistoryIndex, metaData).then(() => {
                if (index === historyIndices.length - 1) {
                    return;
                }
                return updateHistory(++index);
            })
        }
        return updateHistory(0);
    }


    static shareKey(cataloguesBytesId, subscriber, shareKey) {
        return Utils.getConfig("ParamCatalogueImpl").then(config => {
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig(config);
            let catalogueManager = paramNetwork.getCatalogueManager();
            return catalogueManager["shareKey"](cataloguesBytesId, subscriber, shareKey, options);
        });
    }

    /*  static acceptLinkedItems(linkedItemsId) {
            return Utils.getConfig("ParamCatalogueImpl").then(config => {
                let options = Utils.getNetworkOptions();
                let paramNetwork = ParamConnector.getInstance(config).getNetwork();
                // paramNetwork.setConfig(config);            
                let catalogueManager = paramNetwork.getCatalogueManager(config.address);
                return catalogueManager["acceptLinkedItems"](linkedItemsId, options);
            });
        } */

    static acceptItems(buyerId, sellerId, buyerItemsIds, sellerItemsIds) {
        let catalogueManager = GraphQL.getInstance().items
        return catalogueManager["acceptItems"](buyerId, sellerId, buyerItemsIds, sellerItemsIds).then(res => {
            let signedCatalougeData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
        })

        // return Utils.getConfig("ParamCatalogueImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     paramNetwork.setConfig(config);
        //     let catalogueManager = paramNetwork.getCatalogueManager(config.address);
        //     return catalogueManager["acceptItems"](buyerId, sellerId, buyerItemsIds, sellerItemsIds, options);
        // });
    }

    static rejectItems(buyerId, sellerId, buyerItemsIds, sellerItemsIds) {
        let catalogueManager = GraphQL.getInstance().items
        return catalogueManager["rejectItems"](buyerId, sellerId, buyerItemsIds, sellerItemsIds).then(res => {
            let signedCatalougeData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedCatalougeData)
        })
        // return Utils.getConfig("ParamCatalogueImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     paramNetwork.setConfig(config);
        //     let catalogueManager = paramNetwork.getCatalogueManager(config.address);
        //     return catalogueManager["rejectItems"](buyerId, sellerId, buyerItemsIds, sellerItemsIds, options);
        // });
    }

    static addSameItem(item) {
        let itemData = {
            itemName: item.item,
            purpose: "3",
            itemType: item.type,
            txnType: "2",
            inventoryTxnType: "2",
            inventoryDetails: {
                quantity: 0,
                unitPrice: 0,
            }
        }
        return ItemParser.addCatalogue(itemData);
    }


    static getDocIdFromTxnHash(txnHash, catalogueManager, event) {
        return NetworkUtils.getTransactionInfo(catalogueManager.connection, txnHash).then(data => {
            return catalogueManager.parseNetworkLog(event, data)
        })

    }
    static mapItemsV1(buyerItemId, sellerItemId, buyerAddress, sellerAddress, status, buyerItemName, sellerItemName) {
        return ParamConnector.getInstance().getDB().items.mapItemsV1(buyerItemId, sellerItemId, buyerAddress, sellerAddress, status, buyerItemName, sellerItemName);
    }
    static mapItems(ownerItemId, mapItemId, ownerAddress, receiverAddress) {
        return ParamConnector.getInstance().getDB().items.mapItems(ownerItemId, mapItemId, ownerAddress, receiverAddress);
    }

    static getAllCatalogues(owner, isLogistics) {
        return ParamConnector.getInstance().getDB().items.getAllItems(owner, isLogistics);
    }

    static getCreatedCatalogues(owner) {
        return ParamConnector.getInstance().getDB().items.getCreatedItems(owner);
    }

    static getAllGoods(owner) {
        return ParamConnector.getInstance().getDB().items.getAllGoods(owner);
    }

    static getAllReceivedCatalogues(owner) {
        return ParamConnector.getInstance().getDB().items.getAllReceivedItems(owner)
    }

    static getAllRawMaterials(owner) {
        return ParamConnector.getInstance().getDB().items.getAllRawMaterials(owner)
    }

    static getAllServices(owner) {
        return ParamConnector.getInstance().getDB().items.getAllServices(owner)
    }

    static getAllCategories(owner) {
        return ParamConnector.getInstance().getDB().items.getAllCategories(owner);
    }

    static getAllSubCategories(owner) {
        return ParamConnector.getInstance().getDB().items.getAllSubCategories(owner);
    }

    static getAllItemsByCategory(primaryOwner, category) {
        return ParamConnector.getInstance().getDB().items.getAllItemsByCategory(primaryOwner, category);
    }

    static getAllItemsBySubCategory(primaryOwner, subCategory) {
        return ParamConnector.getInstance().getDB().items.getAllItemsBySubCategory(primaryOwner, subCategory);
    }

    static getAllItemsByCategoryAndSubCategory(owner, category, subCategory) {
        return ParamConnector.getInstance().getDB().items.getAllItemsByCategoryAndSubCategory(owner, category, subCategory);
    }

    static getAllItemsByItemType(itemType, owner) {
        switch (itemType) {
            case "raw materials":
                return Catalogue.getAllRawMaterials(owner);
            case "goods":
                return Catalogue.getAllGoods(owner);
            case "services":
                return Catalogue.getAllServices(owner);
            case "received items":
                return Catalogue.getAllReceivedCatalogues(owner);
            default:
                return Catalogue.getAllRawMaterials(owner);
        }
    }

    static getLinkedCatalogue(catalogueId) {
        return ParamConnector.getInstance().getDB().items.getMappedItems(catalogueId);
    }

    static getAllSubscribers(catalogueId) {
        return ParamConnector.getInstance().getDB().items.getAllSubscribers(catalogueId);
    }

    static getAllSubscribedItems(subscriber) {
        return ParamConnector.getInstance().getDB().items.getAllSubscribedItems(Utils.getParamId(), subscriber);
    }

    static getInventory(inventoryId) {
        return ParamConnector.getInstance().getDB().items.getInventory(inventoryId);
    }

    static getInventoryByItemId(catalogueId) {
        return ParamConnector.getInstance().getDB().items.getInventoryByItemId(catalogueId);
    }

    static getSubscriber(sId) {
        return ParamConnector.getInstance().getDB().items.getSubscriber(sId);
    }

    static getSharedKey(itemId) {
        return ParamConnector.getInstance().getDB().items.getSharedKey(itemId);
    }

    static getCatalogueDataBatchwise(itemIds, batchStartIndex, batchSize = 5, finalResult) {
        let itemPromises = [];
        let itemsLength = itemIds.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(itemsLength, endIndex);

        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for fetching items data from db with items length ${itemsLength}`)

        for (let index = startIndex; index < endIndex; index++) {
            let itemId = itemIds[index].toString();
            itemPromises.push(Catalogue.getCatalogue(itemId));
        }
        return Promise.all(itemPromises).then(result => {
            finalResult.append(result);
            Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for fetching items data from db with items length ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return Catalogue.getCatalogueDataBatchwise(itemIds, batchStartIndex + 1, batchSize, finalResult).then(() => {
                return finalResult;
            })
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for fetching items data from db with items length ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return Catalogue.getCatalogueDataBatchwise(itemIds, batchStartIndex + 1, batchSize, finalResult);
        });
    }

    static getCatalogue(catalogueId) {
        return ParamConnector.getInstance().getDB().items.getItem(catalogueId);
    }
    static getSummary(catalogueId, requiredKeys) {
        return ParamConnector.getInstance().getDB().items.getSummary(catalogueId, requiredKeys);
    }
    static getItemMaterial(catalogueId) {
        return ParamConnector.getInstance().getDB().items.getItemMaterial(catalogueId);
    }
    static getItemV1(itemId, address, idType, addressType) {
        return ParamConnector.getInstance().getDB().items.getItemV1(itemId, address, idType, addressType);
    }
    static getItemByInternalId(internalId, owner) {
        return ParamConnector.getInstance().getDB().items.getItemByInternalId(internalId, owner);
    }
    static doesInternalIdExist(internalId, owner) {
        return ParamConnector.getInstance().getDB().items.doesInternalIdExist(internalId, owner);
    }
    static doesItemMappingExist(itemId, address, idType, addressType) {
        return ParamConnector.getInstance().getDB().items.doesItemMappingExist(itemId, address, idType, addressType);
    }
    static getMappedItems(itemId, owner) {
        return ParamConnector.getInstance().getDB().items.getMappedItems(itemId, owner);
    }

    static getMappedItemsV1(sellerAddress, buyerAddress) {
        return ParamConnector.getInstance().getDB().items.getMappedItemsV1(sellerAddress, buyerAddress);
    }

    static getMappedItemInfo(mappingId) {
        return ParamConnector.getInstance().getDB().items.getMappedItemInfo(mappingId);
    }

    static getMappedItemByItemId(itemId, address, idType, addressType) {
        return ParamConnector.getInstance().getDB().items.getMappedItemByItemId(itemId, address, idType, addressType)
    }

    static getMappedItemInfoSummary(mappingId) {
        return ParamConnector.getInstance().getDB().items.getMappedItemInfoSummary(mappingId);
    }

    static getInventoryIdFromItemId(catalogueId) {
        return ParamConnector.getInstance().getDB().items.getInventoryIdFromItemId(catalogueId);
    }
    static getAllMappedItemByItemId(catalogueId) {
        return ParamConnector.getInstance().getDB().items.getAllMappedItemByItemId(catalogueId);
    }
    static getInventoryUpdateHistory(inventoryId) {
        return ParamConnector.getInstance().getDB().items.getInventoryUpdateHistory(inventoryId);
    }
    static getInventoryLevelDetails(itemId, address, idType, addressType) {
        return ParamConnector.getInstance().getDB().items.getInventoryLevelDetails(itemId, address, idType, addressType);
    }
    static getTransactionHistory(catalogueId) {
        return ParamConnector.getInstance().getDB().transactionsDB.getTransactionsById(catalogueId);
    }

    static searchItem(searchString) {
        return ParamConnector.getInstance().getDB().items.searchItem(searchString);
    }
    static getAllItemsForExport() {
        return ParamConnector.getInstance().getDB().items.getAllItemsForExport();
    }

    static searchItemWithMappingInfo(searchString, address, isLogistics) {
        return ParamConnector.getInstance().getDB().items.searchItemWithMappingInfo(searchString, address, isLogistics);
    }

    static encryptCatalogueInfo(catalogueJsonLd, inventoryJsonLd, catalogueTxnMode = "2", inventoryDataTxnMode = "2", catalogueId, catalogueManager) {
        if (catalogueTxnMode === "2" && inventoryDataTxnMode === "2") {
            let privateKey = Utils.getPrivateKey();
            let publicKey = Utils.getPublicKey();
            let encryptedCatalogueInfo = ECIES.encrypt(privateKey, publicKey, catalogueJsonLd);
            let cipherText = encryptedCatalogueInfo.slice(128, -128);
            let shareKeyPromise = Promise.resolve(ECIES.getReceiptKey(privateKey, encryptedCatalogueInfo));
            let randomStrPromise = Promise.resolve(ECIES.getDecryptedRandomString(privateKey, publicKey, cipherText));
            if (catalogueId) {
                let transactionData = Utils.getTransactionData(catalogueId);
                shareKeyPromise = Promise.resolve(Catalogue.getSharedKey(catalogueId).then(res => { return res.itemShareKey }));
                randomStrPromise = catalogueManager.getCatalogue(catalogueId).then(catalogue => { //transactionData.id
                    cipherText = catalogue['JSONLd'].slice(128, -128);
                    return ECIES.getDecryptedRandomString(privateKey, publicKey, cipherText);
                })
            }
            return Promise.all([shareKeyPromise, randomStrPromise]).then(([shareKey, randomStr]) => {
                let encryptedShareKey = ECIES.encrypt(privateKey, publicKey, shareKey)
                let encryptedInventoryInfo = ECIES.encrypt(privateKey, publicKey, inventoryJsonLd, randomStr);
                if (catalogueId) {
                    encryptedCatalogueInfo = ECIES.encrypt(privateKey, publicKey, catalogueJsonLd, randomStr);
                }
                return { encryptedCatalogueInfo, encryptedInventoryInfo, shareKey: encryptedShareKey };
            })
        }
        return { encryptedCatalogueInfo: catalogueJsonLd, encryptedInventoryInfo: inventoryJsonLd, shareKey: "" }
    }

}

export default Catalogue;