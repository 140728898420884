import ParamConnector from '../../../../src/param-connector'
import * as NetworkUtils from '../../../../src/util/utils';
import ECIES from '../../../../src/util/ecies';
import Console from '../../../logger';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import { ReceiptEvents } from '../../../../src/param-network/utils/event-names';
import RestoreGrn from './grn';
import * as DBUtils from '../../../database/nosql/Utils/utils';
import GraphQL from '..';

class RestoreReceipts {

    static restoreReceipt(receiptDataArray) {
        let receiptFinalData = [];
        let outerPromise, outerPromiseArray = [];
        for (let receiptData of receiptDataArray) {
            let receiptId = receiptData.receiptId,
                contractAddress = receiptData.contractAddress,
                status = receiptData.status,
                step = receiptData.step,
                receiptKey = receiptData.receiptKey,
                pId = receiptData.pId,
                event = receiptData.event;

            let receiptsInstance = ParamConnector.getInstance().getDB().receipts;
            let receiptNote = "", receipt;
            // let receiptIdInfo = NetworkUtils.getTransactionData(receiptId);
            outerPromise = receiptsInstance.doesExist(receiptId).then(doesExist => {
                if (doesExist && Number(step) >= Number(doesExist.step) && Number(status) >= Number(doesExist.status) && event != "onReceipStatusUpdateV1") {
                    Console.log("Receipt already exists ", receiptId);
                    Console.log("Trying to update status for ", receiptId);
                    let restoreLinkedInvoicePromise = Promise.resolve();
                    return receiptsInstance.updateStatus(receiptId, status, step).then(() => {
                        return restoreLinkedInvoicePromise;
                    });
                }
                let paramNetworkReceiptManager = GraphQL.getInstance().receipts;
                return paramNetworkReceiptManager.getReceipt(receiptId).then(receiptResponse => { //receiptIdInfo.id
                    if (!receiptResponse) {
                        return;
                    }
                    return receiptResponse
                }).then(res => {
                    receipt = res;
                    let privateKey = receiptKey || NetworkUtils.getFromLocalStorage("privateKey");
                    pId = NetworkUtils.getTransactionId(receipt.pId, contractAddress);

                    if (receipt.pId === "0x0000000000000000000000000000000000000000000000000000000000000000") {
                        pId = null;
                    }
                    if (receipt.txnMode === "2") {
                        try {
                            let temp = ECIES.decrypt(privateKey, receipt.jsonLD);
                            console.log("FormData-Testing:", temp)
                            receipt.jsonLD = temp;
                        } catch (error) {
                            receipt.jsonLD = { noAccess: true }
                            receipt.jsonLD = JSON.stringify(receipt.jsonLD);
                        }
                    }

                    return paramNetworkReceiptManager.getTransactions(receiptId);
                }).then(txnRecords => {
                    for (let index in txnRecords) {
                        let txnData = txnRecords[index]
                        if (txnData.note) {
                            receiptNote = txnData.note;
                        }
                    }
                    receiptFinalData.push(
                        {
                            receiptId: receiptId,
                            jsonLd: receipt.jsonLD,
                            status: receipt.status,
                            parentDocId: pId,
                            step: receipt.step,
                            receiptNote: receiptNote
                        }
                    );
                })

            });
            outerPromiseArray.push(outerPromise);
        }
        return Promise.all(outerPromiseArray).then(() => {
            return ParamConnector.getInstance().getDB().receipts.addReceipts(receiptFinalData);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring receipts [Reason] ${e} [Module] : Sync/receipts/restoreReceipt`);
        });
    }

    static restoreLinkedInvoices(receiptId, contractAddress, isEncrypted, receiptKey) {
        let receiptsInstance = ParamConnector.getInstance().getDB().receipts;
        let linkedInvoicesInstance = ParamConnector.getInstance().getDB().linkedInvoices;
        let receiptIdInfo = NetworkUtils.getTransactionData(receiptId);
        let receiptManager = ParamConnector.getInstance().getNetwork().getReceiptManager(contractAddress);
        let promise;
        let promiseArray = [];
        return receiptManager.getExtendedKnowledgeByReceiptId(receiptIdInfo.id).then(ekIds => {
            if (ekIds.length === 0) {
                return;
            }
            Console.log(`[MongoDB] Extended knowledge is present`);
            for (let index in ekIds) {
                let privateKey = receiptKey || NetworkUtils.getFromLocalStorage("privateKey");
                promise = receiptManager.getExtendedKnowledge(ekIds[index].toString()).then(extdKnowledge => {
                    if (isEncrypted) {
                        extdKnowledge[2] = ECIES.decrypt(privateKey, extdKnowledge[2]);
                    }
                    Console.log(`[MongoDB] Got extended knowledge ${ekIds[index].toString()} from the network.`);
                    return linkedInvoicesInstance.addLinkedInvoice(receiptId, extdKnowledge[2]);
                }).catch(e => {
                    Console.error(`[MongoDB] Unable to add extended knowledge for receipt ${receiptId} and ${ekIds[index].toString()}`);
                });
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray)
        });
    }

    /**
     * 
     * @param {*} contractAddress 
     * @param {*} typeFunction getAllEventsBySeller or getAllEventsByBuyer
     * @param {*} owner 
     * @param {*} callback 
     */
    static restoreAllReceiptsByType(contractAddress, typeFunction, owner, callback, batchSize) {
        if (!batchSize) {
            batchSize = 10;
        }
        let grapQlInstance = GraphQL.getInstance()
        let filteredReceipts = [];
        return grapQlInstance.getEventDetails(owner, NetworkUtils.getEventType('receipt'), "Owner", ["onReceipStatusUpdateV1", "onStatusUpdateV1"]).then(_receipts => {
            let receipts = NetworkUtils.getAllEventDataByName(_receipts)
            Console.info(`[MongoDB] Got e-purchases metainfo from network.`, receipts);
            callback.onProgress("invoices", 1)
            Console.info(`[MongoDB] Trying to fetch missing invoices from network.`);
            let map = new Map();
            for (let index in receipts) {
                if (!map.get(receipts[index].args.rId)) { //temp && "0xed9681ea1529572949ef4da4f655c23cbe6d35f9" === receipts[index].args.buyer
                    filteredReceipts.push(receipts[index]);
                    map.set(receipts[index].args.rId, "1");
                }
            }
            filteredReceipts.sort((a, b) => Number(a.args.step) - Number(b.args.step));
            return RestoreReceipts._restoreAllReceiptsAsBatch(filteredReceipts, contractAddress, 0, batchSize);
        }).then(() => {
            let receiptPromiseArray = [];
            for (let index in filteredReceipts) {
                contractAddress = NetworkUtils.getChecksumAddress(filteredReceipts[index].args.contractAddress)
                let networkReceiptId = filteredReceipts[index].args.rId;
                let receiptPromise = RestoreGrn.restoreAllGrn(contractAddress, networkReceiptId);
                receiptPromiseArray.push(receiptPromise);
            }
            return Promise.all(receiptPromiseArray);
        }).then(() => {
            Console.info(`[MongoDB] documents restored for type function ${typeFunction} successfully.`);
            callback.onProgress("invoices", 2)
        }).catch(error => {
            Console.error(`[MongoDB] Error in restoring receipts for type function ${typeFunction} [Reason] ${error} [Module] : Sync/receipts/restoreAllReceiptsByType`);
        });
    }

    static _restoreAllReceiptsAsBatch(receipts, contractAddress, batchStartIndex, batchSize) {
        let receiptsLength = receipts.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(receiptsLength, endIndex);
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts ${receiptsLength}`)

        let receiptDataArray = [];

        for (let index = startIndex; index < endIndex; index++) {
            contractAddress = NetworkUtils.getChecksumAddress(receipts[index].args.contractAddress)
            let networkReceiptId = receipts[index].args.rId;
            let status = receipts[index].args.status;
            let step = receipts[index].args.step;
            let receiptId = NetworkUtils.getTransactionId(networkReceiptId, contractAddress);
            receiptDataArray.push({
                receiptId: receiptId,
                contractAddress: contractAddress,
                status: status,
                step: step,
                event: receipts[index].event
            })
        }

        return this.restoreReceipt(receiptDataArray).then(result => {
            Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts ${receiptsLength}`)
            if (endIndex === receiptsLength) {
                return;
            }
            return RestoreReceipts._restoreAllReceiptsAsBatch(receipts, contractAddress, batchStartIndex + 1, batchSize);
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts ${receiptsLength}`)
            if (endIndex === receiptsLength) {
                return;
            }
            return RestoreReceipts._restoreAllReceiptsAsBatch(receipts, contractAddress, batchStartIndex + 1, batchSize);
        });
    }

    static restoreAllReceiptEvents(contractAddress, typeFunction, owner, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("receipt", `Trying to get all the events from ${contractAddress}`);
        }
        if (!batchSize) {
            batchSize = 10;
        }
        let receiptManager = GraphQL.getInstance().receipts;
        return receiptManager[typeFunction](owner).then(receiptIds => {
            return RestoreReceipts._restoreAllReceiptEventsAsBatch(receiptIds, contractAddress, owner, 0, batchSize);
        }).then(() => {
            Console.info(`[MongoDB] document events restored for type function ${typeFunction} successfully.`);
        }).catch(error => {
            Console.error(`[MongoDB] Error in restoring receipts for type function ${typeFunction} [Reason] ${error} [Module] : Sync/receipts/restoreAllReceiptsByType`);
        });
    }

    static _restoreAllReceiptEventsAsBatch(receipts, contractAddress, owner, batchStartIndex, batchSize) {
        let receiptsLength = receipts.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(receiptsLength, endIndex);
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts events ${receiptsLength}`)
        let grapQlInstance = GraphQL.getInstance();
        let promiseArray = [];
        for (let index = startIndex; index < endIndex; index++) {
            let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
            contractAddress = NetworkUtils.getTransactionData(receipts[index]).address
            promiseArray.push(grapQlInstance.getEventDetails(receipts[index], NetworkUtils.getEventType('receipt'), 'RecordID', ["onReceipStatusUpdateV1", "onStatusUpdateV1", "onDocumentUpdate"]).then(receiptEvents => {
                return RestoreReceipts.restoreReceiptIdEvents(transactionsDB, contractAddress, receiptEvents, owner);
            }))
        }

        return Promise.all(promiseArray).then(result => {
            Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts events ${receiptsLength}`);
            if (endIndex === receiptsLength) {
                return;
            }
            return RestoreReceipts._restoreAllReceiptEventsAsBatch(receipts, contractAddress, owner, batchStartIndex + 1, batchSize);
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for receipts events ${receiptsLength}`)
            if (endIndex === receiptsLength) {
                return;
            }
            return RestoreReceipts._restoreAllReceiptEventsAsBatch(receipts, contractAddress, owner, batchStartIndex + 1, batchSize);
        });
    }

    static restoreReceiptIdEvents(transactionsDB, contractAddress, receiptEvents, owner) {
        let transactionsData = [], receiptEventsData = []
        receiptEventsData = NetworkUtils.getAllEventDataByName(receiptEvents)
        for (let index in receiptEventsData) {
            let event = receiptEventsData[index];
            let label = ReceiptEvents[event.event].label;
            event.args.rId = NetworkUtils.getTransactionId(event.args.rId, contractAddress);
            let id = event.event === "onDocumentUpdate" ? NetworkUtils.getTransactionId(event.args.docId, contractAddress) : event.args.rId;
            let metaData = NetworkDataFormat.getMetaInfo(id, event, label);
            metaData.step = event.args.step;
            metaData.buyer = NetworkUtils.getChecksumAddress(event.args.buyer);
            metaData.status = (event.args.status || event.args.status === 0) ? event.args.status : event.args.docStatus;
            metaData.seller = NetworkUtils.getChecksumAddress(event.args.seller);
            metaData.docType = event.args.docType;
            metaData.step = (metaData.step || metaData.step === 0) ? metaData.step.toString() : metaData.step;
            metaData.status = metaData.status.toString();
            transactionsData.push(
                {
                    id,
                    owner,
                    metaData
                }
            );
        }
        return transactionsDB.addTransactions(transactionsData).then(() => {
            Console.log('[MongoDB] Events Restored')
        }).catch(error => {
            Console.error(`Unable to restore events, Reason: ${error}`)
        });
    }

}
export default RestoreReceipts;
