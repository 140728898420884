import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
class NetworkUtils {
    static getLatestContractAddressFromName(contractName) {
        let graphDB = ParamConnector.getInstance().getGraphDB();
        let myProfile = Utils.getFromLocalStorage("myProfile"), organizationId = "";
        try {
            myProfile = JSON.parse(myProfile);
            organizationId = myProfile.configObj.publicAddress[0];
        } catch (e) { }
        let paramId = Utils.getParamId();
        // return graphDB.ePhoneBook.isEnterpriseNode(paramId)
        return graphDB.ePhoneBook.getEntry(paramId).then(async nodeObj => {
            let nodeId;
            if (!nodeObj) {
                //TODO: Get masternode Id, and pass that id to here.
                nodeId = await graphDB.ePhoneBook.getAllMasterNodes();
            } else {
                nodeId = nodeObj.primaryKey;
            }
            return graphDB.versionControl.getLatestContractAddressFromNameAndNodeId(contractName, nodeId);
        })
    }

    static getTransactionInfo(connection, txnHash) {
        return new Promise((resolve, reject) => {
            NetworkUtils._getTransactionInfo(connection, txnHash, (err, data) => {
                if (err) {
                    return reject(err);
                }
                resolve(data);
            });
        })

    }
    static _getTransactionInfo(connection, txnHash, callback) {
        connection.eth.getTransactionReceipt(txnHash, (err, data) => {
            if (!err && !data) {
                setTimeout(() => {
                    NetworkUtils._getTransactionInfo(connection, txnHash, callback);
                }, 500)
                return;
            }
            callback(err, data);
        })
    }
}
export default NetworkUtils;