import ParamConnector from '../../param-connector';
import * as Utils from '../utils';

class WorkflowReport {

    static getConsensusCount(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getConsensusNumbers(templateId)
    }
    static getConsensusIdsByStep(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getConsensusIdsByStep(templateId)
    }

    static getAllGraphs(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getAllGraphs(templateId)
    }

    static getGraphDataset(graphId, templateId, fromDate, toDate) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getGraphDataset(graphId, templateId, fromDate, toDate)
    }

    static saveGraph(templateId, data) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.saveGraph(templateId, data);
    }

    static createDefaultGraphs(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.createDefaultGraphs(templateId);
    }

    static isDefaultGraphPresent(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.isDefaultGraphPresent(templateId);
    }
}
export default WorkflowReport;