import GraphQl from '../index'
import { gql } from '@apollo/client';
import Database from '../../../database/index';
import * as NetworkUtils from '../../../../src/util/utils';
import RestoreVendorManagement from '../sync/vendor';
import Console from '../../../logger/index';

class VendorEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient
        this.dbInstance = Database.getInstance().getDB()
    }

    registerEvents(paramId) {
        let that = this
        let addInviteOptions = {
            query: gql`
               subscription onAddInvited($paramId: [String]){
                    onAddInvited(VendorOwnerIDs: $paramId){
                        contractAddress,
                        invitationID,
                        owner,
                        buyer,
                        vendor,
                        templateId,
                        templateType,
                        txnMode
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(addInviteOptions).subscribe({
            next(data) {
                that._onInviteAdded(data)
            },
            error(error) {
                Console.error(`Unable to get InviteAdded details form network, Reason: ${error}`);
            },
        });

        let inviteOption = {
            query: gql`
            subscription onInvited($paramId: [String]){
                onInvited(VendorOwnerIDs: $paramId){
                    contractAddress,
	                invitationID,
                    owner,
                    buyer,
                    vendor,
                    templateId,
                    templateType,
                    txnMode
	                }
                }   
            `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(inviteOption).subscribe({
            next(data) {
                that._onInvited(data)
            },
            error(error) {
                Console.error(`Unable to get onInvited details form network, Reason: ${error}`);
            },
        });

        let updateInviteOptions = {
            query: gql`
               subscription onInviteUpdated($paramId: [String]){
                onInviteUpdated(VendorOwnerIDs: $paramId){
                    contractAddress,
                    invitationID,
                    templateId,
                    status,
                    step,
                    buyer,
                    vendor,
                    templateType
                  }
                }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(updateInviteOptions).subscribe({
            next(data) {
                that._onInviteUpdated(data)
            },
            error(error) {
                Console.error(`Unable to get Inviteupdated details form network, Reason: ${error}`);
            },
        });

        let invitationTemplateOption = {
            query: gql`
                subscription onInvitationTemplateAdded($paramId: [String]){
                    onInvitationTemplateAdded(VendorOwnerIDs: $paramId){
                      contractAddress,
                      templateId,
                      owner,
                      templateType,
                      txnMode
                    }
                  }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(invitationTemplateOption).subscribe({
            next(data) {
                that._onInvitationTemplateAdded(data)
            },
            error(error) {
                Console.error(`Unable to get invitationTemplate details form network, Reason: ${error}`);
            },
        });

        let evalRequestOptions = {
            query: gql`
            subscription onEvalRequestAdded($paramId: [String]){
                onEvalRequestAdded(VendorOwnerIDs: $paramId){
                  contractAddress,
                  evalReqID,
                  buyer,
                  buyer,
                  invitationId,
                  docId
                }
            }    
            `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(evalRequestOptions).subscribe({
            next(data) {
                that._onEvalRequestAdded(data)
            },
            error(error) {
                Console.error(`Unable to get EvalRequest details form network, Reason: ${error}`);
            },
        });

        let publicInvitationTemplateOption = {
            query: gql`
                subscription onPublicInvitationTemplateAdded($paramId: [String]){
                    onPublicInvitationTemplateAdded(VendorOwnerIDs: $paramId){
                        templateId,
                        contractAddress,
                        owner,
                        txnMode,
                        templateType
                    }
                  }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(publicInvitationTemplateOption).subscribe({
            next(data) {
                that._onInvitationTemplateAdded(data)
            },
            error(error) {
                Console.error(`Unable to get publicInvitationTemplate details form network, Reason: ${error}`);
            },
        });
        Console.log("Registered Events for Vendor", paramId);
    }

    _emitOnInviteAdded(invitationId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("onAddInvite", invitationId);
    }
    _emitOnInviteUpdated(invitationId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("onInviteUpdated", invitationId);
    }

    _emitOnEvalRequestAdded(evalId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("onEvalRequestAdded", evalId);
    }

    _emitInvitationTemplate(templateId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("onInvitationTemplateAdded", templateId);
    }

    _onInvited(subscriptionDataJSON) {
        Console.info(`[GraphDB] Got _onInvited event`, subscriptionDataJSON);
        debugger
        let dataJSON = {};
        dataJSON.args = subscriptionDataJSON.data.onInvited
        dataJSON.args.invitationId = NetworkUtils.getTransactionData(subscriptionDataJSON.data.onInvited.invitationID).id
        dataJSON.args.templateType = subscriptionDataJSON.data.onInvited.templateType.toString()
        dataJSON.args.contractAddress = subscriptionDataJSON.data.onInvited.contractAddress
        return RestoreVendorManagement.restoreInvitation(dataJSON.args.invitationId, dataJSON.args.templateType, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onInvited", dataJSON.args.invitationId, null);
        }).then(() => {
            dataJSON.args.invitationId = NetworkUtils.getTransactionId(dataJSON.args.invitationId, dataJSON.args.contractAddress);
            let owner = dataJSON.args.templateType === '0' ? dataJSON.args.buyer : dataJSON.args.vendor;
            return this.dbInstance.notifications.addNotification(dataJSON.args.invitationId, "onBuyerInvited", "invitation", owner).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.invitationId, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.invitationId}, reason: ${error.toString()}`);
        });
    }


    _onInviteAdded(subscriptionDataJSON) {
        Console.info(`[GraphDB] Got _onInviteAdded event`, subscriptionDataJSON);
        debugger
        let dataJSON = { args: {} };
        dataJSON.args.invitationId = NetworkUtils.getTransactionData(subscriptionDataJSON.data.onAddInvited.invitationID).id
        dataJSON.args.templateType = subscriptionDataJSON.data.onAddInvited.templateType.toString()
        dataJSON.args.contractAddress = subscriptionDataJSON.data.onAddInvited.contractAddress

        return RestoreVendorManagement.restoreInvitation(dataJSON.args.invitationId, dataJSON.args.templateType, dataJSON.args.contractAddress).then(() => {
            Console.log(`[GraphDB] Added invite info to graph ${dataJSON.args.invitationId}`);
            return this._emitOnInviteUpdated(dataJSON.args.invitationId);
        }).catch(e => {
            Console.error(`Unable to get invite status, Reason: ${e.toString()}`);
        })
    }

    _onInviteUpdated(subscriptionDataJSON) {
        Console.info(`[GraphDB] Got _onInviteUpdated event`, subscriptionDataJSON);
        debugger
        let dataJSON = { args: {} };
        dataJSON.args.invitationId = NetworkUtils.getTransactionData(subscriptionDataJSON.data.onInviteUpdated.invitationID).id
        dataJSON.args.templateType = subscriptionDataJSON.data.onInviteUpdated.templateType.toString()
        dataJSON.args.contractAddress = subscriptionDataJSON.data.onInviteUpdated.contractAddress
        return RestoreVendorManagement.restoreInvitation(dataJSON.args.invitationId, dataJSON.args.templateType, dataJSON.args.contractAddress).then(() => {
            Console.log(`[GraphDB] Update invite info to graph ${dataJSON.args.invitationId}`);
            return this._emitOnInviteUpdated(dataJSON.args.invitationId);
        }).catch(e => {
            Console.error(`Unable to get inviteUpdate status, Reason: ${e.toString()}`);
        })
    }

    _onInvitationTemplateAdded(subscriptionDataJSON) {
        debugger;
        Console.info(`[GraphDB] Got _onInvitationTemplateAdded event`, subscriptionDataJSON);
        let dataJSON = {
            args: {}
        };
        dataJSON.args.templateId = NetworkUtils.getTransactionData(subscriptionDataJSON.data.onInvitationTemplateAdded.templateId).id
        dataJSON.args.contractAddress = subscriptionDataJSON.data.onInvitationTemplateAdded.contractAddress
        return RestoreVendorManagement.restoreVendorTemplate(dataJSON.args.templateId, dataJSON.args.owner, dataJSON.args.contractAddress).then(() => {
            Console.log(`[GraphDB] invitationTemplate info to graph ${dataJSON.args.templateId}`);
            return this._emitInvitationTemplate(dataJSON.args.templateId);
        }).catch(e => {
            Console.error(`Unable to get InvitationTemplate status, Reason: ${e.toString()}`);
        })
    }

    _onPublicInvitationTemplateAdded(subscriptionDataJSON) {
        Console.info(`[GraphDB] Got _onPublicInvitationTemplateAdded event`, subscriptionDataJSON);
        debugger
        let dataJSON = { args: {} };
        dataJSON.args.templateId = NetworkUtils.getTransactionData(subscriptionDataJSON.data.onInvitationTemplateAdded.templateID).id
        dataJSON.args.contractAddress = subscriptionDataJSON.data.onInvitationTemplateAdded.contractAddress
        return RestoreVendorManagement.restoreVendorTemplate(dataJSON.args.templateId, NetworkUtils.getPublicKey(), dataJSON.args.contractAddress).then(() => {
            Console.log(`[GraphDB] invitationTemplate info to graph ${dataJSON.args.templateId}`);
            return this._emitInvitationTemplate(dataJSON.args.templateId);
        }).catch(e => {
            Console.error(`Unable to get InvitationTemplate status, Reason: ${e.toString()}`);
        })
    }

    _onEvalRequestAdded(subscriptionDataJSON) {
        Console.info(`[GraphDB] Got _onEvalRequestAdded event`, subscriptionDataJSON);
        debugger
        let dataJSON = { args: {} };
        dataJSON.args = subscriptionDataJSON.data.onEvalRequestAdded
        dataJSON.args.evalId = NetworkUtils.getTransactionData(dataJSON.args.evalReqID).id
        return RestoreVendorManagement.restoreEvalRequest(dataJSON.args.evalId, dataJSON.args.invitationId, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("onEvalRequestAdded", dataJSON.args.evalId, null);
        }).then(() => {
            // dataJSON.args.evalId = NetworkUtils.getTransactionId(dataJSON.args.evalId, dataJSON.args.contractAddress);
            dataJSON.args.invitationId = NetworkUtils.getTransactionId(dataJSON.args.invitationId, dataJSON.args.contractAddress);
            return this.dbInstance.notifications.addNotification(dataJSON.args.evalReqID, "onEvalRequestAdded", "evalRequest", dataJSON.args.buyer).then(() => {
                this.dbInstance.events.emit("notification", dataJSON.args.evalReqID, null);
            });
        }).catch(error => {
            Console.error(`Unable to restore step ${dataJSON.args.evalReqID}, reason: ${error.toString()}`);
        });
    }

}

export default VendorEvents;