
import React from 'react';
import { Row, Col, Drawer, message } from "antd";
import './index.less';
import * as APP_CONSTANTS from '../../../constants/AppConstants';
import NetworkBridge from '../../../util/network-bridge/index';
import AttachWorkFlowDetail from '../AttachWorkFlowDetail';
import Console from '../../../logger/index';
import FlyingBird from '../../../components/Widgets/FlyingBird';
import CircularProgress from '../../../components/CircularProgress';
import NoData from '../../components/no-Data';
import { Images } from '../../../constants/AppConstants';

class AttatchWorkFlow extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDrawerVisible: true,
            isDetailDrawerVisible: false,
            showDrawerLoader: true,
            templateData: [],
            templateDetail: {},
            currentState: this.props.currentState
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps")
        this.setState({ isDrawerVisible: true, isDetailDrawerVisible: false })
    }

    componentDidMount() {
        this.getTemplateData();
    }

    getTemplateData = () => {
        const templateRepoManager = NetworkBridge.getTemplateRepoManager();
        templateRepoManager.getAllTemplates().then(templates => {
            let promiseArray = [];
            templates = templates.templates;
            for (let index in templates) {
                promiseArray.push(templateRepoManager.getTemplate(templates[index]));
            }
            return Promise.all(promiseArray);
        }).then(templateData => {
            Console.log("Template Data:", templateData);
            this.setState({
                templateData
            })
        }).catch(err => {
            message.error("Error in getting Templates")
            Console.error(err);
        }).finally(() => {
            this.setState({
                showDrawerLoader: false
            })
        })
    }

    hideDrawer = (workflowDetails, workflowTxnType, selectedState) => {
        console.log("hideDrawer")
        workflowDetails['transactionType'] = workflowTxnType;
        this.setState({ isDrawerVisible: false, isDetailDrawerVisible: false })
        setTimeout(() => {
            this.props.onClose(workflowDetails, workflowTxnType, selectedState);
        }, 1000)
    }

    showDetailDrawer = (index) => {
        let templateDetail = this.state.templateData[index];
        this.setState({
            isDetailDrawerVisible: true,
            templateDetail
        })
    }

    hideDetailDrawer = () => {
        this.setState({ isDetailDrawerVisible: false, templateDetail: {} })
    }

    showAllTemplates = () => {

        let cards = this.state.templateData.map((item, index) => {
            return (
                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <FlyingBird
                        className="workflow-card"
                        title={item.title}
                        description={item.metaData.description ? item.metaData.description : ""}
                        count={`${item.stepIds ? item.stepIds.length : ''} Steps`}
                        callBack={() => this.showDetailDrawer(index)}
                        logoStyle={{ maxWidth: '1.5rem' }}
                    />
                </Col>
            )
        })
        return (
            cards.length > 0
                ?
                <Row style={{ margin: '0.5rem', width: '100%' }}>
                    {cards}
                </Row>
                :
                <div style={{ marginTop: "30rem" }}>
                    <NoData noDataIcon={Images.noWorkflows} title="No workflows are there in your account, Please click workflow builder to add workflow." />
                </div>
        )
    }

    render() {
        const title = <h2 style={{ margin: 0 }}>Select Workflow</h2>
        return (
            <Row>
                <Drawer
                    className="attach-workflow"
                    title={title}
                    onClose={this.hideDrawer}
                    visible={this.state.isDrawerVisible}
                >
                    {
                        this.state.showDrawerLoader ?
                            <CircularProgress />
                            :
                            <Row className="drawercontainer">
                                {this.showAllTemplates()}
                            </Row>
                    }

                </Drawer>

                {
                    this.state.isDetailDrawerVisible &&
                    <AttachWorkFlowDetail onWorkflowButtonClicked={this.props.onWorkflowButtonClicked} onClose={this.hideDrawer} onDetailClose={this.hideDetailDrawer} templateDetail={this.state.templateDetail} currentState={this.state.currentState} />
                }
            </Row>
        )
    }
}

export default AttatchWorkFlow;
