import React from "react";
import { Card, message } from 'antd';
import CopyToClipBoard from 'react-copy-to-clipboard';
import QRCode from "qrcode.react";
import { Row, Col, Icon, Button } from "antd";
import IRNError from '../../../assets/images/IRN_Errors.svg';
import * as Utils from '../../../util/utils';
import './index.less';

const Contact = ({ paramId, email }) => {
  let currentTheme = Utils.getFromLocalStorage('theme');
  let paramIdTextStyle = {
    padding: "0.5rem",
    background: "#f5f5f5",
    marginTop: "1rem"
  }
  if (currentTheme === 'THEME_TYPE_DARK') {
    paramIdTextStyle = {
      background: "transparent",
      border: "1px solid",
      padding: "0.5rem",
      marginTop: "1rem"
    }
  }
  return (
    <Card title={<h2 style={{ marginBottom: 0 }}>Param ID</h2>} className="contact-card">
      <center>
        {
          paramId
            ?
            <QRCode value={paramId} style={{ marginTop: "1rem" }} />
            :
            <img src={IRNError} alt="loader" width={125} />
        }
        <div
          style={paramIdTextStyle}
        >
          <span style={{ overflowWrap: "break-word", fontSize: '0.6rem' }}>{paramId ? paramId : "You don't have a param id yet!"}</span>
        </div>
        <br />
        {
          paramId
            ?
            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <a href={`mailto:${email}?subject=My%20Param%20ID&body=This is my paramId: ${paramId}`} target="_top"><Button
                  style={{ backgroundColor: "#e8e1ff", color: "#6236ff" }}
                  size="small"
                >
                  <Icon type="mail" id="contactEmailIcon" /> E-Mail QR Code
            </Button></a>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <CopyToClipBoard text={paramId} onCopy={() => { message.success('Address copied') }}>
                  <Button
                    style={{ backgroundColor: "#6236ff", color: "#e8e1ff" }}
                    size="small"
                  >
                    <Icon type="copy" /> Copy Address
                </Button>
                </CopyToClipBoard>
              </Col>
            </Row>
            :
            null
        }
      </center>
    </Card>
  );
};

export default Contact;
