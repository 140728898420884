// import GraphDB from '../graphDB/index';
import ECIES from '../../../../src/util/ecies';
import * as NetworkUtils from '../../../../src/util/utils';
import Console from '../../../logger';
import NetworkDataFormat from '../../nosql/Utils/network-data-format';
import { ContactEvents } from '../../../../src/param-network/utils/event-names';
import ParamConnector from '../../../../src/param-connector';

class RestoreContacts {
    static restoreContact(contactId, contractAddress, owner, paramId) {
        let contactsInstance = ParamConnector.getInstance().getDB().contacts;
        return ParamConnector.getInstance().getNetwork().getContactManager(contractAddress).getContact(contactId)
            .then(contact => {
                let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
                if (contact[2] === "2") {
                    contact[1] = ECIES.decrypt(privateKey, contact[1]);
                }
                return contactsInstance.updateContact(contactId, contact[1], owner, contact[3], paramId);
            });
    }
    static restoreAllContacts(contractAddress, owner, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("contact", `Trying to get all the contacts from ${contractAddress}`);
        }
        return ParamConnector.getInstance().getNetwork().getContactManager(contractAddress).getAllContacts(owner).then(contactIds => {
            Console.log(`[MongoDB] Got contacts from ${contractAddress} metainfo from network.`);
            let contactPromises = [];
            for (let index = 0; index < contactIds.length; index++) {
                let contactId = contactIds[index].toString();
                contactId = NetworkUtils.getTransactionId(contactId, contractAddress);
                let contactPromise = RestoreContacts.restoreContact(contactId, contractAddress, owner)
                contactPromises.push(contactPromise);
            }
            return Promise.all(contactPromises);
        });
    }
    static restoreAllContactEvents(contractAddress, owner, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("contact", `Trying to get all the events from ${contractAddress}`);
        }
        let contactManager = ParamConnector.getInstance().getNetwork().getContactManager(contractAddress);
        return contactManager.getAllContacts(owner).then(contactIds => {
            let promiseArray = [];
            let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
            let selfParamId = NetworkUtils.getParamId();
            for (let contactIndex in contactIds) {
                promiseArray.push(contactManager.getAllEventsByContactId(contactIds[contactIndex]).then(contactEvents => {
                    return RestoreContacts.restoreContactIdEvents(transactionsDB, selfParamId, contractAddress, contactEvents);
                }))
            }
            return Promise.all(promiseArray);
        })
    }
    static restoreContactIdEvents(transactionsDB, selfParamId, contractAddress, contactEvents) {
        let addTransactionsPromiseArray = [];
        for (let index in contactEvents) {
            let event = contactEvents[index];
            let label = ContactEvents[event.event].label;
            event.args.contactId = NetworkUtils.getTransactionId(event.args.contactId, contractAddress);
            let metaData = NetworkDataFormat.getMetaInfo(event.args.contactId, event, label);
            let owner = event.args.owner;
            if (!owner) {
                if (selfParamId === event.args.sender || selfParamId === event.args.receiver) {
                    owner = selfParamId;
                }
            }
            let transactionPromise = transactionsDB.addTransaction(event.args.contactId, owner, metaData).catch(error => {
                Console.error(`Unable to restore contact event for ${event.args.contactId}, Reason: ${error}`)
            })
            addTransactionsPromiseArray.push(transactionPromise);
        }
        return Promise.all(addTransactionsPromiseArray);
    }
}
export default RestoreContacts;