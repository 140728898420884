import React, { PureComponent } from "react";
import { Input, Icon, Button, message, Card, Form } from "antd";

import * as Utils from '../../util/utils';
import "./index.less";
import NetworkBridge from "../../util/network-bridge";
import Web3Utils from 'web3-utils';
import UserIcon from '../../assets/images/loginProfile1.svg';
import CircularProgress from "../../components/CircularProgress";
import IconComponent from "../../components/IconComponent";
import { dbURL } from '../../constants/AppConstants';
import * as ConfigObj from '../../param-network/config1.json';
import * as IpfsConfig from '../../param-network/ipfs-config.json';

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.configObj = ConfigObj.privateNodes;
        this.ipfsConfig = IpfsConfig.ipfsNodes;
        this.setConfigObj = ConfigObj.default;
        this.state = {
            headingText: "Please enter the vendor email id and 12 words",
            heading: "mobileNo",
            mobileNo: "",
            admin: Utils.getFromLocalStorage('otpToken'),
            adminEmail: JSON.parse(Utils.getFromLocalStorage('profile')).email
        }

    }

    verifyMobileNumber = (rule, value, callback) => {
        if (this.state.heading === "OTP") {
            callback();
        }
        var mobileno = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value && !value.match(mobileno)) {
            callback("Please input a valid email");
        }
        else {
            callback();
        }
    }
    verifyPhrase = (rule, value, callback) => {
        console.log(value)
        if (value && value.split(' ').length !== 12) {
            callback("Please input all 12 Words");
        }
        else {
            callback();
        }
    }

    // validateOTP = (mobileNo, otp) => {
    //     Utils.clearLocalStorage();
    //     return NetworkBridge.getKeyStoreManager().verifyOTP(mobileNo, otp).then(res => {
    //         console.log(res)

    //         // if (!mobileNo.startsWith('+91')) {
    //         //   mobileNo = '+91' + mobileNo;
    //         // }
    //         Utils.setLocalStorage("accountNo", mobileNo);
    //         Utils.setLocalStorage("encryptedMnemonic", res[0].mnemonic);
    //         Utils.setLocalStorage("privateKey", res[0].privateKey);
    //         Utils.setLocalStorage("publicKey", res[0].publicKey);
    //         Utils.setLocalStorage("param_id", Web3Utils.toChecksumAddress(res[0].paramId));
    //         // this.props.history.push({ pathname: '/password', state: { source: "create_otp" } });
    //         //Profile Creation
    //         // nHLzpsjqmIKB02Phyc4+cmd4LQQcl10SmxNKudnNNSo=
    //         Utils.setLocalStorage('organizationId', "");
    //         Utils.setLocalStorage("url", "ws://34.224.243.54:8546");
    //         Utils.setLocalStorage('dbURL', dbURL);
    //         Utils.setLocalStorage('privateUrl', this.configObj[0].privateUrl);
    //         Utils.setLocalStorage('configObj', JSON.stringify(this.setConfigObj));
    //         Utils.setLocalStorage('versionControl', "0x22AD1FF3161D6D40EC7503cB88A4028Aa983d139");
    //         let jsonLd = {
    //             "@context": "http://schema.org/",
    //             "@type": "Person",
    //             "name": " ",
    //             "identifier": "",
    //             "address": {
    //                 "@type": "PostalAddress",
    //                 "addressLocality": undefined,
    //                 "addressRegion": undefined,
    //                 "postalCode": undefined,
    //                 "streetAddress": undefined
    //             },
    //             "birthDate": undefined,
    //             "contactPoint": {
    //                 "@type": "ContactPoint",
    //                 "telephone": undefined,
    //                 "contactType": undefined,
    //                 "contactOption": undefined,
    //                 "areaServed": undefined
    //             },
    //             "gender": undefined,
    //             "globalLocationNumber": undefined,
    //             "taxId": undefined,
    //             "vatId": undefined,
    //             "faxNumber": undefined,
    //             "email": mobileNo,
    //             "telephone": undefined,
    //             "url": undefined,
    //             "internalId": undefined,
    //             "hasOccupation": [
    //             ],
    //             "jobTitle": {
    //                 "@type": "DefinedTerm",
    //                 "inDefinedTermSet": undefined,
    //                 "termCode": undefined,
    //                 "name": undefined,
    //                 "url": undefined
    //             },
    //             "worksFor": {
    //                 "@type": "Organization",
    //                 "name": undefined
    //             },
    //             "nationality": {
    //                 "@type": "Country",
    //                 "name": "IN"
    //             },
    //             "logoUrl": undefined,
    //             "city": undefined,
    //             "publicKey": undefined
    //         }
    //         Utils.setLocalStorage('profile', JSON.stringify(jsonLd))
    //         this.props.history.push('/main/landing?init')
    //         message.success("Success");
    //     }).catch(err => {
    //         console.error("Error: ", err);
    //         message.error("Error");
    //     }).finally(() => {
    //         this.setState({
    //             showLoader: false,
    //         });
    //     })
    // }

    // sendOTP = (mobileNo) => {
    //     return NetworkBridge.getKeyStoreManager().sendOTP(mobileNo).then(res => {
    //         message.success("OTP Sent successfully")
    //         this.setState({ heading: 'OTP', headingText: `Enter OTP you received on ${mobileNo}` })
    //     }).catch(err => {
    //         console.error("Error: ", err);
    //         message.error("Error");
    //     }).finally(() => {
    //         this.setState({
    //             showLoader: false
    //         })
    //     })
    // }

    addAccount = (mail, phrase) => {
        return NetworkBridge.getKeyStoreManager().addAccount(mail, phrase, this.state.admin).then(res => {
            message.success("Account added successfully")
            //this.setState({ heading: 'OTP', headingText: `Enter OTP you received on ${mobileNo}` })

        }).catch(err => {
            console.error("Error: " + err);
            message.error("Error: " + err);
        }).finally(() => {
            this.setState({
                showLoader: false
            })
        })
    }

    // resendOtp = () => {
    //     this.setState({ showLoader: true });
    //     return NetworkBridge.getKeyStoreManager().sendOTP(this.state.mobileNo).then(res => {
    //         message.success("OTP Re-Sent successfully")
    //     }).catch(err => {
    //         console.error("Error: ", err);
    //         message.error("Error");
    //     }).finally(() => {
    //         this.setState({
    //             showLoader: false
    //         })
    //     })
    // }

    updateAccountInfo = () => {
        this.setState({
            headingText: "Please enter the vendor email id and 12 words",
            heading: "mobileNo"
        })
    }

    handleClick = () => {

        this.props.form.validateFields((err, values) => {
            if (err) {
                this.setState({ showLoader: false });
                return false;
            }
            else {
                this.addAccount(values["mobileNo"], values["12-words"])
            }
        })
        this.props.form.resetFields()
    }

    handleForgotPassword = (path, type) => {
        if (!type) {
            this.props.history.push(path);
        }
        this.props.history.push(({
            pathname: path,
            state: { account: 'Switch Account' }
        }))
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Card className="login" actions={[<a href="https://param.network" target="_blank">&copy; ⦃param⦄.network</a>]}>
                {this.state.showLoader && <CircularProgress />}
                <div className="login__heading">

                    {
                        this.state.heading === "OTP" &&
                        <IconComponent onClick={this.updateAccountInfo} style={{ marginLeft: "-2em", fontSize: "1.2em" }} type="arrow-left" actionIcon={true} hoverText="Go Back" />
                    }
                </div>
                <center>
                    {
                        <img src={UserIcon} className="login__logo-image" alt="avatar" />
                    }
                </center>
                <center>
                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-4 login_welcomeText">
                        {`Welcome, ${this.state.adminEmail}`}
                    </h1>
                </center>
                <div className="login__inner">
                    <center>
                        <h5 className="gx-mb-3 login_passwordText">
                            {this.state.headingText}
                        </h5>
                    </center>
                    <Form className="login__form">
                        <Form.Item style={{ marginBottom: "0.35em" }}>
                            {getFieldDecorator(`${this.state.heading}`, {
                                rules: [{
                                    required: true,
                                    message: `Please input email id`,
                                },
                                { validator: this.verifyMobileNumber }],
                                initialValue: this.state.heading === "mobileNo" ? this.state.mobileNo : null
                            })(
                                <Input id="login_mobileNo"
                                    prefix={<Icon type="mail" />}
                                    placeholder={`Enter email id`}
                                    className="login_input"
                                    autoFocus
                                />
                            )
                            }
                        </Form.Item>
                        <Form.Item style={{ marginBottom: "0.35em" }}>
                            {getFieldDecorator(`12-words`, {
                                rules: [{
                                    required: true,
                                    message: `Please input 12 Words`,
                                },
                                { validator: this.verifyPhrase }],
                            })(
                                <Input id="login_phrase"
                                    placeholder={`Enter 12 words`}
                                    className="login_input"
                                    autoFocus
                                />
                            )
                            }
                        </Form.Item>
                    </Form>
                    {/* {this.state.heading === "mobileNo" &&
            <>
              <p className="gx-text-primary gx-mb-3 gx-mb-sm-4 login__forgotText">
                <span onClick={() => this.handleForgotPassword('/restore')}>Forgot Password</span> | <span onClick={() => this.handleForgotPassword('/restore', 'switch')}>Switch Account</span>
              </p>
              <p onClick={() => this.handleForgotPassword('/landing')} className="gx-text-primary gx-mb-3 gx-mb-sm-4 login__createNew gx-pointer">Create New Account</p>
            </>
          } */}

                    <Button disabled={this.state.showLoader} type="button" className="login__loginBtn" onClick={this.handleClick}>Submit</Button>
                </div >
            </Card >
        );
    }
}
const AdminLogin = Form.create({ name: 'login' })(Login)
export default AdminLogin;