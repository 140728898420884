class PieChartFormatter {
    static disputePieChart(dataFromDb) {
        let data = [
            { type: 'Price', value: dataFromDb.price },
            { type: 'Quantity', value: dataFromDb.quantity },
            { type: 'Quantity & Price', value: dataFromDb.quantityAndPrice },
            { type: 'Pending', value: dataFromDb.pending },
            { type: 'Matched', value: dataFromDb.matched },
        ]
        return data;
    }
}

export default PieChartFormatter;