import React, { Component } from 'react'
import { Divider, Row, Col } from 'antd'
import "./index.less"
class ClientBanner extends React.Component {

    render() {
        return (
            <div className="clientBanner__container">
                <Row>
                    <Col>
                        <div className="clientBanner__logoContainer">
                            <img className="clientBanner__logo"
                                src={this.props.imageURL}
                                alt={`logo`}
                            />
                        </div>
                    </Col>
                    <Divider type="vertical" style={{ height: "max-content" }} />
                    <Col>
                        <div style={{ marginTop: "2rem" }}>
                            <Row>
                                <h1 className="clientBanner__title">{this.props.clientText}</h1>
                            </Row>
                            <Row>
                                <h1 className="clientBanner__subTitle" style={{ color: "#535353" }}>on ⦃param⦄ network</h1>
                            </Row>
                            <Row>
                                <h1 className="clientBanner__subTitle">streamlining B2B commerce collaboration</h1>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default ClientBanner