import Console from '../../../logger/index';
import { gql } from '@apollo/client';
import GraphQl from '../index'
import Database from '../../../database/index';
import * as NetworkUtils from '../../../../src/util/utils'
import RestoreSubscribers from '../sync/subscriber';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import ECIES from '../../../../src/util/ecies';
import * as DBUtils from '../../../database/nosql/Utils/utils'
import GraphQL from '../index';


class SubscribersEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient;
        this.dbInstance = Database.getInstance().getDB()
        // this.qraphQl = GraphQl.getInstance()
    }
    registerEvents(paramId) {
        // onSubscriber
        let onSubscriberOptions = {
            query: gql`
               subscription onSubscriber($paramId: [String]){
                    onSubscriber(ReceiptOwnerID: $paramId){
                        contractAddress,
                        rId,
                        Subscriber,
                        Seller,
                        buyer,
                        sId,
                        docContractAddress
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(onSubscriberOptions).subscribe({
            next(data) {
                that._onSubscriber(data)
            },
            error(error) {
                Console.error(`Unable to get onSubscriber details form network, Reason: ${error}`);
            },
        });
        // onConsent
        let onConsentOptions = {
            query: gql`
               subscription onSubscriberConsent($paramId: [String]){
                onSubscriberConsent(ReceiptOwnerID: $paramId){
                    contractAddress,
                    rId,
                    owner,
                    _type,
                    docContractAddress
                  }
                }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(onConsentOptions).subscribe({
            next(data) {
                that._onConsent(data)
            },
            error(error) {
                Console.error(`Unable to get onConsent details form network, Reason: ${error}`);
            },
        });
    }

    _emitOnSubscriberAdded(rId, subscriberInfo) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("OnSubscriber", rId, subscriberInfo);
    }

    _emitOnConsent(rId, subscriberInfo) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("OnConsent", rId, subscriberInfo);
    }

    _onSubscriber = (subscriptionDataJSON) => {
        Console.info(`[GraphDB] Got _onSubscriberAdded event`, subscriptionDataJSON);
        let dataJSON = {}
        let dbReceiptId = subscriptionDataJSON.data.onSubscriber.rId;
        dataJSON.args = subscriptionDataJSON.data.onSubscriber
        dataJSON.args.rId = NetworkUtils.getTransactionData(subscriptionDataJSON.data.onSubscriber.rId).id
        dataJSON.args.subscriber = dataJSON.args.Subscriber
        let subsId = dataJSON.args.sId;
        let self = NetworkUtils.getParamId(), receiptKey = NetworkUtils.getFromLocalStorage("privateKey"), promise;
        if (dataJSON.args.subscriber !== self) {
            promise = this.dbInstance.receipts.doesExist(dbReceiptId).then(res => {
                if (!res) {
                    return;
                }
                return this.dbInstance.subscribers.addSubscriberForParticipant(dbReceiptId, dataJSON.args.subscriber);
            })
        }
        else {
            let graphQlInstance = new GraphQL.getInstance()
            promise = graphQlInstance.subscribers.getReceiptSubscriber(NetworkUtils.getTransactionId(subsId, dataJSON.args.docContractAddress)).then(subscriberData => {
                if (subscriberData['payload'] !== "") {
                    receiptKey = ECIES.decrypt(NetworkUtils.getFromLocalStorage("privateKey"), subscriberData['payload']);
                }
                return RestoreSubscribers.restoreAllReceiptsInTree(dataJSON.args.rId, dataJSON.args.docContractAddress, dataJSON.args.subscriber, receiptKey);
            })
        }

        return promise.then(() => {
            Console.info(`[GraphDB] Add subscriber for ${dataJSON.args.rId}`);
            this._emitOnSubscriberAdded(dataJSON.args.rId, dataJSON.args.subscriber);
        }).catch(e => {
            Console.error(`Unable to add subscriber, Reason: ${e}`);
        })
    }

    _onConsent = (subscriptionDataJSON) => {
        Console.info(`[GraphDB] Got _onConsent event`, subscriptionDataJSON);
        let dataJSON = { args: {} }
        dataJSON.args._type = subscriptionDataJSON.data.onSubscriberConsent._type
        dataJSON.args.rId = subscriptionDataJSON.data.onSubscriberConsent.rId
        dataJSON.args.owner = subscriptionDataJSON.data.onSubscriberConsent.owner
        dataJSON.args.docContractAddress = subscriptionDataJSON.data.onSubscriberConsent.docContractAddress
        dataJSON.args.contractAddress = subscriptionDataJSON.data.onSubscriberConsent.contractAddress

        let type = dataJSON.args._type.toString();
        // dataJSON.args.rId = NetworkUtils.getTransactionId(dataJSON.args.rId, dataJSON.args.docContractAddress);

        let consent = {};
        if (type === "1") {
            type = "seller";
        } else {
            type = "buyer";
        }
        let that = this;
        consent[`${type}`] = { "primaryKey": dataJSON.args.rId + "", "addres": dataJSON.args.owner }

        return this.dbInstance.receipts.doesExist(dataJSON.args.rId).then(doesExist => {
            if (doesExist) {
                return this.dbInstance.subscribers.addConsent(dataJSON.args.rId, consent).then(data => {
                    let consent = {
                        id: `${dataJSON.args.rId}`,
                        type: type,
                        value: [dataJSON.args.owner]
                    };
                    console.info(`[GraphDB] Add consent for ${dataJSON.args.rId}`);
                    that._emitOnConsent(dataJSON.args.rId, consent);
                }).catch(e => {
                    console.info(`[GraphDB] Unable to add consent, reason ${e}`);
                }).finally(() => {
                    let docId = dataJSON.args.rId;
                    let metaData = NetworkDataFormat.getMetaInfo(docId, dataJSON, "On conent");
                    metaData.type = type;
                    return this.dbInstance.transactionsDB.addTransaction(docId, dataJSON.args.owner, metaData).catch(error => {
                        Console.error(`Unable to add subscriber info for ${docId}, Reason: ${error}`)
                    })
                })
            }
            return;
        })
    }
}
export default SubscribersEvents;