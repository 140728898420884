import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const VendorManagement = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/default`} />
    <Route
      path={`${match.url}/supplier`}
      component={asyncComponent(() => import("./Suppliers/index"))}
    />
    <Route
      path={`${match.url}/customer`}
      component={asyncComponent(() => import("./Customers/index.js"))}
    />
    <Route
      path={`${match.url}/onboarding`}
      component={asyncComponent(() => import("./OnBoarding/index"))}
    />
    <Route
      path={`${match.url}/template`}
      component={asyncComponent(() => import("./Template/index"))}
    />
    <Route
      path={`${match.url}/invite`}
      component={asyncComponent(() => import("./invite.js"))}
    />
    <Route
      path={`${match.url}/add`}
      component={asyncComponent(() => import("./invite.js"))}
    />
  </Switch>
);

export default VendorManagement;
