import React, { Component } from 'react';
import { Card, Button, message } from 'antd';
import Tags from '../../components/others/Tags';
import * as Utils from '../../../util/utils';
import './setting.less';


class ColumnsConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemProperties: []
        }
    }

    componentDidMount() {
        let itemProperties = Utils.getFromLocalStorage('itemProperties');
        this.setState({
            itemProperties
        })
    }

    getTags = (tags) => {
        this.setState({
            itemProperties: [...tags]
        })
    }

    deleteColumnFromTable = (tags, deletedTag) => {
        console.log(tags, deletedTag);
        this.setState({
            itemProperties: [...tags]
        })
    }

    saveColumns = () => {
        let columnProperties = Utils.getFromLocalStorage('itemProperties')
        if (columnProperties.length === 0 && this.state.itemProperties.length === 0) {
            message.error('Please add atleast one tag to continue')
            return;
        }
        Utils.setLocalStorage('itemProperties', this.state.itemProperties);
        message.success("Receipt configuration saved successfully");
    }

    render() {
        return (
            <Card className="setting__card columnCard"
                title={<h2 className="setting__nomargin">Configure Receipts</h2>}
                extra={<Button className="gx-mb-0" type="primary" onClick={this.saveColumns}>Save</Button>} >
                <Tags getTags={this.getTags}
                    deleteColumnFromTable={this.deleteColumnFromTable}
                    tags={this.state.itemProperties}
                    style={{ marginBottom: '0.7rem' }}
                />

            </Card>
        )
    }
}

export default ColumnsConfig;