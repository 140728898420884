import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import NetworkBridge from '.';
import GraphQL from '../../param-libs/graph-ql';


class Contact {
    static addContact(address, info, txnType = "1") {
        let contactManager = GraphQL.getInstance().contacts
        return contactManager.addContact(address, info, txnType).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            // signedData = signedData.substring(2);
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamContactImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     // config.contracts = [{ "contact": config.address }]
        //     // paramNetwork.setConfig(config.address);
        //     let contactManager = paramNetwork.getContactManager(config.address);
        //     return contactManager["addContact"](address, info, txnType, options);
        // })
    }

    static updateContact(contactId, info, txnType = "1") {
        let contactManager = GraphQL.getInstance().contacts
        let transactionData = Utils.getTransactionData(contactId)
        return contactManager.updateContact(transactionData.id, info, txnType).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return Utils.getConfig("ParamContactImpl").then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let transactionData = Utils.getTransactionData(contactId);
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     // paramNetwork.setConfig(config);
        //     let contactManager = paramNetwork.getContactManager(transactionData.address);
        //     return contactManager["updateContact"](transactionData.id, info, txnType, options);
        // })
    }

    static draftContact(info, txnType = "2") {
        return Utils.getConfig("ParamContactImpl").then(config => {
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let contactManager = paramNetwork.getContactManager(config.address);
            return contactManager["draftContact"](info, txnType, options);
        })
    }

    static inviteContact(contactId) {
        return Utils.getConfig("ParamContactImpl").then(config => {
            let transactionData = Utils.getTransactionData(contactId);
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let contactManager = paramNetwork.getContactManager(transactionData.address);
            return contactManager["invitedContact"](transactionData.id, options)
        }).then(res => {
            return Contact.getContact(contactId).then(receiverContact => {
                // emailType
                // -- 0: invite
                // -- 1: share
                let emailType = "0";
                return Contact.sendEmail(receiverContact, emailType);
            })
        })
    }

    static sendEmail(receiverContact, emailType) {
        let emailManager = NetworkBridge.getEmailManager();
        let profile = Utils.getFromLocalStorage('profile');
        profile = JSON.parse(profile);
        let jsonLd = {
            email: receiverContact.email,
            emailType: emailType,
            senderContact: {
                faxNumber: profile.faxNumber,
                name: profile.name,
                identifier: profile.identifier,
                taxId: profile.taxId,
                telephone: profile.telephone,
                email: profile.email,
                publicKey: profile.publicKey,
                contactId: receiverContact['@id'],
                randomString: receiverContact.randomString,
                emailType: emailType,
                publicKey: Utils.getFromLocalStorage('publicKey'),
                address: profile.address,
                contactPoint: profile.contactPoint,
                nationality: profile.nationality,
                worksFor: profile.worksFor
            }
        }
        jsonLd = JSON.stringify(jsonLd);
        jsonLd = btoa(jsonLd);
        return emailManager.sendMail("1", jsonLd, "");
    }

    static async shareContact(contactList) {
        let emailType = "1";
        for (let index in contactList) {
            await Contact.sendEmail(contactList[index], emailType);
        }
        return Promise.resolve();
    }

    static acceptedInvite(paramId, contactInfo, txnType, contactIdAtSenderEnd, randomString) {
        return Utils.getConfig("ParamContactImpl").then(config => {
            let transactionData = Utils.getTransactionData(contactIdAtSenderEnd);
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let contactManager = paramNetwork.getContactManager(transactionData.address);
            return contactManager["acceptedInvite"](paramId, contactInfo, txnType, transactionData.id, randomString, options);
        })
    }

    static acknowledgement(senderContactId, paramId, receiverContactId) {
        return Utils.getConfig("ParamContactImpl").then(config => {
            let senderTransactionData = Utils.getTransactionData(senderContactId);
            let receiverTransactionData = Utils.getTransactionData(receiverContactId);
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let contactManager = paramNetwork.getContactManager(senderTransactionData.address);
            return contactManager["acknowledgement"](senderTransactionData.id, paramId, receiverTransactionData.id, options);
        })
    }

    static getAllContacts(owner) {
        return ParamConnector.getInstance().getDB().contacts.getAllContacts(owner);
    }

    static getContact(contactId) {
        return ParamConnector.getInstance().getDB().contacts.getContact(contactId);
    }
    static getContactSummary(contactId) {
        return ParamConnector.getInstance().getDB().contacts.getContactSummary(contactId);
    }
    static getContactByInternalId(internalId) {
        return ParamConnector.getInstance().getDB().contacts.getContactByInternalId(internalId, Utils.getParamId());
    }
    static getContactByParamId(paramId, owner) {
        return ParamConnector.getInstance().getDB().contacts.getContactByParamId(paramId, owner);
    }
    static getContactSummaryByParamId(paramId, owner) {
        return ParamConnector.getInstance().getDB().contacts.getContactSummaryByParamId(paramId, owner);
    }

    static getContactNameByParamId(paramId) {
        return ParamConnector.getInstance().getDB().contacts.getContactNameByParamId(paramId);
    }

    static getAllTags(owner) {
        return ParamConnector.getInstance().getDB().contacts.getAllTags(owner);
    }

    static getContactsFromTag(tag, owner) {
        return ParamConnector.getInstance().getDB().contacts.getContactsFromTag(tag, owner);
    }

    static search(searchText, owner, options) {
        return ParamConnector.getInstance().getDB().contacts.search(searchText, owner, options);
    }
    static getAllItemsForExport() {
        return ParamConnector.getInstance().getDB().contacts.getAllItemsForExport();
    }
    static doesInternalIdExist(internalId, owner) {
        return ParamConnector.getInstance().getDB().contacts.doesInternalIdExist(internalId, owner);
    }
}

export default Contact;