class POReconciliation {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    directInvoices(options) {
        return this.mongoDBConnector.invoke('poReconciliation', "directInvoices", options)
    }

    poWithoutInvoices(options) {
        return this.mongoDBConnector.invoke('poReconciliation', "poWithoutInvoices", options)
    }

    poLessThanInvoice(options) {
        return this.mongoDBConnector.invoke('poReconciliation', "poLessThanInvoice", options)
    }

    directInvoicesGraph(options) {
        return this.mongoDBConnector.invoke('poReconciliation', "directInvoicesGraph", options)
    }
    poWithoutInvoicesGraph(options) {
        return this.mongoDBConnector.invoke('poReconciliation', "poWithoutInvoicesGraph", options)
    }
    poLessThanInvoiceGraph(options) {
        return this.mongoDBConnector.invoke('poReconciliation', "poLessThanInvoiceGraph", options)
    }
}
export default POReconciliation