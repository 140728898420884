import FileErrors from './errors'
import Progress from '../../progress';
class Parser {
    parse(pathObject, callback){
        return new Promise((resolve, reject)=>{
            callback.onStatus({msg:"Checking file size."})
            let fileReader = new FileReader();

            const fileSize = pathObject.size;
            const fileName = pathObject.name;
            if(fileSize === 0){
                callback.onStatus({msg:"File is empty."})
            return callback.onFileError({fileName, error: FileErrors.EMPTY_FILE});
            }
            if(fileSize > 1*1024*1024){
                return callback.onFileError({fileName, error: FileErrors.MAX_FILE_SIZE});
            }
            callback.onStatus({msg:"File is less than 10MB."})
            callback.onProgress(Progress.ON_FILE_VALIDATION);
            fileReader.onload = (e) => { 
                callback.onStatus({msg:`Loaded ${fileName} content.`});
                callback.onData({fileName, data:e.target.result});
                callback.onProgress(Progress.ON_FILE_LOADED);
                resolve();
            };

            fileReader.onloadstart = (e)=> {
                callback.onProgress(Progress.ON_FILE_LOADED);
                callback.onStatus({msg:`Reading ${fileName}...`});
                callback.onFileReading({fileName});
            }

            fileReader.onerror = (e)=> {
                callback.onProgress(Progress.ON_COMPLETED);
                callback.onFileError({fileName, error:`Unable to read ${fileName}, reason ${e}`});
                resolve();
            }
            fileReader.onabort = (e) => {
                callback.onProgress(Progress.ON_COMPLETED);
                callback.onFileAbort({fileName, error:`Unable to read ${fileName}, reason ${e}`});
                resolve();
            }
            return new Promise((resolve, reject)=>{
                fileReader.readAsText(pathObject)
            })
        });
    }

    isValidJSONLd(jsonLd){
        return true;
    }
}
export default Parser;