import ParamConnector from '../../param-connector';
import Web3Utils from 'web3-utils';
import * as Utils from '../utils';

class EmailManager {

    static sendMail(emailType, jsonLd, metaData) {
        return Utils.getConfig("EmailManager", true).then(config => {
            let options = Utils.getNetworkOptions();
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let emailManager = paramNetwork.getEmailManager(config.address);
            return emailManager["sendMail"](emailType, jsonLd, metaData, options);
        })
    }

}

export default EmailManager;