import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar, Tooltip, message, Icon, Row, Col, Typography } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Utils from '../../util/utils';
import EventManager from '../../event-manager/event-manager.js';
import IconComponent from "../../components/IconComponent";
import {
  NAV_STYLE_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";

const { Paragraph } = Typography;

class UserProfile extends Component {
  constructor(props) {
    super(props)
    let profileObj = JSON.parse(Utils.getFromLocalStorage('profile'));
    this.state = {
      userName: profileObj.name,
      userParamId: Utils.getFromLocalStorage('param_id'),
      isLogoAvailable: profileObj.logoUrl !== undefined && profileObj.logoUrl !== "" ? false : true,
      userAvatartName: profileObj.name ? profileObj.name.charAt(0) : null,
      userAvatarImg: profileObj.logoUrl
    }

  }

  componentDidMount() {
    EventManager.getInstance().registerEvent('onProfileUpdate', this.profileUpdated)
  }


  profileUpdated = (data) => {
    this.setState({
      userName: data.name,
      isLogoAvailable: data.logoUrl !== "" ? true : false,
      userAvatartName: data.name ? data.name.charAt(0) : null,
      userAvatarImg: data.logoUrl,
      userParamId: data.identifier
    })
  }

  render() {
    const { userName, userParamId, isLogoAvailable, userAvatartName, userAvatarImg } = this.state;
    const { navStyle } = this.props;
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row" ref="sidebarName">
        {
          // isLogoAvailable ?
          userAvatarImg && userAvatarImg !== "" ?
            <Link to="/main/userprofile">
              <img alt={userAvatartName} style={{ marginRight: "10px", maxHeight: '1.8rem' }} src={userAvatarImg} />
            </Link>
            :
            <Link to="/main/userprofile">
              <Avatar className="gx-size-40 gx-pointer " alt="" style={{ marginRight: "10px" }}>
                <b style={{ color: '#222' }}>{userAvatartName.toUpperCase()}</b>
              </Avatar>
            </Link>
        }

        {/* <Paragraph copyable ellipsis={1}>
          <Tooltip placement="top" title={userParamId}>
            {userName}
          </Tooltip>
        </Paragraph> */}
        {
          navStyle !== NAV_STYLE_MINI_SIDEBAR &&
          <Row>
            <Col span={16} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <Tooltip placement="top" title={userParamId}>
                {userName}
              </Tooltip>
            </Col>
            <Col span={6}>
              <CopyToClipboard text={userParamId} onCopy={() => { message.success('Param Id copied') }}>
                <IconComponent actionIcon={true} hoverText="Copy paramID" type="copy" />
              </CopyToClipboard>
            </Col>
          </Row>
        }
      </div>

    )

  }
}
const mapStateToProps = ({ settings }) => {
  const { navStyle } = settings;
  return { navStyle }
};
export default connect(mapStateToProps, {})(UserProfile);

// export default UserProfile;
