import React from 'react';
import { Row, Col, Button, Card, Icon, message, Modal, Select, Input, Switch, Radio, Form } from 'antd';
import ColumnsConfig from './columns-config';
import CircularProgress from '../../../components/CircularProgress';
import * as Utils from '../../../util/utils';
import ReceiptEvents from '../../../param-libs/param-dashboard/events/receipt';
import CatalogueEvents from '../../../param-libs/param-dashboard/events/catalogue';
import VendorEvents from '../../../param-libs/param-dashboard/events/vendors';
import ReturnsEvents from '../../../param-libs/param-dashboard/events/returns';
import CreateModalForm from "../../components/ModalForm";
import { connect } from "react-redux";
import { changeIrpMode } from "../../../appRedux/actions/SideBar";

import './setting.less';
import IconComponent from '../../../components/IconComponent';
const { Option } = Select;
const { confirm } = Modal;

class BetaSetting extends React.Component {

    constructor(props) {
        super(props);
        let dashboardUpdateConfig = Utils.getFromLocalStorage('dashboardUpdateConfig')
        let defaultDashboardConfig = {
            receiptsEnabled: false,
            grnEnabled: false,
            catalogueEnabled: false,
            vendorEnabled: false,
            returnsEnabled: false,
            allEnabled: false
        }
        this.irpConfig = Utils.getFromLocalStorage('irpConfig') || {};
        this.state = {
            fileLimit: Utils.getFromLocalStorage('fileUploadConfig'),
            showCredModal: false,
            editFileConfig: false,
            fileType: 'MB',
            fileExtension: [],
            fileSizeValue: "10",
            dashboardConfig: dashboardUpdateConfig ? dashboardUpdateConfig : defaultDashboardConfig,
            receiptsEnabled: dashboardUpdateConfig ? dashboardUpdateConfig.receiptsEnabled : defaultDashboardConfig.receiptsEnabled,
            grnEnabled: dashboardUpdateConfig ? dashboardUpdateConfig.grnEnabled : defaultDashboardConfig.grnEnabled,
            catalogueEnabled: dashboardUpdateConfig ? dashboardUpdateConfig.catalogueEnabled : defaultDashboardConfig.catalogueEnabled,
            vendorEnabled: dashboardUpdateConfig ? dashboardUpdateConfig.vendorEnabled : defaultDashboardConfig.vendorEnabled,
            allEnabled: dashboardUpdateConfig ? dashboardUpdateConfig.allEnabled : defaultDashboardConfig.allEnabled,
            singleEnterpriseMode: Utils.getFromLocalStorage('singleEnterpriseMode'),
            irpMode: this.irpConfig ? this.irpConfig.irpMode : false,
            returnsEnabled: dashboardUpdateConfig ? dashboardUpdateConfig.returnsEnabled : defaultDashboardConfig.returnsEnabled,
        }
    }

    showCredModal = () => {
        this.setState({
            showCredModal: true
        })
    }

    checkCreds = (checked) => {
        if (!checked) {
            this.setState({ irpMode: checked });
            this.setIrpConfig({ ...this.irpConfig, 'irpMode': checked });
            return;
        }
        if (!Utils.isCredentialExists()) {
            this.setState({
                showCredModal: true
            })
        }
        else {
            this.setState({ irpMode: checked });
            this.setIrpConfig({ ...this.irpConfig, 'irpMode': checked });
        }
    }

    setIrpConfig = (config) => {
        Utils.setLocalStorage("irpConfig", config);
        this.props.changeIrpMode(config.irpMode)
    }

    saveCreds = () => {
        this.modalFormRef.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const irpConfig = {
                    userName: values.userName,
                    password: values.password,
                    baseURL: values.baseURL,
                    clientId: values.clientId,
                    clientSecretKey: values.clientSecretKey,
                    irpMode: this.state.irpMode
                }
                this.irpConfig = irpConfig;
                this.setIrpConfig(irpConfig);
                this.setState({
                    showCredModal: false,
                    irpMode: this.state.irpMode,
                })
                return;
            }
            message.error("Please provide mandatory fields");
        });
    }

    cancelCreds = () => {
        let irpConfig = Utils.getFromLocalStorage("irpConfig");
        this.setState({
            showCredModal: false,
            irpMode: irpConfig.irpMode
        })
    }

    onEnterpriseMode = (checked) => {
        const that = this;
        this.setState({ singleEnterpriseMode: checked });
        let title = `Are you sure, do you want to disable Standalone mode?`;
        if (checked) {
            title = `Are you sure, do you want to enable Standalone mode?`
        }
        confirm({
            title,
            content: '',
            onOk() {
                that.setState({ singleEnterpriseMode: checked });
                Utils.setLocalStorage("singleEnterpriseMode", checked);
            },
            onCancel() {
                that.setState({ singleEnterpriseMode: !checked })
            },
        });
    }

    handleIrpSwitch = (checked) => {
        const that = this;
        this.setState({ irpMode: checked });
        let title = `Are you sure, do you want to disable IRP mode?`;
        if (checked) {
            title = `Are you sure, do you want to enable IRP mode?`
        }
        confirm({
            title,
            content: '',
            onOk() {
                that.checkCreds(checked);
            },
            onCancel() {
                that.setState({ irpMode: !checked })
            },
        });
    }

    onDashboardUpdate = (checked, event, type) => {
        event.preventDefault()
        const { dashboardConfig } = this.state;
        let msg = "disable";
        let that = this;
        if (checked) {
            msg = "enable"
        }
        if (!checked) {
            confirm({
                title: `Are you sure, do you want to ${msg} dashboard realtime ${type} updates?`,
                content: '',
                onOk() {
                    that.setDashboardChecker(checked, type)
                },
                onCancel() {
                    that.setState({ dashboardConfig })
                },
            });
        } else {
            that.setDashboardChecker(checked, type)
        }

    }

    updateDashBoardChecker = (key, checked) => {
        let { dashboardConfig } = this.state;
        let enabled = true
        dashboardConfig[key] = checked;
        if (checked === false) {
            dashboardConfig.allEnabled = checked;
            this.setState({ [key]: checked, allEnabled: checked })
        }
        else {
            for (let key in dashboardConfig) {
                if (dashboardConfig[key] !== true && key !== 'allEnabled') {
                    enabled = false
                    break;
                }
            }
            dashboardConfig.allEnabled = enabled;
            this.setState({ [key]: checked, allEnabled: enabled })
        }
        return dashboardConfig
    }

    setDashboardChecker = (checked, type) => {
        let { dashboardConfig } = this.state;
        switch (type) {
            case 'receipts':
                dashboardConfig = this.updateDashBoardChecker('receiptsEnabled', checked)
                break;
            case 'grn':
                dashboardConfig = this.updateDashBoardChecker('grnEnabled', checked)
                break;
            case 'catalogue':
                dashboardConfig = this.updateDashBoardChecker('catalogueEnabled', checked)
                break;
            case 'vendor':
                dashboardConfig = this.updateDashBoardChecker('vendorEnabled', checked)
                break;
            case 'returns':
                dashboardConfig = this.updateDashBoardChecker('returnsEnabled', checked)
                break;
            case 'all':
                dashboardConfig.receiptsEnabled = checked;
                dashboardConfig.grnEnabled = checked;
                dashboardConfig.catalogueEnabled = checked;
                dashboardConfig.vendorEnabled = checked;
                dashboardConfig.returnsEnabled = checked;
                dashboardConfig.allEnabled = checked;
                this.setState({
                    receiptsEnabled: checked, grnEnabled: checked, catalogueEnabled: checked, vendorEnabled: checked, returnsEnabled: checked, allEnabled: checked
                })
                break;
            default: break;
        }
        this.setState({ dashboardConfig }, () => {
            Utils.setLocalStorage('dashboardUpdateConfig', dashboardConfig)
        })
    }


    howUpdateFileConfig = () => {
        // console.log('FileConfig', Utils.getFromLocalStorage('fileUploadConfig'))
        if (this.state.fileLimit && this.state.fileLimit.value) {
            this.setState({ editFileConfig: true, fileSizeValue: this.state.fileLimit.value, fileType: this.state.fileLimit.type, fileExtension: this.state.fileLimit.extension === '' ? [] : this.state.fileLimit.extension.split(',') })
            return
        }
        this.setState({ editFileConfig: true })
    }
    saveFileConfig = () => {
        let { fileSizeValue, fileType, fileExtension } = this.state;
        // let fileSize = document.getElementById('fileSizeValue').value
        let fileSize = fileSizeValue
        if (!fileSize || fileSize === '') {
            message.error('Please provide file size')
            return;
        }
        let limitSize = 1
        if (fileType === 'GB') {
            limitSize = 1048576
        } else if (fileType == 'MB') {
            limitSize = 1024
        }
        if (Number(fileSize) * limitSize > 1048576) {
            message.error("File size can't exceeds 1GB")
            return
        }
        if (Array.isArray(fileExtension))
            fileExtension = fileExtension.toString()
        let fileObj = {
            type: fileType,
            extension: fileExtension,
            value: fileSize
        }
        this.setState({ fileLimit: fileObj, editFileConfig: false }, () => {
            Utils.setLocalStorage('fileUploadConfig', fileObj)
            message.success('File upload config updated successfully')
        })
    }

    handleFileTypeChange = (type) => {
        this.setState({ fileType: type })

    }

    handleFileExtensionChange = (value) => {
        debugger
        const selectedExt = value.toString();
        this.setState({ fileExtension: selectedExt })
    }

    renderSwitchCard = (title, text, checked, onClick, switchName) => {
        return <Card className="setting__card" title={<h2 className="setting__nomargin">{title}</h2>}>
            {this.renderSwitch(text, onClick, checked, switchName)}
        </Card>
    }

    renderSwitch = (text, onClick, checked, switchName) => {
        return <Row>
            <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                <h4>{text}</h4>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <Switch onClick={(event, checked) => onClick(event, checked, switchName)} checked={checked} />
            </Col>
        </Row>
    }

    renderTextLine = (label, value) => {
        return <Row>
            <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                <h4>{label}</h4>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                <p>{value}</p>
            </Col>
        </Row>
    }

    handleOnChange = e => {
        if (isNaN(Number(e.target.value)) || Number(e.target.value) < 1) {
            this.setState({ fileSizeValue: 1 })
            return
        }
        this.setState({ fileSizeValue: e.target.value })
    }

    renderFileUploadConfig = () => {
        return (
            <>
                <Row>
                    <Col span={12}>
                        <Input type="number" min={1} placeholder="Enter File Size" value={this.state.fileSizeValue} onChange={this.handleOnChange} />
                    </Col>
                    <Col span={4}>
                        <Select value={this.state.fileType} onChange={this.handleFileTypeChange}>
                            <Option value="KB">KB</Option>
                            <Option value="MB">MB</Option>
                            <Option value="GB">GB</Option>
                        </Select>
                    </Col>
                </Row><br />
                <Row>
                    <Col span={12}>
                        <Select defaultValue={this.state.fileExtension} mode="multiple" placeholder="Select File Extension" onChange={this.handleFileExtensionChange} style={{ width: '100%' }}>
                            <Option value=".pdf">pdf</Option>
                            <Option value=".doc">doc</Option>
                            <Option value=".png">png</Option>
                            <Option value=".jpg">jpg</Option>
                            <Option value=".zip">zip</Option>
                            <Option value=".csv">csv</Option>
                            <Option value=".json">json</Option>
                        </Select>
                    </Col>
                </Row>
            </>
        )
    }

    renderFileUploadCard = () => {
        return (
            <Card className="setting__card" title={<h2 className="setting__nomargin">File Upload Config</h2>}>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                        <h4>File Upload limit</h4>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                        <div>
                            <span>{this.state.fileLimit ? `${this.state.fileLimit.value + this.state.fileLimit.type}` : '10MB'}</span>&nbsp;
                        <span>
                                <Icon type="edit" onClick={this.showUpdateFileConfig} className="iconStyle" />
                            </span>
                        </div>

                    </Col>
                </Row>
            </Card>
        )
    }
    renderStandaloneModeCard = () => {
        return this.renderSwitchCard("Standalone Mode", "Enable/Disable", this.state.singleEnterpriseMode, this.onEnterpriseMode);
    }

    renderIrpSwitchCard = () => {
        const { irpConfig } = this;
        return <Card className="setting__card" title={<h2 className="setting__nomargin">IRP Mode</h2>}>
            {this.renderSwitch("Enable/Disable", this.handleIrpSwitch, this.state.irpMode)}
            {
                irpConfig.userName &&
                <div style={{ marginTop: '0.1em' }}>
                    <h3>IRP Credentials: <IconComponent type="edit" actionIcon={true} onClick={this.showCredModal} /> </h3>
                    <div style={{ marginLeft: '2em' }}>
                        {this.renderTextLine("User Name", irpConfig.userName)}
                        {this.renderTextLine("Password", irpConfig.password)}
                        {this.renderTextLine("Base URL", irpConfig.baseURL)}
                        {this.renderTextLine("Client ID", irpConfig.clientId)}
                        {this.renderTextLine("Client Secret Key", irpConfig.clientSecretKey)}
                    </div>
                </div>
            }
        </Card>
    }

    renderRealtimeUpdateCard = () => {
        console.log(Utils.getFromLocalStorage("dashboardUpdateConfig"), "dashboardUpdateConfig");
        const { receiptsEnabled, grnEnabled, catalogueEnabled, vendorEnabled, returnsEnabled, allEnabled } = this.state;
        return (
            <Card className="setting__card" title={<h2 className="setting__nomargin">Dashboard Realtime Update</h2>}>
                {this.renderSwitch("Receipts", this.onDashboardUpdate, receiptsEnabled, 'receipts')}
                {this.renderSwitch("GRN", this.onDashboardUpdate, grnEnabled, 'grn')}
                {this.renderSwitch("Catalogue", this.onDashboardUpdate, catalogueEnabled, 'catalogue')}
                {this.renderSwitch("Vendor", this.onDashboardUpdate, vendorEnabled, 'vendor')}
                {this.renderSwitch("Returns", this.onDashboardUpdate, returnsEnabled, 'returns')}
                {this.renderSwitch("Enable/Disable All", this.onDashboardUpdate, allEnabled, 'all')}
            </Card>
        )
    }

    setModalFormRef = (element) => {
        this.modalFormRef = element;
    }

    render() {
        const ModalForm = Form.create({ name: 'newForm' })(CreateModalForm)
        return (
            <>
                <Row>
                    <Col md={12} xs={24}>{this.renderFileUploadCard()}</Col>
                    <Col md={12} xs={24}>{this.renderStandaloneModeCard()}</Col>
                    <Col md={12} xs={24}>{this.renderRealtimeUpdateCard()}</Col>
                    <Col md={12} xs={24}>{this.renderIrpSwitchCard()}</Col>
                </Row>
                <Modal centered
                    title="Configure File Upload"
                    visible={this.state.editFileConfig}
                    closable={true}
                    onCancel={() => { this.setState({ editFileConfig: false }) }}
                    footer={
                        <Button onClick={this.saveFileConfig}>Save</Button>
                    }
                >
                    {this.renderFileUploadConfig()}
                </Modal>

                <Modal
                    title="GSTN Credentials"
                    visible={this.state.showCredModal}
                    onOk={this.saveCreds}
                    onCancel={this.cancelCreds}
                >
                    <ModalForm formName="irpCredForm" modalFormData={this.irpConfig} ref={this.setModalFormRef} />

                </Modal>

            </>
        )
    }
}
// export default BetaSetting;

const mapStateToProps = ({ settings }) => {
    const { themeType, navStyle, navCollapsed, width, locale } = settings;
    return { themeType, navStyle, navCollapsed, width, locale }
};
export default connect(mapStateToProps, { changeIrpMode })(BetaSetting);