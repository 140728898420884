import Web3_1_0 from '../utils/Web3_1_0';
const ParamUtils = require('../utils/index');

class NodeInfo {
    /**
     * Default constructor for initialising or establishing the contacts.
     * @param {ParamNetwork} paramNetwork Object
     * 
     */
    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const enterprise = require('./node-info.json');

        this.enterpriseABI = enterprise.abi;
        this.paramNetwork = _paramNetwork;
        this.enterpriseContract = Web3_1_0.getContract(this.connection, enterprise.abi, contractAddress ? contractAddress : enterprise.address);
        this.to = enterprise.address;
    }

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        if (options) {
            options = {
                owner: options.address
            };
        }

        this.watchAddNodeEvent(options);
        this.watchUpdateNodeEvent(options);
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }
    
    watchAddNodeEvent(options) {
        if (typeof this.addNodeEvent === 'undefined' || !this.addNodeEvent) {
            let filter = {
                filter: options
            };
            // this.addNodeEvent = this.enterpriseContract.events.OnNodeAdded(filter);
            this.addNodeEvent = Web3_1_0.getEvent(this, this.enterpriseContract, "OnNodeAdded", filter);
        }
        let that = this;
        this.addNodeEvent.on('data', function (event) {
            that.emitEvent("OnNodeAdded", event);
        })
            .on('error', function (error) {
                that.events.emit("OnNodeAdded", error);
                that.addNodeEvent.unsubscribe();
                that.addNodeEvent = undefined;
                that.watchAddNodeEvent(options);
            });
    }

    watchUpdateNodeEvent(options) {
        if (typeof UpdateNodeEvent === 'undefined' || !this.UpdateNodeEvent) {
            let filter = {
                filter: options
            };
            // this.UpdateNodeEvent = this.enterpriseContract.events.OnNodeUpdated(filter);
            this.UpdateNodeEvent = Web3_1_0.getEvent(this, this.enterpriseContract, "OnNodeUpdated", filter);
        }
        let that = this;
        this.UpdateNodeEvent.on('data', function (event) {
            that.emitEvent("OnNodeUpdated", event);
        })
            .on('error', function (error) {
                that.events.emit("OnNodeUpdated", error);
                that.UpdateNodeEvent.unsubscribe();
                that.UpdateNodeEvent = undefined;
                that.watchUpdateNodeEvent(options);
            });
    }

    addEnterpriseNode(paramId, organizationId, config, metaData, options) {
        return Web3_1_0.send(this, this.enterpriseContract, "addEnterpriseNode", options, paramId, organizationId, config, metaData);
    }

    addMasterNode(organizationId, config, metaData, options) {
        return Web3_1_0.send(this, this.enterpriseContract, "addMasterNode", options, organizationId, config, metaData);
    }

    updateEnterPriseNode(nodeId, paramId, organizationId, config, metaData, options) {
        return Web3_1_0.send(this, this.enterpriseContract, "updateEnterPriseNode", options, nodeId, paramId, organizationId, config, metaData);
    }

    updateMasterNode(nodeId, organizationId, config, metaData, options) {
        return Web3_1_0.send(this, this.enterpriseContract, "updateMasterNode", options, nodeId, organizationId, config, metaData);
    }

    getAllEnterpriseNodes() {
        return Web3_1_0.call(this, this.enterpriseContract, "getAllEnterpriseNodes", null);
    }

    getAllMasterNodes() {
        return Web3_1_0.call(this, this.enterpriseContract, "getAllMasterNodes", null);
    }

    getNodeDetailsById(masterNodesBytesId) {
        return Web3_1_0.call(this, this.enterpriseContract, "getNodeDetailsById", null, masterNodesBytesId);
    }

    getNodeDetailsByParamId(paramId) {
        return Web3_1_0.call(this, this.enterpriseContract, "getNodeDetailsByParamId", null, paramId);
    }

    isExists(nodeId) {
        return Web3_1_0.call(this, this.enterpriseContract, "isExists", null, nodeId);
    }

    isEnterpriseNodeByNodeId(nodeId) {
        return Web3_1_0.call(this, this.enterpriseContract, "isEnterpriseNodeByNodeId", null, nodeId);
    }

    isEnterpriseNodeByParamId(paramId) {
        return Web3_1_0.call(this, this.enterpriseContract, "isEnterpriseNodeByParamId", null, paramId);
    }

    registerOnNodeAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("OnNodeAdded", callback);
    }

    unRegisterOnNodeAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("OnNodeAdded", callback);
    }

    registerOnNodeUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        this.events.addListener("OnNodeUpdated", callback);
    }

    unRegisterOnNodeUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("OnNodeUpdated", callback);
    }
}

export default NodeInfo;