import React from "react";

import Widget from "../../components/Widget/index";
import { connect } from "react-redux";
import { THEME_TYPE_DARK } from "../../constants/ThemeSetting";

const IconWithTextCard = props => {
  const { icon, title, subTitle, marginBottom } = props;
  let { iconColor, cardColor, fontColor } = props;
  if (props.themeType === THEME_TYPE_DARK) {
    iconColor = "white";
  }

  return (
    <Widget styleName={`gx-bg-${cardColor} gx-mb-${marginBottom}`}>
      <div className="gx-media gx-align-items-center gx-flex-nowrap" style={{ minHeight: '3rem' }}>
        <div className="gx-mr-lg-4 gx-mr-3">
          <i
            className={`icon icon-${icon} gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
            style={{ fontSize: 25 }}
          />
        </div>
        <div className="gx-media-body">
          <h3 className={`gx-fs-l gx-font-weight-medium gx-mb-1 gx-text-${fontColor}`}>{title}</h3>
          <p className={`gx-fs-sm gx-mb-0 gx-text-${fontColor}`}>{subTitle}</p>
        </div>
      </div>
    </Widget>
  );
};

const mapStateToProps = ({ settings }) => {
  const { themeType } = settings;
  return { themeType };
};
export default connect(
  mapStateToProps,
  null
)(IconWithTextCard);