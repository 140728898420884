import ParamConnector from '../../param-connector';
// import Sync from '../../database/nosql/sync/index';
import Sync from '../../param-libs/graph-ql/sync/index';
import * as Utils from '../../util/utils';

class SyncManager {
    static syncAllData(callback) {
        let config = Utils.getConfigObject();
        let options = {
            address: Utils.getParamId(),
            paramNetwork: ParamConnector.getInstance(config).getNetwork()
        }
        return Sync.sync(options, callback);
    }
}

export default SyncManager;