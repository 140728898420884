import Charts from '../index';
import DBUtils from '../../utils/util';

class CustomerInsights {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    /**
        * Ref No.: CUSTOMER_INSIGHTS_001
        * Graph Name: Repeat Customers
    */
    repeatCustomers() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "repeatCustomers");
    }

    /**
        * Ref No.: CUSTOMER_INSIGHTS_002
        * Graph Name: Reorder Ratio
    */
    reorderRatio() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "reorderRatio");
    }

    getCustomerFrequencyData() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getCustomerFrequencyData");
    }

    /**
        * Ref No.: CUSTOMER_INSIGHTS_004
        * Graph Name: Fill Rate
    */
    //TODO
    fillRate() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "fillRate");
    }

    /**
        * Ref No.: CUSTOMER_INSIGHTS_006
        * Graph Name: Active Customers
    */
    //TODO
    activeCustomers(fromDate, toDate) {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "activeCustomers", fromDate, toDate);
    }

    //TODO
    customerShipment() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "customerShipment");
    }
    /**
        * Ref No.: CUSTOMER_INSIGHTS_007
        * Graph Name: Overview
    */
    //TODO
    overview() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "overview");
    }

    getOpenPOCount() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getOpenPOCount");
    }

    getOnTimeInvoiceCount() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getOnTimeInvoiceCount");
    }

    getOrderToShipCount() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getOrderToShipCount");
    }

    getOverdueShipmentCount() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getOverdueShipmentCount");
    }

    getLateShipmentCount() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getLateShipmentCount");
    }

    getReturnCount() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getReturnCount");
    }

    /**
        * Ref No.: CUSTOMER_INSIGHTS_008
        * Graph Name: Contract
    */
    //TODO
    contractDetails() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "contractDetails");
    }

    /**
        * Ref No.: CUSTOMER_INSIGHTS_010
        * Graph Name: Top Customers
    */
    //TODO
    topCustomers() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "topCustomers");
    }

    //TODO
    getNewCustomers() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getNewCustomers");
    }

    //TODO
    getNewAndReturnCustomers() {
        return this.mongoDBConnector.invoke("reports", "customerInsights", "getNewAndReturnCustomers");
    }
}

export default CustomerInsights;