import SecureLS from "secure-ls";
import ParamConnector from "../param-connector";
import ECIES from "./ecies";
import NetworkBridge from "./network-bridge/index";
import { Modal, Select, Tooltip, Icon } from 'antd';
import React from 'react';
import Web3 from "web3-utils";
import Console from '../logger/index';
import ContactParser from "../parsers/ContactParser";
import pbkdf2 from 'pbkdf2';
import toWords from 'to-words';
import writtenNumber from 'written-number';
import InMemoryStorage from './InMemoryStorage'
import Skeleton from 'react-loading-skeleton';
import { alignedInfoText } from '../constants/AppConstants';
import moment from 'moment';
import AppUtils from "../app.utils";
import SaveAndReadFile from "../param-libs/save-read-file";
import DocumentTheme from "../routes/main/Setting/document-theme";
import AppInfo from '../app-config.json';
import IconComponent from "../components/IconComponent";
import Web3Utils from 'web3-utils';
import ParamUtils from "../param-network/utils";
import ParamPrivateNode from "../param-network/privatenode";
import ProxyUtils from "../param-network/utils/proxy-utils";
import Tx from 'ethereumjs-tx';
import Crypto from 'crypto';
import { Parser } from "json2csv";
import { JsonWebTokenError } from "jsonwebtoken";
import KeyStore from './network-bridge/keystore'
import Config from "../config.json"
const rp = require('request-promise-native');
const ipfsAPI = require('ipfs-api');

var ls = new SecureLS({
  encodingType: "aes"
});
// var ls = InMemoryStorage
const confirm = Modal.confirm;
const { Option, OptGroup } = Select;
const countryCurrency = require('country-js');

export function getAmountText(amount, type) {
  //temporary
  if (amount < 1) {
    return 'Zero';
  }
  if (!amount || isNaN(Number(amount)) || amount === "0") {
    return "Zero";
  }
  amount = Number(Number(amount).toFixed(2))
  if (!type) {
    return toWords(amount, { currency: true });
  }
  let currency = checkCurrency(type);
  if (currency === "INDIAN RUPEE") {
    return toWords(amount, { currency: true })
  }
  return `${writtenNumber(amount).toUpperCase()} ${currency} ONLY`;
}

export function getCurrency(amount, type, precision = 2) {
  if (!amount || isNaN(amount)) {
    amount = 0;
  }
  amount = Number(amount);
  let negativeSign = amount < 0 ? "- " : "";
  amount = Math.abs(amount);
  amount = amount.toFixed(precision);
  if (type && type !== null && type !== undefined) {
    let symbol = checkCurrencyType(type);
    amount = numberWithCommas(amount, precision)
    let amountValue = symbol + negativeSign + amount;
    return amountValue;
  }
  // let currencyType = ls.get("Currency");
  let currencyType = "";

  if (
    currencyType === "" ||
    currencyType === undefined ||
    currencyType === null
  ) {
    amount = numberWithCommas(amount, precision)
    return "\u20B9 " + negativeSign + amount;
  }
  let symbol = checkCurrencyType(currencyType);
  amount = numberWithCommas(amount, precision)
  return symbol + negativeSign + amount;
  // return '\u20B9 '+amount;
}

export function getPrice(value, precision = 2) {
  if ((!value && value != 0) || isNaN(value)) {
    return 0;
  }
  // value = numberWithCommas(value)
  // return value
  let amount = Number(value);
  return amount.toFixed(precision);
}
export function getValidToken(token) {
  return KeyStore.getValidToken(token)
}
export function getFromLocalStorage(key) {
  return ls.get(key);
  // return localStorage.getItem(key)
}

export function setLocalStorage(key, value) {
  ls.set(key, value);
  // localStorage.setItem(key, value)
}
export function removeItemFromLocalStorage(key) {
  ls.remove(key)
}


export function clearLocalStorage() {
  ls.clear()
  // localStorage.clear();
}

export function checkCurrencyType(currencyType) {
  let searchResult = countryCurrency.search(currencyType);
  if (!currencyType || !searchResult || !searchResult[0].currency || !searchResult[0].currency.currencySymbol) {
    return '';
  }
  return searchResult[0].currency.currencySymbol;
}

export function checkCurrency(currencyType) {
  let searchResult = countryCurrency.search(currencyType);
  if (!currencyType || !searchResult || !searchResult[0].currency || !searchResult[0].currency.currencySymbol) {
    return '';
  }
  return searchResult[0].currency.currencyName;
}

export function getConfig(contractName, light) {
  let config = getConfigObject();
  const contractNameToAddress = {
    ParamContract: '0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815',
    ParamContactImpl: '0xDe3D2d87C02657A53fC379b4d45eeD9188266856',
    ParamCatalogueImpl: '0xa5f126CFF0835db28DecdDb833157B19f53e733d',
    ParamInventoryImpl: '0xf70451c209fD3f767984d2F0fb6f000aeD4b10D1',
    TemplateRepositoryImpl: '0x3e8105e05a7887df1cd00ee884aa2ad5461ef2df',
    TemplateConsensusImpl: '0xfbEe16E2d7c36c24455039702051798B073ed91a',
    ParamVendors: '0xAD36bcd5Ec0408826e5Fe4aD81A822A36794Ef46',
  }
  config.address = contractNameToAddress[contractName];
  if (light || true) {
    return Promise.resolve(config);
  }
  return getLatestContractAddressFromName(contractName).then(
    contractAddress => {
      config.address = contractAddress[0];
      return config;
    }
  );
}
export function getConfigObject() {
  return {
    url: getFromLocalStorage("url"),
    privateUrl: getFromLocalStorage("privateUrl"),
    configObj: getFromLocalStorage("configObj")
  };
}
export function getIpfsURL() {
  return Config.ipfs.url;//"/ip4/140.238.243.37/tcp/5001";
}
export function sendToIPFS(payload) {
  // if (payload.length < 10240) {
  //   return payload;
  // }
  const ipfsURL = getIpfsURL();
  const ipfs = ipfsAPI(ipfsURL);
  const bufferPayload = Buffer.from(payload);
  return ipfs.add(bufferPayload).then(res => {
    return `ipfs://${res[0].hash}`
  });
}
export function getLatestContractAddressFromName(contractName) {
  const contractNameToAddress = {
    ParamContract: '0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815',
    ParamContactImpl: '0xDe3D2d87C02657A53fC379b4d45eeD9188266856',
    ParamCatalogueImpl: '0xa5f126CFF0835db28DecdDb833157B19f53e733d',
    ParamInventoryImpl: '0xf70451c209fD3f767984d2F0fb6f000aeD4b10D1',
    TemplateRepositoryImpl: '0x3e8105e05a7887df1cd00ee884aa2ad5461ef2df',
    TemplateConsensusImpl: '0xfbEe16E2d7c36c24455039702051798B073ed91a',
    ParamVendors: '0xAD36bcd5Ec0408826e5Fe4aD81A822A36794Ef46',
  }
  return Promise.resolve(contractNameToAddress[contractName]);
  let graphDB = ParamConnector.getInstance(getConfigObject()).getGraphDB();

  let paramId = getParamId();
  return graphDB.versionControl.getLatestContractAddressFromName(
    paramId,
    contractName
  );
}

export function getTransactionId(transactionId, contractAddress) {
  if (contractAddress)
    contractAddress = Web3.toChecksumAddress(contractAddress);
  return transactionId + "-" + contractAddress;
}

export function getTransactionData(data) {
  if (typeof data === 'number' || !data.includes('-'))
    return data;
  data = data.split("-");
  return {
    id: data[0],
    address: data[1]
  };
}

export function getNetworkOptions(privateFor) {
  return {
    from: getParamId(),
    privateKey: getPrivateKey(),
    privateFor: privateFor
  };
}

export function syncEncryptedData(dataArray, dataIndex, txnType) {
  let privateKey = getPrivateKey();
  for (let index = 0; index < dataArray.length; index++) {
    if (dataArray[index] === null) {
      continue;
    }
    if (dataArray[index][txnType] === "2") {
      dataArray[index][dataIndex] = ECIES.decrypt(
        privateKey,
        dataArray[index][dataIndex]
      );
    }
  }
  return dataArray;
}

export function getAboutList(contactDetails) {
  let aboutList = [
    {
      id: 1,
      title: "Phone Number",
      icon: "phone",
      userList: "",
      desc: [contactDetails.telephone]
    },
    {
      id: 2,
      title: "Email",
      icon: "email",
      userList: "",
      desc: [contactDetails.email]
    },
    {
      id: 3,
      title: "Fax",
      icon: "fax",
      userList: "",
      desc: [contactDetails.faxNumber]
    },
    {
      id: 4,
      title: "Tax Number",
      icon: "tax",
      userList: "",
      desc: [contactDetails.taxId]
    },
    {
      id: 5,
      title: "Office Address",
      icon: "company",
      userList: "",
      desc: [contactDetails.address.streetAddress]
    }
  ];
  return aboutList;
}

export function getArrayOfData(data) {
  if (!data) {
    return [];
  }
  if (data instanceof Array) {
    return data;
  }
  return [data];
}

export function getParamId() {
  return getFromLocalStorage("param_id");
}

export function getPrivateKey() {
  return getFromLocalStorage("privateKey");
}

export function getPublicKey() {
  return getFromLocalStorage("publicKey");
}

export function fetchStatus(status, typeOfDocument, subType) {
  switch (subType) {
    case "acceptNote":
      return "Accept Note";
    case "partPurchaseOrder":
      return "Part Puchase Order";
    case "GRN":
      return fetchGrnStatus(status);
    case "creditNote":
    case "elr":
    case "ELR":
      return fetchELRStatus(status);
    case "ePoD":
      return fetchePoDStatus(status)
    case "draftInvoice":
      return fetchDraftInvoiceStatus(status)
    case "debitNote":
      return fetchNoteStatus(status, subType);
    case "returnRequest":
    case "returnOrder":
      return fetchReturnsStatus(status, subType);
    case "logistics":
      return fetchLogisticsStatus(status, subType);
    case "schedule":
      return fetchSheduleStatus(status)
    case "quality":
        return fetchQualityStatus(status)
    default:
      return fetchReceiptstatus(status);
  }
}
export function fetchSheduleStatus(status) {
  switch (status) {
    case "0":
      return "TBD";
    case "1":
      return "Scheduled";
    case "2":
      return "Rescheduled";
    case "3":
      return "Approved"
    case "4":
      return "Escalated"
    case "5":
      return "Completed"
    default:
      return status;
  }
}
export function fetchQualityStatus(status) {
  switch (status) {
    case "0":
      return "DebitAdvice Created";
    case "1":
      return "CreditNote Created";
    default:
      return status;
  }
}
export function fetchLogisticsStatus(status) {
  switch (status) {
    case "0":
      return "Rate Card Created";
    case "1":
      return "Rate Card Accepted";
    case "2":
      return "Rate Card Rejected";
    case "3":
      return "TO Created";
    case "4":
      return "TO Rejected";
    case "5":
      return "LSP Invoice Uploaded";
    case "6":
      return "LSP Invoice Rejected";
    case "7":
      return "TO Closed";
    case "8":
      return "Payment Successful";
    case "9":
      return "Payment Failed";
    case "10":
      return "Digital Receipt";
    case "11":
      return "Receipt Cancelled";
    default:
      return undefined;
  }
}

export function fetchReceiptstatus(status) {
  switch (status) {
    case "0":
      return "Contract Created";
    case "1":
      return "Contract Accepted";
    case "2":
      return "Contract Rejected";
    case "3":
      return "Purchase Order Created";
    case "4":
      return "Purchase Order Rejected";
    case "5":
      return "Invoice Generated";
    case "6":
      return "Invoice Rejected";
    case "7":
      return "Purchase Order Closed";
    case "8":
      return "Payment Successful";
    case "9":
      return "Payment Failed";
    case "10":
      return "Digital Receipt";
    case "11":
      return "Receipt Cancelled";
    default:
      return undefined;
  }
}

export function fetchNoteStatus(status, subType) {
  const docName = camelCaseToString(subType);
  switch (status) {
    case "8":
      return `${docName} Created`;
    case "9":
      return `${docName} Rejected`;
    case "10":
      return `${docName} Accepted`;
    default:
      return `${docName} Created`;
  }
}

export function fetchReturnsStatus(status, subType) {
  const docName = camelCaseToString(subType);
  switch (status) {
    case "0":
      return `${docName} Created`;
    case "1":
      return `${docName} Accepted`;
    case "2":
      return `${docName} Rejected`;
    default:
      return `${docName} Created`;
  }
}

export function fetchGrnStatus(status) {
  switch (status) {
    case "0":
      return "GRN Created";
    case "1":
      return "GRN Closed";
    default:
      return undefined;
  }
}
export function fetchDraftInvoiceStatus(status) {
  switch (status) {
    case "0":
      return "Clearing Details Created";
    case "1":
      return "Clearing Details Closed";
    default:
      return status;
  }
}
export function fetchELRStatus(status) {
  switch (status) {
    case "0":
      return "eLR Created";
    case "1":
      return "eLR Closed";
    default:
      return undefined;
  }
}
export function fetchePoDStatus(status) {
  switch (status) {
    case "0":
      return "ePoD Created";
    case "1":
      return "ePoD Closed";
    default:
      return undefined;
  }
}
export function fetchAcceptNoteStatus(status) {
  switch (status) {
    case "0":
      return "Accept Note Created";
    case "1":
      return "Accept Note Closed";
    default:
      return undefined;
  }
}

export function trimNumbers(str) {
  // return str.substr(str.length - 6)
  if (str) {
    return str.substr(0, 10) + (str.length > 10 ? "..." : "");
  }
}

export function getReceiptSummaryList(receiptDetails) {
  let summaryList = [
    {
      id: 1,
      title: "From",
      icon: "company",
      userList: "",
      desc: [receiptDetails.provider.name]
    },
    {
      id: 2,
      title: "To",
      icon: "company",
      userList: "",
      desc: [receiptDetails.customer.name]
    },
    {
      id: 3,
      title: "Created On",
      icon: "datepicker",
      userList: "",
      desc: [receiptDetails.date]
    },
    {
      id: 4,
      title: "Status",
      icon: "stats",
      userList: "",
      desc: [fetchStatus(receiptDetails.status)]
    },
    {
      id: 5,
      title: "Tax",
      icon: "pricing-table",
      userList: "",
      desc: [receiptDetails.taxValue]
    },
    {
      id: 6,
      title: "Amount",
      icon: "pricing-table",
      userList: "",
      desc: [receiptDetails.totalAmount]
    }
  ];
  return summaryList;
}

export function isDataPresent(data, userInput) {
  if (data && userInput) {
    return data.toLowerCase().startsWith(userInput.toLowerCase());
  }
  return false;
}

export function getDocs(stepJson) {
  return {
    document: stepJson["@type"],
    fields: stepJson["fields"]
  };
}

function getPartyFromStepNo(stepNo, partiesInvolved) {
  for (let i = 0; i < partiesInvolved.length; i++) {
    if (partiesInvolved[i].stepNo === stepNo) {
      return partiesInvolved[i].party;
    }
  }
}

export function createPreviewTableData(stepJson, metaData) {
  let previewTableData = [];
  for (let index = 0; index < stepJson.length; index++) {
    previewTableData[index] = {
      sno: stepJson[index].stepNo,
      key: parseInt(stepJson[index].stepNo) - 1,
      steps: stepJson[index].step,
      docs: getDocs(stepJson[index]),
      party: getPartyFromStepNo(stepJson[index].stepNo, metaData.partiesInvolved),
      allowMultipleAdd: stepJson[index].allowMultipleAdd
    }
  }
  previewTableData.sort(function (a, b) {
    return a.sno - b.sno
  })
  return previewTableData;
}

export function isAddressExist(valueArr) {
  let address = getFromLocalStorage("param_id");
  for (let index = 0; index < valueArr.length; index++) {
    if (valueArr[index] === address) {
      return true;
    }
  }
  return false;
}

export function setPublic(publicKey) {
  return NetworkBridge.getProfileManager().setPublic(publicKey);
}

export function getPublic(paramId) {
  return NetworkBridge.getProfileManager().getPublic(paramId).then(res => {
    if (!res) {
      res = undefined;
    }
    return res;
  })
}

export function getTxnType(value) {
  switch (value) {
    case "1":
      return "Public";
    case "2":
      return "Protected";
    case "3":
      return "Private";
    default:
      return "Public";
  }
}
export function getTxnTypeForGraphQL(value) {
  switch (value) {
    case "1":
      return "PUBLIC";
    case "2":
      return "PROTECTED";
    case "3":
      return "PRIVATE";
    default:
      return "PUBLIC";
  }
}

export function getTxnTypeNumForGraphQL(value) {
  switch (value) {
    case "PUBLIC":
      return "1";
    case "PROTECTED":
      return "2";
    case "PRIVATE":
      return "3";
    default:
      return "1";
  }
}

export function getItemTypeForGraphQL(value) {
  switch (value) {
    case "1":
      return "GOODS";
    case "2":
      return "SERVICES";
    case "3":
      return "RAW_MATERIAL";
    default:
      return "RAW_MATERIAL";
  }
}
export function getDecimalValues(value) {
  if (!value) {
    return '0.00'
  }
  if (typeof (value) === "string")
    value = Number(value);
  if (isNaN(value))
    return '0.00'
  return value.toFixed(2);
}

export function getReportsData(reportsData) {
  let reports = [];
  reports[0] = reportsData.contractsSent;
  reports[1] = reportsData.poSent;
  reports[2] = reportsData.invoicesSent;
  reports[3] = reportsData.paymentInitiated;
  reports[4] = reportsData.paymentReceived;
  reports[5] = reportsData.paymentDue;
  return reports;
}

export function getCountData(data) {
  if (data === null) {
    return null
  }
  if (!data) {
    return 0;
  }
  if (data < 10) {
    return "0" + data;
  }
  return data;
}

export function camelCaseToString(word) {
  if (typeof (word) !== "string") {
    return '';
  }
  return word
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function (str) { return str.toUpperCase(); })
}

export function stringToCamelCase(str) {
  if (typeof (str) !== "string") {
    return '';
  }
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index == 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}
export function getTimeLineData(timelineList, templateData, item) {
  timelineList.sort(function (a, b) {
    return a.blockNumber - b.blockNumber
  })
  if (templateData == undefined || item.isTemplateCompleted == "true")
    return timelineList

  let steps = templateData.stepIds;
  steps.sort(function (a, b) {
    return a.stepNo - b.stepNo
  })
  let length = timelineList.length;
  let data = timelineList[length - 1]
  steps = steps.slice(data.currentStep)
  timelineList = timelineList.concat(steps)
  return timelineList;
}

export function headsUpCheck(nameSpace, form) {
  return new Promise((resolve, reject) => {
    form.validateFields((err, values) => {
      for (let key in values) {
        if (values[key]) {
          showConfirm(nameSpace);
          return resolve(true);
        }
      }
      nameSpace.props.history.goBack();
      return resolve(false);
    });
  })
}

export function showConfirm(nameSpace) {
  let that = nameSpace;
  confirm({
    title: 'Heads up!',
    content: 'All changes will be lost, Are you sure you want to continue?',
    onOk() {
      that.props.history.goBack();
    },
    onCancel() {
      Console.log("Cancel");
    },
  });
}

export function getSubString(str, from, to) {
  if (typeof (str) === 'string') {
    if (from) {
      if (to) {
        return str.substr(from, to);
      }
      return str.substr(from);
    }
  }
  return str;
}
export function getMyProfileJsonLd() {
  let profile = JSON.parse(getFromLocalStorage('profile'));
  profile['faxNumber'] = profile['faxNo'];
  profile['address'] = {
    streetAddress: profile.streetAddress,
    postalCode: profile.postalCode,
    addressRegion: profile.addressRegion,
    addressLocality: profile.addressLocality
  }
  profile['taxId'] = profile.taxNo;
  profile['occupation'] = [];
  profile['jobTitle'] = {};
  let jsonLd = ContactParser.getContactJsonLd(profile, 'profile');
  jsonLd['identifier'] = getParamId();
  jsonLd['logoUrl'] = profile.logoUrl;
  jsonLd['publicKey'] = getFromLocalStorage('publicKey');
  return jsonLd;
}

export function convertProfileToJsonLd(profile) {
  // let profile = JSON.parse(getFromLocalStorage('profile'));
  profile['name'] = profile.name
  profile['faxNumber'] = profile['faxNo'];
  profile['address'] = {
    streetAddress: profile.streetAddress ? profile.streetAddress : undefined,
    postalCode: profile.postalCode ? profile.postalCode.toString() : undefined,
    addressRegion: profile.addressRegion ? profile.addressRegion : undefined,
    addressLocality: profile.addressLocality ? profile.addressLocality : undefined
  }
  profile['worksFor'] = {
    '@type': 'Organization',
    'name': profile.organisation
  }
  profile['nationality'] = {
    '@type': 'Country',
    'name': profile.country
  }
  profile['contactPoint'] = {
    '@type': 'ContactPoint',
    'telephone': profile.telephone
  }
  profile['telephone'] = profile.telephone;
  profile['taxId'] = profile.taxNo;
  profile['occupation'] = [];
  profile['jobTitle'] = {};
  let jsonLd = ContactParser.getContactJsonLd(profile, 'profile');
  jsonLd['identifier'] = getParamId();
  jsonLd['logoUrl'] = profile.logoUrl;
  jsonLd['city'] = profile.city;
  jsonLd['email'] = profile.email;
  jsonLd['publicKey'] = getFromLocalStorage('publicKey');
  return jsonLd;
}

export function createHeading(heading) {
  switch (heading) {
    case "Purchase Order":
      return "Raise Purchase Order"
    case "Invoice":
      return "Raise Invoice"
    case "paymentInit":
      return "Initiate Payment"
    case "paymentConfirm":
      return "Confirm Payment"
    case "resolveDispute":
      return "Resolve Dispute";
    default:
      break;
  }
}

export function getContactStatusText(state) {
  switch (state) {
    case '0':
      return 'Manually Created';
    case '1':
      return 'Draft';
    case '2':
      return 'Invited';
    case '3':
      return 'Accepted';
    case '4':
      return 'Acknowledged';
    default:
      break;
  }
}

export function sortObjectArrayByKey(objectArray, key) {
  return objectArray.sort((a, b) => (a.key > b.key) ? 1 : (a.key === b.key) ? ((a.size > b.size) ? 1 : -1) : -1);
}

export function isCredentialExists() {
  const irpConfig = getFromLocalStorage("irpConfig");
  if (!irpConfig) {
    return false;
  }
  return (irpConfig.userName && irpConfig.password && irpConfig.baseURL && irpConfig.clientId && irpConfig.clientSecretKey);
}

export function compareObjects(obj1, obj2) {
  for (let key in obj1) {
    if (typeof (obj1[key]) === "object") {
      compareObjects(obj1[key], obj2[key]);
    }
    else if (obj1[key] != obj2[key]) {
      return false;
    }
  }
  return true;
}

export function getDigest(text, randomStr = "", length) {
  return pbkdf2.pbkdf2Sync(text, randomStr, 2048, length, 'sha512').toString('hex');
}

export function uploadFileToServer(file) {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "multipart/form-data");
  myHeaders.append("api-key", "iphone21");

  var formdata = new FormData();
  formdata.append("file", file, file.name);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

  return fetch(`${Config["server-file-upload"].url}/fileUpload`, requestOptions) //http://140.238.242.231:5001
    .then(response => {
      return response.text()
    })
}

export function getFileFromServer(fileInfo) {
  var myHeaders = new Headers();
  myHeaders.append("api-key", "iphone21");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  return fetch(`${Config["server-file-upload"].url}/getFile/${fileInfo.filename}`, requestOptions) //http://140.238.242.231:5001/getFile/
}

export function createSuggestionList(suggestionData, type, subType, invitationId) {
  // let selfContact = getMyProfileJsonLd();
  let profile = getFromLocalStorage('profile');
  let selfContact = JSON.parse(profile)
  let optionSuggestionData = [].concat(suggestionData);
  if (type === "contacts" && subType !== "subscribers") {
    optionSuggestionData.push(selfContact);
  }
  let suggestionDetails = [], suggestionList = [], supplierContacts = [], customerContacts = [], directContacts = [], selfContacts = [], idToValue = {};
  let index = 0;
  let label;
  for (let i in optionSuggestionData) {
    if ((optionSuggestionData[i].identifier && optionSuggestionData[i].identifier !== "0x0000000000000000000000000000000000000000") ||
      type === "items" || type === "flatArray" || type === "documents") {
      suggestionList.push(optionSuggestionData[i]);
      switch (type) {
        case "items":
          label = optionSuggestionData[i].name;
          idToValue[optionSuggestionData[i]['@id']] = optionSuggestionData[i].inventory && optionSuggestionData[i].inventory.inventoryLevel && optionSuggestionData[i].inventory.inventoryLevel.currentValue;
          break;
        case "contacts":
          label = optionSuggestionData[i].name;
          break;
        case "flatArray":
          label = optionSuggestionData[i];
          break;
        case "documents":
          label = optionSuggestionData[i].internalId;
          break;
        default:
          break;
      }
      let optionNode = <Option key={index} label={label} value={index++}>
        {label}
      </Option>;
      if (type === "contacts") {
        if (optionSuggestionData[i].contactType === "vendor") {
          supplierContacts.push(optionNode)
        }
        else if (optionSuggestionData[i].contactType === "customer") {
          customerContacts.push(optionNode)
        } else if (optionSuggestionData[i].contactType === "directContact") {
          directContacts.push(optionNode)
        } else {
          selfContacts.push(optionNode)
        }
      }
      else {
        suggestionDetails.push(optionNode)
      }
    }
  }
  if (type === "contacts") {
    suggestionDetails = [];
    suggestionDetails.push(
      <OptGroup label="Supplier">
        {supplierContacts}
      </OptGroup>
    )
    suggestionDetails.push(
      <OptGroup label="Customer">
        {customerContacts}
      </OptGroup>
    )
    suggestionDetails.push(
      <OptGroup label="Contacts">
        {directContacts}
      </OptGroup>
    )
    suggestionDetails.push(
      <OptGroup label="Self">
        {selfContacts}
      </OptGroup>
    )
  }
  if (type === "items") {
    suggestionDetails.push(
      <Option value="addnewItem"><a>Add New Item</a></Option>
    )
  }
  return { suggestionDetails, suggestionList, idToValue };
}

export function itemMappingSuggestionList(suggestionData, type) {
  let optionSuggestionData = [].concat(suggestionData);
  let suggestionDetails = [], suggestionList = [];
  for (let i in optionSuggestionData) {
    if (optionSuggestionData[i].identifier || type === "items") {
      suggestionList.push(optionSuggestionData[i]);
      suggestionDetails.push(
        <Option value={optionSuggestionData[i]["@id"]} label={optionSuggestionData[i].name}>
          {optionSuggestionData[i].name}
        </Option>
      )
    }
  }
  return { suggestionDetails, suggestionList };
}
// export function routeMappingSuggestionList(suggestionData, type) {
//   let optionSuggestionData = [].concat(suggestionData);
//   let suggestionDetails = [], suggestionList = [];
//   for (let i in optionSuggestionData) {
//     if (optionSuggestionData[i].identifier || type === "route") {
//       suggestionList.push(optionSuggestionData[i]);
//       suggestionDetails.push(
//         <Option value={optionSuggestionData[i]["@id"]} label={optionSuggestionData[i].route}>
//           {optionSuggestionData[i].route}
//         </Option>
//       )
//     }
//   }
//   return { suggestionDetails, suggestionList };
// }
export function getAttachedDocumentText(currentTab, subType) {
  switch (subType) {
    case "returnRequest":
      return "returnOrder";
    case "debitNote":
    case "creditNote":
      return "Payment";
    case "acceptNote":
      return "An";
    case "logistics":
      return attachedLogisticsDocumentText(currentTab);
    default:
      break;
  }
  switch (currentTab) {
    case "contract":
      return "Purchase Order";
    case "purchaseOrder":
      return "Invoice";
    case "invoice":
      return "Payment";
    case "grn":
      return "Grn";
    case "dispute":
      return "Dispute";
    case "returns":
      return "Returns";
    case "returnRequest":
      return "Return Order";
    default:
      return "Contract";
  }
}
function attachedLogisticsDocumentText(currentTab) {
  switch (currentTab) {
    case "rateCard":
      return "Transport Order";
    case "transportOrder":
      return "Invoice";
    case "invoice":
      return "Payment";
    default:
      return "Transport Order";
  }
}
export function getTransactionTypeWithIcon(transactionType) {
  switch (transactionType) {
    case "1":
      return <Tooltip title="Public">
        <Icon type="global" />
      </Tooltip>
    case "2":
      return <Tooltip title="Protected">
        <Icon type="key" />
      </Tooltip>;
    case "3":
      return <Tooltip title="Private">
        <Icon type="lock" />
      </Tooltip>;
    default:
      return <Tooltip title="Public">
        <Icon type="global" />
      </Tooltip>;
  }
}

export function getItemTableData(receiptData) {
  let items = [];
  let subTotal = 0, totalTaxValue = 0, sgstTotal = 0, cgstTotal = 0, igstTotal = 0;
  let data = [].concat(receiptData);

  for (let i = 0; i < data.length; i++) {
    let itemObj = {}
    if (data[i].orderQuantity != "0") {
      itemObj["grnQuantities"] = data[i].grnQuantities;
      itemObj["grnPrices"] = data[i].grnPrices;
      itemObj["poQuantity"] = data[i].poQuantity;
      itemObj["poPrice"] = data[i].poPrice;
      itemObj["status"] = data[i].status;
      itemObj["mappingInfo"] = data[i].mappingInfo;
      itemObj["key"] = i;
      itemObj["item"] = data[i].orderedItem.name;
      itemObj["invoice"] = data[i].invoice;
      itemObj["truckType"] = data[i].truckType;
      itemObj["type"] = data[i].orderedItem.category;
      itemObj["unitPrice"] = data[i].orderedItem.offers.price;
      itemObj["quantity"] = data[i].orderQuantity;
      itemObj["load"] = data[i].orderQuantity;
      itemObj["productId"] = data[i].orderedItem.productId || '-';
      itemObj["internalId"] = data[i].orderItemNumber
      itemObj["materials"] = data[i].orderedItem.materials;
      itemObj["logistics"] = data[i].logistics;
      itemObj["additionalProperties"] = data[i].orderedItem.additionalProperties;

      let addOn = data[i].orderedItem.offers.addOn
      for (let index = 0; index < addOn.length; index++) {
        itemObj[(addOn[index].name).toLowerCase()] = Number(addOn[index].price)
      }
      if (!itemObj.igst) {
        itemObj.igst = 0;
      }
      if (!itemObj.discount) {
        itemObj.discount = 0;
      }
      let amt = Number(data[i].orderedItem.offers.price) * Number(data[i].orderQuantity);
      sgstTotal += (Number(itemObj.sgst) / 100) * amt
      cgstTotal += (Number(itemObj.cgst) / 100) * amt
      igstTotal += (Number(itemObj.igst) / 100) * amt
      itemObj["subTotal"] = amt;
      let totalTax = Number(itemObj.sgst) + Number(itemObj.cgst) + Number(itemObj.igst);
      let taxValue = Number(amt * 0.01 * totalTax);
      totalTaxValue += taxValue
      const discount = Number(itemObj.discount);
      const discountValue = Number(discount / 100 * amt);
      itemObj["amount"] = (taxValue + amt - discountValue);
      subTotal += amt;

      items.push(itemObj);
    }

  }
  subTotal = Number(subTotal).toFixed(2);
  totalTaxValue = Number(totalTaxValue).toFixed(2);
  sgstTotal = Number(sgstTotal).toFixed(2);
  cgstTotal = Number(cgstTotal).toFixed(2);
  igstTotal = Number(igstTotal).toFixed(2);
  return { items, subTotal, totalTaxValue, sgstTotal, cgstTotal, igstTotal };
}

export function checkForAlignedWithContract(data1, data2) {
  let priceNotAligned = 0, quantityNotAligned = 0;
  for (let index1 in data1) {
    for (let index2 in data2) {
      if (data1[index1].orderItemNumber === data2[index2].orderItemNumber) {
        if (Number(data2[index2].orderedItem.offers.price) > Number(data1[index1].orderedItem.offers.price)) {
          priceNotAligned = 1;
        }
        if (Number(data2[index2].orderQuantity) > Number(data1[index1].orderQuantity)) {
          quantityNotAligned = 2;
        }
      }
    }
  }

  return priceNotAligned + quantityNotAligned;
}

export function getDataToValidate(receiptData, heading, noGrnForReturnRequest, inData, outData) {
  inData = getArrayOfData(inData);
  outData = getArrayOfData(outData);
  receiptData = JSON.parse(JSON.stringify(receiptData)); //for cloning purpose
  let originalReceiptData = [].concat(receiptData);
  for (let index in originalReceiptData) {
    originalReceiptData[index].orderQuantity = 0;
  }

  switch (heading) {
    case "Payment":
      outData = [];
      break;
    default:
      break;
  }

  const adjustQuantity = (childrenData, operation) => {

    for (let childIndex in childrenData) {
      let innerTraversal = getArrayOfData(childrenData[childIndex]['orderedItem']);
      for (let childItemIndex in innerTraversal) {
        for (let index in originalReceiptData) {
          if (innerTraversal[childItemIndex].orderItemNumber === originalReceiptData[index].orderItemNumber) {
            originalReceiptData[index].traversed = true;
            if (operation === "minus") {
              originalReceiptData[index].orderQuantity =
                Math.max(0, Number(originalReceiptData[index].orderQuantity) -
                  Number(innerTraversal[childItemIndex].orderQuantity));
            } else if (operation === "plus") {
              originalReceiptData[index].orderQuantity = Number(originalReceiptData[index].orderQuantity) +
                Number(innerTraversal[childItemIndex].orderQuantity);
            }
          }
        }
      }
    }
  }

  adjustQuantity(inData, "plus");
  adjustQuantity(outData, "minus");

  return originalReceiptData;

}

export function getParentTab(currentTab, subType) {
  if (subType === "logistics") {
    switch (currentTab) {
      case "rateCard":
        return "rateCard";
      case "transportOrder":
        return "rateCard"
      case "invoice":
        return "transportOrder";
      case 'eLR':
      case 'elr':
      case 'epod':
      case 'ePoD':
      case 'draftinvoices':
      case 'draftInvoice':
        return "transportOrder"
      default:
        return "rateCard";
    }
  }
  if (subType === "quality") {
    switch (currentTab) {
      case 'qcreditNote':
      case 'qcreditnote':
        return 'debitAdvice'
      default:
        return 'debitAdvice'
    }
  }
  switch (currentTab) {
    case "contract":
      return "contract";
    case "purchaseOrder":
      return "contract";
    case "invoice":
      return "purchaseOrder";
    case "payment":
      return "invoice";
    case "grn":
    case "gRN":
      return "purchaseOrder";
    case "returnRequest":
      return "invoice";
    case "returnOrder":
      return "returnRequest";
    case "acceptNote":
      return "purchaseOrder";
    default:
      return "contract";
  }
}

export function getStepNoFromTabName(tabName) {
  switch (tabName) {
    case "contract":
      return "0";
    case "purchaseOrder":
      return "1";
    case "invoice":
      return "2";
    case "payment":
      return "3";
    default:
      return "0";
  }
}

export function getAttachedTextByStatus(status, subType) {
  if (subType === "returnRequest" || subType === "returnOrder" ||
    subType === "grn" || subType === "acceptNote" ||
    subType === "partPurchaseOrder" || subType === "debitNote" || subType === "creditNote" || subType === "epod") {
    return camelCaseToString(subType)
  }
  if (subType === "logistics") {
    return getAttachedLogisticsTextByStatus(status);
  }
  return getAttachedReceiptTextByStatus(status);
}

export function getAttachedLogisticsTextByStatus(status) {
  let tabNameArray = ["", "Transport Order", "Transport Order", "Invoice", "Invoice", "Payment", "Payment"];
  return tabNameArray[status];
}


export function getAttachedReceiptTextByStatus(status) {
  let tabNameArray = ["", "Purchase Order", "Purchase Order", "Invoice", "Invoice", "Payment", "Payment"];
  return tabNameArray[status];
}


export function convertToTemplate(jsonld) {
  let keys = ['name', 'email', 'identifier', 'telephone', 'faxNumber', 'address', 'city', 'worksFor', 'taxId', 'nationality']
  let convertedData = {}, formValue = [], cardsInvolved = [], cards = {};
  let data = Object.entries(jsonld);
  for (let index = 0; index < data.length; index++) {
    if (keys.includes(data[index][0])) {
      if (typeof data[index][1] === 'string') {
        formValue.push({
          fieldName: data[index][0].toLowerCase(),
          value: data[index][1]
        })
      } else if (typeof data[index][1] === 'object') {
        if (data[index][0] === 'address') {
          formValue.push({
            fieldName: 'state',
            value: data[index][1].addressRegion
          })
          formValue.push({
            fieldName: 'street',
            value: data[index][1].streetAddress
          })
          formValue.push({
            fieldName: 'locality',
            value: data[index][1].addressLocality
          })
          formValue.push({
            fieldName: 'postalcode',
            value: data[index][1].postalCode
          })
        } else if (data[index][0] === 'worksFor') {
          formValue.push({
            fieldName: 'organisation',
            value: data[index][1].name
          })
        } else if (data[index][0] === 'nationality') {
          formValue.push({
            fieldName: 'country',
            value: data[index][1].name
          })
        } else {
          formValue.push({
            fieldName: data[index][0].toLowerCase(),
            value: data[index][1].name
          })
        }

      }
    }
  }
  for (let index in formValue) {
    formValue[index].key = index;
  }
  convertedData["key"] = "1"
  convertedData["cardName"] = "Basic Details"
  convertedData["formValue"] = formValue;
  cardsInvolved.push(convertedData)
  cards["cardsInvolved"] = cardsInvolved;
  cards["publicKey"] = jsonld.publicKey
  console.debug('converted data', JSON.stringify(cards))
  return JSON.stringify(cards);
}

export function getDisputedata(receiptData) {
  let dataSource = [];
  let taxValue;
  if (receiptData.referencesOrder) {
    let { orderedItem } = receiptData.referencesOrder;
    let receiptObj;
    for (let index in orderedItem) {
      taxValue = 0;
      let currentData = orderedItem[index];
      // if(currentData.priceDispute !== "true" && currentData.quantityDispute !== "true"){
      //     continue;
      // }
      let itemPrice = Number(currentData.orderedItem.offers.price);
      let itemQuantity = Number(currentData.orderQuantity);
      let itemAddOns = currentData.orderedItem.offers.addOn;
      receiptObj = {
        key: index,
        item: currentData.orderedItem.name,
        type: currentData.orderedItem.category,
        unitPrice: itemPrice,
        quantity: itemQuantity,
        internalId: currentData.orderItemNumber,
        subTotal: itemPrice * itemQuantity,
        amount: itemPrice * itemQuantity,
        quantityMismatch: Boolean(currentData.quantityDispute),
        amountMismatch: Boolean(currentData.priceDispute),
        additionalProperties: currentData.orderedItem.additionalProperties

      }
      for (let addOnIndex in itemAddOns) {
        receiptObj[itemAddOns[addOnIndex].name.toLowerCase()] = Number(itemAddOns[addOnIndex].price);
      }
      if (!receiptObj.igst) {
        receiptObj.igst = 0;
      }
      if (!receiptObj.discount) {
        receiptObj.discount = 0;
      }
      let sgstTotal = (Number(receiptObj.sgst) / 100) * receiptObj.amount
      let cgstTotal = (Number(receiptObj.cgst) / 100) * receiptObj.amount
      let igstTotal = (Number(receiptObj.igst) / 100) * receiptObj.amount

      const discount = Number(receiptObj.discount);
      const discountValue = Number(discount / 100 * receiptObj.amount);

      receiptObj.amount += (cgstTotal + sgstTotal + igstTotal - discountValue);
      let grnPrice = Number(currentData.grnPrices || 0);
      let grnQuantity = Number(currentData.grnQuantities || 0);
      let ANPrice = Number(currentData.ANPrices || 0);
      let ANQuantity = Number(currentData.asnQuantities || 0);

      taxValue = (0.01 * grnPrice * grnQuantity * receiptObj.cgst)
        + (0.01 * grnPrice * grnQuantity * receiptObj.sgst)
        + (0.01 * grnPrice * grnQuantity * receiptObj.igst)
        - (0.01 * grnPrice * grnQuantity * (receiptObj.discount || 0))
      grnPrice = (grnPrice * grnQuantity);
      grnPrice += taxValue;
      let poPrice = Number(currentData.poPrice);
      let poQuantity = Number(currentData.poQuantity);
      let receiptChildren = [
        {
          item: "Invoice",
          unitPrice: receiptObj.unitPrice,
          quantity: receiptObj.quantity,
          cgst: receiptObj.cgst,
          sgst: receiptObj.sgst,
          igst: receiptObj.igst,
          amount: receiptObj.amount,
          childData: true,
          receiptId: receiptData["@id"]
        },
        {
          item: "GRN",
          unitPrice: Number(currentData.grnPrices || 0),
          quantity: grnQuantity,
          cgst: receiptObj.cgst,
          sgst: receiptObj.sgst,
          igst: receiptObj.igst,
          subTotal: (grnPrice * grnQuantity),
          amount: grnPrice,
          // childData: true
        },
        // {
        //   item: "AN",
        //   unitPrice: Number(currentData.ANPrices || 0),
        //   quantity: ANQuantity,
        //   cgst: receiptObj.cgst,
        //   sgst: receiptObj.sgst,
        //   igst: receiptObj.igst,
        //   subTotal: (ANPrice * ANQuantity),
        //   amount: ANPrice,
        //   // childData: true
        // },
        {
          item: "Purchase Order",
          unitPrice: poPrice,
          quantity: poQuantity,
          cgst: receiptObj.cgst,
          sgst: receiptObj.sgst,
          igst: receiptObj.igst,
          subTotal: (poPrice * poQuantity),
          amount: (poPrice * poQuantity)
            + (0.01 * poPrice * poQuantity * receiptObj.cgst)
            + (0.01 * poPrice * poQuantity * receiptObj.sgst)
            + (0.01 * poPrice * poQuantity * receiptObj.igst)
            - (0.01 * poPrice * poQuantity * (receiptObj.discount || 0)),
          childData: true,
          receiptId: receiptData["parentDocId"]
        }
      ]
      receiptObj.children = receiptChildren;
      dataSource.push(receiptObj)
    }
  }
  return { dataSource };
}

export function getNoteInfo(receiptData) {
  let orderedItem = receiptData.referencesOrder.orderedItem;
  let addOnObj = {};
  let extraAmount = 0, priceDifference;
  for (let index in orderedItem) {
    let currentData = orderedItem[index];
    let itemAddOns = currentData.orderedItem.offers.addOn;
    for (let addOnIndex in itemAddOns) {
      addOnObj[itemAddOns[addOnIndex].name.toLowerCase()] = Number(itemAddOns[addOnIndex].price || 0);
    }
    let grnQuantity = Number(currentData.grnQuantities || 0);
    let grnPrice = Number(currentData.grnPrices || 0);
    let itemPrice = Number(currentData.orderedItem.offers.price || 0);
    let itemQuantity = Number(currentData.orderQuantity || 0);
    priceDifference = (itemPrice * itemQuantity) - (grnQuantity * grnPrice);
    extraAmount += (priceDifference)
      + (priceDifference * 0.01 * addOnObj.cgst)
      + (priceDifference * 0.01 * addOnObj.sgst)
      + (priceDifference * 0.01 * addOnObj.igst)
      - (priceDifference * 0.01 * (addOnObj.discount || 0))
  }

  let noteType = "Debit";
  let noteValue = Math.abs(extraAmount);
  if (extraAmount < 0) {
    noteType = "Credit";
  }
  return { noteType, noteValue };
}

export function convertToProfileFormat(data) {
  let profile = {};
  profile["@context"] = "http://schema.org/"
  profile["@type"] = "Person"
  profile["identifier"] = data.identifier;
  profile["publicKey"] = data.publicKey;
  profile["address"] = {
    "@type": "PostalAddress"
  }
  let basicDetails;
  if (Array.isArray(data.cardsInvolved)) {
    for (let cardIndex in data.cardsInvolved) {
      if (data.cardsInvolved[cardIndex].cardName === "Basic Details") {
        basicDetails = data.cardsInvolved[cardIndex].formValue;
      }
    }
  } else if (data.cardsInvolved) {
    basicDetails = data.cardsInvolved.formValue
  } else {
    return data;
  }
  let defaultValueChecker = (value) => { return (value && value !== "DefaultValue") ? value : "" }

  for (let index in basicDetails) {
    if (basicDetails[index].fieldName === 'name') {
      profile['name'] = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'email') {
      profile['email'] = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'faxnumber') {
      profile['faxNumber'] = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'taxid') {
      profile['taxId'] = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'telephone') {
      profile['telephone'] = defaultValueChecker(basicDetails[index].value)
      profile['contactPoint'] = { "@type": "ContactPoint", "telephone": defaultValueChecker(basicDetails[index].value) }
    } else if (basicDetails[index].fieldName === 'organisation') {
      profile['WorksFor'] = {
        "@type": "Organization",
        "name": defaultValueChecker(basicDetails[index].value)
      }
    } else if (basicDetails[index].fieldName === 'country') {
      profile["nationality"] = {
        "@type": "Country",
        "name": defaultValueChecker(basicDetails[index].value)
      }
    } else if (basicDetails[index].fieldName === 'state') {
      profile["address"].addressRegion = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'street') {
      profile["address"].streetAddress = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'locality') {
      profile["address"].addressLocality = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'postalcode') {
      profile["address"].postalCode = defaultValueChecker(basicDetails[index].value)
    } else if (basicDetails[index].fieldName === 'city') {
      profile["city"] = defaultValueChecker(basicDetails[index].value)
    }
  }
  profile["jobTitle"] = {
    "@type": "DefinedTerm"
  }

  return profile;
}

export function getDefaultTemplate(type) {
  if (type === 'supplier') {
    return getFromLocalStorage('supplierDefaultTemplate')
  }
  return getFromLocalStorage('customerDefaultTemplate')
}

export function getLatestContactStatus(status) {
  switch (status) {
    case '0':
      return 'Invited';
    case '1':
      return 'Pending for approval';
    case '2':
      return 'Rejected';
    case '3':
      return '';
    case '4':
      return 'Approved';
    default:
      break;
  }
}

export function getTabNameFromStep(step) {
  switch (step) {
    case "0":
      return "contract";
    case "1":
      return "purchaseOrder";
    case "2":
      return "invoice";
    case "3":
      return "payment";
    default:
      return "draftinvoices";
  }
}

export function getUnique(arr, comp) {
  const unique = arr.map(e => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e]).map(e => arr[e]);
  return unique;
}

export function getInvoiceId(timelineData, defaultReceiptId) {
  for (let timelineIndex = 0; timelineIndex < timelineData.length; timelineIndex++) {
    if (timelineData[timelineIndex].docType === "SEND_INVOICE") {
      return timelineData[timelineIndex].currentDocId;
    }
  }
  return defaultReceiptId;
}

export function getDocumentInternalId(data) {
  if (data["acceptNoteNumber"] !== undefined) {
    return data["acceptNoteNumber"]
  } else if (data["gRNNumber"] !== undefined) {
    return data["gRNNumber"]
  } else if (data["eLRNumber"] !== undefined) {
    return data["eLRNumber"]
  } else if (data["ePoDNumber"] !== undefined) {
    return data["ePoDNumber"]
  } else if (data["noteNumber"] !== undefined) {
    return data["noteNumber"]
  } else if (data["returnRequestNumber"] !== undefined) {
    return data["returnRequestNumber"]
  } else if (data["returnOrderNumber"] !== undefined) {
    return data["returnOrderNumber"]
  } else if (data["paymentNumber"] !== undefined) {
    return data["paymentNumber"]
  } else if (data["invoiceNumber"] !== undefined) {
    return data["invoiceNumber"]
  } else if (data["purchaseOrderNumber"] !== undefined) {
    return data["purchaseOrderNumber"]
  } else if (data["transportOrderNumber"] !== undefined) {
    return data["transportOrderNumber"]
  } else if (data["rateCardNumber"] !== undefined) {
    return data["rateCardNumber"]
  } else if (data["poNumber"] !== undefined) {
    return data["poNumber"]
  } else if (data["contractNumber"] !== undefined) {
    return data["contractNumber"]
  } else if (data["draftInvoiceNumber"] !== undefined) {
    return data["draftInvoiceNumber"]
  }
  return trimNumbers(data["@id"])
}

export function capitalizeText(text) {
  if (!text || typeof (text) !== "string") {
    return text;
  }
  return text[0].toUpperCase() + text.slice(1);
}

export function numberWithCommas(text, precision = 2) {
  let number = Number(text)
  if (isNaN(number) || number === undefined) {
    return 0.00
  }
  number = number.toFixed(precision)
  let fragments = number.split('.');
  return fragments[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + fragments[1];
}

export function getDisplay(length) {
  if (length == 0) {
    return {
      display: 'none'
    }
  }
}

export function getPlaceholderMessage(chartName, data) {
  let msg;
  if (data === null || data === undefined) {
    msg = `There is no data for ${chartName}`
  } else if ((Array.isArray(data) && data.length === 0) || (typeof data === 'object')) {
    msg = `There is no data for ${chartName}`
  }
  return msg;
}

export function getNameByParamId(paramId) {
  const selfParamId = getParamId();
  const contactManager = NetworkBridge.getContactManager();
  const vendorManager = NetworkBridge.getVendorManager();
  if (paramId === selfParamId) {
    return Promise.resolve(JSON.parse(getFromLocalStorage('profile')).name);
  }
  return contactManager.getContactNameByParamId(paramId).then(name => {
    if (name) {
      return name;
    }
    return vendorManager.getContactNameByParamId(paramId).then(name => {
      if (name) {
        return name;
      }
      return paramId;
    })
  })
}

export function getContactSummaryByParamId(paramId) {
  const selfParamId = getParamId();
  const contactManager = NetworkBridge.getContactManager();
  const vendorManager = NetworkBridge.getVendorManager();
  if (paramId === selfParamId) {
    return Promise.resolve(getMyProfileJsonLd());
  }

  return contactManager.getContactSummaryByParamId(paramId, selfParamId).then(res => {
    if (res) {
      return res;
    }
    return vendorManager.getContactSummaryByParamId(paramId).then(res => {
      if (res) {
        return res;
      }
      return paramId;
    })
  })
}

export function getAllContacts(paramId) {
  let vendorManager = NetworkBridge.getVendorManager();
  let contactManager = NetworkBridge.getContactManager();
  let contactsContactData = [];
  return contactManager.getAllContacts(paramId).then((contactData) => {
    let contactPromise = [];
    for (let i = 0; i < contactData.count; i++) {
      contactPromise.push(contactManager.getContactSummary(contactData.contacts[i]).then(res => {
        if (res) {
          res.contactType = "directContact";
        }
        return res;
      }));
    }
    return Promise.all(contactPromise);
  })
    .then((contactDetails) => {
      if (contactDetails) {
        contactsContactData = contactDetails;
      }
      return vendorManager.getAllContacts(paramId);
    }).then((contactData) => {
      let contactPromise = [];
      for (let i = 0; i < contactData.count; i++) {
        contactPromise.push(vendorManager.getContactSummary(contactData.contacts[i]));
      }
      return Promise.all(contactPromise);
    }).then(contactDetails => {
      console.log("-----contacts---", contactDetails);
      contactDetails.push(...contactsContactData);
      return contactDetails
    })
}

export function getRealTimeUpdate(type) {
  let config = getFromLocalStorage('dashboardUpdateConfig')
  switch (type) {
    case 'receipts': return config.receiptsEnabled;
    case 'grn': return config.grnEnabled;
    case 'catalogue': return config.catalogueEnabled;
    case 'vendor': return config.vendorEnabled;
    case 'returns': return config.returnsEnabled;
    default: break;
  }
}

export function canFetchData(type) {
  let config = getFromLocalStorage('canFetchData')
  if (!type) {
    return config;
  }
  switch (type) {
    case 'receipts': return config.receipts;
    case 'grn': return config.grn;
    case 'catalogue': return config.catalogue;
    case 'vendor': return config.vendor;
    case 'returns': return config.returns;
    default: break;
  }
}

export function setCanFetchData(type, value) {
  let canFetchDataConfig = getFromLocalStorage('canFetchData');
  if (!canFetchDataConfig) {
    setLocalStorage("canFetchData",
      {
        receipts: true,
        grn: true,
        catalogue: true,
        vendor: true,
        returns: true
      }
    )
    return;
  }
  canFetchDataConfig[type] = value;
  setLocalStorage('canFetchData', canFetchDataConfig);
  return;
}

export function getDocumentTemplateType(type) {
  if (type) {
    let customThemeSetting = getFromLocalStorage('customThemeSetting');
    if (!customThemeSetting) {
      customThemeSetting = setDefaultCustomTheme();
    }
    return customThemeSetting[type].isCustomTheme ? "custom" : "default"
  }
  return "default";
}

export function getDocumentTemplate(type) {
  return DocumentTheme.getInstance().getTheme(type).data;
}

export function isDocumentRejected(status) {
  return status === "2" || status === "4" || status === "6" || status === "9" || status === "11";
}

export function getSkeletonRow(columns) {
  let skeletonObj = {}
  for (let index in columns) {
    let currentColumn = columns[index]
    if (currentColumn.children) {
      for (let innerIndex in currentColumn.children) {
        skeletonObj[currentColumn.children[innerIndex].dataIndex] = null
      }
    }
    else {
      skeletonObj[currentColumn.dataIndex] = null
    }
  }
  return skeletonObj
}


export function getSkeletonCard(headings) {
  let skeletonObj = {}
  for (let index in headings) {
    let currentKey = headings[index]
    skeletonObj[currentKey] = null
  }
  return skeletonObj
}

export function getTemplateCurrentTab(step) {
  let currentTab
  switch (step) {
    case '0':
      currentTab = 'contract'
      break;
    case "1":
      currentTab = 'purchaseOrder'
      break;
    case "2":
      currentTab = "invoice"
      break;
    case "3":
      currentTab = "payment"
      break;
    // case "4":
    //   currentTab = "payment"
    //   break;
    default:
      currentTab = "payment"
      break
  }
  return currentTab
}

export function getTaxAmountInWords(receiptData) {
  let items = receiptData.referencesOrder.orderedItem, totalTaxAmount = 0;
  for (let index in items) {
    let amt = Number(items[index].orderQuantity) * Number(items[index].orderedItem.offers.price);
    for (let tIndex in items[index].orderedItem.offers.addOn) {
      if (items[index].orderedItem.offers.addOn[tIndex].name !== 'discount') {
        totalTaxAmount += amt * (Number(items[index].orderedItem.offers.addOn[tIndex].price) / 100)
      }
    }
  }
  totalTaxAmount = totalTaxAmount.toFixed(2);
  return getAmountText(totalTaxAmount, receiptData.totalPaymentDue.priceCurrency)
}

export function getDocInfo(state) {
  let currentTab = getTemplateCurrentTab(state.receiptData.step)
  let nextTabType = getAttachedDocumentText(currentTab, state.receiptData['@subType']), noteValuesInfo = {}
  let status = fetchStatus(state.receiptData.status, nextTabType, state.receiptData['@subType'])
  let amountDue = state.receiptData.totalPaymentDue.price;
  let amountDueDisplay = amountDue;
  // amountDueDisplay = Number(amountDue) + noteValuesInfo.creditNote + noteValuesInfo.debitNote - Number(amountReceived);
  let amountDueInWords = getAmountText(amountDue, state.receiptData.totalPaymentDue.priceCurrency);
  let attachments = [];
  if (state.receiptData.fileAttachments) {
    attachments = getArrayOfData(state.receiptData.fileAttachments)
  }
  let docInfoObj = {
    receiptMode: getFromLocalStorage('receiptMode'),
    docInternalId: getDocumentInternalId(state.receiptData),
    docId: state.receiptData["@id"],
    invoiceNo: state.receiptData["@id"],
    noOfDocuments: state.receiptData.childDocId && Array.isArray(state.receiptData.childDocId) ? state.receiptData.childDocId.length : undefined,
    attachments,
    taxAmountInWords: getTaxAmountInWords(state.receiptData),
    currency: state.receiptData.totalPaymentDue.priceCurrency,
    amountDue,
    amountDueInWords,
    amountDueDisplay,
    // amountReceived,
    noteValuesInfo,
    // noteType,
    fetchStatus: status,
    currentTab,
    typeOfDocument: nextTabType,
    expiryDate: moment(state.receiptData.expiryDate).format("YYYY-MM-DD"),
    orderDate: moment.unix(state.receiptData.referencesOrder.orderDate / 1000).format("YYYY-MM-DD"),
    parentDocId: state.receiptData['parentDocId'] && getTransactionData(state.receiptData['parentDocId']).id,
    alignedInfoText: alignedInfoText[state.receiptData.alignedInfo],
    contractId: state.contractId,
    contractInternalId: state.contractInternalId,
    parentInternalDocId: state.parentInternalDocId,
    receiptInternalId: state.receiptInternalId,
    additionalProperties: state.receiptData.additionalProperties && JSON.parse(state.receiptData.additionalProperties),
    subTypeDocsAttached: []
  }
  return docInfoObj

}


export function saveTheme(type, themeObj) {
  if (AppUtils.isDesktop() && themeObj.type != "default") {
    SaveAndReadFile.getInstance().saveFile({ screenName: "Themes", fileName: `${getParamId()}-${type}.ejs`, fileContent: themeObj.template, paramId: getParamId(), password: getFromLocalStorage('hashedPassword') });
  } else {
    setLocalStorage(type, themeObj);
  }
  let customThemeSetting = getFromLocalStorage('customThemeSetting');
  if (!customThemeSetting) {
    customThemeSetting = setDefaultCustomTheme();
  }
  if (themeObj.type != "default") {
    customThemeSetting[type].isCustomTheme = true;
  } else {
    customThemeSetting[type].isCustomTheme = false;
  }
  if (themeObj) {
    debugger;
    customThemeSetting[type].versionInfo = themeObj.templateVersionInfo || {};
    customThemeSetting[type].additionalProperties = themeObj.additionalProperties || {};
    customThemeSetting[type].dynamicFormDataType = themeObj.dynamicFormDataType || [];
  }
  setLocalStorage('customThemeSetting', customThemeSetting);
}

export function setDefaultCustomTheme() {
  let customThemeSetting = {
    'contract': { isCustomTheme: false, versionInfo: {}, additionalProperties: {}, dynamicFormDataType: [] },
    'purchaseOrder': { isCustomTheme: false, versionInfo: {}, additionalProperties: {}, dynamicFormDataType: [] },
    'invoice': { isCustomTheme: false, versionInfo: {}, additionalProperties: {}, dynamicFormDataType: [] },
    'payment': { isCustomTheme: false, versionInfo: {}, additionalProperties: {}, dynamicFormDataType: [] },
    'rateCard': { isCustomTheme: false, versionInfo: {}, additionalProperties: {}, dynamicFormDataType: [] }
  }
  setLocalStorage("customThemeSetting", customThemeSetting);
  return customThemeSetting;
}

export function getTemplateVersion(type) {
  let customThemeSetting = getFromLocalStorage('customThemeSetting');
  if (!customThemeSetting) {
    customThemeSetting = setDefaultCustomTheme();
  }
  return customThemeSetting[type].versionInfo;
}

export function getTemplateAdditionalProperties(type) {
  if (!type) {
    return [];
  }
  let customThemeSetting = getFromLocalStorage('customThemeSetting');
  if (!customThemeSetting) {
    customThemeSetting = setDefaultCustomTheme();
  }
  if (!customThemeSetting[type]) {
    return {};
  }
  return customThemeSetting[type].additionalProperties || {};
}

export function isVersionCompatible(templateMinBuildVersion, templateMaxBuildVersion) {
  templateMinBuildVersion = Number(templateMinBuildVersion) || 0;
  templateMaxBuildVersion = Number(templateMaxBuildVersion) || 999;
  let currentVersion = Number(AppInfo.appInfo.version) || 0;
  return templateMinBuildVersion <= currentVersion && currentVersion <= templateMaxBuildVersion;
}

export function getTemplateFormData(type) {
  if (!type) {
    return [];
  }
  let customThemeSetting = getFromLocalStorage('customThemeSetting');
  if (!customThemeSetting) {
    customThemeSetting = setDefaultCustomTheme();
  }
  if (!customThemeSetting[type]) {
    return [];
  }
  return customThemeSetting[type].dynamicFormDataType || [];
}

export function serializeURLParameters(obj) {
  return Object.keys(obj)
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

export function getCurrencyFromProfile() {

  let profile = JSON.parse(getFromLocalStorage('profile'));
  let country = 'INR';
  if (profile && profile.nationality && profile.nationality.name) {
    country = profile.nationality.name;
  }
  return countryCurrency.search(country)[0].currency.currencyCode;
}

export function encodeString(str) {
  let encodedStr = btoa(str);
  return encodedStr;
}

export function decodeString(str) {
  let decodedStr = atob(str);
  return decodedStr;
}


export function getItemNameWithIcon(itemInfo) {
  return <div>
    {getItemStatusIcon(itemInfo)}
    {itemInfo.name || itemInfo.item}
  </div>
}

export function getItemStatusIcon(itemInfo) {
  if (!itemInfo) {
    return;
  }
  let iconType = "warning", iconColor = "red", hoverText = "UnMapped & UnApproved";
  if (itemInfo.mappingInfo) {
    iconColor = "orange";
    hoverText = "Mapped & UnApproved";
    if (itemInfo.mappingInfo.status === "1") {
      iconType = "check-circle";
      iconColor = "green";
      hoverText = "Approved";
    }
    if (itemInfo.mappingInfo.status === "0") {
      iconType = "close-circle";
      iconColor = "red";
      hoverText = "Rejected";
    }
  }
  return <IconComponent type={iconType} style={{ color: iconColor }} hoverText={hoverText} />
}

export function generateByte64Id(id = '') {
  let randomHex = Crypto.randomBytes(32).toString('hex');
  let generatedId = Web3Utils.keccak256(randomHex, id)
  return generatedId;
}


export function codeSignPaylaod(payloadRes, contactAddress) {
  let data = payloadRes.data
  let options = {}
  options.privateKey = getPrivateKey()
  options.nonce = payloadRes.signedParams.Nonce
  options.from = payloadRes.signedParams.From
  options.to = payloadRes.signedParams.To || contactAddress
  options.gasPrice = payloadRes.signedParams.GasPrice
  options.gas = payloadRes.signedParams.Gas

  //Public transaction
  // if (data && !data.toLowerCase().startsWith('0x')) {
  //   data = '0x' + data;
  // }

  // options.from = options.from.toLocaleLowerCase()
  // if (!options.from.startsWith("0x")) {
  //   options.from = "0x" + options.from;
  // }
  // let transactionObj = {
  //   nonce: options.nonce,
  //   from: options.from,
  //   data: data,
  //   gasPrice: 0,
  //   gas: options.gas,
  //   to: options.to,
  // }

  // const privateKey = Buffer.from(options.privateKey, 'hex');
  // const tx = new Tx(transactionObj);
  // tx.sign(privateKey);
  // const serializedTx = tx.serialize();
  // return serializedTx.toString("hex")

  //Private transaction
  // let privateKey = getPrivateKey()
  // let options = {}
  // options.privateKey = privateKey
  // options.nonce = payloadRes.signedParams.Nonce
  // options.from = getParamId() //temp
  // options.to = contactAddress //contract Address
  // options.gasPrice = payloadRes.signedParams.GasPrice
  // options.data = payloadRes.data


  // if (options.data.toLowerCase().startsWith('0x')) {
  //   options.data = options.data.substring(2);
  // }
  // const privateUrl = "http://34.224.243.54:9081";
  // const payload = ParamUtils.getHexToBase64(options.data)
  // const from = "nHLzpsjqmIKB02Phyc4+cmd4LQQcl10SmxNKudnNNSo=";
  // const url = ProxyUtils.getProxyBaseURL(`${privateUrl}/storeraw`)
  // const network_options = {
  //   method: "POST",
  //   uri: url,
  //   json: true,
  //   body: { payload, from }
  // };
  // let headers = ProxyUtils.getAxiosHeaders(null, privateUrl, "storeraw");
  // if (headers) {
  //   network_options["headers"] = headers;
  // }
  // return rp(network_options).then(payload => {
  //   return ParamPrivateNode.serializeSignedTransaction(options, ParamUtils.getBase64ToHex(payload.key));
  // })
  let serializedTx = ParamPrivateNode.serializeSignedTransaction(options, data);
  return serializedTx.substring(2);
  // serializedTx = ParamPrivateNode.setPrivate(serializedTx);
  // return serializedTx.toString("hex")
}

export function convertGraphQLTnxType(type) {
  let txnType = Number(type) + 1
  return txnType.toString();
}

export function getReceiptMetaDataFromReceipt(receiptData) {
  let receiptMetaData = {}
  receiptMetaData.transactionType = receiptData.transactionType
  let step = receiptData.step
  if (step === "0" || step === "2") {
    receiptMetaData.seller = receiptData.provider.identifier
    receiptMetaData.buyer = receiptData.customer.identifier
  } else {
    receiptMetaData.seller = receiptData.customer.identifier
    receiptMetaData.buyer = receiptData.provider.identifier
  }
  return receiptMetaData
}

export function extractEventData(txnData) {
  let eventData = {}
  eventData['blockNumber'] = txnData.blockNumber
  eventData['blockHash'] = txnData.transactionBlockHash
  eventData['dateAndTime'] = txnData.createdAt / 1000000
  eventData['transactionHash'] = txnData.transactionHash
  eventData['event'] = txnData.type
  return eventData
}

export function getEventType(module) {
  switch (module) {
    case "contact":
      return "ContactEvents"
    case "catalogue":
      return "MasterEvents"
    case "inventory":
      return "InventoryEvents"
    case "inventoryHistory":
      return "InventoryHistoryEvents"
    case "receipt":
      return "ReceiptEvents"
    case "receiptSub":
      return "ReceiptSubscriberEvents"
    case "returns":
      return "ReturnsEvents"
    case "vendor":
      return "VendorEvents"
    case "templateRepo":
      return "TemplateRepoEvents"
    case "templateCons":
      return "TemplateConsensusEvents"
    default:
      return "ReceiptEvents"
  }
}

export function getLatestEventDataByName(eventRes, eventName) {
  let result = eventRes.slice().sort((a, b) => Number(b.eventID) - Number(a.eventID))
  if (!eventName) {
    return result[0]
  }
  return result.filter((element, index) => {
    return element.type === eventName;
  })[0]
}

export function getAllEventDataByName(eventRes, eventName, skipEvent) {
  let eventDetails = []
  let result = eventRes.slice().sort((a, b) => Number(b.eventID) - Number(a.eventID))
  if (eventName) {
    result = result.filter((element, index) => {
      return element.type === eventName;
    })
  }
  if (skipEvent) {
    result = result.filter((element) => {
      return element.type !== skipEvent;
    })
  }
  for (let index in result) {
    let eventMetaData = extractEventData(result[index])
    let dataJSON = getSubscriptionDataFromEventData(result[index])
    dataJSON = { ...dataJSON, ...eventMetaData }
    eventDetails.push(dataJSON)
  }
  return eventDetails
}

export function getSubscriptionDataFromEventData(eventData) {
  let dataJSON = { args: {} }
  let parseData = JSON.parse(eventData.json)
  for (let key in parseData) {
    if (key.endsWith('Id')) {
      dataJSON.args[stringToCamelCase(key)] = toHexString(parseData[key])
    }
    else if (key === 'ContractAddress') {
      dataJSON.args[stringToCamelCase(key)] = getChecksumAddress(parseData[key])
    }
    else {
      dataJSON.args[stringToCamelCase(key)] = parseData[key]
    }

  }
  return dataJSON
}

export function toHexString(byteArray) {
  var s = '0x';
  byteArray.forEach(function (byte) {
    s += ('0' + (byte & 0xFF).toString(16)).slice(-2);
  });
  return s;
}
export function downloadJsonAsCSV(json, fileName) {

  const fields = Object.keys(json[0]);
  const parser = new Parser({ fields });
  const csv = parser.parse(json);
  const element = document.createElement("a");
  const file = new Blob([csv],
    { type: 'text/plain;charset=utf-8' });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.csv`;
  document.body.appendChild(element);
  element.click();
}


export function getChecksumAddress(contractAddress) {
  return Web3.toChecksumAddress(contractAddress);

}
