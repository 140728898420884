class VendorManagement {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getAllContacts = (address) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getAllContacts", address)
    }

    getAllBuyers = (vendorAddress) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getAllBuyers", vendorAddress)
    }

    getAllCountByAddressAndIdentifier = (address, identifier) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getAllCountByAddressAndIdentifier", address, identifier)
    }

    getAllVendors = (buyerAddress) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getAllVendors", buyerAddress)
    }

    getAllEvalRequestsByInvitation = (invitationId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getAllEvalRequestsByInvitation", invitationId)
    }

    /**
     * 
     * @param {*} templateType - "0" - customer template
     *                           "1" - supplier template   
     */
    getAllInvitationTemplate = (owner, templateType) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getAllInvitationTemplate", owner, templateType)
    }

    getVendorByAddress = (vendor) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getVendorByAddress", vendor)
    }

    getBuyerByAddress = (buyer) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getBuyerByAddress", buyer)
    }

    getContactSummaryByParamId = (paramId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getContactSummaryByParamId", paramId)
    }

    getContactSummary = (contactId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getContactSummary", contactId)
    }
    getContactByParamId = (contactId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getContactByParamId", contactId)
    }

    getContact = (invitationId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getContact", invitationId)
    }

    getInvitaionTemplate = (templateId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getInvitaionTemplate", templateId)
    }

    getInvitaionTemplateSummary = (templateId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getInvitaionTemplateSummary", templateId)
    }

    getEvalRequest = (evalRequestId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getEvalRequest", evalRequestId)
    }

    getEvalRequestStatus = (documentId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getEvalRequestStatus", documentId)
    }

    getContactNameByParamId = (paramId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "getContactNameByParamId", paramId)
    }

    /**
     * invitationType:0-Buyer
     *                1-Vendor  
     */
    addContact = (invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, invitationType, owner, shareKey, formJsonLd, txnMode) => {
        return this.mongoDBConnector.invoke("vendorManagement", "addContact", invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, invitationType, owner, shareKey, formJsonLd, txnMode)
    }

    addVendor = (invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, owner, shareKey, formJsonLd) => {
        return this.mongoDBConnector.invoke("vendorManagement", "addVendor", invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, owner, shareKey, formJsonLd)
    }

    addBuyer = (invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, owner, shareKey, formJsonLd) => {
        return this.mongoDBConnector.invoke("vendorManagement", "addBuyer", invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, owner, shareKey, formJsonLd)
    }

    evalRequest = (evalRequestId, invitationId, documentId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "evalRequest", evalRequestId, invitationId, documentId)
    }

    updateContact = (invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, invitationType, owner, shareKey, formJsonLd, txnMode) => {
        return this.mongoDBConnector.invoke("vendorManagement", "updateContact", invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, invitationType, owner, shareKey, formJsonLd, txnMode)
    }

    createInvitationTemplate = (templateId, jsonLd, metaData, templateType, isPublic, owner, txnMode) => {
        return this.mongoDBConnector.invoke("vendorManagement", "createInvitationTemplate", templateId, jsonLd, metaData, templateType, isPublic, owner, txnMode)
    }

    enableDisableInvitationTemplate = (templateId, enableOrDisable) => {
        return this.mongoDBConnector.invoke("vendorManagement", "enableDisableInvitationTemplate", templateId, enableOrDisable)
    }

    updateOrAddInvitation = (invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, invitationType, owner, shareKey, formJsonLd, txnMode) => {
        return this.mongoDBConnector.invoke("vendorManagement", "updateOrAddInvitation", invitationId, vendorAddress, buyerAddress, templateId, jsonLd, status, step, invitationType, owner, shareKey, formJsonLd, txnMode)
    }

    updateInvitationStatus = (invitationId, newStatus, step) => {
        return this.mongoDBConnector.invoke("vendorManagement", "updateInvitationStatus", invitationId, newStatus, step)
    }

    writeStatus = (invitationId, status, step) => {
        return this.mongoDBConnector.invoke("vendorManagement", "writeStatus", invitationId, status, step)
    }

    doesInvitationExist = (invitationId) => {
        return this.mongoDBConnector.invoke("vendorManagement", "doesInvitationExist", invitationId)
    }

}
export default VendorManagement;