import ThirdPartyUtils from '../utils/IIIrd-party-utils';
class JSONConverter {

    static getTransactionDetails(jsonLd, json) {
        if (!jsonLd.category) {
            return json;
        }
        let TranDtls = {};
        TranDtls["SupTyp"] = jsonLd.category.identifier;
        TranDtls["RegRev"] = jsonLd.addOns.reverseCharge;
        TranDtls["EcmTrn"] = jsonLd.addOns.ecomPos;
        TranDtls["EcmGstin"] = jsonLd.addOns.ecomGstin;
        TranDtls["TaxSch"] = "GST"
        json["TranDtls"] = TranDtls;
        return json;
    }

    static getTaxValue = (amount, taxPercent) => amount * (ThirdPartyUtils.getCorrectNumber(taxPercent) / 100);

    static getSubTotal(quantity, unitPrice, itemListObject) {
        let taxableAmount = (ThirdPartyUtils.getCorrectNumber(quantity) * ThirdPartyUtils.getCorrectNumber(unitPrice)) + ThirdPartyUtils.getCorrectNumber(itemListObject['OthChrg']) - ThirdPartyUtils.getCorrectNumber(itemListObject['Discount']);
        let taxAmount = 0;
        const taxArray = ["SgstRt", "CgstRt", "IgstRt", "CesRt", "StateCes"];
        for (let i in taxArray) {
            taxAmount += JSONConverter.getTaxValue(taxableAmount, itemListObject[taxArray[i]]);
        }
        let cessNonAdVal = ThirdPartyUtils.getCorrectNumber(itemListObject["CesNonAdval"]);
        return taxableAmount + taxAmount + cessNonAdVal;
    }

    static getDocumentDetails(documentType, documentNumber, date, originalInvoiceNumber, json) {
        let DocDtls = {};
        DocDtls["Typ"] = documentType;
        DocDtls["No"] = ThirdPartyUtils.getDataAfterSplit(documentNumber);
        DocDtls["Dt"] = date;
        DocDtls["OrgInvNo"] = originalInvoiceNumber;
        json["DocDtls"] = DocDtls;
        return json;
    }

    static getDocumentRefDetails(jsonLd, json) {
        let RefDtls = {};
        json["RefDtls"] = RefDtls;
        return json;
    }

    static getPersonDetails(personData) {
        if (!personData || !personData.name) {
            return undefined;
        }
        let personDetails = {};
        personDetails["Gstin"] = personData.taxId;
        personDetails["LglNm"] = personData.name;
        personDetails["Nm"] = personData.name;
        personDetails["LglNm"] = personData.name;
        // personDetails["Bno"] = personData.
        // personDetails["Bnm"] = personData.
        // personDetails["Flno"] = personData.
        // personDetails["Stcd"] = personData.
        if (personData.telephone) {
            personDetails["Ph"] = personData.telephone;
        }
        personDetails["Em"] = personData.email;
        if (!personData.address) {
            return personDetails;
        }
        personDetails["Loc"] = personData.address.addressLocality;
        personDetails["Pos"] = personData.address.addressRegion ? personData.address.addressRegion : '33';
        personDetails["Addr1"] = personData.address.addressLocality;
        personDetails["Bnm"] = personData.address.streetAddress;
        personDetails["Stcd"] = personData.address.addressRegion ? personData.address.addressRegion : '33';
        personDetails["State"] = personData.address.state;
        personDetails["Pin"] = personData.address.postalCode;
        return personDetails;
    }

    static getBuyerAndSeller(jsonLd, json) {
        json["SellerDtls"] = JSONConverter.getPersonDetails(jsonLd.provider);
        json["BuyerDtls"] = JSONConverter.getPersonDetails(jsonLd.customer);
        json["DispDtls"] = JSONConverter.getPersonDetails(jsonLd.consignor);
        json["ShipDtls"] = JSONConverter.getPersonDetails(jsonLd.consignee);
        return json;
    }

    static getTaxAndOtherCharges(itemListObject, addOnArray) {
        const taxKeys = {
            "SGST": "SgstRt",
            "CGST": "CgstRt",
            "IGST": "IgstRt",
            "IGST": "GstRt",
            "Discount Amount": "Discount",
            "Cess Value": "CesRt",
            "State Cess Amt": "StateCes",
            "Other Charges": "OthChrg",
            "Cess Non AdVal": "CesNonAdval"
        };
        for (let i in addOnArray) {
            itemListObject[taxKeys[addOnArray[i].name]] = ThirdPartyUtils.getCorrectNumber(addOnArray[i].price);
        }
        return itemListObject;
    }

    static getItemDetails(orderedItems, json) {
        let itemList = [];
        for (let index in orderedItems) {
            let itemListObject = {};
            itemListObject["Slno"] = orderedItems[index].orderedItem.slno;
            itemListObject["PrdNm"] = orderedItems[index].orderedItem.name;
            itemListObject["HsnCd"] = orderedItems[index].orderedItem.hsnCode;
            itemListObject["Qty"] = orderedItems[index].orderQuantity;
            itemListObject["Unit"] = orderedItems[index].orderedItem.unit;
            itemListObject["UnitPrice"] = ThirdPartyUtils.getCorrectNumber(orderedItems[index].orderedItem.offers.price);
            itemListObject["TotAmt"] = (ThirdPartyUtils.getCorrectNumber(orderedItems[index].orderQuantity) * ThirdPartyUtils.getCorrectNumber(orderedItems[index].orderedItem.offers.price));
            itemListObject = JSONConverter.getTaxAndOtherCharges(itemListObject, orderedItems[index].orderedItem.offers.addOn);
            itemListObject["AssAmt"] = ThirdPartyUtils.getCorrectNumber(itemListObject["TotAmt"]) - ThirdPartyUtils.getCorrectNumber(itemListObject["Discount"]);
            itemListObject["TotItemVal"] = JSONConverter.getSubTotal(orderedItems[index].orderQuantity, orderedItems[index].orderedItem.offers.price, itemListObject);
            itemListObject["IsServc"] = orderedItems[index].orderedItem.isService;
            itemList.push(itemListObject);
        }
        json["ItemList"] = itemList;
        return json;
    }

    static calculateTaxes(unitPrice, qty, taxArray, taxCharges) {
        for (let i in taxArray) {
            let taxName = taxArray[i].name;
            if (!taxCharges.taxName) {
                taxCharges[taxName] = 0;
            }
            taxCharges[taxName] += unitPrice * qty * Number(taxArray[i].price) / 100;
        }
        return taxCharges;
    }

    static calculateTotal(taxCharges, unitPrice, qty) {
        let totalCharges = 0;
        let price = unitPrice * qty;
        Object.values(taxCharges).forEach((value) => {
            totalCharges += value;
        })
        return price + totalCharges;
    }

    static getValueDetails(orderedItems, json) {
        let valDtls = {};
        let assVal = 0;
        let taxCharges = {};
        let total = 0;
        let otherCharges = [];
        let cessNonAdVal = 0;
        for (let index in orderedItems) {
            let qty = Number(orderedItems[index].orderQuantity);
            let taxArray = [];
            for (let i = 0; i < orderedItems[index].orderedItem.offers.addOn.length; i++) {
                if (orderedItems[index].orderedItem.offers.addOn[i].name != 'Other Charges') {
                    taxArray.push(orderedItems[index].orderedItem.offers.addOn[i]);
                }
                else if (orderedItems[index].orderedItem.offers.addOn[i].name === 'Cess Non AdVal') {
                    cessNonAdVal += ThirdPartyUtils.getCorrectNumber(orderedItems[index].orderedItem.offers.addOn[i].price);
                }
                else {
                    otherCharges.push(ThirdPartyUtils.getCorrectNumber(orderedItems[index].orderedItem.offers.addOn[i].price));
                }
            }
            let unitPrice = Number(orderedItems[index].orderedItem.offers.price);
            taxCharges = JSONConverter.calculateTaxes(unitPrice, qty, taxArray, taxCharges);
            assVal += (qty * unitPrice);
            total += JSONConverter.calculateTotal(taxCharges, unitPrice, qty);
        }
        let taxKeys = ["SgstVal:SGST", "CgstVal:CGST", "IgstVal:IGST", "CesVal:Cess Value", "StCesVal:State Cess Amt"];
        for (let i = 0; i < taxKeys.length; i++) {
            let jsonLdKey = taxKeys[i].split(':')[1];
            let jsonKey = taxKeys[i].split(':')[0];
            valDtls[jsonKey] = taxCharges[jsonLdKey] ? taxCharges[jsonLdKey] : 0;
        }
        valDtls["OthChrg"] = ThirdPartyUtils.calculateOtherCharges(otherCharges);
        valDtls["TotInvVal"] = total ? total : 0;
        valDtls["CesNonAdVal"] = cessNonAdVal.toString();
        valDtls["AssVal"] = assVal;
        json["ValDtls"] = valDtls;
        return json;
    }

    static convertJsonLdToJson(addOns, json, jsonLdToJsonKeys) {
        for (let i in jsonLdToJsonKeys) {
            let jsonLdKey = jsonLdToJsonKeys[i].split(":")[0];
            let jsonKey = jsonLdToJsonKeys[i].split(":")[1];
            if (addOns[jsonLdKey]) {
                json[jsonKey] = addOns[jsonLdKey];
            }
        }
        return json;
    }

    static getRefDetails(addOns, json) {
        if (!addOns) {
            return json;
        }
        let jsonLdToJsonKeys = ["projectReference:ProjRef", "contractReference:ContrRef", "externalReference:ExtRef", "tenderOfLotReference:TendRef", "precedingInvoiceNumber:PrecInvNo", "receiptAdviceReference:RecAdvRef", "venderPOReferenceNumber:PORef", "venderPOReferenceDate:InvStDt", "venderPOReferenceDate:InvEndDt"];
        let RefDtls = {};
        json["RefDtls"] = JSONConverter.convertJsonLdToJson(addOns, RefDtls, jsonLdToJsonKeys);
        return json;
    }

    static getPayDetails(addOns, json) {
        if (!addOns) {
            return json;
        }
        let jsonLdToJsonKeys = ["payerFinancialAccount:AcctDet", "creditTransfer:CrTrn", "financialInstitutionBranch:FinInsBr", "directDebit:DirDr", "paymentInstruction:PayInstr", "creditDays:CrDay"];
        let PayDtls = {};
        json["PayDtls"] = JSONConverter.convertJsonLdToJson(addOns, PayDtls, jsonLdToJsonKeys);
        return json;
    }

    static removeEmptyFields = (key, json) => {
        if (json[key] && Object.entries(json[key]).length === 0 && json[key].constructor === Object) {
            delete json[key];
        }
        return json
    }

    static convertToEinvoiceSchema(jsonLdString) {
        let jsonLd = JSON.parse(jsonLdString);
        let json = {
            "TaxSch": "GST",
            "Version": "1.01",
            "Irn": jsonLd.addOns && jsonLd.addOns.invoiceInfo && jsonLd.addOns.invoiceInfo.irn ? jsonLd.addOns.invoiceInfo.irn : undefined,

        };
        json = JSONConverter.getTransactionDetails(jsonLd, json);
        json = JSONConverter.getDocumentDetails("INV", jsonLd["displayReceiptId"], jsonLd.invoiceDate, undefined, json);
        json = JSONConverter.getBuyerAndSeller(jsonLd, json);
        json = JSONConverter.getValueDetails(jsonLd.referencesOrder.orderedItem, json);
        json = JSONConverter.getItemDetails(jsonLd.referencesOrder.orderedItem, json);
        json = JSONConverter.getRefDetails(jsonLd.addOns, json);
        json = JSONConverter.getPayDetails(jsonLd.addOns, json);
        json['Status'] = jsonLd.addOns && jsonLd.addOns.invoiceInfo && jsonLd.addOns.invoiceInfo.status ? jsonLd.addOns.invoiceInfo.status : "NEW";
        json = JSONConverter.removeEmptyFields("RefDtls", json);
        json = JSONConverter.removeEmptyFields("PayDtls", json);
        console.log("Converted JSON", json)
        return JSON.stringify(json);
    }
}

export default JSONConverter;
