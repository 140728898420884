import React, { PureComponent } from 'react';
import { Form, Input, Card, Divider, Button, Select, InputNumber, message, Modal } from 'antd';
import PhoneInput from 'react-phone-input-2';
import ReactFlagsSelect from 'react-flags-select';
// import { CountryDropdown } from 'react-country-region-selector';
import * as Utils from '../../util/utils';
import * as ConfigObj from '../../param-network/config1.json';
import * as IpfsConfig from '../../param-network/ipfs-config.json';

import './index.less';
import 'react-phone-input-2/lib/style.css';
import 'react-flags-select/css/react-flags-select.css';
import { dbURL } from '../../constants/AppConstants';
import Analytics from '../../analytics';


const { Option } = Select;
const { confirm } = Modal;

class AddProfile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            country: "IN"
        }
        this.configObj = ConfigObj.privateNodes;
        this.ipfsConfig = IpfsConfig.ipfsNodes;
        this.setConfigObj = ConfigObj.default;
        Analytics.getInstance().trackPageview();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let country = this.state.country;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let selectedIndex = values.server;
                values.country = country;
                Utils.setLocalStorage('organizationId', this.configObj[selectedIndex].publicAddress[0]);
                Utils.setLocalStorage('url', this.configObj[selectedIndex].rpcURL);
                Utils.setLocalStorage('dbURL', dbURL);
                Utils.setLocalStorage('privateUrl', this.configObj[selectedIndex].privateUrl);
                Utils.setLocalStorage('configObj', JSON.stringify(this.setConfigObj));
                Utils.setLocalStorage('versionControl', "0x22AD1FF3161D6D40EC7503cB88A4028Aa983d139");
                for (let index in this.ipfsConfig) {
                    if (this.ipfsConfig[index].isDefault) {
                        Utils.setLocalStorage('ipfsEndPoint', this.ipfsConfig[index].url)
                    }
                }
                // Utils.setLocalStorage('profile', JSON.stringify(values))
                let jsonLd = Utils.convertProfileToJsonLd(values)
                Utils.setLocalStorage('profile', JSON.stringify(jsonLd))
                this.setState({ showLoader: false })

                // let that = this;
                // if (true) {
                //     confirm({
                //         title: 'Do you Want to change GraphDB Port?',
                //         content: <Input onChange={(e) => that.setState({ graphDBURL: e.target.value })} />,
                //         onOk() {
                //             Utils.setLocalStorage("serverPort", that.state.graphDBURL);
                //             that.props.history.push('/main/landing?init') //finance landing/welcome page
                //         },
                //         onCancel() {
                //             that.props.history.push('/main/landing?init') //finance landing/welcome page
                //         },
                //     })
                // }
                // else {
                this.props.history.push('/main/landing?init') //finance landing/welcome page
                // }
            }
            else {
                message.error('Please fill all the required fields')
            }
            this.setState({ showLoader: false })
        });
    }

    handleChange = (value) => {
    }

    selectCountry = (val) => {
        this.setState({ country: val });
    }

    verifyFaxNumber = (rule, value, callback) => {
        let faxno = /^\d+$/;
        if (value && !value.match(faxno)) {
            callback("Please input a valid fax number");
        }
        else {
            callback();
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const paramId = Utils.getParamId();
        const accountNo = Utils.getFromLocalStorage("accountNo");

        return (
            <Card className="profile addprofile" title={<h2 style={{ margin: '0' }}>Profile</h2>}>
                <Form {...formItemLayout} style={{ width: '80%', margin: '0 auto' }} className="add-profile-form">
                    <Form.Item label="Param Id">
                        {getFieldDecorator('paramId', {
                            rules: [{
                                required: true,
                                message: 'Please input your param Id!',
                            }],
                            initialValue: paramId
                        })(<Input disabled />)
                        }
                    </Form.Item>
                    <Form.Item label="Name">
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                                message: 'Please input your name!',
                            }],
                            initialValue: ""
                        })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item label="Phone Number" className="phoneInput">
                        {getFieldDecorator('telephone', {
                            rules: [{
                                required: true,
                                message: 'Please input your phone number!'
                            }],
                            initialValue: accountNo
                        })(
                            <PhoneInput
                                placeholder="Please provide number with country code"
                                enableSearchField={true}
                                defaultCountry={'in'}
                                countryCodeEditable={true}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="E-mail">
                        {getFieldDecorator('email', {
                            rules: [{
                                type: 'email',
                                required: true,
                                message: `The input is not valid Email`,
                            }],
                            initialValue: ""
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Fax No">
                        {getFieldDecorator('faxNo', {
                            rules: [{
                                required: false
                            }],
                            initialValue: ""
                        })(<Input type="number" min={0} />)}
                    </Form.Item>
                    <Form.Item label="Taxation Id">
                        {getFieldDecorator('taxNo', {
                            rules: [{
                                required: true,
                                message: 'Please input your taxation id!'
                            }],
                            initialValue: ""
                        })(<Input />)}
                    </Form.Item>
                    <Divider dashed />

                    <Form.Item label="Organisation">
                        {getFieldDecorator('organisation', {
                            rules: [{
                                required: true,
                            }],
                        })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item label="Street Address">
                        {getFieldDecorator('streetAddress', {
                            rules: [{
                                required: false,
                            }],
                            initialValue: ""
                        })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item label="Locality">
                        {getFieldDecorator('addressLocality', {
                            rules: [{
                                required: false,
                            }]
                        })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item label="City">
                        {getFieldDecorator('city', {
                            rules: [{
                                required: false,
                            }],
                            initialValue: ""
                        })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item label="State/Province">
                        {getFieldDecorator('addressRegion', {
                            rules: [{
                                required: false,
                            }],
                            initialValue: ""
                        })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item label="Zip/Postal Code">
                        {getFieldDecorator('postalCode', {
                            rules: [{
                                required: false,
                            }],
                            initialValue: ""
                        })(<InputNumber min={0} />)
                        }
                    </Form.Item>
                    <Form.Item label="Logo URL">
                        {getFieldDecorator('logoUrl', {
                            rules: [{
                                required: false,
                            }],
                        })(<Input />)
                        }
                    </Form.Item>
                    <Form.Item label="Country" className="country">
                        {getFieldDecorator('country', {
                            rules: [{
                                required: false,
                            }],
                            initialValue: this.state.country
                        })(
                            <>
                                <ReactFlagsSelect
                                    searchable={true}
                                    onSelect={this.selectCountry}
                                    searchPlaceholder="Search for a country"
                                    className="country-select"
                                    defaultCountry={this.state.country}
                                />
                            </>
                        )
                        }
                    </Form.Item>
                    <Form.Item label="Choose server">
                        {getFieldDecorator('server', {
                            rules: [{
                                required: true,
                                message: "Please select organization Id"
                            }], initialValue: 0
                        })(
                            <Select style={{ width: '77%' }}
                                defaultValue={0}
                                placeholder="Please select organization Id"
                                onChange={this.handleChange}
                            >
                                {
                                    this.configObj.map((item, index) => (
                                        <Option
                                            value={index}
                                        >
                                            {item.publicAddress[0]}
                                        </Option>
                                    ))
                                }
                            </Select >
                        )}
                    </Form.Item>
                </Form>
                <Button type="primary" className="profile__saveBtn" onClick={this.handleSubmit}>Save</Button>
            </Card>
        )
    }
}
const AddProfileForm = Form.create({ name: 'profile' })(AddProfile)
export default AddProfileForm;