import POIncomingBig from '../assets/images/POIncomingBIG.svg';
import ContractOutgoingBig from '../assets/images/ContractOutgoingBIG.svg';
import PaymentReceivedBig from '../assets/images/PaymentRecievedBIG.svg';
import PaymentInitiatedBig from '../assets/images/PaymentInitiatedBIG.svg';
import PaymentDueBig from '../assets/images/PaymentDueBIG.svg';
import InvoiceSentBig from '../assets/images/InvoiceOutgoingBIG.svg';
import React from 'react';
import * as Config from "../config.json"
import * as Utils from '../util/utils';
import Skeleton from 'react-loading-skeleton';


const importAll = require =>
    require.keys().reduce((acc, next) => {
        acc[next.replace("./", "")] = require(next);
        return acc;
    }, {});

export const ImageFolder = importAll(
    require.context("../assets/images", false, /\.(svg)$/)
);

export const requiredDocs = [
    "PAN Card",
    "Credit Card Bills",
    "Bank Statement",
    "Driving Lisence"
];

export const givenDocs = {
    "Permit": {
        "issuedBy": "Organization",
        "issuedThrough": "Service",
        "validFor": "Duration",
        "validFrom": "DateTime",
        "validIn": "Text",
        "name": "Text",
        "number": "Text",
        "description": "Text"
    },
    "PlanActionTask": {
        "name": "Text",
        "description": "Text",
        "status": "Text",
        "startTime": "DateTime",
        "endTime": "DateTime",
        "location": "Text",
        "scheduledTime": "DateTime",
        "participant": "Organization",
        "result": "Text"
    },
    "Delivery": {
        "deliveryAddress": "PostalAddress",
        "deliveryStatus": "Text",
        "expectedArrivalFrom": "DateTime",
        "expectedArrivalUntil": "DateTime",
        "hasDeliveryMethod": "Text",
        "itemShipped": "Product",
        "originAddress": "PostalAddress",
        "partOfOrder": "Text",
        "provider": "Organization",
        "trackingNumber": "Text",
        "trackingUrl": "Text",
    },
    "Person/Organization": {
        "name": "Text",
        "telephone": "Text",
        "email": "Text",
        "identifier": "Text",
        "address": "PostalAddress"
    },
    // "Document items review": {

    // },
    "Review": {
        "reviewBody": "Text",
        "reviewRating": "Text",
        "name": "Text"
    },
    "Service": {
        "areaServed": "Text",
        "brand": "Text",
        "broker": "Organization",
        "category": "Text",
        "provider": "Organization",
        "serviceType": "Text",
        "hoursAvailable": "Text",
        "isRelatedTo": "Product",
        "offers": "Offer",
        "serviceOutput": "Text",
        "slogan": "Text",
        "termsOfService": "Text"
    },
    "Product": {
        "name": "Text",
        "desciption": "Text",
        "price": "Text",
        "color": "Text",
        "height": "Text",
        "width": "Text",
        "additionalProperty": "Text",
        "category": "Text",
        "gtin": "Text",
        "gtin12": "Text",
        "gtin13": "Text",
        "gtin14": "Text",
        "gtin8": "Text",
        "hasProductReturnPolicy": "Text",
        "model": "Text",
        "mpn": "Text",
        "productID": "Text",
        "productionDate": "DateTime",
        "purchaseDate": "DateTime",
        "releaseDate": "DateTime",
        "slogan": "Text",
        "weight": "Text",
    },
    "Ticket": {
        "name": "Text",
        "dateIssued": "DateTime",
        "issuedBy": "Organization",
        "priceCurrency": "Text",
        "ticketNumber": "Text",
        "ticketToken": "Text",
        "tickedSeat": "Seat",
        "totalPrice": "Text",
        "underName": "Organization"

    },
    "Invoice": {
        "item": "Item",
    },
    "Step PO": {
        "item": "Item",
    },
    "Step Invoice": {
        "item": "Item",
    },
    "Others": {

    },
    "PostalAddress": {
        "organization": "",
        "streetAddress": "",
        "city": "",
        "state": "",
        "zipCode": "",
        "country": "",
    },
    "Offer": {
        "price": "",
        "priceCurrency": ""
    },
    "Item": {
        "name": "",
        "quantity": "",
        "unitPrice": "",
        "itemTax": ""
    },
    "Seat": {
        "seatNumber": "Text",
        "seatRow": "Text",
        "seatType": "Text"
    }
};

export const givenDocsLabel = {
    issuedBy: 'Issued By',
    issuedThrough: 'Issued Through',
    validFor: 'Valid For',
    validFrom: 'Valid From',
    validIn: 'Valid In',
    name: 'Name',
    number: 'Number',
    description: 'Description',
    status: 'Status',
    startTime: 'Start Time',
    endTime: 'End Time',
    location: 'Location',
    scheduledTime: 'Scheduled Time',
    participant: 'Participant',
    result: 'Result',
    deliveryAddress: 'Delivery Address',
    deliveryStatus: 'Delivery Status',
    expectedArrivalFrom: 'Expected Arrival From',
    expectedArrivalUntil: 'Expected Arrival Until',
    hasDeliveryMethod: 'Has Delivery Method',
    itemShipped: 'Item Shipped',
    originAddress: 'Origin Address',
    partOfOrder: 'Part Of Order',
    provider: 'Provider',
    trackingNumber: 'Tracking Number',
    trackingUrl: 'Tracking Url',
    telephone: 'Telephone',
    email: 'Email',
    identifier: 'Identifier',
    address: 'Address',
    reviewRating: 'Review Rating',
    reviewBody: 'Review Body',
    areaServed: 'Area Served',
    brand: 'Brand',
    broker: 'Broker',
    category: 'Category',
    serviceType: 'Service Type',
    hoursAvailable: 'Hours Available',
    isRelatedTo: 'Is Related To',
    offers: 'Offers',
    serviceOutput: 'Service Output',
    slogan: 'Slogan',
    termsOfService: 'Terms Of Service',
    desciption: 'Desciption',
    price: 'Price',
    color: 'Color',
    height: 'Height',
    width: 'Width',
    additionalProperty: 'Additional Property',
    gtin: 'Gtin',
    gtin12: 'Gtin12',
    gtin13: 'Gtin13',
    gtin14: 'Gtin14',
    gtin8: 'Gtin8',
    hasProductReturnPolicy: 'Has Product Return Policy',
    model: 'Model',
    mpn: 'Mpn',
    productID: 'Product I D',
    productionDate: 'Production Date',
    purchaseDate: 'Purchase Date',
    releaseDate: 'Release Date',
    weight: 'Weight',
    dateIssued: 'Date Issued',
    priceCurrency: 'Price Currency',
    ticketNumber: 'Ticket Number',
    ticketToken: 'Ticket Token',
    tickedSeat: 'Ticked Seat',
    totalPrice: 'Total Price',
    underName: 'Under Name',
    item: 'Item',
    organization: "Organization",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    country: "Country",
}

export const dataTypes = [
    "Number",
    "Text",
    "Telephone",
    "Email",
    "Textarea"
];

export const partiesInvolved = [
    "Buyer",
    "Seller",
    "LSP",
    "Driver"
];

export const previewTableData = [
    {
        sno: 1,
        key: 0,
        steps: 'Assign Driver',
        docs: { document: "Contract", fields: [{ key: 0, name: "Name", dataType: "Text" }] },
        party: 'Buyer'
    },
    {
        sno: 2,
        key: 1,
        steps: 'Start Trip',
        docs: { document: "Purchase Order", fields: [{ key: 0, name: "Name", dataType: "Text" }] },
        party: 'Driver'
    },
    {
        sno: 3,
        key: 2,
        steps: 'End Trip',
        docs: { document: "PurchaseOrder", fields: [{ key: 0, name: "Name", dataType: "Text" }] },
        party: 'Driver'
    }
]

export const txnTypeText = 'Transaction Type: * Private: Between two private nodes securely * Protected: Encrypted documents are stored on the public nodes * Public: All nodes sync this data';

export const AppVersion = '0.11.0-lsp';

export const reportTabsForSales = [
    'Contract Raised',
    'Accepted PO',
    'Invoice Sent',
    'Payment Pending',
    'Payment Received',
    'Payment Due'
]
export const reportTabsForPurchases = [
    'Contract Received',
    'PO Raised',
    'Invoice Received',
    'Payment Sent',
    'Payment Confirm',
    'Payment Due'
]

export const reportCardColors = [
    "bluereconcile",
    "orangereconcile",
    "greenreconcile",
    "redreconcile",
    "bluereconcile",
    "orangereconcile"
]

export const reportsData = {
    "sales": {
        "poReceivedOrRaised": 0,
        "invoiceRaisedOrReceived": 0,
        "paymentReceivedOrReceived": 0,
        "paymentInititatedOrPending": 0,
        "paymentDue": 0,
        "invoiceDueOrPending": 0
    },
    "purchases": {
        "poReceivedOrRaised": 0,
        "invoiceRaisedOrReceived": 0,
        "paymentReceivedOrReceived": 0,
        "paymentInititatedOrPending": 0,
        "paymentDue": 0,
        "invoiceDueOrPending": 0
    },
    "counts": {
        "contractsSent": 0,
        "invoicesSent": 0,
        "paymentReceived": 0,
        "paymentDue": 0,
        "poSent": 1,
        "paymentInitiated": 0
    }
};

export const NO_ITEMS_PENDING_TASK = "No Pending Tasks.";
export const NO_ITEMS_ALL_TASK = "No Tasks.";
export const NO_ITEMS_COMPLETED_TASK = "No Completed Tasks.";

export const COMPLETED = "Completed";
export const PENDING = "Pending";
export const ALL = "All"

export const Images = {
    contractIncoming: ImageFolder['contractIncoming.svg'],
    contractOutgoing: ImageFolder['contractOutgoing.svg'],
    poIncoming: ImageFolder['purchaseOrderIncoming.svg'],
    poOutgoing: ImageFolder['purchaseOrderOutgoing.svg'],
    invoiceIncoming: ImageFolder['invoiceIncoming.svg'],
    invoiceOutgoing: ImageFolder['invoiceOutgoing.svg'],
    sales: ImageFolder['salesSvg.svg'],
    purchases: ImageFolder['purchasesSvg.svg'],
    reconcilliation: ImageFolder['reconciliationSvg.svg'],
    item: ImageFolder['itemSvg.svg'],
    contact: ImageFolder['contactSvg.svg'],
    graph: ImageFolder['graphSvg.svg'],
    reports: ImageFolder['reportsSvg.svg'],
    invitation: ImageFolder['invitationSvg.svg'],
    workflow: ImageFolder['workflow.svg'],
    workflowBuilder: ImageFolder['workflowBuilder.svg'],
    noTemplates: ImageFolder['noTemplates.svg'],
    noInvitations: ImageFolder['noInvitations.svg'],
    noWorkflows: ImageFolder['noWorkflows.svg'],
    noAccess: ImageFolder['noAccess.svg']
}

export const reconcileIconsForSales = [
    ContractOutgoingBig,
    POIncomingBig,
    InvoiceSentBig,
    PaymentInitiatedBig,
    PaymentReceivedBig,
    PaymentDueBig
]
export const reconcileIconsForPurchases = [
    ContractOutgoingBig,
    POIncomingBig,
    InvoiceSentBig,
    PaymentInitiatedBig,
    PaymentReceivedBig,
    PaymentDueBig
]

export const stepFormFields = [
    {
        label: "Divider",
        id: "divider",
        required: false,
        disabled: false,
        dataType: {
            input: "Divider"
        }
    },
    {
        label: "Function Type",
        id: "argFun",
        required: false,
        disabled: false,
        dataType: {
            input: "Select",
            options: [{
                label: "Count",
                key: "count"
            },
            {
                label: "Cumulative Count",
                key: "cumulativeCount"
            },
            {
                label: "Cumulative Total",
                key: "cumulativeTotal"
            },
            {
                label: "Total",
                key: "total"
            }
            ]
        }
    },
    {
        label: "Step",
        id: "stepId",
        required: false,
        disabled: false,
        dataType: {
            input: "Select"
        }
    }, {
        label: "Select X",
        id: "labelX",
        required: false,
        disabled: false,
        dataType: {
            input: "Select"
        }
    }, {
        label: "Field X",
        id: "fieldX",
        required: false,
        disabled: false,
        dataType: {
            input: "Input"
        }
    },
    {
        label: "Select Y",
        id: "labelY",
        required: false,
        disabled: false,
        dataType: {
            input: "Select"
        }
    }, {
        label: "Field Y",
        id: "fieldY",
        required: false,
        disabled: false,
        dataType: {
            input: "Input"
        }
    }, {
        label: "Type",
        id: "type",
        required: false,
        disabled: false,
        dataType: {
            input: "Select",
            options: [{
                label: "Template Consensus",
                key: "templateConsensus"
            },
            {
                label: "Step Consensus",
                key: "stepConsensus"
            },
            {
                label: "Step Details",
                key: "stepDetails"
            }]
        }
    }
]

export const alignedInfoText = ["", "Not aligned with PO item value", "Not aligned with PO quantity", "Not aligned with PO Item Quantity and Item Price."];
export const alignedInfoDialogue = {
    grn: ["",
        {
            title: "Quantity missmatched",
            info: "Are you sure, did you received more items, when compare to PO? Do you want to proceed to create GRN"
        }],
    invoice: [{
        title: "Unable to raise invoice",
        info: "Invoice Item value is greater than PO value"
    }, {
        title: "Unable to raise invoice",
        info: "Invoice quantity is greater than PO quantity"
    }, {
        title: "Unable to raise invoice",
        info: "Invoice quantity & price value is greater than PO quantity & price value"
    }],
    purchaseorder: [{
        title: "Unable to raise PO",
        info: "PO Item value is greater than PO value"
    }, {
        title: "Unable to raise PO",
        info: "PO quantity is greater than PO quantity"
    }, {
        title: "Unable to raise PO",
        info: "PO quantity & price value is greater than PO quantity & price value"
    }
    ]
}
export const VenderOnBoardingForms = ["ValidationForm", "BasicDetailsForm", "BankDetailsForm", "DocumentsForm"];

export const VenderOnBoardingTexts = {
    ValidationForm: {
        title: "Validation",
        description: "Please enter the below details to get started"
    },
    BasicDetailsForm: {
        title: "Basic Details",
        description: "Please enter the below details to get started"
    },
    BankDetailsForm: {
        title: "Bank Details",
        description: "Please enter the below details to get started"
    },
    DocumentsForm: {
        title: "Documents",
        description: "Please upload the below details to get started"
    },
}

export const treeData = [
    {
        title: 'Purchase Orders',
        key: '55',
        children: [
            {
                title: 'PO',
                key: '5',
                children: [
                    {
                        title: 'Id: 0x3259582389573889745893457873',
                        key: '6',
                    },
                    {
                        title: 'Block Number: 5567',
                        key: '7'
                    },
                    {
                        title: 'Signed By: 0x3534534534534546',
                        key: '8'
                    },
                    {
                        title: 'Date: 18/04/2020',
                        key: '9'
                    },
                ]
            },
            {
                title: 'PO',
                key: '6',
                children: [
                    {
                        title: 'Id: 0x3259582389573889745893457875',
                        key: '10',
                    },
                    {
                        title: 'Block Number: 5568',
                        key: '11'
                    },
                    {
                        title: 'Signed By: 0x3534534534534546',
                        key: '12'
                    },
                    {
                        title: 'Date: 19/04/2020',
                        key: '13'
                    }
                ]
            }
        ]
    },
    {
        title: 'Contract',
        key: '0',
        children: [
            {
                title: 'Id: 0x222343254654645654645485u4358',
                key: '1',
            },
            {
                title: 'Block Number: 3369',
                key: '2',
            },
            {
                title: 'Signed by: 0x34837283728',
                key: '3',
            },
            {
                title: 'Date: 17/04/2020',
                key: '4',
            }
        ]
    },
    {
        title: 'Invoice',
        key: '14',
        children: [
            {
                title: 'Inv',
                key: '18',
                children: [
                    {
                        title: 'Block Number: 5568',
                        key: '15'
                    },
                    {
                        title: 'Signed By: 0x3534534534534546',
                        key: '16'
                    },
                    {
                        title: 'Date: 19/04/2020',
                        key: '17'
                    }
                ]
            }
        ]
    }
]

export function templateBasicSource() {
    let data = [{
        isRequired: true,
        key: "0",
        fieldName: "name",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: true,
        key: "1",
        fieldName: "telephone",
        dataType: 'Telephone',
        value: '',
    },
    {
        isRequired: true,
        key: "2",
        fieldName: "email",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: true,
        key: "3",
        fieldName: "taxid",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: false,
        key: "4",
        fieldName: "faxnumber",
        dataType: 'Number',
        value: '',
    },
    {
        isRequired: true,
        key: "5",
        fieldName: "organisation",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: false,
        key: "6",
        fieldName: "street",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: false,
        key: "7",
        fieldName: "locality",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: false,
        key: "8",
        fieldName: "city",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: false,
        key: "9",
        fieldName: "state",
        dataType: 'Text',
        value: '',
    },
    {
        isRequired: false,
        key: "10",
        fieldName: "postalcode",
        dataType: 'Number',
        value: '',
    },
    {
        isRequired: false,
        key: "11",
        fieldName: "country",
        dataType: 'Country',
        value: '',
    }
    ]
    return data;
}

export function templateBankSource() {
    let data = [
        {
            isRequired: true,
            key: "0",
            fieldName: "Bank Name",
            dataType: 'Text',
            value: '',
        },
        {
            isRequired: true,
            key: "1",
            fieldName: "Account No",
            dataType: 'Text',
            value: '',
        }, {
            isRequired: true,
            key: "2",
            fieldName: "IFSC Code",
            dataType: 'Text',
            value: '',
        },
        {
            isRequired: true,
            key: "3",
            fieldName: "Branch",
            dataType: 'Text',
            value: '',
        },
        {
            isRequired: true,
            key: "4",
            fieldName: "Bank Address",
            dataType: 'Text',
            value: '',
        }
    ]
    return data;
}

export const shareCatalogueText = {
    protectedEnabled: "Share Catalogue with Customer",
    protectedDisabled: "Protected mode is not enabled for selected customer, so can't share catalogue"
}

export const priceLessText = {
    priceLessEnabled: "The total amount of the document can be 0",
    priceLessDisabled: "The total amount of the document cannot be 0"
}

export const itemsColumn = [
    {
        dataIndex: 'avatar',
        key: 'avatar',
        width: '10%',
        render: (text, record) => (
            text !== null
                ? text : <Skeleton circle={true} height={40} width={40} />
        )
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '18%',
        render: (text, record) => (
            text !== null
                ? text : <Skeleton />)
    },
    {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
        render: (text, record) => (
            text !== null
                ?
                <span>{text || '-'}</span>
                :
                <Skeleton />
        )
    },
    {
        title: 'Available',
        dataIndex: 'available',
        key: 'available',
        render: (text, record) => (
            text !== null
                ?
                <span>{Utils.numberWithCommas(text)}</span>
                :
                <Skeleton />
        ),
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        render: (text) => (text !== null
            ? text : <Skeleton />)
    },
    {
        title: 'Sub Category',
        dataIndex: 'subCategory',
        key: 'subCategory',
        render: (text) => (text !== null
            ? text : <Skeleton />)
    },
]
export const logisticsColumn = [
    {
        dataIndex: 'avatar',
        key: 'avatar',
        width: '10%',
        render: (text, record) => (
            text !== null
                ? text : <Skeleton circle={true} height={40} width={40} />
        )
    },
    {
        title: 'From - To',
        dataIndex: 'name',
        key: 'name',
        width: '18%',
        render: (text, record) => (
            text !== null
                ? text : <Skeleton />)
    },
    {
        title: 'Truck Name',
        dataIndex: 'truckName',
        key: 'truckName',
        render: (text, record) => (
            text !== null
                ?
                <span>{text || '-'}</span>
                :
                <Skeleton />
        )
    },
    {
        title: 'Truck Type',
        dataIndex: 'truckType',
        key: 'truckType',
        render: (text, record) => (
            text !== null
                ?
                <span>{text || '-'}</span>
                :
                <Skeleton />
        ),
    },
    {
        title: 'Route ID',
        dataIndex: 'internalId',
        key: 'internalId',
        render: (text, record) => (
            text !== null
                ?
                <span>{text || '-'}</span>
                :
                <Skeleton />
        ),
    }
]
export const inventoryHistoryColumns = [
    {
        title: 'Date',
        dataIndex: 'date',
        render: (text) => (
            text !== null
                ?
                text
                :
                <Skeleton />
        )
    },
    {
        title: 'Event',
        dataIndex: 'event',
        render: (text) => (
            text !== null
                ?
                text
                :
                <Skeleton />
        )
    },
    {
        title: 'Adjusted By',
        dataIndex: 'adjustedBy',
        render: (text) => (
            text !== null
                ?
                text
                :
                <Skeleton />
        )
    },
    {
        title: 'Adjustment',
        dataIndex: 'adjustment',
        align: 'right',
        render: (text) => (
            text !== null
                ?
                text
                :
                <Skeleton />
        )
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'right',
        render: (text) => (
            text !== null
                ?
                text
                :
                <Skeleton />
        )
    },
    {
        title: 'Lot #',
        dataIndex: 'lotNo',
        editable: true,
        render: (text) => (
            text !== null
                ?
                text
                :
                <Skeleton />
        )
    },
    {
        title: 'Pickaway Location',
        dataIndex: 'pickAwayLocation',
        className: 'truncateId xs_hide',
        editable: true,
        render: (text) => (
            text !== null
                ?
                text
                :
                <Skeleton />
        )
    },
];

export const inventoryHistory = [
    {
        date: '29/04/2020',
        event: <a>PO Created (#PO_001)</a>,
        adjustedBy: "PO",
        adjustment: '-1',
        lotNo: '-',
        pickAwayLocation: '-',
        quantity: '50'
    },
    {
        date: '30/04/2020',
        event: <a>PO Created (#PO_002)</a>,
        adjustedBy: "PO",
        adjustment: '-1',
        lotNo: '-',
        pickAwayLocation: '-',
        quantity: '49'
    },
    {
        date: '01/05/2020',
        event: <a>PO Created (#PO_003)</a>,
        adjustedBy: "PO",
        adjustment: '-1',
        lotNo: '-',
        pickAwayLocation: '-',
        quantity: '48'
    },
    {
        date: '01/05/2020',
        event: <a>PO Created (#PO_004)</a>,
        adjustedBy: "PO",
        adjustment: '-1',
        lotNo: '-',
        pickAwayLocation: '-',
        quantity: '47'
    }
]

export const keyStoreBaseURL = {
    Url: Config["keystore"].url//"http://localhost:5000"//"https://13.127.97.41:3001"
}

export const MappingChar = "⇔"

export const customStepDataTypes = ["Text", "Number", "Telephone", "DateTime", "Country", "File"];

export const dbURL = Config["db-services"].mongoDBUrl//"mongodb://localhost:27017";

export const serverURL = Config["db-services"].dbserverURL//"http://localhost:4000"

export const grapQLEndPonitUrl = Config["graph-ql-services"].url//"exchange.param.network"

export const httpPort = Config["graph-ql-services"].httpPort//"3000"

export const wsPort = Config["graph-ql-services"].wsPort//"3001"

export const protocol = Config["graph-ql-services"].protocol//"http"

export const websocket = Config["graph-ql-services"].websocket

export const appKey = Config["auth-server"].appKey