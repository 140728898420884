import React from 'react';
import './index.less';

class NoData extends React.Component {

    render() {
        const { title, noDataIcon, width, description } = this.props
        return (
            <div className="nodata" style={{ width: width }}>
                <img src={noDataIcon} alt="" /> <br /><br />
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        )
    }
}
export default NoData;