import React, { Component } from 'react';
import { TweenOneGroup } from 'rc-tween-one';
import { Tag, Input, Icon } from 'antd';

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            inputVisible: false,
            inputValue: '',
        };
    }

    componentDidMount() {
        if (this.props.tags) {
            this.setState({
                tags: this.props.tags
            })
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.tags) {
            this.setState({
                tags: newProps.tags
            })
        }
    }

    handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        this.setState({ tags }, () => {
            this.props.deleteColumnFromTable(tags, removedTag);
        });
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1 && inputValue.trim() !== '') {
            tags = [...tags, inputValue];
        }
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        }, () => {
            this.props.getTags(tags);
        });
    };

    saveInputRef = input => (this.input = input);

    forMap = tag => {
        const tagElem = (
            <Tag
                closable
                onClose={e => {
                    e.preventDefault();
                    this.handleClose(tag);
                }}
            >
                {tag}
            </Tag>
        );
        return (
            <span key={tag} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        );
    };

    render() {
        const { tags, inputVisible, inputValue } = this.state;
        const tagChild = tags.map(this.forMap);
        return <div>
            <TweenOneGroup
                enter={{
                    scale: 0.8,
                    opacity: 0,
                    type: 'from',
                    duration: 100,
                    onComplete: e => {
                        e.target.style = '';
                    },
                }}
                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                appear={false}
            >
                {tagChild}

                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed', display: 'inline-block' }}>
                        <Icon type="plus" /> Add
                    </Tag>
                )}
            </TweenOneGroup>
        </div>
    }
}

export default Tags;