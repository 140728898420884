import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
class ReconciliationManager {
    static getDashboardData(currency, option) {
        let paramId = Utils.getParamId();
        return ParamConnector.getInstance().getDB().reconciliation.dashboard(paramId, currency, undefined, undefined, option);
    }
    //options={seller/buyer:"address"} 
    static getReceiptsSummaryByParticipants(options, currency) {
        return ParamConnector.getInstance().getDB().reconciliation.getReceiptsSummaryByParticipants(options, currency);
    }
    static getReceiptsSummaryByParticipantsStepLevel(options, currency, step, pageNumber, nPerPage) {
        return ParamConnector.getInstance().getDB().reconciliation.getReceiptsSummaryByParticipantsStepLevel(options, currency, step, pageNumber, nPerPage);
    }


    static directInvoices(options) {
        return ParamConnector.getInstance().getDB().poReconciliation.directInvoices(options);
    }
    static poWithoutInvoices(options) {
        return ParamConnector.getInstance().getDB().poReconciliation.poWithoutInvoices(options);
    }
    static poLessThanInvoice(options) {
        return ParamConnector.getInstance().getDB().poReconciliation.poLessThanInvoice(options);
    }
    static directInvoicesGraph(options) {
        return ParamConnector.getInstance().getDB().poReconciliation.directInvoicesGraph(options);
    }
    static poWithoutInvoicesGraph(options) {
        return ParamConnector.getInstance().getDB().poReconciliation.poWithoutInvoicesGraph(options);
    }
    static poLessThanInvoiceGraph(options) {
        return ParamConnector.getInstance().getDB().poReconciliation.poLessThanInvoiceGraph(options);
    }


    static grnMissing(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.grnMissing(options);
    }
    static grnMissingGraph(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.grnMissingGraph(options);
    }
    static paymentLessThanInvoice(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.paymentLessThanInvoice(options);
    }
    static paymentLessThanInvoiceGraph(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.paymentLessThanInvoiceGraph(options);
    }
    static quantityMisMatch(options, type) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.quantityMisMatch(options, type);
    }
    static excessSupply(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.excessSupply(options);
    }
    static excessSupplyGraph(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.excessSupplyGraph(options);
    }
    static shortSupply(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.shortSupply(options);
    }
    static shortSupplyGraph(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.shortSupplyGraph(options);
    }
    static priceMisMatch(options, type) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.priceMisMatch(options, type);
    }
    static lessPrice(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.lessPrice(options);
    }
    static lessPriceGraph(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.lessPriceGraph(options);
    }
    static excessPrice(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.excessPrice(options);
    }
    static excessPriceGraph(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.excessPriceGraph(options);
    }
    static quantityAndPriceMismatch(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.quantityAndPriceMismatch(options);
    }
    static quantityAndPriceMismatchGraph(options) {
        return ParamConnector.getInstance().getDB().invoiceReconciliation.quantityAndPriceMismatchGraph(options);
    }



    static notesToBeResolved(options, type) {
        return ParamConnector.getInstance().getDB().resolveReconciliation.notesToBeResolved(options, type);
    }
    static creditNotesToBeResolved(options) {
        return ParamConnector.getInstance().getDB().resolveReconciliation.creditNotesToBeResolved(options);
    }
    static debitNotesToBeResolved(options) {
        return ParamConnector.getInstance().getDB().resolveReconciliation.debitNotesToBeResolved(options);
    }
    static creditNotesToBeResolvedGraph(options) {
        return ParamConnector.getInstance().getDB().resolveReconciliation.creditNotesToBeResolvedGraph(options);
    }
    static debitNotesToBeResolvedGraph(options) {
        return ParamConnector.getInstance().getDB().resolveReconciliation.debitNotesToBeResolvedGraph(options);
    }
}
export default ReconciliationManager;