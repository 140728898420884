import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import NetworkBridge from '.';
import TemplateCons from './template-cons';
import ECIES from '../ecies';
import NetworkUtils from './network-utils';
import * as DBUtils from '../../database/nosql/Utils/utils';
import Catalogue from './catalogue';
import VendorManager from './vendor-manager'
import Web3Utils from 'web3-utils';
import GraphQL from '../../param-libs/graph-ql';
import RestoreReceipts from '../../param-libs/graph-ql/sync/receipts';
import * as Config from '../../config.json'
import Analytics from '../../analytics/index'

class Receipt {
    static initProposal(buyerId, jsonLd, note = "", txnType = "1", isMandatory, shareCatalogue, buyerPublicKey, sellerId, fromPurchase, templateObj) {
        let receiptManager = GraphQL.getInstance().receipts
        let eventData, txnHash, receiptId, txnTypeString;
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, "", receiptManager, undefined, fromPurchase)
        })
            .then(res => {
                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = res.receiptJson
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
                return receiptManager["initProposal"](buyerId, sellerId, inputObject, note, txnTypeString, isMandatory);
            }).then(res => {
                let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then((res) => {
                txnHash = res;
                let shareCataloguePromise = Promise.resolve();
                jsonLd = JSON.parse(jsonLd);
                let orderedItem = jsonLd.referencesOrder.orderedItem, catalogueIds = [];
                for (let index in orderedItem) {
                    catalogueIds.push(orderedItem[index].orderItemNumber);
                }
                if (shareCatalogue == "1") {
                    shareCataloguePromise = Catalogue.addSubscriberForMultipleCatalogues(catalogueIds, buyerId, buyerPublicKey);
                }
                return shareCataloguePromise;
            })
        // .then(res => {
        //     return Receipt.getDocIdFromTxnHash(txnHash, receiptManager); //TO DO
        // }).then(res => {
        //     eventData = res;
        //     receiptId = Utils.getTransactionId(eventData.rId, eventData.contractAddress);
        //     return Promise.resolve();//Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType)
        // }).then(() => {
        //     if (!isMandatory) {
        //         return Receipt.changeDocumentAcceptState(receiptId, "", txnTypeString, "acceptProposal").then(() => {
        //             return eventData;
        //         })
        //     }
        //     return eventData;
        // })
    }

    static initProposalV1(buyerId, jsonLd, note = "", txnType = "1", isMandatory, shareCatalogue, buyerPublicKey, sellerId, fromPurchase, templateObj) {
        let receiptManager = GraphQL.getInstance().receipts
        let txnTypeString, rootDetails, contractAddress;
        let internalId = Utils.getDocumentInternalId(JSON.parse(jsonLd))
        let receiptId = Utils.generateByte64Id(internalId);
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, "", receiptManager, undefined, fromPurchase)
        })
            .then(res => {
                rootDetails = res.rootDetails
                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = JSON.parse(res.receiptJson)
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
                return receiptManager["initProposalV1"](receiptId, buyerId, sellerId, inputObject, note, txnTypeString, isMandatory);
            }).then(res => {
                let parseData = JSON.parse(res)
                contractAddress = parseData.signedParams.To
                let signedReceiptData = Utils.codeSignPaylaod(parseData)
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then((res) => {
                let shareCataloguePromise = Promise.resolve();
                jsonLd = JSON.parse(jsonLd);
                let orderedItem = jsonLd.referencesOrder.orderedItem, catalogueIds = [];
                for (let index in orderedItem) {
                    catalogueIds.push(orderedItem[index].orderItemNumber);
                }
                if (shareCatalogue == "1") {
                    shareCataloguePromise = Catalogue.addSubscriberForMultipleCatalogues(catalogueIds, buyerId, buyerPublicKey);
                }
                return shareCataloguePromise;
            }).then(res => {
                let receiptMetaData = {}
                receiptMetaData.seller = sellerId
                receiptMetaData.buyer = buyerId
                receiptMetaData.transactionType = txnType
                receiptId = Utils.getTransactionId(receiptId, contractAddress);
                rootDetails.rootId = receiptId
                return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails)
            }).then(() => {
                if (!isMandatory) {
                    return Receipt.changeDocumentAcceptState(receiptId, "", txnType, "acceptProposal").then(() => {
                        return receiptId;
                    })
                }
                return receiptId;
            })
    }

    static updateInitProposal(proposalId, jsonLd, note = "", txnType = "1", isMandatory, shareCatalogue, buyerPublicKey, sellerId, buyerId, fromPurchase, templateObj) {
        let receiptManager = GraphQL.getInstance().receipts
        let txnTypeString, rootDetails;
        let transactionData = Utils.getTransactionData(proposalId)
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, "", receiptManager, undefined, fromPurchase)
        })
            .then(res => {
                rootDetails = res.rootDetails
                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = res.receiptJson
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
                return receiptManager["updateInitProposal"](transactionData.id, inputObject, note, txnTypeString, isMandatory);
            }).then(res => {
                let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then((res) => {
                let shareCataloguePromise = Promise.resolve();
                jsonLd = JSON.parse(jsonLd);
                let orderedItem = jsonLd.referencesOrder.orderedItem, catalogueIds = [];
                for (let index in orderedItem) {
                    catalogueIds.push(orderedItem[index].orderItemNumber);
                }
                if (shareCatalogue == "1") {
                    shareCataloguePromise = Catalogue.addSubscriberForMultipleCatalogues(catalogueIds, buyerId, buyerPublicKey);
                }
                return shareCataloguePromise;
            }).then(res => {
                let receiptMetaData = {}
                receiptMetaData.seller = sellerId
                receiptMetaData.buyer = buyerId
                receiptMetaData.transactionType = txnType
                rootDetails.rootId = proposalId
                return Receipt.getAttachTemplate(proposalId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails)
            }).then(() => {
                if (!isMandatory) {
                    return Receipt.changeDocumentAcceptState(proposalId, "", txnType, "acceptProposal").then(() => {
                        return proposalId;
                    })
                }
                return proposalId;
            })
    }

    static createPo(proposalId, sellerId, jsonLd, note = "", txnType = "1", templateObj, grnManager, inward = false) {
        let buyerId = Utils.getParamId();
        let transactionData, contractAddress, receiptManager, eventData, options, config, receiptId;
        let grnManagerAddress;
        if (grnManager) {
            grnManagerAddress = grnManager.identifier;
        } else {
            grnManagerAddress = Utils.getParamId();
        }
        let paramID = Utils.getParamId();
        return GraphQL.getInstance().receipts.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.getConfig(buyerId, sellerId)
        }).then(res => {
            config = res;
            let reverseRoles = !inward; //When for initiating the transaction, seller and buyer roles will be swapped except for inward documents
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig(config);
            receiptManager = paramNetwork.getReceiptManager(contractAddress || config.address);
            let receiptJson = Receipt.encryptJsonLd(jsonLd, proposalId, receiptManager, undefined, reverseRoles)
            return Promise.all([receiptJson, config]);
        }).then(res => {
            if (proposalId) {
                transactionData = Utils.getTransactionData(proposalId);
                proposalId = transactionData.id;
                contractAddress = transactionData.address;
            }
            else {
                contractAddress = config.address;
                proposalId = "0x0000000000000000000000000000000000000000000000000000000000000000";
            }
            options = Utils.getNetworkOptions();
            options.privateFor = options.privateFor || res[1].privateFor;
            let receiptJson = res[0].receiptJson;
            return Utils.sendToIPFS(receiptJson);
        }).then(ipfsHash => {
            if (inward)
                return receiptManager["createInwardPOSync"](proposalId, sellerId, ipfsHash, note, txnType, grnManagerAddress, options);
            return receiptManager["createPOSync"](proposalId, sellerId, ipfsHash, note, txnType, grnManagerAddress, options);
        }).then((res) => {
            receiptId = Utils.getTransactionId(res, contractAddress);
            return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType);
        }).then(() => {
            let publicKeyOfGrnManager
            let subscriberManager = NetworkBridge.getSubscriberManager();
            Utils.getPublic(grnManagerAddress).then(res => {
                publicKeyOfGrnManager = res;
                return Receipt.getRootReceiptId(receiptId);
            }).then(rootId => {
                subscriberManager.isSubscriberExists(rootId, grnManagerAddress).then(res => {
                    if (!res) {
                        return subscriberManager.addSubscriberForGRN("", receiptId, txnType, grnManagerAddress, publicKeyOfGrnManager, sellerId, buyerId)
                    }
                }).catch(err => {
                    console.error("error in adding subscriber", err);
                })
            })
            // Utils.getPublic(grnManagerAddress).then(publicKeyOfGrnManager => {
            //     let receiptId = Utils.getTransactionId(eventData.rId, eventData.contractAddress)
            //     NetworkBridge.getSubscriberManager().addSubscriber("", receiptId, txnType, grnManagerAddress, publicKeyOfGrnManager).catch(err => {
            //         console.error("error in adding subscriber", err);
            //     })
            // })
            return receiptId;
        })
    }

    static createPoV1(proposalId, sellerId, jsonLd, note = "", txnType = "1", templateObj, grnManager, inward = false) {
        let buyerId = Utils.getParamId();
        let receiptManager = GraphQL.getInstance().receipts
        let transactionData, contractAddress, txnTypeString;
        let internalId = Utils.getDocumentInternalId(JSON.parse(jsonLd))
        let receiptId = Utils.generateByte64Id(internalId);
        let grnManagerAddress, rootDetails;
        if (grnManager) {
            grnManagerAddress = grnManager.identifier;
        } else {
            grnManagerAddress = Utils.getParamId();
        }
        let reverseRoles = !inward; //When for initiating the transaction, seller and buyer roles will be swapped except for inward documents
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, proposalId, receiptManager, undefined, reverseRoles)
        }).then(res => {
            rootDetails = res.rootDetails
            if (proposalId) {
                transactionData = Utils.getTransactionData(proposalId);
                proposalId = transactionData.id;
                contractAddress = transactionData.address;
            }
            else {
                proposalId = "0x0000000000000000000000000000000000000000000000000000000000000000";
            }
            let inputObject = {}
            if (txnType !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
            if (inward)
                return receiptManager["createInwardPOV1"](receiptId, proposalId, sellerId, inputObject, note, txnTypeString, grnManagerAddress);
            return receiptManager["createPOV1"](receiptId, proposalId, sellerId, inputObject, note, txnTypeString, grnManagerAddress);
        }).then(res => {
            let parseData = JSON.parse(res)
            contractAddress = parseData.signedParams.To
            let signedReceiptData = Utils.codeSignPaylaod(parseData)
            return GraphQL.getInstance().sendRawTxn(signedReceiptData)
        }).then(res => {
            receiptId = Utils.getTransactionId(receiptId, contractAddress);
            let receiptMetaData = {}
            receiptMetaData.seller = sellerId
            receiptMetaData.buyer = buyerId
            receiptMetaData.transactionType = txnType
            if (!rootDetails.rootId) {
                if (proposalId !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                    rootDetails.rootId = Utils.getTransactionId(proposalId, contractAddress)
                }
                else {
                    rootDetails.rootId = receiptId
                }
            }
            return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails);
        }).then(() => {
            let publicKeyOfGrnManager
            let subscriberManager = NetworkBridge.getSubscriberManager();
            Utils.getPublic(grnManagerAddress).then(res => {
                publicKeyOfGrnManager = res;
                let promise = Promise.resolve(false)
                if (proposalId !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                    promise = subscriberManager.isSubscriberExists(rootDetails.rootId, grnManagerAddress)
                }
                return promise.then(res => {
                    if (!res) {
                        return subscriberManager.addSubscriberForGRN("", receiptId, txnType, grnManagerAddress, publicKeyOfGrnManager, sellerId, buyerId, rootDetails)
                    }
                }).catch(err => {
                    console.error("error in adding subscriber", err);
                })
            })
            return receiptId;
        })

    }

    static createLogisticsPO(proposalId, sellerId, jsonLd, note = "", txnType = "1", templateObj, grnManager, inward = false) {
        let buyerId = Utils.getParamId();
        let receiptManager = GraphQL.getInstance().receipts
        let transactionData, contractAddress, eventData = {}, txnTypeString;
        let internalId = Utils.getDocumentInternalId(JSON.parse(jsonLd))
        let receiptId = Utils.generateByte64Id(internalId);
        let grnManagerAddress, rootDetails;
        if (grnManager) {
            grnManagerAddress = grnManager.identifier;
        } else {
            grnManagerAddress = Utils.getParamId();
        }
        let reverseRoles = !inward; //When for initiating the transaction, seller and buyer roles will be swapped except for inward documents
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, proposalId, receiptManager, undefined, reverseRoles)
        }).then(res => {
            rootDetails = res.rootDetails
            if (proposalId) {
                transactionData = Utils.getTransactionData(proposalId);
                proposalId = transactionData.id;
                contractAddress = transactionData.address;
            }
            else {
                proposalId = "0x0000000000000000000000000000000000000000000000000000000000000000";
            }
            let inputObject = {}
            if (txnType !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
            if (inward)
                return receiptManager["createInwardPOV1"](proposalId, sellerId, inputObject, note, txnTypeString, grnManagerAddress);
            return receiptManager["createLogisticsPO"](proposalId, sellerId, inputObject, note, txnTypeString, grnManagerAddress);
        }).then(res => {
            let parseData = JSON.parse(res)
            contractAddress = parseData.signedParams.To
            let signedReceiptData = Utils.codeSignPaylaod(parseData)
            return GraphQL.getInstance().sendRawTxn(signedReceiptData)
        }).then(res => {
            eventData.rId = receiptId;
            eventData.contractAddress = contractAddress;
            receiptId = Utils.getTransactionId(receiptId, contractAddress);
            let receiptMetaData = {}
            receiptMetaData.seller = sellerId
            receiptMetaData.buyer = buyerId
            receiptMetaData.transactionType = txnType
            if (!rootDetails.rootId) {
                if (proposalId !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                    rootDetails.rootId = Utils.getTransactionId(proposalId, contractAddress)
                }
                else {
                    rootDetails.rootId = receiptId
                }
            }
            return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails);
        }).then(() => {
            let publicKeyOfGrnManager
            let subscriberManager = NetworkBridge.getSubscriberManager();
            Utils.getPublic(grnManagerAddress).then(res => {
                publicKeyOfGrnManager = res;
                let promise = Promise.resolve(false)
                if (proposalId !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                    promise = subscriberManager.isSubscriberExists(Utils.getTransactionId(proposalId, contractAddress), grnManagerAddress)
                }
                return promise.then(res => {
                    if (!res) {
                        return subscriberManager.addSubscriberForGRN("", receiptId, txnType, grnManagerAddress, publicKeyOfGrnManager, sellerId, buyerId, rootDetails)
                    }
                }).catch(err => {
                    console.error("error in adding subscriber", err);
                })
            })
            return eventData;
        })

    }

    static updatePO(proposalId, sellerId, jsonLd, note = "", txnType = "1", templateObj, grnManager, inward) {
        let buyerId = Utils.getParamId();
        let transactionData, txnTypeString;
        let grnManagerAddress, rootDetails;
        let receiptManager = GraphQL.getInstance().receipts

        if (grnManager) {
            grnManagerAddress = grnManager.identifier;
        } else {
            grnManagerAddress = Utils.getParamId();
        }
        let reverseRoles = !inward; //When for initiating the transaction, seller and buyer roles will be swapped except for inward documents
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {

                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, proposalId, receiptManager, undefined, reverseRoles)
        }).then(res => {
            rootDetails = res.rootDetails
            transactionData = Utils.getTransactionData(proposalId);

            let inputObject = {}
            if (txnType !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
            return receiptManager["updateReceipt"]("updatePO", transactionData.id, inputObject, note, txnTypeString);
        }).then(res => {
            let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedReceiptData)
        }).then(res => {
            let receiptMetaData = {}
            receiptMetaData.seller = sellerId
            receiptMetaData.buyer = buyerId
            receiptMetaData.transactionType = txnType
            return Receipt.getAttachTemplate(proposalId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails);
        }).then(() => {
            let publicKeyOfGrnManager
            let subscriberManager = NetworkBridge.getSubscriberManager();
            Utils.getPublic(grnManagerAddress).then(res => {
                publicKeyOfGrnManager = res;
                return subscriberManager.isSubscriberExists(rootDetails.rootId, grnManagerAddress).then(res => {
                    if (!res) {
                        return subscriberManager.addSubscriberForGRN("", proposalId, txnType, grnManagerAddress, publicKeyOfGrnManager, sellerId, buyerId, rootDetails)
                    }
                }).catch(err => {
                    console.error("error in adding subscriber", err);
                })
            })
            return proposalId;
        })
    }


    static createInvoice(proposalId, buyerId, jsonLd, note = "", txnType = "1", templateObj, inward = false) {
        let sellerId = Utils.getParamId();
        let transactionData, contractAddress, txnTypeString, eventData;
        let directInvoice = false;
        let reverseRole = inward;
        let receiptManager = GraphQL.getInstance().receipts
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, proposalId, receiptManager, "", reverseRole)

        }).then(res => {
            if (proposalId) {
                transactionData = Utils.getTransactionData(proposalId);
                proposalId = transactionData.id;
                contractAddress = transactionData.address;
            }
            else {
                proposalId = "0x0000000000000000000000000000000000000000000000000000000000000000";
                directInvoice = true;
            }

            let inputObject = {}
            if (txnType !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            txnTypeString = Utils.getTxnTypeForGraphQL(txnType)

            if (directInvoice && !inward) {
                return receiptManager["createInvoice"](buyerId, inputObject, note, txnTypeString); //buyer -> seller ?
            } else if (directInvoice && inward) {
                return receiptManager["createInwardInvoice"](buyerId, inputObject, note, txnTypeString);
            } else if (inward) {
                return receiptManager["sendInwardInvoice"](proposalId, inputObject, note, txnTypeString);
            } else
                return receiptManager["sendInvoice"](proposalId, inputObject, note, txnTypeString);
        }).then(res => {
            let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedReceiptData)
        })
        // .then((txnHash) => {
        //     return Receipt.getDocIdFromTxnHash(txnHash, receiptManager);
        // }).then(res => {
        //     eventData = res;
        //     let receiptId = Utils.getTransactionId(eventData[3], eventData[0]);
        //     return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType)
        // }).then(() => {
        //     return eventData;
        // })
    }

    static createInvoiceV1(proposalId, buyerId, jsonLd, note = "", txnType = "1", templateObj, inward = false) {
        let sellerId = Utils.getParamId();
        let transactionData, contractAddress, txnTypeString, eventData = {}, rootDetails;
        let directInvoice = false;
        let reverseRole = inward;
        let internalId = Utils.getDocumentInternalId(JSON.parse(jsonLd))
        let receiptId = Utils.generateByte64Id(internalId);
        let receiptManager = GraphQL.getInstance().receipts

        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        // }).then(() => {
        //     //writing data to local mongodb
        //     //Initial DB updation
        //     let tempJson = JSON.parse(jsonLd)
        //     tempJson["status"] = "pending"
        //     tempJson = JSON.stringify(tempJson)
        //     let receiptDataObject = {
        //         receiptId: receiptId,
        //         jsonLd: tempJson,
        //         status: "pending",
        //         parentDocId: proposalId,
        //         step: "2",
        //         receiptNote: ""
        //     }
        //     let promise = ParamConnector.getInstance().getDB().receipts.addReceipts([receiptDataObject])
        //     return Promise.resolve(promise)
        //     // .then(() => {
        //     //     RestoreReceipts._emitOnReceipt(receiptId, proposalId, templateObj.step);
        //     // })
        }).then((res) => {
            return Receipt.encryptJsonLd(jsonLd, proposalId, receiptManager, "", reverseRole)

        })
            .then(res => {
                rootDetails = res.rootDetails
                if (proposalId) {
                    transactionData = Utils.getTransactionData(proposalId);
                    proposalId = transactionData.id;
                    contractAddress = transactionData.address;
                }
                else {
                    proposalId = "0x0000000000000000000000000000000000000000000000000000000000000000";
                    directInvoice = true;
                }

                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = res.receiptJson
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)

                if (directInvoice && !inward) {
                    return receiptManager["createInvoiceV1"](receiptId, buyerId, inputObject, note, txnTypeString); //buyer -> seller ?
                } else if (directInvoice && inward) {
                    return receiptManager["createInwardInvoiceV1"](receiptId, buyerId, inputObject, note, txnTypeString);
                } else if (inward) {
                    return receiptManager["sendInwardInvoiceV1"](receiptId, proposalId, inputObject, note, txnTypeString);
                } else
                    return receiptManager["sendInvoiceV1"](receiptId, proposalId, inputObject, note, txnTypeString);
            }).then(res => {
                let parseData = JSON.parse(res)
                contractAddress = parseData.signedParams.To
                let signedReceiptData = Utils.codeSignPaylaod(parseData)
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then(res => {
                receiptId = Utils.getTransactionId(receiptId, contractAddress);
                let receiptMetaData = {}
                receiptMetaData.seller = sellerId
                receiptMetaData.buyer = buyerId
                receiptMetaData.transactionType = txnType
                if (!rootDetails.rootId) {
                    if (proposalId !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                        rootDetails.rootId = Utils.getTransactionId(proposalId, contractAddress)
                    }
                    else {
                        rootDetails.rootId = receiptId
                    }
                }
                return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails)
            }).then(() => {
                return receiptId;
            })
    }

    static updateInvoice(proposalId, buyerId, jsonLd, note = "", txnType = "1", templateObj, inward = false) {
        let sellerId = Utils.getParamId();
        let transactionData, contractAddress, txnTypeString, rootDetails;
        let reverseRole = inward;
        let receiptManager = GraphQL.getInstance().receipts

        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, proposalId, receiptManager, "", reverseRole)

        })
            .then(res => {
                rootDetails = res.rootDetails
                transactionData = Utils.getTransactionData(proposalId);

                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = res.receiptJson
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
                return receiptManager["updateReceipt"]("updateInvoice", transactionData.id, inputObject, note, txnTypeString);
            }).then(res => {
                let parseData = JSON.parse(res)
                contractAddress = parseData.signedParams.To
                let signedReceiptData = Utils.codeSignPaylaod(parseData)
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then(res => {
                let receiptMetaData = {}
                receiptMetaData.seller = sellerId
                receiptMetaData.buyer = buyerId
                receiptMetaData.transactionType = txnType
                return Receipt.getAttachTemplate(proposalId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails)
            }).then(() => {
                return proposalId;
            })
    }

    static changeDocumentAcceptState(receiptId, note = "", txnType = "2", functionName) {
        let transactionData = Utils.getTransactionData(receiptId);
        let receiptManager = GraphQL.getInstance().receipts
        let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
        return receiptManager[functionName](transactionData.id, note, txnTypeString).then(res => {
            let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedReceiptData)
        })
    }

    static makePayment(receiptId, jsonLd, note = "", txnType = "1", templateObj = {}, inward = false) {
        let extendedKnowledge = undefined; //for future purpose
        const reverseRoles = !inward;
        let transactionData = Utils.getTransactionData(receiptId), txnTypeString;
        let receiptManager = GraphQL.getInstance().receipts
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, receiptId, receiptManager, extendedKnowledge, reverseRoles)
        })
            .then(res => {
                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = res.receiptJson
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)

                if (inward)
                    return receiptManager["makeInwardPayment"](transactionData.id, inputObject, note, txnTypeString);
                return receiptManager["makePayment"](transactionData.id, inputObject, note, txnTypeString);
            }).then(res => {
                let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            })
        // .then((txnHash) => {
        //     return Receipt.getDocIdFromTxnHash(txnHash, receiptManager);
        // }).then(res => {
        //     eventData = res;
        //     let receiptId = Utils.getTransactionId(eventData[3], eventData[0]);
        //     return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType)
        // }).then(() => {
        //     return eventData;
        // })
    }

    static makePaymentV1(receiptId, jsonLd, note = "", txnType = "1", templateObj = {}, inward = false, sellerId, buyerId) {
        let extendedKnowledge = undefined; //for future purpose
        const reverseRoles = !inward;
        let transactionData = Utils.getTransactionData(receiptId), eventData = {}, txnTypeString, rootDetails, contractAddress;
        let receiptManager = GraphQL.getInstance().receipts
        let internalId = Utils.getDocumentInternalId(JSON.parse(jsonLd))
        let docId = Utils.generateByte64Id(internalId);

        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, receiptId, receiptManager, extendedKnowledge, reverseRoles)
        })
            .then(res => {
                rootDetails = res.rootDetails
                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = res.receiptJson
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)

                if (inward)
                    return receiptManager["makeInwardPaymentV1"](docId, transactionData.id, inputObject, note, txnTypeString);
                return receiptManager["makePaymentV1"](docId, transactionData.id, inputObject, note, txnTypeString);
            }).then(res => {
                let parseData = JSON.parse(res)
                contractAddress = parseData.signedParams.To
                let signedReceiptData = Utils.codeSignPaylaod(parseData)
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then(res => {
                docId = Utils.getTransactionId(docId, contractAddress);
                let receiptMetaData = {}
                receiptMetaData.seller = sellerId
                receiptMetaData.buyer = buyerId
                receiptMetaData.transactionType = txnType
                return Receipt.getAttachTemplate(docId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails)
            }).then(() => {
                return docId;
            })
    }

    static updatePayment(receiptId, jsonLd, note = "", txnType = "1", templateObj = {}, inward = false, sellerId, buyerId) {
        let extendedKnowledge = undefined; //for future purpose
        const reverseRoles = !inward;
        let transactionData = Utils.getTransactionData(receiptId), txnTypeString, rootDetails;
        let receiptManager = GraphQL.getInstance().receipts
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return Receipt.encryptJsonLd(jsonLd, receiptId, receiptManager, extendedKnowledge, reverseRoles)
        })
            .then(res => {
                rootDetails = res.rootDetails
                let inputObject = {}
                if (txnType !== "2") {
                    inputObject.receipt = res.receiptJson
                }
                else {
                    inputObject.data = res.receiptJson
                }
                txnTypeString = Utils.getTxnTypeForGraphQL(txnType)

                return receiptManager["updateReceipt"]("updatePayment", transactionData.id, inputObject, note, txnTypeString);
            }).then(res => {
                let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then(res => {
                let receiptMetaData = {}
                receiptMetaData.seller = sellerId
                receiptMetaData.buyer = buyerId
                receiptMetaData.transactionType = txnType
                return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType, receiptMetaData, rootDetails)
            }).then(() => {
                return receiptId;
            })
    }

    static createReceipt(receiptId, note = "", txnType = "1", templateObj, inward = false) {
        let extendedKnowledge = undefined; //for future purpose
        let transactionData = Utils.getTransactionData(receiptId);
        let receiptManager = GraphQL.getInstance().receipts
        let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)

        let promise
        if (inward)
            promise = receiptManager["createInwardReceipt"](transactionData.id, note, txnTypeString);
        promise = receiptManager["createReceipt"](transactionData.id, note, txnTypeString);
        let paramID = Utils.getParamId();
        return receiptManager.getMissingNonce(paramID).then(res => {
            if (!res) {
                return;
            }
            let arr = res.NonceJSON;
            let promiseArray = [];
            for (let index in arr) {
                let signedData = Utils.codeSignPaylaod(JSON.parse(arr[index]));
                let nonce = JSON.parse(arr[index]).signedParams.Nonce;
                Analytics.getInstance().trackEvent(Config['analytics'].eeCategory, Config['analytics'].codeSign, Config['analytics'].sendRawTxn, nonce)
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
                promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return promise
        })
            .then(res => {
                let signedReceiptData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedReceiptData)
            }).then(result => {
                return Receipt.getAttachTemplate(receiptId, templateObj.templateCons, templateObj.step, templateObj.txnType);
            })
    }


    static getDocIdFromTxnHash(txnHash, receiptManager, event) {
        return NetworkUtils.getTransactionInfo(receiptManager.connection, txnHash).then(data => {
            if (event) {
                return receiptManager.parseNetworkLog(event, data)
            }
            return receiptManager.parseNetworkLog('onStatusUpdateV1', data)
        })

    }

    static attachTemplateToCreateDocument(txnHash, buyerId, sellerId, templateObj) {
        //temporary
        if (!templateObj) {
            return txnHash;
        }
        let { templateCons, step, txnType } = templateObj;
        return Receipt.getConfig(buyerId, sellerId).then(config => {
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig(config);
            let receiptManager = paramNetwork.getReceiptManager(config.address);
            return Receipt.getDocIdFromTxnHash(receiptManager, txnHash);
        }).then(docId => {
            let receiptId = docId["3"];
            let contractAddress = docId["0"];
            receiptId = receiptId + "-" + contractAddress;
            return Receipt.getAttachTemplate(receiptId, templateCons, step, txnType)
        });
    }

    static getUserId(options, userId) {
        if (!userId) {
            userId = Utils.getParamId();
        }
        if (options && options.type && options.type.includes("anx")) {
            userId += "_" + options.type;
        }
        return userId;
    }
    static addReceipt(receiptId, jsonLd, options) {
        return Receipt.getReceiptsGraphDB(options).addReceipt(receiptId, jsonLd, undefined, Receipt.getUserId(options));
    }
    static updateReceipt(receiptId, jsonLd, options) {
        return Receipt.getReceiptsGraphDB(options).updateReceipt(receiptId, jsonLd, undefined, Receipt.getUserId(options));
    }

    static deleteReceipt(receiptId) {
        let options = {
            "type": "irn"
        }
        return Receipt.getReceiptsGraphDB(options).deleteReceipt(receiptId);
    }

    static addIrn(receiptId, irn, options) {
        return Receipt.getReceiptsGraphDB(options).addIrn(receiptId, irn);
    }

    static updateErpInvoiceStatus(receiptId, status, options) {
        return Receipt.getReceiptsGraphDB(options).updateErpInvoiceStatus(receiptId, status);
    }

    static addBulkReceipts(receipts, options) {
        return Receipt.getReceiptsGraphDB(options).addBulkReceipts(receipts, Receipt.getUserId(options));
    }

    static getAttachTemplate(receiptId, templateCons, step, txnType, receiptMetaData, rootDetails) {

        // let promise;
        if (!templateCons) {
            return Promise.resolve();
        }
        return TemplateCons.attachWorkflow(receiptId, templateCons["@id"], templateCons.stepSigners, step, txnType, receiptMetaData, rootDetails);
    }

    static getAllReceipts(owner, options, status, fromDate, toDate) {
        return Receipt.getReceiptsGraphDB(options).getAllReceipts(Receipt.getUserId(options, owner), status, fromDate, toDate);
    }

    static getAllReceiptsByParent(parentDocId, options) {
        return Receipt.getReceiptsGraphDB(options).getAllReceiptsByParent(parentDocId);
    }
    static getAllReceiptsByParentV1(parentDocId, options) {
        return Receipt.getReceiptsGraphDB(options).getAllReceiptsByParentV1(parentDocId);
    }
    static getAllReceiptsByParentAndSubType(parentDocId, subType, options) {
        return Receipt.getReceiptsGraphDB(options).getAllReceiptsByParentAndSubType(parentDocId, subType);
    }

    static getAllReceiptsByStep(from, to, step, type, isLogistics, options) {
        return Receipt.getReceiptsGraphDB(options).getAllReceiptsByStep(from, to, step, type, isLogistics, undefined);
    }

    static getAllOrphanReceipts(paramId, role, options) {
        return Receipt.getReceiptsGraphDB(options).getAllOrphanReceipts(paramId, role);
    }
    static getAllCurrencies() {
        return Receipt.getReceiptsGraphDB().getAllCurrencies();
    }
    static getParentDocId(childDocId, options) {
        return Receipt.getReceiptsGraphDB(options).getParentDocId(childDocId);
    }

    static getValidationData(receiptId, step, options) {
        return Receipt.getReceiptsGraphDB(options).getValidationData(receiptId, step);
    }
    static getSellerAndBuyer(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getSellerAndBuyer(receiptId);
    }

    static isDocumentRejected(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).isDocumentRejected(receiptId);
    }

    static getRootReceiptId(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getRootReceiptId(receiptId);
    }
    static getRootReceiptIdFromGrn(grnId, options) {
        return Receipt.getReceiptsGraphDB(options).getRootReceiptIdFromGrn(grnId);
    }
    static getItemQuantitySummary(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getItemQuantitySummary(receiptId);
    }
    static doesChildExist(parentDocId, internalChildId, childStep, options) {
        return Receipt.getReceiptsGraphDB(options).doesChildExist(parentDocId, internalChildId, childStep);
    }
    static getInvoiceFromPoAndInvoiceInternalId(poId, invoiceInternalId, options) {
        // return Promise.resolve(false);
        return Receipt.getReceiptsGraphDB(options).getInvoiceFromPoAndInvoiceInternalId(poId, invoiceInternalId);
    }
    static getReceiptsGraphDB(options) {
        if (!options || !options.type) {
            return ParamConnector.getInstance().getDB().receipts;
        }
        if (options.type === 'irn') {
            return ParamConnector.getInstance().getdatabase(options).receipts;
        }
        return ParamConnector.getInstance().getdatabase(options).receipts;
    }

    static getReceipt(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getReceipt(receiptId, Receipt.getUserId(options));
    }

    static getReceiptByItem(itemId, step, options) {
        return Receipt.getReceiptsGraphDB(options).getReceiptsByItem(itemId, step);
    }

    static getLinkedInvoices(receiptId) {
        return ParamConnector.getInstance().getDB().linkedInvoices.getLinkedInvoice(receiptId);
    }

    static getReceiptMetaData(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getReceiptMetaData(receiptId);
    }
    static getReceiptInfoByNode(receiptId, node, options) {
        return Receipt.getReceiptsGraphDB(options).getReceiptInfoByNode(receiptId, node);
    }
    static getSummary(receiptId, nodes, options) {
        return Receipt.getReceiptsGraphDB(options).getSummary(receiptId, nodes);
    }
    static getSummaryInBulk(receiptIds, nodes, options) {
        return Receipt.getReceiptsGraphDB(options).getSummaryInBulk(receiptIds, nodes);
    }
    static getReceiptInternalId(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getReceiptInternalId(receiptId);
    }
    static search(searchString, owner, options) {
        return Receipt.getReceiptsGraphDB(options).search(searchString, owner);
    }

    static filterReceiptsByDate(startDate, endDate, owner, options) {
        return Receipt.getReceiptsGraphDB(options).filterReceiptsByDate(startDate, endDate, owner);
    }

    static getReceiptByIRN(irn, options) {
        return Receipt.getReceiptsGraphDB(options).getReceiptByIRN(irn);
    }

    static getIrnByReceiptId(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getIrnByReceiptId(receiptId);
    }

    static getAllReceiptsWithIRNByOwner(owner, options) {
        return Receipt.getReceiptsGraphDB(options).getAllReceiptsWithIRNByOwner(Receipt.getUserId(options, owner));
    }
    static getInvoicesByDisputeKey(owner, disputeKey, type, options) {
        return Receipt.getReceiptsGraphDB(options).getInvoicesByDisputeKey(Receipt.getUserId(options, owner), disputeKey, type);
    }

    static getAllReceiptIdsInPath(source, destination, receiptArray, options) {
        return Receipt.getReceiptsGraphDB(options).getAllReceiptIdsInPath(source, destination, receiptArray);
    }

    static editDisputedInfoAndState(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).editDisputedInfoAndState(receiptId);
    }
    static getAllItemsSummaryForReceipt(receiptId, options) {
        return Receipt.getReceiptsGraphDB(options).getAllItemsSummaryForReceipt(receiptId);
    }

    static getAllReceiptsByTypeForExport(type, step, options) {
        return Receipt.getReceiptsGraphDB(options).getAllReceiptsByTypeForExport(type, step);
    }

    static getAttachedInvoices(creditNoteId, options) {
        return Receipt.getReceiptsGraphDB(options).getAttachedInvoices(creditNoteId);
    }

    static getConfig(transactionInit, transactionReceiver) {
        return Utils.getConfig("ParamContract");
        let graphDB = ParamConnector.getInstance().getDB();
        let ePhoneBook = graphDB.ePhoneBook;
        let promiseArray = [ePhoneBook.isEnterpriseNode(transactionInit), ePhoneBook.isEnterpriseNode(transactionReceiver)]
        return Promise.all(promiseArray).then(result => {
            //Non enterprise nodes.
            if (!result || (!result[0] && !result[1])) {
                // return Utils.getConfig("ParamContract");
                return Promise.resolve(Utils.getConfig("ParamContract"));
            }
            return Receipt.getConfigPrivateFor(graphDB, transactionInit, transactionReceiver, "ParamContract")
        })
    }

    static getConfigPrivateFor(graphDB, transactionInit, transactionReceiver, contractType) {
        return graphDB.versionControl.getNodePublicFor(transactionInit, transactionReceiver, contractType).then(privateForInfo => {
            //hard coded index 0
            let promiseArray = [];
            promiseArray.push(privateForInfo)
            for (let contractId in privateForInfo) {
                for (let index in privateForInfo[contractId][0]) {
                    promiseArray.push(graphDB.ePhoneBook.getNodeDetailsById(privateForInfo[contractId][0][index]));
                }
            }
            return Promise.all(promiseArray)
        }).then(res => {
            let privateForInfo = res[0];
            res.shift(1);
            let nodeInfos = res;
            let contractRootAddress = Object.keys(privateForInfo);
            contractRootAddress = contractRootAddress[0];
            return Promise.all([graphDB.versionControl.getLatestContractByRoot(contractRootAddress), nodeInfos]);
        }).then(([contractAddress, nodeInfos]) => {
            let config = JSON.parse(nodeInfos[0].config);
            config.address = contractAddress;
            config.privateFor = [nodeInfos[0].organizationId, nodeInfos[1].organizationId];
            console.log(contractAddress, nodeInfos)
            return config;
        })
    }

    static isEnterpriseNode(paramId) {
        let graphDB = ParamConnector.getInstance().getDB();
        return graphDB.ePhoneBook.isEnterpriseNode(paramId);
    }

    static getPrivateFor(sellerId, buyerId) {
        let graphDB = ParamConnector.getInstance().getDB();
        return graphDB.versionControl.getNodePublicFor(sellerId, buyerId, "ParamContract");
    }

    static encryptJsonLd(jsonLd, receiptId, receiptManager, extendedKnowledge, isDirectPO) {
        let privateKey = Utils.getPrivateKey();
        let receiptKey, rootId;
        jsonLd = JSON.parse(jsonLd);
        let publicKey = jsonLd.customer.publicKey;
        if (isDirectPO) {
            publicKey = jsonLd.provider.publicKey;
        }
        if (jsonLd.transactionType !== '2') {
            return Promise.resolve({ receiptJson: JSON.stringify(jsonLd), extendedKnowledge: extendedKnowledge, rootDetails: { receiptKey, rootId } });
        }
        jsonLd = JSON.stringify(jsonLd);
        if (receiptId) {
            return Receipt.getRootReceiptId(receiptId).then(res => {
                let transactionData = Utils.getTransactionData(res);
                rootId = res
                return receiptManager.getReceipt(res); //transactionData.id
                // }).then(receiptData => {
                //     return DBUtils.getFromIpfsHash(receiptData.jsonLd);
            }).then(receipt => {
                let cipherText = receipt.jsonLD;
                publicKey = ECIES.getPublicKey(cipherText);
                receiptKey = ECIES.getReceiptKey(privateKey, cipherText);
                if (extendedKnowledge) {
                    extendedKnowledge = ECIES.encrypt(privateKey, publicKey, extendedKnowledge);
                }
                let receiptJson = ECIES.encrypt(privateKey, publicKey, jsonLd, receiptKey);
                return { receiptJson: receiptJson, extendedKnowledge: extendedKnowledge, rootDetails: { receiptKey, rootId } };
            })
        }
        else {
            let receiptJson = ECIES.encrypt(privateKey, publicKey, jsonLd, receiptKey);
            receiptKey = ECIES.getReceiptKey(privateKey, receiptJson);
            return Promise.resolve({ receiptJson: receiptJson, extendedKnowledge: extendedKnowledge, rootDetails: { receiptKey, rootId } });
        }

    }

    static getTimeline(receiptId) {
        let graphDb = ParamConnector.getInstance().getDB();
        let transactionsDB = graphDb.transactionsDB;
        let receiptIds = [];
        return graphDb.receipts.getAllReceiptIdsInTree(receiptId, receiptIds).then(res => {
            receiptIds = res;
            return graphDb.receipts.getRootReceiptId(receiptId);
        }).then((rootReceiptId) => {
            return Receipt.getAllReceiptIdsInPath(receiptId, rootReceiptId, []);
        }).then((res) => {
            receiptIds = [...receiptIds, ...res];
            let promise, promiseArray = [];
            let result = [];
            for (let index in receiptIds) {
                promise = transactionsDB.getTransactions(receiptIds[index]).then(transactions => {
                    let formattedTransactions = [];
                    for (let index in transactions) {
                        const transactionEvent = transactions[index] ? transactions[index].event : "";
                        if (transactionEvent === "onSubscriber" || transactionEvent === "onSubscriberReceiptUpdateV1") {
                            continue;
                        }
                        formattedTransactions.push(transactions[index]);
                    }
                    let promiseArray = [];
                    // let transactions = receiptData.transactions;
                    promiseArray = formattedTransactions;
                    promiseArray.splice(0, 0, Receipt.getWorkflowTimeline(receiptIds[index]));
                    return Promise.all(promiseArray);
                }).then(result => {
                    let workflowSteps = result.shift();
                    if (workflowSteps.templateInfo.length !== 0)
                        result = result.concat(workflowSteps);
                    result = [].concat.apply([], result);
                    result.sort(function (object1, object2) {
                        return object1.blockNumber - object2.blockNumber;
                    });
                    return result

                }).then(res => {
                    result = result.concat(res);
                    return result;
                })
                // .then(res => {
                //     return res;
                // });
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray).then(() => {
                return result;
            });
        });

    }

    static getWorkflowTimeline(receiptId) {
        return ParamConnector.getInstance().getDB().templateConsensusDB.getTemplateConsensusByDocId(receiptId).then(result => {
            let promiseArray = [];
            if (!result || !result.templateConsensusId || result.templateConsensusId.length == 0) {
                return Promise.all(promiseArray);
            }
            for (let index in result.templateConsensusId) {
                promiseArray.push(
                    TemplateCons.getTimeline(result.templateConsensusId[index])
                )
            }
            return Promise.all(promiseArray);
            // return TemplateCons.getTimeline(result.templateConsensusId[result.templateConsensusId.length - 1]);
        }).then((result => {
            return { receiptId, "templateInfo": result }
        }));
    }

    static assignTemplateReceipt(templateConsId, receiptId, contractAddress, step) {
        // let transactionData = Utils.getTransactionData(receiptId);
        let receiptManager = GraphQL.getInstance().receipts;
        return receiptManager["assignTemplate"](templateConsId, receiptId, contractAddress, step);
        // return Utils.getConfig("ParamContract", true).then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //     paramNetwork.setConfig({ receipt: transactionData.address });
        //     let receiptManager = paramNetwork.getReceiptManager();
        //     return receiptManager["assignTemplate"](templateConsId, transactionData.id, contractAddress, step, options);
        // })
    }

    static getTransactionFromNetwork(receiptId) {
        let transactionData = Utils.getTransactionData(receiptId);
        return Utils.getConfig("ParamContract", true).then(config => {
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig({ receipt: transactionData.address });
            let receiptManager = paramNetwork.getReceiptManager();
            return Promise.all([receiptManager["getTransactions"](transactionData.id), receiptManager])
        }).then(([txns, receiptManager]) => {
            let promiseArray = [];
            for (let index in txns) {
                promiseArray.push(receiptManager['getTransaction'](txns[index]));
            }
            return Promise.all(promiseArray);
        }).then(res => {
            let statusToNote = {};
            for (let index in res) {
                statusToNote[`${res[index][5]}_note`] = res[index][6];
            }
            console.log(statusToNote);
            return statusToNote;
        })
    }

    static sendInvoice(receiverEmail, receiptDetails, emailType) {
        let emailManager = NetworkBridge.getEmailManager();
        let jsonLd = {
            email: receiverEmail,
            emailType: emailType,
            receipt: receiptDetails
        }
        jsonLd = JSON.stringify(jsonLd);
        jsonLd = btoa(jsonLd);
        return emailManager.sendMail("2", jsonLd, "");
    }

    static getReceiptRootIdFromNetwork(receiptId, receiptManager) {
        let contractAddress = Utils.getTransactionData(receiptId).address
        return receiptManager.getReceiptChild(receiptId).then(res => {
            let pId = res.pId;
            if (pId === "0x0000000000000000000000000000000000000000000000000000000000000000") {
                return receiptId;
            }
            pId = Utils.getTransactionId(pId, contractAddress)
            return this.getReceiptRootIdFromNetwork(pId, receiptManager);
        })

        // return receiptManager.getReceiptChild(receiptId, receiptManager).then(res => {
        //     let pId = res.pId;
        //     if (pId === "0x0000000000000000000000000000000000000000000000000000000000000000") {
        //         return receiptId;
        //     }
        //     return this.getReceiptRootIdFromNetwork(pId, receiptManager);
        // })
    }

    static getAllReceiptIdsInTree(receiptId, receiptManager) {
        return Receipt.getReceiptRootIdFromNetwork(receiptId, receiptManager).then(rootId => {
            let receiptIds = [Utils.getTransactionData(receiptId).id];
            return Receipt.getTree(rootId, receiptManager, receiptIds).then((res) => {
                console.log("res", res);
                receiptIds = [].concat.apply([], receiptIds)
                return { receiptIds, rootId: receiptId };
            })
        })
    }

    static getTree(receiptId, receiptManager, returnResult) {
        console.log("receiptId", receiptId)
        let contractAddress = Utils.getTransactionData(receiptId).address
        return receiptManager.getReceiptChild(receiptId).then((node) => {
            // delete node[0];
            // delete node['pId'];
            let promiseArray = [];
            for (let index in node.docMapIds) {
                let _receiptId = Utils.getTransactionId(node.docMapIds[index], contractAddress)
                let promise = Receipt.getTree(_receiptId, receiptManager, returnResult)
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray).then((children) => {
                returnResult.push(node.docMapIds);
                node.docMapIds = children;
                console.log('children', children);
                return node;
            })
        });
    }

    static getTimelineV1(receiptId) {
        receiptId = receiptId.split("-")[0]
        let receiptManager;
        return Receipt.getConfig().then(config => {
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig(config);
            receiptManager = paramNetwork.getReceiptManager(config.address);
            return this.getAllReceiptIdsInTree(receiptId, receiptManager)
        }).then(data => {
            let ProArray = [];
            data.receiptIds.forEach(element => {
                ProArray.push(receiptManager.getAllEventsByReceiptId(element));
            });
            return Promise.all(ProArray);
        }).then(data => {
            let res = [];
            data.forEach(element => {
                element.forEach(rData => {
                    let formatedTimelineInfo = Receipt.getFormatedTimelineData(data, rData)
                    res.push(formatedTimelineInfo);
                });
            })
            return Promise.all(res);
        })
    }
    static getFormatedTimelineData(data, rData) {
        const stepData = ["INIT_PROPOSAL", "CREATE_PO", "SEND_INVOICE", "MAKE_PAYMENT", "CREATE_RECEIPT", "GRN_ATTACHED"];
        const docData = ["GRN", "NOTE"]
        let pId = rData.args.pId;
        let rId = rData.args.rId;
        let aRes = {
            "pDocId": pId,
            "currentDocId": rId,
            "childrens": [
            ],
            "blockInfo": {
                "number": rData.blockNumber,
                "txHash": rData.transactionHash,
                "created": rData.dateAndTime
            },
            "seller": rData.args.seller,
            "buyer": rData.args.buyer,
            "step": rData.args.step,
            "status": rData.args.status,
            "event": rData.event,
            "docType": rData.args.step ? stepData[rData.args.step] : docData[rData.args.docType],
            "contractAddress": rData.contractAddress,
            "docId": rData.args.docId
        }
        aRes.childrens = Receipt.getChild(data, rData.args.rId);
        console.log(rData.from)
        return VendorManager.getContactSummaryByParamId(Web3Utils.toChecksumAddress(rData.from)).then(contact => {
            aRes["from"] = { "address": rData.from, contactName: contact.name }
            return aRes;
        })
    }
    static getChild(data, rId) {
        let cRes = [];
        data.forEach(element => {
            element.forEach(rData => {
                if (rId == rData.args.pId) {
                    cRes.push(rData.args.rId);
                }
            });
        });
        return cRes;
    }

}

export default Receipt;