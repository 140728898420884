class DateUtils{
    static getLogDateFormat(date){
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + '_' + minutes + '_' + ampm;
        return date.getMonth()+1 + "-" + date.getDate() + "-" + date.getFullYear() + "-" + strTime;     
    }
}
export default DateUtils; 