import { SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH, TOGGLE_PURCHASE_VISIBILITY, TOGGLE_SALES_VISIBILITY, TOGGLE_LSALES_VISIBILITY, TOGGLE_LPURCHASES_VISIBILITY, TOGGLE_DRAFT_INVOICE_VISIBILITY, SHOW_DRAFT_INVOICE, SHOW_SALES, SHOW_PURCHASES, SHOW_LSALES, SHOW_LPURCHASES, TOGGLE_REPORTS_VISIBILITY, TOGGLE_OPERATIONS_VISIBILITY, TOGGLE_SUBDOCUMENTS_VISIBILITY, SHOW_REPORTS, SHOW_SUBDOCUMENTS, SHOW_OPERATIONS, SHOW_CATALOGUE, TOGGLE_CATALOGUE_VISIBILITY, TOGGLE_CONTACTS_VISIBILITY, TOGGLE_CREDIT_NOTE_TEMPLATE_VISIBILITY, SHOW_CONTACTS, SHOW_CREDIT_NOTE_TEMPLATE, HIDE_CREDIT_NOTE_TEMPLATE } from "../../constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR_SELECTION,
  THEME_COLOR_SELECTION_PRESET,
  THEME_TYPE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

const initialSettings = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_LITE,
  colorSelection: THEME_COLOR_SELECTION_PRESET,
  salesVisibility: SHOW_SALES,
  purchasesVisibility: SHOW_PURCHASES,
  lSalesVisibility: SHOW_LSALES,
  lPurchasesVisibility: SHOW_LPURCHASES,
  draftInvoicesVisibility: SHOW_DRAFT_INVOICE,
  reportsVisibility: SHOW_REPORTS,
  subscribedDocumentsVisibility: SHOW_SUBDOCUMENTS,
  operationsVisibility: SHOW_OPERATIONS,
  catalogueVisibility: SHOW_CATALOGUE,
  contactsVisibility: SHOW_CONTACTS,
  creditNoteTemplateVisibility: HIDE_CREDIT_NOTE_TEMPLATE,
  pathname: '',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.pathname,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      };
    case THEME_COLOR_SELECTION:
      return {
        ...state,
        colorSelection: action.colorSelection
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };
    case TOGGLE_PURCHASE_VISIBILITY:
      return {
        ...state,
        purchasesVisibility: action.purchasesVisibility
      };
    case TOGGLE_SALES_VISIBILITY:
      return {
        ...state,
        salesVisibility: action.salesVisibility
      };
    case TOGGLE_LSALES_VISIBILITY:
      return {
        ...state,
        lSalesVisibility: action.lSalesVisibility
      };
    case TOGGLE_LPURCHASES_VISIBILITY:
      return {
        ...state,
        lPurchasesVisibility: action.lPurchasesVisibility
      };
    case TOGGLE_DRAFT_INVOICE_VISIBILITY:
      return {
        ...state,
        draftInvoicesVisibility: action.draftInvoicesVisibility
      };
    case TOGGLE_REPORTS_VISIBILITY:
      return {
        ...state,
        reportsVisibility: action.reportsVisibility
      };
    case TOGGLE_SUBDOCUMENTS_VISIBILITY:
      return {
        ...state,
        subscribedDocumentsVisibility: action.subscribedDocumentsVisibility
      };
    case TOGGLE_OPERATIONS_VISIBILITY:
      return {
        ...state,
        operationsVisibility: action.operationsVisibility
      };
    case TOGGLE_CATALOGUE_VISIBILITY:
      return {
        ...state,
        catalogueVisibility: action.catalogueVisibility
      };
    case TOGGLE_CONTACTS_VISIBILITY:
      return {
        ...state,
        contactsVisibility: action.contactsVisibility
      }; case TOGGLE_CREDIT_NOTE_TEMPLATE_VISIBILITY:
      return {
        ...state,
        creditNoteTemplateVisibility: action.creditNoteTemplateVisibility
      };
    default:
      return state;
  }
};

export default settings;
