/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Card, Tag, Form } from "antd";
import './index.less'
import BaseComponent from '../BaseComponent'
import * as Utils from '../../../util/utils';
import IconComponent from "../../../components/IconComponent";
import CreateFormComponent from "../../components/CreateFormComponent";

class Create extends BaseComponent {

    constructor(props) {
        super(props);
        this.initStates();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    initStates() {
        let path = this.props.location.pathname;
        let pathArr = path.split('/');
        let currency = Utils.getCurrencyFromProfile();
        let type = pathArr[pathArr.length - 2];
        this.searchParams = new URLSearchParams(this.props.location.search);
        let cnId = this.searchParams.get('draftInvoiceNumber')
        Utils.setLocalStorage('draftInvoiceNumber', cnId);
        let daId = this.searchParams.get('debitAdviceNumber')
        Utils.setLocalStorage('debitAdviceNumber', daId);
        this.state = {
            heading: this.searchParams.get('heading') || 'Contract',
            subType: this.searchParams.get('subType'),
            createText: this.searchParams.get('isUpdate') ? "Update" : this.searchParams.get('isEditable') ? "Edit" : "Create",
            type,
            currency
        };

    }
    setCreateFormRef = element => {
        this.createFormRef = element;
    }

    goBack = () => {
        this.props.history.goBack();
    }

    checkAndGoBack = () => {
        Utils.headsUpCheck(this, this.createFormRef).then(result => {
            if (result) {
                return;
            }
        })
    }

    renderMainContent() {

        const titleText = `Upload ${this.state.heading}`;//${this.state.createText}
        let title = <h2 className="nomargin">
            {this.state.invitationId && this.state.isShareCatalogue ?
                'Share Catalogue'
                : this.state.invitationId ?
                    `Create Evaluation Request`
                    :
                    titleText
            }
        </h2>
        console.log('Logi', this.state.logistics)
        return <div>
            <Card title={title}
                extra={
                    <div>
                        {this.state.invitationId || this.state.subType === 'evalRequest' ?
                            <Tag color="purple" style={{ marginLeft: '1rem' }}>EVALUATION REQUEST</Tag>
                            :
                            null
                        }
                        <IconComponent hoverText="Close" type="close" onClick={this.checkAndGoBack} actionIcon={true} />
                    </div>
                } className='create-card'>
                <CreateFormComponent
                    heading={this.state.heading}
                    currency={this.state.currency}
                    subType={this.state.subType}
                    type={this.state.type}
                    searchParams={this.searchParams}
                    createText={this.state.createText}
                    showExtraOptions={true}
                    onSuccess={this.goBack}
                    ref={this.setCreateFormRef}
                />
            </Card >
        </div >
    }
}

// const CreateForm = Form.create()(Create)
export default Create;
