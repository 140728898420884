import Console from '../../../logger/index';
import Database from '../../../database/index';
import { gql } from '@apollo/client';
import RestoreContacts from '../sync/contacts';
import * as NetworkUtils from '../../../../src/util/utils';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import GraphQL from '..';

class ContactEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient
        this.dbInstance = Database.getInstance().getDB()
    }

    registerEvents(paramId) {
        let addContactOptions = {
            query: gql`
               subscription onContactAdded($paramId: [String]){
                    onContactAdded(contactOwnerID: $paramId){
                        contractAddress,
                        contactId,
                        owner
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(addContactOptions).subscribe({
            next(data) {
                that._onContactAdded(data)
            },
            error(error) {
                Console.error(`Unable to get create contact details form network, Reson: ${error}`);
            },
        });
        //receiver 
        let updateContactOptions = {
            query: gql`
               subscription onContactUpdated($paramId: [String]){
                    onContactUpdated(contactOwnerID: $paramId){
                        contractAddress,
                        contactId,
                        owner
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(updateContactOptions).subscribe({
            next(data) {
                that._onContactUpdated(data)
            },
            error(error) {
                Console.error(`Unable to get create contact details form network, Reson: ${error}`);
            },
        });
        Console.log(`Registered Events for Contact`)

    }

    _emitOnContactAdded(contactId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("OnContactUpdate", contactId);

    }

    _emitOnContactUpdated(contactId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("OnContactUpdate", contactId);
    }

    _onContactAdded(subscriptionDataJSON) {
        Console.debug(subscriptionDataJSON)
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onContactAdded
        // dataJSON.args.contactId = NetworkUtils.getTransactionId(dataJSON.args.contactId, dataJSON.args.contractAddress);
        return RestoreContacts.restoreContact(dataJSON.args.contactId, dataJSON.args.contractAddress, dataJSON.args.owner).then(() => {
            Console.log(`[GraphDB] Added contact info to graph ${dataJSON.args.contactId}`);
            return this._emitOnContactAdded(dataJSON.args.contactID);
        }).catch(e => {
            Console.error(`Unable to get contact status, Reason: ${e.toString()}`);
        })

    }

    _onContactUpdated(subscriptionDataJSON) {
        // dataJSON.args.receiver
        Console.info(`[GraphDB] Got _onContactUpdated event`);
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onContactUpdated
        // dataJSON.args.contactId = NetworkUtils.getTransactionId(dataJSON.args.contactId, dataJSON.args.contractAddress);
        return RestoreContacts.restoreContact(dataJSON.args.contactId, dataJSON.args.contractAddress, dataJSON.args.owner, dataJSON.args.receiver).then(() => {
            Console.log(`[GraphDB] Updated contact info to graph ${dataJSON.args.contactId}`);
            return this._emitOnContactUpdated(dataJSON.args.contactId);
        }).catch(e => {
            Console.error(`Unable to get contact status, Reason: ${e.toString()}`);
        }).finally(() => {
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(dataJSON.args.contactId, NetworkUtils.getEventType('contact')).then(res => {
                let result = NetworkUtils.getLatestEventDataByName(res, "onContactUpdated")
                let eventMetaData = NetworkUtils.extractEventData(result)
                dataJSON = { ...dataJSON, ...eventMetaData }
                let metaData = NetworkDataFormat.getMetaInfo(dataJSON.args.contactId, dataJSON, "Contact edited");
                return this.dbInstance.transactionsDB.addTransaction(dataJSON.args.contactId, dataJSON.args.owner, metaData).catch(error => {
                    Console.error(`Unable to edit transaction for ${dataJSON.args.contactId}, Reason: ${error.toString()}`)
                })
            })
        })
    }

}

export default ContactEvents;