import NetworkBridge from "../../../../util/network-bridge";


class Wrapper {

    static getRefManager(reference) {
        console.log(reference)
        switch (reference) {
            case "contract":
            case "purchaseOrder":
            case "invoice":
            case "payment":
                return NetworkBridge.getReceiptManager();
            case "gRN":
            case "grn":
            case "GRN":
            case "epod":
            case "ePoD":
                return NetworkBridge.getGRNManager();
            case "returnRequest":
            case "returnOrder":
                return NetworkBridge.getReturnsManager();
            case "eLR":
            case "elr":
            case "acceptNote":
            case "draftIvoice":
            case "draftinvoices":
                return NetworkBridge.getAcceptNoteManager();
            case "partPurchaseOrder":
                return NetworkBridge.getPartPOManager();
            case "debitadvice":
            case "debitAdvice":
            case "cn":
            case "CN":
                return NetworkBridge.getQualityManager();
            default:
                return NetworkBridge.getReceiptManager();
        }
    }
}

export default Wrapper;