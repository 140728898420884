import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import ECIES from '../ecies';
import * as DBUtils from '../../database/nosql/Utils/utils';
import GraphQL from '../../param-libs/graph-ql';

class Subscriber {

    static addSellerConsent(receiptId, txnType) {
        let transactionData;
        let receiptManager = GraphQL.getInstance().receipts;
        let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
        return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
            transactionData = Utils.getTransactionData(rootId);
            return receiptManager["addSellerConsent"](transactionData.id, txnTypeString, transactionData.address);
        }).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
        //     transactionData = Utils.getTransactionData(rootId);
        //     return Utils.getConfig("ParamContract", true)
        // }).then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //     paramNetwork.setConfig({ receipt: transactionData.address });
        //     let paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //     return paramReceiptManager["addSellerConsent"](transactionData.id, txnType, transactionData.address, options);
        // })
    }

    static addBuyerConsent(receiptId, txnType) {
        let transactionData;
        let receiptManager = GraphQL.getInstance().receipts;
        let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
        return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
            transactionData = Utils.getTransactionData(rootId);
            return receiptManager["addBuyerConsent"](transactionData.id, txnTypeString, transactionData.address);
        }).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
        // let transactionData;
        // return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
        //     transactionData = Utils.getTransactionData(rootId);
        //     return Utils.getConfig("ParamContract", true)
        // }).then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //     paramNetwork.setConfig({ receipt: transactionData.address });
        //     let paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //     return paramReceiptManager["addBuyerConsent"](transactionData.id, txnType, transactionData.address, options);
        // })
    }

    static addSubscriber(payload, receiptId, txnType, subscriber, publicKeyOfSubscriber) {
        debugger;
        let transactionData;
        let receiptManager = GraphQL.getInstance().receipts;
        let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
        return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
            transactionData = Utils.getTransactionData(rootId);
            if (txnType === '2') {
                // let receiptManager = paramNetwork.getReceiptManager(transactionData.address);
                return receiptManager.getReceipt(rootId).then(receiptData => {
                    let receipt = receiptData.jsonLD
                    let privateKey = Utils.getPrivateKey();
                    const receiptKey = ECIES.getReceiptKey(privateKey, receipt);
                    payload = ECIES.encrypt(privateKey, publicKeyOfSubscriber, receiptKey);
                    return receiptManager["addSubscriber"](payload, transactionData.id, txnTypeString, subscriber, transactionData.address).then((res) => {
                        let signedData = Utils.codeSignPaylaod(JSON.parse(res))
                        return GraphQL.getInstance().sendRawTxn(signedData)
                    })
                })
            }
            return receiptManager["addSubscriber"](payload, transactionData.id, txnTypeString, subscriber, transactionData.address).then((res) => {
                let signedData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedData)
            })

        })
        // return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
        //     transactionData = Utils.getTransactionData(rootId);
        //     return Utils.getConfig("ParamContract", true)
        // }).then(config => {
        //     if (txnType === '2') {
        //         return Utils.getConfig("ParamContract", true).then(receiptConfig => {
        //             let paramNetwork = ParamConnector.getInstance(receiptConfig).getNetwork(receiptConfig);
        //             paramNetwork.setConfig({ receipt: transactionData.address });
        //             let receiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //             return receiptManager.getReceipt(transactionData.id)
        //         }).then(receiptData => {
        //             return DBUtils.getFromIpfsHash(receiptData.jsonLd);
        //         }).then(receipt => {
        //             let privateKey = Utils.getPrivateKey();
        //             const receiptKey = ECIES.getReceiptKey(privateKey, receipt);
        //             payload = ECIES.encrypt(privateKey, publicKeyOfSubscriber, receiptKey);
        //             let options = Utils.getNetworkOptions();
        //             let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //             paramNetwork.setConfig({ receipt: transactionData.address });
        //             let paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //             return paramReceiptManager["addSubscriber"](payload, transactionData.id, txnType, subscriber, transactionData.address, options);
        //         })
        //     }
        //     else {
        //         let options = Utils.getNetworkOptions();
        //         let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //         paramNetwork.setConfig({ receipt: transactionData.address });
        //         let paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //         return paramReceiptManager["addSubscriber"](payload, transactionData.id, txnType, subscriber, transactionData.address, options);
        //     }
        // })
    }

    static addSubscriberForGRN(payload, receiptId, receiptType, subscriber, publicKeyOfSubscriber, seller, buyer, rootDetails) {
        return Subscriber.addSubscriberForCons(payload, receiptId, receiptType, subscriber, publicKeyOfSubscriber, seller, buyer, "addSubscriberForGRN", rootDetails)
    }

    static addSubscriberForCons(payload, receiptId, txnType, subscriber, publicKeyOfSubscriber, seller, buyer, functionName = "addSubscriberForConsSync", rootDetails) {
        let receiptManager = GraphQL.getInstance().receipts
        let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
        let promise = Promise.resolve(rootDetails)
        let transactionData, privateKey

        if (txnType === "2") {
            privateKey = Utils.getPrivateKey();
            if (!rootDetails || !rootDetails.receiptKey) {
                promise = ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
                    return receiptManager.getReceipt(rootId).then(receiptData => {
                        let receipt = receiptData.jsonLD
                        return { receiptKey: ECIES.getReceiptKey(privateKey, receipt), rootId }
                    })
                })
            }
            return promise.then(({ receiptKey, rootId }) => {
                transactionData = Utils.getTransactionData(rootId)
                payload = ECIES.encrypt(privateKey, publicKeyOfSubscriber, receiptKey);
                return receiptManager[functionName](payload, transactionData.id, txnTypeString, subscriber, transactionData.address, seller, buyer).then(res => {
                    let signedData = Utils.codeSignPaylaod(JSON.parse(res))
                    return GraphQL.getInstance().sendRawTxn(signedData)
                })
            })
        }

        return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
            transactionData = Utils.getTransactionData(rootId)
            return receiptManager[functionName](payload, transactionData.id, txnTypeString, subscriber, transactionData.address, seller, buyer)
        }).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })

        // return ParamConnector.getInstance().getDB().receipts.getRootReceiptId(receiptId).then(rootId => {
        //     transactionData = Utils.getTransactionData(rootId);
        //     return Utils.getConfig("ParamContract", true)
        // }).then(config => {
        //     if (txnType === '2') {
        //         return Utils.getConfig("ParamContract", true).then(receiptConfig => {
        //             let paramNetwork = ParamConnector.getInstance(receiptConfig).getNetwork(receiptConfig);
        //             paramNetwork.setConfig({ receipt: transactionData.address });
        //             let receiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //             return receiptManager.getReceipt(transactionData.id)
        //         }).then(receiptData => {
        //             return DBUtils.getFromIpfsHash(receiptData.jsonLd);
        //         }).then(receipt => {
        //             let privateKey = Utils.getPrivateKey();
        //             const receiptKey = ECIES.getReceiptKey(privateKey, receipt);
        //             payload = ECIES.encrypt(privateKey, publicKeyOfSubscriber, receiptKey);
        //             let options = Utils.getNetworkOptions();
        //             let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //             paramNetwork.setConfig({ receipt: transactionData.address });
        //             let paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //             return paramReceiptManager[functionName](payload, transactionData.id, txnType, subscriber, transactionData.address, seller, buyer, options);
        //         })
        //     }
        //     else {
        //         let options = Utils.getNetworkOptions();
        //         let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //         paramNetwork.setConfig({ receipt: transactionData.address });
        //         let paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //         return paramReceiptManager[functionName](payload, transactionData.id, txnType, subscriber, transactionData.address, seller, buyer, options);
        //     }
        // })
    }

    static isSubscriberExists(receiptId, subscriberAddress) {
        let paramReceiptManager = GraphQL.getInstance().receipts
        // let transactionData = Utils.getTransactionData(receiptId);
        return paramReceiptManager["isSubscriberExists"](receiptId, subscriberAddress);

        // return Utils.getConfig("ParamContract", true).then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //     paramNetwork.setConfig({ receipt: transactionData.address });
        //     let paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
        //     return paramReceiptManager["isSubscriberExists"](transactionData.id, subscriberAddress, options);
        // })
    }
    static getConsentsForReceipt(receiptId) {
        let receiptContractAddress = receiptId.split('-')[1];
        return ParamConnector.getInstance().getDB().subscribers.getConsentsForReceipt(receiptId, receiptContractAddress);
    }

    static getReceiptsBySubscriberAndStep(address, step) {
        return ParamConnector.getInstance().getDB().subscribers.getReceiptsBySubscriberAndStep(address, step);
    }

    static getAllSubscribers(receiptId) {
        let receiptContractAddress = receiptId.split('-')[1];
        return ParamConnector.getInstance().getDB().subscribers.getAllSubscribers(receiptId, receiptContractAddress);
    }

    static isValidConsent(receiptId) {
        let receiptContractAddress = receiptId.split('-')[1];
        return ParamConnector.getInstance().getDB().subscribers.isValidConsent(receiptId, receiptContractAddress);
    }

}

export default Subscriber;