class ReportsTemplateConsensus {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getConsensusNumbers(templateId) {
        return this.mongoDBConnector.invoke('templateConsensusReportDB', "getConsensusNumbers", templateId);
    }

    getTotalStepConsensus(templateId) {
        return this.mongoDBConnector.invoke('templateConsensusReportDB', "getTotalStepConsensus", templateId);
    }

    getConsensusIdsByStep(templateId) {
        return this.mongoDBConnector.invoke('templateConsensusReportDB', "getConsensusIdsByStep", templateId);
    }

    getAverageCycleTime(templateId) {
        return this.mongoDBConnector.invoke('templateConsensusReportDB', "getAverageCycleTime", templateId);
    }

    getCompletionStatus(templateId) {
        return this.mongoDBConnector.invoke('templateConsensusReportDB', "getCompletionStatus", templateId);
    }

    getConsensusDetails(templateId) {
        return this.mongoDBConnector.invoke('templateConsensusReportDB', "getConsensusDetails", templateId);
    }

    averageTaskCompletionCycleTime(templateId) {
        return this.mongoDBConnector.invoke('templateConsensusReportDB', "averageTaskCompletionCycleTime", templateId);
    }
}
export default ReportsTemplateConsensus;