import Database from '../../index'
import * as NetworkUtils from '../../../util/utils';
import Console from '../../../logger/index';
import RestoreReturns from '../sync/returns';
import NetworkBridge from '../../../util/network-bridge';

class ReturnsDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerOnRequestUpdated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onRequestUpdated", callback);

    }

    unRegisterOnRequestUpdated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onRequestUpdated", callback);
    }

    registerOnReturnRequestCreated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onReturnRequestCreated", callback);

    }
    unRegisterOnReturnRequestCreated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onReturnRequestCreated", callback);
    }

    registerOnReturnRequestAccepted(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onReturnRequestAccepted", callback);

    }
    unRegisterOnReturnRequestAccepted(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onReturnRequestAccepted", callback);
    }

    registerOnReturnRequestRejected(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onReturnRequestRejected", callback);

    }
    unRegisterOnReturnRequestRejected(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onReturnRequestRejected", callback);
    }

    registerOnOrderRequestCreated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onOrderRequestCreated", callback);

    }
    unRegisterOnOrderRequestCreated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onOrderRequestCreated", callback);
    }

    registerOnOrderRequestAccepted(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onOrderRequestAccepted", callback);

    }
    unRegisterOnOrderRequestAccepted(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onOrderRequestAccepted", callback);
    }

    registerOnOrderRequestRejected(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onOrderRequestRejected", callback);

    }

    unRegisterOnOrderRequestRejected(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onOrderRequestRejected", callback);
    }

    onRequestUpdated(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return this.onRequesStatusUpdate(error, dataJSON, "onRequestUpdated");
    }
    onReturnRequestCreated(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return this.onRequesStatusUpdate(error, dataJSON, "onReturnRequestCreated");
    }
    onReturnRequestAccepted(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return this.onRequesStatusUpdate(error, dataJSON, "onReturnRequestAccepted");
    }
    onReturnRequestRejected(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return this.onRequesStatusUpdate(error, dataJSON, "onReturnRequestRejected");

    }
    onOrderRequestCreated(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return this.onRequesStatusUpdate(error, dataJSON, "onOrderRequestCreated");

    }
    onOrderRequestAccepted(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        //operation will contiue in background
        const returnsDbId = NetworkUtils.getTransactionId(dataJSON.args.id, dataJSON.args.contractAddress);
        this.dbInstance.returns.getReturn(returnsDbId).then(res => {
            if (res) {
                let selfParamId = NetworkUtils.getParamId();
                let otherParamId;
                if (selfParamId === res.provider.identifier) {
                    otherParamId = res.customer.identifier;
                } else if (selfParamId === res.customer.identifier) {
                    otherParamId = res.provider.identifier;
                }
                if (otherParamId) {
                    return NetworkBridge.getItemManager().updateInventories(res, "Returns", otherParamId);
                }
            }
        })
        return this.onRequesStatusUpdate(error, dataJSON, "onOrderRequestAccepted")
    }
    onOrderRequestRejected(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }

        return this.onRequesStatusUpdate(error, dataJSON, "onOrderRequestRejected");

    }

    onRequesStatusUpdate(error, dataJSON, event) {
        if (error) {
            return;
        }
        let eventName = event ? event : "onRequestStatusUpdate";
        if (dataJSON.args.id === "0x0000000000000000000000000000000000000000000000000000000000000000") {
            dataJSON.args.id = dataJSON.args.parentId;
        }
        dataJSON.args.id = NetworkUtils.getTransactionId(dataJSON.args.id, dataJSON.args.contractAddress);
        // dataJSON.args.pId = NetworkUtils.getTransactionId(dataJSON.args.pId, dataJSON.args.contractAddress);
        // dataJSON.args.status = dataJSON.args.status.toString();
        // dataJSON.args.step = dataJSON.args.step.toString();

        return RestoreReturns.restoreReturns(dataJSON.args.id, dataJSON.args.contractAddress, "", dataJSON.args.parentContractAddress).then((res) => {
            Console.log(`[GraphDB] Updated ${dataJSON.args.id} with status ${dataJSON.args.step}`);
            return this.dbInstance.emitEvent(eventName, dataJSON.args.id, dataJSON.args.parentId);
        }).catch(e => {
            Console.error(`[Error] Unable to update return status for ${dataJSON.args.id}, Reason: ${e.toString()}`);
        })
    }
}

export default ReturnsDBEvents;