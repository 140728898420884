import * as Config from '../../config/defaultGraphConfig';
import GraphDB from '../../../../database';
import FormatData from '../../utils/formatData';

class Graphs {

    constructor(paramId, mongoClient) {
        this.paramId = paramId;
        let mongoDatabase = mongoClient.db(this.paramId);
        this.graphDB = GraphDB.getInstance();
    }

    getAllGraphs(paramId) {
        console.log("get all graphs")
        let response = [];
        return this.g.V(`<${paramId}>`).Out("<graphs>").GetLimit(-1).then(res => {
            res = res.result;
            if (!res) {
                return null;
            }
            for (let index in res) {
                response.push(this.getGraph(FormatData.removeAngular(res[index].id)));
            }
            return Promise.all(response);
        })
    }

    getGraph(graphId) {
        return this.graphDB.core.getter.get(graphId);
    }

    createDefaultGraphs() {
        let paramId = this.paramId;
        let defaultGraphsConfigs = Config.chartConfig;
        let promiseArray = [];
        for (let index = 0; index < defaultGraphsConfigs.length; index++) {
            promiseArray.push(this.saveGraph(paramId, defaultGraphsConfigs[index]));
        }
        return Promise.all(promiseArray);
    }

    saveGraph(paramId, data) {
        try {
            data = JSON.parse(data);
        } catch (e) {

        }
        if (typeof data !== "object") {
            data = {};
        }
        let graphId = paramId + "/" + data["@type"]   //Utils.getDigest(JSON.stringify(data), undefined, 16);
        data['@id'] = graphId;
        let promise, promiseArray = [];
        promise = this.graphDB.core.writer.write(data);
        promiseArray.push(promise);
        promise = this.client.write([
            {
                primaryKey: paramId,
                graphs: `<${graphId}>`
            }
        ]).catch(e => {
            console.log(e);
            return new Error(e)
        })
        return Promise.all(promiseArray);
    }

    isDefaultGraphPresent(paramId) {
        return this.g.V(`<${paramId}>`).Out("<graphs>").GetLimit(-1).then(res => {
            res = res.result;
            if (!res) {
                return false;
            }
            return true;
        })
    }
}
export default Graphs;