// const fetch = require('node-fetch');
import { getParamId, getFromLocalStorage, getValidToken } from '../../../util/utils';
import { serverURL, appKey } from '../../../constants/AppConstants';
import EventManager from '../../../event-manager/event-manager';

class HTTPMongoConnector {
    initDatabase(URL, paramId, port = 4000) {
        this.port = port;
        return fetch(`${serverURL}/initializedb`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                URL,
                paramId
            })
        })
    }

    invoke(moduleName, functionName, ...params) {
        let data = [moduleName, functionName, params];
        if (moduleName === "reports") {
            moduleName = functionName;
            functionName = params.shift();
            data = ["reports", moduleName, functionName, params];
        }
        const paramId = getParamId();
        let json = { data: data, paramId: paramId };
        return getValidToken(getFromLocalStorage('otpToken')).then(token => {
            return fetch(serverURL, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': token,
                    'app-key': appKey
                },
                body: JSON.stringify(json)
            })
        }).then(res => {
            if (res.status === 401) {
                return Promise.reject(res.message).finally(() => {
                    EventManager.getInstance().emitEvent("sessionExpired", res.status)
                })
            }
            if (res.status !== 200) {
                return Promise.reject(res.message)
            }
            return res.json();
        }).then(({ result }) => {
            this.retried = false;
            return result;
        }).catch(err => {
            console.error(err);
            const dbURL = getFromLocalStorage('dbURL');
            if (!this.retried) {
                return this.initDatabase(dbURL, paramId).then(() => {
                    this.retried = true;
                    return this.invoke(moduleName, functionName, ...params);
                })
            }
        })
    }

    resetDB() {
        return fetch(`${serverURL}/resetdb`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }
}

export default HTTPMongoConnector;