import { gql } from '@apollo/client';
import * as Utils from '../../../../src/util/utils';

class Receipts {

    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }
    /**
     *
     * @param {*} participant address
     * @param {*} options : (type) Object
     *                      Keys 'type' and 'state'
     *                          type : sales - the function will return all the receipt ids sent by the participant.
     *                          type : purchases - the function will return all the receipt ids received by the participant.
     *                          type : undefined -  gives all the receipts based on the participant
     *                          state : "0","1","2","3","4"
     *                          state : undefined - gives all the receipts with different states.
     *
     */
    getAllLatestReceipts(participant, options, fromDate, toDate) {

    }
    /**
     *
     * @param {*} participant address
     * @param {*} options : (type) Object
     *                      Keys 'type' and 'state'
     *                          type : sales - the function will return all the receipt ids sent by the participant.
     *                          type : purchases - the function will return all the receipt ids received by the participant.
     *                          type : undefined -  gives all the receipts based on the participant
     *                          state : "0","1","2","3","4"
     *                          state : undefined - gives all the receipts with different states.
     *
     */
    getAllReceipts(participant, options, fromDate, toDate, startIndex = 0, pageSize = 10) {
        let receiptPromises = [];
        let promise;
        if (fromDate && toDate) {
            fromDate = fromDate.toString();
            toDate = toDate.toString();
        }
        if (!options || !options.state) {
            return this.getAllLatestReceiptsByStatus(participant, options, fromDate, toDate, startIndex, pageSize);
        }
        let states = options.state;
        if (typeof options.state === "string") {
            states = [options.state];
        }
        options.state = null;
        //TODO avoid looping
        for (let index in states) {
            options.state = states[index];
            promise = this.getAllLatestReceiptsByStatus(participant, options, fromDate, toDate, startIndex, pageSize);
            receiptPromises.push(promise);
        }
        return Promise.all(receiptPromises).then(receiptIds => {
            receiptIds = [].concat.apply([], receiptIds);
            receiptIds = [...new Set(receiptIds)];
            return {
                receipts: receiptIds,
                count: receiptIds.length
            };
        });
    }

    getAllReceiptsByCurrency(participant, options, fromDate, toDate, currency) {

    }

    getInvoicesByDisputeKey(owner, disputeKey, type) {
        //review
        return { "receipts": [], "count": 0 }
    }

    getAllLatestReceiptsByStatus(participant, options, fromDate, toDate, startIndex = 0, pageSize = 10) {
        let optionsArray = ['Quotation', 'PO', 'Invoice', 'PaymentInit', 'DigitalReceipt']
        let step = 0
        // if (participant) {
        //     participant = participant.toString();
        // }
        if (options && options.state) {
            step = Number(options.state)
        }
        // let filter = "";
        // if (options && options.type) {
        //     filter = options.type === "sales" ? "provider" : "customer";
        //     queryFilter[`${filter}.identifier`] = participant;
        // }
        let queryOptions = {
            query: gql`
            {
                getAllDocuments(startIndex:${startIndex},pageSize:${pageSize},documentType:${optionsArray[step]}){
                    result
                }
            }
            `
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let receiptResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            return graphQLResult.data.result;
        })

    }

    //isClosed:0 - false
    //isClosed:1 - true
    getAllReceiptsByStep(from, to, step, type, isClosed, startIndex = 0, pageSize = 10) {
        let optionsArray = ['Quotation', 'PO', 'Invoice', 'PaymentInit', 'DigitalReceipt']
        let options = {
            query: gql`
            {
                getAllDocuments(startIndex:${startIndex},pageSize:${pageSize},documentType:${optionsArray[step]}){
                    result
                }
            }
            `
        }
        let receiptResult = {
            receiptIds: [],
            count: 0
        }
        let receiptIds = []
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            let promiseArray = []
            receiptIds = graphQLResult.data.result;
            for (let index in receiptIds) {
                let subOptions = {
                    query: gql`
                        {
                            getDocumentsInfo(id:${receiptIds[index]}){
                                document{
                                    isClosed
                                }
                            }
                        }
                     `
                }
                promiseArray.push(this.apolloClientConnector.query(subOptions))
            }
            return Promise.all(promiseArray).then(res => {
                for (let index in res) {
                    if (res[index] && res[index].data && res[index].data.document.isClosed === isClosed) {
                        receiptResult.receiptIds.push(receiptIds[index])
                    }
                }
                receiptResult.count = receiptResult.receiptIds.length
                return receiptResult
            })
        })
    }
    getMissingNonce = (paramID) => {
        let options = {
            query: gql`
            query getMissingNonce($paramID: String!){
                getMissingNonce(paramID: $paramID){
                    ParamID,
                    NonceJSON
                }
            }`,
            variables: { paramID }
        }
        return this.apolloClientConnector.query(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.getMissingNonce
        })
    }
    getAllReceiptsByParent(parentDocId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${parentDocId}"){
                jsonLD
            }
            `
        }
        let receiptResult = {
            receipts: [],
            count: 0
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
            receiptResult.receipts = receiptJson.childDocId
            receiptResult.count = receiptResult.receipts.length
            return receiptResult;
        })
    }

    getAllReceiptsByParentV1(parentDocId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${parentDocId}"){
                jsonLD
            }
            `
        }
        let receiptResult = {
            receipts: [],
            count: 0
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
            receiptResult.receipts = receiptJson.childDocInfos
            receiptResult.count = receiptResult.receipts.length
            return receiptResult;
        })

    }

    getAllReceiptsByParentAndSubType(parentDocId, subType) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${parentDocId}"){
                jsonLD
            }
            `
        }
        let receiptResult = {
            receipts: [],
            count: 0
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
            for (let index in receiptJson.childDocInfos) {
                if (receiptJson.childDocInfos[index].subType === subType) {
                    receiptResult.receipts.push(receiptJson.childDocInfos[index].id)
                }
            }
            receiptResult.count = receiptResult.receipts.length
            return receiptResult;
        })
    }

    getAllCurrencies() {
        return {
            "currencies": [
                "INR"
            ],
            "count": 1
        }
    }

    getParentDocId(childDocId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${childDocId}"){
                pID
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = null
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            return graphQLResult.data.getReceipt.pID;
        })
    }

    getInvoiceFromPoAndInvoiceInternalId(poId, invoiceInternalId) {

    }

    getParentDocInternalId(childDocId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${childDocId}"){
                pID
            }
            `
        }
        let receiptResult = null
        return this.getParentDocId(childDocId).then(parentDocId => {
            if (!parentDocId)
                return receiptResult
            return this.apolloClientConnector.query(options).then(graphQLResult => {
                if (!graphQLResult || !graphQLResult.data) {
                    return receiptResult
                }
                let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
                //check internalID
                return receiptJson.docInternalId;
            })
        })
    }

    getSellerAndBuyer(receiptId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                buyerID,
                sellerID,
                receiptID
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            receiptResult['_id'] = receiptId
            receiptResult['seller'] = graphQLResult.data.getReceipt['sellerID']
            receiptResult['buyer'] = graphQLResult.data.getReceipt['buyerID']
            return receiptResult
        })
    }

    /**
     * 
     * @param {
        return this.mongoDBConnector.invoke('receipts', "receipts", participant, options, fromDate, toDate);
     * *} receiptId 
     * @param {
        return this.mongoDBConnector.invoke('receipts', "receipts", participant, options, fromDate, toDate);
     * *} predicates Array -> possible values include :
     *                          status
     *                          providerName
     *                          customerName
     *                          amount
     *                          currency
     *                          date
     *                          isTemplateCompleted
     *                          docInternalId
     *                          docStep
     *                          additionalProperty
     *                          parentDocInternalId
     *                          step
     *                          disputeInfo
     *                          @subType
     *                          customerParamId
     *                          providerParamId
     *                          paymentTerms
     *                          transactionType
     */
    getSummary(receiptId, predicates) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                step,
                pID,
                jsonLD,
                status,
                txnMode
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            receiptResult['@id'] = receiptId
            receiptResult['status'] = graphQLResult.data.getReceipt['status']
            receiptResult['step'] = graphQLResult.data.getReceipt['step']
            receiptResult['parentDocId'] = graphQLResult.data.getReceipt['pID']
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])

            receiptResult['additionalProperty'] = receiptJson['additionalProperty']
            receiptResult['customerName'] = receiptJson['customer']['name']
            receiptResult['providerName'] = receiptJson['provider']['name']
            receiptResult['amount'] = receiptJson['totalPaymentDue']['price']
            receiptResult['currency'] = receiptJson['totalPaymentDue']['currency']
            receiptResult['date'] = receiptJson['referencesOrder']['orderDate']
            //parentDocInternalId, docInternalId
            return receiptResult;
        })
    }

    getSummaryInBulk(receiptIds, predicates) {
        // return [{ "_id": "0x40d04866f4f9ca51ceb71a47faf673759fb4f767328ba2993815bd1eba4ff57f-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "additionalProperty": "0", "status": "1", "receiptId": "0x40d04866f4f9ca51ceb71a47faf673759fb4f767328ba2993815bd1eba4ff57f-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "customerName": "vikram", "amount": 90, "currency": "INR", "date": 1603789294000, "docInternalId": "27/C2", "parentDocInternalId": "", "parentDocId": "" },
        // { "_id": "0x4d8fc1b918668fbf38d122806c795d2be689c166e9c9ce3a9ca730cf223497d2-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "additionalProperty": "0", "status": "1", "receiptId": "0x4d8fc1b918668fbf38d122806c795d2be689c166e9c9ce3a9ca730cf223497d2-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "customerName": "vikram", "amount": 1615, "currency": "INR", "date": 1603789639000, "docInternalId": "27/C3", "parentDocInternalId": "", "parentDocId": "" },
        // { "_id": "0x654ab7b1dd5010cbbe5b50506ccaccee2efdd9ebe37582993ac63dbf98f9468a-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "additionalProperty": "0", "status": "1", "receiptId": "0x654ab7b1dd5010cbbe5b50506ccaccee2efdd9ebe37582993ac63dbf98f9468a-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "customerName": "vikram", "amount": 1080, "currency": "INR", "date": 1603796320000, "docInternalId": "27/C9", "parentDocInternalId": "", "parentDocId": "" },
        // { "_id": "0x74d89349141e3d0630d87712d96c70cf245979bd6f82a98f1897f36bb5cdfbe5-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "additionalProperty": "0", "status": "1", "receiptId": "0x74d89349141e3d0630d87712d96c70cf245979bd6f82a98f1897f36bb5cdfbe5-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "customerName": "Vidhya", "amount": 92, "currency": "INR", "date": 1604500407000, "docInternalId": "4/EVAL1", "parentDocInternalId": "", "parentDocId": "" },
        // { "_id": "0xbae0517efd51d1736e843542319893a2713da77722dc7a2c9baa9b326de033e1-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "additionalProperty": "0", "status": "1", "receiptId": "0xbae0517efd51d1736e843542319893a2713da77722dc7a2c9baa9b326de033e1-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "customerName": "vikram", "amount": 270, "currency": "INR", "date": 1603786888000, "docInternalId": "27/C1/EVAL", "parentDocInternalId": "", "parentDocId": "" },
        // { "_id": "0xe8dede6b5bec72398e6bb6a1aea7a6881d99b98be2457509441f29f438bdc12b-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "additionalProperty": "0", "status": "0", "receiptId": "0xe8dede6b5bec72398e6bb6a1aea7a6881d99b98be2457509441f29f438bdc12b-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", "customerName": "vikram", "amount": 200000, "currency": "INR", "date": 1603787754000, "docInternalId": "27/C1", "parentDocInternalId": "", "parentDocId": "" }]
        let promiseArray = []
        for (let index in receiptIds) {
            promiseArray.push(this.getSummary(receiptIds[index]), predicates)
        }
        return Promise.all(promiseArray)
    }

    getAllItemsSummaryForReceipt(receiptId) {
        // return [{ "orderItemNumber": "0x7fb988c009c67944403737175580257379df32970e1a9e5f8b84b60755b47e1c-0xa5f126CFF0835db28DecdDb833157B19f53e733d", "orderQuantity": "5", "price": 18 }]
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                jsonLD
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
            let referencesOrder = receiptJson.referencesOrder
            for (let index in referencesOrder.orderedItem) {
                receiptResult.push({
                    orderItemNumber: referencesOrder.orderedItem[index].orderItemNumber,
                    orderQuantity: referencesOrder.orderedItem[index].orderQuantity,
                    price: referencesOrder.orderedItem[index].orderedItem.offers.price
                })
            }
            return receiptResult;
        })
    }

    getValidationData(docId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${docId}"){
                status,
                jsonLD
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            let status = graphQLResult.data.getReceipt['status'];
            if (status === "2" || status === "4" || status === "6" || status === "9" || status === "11")
                return receiptResult;
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
            return receiptJson.referencesOrder
        })

    }

    getItemQuantitySummary(receiptId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                jsonLD
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
            let referencesOrder = receiptJson.referencesOrder
            for (let index in referencesOrder.orderedItem) {
                receiptResult[referencesOrder.orderedItem[index].orderItemNumber] = referencesOrder.orderedItem[index].orderQuantity
            }
            return receiptResult
        })
    }

    getReceiptInternalId(receiptId) {
        //getDocumentsInfo not returning docInternalId
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                jsonLD
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return null
            }
            let receiptJson = JSON.parse(graphQLResult.data.getReceipt['jsonLD'])
            //check internalId
            return receiptJson.docInternalId
        })
    }

    getReceiptInfoByNode(receiptId, node) {
        //review
        return "0"
    }

    isDocumentRejected(receiptId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                status
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let res = false
            if (!graphQLResult || !graphQLResult.data) {
                return res
            }
            let status = graphQLResult.data.getReceipt['status'];
            if ((status === "2" || status === "4" || status === "6" || status === "9" || status === "11")) {
                return true
            }
            return res;
        })
    }

    getRootReceiptId(receiptId) {
        // return "0xccb39c69627d5af22d091d9a31b63cd73e11151a53faaaa9c5296101d62e2eab-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815"
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                pID
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return receiptId
            }
            if (!graphQLResult.data.getReceipt['pID'] || graphQLResult.data.getReceipt['pID'].includes("0x0000000000000000000000000000000000000000000000000000000000000000")) {
                return receiptId;
            }
            return this.getRootReceiptId(graphQLResult.data.getReceipt['pID'])
        })
    }


    getReceipts(receiptId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                step,
                pID,
                jsonLD,
                status,
                txnMode,
                buyerID,
                sellerID,
                isMandatory,
                blockNumber,
                receiptID,
                grnManager,
                templateConsIds,
                owner
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            receiptResult = graphQLResult.data.getReceipt['jsonLD']
            receiptResult["status"] = graphQLResult.data.getReceipt['status']
            receiptResult["step"] = graphQLResult.data.getReceipt['step']
            receiptResult['@id'] = receiptId
            return receiptResult;
        })

    }

    getReceiptsByItem(itemId, step, filter, filterType) {

    }

    getReceiptMetaData(receiptId) {
        // return {
        //     "_id": "0x2d0228b69e219c1d13b2cca4a48acb50778a1092525e069091c738c194334b48-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815",
        //     "transactionType": "2",
        //     "seller": "0x5a3cb59a42eF91F7784d13cA07f3385CaCB3D6c3",
        //     "buyer": "0x96A49902d231F68F86843b7C0B3d4a54E122F429"
        // }
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                step,
                pID,
                status,
                txnMode,
                buyerID,
                sellerID
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return receiptResult
            }
            receiptResult['_id'] = receiptId
            receiptResult['seller'] = graphQLResult.data.getReceipt['sellerID']
            receiptResult['buyer'] = graphQLResult.data.getReceipt['buyerID']
            receiptResult['transactionType'] = graphQLResult.data.getReceipt['txnMode']
            return receiptResult;
        })


    }

    doesExist(receiptId) {
        let options = {
            query: gql`
            getReceipt(receiptID:"${receiptId}"){
                step,
                status
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data || !graphQLResult.data.document) {
                return false
            }
            receiptResult = graphQLResult.data.getReceipt
            receiptResult['_id'] = receiptId
            return receiptResult;
        })

    }



    //Query

    getReceiptsBySeller(primaryOwner, startIndex = 0, pageSize = 10) {
        let options = {
            query: gql`
            query getReceiptsBySeller($filterOptions: InputFilterOptions!) {
                getReceiptsBySeller(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            receiptResult['receipts'] = graphQLResult.data.getReceiptsBySeller['IDs']
            receiptResult['count'] = graphQLResult.data.getReceiptsBySeller['totalRecords']
            return receiptResult;
        })
    }


    getReceiptsByBuye(primaryOwner, startIndex = 0, pageSize = 10) {
        let options = {
            query: gql`
            query getReceiptsByBuye($filterOptions: InputFilterOptions!) {
                getReceiptsByBuye(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            receiptResult['receipts'] = graphQLResult.data.getReceiptsByBuye['IDs']
            receiptResult['count'] = graphQLResult.data.getReceiptsByBuye['totalRecords']
            return receiptResult;
        })
    }

    getAllReceiptsBySeller(primaryOwner, startIndex = 0, pageSize = 10, totalRecords = []) {
        let options = {
            query: gql`
            query  getAllReceiptsBySeller($filterOptions: InputFilterOptions!){
                getAllReceiptsBySeller(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllReceiptsBySeller['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllReceiptsBySeller['totalRecords']) {
                return totalRecords
            }
            return this.getAllReceiptsBySeller(primaryOwner, startIndex + 1, pageSize, totalRecords);
        })
    }

    getAllReceiptsByBuyer(primaryOwner, startIndex = 0, pageSize = 10, totalRecords = []) {
        let options = {
            query: gql`
            query getAllReceiptsByBuyer($filterOptions: InputFilterOptions!) {
                getAllReceiptsByBuyer(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllReceiptsByBuyer['IDs']);
            if (totalRecords.length === graphQLResult.data.getAllReceiptsByBuyer['totalRecords']) {
                return totalRecords
            }
            return this.getAllReceiptsByBuyer(primaryOwner, startIndex + 1, pageSize, totalRecords);
        })
    }

    getTransactions(receiptId) {
        let options = {
            query: gql`
            query getTransactions($receiptId: String!) {
                getTransactions(receiptID: $receiptId){
                    receiptId,
                    gasPrice,
                    blockNumber,
                    txnID,
                    datetime,
                    Status,
                    Step,
                    note,
                    isNew
                    }
                }
            `,
            variables: { receiptId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getTransactions
        })
    }

    getReceipt(receiptId) {
        let options = {
            query: gql`
            query getReceipt($receiptId: String!) {
                getReceipt(receiptID: $receiptId){
                    step,
                    pID,
                    jsonLD,
                    status,
                    txnMode,
                    buyerID,
                    sellerID,
                    isMandatory,
                    blockNumber,
                    receiptID,
                    grnManager,
                    templateConsIds,
                    owner
                    }
                }
            `,
            variables: { receiptId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            receiptResult = {}
            receiptResult['step'] = graphQLResult.data.getReceipt['step'].toString()
            receiptResult['pId'] = graphQLResult.data.getReceipt['pID']//Utils.getTransactionData(graphQLResult.data.getReceipt['pID']).id //without contractaddress
            receiptResult['status'] = graphQLResult.data.getReceipt['status'].toString();
            receiptResult['txnMode'] = Utils.convertGraphQLTnxType(graphQLResult.data.getReceipt['txnMode']);
            receiptResult['buyerId'] = graphQLResult.data.getReceipt['buyerID']
            receiptResult['sellerId'] = graphQLResult.data.getReceipt['sellerID']
            receiptResult['isMandatory'] = graphQLResult.data.getReceipt['isMandatory']
            receiptResult['blockNumber'] = graphQLResult.data.getReceipt['blockNumber']
            receiptResult['receiptId'] = graphQLResult.data.getReceipt['receiptID']
            receiptResult['grnManager'] = graphQLResult.data.getReceipt['grnManager']
            receiptResult['templateConsIds'] = graphQLResult.data.getReceipt['templateConsIds']
            receiptResult['owner'] = graphQLResult.data.getReceipt['owner']
            receiptResult['jsonLD'] = graphQLResult.data.getReceipt['jsonLD']
            return receiptResult
        })
    }

    getReceiptChild(receiptId) {
        let options = {
            query: gql`
            query getReceipt($receiptId: String!) {
                getReceipt(receiptID: $receiptId){
                    pID,
                    pIDs,
                    }
                }
            `,
            variables: { receiptId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            receiptResult['docMapIds'] = graphQLResult.data.getReceipt['pIDs']
            receiptResult['pId'] = graphQLResult.data.getReceipt['pID']// Utils.getTransactionData(graphQLResult.data.getReceipt['pID']).id
            return receiptResult
        })
    }

    getAllGRNsByDocId(receiptId) {
        let options = {
            query: gql`
            query getAllGRNsByDocID($receiptId: String!) {
                getAllGRNsByDocID(receiptID: $receiptId)
                }
            `,
            variables: { receiptId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            let receiptResult = []
            for (let index in graphQLResult.data.getAllGRNsByDocID) {
                receiptResult.push(Utils.getTransactionData(graphQLResult.data.getAllGRNsByDocID[index]).id)
            }
            return receiptResult
        })
    }

    getDocument(docId) {
        // txnMode,
        let options = {
            query: gql`
            query getDocument($docId: String!) {
                getDocument(docID: $docId){
                    receiptID,
                    docID,
                    jsonLd,
                    txnMode,
                    owner,
                    docType,
                    rInternalID,
                    docStatus
                    }
                }
            `,
            variables: { docId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            receiptResult = {}
            receiptResult['docStatus'] = graphQLResult.data.getDocument['docStatus'].toString()
            receiptResult['rId'] = graphQLResult.data.getDocument['receiptID']
            receiptResult['docId'] = graphQLResult.data.getDocument['docID']
            receiptResult['txnMode'] = Utils.convertGraphQLTnxType(graphQLResult.data.getDocument['txnMode']);
            receiptResult['owner'] = graphQLResult.data.getDocument['owner']
            receiptResult['docType'] = graphQLResult.data.getDocument['docType']
            receiptResult['rInternalID'] = graphQLResult.data.getDocument['rInternalID']
            receiptResult['jsonLd'] = graphQLResult.data.getDocument['jsonLd']
            return receiptResult
        })
    }

    getTransaction(transactionId) {
        let options = {
            query: gql`
            query getTransaction($transactionId: String!){
                getTransaction(transactionID: $transactionId){
                    receiptId,
                    gasPrice,
                    txnID,
                    datetime,
                    Status,
                    Step,
                    note,
                    isNew
                    }
                }
            `,
            variables: { transactionId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getTransaction
        })
    }

    getConsentsForReceipt(receiptId) {
        let options = {
            query: gql`
            query getConsentsForReceipt($receiptId: String!){
                getConsentsForReceipt(receiptID: $receiptId){
                    payload,
                    receiptID,
                    receiptType,
                    subscriber,
                    owner,
                    sID,
                    docContractAddress
                    }
                }
            `,
            variables: { receiptId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getConsentsForReceipt
        })
    }

    getSubscriberReceipts(sender) {
        let options = {
            query: gql`
            query getSubscriberReceipts($sender: String!){
                getSubscriberReceipts(sender: $sender){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { sender }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getSubscriberReceipts.IDs
        })
    }

    isSubscriberExists(receiptId, subscriberAddress) {
        let options = {
            query: gql`
            query isSubscriberExists($receiptId: String!, $subscriberAddress: String!){
                isSubscriberExists(receiptID: $receiptId, subscriberAddress: $subscriberAddress)
                }
            `,
            variables: { receiptId, subscriberAddress }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.isSubscriberExists
        })
    }

    getSubscribers(receiptId) {
        let options = {
            query: gql`
            query getSubscribers($receiptId: String!) {
                getSubscribers(receiptID: $receiptId){
                    payload,
                    receiptID,
                    receiptType,
                    subscriber,
                    owner,
                    sID,
                    docContractAddress
                    }
                }
            `,
            variables: { receiptId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getSubscribers
        })
    }

    getSubscriberDetailsForId(subscriberId, receiptId) {
        //subscriberID
        let options = {
            query: gql`
            query getSubscriberDetailsForID($subscriberId: String!, $receiptId: String!) {
                getSubscriberDetailsForID(subscriberAddress: $subscriberId, receiptID: $receiptId){
                    payload,
                    receiptID,
                    receiptType,
                    subscriber,
                    owner,
                    sID,
                    docContractAddress
                    }
                }
            `,
            variables: { subscriberId, receiptId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let receiptResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            receiptResult = graphQLResult.data.getSubscriberDetailsForID
            return receiptResult;
        })
    }

    //Mutation

    initProposal(buyerId, sellerId, inputObject, note, txnMode, isMandatory) {
        let options;
        options = {
            mutation: gql`
                mutation initProposal($buyerId: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $isMandatory: Boolean!){
                        initProposal(buyerID: $buyerId, sellerID: $sellerId, inputObject: $inputObject, note: $note, txnMode: $txnMode, isMandatory: $isMandatory)
                     }
                    `,
            variables: { buyerId, sellerId, inputObject, note, txnMode, isMandatory }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.initProposal
        })
    }

    initProposalV1(index, buyerId, sellerId, inputObject, note, txnMode, isMandatory) {
        let options;
        options = {
            mutation: gql`
                mutation initProposalV1( $index: String!, $buyerId: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $isMandatory: Boolean!){
                        initProposalV1(index: $index, buyerID: $buyerId, sellerID: $sellerId, inputObject: $inputObject, note: $note, txnMode: $txnMode, isMandatory: $isMandatory)
                     }
                    `,
            variables: { index, buyerId, sellerId, inputObject, note, txnMode, isMandatory }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.initProposalV1
        })
    }

    acceptProposal(index, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation acceptProposal($index: String!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                acceptProposal(index: $index, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.acceptProposal
        });
    }

    rejectProposal(index, note, txnMode,) {
        let options;
        options = {
            mutation: gql`
            mutation rejectProposal($index: String!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                rejectProposal(index: $index, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectProposal
        });
    }

    createPO(proposalId, sellerId, inputObject, note, txnMode, grnManager) {
        let options;
        options = {
            mutation: gql`
            mutation createPO($proposalId: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $grnManager: String!) {
                createPO(proposalID: $proposalId, sellerID: $sellerId, inputObject: $inputObject, note: $note, txnMode: $txnMode, grnManager: $grnManager)            
            }`,
            variables: { proposalId, sellerId, inputObject, note, txnMode, grnManager }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createPO
        });
    }

    createPOV1(index, proposalId, sellerId, inputObject, note, txnMode, grnManager) {
        let options;
        options = {
            mutation: gql`
            mutation createPOV1($index: String!, $proposalId: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $grnManager: String!) {
                createPOV1(index: $index, proposalID: $proposalId, sellerID: $sellerId, inputObject: $inputObject, note: $note, txnMode: $txnMode, grnManager: $grnManager)            
            }`,
            variables: { index, proposalId, sellerId, inputObject, note, txnMode, grnManager }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createPOV1
        });
    }
    createLogisticsPO(proposalId, sellerId, inputObject, note, txnMode, grnManager) {
        let options;
        options = {
            mutation: gql`
            mutation createLogisticsPO($proposalId: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $grnManager: String!) {
                createLogisticsPO(proposalID: $proposalId, sellerID: $sellerId, inputObject: $inputObject, note: $note, txnMode: $txnMode, grnManager: $grnManager)            
            }`,
            variables: { proposalId, sellerId, inputObject, note, txnMode, grnManager }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createLogisticsPO
        });
    }

    rejectPO(index, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation rejectPO($index: String!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                rejectPO(index: $index, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectPO
        });
    }

    sendInvoice(poId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation sendInvoice($poId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                sendInvoice(poID: $poId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { poId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.sendInvoice
        });
    }

    sendInvoiceV1(index, poId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation sendInvoiceV1($index: String!, $poId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                sendInvoiceV1(index: $index, poID: $poId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, poId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.sendInvoiceV1
        });
    }

    rejectInvoice(index, note, txnMode,) {
        let options;
        options = {
            mutation: gql`
            mutation rejectInvoice($index: String!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                rejectInvoice(index: $index, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectInvoice
        });
    }

    createInvoice(buyerId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation createInvoice($buyerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                createInvoice(buyerID: $buyerId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { buyerId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInvoice
        });
    }

    createInvoiceV1(index, buyerId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation createInvoiceV1($index: String!, $buyerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                createInvoiceV1(index: $index, buyerID: $buyerId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, buyerId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInvoiceV1
        });
    }

    makePayment(invoiceId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation makePayment($invoiceId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                makePayment(invoiceID: $invoiceId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { invoiceId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.makePayment
        });
    }

    makePaymentV1(index, invoiceId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation makePaymentV1($index: String!, $invoiceId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                makePaymentV1(index: $index, invoiceID: $invoiceId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, invoiceId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.makePaymentV1
        });
    }

    cancelPayment(index, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation cancelPayment($index: String!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                cancelPayment(index: $index, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.cancelPayment
        });
    }

    createReceipt(index, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation createReceipt($index: String!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                createReceipt(index: $index, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createReceipt
        });
    }

    closePO(poId) {
        let options;
        options = {
            mutation: gql`
            mutation closePO($poId: String!) {
                closePO(poID: $poId)            
            }`,
            variables: { poId }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.closePO
        });
    }

    closeInvoice(invoiceId) {
        let options;
        options = {
            mutation: gql`
            mutation closeInvoice($invoiceId: String) {
                closeInvoice(invoiceID: $invoiceId)            
            }`,
            variables: { invoiceId }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.closeInvoice
        });
    }

    createInwardPO(proposalId, buyerId, inputObject, note, txnMode, grnManager) {
        let options;
        options = {
            mutation: gql`
            mutation createInwardPO($proposalId: String!, $buyerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $grnManager: String!) {
                createInwardPO(proposalID: $proposalId, buyerID: $buyerId, inputObject: $inputObject, note: $note, txnMode: $txnMode, grnManager: $grnManager)            
            }`,
            variables: { proposalId, buyerId, inputObject, note, txnMode, grnManager }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInwardPO
        });
    }

    createInwardPOV1(index, proposalId, buyerId, inputObject, note, txnMode, grnManager) {
        let options;
        options = {
            mutation: gql`
            mutation createInwardPOV1($index: String!, $proposalId: String!, $buyerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $grnManager: String!) {
                createInwardPOV1(index: $index, proposalID: $proposalId, buyerID: $buyerId, inputObject: $inputObject, note: $note, txnMode: $txnMode, grnManager: $grnManager)            
            }`,
            variables: { index, proposalId, buyerId, inputObject, note, txnMode, grnManager }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInwardPOV1
        });
    }

    sendInwardInvoice(poId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation sendInwardInvoice($poId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                sendInwardInvoice(poID: $poId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { poId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.sendInwardInvoice
        });
    }

    sendInwardInvoiceV1(index, poId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation sendInwardInvoiceV1($index: String!, $poId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                sendInwardInvoiceV1(index: $index, poID: $poId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, poId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.sendInwardInvoiceV1
        });
    }

    createInwardInvoice(sellerId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation createInwardInvoice($sellerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                createInwardInvoice(sellerID: $sellerId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { sellerId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInwardInvoice
        });
    }

    createInwardInvoiceV1(index, sellerId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation createInwardInvoiceV1($index: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                createInwardInvoiceV1(index: $index, sellerID: $sellerId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, sellerId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInwardInvoiceV1
        });
    }

    makeInwardPayment(invoiceId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation makeInwardPayment($invoiceId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                makeInwardPayment(invoiceID: $invoiceId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { invoiceId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.makeInwardPayment
        });
    }

    makeInwardPaymentV1(index, invoiceId, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation makeInwardPaymentV1($index: String!, $invoiceId: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                makeInwardPaymentV1(index: $index, invoiceID: $invoiceId, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, invoiceId, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.makeInwardPaymentV1
        });
    }

    createInwardReceipt(index, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation createInwardReceipt($index: String!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                createInwardReceipt(index: $index, note: $note, txnMode: $txnMode)            
            }`,
            variables: { index, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInwardReceipt
        });
    }

    updateInitProposal(index, inputObject, note, txnMode, isMandatory) {
        let options;
        options = {
            mutation: gql`
            mutation updateInitProposal($index: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!, $isMandatory: Boolean!) {
                updateInitProposal(index: $index, inputObject: $inputObject, note: $note, txnMode: $txnMode, isMandatory: $isMandatory)            
            }`,
            variables: { index, inputObject, note, txnMode, isMandatory }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateInitProposal
        });
    }

    updateReceipt(functionName, index, inputObject, note, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation updateReceipt($functionName: String!, $index: String!, $inputObject: InputDocumentInfo!, $note: String, $txnMode: TRANSACTION_TYPE!) {
                updateReceipt(functionName: $functionName, index: $index, inputObject: $inputObject, note: $note, txnMode: $txnMode)            
            }`,
            variables: { functionName, index, inputObject, note, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateReceipt
        });
    }
    //addSubscriberForGRN( payload: String! receiptId: String! receiptTxnType: TRANSACTION_TYPE! subscriberAddress: String! docContractAddress: String! seller: String! buyer: String! )
    addSubscriberForGRN(payload, receiptId, receiptTxnType, subscriberAddress, docContractAddress, seller, buyer) {
        let options;
        options = {
            mutation: gql`
            mutation addSubscriberForGRN($payload: String!, $receiptId: String!, $receiptTxnType: TRANSACTION_TYPE!, $subscriberAddress: String!, $docContractAddress: String!, $seller: String!, $buyer: String!) {
               addSubscriberForGRN(payload: $payload, receiptId: $receiptId, receiptTxnType: $receiptTxnType, subscriberAddress: $subscriberAddress, docContractAddress: $docContractAddress, seller: $seller, buyer: $buyer)        
            }`,
            variables: { payload, receiptId, receiptTxnType, subscriberAddress, docContractAddress, seller, buyer }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSubscriberForGRN
        });
    }

    assignTemplate(templateConsId, receiptId, contractAddress, step) {
        let options;
        // options = {
        //     mutation:gql`
        //     mutation assignTemplate(){
        //         assignTemplate()
        //     }`,
        //     variables:{}
        // }
        // return this.apolloClientConnector.mutate(options).then(res => {
        //     if (!res.data) {
        //         throw new Error(res.errors[0].message)
        //     }
        //     return res.data.assignTemplate
        // });
    }

    // subsccriber related

    addSellerConsent(receiptId, receiptTxnType, docContractAddress) {
        let queryOptions = {
            mutation: gql`
                mutation addSellerConsent($receiptId: String!, $receiptTxnType: TRANSACTION_TYPE!, $docContractAddress: String!){
                    addSellerConsent(receiptId: $receiptId, receiptTxnType: $receiptTxnType, docContractAddress: $docContractAddress)
                }
                `,
            variables: { receiptId, receiptTxnType, docContractAddress }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSellerConsent
        })
    }

    addBuyerConsent(receiptId, receiptTxnType, docContractAddress) {
        let queryOptions = {
            mutation: gql`
                mutation addBuyerConsent($receiptId: String!, $receiptTxnType: TRANSACTION_TYPE!, $docContractAddress: String!){
                    addBuyerConsent(receiptId: $receiptId, receiptTxnType: $receiptTxnType, docContractAddress: $docContractAddress)
                }
                `,
            variables: { receiptId, receiptTxnType, docContractAddress }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addBuyerConsent
        })
    }

    addSubscriberForCons(payload, receiptId, receiptTxnType, subscriberAddress, docContractAddress, seller, buyer) {
        let options;
        options = {
            mutation: gql`
            mutation addSubscriberForCons($payload: String!,$receiptId: String!,$receiptTxnType: TRANSACTION_TYPE!,$subscriberAddress: String!,$docContractAddress: String!,$seller: String!,$buyer: String!) {
                addSubscriberForCons(payload:$payload,receiptId:$receiptId,receiptTxnType:$receiptTxnType,subscriberAddress:$subscriberAddress,docContractAddress:$docContractAddress, seller:$seller,buyer:$buyer)            
            }`,
            variables: { payload, receiptId, receiptTxnType, subscriberAddress, docContractAddress, seller, buyer }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSubscriberForCons
        });
    }

    addSubscriber(payload, receiptId, receiptTxnType, subscriber, docContractAddress) {
        let queryOptions = {
            mutation: gql`
                mutation addSubscriber($payload:String!, $receiptId: String!, $receiptTxnType: TRANSACTION_TYPE!,$subscriber: String!, $docContractAddress: String!){
                    addSubscriber(payload:$payload, receiptId: $receiptId, receiptTxnType: $receiptTxnType, subscriber:$subscriber, docContractAddress: $docContractAddress)
                }
                `,
            variables: { payload, receiptId, receiptTxnType, subscriber, docContractAddress }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSubscriber
        })
    }
    addeLR(toID, inputObject, txnMode) {
        let queryOptions = {
            mutation: gql`
                mutation addeLR($toID:String!, $inputObject:InputDocumentInfo!, $txnMode:TRANSACTION_TYPE!){
                    addeLR(toID:$toID, inputObject:$inputObject, txnMode:$txnMode)
                }
                `,
            variables: { toID, inputObject, txnMode }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addeLR
        })
    }

    attachAN(docId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation attachAN($docId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                attachAN(docID: $docId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { docId, inputObject, txnMode }

        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachAN
        });
    }

    addCreditNote(poId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation addCreditNote($poId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                addCreditNote(poID: $poId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { poId, inputObject, txnMode }

        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addCreditNote
        });
    }

    attachANV1(index, docId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation attachANV1($index: String!, $docId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                attachANV1(index: $index, docID: $docId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { index, docId, inputObject, txnMode }

        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachANV1
        });
    }

    updateAN(grnId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation updateAN($grnId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                updateAN(grnID: $grnId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { grnId, inputObject, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateAN
        });
    }

    attachPartPO(docId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation attachPartPO($docId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                attachPartPO(poID: $docId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { docId, inputObject, txnMode }

        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachPartPO
        });
    }

    attachPartPOV1(index, docId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation attachPartPOV1($index: String!, $docId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                attachPartPOV1(index: $index, poID: $docId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { index, docId, inputObject, txnMode }

        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachPartPOV1
        });
    }

    updatePartPO(grnId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation updatePartPO($grnId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                updatePartPO(grnID: $grnId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { grnId, inputObject, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updatePartPO
        });
    }
}
export default Receipts;
