import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Reports = ({ match }) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/default`} />
        <Route
            path={`${match.url}/default`}
            component={asyncComponent(() => import("./Default/index"))}
        />
        <Route
            path={`${match.url}/sales`}
            component={asyncComponent(() => import("./Reconciliation/index"))}
        />
        <Route
            path={`${match.url}/purchases`}
            component={asyncComponent(() => import("./Reconciliation/index"))}
        />

    </Switch>
);

export default Reports;
