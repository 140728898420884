import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';

class EmailManager {

    constructor(_paramNetwork, contractAddress) {

        this.connection = _paramNetwork.getConnection();
        const emailManager = require('./param-email.json')
        // this.emailManagerContract = new this.connection.eth.Contract(emailManager.abi, contractAddress);
        this.emailManagerContract = Web3_1_0.getContract(this.connection, emailManager.abi, contractAddress ? contractAddress : emailManager.address);
        this.to = contractAddress;
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        if (options) {
            options = { owner: options.address };
        }

        this.watchEmailSendEvents(options);
    }

    watchEmailSendEvents(options) {

        if (typeof this.emailSendEvent === 'undefined' || !this.emailSendEvent) {
            let filter = {
                filter: options
            };
            // this.emailSendEvent = this.emailManagerContract.events.onEmailSend(filter);
            this.emailSendEvent = Web3_1_0.getEvent(this, this.emailManagerContract, "onEmailSend", filter);
        }

        let that = this;
        this.emailSendEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event)
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onEmailSend", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onEmailSend", error);
                that.emailSendEvent.unsubscribe();
                that.emailSendEvent = undefined;
                that.watchEmailSendEvents(options);
            });
    }

    sendMail(emailType, jsonLd, metaData, options) {
        return Web3_1_0.send(this, this.emailManagerContract, "sendMail", options, emailType, jsonLd, metaData);
    }

    changeOwner(paramId, options) {
        return Web3_1_0.send(this, this.emailManagerContract, "changeOwner", options, paramId);
    }

    getEmail(emailId) {
        return Web3_1_0.call(this, this.emailManagerContract, "getEmail", null, emailId);
    }

    getOwner() {
        return Web3_1_0.call(this, this.emailManagerContract, "getOwner", null);
    }

    registerOnEmailSend(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        this.events.addListener("onEmailSend", callback);
    }

    unRegisterOnEmailSend(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onEmailSend", callback);
    }
}

export default EmailManager;