import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import { InventoryEvents } from '../utils/event-names';
import Web3Utils from 'web3-utils';
import BlockInfo from '../utils/block-info';
import Web3 from 'web3.0.20.0/lib/web3';

/**
 * InventoryManager is an implementation of decentralized inventory storage, using this class user can able to save inventory into ParamNetwork. 
 */
class InventoryManager {

    /**
     * Default constructor for initialising or establishing the inventorys.
     * @param {ParamNetwork} paramNetwork Object
     * 
     */
    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const catManager = require('./inventory-manager.json')
        this.inventoryManagerContract = Web3_1_0.getContract(this.connection, catManager.abi, contractAddress ? contractAddress : catManager.address);
        this.to = contractAddress;
    }

    /**
     * initEvents is a event listener used for listening to the real time changes whenever a inventory is added or updated.
     * @param {JSON} options - {"address":"0x"}
     * @example 
     * Usage:
     *  inventoryManager
     *   .initEvents({address:'0x'})
     *   .then((result)=>{
     *       //TODO 
     *   }) 
     */

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }
        let receiver, buyer, seller;
        if (options) {
            let address = Web3Utils.toChecksumAddress(options.address)
            options = { owner: address };
            buyer = { buyerId: address };
            seller = { sellerId: address };
        }

        this.watchAddInventoryEvents(options);
        this.watchUpdateInventoryEvent(options);
        this.watchUpdateInventoryHistoryEvent(options);
        this.watchInventoryUpdatedEventForSubscriber(options);
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        }).catch(err => {
            console.error("error in emitting event", err)
        })
    }

    watchAddInventoryEvents(options) {

        if (typeof this.inventoryAddEvent === 'undefined' || !this.inventoryAddEvent) {
            let filter = {
                filter: options
            };
            this.inventoryAddEvent = Web3_1_0.getEvent(this, this.inventoryManagerContract, "onInventoryAdd", filter);
        }
        let that = this;
        this.inventoryAddEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onInventoryAdd", event);
            }
            // that.emitEvent("onInventoryAdd", event);
        })
            .on('error', function (error) {
                that.events.emit("onInventoryAdd", error);
                that.inventoryAddEvent.unsubscribe();
                that.inventoryAddEvent = undefined;
                that.watchAddInventoryEvents(options);
            });
    }

    watchUpdateInventoryEvent(options) {
        if (typeof this.inventoryUpdateEvent === 'undefined' || !this.inventoryUpdateEvent) {
            let filter = {
                filter: options
            };
            // this.inventoryUpdateEvent = this.inventoryManagerContract.events.onInventoryUpdateV1(filter);
            this.inventoryUpdateEvent = Web3_1_0.getEvent(this, this.inventoryManagerContract, "onInventoryUpdateV1", filter);
        }
        let that = this;
        this.inventoryUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onInventoryUpdateV1", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onInventoryUpdateV1", error);
                that.inventoryUpdateEvent.unsubscribe();
                that.inventoryUpdateEvent = undefined;
                that.watchUpdateInventoryEvent(options)
            });

    }

    watchUpdateInventoryHistoryEvent(options) {
        if (typeof this.inventoryHistoryUpdateEvent === 'undefined' || !this.inventoryHistoryUpdateEvent) {
            let filter = {
                filter: options
            };
            // this.inventoryHistoryUpdateEvent = this.inventoryManagerContract.events.onInventoryUpdateV1(filter);
            this.inventoryHistoryUpdateEvent = Web3_1_0.getEvent(this, this.inventoryManagerContract, "onInventoryHistoryUpdateV1", filter);
        }
        let that = this;
        this.inventoryHistoryUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onInventoryHistoryUpdateV1", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onInventoryHistoryUpdateV1", error);
                that.inventoryHistoryUpdateEvent.unsubscribe();
                that.inventoryHistoryUpdateEvent = undefined;
                that.watchUpdateInventoryHistoryEvent(options)
            });

    }

    watchInventoryUpdatedEventForSubscriber(options) {
        if (typeof this.inventoryUpdateForSubscriberEvent === 'undefined' || !this.inventoryUpdateForSubscriberEvent) {
            this.inventoryUpdateForSubscriberEvent = Web3_1_0.getEvent(this, this.inventoryManagerContract, "onInventoryUpdateForSubscriber");
        }
        let that = this;
        this.inventoryUpdateForSubscriberEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            for (let index = 0; index < transInfo.subscribers.length; index++) {
                const element = transInfo.subscribers[index];
                if (options.owner.toLowerCase() === element.toLowerCase()) {
                    that.emitEvent("onInventoryUpdateForSubscriber", event);
                }
            }
        })
            .on('error', function (error) {
                that.events.emit("onInventoryUpdateForSubscriber", error);
                that.inventoryUpdateForSubscriberEvent.unsubscribe();
                that.inventoryUpdateForSubscriberEvent = undefined;
                that.watchInventoryUpdatedEventForSubscriber(options)
            });
    }

    addInventory(catalogueId, inventoryData, inventoryKey, inventoryTxnMode, catalogueContractAddress, metaData, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "addInventory", options, catalogueId, inventoryData, inventoryKey, inventoryTxnMode, catalogueContractAddress, metaData);
    }

    addInventorySync(catalogueId, inventoryData, inventoryKey, inventoryTxnMode, catalogueContractAddress, metaData, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "addInventory", options, catalogueId, inventoryData, inventoryKey, inventoryTxnMode, catalogueContractAddress, metaData).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[1];
        })
    }

    updateInventory(id, inventoryData, catalogueContractAddress, metaData, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "updateInventory", options, id, inventoryData, catalogueContractAddress, metaData);
    }

    updateInventorySync(id, inventoryData, catalogueContractAddress, metaData, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "updateInventory", options, id, inventoryData, catalogueContractAddress, metaData).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            return id;
        })
    }

    updateInventoryHistory(inventoryId, historyIndex, metaData, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "updateInventoryHistory", options, inventoryId, historyIndex, metaData);
    }

    updateInventoryHistorySync(inventoryId, historyIndex, metaData, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "updateInventoryHistory", options, inventoryId, historyIndex, metaData).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            return inventoryId;
        })
    }

    addSubscriberForInventory(cataloguesBytesId, subscriber, shareKey, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "addSubscriberForInventory", options, cataloguesBytesId, subscriber, shareKey);
    }

    addSubscriberForInventorySync(cataloguesBytesId, subscriber, shareKey, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "addSubscriberForInventory", options, cataloguesBytesId, subscriber, shareKey).then((txHash) => {
            return BlockInfo.getTransactionInfo(this.connection, txHash);
        }).then((logs) => {
            return cataloguesBytesId;
        })
    }

    setContractInfoId(contractId, options) {
        return Web3_1_0.send(this, this.inventoryManagerContract, "setContractInfoId", options, contractId);
    }

    getInventoryByCatalogueId(index) {
        return Web3_1_0.call(this, this.inventoryManagerContract, "getInventoryByCatalogueId", null, index);
    }

    getInventoryByInventoryId(index) {
        return Web3_1_0.call(this, this.inventoryManagerContract, "getInventoryByInventoryId", null, index);
    }

    getInventoryUpdateEventsByCatalogueId(catalogueId) {
        let events = Object.keys(InventoryEvents);
        let promiseArray = [];
        let filter = {
            filter: {
                "catalogueId": catalogueId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.inventoryManagerContract, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection);
    }

    getInventoryHistoryUpdateEvents(inventoryId) {
        let promiseArray = [];
        let filter = {
            filter: {
                "inventoryId": inventoryId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        let promise = Web3_1_0.getPastEvents(this, this.inventoryManagerContract, "onInventoryHistoryUpdateV1", filter);
        promiseArray.push(promise);
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection);
    }

    registerOnInventoryAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onInventoryAdd"]) {
            this.events.removeAllListeners("onInventoryAdd");
        }
        this.events.addListener("onInventoryAdd", callback);
    }

    unRegisterOnInventoryAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onInventoryAdd", callback);
    }

    registerOnInventoryUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onInventoryUpdateV1"]) {
            this.events.removeAllListeners("onInventoryUpdateV1");
        }
        this.events.addListener("onInventoryUpdateV1", callback);
    }

    unRegisterOnInventoryUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onInventoryUpdateV1", callback);
    }

    registerOnInventoryHistoryUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onInventoryHistoryUpdateV1"]) {
            this.events.removeAllListeners("onInventoryHistoryUpdateV1");
        }
        this.events.addListener("onInventoryHistoryUpdateV1", callback);
    }

    unRegisterOnInventoryHistoryUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onInventoryHistoryUpdateV1", callback);
    }

    registerOnInventoryUpdateForSubscriber(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onInventoryUpdateForSubscriber"]) {
            this.events.removeAllListeners("onInventoryUpdateForSubscriber");
        }
        this.events.addListener("onInventoryUpdateForSubscriber", callback);
    }

    unRegisterOnInventoryUpdateForSubscriber(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onInventoryUpdateForSubscriber", callback);
    }
}
export default InventoryManager;