import React from 'react';
import { withRouter } from 'react-router-dom';
import { message, Card, Tabs, List, Icon, Row, Col, Avatar, Tag } from 'antd';
import moment from 'moment';
import Highlighter from "react-highlight-words";
import BaseComponent from "../BaseComponent";
import IconComponent from "../../../components/IconComponent";
import NetworkBridge from '../../../util/network-bridge'
import * as Utils from '../../../util/utils';
import Console from '../../../logger/index';
import { ReactComponent as item } from '../../../assets/images/itemSvg.svg';
import { ReactComponent as contact } from '../../../assets/images/contactSvg.svg';
import { ReactComponent as workflow } from '../../../assets/images/workflow.svg';
import Analytics from '../../../analytics';
import './index.less';
const { TabPane } = Tabs;

class Search extends BaseComponent {

    constructor(props) {
        super(props);
        Analytics.getInstance().trackPageview();
    }
    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }
    initStates() {
        this.state = {
            showLoader: true,
            activeTab: 'receipts',
            receipts: [],
            acceptNotes: [],
            contacts: [],
            items: [],
            workflow: [],
            tabHeaders: [],
            flag: 0
        };
    }

    componentDidMount() {
        super.componentDidMount();
        let searchProp = this.props.location.search;
        let query = searchProp.split('?query=')[1]
        let searchType = query.split('&')[1];
        let searchText = query.split('&')[0]
        if (searchType !== "") {
            searchType = searchType.split(',')
        } else {
            searchType = ['AcceptNotes', 'Receipts', 'Contacts', 'Items', 'Tasks']
        }
        this.fetchSearchResult(searchText, searchType)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let searchProp = nextProps.location.search;
        let query = searchProp.split('?query=')[1]
        let nextSearchText = query.split('&')[0];
        let nextSearchType = query.split('&')[1];
        let flag = prevState.flag;
        if (nextSearchText !== prevState.searchText) {
            return { searchText: nextSearchText, flag: ++flag };
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        let searchType, prevSearchType, searchText, prevSearchText;
        let searchProp = this.props.location.search;
        let query = searchProp.split('?query=')[1]
        searchText = query.split('&')[0];
        searchType = query.split('&')[1];
        let prevSearchProp = prevProps.location.search;
        let prevQuery = prevSearchProp.split('?query=')[1]
        prevSearchText = prevQuery.split('&')[0];
        prevSearchType = prevQuery.split('&')[1];
        if (searchType !== "") {
            searchType = searchType.split(',')
        }
        if (prevSearchType !== "") {
            prevSearchType = prevSearchType.split(',')
        }

        if (searchText === prevSearchText) {
            if (searchType.length !== prevSearchType.length) {
                this.fetchSearchResult(searchText, searchType);
            }
        } else {
            this.fetchSearchResult(searchText, searchType);
        }
    }

    fetchSearchResult = (searchText, type) => {
        if (!type) {
            type = ['AcceptNotes', 'Receipts', 'Contacts', 'Items', 'Tasks']
        }
        let searchManager = NetworkBridge.getSearchManager();
        let owner = Utils.getParamId();
        searchManager.getSearchResult(searchText, owner, type).then(res => {
            Console.debug('search res', res)
            if (res) {
                let keys = Object.keys(res)
                if (keys.length > 1) {
                    keys.push('All')
                }
                this.setState({ tabHeaders: keys })
                this.formatSearchData(res)
            } else {
                message.info(`No result found for query ${searchText}`)
            }
        }).catch(err => {
            Console.error(err)
            message.error('Failed to fetch Search result')
        }).finally(() => {
            this.setState({ showLoader: false })
        })
    }

    formatSearchData = (data) => {
        this.setState({ showLoader: true })
        let acceptNoteResult = [], receiptResult = [], contactResult = [], itemResult = [], workflowResult = [];
        let promiseArray = [];
        let promise = null;

        for (let index in data) {
            if (data[index].length > 0) {
                for (let jIndex in data[index]) {
                    if (data[index][jIndex].type === 'AcceptNotes') {
                        acceptNoteResult.push(data[index][jIndex])
                    } else if (data[index][jIndex].type === 'Receipts') {
                        receiptResult.push(data[index][jIndex])
                    } else if (data[index][jIndex].type === 'Contacts') {
                        contactResult.push(data[index][jIndex])
                    } else if (data[index][jIndex].type === 'Items') {
                        itemResult.push(data[index][jIndex])
                    } else if (data[index][jIndex].type === 'Tasks') {
                        workflowResult.push(data[index][jIndex])
                    }
                }
            }

            promiseArray.push(promise);
            promise = null;
        }

        return Promise.all(promiseArray).then(result => {
            this.setState({
                acceptNotes: acceptNoteResult,
                receipts: receiptResult,
                contacts: contactResult,
                items: itemResult,
                workflow: workflowResult,
                showLoader: false
            })
        })

    }

    getAcceptNoteSummary = (data) => {
        let AcceptNoteManager = NetworkBridge.getAcceptNoteManager()
        const requiredKeys = [
            'customerName',
            'providerName',
            'providerParamId',
            'customerParamId',
            'step',
            'amount',
            'date',
            'status'
        ]
        return AcceptNoteManager.getSummary(data.searchIndex, requiredKeys).then(receiptDetail => {
            return {
                type: data.type,
                matchKey: Utils.camelCaseToString(data.matchKey),
                matchValue: data.matchValue,
                searchIndex: data.searchIndex,
                customerName: receiptDetail.customerName,
                providerName: receiptDetail.providerName,
                customerParamId: receiptDetail.customerParamId,
                providerParamId: receiptDetail.providerParamId,
                step: receiptDetail.step,
                amount: receiptDetail.amount,
                date: moment.unix(receiptDetail.date / 1000).format("YYYY-MM-DD"),
                status: receiptDetail.status,
                contractNumber: data.contractNumber ? data.contractNumber : undefined,
                purchaseOrderNumber: data.purchaseOrderNumber ? data.purchaseOrderNumber : undefined,
                invoiceNumber: data.invoiceNumber ? data.invoiceNumber : undefined,
            }
        }).catch(e => {
            Console.error(e)
            return null;
        })
    }



    getReceiptSummary = (data) => {
        let receiptManager = NetworkBridge.getReceiptManager()
        const requiredKeys = [
            'customerName',
            'providerName',
            'providerParamId',
            'customerParamId',
            'step',
            'amount',
            'date',
            'status'
        ]
        return receiptManager.getSummary(data.searchIndex, requiredKeys).then(receiptDetail => {
            return {
                type: data.type,
                matchKey: Utils.camelCaseToString(data.matchKey),
                matchValue: data.matchValue,
                searchIndex: data.searchIndex,
                customerName: receiptDetail.customerName,
                providerName: receiptDetail.providerName,
                customerParamId: receiptDetail.customerParamId,
                providerParamId: receiptDetail.providerParamId,
                step: receiptDetail.step,
                amount: receiptDetail.amount,
                date: moment.unix(receiptDetail.date / 1000).format("YYYY-MM-DD"),
                status: receiptDetail.status,
                contractNumber: data.contractNumber ? data.contractNumber : undefined,
                purchaseOrderNumber: data.purchaseOrderNumber ? data.purchaseOrderNumber : undefined,
                invoiceNumber: data.invoiceNumber ? data.invoiceNumber : undefined,
            }
        }).catch(e => {
            Console.error(e)
            return null;
        })
    }

    getContactSummary = (data) => {
        let contactManager = NetworkBridge.getContactManager()
        return contactManager.getContact(data.searchIndex).then(contactDetail => {
            // Console.debug('contactDetail', contactDetail)
            return {
                type: data.type,
                matchKey: Utils.camelCaseToString(data.matchKey),
                matchValue: data.matchValue,
                searchIndex: data.searchIndex,
                name: contactDetail.name,
                identifier: contactDetail.identifier,
                email: contactDetail.email,
                phone: contactDetail.telephone,
                jobTitle: contactDetail.jobTitle,
                hasOccupation: contactDetail.hasOccupation
            }
        }).catch(e => {
            Console.error(e)
            return null;
        })
    }

    getItemSummary = (data) => {
        let itemManager = NetworkBridge.getItemManager()
        const requiredKeys = [
            "name",
            "category",
            "description",
            "primaryOwner",
            "productId"
        ]
        return itemManager.getSummary(data.searchIndex, requiredKeys).then(itemDetail => {
            // Console.debug('itemDetail', itemDetail)
            return {
                type: data.type,
                matchKey: Utils.camelCaseToString(data.matchKey),
                matchValue: data.matchValue,
                searchIndex: data.searchIndex,
                itemName: itemDetail.name,
                itemCategory: itemDetail.category,
                productId: itemDetail.productId,
                owner: itemDetail.primaryOwner,
                description: itemDetail.description
            }

        }).catch(e => {
            Console.error(e)
            return null;
        })
    }

    SearchLinkNavigation = (item) => {
        let type = item.type;
        let id = item.searchIndex;
        let templateConsId = item.templateConsId;
        let encodedId = Utils.encodeString(id)
        if (type === 'AcceptNotes') {
            let receiptSubType = 'logistics'
            const selfParamId = Utils.getParamId();
            let tabName;
            if (selfParamId === item.customerParamId) {
                tabName = "purchases";
            } else if (selfParamId === item.providerParamId) {
                tabName = "sales";
            } else {
                tabName = "subscribed";
            }
            const currentState = item.subType || Utils.getTabNameFromStep(item.step);
            this.props.history.push({
                pathname: `/main/finance/${tabName}/receipt/${encodedId}`,
                search: Utils.serializeURLParameters({
                    currentTab: currentState,
                    subType: receiptSubType
                })
            })

        } else if (type === 'Receipts') {
            const selfParamId = Utils.getParamId();
            let tabName;
            if (selfParamId === item.customerParamId) {
                tabName = "purchases";
            } else if (selfParamId === item.providerParamId) {
                tabName = "sales";
            } else {
                tabName = "subscribed";
            }
            const currentState = item.subType || Utils.getTabNameFromStep(item.step);
            this.props.history.push({
                pathname: `/main/finance/${tabName}/receipt/${encodedId}`,
                search: Utils.serializeURLParameters({
                    currentTab: currentState
                })
            })
        } else if (type === 'Contacts') {
            this.props.history.push({ pathname: `/main/finance/contacts/details/id=${id}` })
        } else if (type === 'Items') {
            this.props.history.push(`/main/finance/items/itemDetails/${encodedId}`)
        } else if (type === 'Tasks') {
            this.props.history.push({ pathname: `/main/operations/stepform/id=${templateConsId}`, state: { stepConsNumber: id } })
        }
    }
    onTabChange = (key) => {
        this.setState({ activeTab: key })
    }

    getSubText = (item) => {
        switch (item.type) {
            case "AcceptNotes":
                const currentStatev1 = item.subType || Utils.getTabNameFromStep(item.step);
                return `${item.providerName} sent ${currentStatev1} ${item.draftInvoiceNumber} to ${item.customerName} worth ${item.amount}`
            case "Receipts":
                const currentState = item.subType || Utils.getTabNameFromStep(item.step);
                return `${item.providerName} sent ${currentState} ${item.invoiceNumber} to ${item.customerName} worth ${item.amount}`
            case "Contacts":
                return `${item.name} - ${item.identifier} - ${item.email}`;
            case "Items":
                return `${item.name}- ${item.productId} - ${item.itemCategory} - ${item.owner}`;
            case "Tasks":
                return `${item.templateName} - ${item.templateDescription}`
            default:
                return "";
        }
    }

    goBack = () => {
        let { flag } = this.state;
        let history = this.props.history;
        if (flag <= 1) {
            history.goBack()
        } else {
            flag = flag - 1;
            history.goBack(-flag)
        }
    }

    renderAcceptNote = (item) => {
        return (
            <List.Item>
                <div className="search__result" >
                    <p className="search__result_title" onClick={() => { this.SearchLinkNavigation(item) }}>
                        <a>
                            <Highlighter
                                highlightClassName="search__result_highlightText"
                                searchWords={[this.state.searchText]}
                                autoEscape={true}
                                textToHighlight={item.draftInvoiceNumber}
                            />
                        </a>
                    </p>
                    <h5>
                        <Highlighter
                            // className="search__result_subText"
                            searchWords={[this.state.searchText]}
                            autoEscape={true}
                            textToHighlight={this.getSubText(item)}
                        />
                    </h5>
                </div>
            </List.Item>
        )
    }

    renderReceipt = (item) => {
        return (
            <List.Item>
                <div className="search__result" >
                    <p className="search__result_title" onClick={() => { this.SearchLinkNavigation(item) }}>
                        <a>
                            <Highlighter
                                highlightClassName="search__result_highlightText"
                                searchWords={[this.state.searchText]}
                                autoEscape={true}
                                textToHighlight={item.invoiceNumber}
                            />
                        </a>
                    </p>
                    <h5>
                        <Highlighter
                            // className="search__result_subText"
                            searchWords={[this.state.searchText]}
                            autoEscape={true}
                            textToHighlight={this.getSubText(item)}
                        />
                    </h5>
                </div>
            </List.Item>
        )
    }


    getTag = (category) => {
        return <Tag style={{ borderRadius: '13px', border: '1px solid #008080', color: '#008080', fontSize: '0.55rem' }}>{category[0].toUpperCase() + category.slice(1)}</Tag>
    }

    getAllTags = hasOccupation => {
        let tags = []
        let newList = [];
        for (let i = 0; i < hasOccupation.length; i++) {
            newList.push(hasOccupation[i])
        }
        for (let index = 0; index < newList.length; index++) {
            tags.push(<Tag style={{ borderRadius: '13px', border: '1px solid #008080', color: '#008080', fontSize: '0.55rem' }}>{newList[index].name}</Tag>);
        }
        return tags;
    }

    renderContacts = (item) => {
        const { name, email, phone, jobTitle, hasOccupation } = item;
        // Console.debug('render conatct', name, email, phone, jobTitle, hasOccupation)

        return (
            <div className="gx-contact-item" style={{ padding: '0.5rem' }} onClick={() => { this.SearchLinkNavigation(item) }}>
                <div className="gx-module-list-icon">
                    <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
                        <Avatar size="large">
                            {name.charAt(0).toUpperCase()}
                        </Avatar>
                    </div>
                </div>
                <div className="gx-module-list-info gx-contact-list-info">
                    <div className="gx-module-contact-content">
                        <p className="gx-mb-1">
                            <span className="gx-text-truncate gx-contact-name"> {name} </span>
                        </p>

                        <div className="gx-text-muted">
                            <span className="gx-text-truncate gx-job-title">
                                {jobTitle ? jobTitle.name : "-"}
                            </span>
                        </div>
                        <div>
                            <span className="gx-email gx-d-inline-block">{email}</span>
                            <span className="gx-toolbar-separator">&nbsp;</span>
                            <span className="gx-phone gx-d-inline-block">{phone}</span>
                        </div>
                        <div style={{ marginTop: "0.4rem" }}>
                            {hasOccupation ? this.getAllTags(Utils.getArrayOfData(hasOccupation)) : null}
                        </div>
                    </div>
                    <div className="gx-module-contact-right">
                    </div>
                </div>
            </div>
        )
    }

    renderItems = (item) => {
        const { productId, itemCategory, description } = item;
        const itemName = item.name;
        return (
            <div className="gx-contact-item" style={{ padding: '0.5rem' }} onClick={() => { this.SearchLinkNavigation(item) }}>
                <div className="gx-module-list-icon">
                    <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
                        <Avatar size="large">
                            {itemName.charAt(0).toUpperCase()}
                        </Avatar>
                    </div>
                </div>
                <div className="gx-module-list-info gx-contact-list-info">
                    <div className="gx-module-contact-content">
                        <p className="gx-mb-1">
                            <span className="gx-text-truncate gx-contact-name"> {itemName} </span>
                        </p>

                        <div className="gx-text-muted">
                            <span className="gx-email gx-d-inline-block gx-mr-2">
                                Id: {productId}
                            </span>
                            <span className="gx-toolbar-separator">&nbsp;</span>
                            <span className="gx-phone gx-d-inline-block">Purpose: {description}</span>
                        </div>
                        <div style={{ marginTop: '0.4rem' }}>
                            {itemCategory ? this.getTag(itemCategory) : null}
                        </div>
                    </div>
                    <div className="gx-module-contact-right">
                    </div>
                </div>
            </div >
        )
    }

    renderSearchItem = (item) => {
        let type = item.type;
        if (type === 'Contacts') {
            return this.renderContacts(item)
        } else if (type === 'Items') {
            return this.renderItems(item)
        } else if (type === 'AcceptNotes') {
            return this.renderAcceptNote(item)
        }
        else {
            return this.renderReceipt(item)
        }
    }

    getDatasource = (type) => {
        if (type === 'AcceptNotes') {
            return this.state.acceptNotes
        } else if (type === 'Receipts') {
            return this.state.receipts
        } else if (type === 'Contacts') {
            return this.state.contacts
        } else if (type === 'Items') {
            return this.state.items
        } else if (type === 'Tasks') {
            return this.state.workflow
        } else if (type === 'All') {
            return [...this.state.acceptNotes, ...this.state.receipts, ...this.state.contacts, ...this.state.items, ...this.state.workflow]
        }
    }

    getTabName = (type) => {
        let iconType;
        let tabTitle;
        if (type === 'Receipts') {
            iconType = <Icon className="sidebar-icon" type="file-text" alt="" />
            tabTitle = `Invoice (${this.state.receipts.length})`
        } else if (type === "AcceptNotes") {
            iconType = <Icon className="sidebar-icon" type="file-text" alt="" />
            tabTitle = `Clearing Details (${this.state.acceptNotes.length})`
        } else if (type === 'Contacts') {
            iconType = <Icon className="sidebar-icon" component={contact} alt="" />
            tabTitle = `Contacts (${this.state.contacts.length})`
        } else if (type === 'Items') {
            iconType = <Icon className="sidebar-icon" component={item} alt="" />
            tabTitle = `Items (${this.state.items.length})`
        } else if (type === 'Tasks') {
            iconType = <Icon className="sidebar-icon" component={workflow} alt="" />
            tabTitle = `Tasks (${this.state.workflow.length})`
        } else if (type === 'All') {
            let allType = [...this.state.receipts, ...this.state.contacts, ...this.state.items, ...this.state.workflow]
            iconType = <Icon className="sidebar-icon" type="unordered-list" />
            tabTitle = `All (${allType.length})`

        }
        return (
            <span>
                {iconType}
                {tabTitle}
            </span>
        )
    }


    renderMainContent() {
        const { receipts, contacts, items, workflow, tabHeaders } = this.state;
        let searchText = this.state.searchText
        searchText = searchText.replace('%20', ' ')
        const paginationProps = {
            showQuickJumper: true,
            pageSize: 15,
            hideOnSinglePage: true
        };
        return (
            <div className="search tabCardContainer">
                <Row className="search__header">
                    <Col span={12}>
                        <p style={{ margin: '0' }}>
                            <Highlighter
                                searchWords={[searchText]}
                                autoEscape={true}
                                textToHighlight={`Search result matching ${searchText}`}
                            />
                        </p>
                    </Col>
                    <Col span={12} >
                        <IconComponent actionIcon={true}
                            hoverText="Close"
                            type="close"
                            style={{ color: 'gray', float: 'right' }}
                            onClick={this.goBack} />
                    </Col>
                </Row>
                <Tabs defaultActiveKey={this.state.activeTab}
                    onChange={this.onTabChange}
                    tabPosition="left"
                    className="search__tabs">
                    {
                        tabHeaders.map((data, index) => (
                            <TabPane tab={this.getTabName(data)} key={data} className="search__tabpane" >
                                <List
                                    className="search__result_list"
                                    itemLayout="horizontal"
                                    dataSource={this.getDatasource(data)}
                                    renderItem={item => (
                                        this.renderSearchItem(item)
                                    )}
                                    pagination={paginationProps}
                                />
                            </TabPane>
                        ))
                    }
                </Tabs>
            </div>
        )
    }
}
export default withRouter(Search);

