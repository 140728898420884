import EInvoiceErrors from '../errors';
import JSONFileParser from './e-invoice/file-parser/json-file-parser';
import CSVFileParser from './e-invoice/file-parser/csv-file-parser';
import PrmFileParser from './e-invoice/file-parser/prm-file-parser';
import NetworkBridge from '../../util/network-bridge';
import Progress from './progress';
import Errors from '../errors'
import ThirdPartyUtils from './utils/IIIrd-party-utils';
class IRNProvider {
    constructor() {

    }

    /**
     * 
     * @param {*} type 
     * @param {*} filePaths 
     * @param {*} callback 
     */
    importReceipts(type, filePaths, callback) {
        if (!callback) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK);
        }
        if (callback && !callback.onProgress) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_PROGRESS);
        }
        if (callback && !callback.onConflict) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_CONFLICT);
        }
        if (callback && !callback.onStatus) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_STATUS);
        }
        if (callback && !callback.onComplete) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_SUCCESS);
        }
        if (callback && !callback.onError) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_ERROR);
        }
        if (callback && !callback.onDone) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_DONE);
        }
        if (callback && !callback.onAbort) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_ABORT);
        }
        if (callback && !callback.onFileSkip) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_FILE_SKIP_ERROR);
        }
        if (callback && !callback.onFiles) {
            return Promise.reject(EInvoiceErrors.NO_CALL_BACK_ON_FILES_ERROR);
        }
        if (!type.dbType)
            type.dbType = "irn"
        return this._parseBrowserFiles(type, filePaths, callback);
    }

    async _parseBrowserFiles(type, filePaths, callback) {
        let fileParser;
        switch (type.fileType) {
            case "json":
                fileParser = new JSONFileParser();
                break;
            case "csv":
                fileParser = new CSVFileParser();
                break;
            case "prm":
                fileParser = new PrmFileParser();
                break;
            default:
                fileParser = null;
        }

        if (!fileParser) {
            return Promise.reject(EInvoiceErrors.INVALID_FILE_FORMAT);
        }

        if (filePaths.target.files.length === 0) {
            return callback.onAbort({ fileName: "", error: EInvoiceErrors.NO_FILES_SELECTED });
        }
        let fileNames = [];
        let promisesArray = [];
        let files = filePaths.target.files;
        // return new Promise(async(resolve, reject)=>{
        for (let index = 0; index < files.length; index++) {
            let fileName = files[index].name;
            let local_options = {
                index: index,
                isLastFile: files.length === index + 1,
                files: files.length,
                dbType: type.dbType
            }
            let _localPromise = await fileParser.parse(files[index],
                this._getFileParserCallback(fileName, callback, local_options))
                .catch(e => {
                    callback.onError({ fileName, msg: `Unable to parse data, ${e.error}` });
                });
            promisesArray.push(_localPromise)

            fileNames.push(fileName);
        }
        // })

        // callback.onFiles(fileNames);
        return Promise.all(promisesArray);
    }

    _getFileParserCallback(fileName, callback, options) {

        return {
            onProgress: (currentFileProgress) => {
                this._sendProgress(fileName, currentFileProgress, options, callback);
            },
            onStatus: (statusObj) => {
                callback.onStatus(statusObj)
            },
            onError: (error) => {
                callback.onError(error);
            },
            onValidationError: (error) => {
                callback.onValidationError(error);
            },
            onAbort: (error) => {
                let index = options.index;
                let isLastFile = options.isLastFile;
                if (!error) {
                    error = { fileName, error: EInvoiceErrors.UNKNOWN_ERROR };
                }
                if (typeof error !== 'object') {
                    error = {
                        "index": index
                    }
                }
                error["index"] = index;
                callback.onFileSkip(error);
                if (isLastFile) {
                    callback.onDone();
                }
            },
            onData: (dataObj) => {
                if (!dataObj || !dataObj.jsonObjects || !dataObj.resolve || !dataObj.reject) {
                    return callback.onAbort({ fileName, error: EInvoiceErrors.INVALID_FILE_FORMAT });
                }
                if (!Array.isArray(dataObj.jsonObjects)) {
                    dataObj.jsonObjects = [dataObj.jsonObjects];
                }
                // let jsonLd = []
                // let jsonLd = dataObj.jsonObjects[0];
                // jsonLd = this._getJSONLdWithStatus(jsonLd);
                let jsonLdWritePromises = [];
                let percentage = 90 / dataObj.jsonObjects.length;
                for (let index = 0; index < dataObj.jsonObjects.length; index++) {
                    let jsonLd = this._getJSONLdWithStatus(dataObj.jsonObjects[index]);
                    let _writePromise = NetworkBridge.getReceiptManager()
                        .addReceipt(jsonLd["@id"], jsonLd, { type: options.dbType })
                        .then((result) => {
                            if (result && result instanceof Error) {
                                callback.onStatus(EInvoiceErrors.RECORD_EXISTS)
                                return this._onConflict(jsonLd, null, callback);
                            }
                            return null;
                        }).catch(() => {
                            callback.onStatus(EInvoiceErrors.RECORD_EXISTS)
                            return;
                        }).finally(() => {
                            this._sendProgress(
                                fileName,
                                Progress.ON_FILE_PARSING + (percentage * (index + 1)),
                                options,
                                callback
                            );

                        });
                    jsonLdWritePromises.push(_writePromise);
                }

                return Promise.all(jsonLdWritePromises).finally(() => {
                    this._sendProgress(fileName, 100, options, callback);
                    let index = options.index;
                    let isLastFile = options.isLastFile;
                    callback.onComplete({ index, fileName })
                    if (isLastFile) {
                        callback.onDone();
                    }
                    dataObj.resolve();
                })
                // Got conf:
                // callback.onConflict({invoiceId:"", conflitFun:this._getConflitCallback()})
                // Data into graphDB
                // Save to graphDB
            },
            onFileReading: (error) => {

            },
        }
    }
    _getJSONLdWithStatus(jsonLd) {
        if (!jsonLd) {
            return null;
        }
        if (jsonLd && !jsonLd.addOns) {
            jsonLd["addOns"] = {
                "@type": "Thing",
            }
        }
        let jsonLdObj = {
            status: "NEW",
            lastChecked: new Date().getTime()
        }
        if (!jsonLd.addOns.invoiceInfo) {
            jsonLd.addOns["invoiceInfo"] = jsonLdObj
        } else if (!jsonLd.addOns["invoiceInfo"].irn) {
            jsonLdObj['status'] = 'NEW';
        } else if (jsonLd.addOns["invoiceInfo"].status) {
            jsonLdObj['status'] = jsonLd.addOns["invoiceInfo"].status;
            jsonLdObj['irn'] = jsonLd.addOns["invoiceInfo"].irn;
        } else if (jsonLd.addOns["invoiceInfo"].irn) {
            jsonLdObj['status'] = 'ACT';
            jsonLdObj['irn'] = jsonLd.addOns["invoiceInfo"].irn;
        } else if (!jsonLd.addOns["invoiceInfo"].status) {
            jsonLdObj['status'] = 'ACT';
        }
        jsonLd.addOns['invoiceInfo'] = jsonLdObj;
        if (!jsonLd.addOns['erpTaxInvoiceInfo']) {
            let { objects, total } = ThirdPartyUtils.getReceiptInvoiceSum(jsonLd);
            jsonLd.addOns["erpTaxInvoiceInfo"] = objects;
            // jsonLd['minimumPaymentDue'] = {
            //     "@type": "PriceSpecification",
            //     "price": total,
            //     "priceCurrency": "INR"
            // }
            // jsonLd['totalPaymentDue'] = {
            //     "@type": "PriceSpecification",
            //     "price": total,
            //     "priceCurrency": "INR"
            // }
        }
        return jsonLd;
    }
    _onConflict(jsonLd, promise, callback) {
        return new Promise((resolve, reject) => {
            return NetworkBridge.getReceiptManager().getReceipt(jsonLd['@id'], { type: "irn" }).then(existingReceipt => {
                let conflict = this._getConflitCallback(callback);
                let receiptId = jsonLd['@id'];
                jsonLd['displayReceiptId'] = jsonLd['@id'];
                callback.onConflict({ receiptId, jsonLd, existingReceipt }, conflict, resolve, reject);
            }).catch(e => {
                callback.onError(Errors.UNABLE_TO_FETCH_RECOED);
                resolve()
            })
        }).then(result => {
            return promise;
        })
    }
    _sendProgress(fileName, currentFileProgress, options, callback) {
        if (!currentFileProgress) {
            currentFileProgress = 1;
        }
        let index = options.index;
        // let files = options.files;
        let currentProgress = index === 0 ? 0 : (100 / (index)); //(100/5) = 20
        let oneFileProgress = 100 / options.files;//(100/5) = 20 = 100
        // currentFileProgress = Progress.getFileProgress(currentFileProgress);
        let totalFileProgress = (oneFileProgress * index) + ((currentFileProgress / 100) * oneFileProgress);
        callback.onProgress({
            index, fileName,
            fileProgress: currentFileProgress,
            totalProgress: totalFileProgress
        })
    }
    _getConflitCallback(callback) {
        return {
            skip: (resolve, reject, skipType) => {
                resolve();
                callback.onStatus({ msg: `Record skipped successfully.` });
            },
            replace: (invoiceId, jsonLd, resolve, reject) => {
                return NetworkBridge.getReceiptManager()
                    .updateReceipt(invoiceId, jsonLd, { type: "irn" })
                    .then(() => {
                        callback.onStatus({ msg: `Record updated successfully.` });
                    })
                    .finally(() => {
                        resolve();
                    });
            }
        }
    }
    sendViaMail(receiptIds) {

    }

    sendViaParam(receiptIds) {

    }

    generateUUID(receiptIds) {

    }


}
export default IRNProvider;