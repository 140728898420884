class InvoiceReconciliation {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    grnMissing(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "grnMissing", options)
    }
    grnMissingGraph(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "grnMissingGraph", options)
    }
    paymentLessThanInvoice(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "paymentLessThanInvoice", options)
    }
    paymentLessThanInvoiceGraph(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "paymentLessThanInvoiceGraph", options)
    }
    quantityMisMatch(options, type) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "quantityMisMatch", options, type)
    }
    excessSupply(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "excessSupply", options)
    }
    excessSupplyGraph(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "excessSupplyGraph", options)
    }
    shortSupply(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "shortSupply", options)
    }
    shortSupplyGraph(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "shortSupplyGraph", options)
    }
    priceMisMatch(options, type) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "priceMisMatch", options, type)
    }
    lessPrice(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "lessPrice", options)
    }
    lessPriceGraph(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "lessPriceGraph", options)
    }
    excessPrice(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "excessPrice", options)
    }
    excessPriceGraph(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "excessPriceGraph", options)
    }
    quantityAndPriceMismatch(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "quantityAndPriceMismatch", options)
    }
    quantityAndPriceMismatchGraph(options) {
        return this.mongoDBConnector.invoke('invoiceReconciliation', "quantityAndPriceMismatchGraph", options)
    }


}
export default InvoiceReconciliation