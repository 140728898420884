import Database from '../../index';
import ParamConnector from '../../../param-connector'
import ECIES from '../../../util/ecies';
import * as NetworkUtils from '../../../util/utils';
import Console from '../../../logger/index';
import NetworkDataFormat from '../Utils/network-data-format';
import * as Utils from '../../../util/utils';
// import { ContactEvents } from '../../param-network/utils/event-names';

class RestoreVendorManagement {
    static restoreInvitation(invitationId, invitationType, contractAddress) {
        let vendorManagementInstance = Database.getInstance().getDB().vendorManagement;
        return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getInvitation(invitationId).then(invitation => {
            return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getInvitationProfile(invitationId).then(invitationProfile => {
                invitationId = NetworkUtils.getTransactionId(invitationId, contractAddress);

                let owner = invitationProfile[0],
                    vendorAddress = invitationProfile[2],
                    buyerAddress = invitationProfile[1],
                    initiatorJsonLd = invitationProfile[3],
                    receiverJsonLd = invitationProfile[4],
                    templateId = invitation[5],
                    shareKey = invitation[2],
                    status = invitation[3],
                    step = invitation[4],
                    txnMode = invitation[6];
                let selfParamId = NetworkUtils.getParamId();


                templateId = NetworkUtils.getTransactionId(templateId, contractAddress);
                let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
                if (txnMode === "2") {
                    initiatorJsonLd = ECIES.decrypt(privateKey, initiatorJsonLd);
                    receiverJsonLd = ECIES.decrypt(privateKey, receiverJsonLd);
                }
                let paramId = Utils.getParamId();
                let functionKey;
                if (status === '0') {
                    functionKey = 'addContact';
                }
                else if (status === '1') {
                    functionKey = 'updateContact';
                }
                else {
                    functionKey = 'updateOrAddInvitation';
                }
                if (buyerAddress === "0x0000000000000000000000000000000000000000" && owner !== selfParamId) {
                    buyerAddress = selfParamId;
                }
                if (vendorAddress === "0x0000000000000000000000000000000000000000" && owner !== selfParamId) {
                    vendorAddress = selfParamId;
                }
                if (owner === buyerAddress && owner === vendorAddress) {
                    //self invite
                    if (invitationType === '0') {
                        return vendorManagementInstance[functionKey](invitationId + '_0', vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '0', owner, shareKey, initiatorJsonLd).then(() => {
                            return vendorManagementInstance[functionKey](invitationId + '_1', vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '1', owner, shareKey, receiverJsonLd)
                        });
                    }
                    return vendorManagementInstance[functionKey](invitationId + '_1', vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '1', owner, shareKey, initiatorJsonLd).then(() => {
                        return vendorManagementInstance[functionKey](invitationId + '_0', vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '0', owner, shareKey, receiverJsonLd)
                    })
                }
                else if (owner === buyerAddress) {
                    //vendor invite 
                    if (paramId === owner) {
                        return vendorManagementInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '1', owner, shareKey, initiatorJsonLd);
                    }
                    return vendorManagementInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '0', owner, shareKey, receiverJsonLd);
                }
                else if (owner === vendorAddress) {
                    //buyer invite
                    if (paramId === owner) {
                        return vendorManagementInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '0', owner, shareKey, initiatorJsonLd);
                    }
                    return vendorManagementInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '1', owner, shareKey, receiverJsonLd);
                }
            }).then(() => {
                return RestoreVendorManagement.restoreVendorTemplate(invitation[5], undefined, contractAddress);
            });
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreInvitation`);
        });
    }
    static restoreVendorTemplate(templateId, owner, contractAddress) {
        let vendorManagementInstance = Database.getInstance().getDB().vendorManagement;
        return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getInvitationTemplate(templateId)
            .then(vendorTemplate => {
                templateId = NetworkUtils.getTransactionId(templateId, contractAddress);
                let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
                if (vendorTemplate[4] === "2") {
                    vendorTemplate[2] = ECIES.decrypt(privateKey, vendorTemplate[2]);
                }
                return vendorManagementInstance.createInvitationTemplate(templateId, vendorTemplate[2], vendorTemplate[3], vendorTemplate[6], vendorTemplate[7], owner);
            }).catch(e => {
                Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreVendorTemplate`);
            });
    }
    static restoreEvalRequest(evalRequestId, invitationId, contractAddress) {
        let vendorManagementInstance = Database.getInstance().getDB().vendorManagement;
        return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getEvalRequest(evalRequestId)
            .then(evalRequest => {
                evalRequestId = NetworkUtils.getTransactionId(evalRequestId, contractAddress);
                let docId = NetworkUtils.getTransactionId(evalRequest[1], evalRequest[0]);
                return vendorManagementInstance.evalRequest(evalRequestId, invitationId, docId);
            }).catch(e => {
                Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreEvalRequest`);
            });
    }
    static restoreAllInvitationsAndEvalRequests(address, contractAddress, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getAllInvitationsByBuyer(address).then(invitations => {
            Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
            let invitationAndEvalPromises = [];
            for (let index = 0; index < invitations.length; index++) {
                let invitationId = invitations[index].toString();
                let invitationAndEvalPromise = RestoreVendorManagement.restoreInvitation(invitationId, "0", contractAddress).then(() => {
                    return RestoreVendorManagement.restoreAllEvalRequests(invitationId, contractAddress);
                });
                invitationAndEvalPromises.push(invitationAndEvalPromise);
            }
            return Promise.all(invitationAndEvalPromises);
        }).then(() => {
            return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getAllInvitationsByVendor(address).then(invitations => {
                Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
                let invitationAndEvalPromises = [];
                for (let index = 0; index < invitations.length; index++) {
                    let invitationId = invitations[index].toString();
                    let invitationAndEvalPromise = RestoreVendorManagement.restoreInvitation(invitationId, "1", contractAddress).then(() => {
                        return this.restoreAllEvalRequests(invitationId, contractAddress);
                    });
                    invitationAndEvalPromises.push(invitationAndEvalPromise);
                }
                return Promise.all(invitationAndEvalPromises);
            });
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreAllInvitationsAndEvalRequests`);
        });
    }
    static restoreAllVendorTemplates(owner, contractAddress, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getAllInvitationTemplates(owner).then(vendorTemplates => {
            Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
            let vendorTemplatePromises = [];
            for (let index = 0; index < vendorTemplates.length; index++) {
                let vendorTemplateId = vendorTemplates[index].toString();
                let vendorTemplatePromise = RestoreVendorManagement.restoreVendorTemplate(vendorTemplateId, owner, contractAddress);
                vendorTemplatePromises.push(vendorTemplatePromise);
            }
            return Promise.all(vendorTemplatePromises);
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreAllVendorTemplates`);
        });
    }
    static restoreAllEvalRequests(invitationId, contractAddress, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        return ParamConnector.getInstance().getNetwork().getVendorManager(contractAddress).getAllEvalRequestsByInvitation(invitationId).then(evalRequests => {
            Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
            let evalRequestPromises = [];
            for (let index = 0; index < evalRequests.length; index++) {
                let evalRequestId = evalRequests[index].toString();
                let evalRequestPromise = RestoreVendorManagement.restoreEvalRequest(evalRequestId, invitationId, contractAddress);
                evalRequestPromises.push(evalRequestPromise);
            }
            return Promise.all(evalRequestPromises);
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreAllEvalRequests`);
        });
    }
}
export default RestoreVendorManagement;