import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import { ReceiptEvents, ReceiptStatusEvents } from '../utils/event-names';
import BlockInfo from '../utils/block-info';

/**
 * ParamReceipt is an implementation of core param protocol, using this class user can able to post receipt into ParamNetwork. 
 * 
 */
class ParamReceipt {
    /**
     * Default constructor for initialising or establishing the param receipt.
     * @param {ParamNetwork} paramNetwork Object
     */
    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const paramManager = require('./param-receipt.json')
        this.paramReceiptManagerContract = Web3_1_0.getContract(this.connection, paramManager.abi, contractAddress ? contractAddress : paramManager.address);
        this.to = contractAddress;
        /*  this.paramReceiptManagerContract = new this.connection.eth.Contract(paramManager.abi, contractAddress);
         this.paramReceiptManagerContract.abi = paramManager.abi;
         this.paramReceiptManagerContract = this.paramReceiptManagerContract.at(paramManager.address); */
    }

    /**
     * initEvents is a event listener used for listening the real time changes whenever a receipt is added or updated.
     * @param {JSON} options - {"address":"0x"}
     * @example 
     * Usage:
     *  paramReceiptManager
     *   .initEvents({address:'0x'})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }

    initEvents(options) {
        if (!this.events) {
            const events = require('events');
            this.events = new events.EventEmitter();
        }

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        let sellerAddress, buyerAddress, owner, caller;

        if (options && options.address) {
            sellerAddress = { seller: options.address }
            buyerAddress = { buyer: options.address }
            options = { address: options.address }
            owner = { owner: options.address }
            caller = { caller: options.address }
        }

        this.watchStatusUpdateForSeller(sellerAddress);
        this.watchStatusUpdateForBuyer(buyerAddress);
        this.watchTransactionUpdateEvent(caller);
        this.watchDocumentUpdateEvent(owner);
        this.watchGrnsUpdateEvent(owner);
        this.watchStatusUpdateV1(options);

        this.watchAddConsentEvent(options);
        this.watchAddSubscriberEvent(options);
    }

    watchDocumentUpdateEvent(options) {
        if (typeof this.documentUpdateEvent === 'undefined' || !this.documentUpdateEvent) {
            this.documentUpdateEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onDocumentUpdate");
        }
        let that = this;
        this.documentUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.seller.toLowerCase() || options.owner.toLowerCase() === transInfo.buyer.toLowerCase() || options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onDocumentUpdate", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onDocumentUpdate", error);
                that.documentUpdateEvent.unsubscribe();
                that.documentUpdateEvent = undefined;
                that.watchDocumentUpdateEvent(options);
            });
    }

    watchGrnsUpdateEvent(options) {
        if (typeof this.grnsUpdateEvent === 'undefined' || !this.grnsUpdateEvent) {
            let filter = { filter: options }
            this.grnsUpdateEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onGRNUpdate", filter);

        }
        let that = this;
        this.grnsUpdateEvent.on('data', function (event) {
            that.emitEvent("onGRNUpdate", event);
        })
            .on('error', function (error) {
                that.events.emit("onGRNUpdate", error);
                that.grnsUpdateEvent.unsubscribe();
                that.grnsUpdateEvent = undefined;
                that.watchGrnsUpdateEvent(options);
            });
    }

    watchStatusUpdateV1(options) {
        if (typeof this.statusUpdateEvent === 'undefined' || !this.statusUpdateEvent) {
            let filter = { filter: options.address }
            // this.sellerStatusUpdateEvent = this.paramReceiptManagerContract.events.onStatusUpdateV1({ filter: sellerAddress });
            this.statusUpdateEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onReceipStatusUpdateV1", filter);
        }
        let that = this;
        this.statusUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if ((options.address.toLowerCase() === transInfo.seller.toLowerCase()) || (options.address.toLowerCase() === transInfo.buyer.toLowerCase())) {
                that.emitEvent("onReceipStatusUpdateV1", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onReceipStatusUpdateV1", error);
                that.statusUpdateEvent.unsubscribe();
                that.statusUpdateEvent = undefined;
                that.watchStatusUpdateV1(options)
            });
    }

    watchStatusUpdateForSeller(sellerAddress) {
        if (typeof this.sellerStatusUpdateEvent === 'undefined' || !this.sellerStatusUpdateEvent) {
            let filter = { filter: sellerAddress }
            // this.sellerStatusUpdateEvent = this.paramReceiptManagerContract.events.onStatusUpdateV1({ filter: sellerAddress });
            this.sellerStatusUpdateEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onStatusUpdateV1", filter);
        }
        let that = this;
        this.sellerStatusUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (sellerAddress.seller.toLowerCase() === transInfo.seller.toLowerCase()) {
                that.emitEvent("onStatusUpdateV1", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onStatusUpdateV1", error);
                that.sellerStatusUpdateEvent.unsubscribe();
                that.sellerStatusUpdateEvent = undefined;
                that.watchStatusUpdateForSeller(sellerAddress)
            });
    }

    watchStatusUpdateForBuyer(buyerAddress) {
        if (typeof this.buyerStatusUpdateEvent === 'undefined' || !this.buyerStatusUpdateEvent) {
            let filter = { filter: buyerAddress }
            // this.buyerStatusUpdateEvent = this.paramReceiptManagerContract.events.onStatusUpdateV1({ filter: buyerAddress });
            this.buyerStatusUpdateEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onStatusUpdateV1", filter);
        }
        let that = this;
        this.buyerStatusUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (buyerAddress.buyer.toLowerCase() === transInfo.buyer.toLowerCase()) {
                that.emitEvent("onStatusUpdateV1", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onStatusUpdateV1", error);
                that.buyerStatusUpdateEvent.unsubscribe();
                that.buyerStatusUpdateEvent = undefined;
                that.watchStatusUpdateForBuyer(buyerAddress)
            });
    }

    watchTransactionUpdateEvent(caller) {
        if (typeof this.transactionUpdateEvent === 'undefined' || !this.transactionUpdateEvent) {
            let filter = { filter: caller }
            // this.transactionUpdateEvent = this.paramReceiptManagerContract.events.onTransactionUpdate({ filter: caller });
            this.transactionUpdateEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onTransactionUpdate", filter);
        }
        let that = this;
        this.transactionUpdateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (caller.caller.toLowerCase() === transInfo.caller.toLowerCase()) {
                that.emitEvent("onTransactionUpdate", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onTransactionUpdate", error);
                that.transactionUpdateEvent.unsubscribe();
                that.transactionUpdateEvent = undefined;
                that.watchTransactionUpdateEvent(caller);
            });
    }

    watchAddConsentEvent(options) {
        if (typeof this.addConsentEvent === 'undefined' || !this.addConsentEvent) {
            // this.addConsentEvent = this.paramReceiptManagerContract.events.onConsent();
            this.addConsentEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onConsent");
        }
        let that = this;
        this.addConsentEvent.on('data', function (event) {
            that.emitEvent("onConsent", event);
        })
            .on('error', function (error) {
                that.events.emit("onConsent", error);
                that.addConsentEvent.unsubscribe();
                that.addConsentEvent = undefined;
                that.watchAddConsentEvent(options);
            });
    }

    watchAddSubscriberEvent(options) {
        if (typeof this.addSubscriberEvent === 'undefined' || !this.addSubscriberEvent) {
            // this.addSubscriberEvent = this.paramReceiptManagerContract.events.onSubscriber();
            this.addSubscriberEvent = Web3_1_0.getEvent(this, this.paramReceiptManagerContract, "onSubscriber");
        }
        let that = this;
        that.addSubscriberEvent.on('data', function (event) {
            that.emitEvent("onSubscriber", event);
        }).on('error', function (error) {
            that.events.emit("onSubscriber", error);
            that.addSubscriberEvent.unsubscribe();
            that.addSubscriberEvent = undefined;
            that.watchAddSubscriberEvent(options);
        });
    }
    /**
    * initProposal is an async function, using this function post new contract into param network.
    * 
    * @param {address} buyerId - Param BuyerId to post contract
    * @param {string} json - Receipt content.
    * @param {JSON} options - {from: <PARAM_ADDRESS>}
    * @returns {Promise} promise
    * 
    * @example
    * Usage:
    * paramReceiptManager
    *   .initProposal("0x","<JSONLD>", {from:<FROM_PARAM_ADDRESS>})
    *   .then((result)=>{
    *       //TODO 
    *   })
    */

    initProposal(buyerId, sellerId, jsonLd, note, txnMode, isMandatory, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "initProposal", options, buyerId, sellerId, jsonLd, note, txnMode, isMandatory);
    }

    initProposalSync(buyerId, sellerId, jsonLd, note, txnMode, isMandatory, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "initProposal", options, buyerId, sellerId, jsonLd, note, txnMode, isMandatory).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    updateInitProposal(proposalId, jsonLd, note, txnMode, isMandatory, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateInitProposal", options, proposalId, jsonLd, note, txnMode, isMandatory);
    }

    updateInitProposalSync(proposalId, jsonLd, note, txnMode, isMandatory, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateInitProposal", options, proposalId, jsonLd, note, txnMode, isMandatory).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    getStartStatus(rId) {
        return Web3_1_0.getPastEvents(this, this.paramReceiptManagerContract, "onStatusUpdateV1", {
            filter: { rId: rId },
            fromBlock: 0,
            toBlock: 'latest'
        }).then(result => {
            let status = -1;
            for (let index = 0; index < result.length; index++) {
                result[index] = Web3_1_0.upgradeEventData(result[index]);
                if (index === 0) {
                    status = parseInt(result[index].args.step.toString());
                    continue;
                }
                status = Math.min(status, parseInt(result[index].args.step.toString()))
            }
            if (status === -1) {
                throw new Error("Unable to fetch start status.")
            }
            return status;
        });
    }
    /**
     * acceptProposal is an async function, using this function buyer will accept proposal.
     * If transaction is successful receipt state is CREATE_PO and step will be ACCEPT_PROPOSAL_AND_CREATE_PO state. 
     * This function will return promise function.     
     * 
     * @param {String} rId - Receipt Id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .acceptProposal("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    acceptProposal(rId, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "acceptProposal", options, rId, note, txnMode);
    }

    acceptProposalSync(rId, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "acceptProposal", options, rId, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    assignTemplate(id, rId, contractAddress, step, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "assignTemplate", options, id, rId, contractAddress, step);
    }

    /**
     * rejectProposal is an async function, using this function buyer will reject proposal.
     * If transaction is successful receipt state is REJECT_PROPOSAL and step will be same state. 
     * Once proposal is canceled seller or buyer can't perfrom any action in future. 
     * This function will return promise function.
     * 
     * @param {String} rId - Receipt Id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .rejectProposal("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    changeDocumentAcceptState(proposalId, note, txnMode, functionName, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, functionName, options, proposalId, note, txnMode);
    }
    /**
     * createPO is an async function, using this function seller will change receipt state from purchase order to invoice.
     * If transaction is successful receipt state will be change to SEND_INVOICE and step will be SEND_INVOICE state. 
     * This function will return promise function.
     * 
     * @param {String} rId - Receipt Id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .createPO("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    createPO(pId, sellerId, jsonLd, note, txnMode, grnManager, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createPO", options, pId, sellerId, jsonLd, note, txnMode, grnManager);
    }

    createPOSync(pId, sellerId, jsonLd, note, txnMode, grnManager, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createPO", options, pId, sellerId, jsonLd, note, txnMode, grnManager).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    createInwardPO(pId, buyerId, jsonLd, note, txnMode, grnManager, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInwardPO", options, pId, buyerId, jsonLd, note, txnMode, grnManager);
    }

    createInwardPOSync(pId, sellerId, jsonLd, note, txnMode, grnManager, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInwardPO", options, pId, sellerId, jsonLd, note, txnMode, grnManager).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    updatePO(pId, jsonLd, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updatePO", options, pId, jsonLd, note, txnMode);
    }

    updatePOSync(pId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updatePO", options, pId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    /**
     * sendInvoice is an async function, using this function seller will change receipt state from purchase order to invoice.
     * If transaction is successful receipt state will be change to SEND_INVOICE and step will be SEND_INVOICE state. 
     * This function will return promise function.
     * 
     * @param {String} rId - Receipt Id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .sendInvoice("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    sendInvoice(poId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "sendInvoice", options, poId, jsonLd, note, txnMode);
    }

    sendInvoiceSync(poId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "sendInvoice", options, poId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    sendInwardInvoice(poId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "sendInwardInvoice", options, poId, jsonLd, note, txnMode);
    }

    sendInwardInvoiceSync(poId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "sendInwardInvoice", options, poId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    createInvoice(buyerId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInvoice", options, buyerId, jsonLd, note, txnMode);
    }

    createInvoiceSync(buyerId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInvoice", options, buyerId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    createInwardInvoice(sellerId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInwardInvoice", options, sellerId, jsonLd, note, txnMode);
    }

    createInwardInvoiceSync(sellerId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInwardInvoice", options, sellerId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    updateInvoice(invoiceId, jsonLd, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateInvoice", options, invoiceId, jsonLd, note, txnMode);
    }

    updateInvoiceSync(invoiceId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateInvoice", options, invoiceId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }


    /**
     * sendInvoice is an async function, using this function seller will change receipt state from purchase order to invoice.
     * If transaction is successful receipt state will be change to SEND_INVOICE and step will be SEND_INVOICE state. 
     * This function will return promise function.
     * 
     * @param {String} rId - Receipt Id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .sendInvoice("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    // sendInvoiceForUpdate(rId, jsonLd, extendedKnowledge, note, txnType, options) {
    //     return Web3_1_0.send(this, this.paramReceiptManagerContract, "sendInvoiceForUpdate", options, rId, jsonLd, extendedKnowledge, note, txnType);
    // }

    /**
     * makePayment is an async function, using this function buyer will pay invoice.
     * If transaction is successful,receipt state is MAKE_PAYMENT and step will be PAYMENT_SUCCESS state. 
     * This function will return promise function.
     * 
     * @param {String} rId - Receipt Id
     * @param {JSON} options - {from: <PARAM_ADDRESS>}
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .makePayment("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>, value:100})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    makePayment(invoiceId, jsonLd, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "makePayment", options, invoiceId, jsonLd, note, txnMode);
    }

    makePaymentSync(invoiceId, jsonLd, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "makePayment", options, invoiceId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    makeInwardPayment(invoiceId, jsonLd, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "makeInwardPayment", options, invoiceId, jsonLd, note, txnMode);
    }

    makeInwardPaymentSync(invoiceId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "makeInwardPayment", options, invoiceId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    updatePayment(paymentId, jsonLd, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updatePayment", options, paymentId, jsonLd, note, txnMode);
    }

    updatePaymentSync(paymentId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updatePayment", options, paymentId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    /**
     * createReceipt is an async function, using this function seller will create receipt.
     * If transaction is successful,receipt state is CREATE_RECEIPT and step will be CREATE_RECEIPT state. 
     * This function will return promise function.
     *
     * @param {String} rId - Receipt Id
     * @param {JSON} options - {from: <PARAM_ADDRESS>}
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .createReceipt("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    createReceipt(paymentId, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createReceipt", options, paymentId, note, txnMode);
    }

    createReceiptSync(paymentId, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createReceipt", options, paymentId, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    createInwardReceipt(paymentId, note, txnMode, options) {
        options.gasValue = 2.1;
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInwardReceipt", options, paymentId, note, txnMode);
    }

    createInwardReceiptSync(paymentId, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "createInwardReceipt", options, paymentId, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    /**
     * cancelReceipt is an async function, using this function seller/buyer will cancel the receipt.
     * If transaction is successful, receipt state is CANCEL_RECEIPT and step will be CANCEL_RECEIPT state. 
     * Once receipt is canceled, you can't perfrom any action in future.
     * This function will return promise function.
     *
     * @param {String} rId - Receipt Id
     * @param {JSON} options - Param Options
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .cancelReceipt("<RECEIPT_ID>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    cancelReceipt(paymentId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "cancelReceipt", options, paymentId, jsonLd, note, txnMode);
    }

    cancelReceiptSync(paymentId, jsonLd, note, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "cancelReceipt", options, paymentId, jsonLd, note, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    attachGRN(docId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "attachGRN", options, docId, jsonLd, txnMode);
    }

    attachGRNSync(docId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "attachGRN", options, docId, jsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    attachAN(docId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "attachAN", options, docId, jsonLd, txnMode);
    }

    attachANSync(docId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "attachAN", options, docId, jsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    assignGRN(grnId, owner, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "assignGRN", options, grnId, owner);
    }

    assignGRNSync(grnId, owner, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "assignGRN", options, grnId, owner).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return grnId;
        })
    }

    updateGRN(grnId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateGRN", options, grnId, jsonLd, txnMode);
    }

    updateGRNSync(grnId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateGRN", options, grnId, jsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[3];
        })
    }

    updateAN(anId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateAN", options, anId, jsonLd, txnMode);
    }

    updateANSync(anId, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "updateAN", options, anId, jsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[3];
        })
    }

    issueNote(docId, noteType, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "issueNote", options, docId, noteType, jsonLd, txnMode);
    }

    issueNoteSync(docId, noteType, jsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "issueNote", options, docId, noteType, jsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    closePO(poId, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closePO", options, poId);
    }

    closePOSync(poId, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closePO", options, poId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return poId;
        })
    }

    closeInvoice(invoiceId, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closeInvoice", options, invoiceId);
    }

    closeInvoiceSync(invoiceId, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closeInvoice", options, invoiceId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invoiceId;
        })
    }

    closeGRN(docId, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closeGRN", options, docId);
    }

    closeGRNSync(docId, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closeGRN", options, docId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    closeGRNs(docIds, rIds, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closeGRNs", options, docIds, rIds);
    }

    closeGRNsSync(docIds, rIds, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "closeGRNs", options, docIds, rIds).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    setContractInfoId(contractId, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "setContractInfoId", options, contractId);

    }

    setSubscriberContract(address, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "setSubscriberContract", options, address);
    }

    setAddress(contractAddress, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "setAddress", options, contractAddress);
    }


    //Subscriber POST functions

    addSellerConsent(receiptId, receiptType, docContractAddress, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSellerConsent", options, receiptId, receiptType, docContractAddress);
    }

    addSellerConsentSync(receiptId, receiptType, docContractAddress, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSellerConsent", options, receiptId, receiptType, docContractAddress).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return receiptId;
        })
    }

    addBuyerConsent(receiptId, receiptType, docContractAddress, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addBuyerConsent", options, receiptId, receiptType, docContractAddress);
    }

    addBuyerConsentSync(receiptId, receiptType, docContractAddress, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addBuyerConsent", options, receiptId, receiptType, docContractAddress).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return receiptId;
        })
    }

    addSubscriber(payload, receiptId, receiptType, subscriber, docContractAddress, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSubscriber", options, payload, receiptId, receiptType, subscriber, docContractAddress);
    }

    addSubscriberSync(payload, receiptId, receiptType, subscriber, docContractAddress, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSubscriber", options, payload, receiptId, receiptType, subscriber, docContractAddress).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    addSubscriberForCons(payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSubscriberForCons", options, payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer);
    }

    addSubscriberForConsSync(payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSubscriberForCons", options, payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            console.log("logs", logs);
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    addSubscriberForGRN(payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSubscriberForGRN", options, payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer);
    }

    addSubscriberForGRNSync(payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer, options) {
        return Web3_1_0.send(this, this.paramReceiptManagerContract, "addSubscriberForGRN", options, payload, receiptId, receiptType, subscriber, docContractAddress, seller, buyer).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    /**
     * getTransaction is an async function, using this function you can get details for a given receipt transaction details.
     * @param {String} txId - Transaction id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getTransaction("<TXN_ID>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getTransaction(txId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getTransaction", null, txId);
    }

    /**
     * getExtendedKnowledge is an async function, using this function you can get details for a given extendedKnowledge Id.
     * @param {String} extendedKnowledgeId - extendedKnowledge Id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getExtendedKnowledge("<EXTENDEDKNOWLEDGE_ID>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getExtendedKnowledge(extendedKnowledgeId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getExtendedKnowledge", null, extendedKnowledgeId);
    }

    getContractInfoId() {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getContractInfoId", null);
    }

    /**
     * getExtendedKnowledge is an async function, using this function you can get details for a given extendedKnowledge Id.
     * @param {String} extendedKnowledgeId - extendedKnowledge Id
     * @returns {Promise} promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getExtendedKnowledge("<EXTENDEDKNOWLEDGE_ID>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getExtendedKnowledgeByReceiptId(rId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getExtendedKnowledgeByReceiptId", null, rId);
    }

    /**
     * getTransactions is an async function, using this function you can get list of transaction ids for a given receipt id.
     * @param {String} rId - Receipt Id
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getTransactions("<TXN_ID>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getTransactions(rId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getTransactions", null, rId);
    }

    /**
     * getReceiptsByBuyer is an async function, using this function you can get list of receipt ids for a given param buyer id.
     * @param {String} buyerId - Buyer Id
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getReceiptsByBuyer("<PARAM_ADDRESS>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getReceiptsByBuyer(buyerId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getReceiptsByBuyer", null, buyerId);
    }

    /**
     * getReceiptsBySeller is an async function, using this function you can get list of receipt ids for a given param seller id.
     * @param {String} sellerId - Seller Id
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getReceiptsBySeller("<TXN_ID>")
     *   .then((result)=>{
     *       //TODO
     *   })     
     */
    getReceiptsBySeller(sellerId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getReceiptsBySeller", null, sellerId);
    }

    /**
     * getAllReceiptsByBuyer is an async function, using this function you can get list of receipt ids for a given param buyer id.
     * @param {String} buyerId - Buyer Id
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getAllReceiptsByBuyer("<PARAM_ADDRESS>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getAllReceiptsByBuyer(buyerId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getAllReceiptsByBuyer", null, buyerId);
    }

    /**
     * getAllReceiptsBySeller is an async function, using this function you can get list of receipt ids for a given param buyer id.
     * @param {String} sellerId - Seller Id
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getAllReceiptsBySeller("<PARAM_ADDRESS>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getAllReceiptsBySeller(sellerId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getAllReceiptsBySeller", null, sellerId);
    }

    /**
     * getReceipt is an async function, using this function you can get receipt info like seller, buyer, receipt info, current state and status.
     * @param {String} rId - Receipt Id
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getReceipt("<RECEIPT_ID>")
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getReceipt(receiptId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getReceiptV1", null, receiptId);
    }

    /**
     * getReceiptsByStep is an async function, using this function you can get list of receipt id's for a given step.
     * @param {Number} step - Step 
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getReceiptsByStep(<STEP>)
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getReceiptsByStep(step) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getReceiptsByStep", null, step);
    }

    /**
     * getReceiptsByStatus is an async function, using this function you can get list of receipt id's for a given status.
     * @param {String} status - Status
     * @returns {Promise}  promise
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .getReceiptsByStatus(<STATUS>)
     *   .then((result)=>{
     *       //TODO
     *   })
     */
    getReceiptsByStatus(status) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getReceiptsByStatus", null, status);
    }

    getAllGRNsByDocId(docId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getAllGRNsByDocId", null, docId);
    }

    getAllANsByDocId(docId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getAllANsByDocId", null, docId);
    }

    getAllDocsByGRNManager(grnManagerId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getAllDocsByGRNManager", null, grnManagerId);
    }

    getAllGRNsBySeller(seller) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getAllGRNsBySeller", null, seller);
    }

    getAllGRNsByBuyer(buyer) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getAllGRNsByBuyer", null, buyer);
    }

    getGRNStatus(grnId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getGRNStatus", null, grnId);
    }

    getDocument(docId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getDocument", null, docId);
    }

    getReceiptChild(docId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getReceiptChild", null, docId);
    }

    //Subscriber GET functions
    getConsentsForReceipt(receiptId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getConsentsForReceipt", null, receiptId);
    }

    getSubscribers(receiptId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getSubscribers", null, receiptId);

    }

    getSubscriber(sId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getSubscriber", null, sId);
    }

    getSubscriberReceipts(owner) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getSubscriberReceipts", null, owner);
    }

    isSubscriberExists(receiptId, subscriberAddress) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "isSubscriberExists", null, receiptId, subscriberAddress);
    }

    isValidConsent(receiptId, subscriberAddress) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "isValidConsent", null, receiptId, subscriberAddress);
    }

    getSubscriberDetailsForId(subscriberAddress, receiptId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getSubscriberDetailsForId", null, subscriberAddress, receiptId);
    }

    getGRNManager(receiptId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getGRNManager", null, receiptId);
    }

    getIsMandatory(receiptId) {
        return Web3_1_0.call(this, this.paramReceiptManagerContract, "getIsMandatory", null, receiptId);
    }

    getAllEventsByReceiptId(rId) {
        let events = Object.keys(ReceiptEvents);
        let promiseArray = [];
        let filter = {
            filter: {
                "rId": rId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.paramReceiptManagerContract, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection, true);
    }
    getAllEventsBySeller(seller) {
        let events = Object.keys(ReceiptStatusEvents);
        let promiseArray = [];
        let filter = {
            filter: {
                "seller": seller
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.paramReceiptManagerContract, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection, true);
    }
    getAllEventsByBuyer(buyer) {
        let events = Object.keys(ReceiptStatusEvents);
        let promiseArray = [];
        let filter = {
            filter: {
                "buyer": buyer
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.paramReceiptManagerContract, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection, true);
    }

    getTimeline(rId, treeTimeline = [], keyCount = 0) {
        let treeObj = {
            title: "",
            key: keyCount++,
            children: []
        };
        return this.getAllEventsByReceiptId(rId).then(data => {
            treeObj["title"] = data;
            return Promise.all([this.getReceiptChild(rId)]);
        }).then(([rData]) => {
            let promiseArray = [];
            if (rData && rData.docMapIds) {
                rData.docMapIds.forEach(element => {
                    promiseArray.push(this.getTimeline(element, treeObj["children"], keyCount));
                });
            }
            return Promise.all(promiseArray)
        }).then((children) => {
            treeObj["children"] = children;
            treeTimeline.push(treeObj);
            return treeTimeline;
        })
    }
    /**
     * registerOnTransactionUpdate function is used to register live transaction updates from the param network. 
     * @param {function} callback (error, result)=>{}
     * 
     * @example
     * Usage:
     * const transUpdate = (error, data)=>{
    *      //TODO
    *  }
     * paramReceiptManager
     *   .registerOnTransactionUpdate(transUpdate)
     */
    registerOnTransactionUpdate(callback, options) {
        if (!this.events) {
            this.initEvents(options);
            // throw Error("Events is null. Call initEvents()");
        }
        if (this.events._events["onTransactionUpdate"]) {
            this.events.removeAllListeners("onTransactionUpdate");
        }
        this.events.addListener("onTransactionUpdate", callback);
    }

    /**
     * unRegisterOnTransactionUpdate function is used to stop getting live transaction updates from the param network. 
     * @param {function} callback - registered callback
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .unRegisterOnTransactionUpdate(transUpdate);
     */
    unRegisterOnTransactionUpdate(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onTransactionUpdate", callback);
    }

    /**
     * registerOnStatusUpdate function is used to register live status updates from the param network. 
     * @param {function}  callback (error, result)=>{}
     * 
     * @example
     * Usage:
     * let statusUpdate = (error, data) => {
     *      //TODO
     *  }
     * paramReceiptManager
     *   .registerOnStatusUpdate(statusUpdate)
     */
    registerOnStatusUpdate(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onStatusUpdateV1"]) {
            this.events.removeAllListeners("onStatusUpdateV1");
        }
        this.events.addListener("onStatusUpdateV1", callback);
    }

    /**
     * unRegisterOnStatusUpdate function is used to stop getting live status updates from the param network. 
     * @param {function}  callback - registered callback
     * 
     * @example
     * Usage:
     * paramReceiptManager
     *   .unRegisterOnStatusUpdate(statusUpdate);
     */
    unRegisterOnStatusUpdate(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onStatusUpdateV1", callback);
    }

    registerOnStatusUpdateV1(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onReceipStatusUpdateV1"]) {
            this.events.removeAllListeners("onReceipStatusUpdateV1");
        }
        this.events.addListener("onReceipStatusUpdateV1", callback);
    }

    unRegisterOnStatusUpdate(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onReceipStatusUpdateV1", callback);
    }

    registerOnDocumentUpdate(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onDocumentUpdate"]) {
            this.events.removeAllListeners("onDocumentUpdate");
        }
        this.events.addListener("onDocumentUpdate", callback);
    }

    unRegisterOnDocumentUpdate(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onDocumentUpdate", callback);
    }

    registerOnGRNUpdate(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onGRNUpdate"]) {
            this.events.removeAllListeners("onGRNUpdate");
        }
        this.events.addListener("onGRNUpdate", callback);
    }

    unRegisterOnGRNUpdate(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onGRNUpdate", callback);
    }

    //Subscriber Events

    registerOnSubscriber(callback, options) {
        if (!this.events) {
            this.initEvents(options);
            // throw Error("Events is null. Call initEvents()");
        }
        if (this.events._events["onSubscriber"]) {
            this.events.removeAllListeners("onSubscriber");
        }
        this.events.addListener("onSubscriber", callback);
    }

    unRegisterOnSubscriber(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onSubscriber", callback);
    }

    registerOnConsent(callback, options) {
        if (!this.events) {
            this.initEvents(options);
            // throw Error("Events is null. Call initEvents()");
        }
        if (this.events._events["onConsent"]) {
            this.events.removeAllListeners("onConsent");
        }
        this.events.addListener("onConsent", callback);
    }

    unRegisterOnConsent(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onConsent", callback);
    }
    parseNetworkLog(event, data) {
        let abi = this.paramReceiptManagerContract._jsonInterface;
        let abiInputs;
        for (let index in abi) {
            let abiObj = abi[index];
            if (abiObj.type === 'event' && abiObj.name === event) {
                abiInputs = abiObj;
                break;
            }
        }
        if (!abiInputs) {
            return null;
        }
        var Web3EthAbi = require('web3-eth-abi');

        let eventId = Web3EthAbi.encodeEventSignature(abiInputs);
        for (let eventIndex in data.logs) {
            if (data.logs[eventIndex].topics && eventId === data.logs[eventIndex].topics[0]) {
                // return this.connection.eth.abi.decodeLog(abiInputs.inputs, data.logs[eventIndex].data, data.logs[eventIndex].topics.splice(1));
                return Web3EthAbi.decodeLog(abiInputs.inputs, data.logs[eventIndex].data, data.logs[eventIndex].topics.splice(1));
            }
        }
        return null;
    }
}
export default ParamReceipt;