import Console from '../../../logger/index';
import GraphQl from '../index'
import Database from '../../../database/index';
import { gql } from '@apollo/client';
import RestoreTemplateRepo from '../sync/template-repo'
import * as NetworkUtils from '../../../../src/util/utils'

class TemplateRepoEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient;
        this.dbInstance = Database.getInstance().getDB()
        // this.qraphQl = GraphQl.getInstance()
    }

    registerEvents(paramId) {
        let templateOptions = {
            query: gql`
               subscription onTemplateAdded($paramId: [String]){
                    onTemplateAdded(TemplateOwnerIDs: $paramId){
                        owner,
                        templateId,
                        contractAddress
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(templateOptions).subscribe({
            next(data) {
                that._onTemplateAdded(data)
            },
            error(error) {
                Console.error(`Unable to get create Template details form network, Reson: ${error}`);
            },
        });

        let templateSharedOptions = {
            query: gql`
               subscription onTemplateShared($paramId: [String]){
                    onTemplateShared(TemplateOwnerIDs: $paramId){
                        owner,
                        templateId,
                        contractAddress,
                        subscribers
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(templateSharedOptions).subscribe({
            next(data) {
                that._onTemplateShared(data)
            },
            error(error) {
                Console.error(`Unable to get share Template details form network, Reson: ${error}`);
            },
        });

        let stepOptions = {
            query: gql`
               subscription onStepAdded($paramId: [String]){
                    onStepAdded(TemplateOwnerIDs: $paramId){
                        owner,
                        stepId,
                        contractAddress
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(stepOptions).subscribe({
            next(data) {
                that._onStepAdded(data)
            },
            error(error) {
                Console.error(`Unable to get create step details form network, Reson: ${error}`);
            },
        });

        Console.log("Registered events for template repo ", paramId)
    }

    _emitOnStep(stepId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("onStepAdded", stepId);
    }

    _emitOnTemplate(templateId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("onTemplateAdded", templateId);
    }

    _onStepAdded(subscriptionDataJson) {
        Console.info(`[GraphDB] Got _onStepAdded event`);
        this._emitOnStep(subscriptionDataJson.data["onStepAdded"].stepId)
    }

    _onTemplateAdded(subscriptionDataJson) {
        Console.info(`[GraphDB] Got _onTemplateAdded event`, subscriptionDataJson);
        let dataJSON = { args: {} };
        let transactionData = NetworkUtils.getTransactionData(subscriptionDataJson.data["onTemplateAdded"].templateId)
        dataJSON.args.templateId = transactionData.id;
        dataJSON.args.contractAddress = subscriptionDataJson.data["onTemplateAdded"].contractAddress;
        return RestoreTemplateRepo.restoreTemplate(dataJSON.args.templateId, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("OnTemplate", dataJSON.args.templateId, null);
        }).catch(error => {
            Console.error(`Unable to add template ${dataJSON.args.templateId}, reason: ${error.toString()}`);
        })
    }

    _onTemplateShared(subscriptionDataJson) {
        Console.info(subscriptionDataJson);
        debugger
        let dataJSON = {}
        dataJSON.args = subscriptionDataJson.data.onTemplateShared
        dataJSON.args.templateId = NetworkUtils.getTransactionData(dataJSON.args.templateId).id
        return RestoreTemplateRepo.restoreTemplateAsSubscriber(dataJSON.args.templateId, dataJSON.args.owner, dataJSON.args.subscribers, dataJSON.args.contractAddress).then(response => {
            this.dbInstance.emitEvent("OnTemplateShared", dataJSON.args.templateId, null);
        }).then(() => {
            let templateId = dataJSON.args.templateId + "-" + dataJSON.args.contractAddress;
            return this.dbInstance.templateRepoDB.isInstalled(templateId)
                .then(isInstalled => {
                    if (isInstalled === "false") {
                        return this.dbInstance.notifications.addNotification(templateId, "onTemplate", "template", dataJSON.args.creator || dataJSON.args.owner).then(() => {
                            this.dbInstance.events.emit("notification", templateId, null);
                        });
                    }
                })
        }).catch(error => {
            Console.error(`Unable to share template ${dataJSON.args.templateId}, reason: ${error.toString()}`);
        })
    }

}

export default TemplateRepoEvents;