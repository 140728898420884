import React from 'react';
import './index.less';
import PlacesAutocomplete from 'react-places-autocomplete';
import * as Utils from '../../../util/utils';

class AutoCompletePlaces extends React.Component {

    render() {
        const { value, onChange, onSelect } = this.props;
        let currentTheme = Utils.getFromLocalStorage('theme'), autocompleteStyle = {};
        if (currentTheme === 'THEME_TYPE_DARK') {
            autocompleteStyle = {
                background: '#4f575f'
            }
        }
        return (
            <PlacesAutocomplete
                value={value}
                onChange={onChange}
                onSelect={onSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        <input
                            {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                            })}
                            style={{ width: '15rem', borderRadius: '6px', padding: '0.5px 11px', height: '36px', border: '1px solid #d9d9d9', background: 'transparent' }}
                        />
                        <div className="autocomplete-dropdown-container" style={autocompleteStyle}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--aborderctive'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                let style;
                                currentTheme === 'THEME_TYPE_DARK' ?
                                    suggestion.active ?
                                        style = { backgroundColor: '#ffffff', cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                                        :
                                        style = { backgroundColor: '#4f575f', cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                                    :
                                    suggestion.active ?
                                        style = { backgroundColor: '#fafafa', cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
                                        :
                                        style = { backgroundColor: '#ffffff', cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }


                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        )
    }
}
export default AutoCompletePlaces;