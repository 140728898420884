import Contacts from "../nosql/contacts/contacts.js";
import Items from "../nosql/items/items.js";
import Receipts from "../nosql/receipts/receipts.js";
import Quality from "../nosql/quality/quality.js"
import Subscribers from "./subscribers/subscribers.js";
import EPhoneBook from './versionControl/ePhoneBook';
import VersionControl from './versionControl/versionControl';
// import StepDB from './templates/steps';
import TemplatesDB from './templates/templates';
import TemplatesConsensusDB from './templates/consensus';
import TemplateConsensusReportDB from './templates/reports-templateConsensusV2'
import TransactionsDB from './transactions/index'
// import Sync from './sync';
import Reconciliation from "./dashboard/reconciliation.js";
import POReconciliation from "./dashboard/poReconciliation.js";
import ResolveReconciliation from "./dashboard/resolveReconciliation.js";
import InvoiceReconciliation from "./dashboard/invoiceReconciliation.js";

import Console from '../../logger/index';
// import LinkedInvoices from './receipts/linkedInvoices';
// import Notifications from './notifications/notifications';
// import ExecuteQuery from './queryConsole/executeQuery';
// import KnowledgeGraph from './knowledgeGraph/knowledgeGraph';
// import Helper from './templates/reports/helper';
import SearchDB from './searchDB/index';
import Grn from './grn/grn';
import VendorManagement from './vendorManagement/index';
import Returns from './receipts/returns';
import AcceptNote from './acceptNote/acceptNote';
import PartPO from './partPurchaseOrder/partPurchaseOrder';
// // import GraphDB_NON_IRN from './nonIrn/index';
// import { MongoClient, Db, Server } from 'mongodb';

// import VersionControlDBEvents from "./events/version-control.js";
import ContactDBEvents from "./events/contacts.js";
import CatalogueDBEvents from "./events/catalog.js";
import InventoryDBEvents from "./events/inventory.js";
import ReceiptDBEvents from "./events/receipt.js";
import TemplateRepoDBEvents from "./events/template-repo.js"
import TemplateConsensusDBEvents from "./events/template-cons.js"
import VendorDBEvents from "./events/vendor.js"
import ReturnsDBEvents from "./events/returns.js";
import SubscriberDBEvents from "./events/subscriber.js";
import HTTPMongoConnector from './connector/http-mongo-connector';
// import Sync from "./sync/index.js";

class NoSQLDatabase {

    constructor(address) {
        if (NoSQLDatabase.instance) {
            throw new Error("You can't create object. Use Database.getInstance()");
        }
        this.setParamId(address);
        this.mongoConnector = new HTTPMongoConnector();
    }

    static getInstance(address) {
        if (!NoSQLDatabase.instance) {
            NoSQLDatabase.instance = new NoSQLDatabase(address);
            Console.info("Creating Database instance");
        }
        return NoSQLDatabase.instance;
    }

    initDatabase(URL, paramNetwork, serverPort) {
        if (this.mongoClient) {
            return Promise.resolve();
        }

        this.paramNetwork = paramNetwork;
        //complete this
        return this.mongoConnector.initDatabase(URL, this.paramId, serverPort).then((res) => {
            this.mongoClient = res;
            Console.log("DATABASE INITIALIZED", res);
            this.initObjects(this.mongoConnector);
            this.registerEvents();
            return this.mongoClient;
        }).catch((err) => {
            Console.error(err);
        })
    }

    setParamId(paramId) {
        this.paramId = paramId;
    }

    resetConnection() {
        this.mongoClient = null;
        this.paramId = null;
        return this.mongoConnector.resetDB();
    }

    initObjects(mongoDatabase) {
        this.versionControl = new VersionControl(mongoDatabase);
        this.ePhoneBook = new EPhoneBook(mongoDatabase);
        this.contacts = new Contacts(mongoDatabase);
        this.items = new Items(mongoDatabase);
        this.receipts = new Receipts(mongoDatabase);
        this.subscribers = new Subscribers(mongoDatabase);
        this.templateRepoDB = new TemplatesDB(mongoDatabase);
        // this.stepsRepoDB = new StepDB(mongoDatabase);
        this.templateConsensusDB = new TemplatesConsensusDB(mongoDatabase);
        this.templateConsensusReportDB = new TemplateConsensusReportDB(mongoDatabase);
        this.reconciliation = new Reconciliation(mongoDatabase);
        //this.notifications = new Notifications(mongoDatabase);
        this.transactionsDB = new TransactionsDB(mongoDatabase);
        // this.linkedInvoices = new LinkedInvoices(mongoDatabase);
        // this.executeQuery = new ExecuteQuery(mongoDatabase);
        // this.knowledgeGraph = new KnowledgeGraph(mongoDatabase);
        // this.templateHelper = new Helper(mongoDatabase);
        this.searchDB = new SearchDB(mongoDatabase);
        this.grn = new Grn(mongoDatabase);
        this.acceptNote = new AcceptNote(mongoDatabase);
        this.partPurchaseOrder = new PartPO(mongoDatabase);
        this.returns = new Returns(mongoDatabase);
        this.vendorManagement = new VendorManagement(mongoDatabase);
        this.poReconciliation = new POReconciliation(mongoDatabase);
        this.resolveReconciliation = new ResolveReconciliation(mongoDatabase);
        this.invoiceReconciliation = new InvoiceReconciliation(mongoDatabase);
        this.quality = new Quality(mongoDatabase);


        //testingpurpose
        /* *************** IRN ************************/
        // this.graphDB_IRN = GraphDB_IRN.getInstance();
        /* *************** NON-IRN ************************/
        // this.graphDB_ReconciliationDB = GraphDB_NON_IRN.getInstance();

    }

    registerEvents() {
        Console.info(`[MongoDB] registering to the events`)
        this.contactDBEvents = new ContactDBEvents()
        // this.contactDBEvents.registerEvents("0xDe3D2d87C02657A53fC379b4d45eeD9188266856", this.paramId)

        this.receiptDBEvents = new ReceiptDBEvents()
        // this.receiptDBEvents.registerEvents("0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", this.paramId)

        this.catalogueDBEvents = new CatalogueDBEvents()
        // this.catalogueDBEvents.registerEvents("0xa5f126CFF0835db28DecdDb833157B19f53e733d", this.paramId)

        this.inventoryDBEvents = new InventoryDBEvents()
        // this.inventoryDBEvents.registerEvents("0xf70451c209fD3f767984d2F0fb6f000aeD4b10D1", this.paramId)

        this.returnsDBEvents = new ReturnsDBEvents()
        // this.returnsDBEvents.registerEvents("0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15", this.paramId)

        this.templateRepoDBEvents = new TemplateRepoDBEvents()
        // this.templateRepoDBEvents.registerEvents("0x65253d2c3C601E2cE2a00BB25bC192c905F85bdc", this.paramId)

        this.templateConsensusDBEvents = new TemplateConsensusDBEvents()
        // this.templateConsensusDBEvents.registerEvents("0xfbEe16E2d7c36c24455039702051798B073ed91a", this.paramId)

        this.vendorDBEvents = new VendorDBEvents()
        // this.vendorDBEvents.registerEvents("0xAD36bcd5Ec0408826e5Fe4aD81A822A36794Ef46", this.paramId)

        this.subscriberDBEvents = new SubscriberDBEvents()
        // this.subscriberDBEvents.registerEvents("0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815", this.paramId)


        /*return this.ePhoneBook.getAllNodes().then(data => {
            if (data.length === 0) {
                return Sync.syncMasterNodes(this.paramNetwork);
            }
            return data;
        }).then(data => {
            this.versionControlDBEvents = new this.versionControlDBEvents()
            this.versionControlDBEvents.registerEvents(this.getVersionControlManager(), this.getNodeInfoManager());
            return this.getContactManagerV1(this.paramId);
        }).then(contactAddress => {
            this.contactDBEvents = new ContactDBEvents()
            if (contactAddress && contactAddress[0]) {
                this.contactDBEvents.registerEvents(contactAddress[0], this.paramId)
            }
            return this.getCatalogueManagerV1(this.paramId);
        }).then((addresses) => {
            // addresses = ["0xa5f126CFF0835db28DecdDb833157B19f53e733d"];
            this.catalogDBEvents = new CatalogueDBEvents()
            if (addresses && addresses[0]) {
                this.catalogDBEvents.registerEvents(addresses[0], this.paramId)
            }
            return this.getInventoryManagerV1(this.paramId);
        }).then((addresses) => {
            this.inventoryDBEvents = new InventoryDBEvents()

            if (addresses && addresses[0]) {
                this.inventoryDBEvents.registerEvents(addresses[0], this.paramId)
            }
            return this.getReceiptManagerV1(this.paramId);
        }).then((addresses) => {
            addresses = ["0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815"]
            this.receiptDBEvents = new ReceiptDBEvents()
            for (let index in addresses) {
                let contractAddress = addresses[index];
                this.receiptDBEvents.registerEvents(contractAddress, this.paramId)
            }
            return this.getTemplateRepoManagerV1(this.paramId);
        }).then((templateRepoAddresses) => {
            this.templateRepoDBEvents = new TemplateRepoDBEvents()
            for (let index in templateRepoAddresses) {
                this.templateRepoDBEvents.registerEvents(templateRepoAddresses[index], this.paramId)
            }
            return this.getTemplateConsManagerV1(this.paramId);
        }).then(templateConsAddresses => {
            templateConsAddresses = ["0xB140C443fE41277f71C6a9750C83f3b37Fb47680"]
            this.templateConsensusDBEvents = new TemplateConsensusDBEvents()
            for (let index in templateConsAddresses) {
                this.templateConsensusDBEvents.registerEvents(templateConsAddresses[index], this.paramId)
            }
            return this.getSubscriberManagerV1(this.paramId);
        }).then(addresses => {
            addresses = ["0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815"];
            this.subscriberDBEvents = new SubscriberDBEvents()
            if (addresses && addresses[0]) {
                this.subscriberDBEvents.registerEvents(addresses[0], this.paramId)
            }
            return this.getVendorManagerV1(this.paramId);
        }).then(addresses => {
            // addresses = ["0xAD36bcd5Ec0408826e5Fe4aD81A822A36794Ef46"];
            this.vendorDBEvents = new VendorDBEvents()
            if (addresses && addresses[0]) {
                this.vendorDBEvents.registerEvents(addresses[0], this.paramId)
            }
            return this.getReturnsManagerV1(this.paramId);
        }).then(addresses => {
            addresses = ["0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15"];
            this.returnsRequestDBEvents = new ReturnRequestDBEvents()
            for (let index in addresses) {
                this.returnsRequestDBEvents.registerEvents(addresses[index], this.paramId)
            }
        }).catch(e => {
            Console.log("Error:", e)
        })*/
    }

    getEvents() {
        if (!this.events) {
            this.initEvents();
        }
        return this.events;
    }

    emitEvent(eventName, ...args) {
        this.getEvents().emit(eventName, args);
    }

    initEvents() {
        if (this.events) {
            return;
        }
        const events = require("events");
        this.events = new events.EventEmitter();
        Console.info(`[GraphDB] init events`);
    }

    getTransactionInfo(transactionHash) {
        return new Promise((resolve, reject) => {
            this.paramNetwork.web3.eth.getTransaction(`${transactionHash}`, (error, result) => {
                if (error) {
                    return reject(error);
                }
                resolve(result);
            });
        })
    }

    _onEmailSend(error, dataJSON) {
        Console.log("_onEmailSend event triggered", error, dataJSON);
    }

    // updateLastSync() {
    //     let lastSyncTime = new Date().getTime()
    //     utils.setLocalStorage("lastSync", lastSyncTime);
    // }

    // latestSync() {
    //     let lastSync = utils.getFromLocalStorage("lastSync");
    //     if (!lastSync) {
    //         this.updateLastSync();
    //         lastSync = utils.getFromLocalStorage("lastSync");
    //     }
    //     return {
    //         milliSecs: utils.getFromLocalStorage("lastSync")
    //     };
    // }

    getContactManagerV1(address) {
        // return this.versionControl.getLatestContractAddressFromName(address, "ParamContactImpl")
        return "0xDe3D2d87C02657A53fC379b4d45eeD9188266856";
    }

    getCatalogueManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "ParamCatalogueImpl")
    }
    getInventoryManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "ParamInventoryImpl")
    }

    getVendorManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "ParamVendors")
    }

    getSubscriberManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "ParamContract")
    }

    getReceiptManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "ParamContract")
    }

    getTemplateRepoManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "TemplateRepositoryImpl")
    }

    getTemplateConsManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "TemplateConsensusImpl")
    }

    getReturnsManagerV1(address) {
        return this.versionControl.getLatestContractAddressFromName(address, "ParamReturns")
    }

    getContactManager(contractAddress) {
        return this.paramNetwork.getContactManager(contractAddress);
    }

    getCatalogueManager(catalogueAddress) {
        return this.paramNetwork.getCatalogueManager(catalogueAddress);
    }

    getInventoryManager(catalogueAddress) {
        return this.paramNetwork.getInventoryManager(catalogueAddress);
    }

    getReceiptManager(receiptAddress) {
        return this.paramNetwork.getReceiptManager(receiptAddress);
    }

    getSubscriberManager(paramSubscribersAddress) {
        return this.paramNetwork.getReceiptManager(paramSubscribersAddress);
    }

    getTemplateRepoManager(templateAddress) {
        return this.paramNetwork.getTemplateRepoManager(templateAddress);
    }

    getVendorManager(vendorManagerAddress) {
        return this.paramNetwork.getVendorManager(vendorManagerAddress);
    }

    getTemplateConsManager(consManager) {
        return this.paramNetwork.getTemplateConsManager(consManager);
    }

    getNodeInfoManager(enterpriseAddress) {
        return this.paramNetwork.getNodeInfoManager(enterpriseAddress);
    }

    getVersionControlManager(configAddress) {
        return this.paramNetwork.getVersionControlManager(configAddress);
    }

    getEmailManager() {
        return this.paramNetwork.getEmailManager();
    }

    getReturnsManager(returnsContractAddress) {
        return this.paramNetwork.getReturnsManager(returnsContractAddress);
    }
}

export default NoSQLDatabase;