
class Templates {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getAllTemplates(options) {
        return this.mongoDBConnector.invoke('templateRepoDB', "getAllTemplates", options)
    }

    getAllTemplatesForSubscriber(options) {
        return this.mongoDBConnector.invoke('templateRepoDB', "getAllTemplatesForSubscriber", options)
    }

    getTemplateName = (templateId) => {
        return this.mongoDBConnector.invoke('templateRepoDB', "getTemplateName", templateId)
    }

    getTemplate = (templateId) => {
        return this.mongoDBConnector.invoke('templateRepoDB', "getTemplate", templateId)
    }

    getStepIdsByTemplateId(templateId) {
        return this.mongoDBConnector.invoke('templateRepoDB', "getStepIdsByTemplateId", templateId)
    }

    getTemplateIdByConsId(templateByConsId) {
        return this.mongoDBConnector.invoke('templateRepoDB', "getTemplateIdByConsId", templateByConsId)
    }

    getTemplatesByReceiptId(receiptId) {
        return this.mongoDBConnector.invoke('templateRepoDB', "getTemplatesByReceiptId", receiptId)
    }

    getSummary(templateId) {
        return this.mongoDBConnector.invoke('templateRepoDB', "getSummary", templateId)
    }

    doesExist(templateId) {
        return this.mongoDBConnector.invoke('templateRepoDB', "doesExist", templateId)
    }

    isInstalled(templateId) {
        return this.mongoDBConnector.invoke('templateRepoDB', "isInstalled", templateId)
    }


    installTemplate(templateId) {
        return this.mongoDBConnector.invoke('templateRepoDB', "installTemplate", templateId)
    }

    addTemplate(templateId, jsonLd, owner, name, stepIds, title, metaData, templateStepsInfo) {
        return this.mongoDBConnector.invoke('templateRepoDB', "addTemplate", templateId, jsonLd, owner, name, stepIds, title, metaData, templateStepsInfo)
    }

    addSubscribers(templateId, subscribers, isShared) {
        return this.mongoDBConnector.invoke('templateRepoDB', "addSubscribers", templateId, subscribers, isShared)
    }

    addStepsToTemplate(templateId, stepIds) {
        return this.mongoDBConnector.invoke('templateRepoDB', "addStepsToTemplate", templateId, stepIds)
    }

    updateStepsToTemplate(templateId, stepIds) {
        return this.mongoDBConnector.invoke('templateRepoDB', "updateStepsToTemplate", templateId, stepIds)
    }

    updateTemplate(templateId, jsonLd, owner, name, stepIds, title, metaData, templateStepsInfo) {
        return this.mongoDBConnector.invoke('templateRepoDB', "updateTemplate", templateId, jsonLd, owner, name, stepIds, title, metaData, templateStepsInfo)
    }
}
export default Templates;