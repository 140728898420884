import React from 'react';
import BaseComponent from '../BaseComponent';
import ParamConnector from '../../../param-connector';
import { Row, Col, Collapse, Form, Input, message } from 'antd';
import ReactJson from 'react-json-view';
import * as Utils from '../../../util/utils';
import IconComponent from '../../../components/IconComponent';
import Analytics from '../../../analytics';
const { Panel } = Collapse;
const modules = {
    "purchasesOverview": "",
    "salesOverview": "",
    "financesOverview": "",
    "customerInsights": "",
    "supplierDelivery": "",
    "commonCharts": "",
    "graphs": "",
}

class TestChartsBackend extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            output: {},
            showLoader: true
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    onDBReady = () => {
        this.setState({ showLoader: false });
    }

    getParams = (func) => {

        // String representaation of the function code 
        var str = func.toString();

        // Remove comments of the form /* ... */ 
        // Removing comments of the form // 
        // Remove body of the function { ... } 
        // removing '=>' if func is arrow function  
        str = str.replace(/\/\*[\s\S]*?\*\//g, '')
            .replace(/\/\/(.)*/g, '')
            .replace(/{[\s\S]*}/, '')
            .replace(/=>/g, '')
            .trim();

        // Start parameter names after first '(' 
        var start = str.indexOf("(") + 1;

        // End parameter names is just before last ')' 
        var end = str.length - 1;

        var result = str.substring(start, end).split(", ");

        var params = [];

        result.forEach(element => {

            // Removing any default value 
            element = element.replace(/=[\s\S]*/g, '').trim();

            if (element.length > 0)
                params.push(element);
        });

        return params;
    }

    handleSubmit = (event, module, functionName, parameters) => {
        event.stopPropagation();
        let that = this;

        const inputFields = parameters.map((value) => {
            return `${module}_${functionName}_${value}`;
        })

        this.props.form.validateFieldsAndScroll(inputFields, (err, values) => {
            if (err) {
                message.error("Please fill all required fields");
                return;
            }
            let callArguments = []
            for (let index in values) {
                callArguments.push(values[index]);
            }
            return that.charts[module][functionName](...callArguments).then(res => {
                console.log(res);
                this.setState({ output: { result: res } });
            }).catch(err => {
                console.error(err);
                this.setState({ output: { error: err } });
            })
        })
    }

    getCollapsePane = () => {
        const config = Utils.getConfigObject();
        const { getFieldDecorator } = this.props.form;
        this.charts = ParamConnector.getInstance(config).charts;
        let renderNode = [], nodeKey = 0;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 6 },
                lg: { span: 6 },
                xl: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 18 },
                lg: { span: 18 },
                xl: { span: 18 }
            },
        };

        for (let module in modules) {
            let header = <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ lineHeight: 3 }}>{module}</Col>
            </Row>
            let functionInfos = [];
            for (let functionName in this.charts[module]) {
                let insideNode = [];
                if (typeof (this.charts[module][functionName]) === 'function') {
                    const parameters = this.getParams(this.charts[module][functionName]);
                    insideNode = parameters.map((parameterName) => {
                        return <Col span={24}>
                            <Form.Item label={parameterName}>
                                {getFieldDecorator(`${module}_${functionName}_${parameterName}`, {
                                    rules: [{
                                        message: `please input valid ${parameterName}`
                                    }]
                                })
                                    (<Input placeholder={parameterName} />)
                                }
                            </Form.Item>
                        </Col>
                    })
                    // if (insideNode.length > 0) {
                    functionInfos.push(
                        <Panel header={functionName} extra={<IconComponent hoverText="run" actionIcon="true" type="play-circle" onClick={(e) => this.handleSubmit(e, module, functionName, parameters)} />}>
                            <Row gutter={24}>
                                {insideNode}
                            </Row>
                        </Panel>
                    )
                    // }
                }
            }
            renderNode.push(
                <Panel header={header} key={nodeKey++}>
                    <Form {...formItemLayout} >
                        <Collapse>
                            {functionInfos}
                        </Collapse>
                    </Form>
                </Panel>
            )
        }
        return <Collapse className="testsmartcontracts-collapse">{renderNode}</Collapse>
    }

    renderMainContent() {
        return (
            <div>
                <Row className="testsmartcontracts" gutter={24}>
                    <Col span={15}>
                        <h1>Test Charts</h1>
                        {this.getCollapsePane()}
                    </Col>
                    <Col span={9} className="testsmartcontracts-output">
                        <ReactJson src={this.state.output} />
                    </Col>
                </Row>
            </div>
        )
    }
}

const TestChartsBackendForm = Form.create()(TestChartsBackend)
export default TestChartsBackendForm;