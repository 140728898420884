import React, { PureComponent } from "react";
import { Input, Icon, Button, message, Card, Form, Divider } from "antd";
import "./index.less";
import { dbURL } from '../../constants/AppConstants';
import * as ConfigObj from '../../param-network/config1.json';
import * as Utils from '../../util/utils';
import Web3Utils from 'web3-utils';

class SelectAccount extends PureComponent {
    constructor(props) {
        super(props);
        this.configObj = ConfigObj.privateNodes;

        this.state = {
            accounts: Utils.getFromLocalStorage('accounts'),
            selectedAccount: {}
        }
    }
    selectAccount = (account) => {
        let email = Utils.getFromLocalStorage('accountNo')
        console.log("Account selected: ", account.paramId)
        Utils.setLocalStorage("encryptedMnemonic", account.mnemonic);
        Utils.setLocalStorage("privateKey", account.privateKey);
        Utils.setLocalStorage("publicKey", account.publicKey);
        Utils.setLocalStorage("param_id", Web3Utils.toChecksumAddress(account.paramId));
        Utils.setLocalStorage('organizationId', "");
        Utils.setLocalStorage("url", "ws://34.224.243.54:8546");
        Utils.setLocalStorage('dbURL', dbURL);
        Utils.setLocalStorage('privateUrl', this.configObj[0].privateUrl);
        Utils.setLocalStorage('configObj', JSON.stringify(this.setConfigObj));
        Utils.setLocalStorage('versionControl', "0x22AD1FF3161D6D40EC7503cB88A4028Aa983d139");
        let jsonLd = {
            "@context": "http://schema.org/",
            "@type": "Person",
            "name": " ",
            "identifier": account.paramId,
            "address": {
                "@type": "PostalAddress",
                "addressLocality": undefined,
                "addressRegion": undefined,
                "postalCode": undefined,
                "streetAddress": undefined
            },
            "birthDate": undefined,
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": undefined,
                "contactType": undefined,
                "contactOption": undefined,
                "areaServed": undefined
            },
            "gender": undefined,
            "globalLocationNumber": undefined,
            "taxId": undefined,
            "vatId": undefined,
            "faxNumber": undefined,
            "email": email,
            "telephone": undefined,
            "url": undefined,
            "internalId": undefined,
            "hasOccupation": [
            ],
            "jobTitle": {
                "@type": "DefinedTerm",
                "inDefinedTermSet": undefined,
                "termCode": undefined,
                "name": undefined,
                "url": undefined
            },
            "worksFor": {
                "@type": "Organization",
                "name": undefined
            },
            "nationality": {
                "@type": "Country",
                "name": "IN"
            },
            "logoUrl": undefined,
            "city": undefined,
            "publicKey": undefined
        }
        Utils.setLocalStorage('profile', JSON.stringify(jsonLd))
        this.props.history.push('/main/landing?init')
        message.success("Success");
    }

    render() {
        console.log(">>>>", this.state.selectedAccount)
        return (
            <Card className="login" style={{ scrollBehavior: "auto" }}>
                <p className="gx-fs-xxxl gx-font-weight-medium gx-mb-4 login_welcomeText">Select an Account</p>
                {
                    this.state.accounts.map((account) => (
                        <div >
                            <p>Nick Name</p>
                            <p onClick={() => { this.selectAccount(account) }} style={{ cursor: "pointer" }}>{account.paramId}</p>
                            <Divider></Divider>
                        </div>
                    ))
                }
                {/* <Button onClick={this.selectAccount}>Proceed</Button> */}
            </Card>
        )
    }
}
export default SelectAccount