
import React, { Component } from 'react';
import { Tooltip, Icon } from 'antd';
import './index.less';

// const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />;

class IconComponent extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        let className = "iconStyle";
        const { tooltipPlacement, hoverText, disabled, component, type, style, theme, onClick, actionIcon, parentStyle } = this.props;
        if (actionIcon) {
            className = "iconStyle iconComponent-actionIcon"
        }
        return (
            <div className="iconComponent" style={parentStyle}>
                <Tooltip placement={tooltipPlacement} title={hoverText}>
                    <Icon disabled={disabled} component={component} className={className} type={type} style={style} theme={theme} onClick={onClick} />
                </Tooltip>
            </div>
        )
    }
}
export default IconComponent;

