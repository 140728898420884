
import React from 'react';
import { Row, Table, Drawer, Popconfirm, Checkbox, Input, Select, Button, Form, message } from "antd";
import './index.less';
import IconComponent from '../../../components/IconComponent';
import MetaInfoUtils from './utils/utils';

const { Option } = Select;

class AddMetaInfo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDrawerVisible: false,
            dataSource: [
                {
                    key: 1,
                    name: '',
                    dataType: 'input',
                    isRequired: true,
                    newAdded: true,
                    defaultValue: ''
                }
            ]
        }

        this.columns = [
            {
                dataIndex: 'operation',
                width: '10%',
                render: (text, row, index) => {
                    return <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(row.key)}>
                        <IconComponent actionIcon={true} hoverText="Delete" type="delete" />
                    </Popconfirm>
                }
            },
            {
                title: 'Field',
                dataIndex: 'name',
                width: '30%',
                render: (value, row, index) => {
                    return row.newAdded ?
                        <Input
                            defaultValue={row.name}
                            placeholder="Custom Field"
                            onChange={(e) => this.handleDataChange(e.target.value, index, "name")}
                        />
                        :
                        row.name
                }
            },
            {
                title: 'Data Type',
                dataIndex: 'dataType',
                width: '25%',
                render: (rowValue, row, index) => {
                    return row.newAdded ?
                        <Select
                            style={{ width: 120 }}
                            defaultValue={rowValue || 'input'}
                            onChange={(value) => { this.handleDataChange(value, index, "dataType") }}
                        >
                            <Option key="input" value="input">Input</Option>
                            <Option key="textArea" value="textArea">Text Area</Option>
                            <Option key="number" value="number">Number</Option>
                            {
                                !this.props.fromItemDetailsPage &&
                                <Option key="radio" value="radio">Radio Button</Option>
                            }
                            {
                                !this.props.fromItemDetailsPage &&
                                <Option key="dropdown" value="dropdown">Dropdown</Option>
                            }
                            <Option key="date" value="date">Date</Option>
                            <Option key="phone" value="phone">Phone</Option>
                        </Select>
                        :
                        rowValue
                }
            },
        ]
        if (!this.props.fromItemDetailsPage) {
            this.columns.push(
                {
                    title: 'Default Value',
                    dataIndex: 'defaultValue',
                    width: '25%',
                    render: (value, row, index) => {
                        return row.newAdded ?
                            <Input
                                placeholder="Default Value"
                                defaultValue={value}
                                onChange={(e) => this.handleDataChange(e.target.value, index, "defaultValue")}
                            />
                            :
                            row.defaultValue
                    }
                }
            )
        }
        this.columns.push(
            {
                title: 'Required',
                dataIndex: 'isRequired',
                width: '10%',
                render: (value, row, index) => {
                    let checked;
                    if (typeof row.isRequired != "boolean") {
                        checked = (row.isRequired === 'true');
                    } else {
                        checked = row.isRequired;
                    }
                    return <Checkbox onChange={(e) => this.handleDataChange(e.target.checked, index, "isRequired")} checked={checked} />
                }
            }
        )
    }

    componentWillReceiveProps(newProps) {
        if (newProps.dataSource) {
            this.setState({
                dataSource: newProps.dataSource
            })
        }
        this.setState({
            isDrawerVisible: newProps.isDrawerVisible
        })
    }

    handleDataChange = (value, index, field) => {
        let dataSource = this.state.dataSource;
        dataSource[index][field] = value;
        this.setState({
            dataSource
        }, () => {
            console.log(this.state.dataSource);
        })
    }

    handleDelete = (key) => {
        let dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    };

    hideDrawer = () => {
        this.setState({ isDrawerVisible: false })
        setTimeout(() => {
            this.props.onClose();
        }, 1000)
    }

    showDetailDrawer = (index) => {
        this.setState({
            isDrawerVisible: true
        })
    }

    addRow = () => {
        let dataSource = [...this.state.dataSource];
        let newRow = {
            key: dataSource.length + 1,
            dataType: 'input',
            newAdded: true,
            isRequired: true,
            defaultValue: ''
        }
        dataSource.push(newRow);
        this.setState({
            dataSource
        })
    }

    createDynamicForm = () => {
        let dataSource = this.state.dataSource;
        for (let formItem of dataSource) {
            if (formItem.name === "") {
                message.error("Please fill all the labels before pressing submit");
                return;
            }
        }
        const { getFieldDecorator } = this.props.form;
        let formItem = dataSource.map((data, index) => {
            let label = MetaInfoUtils.getLabel(data)
            return (
                <Form.Item label={label} key={index} className="form-item">
                    {/* {getFieldDecorator(label, {
                        rules: [{
                            required: data.isRequired, message: `Please input ${label}`
                        }],
                        initialValue: data.defaultValue
                    })( */}
                       { MetaInfoUtils.getInputBox(data, index, this.props.onChangeDynamicFormData)}
                    {/* )} */}
                </Form.Item>
            )
        })
        this.setState({
            formItem
        }, () => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                let metaInfoForm = { err, values }
                this.props.getDynamicFormItem(formItem, dataSource, metaInfoForm);
                this.hideDrawer();
            })

        })
    }

    render() {
        const title = <h2 style={{ margin: 0 }}>ADD MORE INFO</h2>
        return (
            <Row>
                <Drawer
                    title={title}
                    onClose={this.hideDrawer}
                    visible={this.state.isDrawerVisible}
                >
                    <Table
                        dataSource={this.state.dataSource}
                        columns={this.columns}
                        pagination={false}
                        className="gx-mb-4"
                    />
                    <a onClick={this.addRow}>+ Add More</a>
                    <br />
                    <Button onClick={this.createDynamicForm} type="primary" className="meta-submit">Submit</Button>
                </Drawer>
            </Row>
        )
    }
}

// export default AddMetaInfo;
const WrappedMetaInfo = Form.create()(AddMetaInfo)
export default WrappedMetaInfo;