import Database from '../../../database/index';
import ECIES from '../../../util/ecies';
import * as NetworkUtils from '../../../util/utils';
import ParamConnector from '../../../param-connector'
import Console from '../../../logger/index';
import * as DBUtils from '../../../database/nosql/Utils/utils';
import GraphQL from '..';

class RestoreReturns {
    static restoreReturns(returnsId, contractAddress, returnsKey, parentContractAddress) {
        let returnsDBInstance = Database.getInstance().getDB().returns;
        // let returnsIdInfo = NetworkUtils.getTransactionData(returnsId);
        let returnsData;
        return GraphQL.getInstance().returns.getReturn(returnsId).then(res => {
            returnsData = res;
            let privateKey = returnsKey || NetworkUtils.getFromLocalStorage("privateKey");
            if (returnsData.txnMode === "2") {
                try {
                    let temp = ECIES.decrypt(privateKey, returnsData.jsonLd);
                    Console.log(temp);
                    returnsData.jsonLd = temp;
                } catch (error) {
                    returnsData.jsonLd = { noAccess: true }
                    returnsData.jsonLd = JSON.stringify(returnsData.jsonLd);
                }
            }
            return returnsDBInstance.doesExist(returnsId)
                .then(doesExist => {
                    if (doesExist) {
                        return returnsDBInstance.updateStatus(returnsId, returnsData.status, returnsData.step);
                    }
                    const parentId = NetworkUtils.getTransactionId(returnsData.parentId, returnsData.docContractAddress || parentContractAddress);
                    return returnsDBInstance.addReturns(returnsId, parentId, returnsData.sellerId, returnsData.buyerId, returnsData.jsonLd, returnsData.metaData, returnsData.txnMode, returnsData.status, returnsData.step);
                }).catch(e => {
                    Console.error(`[Database] Error in restoring returns [Reason] ${e} [Module] : Sync/returns/restoreReturns`);
                });
        })
    }

    static restoreAllReturns(functionName, participantAddress, contractAddress, callback) {
        return GraphQL.getInstance().returns[functionName](participantAddress).then(returnsIds => {
            let promise, promiseArray = [];
            for (let index in returnsIds) {
                const returnsId = returnsIds[index]
                contractAddress = NetworkUtils.getTransactionData(returnsId).address
                promise = RestoreReturns.restoreReturns(returnsId, contractAddress);
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring returns [Reason] ${e} [Module] : Sync/returns/restoreReturns`);
        });
    }
}
export default RestoreReturns;