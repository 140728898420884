import ParamConnector from '../../param-connector';
import * as Utils from '../utils';

class WorkflowReport {

    static getConsensusNumbers(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getConsensusNumbers(templateId)
    }
    static getTotalStepConsensus(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getTotalStepConsensus(templateId)
    }

    static getAverageCycleTime(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getAverageCycleTime(templateId)
    }

    static getCompletionStatus(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getCompletionStatus(templateId)
    }

    static getConsensusDetails(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.getConsensusDetails(templateId);
    }

    static averageTaskCompletionCycleTime(templateId) {
        let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
        return workflowReportDB.averageTaskCompletionCycleTime(templateId);
    }

    // static createDefaultGraphs(templateId) {
    //     let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
    //     return workflowReportDB.createDefaultGraphs(templateId);
    // }

    // static isDefaultGraphPresent(templateId) {
    //     let workflowReportDB = ParamConnector.getInstance().getDB().templateConsensusReportDB;
    //     return workflowReportDB.isDefaultGraphPresent(templateId);
    // }
}
export default WorkflowReport;