import { gql } from '@apollo/client';
import { toLength } from 'lodash';
import * as Utils from '../../../../src/util/utils';
class Returns {
    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }

    getReturnSummary = (requestId, predicates) => {
        let options = {
            query: gql`
            getReturn(returnID:"${requestId}"){
                returnID,
                buyerID,
                sellerID,
                owner,
                jsonLd,
                parentID,
                status,
                step,
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let returnResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return returnResult
            }
            returnResult['receiptId'] = requestId
            returnResult['_id'] = requestId
            returnResult['status'] = graphQLResult.data.getReturn['status']
            returnResult['step'] = graphQLResult.data.getReturn['step']
            returnResult['parentDocId'] = graphQLResult.data.getReturn['parentID']
            let returnJson = JSON.parse(graphQLResult.data.getReturn['jsonLd'])
            returnResult['customerName'] = returnJson['customer']['name']
            returnResult['providerName'] = returnJson['provider']['name']
            returnResult['amount'] = returnJson['totalPaymentDue']['price']
            returnResult['currency'] = returnJson['totalPaymentDue']['currency']
            returnResult['date'] = returnJson['referencesOrder']['orderDate']
            returnResult['returnRequestNumber'] = returnJson['returnRequestNumber']
            returnResult['docInternalId'] = returnJson['returnRequestNumber']
            //parentDocInternalId
            return returnResult;
        })
    }

    getReturnSummaryInBulk = (requestIds, predicates) => {
        let promiseArray = []
        for (let index in requestIds) {
            promiseArray.push(this.getReturnSummary(requestIds[index]), predicates)
        }
        return Promise.all(promiseArray)
    }

    getAllReceiptsByParent = (requestId) => {
        // return {
        //     "receipts": [
        //         "0xf983cde4e05929229d8829930991f45a3bcee8456c0a3c2fbe5dcc88a4aa08a5-0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15",
        //         "0x142dbb58adc2881c529aa88d2221ba0c423cbfe71291a539ef13df080be00fe3-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815"
        //     ],
        //     "count": 2
        // }
        let options = {
            query: gql`
            getReturn(returnID:"${requestId}"){
                owner,
                jsonLd,
            }
            `
        }
        let returnResult = {
            receipts: [],
            count: 0
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return returnResult
            }
            let returnJson = JSON.parse(graphQLResult.data.getReturn['jsonLd'])
            returnResult.receipts = returnJson.childDocId
            returnResult.count = returnResult.receipts.length
            return returnResult;
        })
    }

    getAllReceiptsByParentV1 = (requestId) => {
        // return {
        //     "receipts": [
        //         {
        //             "id": "0xf983cde4e05929229d8829930991f45a3bcee8456c0a3c2fbe5dcc88a4aa08a5-0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15",
        //             "subType": "returnRequest"
        //         },
        //         {
        //             "id": "0x142dbb58adc2881c529aa88d2221ba0c423cbfe71291a539ef13df080be00fe3-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815",
        //             "subType": "creditNote"
        //         }
        //     ],
        //     "count": 2
        // }
        let options = {
            query: gql`
            getReturn(returnID:"${requestId}"){
                owner,
                jsonLd,
            }
            `
        }
        let returnResult = {
            receipts: [],
            count: 0
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return returnResult
            }
            let returnJson = JSON.parse(graphQLResult.data.getReturn['jsonLd'])
            returnResult.receipts = returnJson.childDocInfos
            returnResult.count = returnResult.receipts.length
            return returnResult;
        })
    }

    getItemQuantitySummary = (requestId) => {
        // return {
        //     "0x824d2f153b6585be3f8725e65fe6befa430a4d1b79d1d6a78cb362afd41c3f22-0xa5f126CFF0835db28DecdDb833157B19f53e733d": "1",
        //     "0xf45e96861c93abac2e5a2e2dbe703b369c760f88363bdafc47deb7c92541cd82-0xa5f126CFF0835db28DecdDb833157B19f53e733d": "1"
        // }
        let options = {
            query: gql`
            getReturn(returnID:"${requestId}"){
                owner,
                jsonLd,
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let returnResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return returnResult
            }
            let returnJson = JSON.parse(graphQLResult.data.getReturn['jsonLd'])
            let referencesOrder = returnJson.referencesOrder
            for (let index in referencesOrder.orderedItem) {
                returnResult[referencesOrder.orderedItem[index].orderItemNumber] = referencesOrder.orderedItem[index].orderQuantity
            }
            return returnResult
        })
    }

    getValidationData = (requestId) => {
        let options = {
            query: gql`
            getReturn(returnID:"${requestId}"){
                owner,
                jsonLd,
                status
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let returnResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return returnResult
            }
            let status = graphQLResult.data.getReturn['status'];
            if (status === "2" || status === "4" || status === "6" || status === "9" || status === "11")
                return returnResult;
            let returnJson = JSON.parse(graphQLResult.data.getReturn['jsonLd'])
            return returnJson.referencesOrder
        })

    }
    getParentDocId = (requestId) => {
        // return "0xf983cde4e05929229d8829930991f45a3bcee8456c0a3c2fbe5dcc88a4aa08a5-0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15"
        let options = {
            query: gql`
            getReturn(returnID:"${requestId}"){
                parentID
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let returnResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return returnResult
            }
            return graphQLResult.data.getReturn['parentID']
        })
    }
    getSellerAndBuyer = (requestId) => {
        // return {
        //     "_id": "0xc85f53e1611bd401a62dc79bbedb71a8e539c3735bcde88eab1ea6a045a84195-0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15",
        //     "seller": "0x96A49902d231F68F86843b7C0B3d4a54E122F429",
        //     "buyer": "0x857E8A1dB468cECeE74d2Bb131697BEebBEE46Bd"
        // }
        let options = {
            query: gql`
            getReturn(returnID:"${requestId}"){
                buyerID,
                sellerID
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let returnResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return returnResult
            }
            returnResult['_id'] = requestId
            returnResult['seller'] = graphQLResult.data.getReturn['sellerID']
            returnResult['buyer'] = graphQLResult.data.getReturn['buyerID']
            return returnResult;
        })
    }

    doesExist = (returnId) => {
        let options = {
            query: gql`
            getReturn(returnID:"${returnId}"){
                jsonLd
            }
            `
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let res = false
            if (!graphQLResult || !graphQLResult.data) {
                return res
            }
            return true;
        })
    }



    //Query

    getAllReturnsBySeller = (sellerAddress, startIndex = 0, pageSize = 10, totalRecords = []) => {
        let options = {
            query: gql`
            query getAllReturnsBySeller($filterOptions: InputFilterOptions!){
                getAllReturnsBySeller(filterOptions: $filterOptions){
                    totalRecords,
                    IDs
                }
            }`,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: sellerAddress } }

        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllReturnsBySeller['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllReturnsBySeller["totalRecords"]) {
                return totalRecords
            }
            return this.getAllReturnsBySeller(sellerAddress, startIndex + 1, pageSize, totalRecords);
        })
    }

    getAllReturnsByBuyer = (buyerAddress, startIndex = 0, pageSize = 10, totalRecords = []) => {
        let options = {
            query: gql`
            query getAllReturnsByBuyer($filterOptions: InputFilterOptions!) {
                getAllReturnsByBuyer(filterOptions: $filterOptions){
                    totalRecords,
                    IDs
                }
            }`,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: buyerAddress } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllReturnsByBuyer['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllReturnsByBuyer["totalRecords"]) {
                return totalRecords
            }
            return this.getAllReturnsByBuyer(buyerAddress, startIndex + 1, pageSize, totalRecords);
        })
    }


    getReturn = (returnId) => {
        let options = {
            query: gql`
            query getReturn($returnId: String!) {
            getReturn(returnID: $returnId){
                returnID,
                buyerID,
                sellerID,
                owner,
                jsonLd,
                metaData,
                txnMode,
                parentID,
                status,
                step,
                docContractAddress
                }
            }
            `,
            variables: { returnId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            let returnResult = {}
            returnResult['returnID'] = graphQLResult.data.getReturn['returnID']
            returnResult['buyerId'] = graphQLResult.data.getReturn['buyerID']
            returnResult['sellerId'] = graphQLResult.data.getReturn['sellerID']
            returnResult['owner'] = graphQLResult.data.getReturn['owner']
            returnResult['metaData'] = graphQLResult.data.getReturn['metaData']
            returnResult['txnMode'] = Utils.convertGraphQLTnxType(graphQLResult.data.getReturn['txnMode'])
            returnResult['parentId'] = graphQLResult.data.getReturn['parentID']
            returnResult['status'] = graphQLResult.data.getReturn['status'].toString()
            returnResult['step'] = graphQLResult.data.getReturn['step'].toString()
            returnResult['docContractAddress'] = graphQLResult.data.getReturn['docContractAddress']
            returnResult['jsonLd'] = graphQLResult.data.getReturn['jsonLd']
            return returnResult

        })
    }

    //Mutation

    initReturnRequest(docId, buyerId, sellerId, inputObject, metaData, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation initReturnRequest($docId: String!, $buyerId: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $metaData: String, $txnMode: TRANSACTION_TYPE!) {
                initReturnRequest(docID: $docId, buyerID: $buyerId, sellerID: $sellerId, inputObject: $inputObject, metaData: $metaData, txnMode: $txnMode)            
            }`,
            variables: { docId, buyerId, sellerId, inputObject, metaData, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.initReturnRequest
        });
    }

    initReturnRequestV1(requestId, docId, buyerId, sellerId, inputObject, metaData, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation initReturnRequestV1($requestId: String!, $docId: String!, $buyerId: String!, $sellerId: String!, $inputObject: InputDocumentInfo!, $metaData: String, $txnMode: TRANSACTION_TYPE!) {
                initReturnRequestV1(requestID: $requestId, docID: $docId, buyerID: $buyerId, sellerID: $sellerId, inputObject: $inputObject, metaData: $metaData, txnMode: $txnMode)            
            }`,
            variables: { requestId, docId, buyerId, sellerId, inputObject, metaData, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.initReturnRequestV1
        });
    }


    issueReturnOrder(requestId, inputObject, metaData, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation issueReturnOrder($requestId: String!, $inputObject: InputDocumentInfo!, $metaData: String, $txnMode: TRANSACTION_TYPE!) {
                issueReturnOrder(requestID: $requestId, inputObject: $inputObject, metaData: $metaData, txnMode: $txnMode)            
            }`,
            variables: { requestId, inputObject, metaData, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.issueReturnOrder
        });
    }

    issueReturnOrderV1(returnOrderId, requestId, inputObject, metaData, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation issueReturnOrderV1($returnOrderId: String!, $requestId: String!, $inputObject: InputDocumentInfo!, $metaData: String, $txnMode: TRANSACTION_TYPE!) {
                issueReturnOrderV1(returnOrderID: $returnOrderId, requestID: $requestId, inputObject: $inputObject, metaData: $metaData, txnMode: $txnMode)            
            }`,
            variables: { returnOrderId, requestId, inputObject, metaData, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.issueReturnOrderV1
        });
    }

    acceptReturnRequest(requestId) {
        let options;
        options = {
            mutation: gql`
            mutation acceptReturnRequest($requestId: String!) {
                acceptReturnRequest(requestID: $requestId)            
            }`,
            variables: { requestId }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.acceptReturnRequest
        });
    }

    rejectReturnRequest(requestId) {
        let options;
        options = {
            mutation: gql`
            mutation rejectReturnRequest($requestId: String!) {
                rejectReturnRequest(requestID: $requestId)            
            }`,
            variables: { requestId }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectReturnRequest
        });
    }

    acceptReturnOrder(returnOrderId) {
        let options;
        options = {
            mutation: gql`
            mutation acceptReturnOrder($returnOrderId: String!) {
                acceptReturnOrder(returnOrderID: $returnOrderId)            
            }`,
            variables: { returnOrderId }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.acceptReturnOrder
        });
    }

    rejectReturnOrder(returnOrderId) {
        let options;
        options = {
            mutation: gql`
            mutation rejectReturnOrder($returnOrderId: String!) {
                rejectReturnOrder(returnOrderID: $returnOrderId)            
            }`,
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.returnOrderId
        });
    }
}
export default Returns;