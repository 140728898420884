import ParamNetwork from '../param-network/index';
import Database from '../database';
// import Charts from '../param-libs/param-dashboard/charts/index'
import Console from '../logger/index';
import ProxyUtils from '../param-network/utils/proxy-utils';

import * as Utils from '../util/utils';
import { dbURL, grapQLEndPonitUrl } from '../constants/AppConstants';
import GraphQL from '../param-libs/graph-ql';

class ParamConnector {
    constructor() {
        if (ParamConnector.instance) {
            throw new Error("You can't create object. Use ParamConnector.getInstance()");
        }
        this.dbStatus = ParamConnector.DB_NOT_INIT;
        this.isConnected = navigator.onLine;
        this.initNetworkCallback();
    }

    initNetworkCallback() {
        let that = this;
        this.primaryNetworkCallback = {
            onNetworkStateChange: (result) => {
                that._onNetwotkChanged(1, result);
            },
            onError: (error) => {
                that._onError(1, error);
            }
        }

        this.secondaryNetworkCallback = {
            onNetworkStateChange: (result) => {
                if (that.callback && that.callback.onNetworkStateChange) {
                    that.callback.onNetworkStateChange(2, result);
                }
            },
            onError: (error) => {
                that._onError(2, error);
            }
        }
    }

    static getInstance(config) {

        if (!ParamConnector.instance) {
            ParamConnector.instance = new ParamConnector();
        }
        return ParamConnector.instance;
    }

    setNetworkCallback(callback) {
        this.networkCallback = callback;
    }

    addDatabaseInstances(key, object, func, ...args) {
        ParamConnector.instance[key] = object;
        if (!func) {
            func = "setURL"
        }
        ParamConnector.instance[key][func].apply(object, args);
    }

    setCallback(callback) {
        // if (!this.callback || !this.callback.onReady) {
        //     throw new Error("You should implement onReady() before calling this function")
        // }
        if (!this.callback) {
            this.registerCallbacks();
        }
        this.callback = callback;
        this._checkNetworkStatus();
        if (this.dbStatus === ParamConnector.DB_READY) {
            this._onReady();
        } else if (this.dbStatus === ParamConnector.DB_ERROR
            || this.dbStatus === ParamConnector.DB_NOT_INIT) {
            this._initDatabase(this.address)
        } else {
            console.log("dbStatus", this.dbStatus);
        }
        return this;
    }

    setUserParamId(address) {
        // if (address) {
        //     this.resetEvents(address);
        // }
        this.address = address
        // this._initDatabase(address);
        return this;
    }

    _onReady() {
        if (this.callback && this.callback.onReady) {
            return this.callback.onReady();
        }
        throw new Error("");
    }


    _initDatabase(address) {
        if (ParamConnector.instance.database) {
            return;
        }
        let serverPort = Utils.getFromLocalStorage('serverPort') || "4000";
        let url = Utils.getFromLocalStorage('dbURL') || dbURL;
        let databaseInstance = Database.getInstance(address);
        let graphQlInstance = GraphQL.getInstance(address)
        this.dbStatus = ParamConnector.DB_WAITING
        databaseInstance.initDatabase(url, ParamConnector.primaryConnection, serverPort).then(() => {
            this.dbStatus = ParamConnector.DB_READY;
            ParamConnector.instance.databaseInstance = databaseInstance;
            return graphQlInstance.initGraphQl(grapQLEndPonitUrl)
        }).then(grapQL => {
            ParamConnector.instance.graphQlInstance = graphQlInstance;
            this._onReady();
        }).catch(e => {
            this.dbStatus = ParamConnector.DB_ERROR;
        });
    }

    getcharts() {
        return this.databaseInstance.chartsDatabase;
    }

    getdatabase() {
        return this.databaseInstance.receiptsDatabase;
    }

    getDB(dbType) {
        return this.databaseInstance.getDB(dbType);
    }
    resetConnection() {
        try {
            //ParamConnector.primaryConnection.web3.eth.clearSubscriptions()
            this.databaseInstance.resetDB();
            ParamConnector.getInstance().unRegisterCallbacks();
            ParamConnector.primaryConnection.web3 = undefined;
            ParamConnector.primaryConnection = undefined;
            ParamConnector.primaryConnection = null;
            ParamConnector.instance = undefined
        } catch (e) {
            Console.error(e.toString());
        }

        return;
    }

    retryConnection() {
        try {
            //ParamConnector.primaryConnection.web3.eth.clearSubscriptions()
            ParamConnector.getInstance().unRegisterCallbacks();
            ParamConnector.primaryConnection.web3 = undefined;
            ParamConnector.primaryConnection = undefined;
            ParamConnector.primaryConnection = null;
            ParamConnector.secondaryConnection = null;
            // ParamConnector.instance = undefined
        } catch (e) {
            Console.error(e.toString());
        }
        return;
    }

    static assertConfig(config) {
        if (!config) {
            throw new Error("Please provide config.")
        }
        if (!config.url || config.url.trim().length === 0) {
            throw new Error("Url can't empty.")
        }
        if (!config.privateUrl || config.privateUrl.trim().length === 0) {
            throw new Error("Private Url can't empty.")
        }
        return true;
    }

    getConnection(config) {
        return this //this.getNetwork(config).getConnection();
    }

    getNetwork(config) {
        if (!config || !config.url || config.url.length === 0 || ParamConnector.primaryConnection.config.url === config.url) {
            if (config) {
                // ParamConnector.primaryConnection.setNetworkCallback(this.getNetworkCallback(1))
                config["proxy"] = ProxyUtils.getProxyObject();
                ParamConnector.primaryConnection.setConfig(config);
            }
            return ParamConnector.primaryConnection;
        }
        if (ParamConnector.secondaryConnection && config.url !== ParamConnector.secondaryConnection.config.url) {
            try {
                ParamConnector.secondaryConnection.getConnection().currentProvider.connection.close();
            } catch (e) {
            }
            ParamConnector.secondaryConnection = new ParamNetwork({ url: config.url, enableCors: true, address: this.address, contracts: config.contracts, privateUrl: config.privateUrl, privateFor: config.privateFor });

        } else if (!ParamConnector.secondaryConnection) {
            ParamConnector.secondaryConnection = new ParamNetwork({ url: config.url, enableCors: true, address: this.address, contracts: config.contracts, privateUrl: config.privateUrl, privateFor: config.privateFor });
        } else {
            if (config) {
                config["proxy"] = ProxyUtils.getProxyObject();
                ParamConnector.secondaryConnection.setConfig(config);
            }
        }
        ParamConnector.secondaryConnection.setNetworkCallback(this.getNetworkCallback(2))
        return ParamConnector.secondaryConnection;
    }

    getNetworkCallback(type) {
        if (this.primaryNetworkCallback && type === 1) {
            return this.primaryNetworkCallback;
        } else if (this.secondaryNetworkCallback && type === 2) {
            return this.secondaryNetworkCallback;
        }
        return null;
    }

    getDatabase(config) {
        if (!config)
            return this.database;
        if (config.type === "irn") {
            return this.database.irn;
        }
        return this.datase.reconciliationDB;
    }

    _checkNetworkStatus() {
        this.isConnected = navigator.onLine;
        // ParamConnector.primaryConnection.isConnected = this.isConnected;
        // if (ParamConnector.primaryConnection && ParamConnector.primaryConnection.callback && ParamConnector.primaryConnection.callback.onNetworkStateChange) {
        // ParamConnector.primaryConnection.callback.onNetworkStateChange(ParamConnector.primaryConnection.isConnected)
        this._onNetwotkChanged(1, this.isConnected);
    }

    _onNetwotkChanged(connection, status) {
        if (this.networkCallback && this.networkCallback.onNetworkStateChange) {
            this.networkCallback.onNetworkStateChange(status);
        }
        if (this.callback && this.callback.onNetworkStateChange) {
            this.callback.onNetworkStateChange(connection, status);
        }
    }

    _onError(connection, error) {
        if (this.networkCallback && this.networkCallback.onError) {
            this.networkCallback.onError(1, error);
        }

        if (this.callback && this.callback.onError) {
            this.callback.onError(connection, error);
        }
    }

    registerCallbacks() {
        if (!this.checkOnlineCallback) {
            this.checkOnlineCallback = this._checkNetworkStatus.bind(this)
        }
        window.addEventListener('online', this.checkOnlineCallback);
        window.addEventListener('offline', this.checkOnlineCallback);
    }

    unRegisterCallbacks() {
        if (!this.checkOnlineCallback) {
            return;
        }
        window.removeEventListener('online', this.checkOnlineCallback);
        window.removeEventListener('offline', this.checkOnlineCallback);
    }

    isNetworkConnected() {
        return this.isConnected;
    }
}
ParamConnector.DB_NOT_INIT = 0
ParamConnector.DB_WAITING = 1
ParamConnector.DB_READY = 2
ParamConnector.DB_ERROR = 3

export default ParamConnector;