import ParamConnector from '../../../../src/param-connector';
import * as Utils from '../../../../src/util/utils';
import ECIES from '../../../../src/util/ecies';
import Console from '../../../logger';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import { CatalogueEvents } from '../../../../src/param-network/utils/event-names';
import GraphQL from '..';
import * as DBUtils from '../../../database/nosql/Utils/utils';

class RestoreItems {
    static restoreItem(itemId, contractAddress, primaryOwner, forSubscriber, fromEvent, updateItem) {
        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsDBInstance = ParamConnector.getInstance().getDB().items;
        let itemsInstance = GraphQL.getInstance().items
        return itemsInstance.getCatalogue(dbItemId).then(item => {
            if (!item) {
                return;
            }
            return itemsDBInstance.getSharedKey(dbItemId).then(sharedKey => {
                let privateKey = Utils.getPrivateKey();
                let itemShareKey;
                if (sharedKey.itemShareKey && sharedKey.inventoryShareKey) {
                    itemShareKey = sharedKey.itemShareKey;
                }
                if (item.catalogueTxnMode === "2") {
                    if (!itemShareKey)
                        itemShareKey = ECIES.decrypt(privateKey, item.catalogueKey);
                    item.JSONLd = ECIES.decrypt(itemShareKey, item.JSONLd);
                }
                if (!updateItem) {
                    return itemsDBInstance.doesExist(dbItemId).then(doesExist => {
                        if (doesExist) {
                            Console.log("Item already exists. Skipping..!")
                            return;
                        }
                        return itemsDBInstance.addItem(dbItemId, item.JSONLd, primaryOwner, undefined, itemShareKey, forSubscriber)
                            .then(() => {
                                if (!fromEvent)
                                    return RestoreItems.restoreInventory(itemId, item.inventoryContractAddress, primaryOwner, contractAddress);
                            });
                    })
                }
                return itemsDBInstance.updateItem(dbItemId, item.JSONLd, primaryOwner, undefined, itemShareKey, forSubscriber)
                    .then(() => {
                        if (!fromEvent)
                            return RestoreItems.restoreInventory(itemId, item.inventoryContractAddress, primaryOwner, contractAddress);
                    });
            })
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItem for item id ${itemId} and contract address ${contractAddress}`);
        });
    }

    static restoreInventory(itemId, inventoryContractAddress, owner, itemContractAddress, forSubscriber) {
        let dbItemId = Utils.getTransactionId(itemId, itemContractAddress);
        let itemsDBInstance = ParamConnector.getInstance().getDB().items;
        let itemAlreadyExist = true;
        return itemsDBInstance.doesExist(dbItemId).then(doesExist => {
            let promise = Promise.resolve({});
            if (!doesExist) {
                itemAlreadyExist = false;
                promise = RestoreItems.restoreItem(itemId, itemContractAddress, owner, forSubscriber)
            }
            return promise
        }).then(() => {
            return GraphQL.getInstance().items.getInventoryByCatalogueID(dbItemId);
        }).then(inventoryData => {
            let dbInventoryId = inventoryData.inventoryId //Utils.getTransactionId(inventoryData.inventoryId, inventoryContractAddress);
            return itemsDBInstance.getSharedKey(dbItemId).then(sharedKey => {
                let privateKey = Utils.getPrivateKey();
                let inventoryShareKey;
                if (sharedKey.inventoryShareKey) {
                    inventoryShareKey = sharedKey.inventoryShareKey;
                }
                if (inventoryData.inventoryDataTxnMode === "2") {
                    try {
                        if (!inventoryShareKey)
                            inventoryShareKey = ECIES.decrypt(privateKey, inventoryData.inventoryKey);
                        inventoryData.inventoryData = ECIES.decrypt(inventoryShareKey, inventoryData.inventoryData);
                    } catch (e) {
                        Console.error("Error in decrypting inventory", e);
                        inventoryData.inventoryData = {}
                    }
                }
                return itemsDBInstance.updateInventory(dbInventoryId, inventoryData.inventoryData, dbItemId, inventoryShareKey, forSubscriber);
            });
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreInventory`);
        });
    }

    static restoreInventoryUpdatableHistory(contractAddress, inventoryId, historyIndex, metaData) {
        let dbInventoryId = Utils.getTransactionId(inventoryId, contractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        return itemsInstance.updateInventoryHistory(dbInventoryId, historyIndex, metaData).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreInventoryUpdatableHistory`);
        });;
    }

    static restoreItemMapping(contractAddress) {
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        let graphQLInstance = GraphQL.getInstance();
        let selfParamId = Utils.getParamId();
        let buyerItemId, sellerItemId, buyerId, sellerId, status, promise, promiseArray = []
        return graphQLInstance.getEventDetails(selfParamId, Utils.getEventType('catalogue'), "Owner", ["onCatalogueStatusUpdated"]).then(res => {
            let filteredEvents = Utils.getAllEventDataByName(res)
            for (let index in filteredEvents) {
                if (filteredEvents[index].args.buyerId === selfParamId) {
                    buyerItemId = Utils.getTransactionId(filteredEvents[index].args.buyerItemId, filteredEvents[index].args.contractAddress);
                    sellerItemId = Utils.getTransactionId(filteredEvents[index].args.sellerItemId, filteredEvents[index].args.contractAddress);
                    buyerId = filteredEvents[index].args.buyerId;
                    sellerId = filteredEvents[index].args.sellerId;
                    status = filteredEvents[index].args.status;
                    promise = itemsInstance.mapItemsV1(buyerItemId, sellerItemId, buyerId, sellerId, status);
                    promiseArray.push(promise);
                }
                else if (filteredEvents[index].args.sellerId === selfParamId) {
                    buyerItemId = Utils.getTransactionId(filteredEvents[index].args.buyerItemId, filteredEvents[index].args.contractAddress);
                    sellerItemId = Utils.getTransactionId(filteredEvents[index].args.sellerItemId, filteredEvents[index].args.contractAddress);
                    buyerId = filteredEvents[index].args.buyerId;
                    sellerId = filteredEvents[index].args.sellerId;
                    status = filteredEvents[index].args.status;
                    promise = itemsInstance.mapItemsV1(buyerItemId, sellerItemId, buyerId, sellerId, status);
                    promiseArray.push(promise);
                }
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItemMapping`);
        });
        // return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllUpdatedItemsForBuyer(Utils.getParamId()).then(mappings => {
        //     let buyerItemId, sellerItemId, buyerId, sellerId, status, promise, promiseArray = [];
        //     for (let index in mappings) {
        //         buyerItemId = Utils.getTransactionId(mappings[index].buyerItemId, contractAddress);
        //         sellerItemId = Utils.getTransactionId(mappings[index].sellerItemId, contractAddress);
        //         buyerId = mappings[index].buyerId;
        //         sellerId = mappings[index].sellerId;
        //         status = mappings[index].status;
        //         promise = itemsInstance.mapItemsV1(buyerItemId, sellerItemId, buyerId, sellerId, status);
        //         promiseArray.push(promise);
        //     }
        //     return Promise.all(promiseArray);
        // }).then(() => {
        //     return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllUpdatedItemsForSeller(Utils.getParamId()).then(mappings => {
        //         let buyerItemId, sellerItemId, buyerId, sellerId, status, promise, promiseArray = [];
        //         for (let index in mappings) {
        //             buyerItemId = Utils.getTransactionId(mappings[index].buyerItemId, contractAddress);
        //             sellerItemId = Utils.getTransactionId(mappings[index].sellerItemId, contractAddress);
        //             buyerId = mappings[index].buyerId;
        //             sellerId = mappings[index].sellerId;
        //             status = mappings[index].status;
        //             promise = itemsInstance.mapItemsV1(buyerItemId, sellerItemId, buyerId, sellerId, status);
        //             promiseArray.push(promise);
        //         }
        //         return Promise.all(promiseArray);
        //     })
        // }).catch(e => {
        //     Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItemMapping`);
        // });
    }

    static restoreItemForSubscriber(itemId, contractAddress, sId, owner, updateItem) {
        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsDBInstance = ParamConnector.getInstance().getDB().items;
        let itemsInstance = GraphQL.getInstance().items;
        let item, inventory, inventoryShareKey, itemShareKey, dbInventoryId;

        return itemsInstance.getCatalogue(dbItemId).then(res => {
            item = res;
            if (!item) {
                return;
            }
            return itemsInstance.getInventoryByCatalogueID(dbItemId);
        }).then(res => {
            inventory = res;
            dbInventoryId = inventory.inventoryId//Utils.getTransactionId(inventory.inventoryId, item['inventoryContractAddress']);
            let networkSId = Utils.getTransactionId(sId, contractAddress)
            return itemsInstance.getSubscriber(networkSId);
        }).then(subscriberInfo => {
            let subscriberShareKey = subscriberInfo['shareKey'];
            let privateKey = Utils.getPrivateKey();
            itemShareKey = subscriberShareKey;
            inventoryShareKey = subscriberShareKey;
            if (item.catalogueTxnMode === "2") {
                itemShareKey = ECIES.decrypt(privateKey, itemShareKey);
                item.catalogueInfo = ECIES.decrypt(itemShareKey, item.JSONLd);
            }
            if (inventory.inventoryDataTxnMode === '2') {
                inventoryShareKey = ECIES.decrypt(privateKey, inventoryShareKey);
                inventory.inventoryData = ECIES.decrypt(inventoryShareKey, inventory.inventoryData);
            }
            return itemsDBInstance.doesExist(dbItemId);
        }).then(doesExist => {
            let catalogueFunction = "addItem";
            if (updateItem || doesExist) {
                catalogueFunction = "updateItem";
            }
            return Promise.all([
                itemsDBInstance[catalogueFunction](dbItemId, item.catalogueInfo, owner, undefined, itemShareKey, true),
                itemsDBInstance.updateInventory(dbInventoryId, inventory.inventoryData, dbItemId, inventoryShareKey, true)
            ]);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItemForSubscriber`);
        });
    }

    static updateItemForSubscriber(itemId, contractAddress, owner) {
        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsDBInstance = ParamConnector.getInstance().getDB().items;
        let itemsInstance = GraphQL.getInstance().items;
        let item, inventory, dbInventoryId;

        return itemsInstance.getCatalogue(dbItemId).then(res => {
            item = res;
            if (!item) {
                return;
            }
            return itemsInstance.getInventoryByCatalogueID(dbItemId);
        }).then(res => {
            inventory = res;
            dbInventoryId = inventory.inventoryId
            return itemsDBInstance.getSharedKey(dbItemId);
        }).then(sharedKey => {
            const itemShareKey = sharedKey.itemShareKey;
            const inventoryShareKey = sharedKey.inventoryShareKey;
            if (item.catalogueTxnMode === "2") {
                item.catalogueInfo = ECIES.decrypt(itemShareKey, item.JSONLd);
            }
            if (inventory.inventoryDataTxnMode === '2') {
                inventory.inventoryData = ECIES.decrypt(inventoryShareKey, inventory.inventoryData);
            }
            return Promise.all([
                itemsDBInstance.updateItem(dbItemId, item.catalogueInfo, owner, undefined, itemShareKey, true),
                itemsDBInstance.updateInventory(dbInventoryId, inventory.inventoryData, dbItemId, inventoryShareKey, true)
            ]);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItemForSubscriber`);
        });
    }

    static restoreAllSubscribers(itemId, contractAddress) {
        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        let grapQlInstance = GraphQL.getInstance()
        return grapQlInstance.items.getAllSubscribers(itemId).then(sIds => {
            let promise, promiseArray = [];
            for (let index in sIds) {
                let sIds = Utils.getTransactionId(sIds[index], contractAddress)
                promise = grapQlInstance.items.getSubscriber(sIds).then(subscriberInfo => {
                    return itemsInstance.addSubscriber(dbItemId, subscriberInfo['shareKey']);
                });
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreAllSubscribers`);
        });
    }

    static restoreAllSubscribedItems(contractAddress) {
        return Promise.resolve();

        // NEED TO IMPLEMENT 

        // return GraphDB.getInstance().vendorManagement.getAllVendors(Utils.getParamId()).then(vendors => {
        //     let promise, promiseArray = [];
        //     for (let index in vendors.invitations) {
        //         promise = GraphDB.getInstance().vendorManagement.getContactSummary(vendors.invitations[index]).then(vendorDetails => {
        //             return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllSubscribedCatalogues(vendorDetails.identifier, Utils.getParamId()).then(itemIds => {
        //                 let innerPromise, innerPromiseArray = [];
        //                 for (let index in itemIds) {
        //                     innerPromise = ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getCatalogueForSubscriber(itemIds[index], Utils.getParamId()).then(data => {
        //                         return RestoreItems.restoreItemForSubscriber(itemIds[index], contractAddress, data.sId, vendorDetails.identifier);
        //                     })
        //                     innerPromiseArray.push(innerPromise);
        //                 }
        //                 return Promise.all(innerPromiseArray);
        //             })
        //         })
        //         promiseArray.push(promise);
        //     }
        //     return Promise.all(promiseArray);
        // }).catch(e => {
        //     Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreAllSubscribedItems`);
        // });
    }

    // static _restoreAllSubscribedItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
    //     let itemPromises = [];
    //     let itemsLength = itemIds.length;
    //     let endIndex = ((batchStartIndex + 1) * batchSize)
    //     endIndex = Math.min(itemsLength, endIndex);
    //     if (endIndex === itemsLength) {
    //         endIndex = endIndex + 1;
    //     }
    //     let startIndex = (batchStartIndex * batchSize)
    //     Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)

    //     for (let index = startIndex; index < endIndex; index++) {

    //     }
    //     return Promise.all(itemPromises).then(result => {
    //         Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
    //         if (endIndex === itemsLength + 1) {
    //             return;
    //         }
    //         return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
    //     }).catch(() => {
    //         Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
    //         if (endIndex === itemsLength + 1) {
    //             return;
    //         }
    //         return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
    //     });
    // }

    static restoreAllItems(contractAddress, catalogueOwnerAddress, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("items", `Trying to get all the catalogue from ${contractAddress}`);
        }
        let graphQLInstance = GraphQL.getInstance()
        return graphQLInstance.items.getAllCatalogues(catalogueOwnerAddress).then(itemIds => {
            Console.info(`[MongoDB] Got items meta info from network.`);
            if (!batchSize) {
                batchSize = 50;
            }
            return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, 0, batchSize)
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreAllItems`);
        });
    }

    static _restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
        let itemPromises = [];
        let itemsLength = itemIds.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(itemsLength, endIndex);
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)

        for (let index = startIndex; index < endIndex; index++) {
            let transcationData = Utils.getTransactionData(itemIds[index])
            let itemId = transcationData.id;
            // itemId = Utils.getTransactionId(itemId, contractAddress);
            contractAddress = transcationData.address
            let itemPromise = RestoreItems.restoreItem(itemId, contractAddress, catalogueOwnerAddress).then(() => {
                return RestoreItems.restoreAllSubscribers(itemId, contractAddress);
            });
            itemPromises.push(itemPromise);
        }
        return Promise.all(itemPromises).then(result => {
            Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        });
    }
    static restoreAllCatalogueEvents(contractAddress, owner, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("catalogue", `Trying to get all the item events from ${contractAddress}`);
        }
        let catalogueIds;
        let catalogueManager = GraphQL.getInstance().items;
        return catalogueManager.getAllCatalogues(owner).then(res => {
            catalogueIds = res;
            if (!batchSize) {
                batchSize = 50;
            }
            return RestoreItems._restoreCatalogueEventsAsBatch(catalogueIds, contractAddress, owner, 0, batchSize);
        }).then(() => {
            return RestoreItems.restoreAllInventoryEvents(catalogueIds, '', callback);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items events [Reason] ${e} [Module] : Sync/items/restoreAllCatalogueEvents`);
        });
    }

    static _restoreCatalogueEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
        let itemsLength = itemIds.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(itemsLength, endIndex);
        // if (endIndex === itemsLength) {
        //     endIndex = endIndex + 1;
        // }
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items events ${itemsLength}`)
        let graphQLInstance = GraphQL.getInstance();
        let promiseArray = [];
        let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
        let selfParamId = Utils.getParamId();
        for (let index = startIndex; index < endIndex; index++) {
            contractAddress = Utils.getTransactionData(itemIds[index]).address
            promiseArray.push(graphQLInstance.getEventDetails(itemIds[index].toString(), Utils.getEventType('catalogue')).then(catalogueEvents => {
                return RestoreItems.restoreCatalogueIdEvents(transactionsDB, selfParamId, contractAddress, catalogueEvents);
            }))
        }
        return Promise.all(promiseArray).then(result => {
            Console.log(`Completed  batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreCatalogueEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreCatalogueEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        });
    }
    static restoreCatalogueIdEvents(transactionsDB, selfParamId, contractAddress, catalogueEvents) {
        let addTransactionsPromiseArray = [];
        for (let index in catalogueEvents) {
            let event = catalogueEvents[index];
            let dataJSON = Utils.getSubscriptionDataFromEventData(event)
            let eventMetaData = Utils.extractEventData(event)
            dataJSON = { ...dataJSON, ...eventMetaData }
            let label = CatalogueEvents[event.type].label;
            dataJSON.args.catalogueId = Utils.getTransactionId(dataJSON.args.catalogueId, contractAddress);
            let metaData = NetworkDataFormat.getMetaInfo(dataJSON.args.catalogueId, dataJSON, label);
            let owner = dataJSON.args.owner;
            let transactionPromise = transactionsDB.addTransaction(dataJSON.args.catalogueId, owner, metaData).catch(error => {
                Console.error(`Unable to restore catalogue event for ${dataJSON.args.catalogueId}, [Reason]: ${error}`)
            })
            addTransactionsPromiseArray.push(transactionPromise);
        }
        return Promise.all(addTransactionsPromiseArray).catch(e => {
            Console.error(`[MongoDB] Error in restoring items events [Reason] ${e} [Module] : Sync/items/restoreCatalogueIdEvents`);
        });
    }


    static restoreAllInventoryEvents(catalogueIds, contractAddress, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("inventory", `Trying to get all the inventory events from ${contractAddress}`);
        }
        if (!batchSize) {
            batchSize = 50;
        }
        return RestoreItems._restoreAllInventoryEventsAsBatch(catalogueIds, contractAddress, undefined, 0, batchSize);

    }
    static _restoreAllInventoryEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
        let itemsLength = itemIds.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(itemsLength, endIndex);
        // if (endIndex === itemsLength) {
        //     endIndex = endIndex + 1;
        // }
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for iventory events ${itemsLength}`)

        let graphQLInstance = GraphQL.getInstance();
        let promiseArray = [];
        let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
        let selfParamId = Utils.getParamId();
        for (let index = startIndex; index < endIndex; index++) {
            contractAddress = Utils.getTransactionData(itemIds[index]).address
            promiseArray.push(
                graphQLInstance.getEventDetails(itemIds[index].toString(), Utils.getEventType('inventory')).then(inventoryEvents => {
                    return RestoreItems.restoreInventoryIdEvents(transactionsDB, selfParamId, contractAddress, inventoryEvents);
                })
            )
        }
        return Promise.all(promiseArray).then(result => {
            Console.log(`Completed  batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for inventory events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreAllInventoryEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        }).catch((e) => {
            Console.error(`[MongoDB] Error in restoring inventory events [Reason] ${e} [Module] : Sync/items/restoreAllInventoryEvents`);
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for inventory events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreAllInventoryEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        });
    }
    static restoreInventoryIdEvents(transactionsDB, selfParamId, contractAddress, inventoryEvents) {
        let oldInventoryData, eventPromiseArray = [], itemId, inventoryEventData = [];
        for (let index in inventoryEvents) {
            let event = inventoryEvents[index]
            let dataJSON = Utils.getSubscriptionDataFromEventData(event)
            let eventMetaData = Utils.extractEventData(event)
            dataJSON = { ...dataJSON, ...eventMetaData }
            inventoryEventData.push(dataJSON)
        }
        if (inventoryEventData.length) {
            itemId = Utils.getTransactionId(inventoryEventData[0].args.catalogueId, inventoryEventData[0].args.catalogueContractAddress);
            let inventoryId = inventoryEventData[0].args.inventoryId;
            return ParamConnector.getInstance().getDB().items.getSharedKey(itemId).then(itemShareKeyResponse => {
                for (let index in inventoryEventData) {
                    if (!itemShareKeyResponse.inventoryShareKey) {
                        Console.log("skipping inventory id events restore because of missing inventoryShareKey")
                        continue;
                    }
                    try {
                        let eventData = inventoryEventData[index];
                        eventPromiseArray.push(RestoreItems.restoreInventoryUpdateEvent(eventData, oldInventoryData, transactionsDB, itemShareKeyResponse));
                        oldInventoryData = ECIES.decrypt(itemShareKeyResponse.inventoryShareKey, eventData.args.inventoryData);

                        oldInventoryData = JSON.parse(oldInventoryData);
                    } catch (error) {

                    }
                }
                eventPromiseArray.push(RestoreItems.restoreInventoryHistoryEvents(inventoryId, contractAddress));
            })
        }
        return Promise.all(eventPromiseArray).catch(e => {
            Console.error(`[MongoDB] Error in restoring inventory events [Reason] ${e} [Module] : Sync/items/restoreInventoryIdEvents`);
        });
    }

    static restoreInventoryUpdateEvent(eventData, oldInventoryData, transactionsDB, itemShareKeyResponse) {
        let itemId = Utils.getTransactionId(eventData.args.catalogueId, eventData.args.catalogueContractAddress);
        let inventoryId = Utils.getTransactionId(eventData.args.inventoryId, eventData.args.contractAddress);

        let metaData = NetworkDataFormat.getMetaInfo(itemId, eventData, "Inventory Updated");
        let eventMetaData = JSON.parse(eventData.args.metaData);
        metaData._documentId = eventMetaData._documentId;
        metaData._event = eventMetaData._event;
        metaData.adjustedBy = eventMetaData.adjustedBy;
        metaData._docType = eventMetaData._docType;
        metaData.inventoryHistoryIndex = eventData.args.historyIndex;
        try {
            metaData.inventoryData = ECIES.decrypt(itemShareKeyResponse.inventoryShareKey, eventData.args.inventoryData);
        } catch (err) {
            return;
        }
        let updateType = "increment", adjustment;
        const currentValue = Number(JSON.parse(metaData.inventoryData).inventoryLevel.currentValue || 0);
        if (oldInventoryData) {
            if (Number(oldInventoryData.inventoryLevel.currentValue) > currentValue) {
                updateType = "decrement";
            }
            adjustment = currentValue - Number(oldInventoryData.inventoryLevel.currentValue)
        }
        else {
            adjustment = currentValue;
            metaData.transInfo = "Inventory Added";
        }
        metaData.updateType = updateType;
        metaData.adjustment = adjustment;
        if (adjustment !== 0) {
            return transactionsDB.addTransaction(inventoryId, eventData.args.owner, metaData).catch(error => {
                Console.error(`Unable to write inventory transaction for ${itemId}, [Reason]: ${error.toString()} [Module]: Sync/items/restoreInventoryUpdateEvent`)
            })
        }
        return;
    }

    static restoreInventoryHistoryEvents(inventoryId, contractAddress) {
        let graphQLInstance = GraphQL.getInstance();
        let promiseArray = [], historyEventsData = [];
        inventoryId = Utils.getTransactionId(inventoryId, contractAddress);
        return graphQLInstance.getEventDetails(inventoryId, Utils.getEventType('inventoryHistory')).then(historyEvents => {
            for (let index in historyEvents) {
                let event = historyEvents[index]
                let dataJSON = Utils.getSubscriptionDataFromEventData(event)
                let eventMetaData = Utils.extractEventData(event)
                dataJSON = { ...dataJSON, ...eventMetaData }
                historyEventsData.push(dataJSON)
            }
            const key = "historyIndex";
            historyEventsData = historyEventsData.reduce((result, currentValue) => {
                if (result[currentValue.args[key]]) {
                    if (currentValue.blockNumber > result[currentValue.args[key]].blockNumber) {
                        result[currentValue.args[key]] = currentValue;
                    }
                }
                else {
                    result[currentValue.args[key]] = currentValue;
                }
                return result;
            }, {})
            for (let index in historyEventsData) {
                promiseArray.push(RestoreItems.restoreInventoryUpdatableHistory(contractAddress, inventoryId, historyEventsData[index].args.historyIndex, historyEventsData[index].args.metaData));
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring inventory history events [Reason] ${e} [Module] : Sync/items/restoreInventoryHistoryEvents`);
        });
    }

}
export default RestoreItems;
