import Console from '../../../logger/index';
import Database from '../../../database/index';
import { gql } from '@apollo/client';
import RestoreItems from '../sync/items';
import * as NetworkUtils from '../../../../src/util/utils';
import GraphQL from '..';

class InventoryEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient
        this.dbInstance = Database.getInstance().getDB()
    }
    registerEvents(paramId) {
        let addInventoryOptions = {
            query: gql`
               subscription onInventoryAdded($paramId: [String]){
                   onInventoryAdded(InventoryOwnerID: $paramId){
                        contractAddress,
                        catalogueId,
                        inventoryID,
                        owner,
                        data,
                        catalogueContractAddress,
                        metaData,
                        historyIndex,
                        status
                        }
                    }
                `,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(addInventoryOptions).subscribe({
            next(data) {
                that._onInventoryUpdated(data, "onInventoryAdded")
            },
            error(error) {
                Console.error(`Unable to get create Inventory details form network, Reson: ${error}`);
            },
        });

        let updateInventoryOptions = {
            query: gql`
               subscription onInventoryUpdated($paramId: [String]){
                   onInventoryUpdated(InventoryOwnerID: $paramId){
                        contractAddress,
                        catalogueId,
                        inventoryID,
                        owner,
                        data,
                        catalogueContractAddress,
                        metaData,
                        historyIndex,
                        status
                        }
                    }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(updateInventoryOptions).subscribe({
            next(data) {
                that._onInventoryUpdated(data, "onInventoryUpdated")
            },
            error(error) {
                Console.error(`Unable to get updated Inventory details form network, Reson: ${error}`);
            },
        });

        let historyUpdatedOptions = {
            query: gql`
               subscription onInventoryHistoryUpdated($paramId: [String]){
                   onInventoryHistoryUpdated(InventoryOwnerID: $paramId){
                        contractAddress,
                        catalogueId,
                        inventoryID,
                        owner,
                        data,
                        catalogueContractAddress,
                        metaData,
                        historyIndex,
                        status
                        }
                    }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(historyUpdatedOptions).subscribe({
            next(data) {
                that._onInventoryHistoryUpdated(data)
            },
            error(error) {
                Console.error(`Unable to get updated Inventory details form network, Reson: ${error}`);
            },
        });

        let updateInventoryForSubscriberOptions = {
            query: gql`
               subscription onInventoryUpdateForSubscriber($paramId: [String]){
                  onInventoryUpdateForSubscriber(InventoryOwnerID: $paramId){
                        contractAddress,
                        catalogueId,
                        inventoryID,
                        owner,
                        data,
                        catalogueContractAddress,
                        metaData,
                        historyIndex,
                        status
                        }
                    }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(updateInventoryForSubscriberOptions).subscribe({
            next(data) {
                that._onInventoryUpdateForSubscriber(data)
            },
            error(error) {
                Console.error(`Unable to get updated Inventory details For subscriber form network, Reson: ${error}`);
            },
        });
        Console.log(`Registered Events for Inventory`)
    }

    _onInventoryUpdated(subscriptionDataJSON, event) {
        Console.debug(subscriptionDataJSON, event)
        let functionName = event === "onInventoryAdded" ? "onInventoryAdded" : "onInventoryUpdated"
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data[functionName]
        dataJSON.args.inventoryData = dataJSON.args.data
        // let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.catalogueContractAddress);
        let itemId = dataJSON.args.catalogueId
        let inventoryTxnData = NetworkUtils.getTransactionData(dataJSON.args.inventoryID)
        let catalogueTxnData = NetworkUtils.getTransactionData(dataJSON.args.catalogueId)
        dataJSON.args.inventoryId = inventoryTxnData.id
        dataJSON.args.catalogueId = catalogueTxnData.id
        let oldInventoryData, shareKeyResponse;
        Console.log(`[GraphDB] Got Inventory event for ${itemId}`);
        return this.dbInstance.items.getInventoryByItemId(itemId).then(res => {
            oldInventoryData = res;
            return RestoreItems.restoreInventory(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, dataJSON.args.catalogueContractAddress);
        }).then(() => {
            Console.log(`[GraphDB] Added inventory info to graph ${itemId}`);
            return this.dbInstance.emitEvent("OnInventoryUpdated", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add inventory ${e.toString()}`);
        }).finally(() => {
            return this.dbInstance.items.getSharedKey(itemId).then(res => {
                shareKeyResponse = res
                let grapQlInstance = GraphQL.getInstance()
                // let inventoryId = NetworkUtils.getTransactionId(dataJSON.args.inventoryId, dataJSON.args.contractAddress);
                let eventFilter = event === "onInventoryAdded" ? "onInventoryAdd" : "onInventoryUpdated"
                return grapQlInstance.getEventDetails(itemId, NetworkUtils.getEventType('inventory'), "RecordID", [eventFilter])
            }).then(res => {
                if (!res || res.length === 0)
                    return
                let result = NetworkUtils.getLatestEventDataByName(res)
                let _eventMetaData = NetworkUtils.extractEventData(result)
                let eventData = { ...dataJSON, ..._eventMetaData }
                return RestoreItems.restoreInventoryUpdateEvent(eventData, oldInventoryData, this.dbInstance.transactionsDB, shareKeyResponse);
            })
        })
    }

    _onInventoryHistoryUpdated(subscriptionDataJSON) {
        Console.debug(subscriptionDataJSON)
        debugger
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data['onInventoryHistoryUpdated']
        let inventoryId = dataJSON.args.inventoryID
        dataJSON.args.inventoryId = NetworkUtils.getTransactionData(dataJSON.args.inventoryID).id
        return RestoreItems.restoreInventoryUpdatableHistory(dataJSON.args.contractAddress, dataJSON.args.inventoryId, dataJSON.args.historyIndex, dataJSON.args.metaData).then(() => {
            Console.log(`[GraphDB] Updated inventroy history ${inventoryId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnInventoryHistoryUpdated", inventoryId, null);
        }).catch(e => {
            Console.error(`Unable to Updated inventroy history  ${e.toString()}`);
        })
    }
    _onInventoryUpdateForSubscriber(subscriptionDataJSON) {
        Console.debug(subscriptionDataJSON)
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data['onInventoryUpdateForSubscriber']
        let itemId = dataJSON.args.catalogueId
        let catalogueTxnData = NetworkUtils.getTransactionData(dataJSON.args.catalogueId)
        dataJSON.args.catalogueId = catalogueTxnData.id
        Console.log(`[GraphDB] Got InventorySubscriber event for ${itemId}`);
        return RestoreItems.restoreInventory(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, dataJSON.args.catalogueContractAddress, true).then(() => {
            Console.log(`[GraphDB] Added inventory info to graph ${itemId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnInventoryUpdateForSubscriber", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add inventory ${e.toString()}`);
        })
    }
}

export default InventoryEvents;