class ResolveReconciliation {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    notesToBeResolved(options, type) {
        return this.mongoDBConnector.invoke('resolveReconciliation', "notesToBeResolved", options, type)
    }

    creditNotesToBeResolved(options) {
        return this.mongoDBConnector.invoke('resolveReconciliation', "creditNotesToBeResolved", options)
    }

    debitNotesToBeResolved(options) {
        return this.mongoDBConnector.invoke('resolveReconciliation', "debitNotesToBeResolved", options)
    }

    creditNotesToBeResolvedGraph(options) {
        return this.mongoDBConnector.invoke('resolveReconciliation', "creditNotesToBeResolvedGraph", options)
    }

    debitNotesToBeResolvedGraph(options) {
        return this.mongoDBConnector.invoke('resolveReconciliation', "debitNotesToBeResolvedGraph", options)
    }



}
export default ResolveReconciliation