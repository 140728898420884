// import GraphDB from '../graphDB/index';
import ParamConnector from "../../../../src/param-connector";

class RestoreVersionControl {
    static restoreVersionControl(contractIds, paramNetwork) {
        let versionControlManager = paramNetwork.getVersionControlManager();
        let promiseArray = []
        for (let id in contractIds) {
            let promise = versionControlManager.getContract(contractIds[id]).then(contract => {
                return this.restoreContractById(contract, paramNetwork);
            })
            promiseArray.push(promise);
        }
        return Promise.all(promiseArray);
    }
    static restoreContractById(contract, paramNetwork) {
        let versionControlManager = paramNetwork.getVersionControlManager();
        let versionControl = ParamConnector.getInstance().getDB().versionControl;
        let promiseArray = []
        let contractInfoArray = contract[5];
        for (let index in contractInfoArray) {
            let promise = versionControlManager.getContractInfo(contractInfoArray[index]).then(res => {
                return versionControl.updateContract(contract[0], res[0], contract[1], res[1], contract[2], res[3], contract[3], res[2], res[4], "forSync");
            })
            promiseArray.push(promise);
        }
        return Promise.all(promiseArray);
    }
}
export default RestoreVersionControl;