import React from "react";
import loader from "../../assets/images/Loader-Param.gif"
import { Spin } from "antd";
const CircularProgress = ({ className, type }) => <div className={`loader ${className}`} style={{ zIndex: '10' }}>
  {type === "spinner" ?
    <Spin />
    :
    <img src={loader} alt="loader" />
  }
</div>;
export default CircularProgress;
