
import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
class VersionControl {

    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        this.paramNetwork = _paramNetwork;

        const versionControlManager = require('./version-control.json');
        this.versionControlManagerContract = Web3_1_0.getContract(this.connection, versionControlManager.abi, contractAddress ? contractAddress : versionControlManager.address);
        this.to = contractAddress ? contractAddress : versionControlManager.address
        // this.versionControlManagerContract = new this.connection.eth.Contract(versionControlManager.abi, contractAddress ? contractAddress : versionControlManager.address);
    }

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        if (options) {
            options = {
                userAddress: options.address
            }
        }
        this.watchAddVersionEvent(options);
        this.watchUpdateVersionEvent(options);
    }

    watchAddVersionEvent(options) {

        if (typeof this.addDataEvent === 'undefined' || !this.addDataEvent) {
            // this.addDataEvent = this.versionControlManagerContract.events.OnContractAdded();
            this.addDataEvent = Web3_1_0.getEvent(this, this.versionControlManagerContract, "OnContractAdded");
        }
        let that = this;
        this.addDataEvent.on('data', function (event) {
            that.events.emit("OnContractAdded", null, Web3_1_0.upgradeEventData(event, that.connection));
        })
            .on('error', function (error) {
                that.events.emit("OnContractAdded", error);
                that.addDataEvent.unsubscribe();
                that.addDataEvent = undefined;
                that.watchAddVersionEvent(options);
            });
    }

    watchUpdateVersionEvent(options) {

        if (typeof this.updateDataEvent === 'undefined' || !this.updateDataEvent) {
            // this.updateDataEvent = this.versionControlManagerContract.events.OnContractUpdated();
            this.updateDataEvent = Web3_1_0.getEvent(this, this.versionControlManagerContract, "OnContractUpdated");
        }
        let that = this;
        this.updateDataEvent.on('data', function (event) {
            that.events.emit("OnContractUpdated", null, Web3_1_0.upgradeEventData(event));
        })
            .on('error', function (error) {
                that.events.emit("OnContractUpdated", error);
                that.updateDataEvent.unsubscribe();
                that.updateDataEvent = undefined;
                that.watchUpdateVersionEvent(options);
            });
    }

    addContractAddress(nodeId, contractName, contractAddress, metaData, types, nodeIds, options) {
        return Web3_1_0.send(this, this.versionControlManagerContract, "addContractAddress", options, nodeId, contractName, contractAddress, metaData, types, nodeIds);
    }

    enableContractAddress(contractId, contractAddress, metaData, options) {
        return Web3_1_0.send(this, this.versionControlManagerContract, "enableContractAddress", options, contractId, contractAddress, metaData);
    }

    disableContractAddress(contractId, contractAddress, options) {
        return Web3_1_0.send(this, this.versionControlManagerContract, "disableContractAddress", options, contractId, contractAddress);
    }

    getAllClassNames(nodeId) {
        return Web3_1_0.call(this, this.versionControlManagerContract, "getAllClassNames", null, nodeId);
    }

    getAllClassName(classId) {
        return Web3_1_0.call(this, this.versionControlManagerContract, "getAllClassName", null, classId);
    }

    getContract(contractId) {
        return Web3_1_0.call(this, this.versionControlManagerContract, "getContract", null, contractId);
    }

    getContractInfo(rInfoId) {
        return Web3_1_0.call(this, this.versionControlManagerContract, "getContractInfo", null, rInfoId);
    }

    getContractByAddress(contractaddress) {
        return Web3_1_0.call(this, this.versionControlManagerContract, "getContractByAddress", null, contractaddress);
    }

    getAllContractIdsByNodeId(nodeId) {
        return Web3_1_0.call(this, this.versionControlManagerContract, "getAllContractIdsByNodeId", null, nodeId);
    }

    getLatestContract(contractId) {
        return Web3_1_0.call(this, this.versionControlManagerContract, "getLatestContract", null, contractId);
    }

    registerOnContractAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        this.events.addListener("OnContractAdded", callback);
    }

    unRegisterOnContractAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("OnContractAdded", callback);
    }

    registerOnContractUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        this.events.addListener("OnContractUpdated", callback);
    }

    unRegisterOnContractUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("OnContractUpdated", callback);
    }
}

export default VersionControl;