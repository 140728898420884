import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import NetworkBridge from '.';
import ECIES from '../ecies';
import * as DBUtils from '../../database/nosql/Utils/utils';
import GraphQL from '../../param-libs/graph-ql';

class GRNManager {

  static addGRN(parentId, jsonLd, txnType = "1", buyerId) { //ocId, inputObject, txnMode
    let receiptData = {}, txnTypeString;
    let selfParamId = Utils.getParamId();
    let transactionData = Utils.getTransactionData(parentId);
    let privateKey = Utils.getPrivateKey();
    let promise = Promise.resolve({ receiptJson: jsonLd });
    let paramReceiptManager = GraphQL.getInstance().receipts
    let paramGRNManager = GraphQL.getInstance().grn
    if (txnType === "2") {
      if (selfParamId === buyerId) {
        promise = NetworkBridge.getReceiptManager().encryptJsonLd(jsonLd, parentId, paramReceiptManager);
      }
      else {
        promise = paramReceiptManager.getReceipt(parentId).then(receipt => {
          // receiptData[2] = receipt['jsonLD'];
          receiptData.jsonLd = receipt['jsonLD'];
          return NetworkBridge.getReceiptManager().getReceiptRootIdFromNetwork(parentId, paramReceiptManager); //TO DO
        }).then(rootId => {
          return paramReceiptManager.getSubscriberDetailsForId(selfParamId, rootId);
        }).then(subscriberPayload => {
          const receiverPublicKey = receiptData.jsonLd.substr(-128);
          const senderPublicKey = receiptData.jsonLd.substr(0, 128);
          const encryptedRandomStr = ECIES.getEncryptedRandomString(receiptData.jsonLd.slice(128, -128));
          const randomStr = ECIES.decrypt(privateKey, subscriberPayload['payload']);
          return { receiptJson: ECIES.encryptForSubscriber(receiverPublicKey, jsonLd, randomStr, encryptedRandomStr, senderPublicKey) };
        })
      }
    }
    return promise.then(res => {
      let inputObject = {}
      if (txnType !== "2") {
        inputObject.receipt = res.receiptJson
      }
      else {
        inputObject.data = res.receiptJson
      }
      txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
      return paramGRNManager["attachGRN"](transactionData.id, inputObject, txnTypeString);
    }).then(res => {
      let signedGrnData = Utils.codeSignPaylaod(JSON.parse(res))
      return GraphQL.getInstance().sendRawTxn(signedGrnData)
    })
  }

  static addEPoD(elrId, parentId, jsonLd, txnType = "1", buyerId) {

    let receiptData = {}, txnTypeString;
    let selfParamId = Utils.getParamId();
    let transactionData = Utils.getTransactionData(elrId);
    let privateKey = Utils.getPrivateKey();
    let promise = Promise.resolve({ receiptJson: jsonLd });
    let paramReceiptManager = GraphQL.getInstance().receipts
    let paramGRNManager = GraphQL.getInstance().grn
    if (txnType === "2") {
      if (selfParamId === buyerId) {
        promise = NetworkBridge.getReceiptManager().encryptJsonLd(jsonLd, parentId, paramReceiptManager);
      }
      else {
        promise = paramReceiptManager.getReceipt(parentId).then(receipt => {
          // receiptData[2] = receipt['jsonLD'];
          receiptData.jsonLd = receipt['jsonLD'];
          return NetworkBridge.getReceiptManager().getReceiptRootIdFromNetwork(parentId, paramReceiptManager); //TO DO
        }).then(rootId => {
          return paramReceiptManager.getSubscriberDetailsForId(selfParamId, rootId);
        }).then(subscriberPayload => {
          const receiverPublicKey = receiptData.jsonLd.substr(-128);
          const senderPublicKey = receiptData.jsonLd.substr(0, 128);
          const encryptedRandomStr = ECIES.getEncryptedRandomString(receiptData.jsonLd.slice(128, -128));
          const randomStr = ECIES.decrypt(privateKey, subscriberPayload['payload']);
          return { receiptJson: ECIES.encryptForSubscriber(receiverPublicKey, jsonLd, randomStr, encryptedRandomStr, senderPublicKey) };
        })
      }
    }
    return promise.then(res => {
      let inputObject = {}
      if (txnType !== "2") {
        inputObject.receipt = res.receiptJson
      }
      else {
        inputObject.data = res.receiptJson
      }
      txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
      return paramGRNManager["attachGRN"](transactionData.id, inputObject, txnTypeString);
    }).then(res => {
      let signedGrnData = Utils.codeSignPaylaod(JSON.parse(res))
      return GraphQL.getInstance().sendRawTxn(signedGrnData)
    })
  }

  static assignGRN(grnId, owner) {
    let config;
    return Utils.getConfig("ParamContract").then(res => {
      config = res;
      let options = Utils.getNetworkOptions();
      let paramNetwork = ParamConnector.getInstance(config).getNetwork();
      let paramReceiptManager = paramNetwork.getReceiptManager(config.address);
      return paramReceiptManager["assignGRN"](grnId, owner, options);
    })
  }

  static updateGRN(grnId, parentId, jsonLd, txnType = "1", buyerId) {
    let receiptData, txnTypeString;
    let selfParamId = Utils.getParamId();
    let transactionData = Utils.getTransactionData(parentId);
    let grnTransactionData = Utils.getTransactionData(grnId);
    let privateKey = Utils.getPrivateKey();
    let promise = Promise.resolve({ receiptJson: jsonLd });
    let paramReceiptManager = GraphQL.getInstance().receipts
    let paramGRNManager = GraphQL.getInstance().grn

    if (txnType === "2") {
      if (selfParamId === buyerId) {
        promise = NetworkBridge.getReceiptManager().encryptJsonLd(jsonLd, parentId, paramReceiptManager);
      }
      else {
        promise = paramReceiptManager.getReceipt(transactionData.id).then(res => {
          receiptData = res['jsonLD'];
          return NetworkBridge.getReceiptManager().getReceiptRootIdFromNetwork(transactionData.id, paramReceiptManager); //TO DO
        }).then(rootId => {
          return paramReceiptManager.getSubscriberDetailsForId(selfParamId, rootId);
        }).then(subscriberPayload => {
          const receiverPublicKey = receiptData.substr(-128);
          const senderPublicKey = receiptData.substr(0, 128);
          const encryptedRandomStr = ECIES.getEncryptedRandomString(receiptData.slice(128, -128));
          const randomStr = ECIES.decrypt(privateKey, subscriberPayload['payload']);
          return { receiptJson: ECIES.encryptForSubscriber(receiverPublicKey, jsonLd, randomStr, encryptedRandomStr, senderPublicKey) };
        })
      }
    }
    return promise.then(res => {
      let inputObject = {}
      if (txnType !== "2") {
        inputObject.receipt = res.receiptJson
      }
      else {
        inputObject.data = res.receiptJson
      }
      txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
      return paramGRNManager["updateGRN"](grnTransactionData.id, inputObject, txnTypeString);
    }).then(res => {
      let signedGrnData = Utils.codeSignPaylaod(JSON.parse(res))
      return GraphQL.getInstance().sendRawTxn(signedGrnData)
    })
  }

  static closeGRN(grnId) {
    let paramGRNManager = GraphQL.getInstance().grn;
    let transactionData = Utils.getTransactionData(grnId);
    return paramGRNManager["closeGRN"](transactionData.id).then(res => {
      let signedGrnData = Utils.codeSignPaylaod(JSON.parse(res))
      return GraphQL.getInstance().sendRawTxn(signedGrnData)
    })
  }

  static closeAllGRNs(invId) {
    let promiseArray = [], parentIds = [], transactionData, grnIds;
    let paramGRNManager = GraphQL.getInstance().grn
    return GRNManager.getAllGrnByDocId(invId, "2").then(res => {
      grnIds = res.grns;
      transactionData = Utils.getTransactionData(grnIds[0]);
      for (let index in grnIds) {
        promiseArray.push(GRNManager.getGrnParentId(grnIds[index]).then(res => {
          return Utils.getTransactionData(res).id;
        }))
        grnIds[index] = Utils.getTransactionData(grnIds[index]).id;
      }
      return Promise.all(promiseArray)
    }).then(res => {
      parentIds = res;
      console.log("resutl", grnIds, parentIds)
      return paramGRNManager["closeGRNs"](grnIds, parentIds);
    }).then(res => {
      let signedGrnData = Utils.codeSignPaylaod(JSON.parse(res))
      return GraphQL.getInstance().sendRawTxn(signedGrnData)
    })
  }

  static issueNote(docId, noteType, jsonLd, txnType = "1", parentId) {
    let config, paramReceiptManager;
    let transactionData = Utils.getTransactionData(docId);
    return Utils.getConfig("ParamContract").then(res => {
      let promise = Promise.resolve({ receiptJson: jsonLd });
      config = res;
      let paramNetwork = ParamConnector.getInstance(config).getNetwork();
      paramReceiptManager = paramNetwork.getReceiptManager(transactionData.address);
      if (txnType === "2") {
        promise = NetworkBridge.getReceiptManager().encryptJsonLd(jsonLd, parentId, paramReceiptManager);
      }
      return promise;
    }).then(res => {
      return Utils.sendToIPFS(res.receiptJson);
    }).then(ipfsHash => {
      let options = Utils.getNetworkOptions();
      return paramReceiptManager["issueNote"](transactionData.id, noteType, ipfsHash, txnType, options);
    })
  }

  static resolvePO(parentId) {
    return Utils.getConfig("ParamContract").then(config => {
      let options = Utils.getNetworkOptions();
      let paramNetwork = ParamConnector.getInstance(config).getNetwork();
      let paramReceiptManager = paramNetwork.getReceiptManager(config.address);
      return paramReceiptManager["resolvePO"](parentId, options);
    })
  }

  static getAllGrnByDocId(receiptId, docStep) {
    return ParamConnector.getInstance().getDB().grn.getAllGrnByDocId(receiptId, docStep);
  }

  static getAllGrns(address) {
    return ParamConnector.getInstance().getDB().grn.getAllGrns(address);
  }

  static getGrn(grnId) {
    return ParamConnector.getInstance().getDB().grn.getGrn(grnId);
  }

  static getReceipt(rId) {
    return GRNManager.getGrn(rId);
  }

  static getSummary(grnId, predicates) {
    return ParamConnector.getInstance().getDB().grn.getSummary(grnId, predicates);
  }
  static getSummaryInBulk(grnIds, predicates) {
    return ParamConnector.getInstance().getDB().grn.getSummaryInBulk(grnIds, predicates);
  }
  static getGrnIdsByInternalPOId(poId, invoiceNumber, docStep) {
    return ParamConnector.getInstance().getDB().grn.getGrnIdsByInternalPOId(poId, invoiceNumber, docStep);
  }
  static getAllReturnNotesByDocId(docId) {
    return ParamConnector.getInstance().getDB().grn.getAllReturnNotesByDocId(docId);
  }
  static getGrnParentId(grnId) {
    return ParamConnector.getInstance().getDB().grn.getGrnParentId(grnId);
  }
  static getGrnStatus(grnId) {
    return ParamConnector.getInstance().getDB().grn.getGrnStatus(grnId);
  }
  static getAllItemsSummaryForReceipt(grnId) {
    return ParamConnector.getInstance().getDB().grn.getAllItemsSummaryForReceipt(grnId);
  }
  static getAllReceiptsByParent(grnId) {
    return ParamConnector.getInstance().getDB().grn.getAllReceiptsByParent(grnId);
  }
  static getAllReceipts(owner, options, status, fromDate, toDate) {
    return ParamConnector.getInstance().getDB().grn.getAllReceipts(owner, status, fromDate, toDate);
  }
  static getAllReceiptsByParentV1(grnId) {
    return ParamConnector.getInstance().getDB().grn.getAllReceiptsByParentV1(grnId);
  }
  static getItemQuantitySummary(grnId) {
    return ParamConnector.getInstance().getDB().grn.getItemQuantitySummary(grnId);
  }
  static getValidationData(grnId) {
    return ParamConnector.getInstance().getDB().grn.getValidationData(grnId);
  }
  static getAllReceiptsByStep(from, to, step, type, isClosed, options) {
    return ParamConnector.getInstance().getDB().grn.getAllReceiptsByStep(from, to, step, type, isClosed, options);
  }
  static getSellerAndBuyer(grnId) {
    return ParamConnector.getInstance().getDB().grn.getSellerAndBuyer(grnId);
  }
  static getAllReceiptsByTypeForExport() {
    return ParamConnector.getInstance().getDB().grn.getAllReceiptsByTypeForExport();
  }

  static getDisputeInfo(receiptId, receiptJson, parentId, resolveMatchedGRNs) {
    receiptJson = JSON.parse(receiptJson);
    let poDetails, grnIds, promiseArray = [];
    let receiptManager = NetworkBridge.getReceiptManager();
    return receiptManager.getAllItemsSummaryForReceipt(parentId).then(res => {
      poDetails = res;
      if (parentId) {
        return GRNManager.getGrnIdsByInternalPOId(parentId, receiptJson.invoiceNumber, "1");
      }
      return GRNManager.getGrnIdsByInternalPOId(receiptId, receiptJson.invoiceNumber, "2");
    }).then(res => {
      grnIds = res.grns;
      for (let index in grnIds) {

        promiseArray.push(
          GRNManager.getAllItemsSummaryForReceipt(grnIds[index]).then(res => {
            return GRNManager.getGrnStatus(grnIds[index]).then(grnStatus => {
              let returnResult = {}
              returnResult.status = grnStatus;
              returnResult.items = res;
              return returnResult;
            })
          })
        )
      }
      return Promise.all(promiseArray);
    }).then(grnDetails => {


      let receiptOrderedItems = receiptJson.referencesOrder.orderedItem;
      let parentOrderedItems;
      if (parentId) {
        parentOrderedItems = poDetails;
      }
      let priceDispute = 0, quantityDispute = 0;
      let grnMapping = {}, openGrn = false;
      for (let grnIndex in grnDetails) {
        let grnOrderedItems = grnDetails[grnIndex].items;
        if (grnDetails[grnIndex].status === "0") {
          openGrn = true;
        }
        for (let index in grnOrderedItems) {
          let itemId = grnOrderedItems[index].orderItemNumber;
          if (!grnMapping[itemId]) {
            grnMapping[itemId] = {
              quantity: 0,
              price: Number(grnOrderedItems[index].price)
            };
          }
          grnMapping[itemId].quantity += Number(grnOrderedItems[index].orderQuantity);
        }
      }
      for (let receiptIndex in receiptOrderedItems) {
        let receiptOrderedItem = receiptOrderedItems[receiptIndex];
        receiptOrderedItem.poQuantity = 0;
        receiptOrderedItem.poPrice = 0;
        // receiptOrderedItem.priceDispute = false;
        // receiptOrderedItem.quantityDispute = false;
        for (let parentIndex in parentOrderedItems) {
          let parentOrderedItem = parentOrderedItems[parentIndex];
          if (grnMapping[parentOrderedItem.orderItemNumber] && Number(parentOrderedItem.orderQuantity) < grnMapping[parentOrderedItem.orderItemNumber].quantity) {
            quantityDispute = 2;
          }
          if (receiptOrderedItem.orderItemNumber === parentOrderedItem.orderItemNumber) {
            receiptOrderedItem.poQuantity = parentOrderedItem.orderQuantity;
            receiptOrderedItem.poPrice = parentOrderedItem.price;
            if (Number(parentOrderedItem.price) < Number(receiptOrderedItem.orderedItem.offers.price)) {
              receiptOrderedItem.priceDispute = true;
              priceDispute = 1;
            }
            else {
              delete receiptOrderedItem.priceDispute;
            }
          }
        }
        if (grnMapping[receiptOrderedItem.orderItemNumber]) {
          if (Number(receiptOrderedItem.orderQuantity) !== grnMapping[receiptOrderedItem.orderItemNumber].quantity) {
            receiptOrderedItem.quantityDispute = true;
            quantityDispute = 2;
          }
          else {
            delete receiptOrderedItem.quantityDispute;
          }
          receiptOrderedItem.grnQuantities = grnMapping[receiptOrderedItem.orderItemNumber].quantity;
          receiptOrderedItem.grnPrices = grnMapping[receiptOrderedItem.orderItemNumber].price;
        }
        else {
          delete receiptOrderedItem.quantityDispute;
          delete receiptOrderedItem.grnQuantities;
          delete receiptOrderedItem.grnPrices;
        }
      }
      receiptJson.disputeInfo = priceDispute + quantityDispute;
      if (grnDetails.length === 0) {
        receiptJson.disputeInfo = 4;
      } else if (!openGrn) {
        receiptJson.disputeInfo = 0;
      }
      if (resolveMatchedGRNs && openGrn && receiptJson.disputeInfo === 0) {
        GRNManager.closeAllGRNs(receiptId);
      }
      receiptJson.referencesOrder.orderedItem = receiptOrderedItems;
      return JSON.stringify(receiptJson);
    })
  }

  static getTimeline(grnId) {
    let graphDb = ParamConnector.getInstance().getDB();

    return graphDb.transactionsDB.getTransactions(grnId);
  }

}

export default GRNManager;