export const ContactEvents = {
    "onContactAdded": {
        label: "Contact Added"
    },
    "onNotificationInvited": {
        label: "Invited"
    },
    "onContactUpdated": {
        label: "Contact Updated"
    }
}
export const CatalogueEvents = {
    "onCatalogueAdded": {
        label: "Catalogue Added"
    },
    "onCatalogueUpdated": {
        label: "Catalogue Updated"
    },
    "onCatalogueSubscriberAdded": {
        label: "Catalogue Added"
    },
    "onCatalogueUpdateForSubscriber": {
        label: "Catalogue Updated"
    }
}
export const InventoryEvents = {
    "onInventoryAdd": {
        label: "Inventory Added"
    },
    "onInventoryUpdatedV1": {
        label: "Inventory Updated"
    }
}
export const ReceiptEvents = {
    "onStatusUpdateV1": {
        label: "Receipt Status Updated"
    }, "onReceiptV1": {
        label: "Digital Receipt is created successfully."
    }, "onGRNStatusUpdate": {
        label: "GRN updated successfully."
    }, "onConsent": {
        label: "Receipt consent is created."
    }, "onSubscriber": {
        label: "Subscribed Successfully."
    }, "onDocumentUpdate": {
        label: "Document Update Successfully."
    }, "onSubscriberReceiptUpdateV1": {
        label: "Subscriber Receipt UpdateV1 Successfully."
    }
}

export const ReceiptStatusEvents = {
    "onStatusUpdateV1": {
        label: "Receipt Status Updated"
    }
}

export const TemplateConsEvents = {
    "onTemplateAttached": {
        label: ""
    },
    "onSubscriberAdded": {
        label: ""
    },
    "onStepAssinged": {
        label: ""
    },
    "onStepSigned": {
        label: ""
    },
    "onTemplateCompleted": {
        label: ""
    },
    "onTemplateConsensusReset": {
        label: ""
    }
}
export const TemplateConsMerge = {
    "onTemplateConsensusMerged": {
        label: ""
    },
}
export const TemplateRepoEvents = {
    "onTemplateAdded": {
        label: "Template Added"
    },
    "onTemplateEdited": {
        label: "Template Updated"
    },
    "onTemplateStepUpdated": {
        label: "Template Step Updated"
    },
    "onStepAdded": {
        label: "Step Added"
    },
    "onStepEdited": {
        label: "Step Updated"
    }
}

export const RequestEvents = {
    "onRequestUpdated": {
        label: "Request updated"
    }
}