import AppUtils from '../../../app.utils';
import { getFromLocalStorage, setLocalStorage, getRealTimeUpdate } from '../../../util/utils';
// import ElectronStore from 'electron-store';
class RedisSocketClient {

    constructor() {
        const event = require('events')
        this.events = new event.EventEmitter();
        this.canRefesh = new Map();
        this.store = null;
    }


    static getInstance() {
        if (!RedisSocketClient.instance) {
            RedisSocketClient.instance = new RedisSocketClient();
        }
        return RedisSocketClient.instance;
    }

    connect(url, config) {
        return Promise.resolve();
    }

    isConnected() {
        return true;
    }

    publish(channel, message) {
        return this.sendGraphDBResult(channel, message)
    }

    get(channel) {
        let data = null;
        if (this.isDesktop()) {
            let store = this._getStore();
            data = store.get(channel);
        } else {
            data = getFromLocalStorage(channel);
        }
        return this.emit(channel, data)
    }

    subscribe(channel, callback) {
        this.events.addListener(channel, callback);
    }

    getData(data) {
        try {
            return JSON.parse(data);
        }
        catch (err) {
            return data;
        }
    }

    emit(channel, message) {
        let error = null;
        if (!message) {
            error = new Error("Unable to fetch the data.")
        }
        this.events.emit(channel, error, this.getData(message));
    }

    sendGraphDBResult(key, value) {
        try {
            value = JSON.stringify(value);
        } catch{
        }
        if (this.isDesktop()) {
            let store = this._getStore();
            store.set(key, value);
        } else {
            setLocalStorage(key, value);
        }
        this.emit(key, value)
        this.updateCanRefresh(key, false);
    }

    isDesktop() {
        return false;
        // return AppUtils.isDesktop();
    }
    _getStore() {
        if (this.store) {
            return this.store;
        }
        let ElectronStore = require('electron-store');
        debugger;

        ElectronStore = ElectronStore.default;
        this.store = new ElectronStore();

        return this.store;
    }
    unSubscribe(channel, callback) {
        this.events.removeListener(channel, callback);
    }

    getAndSubscribe(channel, callback) {
        this.subscribe(channel, callback);
        this.get(channel);
    }

    canRefresh(channel) {
        if (!this.canRefesh.has(channel)) {
            return true;
        }
        return this.canRefesh.get(channel);
    }

    updateCanRefresh(channel, canUpdate) {
        return this.canRefesh.set(channel, canUpdate)
    }
}

// module.exports = RedisSocketClient;
export default RedisSocketClient