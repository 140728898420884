class LineChartFormatter {
    static salesOrPurchasesStepLineChart(dataFromDb) {
        let data = [];
        dataFromDb.forEach((dbData) => {
            data.push({
                month: dbData.month,
                value: dbData.sales || dbData.purchases
            })
        })
        return data;
    }
    static topPayableReceivableSupplier(dataFromDb) {
        let data = [];
        for (let index in dataFromDb) {
            data.push({
                supplier: index,
                value: dataFromDb[index]
            })
        }
        return data;
    }
}

export default LineChartFormatter;