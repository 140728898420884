class ParserUtils {
    static getCorrectData(value, key, json) {
        if (!value && value !== 0) {
            json[key] = undefined;
        }
        else {
            json[key] = value;
        }
        return json;
    }
}

export default ParserUtils;