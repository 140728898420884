import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";


const Finance = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/default`} />
    {/* <Route
      path={`${match.url}/default`}
      component={asyncComponent(() => import("./Default/index"))}
    />
    <Route
      path={`${match.url}/default?init`}
      component={asyncComponent(() => import("./Default/index"))}
    /> */}
    <Route
      path={`${match.url}/contacts`}
      component={asyncComponent(() => import("../Contacts/index"))}
    />
    <Route
      path={`${match.url}/items`}
      component={asyncComponent(() => import("../Items/index"))}
    />
    <Route
      path={`${match.url}/sales`}
      component={asyncComponent(() => import("./Sales/index"))}
    />
    <Route
      path={`${match.url}/receipt/:id`}
      component={asyncComponent(() => import('./Receipt/index.js'))}
    />
    <Route
      path={`${match.url}/purchases`}
      component={asyncComponent(() => import("./Sales/index"))}
    />
    <Route
      path={`${match.url}/grn`}
      component={asyncComponent(() => import("./Grn/index"))}
    />
    {/* <Route
      path={`${match.url}/elr`}
      component={asyncComponent(() => import("./ELR/index"))}
    /> */}
    <Route
      path={`${match.url}/subscribed`}
      component={asyncComponent(() => import("./Subscribers/index"))}
    />
    <Route
      path={`${match.url}/irp`}
      component={asyncComponent(() => import("./GSTInvoices/index"))}
    />
    <Route
      path={`${match.url}/anx1`}
      component={asyncComponent(() => import("./Annexure1/index"))}
    />
    <Route
      path={`${match.url}/anx2`}
      component={asyncComponent(() => import("./Annexure2/index"))}
    />
    <Route
      path={`${match.url}/invoiceValidation`}
      component={asyncComponent(() => import("./InvoiceValidation/index"))}
    />
    {/* {testing purpose} */}
    <Route
      path={`${match.url}/scheduler`}
      component={asyncComponent(() => import("../../../components/Scheduler/index"))}
    />

  </Switch>
);
export default Finance