import React from "react";
import { BorderlessTableOutlined, BoxPlotOutlined, AuditOutlined, UserSwitchOutlined, ScheduleOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Utils from '../../../../../util/utils';
class ParseTimelineData {

    static getFormattedTimeline = (timelineData, currentStep = '2', currentDocId) => {
        let formattedTimeline = [
            {
                title: 'Contract',
                key: 0,
                children: []
            },
            {
                title: 'Purchase Orders',
                key: 1,
                children: []
            },
            {
                title: 'Invoices',
                key: 2,
                children: []
            },
            {
                title: 'GRNs',
                key: 3,
                children: []
            },
            {
                title: 'Credit Note ',
                key: 4,
                children: []
            },
            {
                title: 'Payments',
                key: 5,
                children: []
            }
        ];
        timelineData = ParseTimelineData.filterTimelineData(timelineData, currentStep, currentDocId, []);
        timelineData = Utils.getUnique(timelineData, '@id');
        console.log("Filtered timeline data", timelineData);
        let docTypeCount = ParseTimelineData.getDocTypeCount(timelineData);
        timelineData.forEach((value, index) => {
            switch (value.docType) {
                case "INIT_PROPOSAL":
                    if (value.step === "0") {
                        formattedTimeline[0].title += `: ${value.currentDocInternalId}`;
                        formattedTimeline[0].children = ParseTimelineData.getChildren(value, 0);
                    }
                    break;
                case "CREATE_PO":
                    if (value.step === "1") {
                        // formattedTimeline[1].title += `: ${ParseTimelineData.getSlicedData(value.currentDocId)}`;
                        formattedTimeline = ParseTimelineData.pushChildrenData(formattedTimeline, 1, "Purchase Order", index, value, docTypeCount, currentStep);
                    }
                    break;
                case "SEND_INVOICE":
                    if (value.step === "2") {
                        formattedTimeline = ParseTimelineData.pushChildrenData(formattedTimeline, 2, "Partial Invoice", index, value, docTypeCount, currentStep);
                    }
                    break;
                case "GRN":
                    formattedTimeline = ParseTimelineData.pushChildrenData(formattedTimeline, 3, "GRN", index, value, docTypeCount);
                    break;
                case "MAKE_PAYMENT":
                    formattedTimeline = ParseTimelineData.pushChildrenData(formattedTimeline, 5, "Partial Payment", index, value, docTypeCount);
                    break;
                case "NOTE":
                    formattedTimeline = ParseTimelineData.pushChildrenData(formattedTimeline, 4, "Credit Note", index, value, docTypeCount);
                    break;
                default:
                    break;
            }
        })
        return formattedTimeline;
    }

    static filterTimelineData = (timelineData, currentStep, currentDocId, filteredTimelineData = []) => {
        for (let i = 0; i < timelineData.length; i++) {
            if (currentDocId === timelineData[i].currentDocId) {
                filteredTimelineData.push(timelineData[i]);
                return ParseTimelineData.filterTimelineData(timelineData, currentStep, `${timelineData[i].pDocId}-${timelineData[i].contractAddress}`, filteredTimelineData)
            }
            else if ((Number(currentStep) < Number(timelineData[i].step) && !ParseTimelineData.doesExists(filteredTimelineData, timelineData[i])) || (!ParseTimelineData.doesExists(filteredTimelineData, timelineData[i]) && timelineData[i].docType === "GRN")) {
                filteredTimelineData.push(timelineData[i]);
            }
        }
        return filteredTimelineData;
    }

    static doesExists(filteredTimelineData, timelineDataObj) {
        if (!timelineDataObj.blockInfo) {
            return false;
        }
        filteredTimelineData.forEach((filteredTimelineDataObj) => {
            if (filteredTimelineDataObj.blockInfo.number === timelineDataObj.blockInfo.number || filteredTimelineDataObj.currentDocId === timelineDataObj.currentDocId) {
                return true;
            }
        })
        return false;
    }

    static getDocTypeCount = (timelineData) => {
        let docTypeCount = {
            "INIT_PROPOSAL": 0,
            "CREATE_PO": 0,
            "SEND_INVOICE": 0,
            "MAKE_PAYMENT": 0,
            "CREATE_RECEIPT": 0,
            "GRN": 0,
            "NOTE": 0,
        }
        timelineData.forEach((data, index) => {
            if (data.docType)
                docTypeCount[data.docType] += 1
        })
        return docTypeCount;
    }

    static pushChildrenData = (formattedTimeline, docTypeIndex, documentText, index, value, docTypeCount) => {
        if (docTypeCount[value.docType] == 1) {
            formattedTimeline[docTypeIndex].title = formattedTimeline[docTypeIndex].title.slice(0, formattedTimeline[docTypeIndex].title.length - 1);
            formattedTimeline[docTypeIndex].title += `: ${value.currentDocInternalId ? value.currentDocInternalId : ""}`
            formattedTimeline[docTypeIndex].children = ParseTimelineData.getChildren(value, docTypeIndex);
        }
        else {
            formattedTimeline[docTypeIndex].children.push({
                title: `${documentText}: ${value.currentDocInternalId}`,
                key: `${docTypeIndex}-${index}`,
                children: ParseTimelineData.getChildren(value, `${docTypeIndex}-${index}`)
            });
        }
        return formattedTimeline;
    }

    static getChildren = (value, index, isWorkflow) => {
        return [
            {
                // title: <a href={`/main/finance/purchases/receipt/id=${currentDocId}`}>Id: ${value.currentDocId}</a>,
                title: `Doc Id: ${value.currentDocInternalId || ParseTimelineData.getSlicedData(value.currentDocId)}`,
                documentId: value.currentDocId,
                step: value.step,
                isWorkflow: isWorkflow,
                contractAddress: value.contractAddress,
                key: `${index}-0`,
                icon: <BorderlessTableOutlined />
            },
            {
                title: `Created at: ${value.blockInfo.created && moment.unix(value.blockInfo.created / 1000).format("YYYY-MM-DD")}`,
                key: `${index}-3`,
                icon: <ScheduleOutlined />
            },
            {
                title: `Block Number: ${value.blockInfo.number}`,
                key: `${index}-1`,
                icon: <BoxPlotOutlined />,
                children: [
                    {
                        // title: <a href={`/main/finance/purchases/receipt/id=${currentDocId}`}>Id: ${value.currentDocId}</a>,
                        title: `Id: ${ParseTimelineData.getSlicedData(value.currentDocId)}`,
                        documentId: value.currentDocId,
                        step: value.step,
                        contractAddress: value.contractAddress,
                        key: `${index}-0`,
                        icon: <BorderlessTableOutlined />
                    },
                    {
                        title: `Txn Hash: ${ParseTimelineData.getSlicedData(value.blockInfo.txHash)}`,
                        key: `${index}-2`,
                        icon: <AuditOutlined />
                    },
                    {
                        title: `Created By: ${value.from.contactName ? value.from.contactName : ParseTimelineData.getSlicedData(value.from.address)}`,
                        key: `${index}-4`,
                        icon: <UserSwitchOutlined />
                    }
                ]
            }
        ]
    }

    static getSlicedData = (data) => {
        if (!data) {
            return ''
        }
        return data.slice(0, 20)
    }

    static getTemplateAttached = (workflowTimelineData, formattedTimeline) => {
        let attachedDocumentId = workflowTimelineData.receiptId;
        for (let timelineIndex in formattedTimeline) {
            if (formattedTimeline[timelineIndex].children && formattedTimeline[timelineIndex].children[0] && formattedTimeline[timelineIndex].children[0].documentId) {
                if (formattedTimeline[timelineIndex].children[0].documentId === attachedDocumentId) {
                    formattedTimeline[timelineIndex].children = ParseTimelineData.attachTemplate(workflowTimelineData, formattedTimeline[timelineIndex].children);
                }
            } else if (formattedTimeline[timelineIndex].children) {
                formattedTimeline[timelineIndex].children = ParseTimelineData.getDocumentChildren(workflowTimelineData, formattedTimeline[timelineIndex].children, attachedDocumentId);
            }
        }
        return formattedTimeline;
    }

    static getDocumentChildren = (workflowTimelineData, children, attachedDocumentId) => {
        for (let child in children) {
            if (children[child].children[0].documentId === attachedDocumentId) {
                children[child] = ParseTimelineData.attachTemplate(workflowTimelineData, children[child])
            }
        }
        return children;
    }

    static attachTemplate(workflowTimelineData, children) {
        let templateInfo = workflowTimelineData.templateInfo[0];
        for (let templateIndex in templateInfo) {
            let value = {};
            if (templateInfo[templateIndex].status === "1") {
                value = {
                    currentDocId: templateInfo[templateIndex].documentId ? templateInfo[templateIndex].documentId : '',
                    blockInfo: {
                        number: templateInfo[templateIndex].blockNumber,
                        txHash: templateInfo[templateIndex].transactionHash,
                        created: templateInfo[templateIndex].dateAndTime,
                    },
                    from: {
                        address: templateInfo[templateIndex].sender
                    }
                }
                children.push({
                    title: templateInfo[templateIndex].title,
                    key: `workflow-${templateIndex}`,
                    children: ParseTimelineData.getChildren(value, templateIndex, true)
                });
            } else if (templateInfo[templateIndex].status === "2") {
                children.push({
                    title: `${templateInfo[templateIndex].title}`,
                    key: `workflow-${templateIndex}`,
                    children: [
                        {
                            title: 'Waiting for the signer to complete the step.',
                            key: `workflow-${templateIndex}-${templateInfo[templateIndex].title}`
                        }
                    ]
                })
            } else {
                children.push({
                    title: `${templateInfo[templateIndex].title}`,
                    key: `workflow-${templateIndex}`,
                    children: [
                        {
                            title: 'Waiting for the signer to complete the step.',
                            key: `workflow-${templateIndex}-${templateInfo[templateIndex].title}`
                        }
                    ]
                })
            }
        }
        return children;
    }

}

export default ParseTimelineData;