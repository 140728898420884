
import { ApolloClient, InMemoryCache, split, HttpLink, createHttpLink, ApolloLink, concat, gql } from '@apollo/client';
import * as Config from "../../config.json"
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws'; import Contacts from './contacts/contacts';
import Items from './items/items';
import Subscribers from './subscribers/subscribers';
import VendorManagement from './vendorManagement/index';
import Receipts from './receipts/receipts';
import Returns from './receipts/returns';
import Grn from './grn/grn';
import TemplatesRepo from './templates/templates';
import TemplatesConsensus from './templates/consensus';
import Console from '../../logger/index';
import ContactEvents from './subscriptions/contact';
import CatalogueEvents from './subscriptions/catalog';
import InventoryEvents from './subscriptions/inventory';
import ReceiptEvents from './subscriptions/receipt';
import ReturnsEvents from './subscriptions/returns';
import SubscriberEvents from './subscriptions/subscriber';
import TemplateConsensusEvents from './subscriptions/template-cons';
import VendorEvents from './subscriptions/vendor';
import TemplateRepoEvents from './subscriptions/template-repo';
import * as Utils from '../../util/utils';
import PublicBook from './publicBook';
import { httpPort, wsPort, protocol, websocket } from '../../constants/AppConstants';

class GraphQL {

    constructor(address) {
        if (GraphQL.instance) {
            throw new Error("You can't create object. Use GraphQL.getInstance()");
        }
        this.setParamId(address);
    }

    setParamId(paramId) {
        this.paramId = paramId;
    }

    static getInstance(address) {
        if (!GraphQL.instance) {
            GraphQL.instance = new GraphQL(address);
            Console.info("Creating GraphQL instance");
        }
        return GraphQL.instance;
    }

    initGraphQl = (uri = "140.238.249.255") => {
        if (this.apolloClientConnector) {
            return  //Promise.resolve();
        }
        const cache = new InMemoryCache();
        const httpLink = new HttpLink({
            uri: `${protocol}://${uri}:${httpPort}/query`
        });

        const wsLink = new WebSocketLink({
            uri: `${websocket}://${uri}:${wsPort}/graphql`,
            options: {
                reconnect: true
            }
        });

        const authMiddleware = new ApolloLink((operation, forward) => {
            // add the authorization to the headers
            operation.setContext({
                headers: {
                    "Authorization": Utils.getParamId(),
                }
            });
            return forward(operation);
        })

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        );

        this.apolloClientConnector = new ApolloClient({
            cache: cache,
            link: concat(authMiddleware, splitLink),
            // Provide some optional constructor fields
            name: 'react-web-client',
            version: '1.3',
            queryDeduplication: false,
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                },
                query: {
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
                mutate: {
                    errorPolicy: 'all',
                }
            },

        });
        this.initObjects(this.apolloClientConnector);
        this.registerForSubsciptions(this.apolloClientConnector);
        return this.apolloClientConnector

    }

    initObjects(apolloClientConnector) {
        this.publicBook = new PublicBook(apolloClientConnector)
        this.contacts = new Contacts(apolloClientConnector);
        this.items = new Items(apolloClientConnector);
        this.receipts = new Receipts(apolloClientConnector);
        this.grn = new Grn(apolloClientConnector);
        this.returns = new Returns(apolloClientConnector);
        this.subscribers = new Subscribers(apolloClientConnector);
        this.templateRepo = new TemplatesRepo(apolloClientConnector);
        this.templateConsensus = new TemplatesConsensus(apolloClientConnector);
        this.vendorManagement = new VendorManagement(apolloClientConnector);
    }
    registerForSubsciptions(apolloClientConnector) {
        let paramId = Utils.getParamId()
        this.contactEvents = new ContactEvents(apolloClientConnector)
        this.contactEvents.registerEvents(paramId)

        this.catalogueEvents = new CatalogueEvents(apolloClientConnector)
        this.catalogueEvents.registerEvents(paramId)

        this.inventoryEvents = new InventoryEvents(apolloClientConnector)
        this.inventoryEvents.registerEvents(paramId)

        this.receiptEvents = new ReceiptEvents(apolloClientConnector)
        this.receiptEvents.registerEvents(paramId)

        this.returnsEvents = new ReturnsEvents(apolloClientConnector)
        this.returnsEvents.registerEvents(paramId)

        this.subscriberEvents = new SubscriberEvents(apolloClientConnector)
        this.subscriberEvents.registerEvents(paramId)

        this.templateRepoEvents = new TemplateRepoEvents(apolloClientConnector)
        this.templateRepoEvents.registerEvents(paramId)

        this.templateConsensusEvents = new TemplateConsensusEvents(apolloClientConnector)
        this.templateConsensusEvents.registerEvents(paramId)

        this.vendorEvents = new VendorEvents(apolloClientConnector)
        this.vendorEvents.registerEvents(paramId)

    }

    sendRawTxn(txn, txnInfo = "private") {
        let options;
        options = {
            mutation: gql`
            mutation sendSignedRawTxn($txn: String!, $txnInfo: String) {
                sendSignedRawTxn(txn: $txn, TxnInfo: $txnInfo)            
            }`,
            variables: { txn, txnInfo }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.sendSignedRawTxn
        })
    }

    getTxnData(txnHash, blockHash = null) {
        let options;
        options = {
            query: gql`
            query getTxnData($txnHash: String, $blockHash: String) {
                getTxnData(txnHash: $txnHash, blockHash: $blockHash){
                    from,
                    to,
                    gas,
                    gasPrice,
                    nonce,
                    date,
                    input,
                    blockHash,
                    hash,
                    type,
                    index,
                    blockNumber,
                    root,
                    logs,
                    value,
                    status,
                    gasUsed
                }          
            }`,
            variables: { txnHash, blockHash }
        }
        return this.apolloClientConnector.query(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.getTxnData
        })
    }

    getEventDetails(id, eventType, idType = "RecordID", eventName) {
        let eventFilterOptions = {}
        eventFilterOptions.ID = id
        eventFilterOptions.IDType = idType
        eventFilterOptions.eventType = eventType
        if (eventName) {
            eventFilterOptions.eventName = eventName
        }
        let options;
        options = {
            query: gql`
            query getEventDetails($eventFilterOptions: InputEventFilterOptions!) {
                 getEventDetails(eventFilterOptions: $eventFilterOptions){
                    eventID,
                    recordID,
                    type,
                    json,
                    blockNumber,
                    address,
                    transactionHash,
                    transactionIndex,
                    transactionBlockHash,
                    index,
                    from,
                    createdAt
                }          
            }`,
            variables: { eventFilterOptions }
        }
        return this.apolloClientConnector.query(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.getEventDetails
        })
    }

    stateGraphV1(docID, contractAddress, state, subState, customer, inputObject, txnType) {
        let options = {
            mutation: gql`
            mutation stateGraphV1($docID: String!, $contractAddress: String!, $state: Int!, $subState: Int!, $customer: String!, $inputObject: InputStateGraphInfo!, $txnType: TRANSACTION_TYPE!){
                stateGraphV1(docID: $docID, ContractAddress: $contractAddress, State: $state, SubState: $subState, Customer: $customer, inputObject: $inputObject, txnType: $txnType)
            }`,
            variables: { docID, contractAddress, state, subState, customer, inputObject, txnType }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.stateGraphV1;
        })
    }

}
export default GraphQL;
