import { GET_IRP_MODE } from "../../constants/ActionTypes";
import { getFromLocalStorage } from '../../util/utils';

const irpConfig = getFromLocalStorage('irpConfig');
const initialSettings = {
    irpMode: irpConfig ? irpConfig.irpMode : false
};

const sidebar = (state = initialSettings, action) => {
    switch (action.type) {

        case GET_IRP_MODE:
            return {
                ...state,
                irpMode: state.irpMode
            };
        default:
            return state;
    }
};

export default sidebar;
