class AcceptNote {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getAcceptNote(acceptNoteId) {
        return this.mongoDBConnector.invoke('acceptNote', "getAcceptNote", acceptNoteId);
    }

    getSummary(acceptNoteId, predicates) {
        return this.mongoDBConnector.invoke('acceptNote', "getSummary", acceptNoteId, predicates);
    }

    getSummaryInBulk(receiptIds, predicates) {
        return this.mongoDBConnector.invoke('acceptNote', "getSummaryInBulk", receiptIds, predicates);
    }

    getValidationData(acceptNoteId) {
        return this.mongoDBConnector.invoke('acceptNote', "getValidationData", acceptNoteId);
    }

    getAllAcceptNotesByDocId(docId, docStep) {
        return this.mongoDBConnector.invoke('acceptNote', "getAllAcceptNotesByDocId", docId, docStep);
    }

    getAcceptNoteInternalId(acceptNoteId) {
        return this.mongoDBConnector.invoke('acceptNote', "getAcceptNoteInternalId", acceptNoteId);
    }

    getAllReceiptsByParentV1(docId) {
        return this.mongoDBConnector.invoke('acceptNote', "getAllReceiptsByParentV1", docId);
    }

    getItemQuantitySummary(docId) {
        return this.mongoDBConnector.invoke('acceptNote', "getItemQuantitySummary", docId);
    }

    attachAcceptNote(acceptNoteId, docId, jsonLd, status, owner) {
        return this.mongoDBConnector.invoke('acceptNote', "attachAcceptNote", acceptNoteId, docId, jsonLd, status, owner);
    }

    getAllReceipts(participant, options, fromDate, toDate) {
        return this.mongoDBConnector.invoke('acceptNote', "getAllReceipts", participant, options, fromDate, toDate);
    }
}

export default AcceptNote;