class SearchDB {
    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    searchIndexByType(searchString, owner, type) {
        return this.mongoDBConnector.invoke("searchDB", "searchIndexByType", searchString, owner, type);
    }

    searchIndex(searchString, owner, type) {
        return this.mongoDBConnector.invoke("searchDB", "searchIndex", searchString, owner, type);
    }
}
export default SearchDB;