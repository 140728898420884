import Contact from './contact';
import Catalogue from './catalogue';
import Receipt from './receipt';
import TemplateRepo from './template-repo';
import TemplateConsensus from './template-cons';
import SyncManager from './sync';
import Subscriber from './subscribers';
import ReconciliationManager from './reconciliation';
import ProfileManager from './profile';
import NotificationManager from './notifications';
import QueryConsoleManager from './queryConsole';
import NodeInfoManager from './nodeInfo';
import EmailManager from './param-email';
import WorkflowReportManager from './workflowReport';
import SearchManager from './search';
import MultiPOManager from './multi-po';
import VendorManager from './vendor-manager';
import KeyStore from './keystore';
import GRNManager from './multi-po';
import Returns from './returns';
import WorkflowReportManagerV1 from './workflowReportV2';
import AcceptNoteManager from './acceptNote';
import PartPOManager from './partPurchaseOrder';
import ELRManager from './elr'
import QualityManager from './qualityManager'
class NetworkBridge {
    constructor() {

    }
    static getContactManager() {
        return Contact;
    }

    static getQualityManager() {
        return QualityManager
    }

    static getItemManager() {
        return Catalogue;
    }

    static getReceiptManager() {
        return Receipt;
    }

    static getTemplateRepoManager() {
        return TemplateRepo;
    }

    static getTemplateConsensusManager() {
        return TemplateConsensus;
    }

    static getSyncManager() {
        return SyncManager;
    }

    static getSubscriberManager() {
        return Subscriber;
    }

    static getReconciliationManager() {
        return ReconciliationManager;
    }

    static getProfileManager() {
        return ProfileManager;
    }

    static getNotificationManager() {
        return NotificationManager
    }

    static getQueryConsoleManager() {
        return QueryConsoleManager
    }

    static getNodeInfoManager() {
        return NodeInfoManager
    }

    static getEmailManager() {
        return EmailManager
    }

    static getWorkflowReportManager() {
        return WorkflowReportManager
    }

    static getSearchManager() {
        return SearchManager
    }

    static getMultiPOManager() {
        return MultiPOManager
    }

    static getGRNManager() {
        return GRNManager
    }

    static getAcceptNoteManager() {
        return AcceptNoteManager
    }

    static getVendorManager() {
        return VendorManager
    }

    static getKeyStoreManager() {
        return KeyStore
    }

    static getReturnsManager() {
        return Returns
    }

    static getWorkflowReportsManagerV1() {
        return WorkflowReportManagerV1
    }

    static getPartPOManager() {
        return PartPOManager;
    }

    static getELRManager() {
        return ELRManager;
    }
}

export default NetworkBridge;