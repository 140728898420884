import React from 'react';
import './index.less';
import * as d3 from "d3";
import * as Utils from '../../util/utils';
import Console from '../../logger';
import noReceiptsImg from '../../assets/images/no_subscriber.png';
import NetworkBridge from '../../util/network-bridge';
import BaseComponent from '../../routes/main/BaseComponent';
import CircularProgress from '../../components/CircularProgress';
import CayleyError from '../../routes/components/CayleyError';
import Analytics from '../../analytics';
import { Card } from 'antd';


class DetailGraph extends BaseComponent {
    constructor(props) {
        super(props);
        Analytics.getInstance().trackPageview();
        this.state = {
            selectedNode: undefined,
            width: 0,
            height: 0,
            showNoReceipt: false,
            isCayleyError: false,
            workflowData: [],
            clickedIds: {}
        }
        this.links = [];
        this.nodes = [{
            id: "receipts",
            label: "Receipts",
            level: 1,
            relation: "0",
            root: "0"
        }];

        this.clicked = {};
        this.receipt = {};
    }
    componentDidMount() {
        super.componentDidMount();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    onDBReady = () => {
        this.getGraphData();
    }

    componentWillUnmount() {
        this.svg.selectAll("*").remove();
        window.removeEventListener('resize', this.updateWindowDimensions);
    }


    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
            Console.debug("Graph- Window width", window.innerWidth)
        });
    }

    getGraphData = () => {
        let address = Utils.getFromLocalStorage('param_id');
        let options = {
            state: ["0", "1", "2", "3", "4"]
        }
        this.receiptManager = NetworkBridge.getReceiptManager();
        this.receiptManager.getAllReceipts(address, undefined, options).then((res) => {
            this.setState({ isCayleyError: false })
            Console.debug('getDocumentIds from d3helper', res);
            if (res.receipts === null || res.count === 0) {
                this.setState({ showNoReceipt: true })
            }
            let arr = Object.assign({}, res)
            arr = arr.receipts;
            arr = Array.from(new Set(arr));
            let internalReceiptIdPromises = [];
            for (let id in arr) {
                let nodeInfo = { id: arr[id], label: arr[id] }
                nodeInfo.level = 2;
                nodeInfo.label = nodeInfo.label.substring(0, 8)
                nodeInfo.root = nodeInfo.id;
                nodeInfo.relation = nodeInfo.id;
                nodeInfo.nodeType = "Document";
                let internalReceiptIdPromise = this.getReceiptInternalId(nodeInfo);

                this.links[id] = {
                    source: "receipts",
                    target: nodeInfo.id,
                    strength: 0.03
                }
                this.links.push(this.links[id])
                internalReceiptIdPromises.push(internalReceiptIdPromise);
            }
            // this.renderGraph(this.nodes, this.links)
            return Promise.all(internalReceiptIdPromises);
        }).then(receiptsInfo => {
            this.nodes = this.nodes.concat(receiptsInfo)
            this.renderGraph(this.nodes, this.links)
        }).catch(error => {
            Console.error(error);
            this.setState({ isCayleyError: true })
        })
    }

    getReceiptInternalId = (nodeObject) => {
        return this.receiptManager.getReceiptInternalId(nodeObject.id)
            .then(label => {
                if (!label) {
                    return nodeObject;
                }
                nodeObject["label"] = label;
                return nodeObject;
            });
    }

    renderGraph = (nodes, links) => {
        let width = window.innerWidth;
        let height = window.innerHeight * 0.65;
        this.svg = d3.select('.chart');
        this.svg.attr('width', width).attr('height', height);
        this.linkGroup = this.svg.append('g').attr('class', 'links')
        this.nodeGroup = this.svg.append('g').attr('class', 'nodes')
        this.textGroup = this.svg.append('g').attr('class', 'texts')
        let linkForce = d3
            .forceLink()
            .id(function (link) {
                return link.id
            })
            .strength(function (link) {
                return link.strength
            })

        this.simulation = d3
            .forceSimulation(nodes)
            .force('link', linkForce)
            .force('charge', d3.forceManyBody().strength(-70))
            .force('center', d3.forceCenter(width / 2, height / 2))

        this.dragDrop = d3.drag()
            .on('start', node => {
                node.fx = node.x
                node.fy = node.y
            })
            .on('drag', node => {
                this.simulation.alphaTarget(0.7).restart()
                node.fx = d3.event.x
                node.fy = d3.event.y
            })
            .on('end', node => {
                if (!d3.event.active) {
                    this.simulation.alphaTarget(0)
                }
                node.fx = null
                node.fy = null
            })
        this.updateSimulation();
    }

    getFontSize = (node) => {
        switch (node.level) {
            case 1:
                return 20;
            case 2:
                return 15;
            case 3:
                return 13;
            case 4:
                return 11;
            case 5:
                return 10;
            default:
                return;
        }
    }

    getNeighbors = (node) => {
        return this.links.reduce(function (neighbors, link) {
            if (link.target.id === node.id) {
                neighbors.push(link.source.id)
            } else if (link.source.id === node.id) {
                neighbors.push(link.target.id)
            }
            return neighbors
        },
            [node.id]
        )
    }

    isNeighborLink = (node, link) => {
        return link.target.id === node.id || link.source.id === node.id
    }

    getNodeColor = (node) => {
        switch (node.level) {
            case 1:
                return "black";
            case 2:
                return "green";
            case 3:
                return "rgb(148, 103, 189)";
            case 4:
                return "rgb(255, 127, 14)";
            case 5:
                return "rgb(174, 199, 232)";
            default:
                return "white";
        }
    }

    getNodeSize = (node) => {
        switch (node.level) {
            case 1:
                return 25;
            case 2:
                return 10;
            case 3:
                return 8;
            case 4:
                return 6;
            case 5:
                return 4;
            default:
                return 1;
        }
    }

    getStrokeColor = () => {
        let theme = Utils.getFromLocalStorage('theme');
        if (theme === 'THEME_TYPE_DARK') {
            return "#E0E0E0"
        } else {
            return "rgba(50, 50, 50, 0.2)"
        }
    }

    getTextColor = () => {
        let theme = Utils.getFromLocalStorage('theme');
        if (theme === 'THEME_TYPE_DARK') {
            return "#E0E0E0"
        } else {
            return "black"
        }
    }

    formatWorkflowData = (templateInfo) => {
        let workflowData = []
        let stepArr = []
        for (let stepIndex in templateInfo.stepInfos) {
            stepArr.push(
                {
                    'id': templateInfo.stepInfos[stepIndex]["@id"],
                    'name': templateInfo.stepInfos[stepIndex].step
                }
            )
        }
        workflowData.push(
            {
                'id': templateInfo["@id"],
                'name': templateInfo.title,
                'steps': stepArr
            }
        )
        Console.debug('workflowData', workflowData)
        this.setState({ workflowData })
    }

    formatReceiptData = (receiptId, selectedNode, receiptJson) => {
        this.receipt[receiptId] = {}
        this.receipt[receiptId]["seller"] = this.setSeller(receiptId, selectedNode, receiptJson.provider);
        this.receipt[receiptId]["buyer"] = this.setBuyer(receiptId, selectedNode, receiptJson.customer);
        this.setItems(receiptId, selectedNode, receiptJson.referencesOrder.orderedItem);
        this.setChildrenNodes(receiptId, selectedNode, receiptJson.childDocId);
        this.updateSimulation();
    }

    setChildrenNodes = (param_document_id, selectedNode, children) => {
        children = Utils.getArrayOfData(children);
        let promiseArray = [];
        for (let index in children) {
            let child = {}
            child['id'] = children[index];
            child['level'] = 2;
            child['label'] = `child${index}`
            child["root"] = param_document_id;
            child['relation'] = `${selectedNode.relation}_${child["id"]}`
            child['nodeType'] = "Document";
            promiseArray.push(this.getReceiptInternalId(child));
        }

        return Promise.all(promiseArray).then(childrenNodes => {
            for (let index in childrenNodes) {
                let childLink = {
                    source: selectedNode,
                    target: childrenNodes[index],
                    strength: 0.03
                }
                this.links.push(childLink);
                this.nodes.push(childrenNodes[index]);
            }
            this.updateSimulation();
            this.setWorkflowNode(param_document_id, selectedNode);
        })
    }

    setWorkflowNode = (param_document_id, selectedNode) => {
        let templateConsensusManager = NetworkBridge.getTemplateConsensusManager();
        templateConsensusManager.getAttachedTemplateDetailByReceiptId(param_document_id).then(result => {
            Console.debug('getData', result)
            this.formatWorkflowData(result)
            if (result) {
                let workflow = {};
                workflow["id"] = `workflow${param_document_id}`
                workflow["label"] = 'Workflow'
                workflow["root"] = param_document_id
                workflow["relation"] = `${selectedNode.relation}_${workflow["id"]}`
                workflow["level"] = 3
                workflow["nodeType"] = "Workflow"

                let workflowLink = {
                    source: selectedNode,
                    target: workflow,
                    strength: 0.1
                }
                this.nodes.push(workflow);
                this.links.push(workflowLink);
                this.receipt[param_document_id]["workflow"] = workflowLink
                this.updateSimulation()
            }
        }).catch(error => {
            Console.error(error);
        }).finally(() => {
            this.updateSimulation();
            this.setState({ showLoaderIcon: false });
        })
    }

    setSeller = (param_document_id, selectedNode, sellerData) => {
        let seller = sellerData;
        seller["id"] = `${seller["identifier"]}_${param_document_id}_seller`;
        seller["label"] = "seller";
        seller["level"] = 3;
        seller["root"] = param_document_id;
        seller["relation"] = `${selectedNode.relation}_${seller["id"]}`;
        seller["nodeType"] = "Seller";

        let sellerLink = {
            source: selectedNode,
            target: seller,
            strength: 0.1
        }
        this.nodes.push(seller);
        this.links.push(sellerLink);
        return sellerLink;
    }

    setBuyer = (param_document_id, selectedNode, buyerData) => {
        let buyer = buyerData;
        buyer["id"] = `${buyer["identifier"]}_${param_document_id}_buyer`;
        buyer["label"] = "buyer";
        buyer["level"] = 3;
        buyer["root"] = param_document_id;
        buyer["relation"] = `${selectedNode.relation}_${buyer["id"]}`;
        buyer["nodeType"] = "Buyer";

        let buyerLink = {
            source: selectedNode,
            target: buyer,
            strength: 0.1
        }
        this.nodes.push(buyer);
        this.links.push(buyerLink);
        return buyerLink;
    }

    setItems = (param_document_id, selectedNode, itemsArray) => {
        let length = itemsArray.length;
        for (let i = 0; i < length; i++) {
            let item = itemsArray[i];
            item = Object.assign({}, item);
            item["id"] = `item${i}`
            item["label"] = item.orderedItem["name"];
            item["name"] = item["label"];
            item["level"] = 4;
            item["root"] = param_document_id
            item["relation"] = `${selectedNode.relation}_${item["id"]}`
            item["nodeType"] = "Item";

            let itemLink = {
                source: selectedNode,
                target: item,
                strength: 0.1
            }

            this.nodes.push(item);
            this.links.push(itemLink);
            this.receipt[param_document_id][item["name"]] = itemLink;
        }
    }

    getNode = (id, label, level, root, selectedNode) => {
        let node = {};
        if (!label)
            return node;
        if (label.startsWith("0x")) {
            label = label.substring(0, 8)
        }
        node["id"] = id;
        node["label"] = label;
        node["level"] = level;
        node["root"] = root;
        node["relation"] = `${selectedNode.relation}_${id}`;
        node = Object.assign({}, node);
        Console.debug("Node Data", node);
        return node;
    }

    getLink = (source, target, strength) => {
        let link = {};
        link["source"] = source;
        link["target"] = target;
        link["strength"] = strength;
        return link;
    }

    isChild(selectedNode, node) {
        if (selectedNode === node || !(node.relation.startsWith(selectedNode.relation))) {
            return true;
        }
        this.links = this.links.filter(link => node.index !== link.index);
        return false;
    }

    resetData = (selectedNode) => {
        this.nodes = this.nodes.filter(node => this.isChild(selectedNode, node));
    }

    handleDocumentNodeClicked = (node) => {
        this.setState({ showLoaderIcon: true }, () => {
            this.receiptManager.getReceipt(node.id).then(res => {

                this.formatReceiptData(res["@id"], node, res);
            })
        })
    }

    setPersonNode = (node, personType) => {

        let root = node.root;
        let object = this.receipt[root][node.label];
        let nameNode = this.getNode(
            `${object.target["id"]}_${personType}`,
            object.target.name,
            node.level + 1,
            object.target.root,
            node
        )
        let nameLink = this.getLink(
            node,
            nameNode,
            0.1
        )
        if (Object.keys(nameNode).length !== 0) {
            this.nodes.push(nameNode);
            this.links.push(nameLink);
        }

        let idNode = this.getNode(
            object.target["id"],
            object.target["id"],
            node.level + 1,
            object.target.root,
            node
        )
        let idLink = this.getLink(
            node,
            idNode,
            0.1
        )
        this.nodes.push(idNode);
        this.links.push(idLink);
        this.updateSimulation();
    }

    handleItemNodeClicked = (node) => {

        let root = node.root;
        let object = this.receipt[root][node.label];
        let quantityNode = this.getNode(
            `${object.target["id"]} quantity`,
            `quantity: ${object.target.orderQuantity}`,
            node.level + 1,
            object.target.root,
            node
        )
        let quantityLink = this.getLink(
            node,
            quantityNode,
            0.1
        )
        this.nodes.push(quantityNode);
        this.links.push(quantityLink);

        let amountNode = this.getNode(
            `${object.target["id"]} amount`,
            `amount: ${object.target.orderedItem.offers.price}`,
            node.level + 1,
            object.target.root,
            node
        )
        let amountLink = this.getLink(
            node,
            amountNode,
            0.1
        )
        this.nodes.push(amountNode);
        this.links.push(amountLink);

        let idNode = this.getNode(
            `${object.target["id"]} id`,
            `id: ${object.target.id}`,
            node.level + 1,
            object.target.root,
            node
        )
        let idLink = this.getLink(
            node,
            idNode,
            0.1
        )
        this.nodes.push(idNode);
        this.links.push(idLink);
        this.updateSimulation();
    }

    selectNode = (selectedNode) => {
        Console.debug('selected Node', selectedNode);
        if (!selectedNode.nodeType) {
            return;
        }
        if (this.state.clickedIds[selectedNode.id]) {
            this.resetData(selectedNode);
            this.updateSimulation();
            this.setState({ clickedIds: { ...this.state.clickedIds, [selectedNode.id]: false } });
            return;
        }
        this.setState({ clickedIds: { ...this.state.clickedIds, [selectedNode.id]: true } });

        switch (selectedNode.nodeType) {
            case "Document":
                return this.handleDocumentNodeClicked(selectedNode);
            case "Seller":
                return this.setPersonNode(selectedNode, "seller");
            case "Buyer":
                return this.setPersonNode(selectedNode, "buyer");
            case "Item":
                return this.handleItemNodeClicked(selectedNode);
            case "Workflow":
                return this.addWorkflowChildNodes(selectedNode);
            case "WorkflowSteps":
                return this.addWorkflowStepChild(selectedNode);
            default:
                return;
        }

    }

    addWorkflowChildNodes = (selectedNode) => {
        let itemsArray = this.state.workflowData;
        let param_document_id = selectedNode.root;
        let length = itemsArray.length;
        for (let i = 0; i < length; i++) {
            let item = itemsArray[i];
            item = Object.assign({}, item);
            item["label"] = item["name"];
            item["name"] = 'workflow_' + item["label"];
            item["level"] = 4;
            item["root"] = param_document_id;
            item["relation"] = `${selectedNode.relation}_${item["id"]}`;
            item["nodeType"] = "WorkflowSteps";

            let itemLink = {
                source: selectedNode,
                target: item,
                strength: 0.3
            }

            this.nodes.push(item);
            this.links.push(itemLink);
            this.receipt[param_document_id]["workflow"][item["name"]] = itemLink;
            this.updateSimulation();
        }
    }

    addWorkflowStepChild = (selectedNode) => {
        let itemsArray = selectedNode.steps;
        let param_document_id = selectedNode.root;
        let length = itemsArray.length;
        for (let i = 0; i < length; i++) {
            let item = itemsArray[i];
            item = Object.assign({}, item);
            item["label"] = item["name"];
            item["name"] = 'step_' + item["label"];
            item["level"] = 5;
            item["root"] = param_document_id
            item["relation"] = `${selectedNode.relation}_${item["id"]}`

            let itemLink = {
                source: selectedNode,
                target: item,
                strength: 0.3
            }

            this.nodes.push(item);
            this.links.push(itemLink);
            this.receipt[param_document_id]["workflow"][selectedNode.name][item["name"]] = itemLink;
            // this.receipt[param_document_id]["workflow"][selectedNode.name] = itemLink;
            this.updateSimulation();
        }
    }

    updateGraph = () => {
        this.linkElements = this.linkGroup.selectAll('line')
            .data(this.links, function (link) {
                return link.target.id + link.source.id
            })
        this.linkElements.exit().remove()
        var linkEnter = this.linkElements
            .enter().append('line')
            .attr('stroke-width', 1)
            .attr('stroke', this.getStrokeColor)
        this.linkElements = linkEnter.merge(this.linkElements)
        // nodes
        this.nodeElements = this.nodeGroup.selectAll('circle')
            .data(this.nodes, function (node) {
                return node.id
            })
        this.nodeElements.exit().remove()
        var nodeEnter = this.nodeElements
            .enter()
            .append('circle')
            .attr('r', this.getNodeSize)
            .attr('fill', this.getNodeColor)
            .call(this.dragDrop)
            .on('click', this.selectNode)
        this.nodeElements = nodeEnter.merge(this.nodeElements)
        // texts
        this.textElements = this.textGroup.selectAll('text')
            .data(this.nodes, function (node) {
                return node.id
            })
        this.textElements.exit().remove()
        var textEnter = this.textElements
            .enter()
            .append('text')
            .attr("fill", this.getTextColor)
            .text(function (node) {
                return node.label
            })
            .attr('font-size', this.getFontSize)
            .attr('dx', 15)
            .attr('dy', 4)
        this.textElements = textEnter.merge(this.textElements)
    }

    updateSimulation = () => {

        this.updateGraph();

        this.simulation.nodes(this.nodes).on('tick', () => {
            this.nodeElements
                .attr('cx', function (node) {
                    return node.x
                })
                .attr('cy', function (node) {
                    return node.y
                })
                .call(this.dragDrop)
            this.textElements
                .attr('x', function (node) {
                    return node.x
                })
                .attr('y', function (node) {
                    return node.y
                })
            this.linkElements
                .attr('x1', function (link) {
                    return link.source.x
                })
                .attr('y1', function (link) {
                    return link.source.y
                })
                .attr('x2', function (link) {
                    return link.target.x
                })
                .attr('y2', function (link) {
                    return link.target.y
                })
            this.simulation.force("link").links(this.links)
        })
        this.simulation.alphaTarget(0.7).restart()
    }

    renderMainContent() {
        return (
            <Card style={{ height: '85vh' }} id="container-card" title={<h2 style={{ marginBottom: 0 }}>Knowledge Graph</h2>}>
                {
                    this.state.isCayleyError ?
                        <CayleyError title="graph data" refresh={this.getGraphData} />
                        :
                        this.state.showNoReceipt ?
                            <div className="noreceipts">
                                <img src={noReceiptsImg} alt="" /><br />
                                <h3 style={{ marginLeft: '-2rem' }}>No Receipts found</h3>
                            </div>
                            :
                            <div>
                                {this.state.showLoaderIcon && <CircularProgress />}
                                <svg width={this.state.width}
                                    height={this.state.height}
                                    className="chart" >
                                </svg>
                            </div>
                }
            </Card>
        )
    }
}

export default DetailGraph;