import Database from '../../index';
import ECIES from '../../../util/ecies';
import * as NetworkUtils from '../../../util/utils';
import ParamConnector from '../../../param-connector'
import Console from '../../../logger/index';
import * as DBUtils from '../Utils/utils';

class RestoreReturns {
    static restoreReturns(returnsId, contractAddress, returnsKey, parentContractAddress) {
        let returnsInstance = Database.getInstance().getDB().returns;
        let returnsIdInfo = NetworkUtils.getTransactionData(returnsId);
        let returnsData;
        return ParamConnector.getInstance().getNetwork().getReturnsManager(contractAddress).getReturn(returnsIdInfo.id).then(res => {
            return DBUtils.getFromIpfsHash(res.jsonLd).then(_receipt => {
                res.jsonLd = _receipt;
                return res;
            })
        }).then(returnData => {
            returnsData = returnData;
            let privateKey = returnsKey || NetworkUtils.getFromLocalStorage("privateKey");
            if (returnsData.txnMode === "2") {
                try {
                    let temp = ECIES.decrypt(privateKey, returnsData.jsonLd);
                    Console.log(temp);
                    returnsData.jsonLd = temp;
                } catch (error) {
                    returnsData.jsonLd = { noAccess: true }
                    returnsData.jsonLd = JSON.stringify(returnsData.jsonLd);
                }
            }
            return returnsInstance.doesExist(returnsId)
        }).then(doesExist => {
            if (doesExist) {
                return returnsInstance.updateStatus(returnsId, returnsData.status, returnsData.step);
            }
            const parentId = NetworkUtils.getTransactionId(returnsData.parentId, returnsData.docContractAddress);
            return returnsInstance.addReturns(returnsId, parentId, returnsData.sellerId, returnsData.buyerId, returnsData.jsonLd, returnsData.metaData, returnsData.txnMode, returnsData.status, returnsData.step);
        }).catch(e => {
            Console.error(`[Database] Error in restoring returns [Reason] ${e} [Module] : Sync/returns/restoreReturns`);
        });
    }

    static restoreAllReturns(functionName, participantAddress, contractAddress, callback) {
        return ParamConnector.getInstance().getNetwork().getReturnsManager(contractAddress)[functionName](participantAddress).then(returnsIds => {
            let promise, promiseArray = [];
            for (let index in returnsIds) {
                const returnsId = NetworkUtils.getTransactionId(returnsIds[index], contractAddress);
                promise = RestoreReturns.restoreReturns(returnsId, contractAddress);
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring returns [Reason] ${e} [Module] : Sync/returns/restoreReturns`);
        });
    }
}
export default RestoreReturns;