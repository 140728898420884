class CommonCharts {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getVendorCustomerCount(contactType, status, fromDate, toDate) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getVendorCustomerCount", contactType, status, fromDate, toDate)
    }

    getActiveInactiveCustomers() {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getActiveInactiveCustomers");
    }

    getTotalCustomers() {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getTotalCustomers");
    }

    getTotalSuppliers() {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getTotalSuppliers");
    }

    getReceiptsCount(role) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getReceiptsCount", role)
    }

    getAllReceiptsCount(state, receiptType, fromDate, toDate) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getAllReceiptsCount", state, receiptType, fromDate, toDate)
    }

    getAllReceipts(paramId, options, fromDate, toDate) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getAllReceipts", paramId, options, fromDate, toDate)
    }

    getDisputeCount(receiptType) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getDisputeCount", receiptType)
    }

    getCatalogueData() {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getCatalogueData");
    }

    getItemCount() {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getItemCount");
    }

    getMinInventoryCount() {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getMinInventoryCount");
    }

    getTopSalesOrPurchases(groupBy, action, fromDate, toDate) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getTopSalesOrPurchases", groupBy, action, fromDate, toDate)
    }

    getItemDetailsBatchwise(itemIds, step, selfRoleFilter, groupByTag, batchStartIndex, batchSize, response) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getItemDetailsBatchwise", itemIds, step, selfRoleFilter, groupByTag, batchStartIndex, batchSize, response)
    }

    getTopSalesOrPurchasesItemwise(selfRole, fromDate, toDate) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getTopSalesOrPurchasesItemwise", selfRole, fromDate, toDate)
    }

    getContractRenewalData(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getContractRenewalData", selfRole)
    }

    getPurchases(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getPurchases", selfRole)
    }

    getAverageCycleTimeV1(cycleType) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getAverageCycleTimeV1", cycleType)
    }

    getAverageCycleTime(cycleType) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getAverageCycleTime", cycleType)
    }

    calculateInitConfirmTime() {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "calculateInitConfirmTime");

    }

    averageFinancePaymentReceivableCycle(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "averageFinancePaymentReceivableCycle", selfRole)
    }

    outstandingReceivableOrPayable(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "outstandingReceivableOrPayable", selfRole)
    }

    getUnreconciledPaymentOrReceivable(receiptType) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getUnreconciledPaymentOrReceivable", receiptType)
    }

    topPayableReceivableSupplier(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "topPayableReceivableSupplier", selfRole)
    }

    getOnTimeDelivery(receiptType) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getOnTimeDelivery", receiptType)
    }

    getDeliveryStatusByRole(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getDeliveryStatusByRole", selfRole)
    }

    getFillRate(receiptType) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getFillRate", receiptType)
    }

    getTopQuantityDisputes(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getTopQuantityDisputes", selfRole)
    }
    getTimeStamps(status, documentType) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getTimeStamps", status, documentType)
        //this function gives the list of time stamps for the given document type and given status. 

    }

    getStartEndDate(address) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getStartEndDate", address)
    }

    getAllOpenOrPartialInvoices(selfRole) {
        return this.mongoDBConnector.invoke("commonCharts", "reports", "getAllOpenOrPartialInvoices", selfRole)
    }

}
export default CommonCharts;