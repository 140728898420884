import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Widgets from "./Widgets";
// import Metrics from "./Metrics";
// import Dashboard from "./dashboard";
import Finance from "./finance";
import VendorManagement from "./VendorManagement";
import Operations from "./operations";
import Landing from "./Landing"
import Setting from "./Setting";
// import Layouts from "./Layouts";
// import asyncComponent from "../../util/asyncComponent";
import Create from "./create";
import Test from "./test";
import UserProfile from "./UserProfile";
// import SocialProfile from "../socialApps/Profile";
import Reports from './reports';
import KnowledgeGraph from "../../components/graph-container";
import Search from './Search';
import TestSmartContract from "./test/testsmartcontracts";
import TestGraphDB from "./test/testgraphdb";
import TestChartsBackend from './test/testChartsBackend';
import TestNetworkBridge from "./test/testNetworkBridge";
import TestSync from "./test/testsync";


const Main = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/finance`} />
    <Route path={`${match.url}/finance`} component={Finance} />
    <Route path={`${match.url}/vendormanagement`} component={VendorManagement} />
    <Route path={`${match.url}/operations`} component={Operations} />
    <Route path={`${match.url}/report`} component={Reports} />
    <Route path={`${match.url}/setting`} component={Setting} />
    {/*<Route path={`${match.url}/dashboard`} component={Dashboard} />
     <Route path={`${match.url}/widgets`} component={Widgets} />
    <Route path={`${match.url}/metrics`} component={Metrics} />
    <Route path={`${match.url}/layouts`} component={Layouts} />
    <Route
      path={`${match.url}/algolia`}
      component={asyncComponent(() => import("../algolia"))}
    /> */}
    <Route path={`${match.url}/create`} component={Create} />
    <Route path={`${match.url}/edit`} component={Create} />
    <Route path={`${match.url}/test`} component={Test} />
    <Route path={`${match.url}/testsmartcontract`} component={TestSmartContract} />
    <Route path={`${match.url}/testgraphdb`} component={TestGraphDB} />
    <Route path={`${match.url}/testChartsBackend`} component={TestChartsBackend} />
    <Route path={`${match.url}/testnetworkbridge`} component={TestNetworkBridge} />
    <Route path={`${match.url}/testsync`} component={TestSync} />
    <Route path={`${match.url}/userprofile`} component={UserProfile} />
    <Route path={`${match.url}/landing`} component={Landing} />
    <Route path={`${match.url}/search`} component={Search} />
    {/* <Route
      path={`${match.url}/social-apps/profile`}
      component={SocialProfile}
    /> */}
    <Route path={`${match.url}/kgraph`} component={KnowledgeGraph} />
  </Switch>
);

export default Main;
