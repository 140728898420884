
import React from 'react';
import { Row, Col, Drawer, Table, Badge, Button, Radio, Form, Select } from "antd";
import './index.less';
import * as APP_CONSTANTS from '../../../constants/AppConstants';
import * as Utils from '../../../util/utils';
import CircularProgress from '../../../components/CircularProgress';
const { Option } = Select;
const fiveSteps = ["Contract", "Purchase Order", "Invoice", "Payment"];

class AttatchWorkFlowDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDrawerVisible: true,
            data: APP_CONSTANTS.previewTableData,
            columns: [
                {
                    title: 'S.No',
                    dataIndex: 'sno',
                    key: 'sno',
                    render: (text, record) => (
                        <Badge
                            count={text}
                            style={{
                                backgroundColor: '#52c41a',
                                boxShadow: '0 0 0 2px #52c41a inset'
                            }}
                        />
                    )
                },
                {
                    title: 'Steps',
                    dataIndex: 'steps',
                    key: 'steps',
                },
                {
                    title: 'Assign Roles',
                    dataIndex: 'party',
                    key: 'party',
                }
            ],
            metaData: {},
            stepJSON: [],
            templateDetail: this.props.templateDetail,
            stepSigners: [],
            showDrawerLoader: false,
            supportProtected: true,
            workflowTxntype: '2',
            selectedContact: {},
            currentState: this.props.currentState,
            selectedState: '0'
        }
    }

    componentDidMount() {
        const selfParamId = Utils.getParamId();
        this.getSteps()
        let totalParties = this.props.templateDetail.metaData.partiesInvolved.length;
        let initialProtectedString = "1 ".repeat(totalParties);
        initialProtectedString = initialProtectedString.trim();
        initialProtectedString = initialProtectedString.split(' ');
        return Utils.getAllContacts(selfParamId).then(contacts => {
            let { suggestionDetails, suggestionList } = Utils.createSuggestionList(contacts, "contacts");
            this.setState({
                selectContactOptionDetails: suggestionDetails,
                contactSuggestionList: suggestionList,
                initialProtectedString,
                modifiedProtectedString: [].concat(initialProtectedString),
                totalParties
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isDrawerVisible: true,
        })
    }

    hideDrawer = () => {
        console.log("AttachDetailDrawer hideDetailDrawer")
        this.setState({ isDrawerVisible: false }, () => {
            this.props.onDetailClose();
        })
    }

    hideAllDrawers = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.setState({
                    isDrawerVisible: false,
                    showDrawerLoader: false
                }, () => {
                    let templateDetail = this.state.templateDetail;
                    templateDetail["stepSigners"] = this.state.stepSigners;
                    this.props.onWorkflowButtonClicked(templateDetail, this.state.workflowTxntype, this.state.selectedState);
                })
            }
        })
    }

    onSelectContact = (item, value, option) => {
        console.log(this.state.totalParties);
        let stepSigners = this.state.stepSigners;
        let roleData = this.props.templateDetail.metaData.partiesInvolved;
        let supportProtected = true;
        let workflowTxntype = "2";
        let modifiedProtectedString = this.state.modifiedProtectedString;
        let initialProtectedString = this.state.initialProtectedString;

        for (let index in roleData) {
            if (roleData[index].party === item) {
                modifiedProtectedString[index] = this.state.contactSuggestionList[value].publicKey ? "1" : "0";
                stepSigners[parseInt(roleData[index].stepNo) - 1] = this.state.contactSuggestionList[value].identifier;
            }
        }
        for (let key in initialProtectedString) {
            if (initialProtectedString[key] !== modifiedProtectedString[key]) {
                workflowTxntype = "1";
                supportProtected = false;
                break;
            }
        }
        this.setState({
            stepSigners,
            supportProtected,
            workflowTxntype,
            modifiedProtectedString
        })
    }

    getSteps = () => {
        let currentState = this.state.currentState;
        let currentStateIndex = fiveSteps.indexOf(currentState);
        let fiveStepsApplicable = fiveSteps.map((value, index) => {
            if (index >= currentStateIndex) {
                return <Option key={index}>{value}</Option>
            }
        })
        this.setState({
            fiveSteps: fiveStepsApplicable,
            selectedState: currentStateIndex
        })
    }

    renderRoles = () => {
        const { getFieldDecorator } = this.props.form;
        let roleData = this.props.templateDetail.metaData;
        let selectContactOptionDetails = this.state.selectContactOptionDetails;
        let formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
        }
        let roleList = roleData.parties.map((item) => {
            return (
                <Form.Item label={item}>
                    {getFieldDecorator(item, {
                        rules: [{
                            required: true, message: `Please input ${item}`
                        }],
                    })(
                        <Select
                            mode="default"
                            showSearch
                            placeholder="Please select"
                            onChange={(value) => this.onSelectContact(item, value)}
                            filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {selectContactOptionDetails}
                        </Select>
                    )}
                </Form.Item>
            )
        });

        return (
            <Form {...formItemLayout}>
                {roleList}
                <Form.Item label="Select Step">
                    {getFieldDecorator('selectStep', {
                        rules: [{
                            required: false
                        }],
                        initialValue: fiveSteps[this.state.selectedState]
                    })(
                        <Select
                            mode="default"
                            placeholder="Please select step to which workflow should be attached"
                            onChange={this.onSelectStep}
                            style={{ width: 330 }}
                        >
                            {this.state.fiveSteps}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Workflow Type">
                    {getFieldDecorator('workflowType', {
                        rules: [{
                            required: false
                        }],
                        initialValue: this.state.workflowTxntype
                    })(
                        <Radio.Group onChange={this.onChangeTransactionType} value={this.state.workflowTxntype} defaultValue={this.state.workflowTxntype}>
                            <Radio.Button value="3">Private</Radio.Button>
                            <Radio.Button disabled={!this.state.supportProtected} value="2">Protected</Radio.Button>
                            <Radio.Button value="1">Public</Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>
            </Form>
        );
    }

    renderTable = () => {
        let dataSource = Utils.createPreviewTableData(this.props.templateDetail.stepInfos, this.props.templateDetail.metaData);
        return (
            <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Table columns={this.state.columns}
                        dataSource={dataSource} size="middle" />
                </Col>
            </Row>
        )
    }

    onChangeTransactionType = (e) => {
        let selectedValue = e.target.value;
        this.setState({
            workflowTxntype: selectedValue
        });
    }

    onSelectStep = (value) => {
        console.log(value)
        this.setState({
            selectedState: value
        })
    }

    render() {
        return (
            <Drawer
                className="attach-workflow-detail"
                title={this.props.templateDetail.metaData.title}
                width='720px'
                onClose={this.hideDrawer}
                visible={this.state.isDrawerVisible}
            >
                {this.state.showDrawerLoader ?
                    <CircularProgress />
                    :
                    <div>
                        <div className="drawercontainer">
                            <p>Brief instruction on how adding these predefined workflow will effect the current workflow</p>
                            {this.renderRoles()}
                        </div>
                        {this.renderTable()}
                    </div>
                }
                <div className="drawercontainer-button drawerFooterButtonSection">
                    <Button disabled={this.state.showDrawerLoader} onClick={this.hideAllDrawers} type="primary">
                        Submit
                    </Button>
                </div>
            </Drawer>
        )
    }
}

const StepSignerForm = Form.create({ name: 'newForm' })(AttatchWorkFlowDetail)
export default StepSignerForm;
