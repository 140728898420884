import localStorageObj from "../param-libs/param-dashboard/config.json";

class InMemoryStorage{
    static get(key){
      return localStorageObj[key]
    }
    static set(key, value){
        localStorageObj[key] = value
    }

    static clear(){
        // localStorageObj = {};
    }

    static remove(key){
        delete localStorageObj[key]
    }
}
export default InMemoryStorage; 