import Contact from './contacts';
import Catalogue from './items';
import Receipt from './receipts';
import TemplateRepo from './template-repo';
import TemplateConsensus from './template-cons';
import Subscriber from './subscribers';
import GRNManager from './grn';
import VendorManager from './vendorManagement';
import Returns from './returns';
import Versioncontrol from './versionControl';


class SyncInstance {

    static getContactManager() {
        return Contact;
    }

    static getItemManager() {
        return Catalogue;
    }

    static getReceiptManager() {
        return Receipt;
    }

    static getTemplateRepoManager() {
        return TemplateRepo;
    }

    static getTemplateConsensusManager() {
        return TemplateConsensus;
    }

    static getSubscriberManager() {
        return Subscriber;
    }

    static getGRNManager() {
        return GRNManager
    }

    static getVendorManager() {
        return VendorManager
    }

    static getReturnsManager() {
        return Returns
    }

    static getVersioncontrolManager(){
        return Versioncontrol;
    }

}

export default SyncInstance;