import Console from '../../../logger/index';
import { gql } from '@apollo/client';
import RestoreItems from '../sync/items';
import Database from '../../../database/index';
import * as NetworkUtils from '../../../../src/util/utils';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import GraphQL from '..';


class CatalogueEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient
        this.dbInstance = Database.getInstance().getDB()
    }
    registerEvents(paramId) {
        let addCatalogOptions = {
            query: gql`
               subscription onCatalogueAdded($paramId: [String]){
                  onCatalogueAdded(catalogueOwnerID: $paramId){
                        contractAddress,
                        catalogueId,
                        owner
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(addCatalogOptions).subscribe({
            next(data) {
                that._onCatalogueAdded(data)
            },
            error(error) {
                Console.error(`Unable to get create catalaogue details form network, Reson: ${error}`);
            },
        });
        let updateCatalogOptions = {
            query: gql`
               subscription onCatalogueUpdated($paramId: [String]){
                  onCatalogueUpdated(catalogueOwnerID: $paramId){
                    contractAddress,
                    catalogueId,
                    owner,
                    status
                  }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(updateCatalogOptions).subscribe({
            next(data) {
                that._onCatalogueUpdated(data)
            },
            error(error) {
                Console.error(`Unable to get create catalaogue details form network, Reson: ${error}`);
            },
        });

        let onCatalogueSubscriberAddedOptions = {
            query: gql`
               subscription onCatalogueSubscriberAdded($paramId: [String]){
                  onCatalogueSubscriberAdded(catalogueOwnerID: $paramId){
                    contractAddress,
                    catalogueId,
                    owner,
                    subscribers,
                    status,
                    subscriber,
                    sID
                  }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(onCatalogueSubscriberAddedOptions).subscribe({
            next(data) {
                that._onCatalogueSubscriberAdded(data)
            },
            error(error) {
                Console.error(`Unable to get create catalaogue details form network, Reson: ${error}`);
            },
        });

        let catalogueStatusUpdatedOptions = {
            query: gql`
               subscription onCatalogueStatusUpdated($paramId: [String]){
                  onCatalogueStatusUpdated(catalogueOwnerID: $paramId){
                    contractAddress,
                    buyerID,
                    sellerID,
                    buyerItemsIds,
                    sellerItemsIds,
                    status
                  }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(catalogueStatusUpdatedOptions).subscribe({
            next(data) {
                that._onCatalogueStatusUpdated(data)
            },
            error(error) {
                Console.error(`Unable to get create catalaogue details form network, Reson: ${error}`);
            },
        });

        let catalogueUpdateForSubscriberOptions = {
            query: gql`
               subscription onCatalogueUpdateForSubscriber($paramId: [String]){
                  onCatalogueUpdateForSubscriber(catalogueOwnerID: $paramId){
                    contractAddress,
                    catalogueId,
                    owner
                  }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(catalogueUpdateForSubscriberOptions).subscribe({
            next(data) {
                that._onCatalogueUpdateForSubscriber(data)
            },
            error(error) {
                Console.error(`Unable to get create catalaogue details form network, Reson: ${error}`);
            },
        });

        Console.log(`Registered Events for catalogue`)
    }

    _onCatalogueAdded(subscriptionDataJSON) {
        Console.debug(subscriptionDataJSON)
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onCatalogueAdded
        // let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress);
        let itemId = dataJSON.args.catalogueId
        let transactionData = NetworkUtils.getTransactionData(dataJSON.args.catalogueId)
        dataJSON.args.catalogueId = transactionData.id
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        return RestoreItems.restoreItem(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, undefined, true).then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            return this._emitOnCatalogueAdded(itemId);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        })
    }

    _onCatalogueUpdated(subscriptionDataJSON) {
        //debugger
        Console.debug(subscriptionDataJSON)
        let dataJSON = {}
        let eventName = "onCatalogueUpdated"
        dataJSON.args = subscriptionDataJSON.data[eventName]
        dataJSON.args.status = dataJSON.args.status.toString();
        // let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress);
        let itemId = dataJSON.args.catalogueId
        let transactionData = NetworkUtils.getTransactionData(dataJSON.args.catalogueId)
        dataJSON.args.catalogueId = transactionData.id
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        let functionName = dataJSON.args.status === '0' ? "restoreItem" : "restoreInventory";
        return RestoreItems[functionName](dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner, undefined, true, "updateItem").then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            return this.dbInstance.emitEvent("OnCatalogueUpdated", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        }).finally(() => {
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(itemId, NetworkUtils.getEventType('catalogue')).then(res => {
                let result = NetworkUtils.getLatestEventDataByName(res, "onCatalogueUpdated")
                let eventMetaData = NetworkUtils.extractEventData(result)
                dataJSON = { ...dataJSON, ...eventMetaData }
                let metaData = NetworkDataFormat.getMetaInfo(itemId, dataJSON, "Catalogue added");
                if (dataJSON.args.status === "1") {
                    metaData.updateType = dataJSON.args.updateType;
                }
                return this.dbInstance.transactionsDB.addTransaction(itemId, dataJSON.args.owner, metaData).catch(error => {
                    Console.error(`Unable to edit transaction for ${itemId}, Reason: ${error.toString()}`)
                })
            })
        })
    }

    _onCatalogueUpdateForSubscriber(subscriptionDataJSON) {
        Console.info(subscriptionDataJSON)
        //debugger
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onCatalogueUpdateForSubscriber
        let itemId = dataJSON.args.catalogueId
        dataJSON.args.catalogueId = NetworkUtils.getTransactionData(dataJSON.args.catalogueId).id
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        return RestoreItems.updateItemForSubscriber(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.owner).then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            // this.dbInstance.updateLastSync();
            return this.dbInstance.emitEvent("OnCatalogueUpdateForSubscriber", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        }).finally(() => {
            return this.writeOnCatalogueSubscriber(dataJSON, "onCatalogueUpdateForSubscriber")
        })
    }

    _onCatalogueStatusUpdated(subscriptionDataJSON) {
        Console.info(subscriptionDataJSON)
        //debugger
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onCatalogueStatusUpdated
        let promise, promiseArray = [];
        for (let index in dataJSON.args.buyerItemsIds) {
            let buyerItemId = NetworkUtils.getTransactionId(dataJSON.args.buyerItemsIds[index], dataJSON.args.contractAddress);
            let sellerItemId = NetworkUtils.getTransactionId(dataJSON.args.sellerItemsIds[index], dataJSON.args.contractAddress);
            promise = this.dbInstance.items.updateMappedItemStatus(buyerItemId, sellerItemId, dataJSON.args.buyerID, dataJSON.args.sellerID, dataJSON.args.status);
            promiseArray.push(promise);
        }
        return Promise.all(promiseArray).then(() => {
            return this.dbInstance.emitEvent("OnCatalogueStatusUpdated", undefined, null);
        }).catch(e => {
            Console.error(`Unable to map catalogue ${e.toString()}`);
        });
    }

    _onCatalogueSubscriberAdded(subscriptionDataJSON) {
        Console.info(subscriptionDataJSON)
        //debugger
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onCatalogueSubscriberAdded
        let itemId = dataJSON.args.catalogueId
        dataJSON.args.catalogueId = NetworkUtils.getTransactionData(dataJSON.args.catalogueId).id
        Console.log(`[GraphDB] Got CatalogueAdd event for ${itemId}`);
        if (dataJSON.args.owner === NetworkUtils.getParamId()) {
            return this.dbInstance.items.addSubscriber(itemId, dataJSON.args.subscriber);
        }
        return RestoreItems.restoreItemForSubscriber(dataJSON.args.catalogueId, dataJSON.args.contractAddress, dataJSON.args.sID, dataJSON.args.owner).then(() => {
            Console.log(`[GraphDB] Added catalogue info to graph ${itemId}`);
            return this.dbInstance.emitEvent("OnCatalogueSubscriberAdded", itemId, null);
        }).catch(e => {
            Console.error(`Unable to add catalogue ${e.toString()}`);
        }).finally(() => {
            return this.writeOnCatalogueSubscriber(dataJSON, "onCatalogueSubscriberAdded")
        })
    }

    writeOnCatalogueSubscriber(dataJSON, eventName) {
        let itemId = NetworkUtils.getTransactionId(dataJSON.args.catalogueId, dataJSON.args.contractAddress)
        let grapQlInstance = GraphQL.getInstance()
        return grapQlInstance.getEventDetails(itemId, NetworkUtils.getEventType('catalogue')).then(res => {
            let result = NetworkUtils.getLatestEventDataByName(res, eventName)
            let eventMetaData = NetworkUtils.extractEventData(result)
            dataJSON = { ...dataJSON, ...eventMetaData }
            let metaData = NetworkDataFormat.getMetaInfo(itemId, dataJSON, "Catalogue added");
            return this.dbInstance.transactionsDB.addTransaction(itemId, dataJSON.args.owner, metaData)
        }).catch(error => {
            Console.error(`Unable to edit transaction for ${itemId}, Reason: ${error.toString()}`)
        })
    }

    _emitOnCatalogueAdded(itemId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.items.getItem(`${itemId}`).then(itemResponse => {
            this.dbInstance.emitEvent("OnCatalogue", itemResponse);
            console.info(`[GraphDB] emiting OnCatalogue event.`);
        }).catch(e => {
            this.dbInstance.emitEvent("OnCatalogue", null, e);
            Console.error(`[GraphDB] emiting OnCatalogue event error ${e}.`);
        });
    }
}

export default CatalogueEvents;