import ReceiptEvents from './events/receipt';
import CatalogueEvents from './events/catalogue';
import VendorEvents from './events/vendors';
import ReturnsEvents from './events/returns';
import * as NetworkUtils from '../../util/utils';

class ParamDashBoardConnector {

    constructor() {
        if (this.instance) {
            throw new Error("Instance already exists");
        }
    }

    static getInstance() {
        if (!ParamDashBoardConnector.instance) {
            ParamDashBoardConnector.instance = new ParamDashBoardConnector();
        }
        return ParamDashBoardConnector.instance;
    }

    registerEvents(paramId) {
        NetworkUtils.setCanFetchData();

        // Receipt Events
        if (!this.receiptEvents) {
            this.receiptEvents = new ReceiptEvents(paramId);
            this.receiptEvents.registerEvents();
        }

        // Catalogue Events
        if (!this.catalogueEvents) {
            this.catalogueEvents = new CatalogueEvents(paramId);
            this.catalogueEvents.registerEvents();
        }

        // Vendor Management Events
        if (!this.vendorEvents) {
            this.vendorEvents = new VendorEvents(paramId);
            this.vendorEvents.registerEvents();
        }

        // Return Request 
        if (!this.returnsEvents) {
            this.returnsEvents = new ReturnsEvents(paramId);
            this.returnsEvents.registerEvents();
        }
    }
    getReceiptDashboard() {
        return this.receiptEvents;
    }
    geCatalogueDashboard() {
        return this.catalogueEvents;
    }
    getVendorDashboard() {
        return this.vendorEvents;
    }
    getReturnsDashboard() {
        return this.returnsEvents;
    }
}

export default ParamDashBoardConnector;