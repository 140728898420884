import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import Receipt from './receipt';
import NetworkBridge from '.';
import ECIES from '../ecies';
import * as DBUtils from '../../database/nosql/Utils/utils';
import GraphQL from '../../param-libs/graph-ql';

class Returns {
    static initReturnRequest(docId, buyerId, sellerId, jsonLd, metaData, txnType) {
        let returnsManager = GraphQL.getInstance().returns
        // let receiptTransactionData = Utils.getTransactionData(docId);
        const receiptManager = GraphQL.getInstance().receipts;
        return Receipt.encryptJsonLd(jsonLd, docId, receiptManager).then(res => {
            let inputObject = {}
            if (txnType !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
            return returnsManager["initReturnRequest"](docId, buyerId, sellerId, inputObject, metaData, txnTypeString); //receiptTransactionData.id
        }).then(res => {
            let signedReturnData = Utils.codeSignPaylaod(JSON.parse(res), '0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15')
            return GraphQL.getInstance().sendRawTxn(signedReturnData)
        })
    }

    static issueReturnOrder(docId, requestId, jsonLd, metaData, txnType) {
        let returnsManager = GraphQL.getInstance().returns
        let returnsTransactionData = Utils.getTransactionData(requestId);
        const receiptManager = GraphQL.getInstance().receipts;
        return Receipt.encryptJsonLd(jsonLd, docId, receiptManager).then(res => {
            let inputObject = {}
            if (txnType !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            let txnTypeString = Utils.getTxnTypeForGraphQL(txnType)
            return returnsManager["issueReturnOrder"](returnsTransactionData.id, inputObject, metaData, txnTypeString);
        }).then(res => {
            let signedReturnData = Utils.codeSignPaylaod(JSON.parse(res), '0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15')
            return GraphQL.getInstance().sendRawTxn(signedReturnData)
        })
    }

    static changeDocumentAcceptState(docId, note = "", txnType = "1", functionName) {
        let returnsTransactionData = Utils.getTransactionData(docId);
        let returnsManager = GraphQL.getInstance().returns
        return Returns[functionName](docId).then(res => { //returnsTransactionData.id
            if (functionName === "acceptReturnOrder") {
                const receiptManager = NetworkBridge.getReceiptManager();
                let jsonLd, returnsData;
                return returnsManager.getReturn(docId).then(res => { //returnsTransactionData.id
                    returnsData = res;
                    jsonLd = res.jsonLd;
                    const privateKey = Utils.getPrivateKey();
                    const parentId = Utils.getTransactionId(returnsData.parentId, returnsTransactionData.address);
                    if (txnType === "2") {
                        jsonLd = ECIES.decrypt(privateKey, jsonLd);
                    }
                    jsonLd = JSON.parse(jsonLd);
                    jsonLd['@subType'] = "debitNote";
                    jsonLd['attachedFromReturns'] = true;
                    jsonLd = JSON.stringify(jsonLd);
                    return Returns.getParentDocId(parentId);
                }).then(receiptId => {
                    return receiptManager.makePayment(receiptId, jsonLd, "", txnType)
                })
            }
            else {
                return res;
            }
        })
    }

    static acceptReturnRequest(requestId) {
        let returnsManager = GraphQL.getInstance().returns
        let returnsTransactionData = Utils.getTransactionData(requestId);
        return returnsManager["acceptReturnRequest"](returnsTransactionData.id).then(res => {
            let signedReturnData = Utils.codeSignPaylaod(JSON.parse(res), '0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15')
            return GraphQL.getInstance().sendRawTxn(signedReturnData)
        })
    }

    static acceptReturnOrder(returnOrderId) {
        let returnsManager = GraphQL.getInstance().returns
        let returnsTransactionData = Utils.getTransactionData(returnOrderId);
        return returnsManager["acceptReturnOrder"](returnsTransactionData.id).then(res => {
            let signedReturnData = Utils.codeSignPaylaod(JSON.parse(res), '0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15')
            return GraphQL.getInstance().sendRawTxn(signedReturnData)
        })
    }

    static rejectReturnRequest(requestId) {
        let returnsManager = GraphQL.getInstance().returns
        let returnsTransactionData = Utils.getTransactionData(requestId);
        return returnsManager["rejectReturnRequest"](returnsTransactionData.id).then(res => {
            let signedReturnData = Utils.codeSignPaylaod(JSON.parse(res), '0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15')
            return GraphQL.getInstance().sendRawTxn(signedReturnData)
        })
    }

    static rejectReturnOrder(returnOrderId) {
        let returnsManager = GraphQL.getInstance().returns
        let returnsTransactionData = Utils.getTransactionData(returnOrderId);
        return returnsManager["rejectReturnOrder"](returnsTransactionData.id).then(res => {
            let signedReturnData = Utils.codeSignPaylaod(JSON.parse(res), '0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15')
            return GraphQL.getInstance().sendRawTxn(signedReturnData)
        })
    }

    static getAllReturnsByDocId(step, docId) {
        return ParamConnector.getInstance().getDB().returns.getAllReturnsByDocId(step, docId);
    }
    static getAllReturnsBySeller(sellerAddress, step) {
        return ParamConnector.getInstance().getDB().returns.getAllReturnsBySeller(sellerAddress, step);
    }
    static getAllReturnsByBuyer(buyerAddress, step) {
        return ParamConnector.getInstance().getDB().returns.getAllReturnsByBuyer(buyerAddress, step);
    }
    static getReturn(requestId) {
        return ParamConnector.getInstance().getDB().returns.getReturn(requestId);
    }
    static getReceipt(requestId) {
        return Returns.getReturn(requestId);
    }
    static getReturnSummary(requestId, predicates) {
        return ParamConnector.getInstance().getDB().returns.getReturnSummary(requestId, predicates);
    }
    static getReturnSummaryInBulk(requestIds, predicates) {
        return ParamConnector.getInstance().getDB().returns.getReturnSummaryInBulk(requestIds, predicates);
    }
    static getAllReceiptsByParent(requestId) {
        return ParamConnector.getInstance().getDB().returns.getAllReceiptsByParent(requestId);
    }
    static getAllReceiptsByParentV1(requestId) {
        return ParamConnector.getInstance().getDB().returns.getAllReceiptsByParentV1(requestId);
    }
    static getRootReceiptId(receiptId) {
        return ParamConnector.getInstance().getDB().returns.getRootReceiptId(receiptId);
    }
    static getReceiptInfoByNode(receiptId) {
        return ParamConnector.getInstance().getDB().returns.getReceiptInfoByNode(receiptId);
    }
    static getReceiptInternalId(receiptId) {
        return ParamConnector.getInstance().getDB().returns.getReceiptInternalId(receiptId);
    }
    static getSummary(requestId, predicates) {
        return Returns.getReturnSummary(requestId, predicates);
    }
    static getItemQuantitySummary(requestId) {
        return ParamConnector.getInstance().getDB().returns.getItemQuantitySummary(requestId);
    }
    static getValidationData(requestId) {
        return ParamConnector.getInstance().getDB().returns.getValidationData(requestId);
    }
    static getParentDocId(requestId) {
        return ParamConnector.getInstance().getDB().returns.getParentDocId(requestId);
    }
    static getAllReceiptsByStep(from, to, step, type, isClosed, options) {
        return ParamConnector.getInstance().getDB().returns.getAllReceiptsByStep(from, to, step, type, isClosed, options);
    }
    static getSellerAndBuyer(equestId) {
        return ParamConnector.getInstance().getDB().returns.getSellerAndBuyer(equestId);
    }
    static getAllReceiptsByTypeForExport(type, step) {
        return ParamConnector.getInstance().getDB().returns.getAllReceiptsByTypeForExport(type, step);
    }
}

export default Returns;