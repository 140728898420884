import React from 'react';
import BaseComponent from "../BaseComponent";
import { Collapse, Row, Col, Input, Form, message } from 'antd';
import * as Utils from '../../../util/utils';
import IconComponent from '../../../components/IconComponent';
import Web3Utils from 'web3-utils';
import ParamConnector from '../../../param-connector';
import Web3_1_0 from '../../../param-network/utils/Web3_1_0';
import './index.less';
import ReactJson from 'react-json-view';
import Analytics from '../../../analytics';

const ParamContract = require('../../../param-network/param-receipt/param-receipt.json');
const ParamInventoryImpl = require('../../../param-network/inventory-manager/inventory-manager.json');
const ParamCatalogueImpl = require('../../../param-network/catalogue-manager/catalogue-manager.json');
const ParamContactImpl = require('../../../param-network/contact-manager/contact-manager.json');
const ParamVendors = require('../../../param-network/vendor-manager/vendor-manager.json');
const TemplateRepositoryImpl = require('../../../param-network/template-repository-manager/template-repository-manager.json');
const TemplateConsensusImpl = require('../../../param-network/template-consensus-manager/template-consensus-manager.json');
const ParamReturns = require('../../../param-network/returns-manager/returns-manager.json');
const VersionControl = require('../../../param-network/version-control/version-control.json');
const NodeInfo = require('../../../param-network/node-info/node-info.json');
const PublicBook = require('../../../param-network/public-book/public-book.json');

const { Panel } = Collapse;
const SmartContracts = {
    ParamContract: {
        abi: ParamContract.abi,
        networkManager: "getReceiptManager",
        contractManagerName: "paramReceiptManagerContract"
    }, ParamInventoryImpl: {
        abi: ParamInventoryImpl.abi,
        networkManager: "getInventoryManager",
        contractManagerName: "inventoryManagerContract"
    }, ParamCatalogueImpl: {
        abi: ParamCatalogueImpl.abi,
        networkManager: "getCatalogueManager",
        contractManagerName: "catalogueManagerContract"
    }, ParamContactImpl: {
        abi: ParamContactImpl.abi,
        networkManager: "getContactManager",
        contractManagerName: "contactManagerContract"
    }, ParamVendors: {
        abi: ParamVendors.abi,
        networkManager: "getVendorManager",
        contractManagerName: "vendorManagerContract"
    }, TemplateRepositoryImpl: {
        abi: TemplateRepositoryImpl.abi,
        networkManager: "getTemplateRepoManager",
        contractManagerName: "templateRepositoryManager"
    }, TemplateConsensusImpl: {
        abi: TemplateConsensusImpl.abi,
        networkManager: "getTemplateConsManager",
        contractManagerName: "templateConsensusManager"
    }, ParamReturns: {
        abi: ParamReturns.abi,
        networkManager: "getReturnsManager",
        contractManagerName: "returnsManager"
    }, VersionControl: {
        abi: VersionControl.abi,
        networkManager: "getVersionControlManager",
        contractManagerName: "versionControlManagerContract"
    }, NodeInfo: {
        abi: NodeInfo.abi,
        networkManager: "getNodeInfoManager",
        contractManagerName: "nodeInfoManagerContract"
    }, PublicBook: {
        abi: PublicBook.abi,
        networkManager: "getPublicBookManager",
        contractManagerName: "publicBookManagerContract"
    }
};

class TestSmartContract extends BaseComponent {

    constructor(props) {
        super(props);
        Analytics.getInstance().trackPageview();
        this.state = {
            showLoader: true,
            output: {}
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchData();
    }

    fetchData = () => {
        let promiseArray = [];
        for (let index in SmartContracts) {
            promiseArray.push(Utils.getLatestContractAddressFromName(index).then(res => {
                if (res) {
                    if (!Utils.getFromLocalStorage(`testingAddress${index}`)) {
                        Utils.setLocalStorage(`testingAddress${index}`, res[0]);
                    }
                    SmartContracts[index].contractAddress = res[0];
                }
            }))
            SmartContracts[index].abi = SmartContracts[index].abi.sort((a, b) => {
                if (a.name && b.name)
                    return a.name.localeCompare(b.name)
                return null
            });
        }
        return Promise.all(promiseArray).then(res => {
            this.setState({ showLoader: false });
        })
    }

    handleSubmit = (event, contractName, functionDetails, functionType) => {
        event.stopPropagation();
        const functionInputs = functionDetails.inputs;
        const repeatExecutionId = `${contractName}_${functionDetails.name}`;
        const repeatExecution = Number(this.state[repeatExecutionId] || 1);
        const inputFields = functionInputs.map((value) => {
            return `${contractName}_${functionDetails.name}_${value.name}`
        })
        this.props.form.validateFieldsAndScroll(inputFields, (err, values) => {
            if (err) {
                message.error("Please fill all required fields");
                return;
            }
            let callArguments = []
            for (let index in values) {
                callArguments.push(values[index]);
            }
            let contractAddress = Utils.getFromLocalStorage(`testingAddress${contractName}`);
            let configObject = Utils.getConfigObject();
            configObject.address = contractAddress;
            let paramNetwork = ParamConnector.getInstance(configObject).getNetwork();
            let contractManager = paramNetwork[SmartContracts[contractName].networkManager]();

            const submitTransaction = (index) => {
                let options = Utils.getNetworkOptions();
                return Web3_1_0[functionType](contractManager, contractManager[SmartContracts[contractName].contractManagerName], functionDetails.name, options, ...callArguments).then(res => {
                    if (index === repeatExecution - 1) {
                        return res;
                    }
                    index++;
                    this.setState({ output: { ...this.state.output, res } })
                    return submitTransaction(index);
                })
            }
            this.setState({ output: {} })
            return submitTransaction(0).then(res => {
                console.log(res);
                this.setState({ output: { ...this.state.output, res } });
            }).catch(err => {
                message.error("Some error occured");
                console.error(err);
                this.setState({ output: { error: err } });
            })
        })
    }

    validateInput = (info, rule, value, callback) => {
        switch (info.type) {
            case "address":
                if (value && !Web3Utils.isAddress(value)) {
                    callback("Please enter valid address");
                }
                else {
                    callback()
                }
                break;
            case "bytes32":
                if (value && (!value.startsWith('0x') || value.length !== 66 || !Web3Utils.isHexStrict(value))) {
                    callback("Please enter valid bytes32 string");
                }
                else {
                    callback();
                }
                break;
            case "uint256":
                if (value && isNaN(value)) {
                    callback("Please enter valid uint256");
                }
                else {
                    callback();
                }
                break;
            case "bytes32[]":
                if (value) {
                    let validBytes32 = true;
                    value = value.split(',')
                    for (let index in value) {
                        value[index] = value[index].trim();
                        if (!value[index].startsWith('0x') || value[index].length !== 66 || !Web3Utils.isHexStrict(value[index])) {
                            validBytes32 = false;
                            break;
                        }
                    }
                    if (!validBytes32) {
                        callback("Please enter valid array of bytes32 (Comma (,) separated values)")
                    }
                    else {
                        callback();
                    }
                }
                else {
                    callback();
                }
                break;
            case "address[]":
                if (value) {
                    let validAddress = true;
                    value = value.split(',')
                    for (let index in value) {
                        value[index] = value[index].trim();
                        if (!Web3Utils.isAddress(value[index])) {
                            validAddress = false;
                            break;
                        }
                    }
                    if (!validAddress) {
                        callback("Please enter valid array of bytes32 (Comma (,) separated values)")
                    }
                    else {
                        callback();
                    }
                }
                else {
                    callback();
                }
                break;
            default:
                callback();
                break;
        }
    }

    handleContractAddressChanged(event, contractName) {
        let { value } = event.target;
        if (value && Web3Utils.isAddress(value)) {
            Utils.setLocalStorage(`testingAddress${contractName}`, value);
            return;
        }
        message.error(`Please enter valid contract address for ${contractName}`)
    }

    getInternalFunctions = (contractName) => {
        const { getFieldDecorator } = this.props.form;
        let contractABI = SmartContracts[contractName].abi;
        let internalFunctions = [];
        for (let obj in contractABI) {
            if (contractABI[obj].type === "function" && contractABI[obj].stateMutability === "view") {
                const functionInputs = contractABI[obj].inputs;
                if (contractABI[obj].name) {
                    const inputBoxes = functionInputs.map((currentValue) => {
                        return <Col span={24}>
                            <Form.Item label={currentValue.name}>
                                {getFieldDecorator(`${contractName}_${contractABI[obj].name}_${currentValue.name}`, {
                                    rules: [{
                                        required: true,
                                        message: `please input valid ${currentValue.name}`
                                    }, {
                                        validator: (rule, value, callback) => this.validateInput(currentValue, rule, value, callback)
                                    }]
                                })
                                    (<Input placeholder={currentValue.type} />)
                                }
                            </Form.Item>
                        </Col>
                    })
                    internalFunctions.push(
                        <Panel header={contractABI[obj].name} extra={<IconComponent hoverText="run" actionIcon="true" type="play-circle" onClick={(e) => this.handleSubmit(e, contractName, contractABI[obj], "call")} />}>
                            <Row gutter={24}>
                                {inputBoxes}
                            </Row>
                        </Panel>
                    )
                }
            }
        }
        return <Collapse>{internalFunctions}</Collapse>;
    }

    getExternalFunctions = (contractName) => {
        const { getFieldDecorator } = this.props.form;
        let contractABI = SmartContracts[contractName].abi;
        let externalFunctions = [];
        for (let obj in contractABI) {
            if (contractABI[obj].type === "function" && contractABI[obj].stateMutability === "nonpayable") {
                const functionInputs = contractABI[obj].inputs;
                if (contractABI[obj].name) {
                    const inputBoxes = functionInputs.map((currentValue) => {
                        return <Col span={24}>
                            <Form.Item label={currentValue.name}>
                                {getFieldDecorator(`${contractName}_${contractABI[obj].name}_${currentValue.name}`, {
                                    rules: [{
                                        required: true,
                                        message: `please input valid ${currentValue.name}`
                                    }, {
                                        validator: (rule, value, callback) => this.validateInput(currentValue, rule, value, callback)
                                    }]
                                })
                                    (<Input placeholder={currentValue.type} />)
                                }
                            </Form.Item>
                        </Col>
                    })
                    externalFunctions.push(
                        <Panel
                            header={contractABI[obj].name}
                            extra={
                                <>
                                    <Input
                                        className="testsmartcontracts-panel-external-repeatExecution"
                                        type="number"
                                        defaultValue={1}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => this.handleRepeatExecutionChange(e, contractName, contractABI[obj])}
                                    />
                                    <IconComponent
                                        hoverText="run"
                                        actionIcon="true"
                                        type="play-circle"
                                        onClick={(e) => this.handleSubmit(e, contractName, contractABI[obj], "send")}
                                    />
                                </>
                            }
                        >
                            <Row gutter={24}>
                                {inputBoxes}
                            </Row>
                        </Panel>
                    )
                }
            }
        }
        return <Collapse>{externalFunctions}</Collapse>;
    }

    handleRepeatExecutionChange = (event, contractName, functionDetails) => {
        event.stopPropagation();
        const repeatExecutionId = `${contractName}_${functionDetails.name}`;
        this.setState({ [repeatExecutionId]: event.target.value });
    }

    getCollapsePane = () => {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 6 },
                lg: { span: 6 },
                xl: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 18 },
                lg: { span: 18 },
                xl: { span: 18 }
            },
        };
        let renderNode = [], nodeKey = 0;
        for (let index in SmartContracts) {
            let header = <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ lineHeight: 3 }}>{index}</Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}
                    onClick={event => {
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation();
                    }}>
                    <Input
                        // addonBefore="Contract Address"
                        defaultValue={Utils.getFromLocalStorage(`testingAddress${index}`)}
                        onBlur={(e) => this.handleContractAddressChanged(e, index)}
                    />
                </Col>
            </Row>
            renderNode.push(
                <Panel header={header} key={nodeKey++}>
                    <Collapse>
                        <Panel header="Internal Functions" className="testsmartcontracts-panel">
                            <Form {...formItemLayout} >
                                {this.getInternalFunctions(index)}
                            </Form>
                        </Panel>
                        <Panel header="External Functions" className="testsmartcontracts-panel">
                            <Form {...formItemLayout} >
                                {this.getExternalFunctions(index)}
                            </Form>
                        </Panel>
                    </Collapse>
                </Panel>
            )
        }
        return <Collapse className="testsmartcontracts-collapse">{renderNode}</Collapse>
    }

    renderMainContent() {
        return (
            <div>
                <Row className="testsmartcontracts" gutter={24}>
                    <Col span={15}>
                        <h1>Test Smart Contracts</h1>
                        {this.getCollapsePane()}
                    </Col>
                    <Col span={9} className="testsmartcontracts-output">
                        <ReactJson src={this.state.output} />
                    </Col>
                </Row>
            </div>
        );
    }
}
const TestSmartContractForm = Form.create()(TestSmartContract)
export default TestSmartContractForm;