import { gql } from '@apollo/client';
import NetworkUtils from '../../../util/network-bridge/network-utils';
import * as Utils from '../../../util/utils';
class Templates {

    constructor(apolloClient) {
        this.apolloClientConnector = apolloClient;
    }

    getAllTemplates(owner, startIndex = 0, pageSize = 10, totalRecords = []) {
        let query = gql`
            query getAllTemplates($filterOptions: InputFilterOptions!){
                getAllTemplates(filterOptions:$filterOptions){
                    totalRecords,
                    IDs
                  }
            }`
        return this.apolloClientConnector.query({ query, variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner } } }).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllTemplates['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllTemplates['totalRecords']) {
                return totalRecords
            }
            return this.getAllTemplates(owner, startIndex + 1, pageSize, totalRecords);
        })
    }

    getTemplate = (templateId) => {
        let queryOptions = {
            query: gql`{
                getTemplate(templateID:"${templateId}"){
                    owner,
                    TemplateID,
                    name,
                    title,
                    stepIds,
                    metaData,
                    isPublic
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let templateResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return templateResult;
            }
            templateResult["@id"] = graphQLResult.data["getTemplate"].TemplateID
            templateResult["owner"] = graphQLResult.data["getTemplate"].owner
            templateResult["name"] = graphQLResult.data["getTemplate"].name
            templateResult["metaData"] = graphQLResult.data["getTemplate"].metaData
            templateResult["stepIds"] = graphQLResult.data["getTemplate"].stepIds
            // templateResult["stepInfos"] = stepInfoArr
            return templateResult;
        })
    }

    getStepById = (stepId, owner) => {
        let queryOptions = {
            query: gql`{
                getStepById(stepID:"${stepId}"){
                    createdBy,
                    StepID,
                    stepJson,
                    metaData
                  }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let stepResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return stepResult;
            }
            // stepResult = JSON.parse(graphQLResult.data["getStepById"].stepJSON)
            stepResult["owner"] = graphQLResult.data["getStepById"].createdBy
            stepResult["metaData"] = graphQLResult.data["getStepById"].metaData
            stepResult["stepId"] = graphQLResult.data["getStepById"].StepID
            stepResult["stepJson"] = graphQLResult.data["getStepById"].stepJson //?
            return stepResult;
        });
    }

    getAllSteps = (owner, startIndex = 0, pageSize = 10) => {
        let queryOptions = {
            query: gql`{
                getAllSteps(filterOptions:{dbSource:OFF_CHAIN,startIndex:${startIndex},pageSize:${pageSize},owner:${owner}}){
                    totalRecords,
                    currentPageSize
                    IDs
                  }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let allStepResult = {
                count: 0,
                steps: []
            }
            if (!graphQLResult || !graphQLResult.data) {
                return allStepResult;
            }
            allStepResult["count"] = graphQLResult.data["getAllSteps"].totalRecords
            allStepResult["steps"] = graphQLResult.data["getAllSteps"].stepIDs
            return allStepResult;
        });
    }

    getSummary(templateId) {
        // return {
        //     "_id": "",
        //     "title": "",
        //     "description": "",
        //     "templateId": "",
        //     "stepsCount": 4,
        //     "templateConsensusCount": 0
        // }
        let queryOptions = {
            query: gql`{
                getTemplate(templateID:${templateId}){                  
                    title,
                    stepIds,
                    metaData,
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let templateSummaryResult;
            if (!graphQLResult || !graphQLResult.data) {
                return templateSummaryResult;
            }
            templateSummaryResult["title"] = graphQLResult.data["getTemplate"].title
            templateSummaryResult["description"] = JSON.parse(graphQLResult.data["getTemplate"].metaData).description
            templateSummaryResult["templateId"] = templateId
            templateSummaryResult["stepsCount"] = graphQLResult.data["getTemplate"].stepIds.length
            templateSummaryResult["templateConsensusCount"] = 0
            return templateSummaryResult;
        })
    }

    createStep(stepJson, metaData) {
        stepJson = JSON.parse(stepJson)
        delete Object.assign(stepJson, { "type": stepJson["@type"] })["@type"];
        let options;
        options = {
            mutation: gql`
            mutation createStep( $inputObject: InputWorkFlowStepInfo! $workFlowTxnMode: TRANSACTION_TYPE! $metaData: String ){
                createStep(
                    inputObject:$inputObject,
                    metaData:$metaData,
                    workFlowTxnMode:$workFlowTxnMode,
                    )
                }`,
            variables: { inputObject: { workFlowRepo: stepJson }, metaData, workFlowTxnMode: "PUBLIC" }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createStep
        })
    }

    createStepV1(stepId, stepJson, metaData) {
        // stepJson = JSON.parse(stepJson)
        // delete Object.assign(stepJson, { ["type"]: stepJson["@type"] })["@type"];
        let options;
        options = {
            mutation: gql`
            mutation createStepV1($stepId:String!, $inputObject: InputWorkFlowStepInfo! $workFlowTxnMode: TRANSACTION_TYPE! $metaData: String ){
                createStepV1(
                    stepID:$stepId
                    inputObject:$inputObject,
                    metaData:$metaData,
                    workFlowTxnMode:$workFlowTxnMode,
                    )
                }`,
            variables: { stepId, inputObject: { data: stepJson }, metaData, workFlowTxnMode: "PUBLIC" }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createStepV1
        })
    }

    createTemplate(name, title, stepIds, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation createTemplate($name : String!, $title: String!, $stepIds: [String!]!, $metaData: String) {
                createTemplate(
                    name: $name,
                    title: $title,
                    stepIds: $stepIds
                    metaData: $metaData
                  )
            }`,
            variables: { name, title, stepIds, metaData }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createTemplate
        })
    }

    editTemplate(templateId, name, title, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation {
                editTemplate(
                    templateID:${templateId},
                    name:${name},
                    title:"${title}",
                    metaData:${metaData}
                )
            }`,
        }
        return this.apolloClientConnector.mutate(options);
    }

    editStep(stepId, stepJson, metaData) {
        // stepJson = JSON.parse(stepJson)
        let options;
        options = {
            mutation: gql`
            mutation {
                editStep(
                    stepID:${stepId},
                    inputObject:{workFlowRepo:${stepJson}},
                    metaData:${metaData}
                    workFlowTxnMode:PUBLIC,
                    )
                }`,
        }
        return this.apolloClientConnector.mutate(options);
    }

    addStepsToTemplate(templateId, stepIds) {
        let options;
        options = {
            mutation: gql`
            mutation {
                addStepsToTemplate(
                    templateID:${templateId},
                    stepIDs:${stepIds}
                )
            }`,
        }
        return this.apolloClientConnector.mutate(options);
    }

    updateStepsToTemplate(templateId, stepIds) {
        let options;
        options = {
            mutation: gql`
            mutation {
                updateStepsToTemplate(
                    templateID:${templateId},
                    stepIDs:${stepIds}
                )
            }`,
        }
        return this.apolloClientConnector.mutate(options);
    }

    updateTemplate(templateId, jsonLd, owner, name, stepIds, title, metaData, templateStepsInfo) {
        let options;
        options = {
            mutation: gql`
            mutation {
                editTemplate(
                    templateID:${templateId},
                    name:${name},
                    title:${title},
                    metaData:${metaData}
                  )
            }`,
        }
        return this.apolloClientConnector.mutate(options);
    }

    shareTemplate(templateId, subscribers) {
        let options;
        options = {
            mutation: gql`
            mutation shareTemplate($templateId: String!,$subscribers: [String!]!) {
                shareTemplate(templateID:$templateId, subscribers:$subscribers)
            }`,
            variables: { templateId, subscribers }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.shareTemplate
        })
    }
}
export default Templates;