import BigNumber from "bignumber.js";
import ParamUtils from './index'
import Events from './events';
import Web3Utils from 'web3-utils';
import ProxyUtils from './proxy-utils';
class Web3_1_0 {

    static getWeb3(config) {
        if (!config) {
            return undefined;
        }
        if (config.url.startsWith("http")) {
            return require('web3.0.20.0');
        }
        return require("web31.2.1");
    }

    static getProvider(config) {
        let Web3 = Web3_1_0.getWeb3(config);
        if (!config.url.startsWith("http")) {
            return new Web3.providers.WebsocketProvider(config.url);
        }
        if (!ProxyUtils.isProxySet()) {
            return new Web3.providers.HttpProvider(config.url);
        }
        let HttpHeaderProvider = require('httpheaderprovider');
        let headers = ProxyUtils.getAxiosHeaders(null, config.url);
        if (config.enableCors) {
            if (!headers) {
                headers = {}
            }
            headers["Access-Control-Allow-Origin"] = "*"
        }
        if (headers) {
            return new HttpHeaderProvider(ProxyUtils.getProxyBaseURL(config.url), headers);
        }
        return new Web3.providers.HttpProvider(config.url);
    }
    // static getProxyAuth(proxy, url) {
    //     if (proxy && proxy.userName) {
    //         let basicAuth = `${proxy.userName}:${proxy.password?proxy.password:""}`;
    //         let headers = {}
    //         headers["host-url"] = url;
    //         headers["proxyauthorization"] = `Basic ${btoa(basicAuth)}`
    //         return headers;
    //     }
    //     return null;
    // }

    static isListening() {
        // return 
    }

    static getNodeInfo(connection) {

    }

    static estimateGas(ctx, contract, functionName, options, callback, ...args) {
        if (contract.version === 0.2) {
            args.push(options)
            args.push(callback);
            return contract[functionName]["estimateGas"].apply(ctx, args);
        }
        let estimateGasFunc = contract["methods"][functionName].apply(ctx, args);
        let estimateGasArgs = [options, callback]
        return estimateGasFunc["estimateGas"].apply(ctx, estimateGasArgs)
    }

    static getEncodedData(ctx, contract, functionName, ...args) {
        if (contract.version === 0.2) {
            return contract[functionName]["getData"].apply(ctx, args);
        }
        return contract.methods[functionName].apply(ctx, args).encodeABI();
    }

    static getFunction(ctx, contract, functionName, callback, ...args) {
        if (contract.version === 0.2) {
            args.push(callback);
            return contract[functionName].apply(ctx, args)
        }
        let func = contract.methods[functionName].apply(ctx, args)
        return func.call(callback);
    }

    static call(ctx, contract, functionName, options, ...args) {
        return new Promise((resolve, reject) => {
            let callback = function (error, callResult) {
                if (error) {
                    return reject(error);
                }
                let outputResult;
                for (let abiIndex in contract.abi) {
                    if (contract.abi[abiIndex].name === functionName) {
                        outputResult = contract.abi[abiIndex].outputs
                        break;
                    }
                }
                //Converting to Web3 V1.0 format.
                if (outputResult && callResult && Array.isArray(callResult)) {

                    let key;
                    for (let outPutResultIndex in outputResult) {
                        if (Web3Utils.isAddress(callResult[outPutResultIndex])) {
                            callResult[outPutResultIndex] = Web3Utils.toChecksumAddress(callResult[outPutResultIndex])
                        }

                        if (Web3Utils.isBN(callResult[outPutResultIndex])
                            || Web3Utils.isBigNumber(callResult[outPutResultIndex])) {
                            callResult[outPutResultIndex] = callResult[outPutResultIndex].toString();
                        }

                        if (outputResult[outPutResultIndex] && outputResult[outPutResultIndex].name !== "") {
                            key = outputResult[outPutResultIndex].name
                            callResult[key] = callResult[outPutResultIndex]
                        }
                    }
                }
                resolve(callResult)
            }
            Web3_1_0.getFunction(ctx, contract, functionName, callback, ...args)
        });
    }

    static getEvent(ctx, contract, functionName, filter) {
        let args = [];
        if (filter) {
            args.push(filter.filter);
        }
        if (contract.version === 0.2) {

            let event = new Events();
            let callback = function (error, eventData) {
                if (error) {
                    event.call("error", error)
                    return;
                }
                if (eventData) {
                    if (Array.isArray(eventData) && eventData.length) {
                        eventData = eventData[0];
                    }
                }
                event.call("data", eventData)
            }
            args.push(callback);
            contract[functionName].apply(ctx, args)
            return event;
        }
        return contract.events[functionName].apply(ctx, args);
    }

    static send(ctx, contract, functionName, options, ...args) {
        return new Promise((resolve, reject) => {
            let callback = function (error, _gas) {
                if (error) {
                    return reject(error);
                }
                let gasMultiplyer = 1.3
                if (options.gasValue) {
                    gasMultiplyer = options.gasValue;
                    delete options.gasValue;
                }
                _gas = parseInt(_gas * gasMultiplyer);
                options.gas = _gas;
                options.to = ctx.to;

                if (options.privateKey) {
                    let txData = Web3_1_0.getEncodedData(ctx, contract, functionName, ...args)
                    ParamUtils.submitTransaction(ctx.connection, txData, options).then((data) => {
                        if (contract.version === 0.2) {
                            data = data.result;
                        }
                        resolve(data)
                    }).catch(error => {
                        reject(error)
                    })
                    return;
                }
                reject(new Error("Private key is mandatory"))
            }
            Web3_1_0.estimateGas(ctx, contract, functionName, options, callback, ...args);
        })
    }

    static getContract(connection, abi, address) {
        let contract;
        if (connection.eth.Contract) {
            contract = new connection.eth.Contract(abi, address);
            contract.version = 1;
        } else {
            contract = connection.eth.contract(abi);
            contract = contract.at(address);
            contract.version = 0.2;
        }
        contract.to = address;
        return contract;
    }

    static getPastEvents(ctx, contract, eventName, options, ...args) {
        if (contract.version === 0.2) {
            return new Promise((resolve, reject) => {
                let filter = {}
                let blockFilter = { fromBlock: 0, toBlock: 'latest' }

                if (options && options.filter) {
                    filter = options.filter
                }

                if (options.fromBlock) {
                    blockFilter.fromBlock = options.fromBlock;
                }
                if (options.toBlock) {
                    blockFilter.toBlock = options.toBlock;
                }

                contract[eventName].apply(ctx, filter, blockFilter).get((error, eventResult) => {
                    if (error)
                        return reject(error)
                    resolve(eventResult);
                });
            })
        }
        return contract.getPastEvents(eventName, options);
    }

    static upgradeEventsData(promiseArray, connection, canFetchOwner) {
        return Promise.all(promiseArray).then(transactions => {
            transactions = [].concat.apply([], transactions);
            for (let index in transactions) {
                transactions[index] = Web3_1_0.upgradeEventData(transactions[index], connection, canFetchOwner)
            }
            return Promise.all(transactions);
        }).then(transactions => {
            return [].concat.apply([], transactions);
        });
    }

    static upgradeEventData(data, connection, canFetchOwner) {

        // if (connection) {
        //     if (!connection.eventMapping) {
        //         connection.eventMapping = new Set();
        //     }
        //     if (connection.eventMapping.has(data.id)) {
        //         console.log("Multiple events", data.id)
        //         return Promise.reject("Event already triggered");
        //     }
        //     connection.eventMapping.add(data.id, true);
        // }

        if (!data) {
            return data;
        }
        // if (data.args) {
        //     return data;
        // }
        if (data.returnValues) {
            data["args"] = data.returnValues;
            data.returnValues = undefined;
        }


        for (let index in data["args"]) {
            if (!Array.isArray(data["args"][index]) && Web3Utils.isAddress(data["args"][index])) {
                data["args"][index] = Web3Utils.toChecksumAddress(data["args"][index])
            }
        }

        if (!connection) {
            return data;
        }

        if (canFetchOwner) {
            Web3_1_0.getTransactionDetails(connection, data["transactionHash"]).then(tData => {
                data["from"] = tData['from'];
                data["contractAddress"] = tData['to'];
            })
        }

        return new Promise((resolve, reject) => {
            let blockHash = data.blockNumber;
            // blockHash = new BigNumber(blockHash);
            connection.eth.getBlock(blockHash, (error, blockInfo) => {
                data["blockInfo"] = blockInfo;
                data["dateAndTime"] = blockInfo && (blockInfo.timestamp).toString().substr(0, 13);
                data["canReSyncReq"] = blockInfo ? false : true;
                resolve(data);
            })
        });
    }

    static getTransactionDetails(connection, txHash) {
        return new Promise((resolve, reject) => {
            connection.eth.getTransactionReceipt(txHash, (error, txInfo) => {
                if (error) {
                    return resolve(null)
                }
                return resolve(txInfo)
            });
        });
    }

    static getTransactionDetails(connection, txHash) {
        return new Promise((resolve, reject) => {
            connection.eth.getTransactionReceipt(txHash, (error, txInfo) => {
                if (error) {
                    return resolve(null)
                }
                return resolve(txInfo)
            });
        });
    }
}
export default Web3_1_0;
