import React from 'react';
import NetworkBridge from '../../../util/network-bridge';
import * as Utils from '../../../util/utils';
import { Modal, Radio, Tabs, message } from 'antd';
import Console from '../../../logger';
import LinkPurchaseCard from '../../components/LinkPurchaseCard';
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import FormatData from '../../../database/nosql/Utils/formatData';
const { TabPane } = Tabs;

class SCM extends BaseComponent {

    constructor(props) {
        super(props);
        this.initStates();
    }

    initStates() {
        this.itemManager = NetworkBridge.getItemManager();
        this.receiptManager = NetworkBridge.getReceiptManager();
        this.receiptDetails = {};
        this.state = {
            isLinkPurchaseVisible: false,
            tabInfos: [],
            receipts: {},
            value: null,
            linkedReceipts: {},
            selectedTab: "",
            currentItemId: "",
            selectedPaneRadio: {},
            showInternalLoader: false,
        }
    }

    showLinkPurchaseModal = (item) => {
        let itemId = item.internalId;
        const prevLinkedReceipts = item.linkedReceipts;
        let selectedPaneRadio = {}, selectedTab;
        for (let index in prevLinkedReceipts) {
            selectedTab = prevLinkedReceipts[index].material.materialId;
            selectedPaneRadio[prevLinkedReceipts[index].material.materialId] = prevLinkedReceipts[index].receipt.receiptId;
        }
        this.setState({
            currentItemId: itemId,
            showInternalLoader: true,
            prevData: item.linkedReceipts,
            itemLinkingInfo: {},
            selectedPaneRadio,
            selectedTab
        });
        this.getRawMaterials(itemId, prevLinkedReceipts);
    }

    getRawMaterials = (itemId, prevLinkedReceipts) => {
        this.itemManager = NetworkBridge.getItemManager();
        return this.itemManager.getItemMaterial(itemId).then(itemMaterials => {
            let { itemLinkingInfo } = this.state;
            let tabInfos = [], linkedReceipts = {};
            let promiseArray = [];
            if (!itemMaterials || itemMaterials.length === 0) {
                this.setState({
                    showLoader: false,
                    showInternalLoader: false
                })
                message.error('No invoices to link', 1);
                this.props.stopInnerSpinner();
                return;
            }


            for (let index = 0; index < itemMaterials.length; index++) {
                const materialName = itemMaterials[index].name;
                const materialId = itemMaterials[index]["id"];
                const materialInfo = {
                    materialId,
                    materialName
                }
                tabInfos.push(materialInfo);
                itemLinkingInfo[materialId] = materialInfo;
                itemLinkingInfo[materialId].receipts = [];

                let receiptsPromise = this.getReceiptsByItem(materialId).then((receipts) => {
                    for (let item in receipts) {
                        itemLinkingInfo[materialId].receipts.push(receipts[item].receiptId);
                        this.receiptDetails[receipts[item].receiptId] = receipts[item];
                    }
                    return { receipts: receipts, "id": materialId };
                }).catch((e) => {
                    Console.error(e)
                    return { receipts: [], "id": materialId };
                })
                promiseArray.push(receiptsPromise);
            }
            Promise.all(promiseArray).then((receipts) => {
                for (let index in prevLinkedReceipts) {
                    linkedReceipts[prevLinkedReceipts[index].material.materialId] = prevLinkedReceipts[index].receipt;
                }
                this.setState({ itemLinkingInfo, linkedReceipts });
                this.showLinkPurchaseTabs(tabInfos, receipts)
            })
        })
    }

    getReceiptsByItem = (itemId) => {
        itemId = FormatData.removeAngular(itemId);
        let mapPromiseArray = [];
        return this.itemManager.getAllMappedItemByItemId(itemId).then(mappedItemIds => {
            console.log("mappedItemIds", mappedItemIds)
            mappedItemIds.push(itemId);
            for (let mappedItem in mappedItemIds) {
                mapPromiseArray.push(this.receiptManager.getReceiptByItem(mappedItemIds[mappedItem], "2"))
            }
            return Promise.all(mapPromiseArray);
        }).then(receiptIds => {
            console.log("receiptIds before reduce", receiptIds);
            receiptIds = receiptIds.reduce((result, currentValue) => {
                if (currentValue && currentValue.receipts) {
                    result.push(currentValue.receipts);
                }
                return result;
            }, [])
            receiptIds = [].concat.apply([], receiptIds);
            let promiseArray = [];
            console.log("receiptIds", receiptIds);
            // return [];
            const requiredKeys = [
                'receiptId',
                'docInternalId',
                'customerName',
                'customerParamId',
                'providerName',
                'providerParamId',
                'date',
                'status',
                'transactionType',
                'amount'
            ]
            for (let index in receiptIds) {
                if (receiptIds[index]) {
                    let rId = FormatData.removeAngular(receiptIds[index])
                    promiseArray.push(this.receiptManager.getSummary(rId, requiredKeys));
                }
            }
            return Promise.all(promiseArray);
        })
    }


    showLinkPurchaseTabs = (tabInfos, receipts) => {
        this.props.stopInnerSpinner();
        if (receipts.length === 0) {
            this.setState({
                showInternalLoader: false,
            })
            message.error('No invoices to link', 1);
        }
        else {
            this.setState({
                isLinkPurchaseVisible: true,
                tabInfos: tabInfos,
                receipts: receipts,
                showInternalLoader: false,
                selectedTab: tabInfos[0].materialId
            })
        }
    }

    checkReceiptType = (e) => {
        let receiptId = e.target.value;
        let transactionType = this.receiptDetails[receiptId].transactionType;

        if (transactionType === "3") {
            this.setState({ confirmDialouge: true, selectedReceipt: e.target.value });
        }
        else {
            this.onRadioChange(e.target.value);
        }
    }


    renderReceipts = (rawMaterial) => {
        let customReceipt = [];
        const { itemLinkingInfo } = this.state;
        if (itemLinkingInfo[rawMaterial.materialId]) {
            const rawMaterialReceipts = itemLinkingInfo[rawMaterial.materialId].receipts;
            for (let index = 0; index < rawMaterialReceipts.length; index++) {
                const receiptInfo = this.receiptDetails[rawMaterialReceipts[index]];
                customReceipt.push(
                    <LinkPurchaseCard style={{ display: "inlineBlock" }}
                        receiptId={receiptInfo.receiptId}
                        // price={receiptInfo.totalPaymentDue.price}
                        // , receiptInfo.totalPaymentDue.currency
                        price={Utils.getCurrency(receiptInfo.amount)}
                        time={moment.unix(receiptInfo.date / 1000).format("YYYY-MM-DD")}
                        radioValue={receiptInfo.receiptId}
                        type={Utils.getTxnType(receiptInfo.transactionType)}
                        internalId={receiptInfo.docInternalId}
                    />
                )

            }
        }
        return customReceipt;
    }

    onTabChange = (key) => {
        this.setState({
            selectedTab: key
        })
    }

    renderTabs = () => {
        let tabInfos = this.state.tabInfos;
        let tabPanes = [];
        for (let tabIndex = 0; tabIndex < tabInfos.length; tabIndex++) {
            let tabInfo = tabInfos[tabIndex]
            tabPanes.push(
                <TabPane tab={tabInfo.materialName} key={tabInfo.materialId} style={{ backgroundColor: "#f8f9fd", padding: "0 1rem", overflowY: 'auto', overflowX: "hidden", height: "28vw" }}>
                    <Radio.Group onChange={this.checkReceiptType} style={{ width: "100%" }} value={this.state.selectedPaneRadio[this.state.selectedTab]} >
                        {this.renderReceipts(tabInfos[tabIndex])}
                    </Radio.Group>
                </TabPane>
            );
        }
        let tabs = <Tabs onChange={this.onTabChange}>
            {tabPanes}
        </Tabs>;
        return tabs;
    }

    handleLinkPurchase = () => {
        let { currentItemId, linkedReceipts, itemLinkingInfo } = this.state;
        let returnResult = [];
        for (let index in linkedReceipts) {
            const receiptInfo = linkedReceipts[index];
            returnResult.push({
                material: {
                    materialId: index,
                    name: itemLinkingInfo[index].materialName
                },
                receipt: {
                    receiptId: receiptInfo.receiptId,
                    receiptInternalId: receiptInfo.docInternalId,
                    customer: {
                        name: receiptInfo.customerName,
                        identifier: receiptInfo.customerParamId
                    },
                    provider: {
                        name: receiptInfo.providerName,
                        identifier: receiptInfo.providerParamId
                    },
                    // amount: receiptInfo.amount,
                    date: receiptInfo.date,
                    status: receiptInfo.status
                }
            })
        }
        this.receiptDetails = {};
        this.setState({
            isLinkPurchaseVisible: false,
            tabInfos: [],
            receipts: {},
            currentItemId: "",
            itemLinkingInfo: {},
            linkedReceipts: {}
        }, () => {
            this.props.handleLinkPurchase(returnResult, currentItemId);
        })
    }

    onRadioChange = (receiptId) => {
        const { selectedTab, linkedReceipts, selectedPaneRadio } = this.state;
        this.setState({
            linkedReceipts: { ...linkedReceipts, [selectedTab]: this.receiptDetails[receiptId] },
            selectedPaneRadio: { ...selectedPaneRadio, [selectedTab]: receiptId }
        })

    }

    render() {
        return (
            <Modal
                title={<h2>Link Purchases</h2>}
                visible={this.state.isLinkPurchaseVisible}
                onOk={this.handleLinkPurchase}
                onCancel={() => this.setState({ isLinkPurchaseVisible: false })}
                style={{ width: '600px' }}
            >
                {this.renderTabs()}
            </Modal>
        )
    }
}

export default SCM;