class Consensus {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getCreatedConsensusForTemplate = (owner) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getCreatedConsensusForTemplate", owner)
    }

    getTemplateIdByConsId = (templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateIdByConsId", templateConsId)
    }

    getTemplateSummaryByConsId = (templateByConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateSummaryByConsId", templateByConsId)
    }

    getAllSubscribedTemplateConsensus = (paramId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getAllSubscribedTemplateConsensus", paramId)
    }

    getTemplateConsensus = (templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateConsensus", templateConsId)
    }

    getTemplateConsensusByDocId = (docId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateConsensusByDocId", docId)
    }

    getTemplatesByReceiptId = (receiptId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplatesByReceiptId", receiptId)
    }

    getTransactions = (templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTransactions", templateConsId)
    }

    getStepSubscribers = (stepId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getStepSubscribers", stepId)
    }

    getAllTemplateConsIdsByFilter = (templateId, state, mergeId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getAllTemplateConsIdsByFilter", templateId, state, mergeId)
    }

    getAllTemplateConsIdsByState = (templateId, state) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getAllTemplateConsIdsByState", templateId, state)
    }

    getAllTemplateConsIds = (templateId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getAllTemplateConsIds", templateId)
    }

    getTemplateConsensusDetails = (templateConsensusId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateConsensusDetails", templateConsensusId)
    }

    getTemplateConsensusSummary = (templateConsensusId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateConsensusSummary", templateConsensusId)
    }

    getTemplateConsensusDetailsV1 = (templateConsensusId, blockKeys) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateConsensusDetailsV1", templateConsensusId, blockKeys)
    }

    getAllStepConsByTemplateConsId = (templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getAllStepConsByTemplateConsId", templateConsId)
    }

    getStepsConsById = (templateConsId, stepConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getStepsConsById", templateConsId, stepConsId)
    }

    // getAllTemplatesBySubscriber = (paramId) => {
    //     //Return all template id's where paramId is subscriber and check template is installed then Remove duplicates [...new Set()]
    //     return this.g.V("<Consensus>").Out("<stepConsensus>").Has("<subscribers>", paramId).Out("<templateConsId>").Out("<templateId>").GetLimit(-1).then(response => {
    //         response = response.result;
    //         let templateIds = [];
    //         for (let index in response) {
    //             let templateId = FormatData.removeAngular(response[index].id);
    //             templateIds.push(templateId);
    //         }
    //         return {
    //             templates: [...new Set(templateIds)],
    //             count: templateIds.length
    //         };
    //     }).catch(error => {
    //         Console.log(error);
    //         throw new Error(error);
    //     });
    // }

    getAllTemplateConsIdsByMergeId = (mergeId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getAllTemplateConsIdsByMergeId", mergeId)
    }

    getMergeIdByTemplateConsId = (templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getMergeIdByTemplateConsId", templateConsId)
    }

    getTemplateConsensusFullData = (docId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateConsensusFullData", docId)
    }

    doesExist = (id) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "doesExist", id)
    }

    doesTemplateConsExists = (id) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "doesTemplateConsExists", id)
    }

    doesStepConsExists = (templateConsId, stepConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "doesStepConsExists", templateConsId, stepConsId)
    }

    isTemplateCompleted = (templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "isTemplateCompleted", templateConsId)
    }

    getTemplateMetaDataByReceiptid = (receiptId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getTemplateMetaDataByReceiptid", receiptId)
    }

    getAttachedTemplateDetailByReceiptId = (receiptId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getAttachedTemplateDetailByReceiptId", receiptId)
    }

    getConsensusCountByTemplateId = (templateId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "getConsensusCountByTemplateId", templateId)
    }

    isStepCompleted = (templateConsId, stepConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "isStepCompleted", templateConsId, stepConsId)
    }

    // assignStep = (stepId, subscriber) => {
    //     let jsonLd = {
    //         "@id": stepId,
    //     }
    //     jsonLd["subscribers"] = subscriber;
    //     return this.graphDB.core.writer.write(jsonLd).catch(error => {
    //         Console.error(`Unable to assign step for ${stepId}, Reason: ${error.toString()}`);
    //     });
    // }

    // attachSubscriberForDoc = (docId, subscriber, stepKey) => {
    //     let jsonLd = {};
    //     let promise;
    //     let promiseArray = [];
    //     jsonLd["@id"] = docId;
    //     jsonLd["subscriber"] = subscriber;
    //     jsonLd["stepKey"] = stepKey;
    //     promise = this.graphDB.core.writer.write(jsonLd);
    //     promiseArray.push(promise);
    //     return Promise.all(promiseArray);
    // }

    writeStatus = (templateConsensusId, currentStep) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "writeStatus", templateConsensusId, currentStep)
    }

    writeDocStep = (templateConsensusId, docStep) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "writeDocStep", templateConsensusId, docStep)
    }

    addMergeId = (mergeId, templateConsIds) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "addMergeId", mergeId, templateConsIds)
    }

    addStepConsensus = (stepConsId, owner, stepConsensusJSONLd, metaData, primaryOwner, templateConsId, secondaryOwner, transactionType, subscribers) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "addStepConsensus", stepConsId, owner, stepConsensusJSONLd, metaData, primaryOwner, templateConsId, secondaryOwner, transactionType, subscribers)
    }

    addStepConsensusSubscriber = (templateConsId, stepConsId, subscriber, stepKey) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "addStepConsensusSubscriber", templateConsId, stepConsId, subscriber, stepKey)
    }

    addStepConsensusSubscriberOnAssign = (templateConsId, stepConsId, subscriber, secondaryOwner) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "addStepConsensusSubscriberOnAssign", templateConsId, stepConsId, subscriber, secondaryOwner)
    }

    addTemplateConsensus = (templateConsId, owner, docId, docContractAddress, templateId, currentStep, stepConsIds, isTemplateCompleted = false, transactionType, mergeId, docStep) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "addTemplateConsensus", templateConsId, owner, docId, docContractAddress, templateId, currentStep, stepConsIds, isTemplateCompleted, transactionType, mergeId, docStep)
    }

    // addTransactions(templateConsId, owner, metaData) {
    //     try {
    //         if (typeof metaData === "string") {
    //             metaData = JSON.parse(metaData);
    //         }
    //     } catch (e) {
    //         metaData = {};
    //     }
    //     metaData.sender = owner;
    //     let jsonLd = {
    //         "@id": `${templateConsId}`,
    //         "transactions": metaData
    //     }
    //     return this.graphDB.core.writer.write(jsonLd);
    // }

    updateTemplateConsensusCurrentStep = (templateConsensusId, newStep) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "updateTemplateConsensusCurrentStep", templateConsensusId, newStep)
    }

    updateTemplateConsensusStatus = (templateConsId, status) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "updateTemplateConsensusStatus", templateConsId, status)
    }

    updateStepConsensus = (stepConsId, stepConsensusJSONLd, metaData, secondaryOwner, subscribers, transactionType, owner, templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "updateStepConsensus", stepConsId, stepConsensusJSONLd, metaData, secondaryOwner, subscribers, transactionType, owner, templateConsId)
    }

    updateStepConsensusStatus = (templateConsId, stepConsId, status) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "updateStepConsensusStatus", templateConsId, stepConsId, status)
    }

    resetTemplateConsensus = (templateConsId) => {
        return this.mongoDBConnector.invoke('templateConsensusDB', "resetTemplateConsensus", templateConsId)
    }

}
export default Consensus;