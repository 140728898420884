import axios from 'axios';
import * as Utils from '../../../util/utils';
import cryptoUtils from '../utils/crypto-utils';
import Auth from './auth';
import JSONValidator from "./validators/json-validator";
import ThirdPartyUtils from '../utils/IIIrd-party-utils';
import EInvoiceErrors from '../../errors';
import { AxiosError } from 'axios'
import { AUTH_URL, GEN_IRN, GET_IRN, CAN_IRN } from './constants.js';
import ProxyUtils from '../../../param-network/utils/proxy-utils';

const cryptoRandomString = require('crypto-random-string');

const AES_KEY_LEN = 32;
const GSTN_BASE_URL = "https://param.network/gstin/gstin4.json";
const GSTN_BASE_URL2 = "https://param.network/gstin/gstin5.json";


class eInvoice {
    static getHTTPClient() {
        // let proxyObject = ProxyUtils.getProxyObject();
        let instance;

        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        let IRN_BASE_URL = irpConfig.baseURL;
        IRN_BASE_URL = ProxyUtils.getProxyBaseURL(IRN_BASE_URL);
        // if(proxyObject) {
        //     instance = axios.create({
        //         baseURL: ProxyUtils.getProxyBaseURL(baseURL),
        //         proxy: {
        //             host: proxyObject.host,
        //             port: proxyObject.port,
        //             auth: {
        //                 username: proxyObject.userName,
        //                 password: proxyObject.password
        //             }
        //         }
        //     })
        // }
        // else {
        instance = axios.create({
            baseURL: IRN_BASE_URL,
        })
        // }
        instance.interceptors.response.use(function (response) {
            response = response.data;
            return (response.Status == "1") ? response : Promise.reject({
                isInternalError: false,
                ErrorDetails: response.ErrorDetails,
                "Info": response.Info
            });
        }, function (error) {
            let errorObject = {
                isInternalError: true,
                ErrorDetails: []
            }
            if (!error.response || !error.response.status) {
                // if (!navigator.onLine) {
                errorObject.ErrorDetails.push(EInvoiceErrors.NO_NETWORK_ERROR)
                // }
            } else {
                errorObject.ErrorDetails.push({
                    ErrorCode: error.response.statusText,
                    ErrorMessage: error.message
                })
            }
            return Promise.reject(errorObject);
        });
        return instance;
    }

    static authenticateUser() {
        let appKey = cryptoRandomString({ length: AES_KEY_LEN, type: 'hex' });
        console.log("appKey: ", appKey)
        let encryptedAppKey = cryptoUtils.getRSAEncrypted(appKey);
        let encryptedPassword = cryptoUtils.getRSAEncrypted(Auth.getPassword());
        let data = {
            "UserName": Auth.getUserName(),
            "Password": encryptedPassword,
            "AppKey": encryptedAppKey,
            "ForceRefreshAccessToken": false
        }
        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        const BASE_URL = irpConfig.baseURL;
        let httpClient = eInvoice.getHTTPClient();
        return httpClient.post(
            ProxyUtils.getRelURL(AUTH_URL),
            { data }, {
            headers: ProxyUtils.getAxiosHeaders(Auth.getClientObject(), BASE_URL, AUTH_URL)
        }).then((response) => {
            response = response.Data;
            response["Sek"] = cryptoUtils.getAESDecryptedData(response.Sek, btoa(appKey));
            // response["Sek"] = btoa(response.Sek);
            Auth.save(response);
        })
    }

    static generateIRN(jsonData) {
        let httpClient = eInvoice.getHTTPClient();
        let reqPromise = Promise.resolve();
        if (Auth.isSessionExpired()) {
            reqPromise = eInvoice.authenticateUser();
        }
        let validation = JSONValidator.isValidJSONLd(JSON.parse(jsonData));

        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        const BASE_URL = irpConfig.baseURL;
        if (!validation.isValid) {
            return Promise.reject(ThirdPartyUtils.getSchemaError(validation.errors));
        }
        return reqPromise.then(() => {
            return httpClient.post(
                ProxyUtils.getRelURL(GEN_IRN),
                eInvoice.getRequestBody(jsonData), {
                headers: ProxyUtils.getAxiosHeaders(
                    Auth.getFullClientObject(),
                    BASE_URL,
                    GEN_IRN
                )
            }
            ).then(res => {
                return JSON.parse(atob(cryptoUtils.getAESDecryptedData(res.Data, Auth.getSek())));
            })
        })
    }

    static getRequestBody(jsonData) {
        let sek = Auth.getSek();
        if (!sek || sek === "") {
            return null;
        }
        let encryptedJsonData = cryptoUtils.getAESEncryptedData(jsonData, sek);
        return {
            Data: encryptedJsonData
        }
    }

    static cancelIRN(jsonData) {
        let httpClient = eInvoice.getHTTPClient();
        let reqPromise = Promise.resolve();
        if (Auth.isSessionExpired()) {
            reqPromise = eInvoice.authenticateUser();
        }

        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        const BASE_URL = irpConfig.baseURL;
        return reqPromise.then(() => {
            return httpClient.post(
                ProxyUtils.getRelURL(CAN_IRN),
                eInvoice.getRequestBody(jsonData), {
                headers: ProxyUtils.getAxiosHeaders(
                    Auth.getFullClientObject(),
                    BASE_URL,
                    CAN_IRN
                )
            }
            ).then(res => {
                return JSON.parse(atob(cryptoUtils.getAESDecryptedData(res.Data, Auth.getSek())));
            })
        })
    }

    static getEInvoiceDetails(irn) {
        let httpClient = eInvoice.getHTTPClient();
        let reqPromise = Promise.resolve();
        if (Auth.isSessionExpired()) {
            reqPromise = eInvoice.authenticateUser();
        }
        const _GET_IRN = `${GET_IRN}${irn}`

        const irpConfig = Utils.getFromLocalStorage('irpConfig');
        const BASE_URL = irpConfig.baseURL;
        return reqPromise.then(() => {
            return httpClient.get(
                ProxyUtils.getRelURL(_GET_IRN), {
                headers: ProxyUtils.getAxiosHeaders(
                    Auth.getFullClientObject(),
                    BASE_URL,
                    _GET_IRN
                )
            }
            ).then(res => {
                return JSON.parse(atob(cryptoUtils.getAESDecryptedData(res.Data, Auth.getSek())));
            })
        })
    }

    // static getGstinDetails(gstin) {
    //     axios({
    //         method: 'get',
    //         url: `/api/GetGSTINDetails/${gstin}`,
    //         headers: Auth.getFullClientObject()
    //     }).then((response) => {
    //         //Invoice Details
    //         console.log(response);
    //     }).catch((error) => {
    //         //Throw Error
    //         console.log(error);
    //     })
    // }

    static getGstin2(gstin) {
        if (gstin == 'sales') {
            return axios({
                method: 'get',
                url: `${GSTN_BASE_URL2}`,
            })
        }
        return axios({
            method: 'get',
            url: `${GSTN_BASE_URL}`,
        })
    }

    static getGstin3() {
        return fetch(GSTN_BASE_URL, {
            method: 'GET',
        })
            .then(response => {
                return response.json();
            })
    }

}

export default eInvoice;