import React, { Component } from "react";
import { connect } from "react-redux";
import { Drawer, Layout } from "antd";

import SidebarContent from "./SidebarContent";
import { toggleCollapsedSideNav, updateWindowWidth } from "../../appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
  THEME_TYPE_SEMI_DARK
} from "../../constants/ThemeSetting";
import * as Utils from '../../util/utils';

const { Sider } = Layout;

export class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    }
  }

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    this.listenOnResize();
  }

  listenOnResize = () => {

    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth })
    });
  }

  render() {
    const { themeType, navCollapsed, navStyle } = this.props;
    const { width } = this.state;
    let drawerStyle = "gx-collapsed-sidebar";
    let currentTheme = Utils.getFromLocalStorage('theme')
    currentTheme = currentTheme ? currentTheme : THEME_TYPE_LITE

    if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = "";
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      drawerStyle = "gx-custom-sidebar"
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = "gx-collapsed-sidebar"
    }
    if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
      || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
      drawerStyle = "gx-collapsed-sidebar"
    }

    return (
      <Sider
        className={`gx-app-sidebar ${drawerStyle} 
          ${currentTheme === THEME_TYPE_SEMI_DARK ?
            'gx-sidebar-dark gx-layout-sider-dark'
            :
            currentTheme !== THEME_TYPE_LITE ?
              'gx-layout-sider-dark'
              :
              null
          }
        `}
        trigger={null}
        collapsed={(width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
        theme={currentTheme === THEME_TYPE_LITE ? "lite" : "dark"}
        collapsible>
        {
          navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
            <Drawer
              wrapClassName={`gx-drawer-sidebar ${currentTheme !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
              placement="left"
              closable={false}
              onClose={this.onToggleCollapsedNav.bind(this)}
              visible={navCollapsed}>
              <SidebarContent />
            </Drawer> :
            <SidebarContent />
        }
      </Sider>)
  }
}

const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, navCollapsed, width, locale } = settings;
  return { themeType, navStyle, navCollapsed, width, locale }
};
export default connect(mapStateToProps, { toggleCollapsedSideNav, updateWindowWidth })(Sidebar);
