import React, { PureComponent } from "react";
import { Input, Icon, Button, message, Card, Form } from "antd";

import * as Utils from '../../util/utils';
import "./index.less";
import NetworkBridge from "../../util/network-bridge";
import Web3Utils from 'web3-utils';
import UserIcon from '../../assets/images/loginProfile1.svg';
import CircularProgress from "../../components/CircularProgress";
import IconComponent from "../../components/IconComponent";
import { dbURL } from '../../constants/AppConstants';
import * as ConfigObj from '../../param-network/config1.json';
import * as IpfsConfig from '../../param-network/ipfs-config.json';
import Analytics from '../../analytics';
import EventManager from '../../event-manager/event-manager';
import * as Config from '../../config.json';

class Login extends PureComponent {
  constructor(props) {
    super(props);
    Analytics.getInstance().trackPageview();
    this.configObj = ConfigObj.privateNodes;
    this.ipfsConfig = IpfsConfig.ipfsNodes;
    this.setConfigObj = ConfigObj.default;
    this.searchParams = new URLSearchParams(props.location.search);
    let heading = this.searchParams.get('heading');
    let mobileNo = this.searchParams.get('email');
    let otp = this.searchParams.get('otp')
    let vendorId = this.searchParams.get('vendorId')
    let settings = Utils.getFromLocalStorage('settings') ? Utils.getFromLocalStorage('settings') : {}
    this.state = {
      headingText: heading !== "OTP" ? "Please enter your Email to proceed" : `Enter OTP you received on ${mobileNo}`,
      heading: heading !== "OTP" ? "mobileNo" : "OTP",
      mobileNo: mobileNo ? mobileNo : "",
      otp: otp ? otp : "",
      isWeb: otp ? true : false,
      settings: settings
    }
    if (mobileNo && otp) {
      this.validateOTP(mobileNo, otp, vendorId)
    }
    Utils.clearLocalStorage();

  }

  verifyMobileNumber = (rule, value, callback) => {
    if (this.state.heading === "OTP") {
      callback();
    }
    var mobileno = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value && !value.match(mobileno)) {
      callback("Please input a valid email");
    }
    else {
      callback();
    }
  }
  redirectToAccountLisitng = () => {

  }
  validateOTP = (mobileNo, otp, vendorId) => {
    Analytics.getInstance().setEmailID(mobileNo);
    let autoRefresh = Utils.getFromLocalStorage('autoRefresh') ? Utils.getFromLocalStorage('autoRefresh') : false;
    let settings = this.state.settings ? this.state.settings : {}
    let profile = Utils.getFromLocalStorage('profile')
    Utils.clearLocalStorage();
    return NetworkBridge.getKeyStoreManager().verifyOTP(mobileNo, otp, vendorId).then(res => {
      if (res.status === false) {
        message.error("Please enter valid OTP");
        return;
        //throw new Error(res.message)
      }
      Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].verifyOTP, Config['analytics'].response, 1);
      console.log("OTP Account :", res)
      Utils.setLocalStorage("accountNo", mobileNo);
      Utils.setLocalStorage("autoRefresh", autoRefresh);
      Utils.setLocalStorage("settings", settings);


      if (this.props.location.pathname === "/admin") {
        Utils.setLocalStorage("encryptedMnemonic", res[0].mnemonic);
        Utils.setLocalStorage("privateKey", res[0].privateKey);
        Utils.setLocalStorage("publicKey", res[0].publicKey);
        Utils.setLocalStorage("nick_name", res[0].nick_name);
        Utils.setLocalStorage("param_id", Web3Utils.toChecksumAddress(res[0].paramId));
        // this.props.history.push({ pathname: '/password', state: { source: "create_otp" } });
        //Profile Creation
        // nHLzpsjqmIKB02Phyc4+cmd4LQQcl10SmxNKudnNNSo=
        Utils.setLocalStorage('organizationId', "");
        Utils.setLocalStorage('isAdmin', true);
        Utils.setLocalStorage("url", "ws://34.224.243.54:8546");
        Utils.setLocalStorage('dbURL', dbURL);
        Utils.setLocalStorage('privateUrl', this.configObj[0].privateUrl);
        Utils.setLocalStorage('configObj', JSON.stringify(this.setConfigObj));
        Utils.setLocalStorage('versionControl', "0x22AD1FF3161D6D40EC7503cB88A4028Aa983d139");
        let jsonLd = {
          "@context": "http://schema.org/",
          "@type": "Person",
          "name": res[0].nick_name ? res[0].nick_name : "Guest",
          "identifier": res[0].paramId,
          "address": {
            "@type": "PostalAddress",
            "addressLocality": undefined,
            "addressRegion": undefined,
            "postalCode": undefined,
            "streetAddress": undefined
          },
          "birthDate": undefined,
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": undefined,
            "contactType": undefined,
            "contactOption": undefined,
            "areaServed": undefined
          },
          "gender": undefined,
          "globalLocationNumber": undefined,
          "taxId": undefined,
          "vatId": undefined,
          "faxNumber": undefined,
          "email": mobileNo,
          "telephone": undefined,
          "url": undefined,
          "internalId": undefined,
          "hasOccupation": [
          ],
          "jobTitle": {
            "@type": "DefinedTerm",
            "inDefinedTermSet": undefined,
            "termCode": undefined,
            "name": undefined,
            "url": undefined
          },
          "worksFor": {
            "@type": "Organization",
            "name": undefined
          },
          "nationality": {
            "@type": "Country",
            "name": "IN"
          },
          "logoUrl": undefined,
          "city": undefined,
          "publicKey": undefined
        }
        profile ? Utils.setLocalStorage('profile', profile) : Utils.setLocalStorage('profile', JSON.stringify(jsonLd))
        //this.props.history.push('/addaccount')
        if (res.length > 1) {
          Utils.setLocalStorage("accounts", res);
        }
        EventManager.getInstance().emitEvent("onProfileUpdate", profile)
        this.props.history.push('/main/landing?init')
        return
      }
      // else if (res.length > 1) {
      //   Utils.setLocalStorage("accounts", res);
      //   this.props.history.push('/selectaccount')
      //   return
      if (res.length > 1) {
        Utils.setLocalStorage("accounts", res);
      }


      // if (!mobileNo.startsWith('+91')) {
      //   mobileNo = '+91' + mobileNo;
      // }

      Utils.setLocalStorage("encryptedMnemonic", res[0].mnemonic);
      Utils.setLocalStorage("privateKey", res[0].privateKey);
      Utils.setLocalStorage("publicKey", res[0].publicKey);
      Utils.setLocalStorage("nick_name", res[0].nick_name);
      Utils.setLocalStorage("param_id", Web3Utils.toChecksumAddress(res[0].paramId));
      // this.props.history.push({ pathname: '/password', state: { source: "create_otp" } });
      //Profile Creation
      // nHLzpsjqmIKB02Phyc4+cmd4LQQcl10SmxNKudnNNSo=
      Utils.setLocalStorage('organizationId', "");
      Utils.setLocalStorage("url", "ws://34.224.243.54:8546");
      Utils.setLocalStorage('dbURL', dbURL);
      Utils.setLocalStorage('privateUrl', this.configObj[0].privateUrl);
      Utils.setLocalStorage('configObj', JSON.stringify(this.setConfigObj));
      Utils.setLocalStorage('versionControl', "0x22AD1FF3161D6D40EC7503cB88A4028Aa983d139");
      let jsonLd = {
        "@context": "http://schema.org/",
        "@type": "Person",
        "name": res[0].nick_name ? res[0].nick_name : "Guest",
        "identifier": res[0].paramId,
        "address": {
          "@type": "PostalAddress",
          "addressLocality": undefined,
          "addressRegion": undefined,
          "postalCode": undefined,
          "streetAddress": undefined
        },
        "birthDate": undefined,
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": undefined,
          "contactType": undefined,
          "contactOption": undefined,
          "areaServed": undefined
        },
        "gender": undefined,
        "globalLocationNumber": undefined,
        "taxId": undefined,
        "vatId": undefined,
        "faxNumber": undefined,
        "email": mobileNo,
        "telephone": undefined,
        "url": undefined,
        "internalId": undefined,
        "hasOccupation": [
        ],
        "jobTitle": {
          "@type": "DefinedTerm",
          "inDefinedTermSet": undefined,
          "termCode": undefined,
          "name": undefined,
          "url": undefined
        },
        "worksFor": {
          "@type": "Organization",
          "name": undefined
        },
        "nationality": {
          "@type": "Country",
          "name": "IN"
        },
        "logoUrl": undefined,
        "city": undefined,
        "publicKey": undefined
      }
      Utils.setLocalStorage('profile', JSON.stringify(jsonLd))
      EventManager.getInstance().emitEvent("onProfileUpdate", profile)
      this.props.history.push('/main/landing?init')
      message.success("Success");


    }).catch(err => {
      console.error("Error: ", err);
      message.error(err.message);
      this.props.form.resetFields();
      if (this.state.isWeb) {
        this.setState({ isWeb: false, heading: "OTP" })
        this.props.history.push('/otplogin');
      }
    }).finally(() => {
      this.setState({
        showLoader: false,
      });
    })
  }

  sendOTP = (mobileNo, vendorId) => {
    return NetworkBridge.getKeyStoreManager().sendOTP(mobileNo, vendorId).then(res => {
      Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].sendOTP, Config['analytics'].click, 1);
      message.success("OTP Sent successfully")
      //this.setState({ heading: 'OTP', headingText: `Enter OTP you received on ${mobileNo}` })
      this.setState({ heading: 'authorize', headingText: "Email Sent Successfully click on Authenticate to login", showLoader: false })
    }).catch(err => {
      console.error("Error: ", err);
      message.error(err.message);
    }).finally(() => {
      this.setState({
        showLoader: false
      })
    })
  }

  resendOtp = () => {
    this.setState({ showLoader: true });
    Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].resendOTP);
    return NetworkBridge.getKeyStoreManager().sendOTP(this.state.mobileNo).then(res => {
      Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].response, Config['analytics'].click, 1);
      message.success("OTP Re-Sent successfully")
    }).catch(err => {
      console.error("Error: ", err);
      message.error(err.message);
    }).finally(() => {
      this.setState({
        showLoader: false
      })
    })
  }

  updateAccountInfo = () => {
    this.setState({
      headingText: "Please enter your Email ID to proceed",
      heading: "mobileNo"
    })
  }

  handleClick = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        this.setState({ showLoader: false });
        return false;
      }
      let value = values[this.state.heading];
      if (this.state.heading === "OTP" || this.state.heading === 'authorize') {
        this.setState({ showLoader: true });
        Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].verifyOTP);
        this.validateOTP(this.state.mobileNo, value, this.state.vendorId);
      }
      else {
        this.setState({ mobileNo: value, vendorId: values["VendorId"], showLoader: true });
        Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].sendOTP);
        this.sendOTP(value, values["VendorId"]);
      }
    })
  }

  handleClose = () => {
    window.opener = null;
    window.open(window.location.href, "_self", "");
    window.close();
  }

  handleOTP = () => {
    this.setState({ showLoader: false, heading: 'OTP', headingText: `Enter OTP you received on ${this.state.mobileNo}` })
  }

  handleForgotPassword = (path, type) => {
    if (!type) {
      this.props.history.push(path);
    }
    this.props.history.push(({
      pathname: path,
      state: { account: 'Switch Account' }
    }))
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card className="login" actions={[<a href="https://param.network" target="_blank">&copy; ⦃param⦄.network</a>]}>
        {this.state.showLoader && <CircularProgress />}
        <div className="login__heading">

          {
            this.state.heading === "OTP" &&
            <IconComponent onClick={this.updateAccountInfo} style={{ marginLeft: "-2em", fontSize: "1.2em" }} type="arrow-left" actionIcon={true} hoverText="Go Back" />
          }
        </div>
        <center>
          {
            <img src={UserIcon} className="login__logo-image" alt="avatar" />
          }
        </center>
        <center>
          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-4 login_welcomeText">
            Welcome
            </h1>
        </center>
        { this.state.isWeb ? <p>Please wait while we authenticate you</p> : <div className="login__inner">
          <center>
            <h5 className="gx-mb-3 login_passwordText">
              {this.state.headingText}
            </h5>
          </center>
          {
            this.state.heading === 'mobileNo' || this.state.heading === 'OTP'
              ?
              <Form className="login__form">
                <Form.Item style={{ marginBottom: "0.35em" }}>
                  {getFieldDecorator(`${this.state.heading}`, {
                    rules: [{
                      required: true,
                      message: `${this.state.heading === 'mobileNo' ? 'Please input email' : 'Please input ' + this.state.heading}`,
                    },
                    { validator: this.verifyMobileNumber }],
                    initialValue: this.state.heading === "mobileNo" ? this.state.mobileNo : null
                  })(
                    <Input id="login_mobileNo"
                      prefix={<Icon type="mail" />}
                      placeholder={`${this.state.heading === 'mobileNo' ? 'Enter your email' : "Enter your " + this.state.heading}`}
                      className="login_input"
                      value={this.state.otp}
                      autoFocus
                    />
                  )
                  }
                </Form.Item>
                {this.state.heading !== "OTP" && this.props.location.pathname === "/admin" && <Form.Item style={{ marginBottom: "0.35em" }}>
                  {getFieldDecorator(`VendorId`, {
                    rules: [{
                      required: false,
                      message: `Please input the Vendor ID`,
                    },
                    ],
                  })(
                    <Input id="login_vendorId"
                      prefix={<Icon type="mail" />}
                      placeholder={`Enter Vendor ID`}
                      className="login_input"
                    />
                  )
                  }
                </Form.Item>}
              </Form>
              :
              ""
          }
          {/* {this.state.heading === "mobileNo" &&
            <>
              <p className="gx-text-primary gx-mb-3 gx-mb-sm-4 login__forgotText">
                <span onClick={() => this.handleForgotPassword('/restore')}>Forgot Password</span> | <span onClick={() => this.handleForgotPassword('/restore', 'switch')}>Switch Account</span>
              </p>
              <p onClick={() => this.handleForgotPassword('/landing')} className="gx-text-primary gx-mb-3 gx-mb-sm-4 login__createNew gx-pointer">Create New Account</p>
            </>
          } */}

          {
            this.state.heading === "OTP" &&
            <p onClick={this.resendOtp} className="gx-text-primary gx-mb-3 gx-mb-sm-4 login__createNew gx-pointer">Resend OTP</p>
          }
          {/* {
            this.state.heading === 'authorize' &&
            <Button type="button" className="login__close" onClick={this.handleClose}>Close</Button>
          } */}
          {
            this.state.heading !== 'authorize'
              ?
              <Button disabled={this.state.showLoader} type="button" className="login__loginBtn" onClick={this.handleClick}>Next</Button>
              :
              <Button type="button" className="login__loginBtn" onClick={this.handleOTP}>Enter OTP</Button>
          }
        </div >}
      </Card >
    );
  }
}
const OTPLogin = Form.create({ name: 'login' })(Login)
export default OTPLogin;