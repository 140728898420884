import Database from '../../index'
import RestoreTemplateRepo from '../sync/template-repo';
import Console from '../../../logger/index';

class TemplateRepoDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerOnTemplate(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnTemplate", callback);
        Console.info(`[GraphDB] register OnTemplate event`);
    }

    unRegisterOnTemplate(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnTemplate", callback);
        Console.info(`[GraphDB] un register OnTemplate event`);
    }

    registerOnTemplateShared(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnTemplateShared", callback);
        Console.info(`[GraphDB] register OnTemplateShared event`);
    }

    unRegisterOnTemplateShared(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnTemplateShared", callback);
        Console.info(`[GraphDB] un register OnTemplateShared event`);
    }

    registerOnStep(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnStep", callback);
        Console.info(`[GraphDB] register OnStep event`);
    }

    unRegisterOnStep(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnStep", callback);
        Console.info(`[GraphDB] un register OnStep event`);
    }

    _onStep(error, dataJSON) {
        Console.info(`[GraphDB] Got _onStep event`);
    }


    _onTemplateShared(error, dataJSON) {
        Console.info(`[GraphDB] Got _onTemplateShared event`);
        if (error) {
            return;
        }
        return RestoreTemplateRepo.restoreTemplateAsSubscriber(dataJSON.args.templateId, dataJSON.args.owner, dataJSON.args.subscribers, dataJSON.address).then(response => {
            this.dbInstance.emitEvent("OnTemplateShared", dataJSON.args.templateId, null);
        }).then(() => {
            let templateId = dataJSON.args.templateId + "-" + dataJSON.address;
            return this.dbInstance.templateRepoDB.isInstalled(templateId)
                .then(isInstalled => {
                    if (isInstalled === "false") {
                        return this.dbInstance.notifications.addNotification(templateId, "onTemplate", "template", dataJSON.args.creator).then(() => {
                            this.dbInstance.events.emit("notification", templateId, null);
                        });
                    }
                })
        }).catch(error => {
            Console.error(`Unable to share template ${dataJSON.args.templateId}, reason: ${error.toString()}`);
        })
        // .finally(() => {
        //     RestoreTemplateRepo.restoreTemplateRepoIdEvents(dataJSON.address, dataJSON.args.templateId);
        // })
    }
}

export default TemplateRepoDBEvents;