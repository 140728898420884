export const ModalFormFields = {
    GSTInvoicesAdditionalFields: [
        {
            label: "SLNO",
            id: "SLNO",
            required: false,
            disabled: true,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Is Service?",
            id: "IsServc",
            required: false,
            disabled: false,
            dataType: {
                input: "Radio",
                options: [
                    {
                        label: "Y",
                        key: "Y"
                    },
                    {
                        label: "N",
                        key: "N"
                    }
                ]
            }
        },
        {
            label: "SLNO",
            id: "SLNO",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "HSN Code",
            id: "HsnCd",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "IGST",
            id: "IgstRt",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Cess Non AdVal",
            id: "CesNonAdval",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Discount Amount",
            id: "Discount",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Other Charges",
            id: "OthChrg",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Cess Value",
            id: "CesRt",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "State Cess Amount",
            id: "StateCes",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        }
    ],
    CustomGraphForm: [
        {
            label: "Chart Type",
            id: "chartType",
            required: true,
            disabled: false,
            dataType: {
                input: "Select",
                options: [{
                    label: "Bar Chart",
                    key: "barChart"
                },
                {
                    label: "Stacked Bar Chart",
                    key: "stackedBarChart"
                },
                {
                    label: "Line Graph",
                    key: "lineChart"
                },
                {
                    label: "Area Graph",
                    key: "areaChart"
                },
                {
                    label: "Pie Chart",
                    key: "pieChart"
                }]
            }
        },
        {
            label: "Function Type",
            id: "argFun",
            required: true,
            disabled: false,
            dataType: {
                input: "Select",
                options: [{
                    label: "Count",
                    key: "count"
                },
                {
                    label: "Cumulative Count",
                    key: "cumulativeCount"
                },
                {
                    label: "Cumulative Total",
                    key: "cumulativeTotal"
                },
                {
                    label: "Total",
                    key: "total"
                }]
            }
        },
        {
            label: "Step",
            id: "stepId",
            required: true,
            disabled: false,
            dataType: {
                input: "Select"
            }
        },
        {
            label: "Select X",
            id: "labelX",
            required: true,
            disabled: false,
            dataType: {
                input: "Select"
            }
        },
        {
            label: "Field X",
            id: "fieldX",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Select Y",
            id: "labelY",
            required: true,
            disabled: false,
            dataType: {
                input: "Select"
            }
        },
        {
            label: "Field Y",
            id: "fieldY",
            required: false,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Type",
            id: "type",
            required: true,
            disabled: false,
            dataType: {
                input: "Select",
                options: [{
                    label: "Template Consensus",
                    key: "templateConsensus"
                },
                {
                    label: "Step Consensus",
                    key: "stepConsensus"
                },
                {
                    label: "Step Details",
                    key: "stepDetails"
                }]
            }
        }
    ],
    ManualConnectionForm: [
        {
            label: "Organization ID",
            id: "publicAddress",
            required: true,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "URL",
            id: "rpcURL",
            required: true,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "PrivateURL",
            id: "privateUrl",
            required: true,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "VersionControl",
            id: "versionControl",
            required: true,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Config",
            id: "configObj",
            required: true,
            dataType: {
                input: "TextArea",
                rows: 8
            }
        },
    ],
    InviteSupplierForm: [
        {
            label: "Param Id",
            id: "paramId",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Name",
            id: "name",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Email",
            id: "email",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Organization",
            id: "organization",
            required: true,
            dataType: {
                input: "Input",
            }
        },

    ],

    /*Vender OnBoarding Form*/
    ValidationForm: [

        {
            label: "Country",
            id: "country",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "PAN No.",
            id: "panNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Tax Identification No",
            id: "taxID",
            required: true,
            dataType: {
                input: "Input",
            }
        },
    ],
    BasicDetailsForm: [

        {
            label: "Name",
            id: "name",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Email ID",
            id: "emailID",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Contact No",
            id: "contactNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Fax No",
            id: "faxNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "_divider",
            id: "_divider",
            required: false,
            dataType: {
                input: "Divider",
            }
        },
        {
            label: "Door No",
            id: "doorNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Street",
            id: "street",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "City",
            id: "city",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "State",
            id: "state",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Country",
            id: "country",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "_divider",
            id: "_divider",
            required: false,
            dataType: {
                input: "Divider",
            }
        },
        {
            label: "Business Incorporation No",
            id: "businessIncorporationNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Business Licence No",
            id: "businessLicenceNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Business Organization Type",
            id: "businessOrganizationType",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Business Nature",
            id: "businessNature",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Business Insured",
            id: "businessInsured",
            required: true,
            dataType: {
                input: "Input",
            }
        }
    ],
    BankDetailsForm: [
        {
            label: "Name On Account",
            id: "nameOnAccount",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Bank Name",
            id: "bankName",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Bank Branch",
            id: "bankBranch",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Bank Account No",
            id: "bankAccountNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Bank IFSC Code",
            id: "bankIFSCCode",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Bank Account Type",
            id: "bankAccountType",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "_divider",
            id: "_divider",
            required: false,
            dataType: {
                input: "Divider",
            }
        },
        {
            label: "Door No",
            id: "doorNo",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Country",
            id: "country",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Street",
            id: "street",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Country",
            id: "country",
            required: true,
            dataType: {
                input: "Input",
            }
        },

        {
            label: "_divider",
            id: "_divider",
            required: false,
            dataType: {
                input: "Divider",
            }
        },
        {
            label: "Payment Method",
            id: "paymentMethod",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Currency",
            id: "currency",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Terms Of Payment",
            id: "termsOfPayment",
            required: true,
            dataType: {
                input: "Input",
            }
        },
        {
            label: "Other",
            id: "other",
            required: true,
            dataType: {
                input: "Input",
            }
        }
    ],
    DocumentsForm: [
        {
            label: "Certificate of Incorporation",
            id: "certificateofIncorporation",
            required: true,
            dataType: {
                input: "Input",
                type: "file"
            }
        },
        {
            label: "Govt Licence/Permit",
            id: "govtLicence/Permit",
            required: true,
            dataType: {
                input: "Input",
                type: "file"
            }
        },
        {
            label: "Quality Certificate",
            id: "qualityCertificate",
            required: true,
            dataType: {
                input: "Input",
                type: "file"
            }
        },
        {
            label: "Certificate of Commencement",
            id: "certificateofCommencement",
            required: true,
            dataType: {
                input: "Input",
                type: "file"
            }
        }
    ],
    /*Vender OnBoarding Form*/

    irpCredForm: [
        {
            label: "User Name",
            id: "userName",
            required: true,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Password",
            id: "password",
            required: true,
            disabled: false,
            dataType: {
                input: "Input",
                type: "password"
            }
        },
        {
            label: "Base URL",
            id: "baseURL",
            required: true,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Client ID",
            id: "clientId",
            required: true,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
        {
            label: "Client Secret Key",
            id: "clientSecretKey",
            required: true,
            disabled: false,
            dataType: {
                input: "Input"
            }
        },
    ]
}