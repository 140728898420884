import React, { Component } from "react";
import { Menu, Icon, Badge } from "antd";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "../../util/Auxiliary";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
  THEME_TYPE_SEMI_DARK
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";
import { Images } from '../../constants/AppConstants.js';
import BaseComponent from '../../routes/main/BaseComponent/index';
import NetworkBridge from '../../util/network-bridge/index';
import Console from '../../logger/index';
import * as Config from "../../config.json"
import * as Utils from '../../util/utils';
import EventManager from '../../event-manager/event-manager.js';
import Analytics from '../../analytics';
import './SidebarContent.less';
import { setSalesVisibility, setLSalesVisibility, setLPurchasesVisibility, setPurchasesVisibility, setDraftInvoicesVisibility, setReportsVisibility, setOperationsVisibility, setSubscribedDocumentsVisibility, setCatalogueVisibility, setContactsVisibility, setCreditNoteTemplateVisibility } from "../../appRedux/actions/Setting";
//sidebar icons
import { ReactComponent as contractIncoming } from '../../assets/images/contractIncoming.svg';
import { ReactComponent as contractOutgoing } from '../../assets/images/contractOutgoing.svg';
import { ReactComponent as poIncoming } from '../../assets/images/purchaseOrderIncoming.svg';
import { ReactComponent as poOutgoing } from '../../assets/images/purchaseOrderOutgoing.svg';
import { ReactComponent as invoiceIncoming } from '../../assets/images/invoiceIncoming.svg';
import { ReactComponent as invoiceOutgoing } from '../../assets/images/invoiceOutgoing.svg';
import { ReactComponent as sales } from '../../assets/images/salesSvg.svg';
import { ReactComponent as purchases } from '../../assets/images/purchasesSvg.svg';
import { ReactComponent as reconcilliation } from '../../assets/images/reconciliationSvg.svg';
import { ReactComponent as item } from '../../assets/images/itemSvg.svg';
import { ReactComponent as contact } from '../../assets/images/contactSvg.svg';
import { ReactComponent as graph } from '../../assets/images/graphSvg.svg';
import { ReactComponent as reports } from '../../assets/images/reportsSvg.svg';
import { ReactComponent as invitation } from '../../assets/images/invitationSvg.svg';
import { ReactComponent as workflow } from '../../assets/images/workflow.svg';
import { ReactComponent as workflowBuilder } from '../../assets/images/workflowBuilder.svg';
import { ReactComponent as subscribedDocument } from '../../assets/images/SubscribedDocuments.svg';
import { settings } from "nprogress";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends BaseComponent {

  constructor(props) {
    super(props);
    this.analytics = Analytics.getInstance();
    this.config = Config['analytics'];
    this.state = {
      invitationCount: 0,
      draftInvoiceName: this.props.draftInvoicesVisibility ? "Clearing Details" : "Draft Invoice (CN)"
      // isDevEnabled: Utils.getFromLocalStorage('isDevEnabled')
    }
  }

  componentDidMount() {
    super.componentDidMount();
    // this.getInvitationCount();
    EventManager.getInstance().registerEvent('onInvitationUpdate', this.InvitationUpdated)
    let salesToggle = Utils.getFromLocalStorage('salesToggle') !== "" ? Utils.getFromLocalStorage('salesToggle') : Config["sidebar-toggles"].sales
    let purchaseToggle = Utils.getFromLocalStorage('purchasesToggle') !== "" ? Utils.getFromLocalStorage('purchasesToggle') : Config["sidebar-toggles"].purchases
    let lSalesToggle = Utils.getFromLocalStorage('lSalesToggle')
    let lPurchasesToggle = Utils.getFromLocalStorage('lPurchasesToggle')
    let draftInvoicesToggle = Utils.getFromLocalStorage('draftInvoicesToggle')
    let reports = Utils.getFromLocalStorage('reportsVisibility')
    let subscribedDocuments = Utils.getFromLocalStorage('subscribedDocumentsVisibility')
    let operations = Utils.getFromLocalStorage('operationsVisibility')
    let set = Utils.getFromLocalStorage("settings")
    let profileSettings = Utils.getFromLocalStorage("settings")[Utils.getFromLocalStorage("param_id")]
    if (!profileSettings) {
      this.props.setSalesVisibility(true)
      this.props.setPurchasesVisibility(true)
      this.props.setLSalesVisibility(false)
      this.props.setLPurchasesVisibility(false)
      this.props.setDraftInvoicesVisibility(false)
      this.props.setReportsVisibility(true)
      this.props.setOperationsVisibility(true)
      this.props.setSubscribedDocumentsVisibility(true)
      let settingsObject = {}
      settingsObject["sales"] = true
      settingsObject["purchases"] = true
      settingsObject["lsales"] = false
      settingsObject["lpurchases"] = false
      settingsObject["draftInvoice"] = false
      settingsObject["reports"] = true
      settingsObject["subscribedDocuments"] = true
      settingsObject["operations"] = true
      settingsObject["catalogue"] = true
      settingsObject["contacts"] = true
      settingsObject["creditNoteTemplate"] = false
      let accountSettings = set
      accountSettings[Utils.getFromLocalStorage("param_id")] = settingsObject
      Utils.setLocalStorage("settings", accountSettings)
    } else {
      let settingsObject = profileSettings
      this.props.setSalesVisibility(settingsObject.sales)
      this.props.setPurchasesVisibility(settingsObject.purchases)
      this.props.setLSalesVisibility(settingsObject.lsales)
      this.props.setLPurchasesVisibility(settingsObject.lpurchases)
      this.props.setDraftInvoicesVisibility(settingsObject.draftInvoice)
      this.props.setReportsVisibility(settingsObject.reports)
      this.props.setSubscribedDocumentsVisibility(settingsObject.subscribedDocuments)
      this.props.setOperationsVisibility(settingsObject.operations)
      this.props.setCatalogueVisibility(settingsObject.catalogue)
      this.props.setContactsVisibility(settingsObject.contacts)
      this.props.setCreditNoteTemplateVisibility(settingsObject.creditNoteTemplate)
    }
    console.log(">>>>", salesToggle, purchaseToggle)

    // this.startCounter = 0;
    // document.addEventListener('keypress', this.onKeyDown)
    // document.addEventListener('keyup', this.onKeyUp)
  }

  onDBReady = () => {
    // this.getInvitationCount();
  }

  // getRegisterEvents() {
  //   return [
  //     {
  //       "eventCategory": "subscriber",
  //       "registerEvent": "registerOnSubscriberAdded",
  //       "unRegisterEvent": "unRegisterOnSubscriberAdded",
  //       callback: this.getInvitationCount
  //     }]
  // }

  InvitationUpdated = (data) => {
    this.getInvitationCount();
  }

  getInvitationCount = () => {
    const templateRepoManager = NetworkBridge.getTemplateRepoManager();
    templateRepoManager.getAllInvitations().then(invitationsList => {
      this.setState({
        invitationCount: invitationsList.count
      })
    }).catch(err => {
      Console.error(err)
    })
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  // onKeyDown = (e) => {
  //   if (e.code === 'KeyD') {
  //     this.startCounter++;
  //   }
  // }

  // onKeyUp = (e) => {
  //   if (e.code === 'KeyD') {
  //     if (this.startCounter > 10)
  //       if (this.state.isDevEnabled !== undefined) {
  //         this.startCounter = 0
  //         this.setState({ isDevEnabled: !this.state.isDevEnabled }, () => {
  //           Utils.setLocalStorage('isDevEnabled', !this.state.isDevEnabled)
  //         })
  //       } else {
  //         this.startCounter = 0
  //         this.setState({ isDevEnabled: true }, () => {
  //           Utils.setLocalStorage('isDevEnabled', true)
  //         })
  //       }
  //   }
  // }


  getVisibility = (menuItemVisibility) => {
    return Config['customSettings'] === true && menuItemVisibility
  }


  getFinanceMenuItems = () => {
    const { themeType, navStyle, irpMode, salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility, draftInvoicesVisibility, reportsVisibility, operationsVisibility, subscribedDocumentsVisibility, catalogueVisibility, contactsVisibility } = this.props;
    console.log("testRedux", irpMode, themeType);
    let pathname = this.props.location.pathname;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    let currentTheme = Utils.getFromLocalStorage('theme')
    currentTheme = currentTheme ? currentTheme : THEME_TYPE_LITE;
    console.log('Sales Visibility', salesVisibility);
    console.log('Purchases Visibility', purchasesVisibility);
    console.log('Logistics Sales Visibility', lSalesVisibility);
    console.log('Logistics Purchases Visibility', lPurchasesVisibility);
    console.log('Draft Invoices Visibility', draftInvoicesVisibility)
    return (
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        theme={currentTheme === THEME_TYPE_LITE ? 'lite' : 'dark'}
        mode="inline">
        <MenuItemGroup key="Finance" className="gx-menu-group" title={<IntlMessages id="sidebar.finance" />}>

          {
            Config['customSettings'] === true ? purchasesVisibility : false &&
              <SubMenu key="Purchases" className={this.getNavStyleSubMenuClass(navStyle)}
                title={<span>
                  <Icon className="sidebar-icon submenu-icon" component={purchases} alt="" />
                  <IntlMessages id="sidebar.purchases" /></span>}>

                <Menu.Item key="main/finance/contacts/supplier">
                  <Link to="/main/finance/contacts/supplier"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.suppliers, this.config.open)}>
                    <Icon className="sidebar-icon" component={contact} alt="" />
                    <IntlMessages id="chat.suppliers" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/finance/purchases/contract">
                  <Link to="/main/finance/purchases/contract"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.contract, this.config.open)}>
                    <Icon className="sidebar-icon" component={contractIncoming} />
                    <IntlMessages id="sidebar.purchases.contract" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/finance/purchases/purchaseOrder">
                  <Link to="/main/finance/purchases/purchaseOrder"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.purchaseOrder, this.config.open)}>
                    <Icon className="sidebar-icon" component={poOutgoing} alt="" />
                    <IntlMessages id="sidebar.purchases.purchaseOrder" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/finance/purchases/invoice">
                  <Link to="/main/finance/purchases/invoice"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.invoice, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceIncoming} />
                    <IntlMessages id="sidebar.purchases.invoice" />
                  </Link>
                </Menu.Item>
                {irpMode &&
                  <Menu.Item key="main/finance/purchases/irp">
                    <Link to="/main/finance/purchases/irp"
                      onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.irp, this.config.open)}>
                      <Icon className="sidebar-icon" component={invoiceIncoming} />
                      <IntlMessages id="sidebar.purchases.irp" />
                    </Link>
                  </Menu.Item>
                }

                <Menu.Item key="main/finance/purchases/payment">
                  <Link to="/main/finance/purchases/payment"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.payment, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceOutgoing} />
                    <IntlMessages id="sidebar.purchases.payment" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/finance/grn/default">
                  <Link to="/main/finance/grn/default"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.grn, this.config.open)}>
                    <Icon className="sidebar-icon" component={poOutgoing} alt="" />
                    <IntlMessages id="sidebar.grn" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/finance/purchases/dispute">
                  <Link to="/main/finance/purchases/dispute"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.dispute, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceOutgoing} />
                    <IntlMessages id="sidebar.purchases.dispute" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/finance/purchases/returns">
                  <Link to="/main/finance/purchases/returns"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.purchases + ':' + this.config.returns, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceOutgoing} />
                    <IntlMessages id="sidebar.purchases.returns" />
                  </Link>
                </Menu.Item>
              </SubMenu>
          }
          {
            Config['customSettings'] === true ? salesVisibility : false &&
              <SubMenu key="Sales" className={this.getNavStyleSubMenuClass(navStyle)}
                title={<span>
                  <Icon className="sidebar-icon submenu-icon" component={sales}
                  />
                  <IntlMessages id="sidebar.sales" /></span>}>
                <Menu.Item key="main/finance/contacts/customer">
                  <Link to="/main/finance/contacts/customer"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.customers, this.config.open)}
                  >
                    <Icon className="sidebar-icon" component={contact} alt="" />
                    <IntlMessages id="sidebar.sales.customer" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/finance/sales/contract">
                  <Link to="/main/finance/sales/contract"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.contract, this.config.open)}>
                    <Icon className="sidebar-icon" component={contractOutgoing} />
                    <IntlMessages id="sidebar.sales.contract" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/finance/sales/purchaseOrder">
                  <Link to="/main/finance/sales/purchaseOrder"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.purchaseOrder, this.config.open)}>
                    <Icon className="sidebar-icon" component={poIncoming} alt="" />
                    <IntlMessages id="sidebar.sales.purchaseOrder" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/finance/sales/invoice">
                  <Link to="/main/finance/sales/invoice"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.invoice, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceOutgoing} />
                    <IntlMessages id="sidebar.sales.invoice" />
                  </Link>
                </Menu.Item>
                {irpMode &&
                  <Menu.Item key="main/finance/sales/irp">
                    <Link to="/main/finance/sales/irp"
                      onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.irp, this.config.open)}>
                      <Icon className="sidebar-icon" component={invoiceOutgoing} />
                      <IntlMessages id="sidebar.sales.irp" />
                    </Link>
                  </Menu.Item>
                }
                <Menu.Item key="main/finance/sales/payment">
                  <Link to="/main/finance/sales/payment"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.payment, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceIncoming} />
                    <IntlMessages id="sidebar.sales.payment" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/finance/sales/dispute">
                  <Link to="/main/finance/sales/dispute"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.dispute, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceIncoming} />
                    <IntlMessages id="sidebar.sales.dispute" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="main/finance/sales/returns">
                  <Link to="/main/finance/sales/returns"
                    onClick={() => this.analytics.trackEvent(this.config.menu, this.config.sales + ':' + this.config.returns, this.config.open)}>
                    <Icon className="sidebar-icon" component={invoiceIncoming} />
                    <IntlMessages id="sidebar.sales.returns" />
                  </Link>
                </Menu.Item>
              </SubMenu>
          }

          {Config['customSettings'] === true ? reportsVisibility : false && <SubMenu key="Reports" className={this.getNavStyleSubMenuClass(navStyle)}
            title={
              <span>
                <Icon className="sidebar-icon submenu-icon" component={reports} alt="" />
                <IntlMessages id="sidebar.reports" />
              </span>}>
            <SubMenu key="PurchasesDashboard" className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.purchasesDashboard" />
                </span>}>
              <Menu.Item key="dashboard/purchases/overview">
                <Link to="/dashboard/purchases/overview"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.poOverview, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.purchases.overview" />
                </Link>
              </Menu.Item>
              <Menu.Item key="dashboard/purchases/delivery">
                <Link to="/dashboard/purchases/delivery"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.purchasesDelivery, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.purchases.supplierDelivery" />
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="SalesDashboard" className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.salesDashboard" />
                </span>}>
              <Menu.Item key="dashboard/sales/overview">
                <Link to="/dashboard/sales/overview"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.salesOverview, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.sales.overview" />
                </Link>
              </Menu.Item>
              <Menu.Item key="dashboard/sales/customerInsights">
                <Link to="/dashboard/sales/customerInsights"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.customerInsights, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.sales.customerInsights" />
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="FinanceDashboard" className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.financeDashboard" />
                </span>}>
              <Menu.Item key="dashboard/finance/overview">
                <Link to="/dashboard/finance/overview"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.financeOverview, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.finance.overview" />
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="InventoryDashboard" className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.inventoryDashboard" />
                </span>}
            >
              <Menu.Item key="dashboard/inventory/overview">
                <Link to="/dashboard/inventory/overview"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.inventoryOverview, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.inventory.overview" />
                </Link>
              </Menu.Item>
              <Menu.Item key="dashboard/inventory/insights">
                <Link to="/dashboard/inventory/insights"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.inventoryInsights, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.inventory.insights" />
                </Link>
              </Menu.Item>
              <Menu.Item key="dashboard/inventory/history">
                <Link to="/dashboard/inventory/history"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.inventoryHistory, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.inventory.history" />
                </Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="main/report/default">
              <Link to="/main/report/default">
                <Icon className="sidebar-icon" component={reconcilliation} alt="" />
                <IntlMessages id="sidebar.reports.reconciliation" />
              </Link>
            </Menu.Item>

            <SubMenu key="reconciliation" className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <Icon className="sidebar-icon submenu-icon" component={reconcilliation} alt="" />
                  <IntlMessages id="sidebar.reconcilitaion" />
                </span>}
            >
              <Menu.Item key="main/report/default">
                <Link to="/main/report/default"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reconciliation + ':' + this.config.summary, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.reconcilitaion.summary" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/report/sales">
                <Link to="/main/report/sales"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reconciliation + ':' + this.config.sales, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.reconcilitaion.sales" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/report/purchases">
                <Link to="/main/report/purchases"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reconciliation + ':' + this.config.purchases, this.config.open)}>
                  <Icon className="sidebar-icon" component={item} alt="" />
                  <IntlMessages id="sidebar.reconcilitaion.purchases" />
                </Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="main/kgraph">
              <Link to="/main/kgraph"
                onClick={() => this.analytics.trackEvent(this.config.menu, this.config.reports + ':' + this.config.kGraph, this.config.open)}>
                <Icon className="sidebar-icon" component={graph} alt="" />
                <IntlMessages id="sidebar.reports.graph" />
              </Link>
            </Menu.Item>
          </SubMenu>}


          {/* <SubMenu key="InvoiceValidation" className={this.getNavStyleSubMenuClass(navStyle)}
            title={<span>
              <Icon className="sidebar-icon submenu-icon" component={purchases} alt="" />
              <IntlMessages id="sidebar.invoiceValidation" /></span>}>
            <Menu.Item key="main/finance/invoiceValidation/priceDispute">
              <Link to="/main/finance/invoiceValidation/priceDispute">
                <Icon className="sidebar-icon" component={contractIncoming} />
                <IntlMessages id="sidebar.invoiceValidation.priceDispute" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/finance/invoiceValidation/qtyDispute">
              <Link to="/main/finance/invoiceValidation/qtyDispute">
                <Icon className="sidebar-icon" component={poOutgoing} alt="" />
                <IntlMessages id="sidebar.invoiceValidation.qtyDispute" />
              </Link>
            </Menu.Item>
          </SubMenu> */}

          {Config['customSettings'] === true ? subscribedDocumentsVisibility : false && <SubMenu key="Subscribed Documents" className={this.getNavStyleSubMenuClass(navStyle)}
            title={<span>
              <Icon className="sidebar-icon submenu-icon" component={subscribedDocument} />
              <IntlMessages id="sidebar.subscribed" /></span>}
          >
            <Menu.Item key="main/finance/subscribed/contract">
              <Link to="/main/finance/subscribed/contract"
                onClick={() => this.analytics.trackEvent(this.config.menu, this.config.subscribedDocument + ':' + this.config.contract, this.config.open)}>
                <Icon className="sidebar-icon" component={contractOutgoing} />
                <IntlMessages id="sidebar.subscribed.contract" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/finance/subscribed/purchaseOrder">
              <Link to="/main/finance/subscribed/purchaseOrder"
                onClick={() => this.analytics.trackEvent(this.config.menu, this.config.subscribedDocument + ':' + this.config.purchaseOrder, this.config.open)}>
                <Icon className="sidebar-icon" component={poIncoming} alt="" />
                <IntlMessages id="sidebar.subscribed.purchaseOrder" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/finance/subscribed/invoice">
              <Link to="/main/finance/subscribed/invoice"
                onClick={() => this.analytics.trackEvent(this.config.menu, this.config.subscribedDocument + ':' + this.config.invoice, this.config.open)}>
                <Icon className="sidebar-icon" component={invoiceOutgoing} />
                <IntlMessages id="sidebar.subscribed.invoice" />
              </Link>
            </Menu.Item>
          </SubMenu>}
          {/* <Menu.Item key="main/subscribed/default">
            <Link to="/main/subscribed/default">
              <Icon className="sidebar-icon" component={subscribedDocument} alt="" />
              <IntlMessages id="sidebar.subscribed" />
            </Link>
          </Menu.Item> */}
        </MenuItemGroup>
        {/* <Menu.Item key="main/finance/contacts">
          <Link to="/main/finance/contacts">
            <Icon className="sidebar-icon" component={contact} alt="" />
            <IntlMessages id="sidebar.contacts" />
          </Link>
        </Menu.Item> */}
        {
          Config['customSettings'] === true ? lSalesVisibility : true &&
            <SubMenu key="Logistics Sales" className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <Icon className="sidebar-icon submenu-icon" component={reports} alt="" />
                  <IntlMessages id="Logistic Sales" />
                </span>
              }
            >
              {/* <Menu.Item key="main/finance/sales/rateCard">
              <Link to="/main/finance/sales/rateCard">
                <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                <IntlMessages id="Rate Card" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/finance/sales/transportOrder">
              <Link to="/main/finance/sales/transportOrder"
                onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lSales + ':' + this.config.toOrder, this.config.open)}>
                <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                <IntlMessages id="TO" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/finance/sales/elr">
              <Link to="/main/finance/sales/elr"
                onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lSales + ':' + this.config.elr, this.config.open)}>
                <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                <IntlMessages id="eLR" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/finance/sales/epod">
              <Link to="/main/finance/sales/epod">
                <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                <IntlMessages id="ePoD" />
              </Link>
            </Menu.Item> */}
              {
                Config['customSettings'] === true ? draftInvoicesVisibility : true &&
                  <Menu.Item key="main/finance/sales/draftinvoices">
                    <Link to="/main/finance/sales/draftinvoices"
                      onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lSales + ':' + this.config.draftInvoice, this.config.open)}>
                      <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                      <IntlMessages id={!Config['customSettings'] === true ? "Clearing Details" : "Draft Invoice (CN)"} />
                    </Link>
                  </Menu.Item>
              }
              <Menu.Item key="main/finance/sales/invoice?subType=logistics">
                <Link to="/main/finance/sales/invoice?subType=logistics"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lSales + ':' + this.config.invoice, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="Invoice" />
                </Link>
              </Menu.Item>


              <Menu.Item key="main/finance/sales/debitadvice">
                <Link to="/main/finance/sales/debitadvice"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lSales + ':' + this.config.draftInvoice, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id={"Debit Advice"} />
                </Link>
              </Menu.Item>

              <Menu.Item key="main/finance/sales/cn">
                <Link to="/main/finance/sales/cn"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lSales + ':' + this.config.draftInvoice, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id={"Credit Note"} />
                </Link>
              </Menu.Item>

              {/* <Menu.Item key="main/finance/sales/paymentinit">
              <Link to="/main/finance/sales/paymentinit">
                <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                <IntlMessages id="Payment" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/finance/sales/receipts">
              <Link to="/main/finance/sales/receipts"
                onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lSales + ':' + this.config.receipt, this.config.open)}>
                <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                <IntlMessages id="Receipts" />
              </Link>
            </Menu.Item> */}
            </SubMenu>

        }
        {
          Config['customSettings'] === true ? lPurchasesVisibility : false &&
            <SubMenu key="Logistics Purchases" className={this.getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  <Icon className="sidebar-icon submenu-icon" component={reports} alt="" />
                  <IntlMessages id="Logistic Purchases" />
                </span>
              }
            >
              <Menu.Item key="main/finance/purchases/rateCard">
                <Link to="/main/finance/purchases/rateCard"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lPurchases + ':' + this.config.rateCard, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="Rate Card" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/finance/purchases/transportOrder">
                <Link to="/main/finance/purchases/transportOrder"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lPurchases + ':' + this.config.toOrder, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="TO" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/finance/purchases/elr">
                <Link to="/main/finance/purchases/elr"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lPurchases + ':' + this.config.elr, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="eLR" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/finance/purchases/epod">
                <Link to="/main/finance/purchases/epod">
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="ePoD" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/finance/purchases/draftinvoices">
                <Link to="/main/finance/purchases/draftinvoices"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lPurchases + ':' + this.config.draftInvoice, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="Draft Invoices(CN)" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/finance/purchases/invoice?subType=logistics">
                <Link to="/main/finance/purchases/invoice?subType=logistics"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lPurchases + ':' + this.config.invoice, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="Invoice" />
                </Link>
              </Menu.Item>

              <Menu.Item key="main/finance/purchases/paymentinit">
                <Link to="/main/finance/purchases/paymentinit"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lPurchases + ':' + this.config.payment, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="Payment" />
                </Link>
              </Menu.Item>
              <Menu.Item key="main/finance/purchases/receipts">
                <Link to="/main/finance/purchases/receipts"
                  onClick={() => this.analytics.trackEvent(this.config.menu, this.config.lPurchases + ':' + this.config.receipt, this.config.open)}>
                  <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
                  <IntlMessages id="Receipts" />
                </Link>
              </Menu.Item>
            </SubMenu>

        }

        {Config['customSettings'] === true ? catalogueVisibility : false && <SubMenu key="Catalogue" className={this.getNavStyleSubMenuClass(navStyle)}
          title={
            <span>
              <Icon className="sidebar-icon submenu-icon" component={item} alt="" />
              <IntlMessages id="sidebar.catalogue" />
            </span>}>
          <Menu.Item key="main/finance/items">
            <Link to="/main/finance/items"
              onClick={() => this.analytics.trackEvent(this.config.menu, this.config.catalogue + ':' + this.config.master, this.config.open)}>
              <Icon className="sidebar-icon" component={item} alt="" />
              <IntlMessages id="sidebar.master" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/finance/items/inventory">
            <Link to="/main/finance/items/inventory"
              onClick={() => this.analytics.trackEvent(this.config.menu, this.config.catalogue + ':' + this.config.inventory, this.config.open)}>
              <Icon className="sidebar-icon" component={item} alt="" />
              <IntlMessages id="sidebar.inventory" />
            </Link>
          </Menu.Item>
        </SubMenu>}
        {Config['customSettings'] === true ? operationsVisibility : false && <MenuItemGroup key="Operations" className="gx-menu-group" title={<IntlMessages id="sidebar.operations" />}>
          <Menu.Item key="main/operations/invitations">
            <Link to="/main/operations/invitations" onClick={() => this.analytics.trackEvent(this.config.menu, this.config.invitation, this.config.open)}>
              <Badge style={{ backgroundColor: '#6236ff' }}>
                {/* dot={this.state.invitationCount}  */}
                <Icon className="sidebar-icon" component={invitation} alt="" />
                <IntlMessages id="sidebar.operations.invitations" />
                {this.state.invitationCount > 0 &&
                  <span>&nbsp;({this.state.invitationCount})</span>
                }
              </Badge>
            </Link>
          </Menu.Item>
          <Menu.Item key="main/operations/grn/default">
            <Link to="/main/operations/grn/default"
              onClick={() => this.analytics.trackEvent(this.config.menu, this.config.operations + ':' + this.config.grn, this.config.open)}>
              <Icon className="sidebar-icon" component={poIncoming} alt="" />
              <IntlMessages id="sidebar.operations.grn" />
            </Link>
          </Menu.Item>

          <Menu.Item key="main/operations/templates">
            <Link to="/main/operations/templates" onClick={() => this.analytics.trackEvent(this.config.menu, this.config.workflows, this.config.open)}>
              <Icon className="sidebar-icon" component={workflow} />
              <IntlMessages id="sidebar.operations.workflow" />
            </Link>
          </Menu.Item>
          <Menu.Item key="main/operations/workflowbuilder">
            <Link to="/main/operations/workflowBuilder"
              onClick={() => this.analytics.trackEvent(this.config.menu, this.config.workflowBuilder, this.config.open)}>
              <Icon className="sidebar-icon" component={workflowBuilder} alt="" />
              <IntlMessages id="sidebar.operations.workflowbuilder" />
            </Link>
          </Menu.Item>
        </MenuItemGroup>}
        {/* <Menu.Item key="main/operations/queryConsole" className="sidebar__lastMenu">
          <Link to="/main/operations/queryConsole">
            {/* <Icon className="sidebar-icon" component={workflowBuilder} alt="" /> 
            <Icon type="code" className="sidebar-icon" />
            <IntlMessages id="sidebar.operations.queryConsole" />
          </Link>
        </Menu.Item> */}
        {Config['customSettings'] === true ? contactsVisibility : false && <Menu.Item key="main/operations/contacts" style={{ marginBottom: '2rem' }}>
          <Link to="/main/operations/contacts"
            onClick={() => this.analytics.trackEvent(this.config.menu, this.config.contacts, this.config.open)}>
            <Icon className="sidebar-icon" component={contact} alt="" />
            <IntlMessages id="chat.contacts" />
          </Link>
        </Menu.Item>}

        {/* <MenuItemGroup key="Dashboard" className="gx-menu-group" title={<IntlMessages id="sidebar.dashboard" />}>
         
        </MenuItemGroup> */}

      </Menu>
    )
  }

  getSideMenu = () => {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <CustomScrollbars className="gx-layout-sider-scrollbar sidebar_scroll">
        {this.getFinanceMenuItems()}
      </CustomScrollbars>

    )
  }

  renderMainContent() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile />
          </div>
          <div className="sidebar">
            {this.getSideMenu()}
          </div>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, sidebar }) => {
  const { navStyle, themeType, locale, pathname, salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility, draftInvoicesVisibility, reportsVisibility, subscribedDocumentsVisibility, operationsVisibility, catalogueVisibility, contactsVisibility, creditNoteTemplateVisibility } = settings;
  const { irpMode } = sidebar;
  return { navStyle, themeType, locale, pathname, irpMode, salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility, draftInvoicesVisibility, reportsVisibility, subscribedDocumentsVisibility, operationsVisibility, catalogueVisibility, contactsVisibility, creditNoteTemplateVisibility }
};
export default withRouter(connect(mapStateToProps, { setSalesVisibility, setPurchasesVisibility, setLPurchasesVisibility, setLSalesVisibility, setDraftInvoicesVisibility, setReportsVisibility, setOperationsVisibility, setSubscribedDocumentsVisibility, setCatalogueVisibility, setContactsVisibility, setCreditNoteTemplateVisibility })(SidebarContent));

