import Parser from "./index";
import PRM2JsonLd from '../parsers/prm-jsonld';
import Progress from '../../progress';
import FileErrors from "./errors";

class PrmFileParser extends Parser {
    parse(pathObject, callback) {
        return new Promise((resolve, reject) => {
            return super.parse(pathObject, {
                onProgress: (progress) => {
                    callback.onProgress(progress);
                },
                onStatus: (statusObject) => {
                    callback.onStatus(statusObject);
                },
                onData: (data) => {
                    this.validate(data, callback, resolve, reject);
                },
                onFileReading: (fileName) => {

                },
                onFileError: (errorObject) => {
                    callback.onAbort(errorObject);
                    reject(errorObject);
                },
                onFileAbort: (errorObject) => {
                    callback.onAbort(errorObject);
                    reject(errorObject);
                },
                onError: (errorObject) => {
                    callback.onError(errorObject);
                    reject(errorObject);
                }
            })
        })
    }

    validate(prmText, callback, resolve, reject) {
        callback.onStatus({ msg: `Validating data ${prmText.fileName}..` });
        callback.onProgress(Progress.ON_FILE_VALIDATION);
        let jsonObject;
        try {
            jsonObject = JSON.parse(prmText.data);
        } catch (error) {
            callback.onError(FileErrors.INVALID_FILE_FORMAT);
            callback.onProgress(Progress.ON_COMPLETED);
            callback.onAbort({ fileName: prmText.fileName, error: FileErrors.INVALID_FILE_FORMAT })
            return reject(FileErrors.INVALID_FILE_FORMAT);
        }
        callback.onStatus({ msg: `Importing the data ${prmText.fileName}..` });
        jsonObject = PRM2JsonLd.convert(jsonObject);
        callback.onData({ fileName: prmText.fileName, jsonObjects: jsonObject, resolve, reject })
    }
}
export default PrmFileParser;