
import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
/**
 * ContactManager is an implementation of decentralized contact storage, using this class user can able to save contacts into ParamNetwork. 
 */
class PublicBook {
    /**
     * Default constructor for initialising or establishing the contacts.
     * @param {ParamNetwork} paramNetwork Object
     * 
     */
    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const publicBookManager = require('./public-book.json');

        this.publicBookManagerABI = publicBookManager.abi;
        this.paramNetwork = _paramNetwork;
        this.publicBookManagerContract = Web3_1_0.getContract(this.connection, publicBookManager.abi, contractAddress ? contractAddress : publicBookManager.address);
        this.to = publicBookManager.address;
        // this.publicBookManagerContract = new this.connection.eth.Contract(publicBookManager.abi, contractAddress);
    }

    /** 
     * setPublic method is used for set public key corresponding to paramId.
     * @param {address} address - contact's paramId
     * @param {JSON} options - {from: <PARAM_ADDRESS>}
     * @returns {Promise} promise
     * @example 
     * Usage:
     *  publicBookManager
     *   .setPublic("0x", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    setPublic(publicKey, options) {
        return Web3_1_0.send(this, this.publicBookManagerContract, "setPublic", options, publicKey);
    }

    /**
    * getPublic method is used to get public key of a paramId. 
    * @param {Number} index - contactId 
    * @example
    * Usage:
    *  publicBookManager
    *   .getPublic(<PARAM_ID>)
    *   .then((result)=>{
    *       //TODO 
    *   })
    */
    getPublic(address) {
        /* const promise = new Promise((resolve, reject) => {
            this.publicBookManagerContract.methods.getPublic(address).call(function (error, data) {
                if (error) {
                    return reject(error);
                }
                resolve(data)
            })
        });
        return promise; */
        return Web3_1_0.call(this, this.publicBookManagerContract, "getPublic", null, address);

    }
}
export default PublicBook;