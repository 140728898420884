import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import NetworkBridge from '.';
import GraphQL from '../../param-libs/graph-ql';

class PartPOManager {
    static attachPartPO(docId, jsonLd, txnMode = '1', sync) {
        let paramReceiptManager = GraphQL.getInstance().receipts
        let txnTypeString;
        let transactionData = Utils.getTransactionData(docId);
        return NetworkBridge.getReceiptManager().encryptJsonLd(jsonLd, docId, paramReceiptManager).then(res => {
            let inputObject = {}
            if (txnMode !== "2") {
                inputObject.receipt = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            txnTypeString = Utils.getTxnTypeForGraphQL(txnMode)
            return paramReceiptManager["attachPartPO"](transactionData.id, inputObject, txnTypeString);
        }).then(res => {
            let signedGrnData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedGrnData)
        })
    }

    static attachPartPOSync(docId, jsonLd, buyerId, txnMode = '1') {
        return PartPOManager.attachPartPO(docId, jsonLd, buyerId, txnMode, true);
    }

    static getAllPartPOsByDocId(docId, docStep) {
        return ParamConnector.getInstance().getDB().partPurchaseOrder.getAllPartPOsByDocId(docId, docStep);
    }

    static getReceipt(docId) {
        return ParamConnector.getInstance().getDB().partPurchaseOrder.getReceipt(docId);
    }

    static getReceiptInternalId(docId) {
        return ParamConnector.getInstance().getDB().partPurchaseOrder.getReceiptInternalId(docId);
    }

    static getSummary(docId, predicates) {
        return ParamConnector.getInstance().getDB().partPurchaseOrder.getSummary(docId, predicates);
    }

    static getValidationData(docId) {
        return ParamConnector.getInstance().getDB().partPurchaseOrder.getValidationData(docId);
    }

    static getAllReceiptsByParentV1(docId) {
        return ParamConnector.getInstance().getDB().partPurchaseOrder.getAllReceiptsByParentV1(docId);
    }

    static getItemQuantitySummary(docId) {
        return ParamConnector.getInstance().getDB().partPurchaseOrder.getItemQuantitySummary(docId);
    }

}
export default PartPOManager;