class PartPO {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }
    
    getReceipt(partPOId, predicates) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "getReceipt", partPOId, predicates);
    }

    getReceiptInternalId(partPOId, predicates) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "getReceiptInternalId", partPOId, predicates);
    }

    getSummary(partPOId, predicates) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "getSummary", partPOId, predicates);
    }

    getValidationData(partPOId) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "getValidationData", partPOId);
    }

    getAllPartPOsByDocId(docId, docStep) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "getAllPartPOsByDocId", docId, docStep);
    }

    getAllReceiptsByParentV1(docId) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "getAllReceiptsByParentV1", docId);
    }

    getItemQuantitySummary(docId) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "getItemQuantitySummary", docId);
    }

    attachPartPO(partPOId, docId, jsonLd, status, owner) {
        return this.mongoDBConnector.invoke('partPurchaseOrder', "attachPartPO", partPOId, docId, jsonLd, status, owner);
    }
}

export default PartPO;