// class Analytics {
//     constructor() {
//         if(Analytics.instace){
//             throw new Error("You can’t create object");
//         }
//         this._enabled = false;
//         if(typeof window!=="undefined"){
//             this._enabled = true;
//         }
//     }
//     static getInstance(){
//         if(!Analytics.instance){
//             Analytics.instance = new Analytics();
//         }
//         return Analytics.instance;
//     }
//     setEmailID(email){
//         this.pushTo("setUserId", email);
//     }
//     getHRef(href){
//         if(!href && this._enabled){
//             href = window.location.href;
//         }
//         if(href){
//             href = href.replace("#/","");
//         }
//         return href
//     }
//     trackLink(href, linkType="link"){
//         href = this.getHRef(href)
//         this.pushTo("trackLink", href, linkType);
//     }
//     trackEvent(category, action, name, value, options){
//         if(category && action){
//             this.track(["trackEvent", category, action, name, value], options);
//         }
//     }
//     /**
//      *
//      * @param {*} name
//      * @param {*} options
//      *
//      */
//     trackPageview(documentTitle, href, customDimensions){
//         this.pushToLink(href, "trackPageView");
//         this.pushCustomDimensions(customDimensions);
//         if(documentTitle){
//             this.pushTo("setDocumentTitle",documentTitle);
//         }
//         if(documentTitle){
//             this.pushTo("setDocumentTitle",documentTitle);
//         }
//     }
//     track(data, options){
//         if(options && options.documentTitle){
//             this.pushTo("setDocumentTitle", options.documentTitle)
//         }
//         this.pushCustomDimensions(options)
//         if(data){
//             this.pushTo(...data);
//         }
//     }
//     pushCustomDimensions(options){
//         if (options && options.customDimensions && Array.isArray(options.customDimensions) && options.customDimensions.length) {
//             for(let index in options.customDimensions){
//                 let customDimension = options.customDimensions[index];
//                 if(!customDimension.id){
//                     continue;
//                 }
//                 this.pushTo("setCustomDimension", customDimension.id, customDimension.value);
//             }
//         }
//     }
//     pushToLink(href, name, ...linkArgs){
//         let args = []
//         if(linkArgs && linkArgs.length > 0){
//             args = [...linkArgs];
//         }
//         this.pushTo(name, args);
//         href = this.getHRef(href)
//         if(href){
//             this.pushTo("setCustomUrl", [href])
//         }
//     }
//     pushTo(name, args){
//         if(!this._enabled){
//             return;
//         }
//         window._paq.push([name, ...args])
//     }
// }
// export default Analytics;
const qs = require('querystring');
class Analytics {
    constructor() {
        if(Analytics.instace){
            throw new Error("You can't create object");
        }
        this._enabled = false;
        // if(typeof window!=="undefined"){
        //     this._enabled = true;
        // }
    }
    static getInstance(){
        if(!Analytics.instance){
            Analytics.instance = new Analytics();
        }
        if(this._enabled){
            return this.matomo
        }
        return Analytics.instance;
    }
    initMotomo(siteID, url){
        this.url = url;
        this.siteId = siteID;
        this._enabled = true;
    }
    startSession(){
    }
    endSession(){
    }
    setEmailID(email){
        this.userId = email;
    }
    getHRef(href){
        if(!href && this._enabled){
            href = window.location.href;
        }
        if(href){
            href = href.replace("#/","");
        }
        return href
    }
    trackLink(href, linkType="link", documentTitle){
        href = this.getHRef(href);
        let body = {
            url:href,
            link: linkType
        }
        if(documentTitle){
            body.action_name = documentTitle;
        }
        return this.pushTo(body)
    }
    trackEvent(category, name, action="click", value, options){
        if(!category){
            return;
        }
        let eventObject = {};
        eventObject.e_c = category;
        eventObject.e_a = action;
        if(name){
            eventObject.e_n = name
        }
        if(Number.isNaN(value)){
            value = 0;
        }
        eventObject.e_v = value;
        this.pushTo(eventObject);
    }
    /**
     * 
     * @param {*} name 
     * @param {*} options 
     * 
     */
    trackPageview(documentTitle, href, customDimensions){
        href = this.getHRef(href);
        let body = {
            url:href
        }
        if(documentTitle){
            body.action_name = documentTitle;
        }
        return this.pushTo(body)
    }
    trackBulk(data, options){
    }
    pushTo(body, options){
        if(!body){
            return
        }
        body.idsite = this.siteId;
        body.rec = 1;
        if(this.userId){
            body.uid = this.userId;
        }
        body.rand = new Date().getTime();
        const url = this.url+"?"+qs.stringify(body)
        return fetch(url,{
            method: 'GET',
        }).catch(e=>{
            console.log("Unable to track the event, Reason: ", e);
        })
    }
}
export default Analytics;