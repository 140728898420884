class ePhoneBook {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getAllEnterpriseNodes() {
        return this.mongoDBConnector.invoke('ePhoneBook', 'getAllEnterpriseNodes');
    }

    getAllMasterNodes() {
        return this.mongoDBConnector.invoke('ePhoneBook', 'getAllMasterNodes');
    }

    getAllNodes() {
        return this.mongoDBConnector.invoke('ePhoneBook', 'getAllNodes');
    }

    getNodeIdFromOrganizationId(organizationId) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'getNodeIdFromOrganizationId', organizationId);
    }

    getNodeDetailsByParamId(enterpriseParamId) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'getNodeDetailsByParamId', enterpriseParamId);
    }

    _getNodeInfo(nodeId) {
        return this.mongoDBConnector.invoke('ePhoneBook', '_getNodeInfo', nodeId);
    }

    getNodeDetailsById(nodeId) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'getNodeDetailsById', nodeId);
    }

    addEnterpriseNode(nodeId, paramId, organizationId, config, metaData) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'addEnterpriseNode', nodeId, paramId, organizationId, config, metaData);
    }

    addMasterNode(nodeId, organizationId, config, metaData) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'addMasterNode', nodeId, organizationId, config, metaData);
    }

    updateEnterpriseNode(nodeId, paramId, organizationId, config, metaData) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'updateEnterpriseNode', nodeId, paramId, organizationId, config, metaData);
    }

    updateMasterNode(nodeId, organizationId, config, metaData) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'updateMasterNode', nodeId, organizationId, config, metaData);
    }

    doesExist(id) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'doesExist', id);
    }

    isEnterpriseNode(paramId) {
        return this.mongoDBConnector.invoke('ePhoneBook', 'isEnterpriseNode', paramId);
    }
}

export default ePhoneBook;
