import { gql } from '@apollo/client';
import * as Utils from '../../../../src/util/utils';

class Items {
    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }

    //query

    getSubscriber(sId) {
        let options = {
            query: gql`
          query getCatalogueMasterSubscriber ($sId: String!) {
            getCatalogueMasterSubscriber(sID: $sId){
                subscriberParamID,
                shareKey,
                status,
                subscriberID
                }
            }`,
            variables: { sId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getCatalogueMasterSubscriber from graphQL"
                throw new Error(error)
            }
            let itemResult = {}
            itemResult['subscriber'] = graphQLResult.data.getCatalogueMasterSubscriber['subscriberParamID']
            itemResult['shareKey'] = graphQLResult.data.getCatalogueMasterSubscriber['shareKey']
            itemResult['status'] = graphQLResult.data.getCatalogueMasterSubscriber['status']
            itemResult['subscriberId'] = graphQLResult.data.getCatalogueMasterSubscriber['subscriberID']
            return itemResult
        })
    }

    getTotalNumberOfItems(primaryOwner, startIndex = 0, pageSize = 10) {
        let totalItems = 0
        let options = {
            query: gql`
            query getAllCatalogueMasters ($filterOptions: InputFilterOptions!){
                getAllCatalogueMasters(filterOptions: $filterOptions){
                    totalRecords                }
            }`,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllContacts from graphQL"
                throw new Error(error)
            }
            totalItems = graphQLResult.data.getAllCatalogueMasters["totalRecords"]
            return totalItems;
        })
    }

    getAllCatalogues(primaryOwner, startIndex = 0, pageSize = 10) {
        let itemIds = [], promiseArray = []
        return this.getTotalNumberOfItems(primaryOwner).then(totalRecords => {
            let query = gql`
            query getAllCatalogueMasters ($filterOptions: InputFilterOptions!){
                getAllCatalogueMasters(filterOptions: $filterOptions){
                    IDs
                }
            }`
            let batch = Math.ceil(totalRecords / pageSize);
            for (let index = 0; index < batch; index++) {
                startIndex = index;
                promiseArray.push(
                    this.apolloClientConnector.query({ query, variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } } }).then(graphQLResult => {
                        if (!graphQLResult || !graphQLResult.data) {
                            let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllCatalogueMasters from graphQL"
                            throw new Error(error)
                        }
                        itemIds = itemIds.concat(graphQLResult.data.getAllCatalogueMasters['IDs'])
                    })
                )
            }
            return Promise.all(promiseArray)
        }).then(res => {
            return itemIds
        })
    }

    getCatalogue(itemId) {
        let options = {
            query: gql`
          query getCatalogueMaster ($itemId : String!) {
            getCatalogueMaster(ID: $itemId){
                MasterID,
                JSONLd,
                c_type,
                key,
                subscriberIDs,
                inventoryContractAddress,
                metaData,
                linkedMasterIDs,
                txnMode,
                owner,
                }
            }`,
            variables: { itemId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getCatalogueMaster from graphQL"
                throw new Error(error)
            }
            let itemResult = {}
            itemResult['MasterID'] = graphQLResult.data.getCatalogueMaster['MasterID']
            itemResult['inventoryContractAddress'] = graphQLResult.data.getCatalogueMaster['inventoryContractAddress']
            itemResult['subscriberIDs'] = graphQLResult.data.getCatalogueMaster['subscriberIDs']
            itemResult['metaData'] = graphQLResult.data.getCatalogueMaster['metaData']
            itemResult['linkedMasterIDs'] = graphQLResult.data.getCatalogueMaster['linkedMasterIDs']
            itemResult['owner'] = graphQLResult.data.getCatalogueMaster['owner']
            itemResult['c_type'] = graphQLResult.data.getCatalogueMaster['c_type']
            itemResult["catalogueKey"] = graphQLResult.data.getCatalogueMaster["key"]
            itemResult["JSONLd"] = graphQLResult.data.getCatalogueMaster["JSONLd"]
            itemResult['catalogueTxnMode'] = Utils.convertGraphQLTnxType(graphQLResult.data.getCatalogueMaster["txnMode"]);
            return itemResult
        })
    }

    getLinkedCatalogueMasters(catalogueId) {
        let options = {
            query: gql`
            query getLinkedCatalogueMasters ($catalogueId : String!) {
                getLinkedCatalogueMasters(catalogueID: $catalogueId){
                    totalRecords,
                    IDs,
                    currentPageSize
                }
            }`,
            variables: { catalogueId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let itemResult = {
                items: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getLinkedCatalogueMasters from graphQL"
                throw new Error(error)
            }
            itemResult["items"] = graphQLResult.data.getLinkedCatalogueMasters['IDs']
            itemResult["count"] = graphQLResult.data.getLinkedCatalogueMasters["totalRecords"]
            return itemResult;
        })
    }

    getLinkedCatalogueMasterInfo(catalogueId) {
        let options = {
            query: gql`
            query getLinkedCatalogueMasterInfo ($catalogueId : String!) {
                getLinkedCatalogueMasterInfo(id: $catalogueId}){
                    linkedCatalogMasterID,
                    ownerCatalogMasterID,
                    receivedCatalogMasterID,
                    status
                }
            }`,
            variables: { catalogueId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getLinkedCatalogueMasterInfo from graphQL"
                throw new Error(error)
            }
            return graphQLResult.data.getLinkedCatalogueMasterInfo;
        })
    }

    getAllSubscribers(catalogueId) {
        let options = {
            query: gql`
            query getAllCatalogueMasterSubscribers ($catalogueId : String!) {
                getAllCatalogueMasterSubscribers(catalogueID: $catalogueId){
                    totalRecords,
                    IDs,
                    currentPageSize
                }
            }`,
            variables: { catalogueId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let itemResult = {
                items: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllCatalogueMasterSubscribers from graphQL"
                throw new Error(error)
            }
            // itemResult["items"] = graphQLResult.data.getAllCatalogueMasterSubscribers['IDs']
            // itemResult["count"] = graphQLResult.data.getAllCatalogueMasterSubscribers["totalRecords"]
            return graphQLResult.data.getAllCatalogueMasterSubscribers['IDs'];
        })
    }

    getCatalogueMasterSubscriber(catalogueId) {
        let options = {
            query: gql`
            query getCatalogueMasterSubscriber ($catalogueId : String!) {
                getCatalogueMasterSubscriber(sID: $catalogueId){
                    subscriberParamID,
                    shareKey,
                    status
                }
            }`,
            variables: { catalogueId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getCatalogueMasterSubscriber from graphQL"
                throw new Error(error)
            }
            return graphQLResult.data.getCatalogueMasterSubscriber

        })
    }

    getAllInventories(primaryOwner, startIndex = 0, pageSize = 10) {
        let options = {
            query: gql`
            query getAllInventories($filterOptions: InputFilterOptions!) {
                getAllInventories(filterOptions: $filterOptions){
                    totalRecords,
                    IDs,
                    currentPageSize
                }
            }`,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }

        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllInventories from graphQL"
                throw new Error(error)
            }
            return graphQLResult.data.getAllInventories

        })
    }

    getInventoryByCatalogueID(itemId) {
        let options = {
            query: gql`
            query getInventoryByCatalogueID ($itemId: String!){
                getInventoryByCatalogueID(catalogueID: $itemId){
                    inventoryID,
                    inventoryData,
                    inventoryDataTxnMode,
                    inventoryKey,
                    metaData,
                    sID,
                    ownerParamId,
                    itemID
                }                   
            }`,
            variables: { itemId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getInventoryByCatalogueID from graphQL"
                throw new Error(error)
            }
            let inventoryRes = {}
            inventoryRes['inventoryId'] = graphQLResult.data.getInventoryByCatalogueID['inventoryID'] //without contractAddress
            inventoryRes['inventoryDataTxnMode'] = Utils.convertGraphQLTnxType(graphQLResult.data.getInventoryByCatalogueID['inventoryDataTxnMode'])
            inventoryRes['inventoryKey'] = graphQLResult.data.getInventoryByCatalogueID['inventoryKey']
            inventoryRes['metaData'] = graphQLResult.data.getInventoryByCatalogueID['metaData']
            inventoryRes['sID'] = graphQLResult.data.getInventoryByCatalogueID['sID']
            inventoryRes['owner'] = graphQLResult.data.getInventoryByCatalogueID['ownerParamId']
            inventoryRes['itemId'] = graphQLResult.data.getInventoryByCatalogueID['itemID']
            inventoryRes['inventoryData'] = graphQLResult.data.getInventoryByCatalogueID['inventoryData']
            return inventoryRes
        })
    }

    getInventoryByInventoryID(inventoryId) {
        let options = {
            query: gql`
           query getInventoryByInventoryID ($inventoryId: String!) {
                getInventoryByInventoryID(inventoryID: $inventoryId){
                    inventoryID,
                    inventoryData,
                    inventoryDataTxnMode,
                    inventoryKey,
                    metaData,
                    sID,
                    ownerParamId,
                    itemID
                }                   
            }`,
            variables: { inventoryId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let inventoryRes = {}
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getInventoryByInventoryID from graphQL"
                throw new Error(error)
            }
            inventoryRes = graphQLResult.data.getInventoryByInventoryID
            inventoryRes['@id'] = graphQLResult.data.getInventoryByInventoryID['inventoryID']
            inventoryRes['inventoryTxnType'] = graphQLResult.data.getInventoryByInventoryID['inventoryDataTxnMode']
            inventoryRes['inventoryShareKey'] = graphQLResult.data.getInventoryByInventoryID['inventoryKey']
            return inventoryRes;
        })
    }

    getInventoryForSubscriber(catalogueId, paramId) {
        let options = {
            query: gql`
           query getInventoryForSubscriber ($catalogueId : String!, $paramId: String!) {
                getInventoryForSubscriber(cataloguesID: $catalogueId, paramID: $paramId){
                    inventoryID,
                    inventoryData,
                    inventoryDataTxnMode,
                    inventoryKey,
                    metaData,
                    sID,
                    ownerParamId,
                    itemID
                }                   
            }`,
            variables: { catalogueId, paramId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let inventoryRes = {}
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getInventoryForSubscriber from graphQL"
                throw new Error(error)
            }
            inventoryRes = graphQLResult.data.getInventoryForSubscriber
            inventoryRes['@id'] = graphQLResult.data.getInventoryForSubscriber['inventoryID']
            inventoryRes['inventoryTxnType'] = graphQLResult.data.getInventoryForSubscriber['inventoryDataTxnMode']
            inventoryRes['inventoryShareKey'] = graphQLResult.data.getInventoryForSubscriber['inventoryKey']
            return inventoryRes;
        })
    }

    // getAllInventorySubscribers(inventoryID) {
    //     //[string!]
    //     let options = {
    //         query: gql`
    //        query {
    //             getAllInventorySubscribers(inventoryID:"${inventoryID}"){

    //             }                   
    //         }`
    //     }
    //     return this.apolloClientConnector.query(options).then(graphQLResult => {
    //         let inventoryRes = {}
    //         if (!graphQLResult || !graphQLResult.data) {
    //             let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllInventorySubscribers from graphQL"
    //             throw new Error(error)
    //         }
    //         inventoryRes = graphQLResult.data.getAllInventorySubscribers
    //         return inventoryRes;
    //     })
    // }

    //mutation

    acceptItems(buyerId, sellerId, buyerItemsIds, sellerItemsIds) {
        let options = {
            mutation: gql`
          mutation   acceptItems($buyerId: String!, $sellerId: String!, $buyerItemsIds: [String!]!, $sellerItemsIds: [String!]!) {
                acceptItems(buyerID: $buyerId, sellerID: $sellerId, buyerItemsIDs: $buyerItemsIds, sellerItemsIDs: $sellerItemsIds)
            }`,
            variables: { buyerId, sellerId, buyerItemsIds, sellerItemsIds }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.acceptItems
        })
    }

    rejectItems(buyerId, sellerId, buyerItemsIds, sellerItemsIds) {
        let options = {
            mutation: gql`
         mutation    rejectItems($buyerId: String!, $sellerId: String!, $buyerItemsIds: [String!]!, $sellerItemsIds: [String!]!) {
                rejectItems(buyerID: $buyerId, sellerID: $sellerId, buyerItemsIDs: $buyerItemsIds, sellerItemsIDs: $sellerItemsIds)
            }`,
            variables: { buyerId, sellerId, buyerItemsIds, sellerItemsIds }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectItems
        })
    }


    addCatalogue(inputObject, catalogueType, catalogueTxnMode, catalogueKey, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation addCatalogue($inputObject: InputCatalogueInfo!, $catalogueType: ITEM_TYPE!, $catalogueTxnMode: TRANSACTION_TYPE!, $catalogueKey: String, $metaData: String) {
                addCatalogue(inputObject: $inputObject, catalogueType: $catalogueType, catalogueTxnMode: $catalogueTxnMode, catalogueKey: $catalogueKey, metaData: $metaData)            
            }`,
            variables: { inputObject, catalogueType, catalogueTxnMode, catalogueKey, metaData }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addCatalogue
        })
    }

    addCatalogueV1(catalogueId, inputObject, catalogueType, catalogueTxnMode, catalogueKey, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation addCatalogueV1($catalogueId: String!, $inputObject: InputCatalogueInfo!, $catalogueType: ITEM_TYPE!, $catalogueTxnMode: TRANSACTION_TYPE!, $catalogueKey: String, $metaData: String ){
                addCatalogueV1(cataloguesID: $catalogueId, inputObject: $inputObject, catalogueType: $catalogueType ,catalogueTxnMode: $catalogueTxnMode, catalogueKey: $catalogueKey, metaData: $metaData)            
            }`,
            variables: { catalogueId, inputObject, catalogueType, catalogueTxnMode, catalogueKey, metaData }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data || !res.data.addCatalogueV1) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addCatalogueV1
        })
    }

    addInventory(id, inputObject, inventoryKey, inventoryDataTxnMode, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation addInventory($id: String!, $inputObject: InputInventoryInfo!, $inventoryDataTxnMode: TRANSACTION_TYPE!, $inventoryKey: String, $metaData: String) {
                addInventory(id: $id, inputObject: $inputObject, inventoryDataTxnMode: $inventoryDataTxnMode, inventoryKey: $inventoryKey, metaData: $metaData)            
            }`,
            variables: { id, inputObject, inventoryKey, inventoryDataTxnMode, metaData }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addInventory
        })
    }

    addInventoryV1(inventoryId, id, inputObject, inventoryKey, inventoryDataTxnMode, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation addInventoryV1($id: String!, $inventoryId: String!, $inputObject: InputInventoryInfo!, $inventoryDataTxnMode: TRANSACTION_TYPE!, $inventoryKey: String, $metaData: String) {
                addInventoryV1(id: $id, inventoryID: $inventoryId, inputObject: $inputObject, inventoryDataTxnMode: $inventoryDataTxnMode, inventoryKey: $inventoryKey, metaData: $metaData)            
            }`,
            variables: { id, inventoryId, inputObject, inventoryKey, inventoryDataTxnMode, metaData }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addInventoryV1
        })
    }


    updateCatalogue(catalogueId, inputObject, catalogueType, catalogueTxnMode, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation updateCatalogue($catalogueId: String!, $inputObject: InputCatalogueInfo!, $catalogueType: ITEM_TYPE!, $catalogueTxnMode: TRANSACTION_TYPE!, $metaData: String ) {
                updateCatalogue(cataloguesID: $catalogueId, inputObject: $inputObject, catalogueType: $catalogueType, catalogueTxnMode: $catalogueTxnMode, metaData: $metaData)            
            }`,
            variables: { catalogueId, inputObject, catalogueType, catalogueTxnMode, metaData }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateCatalogue
        })
    }

    updateInventory(id, inputObject, inventoryDataTxnMode, metaData, inventoryId) {
        let options;
        options = {
            mutation: gql`
            mutation updateInventory($id: String!, $inventoryId: String!, $inputObject: InputInventoryInfo!, $inventoryDataTxnMode: TRANSACTION_TYPE!, $metaData: String){
                updateInventory(id: $id, inventoryID: $inventoryId, inputObject: $inputObject, inventoryDataTxnMode: $inventoryDataTxnMode, metaData: $metaData)            
            }`,
            variables: { id, inputObject, inventoryDataTxnMode, metaData, inventoryId }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateInventory
        })
    }

    addSubscriberForCatalogue(catalogueId, subscriber, shareKey) {
        let options;
        options = {
            mutation: gql`
            mutation addSubscriberForCatalogue($catalogueId: String!, $subscriber: String!, $shareKey: String!) {
                addSubscriberForCatalogue(cataloguesID: $catalogueId, subscriber: $subscriber, shareKey: $shareKey)            
            }`,
            variables: { catalogueId, subscriber, shareKey }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSubscriberForCatalogue
        })
    }

    addSubscriberForInventory(catalogueId, subscriber, shareKey) {
        let options;
        options = {
            mutation: gql`
            mutation addSubscriberForInventory($catalogueId: String!, $subscriber: String!, $shareKey: String!) {
                addSubscriberForInventory(cataloguesID: $catalogueId, subscriber: $subscriber, shareKey: $shareKey)            
            }`,
            variables: { catalogueId, subscriber, shareKey }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSubscriberForInventory
        })
    }

    updateInventoryHistory(inventoryId, historyIndex, metaData) {
        let options;
        options = {
            mutation: gql`
            mutation updateInventoryHistory($inventoryId: String!, $historyIndex: String!, $metaData: String) {
                updateInventoryHistory(inventoryID: $inventoryId, historyIndex: $historyIndex, metaData: $metaData)            
            }`,
            variables: { inventoryId, historyIndex, metaData }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateInventoryHistory
        })
    }


}
export default Items;