import { gql } from '@apollo/client';
import * as Utils from '../../../../src/util/utils';
const OFF_CHAIN = undefined
class Contacts {

    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }


    getNameAndId(owner, startIndex = 0, pageSize = 10) {
        //not implemented 
        let options = {
            query: gql`{
                    getAllContacts(filterOptions:{dbSource:"${OFF_CHAIN}",startIndex:"${startIndex}",pageSize:"${pageSize}",owner:"${owner}"}){
                    	totalRecords,
                        IDs
                        }
                    }`
        }
        let contactResult = []
        let contactIds = []
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            let promiseArray = []
            contactIds = graphQLResult.data.getAllContacts.IDs
            for (let index in contactIds) {
                promiseArray.push(this.getContactSummary(contactIds[index]))
            }
            return Promise.all(promiseArray).then(res => {
                for (let index in res) {
                    contactResult.push({ _id: res[index]._id, name: res[index].name })
                }
                return contactResult
            })
        })
    }

    getContactByParamIdV1(paramId, owner) {
        let options = {
            query: gql`
            {
                getContactByParamId(id: "${paramId}") {
                        data,
                        contact {
                            paramId
                            name
                            internalId
                            jobTitle
                            taxId
                            telephone
                            email
                            faxNumber
                            occupation
                            organisation
                            streetAddress
                            addressLocality
                            city
                            addressRegion
                            postalCode
                            country
                            address
                            publicKey
                    }
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            contactResult = graphQLResult.data.contact
            contactResult['identifier'] = graphQLResult.data.contact.paramId
            contactResult['hasOccupation'] = graphQLResult.data.contact.occupation
            contactResult['contactPoint'] = {
                name: graphQLResult.data.contact.organisation
            }
            contactResult['nationality'] = {
                name: graphQLResult.data.contact.country
            }
            // contactResult['@id'] = contactId

            delete contactResult['occupation']
            delete contactResult['country']
            //owner, status, 

            return contactResult;
        })


    }

    getContactSummaryByParamId(paramId, owner) {
        let options = {
            query: gql`
            {
                getContactByParamID(paramID: "${paramId}") {
                        contactID,
                        JSONLD,
                    }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {

            }
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            contactResult = JSON.parse(graphQLResult.data.getContactByParamID.JSONLD)
            contactResult['@id'] = graphQLResult.data.getContactByParamID.contactID

            return contactResult;
        })
    }

    getContactSummaryByParamIdV1(paramId, owner) {
        let options = {
            query: gql`
            {
                getContactSummaryByParamId(id: "${paramId}") {
                        data,
                        contact {
                            paramId
                            name
                            internalId
                            jobTitle
                            telephone
                            email
                            occupation
                            address
                            publicKey
                    }
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {

            }
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            contactResult = graphQLResult.data.contact
            // contactResult['_id'] = contactId
            contactResult['identifier'] = graphQLResult.data.contact.paramId
            contactResult['hasOccupation'] = graphQLResult.data.contact.occupation
            delete contactResult['occupation']
            //contactId, _id
            return contactResult;
        })

    }

    getContactNameByParamId(paramId) {
        let options = {
            query: gql`
            {
                getContactByParamID(paramID: "${paramId}") {
                        JSONLD
                    }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return null
            }
            let jsonLd = JSON.parse(graphQLResult.data.getContactByParamID.JSONLD);
            return jsonLd.name
        })

    }

    getContactNameByParamIdV1(paramId) {
        let options = {
            query: gql`
            {
                getContactSummaryByParamId(id: "${paramId}") {
                        data,
                        contact {
                            name
                    }
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return null
            }
            return graphQLResult.data.contact.name;
        })

    }

    getContactByInternalId(internalId, owner, startIndex = 0, pageSize = 10) {
        //not implemented 
        let options = {
            query: gql`{
                    getAllContacts(filterOptions:{dbSource:"${OFF_CHAIN}",startIndex:"${startIndex}",pageSize:"${pageSize}",owner:"${owner}"}){
                    	totalRecords,
                        IDs
                        }
                    }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                return null
            }
            let contactIds = graphQLResult.data.getAllContacts.IDs
            let promiseArray = []
            for (let index in contactIds) {
                promiseArray.push(this.getContactSummary(contactIds[index]))
            }
            return Promise.all(promiseArray).then(res => {
                let identifier
                for (let innerIndex in res) {
                    if (res[innerIndex].internalId === internalId) {
                        identifier = res[innerIndex].paramId
                        break

                    }
                }
                return identifier
            })
        })
    }


    getContactV1(contactId) {
        let options = {
            query: gql`
            {
                getContact(id: "${contactId}") {
                        data,
                        contact {
                            paramId
                            name
                            internalId
                            jobTitle
                            taxId
                            telephone
                            email
                            faxNumber
                            occupation
                            organisation
                            streetAddress
                            addressLocality
                            city
                            addressRegion
                            postalCode
                            country
                            address
                            publicKey
                    }
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {

            }
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            contactResult = graphQLResult.data.contact
            contactResult['identifier'] = graphQLResult.data.contact.paramId
            contactResult['hasOccupation'] = graphQLResult.data.contact.occupation
            contactResult['contactPoint'] = {
                name: graphQLResult.data.contact.organisation
            }
            contactResult['nationality'] = {
                name: graphQLResult.data.contact.country
            }
            contactResult['@id'] = contactId

            delete contactResult['occupation']
            delete contactResult['country']
            //owner, status, 

            return contactResult;
        })

    }

    getContactSummary(contactId) {
        let options = {
            query: gql`
            {
                getContact(id: "${contactId}") {
                    JSONLD
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {

            }
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            contactResult = JSON.parse(graphQLResult.data.getContact.JSONLD)
            contactResult['@id'] = contactId
            return contactResult;
        })

    }

    getContactSummaryV1(contactId) {
        let options = {
            query: gql`
            {
                getContact(id: "${contactId}") {
                        data,
                        contact {
                            paramId
                            name
                            internalId
                            jobTitle
                            telephone
                            email
                            occupation
                            address
                            publicKey
                    }
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {

            }
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            contactResult = graphQLResult.data.contact
            contactResult['_id'] = contactId
            contactResult['identifier'] = graphQLResult.data.contact.paramId
            contactResult['hasOccupation'] = graphQLResult.data.contact.occupation
            delete contactResult['occupation']
            //contactId, _id
            return contactResult;
        })

    }

    doesExist(contactId) {
        let options = {
            query: gql`
            {
                getContact(contactID: "${contactId}") {
                    contactID
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = false
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            return true
        })

    }

    //GRT METHODS (QUERY)

    getTotalNumberOfContacts(owner) {
        let options = {
            query: gql`
            query getAllContacts($filterOptions: InputFilterOptions!){
                getAllContacts(filterOptions:$filterOptions){
                    	totalRecords
                        }
                    }`,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex: 0, pageSize: 10, owner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let totalNumberOfrecords = 0
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllContacts from graphQL"
                throw new Error(error)
            }
            totalNumberOfrecords = graphQLResult.data.getAllContacts["totalRecords"]
            return totalNumberOfrecords;
        })
    }

    getAllContacts(owner, startIndex = 0, pageSize = 10) {
        let contactIds = [], promiseArray = []
        return this.getTotalNumberOfContacts(owner).then(totalRecords => {
            let query = gql`
            query getAllContacts($filterOptions: InputFilterOptions!){
                getAllContacts(filterOptions:$filterOptions){
                        IDs
                        }
                    }`
            let batch = Math.ceil(totalRecords / pageSize);
            for (let index = 0; index < batch; index++) {
                startIndex = index
                promiseArray.push(
                    this.apolloClientConnector.query({ query, variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner } } }).then(graphQLResult => {
                        if (!graphQLResult || !graphQLResult.data) {
                            let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllContacts from graphQL"
                            throw new Error(error)
                        }
                        contactIds = contactIds.concat(graphQLResult.data.getAllContacts["IDs"])
                    })
                )
            }
            return Promise.all(promiseArray)
        }).then(res => {
            return contactIds
        })
    }

    getContact(contactId) {
        let options = {
            query: gql`
            query getContact($contactId: String!) {
                getContact(contactID:$contactId) {
                        contactID,
                        ownerParamID,
                        contactParamID,
                        JSONLD,
                        txnType,
                        status,
                        invitationIDS
                    }
            }`,
            variables: { contactId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getContact from graphQL"
                throw new Error(error)
            }
            contactResult['contactId'] = graphQLResult.data.getContact.contactID
            contactResult['owner'] = graphQLResult.data.getContact.ownerParamID
            contactResult['contactParamId'] = graphQLResult.data.getContact.contactParamID
            contactResult['JSONLD'] = graphQLResult.data.getContact.JSONLD;
            contactResult['txnType'] = "2"//Utils.convertGraphQLTnxType(graphQLResult.data.getContact.txnType);
            contactResult['status'] = graphQLResult.data.getContact.status.toString();
            contactResult['invitationIds'] = graphQLResult.data.getContact.invitationIDS
            return contactResult;
        })

    }

    getContactByParamId(paramId, owner) {
        let options = {
            query: gql`
            query getContactByParamID($paramId: String!){
                getContactByParamID(paramID: $paramId) {
                        contactID,
                        ownerParamID,
                        contactParamID,
                        JSONLD,
                        txnType,
                        status,
                        invitationIDS
                    }
            }`,
            variables: { paramId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {

            }
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getContactByParamId from graphQL"
                throw new Error(error)
            }
            contactResult['contactId'] = graphQLResult.data.getContact.contactID
            contactResult['owner'] = graphQLResult.data.getContact.ownerParamID
            contactResult['contactParamId'] = graphQLResult.data.getContact.contactParamID
            contactResult['JSONLD'] = graphQLResult.data.getContact.JSONLD;
            contactResult['txnType'] = graphQLResult.data.getContact.txnType.toString();
            contactResult['status'] = graphQLResult.data.getContact.status.toString();
            contactResult['invitationIds'] = graphQLResult.data.getContact.invitationIDS
            return contactResult;
        })
    }

    getContactTimeline(contactId) {
        let options = {
            query: gql`
            query getContactTimeline($contactId: String!) {
                getContactTimeline(contactID: $contactId) {
                    invitationID,
                    contactID,
                    status,
                    receiverParamID,
                    senderParamID,
                    receiverContactID,
                    senderContactID,
                    randomString
                    }
            }`,
            variables: { contactId }
        }


        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let contactResult = {}
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getContactTimeline from graphQL"
                throw new Error(error)
            }
            contactResult = graphQLResult.data.getContactTimeline
            return contactResult;
        })
    }

    //POST METHODS
    addContact(identifier, inputObject, txnType) {
        let options;
        options = {
            mutation: gql`
            mutation addContact( $identifier: String!, $inputObject: InputContactInfo!, $txnType: TRANSACTION_TYPE!) {
                addContact(paramContact: $identifier, inputObject: $inputObject, txnType: $txnType)            
            }`,
            variables: { identifier, inputObject, txnType }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addContact
        })
    }

    addContactV1(contactId, identifier, inputObject, txnType) {
        let options;
        options = {
            mutation: gql`
            mutation addContactV1($contactId: String!, $identifier: String!, $inputObject: InputContactInfo!, $txnType: TRANSACTION_TYPE!) {
                addContactV1(contactID: $contactId, paramContact: $identifier, inputObject: $inputObject, txnType: $txnType)            
            }`,
            variables: { contactId, identifier, inputObject, txnType }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addContactV1
        })
    }

    updateContact(contactId, inputObject, txnType) {
        let options;
        options = {
            mutation: gql`
            mutation updateContact($contactId: String!, $inputObject: InputContactInfo!, $txnType: TRANSACTION_TYPE!){
                updateContact(contactID: $contactId, inputObject: $inputObject, txnType: $txnType)            
            }`,
            variables: { contactId, inputObject, txnType }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateContact
        })
    }
}
export default Contacts