import NetworkBridge from "../../../util/network-bridge";
import { gql } from '@apollo/client';
const OFF_CHAIN = undefined

class Grn {
    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }

    //Query
    // getAllGRNsBySeller(filterOptions: InputFilterOptions!): Result!
    // Receipt

    // getAllGRNsByBuyer(filterOptions: InputFilterOptions!): Result!



    getAllGRNsBySeller(primaryOwner, startIndex = 0, pageSize = 10) {
        let options = {
            query: gql`
             query getAllGRNsBySeller(filterOptions: InputFilterOptions!) {
                getAllGRNsBySeller(filterOptions: $filterOptions){
                    totalRecords,
                    IDs
                }
            }`,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }
        }

        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let grnRes = {
                grns: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllGRNsBySeller from graphQL"
                throw new Error(error)
            }
            grnRes["grns"] = graphQLResult.data.getAllGRNsBySeller['IDs']
            grnRes["count"] = graphQLResult.data.getAllGRNsBySeller['totalRecords']
            return grnRes
        })
    }

    getAllGRNsByBuyer(primaryOwner, startIndex = 0, pageSize = 10) {
        let options = {
            query: gql`
            query getAllGRNsByBuyer(filterOptions: InputFilterOptions!) {
                getAllGRNsByBuyer(filterOptions:$filterOptions){
                    totalRecords,
                    IDs
                }
            }`,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: primaryOwner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let grnRes = {
                grns: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getAllGRNsByBuyer from graphQL"
                throw new Error(error)
            }
            grnRes["grns"] = graphQLResult.data.getAllGRNsByBuyer['IDs']
            grnRes["count"] = graphQLResult.data.getAllGRNsByBuyer['totalRecords']
            return grnRes
        })
    }

    //Muatation
    attachGRN(docId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation attachGRN($docId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                attachGRN(docID: $docId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { docId, inputObject, txnMode }

        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachGRN
        });
    }

    attachGRNV1(index, docId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation attachGRNV1($index: String!, $docId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                attachGRNV1(index: $index, docID: $docId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { index, docId, inputObject, txnMode }

        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachGRNV1
        });
    }

    assignGRN(docId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation assignGRN($docId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE! ) {
                assignGRN(docID: $docId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { docId, inputObject, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.assignGRN
        });
    }

    updateGRN(grnId, inputObject, txnMode) {
        let options;
        options = {
            mutation: gql`
            mutation updateGRN($grnId: String!, $inputObject: InputDocumentInfo!, $txnMode: TRANSACTION_TYPE!) {
                updateGRN(grnID: $grnId, inputObject: $inputObject, txnMode: $txnMode)            
            }`,
            variables: { grnId, inputObject, txnMode }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateGRN
        });
    }

    closeGRN(docId) {
        let options;
        options = {
            mutation: gql`
            mutation closeGRN($docId: String!) {
                closeGRN(docID: $docId)            
            }`,
            variables: { docId }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.closeGRN
        });
    }

    closeGRNs(docIDs, rIds) {
        let options;
        options = {
            mutation: gql`
            mutation closeGRNs($docIDs: [String!]!, $rIds: [String!]!) {
                closeGRNs(docIDs: $docIDs, rIds: $rIds)            
            }`,
            variables: { docIDs, rIds }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.closeGRNs
        });
    }

    closeExternalGRN(grnIDs, rIds) {
        let options;
        options = {
            mutation: gql`
            mutation closeExternalGRN($grnIDs: [String!]!, $rIds: [String!]!) {
                closeExternalGRN(grnIDs: $grnIDs, rIds: $rIds)            
            }`,
            variables: { grnIDs, rIds }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.closeExternalGRN
        });
    }

}
export default Grn;