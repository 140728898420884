import { gql } from '@apollo/client';
import * as NetworkUtils from '../../../../src/util/utils';

class Consensus {

    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }


    getTransaction(transactionId) {
        let options = {
            query: gql`
            query getTransaction($transactionId: String!){
                getTransaction(transactionID: $transactionId){
                    receiptId,
                    gasPrice,
                    txnID,
                    datetime,
                    Status,
                    Step,
                    note,
                    isNew
                    }
                }
            `,
            variables: { transactionId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getTransaction
        })
    }

    getAllTemplateConsensus(owner, startIndex = 0, pageSize = 10) {
        let options = {
            query: gql`{
                getAllTemplateConsensus(filterOptions:{dbSource:"OFF_CHAIN",startIndex:"${startIndex}", pageSize:"${pageSize}", owner:"${owner}"}){
                      totalRecords,
                      IDs
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let allTemplateConsensusResult = {
                count: 0,
                templateConsIds: []
            };
            if (!graphQLResult || !graphQLResult.data) {
                return allTemplateConsensusResult;
            }
            allTemplateConsensusResult.count = graphQLResult.data["getAllTemplateConsensus"].totalRecords
            allTemplateConsensusResult.templateConsIds = graphQLResult.data["getAllTemplateConsensus"].IDs
            return allTemplateConsensusResult
        })
    }

    getTemplateConsensus = (templateConsId) => {
        // diff in editor & abi
        let options = {
            query: gql`{
                getTemplateConsensus(templateConsID:"${templateConsId}"){
                    templateID,
                    templateConsID,
                    owner,
                    docID,
                    docIDs,
                    docStep,
                    docContractAddress,
                    templateContractAddress,
                    stepConsensusIDS,
                    documentSubscribers,
                    transactionType,
                    mergeID,
                    isCompleted,
                    currentStep
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let templateConsensusResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return templateConsensusResult;
            }
            templateConsensusResult = graphQLResult.data["getTemplateConsensus"]
            return templateConsensusResult
        })

    }

    getTemplateConsensusV2 = (templateConsId) => {
        let options = {
            query: gql`{
                getTemplateConsensusV2(templateConsID:"${templateConsId}"){
                    templateConsID,
                    owner,
                    docID,
                    docIDs,
                    docStep,
                    docContractAddress,
                    templateID,
                    templateContractAddress,
                    stepConsensusIDS,
                    documentSubscribers,
                    transactionType,
                    mergeID,
                    isCompleted,
                    currentStep
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let templateConsensusResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return templateConsensusResult;
            }
            // templateConsensusResult = graphQLResult.data["getTemplateConsensusV2"]
            templateConsensusResult.templateConsId = graphQLResult.data["getTemplateConsensusV2"].templateConsID
            templateConsensusResult.owner = graphQLResult.data["getTemplateConsensusV2"].owner
            templateConsensusResult.docId = graphQLResult.data["getTemplateConsensusV2"].docID
            templateConsensusResult.docIds = graphQLResult.data["getTemplateConsensusV2"].docIDs
            templateConsensusResult.docStep = graphQLResult.data["getTemplateConsensusV2"].docStep
            templateConsensusResult.docContractAddress = graphQLResult.data["getTemplateConsensusV2"].docContractAddress
            templateConsensusResult.templateId = graphQLResult.data["getTemplateConsensusV2"].templateID
            templateConsensusResult.templateContractAddress = graphQLResult.data["getTemplateConsensusV2"].templateContractAddress
            templateConsensusResult.stepSigners = graphQLResult.data["getTemplateConsensusV2"].stepConsensusIDS
            templateConsensusResult.documentSubscribers = graphQLResult.data["getTemplateConsensusV2"].documentSubscribers
            templateConsensusResult.transactionType = NetworkUtils.convertGraphQLTnxType(graphQLResult.data["getTemplateConsensusV2"].transactionType)
            templateConsensusResult.mergeId = graphQLResult.data["getTemplateConsensusV2"].mergeID
            templateConsensusResult.isCompleted = graphQLResult.data["getTemplateConsensusV2"].isCompleted
            templateConsensusResult.currentStep = graphQLResult.data["getTemplateConsensusV2"].currentStep
            return templateConsensusResult
        })
    }

    getStepSubscribers = (stepConsId) => {
        let options = {
            query: gql`{
                getStepSubscribers(stepConsID:"${stepConsId}"){
                    subscriber,
                    stepKey,
                    subscribeType
                }
           }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let stepSubscribersResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return stepSubscribersResult;
            }
            stepSubscribersResult = Object.assign({}, graphQLResult.data["getStepSubscribers"])
            return stepSubscribersResult
        })
    }

    getAllSubsribeTemplateConsensus = (owner, startIndex = 0, pageSize = 10, totalRecords = []) => {
        let query = gql`
        query getAllSubsribeTemplateConsensus($filterOptions: InputFilterOptions!){
                getAllSubsribeTemplateConsensus(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                }
            }`
        return this.apolloClientConnector.query({ query, variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner } } }).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message);
            }
            totalRecords = totalRecords.concat(graphQLResult.data["getAllSubsribeTemplateConsensus"].IDs)
            if (totalRecords.length === graphQLResult.data["getAllSubsribeTemplateConsensus"].totalRecords) {
                return totalRecords
            }
            return this.getAllSubsribeTemplateConsensus(owner, startIndex + 1, pageSize, totalRecords);
        })
    }

    getSubscriber = (sId) => {
        let options = {
            query: gql`{
                getConsSubscriber(sID:"${sId}"){
                    stepKey,
                    subscriber,
                    subscribeType
                }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let subscriberResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return subscriberResult;
            }
            //subscriberResult = Object.assign({}, graphQLResult.data["getSubscriber"])
            subscriberResult['subscriber'] = graphQLResult.data["getConsSubscriber"]["subscriber"]
            subscriberResult['stepKey'] = graphQLResult.data["getConsSubscriber"]["stepKey"]
            subscriberResult['subscribeType'] = graphQLResult.data["getConsSubscriber"]["subscribeType"]
            return subscriberResult
        })

    }

    getStepConsensus = (stepConsId) => {
        let options = {
            query: gql`{
                getStepConsensus(stepConsID:"${stepConsId}"){
                    stepConsID,
                    jsonLd,
                    owner,
                    metaData,
                    primaryOwner,
                    templateConsID,
                    secondaryOwner,
                    transactionType,
                    subscribers
                  }
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let stepConsensusResult;
            if (!graphQLResult || !graphQLResult.data) {
                return stepConsensusResult;
            }
            stepConsensusResult = Object.assign({}, graphQLResult.data["getStepConsensus"])
            stepConsensusResult.transactionType = NetworkUtils.convertGraphQLTnxType(stepConsensusResult.transactionType);
            return stepConsensusResult
        })
    }

    isStepCompleted = (stepConsId) => {
        let options = {
            query: gql`{
                isStepCompleted(stepConsID:"${stepConsId}")
            }`
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let isStepCompletedResult = false;
            if (!graphQLResult || !graphQLResult.data) {
                return isStepCompletedResult;
            }
            isStepCompletedResult = graphQLResult.data.isStepCompleted
            return isStepCompletedResult
        })
    }
    getAllEventsByConsId = (id) => {
        let eventFilterOptions = {}
        eventFilterOptions.ID = id
        eventFilterOptions.IDType = "RecordID"
        eventFilterOptions.eventType = NetworkUtils.getEventType('templateCons')
        let options;
        options = {
            query: gql`
            query getEventDetails($eventFilterOptions: InputEventFilterOptions!) {
                 getEventDetails(eventFilterOptions: $eventFilterOptions){
                    eventID,
                    recordID,
                    type,
                    json,
                    blockNumber,
                    address,
                    transactionHash,
                    transactionIndex,
                    transactionBlockHash,
                    index,
                    from,
                    createdAt
                }          
            }`,
            variables: { eventFilterOptions }
        }
        return this.apolloClientConnector.query(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.getEventDetails
        })
    }

    getMissingNonce = (paramID) => {
        let options = {
            query: gql`
            query getMissingNonce($paramID: String!){
                getMissingNonce(paramID: $paramID){
                    ParamID,
                    NonceJSON
                }
            }`,
            variables: { paramID }
        }
        return this.apolloClientConnector.query(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.getMissingNonce
        })
    }

    //mutations 
    mergeConsensus = (templateConsIds) => {
        let options;
        options = {
            mutation: gql`
            mutation mergeConsensus($mergeConsIds:[String!]!){
                mergeConsensus(mergeConsIDs:$mergeConsIds)
            }`,
            variables: { templateConsIds }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.mergeConsensus
        })
    }

    attachTemplate = (docInfoAddress, docId, docStep = 1, templateAddress, templateId, stepSigners, txnType) => {
        let options;
        let templateID = NetworkUtils.getTransactionId(templateId, templateAddress)
        let docID = NetworkUtils.getTransactionId(docId, docInfoAddress)
        txnType = NetworkUtils.getTxnTypeForGraphQL(txnType);
        options = {
            mutation: gql`
              mutation attachTemplate($docID: String!,$docStep: Int!,$templateID: String!,$stepSigners: [String!]!,$txnType: TRANSACTION_TYPE!) {
                attachTemplate(
                    docID:$docID,
                    docStep:$docStep,
                    templateID:$templateID,
                    stepSigners:$stepSigners,
                    txnType:$txnType
                  )
              }`,
            variables: { docID, docStep, templateID, stepSigners, txnType }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachTemplate
        })
    }

    attachTemplateForAll = (docInfoAddress, docId, docStep = 1, templateAddress, templateId, stepSigners, txnType) => {
        let templateID = NetworkUtils.getTransactionId(templateId, templateAddress)
        let docID = NetworkUtils.getTransactionId(docId, docInfoAddress)
        let options = {
            mutation: gql`
            mutation attachTemplateForAll($docID: String!,$docStep: Int!,$templateID: String!,$stepSigners: [String!]!,$txnType: TRANSACTION_TYPE!) {
                attachTemplateForAll(
                  docID:$docID,
                  docStep:$docStep,
                  templateID:$templateID,
                  stepSigners:$stepSigners,
                  txnType:$txnType
                )
            }`,
            variables: { docID, docStep, templateID, stepSigners, txnType }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachTemplateForAll
        })
    }

    attachTemplateForAllV2 = (docContractAddress, docIDs, docStep = 1, templateAddress, templateId, stepSigners, txnMode) => {
        let templateID = NetworkUtils.getTransactionId(templateId, templateAddress)
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode)
        let options = {
            mutation: gql`
            mutation    ($docContractAddress: String!,$docIDs: [String!]!,$docStep: Int!,$templateID: String!,$stepSigners: [String!]!,$txnType: TRANSACTION_TYPE!){
                attachTemplateForAllV2(
                    docContractAddress:$docContractAddress,docIDs:$docIDs,docStep:$docStep, templateID:$templateID, stepSigners:$stepSigners, txnType:$txnType)
            }`,
            variables: { docContractAddress, docIDs, docStep, templateID, stepSigners, txnType }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (res.errors || !res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.attachTemplateForAllV2
        })
    }

    signStep = (stepConsId, info, metaData, txnType) => {
        let inputObject = {}
        if (txnType === '2') {
            inputObject.data = info
        }
        else {
            inputObject.workFlowRepo = info
        }
        txnType = NetworkUtils.getTxnTypeForGraphQL(txnType);
        let options = {
            mutation: gql`
            mutation signStep($stepConsId: String!,$inputObject: InputWorkFlowStepInfo!,$metaData: String,$txnType: TRANSACTION_TYPE!) {
                signStep(stepConsID:$stepConsId,inputObject:$inputObject,metaData:$metaData,txnType:$txnType)
            }`,
            variables: { stepConsId, inputObject, metaData, txnType }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.signStep
        })
    }

    shareStepKey = (stepConsId, subscriber, stepKey) => {
        let options = {
            mutation: gql`
            mutation shareStepKey($stepConsId: String!, $subscriber: String!, $stepKey: String!){
                shareStepKey(stepConsID:$stepConsId,subscriber:$subscriber,stepKey:$stepKey)
            }`,
            variables: { stepConsId, subscriber, stepKey }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.shareStepKey
        })
    }

    assignStep = (stepConsId, stepCons) => {
        let options = {
            mutation: gql`
            mutation assignStep($stepConsId: String!, $stepCons: String!) {
                assignStep(
                    stepConsID:$stepConsId,
                    stepCons:$stepCons
                )
            }`,
            variables: { stepConsId, stepCons }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.assignStep
        })
    }

    mergeSign = (mergeConsId, jsonLd, metaData, txnType) => {
        let options = {
            mutation: gql`
                mutation mergeSign($mergeConsId: String!,$inputObject: InputWorkFlowStepInfo!,$metaData: String!, $txnType: TRANSACTION_TYPE!) {
                    mergeSign(mergeConsID:$mergeConsId,inputObject:$inputObject,metaData:$metaData,txnType:$txnType)
                 }`,
            variables: { mergeConsId, jsonLd, metaData, txnType }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.mergeSign
        })
    }

    mergeAssign = (mergeConsId, stepCons) => {
        let options = {
            mutation: gql`
                mutation mergeAssign($mergeConsId: String!, $stepCons: String!) {
                    mergeAssign(mergeConsID:$mergeConsId, stepCons:$stepCons)
                 }`,
            variables: { mergeConsId, stepCons }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.mergeAssign
        })
    }

    resetTemplateCons = (templateConsId) => {
        let options = {
            mutation: gql`
                mutation resetTemplateCons($templateConsId:String!){
                    resetTemplateCons(templateConsID:$templateConsId)
                 }`,
            variables: { templateConsId }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.resetTemplateCons
        })
    }

    resetMergeTemplateCons = (mergeConsId) => {
        let options = {
            mutation: gql`
                mutation resetMergeTemplateCons($mergeConsId:String!){
                    resetMergeTemplateCons(mergeConsId:$mergeConsId)
                }`,
            variables: { mergeConsId }
        }
        return this.apolloClientConnector.mutate(options).then((res) => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.resetMergeTemplateCons
        })
    }

}
export default Consensus;