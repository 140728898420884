import React, { PureComponent } from "react";
import { withRouter } from 'react-router-dom';
import { Modal, Button } from "antd";
import * as Utils from '../../util/utils';

class AcceptInvitation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAcceptFormModal: false,
      showNewUserModal: false,
    }
  }

  componentDidMount() {
    let paramId = Utils.getParamId();
    if (!paramId) {
      this.setState({ showNewUserModal: true });
      return;
    }
    // else {
    //   this.setState({ showAcceptFormModal: true });
    // }
    let urlData = this.props.location.search.split('=')[1];
    urlData = decodeURIComponent(urlData);
    urlData = atob(urlData);
    urlData = JSON.parse(urlData);
    console.log('URLDATA', urlData)
    let invitationId = urlData.invitationId;
    let acceptType = "acceptVendorInvite";
    if (urlData.templateType === "1") {
      acceptType = 'acceptBuyerInvite'
    }
    this.props.history.push({ pathname: `/main/vendormanagement/template/${acceptType}/id=${invitationId}`, state: { data: urlData } })
  }

  navigatoToCreateAccount = () => {
    let urlData = this.props.location.search.split('=')[1];
    urlData = decodeURIComponent(urlData);
    urlData = atob(urlData);
    urlData = JSON.parse(urlData);
    let invitationId = urlData.invitationId;
    let acceptType = "acceptVendorInvite";
    if (urlData.templateType === "1") {
      acceptType = 'acceptBuyerInvite'
    }
    let url = `/main/vendormanagement/template/${acceptType}/id=${invitationId}`
    Utils.setLocalStorage('deeplinkingurl', url)
    Utils.setLocalStorage('deeplinkingData', urlData)
    this.props.history.push('/landing');
  }


  render() {
    return (
      <div>
        <Modal
          visible={this.state.showAcceptFormModal}
          width={800}
          closable={false}
          footer={false}
        >
          <div style={{ minHeight: "80vh" }}>
            Redirecting to Accept Invitation
          </div>
        </Modal>
        <Modal
          visible={this.state.showNewUserModal}
          closable={false}
          title="Not an existing User"
          footer={
            <Button onClick={this.navigatoToCreateAccount} type="primary">
              OK
            </Button>
          }
        >
          You don't have Account created yet, Create an account first
        </Modal>
      </div>
    )
  }

}
export default withRouter(AcceptInvitation);
