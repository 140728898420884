import React, { Component } from 'react';
import { Card, Radio, Row, Col, Input, Button } from 'antd';
import SAP from "../../../assets/images/Sap-Logo.png";
import QB from "../../../assets/images/qb_logo.png";
import TALLY from "../../../assets/images/tally_logo.jpeg";
import ZOHO from "../../../assets/images/zoho-logo.jpg";
import './setting.less';

class Plugins extends Component {

    constructor(props) {
        super(props);
        this.state = {
            plugin: '0'
        }
    }

    selectPlugin = (e) => {
        this.setState({
            plugin: e.target.value
        })
    }

    render() {
        return (<Card className="setting__card" title={<h2 className="setting__nomargin">Plugins</h2>}>
            <div>
                <Radio.Group defaultValue="0" onChange={this.selectPlugin}>
                    <Radio value="0">
                        <span>
                            <img src={SAP}
                                alt="SAP" width="50px" height="40px" />
                        </span>
                    </Radio>
                    <Radio value="1">
                        <span>
                            <img src={ZOHO}
                                alt="zoho" width="50px" height="40px" />
                        </span>
                    </Radio>
                    <Radio value="2">
                        <span className="gx-fs-xxl gx-text-black">
                            ⦃param⦄
                                </span>
                    </Radio>
                    <Radio value="3">
                        <span>
                            <img src={QB}
                                alt="quickbooks" width="40px" height="40px" />
                        </span>
                    </Radio>
                    <Radio value="4">
                        <span>
                            <img src={TALLY}
                                alt="Tally" width="50px" height="40px" />
                        </span>
                    </Radio>
                </Radio.Group> <br /><br />
                <div>
                    {
                        this.props.plugin != 2
                            ?
                            <div>
                                <Row>
                                    <Col span={4}>
                                        <label style={{ marginRight: '1em' }}>Auth key</label>
                                    </Col>
                                    <Col span={12}>
                                        <Input className="settings-input" />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={4}>
                                        <label style={{ marginRight: '1em' }}>Auth Token</label>
                                    </Col>
                                    <Col span={12}>
                                        <Input className="settings-input" />
                                    </Col>
                                </Row>
                                <br />
                                <Button type="primary">Submit</Button>
                            </div>
                            :
                            <div>
                                <Row>
                                    <Col span={4}>
                                        <label style={{ marginRight: '1em' }}>E-Mail</label>
                                    </Col>
                                    <Col span={12}>
                                        <Input onChange={this.setEmail} className="settings-input" />
                                    </Col>
                                </Row>
                                <br />
                            </div>
                    }
                </div>
            </div>
        </Card>
        )
    }
}

export default Plugins;