import ParamConnector from '../../param-connector';
import GraphQL from '../../param-libs/graph-ql';
import * as Utils from '../utils';
import Crypto from 'crypto';

class TemplateRepo {

    static createTemplate(name, title, stepJSONArray, metaData) {
        metaData = JSON.parse(metaData);
        stepJSONArray = JSON.parse(stepJSONArray);
        if (!stepJSONArray || stepJSONArray.length === 0 || (metaData.partiesInvolved.length !== stepJSONArray.length)) {
            throw new Error("Unable to validate inputs.");
        }
        let stepJSONIds = [];
        let createStep = (index) => {
            let stepId = Utils.generateByte64Id()
            return TemplateRepo.createStepV1(stepId, JSON.stringify(stepJSONArray[index]), "").then(stepJSONId => {
                stepJSONIds.push(stepId);
                if (index == stepJSONArray.length - 1) {
                    return stepJSONId;
                }
                index++;
                return createStep(index);
            }).then(stepJSONId => {
                return stepJSONId;
            }).catch(e => {
                console.error("create Step failed, Reason :", e)
                return Promise.reject(e);
            })
        }
        return createStep(0).then(stepJSONId => {
            let templateRepoMananger = GraphQL.getInstance().templateRepo;
            for (let index in metaData.partiesInvolved) {
                metaData.partiesInvolved[index].stepId = stepJSONIds[index];
            }
            return templateRepoMananger.createTemplate(name, title, stepJSONIds, JSON.stringify(metaData)).then((res) => {
                let signedData = Utils.codeSignPaylaod(JSON.parse(res))
                return GraphQL.getInstance().sendRawTxn(signedData)
            })
            // return Utils.getConfig("TemplateRepositoryImpl").then(config => {
            //     let templateRepoManager = ParamConnector.getInstance(config).getNetwork().getTemplateRepoManager(config.address);
            //     let options = Utils.getNetworkOptions();
            //     for (let index in metaData.partiesInvolved) {
            //         metaData.partiesInvolved[index].stepId = stepJSONIds[index];
            //     }
            //     return templateRepoManager.createTemplate(name, title, stepJSONIds, JSON.stringify(metaData), options);
            // })
        })
    }

    static editTemplate(templateId, name, title, metaData) {
        return Utils.getConfig("TemplateRepositoryImpl").then(config => {
            let templateRepoManager = ParamConnector.getInstance(config).getNetwork(config).getTemplateRepoManager(config.address);
            let options = Utils.getNetworkOptions();
            return templateRepoManager.editTemplate(templateId, name, title, metaData, options);
        })
    }

    static createStep(stepJson, metaData) {
        let templateRepoMananger = GraphQL.getInstance().templateRepo;
        return templateRepoMananger.createStep(stepJson, metaData).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })

        // return Utils.getConfig("TemplateRepositoryImpl").then(config => {
        //     let templateRepoManager = ParamConnector.getInstance(config).getNetwork().getTemplateRepoManager(config.address);
        //     let options = Utils.getNetworkOptions();
        //     options.extractId = true;
        //     return templateRepoManager.createStep(stepJson, metaData, options).then(data => {
        //         return data;
        //     });
        // })
    }

    static createStepV1(stepId, stepJson, metaData) {
        let templateRepoMananger = GraphQL.getInstance().templateRepo;
        return templateRepoMananger.createStepV1(stepId, stepJson, metaData).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })
    }

    static editStep(stepId, stepJson, metaData) {
        return Utils.getConfig("TemplateRepositoryImpl").then(config => {
            let templateRepoManager = ParamConnector.getInstance(config).getNetwork(config).getTemplateRepoManager(config.address);
            let options = Utils.getNetworkOptions();
            return templateRepoManager.editStep(stepId, stepJson, metaData, options);
        })
    }

    static addStepsToTemplate(templateId, stepIds) {
        return Utils.getConfig("TemplateRepositoryImpl").then(config => {
            let templateRepoManager = ParamConnector.getInstance(config).getNetwork(config).getTemplateRepoManager(config.address);
            let options = Utils.getNetworkOptions();
            return templateRepoManager.addStepsToTemplate(templateId, stepIds, options);
        })
    }

    static updateStepsToTemplate(templateId, stepIds) {
        return Utils.getConfig("TemplateRepositoryImpl").then(config => {
            let templateRepoManager = ParamConnector.getInstance(config).getNetwork(config).getTemplateRepoManager(config.address);
            let options = Utils.getNetworkOptions();
            return templateRepoManager.updateStepsToTemplate(templateId, stepIds, options);
        })
    }

    static shareTemplate(templateId, paramId) {
        let templateRepoMananger = GraphQL.getInstance().templateRepo;
        let transactionData = Utils.getTransactionData(templateId)
        return templateRepoMananger.shareTemplate(transactionData.id, paramId).then((res) => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })

        // return Utils.getConfig("TemplateRepositoryImpl").then(config => {
        //     let transactionData = Utils.getTransactionData(templateId)
        //     let templateRepoManager = ParamConnector.getInstance(config).getNetwork(config).getTemplateRepoManager(transactionData.address);
        //     let options = Utils.getNetworkOptions();
        //     return templateRepoManager.shareTemplate(transactionData.id, paramId, options);
        // })
    }

    static getAllTemplatesBySubscriber() {
        let paramId = Utils.getParamId();
        let templatesConsensusDB = ParamConnector.getInstance().getDB().templatesConsensusDB;
        return templatesConsensusDB.getAllTemplatesBySubscriber(paramId)
    }

    static getAllInvitations() {
        let paramId = Utils.getParamId();
        let tepmlateRepoDB = ParamConnector.getInstance().getDB().templateRepoDB;
        let options = {
            owner: paramId,
            isInstalled: "false"
        }
        return tepmlateRepoDB.getAllTemplates(options);
    }

    static acceptInvitation(templateId) {
        let tepmlateRepoDB = ParamConnector.getInstance().getDB().templateRepoDB;
        return tepmlateRepoDB.installTemplate(templateId)
    }

    static getAllTemplates() {
        let paramId = Utils.getParamId();
        let tepmlateRepoDB = ParamConnector.getInstance().getDB().templateRepoDB;
        let options = {
            owner: paramId,
            isInstalled: "true"
        }
        return tepmlateRepoDB.getAllTemplates(options);
    }

    static getTemplate(templateId) {
        let tepmlateRepoDB = ParamConnector.getInstance().getDB().templateRepoDB;
        return tepmlateRepoDB.getTemplate(templateId)
    }
    static getTemplateIdByConsId(templateConsId) {
        let tepmlateRepoDB = ParamConnector.getInstance().getDB().templateRepoDB;
        return tepmlateRepoDB.getTemplateIdByConsId(templateConsId)
    }
    static getTemplateSummary(templateId) {
        let tepmlateRepoDB = ParamConnector.getInstance().getDB().templateRepoDB;
        return tepmlateRepoDB.getSummary(templateId)
    }

    static getStepById(stepId) {
        let stepsRepoDB = ParamConnector.getInstance().getDB().stepsRepoDB;
        return stepsRepoDB.getStep(stepId)
    }

    static getContractAddress() {
        let addressJSON = require('../../param-network/template-repository-manager/template-repository-manager.json')
        return addressJSON.address;
    }
}

export default TemplateRepo;