class TransactionsDB {
    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    addTransaction(id, owner, metaData) {
        return this.mongoDBConnector.invoke("transactionsDB", "addTransaction", id, owner, metaData);
    }

    addTransactions = (transactions) => {
        return this.mongoDBConnector.invoke("transactionsDB", "addTransactions", transactions);
    }

    getTransactionsById = (docId) => {
        return this.mongoDBConnector.invoke("transactionsDB", "getTransactionsById", docId);
    }

    getTransactions = (docId) => {
        return this.mongoDBConnector.invoke("transactionsDB", "getTransactions", docId);
    }

    getTemplateConsTransactions = (consId) => {
        return this.mongoDBConnector.invoke("transactionsDB", "getTemplateConsTransactions", consId);
    }
}
export default TransactionsDB;