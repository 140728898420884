import React from "react";
import ReactDOM from "react-dom";
import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
import { AppContainer } from 'react-hot-loader';
import { message } from 'antd';
import Console from './logger/index';
import packageJson from '../package.json';
import * as maintanance from './maintanance.json';
import ServerMaintanance from './serverMaintanance';


function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
let render;
// process.env.PARAM_APP_VERSION = packageJson.version;
// let isDesktop = process.env && process.env.PARAM_APP_TYPE && process.env.PARAM_APP_TYPE === 'desktop'
// Console.debug('PARAM_APP_', process.env)
let isDesktop =false//process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? true : false
console.debug('isDesktop', isDesktop)

// let isDesktop = false;

if (isDesktop) {
  const electron = window.require('electron');
  const ipcRenderer = electron.ipcRenderer;
  ReactDOM.render(<AppContainer><NextApp showLoader={true} status="Checking ⦃param⦄ configuration" /></AppContainer>, document.getElementById('root'));
  setTimeout(() => {
    ipcRenderer.send('initInstall', 'ping');
  }, getRandomInt(3000));

  ipcRenderer.on('completed', (event, arg) => {
    setTimeout(() => {
      ReactDOM.render(<AppContainer ><NextApp showLoader={true} status={'Starting logger..'} /></AppContainer>, document.getElementById('root'));
      Console.initLogger();
      ReactDOM.render(<AppContainer><NextApp showLoader={false} status="Starting ⦃param⦄.network" /></AppContainer>, document.getElementById('root'));
    }, getRandomInt(3000))
  })
  ipcRenderer.on('onSuccess', (event, arg) => {
    Console.debug('onSuccess', arg) // prints "pong"
  })
  ipcRenderer.on('onProcessStatusUpdate', (event, arg) => {
    Console.debug('onProcessStatusUpdate', arg) // prints "pong"
    ReactDOM.render(<AppContainer><NextApp showLoader={true} status={arg} /></AppContainer>, document.getElementById('root'));
  })
  ipcRenderer.on('onRouteToURL', (event, arg) => {
    Console.debug('onRouteToURL', arg);
    ReactDOM.render(<AppContainer><NextApp urlData={arg} showLoader={true} status='onRouteToURL' /></AppContainer>, document.getElementById('root'));
  })
  ipcRenderer.on('onError', (event, arg) => {
    Console.error('onError', arg)
    message.error("Unable to start the app.Please try again!");
  })
}
else {
  // Wrap the rendering in a function:
  render = Component => {
    maintanance['isUnderMaintanance']
    ?
    ReactDOM.render(<AppContainer>< ServerMaintanance /></AppContainer>, document.getElementById('root'))
    :
    ReactDOM.render(<AppContainer>< NextApp showLoader={false} /></AppContainer>, document.getElementById('root'));//
  };
}

// Do this once
registerServiceWorker();
// Render once
render(NextApp);
// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}