import React, { PureComponent } from "react";
import { Card } from 'antd';
import { Link } from "react-router-dom";
import IconWithTextCard from "../../components/Metrics/IconWithTextCard";
import * as Config from '../../config.json';
import "./index.less";
import onboardingImg from "../../assets/images/onboarding.png";
import Analytics from '../../analytics';

class Landing extends PureComponent {
  constructor(props) {
    super(props);
    Analytics.getInstance().trackPageview();
  }
  
  render() {
    return (
      <div className="landing">
        <Card className="landing__card">
          <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1 landing__center">
            Welcome to ⦃param⦄
          </h1>
          <p className="landing__center" style={{ color: "grey" }}>
            Turbocharge Commerce Across Enterprise
          </p>
          <br />
          <img src={onboardingImg} alt="landing" className="landing__img" />
          <br />
          {/* <div className="landing__textcard_create">
            <Link to={{ pathname: "/password", state: { source: "create" } }}>
              <IconWithTextCard
                icon="avatar"
                iconColor="white"
                title="Create New Identity"
                subTitle="Start by creating your own identity"
                marginBottom="4"
              />
            </Link>
          </div>
          <div className="landing__textcard_restore">
            <Link to="/restore">
              <IconWithTextCard
                icon="frequent"
                iconColor="purple"
                title="Restore from Backup"
                subTitle="using your 12 phrase password"
                marginBottom="4"
              />
            </Link>
          </div> */}
          <div className="landing__textcard_otplogin">
            <Link to="/otplogin" onClick={() => Analytics.getInstance().trackEvent(Config['analytics'].auth, Config['analytics'].otpLogin, Config['analytics'].open)}>
              <IconWithTextCard
                icon="lock-screen"
                iconColor="purple"
                title="OTP Based Login"
                subTitle="using your email or mobile No"
                marginBottom="0"
              />
            </Link>
          </div>
        </Card>
      </div>
    );
  }
}

export default Landing;
