import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import NetworkBridge from '../network-bridge';

class NotificationWrapper {

    static getTitle = (event, type, ownerName) => {
        switch (event) {
            case "receiptStatusUpdate":
                return "Receipt status has been updated";
            case "onSubscriberAdded":
                if (type === "template-consensus")
                    return "Template subscriber has been added";
                else
                    return "Subscriber has been added";
            case "onTemplate":
                return "You received an Invitation";
            case "onBuyerInvited":
                if (ownerName)
                    return `Received invitation from ${ownerName}`;
                else
                    return "Received invitation";
            case "onInvitationTemplateAdded":
                if (type === "vendorTemplate")
                    return "Supplier Invitation Template has been added"
                else
                    return "Customer Invitation Template has been added"
            default:
                return "-"
        }
    }

    static notificationView = (notifications) => {
        if (!notifications) {
            return [];
        }
        TimeAgo.addLocale(en);
        const timeAgo = new TimeAgo('en-US');
        return notifications.map((notification, index) => {
            if (!notification.time) {
                notification.time = Date.now();
            }
            let time = Date.now() - Number(notification.time)

            return {
                title: NotificationWrapper.getTitle(notification.event, notification.type, notification.name),
                docId: notification.id,
                owner: notification.owner,
                time: timeAgo.format(Date.now() - time),
                type: notification.type,
                event: notification.event,
                notificationId: notification["@id"],
                markAsRead: notification.markAsRead
            }
        })
    }
}

export default NotificationWrapper;