import React, { Component } from "react";
import { Layout, Drawer, Icon, Tooltip, message, Dropdown, Row, Col, Checkbox, Input, Button, Alert, Menu, Popover, Divider, Avatar } from "antd";
import { Link, withRouter } from "react-router-dom";
import Web3Utils from 'web3-utils';

import CustomScrollbars from "../../util/CustomScrollbars";
import languageData from "./languageData";
import {
  switchLanguage,
  toggleCollapsedSideNav,
  setSalesVisibility, setPurchasesVisibility, setLPurchasesVisibility, setLSalesVisibility, setDraftInvoicesVisibility, setReportsVisibility, setOperationsVisibility, setSubscribedDocumentsVisibility, setCatalogueVisibility
} from "../../appRedux/actions/Setting";
import SearchBox from "../../components/SearchBox";
import AppNotification from "../../components/AppNotification";
import Auxiliary from "../../util/Auxiliary";
import { ReactComponent as GlobeIcon } from '../../assets/images/globe.svg'
import ReactNotifications from 'react-browser-notifications';
import UserIcon from '../../assets/images/loginProfile1.svg';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import ParamConnector from '../../param-connector/index';
import * as Utils from '../../util/utils';
import * as Config from '../../config.json';
import Console from '../../logger/index';
import NetworkBridge from "../../util/network-bridge";
import NotificationWrapper from "../../util/wrapper/NotifictionWrapper";
import EventManager from '../../event-manager/event-manager.js';
import Analytics from '../../analytics';
import './index.less';
import { LoginOutlined, UserOutlined } from "@ant-design/icons";



const { Header } = Layout;

class Topbar extends Component {

  constructor(props) {
    super(props);
    this.analytics = Analytics.getInstance();
    this.config = Config['analytics']
  }

  state = {
    searchText: "",
    notifydrawer: false,
    isConnected: false,
    numberOfNotifications: '',
    notifications: [],
    profileData: {},
    windowWidth: window.innerWidth,
    checkedList: ['AcceptNotes','Receipts', 'Contacts', 'Items', 'Tasks'],
    indeterminate: false,
    checkAll: true,
    typeOptions: ['AcceptNotes','Receipts', 'Contacts', 'Items', 'Tasks'],
    isAlertVisible: false,
    isShowConnected: false,
    accounts: [],
    accountSelectionVisible: false,
    currentAccount: {}
  };


  componentDidMount() {
    this.setState({ windowWidth: this.props.width, accounts: Utils.getFromLocalStorage('accounts'), currentAccount: Utils.getFromLocalStorage('accounts')[0] });
    this.addWindowResizeListener();
    let profileData = {}
    let profileObj = Utils.getFromLocalStorage('profile');
    if (profileObj) {
      profileData = JSON.parse(profileObj);
    }
    this.setState({ profileData })
    this.isConnected();
    EventManager.getInstance().registerEvent('onProfileUpdate', this.profileUpdated)
    // this.Database.registerOnNotificationReceived(this.onNotificationRegister);
  }

  addWindowResizeListener = () => {
    window.addEventListener('resize', () => {
      this.setState({ windowWidth: window.innerWidth })
    });
  }

  isConnected = () => {
    this.paramNetwork = ParamConnector.getInstance();
    this.connection = this.paramNetwork.getConnection();
    this.paramNetwork.setNetworkCallback({
      onNetworkStateChange: (isOnline) => {
        if (!isOnline) {
          this.showRetry("error");
        } else {
          this.showRetry("connected");
        }
        this.setState({ isConnected: isOnline })
        // if(isOnline){
        // this.fetchAndShowNotifications();
        // }
      }
    })
    // this.connection.eth.net.isListening().then(result => {
    //   this.setState({ isConnected: true })
    //   let paramId = Utils.getParamId();
    //   return Database.getInstance().notifications.getAllNotifications(paramId)
    // }).then(res => {
    //   res = res || { count: 0 }
    //   this.setState({ numberOfNotifications: res.count })
    // }).catch(error => {
    //   Console.error(error);
    //   this.setState({ isConnected: false })
    // })
  }
  showRetry(type) {
    let retry = "updatable"
    switch (type) {
      case "connected":
        this.setState({ isAlertVisible: false }, () => {
          if (this.state.isShowConnected) {
            message.success({ content: 'Connected!', key: retry });
          }
        })
        break;
      case "error":
        this.setState({ isAlertVisible: true })
        // message.error({ content: 'Unable to connect the node, Please check your internet connection!', key: retry, duration: 2 });
        break;
      case "retry":
        message.loading({ content: this.getRetryContent(), key: retry });
        break;
      default:
        break;
    }
  }

  onAccountSelected = (account) => {
    this.setState({ showLoader: true })
    let accountsArray = this.state.accounts;
    console.log("selected-account", account, accountsArray)
    let index = accountsArray.indexOf(account);
    accountsArray.splice(index, 1)
    accountsArray.unshift(account)
    console.log("Rearranged array", accountsArray);
    Utils.setLocalStorage("encryptedMnemonic", accountsArray[0].mnemonic);
    Utils.setLocalStorage("privateKey", accountsArray[0].privateKey);
    Utils.setLocalStorage("publicKey", accountsArray[0].publicKey);
    Utils.setLocalStorage("nick_name", accountsArray[0].nick_name);
    Utils.setLocalStorage("param_id", Web3Utils.toChecksumAddress(accountsArray[0].paramId));
    let profile = JSON.parse(Utils.getFromLocalStorage('profile'))
    profile.identifier = accountsArray[0].paramId
    profile.name = accountsArray[0].nick_name
    Utils.setLocalStorage('profile', JSON.stringify(profile))
    Utils.setLocalStorage('accounts', accountsArray)
    this.setState({ accounts: accountsArray, currentAccount: accountsArray[0], showLoader: false, accountSelectionVisible: false })
    EventManager.getInstance().emitEvent('onProfileUpdate', profile)
    let profileSettings = Utils.getFromLocalStorage("settings")[Utils.getFromLocalStorage("param_id")]
    let set = Utils.getFromLocalStorage('settings')
    if (!profileSettings) {
      this.props.setSalesVisibility(true)
      this.props.setPurchasesVisibility(true)
      this.props.setLSalesVisibility(false)
      this.props.setLPurchasesVisibility(false)
      this.props.setDraftInvoicesVisibility(false)
      this.props.setReportsVisibility(true)
      this.props.setOperationsVisibility(true)
      this.props.setSubscribedDocumentsVisibility(true)
      let settingsObject = {}
      settingsObject["sales"] = true
      settingsObject["purchases"] = true
      settingsObject["lsales"] = false
      settingsObject["lpurchases"] = false
      settingsObject["draftInvoice"] = false
      settingsObject["reports"] = true
      settingsObject["subscribedDocuments"] = true
      settingsObject["operations"] = true
      settingsObject["catalogue"] = true
      let accountSettings = set
      accountSettings[Utils.getFromLocalStorage("param_id")] = settingsObject
      Utils.setLocalStorage("settings", accountSettings)
    } else {
      let settingsObject = profileSettings
      this.props.setSalesVisibility(settingsObject.sales)
      this.props.setPurchasesVisibility(settingsObject.purchases)
      this.props.setLSalesVisibility(settingsObject.lsales)
      this.props.setLPurchasesVisibility(settingsObject.lpurchases)
      this.props.setDraftInvoicesVisibility(settingsObject.draftInvoice)
      this.props.setReportsVisibility(settingsObject.reports)
      this.props.setSubscribedDocumentsVisibility(settingsObject.subscribedDocuments)
      this.props.setOperationsVisibility(settingsObject.operations)
      this.props.setCatalogueVisibility(settingsObject.catalogue)

    }
    window.location.replace('/#/main/landing')
  }

  listAccounts = () => {
    return (
      this.state.accounts.map((account, key) => (
        <div className="topbar__select-account">
          {
            key === 0 ?
              <div className="topbar__active-profile">
                <img src={UserIcon} className="topbar__logo-image" alt="avatar" />
                <Tooltip placement="top" title={account.paramId}>
                  <Link to="/main/userprofile" style={{ color: '#323232' }}>
                    <p className="topbar__account-header">{account.nick_name}</p>
                  </Link>
                </Tooltip>
                <p className="topbar__account-sub-header">{account.paramId}</p>
              </div>
              :
              <div onClick={() => { this.onAccountSelected(account) }}>
                <div className="gx-contact-item" style={{ padding: "0" }}>
                  <div className="gx-module-list-icon">
                    <div className="gx-ml-4 gx-d-none gx-d-sm-flex">
                      <Avatar size="large" icon={<UserOutlined />} />
                    </div>
                  </div>
                  <div className="gx-module-list-info gx-contact-list-info">
                    <div className="gx-module-contact-content">
                      <p className="gx-mb-1">
                        <span className="gx-text-truncate gx-contact-name" style={{ fontSize: "1rem" }}>{account.nick_name} </span>
                      </p>
                      <div className="gx-text-muted">
                        <span style={{ display: "block", textOverflow: "ellipsis" }}>{account.paramId} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
          <Divider />
        </div>
      ))
    )
  }
  getRetryContent() {
    return "Trying to connect ⦃PARAM⦄ node..";
  }

  fetchAndShowNotifications() {
    if (!this.state.isConnected) {
      return;
    }
    let paramId = Utils.getParamId();
    /* return ParamConnector.getInstance().getDB().notifications.getAllNotifications(paramId).then(res => {
      res = res || { count: 0 }
      this.setState({ numberOfNotifications: res.count })
    }).catch(error => {
      Console.error(error);
      this.setState({ isConnected: false })
    }) */
  }

  onNotificationRegister = (data, error) => {
    if (error) {
      return;
    }
    Console.debug('notification event', data)
    if (data !== "" && data !== null) {
      let count = this.state.numberOfNotifications;
      NetworkBridge.getNotificationManager().getNotification(data).then(res => {
        let notifications = NotificationWrapper.notificationView([res]);
        this.setState({ numberOfNotifications: ++count, notificationTitle: notifications[0].title, notificationBy: notifications[0].owner, notificationDocId: notifications[0].docId, notificationItem: notifications[0] }, () => {
          this.showNotifications()
        })
      }).catch(err => {
        Console.error(err);
      })
    }
  }

  profileUpdated = (data) => {
    this.setState({ profileData: data, accounts: Utils.getFromLocalStorage('accounts') })
  }

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  };

  handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      let searchText = this.state.searchText
      searchText = searchText.trim()
      if (searchText.length > 2) {
        this.setState({ searchText: "" })
        this.props.history.push(`/main/search?query=${searchText}&${this.state.checkedList}`)
      } else {
        message.error('Please enter minimum 3 characters to start search')
      }
    }
  }

  toggleNotificationDrawer = () => {
    this.setState({
      notifydrawer: !this.state.notifydrawer
    });
  };

  getAccountDrawer = () => {
    return (
      this.state.accounts.length > 1 ?
        <Popover placement="bottomLeft"
          content={this.listAccounts()}
          visible={this.state.accountSelectionVisible}
          onVisibleChange={this.handleVisibleChange}
          trigger="click"
          onClick={() => this.analytics.trackEvent(this.config.topBar, this.config.profile)}
        >
          {
            this.state.profileData && this.state.profileData.logoUrl
              ?
              <img src={this.state.profileData.logoUrl} style={{ maxHeight: '1.8rem' }} alt="profile" />
              :
              <Icon type="user" className="iconStyle" />
          }
        </Popover>
        :
        <Link to="/main/userprofile" style={{ color: '#323232' }}
          onClick={() => this.analytics.trackEvent(this.config.topBar, this.config.profile, this.config.open)}>{
            this.state.profileData && this.state.profileData.logoUrl
              ?
              <img src={this.state.profileData.logoUrl} style={{ maxHeight: '1.8rem' }} alt="profile" />
              :
              <Icon type="user" className="iconStyle" />
          }</Link>

    )
  }

  getNotificationData = (doNotToggle) => {
    let notificationManager = NetworkBridge.getNotificationManager();
    notificationManager.getAllNotifications().then((res) => {
      Console.debug('notification res', res);
      if (res === undefined) {
        return this.toggleNotificationDrawer();
      }
      this.setState({
        numberOfNotifications: res.count
      })
      let promiseArray = [];
      for (let i = 0; i < res.count; i++) {
        promiseArray.push(notificationManager.getNotification(res.notifications[i]));
      }
      return Promise.all(promiseArray);
    }).then(notifications => {
      this.formatData(notifications, doNotToggle)
    }).catch(err => {
      Console.error(err);
    }).finally(() => {
      this.setState({
        showLoader: false
      })
    })
  }

  formatData = (data, doNotToggle) => {
    let promiseArray = [];
    for (let index in data) {
      promiseArray.push(this.getContactNameByParamId(data[index]))
    }
    return Promise.all(promiseArray)
      .then(notifications => {
        notifications = NotificationWrapper.notificationView(notifications);
        this.setState({
          notifications: notifications.reverse(),
          notifydrawer: doNotToggle ? this.state.notifydrawer : !this.state.notifydrawer
        })
      })
  }

  getContactNameByParamId = (dataObject) => {
    let vendorManager = NetworkBridge.getVendorManager();
    return vendorManager.getContactNameByParamId(dataObject.owner)
      .then(name => {
        if (!name) {
          return dataObject;
        }
        dataObject["name"] = name;
        return dataObject;
      });
  }
  logOut = () => {
    this.setState({ showLoader: true });
    return NetworkBridge.getKeyStoreManager().logout(this.state.mobileNo).then(res => {
      message.success("OTP Re-Sent successfully")
    }).catch(err => {
      console.error("Error: ", err);
      message.error("Error: ", err);
    }).finally(() => {
      message.success('Logged out Successfully')
      this.setState({
        showLoader: false
      })
    })
  }
  goToDocument = (notificationData) => {
    if (!notificationData || !notificationData.docId) {
      return;
    }
    if (notificationData.type === 'template-consensus') {
      this.props.history.push(`/main/operations/stepform/id=${notificationData.docId}`)
    }
    else if (notificationData.type === 'receipt') {
      let encodedId = Utils.encodeString(notificationData.docId);
      this.props.history.push(`/main/finance/purchases/receipt/${encodedId}`)
    }
    else if (notificationData.type === 'subscriber') {
      this.props.history.push(`/main/subscribed/default/id=${notificationData.docId}`)
    }
    else if (notificationData.type === 'template') {
      this.props.history.push(`/main/operations/invitations/id=${notificationData.docId}`)
    }
  }

  showNotifications = () => {
    // If the Notifications API is supported by the browser
    // then show the notification
    if (this.n.supported()) this.n.show();
  }

  handleVisibleChange = visible => {
    this.setState({ accountSelectionVisible: visible });
  };

  handleClick = (event) => {
    // Do something here such as
    Console.debug("Notification Clicked", event.target)
    this.goToDocument(JSON.parse(event.target.tag));
    // window.focus() OR
    // window.open("http://www.google.com")

    // Lastly, Close the notification

    this.n.close(event.target.tag);
  }

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? this.state.typeOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onOptionChange = (checkedList) => {
    Console.debug('checked = ', checkedList);
    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < this.state.typeOptions.length,
      checkAll: checkedList.length === this.state.typeOptions.length,
    })
  }

  handleConnection = () => {
    this.analytics.trackEvent(this.config.topBar, this.config.node)
    // if(this.state.isConnected){
    //   return;
    // }
    this.showRetry("retry");
    let that = this;
    setTimeout(() => {
      that.paramNetwork.retryConnection();
      that.isConnected();
    }, 1000)

  }

  retryConnection = () => {
    this.setState({ isShowConnected: true })
    this.handleConnection()
  }

  render() {
    const { navCollapsed, navStyle } = this.props;
    let width = this.state.windowWidth;

    const menu = (
      <div className="topbar__searchDropdown">
        <Checkbox
          indeterminate={this.state.indeterminate}
          onChange={this.onCheckAllChange}
          checked={this.state.checkAll}
        >
          Select all
        </Checkbox><br />
        <Checkbox.Group options={this.state.typeOptions}
          onChange={this.onOptionChange}
          value={this.state.checkedList} />
      </div>
    );

    return (
      <Auxiliary>
        <Header>
          {navStyle === NAV_STYLE_DRAWER ||
            ((navStyle === NAV_STYLE_FIXED ||
              navStyle === NAV_STYLE_MINI_SIDEBAR) &&
              width < TAB_SIZE) ? (
            <div className="gx-linebar">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  this.props.toggleCollapsedSideNav(!navCollapsed)
                }}
              />
            </div>
          ) : null}

          <div className="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg" style={{ width: '70%' }}>
            <Row className="topbar__search">
              <Col span={22} className="topbar__search_col">
                <SearchBox
                  className="topbar__search_input"
                  // styleName=""
                  placeholder="Search in app..."
                  onChange={this.updateSearchChatUser.bind(this)}
                  onKeyPress={this.handleSearchKeyPress}
                  value={this.state.searchText} />
              </Col>
              <Col span={2} className="topbar__search_col" style={{ textAlign: 'right' }}>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ position: 'relative', top: '0.5rem' }}>
                    <Icon className="iconStyle" type="caret-down" style={{ fontSize: '1.5rem', color: 'rgb(50, 50, 50,0.5)' }} />
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </div>
          <Auxiliary>
            {
              this.state.isAlertVisible &&
              <Alert className="topbar__alert"
                message={<div>
                  <span>Unable to connect the node, Please check your internet connection!</span>&nbsp;&nbsp;&nbsp;
                </div>}
                description={<a onClick={this.retryConnection}>Retry</a>}
                closable
                type="error"
                showIcon
                afterClose={() => {
                  this.setState({
                    isAlertVisible: false
                  })
                }}
              />
            }
          </Auxiliary>
          <ul className="gx-header-notifications gx-ml-auto" style={{ position: "absolute", right: "3%" }}>
            <Auxiliary>
              <li className="gx-notify">
                <Tooltip placement="top" title='Settings'>
                  <Link to="/main/setting" style={{ color: '#323232' }}
                    onClick={() => this.analytics.trackEvent(this.config.topBar, this.config.settings, this.config.open)}>
                    <Icon type="setting" className="iconStyle" />
                  </Link>
                </Tooltip>
              </li>
            </Auxiliary>
            <Auxiliary>
              <li className="gx-notify">
                <Tooltip placement="top" title={this.state.isConnected ? 'Connected' : 'Not connected'}>
                  <span className="gx-pointer gx-status-pos gx-d-block" >
                    {/* <Icon component={GlobeIcon} alt="Network status" onClick={this.handleConnection} /> */}
                    <Icon type="global" onClick={this.handleConnection} className="iconStyle" />
                    {
                      this.state.isConnected ?
                        <span className="gx-status gx-status-rtl gx-small gx-green" />
                        :
                        <span className="gx-status gx-status-rtl gx-small gx-red" />
                    }
                  </span>
                </Tooltip>
              </li>
            </Auxiliary>

            <Auxiliary>
              <li className="gx-notify" style={{ marginTop: '0.2rem' }}>
                <Tooltip placement="top" title='Notifications'>
                  <span
                    className="gx-pointer gx-status-pos gx-d-block"
                    onClick={() => this.analytics.trackEvent(this.config.topBar, this.config.notifications)}
                  // onClick={() => this.getNotificationData(false)} //{this.toggleNotificationDrawer}
                  >
                    <i className="icon icon-notification" />
                    {
                      this.state.numberOfNotifications > 0 &&
                      <span className="gx-status gx-status-rtl gx-small gx-green" />
                    }
                  </span>
                </Tooltip>
              </li>
            </Auxiliary>


            <Auxiliary>
              <li className="gx-notify">
                <Tooltip placement="top" title='Profile'>
                  {
                    this.getAccountDrawer()
                  }
                </Tooltip>
              </li>
            </Auxiliary>
            <Auxiliary>
              <li className="gx-notify">
                <Tooltip placement="top" title='Logout'>
                  <Link to="/otplogin" style={{ color: '#323232' }}
                    onClick={() => Analytics.getInstance().trackEvent(this.config.auth, this.config.logout)}>{
                      < LoginOutlined />
                    }
                  </Link>
                </Tooltip>
              </li>
            </Auxiliary>
            <Drawer
              title="Notifications"
              placement="right"
              closable={true}
              onClose={this.toggleNotificationDrawer}
              visible={this.state.notifydrawer}
            >
              <AppNotification getNotificationData={this.getNotificationData} onClose={this.toggleNotificationDrawer} data={this.state.notifications} count={this.state.numberOfNotifications} />
            </Drawer>
          </ul>
        </Header>
        <ReactNotifications
          onRef={ref => (this.n = ref)} // Required
          title={this.state.notificationTitle} // Required
          body={`By ${this.state.notificationBy}`}
          icon="devices-logo.png"
          // tag={this.state.notificationDocId}
          tag={JSON.stringify(this.state.notificationItem)}
          timeout="5000"
          onClick={event => this.handleClick(event)}
        />
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, navCollapsed, width, salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility, draftInvoicesVisibility, reportsVisibility, subscribedDocumentsVisibility, operationsVisibility, catalogueVisibility } = settings;
  return { locale, navStyle, navCollapsed, width, salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility, draftInvoicesVisibility, reportsVisibility, subscribedDocumentsVisibility, operationsVisibility, catalogueVisibility };
};

export default withRouter(connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage,
  setSalesVisibility, setPurchasesVisibility, setLPurchasesVisibility, setLSalesVisibility, setDraftInvoicesVisibility, setReportsVisibility, setOperationsVisibility, setSubscribedDocumentsVisibility, setCatalogueVisibility
})(Topbar));
