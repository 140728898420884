import Charts from '../../charts'
import { sales } from '../../channel-config.json'
import RedisClientSocket from '../../client-lib/redis-client';
import Console from '../../../../logger'
import EventUtils from '../utils/eventUtils';

class SellerOverView {

    constructor(paramID) {
        this.paramID = paramID;
    }

    publishAllOverView() {
        Console.info("SellerOVerView")

        this.getSummary().then(result => {
            return this.getCustomers();
        }).then(res => {
            return this.getSales()
        }).then(res => {
            return this.getOverview();
        }).then(res => {
            return this.getCatalogueData()
        }).then(res => {
            return this.getContractDueForRenewal()
        }).then(res => {
            return this.getDisputes()
        }).then(res => {
            return this.getGrnAcknowledged()
        }).then(res => {
            return this.getGoodsSentButNotAcknowledged()
        }).then(res => {
            return this.getTopSales('category', "price", '', '')
        }).then(() => {
            return this.getAverageSalesCycle()
        }).then(() => {
            return this.getTopQuantityDisputes()
        }).catch(err => {
            Console.error(err);
        })
    }

    getSummary(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.dashboard}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        let redisConnection = RedisClientSocket.getInstance();
        return redisConnection.connect().then(data => {
            return Charts.getInstance().salesOverview.getSummary()
        }).then(graphDBResult => {
            return redisConnection.sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getCustomers(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.customers}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'vendor'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getCustomers()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getSales(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.sales}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getSales()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getOverview(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.overview}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn', 'vendor'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getOverview()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getCatalogueData(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.catalogue}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'catalogue'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getCatalogueData()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getTopSales(groupBy, action, fromDate, toDate, isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.topsales}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'catalogue'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getTopSales(groupBy, action, fromDate, toDate)
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getContractDueForRenewal(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.contractDueForRenewal}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getContractDueForRenewal()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getDisputes(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.disputes}`
        if (!EventUtils.checkCanRefresh(channelName, ['grn', 'receipts'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getDisputes()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getGrnAcknowledged(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.GRNAcknowledged}`
        if (!EventUtils.checkCanRefresh(channelName, ['grn', 'receipts'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getGrnAcknowledged()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err)
        })
    }

    getGoodsSentButNotAcknowledged(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.GoodsSentButNotAcknowledged}`
        if (!EventUtils.checkCanRefresh(channelName, ['grn', 'receipts'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.goodsSentButNotAcknowledged()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            console.log("error", err)
        })
    }

    getAverageSalesCycle(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.averageSaleCycleTime}`
        if (!EventUtils.checkCanRefresh(channelName, ['grn', 'receipts'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getAverageCycleTime()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            console.log("error", err)
        })
    }

    getTopQuantityDisputes(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.topQuantityDispute}`
        if (!EventUtils.checkCanRefresh(channelName, ['grn',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getTopQuantityDisputes()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            console.log("error", err)
        })
    }

    getAllOpenOrPartialInvoices(isFromEvent) {
        let channelName = `${this.paramID}/${sales.overview.getAllOpenOrPartialInvoices}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().salesOverview.getAllOpenOrPartialInvoices()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            console.log("error", err)
        })
    }
}

export default SellerOverView;