import AppUtils from '../../app.utils';
const events = require('events');
class SaveAndReadFile {

    constructor() {
        if (SaveAndReadFile.instance) {
            throw new Error("You can't create object. Use SaveAndReadFile.getInstance()");
        }
        if (AppUtils.isDesktop()) {
            const electron = window.require('electron');
            this.ipcRenderer = electron.ipcRenderer;
            this.events = new events.EventEmitter();
            this._initEvents();
        }
    }

    static getInstance() {
        if (!SaveAndReadFile.instance) {
            SaveAndReadFile.instance = new SaveAndReadFile();
        }
        return SaveAndReadFile.instance;
    }

    _initEvents() {
        this.ipcRenderer.on("fileSaveResult", (event, result) => {
            this.events.emit("onFileSaved", result);
        })
        this.ipcRenderer.on("fileRead", (event, result) => {
            this.events.emit("onFileRead", result);
        })
        this.ipcRenderer.on("fileReadError", (event, result) => {
            this.events.emit("onFileError", result);
        })
    }

    saveFile(fileInfoObject) {
        if (!AppUtils.isDesktop()) {
            throw new Error("Try in desktop.");
        }

        let { screenName, fileName, fileContent, password, paramId } = fileInfoObject
        if (!screenName) {
            screenName = "unknown-files"
        }
        if (!fileName) {
            // if(callback && callback.onError){
            //     callback.onError({message:"Filename can not be empty", status:"0"});
            //     return;
            // }
            throw new Error("Filename can not be empty");
        }
        if (!fileContent) {
            // if(callback && callback.onError){
            //     callback.onError({message:"File content can not be empty", status:"0"});
            //     return;
            // }
            throw new Error("File content can not be empty");
        }
        this.ipcRenderer.send("saveFile", screenName, fileName, fileContent, paramId, password);
    }

    readFile(fileInfoObject) {
        if (!AppUtils.isDesktop()) {
            throw new Error("Try in desktop.");
        }
        let { screenName, fileName, paramId, password } = fileInfoObject;
        if (!fileName) {
            throw new Error("Please provide valid filename");
        }
        this.ipcRenderer.send("readFile", screenName, fileName, paramId, password);
    }

    registerSaveFile(callback) {
        if (!callback) {
            return;
        }
        if (callback.onFileSaved)
            this.events.addListener("onFileSaved", callback.onFileSaved)
        if (callback.onBatchAdded)
            this.events.addListener("onBatchAssigned", callback.onBatchAdded)
        if (callback.onFileStatusChaged)
            this.events.addListener("onFileStatusChaged", callback.onFileStatusChaged)
        if (callback.onFileStatusResult)
            this.events.addListener("onFileStatusResult", callback.onFileSaved)
    }

    registerReadFile(callback) {
        if (!callback) {
            return;
        }
        if (callback.onFileRead)
            this.events.addListener("onFileRead", callback.onFileRead)
        if (callback.onFileError)
            this.events.addListener("onFileError", callback.onFileError)
    }

    unRegisterReadFile(callback) {
        if (!callback) {
            return;
        }
        if (callback.onFileRead)
            this.events.removeListener("onFileRead", callback.onFileRead)
        if (callback.onFileError)
            this.events.removeListener("onFileError", callback.onFileError)
    }

    unRegisterSaveFile(callback) {
        if (!callback) {
            return;
        }
        if (callback.onFileSaved)
            this.events.removeListener("onFileSaved", callback.onFileSaved)
        if (callback.onBatchAdded)
            this.events.removeListener("onBatchAssigned", callback.onBatchAdded)
        if (callback.onFileStatusChaged)
            this.events.removeListener("onFileStatusChaged", callback.onFileStatusChaged)
        if (callback.onFileStatusResult)
            this.events.removeListener("onFileStatusResult", callback.onFileSaved)
    }

}
export default SaveAndReadFile;