// const _ = require('lodash');
import _ from "lodash";
class Util {

    /**
     * 
     * @param {*} operation 'sum' for sum of the data
     *                      'count' for counting 
     */

    static aggregation(data, path, operation) {
        data = Util.convertToArray(data);
        let response = 0;
        if (operation === 'count') {
            response = data.length;
            return response;
        }
        for (let index in data) {
            switch (operation) {
                case 'sum':
                    response += path ? Number(_.get(data[index], path)) : Number(data[index]);
                    break;
            }
        }
        return response;
    }


    // static getMonths(){
    //     return {
    //         "Jan"
    //     }
    // }

    static convertToArray(data) {
        if (!Array.isArray(data)) {
            data = [data];
        }
        return data;
    }

    static countDistinctKeys(data, keyToFilterAndCount) {
        data = Util.convertToArray(data);
        let distinct = [...new Set(data.map(item => item[keyToFilterAndCount]))];
        return distinct.length;
    }

    static milliSecondsToDays(fromMilliSeconds, toMilliSeconds) {
        fromMilliSeconds = Number(fromMilliSeconds);
        toMilliSeconds = Number(toMilliSeconds);
        let one_day = 1000 * 60 * 60 * 24;
        return Math.round((toMilliSeconds - fromMilliSeconds) / one_day);
    }

    static milliSecondsInDays(milliSeconds) {
        return (milliSeconds / (60 * 60 * 24 * 1000));
    }

    static getDateAndTime(timeNano) {
        let date = new Date(Number(timeNano));
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    static milliSecondsToMonth(milliSeconds) {
        let date = new Date(Number(milliSeconds));
        let monthIndex = date.getMonth();
        let month = "";
        switch (monthIndex) {
            case 0:
                month = 'January'
                break;
            case 1:
                month = 'February'
                break;
            case 2:
                month = 'March'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'May'
                break;
            case 5:
                month = 'June'
                break;
            case 6:
                month = 'July'
                break;
            case 7:
                month = 'August'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'October'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'December'
                break;
            default:
                break;
        }
        return month;
    }
    static addMonthsInObject(fillWith) {
        let defaultValue;
        if (fillWith === 'number') {
            defaultValue = 0;
        }
        else if (fillWith === 'array') {
            defaultValue = [];
        } else {
            defaultValue = ""
        }
        let obj = {};
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        for (let month in months) {
            if (!obj[months[month]]) {
                obj[months[month]] = defaultValue;
            }
        }
        return JSON.parse(JSON.stringify(obj));
    }
}
export default Util;
// module.exports = Util;