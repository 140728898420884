import Database from '../../index'
import * as NetworkUtils from '../../../util/utils';
import NetworkDataFormat from '../Utils/network-data-format';
import Console from '../../../logger/index';
import RestoreContacts from '../sync/contacts';
import ECIES from '../../../util/ecies';
import NetworkBridge from '../../../util/network-bridge';

class ContactDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerOnContactAdded(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnContact", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnContactAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnContact", callback);
        Console.info(`[GraphDB] register event`);
    }

    registerOnContactUpdated(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnContactUpdate", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnContactUpdated(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnContactUpdate", callback);
    }

    _emitOnContactAdded(contactId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("OnContactUpdate", contactId);

        // return this.dbInstance.getContact(`${contactId}`).then(contactResponse => {
        //     Console.info(`[GraphDB] emiting ContactAdded event.`);
        //     return this.dbInstance.emitEvent("OnContact", contactResponse);
        // }).catch(e => {
        //     Console.info(`[GraphDB] emiting ContactAdded event ${e}.`);
        //     this.dbInstance.emitEvent("OnContact", null, e);
        // });
    }

    _emitOnContactUpdated(contactId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.emitEvent("OnContactUpdate", contactId);
        // return this.dbInstance.contacts.getContact(`${contactId}`).then(contactResponse => {
        //     Console.info(`[GraphDB] emiting contact updated event.`);
        // }).catch(e => {
        //     Console.error(`[GraphDB] emiting contact updated event. ${e}`);
        //     this.dbInstance.emitEvent("OnContactUpdate", null, e);
        // });
    }

    _onContactAdded(error, dataJSON) {
        Console.info(`[GraphDB] Got _onContactAdded event`);
        if (error) {
            Console.error(`Unable to get create contact details form network, Reson: ${error}`);
            return;
        }
        dataJSON.args.contactId = NetworkUtils.getTransactionId(dataJSON.args.contactId, dataJSON.args.contractAddress);
        return RestoreContacts.restoreContact(dataJSON.args.contactId, dataJSON.args.contractAddress, dataJSON.args.owner).then(() => {
            Console.log(`[GraphDB] Added contact info to graph ${dataJSON.args.contactId}`);
            // this.dbInstance.updateLastSync();
            return this._emitOnContactAdded(dataJSON.args.contactId);
        }).catch(e => {
            Console.error(`Unable to get contact status, Reason: ${e.toString()}`);
        })
        // .finally(() => {
        //     let metaData = NetworkDataFormat.getMetaInfo(dataJSON.args.contactId, dataJSON, "Contact added");
        //     return this.dbInstance.transactionsDB.addTransaction(dataJSON.args.contactId, dataJSON.args.owner, metaData).catch(error => {
        //         Console.error(`Unable to add transaction for ${dataJSON.args.contactId}, Reason: ${error.toString()}`)
        //     })
        // })
    }

    _onContactAcknowledged(error, dataJSON) {
        Console.info(`[GraphDB] Got _onContactAcknowledged event`);
        if (error) {
            Console.error(`Unable to get update contact details form network, Reason: ${error}`);
            return;
        }
        let selfParamId = NetworkUtils.getParamId();
        let contactId = dataJSON.args.receiverContactId;

        if (selfParamId === dataJSON.args.sender) {
            if (dataJSON.args.senderContactId)
                contactId = dataJSON.args.senderContactId;
        } else if (selfParamId === dataJSON.args.receiver) {
            if (dataJSON.args.receiverContactId)
                contactId = dataJSON.args.receiverContactId;
        }
        dataJSON.args.contactId = contactId;
        dataJSON.args["owner"] = selfParamId;
        return this._onContactUpdated(error, dataJSON);
    }

    _onContactUpdated(error, dataJSON) {
        Console.info(`[GraphDB] Got _onContactUpdated event`);
        if (error) {
            Console.error(`Unable to get update contact details form network, Reson: ${error}`);
            return;
        }
        dataJSON.args.contactId = NetworkUtils.getTransactionId(dataJSON.args.contactId, dataJSON.args.contractAddress);
        let owner = dataJSON.args.owner;

        return RestoreContacts.restoreContact(dataJSON.args.contactId, dataJSON.args.contractAddress, owner, dataJSON.args.receiver).then(() => {
            Console.log(`[GraphDB] Updated contact info to graph ${dataJSON.args.contactId}`);
            // this.dbInstance.updateLastSync();
            return this._emitOnContactUpdated(dataJSON.args.contactId);
        }).catch(e => {
            Console.error(`Unable to get contact status, Reason: ${e.toString()}`);
        }).finally(() => {
            let metaData = NetworkDataFormat.getMetaInfo(dataJSON.args.contactId, dataJSON, "Contact edited");
            return this.dbInstance.transactionsDB.addTransaction(dataJSON.args.contactId, owner, metaData).catch(error => {
                Console.error(`Unable to edit transaction for ${dataJSON.args.contactId}, Reason: ${error.toString()}`)
            })
        })
    }

    _onContactInvited(error, dataJSON) {
        Console.info(`[GraphDB] Got _onContactInvited event`);
        if (error) {
            Console.error(`Unable to get update contact details form network, Reson: ${error}`);
            return;
        }
        dataJSON.args.owner = dataJSON.args.sender;
        return this._onContactUpdated(error, dataJSON);
    }

    _onContactInvitationAccepted(error, dataJSON) {
        if (error) {
            Console.error(`Unable to get update contact details form network, Reson: ${error}`);
            return;
        }
        Console.log(`[GraphDB] Got _onContactInvitationAccepted event for ${dataJSON.args.senderContactId}`);
        dataJSON.args.contactId = NetworkUtils.getTransactionId(dataJSON.args.senderContactId, dataJSON.args.contractAddress);
        let selfParamId = NetworkUtils.getParamId();
        let privateKey = NetworkUtils.getPrivateKey();
        let owner = selfParamId;
        if (dataJSON.args.sender === selfParamId) {
            let encryptedRandomString = dataJSON.args.randomString;
            let randomString = ECIES.decrypt(privateKey, encryptedRandomString);
            return this.dbInstance.getContact(dataJSON.args.contactId).then(contactRes => {
                if (contactRes.randomString === randomString) {
                    //acknowledge
                    let contactManager = NetworkBridge.getContactManager();
                    return contactManager.acknowledgement(dataJSON.args.senderContactId, dataJSON.args.receiver, dataJSON.args.receiverContactId);
                }
                return RestoreContacts.restoreContact(dataJSON.args.contactId, dataJSON.args.contractAddress, owner).then(() => {
                    Console.log(`[GraphDB] Updated contact info to graph ${dataJSON.args.contactId}`);
                    this.dbInstance.updateLastSync();
                    return this._emitOnContactUpdated(dataJSON.args.contactId);
                }).catch(e => {
                    Console.error(`Unable to get contact status, Reason: ${e.toString()}`);
                }).finally(() => {
                    let metaData = NetworkDataFormat.getMetaInfo(dataJSON.args.contactId, dataJSON, "Contact edited");
                    return this.dbInstance.transactionsDB.addTransaction(dataJSON.args.contactId, owner, metaData).catch(error => {
                        Console.error(`Unable to edit transaction for ${dataJSON.args.contactId}, Reason: ${error.toString()}`)
                    })
                })
            })
        }
        dataJSON.args.contactId = NetworkUtils.getTransactionId(dataJSON.args.receiverContactId, dataJSON.args.contractAddress);
        return RestoreContacts.restoreContact(dataJSON.args.contactId, dataJSON.args.contractAddress, owner).then(() => {
            Console.log(`[GraphDB] Updated contact info to graph ${dataJSON.args.contactId}`);
            this.dbInstance.updateLastSync();
            return this._emitOnContactUpdated(dataJSON.args.contactId);
        }).catch(e => {
            Console.error(`Unable to get contact status, Reason: ${e.toString()}`);
        }).finally(() => {
            let metaData = NetworkDataFormat.getMetaInfo(dataJSON.args.contactId, dataJSON, "Contact edited");
            return this.dbInstance.transactionsDB.addTransaction(dataJSON.args.contactId, owner, metaData).catch(error => {
                Console.error(`Unable to edit transaction for ${dataJSON.args.contactId}, Reason: ${error.toString()}`)
            })
        })
    }
}

export default ContactDBEvents;