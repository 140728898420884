import Parser from "./index";
import FileErrors from "./errors";
import JSON2JSONLd from '../parsers/json-jsonld';
import Progress from '../../progress';
import JSONValidator from "../validators/json-validator";

class JSONFileParser extends Parser {
    parse(pathObject, callback) {
        return new Promise((resolve, reject) => {
            return super.parse(pathObject, {
                onProgress: (progress) => {
                    callback.onProgress(progress);
                },
                onStatus: (statusObject) => {
                    callback.onStatus(statusObject);
                },
                onData: (data) => {
                    this.validate(data, callback, resolve, reject);
                },
                onFileReading: (fileName) => {

                },
                onFileError: (errorObject) => {
                    callback.onAbort(errorObject);
                    reject(errorObject);
                },
                onFileAbort: (errorObject) => {
                    callback.onAbort(errorObject);
                    reject(errorObject);
                },
                onError: (errorObject) => {
                    callback.onError(errorObject);
                    reject(errorObject);
                },
                onValidationError: (errorObject) => {
                    callback.onValidationError(errorObject);
                    reject(errorObject);
                }
            })
        })
    }

    validate(jsonText, callback, resolve, reject) {
        callback.onStatus({ msg: `Validating data ${jsonText.fileName}..` });
        callback.onProgress(Progress.ON_FILE_VALIDATION);
        let jsonObject;
        try {
            jsonObject = JSON.parse(jsonText.data);
        } catch (error) {
            callback.onError(FileErrors.INVALID_FILE_FORMAT);
            callback.onProgress(Progress.ON_COMPLETED);
            callback.onAbort({ fileName: jsonText.fileName, error: FileErrors.INVALID_FILE_FORMAT.error })
            return reject(FileErrors.INVALID_FILE_FORMAT);
        }
        let validation = JSONValidator.isValidJSONLd(jsonObject);
        if (!validation.isValid) {
            let errorObject = { isValidationError: true, error: validation.errors };
            callback.onValidationError(errorObject);
            callback.onProgress(Progress.ON_COMPLETED);
            callback.onAbort({ fileName: jsonText.fileName, error: FileErrors.INVALID_SCHEMA.error })
            return reject(FileErrors.INVALID_SCHEMA);
        }
        callback.onStatus({ msg: `Importing the data ${jsonText.fileName}..` });
        jsonObject = JSON2JSONLd.convert(jsonObject);
        callback.onData({ fileName: jsonText.fileName, jsonObjects: [jsonObject], resolve, reject })
    }
}
export default JSONFileParser;