class BlockInfo {
    static getBlockInfo(connection, blockHashOrNumber) {
        return new Promise((resolve, reject) => {
            if (!connection) {
                return reject("");
            }
            connection.eth.getBlock(blockHashOrNumber, (error, result) => {
                if (error) {
                    return reject(error);
                }
                resolve(result);
            })
        })
    }

    static getBlockInfos(connection, blockHashOrNumbers) {
        let promises = [];
        let promise;
        for (let index in blockHashOrNumbers) {
            promise = BlockInfo.getBlockInfo(connection, blockHashOrNumbers[index]).catch(e => {
                return null;
            })
            promises.push(promise);
        }
        return Promise.all(promises);
    }
    static getTransactionInfo(connection, txHash) {
        return new Promise((resolve, reject) => {
            connection.eth.getTransactionReceipt(txHash, (err, data) => {
                if (err) {
                    return reject(err)
                }
                if (data) {
                    if (data.logs && data.logs.length >= 0) {
                        return resolve(data.logs);
                    }
                    return reject(data);
                }
                return resolve(null);
            })
        });
    }

    static getTransactionInfoSync(connection, txHash, _resolve, _reject, count = 1) {
        if (_resolve && _reject) {
            setTimeout(() => {
                BlockInfo.getTransactionInfo(connection, txHash).then((data) => {
                    if (data) {
                        return _resolve(data);
                    }
                    if (count == 10) {
                        return _reject();
                    }
                    BlockInfo.getTransactionInfoSync(connection, txHash, _resolve, _reject, count + 1);
                }).catch(error => {
                    _reject(error);
                })
            }, 1000);
            return;
        }

        return new Promise((resolve, reject) => {
            // setTimeout(()=>{
            if (!count) {
                count = 1;
            }

            BlockInfo.getTransactionInfo(connection, txHash).then(data => {
                if (data) {
                    return resolve(data);
                }
                BlockInfo.getTransactionInfoSync(connection, txHash, resolve, reject, count + 1);
            }).catch(error => {
                reject(error);
            })
            // }, 1000)
        })
    }
}
export default BlockInfo;