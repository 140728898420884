import React from 'react';
import { Row, Col, Button, Card, Icon, message, Modal, Select, Input, Switch, Radio, Upload } from 'antd';
import * as Utils from '../../../util/utils';
import ReceiptUtils from '../finance/Receipt/utils';
import './setting.less'
const DefaultTemplate = require('./config/default-receipt')
const { confirm } = Modal;


class PreviewTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templatePreview: true
        }
    }

    renderTemplate = () => {
        const previewFile = this.props.previewFile || DefaultTemplate.defaultReceiptTemplate
        const type = this.props.type
        let currentTab, jsonLd, docInfo
        switch (type) {
            case "contract":
                currentTab = "contract"
                jsonLd = DefaultTemplate.defaultJsonLd.contract
                docInfo = DefaultTemplate.defaultDocInfo.contract
                break;
            case "purchaseOrder":
                currentTab = "purchaseOrder"
                jsonLd = DefaultTemplate.defaultJsonLd.purchaseOrder
                docInfo = DefaultTemplate.defaultDocInfo.purchaseOrder
                break;
            case "invoice":
                currentTab = "invoice"
                jsonLd = DefaultTemplate.defaultJsonLd.invoice
                docInfo = DefaultTemplate.defaultDocInfo.invoice
                break;
            case "payment":
                currentTab = "payment"
                jsonLd = DefaultTemplate.defaultJsonLd.payment
                docInfo = DefaultTemplate.defaultDocInfo.payment
                break;
            case "digitalReceipt":
                currentTab = "payment"
                jsonLd = DefaultTemplate.defaultJsonLd.digitalReceipt
                docInfo = DefaultTemplate.defaultDocInfo.digitalReceipt
                break;
            default:
                currentTab = "invoice"
                jsonLd = DefaultTemplate.defaultJsonLd.invoice
                docInfo = DefaultTemplate.defaultDocInfo.invoice
                break
        }
        let data = {
            jsonLd,
            currentTab,
            docInfo,
            // onLinkClickedCallback: onLinkClicked
        }
        return ReceiptUtils.viewTemplate(data, previewFile)
    }

    onOk = () => {
        const previewFile = this.props.previewFile
        const type = this.props.type
        this.props.onOk(type, previewFile)
    }

    getFooter = () => {
        let footer = [<Button key="cancel" onClick={this.props.onClose} >Cancel</Button>]
        if (!this.props.disableOk) {
            footer.push(<Button key="submit" type="primary" onClick={this.onOk}>Apply</Button>)
            footer.unshift(<Button key="back" onClick={this.props.onBack}>Back</Button>)
        }
        return footer
    }

    render() {
        const footer = this.getFooter()
        return (
            <>
                <Modal centered
                    width={800}
                    className="preview"
                    title={`Templates Preview`}
                    visible={this.state.templatePreview}
                    closable={true}
                    onCancel={this.props.onClose}
                    footer={footer}
                >
                    <div dangerouslySetInnerHTML={{ __html: this.renderTemplate() }}></div>
                </Modal>

            </>
        )
    }
}
export default PreviewTemplate;