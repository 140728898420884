import ParamConnector from '../../../param-connector'
import Charts from '../charts'
import { chartConfig } from '../config/defaultGraphConfig';
import SellerOverView from './receipt/seller-overview'
import BuyerOverView from './receipt/buyer-overview';
import SupplierDelivery from './receipt/purchase-supplierdelivery';
import CustomerInsights from './receipt/sales-customerinsights';
import * as networkUtils from '../../../util/utils';
import Console from '../../../logger'

class ReturnsEvents {

    constructor(paramId) {
        this.charts = Charts.getInstance();
        this.paramID = paramId;
        this.customerInsights = new CustomerInsights(paramId);
    }

    enableRealTimeUpdateForReturns(enable) {
        this.realTimeReturns = enable;
    }
    registerEvents() {

        const that = this;
        ParamConnector.getInstance().getDB().returnsDBEvents.registerOnRequestUpdated((data, err) => {
            Console.info("Got the registerOnRequestUpdated events")
            if (err) {
                Console.error("[Returns]-registerOnRequestUpdated Unable to get the data from events");
                return;
            }
            that.onReturnsUpdate();
        })

        ParamConnector.getInstance().getDB().returnsDBEvents.registerOnReturnRequestCreated((data, err) => {
            Console.info("Got the registerOnReturnRequestCreated events")
            if (err) {
                Console.error("[Returns]-registerOnReturnRequestCreated Unable to get the data from events");
                return;
            }
            that.onReturnsUpdate();
        })

        ParamConnector.getInstance().getDB().returnsDBEvents.registerOnReturnRequestAccepted((data, err) => {
            Console.info("Got the registerOnReturnRequestAccepted events")
            if (err) {
                Console.error("[Returns]-registerOnReturnRequestAccepted Unable to get the data from events");
                return;
            }
            that.onReturnsUpdate();
        })

        ParamConnector.getInstance().getDB().returnsDBEvents.registerOnReturnRequestRejected((data, err) => {
            Console.info("Got the registerOnReturnRequestRejected events")
            if (err) {
                Console.error("[Returns]-registerOnReturnRequestRejected Unable to get the data from events");
                return;
            }
            that.onReturnsUpdate();
        })

        ParamConnector.getInstance().getDB().returnsDBEvents.registerOnOrderRequestCreated((data, err) => {
            Console.info("Got the registerOnOrderRequestCreated events")
            if (err) {
                Console.error("[Returns]-registerOnOrderRequestCreated Unable to get the data from events");
                return;
            }
            that.onReturnsUpdate();
        })

        ParamConnector.getInstance().getDB().returnsDBEvents.registerOnOrderRequestAccepted((data, err) => {
            Console.info("Got the registerOnOrderRequestAccepted events")
            if (err) {
                Console.error("[Returns]-registerOnOrderRequestAccepted Unable to get the data from events");
                return;
            }
            that.onReturnsUpdate();
        })

        ParamConnector.getInstance().getDB().returnsDBEvents.registerOnOrderRequestRejected((data, err) => {
            Console.info("Got the registerOnOrderRequestRejected events")
            if (err) {
                Console.error("[Returns]-registerOnOrderRequestRejected Unable to get the data from events");
                return;
            }
            that.onReturnsUpdate();
        })
    }

    unRegisterEvents() {

    }

    onReturnsUpdate() {
        this.customerInsights.getOverview(true);
    }
}
export default ReturnsEvents;