import React from "react";
import { connect } from "react-redux";
import { Col, Row, Icon, Form, Modal, Input, Divider, Button, message, Avatar, Card } from "antd";
import PhoneInput from 'react-phone-input-2';
import ReactFlagsSelect from 'react-flags-select';

import * as jsPDF from 'jspdf'
import BaseComponent from "../BaseComponent";
import Auxiliary from "../../../util/Auxiliary";
// import Widget from "../../../components/Widget";
// import About from "../../../components/profile/About/index";
import Contact from "../../../components/profile/Contact/index";
import WalletFunction from '../../../wallet/index';
import * as Utils from "../../../util/utils";
import Console from '../../../logger/index';
import * as ConfigObj from '../../../param-network/config.json';
import NetworkBridge from "../../../util/network-bridge";
import profileBg from "assets/images/bg-profile.jpg";
import EventManager from '../../../event-manager/event-manager.js';
import Analytics from '../../../analytics';
import 'react-phone-input-2/lib/style.css';
import './index.less';
import 'react-flags-select/css/react-flags-select.css';
import csvToJsonLd from "../../../IIIrd-party/india/e-invoice/parsers/csv-jsonld";
import InvoiceProvider from "../../../IIIrd-party/invoice-provider";
import { setThemeType, onNavStyleChange } from "../../../appRedux/actions/Setting";

// const { Option } = Select;
const confirm = Modal.confirm;

const CollectionCreateForm = Form.create({ name: 'Edit_profile' })(
  class extends React.Component {
    handleChange = (value) => {
    }

    verifyFaxNumber = (rule, value, callback) => {
      let faxno = /^\d+$/;
      if (value && !value.match(faxno)) {
        callback("Please input a valid fax number");
      }
      else {
        callback();
      }
    }

    render() {
      const { visible, onCancel, onCreate, form, onSelectCountry } = this.props;
      const { getFieldDecorator } = form;
      let profile = JSON.parse(Utils.getFromLocalStorage('profile'));
      Console.debug('profile edit form', profile)
      // this.configObj = ConfigObj.privateNodes;
      return (
        <Modal className="profile__editModal"
          visible={visible}
          title="Edit Profile"
          okText="Save"
          onCancel={() => { form.resetFields(); onCancel(); }}
          onOk={onCreate}  >
          <Form layout="vertical" className="edit-contact-form">
            <Form.Item label="Param Id">
              {getFieldDecorator('paramId', {
                rules: [{
                  required: true,
                  message: 'Please input your param Id!',
                }],
                initialValue: profile.identifier
              })(<Input disabled />)
              }
            </Form.Item>
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [{
                  required: true,
                  message: 'Please input your name!',
                }],
                initialValue: profile.name
              })(<Input />)
              }
            </Form.Item>
            <Form.Item label="Phone Number" className="phoneInput">
              {getFieldDecorator('telephone', {
                rules: [{
                  required: true,
                  message: 'Please input your phone number!'
                }],
                initialValue: profile.telephone
              })(
                <PhoneInput
                  placeholder="Please provide number with country code"
                  enableSearchField={true}
                  defaultCountry={'in'}
                  countryCodeEditable={true}
                />
              )}
            </Form.Item>
            <Form.Item label="E-mail">
              {getFieldDecorator('email', {
                rules: [{
                  type: 'email',
                  required: true,
                  message: `The input is not valid Email`,
                }],
                initialValue: profile.email
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Taxation Id">
              {getFieldDecorator('taxNo', {
                rules: [{
                  required: true,
                  message: 'Please input your taxation id!'
                }],
                initialValue: profile.taxId
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Fax No">
              {getFieldDecorator('faxNo', {
                rules: [{
                  required: false
                },
                {
                  validator: this.verifyFaxNumber
                }],
                initialValue: profile.faxNumber
              })(<Input />)}
            </Form.Item>
            <Divider dashed />

            <Form.Item label="Organisation">
              {getFieldDecorator('organisation', {
                rules: [{
                  required: true,
                }],
                initialValue: profile.worksFor.name
              })(<Input />)
              }
            </Form.Item>
            <Form.Item label="Street Address">
              {getFieldDecorator('streetAddress', {
                rules: [{
                  required: false,
                }],
                initialValue: profile.address.streetAddress
              })(<Input />)
              }
            </Form.Item>
            <Form.Item label="Locality">
              {getFieldDecorator('addressLocality', {
                rules: [{
                  required: false,
                }],
                initialValue: profile.address.addressLocality
              })(<Input />)
              }
            </Form.Item>
            <Form.Item label="City">
              {getFieldDecorator('city', {
                rules: [{
                  required: false,
                }],
                initialValue: profile.city
              })(<Input />)
              }
            </Form.Item>
            <Form.Item label="State/Province">
              {getFieldDecorator('addressRegion', {
                rules: [{
                  required: false,
                }],
                initialValue: profile.address.addressRegion
              })(<Input />)
              }
            </Form.Item>
            <Form.Item label="Zip/Postal Code">
              {getFieldDecorator('postalCode', {
                rules: [{
                  required: false,
                }],
                initialValue: profile.address.postalCode
              })(<Input type="number" min="0" />)
              }
            </Form.Item>
            <Form.Item label="Logo URL">
              {getFieldDecorator('logoUrl', {
                rules: [{
                  required: false,
                }],
                initialValue: profile.logoUrl
              })(<Input />)
              }
            </Form.Item>
            <Form.Item label="Country" className="country-user">
              {getFieldDecorator('country', {
                rules: [{
                  required: false,
                }],
                initialValue: profile.nationality.name
              })(
                <ReactFlagsSelect
                  searchable={true}
                  onSelect={onSelectCountry}
                  defaultCountry={profile.nationality.name}
                  searchPlaceholder="Search for a country"
                  className="country"
                  style={{ width: '77%' }}
                />
              )
              }
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);


class UserProfile extends BaseComponent {
  constructor(props) {
    super(props);
    Analytics.getInstance().trackPageview();
  }
  initStates() {
    this.state = {
      showLoader: false,
      profileDetails: {},
      visible: false,
      isBackupModal: false,
      password: '',
      passPhrase: '',
      country: ''
    };
    this.configObj = ConfigObj.privateNodes;
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateProfile();
  }

  toggleModal = () => {
    this.setState({ visible: !this.state.visible });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      values.country = this.state.country;
      let jsonLd = Utils.convertProfileToJsonLd(values)
      // Utils.setLocalStorage('profile', JSON.stringify(values))
      Utils.setLocalStorage('profile', JSON.stringify(jsonLd))
      EventManager.getInstance().emitEvent('onProfileUpdate', values)
      this.updateProfile()
      message.success('Profile Updated successfully')
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  onSelectCountry = (value) => {
    this.setState({ country: value })
  }

  updateProfile = () => {
    let profile = JSON.parse(Utils.getFromLocalStorage('profile'));
    let aboutInfo = {
      param_id: Utils.getFromLocalStorage("param_id"),
      name: profile.name ? profile.name : '-',
      organisation: profile.worksFor.name ? profile.worksFor.name : '-',
      telephone: profile.telephone ? profile.telephone : '-',
      email: profile.email ? profile.email : '-',
      faxNumber: profile.faxNumber ? profile.faxNumber : '-',
      taxNo: profile.taxId ? profile.taxId : '-',
      logoUrl: profile.logoUrl ? profile.logoUrl : '',
      address: profile.address,
      city: profile.city ? profile.city : '',
      country: profile.nationality.name
    };
    this.setState({ profileDetails: aboutInfo });
  }

  logout = () => {
    let that = this;
    confirm({
      title: 'Do you want to switch account?',
      content: '',
      okText: 'Confirm',
      onOk() {
        Utils.clearLocalStorage();
        that.props.setThemeType('THEME_TYPE_LITE')
        if (document.body.classList.contains('dark-theme')) {
          document.body.classList.remove('dark-theme');
        }
        NetworkBridge.getProfileManager().resetConnection();
        that.props.history.push('/');
      },
      onCancel() { },
    });

  }

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value })
  }

  downloadAsPdf = () => {
    // let mnemonic = Utils.getFromLocalStorage('encryptedMnemonic');
    let mnemonic = this.state.passPhrase;
    var doc = new jsPDF()
    doc.setFontSize(10);
    doc.text(mnemonic, 10, 10)
    doc.save('paramId-Restore.pdf')
    message.success('12 phrase downloaded');
    return true;
  }

  handleOk = e => {
    // console.log(e);
    if (this.state.password.length === 0) {
      message.warning("Please enter your password");
      return;
    }
    WalletFunction.getPassPhrase(this.state.password).then(passPhrase => {
      if (passPhrase === null) {
        message.warning("Not available with old accounts");
        return;
      }
      this.setState({ passPhrase: passPhrase, isBackupModal: false, password: '' });
      this.downloadAsPdf()
    }).catch(err => {
      Console.error(err);
      message.error("Wrong Password");
    })
  };

  toggleBackupModal = () => {
    this.setState({ isBackupModal: !this.state.isBackupModal, password: '' })
  }

  renderUserProfileHeader = () => {
    return (
      <div
        className="gx-profile-banner"
        style={{ backgroundImage: `url(${profileBg})` }}
      >
        <div className="gx-profile-container">
          <div className="gx-profile-banner-top">
            <div className="gx-profile-banner-top-left">
              <div className="gx-profile-banner-avatar">
                <Avatar className="gx-size-100 gx-pointer" style={{ backgroundColor: '#ffffff' }}>
                  {
                    this.state.profileDetails.logoUrl ?
                      <img src={this.state.profileDetails.logoUrl} style={{ maxWidth: '5rem' }} />
                      :
                      <i className="icon icon-company profile__header_companyicon" />
                  }
                </Avatar>
              </div>
              <div
                className="gx-profile-banner-avatar-info"
                style={{ color: "black" }}
              >
                <h2
                  className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-medium"
                  style={{ color: "black" }}
                >
                  {this.state.profileDetails.organisation}
                </h2>
                <div className="gx-fs-lg">

                  <span>{this.state.profileDetails.name}</span>
                  <span>
                    {
                      this.state.profileDetails.jobTitle ?
                        this.state.profileDetails.jobTitle
                        :
                        null
                    }
                  </span>
                </div>

              </div>
            </div>
          </div>
          <div className="profile__action">
            <span
              className="gx-link gx-profile-setting"
              style={{ color: "#6236ff", marginRight: "1em" }}
              onClick={this.toggleModal}
            >
              <i className="icon icon-edit gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
              <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">
                Edit Profile
            </span>
            </span>
            <span
              className="gx-link gx-profile-setting"
              style={{ color: "#6236ff", marginRight: "1em" }}
              onClick={this.toggleBackupModal}>
              <Icon type="download" id="profile-dark-theme" className="icon icon-logout gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
              <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">
                Backup 12 phrase
                </span>
            </span>
            <span
              className="gx-link gx-profile-setting"
              style={{ color: "#6236ff" }}
              onClick={this.logout}
            >
              <Icon type="logout" id="profile-dark-theme" className="icon icon-logout gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle" />
              <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">
                Switch Account
            </span>
            </span>
          </div>


          <Modal
            title="12 phrase backup"
            visible={this.state.isBackupModal}
            footer={
              <div>
                <Button onClick={this.toggleBackupModal}>Cancel</Button>
                <Button type="primary" disabled={!this.state.password.length} onClick={this.handleOk}>Ok</Button>
              </div>
            }
            onOk={this.handleOk}
            onCancel={this.toggleBackupModal}
          >
            <Input.Password type="password" placeholder="Enter your password" value={this.state.password} onChange={this.handlePasswordChange} />
          </Modal>

          <CollectionCreateForm
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.visible}
            onCancel={this.toggleModal}
            onCreate={this.handleCreate}
            onSelectCountry={this.onSelectCountry}

          />

        </div>
      </div>
    );
  };

  renderUserParamInfo = () => {
    return (
      <Contact
        paramId={
          this.state.profileDetails.param_id
            ? this.state.profileDetails.param_id
            : "0x"
        }
      />
    );
  };

  renderAboutUser = () => {
    const { profileDetails } = this.state
    console.log("Profile Details", profileDetails);
    return (
      <Row className="profile__aboutCard_row">
        <Col span={8} xs={12} className="profile__aboutCard_column">
          <Row>
            <Col>
              <i className="icon icon-phone profile__aboutCard_icon" />
            </Col>
            <Col>
              <label className="profile__aboutCard_label">Phone</label>
              <p>{profileDetails.telephone}</p>
            </Col>
          </Row>
        </Col>
        <Col span={8} xs={12} className="profile__aboutCard_column">
          <Row>
            <Col>
              <i className="icon icon-email profile__aboutCard_icon" />
            </Col>
            <Col>
              <label className="profile__aboutCard_label">Email</label>
              <p>{profileDetails.email}</p>
            </Col>
          </Row>
        </Col>
        <Col span={8} xs={12} className="profile__aboutCard_column">
          <Row>
            <Col>
              <i className="icon icon-revenue-new profile__aboutCard_icon" />
            </Col>
            <Col>
              <label className="profile__aboutCard_label">Tax</label>
              <p>{profileDetails.taxNo ? profileDetails.taxNo : '-'}</p>
            </Col>
          </Row>
        </Col>
        <Col span={8} xs={12} className="profile__aboutCard_column">
          <Row>
            <Col>
              <i className="icon icon-location profile__aboutCard_icon" />
            </Col>
            <Col>
              <label className="profile__aboutCard_label">Address</label>
              {
                <div>
                  <p>{profileDetails.address && profileDetails.address.streetAddress}</p>
                  <p>{profileDetails.address && profileDetails.address.addressLocality}</p>
                  <p>{profileDetails.city}</p>
                  <p>{profileDetails.address && profileDetails.address.addressRegion}</p>
                  <p>{profileDetails.country}</p>
                  <p>{profileDetails.address && profileDetails.address.postalCode}</p>
                </div>
              }


            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  showFile = (e) => {
    let invoiceProvider = InvoiceProvider.getInvoiceProvider();
    let invoiceManager = invoiceProvider.getInvoiceManager();
    invoiceManager.importReceipts("csv", e, this.getImportCallbacks()).catch(e => {
      Console.error(e)
    })
  }

  getImportCallbacks() {
    return {
      onProgress: (data) => {
        Console.debug("onProgress", data);

      },
      onConflict: (obj) => {
        // obj.invoiceId
        //Show dialog
        // obj.conf.replace();
        // obj.conf.skip();
        Console.debug("onConflict", obj);
      },
      onStatus: (data) => {
        Console.debug("onStatus", data);
      },
      onComplete: (data) => {
        Console.debug("onComplete", data);

      },
      onError: (data) => {
        Console.error("onError", data);

      },
      onDone: (data) => {
        Console.debug("onDone", data);

      },
      onAbort: (data) => {
        Console.debug("onAbort", data);

      },
      onFileSkip: (data) => {
        Console.debug("onAbort", data);
      },
      onFiles: (data) => {
        Console.debug("onAbort", data);
      },
    }
  }

  renderMainContent() {
    return (
      <Auxiliary>
        {this.renderUserProfileHeader()}
        <div className="gx-profile-content">
          <Row>
            <Col xl={16} lg={14} md={14} sm={24} xs={24} className="profile__aboutCard">
              <Card title={<h2 style={{ marginBottom: 0 }}>About</h2>} styleName="gx-card-profile">
                {this.renderAboutUser()}
              </Card>
            </Col>

            <Col xl={8} lg={10} md={10} sm={24} xs={24} className="profile__qrCard">
              {this.renderUserParamInfo()}
            </Col>
          </Row>
        </div>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { themeType } = settings;
  return { themeType }
};
export default connect(mapStateToProps, { setThemeType })(UserProfile);