import ParserUtils from './ParserUtils';
import NetworkBridge from '../util/network-bridge';
import ECIES from '../util/ecies.js';
import cryptoRandomString from 'crypto-random-string';
import * as Utils from '../util/utils';
class ContactParser {

    static getAddress(addressData, jsonLd) {
        let address = {
            "@type": "PostalAddress"
        }
        let addressKeys = ["addressLocality", "addressRegion", "postalCode", "streetAddress"];
        let addressValues = [addressData.addressLocality, addressData.addressRegion, addressData.postalCode, addressData.streetAddress];
        for (let i = 0; i < addressKeys.length; i++) {
            address = ParserUtils.getCorrectData(addressValues[i], addressKeys[i], address);
        }
        jsonLd["address"] = address;
        return jsonLd;
    }

    static getContactPoint(contactData, jsonLd) {
        let contactPoint = {
            "@type": "ContactPoint"
        }
        let contactPointKeys = ["telephone", "contactType", "contactOption", "areaServed"];
        let contactPointValues = [contactData.telephone, contactData.contactType, contactData.contactOption, contactData.areaServed];
        for (let index = 0; index < contactPointKeys.length; index++) {
            contactPoint = ParserUtils.getCorrectData(contactPointValues[index], contactPointKeys[index], contactPoint);
        }
        jsonLd['contactPoint'] = contactPoint;
        return jsonLd;
    }

    static getHasOccupation(occupationData, jsonLd) {
        let hasOccupation = [];
        for (let i = 0; i < occupationData.length; i++) {
            let occupation = {
                "@type": "Occupation"
            }
            occupation = ParserUtils.getCorrectData(occupationData[i].name.toUpperCase(), 'name', occupation);
            occupation = ParserUtils.getCorrectData(occupationData[i].educationRequirements, 'educationRequirements', occupation);
            hasOccupation.push(occupation);
        }
        jsonLd['hasOccupation'] = hasOccupation;
        return jsonLd;
    }

    static getJobTitle(jobTitleData, jsonLd) {
        let jobTitle = {
            "@type": "DefinedTerm"
        }
        jobTitle = ParserUtils.getCorrectData(jobTitleData.inDefinedTermSet, 'inDefinedTermSet', jobTitle);
        jobTitle = ParserUtils.getCorrectData(jobTitleData.termCode, 'termCode', jobTitle);
        jobTitle = ParserUtils.getCorrectData(jobTitleData.name, 'name', jobTitle);
        jobTitle = ParserUtils.getCorrectData(jobTitleData.url, 'url', jobTitle);
        jsonLd['jobTitle'] = jobTitle;
        return jsonLd;
    }

    static getOrganization(organizationId, jsonLd) {
        let organization = {
            "@type": "Organization"
        }
        organization = ParserUtils.getCorrectData(organizationId, 'name', organization);
        jsonLd["worksFor"] = organization;
        return jsonLd;
    }

    static getCountry(countryName, jsonLd) {
        let country = {
            "@type": "Country"
        }
        country = ParserUtils.getCorrectData(countryName, 'name', country);
        jsonLd["nationality"] = country;
        return jsonLd;
    }

    static getContactJsonLd(contactData, mode, id) {
        let jsonLd = {
            "@context": "http://schema.org/",
            "@type": "Person",
            "name": contactData.name,
            "identifier": contactData.paramId
        }
        jsonLd = ContactParser.getAddress(contactData.address, jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.birthDate, "birthDate", jsonLd);
        jsonLd = ContactParser.getContactPoint(contactData, jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.gender, 'gender', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.globalLocationNumber, 'globalLocationNumber', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.taxId, 'taxId', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.vatId, 'vatId', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.faxNumber, 'faxNumber', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.email, 'email', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.telephone, 'telephone', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.url, 'url', jsonLd);
        jsonLd = ParserUtils.getCorrectData(contactData.internalId, 'internalId', jsonLd);
        jsonLd = ContactParser.getHasOccupation(contactData.occupation, jsonLd);
        jsonLd = ContactParser.getJobTitle(contactData.jobTitle, jsonLd);

        jsonLd = ContactParser.getOrganization(contactData.organisation, jsonLd);
        jsonLd = ContactParser.getCountry(contactData.country, jsonLd);
        console.log(jsonLd);
        return jsonLd;
    }

    static addContact(contactData, mode, id) {
        let jsonLd = ContactParser.getContactJsonLd(contactData, mode, id)

        //TODO
        let privateFor = contactData.privateFor || "";

        if (contactData.paramId) {
            return Utils.getPublic(contactData.paramId).then(publicKey => {
                jsonLd["publicKey"] = publicKey;
                jsonLd = JSON.stringify(jsonLd);

                contactData.txnType = '2';
                if (contactData.txnType === '2') {
                    let privateKey = Utils.getPrivateKey();
                    let publicKey = Utils.getPublicKey();
                    jsonLd = ECIES.encrypt(privateKey, publicKey, jsonLd);
                }

                // For graphQl
                let inputObject = {}
                if (contactData.txnType === '2') {
                    inputObject.data = jsonLd
                }
                else {
                    inputObject.contact = jsonLd
                }
                let txnType = Utils.getTxnTypeForGraphQL(contactData.txnType)


                switch (mode) {
                    case 'add':
                        return NetworkBridge.getContactManager().doesInternalIdExist(contactData.internalId, contactData.paramId).then(res => {
                            if (res) {
                                return Promise.reject("Internal Id already exists");
                            }
                            return NetworkBridge.getContactManager().addContact(contactData.paramId, inputObject, txnType);
                        })
                    case 'edit':
                        return NetworkBridge.getContactManager().updateContact(id, inputObject, txnType);
                    case 'accept':
                        return NetworkBridge.getContactManager().acceptedInvite(contactData.paramId, jsonLd, contactData.txnType, contactData.contactId, contactData.randomString);
                    default:
                        break;
                }
            })
        }

        contactData.txnType = '2';
        let randomString = cryptoRandomString({ length: 32, type: 'base64' });
        jsonLd["randomString"] = randomString;
        jsonLd = JSON.stringify(jsonLd);
        if (contactData.txnType === '2') {
            let privateKey = Utils.getPrivateKey();
            let publicKey = Utils.getPublicKey();
            jsonLd = ECIES.encrypt(privateKey, publicKey, jsonLd);
        }
        return NetworkBridge.getContactManager().draftContact(jsonLd, contactData.txnType);
    }

}

export default ContactParser;