import Charts from '../../charts'
import { purchase } from '../../channel-config.json'
import Console from '../../../../logger'
import RedisClientSocket from '../../client-lib/redis-client';
import EventUtils from '../utils/eventUtils';

class BuyerOverView {

    constructor(paramID) {
        this.paramID = paramID;
    }

    publishAllOverView() {
        Console.info("BuyerOverView")

        this.getSummary().then(result => {
            return this.getSuppliers();
        }).then(res => {
            return this.getPurchases()
        }).then(res => {
            return this.overView();
        }).then(res => {
            return this.getCatalogueData()
        }).then(res => {
            return this.getContractDueForRenewal()
        }).then(res => {
            return this.getDisputes()
        }).then(res => {
            return this.getGRNCount()
        }).then(res => {
            return this.getGoodsReceivedButNotInvoiced()
        }).then(res => {
            return this.getTopPurchases('category', "price", '', '')
        }).then(res => {
            return this.getSavings()
        }).then(res => {
            return this.getTopSavingOpportunity()
        }).then(() => {
            return this.getAveragePurchaseCycle()
        }).then(() => {
            return this.getTopQuantityDisputes()
        }).catch(err => {
            Console.error("error", err);
        })
    }

    getSummary(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.dashboard}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getSummary()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getSuppliers(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.suppliers}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getSuppliers()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getPurchases(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.purchases}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getPurchases()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    overView(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.overview}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn', 'vendor'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getOverview()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getCatalogueData(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.catalogue}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'catalogue'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getCatalogueData()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getTopPurchases(groupBy, action, fromDate, toDate, isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.topPurchases}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'catalogue'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getTopPurchases(groupBy, action, fromDate, toDate)
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getContractDueForRenewal(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.contractDueForRenewal}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getContractDueForRenewal()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getDisputes(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.disputes}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getDisputes()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getGRNCount(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.GRNPending}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getGRNCount()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getGoodsReceivedButNotInvoiced(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.goodsReceivedButNotInvoiced}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getGoodsReceivedButNotInvoiced()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getSavings(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.savingsMissed}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getSavings()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getTopSavingOpportunity(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.topSavingOpportunity}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getTopSavingOpportunity()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getAveragePurchaseCycle(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.averagePurchaseCycleTime}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getAverageCycleTime()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getTopQuantityDisputes(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.topQuantityDispute}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'grn'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getTopQuantityDisputes()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("error", err)
        })
    }

    getAllOpenOrPartialInvoices(isFromEvent) {
        let channelName = `${this.paramID}/${purchase.overview.getAllOpenOrPartialInvoices}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().purchasesOverview.getAllOpenOrPartialInvoices()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            console.log("error", err)
        })
    }
}

export default BuyerOverView;