import React, { Component } from 'react';
import { Row, Col, Typography, Icon } from 'antd';
import QRCode from 'qrcode.react';
const { Paragraph } = Typography;

class ShowIRNQR extends Component {
    render() {
        const {irnGenerated, irn} = this.props;
        if (!irnGenerated) {
            return <div className="generate-irn">
                <Row>
                    <Col xl={6} lg={6} md={6} sm={24}>
                        <QRCode value="Click to generate" size={65} fgColor="rgb(0,0,0, 0.3)" />
                    </Col>
                    <Col xl={18} lg={18} md={18} sm={24}>
                        <p style={{ marginBottom: '1rem', fontSize: '0.75rem' }}>Authenticate with GSTN IRP Portal</p>
                        <div className='irn-box-generate' onClick={this.props.generateIRN}><span className="generate-irn-button-text">Generate IRN </span><Icon type="check-circle" theme="filled" style={{ color: '#607d8b', float: 'right', fontSize: '1rem' }} /></div>
                    </Col>
                </Row>
            </div>
        }
        return (
            <div className="generate-irn">
                <Row>
                    <Col xl={6} lg={6} md={6} sm={24}>
                        <QRCode value={irn} size={65} />
                    </Col>
                    <Col xl={18} lg={18} md={18} sm={24}>
                        <p style={{ marginBottom: '0.4rem' }}>Unique IRN Generated</p>
                        <p style={{ marginBottom: '0.4rem', fontSize: '0.6rem', color: '#9c9c9c' }}>Using IRP Portal</p>
                        <div className='irn-box'>
                            <Row>
                                <Col xl={18} lg={18} md={18}>
                                    <Paragraph ellipsis className="irn">{irn}</Paragraph>
                                </Col>
                                <Col xl={4} lg={4} md={4}>
                                    <Icon type="check-circle" theme="filled" style={{ color: '#6236ff', float: 'right', fontSize: '1rem', marginTop: '0.15rem' }} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default ShowIRNQR;