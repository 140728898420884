class Contacts {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    /**
     * 
     * @param {*} string search string
     * @param {*} options Default is "2"-All(name or id) 
     * @param {*} owner Owner of the contacts
     */
    // TODO: indexing needs to implement
    search(string, owner, options = "2") {
        return this.mongoDBConnector.invoke('contacts', "search", string, owner, options);
    }

    getNameAndId(owner) {
        return this.mongoDBConnector.invoke('contacts', "getNameAndId", owner);
    }

    filterContactsByFilter(filter, string, data) {
        return this.mongoDBConnector.invoke('contacts', "filterContactsByFilter", filter, string, data);
    }

    getAllTags(owner) {
        return this.mongoDBConnector.invoke('contacts', "getAllTags", owner);
    }

    getContactsFromTag(tag, owner) {
        return this.mongoDBConnector.invoke('contacts', "getContactsFromTag", tag, owner);
    }

    // TODO: facet
    getAllContacts(owner) {
        return this.mongoDBConnector.invoke('contacts', "getAllContacts", owner);

    }

    getContactByParamId(paramId, owner) {
        return this.mongoDBConnector.invoke('contacts', "getContactByParamId", paramId, owner);
    }

    getContactSummaryByParamId(paramId, owner) {
        return this.mongoDBConnector.invoke('contacts', "getContactSummaryByParamId", paramId, owner);
    }

    getContactNameByParamId(paramId) {
        return this.mongoDBConnector.invoke('contacts', "getContactNameByParamId", paramId);

    }

    getContactByInternalId(internalId, owner) {
        return this.mongoDBConnector.invoke('contacts', "getContactByInternalId", internalId, owner);
    }

    getContact(contactId) {
        return this.mongoDBConnector.invoke('contacts', "getContact", contactId);
    }

    getContactSummary(contactId) {
        return this.mongoDBConnector.invoke('contacts', "getContactSummary", contactId);
    }

    getAllItemsForExport() {
        return this.mongoDBConnector.invoke('contacts', "getAllItemsForExport");
    }

    doesExist(contactId) {
        return this.mongoDBConnector.invoke('contacts', "doesExist", contactId);
    }

    doesInternalIdExist(internalId, owner) {
        return this.mongoDBConnector.invoke('contacts', "doesInternalIdExist", internalId, owner);

    }

    addContact(contactId, jsonLd, owner, status, paramId) {
        return this.mongoDBConnector.invoke('contacts', "addContact", contactId, jsonLd, owner, status, paramId);
    }

    addTags(contactId, occupation) {
        return this.mongoDBConnector.invoke('contacts', "addTags", contactId, occupation);
    }

    updateContact(contactId, jsonLd, owner, status, paramId) {
        return this.mongoDBConnector.invoke('contacts', "updateContact", contactId, jsonLd, owner, status, paramId);
    }
}
export default Contacts;