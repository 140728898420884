class DonutFormatter {

    static suppliersDonut(dataFromDb) {
        let data = [];
        for (let index in dataFromDb) {
            if (index === 'total') {
                continue
            }
            data.push({
                type: index,
                value: dataFromDb[index]
            })
        }
        return data;
    }

    static invoice(dataFromDb) {
        let data = [];
        for (let index in dataFromDb) {
            if (index === 'total') {
                continue
            }
            data.push({
                type: index,
                value: dataFromDb[index]
            })
        }
        return data;
    }


}

export default DonutFormatter;