class Overview {
    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }
    /**
        * Ref No.: PURCHASE001
        * Graph Name: Welcome Data
    */
    getSummary() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getSummary")
    }

    /**
        * Ref No.: PURCHASE003
        * Graph Name: Overview Data
    */
    getOverview() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getOverview")
    }

    /**
        * Ref No.: PURCHASE004
        * Graph Name: Catalogue Data
    */
    getCatalogueData() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getCatalogueData")
    }

    /**
        * Ref No.: PURCHASE002/1
        * Graph Name: Suppliers Graph
    */
    getSuppliers() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getSuppliers")
    }

    /**
        * Ref No.: PURCHASE005
        * Graph Name: Top Purchases Graph
        * action : 'price' or 'orderQuantity'
        * groupBy: category/subcategory/items
        * fromDate: From Date in milliseconds
        * toDate: To Date in milliseconds
    */
    getTopPurchases(groupBy, action = 'price', fromDate, toDate) {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getTopPurchases", groupBy, action = 'price', fromDate, toDate)
    }

    /**
        * Ref No.: PURCHASE006
        * Graph Name: Contract Due For Renewal Graph
    */
    getContractDueForRenewal() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getContractDueForRenewal")
    }

    /**
        * Ref No.: PURCHASE007
        * Graph Name: Disputes Graph
    */
    getDisputes() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getDisputes")
    }

    /**
        * Ref No.: PURCHASE002/2
        * Graph Name: Purchases Graph
    */
    getPurchases() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getPurchases")
    }

    /**
        * Ref No.: PURCHASE009/1
        * Graph Name: GRN Pending Data
    */

    // TODO: invoiced
    getGRNCount() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getGRNCount")
    }

    /**
        * Ref No.: PURCHASE009/2
        * Graph Name: GRN Received But Not Invoiced Data
    */
    getGoodsReceivedButNotInvoiced() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getGoodsReceivedButNotInvoiced")
    }

    getItemsAndSuppliersForGrns(grnsWithoutInvoice, goodsReceivedButNotInvoiced) {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getItemsAndSuppliersForGrns", grnsWithoutInvoice, goodsReceivedButNotInvoiced)
    }

    getGrnData(grnId) {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getGrnData", grnId);
    }

    // TODO: optimize
    getSavings() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getSavings")
    }

    getTopSavingOpportunity() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getTopSavingOpportunity")
    }

    getTopQuantityDisputes() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getTopQuantityDisputes")
    }

    getAverageCycleTime() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getAverageCycleTime")
    }

    getAllOpenOrPartialInvoices() {
        return this.mongoDBConnector.invoke("reports", "purchasesOverview", "getAllOpenOrPartialInvoices")
    }
}

export default Overview;
// module.exports = Overview;