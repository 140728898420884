class FormatData {
    static replaceAll(target, search, replacement) {
        return target.replace(new RegExp(search, 'g'), replacement);
    }
    static removeAngular(string) {
        if (!string) {
            return string;
        }
        if (string.charAt(0) === '<' && string.charAt(string.length - 1) === '>') {
            return string.substring(1, string.length - 1);
        }
        return string;
    }
    static replaceWithPrimaryKey(data) {
        let object = JSON.stringify(data);
        object = object.replace(/"(@id)"\s*:\s*"([\W]*[^,|}]*)"/ig, '"primaryKey":"<$2>","@id":"$2"');
        return JSON.parse(object);
    }
}

export default FormatData;
// module.exports = formatData;
// let data = {
//     "@id": "1234234",
//     "@context": "http://schema.org/",
//     "@type": "Person",
//     "name": "Param",
//     "address": {
//         "@type": "PostalAddress",
//         "@id": "lebgrtiegri",
//         "streetAddress": "Bangalore"
//     },
//     "contactPoint": {
//         "@type": "ContactPoint",
//         "@id": "234523fc",
//         "telephone": "7894561230"
//     },
//     "faxNumber": "456789",
//     "email": "ashish@gmail.com",
//     "telephone": "7894561230"
// };
// let newData = formatData.replaceWithPrimaryKey(data);
// console.log(newData);