import React from 'react';
import { Row, Col, Button, Card, Icon, message, Modal, Select, Input, Switch, Radio, Upload } from 'antd';
import ColumnsConfig from './columns-config';
import DocumentTemplate from './documentTemplate';
import TabSettings from "./tab-configuration"
import * as Utils from '../../../util/utils';


const { Option } = Select;
const { confirm } = Modal;


class ReceiptSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={12} xs={24} style={{ display: 'flex' }}><ColumnsConfig /></Col>
                    <Col md={12} xs={24}> <DocumentTemplate /></Col>
                    <Col md={12} xs={24}> <TabSettings /></Col>
                </Row>
            </>
        )
    }
}
export default ReceiptSetting;