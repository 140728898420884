// import RestoreVersionControl from './versionControl';
import RestoreVendorManagement from './vendor';
import RestoreItems from './items';
import RestoreReceipts from './receipts';
import RestoreReturns from './returns';
import RestoreTemplateRepo from './template-repo';
import TemplateConsensusRestore from './template-cons';
import RestoreSubscribers from './subscriber';
import RestoreContacts from './contacts';
// import ParamConnector from '../../../param-connector';
import Console from '../../../logger';
// import Database from '../../index';

class Sync {
    static sync(options, callback) {
        callback.onProgress("contact", 0);
        Console.info(`[MongoDB] Trying to get all contacts metainfo from network.`);
        callback.onProgress("contact", 1);
        return RestoreVendorManagement.restoreAllInvitationsAndEvalRequests(options.address, '', callback).then(() => {
            return RestoreVendorManagement.restoreAllVendorTemplates(options.address, '', callback)
        }).then(res => {
            return RestoreContacts.restoreAllContacts('', options.address, callback)
        }).then(() => {
            return RestoreContacts.restoreAllContactEvents('', options.address, callback)
        }).then(() => {
            Console.info(`[MongoDB] Restored all missing contacts from network.`);
            callback.onProgress("contact", 3)
            callback.onProgress("items", 0)
            Console.info(`[MongoDB] Trying to get all items metainfo from network.`);
            return RestoreItems.restoreAllItems('', options.address, callback)
        }).then(() => {
            return RestoreItems.restoreItemMapping('')
        }).then(() => {
            return RestoreItems.restoreAllSubscribedItems('')
        }).then(() => {
            return RestoreItems.restoreAllCatalogueEvents('', options.address, callback)
        }).then(() => {
            Console.info(`[MongoDB] Restored all missing items from network.`);
            callback.onProgress("items", 3);
            callback.onProgress("invoices", 0);
            Console.info(`[MongoDB] Trying to get all invoices meta info.`);
            return RestoreReceipts.restoreAllReceiptsByType('', 'getAllEventsBySeller/getAllEventsByBuyer', options.address, callback)
        }).then(() => {
            return RestoreReceipts.restoreAllReceiptEvents('', "getAllReceiptsByBuyer", options.address, callback)
        }).then(() => {
            return RestoreReceipts.restoreAllReceiptEvents('', "getAllReceiptsBySeller", options.address, callback)
        }).then(() => {
            Console.info(`[MongoDB] Restored all missing invoices from network.`);
            callback.onProgress("invoices", 3);
            callback.onProgress("returns", 0);
            return RestoreReturns.restoreAllReturns("getAllReturnsBySeller", options.address, '', callback)
        }).then(() => {
            return RestoreReturns.restoreAllReturns("getAllReturnsByBuyer", options.address, '', callback);
        }).then(() => {
            callback.onProgress("returns", 3);
            callback.onProgress("templates", 0);
            return RestoreTemplateRepo.restoreAllTemplates('', options.address, callback)
        }).then(() => {
            return RestoreTemplateRepo.restoreAllTemplateRepoEvents('', options.address, callback)
        }).then(() => {
            Console.info(`[MongoDB] Restored all missing templates from network.`);
            callback.onProgress("templates", 3);
            callback.onProgress("subscribers", 0);
            return RestoreSubscribers.restoreSubscribedReceipts('', options.address, callback)
        }).then(() => {
            Console.info(`[MongoDB] Restored all missing subscribed receipts from network.`);
            callback.onProgress("subscribers", 3);
            callback.onProgress("consensus", 0);
            return TemplateConsensusRestore.restoreAllTemplateConsensus('', options.address, callback)
        }).then(() => {
            return TemplateConsensusRestore.restoreAllTemplateConsensusEvents('', options.address, callback)
        }).then(() => {
            Console.info(`[MongoDB] Restored all missing template consensus from network.`);
            callback.onProgress("consensus", 3);
        }).catch((e) => {
            Console.error(`[MongoDB] Unable to restore data, Reason: ${e}.`);
            callback.onError(e);
        });
    }

    // static syncMasterNodes(paramNetwork) {
    //     let dbInstance = ParamConnector.getInstance().getDB();
    //     let nodeInfoManager = paramNetwork.getNodeInfoManager();
    //     let versionControlManager = paramNetwork.getVersionControlManager();
    //     Console.log("Fetching master nodes from network.")

    //     return nodeInfoManager.getAllMasterNodes().then(masterNodes => {
    //         Console.log("Got master nodes from network.")
    //         let promiseArray = [];
    //         for (let node in masterNodes) {
    //             let promise = nodeInfoManager.getNodeDetailsById(masterNodes[node]).then(nodeInfo => {
    //                 return dbInstance.ePhoneBook.updateMasterNode(masterNodes[node], nodeInfo[1], nodeInfo[2], nodeInfo[3]);
    //             });
    //             promiseArray.push(promise)
    //         }
    //         return Promise.all(promiseArray);
    //     }).then(data => {
    //         Console.log("Fetching enterprise nodes from network.")
    //         return nodeInfoManager.getAllEnterpriseNodes();
    //     }).then(enterpriseNodes => {
    //         Console.log("Got enterprise nodes from network.")
    //         let promiseArray = [];
    //         for (let node in enterpriseNodes) {
    //             let promise = nodeInfoManager.getNodeDetailsById(enterpriseNodes[node]).then(nodeInfo => {
    //                 return dbInstance.ePhoneBook.updateEnterpriseNode(enterpriseNodes[node], nodeInfo[0], nodeInfo[1], nodeInfo[2], nodeInfo[3]);
    //             });
    //             promiseArray.push(promise)
    //         }
    //         return Promise.all(promiseArray);
    //     }).then(nodeInfos => {
    //         return dbInstance.ePhoneBook.getAllNodes();
    //     }).then(nodes => {
    //         let promiseArray = [];
    //         for (let index in nodes) {
    //             let promise = versionControlManager.getAllContractIdsByNodeId(nodes[index]).then(contractIds => {
    //                 return RestoreVersionControl.restoreVersionControl(contractIds, paramNetwork);
    //             })
    //             promiseArray.push(promise);
    //         }
    //         return Promise.all(promiseArray);
    //     }).catch(e => {
    //     })
    // }

    // static syncItems(options, batchSize, callback) {
    //     // let graphDB = GraphDB.getInstance();
    //     // return graphDB.versionControl.getContractAddressFromName("ParamCatalogueImpl").then(contractAddresses => {
    //     let contractAddresses = ["0x14D86d8ba2BCD0783D91F986230E2a9c85028486"];
    //     let promises = [];
    //     for (let outerIndex in contractAddresses) {
    //         Console.info(`[MongoDB] Trying to restore all items for contract ${contractAddresses[outerIndex]}`);
    //         let promise = RestoreItems.restoreAllItems(contractAddresses[outerIndex], options.address, callback, batchSize).then(() => {
    //             Console.info(`[MongoDB] Trying to restore all item mapping for contract ${contractAddresses[outerIndex]}`);
    //             return RestoreItems.restoreItemMapping(contractAddresses[outerIndex])
    //         }).then(() => {
    //             Console.info(`[MongoDB] Trying to restore all subscribed items for contract ${contractAddresses[outerIndex]}`);
    //             return RestoreItems.restoreAllSubscribedItems(contractAddresses[outerIndex])
    //         }).then(() => {
    //             Console.info(`[MongoDB] Trying to restore all catalogue events for contract ${contractAddresses[outerIndex]}`);
    //             return RestoreItems.restoreAllCatalogueEvents(contractAddresses[outerIndex], options.address, callback)
    //         }).then(() => {
    //             Console.log("ITEMS sync complete");
    //             callback.onComplete("ITEMS sync complete");
    //             return;
    //         })
    //         promises.push(promise);
    //     }
    //     return Promise.all(promises)
    //     // });
    // }

    // static syncVendors(options, callback) {
    //     let dbInstance = ParamConnector.getInstance().getDB();
    //     return dbInstance.versionControl.getContractAddressFromName("ParamVendors").then(contractAddresses => {
    //         // contractAddresses = ["0xb00569398e43609176d734ab0909589f80f00528"];
    //         callback.onProgress("contact", 1);
    //         let promises = [];
    //         for (let outerIndex in contractAddresses) {
    //             let promise = RestoreVendorManagement.restoreAllInvitationsAndEvalRequests(options.address, contractAddresses[outerIndex], callback).then(() => {
    //                 return RestoreVendorManagement.restoreAllVendorTemplates(options.address, contractAddresses[outerIndex], callback)
    //             });
    //             promises.push(promise);
    //         }
    //         return Promise.all(promises);
    //     });
    // }

    // static syncContacts(options, callback) {
    //     // return graphDB.versionControl.getContractAddressFromName("ParamContactImpl").then(contractAddresses => {
    //     let contractAddresses = ["0xD0063E22D9C5C33623D514f59F2843247dDE2439"];
    //     callback.onProgress("contact", 1);
    //     let promises = [];
    //     for (let outerIndex in contractAddresses) {
    //         let promise = RestoreContacts.restoreAllContacts(contractAddresses[outerIndex], options.address, callback);
    //         promises.push(promise);
    //     }
    //     return Promise.all(promises);
    //     // });
    // }

    // static syncReceipts(options, batchSize, callback) {
    //     // return graphDB.versionControl.getContractAddressFromName("ParamContract").then(contractAddresses => {
    //     let promiseArray = []
    //     let promise;
    //     let contractAddresses = ["0x868bbCFA5ef83C552F9fC6906689d0F7444E7C6C"];
    //     for (let outerIndex in contractAddresses) {
    //         promise = RestoreReceipts.restoreAllReceiptsByType(contractAddresses[outerIndex], "getAllEventsBySeller", options.address, callback, batchSize).then(() => {
    //             return RestoreReceipts.restoreAllReceiptsByType(contractAddresses[outerIndex], "getAllEventsByBuyer", options.address, callback, batchSize)
    //         }).then(() => {
    //             let eventPromiseArray = [];
    //             eventPromiseArray.push(RestoreReceipts.restoreAllReceiptEvents(contractAddresses[outerIndex], "getAllReceiptsByBuyer", options.address, callback, batchSize))
    //             eventPromiseArray.push(RestoreReceipts.restoreAllReceiptEvents(contractAddresses[outerIndex], "getAllReceiptsBySeller", options.address, callback, batchSize))
    //             return Promise.all(eventPromiseArray);
    //         })
    //         promiseArray.push(promise);
    //     }
    //     return Promise.all(promiseArray)
    // }

    // static syncTemplateRepo(options, callback) {
    //     let dbInstance = ParamConnector.getInstance().getDB();
    //     return dbInstance.versionControl.getContractAddressFromName("TemplateRepositoryImpl").then(contractAddresses => {
    //         let promises = [];
    //         for (let outerIndex in contractAddresses) {
    //             let promise = RestoreTemplateRepo.restoreAllTemplates(contractAddresses[outerIndex], options.address, callback).then(() => {
    //                 return RestoreTemplateRepo.restoreAllTemplateRepoEvents(contractAddresses[outerIndex], options.address, callback)
    //             })
    //             promises.push(promise);
    //         }
    //         return Promise.all(promises)
    //     });
    // }

    // static syncReceiptSubscribers(options, callback) {
    //     let dbInstance = ParamConnector.getInstance().getDB();
    //     return dbInstance.versionControl.getContractAddressFromName("ParamContract")
    //         .then(contractAddresses => {
    //             let promises = [];
    //             contractAddresses = ["0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815"];
    //             for (let outerIndex in contractAddresses) {
    //                 let promise = RestoreSubscribers.restoreSubscribedReceipts(contractAddresses[outerIndex], options.address, callback)
    //                 promises.push(promise);
    //             }
    //             return Promise.all(promises)
    //         });
    // }

    // static syncTemplateConsensus(options, callback) {
    //     let dbInstance = ParamConnector.getInstance().getDB();
    //     return dbInstance.versionControl.getContractAddressFromName("TemplateConsensusImpl").then(contractAddresses => {
    //         // contractAddresses = ["0x8fff1d6d8e854377a16676d63a7c7f1c6d9fd156"];
    //         let promises = [];
    //         for (let outerIndex in contractAddresses) {
    //             let promise = TemplateConsensusRestore.restoreAllTemplateConsensus(contractAddresses[outerIndex], options.address, callback).then(() => {
    //                 return TemplateConsensusRestore.restoreAllTemplateConsensusEvents(contractAddresses[outerIndex], options.address, callback)
    //             })
    //             promises.push(promise);
    //         }
    //         return Promise.all(promises)
    //     });
    // }
}
export default Sync;