// const Util = require('../../utils/util');
// const GraphDB = require('param GraphDB');
import Util from '../../utils/util';
import Database from '../../../../database';
import Charts from '../index';
import DataFormatters from '../../utils/Formatters';

class Overview {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    getSummary() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getSummary");
    }

    invoice() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "invoice");
    }

    averageFinancePaymentCycle() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "averageFinancePaymentCycle");
    }

    averageFinanceReceivableCycle() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "averageFinanceReceivableCycle");
    }

    getSalesPurchasesSummaryAmount(fromDate, toDate) {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getSalesPurchasesSummaryAmount", fromDate, toDate)
    }

    outstandingReceivableOrPayable() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "outstandingReceivableOrPayable");
    }

    topPayables() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "topPayables");
    }

    topReceivables() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "topReceivables");
    }

    getUnreconciledPayment() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getUnreconciledPayment");
    }

    getUnreconciledReceivable() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getUnreconciledReceivable");
    }

    disputes() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "disputes");
    }

    //TODO
    getSavings() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getSavings");
    }

    getTotalInvoiceVsTotalCollections() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getTotalInvoiceVsTotalCollections");
    }

    getTotalOrderVsTotalPayment() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getTotalOrderVsTotalPayment");
    }

    getMonthlyPayment() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getMonthlyPayment");
    }

    getMonthlyReceivables() {
        return this.mongoDBConnector.invoke("reports", "financesOverview", "getMonthlyReceivables");
    }
}

export default Overview;
// module.exports = Overview;