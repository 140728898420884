import Util from '../util';
class BarchartFormatter {

    static topSalesOrPurchases(dataFromDB) {
        let data = [];
        for (let index in dataFromDB) {
            data.push({
                type: index,
                value: dataFromDB[index]
            })
        }
        return data;
    }

    static grnCounts(dataFromDB) {
        let data = [];
        for (let index in dataFromDB) {
            data.push({
                type: index,
                total: dataFromDB[index]
            })
        }
        return data;
    }

    static contractDueForRenewal(dataFromDB) {
        let data = [];
        for (let index in dataFromDB) {
            data.push({
                expiryDate: index,
                total: dataFromDB[index].length
            })
        }
        return data;
    }

    static salesOrPurchases(dataFromDB) {
        let data = [];
        for (let index in dataFromDB) {
            // let month = Util.milliSecondsToMonth(index)
            data.push({
                month: index,
                value: dataFromDB[index]
            })
        }
        return data;
    }

    static outstandingTransactions(dataFromDB) {
        // res
        let data = [];
        for (let index in dataFromDB.dayWise) {
            data.push({
                days: index,
                total: dataFromDB.dayWise[index]
            })
        }
        return data;
    }


    static unreconciledPayment(dataFromDB) {

        let data = [];
        for (let index in dataFromDB) {
            data.push({
                days: index,
                total: dataFromDB[index].length
            })
        }
        return data;
    }
}

export default BarchartFormatter;