import React, { PureComponent } from "react";
import { Input, Icon, Button, Card, Divider, Form, message, Row, Col } from "antd";
import CircularProgress from '../../components/CircularProgress';
import Wallet from '../../wallet/index';
import "./index.less";
import * as Utils from '../../util/utils';
import Console from '../../logger/index';

class CreatePassword extends PureComponent {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.lockIcon = <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />;
    this.checkIcon = <Icon type="check-circle" className="progressIcon" style={{ fontSize: '16px', color: "green", verticalAlign: "inherit" }} theme="filled" />;
    this.errorIcon = <Icon type="close-circle" className="progressIcon" style={{ fontSize: '16px', color: "red", verticalAlign: "inherit" }} theme="filled" />

    this.state = {
      passwordMatched: false,
      showLoader: false,
      password_password: "",
      password_confirmPassword: ""
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ showLoader: true })
    let prevpath = this.props.location.state.source;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.confirmPassword) {
          message.info("Your password & confirm password doesn't match. Please try again");
          this.setState({ showLoader: false })
          return;
        }
        if (prevpath == "create") {
          setTimeout(this.createNewWallet(values.confirmPassword), 3000)
        } else if (prevpath == "restore") {
          setTimeout(this.restoreWallet(values.confirmPassword), 3000)
        } else if (prevpath == "create_otp") {
          setTimeout(this.createOtpBasedWallet(values.confirmPassword), 3000)
        }
      }
    });
  };

  createOtpBasedWallet=(password)=>{
    Wallet.createOtpBasedWallet(password).then(result => {
      message.success('Wallet created successfully')
      this.props.history.push({ pathname: '/addprofile' });
      this.setState({ showLoader: false })
    }).catch(error => {
      Console.error(error)
      message.error('Failed!!!')
      this.setState({ showLoader: false })
    })
  }

  createNewWallet = (password) => {
    Utils.clearLocalStorage();
    Wallet.createWallet(password).then(result => {
      message.success('Wallet created successfully')
      this.props.history.push({ pathname: '/phrase' })
      this.setState({ showLoader: false })
    }).catch(error => {
      Console.error(error)
      message.error('Failed!!!')
      this.setState({ showLoader: false })
    })
  }

  restoreWallet = (password) => {
    let mnemonic = Utils.getFromLocalStorage('encryptedMnemonic');
    Wallet.restoreWallet(password, mnemonic).then(result => {
      message.success('Account restored successfully')
      this.props.history.push({ pathname: '/addprofile' })
      this.setState({ showLoader: false })
    }).catch(error => {
      Console.error(error)
      message.error('Failed to restore')
      this.setState({ showLoader: false })
    })
  }

  handleChange = (e) => {
    let value = e.target.value;
    let confirmPassword = this.state.confirmPassword;
    if (value && confirmPassword && value === confirmPassword) {
      this.setState({
        passwordMatched: "success"
      })
    }
    else {
      this.setState({
        passwordMatched: "error"
      })
    }
    this.setState({
      password: value
    })
  }

  handleConfirmChange = (e) => {
    let value = e.target.value;
    let password = this.state.password;
    if (value && password && value === password) {
      this.setState({
        passwordMatched: "success"
      })
    } else {
      this.setState({
        passwordMatched: "error"
      })
    }
    this.setState({
      confirmPassword: value
    })
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  }

  redirectToLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Card
        className="password"
        title={<h2 className="gx-mb-0">
          <Icon type="arrow-left" onClick={this.goBack} />
          &nbsp;&nbsp; Create password
      </h2>}
      >
        {
          this.state.showLoader &&
          <CircularProgress />
        }
        <div className="password__inner">
          <p className=" gx-mb-4 password__description">
            You must remember your password. It cannot be recovered. Use a
            different one from your social media accounts in case someone gets
            access to your systems.
          </p>

          <Form onSubmit={this.handleSubmit} className="password__form">
            <Form.Item validateStatus={this.state.passwordMatched} hasFeedback>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your password!' },]
              })(
                <Input.Password
                  type="password"
                  prefix={this.lockIcon}
                  placeholder="Enter your password"
                  onChange={this.handleChange}

                />
              )}
            </Form.Item>
            <Form.Item validateStatus={this.state.passwordMatched} hasFeedback>
              {getFieldDecorator('confirmPassword', {
                rules: [{ required: true, message: 'Please input confirm password!' }],
              })(
                <Input.Password
                  type="password"
                  prefix={this.lockIcon}
                  placeholder="Confirm your password"
                  onChange={this.handleConfirmChange}
                  onKeyPress={this.handleKeyPress}
                />
              )}
            </Form.Item>
          </Form>
        </div>
        <Divider />
        <Row>
          <Col xl={12} lg={12} md={12} sm={24}>
            {
              Utils.getParamId() && <p className="gx-pointer" style={{ color: "#6236ff", marginTop: '0.5rem' }} onClick={this.redirectToLogin}>Have an account? Login</p>
            }
          </Col>
          <Col xl={12} lg={12} md={12} sm={24}>
            <Button className="password__nextBtn" onClick={this.handleSubmit} disabled={!this.state.passwordMatched}>
              NEXT
              <Icon type="arrow-right" />
            </Button>
          </Col>
        </Row>
      </Card >
    );
  }
}
const WrappedPasswordForm = Form.create({ name: 'password' })(CreatePassword);

export default WrappedPasswordForm;
