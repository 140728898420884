class TableFormatter {

    static overview(dataFromDb) {
        let data = [];
        for (let index in dataFromDb) {

            data.push({
                label: index,
                value: dataFromDb[index]
            })
        }
        return data;
    }
    static catalogue(dataFromDb) {
        let data = [];
        for (let index in dataFromDb) {
            data.push({
                label: index,
                value: dataFromDb[index]
            })
        }
        return data;
    }
}

export default TableFormatter;