import React, { PureComponent } from "react";
import { Input, Icon, Button, message, Card, Form } from "antd";
import moment from 'moment';
import Wallet from '../../wallet/index';
import * as Utils from '../../util/utils';
import UserIcon from '../../assets/images/loginProfile1.svg';
import * as ConfigObj from '../../param-network/config1.json';
import "./index.less";
import { dbURL } from "../../constants/AppConstants";
// import Setting from "../../routes/main/Setting/index"
import NetworkBridge from '../../util/network-bridge';
import Analytics from '../../analytics';

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.configObj = ConfigObj.privateNodes;
    this.setConfigObj = ConfigObj.default;
    Analytics.getInstance().trackPageview();
    this.state = {
      value: ''
    }

  }

  componentDidMount() {
    let profileObj = Utils.getFromLocalStorage('profile');
    if (!profileObj) {
      this.props.history.push('/addprofile');
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.validatePassword(event.target.value)
      return false;
    }
  }
  backgroundSync = () => {
    message.info("Sync on progress")
    //Sync call

  }
  validatePassword = (password) => {
    if (!Wallet.logIn(password)) {
      message.error('Unable to login to your account, Please check your password.');
      this.setState({ showLoader: false })
      return false;
    }
    message.success('Logged in successfully');
    let configObj = Utils.getFromLocalStorage('configObj');
    let localDbURL = Utils.getFromLocalStorage('dbURL');
    if (!configObj) {
      Utils.setLocalStorage('configObj', JSON.stringify(this.setConfigObj));
      Utils.setLocalStorage('versionControl', "0x6050a738306341b46b13e6997ce3f8c3f72ae973");
    }

    if (!localDbURL) {
      Utils.setLocalStorage('dbURL', dbURL);
    }

    //setting surrent month
    let date = new Date()
    let yr = date.getFullYear()
    let month = date.getMonth();
    let firstDay = moment(new Date(yr, month, 1)).format('YYYY-MM-DD')
    let lastDay = moment(new Date(yr, month + 1, 0)).format('YYYY-MM-DD')
    Utils.setLocalStorage('startDate', firstDay)
    Utils.setLocalStorage('endDate', lastDay)
    this.setState({ showLoader: false })
    this.props.history.push({ pathname: '/main/landing' });
  }

  handleLogin = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        this.setState({ showLoader: false });
        return false;
      }
      let password = values.password;
      this.validatePassword(password);
    })
  }

  handleForgotPassword = (path, type) => {
    if (!type) {
      this.props.history.push(path);
    }
    this.props.history.push(({
      pathname: path,
      state: { account: 'Switch Account' }
    }))
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let profileObj = Utils.getFromLocalStorage('profile');
    if (profileObj) {
      profileObj = JSON.parse(profileObj);
    }
    return (
      <Card className="login" actions={[<a href="https://param.network" target="_blank">&copy; ⦃param⦄.network</a>]}>
        <div className="login__heading">
          <center>
            {
              profileObj && profileObj.logoUrl ?
                <img src={profileObj.logoUrl} className="login__logo-image" />
                :

                <img src={UserIcon} className="login__logo-image" alt="avatar" />
            }
          </center>
          <center>
            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-4 login_welcomeText">
              {profileObj && profileObj.name ? profileObj.name : null}
            </h1>
          </center>
        </div>
        <div className="login__inner">
          <center>
            <h5 className="gx-mb-3 login_passwordText">
              Please enter your password to proceed
            </h5>
          </center>
          <Form className="login__form" onSubmit={this.handleLogin}>
            <Form.Item style={{ marginBottom: "0.35em" }}>
              {getFieldDecorator('password', {
                rules: [{
                  required: true,
                  message: 'Please input your password!',
                }],
                initialValue: ""
              })(
                <Input.Password id="login_password"
                  prefix={<Icon type="lock" />}
                  type="password"
                  placeholder="Password"
                  className="login_input"
                  onKeyPress={this.handleKeyPress}
                  autoFocus
                />
              )
              }
            </Form.Item>
          </Form>

          <p className="gx-text-primary gx-mb-3 gx-mb-sm-4 login__forgotText">
            <span onClick={() => this.handleForgotPassword('/restore')}>Forgot Password</span> | <span onClick={() => this.handleForgotPassword('/restore', 'switch')}>Switch Account</span>
          </p>
          <p onClick={() => this.handleForgotPassword('/landing')} className="gx-text-primary gx-mb-3 gx-mb-sm-4 login__createNew gx-pointer">Create New Account</p>
          <Button disabled={!this.props.form.getFieldValue('password').length} type="button" className="login__loginBtn" onClick={this.handleLogin}>Login</Button>
        </div >
      </Card >
    );
  }
}
const LoginForm = Form.create({ name: 'login' })(Login)
export default LoginForm;