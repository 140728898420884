import Database from '../../index';
import ParamConnector from '../../../param-connector'
import * as NetworkUtils from '../../../util/utils';
import FormatData from '../Utils/formatData';
import Console from '../../../logger';
import NetworkDataFormat from '../Utils/network-data-format';
import { TemplateRepoEvents } from '../../../param-network/utils/event-names';

class RestoreTemplateRepo {

    static restoreStep(stepId, contractAddress, status) {
        let stepsRepoDB = Database.getInstance().getDB().stepsRepoDB;
        let txId = NetworkUtils.getTransactionId(stepId, contractAddress);
        return ParamConnector.getInstance().getNetwork()
            .getTemplateRepoManager(contractAddress)
            .getStepById(stepId).then(stepInfo => {
                let promiseArray = [];
                promiseArray.push(stepInfo);
                promiseArray.push(stepsRepoDB.doesExist(txId));
                return Promise.all(promiseArray);
            }).then(result => {
                let isExists = result[1];
                result = result[0];
                if (isExists) {
                    return stepsRepoDB.updateStep(txId, result.stepJson, result.owner, result.metaData);
                }
                return stepsRepoDB.addStep(txId, result.stepJson, result.owner, result.metaData);
            }).catch(e => {
                Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreStep`);
            });
    }

    static restoreTemplate(templateId, contractAddress) {
        let tepmlateRepoDB = Database.getInstance().getDB().templateRepoDB;
        let txId = NetworkUtils.getTransactionId(templateId, contractAddress);
        let templateRepoManager = ParamConnector.getInstance().getNetwork().getTemplateRepoManager(contractAddress);
        let templateInfo, promise;;
        return templateRepoManager.getTemplate(templateId).then(res => {
            templateInfo = res;
            let promiseArray = [];
            for (let index in templateInfo.stepIds) {
                promiseArray.push(templateRepoManager.getStepById(templateInfo.stepIds[index]).then(stepData => {
                    return {
                        metaData: stepData.metaData,
                        owner: stepData.owner,
                        stepId: NetworkUtils.getTransactionId(templateInfo.stepIds[index], contractAddress),
                        ...JSON.parse(stepData.stepJson)
                    }
                }))
                templateInfo.stepIds[index] = NetworkUtils.getTransactionId(templateInfo.stepIds[index], contractAddress);
            }
            return Promise.all(promiseArray);
        }).then(stepInfos => {
            templateInfo.stepInfos = stepInfos;
            return tepmlateRepoDB.doesExist(txId);
        }).then(isExists => {
            if (isExists) {
                promise = tepmlateRepoDB.updateTemplate(txId, "", templateInfo.owner, templateInfo.name, templateInfo.stepIds, templateInfo.name, templateInfo.metaData, templateInfo.stepInfos);
            } else {
                promise = tepmlateRepoDB.addTemplate(txId, "", templateInfo.owner, templateInfo.name, templateInfo.stepIds, templateInfo.name, templateInfo.metaData, templateInfo.stepInfos);
            }
            return promise;
        }).catch(e => {
            Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreTemplate`);
        });
    }

    static restoreNotExists(templateId) {
        let tepmlateRepoDB = Database.getInstance().getDB().templateRepoDB;
        return tepmlateRepoDB.doesExist(templateId).then(doesExists => {
            if (doesExists) {
                return true;
            }
            let templateInfo = NetworkUtils.getTransactionData(templateId)
            return RestoreTemplateRepo.restoreTemplate(templateInfo.id, templateInfo.address);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreNotExists`);
        });
    }

    static restoreStepForId(txId) {
        let stepsRepoDB = Database.getInstance().getDB().stepsRepoDB;
        let stepObject = NetworkUtils.getTransactionData(txId);
        let stepId = stepObject.id;
        let contractAddress = stepObject.address;
        return ParamConnector.getInstance().getNetwork()
            .getTemplateRepoManager(contractAddress)
            .getStepById(stepId).then(stepInfo => {
                let promiseArray = [];
                promiseArray.push(stepInfo);
                promiseArray.push(stepsRepoDB.doesExist(txId));
                return Promise.all(promiseArray);
            }).then(result => {
                if (result[1]) {
                    //As of now, ignoring. We should update
                    return;
                }
                result = result[0];
                return stepsRepoDB.addStep(txId, result.stepJson, result.owner, result.metaData);
            }).catch(e => {
                Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreStepForId`);
            });
    }
    static restoreTemplateAsSubscriber(templateId, owner, subscribers, contractAddress) {
        let txId = NetworkUtils.getTransactionId(templateId, contractAddress);
        let tepmlateRepoDB = Database.getInstance().getDB().templateRepoDB;
        return RestoreTemplateRepo.restoreNotExists(txId).then(res => {
            return tepmlateRepoDB.addSubscribers(txId, subscribers, "true");
        }).catch(e => {
            Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreTemplateAsSubscriber`);
        });
    }
    /**
     * 
     * @param {*} contractAddress 
     * @param {*} typeFunction getReceiptsByBuyer or getReceiptsBySeller
     * @param {*} owner 
     * @param {*} callback 
     */
    static restoreAllTemplates(contractAddress, owner) {
        return ParamConnector.getInstance().getNetwork().getTemplateRepoManager(contractAddress).getAllTemplates(owner).then(templates => {
            let promiseArray = [];
            for (let index in templates) {
                promiseArray.push(RestoreTemplateRepo.restoreTemplate(templates[index], contractAddress))
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreAllTemplates`);
        });
    }
    static restoreAllTemplateRepoEvents(contractAddress, owner, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("template", `Trying to get all the events from ${contractAddress}`);
        }
        let templateRepoManager = ParamConnector.getInstance().getNetwork().getTemplateRepoManager(contractAddress);
        return templateRepoManager.getAllTemplates(owner).then(templateIds => {
            let promiseArray = [];
            for (let templateIndex in templateIds) {
                promiseArray.push(RestoreTemplateRepo.restoreTemplateRepoIdEvents(contractAddress, templateIds[templateIndex]))
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreAllTemplateRepoEvents`);
        });
    }
    static restoreTemplateRepoIdEvents(contractAddress, templateId) {
        let templateRepoDB = Database.getInstance().getDB().templateRepoDB;
        let templateRepoManager = ParamConnector.getInstance().getNetwork().getTemplateRepoManager(contractAddress);
        let templateIdTrans = NetworkUtils.getTransactionId(templateId, contractAddress);
        return templateRepoDB.getStepIdsByTemplateId(templateIdTrans).then(stepIds => {
            stepIds = stepIds.result;
            let templateRepoEventPromiseArray = [];
            for (let index in stepIds) {
                let stepId = stepIds[index];
                stepId = NetworkUtils.getTransactionData(stepId);
                templateRepoEventPromiseArray.push(templateRepoManager.getAllEventsByStepId(stepId.id));
            }
            templateRepoEventPromiseArray.push(templateRepoManager.getAllEventsByRepoId(templateId))
            return Promise.all(templateRepoEventPromiseArray);
        }).then(allEvents => {
            let transactionsDB = Database.getInstance().getDB().transactionsDB;
            let addTransactionsPromiseArray = [];
            for (let allEventsIndex in allEvents) {
                let templateEvents = allEvents[allEventsIndex];
                for (let index in templateEvents) {
                    let event = templateEvents[index];
                    let label = TemplateRepoEvents[event.event].label;
                    let txId = NetworkUtils.getTransactionId(templateId, contractAddress);
                    let metaData = NetworkDataFormat.getMetaInfo(txId, event, label);
                    let owner = event.args.owner;
                    let transactionPromise = transactionsDB.addTransaction(txId, owner, metaData).catch(error => {
                        Console.error(`Unable to restore template event for ${txId}, Reason: ${error}`)
                    })
                    addTransactionsPromiseArray.push(transactionPromise);
                }
            }
            return Promise.all(addTransactionsPromiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template [Reason] ${e} [Module] : Sync/template-repo/restoreTemplateRepoIdEvents`);
        });
    }
}
export default RestoreTemplateRepo;