import React, { Component } from 'react';
import { Radio, Card, Input, Button, Row, Col, message, Checkbox } from 'antd';
import * as Utils from '../../../util/utils';
import NetworkBridge from '../../../util/network-bridge';
import './setting.less';

class ProxyConfig extends Component {

    constructor(props) {
        super(props);
        let proxy = Utils.getFromLocalStorage("proxySettings");
        if (proxy) {
            proxy = JSON.parse(proxy);
        }
        else {
            proxy = {}
        }
        let defaultProxy = Utils.getFromLocalStorage("defaultProxy") || "0";
        this.state = {
            proxy: defaultProxy,
            applicationProxyFormValues: proxy,
        }
    }

    selectProxyConfig = (e) => {
        this.setState({
            proxy: e.target.value
        })
    }

    saveProxy = () => {
        if (this.state.proxy === "2") {
            Utils.setLocalStorage("proxySettings", JSON.stringify(this.state.applicationProxyFormValues));
        }
        else {
            Utils.setLocalStorage("proxySettings", "");
            this.setState({
                applicationProxyFormValues: {},
            })
        }
        Utils.setLocalStorage("defaultProxy", this.state.proxy)
        NetworkBridge.getProfileManager().resetConnection();
        message.success("Proxy Settings Saved!")
    }

    handleProxyFormChange = (e) => {
        this.setState({ applicationProxyFormValues: { ...this.state.applicationProxyFormValues, [e.target.id]: e.target.value } });
    }

    render() {
        const { applicationProxyFormValues } = this.state;
        return <Card className="setting__card" title={<h2 className="setting__nomargin">Proxy Config</h2>}>
            <Radio.Group defaultValue={this.state.proxy} onChange={this.selectProxyConfig}>
                <Radio value="0">
                    No Proxy
            </Radio>
                <Radio value="1">
                    System Proxy
            </Radio>
                <Radio value="2">
                    Application Proxy
            </Radio>
            </Radio.Group>
            {
                this.state.proxy === "2" &&
                <div style={{ marginTop: '1rem' }}>
                    <Row style={{ marginTop: '0.5rem' }}>
                        <Col span={4}>
                            <p style={{ textAlign: 'left', marginTop: '0.5rem', marginRight: '1em' }}>Param Proxy</p>
                        </Col>
                        <Col span={12}>
                            <Input
                                style={{ width: '57%' }}
                                id="paramProxyIP"
                                onChange={this.handleProxyFormChange}
                                defaultValue={applicationProxyFormValues["paramProxyIP"]}
                            />
                            <Input
                                placeholder="Port"
                                style={{ width: '15%', marginLeft: '1.05rem' }}
                                id="paramProxyPort"
                                onChange={this.handleProxyFormChange}
                                defaultValue={applicationProxyFormValues["paramProxyPort"]}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '0.5rem' }}>
                        <Col span={4}>
                            <p style={{ textAlign: 'left', marginTop: '0.5rem', marginRight: '1em' }}>Server</p>
                        </Col>
                        <Col span={12}>
                            <Input
                                style={{ width: '57%' }}
                                id="applicationProxyIP"
                                onChange={this.handleProxyFormChange}
                                defaultValue={applicationProxyFormValues["applicationProxyIP"]}
                            />
                            <Input
                                placeholder="Port"
                                style={{ width: '15%', marginLeft: '1.05rem' }}
                                id="applicationProxyPort"
                                onChange={this.handleProxyFormChange}
                                defaultValue={applicationProxyFormValues["applicationProxyPort"]}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '0.5rem' }}>
                        <Col xl={6} lg={6} md={10} sm={10}>
                            <Checkbox onChange={(e) => {
                                if (!e.target.checked) {
                                    this.setState({
                                        proxyUserName: '',
                                        proxyPassword: ''
                                    })
                                }
                                this.setState({ applicationProxyFormValues: { ...this.state.applicationProxyFormValues, requireAuth: e.target.checked } })
                            }}
                                checked={applicationProxyFormValues["requireAuth"]}>Enable Authentication</Checkbox>
                        </Col>
                        <Col span={12}>
                        </Col>
                    </Row>
                    {
                        applicationProxyFormValues["requireAuth"] &&
                        <div>
                            <Row style={{ marginTop: '0.5rem' }}>
                                <Col span={4}>
                                    <p style={{ textAlign: 'left', marginTop: '0.5rem', marginRight: '1em' }}>Username</p>
                                </Col>
                                <Col span={12}>
                                    <Input
                                        className="settings-input"
                                        id="proxyUserName"
                                        onChange={this.handleProxyFormChange}
                                        defaultValue={applicationProxyFormValues["proxyUserName"]}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '0.5rem' }}>
                                <Col span={4}>
                                    <p style={{ textAlign: 'left', marginTop: '0.5rem', marginRight: '1em' }}>Password</p>
                                </Col>
                                <Col span={12}>
                                    <Input.Password
                                        className="settings-input"
                                        id="proxyPassword"
                                        onChange={this.handleProxyFormChange}
                                        defaultValue={applicationProxyFormValues["proxyPassword"]}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            }
            <br />
            <Button type="primary" style={{ marginTop: '1rem' }} onClick={this.saveProxy}>Save</Button>
        </Card>
    }
}

export default ProxyConfig;