import ParamConnector from '../../../param-connector'
import Charts from '../charts'
import { chartConfig } from '../config/defaultGraphConfig';
import SellerOverView from './receipt/seller-overview'
import BuyerOverView from './receipt/buyer-overview';
import SupplierDelivery from './receipt/purchase-supplierdelivery';
import CustomerInsights from './receipt/sales-customerinsights';
import * as networkUtils from '../../../util/utils';
import Console from '../../../logger'
class CatalogueEvents {

    constructor(paramId) {
        this.charts = Charts.getInstance();
        this.paramID = paramId;
        this.buyerOverView = new BuyerOverView(paramId);
        this.sellerOverView = new SellerOverView(paramId);
        this.supplierDelivery = new SupplierDelivery(paramId);
        this.customerInsights = new CustomerInsights(paramId);
    }

    //enable - boolean 
    enableRealTimeUpdateForCatalogue(enable) {
        this.realTimeCatalogue = enable;
    }

    registerEvents() {
        Console.info("Got the registerEvents for catalogue dashboard")

        const that = this;
        ParamConnector.getInstance().getDB().catalogueDBEvents.registerOnCatalogueAdded((data, err) => {
            Console.info("Got the registerOnCatalogueAdded events dashboard")
            if (err) {
                Console.error("[Catalogue]-registerOnCatalogueAdded Unable to get the data from events");
                return
            }
            that.onCatalogueAdded(data);
        })

        ParamConnector.getInstance().getDB().catalogueDBEvents.registerOnCatalogueUpdated((data, err) => {
            Console.info("Got the registerOnCatalogueUpdated events")
            if (err) {
                Console.error("[Catalogue]-registerOnCatalogueUpdated Unable to get the data from events", err);
                return;
            }
            that.onCatalogueUpdated(data);
        })

        /* ParamConnector.getInstance().getDB().catalogueDBEvents.registerOnCatalogueUpdateForSubscriber((data, err) => {
            Console.info("Got the registerOnCatalogueUpdateForSubscriber events")
            if (err) {
                Console.error("[Catalogue]-registerOnCatalogueUpdateForSubscriber Unable to get the data from events", err);
                return;
            }
            that.onCatalogueUpdateForSubscriber(data);
        })        

        ParamConnector.getInstance().getDB().catalogueDBEvents.registerOnCatalogueSubscriberAdded((data, err) => {
            Console.info("Got the registerOnCatalogueSubscriberAdded events")
            if (err) {
                Console.error("[Catalogue]-registerOnCatalogueSubscriberAdded Unable to get the data from events", err);
                return;
            }
            that.onCatalogueUpdated(data);
        })

        ParamConnector.getInstance().getDB().catalogueDBEvents.registerOnCatalogueLinkUpdated((data, err) => {
            Console.info("Got the registerOnCatalogueLinkUpdated events")
            if (err) {
                Console.error("[Catalogue]-registerOnCatalogueLinkUpdated Unable to get the data from events", err);
                return;
            }
            that.onCatalogueUpdated(data);
        })

        ParamConnector.getInstance().getDB().catalogueDBEvents.registerOnCatalogueStatusUpdated((data, err) => {
            Console.info("Got the registerOnCatalogueStatusUpdated events")
            if (err) {
                Console.error("[Catalogue]-registerOnCatalogueStatusUpdated Unable to get the data from events", err);
                return;
            }
            that.onCatalogueUpdated(data);
        }) */
    }

    unRegisterEvents() {

    }

    onCatalogueAdded(data) {

        this.buyerOverView.getCatalogueData(true);

        this.sellerOverView.getCatalogueData(true);
        this.buyerOverView.getTopPurchases(undefined, undefined, undefined, undefined, true);
        this.sellerOverView.getTopSales(true);
    }

    onCatalogueUpdateForSubscriber(data) {

    }

    onCatalogueUpdated(data) {

        this.buyerOverView.getCatalogueData(true);

        this.sellerOverView.getCatalogueData(true);
        this.buyerOverView.getTopPurchases(undefined, undefined, undefined, undefined, true);
        this.sellerOverView.getTopSales(true);
    }

}
export default CatalogueEvents;