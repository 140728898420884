import ECIES from '../../../../src/util/ecies';
import * as NetworkUtils from '../../../../src/util/utils';
import Console from '../../../logger';
// import NetworkDataFormat from '../../nosql/Utils/network-data-format';
// import { ContactEvents } from '../../../../src/param-network/utils/event-names';
import * as DBUtils from '../../../database/nosql/Utils/utils'
import GraphQL from '../index';
import ParamConnector from '../../../../src/param-connector';

class RestoreVendor {
    static restoreInvitation(invitationId, invitationType, contractAddress) {
        let vendorManagementDBInstance = ParamConnector.getInstance().getDB().vendorManagement;
        let vendorGraphQLInstance = GraphQL.getInstance().vendorManagement;
        invitationId = NetworkUtils.getTransactionId(invitationId, contractAddress);
        let invitation;
        // return vendorGraphQLInstance.getInvitation(invitationId).then(invitation => {
        return vendorGraphQLInstance.getInvitationProfile(invitationId).then(invitationData => {
            invitation = invitationData;
            // invitationId = NetworkUtils.getTransactionId(invitationId, contractAddress);

            let owner = invitation.owner,
                vendorAddress = invitation.vendorAddress,
                buyerAddress = invitation.buyerAddress,
                initiatorJsonLd = invitation.initiatorJsonLd,
                receiverJsonLd = invitation.receiverJsonLd,
                templateId = invitation.templateId,
                shareKey = invitation.shareKey,
                status = invitation.status,
                step = invitation.step,
                txnMode = invitation.txnMode;
            let selfParamId = NetworkUtils.getParamId();

            templateId = NetworkUtils.getTransactionId(templateId, contractAddress);
            let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
            if (txnMode === "2") {
                initiatorJsonLd = ECIES.decrypt(privateKey, initiatorJsonLd);
                receiverJsonLd = ECIES.decrypt(privateKey, receiverJsonLd);
            }
            let paramId = NetworkUtils.getParamId();
            let functionKey;
            if (status === '0') {
                functionKey = 'addContact';
            }
            else if (status === '1') {
                functionKey = 'updateContact';
            }
            else {
                functionKey = 'updateOrAddInvitation';
            }
            if (buyerAddress === "0x0000000000000000000000000000000000000000" && owner !== selfParamId) {
                buyerAddress = selfParamId;
            }
            if (vendorAddress === "0x0000000000000000000000000000000000000000" && owner !== selfParamId) {
                vendorAddress = selfParamId;
            }
            if (owner === buyerAddress && owner === vendorAddress) {
                //self invite
                if (invitationType === '0') {
                    return vendorManagementDBInstance[functionKey](invitationId + '_0', vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '0', owner, shareKey, initiatorJsonLd, txnMode).then(() => {
                        return vendorManagementDBInstance[functionKey](invitationId + '_1', vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '1', owner, shareKey, receiverJsonLd, txnMode)
                    });
                }
                return vendorManagementDBInstance[functionKey](invitationId + '_1', vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '1', owner, shareKey, initiatorJsonLd, txnMode).then(() => {
                    return vendorManagementDBInstance[functionKey](invitationId + '_0', vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '0', owner, shareKey, receiverJsonLd, txnMode)
                })
            }
            else if (owner === buyerAddress) {
                //vendor invite 
                if (paramId === owner) {
                    return vendorManagementDBInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '1', owner, shareKey, initiatorJsonLd, txnMode);
                }
                return vendorManagementDBInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '0', owner, shareKey, receiverJsonLd, txnMode);
            }
            else if (owner === vendorAddress) {
                //buyer invite
                if (paramId === owner) {
                    return vendorManagementDBInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, receiverJsonLd, status, step, '0', owner, shareKey, initiatorJsonLd), txnMode;
                }
                return vendorManagementDBInstance[functionKey](invitationId, vendorAddress, buyerAddress, templateId, initiatorJsonLd, status, step, '1', owner, shareKey, receiverJsonLd, txnMode);
            }
        }).then(() => {
            return RestoreVendor.restoreVendorTemplate(invitation.templateId, undefined, contractAddress);
            // });
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreInvitation`);
        });
    }

    static restoreVendorTemplate(templateId, owner, contractAddress) {
        let vendorManagementDBInstance = ParamConnector.getInstance().getDB().vendorManagement;
        let vendorGraphQLInstance = GraphQL.getInstance().vendorManagement;
        templateId = NetworkUtils.getTransactionId(templateId, contractAddress);
        return vendorGraphQLInstance.getInvitationTemplate(templateId)
            .then(vendorTemplate => {
                let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
                if (vendorTemplate.txnMode === "2") {
                    vendorTemplate.jsonLd = ECIES.decrypt(privateKey, vendorTemplate.jsonLd);
                }
                return vendorManagementDBInstance.createInvitationTemplate(templateId, vendorTemplate.jsonLd, vendorTemplate.metaData, vendorTemplate.templateType, vendorTemplate.isPublic, owner, vendorTemplate.txnMode);
            }).catch(e => {
                Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreVendorTemplate`);
            });
    }

    static restoreEvalRequest(evalRequestId, invitationId, contractAddress) {
        let vendorManagementDBInstance = ParamConnector.getInstance().getDB().vendorManagement;
        let vendorGraphQLInstance = GraphQL.getInstance().vendorManagement
        evalRequestId = NetworkUtils.getTransactionId(evalRequestId, contractAddress);
        return vendorGraphQLInstance.getEvalRequest(evalRequestId).then(evalReqData => {
            let docId = NetworkUtils.getTransactionId(evalReqData.documentId, evalReqData.contractAddress)
            return vendorManagementDBInstance.evalRequest(evalRequestId, invitationId, docId);
        })
    }

    static restoreAllInvitationsAndEvalRequests(address, contractAddress, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        let graphQLInstance = GraphQL.getInstance()
        return graphQLInstance.vendorManagement.getAllInvitationsByBuyer(address).then(invitations => {
            Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
            let invitationAndEvalPromises = [];
            for (let index = 0; index < invitations.length; index++) {
                let transactionData = NetworkUtils.getTransactionData(invitations[index])
                let invitationId = transactionData.id;
                contractAddress = transactionData.address
                let invitationAndEvalPromise = RestoreVendor.restoreInvitation(invitationId, "0", contractAddress).then(() => {
                    return RestoreVendor.restoreAllEvalRequests(invitationId, contractAddress);
                });
                invitationAndEvalPromises.push(invitationAndEvalPromise);
            }
            return Promise.all(invitationAndEvalPromises);
        }).then(() => {
            return graphQLInstance.vendorManagement.getAllInvitationsByVendor(address).then(invitations => {
                Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
                let invitationAndEvalPromises = [];
                for (let index = 0; index < invitations.length; index++) {
                    let transactionData = NetworkUtils.getTransactionData(invitations[index])
                    let invitationId = transactionData.id;
                    contractAddress = transactionData.address
                    let invitationAndEvalPromise = RestoreVendor.restoreInvitation(invitationId, "1", contractAddress).then(() => {
                        return RestoreVendor.restoreAllEvalRequests(invitationId, contractAddress);
                    });
                    invitationAndEvalPromises.push(invitationAndEvalPromise);
                }
                return Promise.all(invitationAndEvalPromises);
            });
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreAllInvitationsAndEvalRequests`);
        });
    }

    static restoreAllVendorTemplates(owner, contractAddress, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        let vendorGraphQLInstance = GraphQL.getInstance().vendorManagement;
        return vendorGraphQLInstance.getAllInvitationTemplates(owner).then(vendorTemplates => {
            Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
            let vendorTemplatePromises = [];
            for (let index = 0; index < vendorTemplates.length; index++) {
                let transactionData = NetworkUtils.getTransactionData(vendorTemplates[index])
                let vendorTemplateId = transactionData.id;
                contractAddress = transactionData.address
                let vendorTemplatePromise = RestoreVendor.restoreVendorTemplate(vendorTemplateId, owner, contractAddress);
                vendorTemplatePromises.push(vendorTemplatePromise);
            }
            return Promise.all(vendorTemplatePromises);
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreAllVendorTemplates`);
        });
    }


    static restoreAllEvalRequests(invitationId, contractAddress, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("grn", `Trying to get all the Grn from ${contractAddress}`);
        }
        let vendorGraphQLInstance = GraphQL.getInstance().vendorManagement;
        return vendorGraphQLInstance.getAllEvalRequestsByInvitation(invitationId).then(evalRequests => {
            Console.log(`[GraphDB] Got invitation Ids from ${contractAddress} metainfo from network.`);
            let evalRequestPromises = [];
            for (let index = 0; index < evalRequests.length; index++) {
                let transactionData = NetworkUtils.getTransactionData(evalRequests[index])
                let evalRequestId = transactionData.id;
                contractAddress = transactionData.address;
                let evalRequestPromise = RestoreVendor.restoreEvalRequest(evalRequestId, invitationId, contractAddress);
                evalRequestPromises.push(evalRequestPromise);
            }
            return Promise.all(evalRequestPromises);
        }).catch(e => {
            Console.error(`[GraphDB] Error in restoring vendors management [Reason] ${e} [Module] : Sync/vendorManagement/restoreAllEvalRequests`);
        });
    }

}
export default RestoreVendor;