// const Util = require('../../utils/util');
// const GraphDB = require('param GraphDB');
import Charts from '../index';
import DataFormatter from '../../utils/Formatters'

class Overview {


    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    /**
        * Ref No.: SALES001
        * Graph Name: Welcome Data
    */
    getSummary(fromDate, toDate) {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getSummary", fromDate, toDate);
    }

    getRevenue() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getRevenue");
    }

    getProfit(paramId, fromDate, toDate) {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getProfit", paramId, fromDate, toDate);
    }

    /**
        * Ref No.: SALES003
        * Graph Name: Overview Data
    */
    getOverview() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getOverview");
    }

    /**
        * Ref No.: SALES002/1
        * Graph Name: Customers Active Inactive Graph
    */
    getCustomers() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getCustomers");
    }

    /**
        * Ref No.: SALES002/2
        * Graph Name: Sales
    */
    getSales() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getSales");
    }

    /**
        * Ref No.: SALES004
        * Graph Name: Catalogue Data
    */
    getCatalogueData() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getCatalogueData");
    }

    /**
        * Ref No.: SALES007
        * Graph Name: Disputes Pie Chart
    */
    getDisputes() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getDisputes");
    }

    /**
        * Ref No.: SALES005
        * Graph Name: Top Sales Graph
        * action : 'price' or 'orderQuantity'
    */
    getTopSales(groupBy, action = "price", fromDate, toDate) {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getTopSales", groupBy, action = "price", fromDate, toDate);
    }

    /**
        * Ref No.: SALES006
        * Graph Name: Contract due for renewal graph
    */
    getContractDueForRenewal() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getContractDueForRenewal");
    }

    /**
        * Ref No.: SALES009
        * Graph Name: GRN Acknowledged Data
    */
    getGrnAcknowledged() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getGrnAcknowledged");
    }

    goodsSentButNotAcknowledged() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "goodsSentButNotAcknowledged");
    }

    getTopQuantityDisputes() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getTopQuantityDisputes");
    }

    getAverageCycleTime() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getAverageCycleTime");
    }

    //TODO
    getAllOpenOrPartialInvoices() {
        return this.mongoDBConnector.invoke("reports", "salesOverview", "getAllOpenOrPartialInvoices");
    }
}
export default Overview;
// module.exports = Overview;