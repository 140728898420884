import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import Web3Utils from 'web3-utils';
import BlockInfo from '../utils/block-info';

class VendorManager {

    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const venManager = require('./vendor-manager.json')
        this.vendorManagerContract = Web3_1_0.getContract(this.connection, venManager.abi, contractAddress);
        this.to = contractAddress;
    }

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }
        let buyerAddress, vendorAddress;
        if (options && options.address) {
            let address = Web3Utils.toChecksumAddress(options.address)
            options = { owner: address };
            vendorAddress = { vendor: address }
            buyerAddress = { buyer: address }
        }

        this.watchUpdateInviteEvents(options);
        this.watchAddEvalRequestEvent(options);
        this.watchAddInvitationTemplateEvent(options);
        this.watchAddPublicInvitationTemplateEvent(options);
        this.watchInvitedEventForVendor(vendorAddress);
        this.watchInvitedEventForBuyer(buyerAddress);
        this.watchAddInvitedEvents(options);
        // this.watchVendorInvitedEventForBuyer(buyerAddress)
        // this.watchBuyerInvitedEventForBuyer(options);
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }

    watchAddInvitedEvents(options) {
        if (typeof this.addInviteEvent === 'undefined' || !this.addInviteEvent) {
            let filter = {
                filter: options
            };
            this.addInviteEvent = Web3_1_0.getEvent(this, this.vendorManagerContract, "onAddInvited", filter);
        }
        let that = this;
        this.addInviteEvent.on('data', function (event) {
            that.emitEvent("onAddInvited", event);
        }).on('error', function (error) {
            that.events.emit("onAddInvited", error);
            that.addInviteEvent.unsubscribe();
            that.addInviteEvent = undefined;
            that.watchAddInvitedEvents(options);
        });
    }

    watchUpdateInviteEvents(options) {
        if (typeof this.updateInviteEvent === 'undefined' || !this.updateInviteEvent) {
            let filter = {
                filter: options
            };
            this.updateInviteEvent = Web3_1_0.getEvent(this, this.vendorManagerContract, "onInviteUpdated", filter);
        }
        let that = this;
        this.updateInviteEvent.on('data', function (event) {
            that.emitEvent("onInviteUpdated", event);
        }).on('error', function (error) {
            that.events.emit("onInviteUpdated", error);
            that.updateInviteEvent.unsubscribe();
            that.updateInviteEvent = undefined;
            that.watchUpdateInviteEvents(options);
        });
    }

    watchAddEvalRequestEvent(options) {

        if (typeof this.addEvalRequestEvent === 'undefined' || !this.addEvalRequestEvent) {
            let filter = {
                filter: options
            };
            this.addEvalRequestEvent = Web3_1_0.getEvent(this, this.vendorManagerContract, "onEvalRequestAdded", filter);
        }
        let that = this;
        this.addEvalRequestEvent.on('data', function (event) {
            that.emitEvent("onEvalRequestAdded", event);
        })
            .on('error', function (error) {
                that.events.emit("onEvalRequestAdded", error);
                that.addEvalRequestEvent.unsubscribe();
                that.addEvalRequestEvent = undefined;
                that.watchAddEvalRequestEvent(options);
            });
    }

    watchAddInvitationTemplateEvent(options) {

        if (typeof this.addInvitationTemplateEvent === 'undefined' || !this.addInvitationTemplateEvent) {
            let filter = {
                filter: options
            };
            this.addInvitationTemplateEvent = Web3_1_0.getEvent(this, this.vendorManagerContract, "onInvitationTemplateAdded", filter);
        }
        let that = this;
        this.addInvitationTemplateEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onInvitationTemplateAdded", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onInvitationTemplateAdded", error);
                that.addInvitationTemplateEvent.unsubscribe();
                that.addInvitationTemplateEvent = undefined;
                that.watchAddInvitationTemplateEvent(options);
            });
    }

    watchAddPublicInvitationTemplateEvent(options) {

        if (typeof this.addPublicInvitationTemplate === 'undefined' || !this.addPublicInvitationTemplate) {
            this.addPublicInvitationTemplate = Web3_1_0.getEvent(this, this.vendorManagerContract, "onPublicInvitationTemplateAdded");
        }
        let that = this;
        this.addPublicInvitationTemplate.on('data', function (event) {
            that.emitEvent("onPublicInvitationTemplateAdded", event);
        })
            .on('error', function (error) {
                that.events.emit("onPublicInvitationTemplateAdded", error);
                that.addPublicInvitationTemplate.unsubscribe();
                that.addPublicInvitationTemplate = undefined;
                that.watchAddPublicInvitationTemplateEvent(options);
            });
    }

    watchInvitedEventForVendor(vendorAddress) {
        if (typeof this.vendorInvited === 'undefined' || !this.vendorInvited) {
            let filter = {
                filter: vendorAddress
            };
            this.vendorInvited = Web3_1_0.getEvent(this, this.vendorManagerContract, "onInvited", filter);
        }
        let that = this;
        this.vendorInvited.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            if (!event.args || !event.args.owner) {
                return;
            }
            let transInfo = event.args;
            if (vendorAddress.vendor.toLowerCase() === transInfo.vendor.toLowerCase()) {
                that.emitEvent("onInvited", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onInvited", error);
                that.vendorInvited.unsubscribe();
                that.vendorInvited = undefined;
                that.watchInvitedEventForVendor(vendorAddress);
            });
    }

    watchInvitedEventForBuyer(buyerAddress) {
        if (typeof this.vendorInvited === 'undefined' || !this.vendorInvited) {
            let filter = {
                filter: buyerAddress
            };
            this.vendorInvited = Web3_1_0.getEvent(this, this.vendorManagerContract, "onInvited", filter);
        }
        let that = this;
        this.vendorInvited.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            if (!event.args || !event.args.owner) {
                return;
            }
            let transInfo = event.args;
            if (buyerAddress.buyer.toLowerCase() === transInfo.buyer.toLowerCase()) {
                that.emitEvent("onInvited", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onInvited", error);
                that.vendorInvited.unsubscribe();
                that.vendorInvited = undefined;
                that.watchInvitedEventForBuyer(buyerAddress);
            });
    }

    addVendorContact(vendorAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "addVendorContact", options, vendorAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode);
    }

    addVendorContactSync(vendorAddress, templateId, initiatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "addVendorContact", options, vendorAddress, templateId, initiatorJsonLd, receiverJsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }

            return logs[0].topics[4];
        })
    }

    addBuyerContact(buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "addBuyerContact", options, buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode)
    }

    addBuyerContactSync(buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "addBuyerContact", options, buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode)
            .then((txHash) => {
                return BlockInfo.getTransactionInfoSync(this.connection, txHash);
            }).then((logs) => {
                if (logs.length === 0) {
                    return Promise.reject();
                }

                return logs[0].topics[3];
            })
    }

    inviteVendor(vendorAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "inviteVendor", options, vendorAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode);
    }

    inviteVendorSync(vendorAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "inviteVendor", options, vendorAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[4];
        })
    }

    inviteBuyer(buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "inviteBuyer", options, buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode);
    }

    inviteBuyerSync(buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "inviteBuyer", options, buyerAddress, templateId, initatorJsonLd, receiverJsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[3];
        })
    }

    draftVendor(templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "draftVendor", options, templateId, initatorJsonLd, receiverJsonLd, txnMode);
    }

    draftVendorSync(templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "draftVendor", options, templateId, initatorJsonLd, receiverJsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[3];
        })
    }

    draftBuyer(templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "draftBuyer", options, templateId, initatorJsonLd, receiverJsonLd, txnMode);
    }

    draftBuyerSync(templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "draftBuyer", options, templateId, initatorJsonLd, receiverJsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[4];
        })
    }

    getShareKey(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "getShareKey", options, invitationId);
    }

    acceptBuyerInvite(invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "acceptBuyerInvite", options, invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode);
    }

    acceptBuyerInviteSync(invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "acceptBuyerInvite", options, invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[1];
        })
    }

    acceptVendorInvite(invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "acceptVendorInvite", options, invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode);
    }

    acceptVendorInviteSync(invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "acceptVendorInvite", options, invitationId, initatorJsonLd, receiverJsonLd, shareKey, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    updateInvite(invitationId, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "updateInvite", options, invitationId, templateId, initatorJsonLd, receiverJsonLd, txnMode);
    }

    updateInviteSync(invitationId, templateId, initatorJsonLd, receiverJsonLd, txnMode, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "updateInvite", options, invitationId, templateId, initatorJsonLd, receiverJsonLd, txnMode).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invitationId;
        })
    }

    rejectBuyerInvite(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectBuyerInvite", options, invitationId);
    }

    rejectBuyerInviteSync(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectBuyerInvite", options, invitationId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invitationId;
        })
    }

    rejectVendorInvite(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectVendorInvite", options, invitationId);
    }

    rejectVendorInviteSync(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectVendorInvite", options, invitationId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invitationId;
        })
    }

    approveVendor(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "approveVendor", options, invitationId);
    }

    approveVendorSync(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "approveVendor", options, invitationId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invitationId;
        })
    }

    approveBuyer(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "approveBuyer", options, invitationId);
    }

    approveVendorSync(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "approveVendor", options, invitationId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invitationId;
        })
    }

    rejectBuyer(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectBuyer", options, invitationId);
    }

    rejectBuyerSync(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectBuyer", options, invitationId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invitationId;
        })
    }

    rejectVendor(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectVendor", options, invitationId);
    }

    rejectVendorSync(invitationId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "rejectVendor", options, invitationId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return invitationId;
        })
    }

    evalRequest(invitationId, contractAddress, documentId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "evalRequest", options, invitationId, contractAddress, documentId);
    }

    evalRequestSync(invitationId, contractAddress, documentId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "evalRequest", options, invitationId, contractAddress, documentId).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[4];
        })
    }

    createInvitationTemplate(name, jsonLd, metaData, txnMode, templateType, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "createInvitationTemplate", options, name, jsonLd, metaData, txnMode, templateType);
    }

    createInvitationTemplateSync(name, jsonLd, metaData, txnMode, templateType, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "createInvitationTemplate", options, name, jsonLd, metaData, txnMode, templateType).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[2];
        })
    }

    createPublicInvitationTemplate(name, jsonLd, metaData, txnMode, templateType, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "createPublicInvitationTemplate", options, name, jsonLd, metaData, txnMode, templateType);
    }

    enableInvitationTemplate(templateId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "enableInvitationTemplate", options, templateId);
    }

    disableInvitationTemplate(templateId, options) {
        return Web3_1_0.send(this, this.vendorManagerContract, "disableInvitationTemplate", options, templateId);
    }

    getAllVendorsByBuyer(buyer) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllVendorsByBuyer", null, buyer);
    }

    getAllVendorsByStatus(buyerId, status) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllVendorsByStatus", null, buyerId, status);
    }

    getAllInvitationsByBuyer(buyer) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllInvitationsByBuyer", null, buyer);
    }

    getAllInvitationsByVendor(vendor) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllInvitationsByVendor", null, vendor);
    }

    getAllEvalRequestsByInvitation(invitationId) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllEvalRequestsByInvitation", null, invitationId);
    }

    getEvalRequest(evalRequestId) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getEvalRequest", null, evalRequestId);
    }

    getAllInvitationTemplate(owner) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllInvitationTemplate", null, owner);
    }

    getAllInvitationTemplates(owner) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllInvitationTemplates", null, owner);
    }

    getAllPublicInviteTemplates() {
        return Web3_1_0.call(this, this.vendorManagerContract, "getAllPublicInviteTemplates", null);
    }

    getInvitation(invitationId) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getInvitation", null, invitationId);
    }

    getInvitationProfile(invitationId) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getInvitationProfile", null, invitationId);
    }

    getInvitationTemplate(templateId) {
        return Web3_1_0.call(this, this.vendorManagerContract, "getInvitationTemplate", null, templateId);
    }

    registerOnInviteUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options);
        }
        if (this.events._events["onInviteUpdated"]) {
            this.events.removeAllListeners("onInviteUpdated")
        }
        this.events.addListener("onInviteUpdated", callback);
    }

    unRegisterOnInviteUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onInviteUpdated", callback);
    }

    registerOnEvalRequestAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options);
        }
        if (this.events._events["onEvalRequestAdded"]) {
            this.events.removeAllListeners("onEvalRequestAdded")
        }
        this.events.addListener("onEvalRequestAdded", callback);
    }

    unRegisterOnEvalRequestAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onEvalRequestAdded", callback);
    }

    registerOnInvitationTemplateAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options);
        }
        if (this.events._events["onInvitationTemplateAdded"]) {
            this.events.removeAllListeners("onInvitationTemplateAdded")
        }
        this.events.addListener("onInvitationTemplateAdded", callback);
    }

    unRegisterOnInvitationTemplateAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onInvitationTemplateAdded", callback);
    }

    registerOnPublicInvitationTemplateAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options);
        }
        if (this.events._events["onPublicInvitationTemplateAdded"]) {
            this.events.removeAllListeners("onPublicInvitationTemplateAdded")
        }
        this.events.addListener("onPublicInvitationTemplateAdded", callback);
    }

    unRegisterOnPublicInvitationTemplateAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onPublicInvitationTemplateAdded", callback);
    }

    registerOnInvited(callback, options) {
        if (!this.events) {
            this.initEvents(options);
        }
        if (this.events._events["onInvited"]) {
            this.events.removeAllListeners("onInvited")
        }
        this.events.addListener("onInvited", callback);
    }

    unRegisterOnInvited(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onInvited", callback);
    }

    registerOnAddInvited(callback, options) {
        if (!this.events) {
            this.initEvents(options);
        }
        if (this.events._events["onAddInvited"]) {
            this.events.removeAllListeners("onAddInvited")
        }
        this.events.addListener("onAddInvited", callback);
    }

    unRegisterOnAddInvited(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onAddInvited", callback);
    }

    // registerOnBuyerInvited(callback, options) {
    //     if (!this.events) {
    //         this.initEvents(options);
    //     }
    //     this.events.addListener("onBuyerInvited", callback);
    // }

    // unRegisterOnBuyerInvited(callback) {
    //     if (!this.events) {
    //         return;
    //     }
    //     this.events.removeListener("onBuyerInvited", callback);
    // }

}

export default VendorManager;