let jsonSchema = {
    validate: {
        type: 'object',
        additionalProperties: true,
        required: ["Version", "TranDtls", "DocDtls", "SellerDtls", "BuyerDtls", "ItemList", "ValDtls"],
        properties: {
            Version: {
                type: "string",
                minLength: 4,
                maxLength: 10
            },
            Irn: {
                type: "string",
                maxLength: 64,
                minLength: 0
            },
            TranDtls: {
                type: "object",
                additionalProperties: true,
                required: ["TaxSch", "SupTyp"],
                properties: {
                    TaxSch: {
                        type: "string",
                        minLength: 3,
                        maxLength: 10,
                    },
                    SupTyp: {
                        type: "string",
                        minLength: 3,
                        maxLength: 10,
                        enum: [
                            "B2B",
                            "SEZWP",
                            "SEZWOP",
                            "EXPWP",
                            "EXPWOP",
                            "DEXP"
                        ],
                    },
                    RegRev: {
                        type: "string",
                        minLength: 1,
                        maxLength: 1,
                        enum: [
                            "Y", "N"
                        ],
                    },
                    EcmGstin: {
                        type: "string",
                        minLength: 15,
                        maxLength: 15,
                        pattern: "([0-9]{2}[0-9A-Z]{13})",
                    }
                }
            },
            DocDtls: {
                type: "object",
                additionalProperties: true,
                required: ["Typ", "No", "Dt"],
                properties: {
                    Typ: {
                        type: "string",
                        minLength: 3,
                        maxLength: 11,
                        enum: [
                            "INV", "CRN", "DBN"
                        ],
                    },
                    No: {
                        type: "string",
                        minLength: 1,
                        maxLength: 16,
                        pattern: "^([A-Z1-9]{1}[A-Z0-9/-]{0,15})$",
                    },
                    Dt: {
                        type: "string",
                        minLength: 10,
                        maxLength: 10,
                        pattern: "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                    }
                }
            },
            SellerDtls: {
                type: "object",
                additionalProperties: true,
                required: ["Gstin", "LglNm", "Addr1", "Loc", "Pin", "State"],
                properties: {
                    Gstin: {
                        type: "string",
                        minLength: 15,
                        maxLength: 15,
                        pattern: "([0-9]{2}[0-9A-Z]{13})",
                    },
                    LglNm: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    TrdNm: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Addr1: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Addr2: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Loc: {
                        type: "string",
                        minLength: 3,
                        maxLength: 50,
                    },
                    Pin: {
                        type: "string",
                        minLength: 6,
                        maxLength: 6,
                    },
                    State: {
                        type: "string",
                        minimum: 3,
                        maximum: 50,
                    },
                    Ph: {
                        type: "string",
                        minLength: 10,
                        maxLength: 12,
                    },
                    Em: {
                        type: "string",
                        minLength: 6,
                        maxLength: 100,
                    }
                },

            },
            BuyerDtls: {
                type: "object",
                additionalProperties: true,
                required: ["Gstin", "LglNm", "Addr1", "Loc", "Pin", "State"],
                properties: {
                    Gstin: {
                        type: "string",
                        minLength: 3,
                        maxLength: 15,
                        pattern: "^(([0-9]{2}[0-9A-Z]{13})|URP)$",
                    },
                    LglNm: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    TrdNm: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Pos: {
                        type: "string",
                        minLength: 1,
                        maxLength: 2,
                    },
                    Addr1: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Addr2: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Loc: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Pin: {
                        type: "string",
                        minLength: 6,
                        maxLength: 6,
                    },
                    State: {
                        type: "string",
                        minimum: 3,
                        maximum: 50,
                    },
                    Ph: {
                        type: "string",
                        minLength: 10,
                        maxLength: 12,
                    },
                    Em: {
                        type: "string",
                        minLength: 6,
                        maxLength: 100,
                    }
                }
            },
            DispDtls: {
                type: "object",
                additionalProperties: true,
                required: ["Nm", "Addr1", "Loc", "Pin", "Stcd"],
                properties: {
                    Nm: {
                        type: "string",
                        minLength: 3,
                        maxLength: 60,
                    },
                    Addr1: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Addr2: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Loc: {
                        type: "string",
                        minLength: 3,
                        maxLength: 100,
                    },
                    Pin: {
                        type: "string",
                        minLength: 6,
                        maxLength: 6,
                    },
                    Stcd: {
                        type: "string",
                        minimum: 1,
                        maximum: 2,
                    }
                }

            },
            ShipDtls: {
                type: "object",
                additionalProperties: true,
                required: ["LglNm",
                    "Addr1",
                    "Loc",
                    "Pin",
                    "Stcd"],
                "properties": {
                    "Gstin": {
                        "type": "string",
                        "maxLength": 15,
                        "minLength": 3,
                        "pattern": "^(([0-9]{2}[0-9A-Z]{13})|URP)$",
                    },
                    "LglNm": {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 60,
                    },
                    "TrdNm": {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 60,
                    },
                    "Addr1": {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    "Addr2": {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    "Loc": {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    "Pin": {
                        "type": "string",
                        "minLength": 6,
                        "maxLength": 6,
                    },
                    "Stcd": {
                        "type": "string",
                        "minimum": 1,
                        "maximum": 2,
                    }
                }
            },
            ItemList: {
                additionalProperties: true,
                type: "array",
                Item: [
                    {
                        type: "object",
                        additionalProperties: true,
                        required: [
                            "SlNo",
                            "IsServc",
                            "HsnCd",
                            "UnitPrice",
                            "TotAmt",
                            "AssAmt",
                            "GstRt",
                            "TotItemVal"
                        ],
                        properties: {
                            "SlNo": {
                                "type": "string",
                                "minLength": 1,
                                "maxLength": 6,
                            },
                            "PrdDesc": {
                                "type": "string",
                                "minLength": 3,
                                "maxLength": 300,
                            },
                            "IsServc": {
                                "type": "string",
                                "minLength": 1,
                                "maxLength": 1,
                                "enum": [
                                    "Y", "N"
                                ],
                            },
                            "HsnCd": {
                                "type": "string",
                                "minLength": 4,
                                "maxLength": 8,
                            },
                            "BchDtls": {
                                "type": "object",
                                "properties": {
                                    "Nm": {
                                        "type": "string",
                                        "minLength": 3,
                                        "maxLength": 20,

                                    },
                                    "ExpDt": {
                                        "type": "string",
                                        "maxLength": 10,
                                        "minLength": 10,
                                        "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",

                                    },
                                    "WrDt": {
                                        "type": "string",
                                        "maxLength": 10,
                                        "minLength": 10,
                                        "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",

                                    }
                                },
                                "required": [
                                    "Nm"
                                ]
                            },
                            "Barcde": {
                                "type": "string",
                                "minLength": 3,
                                "maxLength": 30,
                            },
                            "Qty": {
                                "type": "string",
                                "minLength": 1,
                                "maxLength": 20,
                            },
                            "FreeQty": {
                                "type": "string",
                                "minLength": 1,
                                "maxLength": 20,
                            },
                            "Unit": {
                                "type": "string",
                                "minLength": 3,
                                "maxLength": 8,
                                "enum": [
                                    "BAG",
                                    "BAL",
                                    "BDL",
                                    "BKL",
                                    "BOU",
                                    "BOX",
                                    "BTL",
                                    "BUN",
                                    "CAN",
                                    "CBM",
                                    "CCM",
                                    "CMS",
                                    "CTN",
                                    "DOZ",
                                    "DRM",
                                    "GGK",
                                    "GMS",
                                    "GRS",
                                    "GYD",
                                    "KGS",
                                    "KLR",
                                    "KME",
                                    "LTR",
                                    "MTR",
                                    "MLT",
                                    "MTS",
                                    "NOS",
                                    "OTH",
                                    "PAC",
                                    "PCS",
                                    "PRS",
                                    "QTL",
                                    "ROL",
                                    "SET",
                                    "SQF",
                                    "SQM",
                                    "SQY",
                                    "TBS",
                                    "TGM",
                                    "THD",
                                    "TON",
                                    "TUB",
                                    "UGS",
                                    "UNT",
                                    "YDS"
                                ],
                            },
                            "UnitPrice": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "TotAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "Discount": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 9999999999.99,
                            },
                            "PreTaxVal": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 9999999999999.99,
                            },
                            "AssAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 9999999999999.99,
                            },
                            "GstRt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 999.999,
                            },
                            "IgstAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "CgstAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "SgstAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "CesRt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "CesAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "CesNonAdvlAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "StateCesRt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "StateCesAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "StateCesNonAdvlAmt": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "OthChrg": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "TotItemVal": {
                                "type": "number",
                                "minimum": 0,
                                "maximum": 99999999999.99,
                            },
                            "OrdLineRef": {
                                "type": "string",
                                "minLength": 1,
                                "maxLength": 50,
                            },
                            "OrgCntry": {
                                "type": "string",
                                "minLength": 2,
                                "maxLength": 2,
                            },
                            "PrdSlNo": {
                                "type": "string",
                                "minLength": 1,
                                "maxLength": 15,
                            },
                            "AttribDtls": {
                                "type": "array",
                                "Attribute": [{
                                    "type": "object",
                                    "properties": {
                                        "Nm": {
                                            "type": "string",
                                            "minLength": 3,
                                            "maxLength": 100,

                                        },
                                        "Val": {
                                            "type": "string",
                                            "minLength": 3,
                                            "maxLength": 100,

                                        }
                                    }
                                }]
                            }
                        }
                    }
                ]
            },
            ValDtls: {
                type: "object",
                additionalProperties: true,
                required: ["AssVal", "TotInvVal"],
                properties: {
                    AssVal: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 9999999999999.99,
                    },
                    CgstVal: {
                        "type": "number",
                        "maximum": 99999999999.99,
                        "minimum": 0,
                    },
                    SgstVal: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 99999999999.99,
                    },
                    IgstVal: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 99999999999.99,
                    },
                    CesVal: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 99999999999.99,
                    },
                    StCesVal: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 99999999999.99,
                    },
                    RndOffAmt: {
                        "type": "number",
                        "minimum": -999,
                        "maximum": 9999.99,
                    },
                    TotInvVal: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 9999999999999.99,
                    },
                    TotInvValFc: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 99999999999.99,
                    }
                }
            },
            PayDtls: {
                type: "object",
                additionalProperties: true,
                properties: {
                    Nm: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    AccDet: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 18,
                    },
                    Mode: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 18,
                    },
                    FinInsBr: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 11,
                    },
                    PayTerm: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    PayInstr: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    CrTrn: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    DirDr: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    CrDay: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 9999,
                    },
                    PaidAmt: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 9999999999.99,
                    },
                    PaymtDue: {
                        "type": "number",
                        "minimum": 0,
                        "maximum": 9999999999.99,
                    }
                }
            },
            RefDtls: {
                type: "object",
                additionalProperties: true,
                required: ["InvStDt", "InvEndDt"],
                properties: {
                    InvRm: {
                        "type": "string",
                        "maxLength": 100,
                        "minLength": 3,
                        "pattern": "^[0-9A-Za-z/-]{3,100}$",
                    },
                    InvStDt: {
                        "type": "string",
                        "maxLength": 10,
                        "minLength": 10,
                        "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                    },
                    InvEndDt: {
                        "type": "string",
                        "maxLength": 10,
                        "minLength": 10,
                        "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                    },
                    PrecDocDtls: {
                        "type": "array",
                        PrecDocument: [{
                            "type": "object",
                            "properties": {
                                "InvNo": {
                                    "type": "string",
                                    "minLength": 3,
                                    "maxLength": 16,
                                    "pattern": "^[1-9A-Za-z]{1}[0-9A-Za-z/-]{3,15}$",
                                },
                                "InvDt": {
                                    "type": "string",
                                    "maxLength": 10,
                                    "minLength": 10,
                                    "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                                },
                                "OthRefNo": {
                                    "type": "string",
                                    "minLength": 3,
                                    "maxLength": 20,
                                }
                            },
                            required: ["InvNo", "InvDt"]
                        }]
                    },
                    ContrDtls: {
                        "type": "array",
                        Contract: [{
                            "type": "object",
                            "properties": {
                                "RecAdvRefr": {
                                    "type": "string",
                                    "minLength": 3,
                                    "maxLength": 20,
                                    "pattern": "^([0-9A-Z-a-z/]){3,20}$",
                                    "description": "Receipt Advice No."
                                },
                                "RecAdvDt": {
                                    "type": "string",
                                    "minLength": 10,
                                    "maxLength": 10,
                                    "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                                    "description": "Date of receipt advice"
                                },
                                "TendRefr": {
                                    "type": "string",
                                    "minLength": 3,
                                    "maxLength": 20,
                                    "pattern": "^([0-9A-Z-a-z/]){3,20}$",
                                    "description": "Lot/Batch Reference No."
                                },
                                "ContrRefr": {
                                    "type": "string",
                                    "minLength": 3,
                                    "maxLength": 20,
                                    "pattern": "^([0-9A-Z-a-z/]){3,20}$",
                                    "description": "Contract Reference Number"
                                },
                                "ExtRefr": {
                                    "type": "string",
                                    "minLength": 3,
                                    "maxLength": 20,
                                    "pattern": "^([0-9A-Z-a-z/]){3,20}$",
                                    "description": "Any other reference"
                                },
                                "ProjRefr": {
                                    "type": "string",
                                    "maxLength": 20,
                                    "minLength": 3,
                                    "pattern": "^([0-9A-Z-a-z/]){3,20}$",
                                    "description": "Project Reference Number"
                                },
                                "PORefr": {
                                    "type": "string",
                                    "maxLength": 16,
                                    "minLength": 3,
                                    "pattern": "^([0-9A-Z-a-z/]){3,16}$",
                                    "description": "Vendor PO Reference Number"
                                },
                                "PORefDt": {
                                    "type": "string",
                                    "minLength": 10,
                                    "maxLength": 10,
                                    "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                                    "description": "Vendor PO Reference date"
                                }
                            }
                        }]
                    }
                }
            },
            AddlDocDtls: {
                "type": "array",
                "AddlDocument": [{
                    "type": "object",
                    "properties": {
                        "Url": {
                            "type": "string",
                            "minLength": 3,
                            "maxLength": 100,
                            "description": "Supporting document URL"
                        },
                        "Docs": {
                            "type": "string",
                            "minLength": 3,
                            "maxLength": 1000,
                            "description": "Supporting document in Base64 Format"
                        },
                        "Info": {
                            "type": "string",
                            "minLength": 3,
                            "maxLength": 1000,
                            "description": "Any additional information"
                        }
                    }
                }]
            },
            ExpDtls: {
                type: "object",
                additionalProperties: true,
                properties: {
                    "ShipBNo": {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 20,
                    },
                    "ShipBDt": {
                        "type": "string",
                        "minLength": 10,
                        "maxLength": 10,
                        "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                    },
                    "Port": {
                        "type": "string",
                        "minLength": 2,
                        "maxLength": 10,
                        "pattern": "^[0-9A-Za-z]{2,10}$",
                    },
                    "RefClm": {
                        "type": "string",
                        "minLength": 1,
                        "maxLength": 1,
                    },
                    "ForCur": {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 16,
                    },
                    "CntCode": {
                        "type": "string",
                        "minLength": 2,
                        "maxLength": 2,
                    }
                }
            },
            EwbDtls: {
                type: "object",
                additionalProperties: true,
                required: ["Distance", "TransMode"],
                properties: {
                    TransId: {
                        "type": "string",
                        "minLength": 15,
                        "maxLength": 15,
                    },
                    TransName: {
                        "type": "string",
                        "minLength": 3,
                        "maxLength": 100,
                    },
                    "TransMode": {
                        "type": "string",
                        "maxLength": 1,
                        "minLength": 1,
                        "enum": ["1", "2", "3", "4"],
                    },
                    "Distance": {
                        "type": "string",
                        "maxLength": 1,
                        "minLength": 4,
                    },
                    "TransDocNo": {
                        "type": "string",
                        "minLength": 1,
                        "maxLength": 15,
                        "pattern": "^([0-9A-Z-a-z/]){1,15}$",
                    },
                    "TransDocDt": {
                        "type": "string",
                        "minLength": 10,
                        "maxLength": 10,
                        "pattern": "[0-3][0-9]/[0-1][0-9]/[2][0][1-2][0-9]",
                    },
                    "VehNo": {
                        "type": "string",
                        "minLength": 4,
                        "maxLength": 20,
                    },
                    "VehType": {
                        "type": "string",
                        "minLength": 1,
                        "maxLength": 1,
                        "enum": ["O", "R"],
                    }
                }
            }
        }
    }
}
export default jsonSchema;