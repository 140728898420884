class Reconciliation {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    dashboard = (address, currency, fromDate, toDate, requiredData) => {
        if (!currency) {
            currency = "INR";
        }
        return this.mongoDBConnector.invoke('reconciliation', "dashboard", address, currency, fromDate, toDate, requiredData);
    }

    getReceiptsSummaryByParticipants = (options, currency) => {
        if (!currency) {
            currency = "INR";
        }
        return this.mongoDBConnector.invoke('reconciliation', "getReceiptsSummaryByParticipants", options, currency);
    }

    getReceiptsSummaryByParticipantsStepLevel(options, currency, step, pageNumber, nPerPage){
        return this.mongoDBConnector.invoke('reconciliation', "getReceiptsSummaryByParticipantsStepLevel", options, currency, step, pageNumber, nPerPage)
    }
}
export default Reconciliation