import ParamConnector from '../../param-connector';
import * as Utils from '../utils.js';

class NotificationManager {
    static getAllNotifications() {
        let paramId = Utils.getParamId();
        return ParamConnector.getInstance().getDB().notifications.getAllNotifications(paramId);

    }

    static getNotification(notificationId) {
        return ParamConnector.getInstance().getDB().notifications.getNotification(notificationId);
    }
    static markAsRead(notificationId) {
        return ParamConnector.getInstance().getDB().notifications.markAsRead(notificationId);
    }
    static deleteNotification(notificationId) {
        return ParamConnector.getInstance().getDB().notifications.deleteNotification(notificationId);
    }
    static deleteAllNotifications(notificationIds) {
        return ParamConnector.getInstance().getDB().notifications.deleteAllNotifications(notificationIds);
    }
}

export default NotificationManager;