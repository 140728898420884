import ParamConnector from '../../../../src/param-connector';
import * as Utils from '../../../../src/util/utils';
import ECIES from '../../../../src/util/ecies';
import Console from '../../../logger';
import NetworkDataFormat from '../../nosql/Utils/network-data-format';
import { CatalogueEvents } from '../../../../src/param-network/utils/event-names';
class RestoreItems {
    static restoreItem(itemId, contractAddress, primaryOwner, forSubscriber, fromEvent, updateItem) {
        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getCatalogue(itemId).then(item => {
            if (!item) {
                return;
            }
            return itemsInstance.getSharedKey(dbItemId).then(sharedKey => {
                let privateKey = Utils.getPrivateKey();
                let itemShareKey;
                if (sharedKey.itemShareKey && sharedKey.inventoryShareKey) {
                    itemShareKey = sharedKey.itemShareKey;
                }
                if (item.catalogueTxnMode === "2") {
                    if (!itemShareKey)
                        itemShareKey = ECIES.decrypt(privateKey, item.catalogueKey);
                    item.catalogueInfo = ECIES.decrypt(itemShareKey, item.catalogueInfo);
                }
                if (!updateItem) {
                    return itemsInstance.doesExist(dbItemId).then(doesExist => {
                        if (doesExist) {
                            Console.log("Item already exists. Skipping..!")
                            return;
                        }
                        return itemsInstance.addItem(dbItemId, item.catalogueInfo, primaryOwner, undefined, itemShareKey, forSubscriber)
                            .then(() => {
                                if (!fromEvent)
                                    return RestoreItems.restoreInventory(itemId, item.inventoryContractAddress, primaryOwner, contractAddress);
                            });
                    })
                }
                return itemsInstance.updateItem(dbItemId, item.catalogueInfo, primaryOwner, undefined, itemShareKey, forSubscriber)
                    .then(() => {
                        if (!fromEvent)
                            return RestoreItems.restoreInventory(itemId, item.inventoryContractAddress, primaryOwner, contractAddress);
                    });
            });
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItem for item id ${itemId} and contract address ${contractAddress}`);
        });
    }

    static restoreInventory(itemId, inventoryContractAddress, owner, itemContractAddress, forSubscriber) {
        let dbItemId = Utils.getTransactionId(itemId, itemContractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        let itemAlreadyExist = true;
        return itemsInstance.doesExist(dbItemId).then(doesExist => {
            if (!doesExist) {
                itemAlreadyExist = false;
                return RestoreItems.restoreItem(itemId, itemContractAddress, owner, forSubscriber);
            }
            return ParamConnector.getInstance().getNetwork().getInventoryManager(inventoryContractAddress).getInventoryByCatalogueId(itemId);
        }).then(inventoryData => {
            let dbInventoryId = Utils.getTransactionId(inventoryData.inventoryId, inventoryContractAddress);
            return itemsInstance.getSharedKey(dbItemId).then(sharedKey => {
                let privateKey = Utils.getPrivateKey();
                let inventoryShareKey;
                if (sharedKey.inventoryShareKey) {
                    inventoryShareKey = sharedKey.inventoryShareKey;
                }
                if (inventoryData.inventoryDataTxnMode === "2") {
                    try {
                        if (!inventoryShareKey)
                            inventoryShareKey = ECIES.decrypt(privateKey, inventoryData.inventoryKey);
                        inventoryData.inventoryData = ECIES.decrypt(inventoryShareKey, inventoryData.inventoryData);
                    } catch (e) {
                        Console.error("Error in decrypting inventory", e);
                        inventoryData.inventoryData = {}
                    }
                }
                return itemsInstance.updateInventory(dbInventoryId, inventoryData.inventoryData, dbItemId, inventoryShareKey, forSubscriber);
            });
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreInventory`);
        });
    }

    static restoreInventoryUpdatableHistory(contractAddress, inventoryId, historyIndex, metaData) {
        let dbInventoryId = Utils.getTransactionId(inventoryId, contractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        return itemsInstance.updateInventoryHistory(dbInventoryId, historyIndex, metaData).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreInventoryUpdatableHistory`);
        });;
    }

    static restoreItemMapping(contractAddress) {
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllUpdatedItemsForBuyer(Utils.getParamId()).then(mappings => {
            let buyerItemId, sellerItemId, buyerId, sellerId, status, promise, promiseArray = [];
            for (let index in mappings) {
                buyerItemId = Utils.getTransactionId(mappings[index].buyerItemId, contractAddress);
                sellerItemId = Utils.getTransactionId(mappings[index].sellerItemId, contractAddress);
                buyerId = mappings[index].buyerId;
                sellerId = mappings[index].sellerId;
                status = mappings[index].status;
                promise = itemsInstance.mapItemsV1(buyerItemId, sellerItemId, buyerId, sellerId, status);
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllUpdatedItemsForSeller(Utils.getParamId()).then(mappings => {
                let buyerItemId, sellerItemId, buyerId, sellerId, status, promise, promiseArray = [];
                for (let index in mappings) {
                    buyerItemId = Utils.getTransactionId(mappings[index].buyerItemId, contractAddress);
                    sellerItemId = Utils.getTransactionId(mappings[index].sellerItemId, contractAddress);
                    buyerId = mappings[index].buyerId;
                    sellerId = mappings[index].sellerId;
                    status = mappings[index].status;
                    promise = itemsInstance.mapItemsV1(buyerItemId, sellerItemId, buyerId, sellerId, status);
                    promiseArray.push(promise);
                }
                return Promise.all(promiseArray);
            })
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItemMapping`);
        });
    }

    static restoreItemForSubscriber(itemId, contractAddress, sId, owner, updateItem) {
        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        let paramNetwork = ParamConnector.getInstance().getNetwork();
        let item, inventory, inventoryShareKey, itemShareKey, dbInventoryId;

        return paramNetwork.getCatalogueManager(contractAddress).getCatalogue(itemId).then(res => {
            item = res;
            if (!item) {
                return;
            }
            return paramNetwork.getInventoryManager(item[4]).getInventoryByCatalogueId(itemId);
        }).then(res => {
            inventory = res;
            dbInventoryId = Utils.getTransactionId(inventory.inventoryId, item[4]);
            return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getSubscriber(sId);
        }).then(subscriberInfo => {
            let subscriberShareKey = subscriberInfo[1];
            let privateKey = Utils.getPrivateKey();
            itemShareKey = subscriberShareKey;
            inventoryShareKey = subscriberShareKey;
            if (item.catalogueTxnMode === "2") {
                itemShareKey = ECIES.decrypt(privateKey, itemShareKey);
                item.catalogueInfo = ECIES.decrypt(itemShareKey, item.catalogueInfo);
            }
            if (inventory.inventoryDataTxnMode === '2') {
                inventoryShareKey = ECIES.decrypt(privateKey, inventoryShareKey);
                inventory.inventoryData = ECIES.decrypt(inventoryShareKey, inventory.inventoryData);
            }

            return itemsInstance.doesExist(dbItemId);
        }).then(doesExist => {

            let catalogueFunction = "addItem";
            if (updateItem || doesExist) {
                catalogueFunction = "updateItem";
            }

            return Promise.all([
                itemsInstance[catalogueFunction](dbItemId, item.catalogueInfo, owner, undefined, itemShareKey, true),
                itemsInstance.updateInventory(dbInventoryId, inventory.inventoryData, dbItemId, inventoryShareKey, true)
            ]);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItemForSubscriber`);
        });
    }

    updateItemForSubscriber(itemId, contractAddress, owner){

        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        let paramNetwork = ParamConnector.getInstance().getNetwork();
        let item, inventory;

        return paramNetwork.getCatalogueManager(contractAddress).getCatalogue(itemId).then(res => {
            item = res;
            if (!item) {
                return;
            }
            return paramNetwork.getInventoryManager(item[4]).getInventoryByCatalogueId(itemId);
        }).then(res => {
            inventory = res;
            return itemsInstance.getSharedKey(dbItemId);
        }).then(sharedKey => {
            const itemShareKey = sharedKey.itemShareKey;
            const inventoryShareKey = sharedKey.inventoryShareKey;
            if (item.catalogueTxnMode === "2") {
                item.catalogueInfo = ECIES.decrypt(itemShareKey, item.catalogueInfo);
            }
            if (inventory.inventoryDataTxnMode === '2') {
                inventory.inventoryData = ECIES.decrypt(inventoryShareKey, inventory.inventoryData);
            }

            return Promise.all([
                itemsInstance.updateItem(dbItemId, item.catalogueInfo, owner, undefined, itemShareKey, true),
                itemsInstance.updateInventory(itemId, inventory.inventoryData, itemId, inventoryShareKey, true)
            ]);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreItemForSubscriber`);
        });
    }

    static restoreAllSubscribers(itemId, contractAddress) {
        let dbItemId = Utils.getTransactionId(itemId, contractAddress);
        let itemsInstance = ParamConnector.getInstance().getDB().items;
        return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllSubscribers(itemId).then(sIds => {
            let promise, promiseArray = [];
            for (let index in sIds) {
                promise = ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getSubscriber(sIds[index]).then(subscriberInfo => {
                    return itemsInstance.addSubscriber(dbItemId, subscriberInfo[0]);
                });
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreAllSubscribers`);
        });
    }

    static restoreAllSubscribedItems(contractAddress) {
        return Promise.resolve();

        // NEED TO IMPLEMENT 

        // return GraphDB.getInstance().vendorManagement.getAllVendors(Utils.getParamId()).then(vendors => {
        //     let promise, promiseArray = [];
        //     for (let index in vendors.invitations) {
        //         promise = GraphDB.getInstance().vendorManagement.getContactSummary(vendors.invitations[index]).then(vendorDetails => {
        //             return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllSubscribedCatalogues(vendorDetails.identifier, Utils.getParamId()).then(itemIds => {
        //                 let innerPromise, innerPromiseArray = [];
        //                 for (let index in itemIds) {
        //                     innerPromise = ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getCatalogueForSubscriber(itemIds[index], Utils.getParamId()).then(data => {
        //                         return RestoreItems.restoreItemForSubscriber(itemIds[index], contractAddress, data.sId, vendorDetails.identifier);
        //                     })
        //                     innerPromiseArray.push(innerPromise);
        //                 }
        //                 return Promise.all(innerPromiseArray);
        //             })
        //         })
        //         promiseArray.push(promise);
        //     }
        //     return Promise.all(promiseArray);
        // }).catch(e => {
        //     Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreAllSubscribedItems`);
        // });
    }

    // static _restoreAllSubscribedItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
    //     let itemPromises = [];
    //     let itemsLength = itemIds.length;
    //     let endIndex = ((batchStartIndex + 1) * batchSize)
    //     endIndex = Math.min(itemsLength, endIndex);
    //     if (endIndex === itemsLength) {
    //         endIndex = endIndex + 1;
    //     }
    //     let startIndex = (batchStartIndex * batchSize)
    //     Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)

    //     for (let index = startIndex; index < endIndex; index++) {

    //     }
    //     return Promise.all(itemPromises).then(result => {
    //         Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
    //         if (endIndex === itemsLength + 1) {
    //             return;
    //         }
    //         return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
    //     }).catch(() => {
    //         Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
    //         if (endIndex === itemsLength + 1) {
    //             return;
    //         }
    //         return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
    //     });
    // }

    static restoreAllItems(contractAddress, catalogueOwnerAddress, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("items", `Trying to get all the catalogue from ${contractAddress}`);
        }
        return ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress).getAllCatalogues(catalogueOwnerAddress).then(itemIds => {
            Console.info(`[MongoDB] Got items meta info from network.`);
            if (!batchSize) {
                batchSize = 50;
            }
            return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, 0, batchSize)
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring items [Reason] ${e} [Module] : Sync/items/restoreAllItems`);
        });
    }

    static _restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
        let itemPromises = [];
        let itemsLength = itemIds.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(itemsLength, endIndex);
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)

        for (let index = startIndex; index < endIndex; index++) {
            let itemId = itemIds[index].toString();
            // itemId = Utils.getTransactionId(itemId, contractAddress);
            let itemPromise = RestoreItems.restoreItem(itemId, contractAddress, catalogueOwnerAddress).then(() => {
                return RestoreItems.restoreAllSubscribers(itemId, contractAddress);
            });
            itemPromises.push(itemPromise);
        }
        return Promise.all(itemPromises).then(result => {
            Console.log(`Completed batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreItemsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        });
    }
    static restoreAllCatalogueEvents(contractAddress, owner, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("catalogue", `Trying to get all the item events from ${contractAddress}`);
        }
        let catalogueIds;
        let catalogueManager = ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress);
        return catalogueManager.getAllCatalogues(owner).then(res => {
            catalogueIds = res;
            if (!batchSize) {
                batchSize = 50;
            }
            return RestoreItems._restoreCatalogueEventsAsBatch(catalogueIds, contractAddress, owner, 0, batchSize);
        })
            // Uncomment once version control is working properly
            // .then(() => {
            //     return GraphDB.getInstance().versionControl.getContractAddressFromName("ParamInventoryImpl");
            // })
            .then(() => {
                let inventoryContractAddresses = ["0xc0B7D3995922086AaF246710868759AaD021C25b"];
                let inventoryPromiseArray = []
                for (let index in inventoryContractAddresses) {
                    inventoryPromiseArray.push(RestoreItems.restoreAllInventoryEvents(catalogueIds, inventoryContractAddresses[index], callback))
                }
                return Promise.all(inventoryPromiseArray);
            }).catch(e => {
                Console.error(`[MongoDB] Error in restoring items events [Reason] ${e} [Module] : Sync/items/restoreAllCatalogueEvents`);
            });
    }

    static _restoreCatalogueEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
        let itemsLength = itemIds.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(itemsLength, endIndex);
        // if (endIndex === itemsLength) {
        //     endIndex = endIndex + 1;
        // }
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items events ${itemsLength}`)
        let catalogueManager = ParamConnector.getInstance().getNetwork().getCatalogueManager(contractAddress);
        let promiseArray = [];
        let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
        let selfParamId = Utils.getParamId();
        for (let index = startIndex; index < endIndex; index++) {
            promiseArray.push(catalogueManager.getAllEventsByCatalogueId(itemIds[index].toString()).then(catalogueEvents => {
                return RestoreItems.restoreCatalogueIdEvents(transactionsDB, selfParamId, contractAddress, catalogueEvents);
            }))
        }
        return Promise.all(promiseArray).then(result => {
            Console.log(`Completed  batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreCatalogueEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        }).catch(() => {
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for items events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreCatalogueEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        });
    }
    static restoreCatalogueIdEvents(transactionsDB, selfParamId, contractAddress, catalogueEvents) {
        let addTransactionsPromiseArray = [];
        for (let index in catalogueEvents) {
            let event = catalogueEvents[index];
            let label = CatalogueEvents[event.event].label;
            event.args.catalogueId = Utils.getTransactionId(event.args.catalogueId, contractAddress);
            let metaData = NetworkDataFormat.getMetaInfo(event.args.catalogueId, event, label);
            let owner = event.args.owner;
            let transactionPromise = transactionsDB.addTransaction(event.args.catalogueId, owner, metaData).catch(error => {
                Console.error(`Unable to restore catalogue event for ${event.args.catalogueId}, [Reason]: ${error}`)
            })
            addTransactionsPromiseArray.push(transactionPromise);
        }
        return Promise.all(addTransactionsPromiseArray).catch(e => {
            Console.error(`[MongoDB] Error in restoring items events [Reason] ${e} [Module] : Sync/items/restoreCatalogueIdEvents`);
        });
    }


    static restoreAllInventoryEvents(catalogueIds, contractAddress, callback, batchSize) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("inventory", `Trying to get all the inventory events from ${contractAddress}`);
        }
        if (!batchSize) {
            batchSize = 50;
        }
        return RestoreItems._restoreAllInventoryEventsAsBatch(catalogueIds, contractAddress, undefined, 0, batchSize);

    }
    static _restoreAllInventoryEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex, batchSize) {
        let itemsLength = itemIds.length;
        let endIndex = ((batchStartIndex + 1) * batchSize)
        endIndex = Math.min(itemsLength, endIndex);
        // if (endIndex === itemsLength) {
        //     endIndex = endIndex + 1;
        // }
        let startIndex = (batchStartIndex * batchSize)
        Console.log(`Starting batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for iventory events ${itemsLength}`)

        let inventoryManager = ParamConnector.getInstance().getNetwork().getInventoryManager(contractAddress);
        let promiseArray = [];
        let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
        let selfParamId = Utils.getParamId();
        for (let index = startIndex; index < endIndex; index++) {
            promiseArray.push(
                inventoryManager.getInventoryUpdateEventsByCatalogueId(itemIds[index]).then(inventoryEvents => {
                    return RestoreItems.restoreInventoryIdEvents(transactionsDB, selfParamId, contractAddress, inventoryEvents);
                })
            )
        }
        return Promise.all(promiseArray).then(result => {
            Console.log(`Completed  batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for inventory events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreAllInventoryEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        }).catch((e) => {
            Console.error(`[MongoDB] Error in restoring inventory events [Reason] ${e} [Module] : Sync/items/restoreAllInventoryEvents`);
            Console.log(`Error batch no: ${batchStartIndex} ranging from ${startIndex} & ${endIndex} for inventory events ${itemsLength}`)
            if (endIndex === itemsLength) {
                return;
            }
            return RestoreItems._restoreAllInventoryEventsAsBatch(itemIds, contractAddress, catalogueOwnerAddress, batchStartIndex + 1, batchSize);
        });
    }
    static restoreInventoryIdEvents(transactionsDB, selfParamId, contractAddress, inventoryEvents) {
        let oldInventoryData, eventPromiseArray = [], itemId;
        if (inventoryEvents.length) {
            itemId = Utils.getTransactionId(inventoryEvents[0].args.catalogueId, inventoryEvents[0].args.catalogueContractAddress);
            let inventoryId = inventoryEvents[0].args.inventoryId;
            return ParamConnector.getInstance().getDB().items.getSharedKey(itemId).then(itemShareKeyResponse => {
                for (let index in inventoryEvents) {
                    if (!itemShareKeyResponse.inventoryShareKey) {
                        Console.log("skipping inventory id events restore because of missing inventoryShareKey")
                        continue;
                    }
                    try {
                        let eventData = inventoryEvents[index];
                        eventPromiseArray.push(RestoreItems.restoreInventoryUpdateEvent(eventData, oldInventoryData, transactionsDB, itemShareKeyResponse));
                        oldInventoryData = ECIES.decrypt(itemShareKeyResponse.inventoryShareKey, eventData.args.inventoryData);

                        oldInventoryData = JSON.parse(oldInventoryData);
                    } catch (error) {

                    }
                }
                eventPromiseArray.push(RestoreItems.restoreInventoryHistoryEvents(inventoryId, contractAddress));
            })
        }
        return Promise.all(eventPromiseArray).catch(e => {
            Console.error(`[MongoDB] Error in restoring inventory events [Reason] ${e} [Module] : Sync/items/restoreInventoryIdEvents`);
        });
    }

    static restoreInventoryUpdateEvent(eventData, oldInventoryData, transactionsDB, itemShareKeyResponse) {
        let itemId = Utils.getTransactionId(eventData.args.catalogueId, eventData.args.catalogueContractAddress);
        let metaData = NetworkDataFormat.getMetaInfo(itemId, eventData, "Inventory Updated");
        let eventMetaData = JSON.parse(eventData.args.metaData);
        metaData._documentId = eventMetaData._documentId;
        metaData._event = eventMetaData._event;
        metaData.adjustedBy = eventMetaData.adjustedBy;
        metaData._docType = eventMetaData._docType;
        metaData.inventoryHistoryIndex = eventData.args.historyIndex;
        try {
            metaData.inventoryData = ECIES.decrypt(itemShareKeyResponse.inventoryShareKey, eventData.args.inventoryData);
        } catch (err) {
            return;
        }
        let updateType = "increment", adjustment;
        const currentValue = Number(JSON.parse(metaData.inventoryData).inventoryLevel.currentValue || 0);
        if (oldInventoryData) {
            if (Number(oldInventoryData.inventoryLevel.currentValue) > currentValue) {
                updateType = "decrement";
            }
            adjustment = currentValue - Number(oldInventoryData.inventoryLevel.currentValue)
        }
        else {
            adjustment = currentValue;
            metaData.transInfo = "Inventory Added";
        }
        metaData.updateType = updateType;
        metaData.adjustment = adjustment;
        let inventoryId = Utils.getTransactionId(eventData.args.inventoryId, eventData.args.contractAddress);
        if (adjustment !== 0) {
            return transactionsDB.addTransaction(inventoryId, eventData.args.owner, metaData).catch(error => {
                Console.error(`Unable to write inventory transaction for ${itemId}, [Reason]: ${error.toString()} [Module]: Sync/items/restoreInventoryUpdateEvent`)
            })
        }
        return;
    }

    static restoreInventoryHistoryEvents(inventoryId, contractAddress) {
        let inventoryManager = ParamConnector.getInstance().getNetwork().getInventoryManager(contractAddress);
        let promiseArray = [];

        return inventoryManager.getInventoryHistoryUpdateEvents(inventoryId).then(historyEvents => {
            const key = "historyIndex";
            historyEvents = historyEvents.reduce((result, currentValue) => {
                if (result[currentValue.args[key]]) {
                    if (currentValue.blockNumber > result[currentValue.args[key]].blockNumber) {
                        result[currentValue.args[key]] = currentValue;
                    }
                }
                else {
                    result[currentValue.args[key]] = currentValue;
                }
                return result;
            }, {})
            for (let index in historyEvents) {
                promiseArray.push(RestoreItems.restoreInventoryUpdatableHistory(contractAddress, inventoryId, historyEvents[index].args.historyIndex, historyEvents[index].args.metaData));
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[MongoDB] Error in restoring inventory history events [Reason] ${e} [Module] : Sync/items/restoreInventoryHistoryEvents`);
        });
    }

}
export default RestoreItems;
