import Database from '../../../database/index';
import Console from '../../../logger';
import ParamConnector from '../../../../src/param-connector'
import * as NetworkUtils from '../../../../src/util/utils';
import ECIES from '../../../../src/util/ecies';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import * as DBUtils from '../../../database/nosql/Utils/utils';
import GraphQL from '..';
import RestoreReceipts from './receipts';
import RestoreGrn from './grn';
import NetworkBridge from '../../../util/network-bridge';

class RestoreSubscribers {

    static restoreSubscribedReceipts(contractAddress, owner, callback) {
        let receiptManager = GraphQL.getInstance().receipts;
        return receiptManager.getSubscriberReceipts(owner).then((subIds) => {
            Console.info(`[Database] Got meta info for e-subscribers metainfo.`);
            callback.onProgress("e-subscribers", 1);
            let paramSubscriberPromises = [];
            for (let index = 0; index < subIds.length; index++) {
                let paramSubscriberPromise = receiptManager.getSubscribers(subIds[index]); //getSubscriber
                paramSubscriberPromises.push(paramSubscriberPromise);
            }
            return Promise.all(paramSubscriberPromises);
        }).then((paramSubscribedReceipts) => {
            Console.info(`[Database] Got e-subscribers receipts.`);
            callback.onProgress("e-subscribers", 2);
            let paramSubscribedReceiptPromise = [];
            let rId, transactionData;

            let receiptKey = undefined;
            for (let index = 0; index < paramSubscribedReceipts.length; index++) {
                transactionData = NetworkUtils.getTransactionData(paramSubscribedReceipts[index].receiptID)
                rId = transactionData.id;
                contractAddress = transactionData.address
                if (paramSubscribedReceipts[index].receiptType !== "1") {
                    receiptKey = ECIES.decrypt(NetworkUtils.getFromLocalStorage('privateKey'), paramSubscribedReceipts[index].payload);
                }
                Console.info(`[Database] Trying to restore ${rId}.`);
                paramSubscribedReceiptPromise.push(RestoreSubscribers.restoreAllReceiptsInTree(rId, contractAddress, paramSubscribedReceipts[index].subscriber, receiptKey));
            }
            return Promise.all(paramSubscribedReceiptPromise);
        }).catch(error => {
            Console.log(error);
        });
    }

    static restoreAllReceiptsInTree(receiptId, contractAddress, subscriber, receiptKey) {
        let receiptManager = NetworkBridge.getReceiptManager();
        let receiptInstance = ParamConnector.getInstance().getDB().receipts;
        let subscriberInstance = ParamConnector.getInstance().getDB().subscribers;
        let networkReceiptManager = GraphQL.getInstance().receipts;
        let _receiptId = NetworkUtils.getTransactionId(receiptId, contractAddress)
        let receiptIds, rootId;
        let promiseArray = [], receiptDetailArray = [];
        return receiptManager.getAllReceiptIdsInTree(_receiptId, networkReceiptManager).then(res => {
            receiptIds = res.receiptIds;
            rootId = res.rootId //NetworkUtils.getTransactionId(res.rootId, contractAddress);
            for (let index in receiptIds) {
                let dbReceiptId = NetworkUtils.getTransactionId(receiptIds[index], contractAddress);
                receiptDetailArray.push({
                    receiptId: dbReceiptId,
                    contractAddress: contractAddress,
                    receiptKey: receiptKey,
                    rId: receiptIds[index],
                    event: ""
                })
                promiseArray.push(
                    receiptInstance.getNoAccessStatus(dbReceiptId).then(noAccessStatus => {
                        if (noAccessStatus && noAccessStatus.noAccess) {
                            return receiptInstance.deleteNoAccess(dbReceiptId);
                        }
                    })
                )
                // promiseArray.push(RestoreSubscribers.restoreReceipt(receiptIds[index], contractAddress, "", "", receiptKey, "fromSubscriber").then(() => {
                //     return subscriberInstance.addSubscriberForParticipant(NetworkUtils.getTransactionId(receiptIds[index], contractAddress), subscriber);
                // }));
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return RestoreReceipts.restoreReceipt(receiptDetailArray);
        }).then(() => {
            promiseArray = [];
            for (let index in receiptDetailArray) {
                promiseArray.push(
                    RestoreGrn.restoreAllGrn(contractAddress, receiptDetailArray[index].rId),
                    subscriberInstance.addSubscriberForParticipant(receiptDetailArray[index].receiptId, subscriber)
                )
            }
            return Promise.all(promiseArray);
        }).then(() => {
            return rootId;
        })
    }

    static restoreReceipt(rId, contractAddress, status, step, receiptKey, fromSubscriber, pId) {
        let receiptManager = GraphQL.getInstance().receipts;
        let receiptsInstance = ParamConnector.getInstance().getDB().receipts;
        let receiptData;
        let receiptId = NetworkUtils.getTransactionId(rId, contractAddress)
        return receiptManager.getReceipt(receiptId).then(res => {
            receiptData = res
            return receiptsInstance.getNoAccessStatus(receiptId)
        }).then(noAccessStatus => {
            let promise = Promise.resolve();
            let privateKey = receiptKey || NetworkUtils.getFromLocalStorage("privateKey");
            if (receiptData.txnMode === "2") {
                try {
                    receiptData.jsonLD = ECIES.decrypt(privateKey, receiptData.jsonLD);
                } catch (error) {
                    receiptData.jsonLD = { noAccess: true }
                    receiptData.jsonLD = JSON.stringify(receiptData.jsonLD);
                }
            }
            if (!noAccessStatus) {
                //means receipt is not present, directly add into database
                promise = receiptsInstance.addReceipt(receiptId, receiptData.jsonLD, receiptData.status, pId, receiptData.step);
            } else if (noAccessStatus.noAccess) {
                //means reciept is present, but is not accessible, grant the access and add receipt
                promise = receiptsInstance.deleteNoAccess(receiptId).then(() => {
                    return receiptsInstance.addReceipt(receiptId, receiptData.jsonLD, receiptData.status, pId, receiptData.step);
                });
            }
            return promise;
        }).then(() => {
            return RestoreGrn.restoreAllGrn(contractAddress, rId);
        })
    }

    static restoreSubscribers(txnId, subscriberContractAddress, docContractAddress) {
        let subscriberInstance = ParamConnector.getInstance().getDB().subscribers;
        Console.info(`[Database] Trying to get consent for a receipt ${txnId}.`);
        let receiptId = NetworkUtils.getTransactionId(txnId, docContractAddress)
        let subscriberManager = GraphQL.getInstance().subscribers
        return subscriberManager.getConsentsForReceipt(txnId).then((consent) => {
            if (!consent) {
                Console.warn(`[Database] No consent for a receipt ${receiptId}.`);
                return Promise.resolve();
            }
            let consentPromises = [];
            if (consent[0] !== "0x0000000000000000000000000000000000000000") {
                let consentSellerObj = { "seller": {} };
                consentSellerObj.seller.addres = consent[0];
                let consentSellerPromise = subscriberInstance.addConsent(receiptId, consentSellerObj)
                consentPromises.push(consentSellerPromise);
                Console.info(`[Database] Trying to add seller consent ${receiptId}.`);
            }
            if (consent[1] !== "0x0000000000000000000000000000000000000000") {
                let consentBuyerObj = { "buyer": {} };
                consentBuyerObj.buyer.addres = consent[1];
                let consentBuyerPromise = subscriberInstance.addConsent(receiptId, consentBuyerObj);
                consentPromises.push(consentBuyerPromise);
                Console.info(`[Database] Trying to add buyer consent ${receiptId}.`);
            }
            return Promise.all(consentPromises);
        }).then(() => {
            Console.info(`[Database] Added consent's for a ${receiptId}.`);
            Console.info(`[Database] Trying to get subscribers for a ${receiptId} from the network.`);
            return subscriberManager.getSubscribers(txnId);
        }).then(subscribers => {
            if (!subscribers) {
                Console.warn(`[Database] No subscribers for a ${receiptId}.`);
                return;
            }
            let subscribersPromise = []
            Console.warn(`[Database] Trying to add subscribers for a ${receiptId}.`);
            for (let index = 0; index < subscribers.length; index++) {
                let subscriberPromise = subscriberInstance.addSubscriberForParticipant(receiptId, subscribers[index]);
                subscribersPromise.push(subscriberPromise);
            }
            return Promise.all(subscribersPromise);
        });
    }

}
export default RestoreSubscribers;