import * as Utils from '../../../util/utils';
import * as IpfsConfig from '../../../param-network/ipfs-config.json'

const ipfsAPI = require('ipfs-api');
const events = require('events');

class IpfsManager {

    constructor() {
        if (IpfsManager.instance) {
            throw new Error("You can't create object. Use IpfsManager.getInstance()");
        }
        this.events = new events.EventEmitter();
        this.ipfsConfig = IpfsConfig.ipfsNodes;
        let currentIpfsConfig, endpoint = Utils.getFromLocalStorage('ipfsEndPoint');
        if (!endpoint) {
            currentIpfsConfig = this.ipfsConfig[0];
            Utils.setLocalStorage('ipfsEndPoint', currentIpfsConfig.url);
        } else {
            for (let index in this.ipfsConfig) {
                if (this.ipfsConfig[index].url === endpoint) {
                    currentIpfsConfig = this.ipfsConfig[index];
                    break;
                }
            }
        }
        this.ipfsAPI = ipfsAPI(currentIpfsConfig);
        const electron = window.require('electron');
        this.ipcRenderer = electron.ipcRenderer;

        this._registerOnFileSelected();
        this._registerOnBatchStarted();
        this._registerOnFileCompressionStarted();
        this._registerOnFileCompressionCompleted();
        this._registerOnFileUploadStarted();
        this._registerOnFileUploadProgress();
        this._registerOnFileUploadCompleted();
        this._registerOnBatchCompleted();

        this._registerOnFolderSelected();
        this._registerOnBatchDownloadStarted();
        this._registerOnFileDownloadStarted();
        this._registerOnFileDownloadProgress();
        this._registerOnFileDownloadCompleted();
        this._registerOnFileDecompressionStarted();
        this._registerOnFileDecompressionCompleted();
        this._registerOnBatchDownloadCompleted();
    }

    static getInstance = () => {
        if (!IpfsManager.instance) {
            IpfsManager.instance = new IpfsManager();
        }
        return IpfsManager.instance;
    }

    _registerOnFileSelected = () => {
        this.ipcRenderer.on('onFileSelected', (event, error, fileData) => {
            this.events.emit("onFileSelected", error, fileData);
        })
    }

    _registerOnBatchStarted = () => {
        this.ipcRenderer.on('onBatchStarted', (event, batchData) => {
            this.events.emit('onBatchStarted', batchData)
        })
    }

    _registerOnFileCompressionStarted = () => {
        this.ipcRenderer.on('onFileCompressionStarted', (event, fileData) => {
            setTimeout(() => {
                this.events.emit('onFileCompressionStarted', fileData);
            }, 500);
        })
    }

    _registerOnFileCompressionCompleted = () => {
        this.ipcRenderer.on('onFileCompressionCompleted', (event, fileData) => {
            setTimeout(() => {
                this.events.emit('onFileCompressionCompleted', fileData);
            }, 500);

        })
    }

    _registerOnFileUploadStarted = () => {
        this.ipcRenderer.on('onFileUploadStarted', (event, fileData) => {
            setTimeout(() => {
                this.events.emit('onFileUploadStarted', fileData);
            }, 500);

        })
    }

    _registerOnFileUploadProgress = () => {
        this.ipcRenderer.on('onFileUploadProgress', (event, fileData) => {
            setTimeout(() => {
                this.events.emit('onFileUploadProgress', fileData);
            }, 500);

        })
    }

    _registerOnFileUploadCompleted = () => {
        this.ipcRenderer.on('onFileUploadCompleted', (event, fileData) => {
            setTimeout(() => {
                this.events.emit('onFileUploadCompleted', fileData);
            }, 500);

        })
    }

    _registerOnBatchCompleted = () => {
        this.ipcRenderer.on('onBatchCompleted', (event, batchData) => {
            this.events.emit('onBatchCompleted', batchData);
        })
    }

    _registerOnFolderSelected = () => {
        this.ipcRenderer.on('onFolderSelected', (event, error, folderPath) => {
            this.events.emit('onFolderSelected', error, folderPath);
        })
    }

    _registerOnBatchDownloadStarted = () => {
        this.ipcRenderer.on('onBatchDownloadStarted', (event, batchInfo) => {
            this.events.emit('onBatchDownloadStarted', batchInfo);
        })
    }

    _registerOnFileDownloadStarted = () => {
        this.ipcRenderer.on('onFileDownloadStarted', (event, fileInfoObject) => {
            this.events.emit('onFileDownloadStarted', fileInfoObject);
        })
    }

    _registerOnFileDownloadProgress = () => {
        this.ipcRenderer.on('onFileDownloadProgress', (event, fileInfoObject) => {
            this.events.emit('onFileDownloadProgress', fileInfoObject);
        })
    }

    _registerOnFileDownloadCompleted = () => {
        this.ipcRenderer.on('onFileDownloadCompleted', (event, fileInfoObject) => {
            this.events.emit('onFileDownloadCompleted', fileInfoObject);
        })
    }

    _registerOnFileDecompressionStarted = () => {
        this.ipcRenderer.on('onFileDecompressionStarted', (event, fileInfoObject) => {
            this.events.emit('onFileDecompressionStarted', fileInfoObject);
        })
    }

    _registerOnFileDecompressionCompleted = () => {
        this.ipcRenderer.on('onFileDecompressionCompleted', (event, fileInfoObject) => {
            this.events.emit('onFileDecompressionCompleted', fileInfoObject);
        })
    }

    _registerOnBatchDownloadCompleted = () => {
        this.ipcRenderer.on('onBatchDownloadCompleted', (event, fileInfoObject) => {
            this.events.emit('onBatchDownloadCompleted', fileInfoObject);
        })
    }

    registerFileUploadCallback = (callback) => {

        if (callback.onFileSelected) {
            this.events.addListener("onFileSelected", callback.onFileSelected)
        }

        if (callback.onBatchStarted) {
            this.events.addListener("onBatchStarted", callback.onBatchStarted)
        }

        if (callback.onFileCompressionStarted) {
            this.events.addListener("onFileCompressionStarted", callback.onFileCompressionStarted)
        }
        if (callback.onFileCompressionCompleted) {
            this.events.addListener("onFileCompressionCompleted", callback.onFileCompressionCompleted)
        }
        if (callback.onFileUploadStarted) {
            this.events.addListener("onFileUploadStarted", callback.onFileUploadStarted)
        }
        if (callback.onFileUploadProgress) {
            this.events.addListener("onFileUploadProgress", callback.onFileUploadProgress)
        }
        if (callback.onFileUploadCompleted) {
            this.events.addListener("onFileUploadCompleted", callback.onFileUploadCompleted)
        }

        if (callback.onBatchCompleted) {
            this.events.addListener("onBatchCompleted", callback.onBatchCompleted)
        }
    }

    unRegisterFileUploadCallback = (callback) => {
        if (callback.onFileSelected) {
            this.events.removeListener("onFileSelected", callback.onFileSelected)
        }

        if (callback.onBatchStarted) {
            this.events.removeListener("onBatchStarted", callback.onBatchStarted)
        }

        if (callback.onFileCompressionStarted) {
            this.events.removeListener("onFileCompressionStarted", callback.onFileCompressionStarted)
        }
        if (callback.onFileCompressionCompleted) {
            this.events.removeListener("onFileCompressionCompleted", callback.onFileCompressionCompleted)
        }
        if (callback.onFileUploadStarted) {
            this.events.removeListener("onFileUploadStarted", callback.onFileUploadStarted)
        }
        if (callback.onFileUploadProgress) {
            this.events.removeListener("onFileUploadProgress", callback.onFileUploadProgress)
        }
        if (callback.onFileUploadCompleted) {
            this.events.removeListener("onFileUploadCompleted", callback.onFileUploadCompleted)
        }

        if (callback.onBatchCompleted) {
            this.events.removeListener("onBatchCompleted", callback.onBatchCompleted)
        }
    }

    reqOnFileSelect = (extensions, maxFileSize) => {
        if (!extensions) {
            extensions = ['*'];
        }
        this.ipcRenderer.send('reqOnFileSelect', extensions, maxFileSize);
    }

    registerFileDownloadCallback = (callback) => {

        if (callback.onFolderSelected) {
            this.events.addListener("onFolderSelected", callback.onFolderSelected)
        }

        if (callback.onBatchDownloadStarted) {
            this.events.addListener("onBatchDownloadStarted", callback.onBatchDownloadStarted)
        }

        if (callback.onFileDownloadStarted) {
            this.events.addListener("onFileDownloadStarted", callback.onFileDownloadStarted)
        }

        if (callback.onFileDownloadProgress) {
            this.events.addListener("onFileDownloadProgress", callback.onFileDownloadProgress)
        }
        if (callback.onFileDownloadCompleted) {
            this.events.addListener("onFileDownloadCompleted", callback.onFileDownloadCompleted)
        }
        if (callback.onFileDecompressionStarted) {
            this.events.addListener("onFileDecompressionStarted", callback.onFileDecompressionStarted)
        }
        if (callback.onFileDecompressionCompleted) {
            this.events.addListener("onFileDecompressionCompleted", callback.onFileDecompressionCompleted)
        }

        if (callback.onBatchDownloadCompleted) {
            this.events.addListener("onBatchDownloadCompleted", callback.onBatchDownloadCompleted)
        }
    }

    unRegisterFileDownloadCallback = (callback) => {
        if (callback.onFolderSelected) {
            this.events.removeListener("onFolderSelected", callback.onFolderSelected)
        }

        if (callback.onBatchDownloadStarted) {
            this.events.removeListener("onBatchDownloadStarted", callback.onBatchDownloadStarted)
        }

        if (callback.onFileDownloadStarted) {
            this.events.removeListener("onFileDownloadStarted", callback.onFileDownloadStarted)
        }
        if (callback.onFileDownloadProgress) {
            this.events.removeListener("onFileDownloadProgress", callback.onFileDownloadProgress)
        }
        if (callback.onFileDownloadCompleted) {
            this.events.removeListener("onFileDownloadCompleted", callback.onFileDownloadCompleted)
        }
        if (callback.onFileDecompressionStarted) {
            this.events.removeListener("onFileDecompressionStarted", callback.onFileDecompressionStarted)
        }
        if (callback.onFileDecompressionCompleted) {
            this.events.removeListener("onFileDecompressionCompleted", callback.onFileDecompressionCompleted)
        }

        if (callback.onBatchDownloadCompleted) {
            this.events.removeListener("onBatchDownloadCompleted", callback.onBatchDownloadCompleted)
        }
    }

    reqOnFolderSelect = (fileInfoArray) => {
        this.ipcRenderer.send('reqOnFolderSelect', fileInfoArray);
    }

    reqDownloadFiles = (fileInfoObjects, folderPath) => {
        if (!Array.isArray(fileInfoObjects)) {
            fileInfoObjects = [fileInfoObjects];
        }
        this.ipcRenderer.send('reqDownloadFiles', fileInfoObjects, folderPath);
    }

    uploadAllFiles = (fileArray, extensions) => {
        if (!extensions) {
            extensions = ['*'];
        } else if (typeof extensions === "string") {
            extensions = extensions.split(',');
        }
        let fileUploadConfig = Utils.getFromLocalStorage('fileUploadConfig');
        if (!fileUploadConfig) {
            fileUploadConfig = {
                type: "MB",
                value: "10"
            }
        }
        let config = {
            ipfsConfig: {
                url: Utils.getFromLocalStorage('ipfsEndPoint')
            },
            fileConfig: {
                maxFileSize: {
                    type: fileUploadConfig.type,
                    value: fileUploadConfig.value
                },
                allowedExtensions: extensions
            }
        }
        fileArray = fileArray.filter(file => !file.uploadedFileHash);
        console.log("Files to be uploaded", fileArray);
        console.log("Config", config);
        this.ipcRenderer.send('reqOnUploadFiles', fileArray, config);
    }
}

export default IpfsManager;