import Database from '../../index'
import * as NetworkUtils from '../../../util/utils';
import NetworkDataFormat from '../Utils/network-data-format';
import Console from '../../../logger/index';
import RestoreReceipts from '../sync/receipts';
import NetworkBridge from '../../../util/network-bridge';
import RestoreGrn from '../sync/grn';
import * as utils from '../../../util/utils';

class ReceiptDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    registerOnReceiptStatusUpdate(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnReceiptStatus", callback);
    }

    unRegisterOnReceiptStatusUpdate(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnReceiptStatus", callback);
    }

    registerOnDocumentUpdate(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("OnDocumentUpdate", callback);
    }

    unRegisterOnDocumentUpdate(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("OnDocumentUpdate", callback);
    }

    registerOnReceiptStatusUpdateV1(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onReceipStatusUpdateV1", callback);
    }

    unRegisterOnReceiptStatusUpdateV1(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onReceipStatusUpdateV1", callback);
    }

    _onReceiptStatusUpdate(error, dataJSON) {
        if (error) {
            return;
        }
        if (dataJSON.args.rId === "0x0000000000000000000000000000000000000000000000000000000000000000") {
            dataJSON.args.rId = dataJSON.args.pId;
        }
        const dbReceiptId = NetworkUtils.getTransactionId(dataJSON.args.rId, dataJSON.args.contractAddress);
        // dataJSON.args.pId = NetworkUtils.getTransactionId(dataJSON.args.pId, dataJSON.args.contractAddress);
        dataJSON.args.status = dataJSON.args.status.toString();
        dataJSON.args.step = dataJSON.args.step.toString();
        let receiptDataObject = {
            receiptId: dbReceiptId,
            contractAddress: dataJSON.args.contractAddress,
            status: dataJSON.args.status,
            step: dataJSON.args.step,
            pId: dataJSON.args.pId,
            event: dataJSON.event
        }
        return RestoreReceipts.restoreReceipt([receiptDataObject]).then(() => {
            Console.log(`[MongoDB] Updated ${dbReceiptId} with status ${dataJSON.args.step}`);
            // this.dbInstance.updateLastSync();
            this._emitOnReceipt(dataJSON.args.rId, dataJSON.args.pId, dataJSON.args.step);
        }).catch(e => {
            Console.error(`[Error] Unable to update receipt status for ${dbReceiptId}, Reason: ${e.toString()}`);
        }).finally(() => {
            this.restoreCatalog(dataJSON)
        }).catch(error => {
            Console.error(`Unable to add transaction for ${dbReceiptId}, Reason: ${error}`)
        })
    }

    restoreCatalog(dataJSON) {
        const dbReceiptId = NetworkUtils.getTransactionId(dataJSON.args.rId, dataJSON.args.contractAddress);
        let transactionHash = dataJSON.transactionHash, result;
        return this.dbInstance.getTransactionInfo(transactionHash).then(res => {
            result = res;
            if (dataJSON.args.step === "2")
                return this.dbInstance.receipts.getReceipt(dbReceiptId);
            return Promise.resolve({});
        }).then(receipt => {
            let selfParamId = utils.getParamId();
            if (receipt.disputeInfo === 0) {
                NetworkBridge.getGRNManager().closeAllGRNs(dbReceiptId);
            }
            if (receipt.step === "2" && selfParamId === receipt.provider.identifier) {
                let catalogueManager = NetworkBridge.getItemManager();
                let fromPage = "Invoice";
                catalogueManager.updateInventories(receipt, fromPage, receipt.provider.identifier).catch(e => {
                    Console.error(`[Error] Unable to update inventory for ${dbReceiptId}, Reason: ${e.toString()}`);
                })
            }
            if (!result || !result.from) {
                Console.error(`Unable get transaction details for ${dbReceiptId}.`)
                return;
            }
            let metaData = NetworkDataFormat.getMetaInfo(dbReceiptId, dataJSON, "Receipt status changed");
            metaData.step = dataJSON.args.step;
            metaData.buyer = dataJSON.args.buyer;
            metaData.status = dataJSON.args.status
            metaData.seller = dataJSON.args.seller;
            //let self = utils.getFromLocalStorage("param_id");
            //let owner = metaData.buyer === self ? metaData.buyer : metaData.seller;
            let owner = result.from;
            return this.dbInstance.transactionsDB.addTransaction(dbReceiptId, owner, metaData).then(() => {
                let paramId = utils.getParamId();
                // if (!(dataJSON.args.seller === paramId && dataJSON.args.buyer === paramId)) {
                //     return this.dbInstance.notifications.addNotification(dbReceiptId, "receiptStatusUpdate", "receipt", [dataJSON.args.seller, dataJSON.args.buyer]).then(() => {
                //         this.dbInstance.emitEvent("notifications", dbReceiptId, null);
                //     });
                // }
            }).catch(error => {
                Console.error(`Unable to add transaction for ${dbReceiptId}, Reason: ${error}`)
            })
        });
    }
    _onDocumentUpdate(error, dataJSON) {
        if (error)
            return;
        let functionName = "restoreGrn"
        if (dataJSON.args.docType === "1") {
            functionName = "restoreAcceptNote";
        }
        let buyerId;
        let attachmentId = NetworkUtils.getTransactionId(dataJSON.args.docId, dataJSON.args.contractAddress);
        return RestoreGrn[functionName](dataJSON.args.docId, dataJSON.args.contractAddress, dataJSON.args.docStatus).then((res) => {
            const networkId = NetworkUtils.getTransactionData(res.docId).id;
            this.dbInstance.emitEvent("OnDocumentUpdate", dataJSON.args.docId, networkId, null);
            buyerId = res.buyerId;
            let transactionHash = dataJSON.transactionHash;
            return this.dbInstance.getTransactionInfo(transactionHash)
        }).then(result => {
            let metaData = NetworkDataFormat.getMetaInfo(attachmentId, dataJSON, "on Document Update");
            metaData.step = dataJSON.args.step;
            metaData.buyer = dataJSON.args.buyer;
            metaData.status = dataJSON.args.docStatus
            metaData.seller = dataJSON.args.seller;
            metaData.docType = dataJSON.args.docType;
            return this.dbInstance.transactionsDB.addTransaction(attachmentId, result.from, metaData)
        }).then(() => {
            return this.dbInstance.grn.getGrn(attachmentId);
        }).then(receipt => {
            if (dataJSON.args.docType === "0") {
                let catalogueManager = NetworkBridge.getItemManager();
                let fromPage = "GRN";
                let selfParamId = utils.getParamId();
                if (selfParamId === buyerId) {
                    catalogueManager.updateInventories(receipt, fromPage, buyerId).catch(e => {
                        console.error(`[Error] Unable to update inventory for ${attachmentId}, Reason: ${e.toString()}`);
                    });
                }
            }
            return;
        })
        // .then(response => {
        //     this.dbInstance.emitEvent("OnDocumentUpdate", dataJSON.args.docId, null);
        //     return this.dbInstance.notifications.addNotification(grnId, "onTemplate", "template", dataJSON.args.creator)
        // }).then(() => {
        //     this.dbInstance.emitEvent("notification", grnId, null);
        // }).catch(error => {
        //     Console.error(`Unable to restore grn ${dataJSON.args.docId} ${dataJSON.args.rId}, reason: ${error}`);
        // });
    }
    _onGrnsUpdate(error, dataJSON) {
        if (error)
            return;
        return this.dbInstance.grn.updateGrnsStatus(dataJSON.args.docIds, dataJSON.args.contractAddress, dataJSON.args.docStatus).then(response => {
            this.dbInstance.emitEvent("OnDocumentUpdate", dataJSON.args.docId, null);
        }).catch(error => {
            Console.error(`Unable to restore grn ${dataJSON.args.grnId}, reason: ${error}`);
        });
    }
    _onTransactionUpdate(error, dataJSON) {
        if (error) {
            return;
        }
        return this.dbInstance.receipts.doesExist(dataJSON.args.rId).then(doesExist => {
            if (!doesExist) {
                return;
            }
            dataJSON.args.ekId = "-1";
            this._onReceiptStatusUpdate(error, dataJSON);
        }).catch(error => {
            Console.error(`[Error] Unable to get transaction update for ${dataJSON.args.rId}, Reason: ${error}`);
        });
    }
    _emitOnReceipt(docId, parentDocId, step) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.receipts.getSellerAndBuyer(docId).then(res => {
            return this.dbInstance.emitEvent("OnReceiptStatus", docId, parentDocId, res);
        })
    }

    _emitOnDocumentUpdate(grnId, parentDocId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.grn.getSellerAndBuyer(grnId).then(res => {
            return this.dbInstance.emitEvent("OnDocumentUpdate", grnId, parentDocId, res);
        })
    }
}

export default ReceiptDBEvents;