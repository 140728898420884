/**
 * ParamNetwork is a root class from using this class reference you can invoke contact manager, catalogue manager and param receipt.
 * Objects can accessable:
 * catalogueManager, paramReceiptManager
 * 
 * @example
 * let paramNetwork = new ParamNetwork({url:"http://localhost:8545"})
 */

import ContactManager from './contact-manager';
import CatalogueManager from './catalogue-manager/index';
import InventoryManager from './inventory-manager/index';
import ParamReceipt from './param-receipt/index';
import ParamSubscriber from './param-subscriber/index';
import VersionControl from './version-control/index';
import NodeInfo from './node-info/index';
import PublicBook from './public-book/index';
import TemplateConsManager from './template-consensus-manager/index';
import TemplateRepoManager from './template-repository-manager/index';
import EmailManager from './param-email/index';
import VendorManager from './vendor-manager/index';
import RequestManager from './returns-manager/index';
import Web3Utils from 'web3-utils';
import Console from '../logger/index';
import Web3_1_0 from './utils/Web3_1_0';

class ParamNetwork {

    /**
     * Using paramReceiptManager, we can able to create or modify receipt. Able to get the details about receipts.
     * @memberof ParamNetwork.prototype
     * @member {ParamReceipt}  paramReceiptManager
     */

    /**
     * Default constructor for setting config like url.
     *  
     * @param {JSON} _config - {"url":"", modules:['contact','catalog']}
     */
    constructor(_config) {
        // if(_config)
        this.setConfig(_config);
    }
    /**
     * Using this method you can set config.
     * 
     * @param {*} config - {"url":"", modules:['contact','catalog'], paramId:""}
     * @example
     * let paramNetwork = new ParamNetwork();
     * paramNetwork.setConfig({url:"http://localhost:8545"})
     */
    setConfig(config) {

        if (!config) {
            return;
        }

        if (typeof this.map === "undefined") {
            this.map = { "contact": [], "catalogue": [], "inventory": [], "receipt": [], "versioncontrol": [], "nodeinfo": [], "publicbook": [], "templatecons": [], "templaterepo": [], "subscriber": [], "emailmanager": [], 'vendor': [], 'returns': [] };

        }

        if (!this.walletAccount && config.walletAccount) {
            try {
                this.walletAccount = Web3Utils.toChecksumAddress(config.walletAccount);
            } catch (e) {

            }
        }

        if (typeof this.web3 === "undefined") {
            // config.url = "http://52.44.214.192:22000"
            let Web3 = Web3_1_0.getWeb3(config);
            let connectionObject = Web3_1_0.getProvider(config);
            this.initCallbacks(connectionObject);
            this.web3 = new Web3(connectionObject);
            this.web3["privateurl"] = config.privateUrl;
            this.web3["publicurl"] = config.url;
            this.web3["privateForObj"] = JSON.parse(config.privateFor);
            this.web3["proxy"] = config.proxy
            this.config = config;
        }

        if (!config.contracts) {
            // Address is Enterprise then look for global address. You will get protected and private contracts
            // Address is SMB, all ways comunicate with Version control to get all the address and init.
            // Version V = new Version();
            config.contracts =
            {
                "contact": "",
                "receipt": "",
                "nodeinfo": "",
                "catalogue": "",
                "inventory": "",
                "subscriber": "",
                "publicbook": "",
                "versioncontrol": "",
                "emailmanager": "",
                "vendor": "",
                "returns": ""
            };
        }

        for (let key in config.contracts) {
            let address = config.contracts[key];
            if (!address || address.length === 0) {
                continue;
            }
            try {
                address = Web3Utils.toChecksumAddress(address);
            } catch (e) {
                Console.error(`Unable to convert checksum for ${address}, Reason ${e.toString()}`)
            }
            let obj = null;
            switch (key) {
                case "contact":
                    obj = new ContactManager(this, address);
                    break;
                case "catalogue":
                    obj = new CatalogueManager(this, address);
                    break;
                case "inventory":
                    obj = new InventoryManager(this, address);
                    break;
                case "receipt":
                    obj = new ParamReceipt(this, address);
                    break;
                case "subscriber":
                    obj = new ParamSubscriber(this, address);
                    break;
                case "versioncontrol":
                    obj = new VersionControl(this, address);
                    break;
                case "nodeinfo":
                    obj = new NodeInfo(this, address);
                    break;
                case "publicbook":
                    obj = new PublicBook(this, address);
                    break;
                case "templatecons":
                    obj = new TemplateConsManager(this, address);
                    break;
                case "templaterepo":
                    obj = new TemplateRepoManager(this, address);
                    break;
                case "emailmanager":
                    obj = new EmailManager(this, address);
                    break;
                case "vendor":
                    obj = new VendorManager(this, address);
                    break;
                case "returns":
                    obj = new RequestManager(this, address);
                    break;
                default:
                    break;
            }
            if (obj) {
                if (!this.getContract(key, address)) {
                    obj.contractAddress = address;
                    this.map[key].push(obj);
                }

            }
        }
    }

    initCallbacks(connectionObject) {

        if (!connectionObject || !connectionObject.connection) {
            return;
        }
        let that = this;
        connectionObject.on("end", () => {

            if (that.connectionCallback && that.connectionCallback.onNetworkStateChange) {
                that.connectionCallback.onNetworkStateChange(false);
            }
        })

        connectionObject.on("error", (error) => {

            if (that.connectionCallback && that.connectionCallback.onError) {
                that.connectionCallback.onError(error);
            }
        })

    }

    setNetworkCallback(callback) {
        this.connectionCallback = callback;
    }

    getContactManager(address) {
        if (address) {
            this.setConfig({ contracts: { "contact": address } })
        }
        return this.getContract("contact", address);
    }

    getCatalogueManager(address) {
        // address = "0xa5f126CFF0835db28DecdDb833157B19f53e733d";
        if (address) {
            this.setConfig({ contracts: { "catalogue": address } })
        }
        return this.getContract("catalogue", address);
    }

    getInventoryManager(address) {
        // address = "0x182e0039aec6206a0ff1a4db4a3dbe561b9035c1";
        if (address) {
            this.setConfig({ contracts: { "inventory": address } })
        }
        return this.getContract("inventory", address);
    }

    getReceiptManager(address) {
        address = "0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815";
        if (address) {
            this.setConfig({ contracts: { "receipt": address } })
        }
        return this.getContract("receipt", address);
    }

    getVersionControlManager(address) {
        if (address) {
            this.setConfig({ contracts: { "versioncontrol": address } })
        }
        return this.getContract("versioncontrol", address);
    }

    getNodeInfoManager(address) {
        if (address) {
            this.setConfig({ contracts: { "nodeinfo": address } })
        }
        return this.getContract("nodeinfo", address);
    }

    getPublicBookManager(address) {
        if (address) {
            this.setConfig({ contracts: { "publicbook": address } })
        }
        return this.getContract("publicbook", address);
    }

    getTemplateConsManager(address) {
        address = "0xB140C443fE41277f71C6a9750C83f3b37Fb47680";
        if (address) {
            this.setConfig({ contracts: { "templatecons": address } })
        }
        return this.getContract("templatecons", address);
    }

    getTemplateRepoManager(address) {
        // address = '0x65253d2c3C601E2cE2a00BB25bC192c905F85bdc';
        if (address) {
            this.setConfig({ contracts: { "templaterepo": address } })
        }
        return this.getContract("templaterepo", address);
    }

    getEmailManager(address) {
        if (address) {
            this.setConfig({ contracts: { "emailmanager": address } })
        }
        return this.getContract("emailmanager", address);
    }

    getSubscriberManager(address) {
        if (address) {
            this.setConfig({ contracts: { "subscriber": address } })
        }
        return this.getContract("subscriber", address);
    }

    getVendorManager(address) {
        // address = "0xAD36bcd5Ec0408826e5Fe4aD81A822A36794Ef46"
        if (address) {
            this.setConfig({ contracts: { "vendor": address } })
        }
        return this.getContract("vendor", address);
    }

    getReturnsManager(address) {
        address = "0x6307Ea384D46ba9874e1C0E5f7d83F7F5C4ACb15";
        if (address) {
            this.setConfig({ contracts: { "returns": address } })
        }
        return this.getContract("returns", address);
    }


    getContract(contractName, contractAddress) {
        let contractArrayObject = this.map[contractName];
        if (!contractAddress) {
            return contractArrayObject[0];
        }
        try {
            contractAddress = Web3Utils.toChecksumAddress(contractAddress);
        } catch (e) {
            Console.error(`Unable to convert checksum for ${contractAddress}, Reason ${e.toString()}`)
        }
        for (let contractIndex in contractArrayObject) {
            if (contractArrayObject[contractIndex].contractAddress === contractAddress) {
                return contractArrayObject[contractIndex];
            }
        }
        return null;
    }

    getContractAddress(contractName, config) {
        if (!config || config.contracts === undefined) {
            return null;
        }
        let contractObj = config.contracts;
        for (let i = 0; i < contractObj.length; i++) {
            if (contractObj[i].key === contractName && contractObj[i].address !== undefined && contractObj[i].address !== null) {
                return contractObj[i].address.toLowerCase();
            }
        }
        return null;
    }

    /**
     * Using this method you can set config.
     * 
     * @deprecated
     * @example
     * let paramNetwork = new ParamNetwork();
     * paramNetwork.getConfig()
     */
    getConfig() {
        return this.web3;
    }

    /**
     * Using this method you get param access object.
     * 
     * @example
     * let paramNetwork = new ParamNetwork();
     * paramNetwork.getConnection()
     */
    getConnection() {
        return this.web3;
    }
}

// export default ParamNetwork;
export default ParamNetwork;