import { RSA_KEYS } from '../e-invoice/keys';
import ThirdPartyUtils from './IIIrd-party-utils';
const crypto = require('crypto');
const CryptoJS = require('crypto-js');
var AES = require("crypto-js/aes");

class cryptoUtils {
    static getRSAEncrypted(data) {
        const buffer = Buffer.from(data, 'utf8')
        const encrypted = crypto.publicEncrypt({ key: RSA_KEYS.publicKey, padding: crypto.constants.RSA_PKCS1_PADDING }, buffer);
        return encrypted.toString('base64');
    }

    // static getAESDecryptedData(messagebase64, keyBase64, ivBase64) {
    //     const key = Buffer.from(keyBase64, 'base64');
    //     // const iv = Buffer.from(ivBase64, 'base64');
    //     const decipher = crypto.createDecipher('aes-256-ecb', key);
    //     let decrypted = decipher.update(messagebase64, 'base64');
    //     decrypted += decipher.final();
    //     return decrypted;
    // }

    static getAESDecryptedData(messagebase64, keyBase64, ivBase64) {
        let key = CryptoJS.enc.Base64.parse(keyBase64)
        key = CryptoJS.enc.Hex.parse(key.toString());
        messagebase64 = CryptoJS.enc.Base64.parse(messagebase64);
        let decrypted = AES.decrypt({
            ciphertext: messagebase64
        }, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        console.log(decrypted.toString(CryptoJS.enc.Base64));
        return decrypted.toString(CryptoJS.enc.Base64);
    }

    static getAESEncryptedData(plainText, keyBase64, ivBase64) {
        let key = CryptoJS.enc.Base64.parse(keyBase64);
        var encrypted = AES.encrypt(plainText, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }
    // static getAESEncryptedData(plainText, keyBase64, ivBase64) {
    //     const key = Buffer.from(keyBase64, 'base64');
    //     console.log("Encryption key len:", key.length)
    //     // const iv = Buffer.from(ivBase64, 'base64');
    //     const cipher = crypto.createCipher('aes-256-ecb', key);
    //     let encrypted = cipher.update(plainText, 'utf8', 'base64')
    //     encrypted += cipher.final('base64');
    //     return encrypted;
    // };
}

export default cryptoUtils;