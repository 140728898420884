
import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import { ContactEvents } from '../utils/event-names';
import Web3Utils from 'web3-utils';
import BlockInfo from '../utils/block-info';
/**
 * ContactManager is an implementation of decentralized contact storage, using this class user can able to save contacts into ParamNetwork. 
 */
class ContactManager {
    /**
     * Default constructor for initialising or establishing the contacts.
     * @param {ParamNetwork} paramNetwork Object
     * 
     */
    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const contManager = require('./contact-manager.json');

        this.contactManagerABI = contManager.abi;
        this.paramNetwork = _paramNetwork;
        this.contactManagerContract = Web3_1_0.getContract(this.connection, contManager.abi, contractAddress ? contractAddress : contManager.address);
        this.to = contractAddress;
        /* this.contactManagerContract = new this.connection.eth.Contract(contManager.abi, contractAddress);
        this.contactManagerContract = this.contactManagerContract.at(contManager.address); */
    }

    /**
     * initEvents is a event listener used for listening the real time changes whenever a contact is added or updated.
     * @param {JSON} options - {"address":"0x"}
     * @example
     * Usage:
     *  contactManager
     *   .initEvents({address:'0x'})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        if (options) {
            options = {
                owner: options.address
            };
        }

        this.watchAddContactEvent(options);
        this.watchUpdateContactEvent(options);
        this.watchInvitatedEvent(options);
        this.watchAcceptedEvent(options);
        this.watchAcknowledgedEvent(options);
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }

    watchAddContactEvent(options) {
        if (typeof this.addContactEvent === 'undefined' || !this.addContactEvent) {
            let filter = {
                filter: options
            };
            // this.addContactEvent = this.contactManagerContract.events.onContactAdd(filter);
            this.addContactEvent = Web3_1_0.getEvent(this, this.contactManagerContract, "onContactAdd", filter);
        }
        let that = this;
        this.addContactEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onContactAdd", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onContactAdd", error);
                that.addContactEvent.unsubscribe();
                that.addContactEvent = undefined;
                that.watchAddContactEvent(options);
            });
    }

    watchUpdateContactEvent(options) {
        if (typeof updateContactEvent === 'undefined' || !this.updateContactEvent) {
            let filter = {
                filter: options
            };
            // this.updateContactEvent = this.contactManagerContract.events.onContactUpdateV1(filter);
            this.updateContactEvent = Web3_1_0.getEvent(this, this.contactManagerContract, "onContactUpdateV1", filter);
        }
        let that = this;
        this.updateContactEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.owner.toLowerCase()) {
                that.emitEvent("onContactUpdateV1", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onContactUpdateV1", error);
                that.updateContactEvent.unsubscribe();
                that.updateContactEvent = undefined;
                that.watchUpdateContactEvent(options);
            });
    }

    watchInvitatedEvent(options) {
        if (typeof this.invitatedEvent === 'undefined' || !this.invitatedEvent) {
            let filter = {
                filter: {
                    sender: options.owner
                }
            };
            // this.invitatedEvent = this.contactManagerContract.events.onNotificationInvited(filter);
            this.invitatedEvent = Web3_1_0.getEvent(this, this.contactManagerContract, "onNotificationInvited", filter);
        }
        let that = this;
        this.invitatedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);
            if (Array.isArray(event) && event.length) {
                event = event[0];
            }
            if (!event || !event.args || !event.args.sender) {
                return;
            }
            that.emitEvent("onNotificationInvited", event)
        })
            .on('error', function (error) {
                that.events.emit("onNotificationInvited", error);
                that.invitatedEvent.unsubscribe();
                that.invitatedEvent = undefined;
                that.watchInvitatedEvent(options);
            });
    }

    watchAcceptedEvent(options) {
        if (typeof this.acceptedEvent === 'undefined' || !this.acceptedEvent) {
            // this.acceptedEvent = this.contactManagerContract.events.onNotificationAccepted();
            let filter = {
                filter: options
            }
            this.acceptedEvent = Web3_1_0.getEvent(this, this.contactManagerContract, "onNotificationAccepted", filter);
        }
        let that = this;
        this.acceptedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);
            if (!event || !event.args || !event.args.sender || !event.args.receiver) {
                return;
            }
            let address = Web3Utils.toChecksumAddress(options.owner)
            if (address === event.args.sender || address === event.args.receiver)
                that.emitEvent("onNotificationAccepted", event);
        })
            .on('error', function (error) {
                that.events.emit("onNotificationAccepted", error);
                that.acceptedEvent.unsubscribe();
                that.acceptedEvent = undefined;
                that.watchAcceptedEvent(options);
            });
    }

    watchAcknowledgedEvent(options) {
        if (typeof this.acknowledgedEvent === 'undefined' || !this.acknowledgedEvent) {
            // this.acknowledgedEvent = this.contactManagerContract.events.onNotificationAcknowledged();
            let filter = {
                filter: options
            }
            this.acknowledgedEvent = Web3_1_0.getEvent(this, this.contactManagerContract, "onNotificationAcknowledged", filter);
        }
        let that = this;
        this.acknowledgedEvent.on('data', function (event) {
            event = Web3_1_0.upgradeEventData(event);
            if (!event || !event.args || !event.args.sender || !event.args.receiver) {
                return;
            }
            let address = Web3Utils.toChecksumAddress(options.owner)
            if (address === event.args.sender || address === event.args.receiver)
                that.emitEvent("onNotificationAcknowledged", event)
        })
            .on('error', function (error) {
                that.events.emit("onNotificationAcknowledged", error);
                that.acknowledgedEvent.unsubscribe();
                that.acknowledgedEvent = undefined;
                that.watchAcknowledgedEvent(options);
            });
    }

    /** 
     * addContact method is used for adding new contacts into the network.
     * @param {address} address - contact's paramId
     * @param {string} info - JSONLD
     * @param {JSON} options - {from: <PARAM_ADDRESS>}
     * @returns {Promise} promise
     * @example 
     * Usage:
     *  contactManager
     *   .addContact("0x","<JSONLD>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    addContact(address, info, txnType, options) {
        return Web3_1_0.send(this, this.contactManagerContract, "addContact", options, address, info, txnType);
    }

    /** 
     * addContactSync method is the synchronous alternative of addContact method
     * @param {address} address - contact's paramId
     * @param {string} info - JSONLD
     * @param {JSON} options - {from: <PARAM_ADDRESS>}
     * @returns {Promise} promise
     * @example 
     * Usage:
     *  contactManager
     *   .addContactSync("0x","<JSONLD>", {from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    addContactSync(address, info, txnType, options) {
        return Web3_1_0.send(this, this.contactManagerContract, "addContact", options, address, info, txnType).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            if (logs.length === 0) {
                return Promise.reject();
            }
            return logs[0].topics[0];
        })
    }

    /** 
     * updateContact method is used for updating the already available/existing contacts data to the network.
     * @param {Number} index - contactId
     * @param {string} info - JSONLD
     * @param {JSON} options - {from: <PARAM_ADDRESS>}
     * @returns {Promise} promise
     * @example 
     * Usage:
     *  contactManager
     *   .updateContact(<CONTACT_ID>,"<JSONLD>",{from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
    */
    updateContact(index, info, txnType, options) {
        index = ParamUtils.getId(index);
        return Web3_1_0.send(this, this.contactManagerContract, "updateContact", options, index, info, txnType);
    }

    /** 
     * updateContactSync method is the synchronous implementation of updateContact method.
     * @param {Number} index - contactId
     * @param {string} info - JSONLD
     * @param {JSON} options - {from: <PARAM_ADDRESS>}
     * @returns {Promise} promise
     * @example 
     * Usage:
     *  contactManager
     *   .updateContact(<CONTACT_ID>,"<JSONLD>",{from:<FROM_PARAM_ADDRESS>})
     *   .then((result)=>{
     *       //TODO 
     *   })
    */
    updateContactSync(index, info, txnType, options) {
        index = ParamUtils.getId(index);
        return Web3_1_0.send(this, this.contactManagerContract, "updateContact", options, index, info, txnType).then((txHash) => {
            return BlockInfo.getTransactionInfoSync(this.connection, txHash);
        }).then((logs) => {
            return index;
        })
    }

    draftContact(contactInfo, txnType, options) {
        return Web3_1_0.send(this, this.contactManagerContract, "draftContact", options, contactInfo, txnType);
    }

    invitedContact(contactBytesId, options) {
        return Web3_1_0.send(this, this.contactManagerContract, "invitedContact", options, contactBytesId);
    }

    acceptedInvite(paramContact, contactInfo, txnType, SenderContactId, randomString, options) {
        return Web3_1_0.send(this, this.contactManagerContract, "acceptedInvite", options, paramContact, contactInfo, txnType, SenderContactId, randomString);
    }

    acknowledgement(senderContactId, paramId, receiverContactId, options) {
        return Web3_1_0.send(this, this.contactManagerContract, "acknowledgement", options, senderContactId, paramId, receiverContactId);
    }

    setContractInfoId(contractId, options) {
        return Web3_1_0.send(this, this.contactManagerContract, "setContractInfoId", options, contractId);
    }

    /** 
     * getAllContacts method is used for getting all the contacts that are available in the network.
     * @param {string} address - contact's paramId
     * @returns {Promise} promise
     * @example 
     * Usage:
     *  contactManager
     *   .getAllContacts('0x')
     *   .then((result)=>{
     *       //TODO 
     *   })
    */
    getAllContacts(address) {
        return Web3_1_0.call(this, this.contactManagerContract, "getAllContacts", null, address);
    }

    /**
     * getTotalContacts method is used to get the total number of contacts available in the network.
     * @param {String} address - contact's paramId
     * @returns {Promise} promise
     * @example 
     * Usage:
     *  contactManager
     *   .getTotalContacts('0x')
     *   .then((result)=>{
     *       //TODO 
     *   })
     */
    getTotalContacts(address) {
        return Web3_1_0.call(this, this.contactManagerContract, "getTotalContacts", null, address);
    }

    /**
    * getContact method is used to get a particular contact via contact index. 
    * @param {Number} index - contactId 
    * @example
    * Usage:
    *  contactManager
    *   .getContact(<CONTACT_ID>)
    *   .then((result)=>{
    *       //TODO 
    *   })
    */
    getContact(index) {
        index = ParamUtils.getId(index);
        return Web3_1_0.call(this, this.contactManagerContract, "getContact", null, index);
    }

    getContactFromBookOwner(bookOwner, contactId) {
        return Web3_1_0.call(this, this.contactManagerContract, "getContactFromBookOwner", null, bookOwner, contactId);
    }

    getAllEventsByContactId(contactId) {
        let events = Object.keys(ContactEvents)
        let promiseArray = [];
        let filter = {
            filter: {
                "contactId": contactId
            },
            fromBlock: 0,
            toBlock: 'latest'
        }

        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.contactManagerContract, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection);
    }

    /**
     * registerOnContactAdded is used for adding listener whenever a contact is added.
     * @param {function} callback - (error, result)=>{}
     * @param {JSON} options - {address:'0x'}
     * @example
     * Usage:
     * const callback = (error, data)=>{
     *      //TODO
     *  }
     *  contactManager
     *   .registerOnContactAdded(callback)
     *   
     */
    registerOnContactAdded(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onContactAdd", callback);
    }

    /**
     * unRegisterOnContactAdded is used for removing the listener that has been added for OnContactAdd event. 
     * @param {function} callback - registered callback
     * @example
     * Usage:
     *  contactManager
     *   .unRegisterOnContactAdded(callback)
     *   
     */
    unRegisterOnContactAdded(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onContactAdd", callback);
    }

    registerOnNotificationInvited(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onNotificationInvited", callback);
    }

    unRegisterOnNotificationInvited(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onNotificationInvited", callback);
    }

    registerOnNotificationAccepted(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onNotificationAccepted", callback);
    }

    unRegisterOnNotificationAccepted(callback, options) {
        if (!this.events) {
            return;
        }

        this.events.removeListener("onNotificationAccepted", callback);
    }

    registerOnNotificationAcknowledged(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.addListener("onNotificationAcknowledged", callback);
    }

    unRegisterOnNotificationAcknowledged(callback, options) {
        if (!this.events) {
            return;
        }

        this.events.removeListener("onNotificationAcknowledged", callback);
    }

    /**
     * registerOnContactUpdated is used for adding listener whenever a contact is updated.
     * @param {function} callback - (error, result)=>{}
     * @param {JSON} options - {address:'0x'}
     * @example
     * Usage:
     * const callback = (error, data)=>{
     *      //TODO
     *  }
     *  contactManager
     *   .registerOnContactUpdated(callback)
     * 
     */
    registerOnContactUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        this.events.addListener("onContactUpdateV1", callback);
    }

    /**
     * unRegisterOnContactUpdated is used for removing the listener that has been added for OnContactUpdate event.
     * @param {function} callback - registered callback
     * @example 
     * Usage:
     *  contactManager
     *   .unRegisterOnContactUpdated(callback)
     *   
     */
    unRegisterOnContactUpdated(callback) {
        if (!this.events) {
            return;
        }
        this.events.removeListener("onContactUpdateV1", callback);
    }
}

export default ContactManager;