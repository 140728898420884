import ParamConnector from '../../param-connector';
import Web3Utils from 'web3-utils';
import * as Utils from '../utils';
import GraphQL from '../../param-libs/graph-ql';

class ProfileManager {

    static resetConnection() {
        ParamConnector.getInstance().resetConnection();
    }

    static setPublic(publicKey) {
        let publicBookManager = GraphQL.getInstance().publicBook
        return publicBookManager['setPublic'](publicKey).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        })

        // return Utils.getConfig("PublicBook", true).then(config => {
        //     config.address = Web3Utils.toChecksumAddress("0x39eeA41FFd090E488FaFf46F9B92d69d72a99C23");
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let publicBookManager = paramNetwork.getPublicBookManager(config.address);
        //     return publicBookManager["setPublic"](publicKey, options);
        // })
    }

    static setPublicForAutoGenerated(generatedParamId, generatedPublicKey, generatedPrivateKey) {
        return Utils.getConfig("PublicBook", true).then(config => {
            config.address = Web3Utils.toChecksumAddress("0x39eeA41FFd090E488FaFf46F9B92d69d72a99C23");
            let options = {
                from: generatedParamId,
                privateKey: generatedPrivateKey
            }
            let paramNetwork = ParamConnector.getInstance(config).getNetwork();
            let publicBookManager = paramNetwork.getPublicBookManager(config.address);
            return publicBookManager["setPublic"](generatedPublicKey, options);
        })
    }

    static getPublic(paramId) {
        let publicBookManager = GraphQL.getInstance().publicBook
        return publicBookManager['getPublic'](paramId)

        // return Utils.getConfig("PublicBook", true).then(config => {
        //     config.address = Web3Utils.toChecksumAddress("0x39eeA41FFd090E488FaFf46F9B92d69d72a99C23");
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork();
        //     let publicBookManager = paramNetwork.getPublicBookManager(config.address);
        //     return publicBookManager["getPublic"](paramId);
        // })
    }

}

export default ProfileManager;