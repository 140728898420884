import ParamConnector from '../../param-connector';
import * as Utils from '../utils';

class Search {

    static getSearchResult(searchIndex, owner, type) {
        let SearchDB = ParamConnector.getInstance().getDB().searchDB;
        return SearchDB.searchIndex(searchIndex, owner, type);
    }

}
export default Search;