import ParamUtils from '../utils/index';
import Web3_1_0 from '../utils/Web3_1_0';
import { RequestEvents } from '../utils/event-names';
import BlockInfo from '../utils/block-info';

class ReturnManager {

    constructor(_paramNetwork, contractAddress) {
        this.connection = _paramNetwork.getConnection();
        const returnsmanager = require('./returns-manager.json');

        this.paramNetwork = _paramNetwork;
        this.contractAddress = contractAddress;
        this.returnsManager = Web3_1_0.getContract(this.connection, returnsmanager.abi, contractAddress ? contractAddress : returnsmanager.address);
        this.to = contractAddress;
    }

    emitEvent(eventName, eventJSON) {
        Web3_1_0.upgradeEventData(eventJSON, this.connection).then(event => {
            this.events.emit(eventName, null, event);
        })
    }

    initEvents(options) {
        let events = require('events');
        this.events = new events.EventEmitter();

        if (!options || !options.address) {
            console.log("Options are getting empty. So unable to register the events...")
            return;
        }

        if (options) {
            options = {
                owner: options.address
            };
        }

        // this.watchRequestUpdatedEvent(options);
        this.watchReturnRequestCreatedEvent(options);
        this.watchReturnRequestAcceptedEvent(options);
        this.watchReturnRequestRejectedEvent(options);
        this.watchOrderRequestCreatedEvent(options);
        this.watchOrderRequestAcceptedEvent(options);
        this.watchOrderRequestRejectedEvent(options);
    }

    watchRequestUpdatedEvent(options) {
        if (typeof this.returnsUpdatedEvent === 'undefined' || !this.returnsUpdatedEvent) {
            let filter = {
                filter: options
            };
            this.returnsUpdatedEvent = Web3_1_0.getEvent(this, this.returnsManager, "onRequestUpdated", filter);
        }
        let that = this;
        this.returnsUpdatedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.buyerId.toLowerCase() || options.owner.toLowerCase() === transInfo.sellerId.toLowerCase()) {
                that.emitEvent("onRequestUpdated", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onRequestUpdated", error);
                that.returnsUpdatedEvent.unsubscribe();
                that.returnsUpdatedEvent = undefined;
                that.watchRequestUpdatedEvent(options);
            });
    }

    watchReturnRequestCreatedEvent(options) {
        if (typeof this.returnRequestCreatedEvent === 'undefined' || !this.returnRequestCreatedEvent) {
            let filter = {
                filter: options
            };
            this.returnRequestCreatedEvent = Web3_1_0.getEvent(this, this.returnsManager, "onReturnRequestCreated", filter);
        }
        let that = this;
        this.returnRequestCreatedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.buyerId.toLowerCase() || options.owner.toLowerCase() === transInfo.sellerId.toLowerCase()) {
                that.emitEvent("onReturnRequestCreated", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onReturnRequestCreated", error);
                that.returnRequestCreatedEvent.unsubscribe();
                that.returnRequestCreatedEvent = undefined;
                that.watchReturnRequestCreatedEvent(options);
            });
    }

    watchReturnRequestAcceptedEvent(options) {
        if (typeof this.returnRequestAcceptedEvent === 'undefined' || !this.returnRequestAcceptedEvent) {
            let filter = {
                filter: options
            };
            this.returnRequestAcceptedEvent = Web3_1_0.getEvent(this, this.returnsManager, "onReturnRequestAccepted", filter);
        }
        let that = this;
        this.returnRequestAcceptedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.buyerId.toLowerCase() || options.owner.toLowerCase() === transInfo.sellerId.toLowerCase()) {
                that.emitEvent("onReturnRequestAccepted", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onReturnRequestAccepted", error);
                that.returnRequestAcceptedEvent.unsubscribe();
                that.returnRequestAcceptedEvent = undefined;
                that.watchReturnRequestAcceptedEvent(options);
            });
    }

    watchReturnRequestRejectedEvent(options) {
        if (typeof this.returnRequestRejectedEvent === 'undefined' || !this.returnRequestRejectedEvent) {
            let filter = {
                filter: options
            };
            this.returnRequestRejectedEvent = Web3_1_0.getEvent(this, this.returnsManager, "onReturnRequestRejected", filter);
        }
        let that = this;
        this.returnRequestRejectedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.buyerId.toLowerCase() || options.owner.toLowerCase() === transInfo.sellerId.toLowerCase()) {
                that.emitEvent("onReturnRequestRejected", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onReturnRequestRejected", error);
                that.returnRequestRejectedEvent.unsubscribe();
                that.returnRequestRejectedEvent = undefined;
                that.watchReturnRequestRejectedEvent(options);
            });
    }

    watchOrderRequestCreatedEvent(options) {
        if (typeof this.orderRequestCreatedEvent === 'undefined' || !this.orderRequestCreatedEvent) {
            let filter = {
                filter: options
            };
            this.orderRequestCreatedEvent = Web3_1_0.getEvent(this, this.returnsManager, "onOrderRequestCreated", filter);
        }
        let that = this;
        this.orderRequestCreatedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.buyerId.toLowerCase() || options.owner.toLowerCase() === transInfo.sellerId.toLowerCase()) {
                that.emitEvent("onOrderRequestCreated", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onOrderRequestCreated", error);
                that.orderRequestCreatedEvent.unsubscribe();
                that.orderRequestCreatedEvent = undefined;
                that.watchOrderRequestCreatedEvent(options);
            });
    }

    watchOrderRequestAcceptedEvent(options) {
        if (typeof this.orderRequestAcceptedEvent === 'undefined' || !this.orderRequestAcceptedEvent) {
            let filter = {
                filter: options
            };
            this.orderRequestAcceptedEvent = Web3_1_0.getEvent(this, this.returnsManager, "onOrderRequestAccepted", filter);
        }
        let that = this;
        this.orderRequestAcceptedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.buyerId.toLowerCase() || options.owner.toLowerCase() === transInfo.sellerId.toLowerCase()) {
                that.emitEvent("onOrderRequestAccepted", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onOrderRequestAccepted", error);
                that.orderRequestAcceptedEvent.unsubscribe();
                that.orderRequestAcceptedEvent = undefined;
                that.watchOrderRequestAcceptedEvent(options);
            });
    }

    watchOrderRequestRejectedEvent(options) {
        if (typeof this.orderRequestRejectedEvent === 'undefined' || !this.orderRequestRejectedEvent) {
            let filter = {
                filter: options
            };
            this.orderRequestRejectedEvent = Web3_1_0.getEvent(this, this.returnsManager, "onOrderRequestRejected", filter);
        }
        let that = this;
        this.orderRequestRejectedEvent.on('data', function (event) {
            if (event.returnValues) {
                event["args"] = event.returnValues;
                event.returnValues = undefined;
            }
            let transInfo = event.args;
            if (options.owner.toLowerCase() === transInfo.buyerId.toLowerCase() || options.owner.toLowerCase() === transInfo.sellerId.toLowerCase()) {
                that.emitEvent("onOrderRequestRejected", event);
            }
        })
            .on('error', function (error) {
                that.events.emit("onOrderRequestRejected", error);
                that.orderRequestRejectedEvent.unsubscribe();
                that.orderRequestRejectedEvent = undefined;
                that.watchOrderRequestRejectedEvent(options);
            });
    }


    initReturnRequest(docId, buyerId, sellerId, jsonLd, metaData, txnMode, docContractAddress, options) {
        return Web3_1_0.send(this, this.returnsManager, "initReturnRequest", options, docId, buyerId, sellerId, jsonLd, metaData, txnMode, docContractAddress);
    }

    rejectReturnRequest(returnsId, options) {
        return Web3_1_0.send(this, this.returnsManager, "rejectReturnRequest", options, returnsId);
    }

    acceptReturnRequest(returnsId, options) {
        return Web3_1_0.send(this, this.returnsManager, "acceptReturnRequest", options, returnsId);
    }

    issueReturnOrder(returnsId, jsonLd, metaData, txnMode, options) {
        return Web3_1_0.send(this, this.returnsManager, "issueReturnOrder", options, returnsId, jsonLd, metaData, txnMode);
    }

    acceptReturnOrder(returnOrderId, options) {
        return Web3_1_0.send(this, this.returnsManager, "acceptReturnOrder", options, returnOrderId);
    }

    rejectReturnOrder(returnOrderId, options) {
        return Web3_1_0.send(this, this.returnsManager, "rejectReturnOrder", options, returnOrderId);
    }

    getAllReturnsByBuyer(buyerId) {
        return Web3_1_0.call(this, this.returnsManager, "getAllReturnsByBuyer", null, buyerId);
    }

    getAllReturnsBySeller(sellerId) {
        return Web3_1_0.call(this, this.returnsManager, "getAllReturnsBySeller", null, sellerId);
    }

    getReturn(returnsId) {
        return Web3_1_0.call(this, this.returnsManager, "getReturn", null, returnsId);
    }

    getReturnStatus(returnsId) {
        return Web3_1_0.call(this, this.returnsManager, "getReturnStatus", null, returnsId);
    }

    getAllDocsByParent(parentId) {
        return Web3_1_0.call(this, this.returnsManager, "getAllDocsByParent", null, parentId);
    }

    getAllEventsByRepoId(id, address) {
        let events = Object.keys(RequestEvents);
        let filter = {
            filter: {
                "id": id
            },
            fromBlock: 0,
            toBlock: 'latest'
        }
        return this.getAllEvents(events, filter);
    }

    getAllEvents(events, filter) {
        let promiseArray = [];
        for (let index in events) {
            let promise = Web3_1_0.getPastEvents(this, this.returnsManager, events[index], filter);
            promiseArray.push(promise);
        }
        return Web3_1_0.upgradeEventsData(promiseArray, this.connection);
    }

    registerOnRequestUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onRequestUpdated"]) {
            this.events.removeAllListeners("onRequestUpdated");
        }
        this.events.addListener("onRequestUpdated", callback);
    }

    unRegisterOnRequestUpdated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onRequestUpdated", callback);
    }

    registerOnReturnRequestCreated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onReturnRequestCreated"]) {
            this.events.removeAllListeners("onReturnRequestCreated");
        }
        this.events.addListener("onReturnRequestCreated", callback);
    }

    unRegisterOnReturnRequestCreated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onReturnRequestCreated", callback);
    }

    registerOnReturnRequestAccepted(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onReturnRequestAccepted"]) {
            this.events.removeAllListeners("onReturnRequestAccepted");
        }
        this.events.addListener("onReturnRequestAccepted", callback);
    }

    unRegisterOnReturnRequestAccepted(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onReturnRequestAccepted", callback);
    }

    registerOnReturnRequestRejected(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onReturnRequestRejected"]) {
            this.events.removeAllListeners("onReturnRequestRejected");
        }
        this.events.addListener("onReturnRequestRejected", callback);
    }

    unRegisterOnReturnRequestRejected(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onReturnRequestRejected", callback);
    }

    registerOnOrderRequestCreated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onOrderRequestCreated"]) {
            this.events.removeAllListeners("onOrderRequestCreated");
        }
        this.events.addListener("onOrderRequestCreated", callback);
    }

    unRegisterOnOrderRequestCreated(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onOrderRequestCreated", callback);
    }

    registerOnOrderRequestAccepted(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onOrderRequestAccepted"]) {
            this.events.removeAllListeners("onOrderRequestAccepted");
        }
        this.events.addListener("onOrderRequestAccepted", callback);
    }

    unRegisterOnOrderRequestAccepted(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onOrderRequestAccepted", callback);
    }

    registerOnOrderRequestRejected(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }
        if (this.events._events["onOrderRequestRejected"]) {
            this.events.removeAllListeners("onOrderRequestRejected");
        }
        this.events.addListener("onOrderRequestRejected", callback);
    }

    unRegisterOnOrderRequestRejected(callback, options) {
        if (!this.events) {
            this.initEvents(options)
        }

        this.events.removeListener("onOrderRequestRejected", callback);
    }
}
export default ReturnManager;