// import * as APP_CONSTANTS from 'constants/AppConstants';
import * as Utils from '../util/utils';
import ParamConnector from '../param-connector';
import Console from '../logger/index';

const hdKey = require("ethereumjs-wallet/hdkey")
const bip39 = require("bip39")
const aesctr = require('../util/aes-ctr')
const unorm = require('unorm')
const _pbkdf2 = require('pbkdf2')
const pbkdf2Async = _pbkdf2.pbkdf2
const keyFunction = require('ethereumjs-util');
const Web3Utils = require('web3-utils');
class Wallet {
    constructor() {
    }

    static mnemonicToSeedAsyncIn(mnemonic, password) {
        return new Promise(function (resolve, reject) {
            try {
                var mnemonicBuffer = Buffer.from(unorm.nfkd(mnemonic), 'utf8')
                var saltBuffer = Buffer.from(Wallet.salt(unorm.nfkd(password)), 'utf8')
            } catch (error) {
                return reject(error)
            }
            pbkdf2Async(mnemonicBuffer, saltBuffer, 2048, 64, 'sha512', function (err, data) {
                if (err) return reject(err)
                else return resolve(data)
            })
        })
    }

    static salt(password) {
        return 'mnemonic' + (password || '')
    }

    static createWallet(password) {
        return new Promise((resolve, reject) => {
            try {
                var mnemonic = bip39.generateMnemonic();
                Utils.setLocalStorage("encryptedMnemonic", mnemonic)
                //hash password
                var hashedPassword = _pbkdf2.pbkdf2Sync(password, '', 2048, 64, 'sha512').toString('hex');
                //store hashed password to local storage
                Utils.setLocalStorage("hashedPassword", hashedPassword)
                //create first wallet
                Wallet.generateWallet(mnemonic, password).then((wallet) => {
                    resolve({ mnemonic: mnemonic, wallet: wallet });
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    static getWallet() {
        return new Promise((resolve, reject) => {
            var mnemonic = bip39.generateMnemonic();
            // Utils.setLocalStorage("encryptedMnemonic", mnemonic)
            //create first wallet
            //Ethereum wallet base path
            var path = "m/44'/60'/0'/0"
            //generate seed from mnemonic and getting the promise
            var seed = Wallet.mnemonicToSeedAsyncIn(mnemonic)
            //resolve the promise
            return seed.then(function (result) {
                //get master key from the seed
                var masterKey = hdKey.fromMasterSeed(result)

                //generate masterNode
                var masterNode = masterKey.derivePath(path)

                // get list of wallets
                // var wallets = JSON.parse(utils.getFromLocalStorage("address"))
                // var len
                // if(wallets === null){
                //     wallets = []
                // }
                // len = wallets.length

                //derive next wallet from master node
                var wallet = masterNode.deriveChild(0).getWallet()

                //get address of generated wallet and use it as key to store this wallet on local storage
                var address = wallet.getAddress()
                var privateKey = wallet.getPrivateKey()
                var publicKey = wallet.getPublicKey()
                var paramId = ("0x" + address.toString('hex'))

                resolve({ paramId: Web3Utils.toChecksumAddress(paramId), mnemonic, publicKey: publicKey.toString('hex'), privateKey: privateKey.toString('hex') });
            })
        })
    }

    static createOtpBasedWallet(password) {
        return new Promise((resolve, reject) => {
            try {
                //hash password
                var hashedPassword = _pbkdf2.pbkdf2Sync(password, '', 2048, 64, 'sha512').toString('hex');
                //store hashed password to local storage
                Utils.setLocalStorage("hashedPassword", hashedPassword)
                resolve();
            } catch (e) {
                reject(e);
            }
        })
    }

    static logIn(password) {
        // hash password
        var hashedPassword = _pbkdf2.pbkdf2Sync(password, '', 2048, 64, 'sha512').toString('hex')
        //retrieve hashed password from local storage
        var retrievedPassword = Utils.getFromLocalStorage("hashedPassword")
        if (retrievedPassword === hashedPassword) {
            return true;
        }
        return false;
    }

    static restoreWallet(password, mnemonic) {
        return new Promise((resolve, reject) => {
            try {
                //encrypt mnemonic
                // var encryptedMnemonic = aesctr.encrypt(mnemonic, password);
                //store encrypted mnemonic to local storage
                // localStorage.setItem("encryptedMnemonic", encryptedMnemonic);
                Utils.setLocalStorage("encryptedMnemonic", mnemonic)
                //hash password
                var hashedPassword = _pbkdf2.pbkdf2Sync(password, '', 2048, 64, 'sha512').toString('hex');
                //store hashed password to local storage
                Utils.setLocalStorage("hashedPassword", hashedPassword)
                // Utils.setLocalStorage("version", APP_CONSTANTS.getVersion());
                //create first wallet
                Wallet.generateWallet(mnemonic, password).then((wallet) => {
                    resolve({ mnemonic: mnemonic, wallet: wallet });
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    static generateWallet(mnemonic, password) {
        return new Promise((resolve, reject) => {
            //Ethereum wallet base path
            var path = "m/44'/60'/0'/0"
            //generate seed from mnemonic and getting the promise
            var seed = Wallet.mnemonicToSeedAsyncIn(mnemonic)
            //resolve the promise
            seed.then(function (result) {
                //get master key from the seed
                var masterKey = hdKey.fromMasterSeed(result)

                //generate masterNode
                var masterNode = masterKey.derivePath(path)

                // get list of wallets
                // var wallets = JSON.parse(utils.getFromLocalStorage("address"))
                // var len
                // if(wallets === null){
                //     wallets = []
                // }
                // len = wallets.length

                //derive next wallet from master node
                var wallet = masterNode.deriveChild(0).getWallet()

                //get address of generated wallet and use it as key to store this wallet on local storage
                var address = wallet.getAddress()
                var privateKey = wallet.getPrivateKey()
                var publicKey = wallet.getPublicKey()
                var param_id = ("0x" + address.toString('hex'))

                Utils.setLocalStorage("privateKey", privateKey.toString('hex'))
                Utils.setLocalStorage("param_id", Web3Utils.toChecksumAddress(param_id))
                // Utils.setLocalStorage("version", APP_CONSTANTS.getVersion());
                Utils.setLocalStorage("publicKey", publicKey.toString('hex'));

                resolve(wallet);
            },
                function (err) {
                    Console.error(err)
                    reject(err);
                })
        })
    }

    static validateMnemonic(mnemonic) {
        return bip39.validateMnemonic(mnemonic);
    }

    static getPassPhrase(password) {
        //hash password
        var hashedPassword = _pbkdf2.pbkdf2Sync(password, '', 2048, 64, 'sha512').toString('hex')

        //retrieve hashed password from local storage
        var retrievedPassword = Utils.getFromLocalStorage("hashedPassword")

        if (retrievedPassword === hashedPassword) {
            //get encrypted mnemonic from local storage
            var encryptedMnemonic = Utils.getFromLocalStorage("encryptedMnemonic")
            if (encryptedMnemonic === null) {
                return Promise.resolve(null);
            }

            //decrypt encryptedMnemonic
            // var mnemonic = aesctr.decrypt(encryptedMnemonic, password)
            return Promise.resolve(encryptedMnemonic);
        }
        return Promise.reject(false);
    }

    static getAddressFromPublickey(publicKey) {
        if (publicKey.startsWith("0x")) {
            publicKey = publicKey.substr(2);
        }
        return keyFunction.publicToAddress(new Buffer(publicKey, 'hex')).toString('hex');
    }

    static validatePublicKey(publicKey) {
        if (publicKey.startsWith("0x")) {
            publicKey = publicKey.substr(2);
        }
        if (publicKey.length % 2 !== 0) {
            return false;
        }
        return keyFunction.isValidPublic(new Buffer(publicKey, 'hex'));
    }
}
export default Wallet;