import Console from '../../../logger/index';
import Database from '../../../database/index';
import { gql } from '@apollo/client';
import RestoreReceipts from '../sync/receipts'
import RestoreGrn from '../sync/grn';
import * as NetworkUtils from '../../../../src/util/utils';
import NetworkDataFormat from '../../../database/nosql/Utils/network-data-format';
import NetworkBridge from '../../../util/network-bridge';
import GraphQL from '..';

class ReceiptEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient
        this.dbInstance = Database.getInstance().getDB()
    }
    registerEvents(paramId) {
        let receiptOptions = {
            query: gql`
               subscription onStatusUpdate($paramId: [String]){
                    onStatusUpdate(ReceiptOwnerID: $paramId){
                        contractAddress,
                        seller,
                        buyer,
                        receiptID,
                        status,
                        step,
                        pId
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(receiptOptions).subscribe({
            next(data) {
                that._onReceiptStatusUpdate(data, 'onStatusUpdate')
            },
            error(error) {
                Console.error(`Unable to get created contact details form network, Reson: ${error}`);
            },
        });
        let receiptUpdateOptions = {
            query: gql`
               subscription onReceiptStatusUpdated($paramId: [String]){
                    onReceiptStatusUpdated(ReceiptOwnerID: $paramId){
                        contractAddress,
                        seller,
                        buyer,
                        receiptID,
                        status,
                        step,
                        pId
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(receiptUpdateOptions).subscribe({
            next(data) {
                that._onReceiptStatusUpdate(data, 'onReceiptStatusUpdated')
            },
            error(error) {
                Console.error(`Unable to get created contact details form network, Reson: ${error}`);
            },
        });

        let documentOptions = {
            query: gql`
               subscription onDocumentUpdate($paramId: [String]){
                    onDocumentUpdate(ReceiptOwnerID: $paramId){
                        contractAddress,
                        seller,
                        buyer,
                        receiptID,
                        docId,
                        docStatus,
                        docType,
                        owner
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(documentOptions).subscribe({
            next(data) {
                that._onDocumentUpdate(data)
            },
            error(error) {
                Console.error(`Unable to get created GRN details form network, Reson: ${error}`);
            },
        });

        let grnStatusUpdateOptions = {
            query: gql`
               subscription onGRNStatusUpdate($paramId: [String]){
                    onGRNStatusUpdate(ReceiptOwnerID: $paramId){
                            contractAddress,
                            owner,
                            status,
                            type,
                            rID,
                            rIDs,
                            docIds
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(grnStatusUpdateOptions).subscribe({
            next(data) {
                that._onGrnsUpdate(data, "onGRNStatusUpdate")
            },
            error(error) {
                Console.error(`Unable to get updated GRN status details form network, Reson: ${error}`);
            },
        });
        let grnUpdateOptions = {
            query: gql`
               subscription onGRNUpdate($paramId: [String]){
                    onGRNUpdate(ReceiptOwnerID: $paramId){
                            contractAddress,
                            owner,
                            status,
                            type,
                            rID,
                            rIDs,
                            docIds
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(grnUpdateOptions).subscribe({
            next(data) {
                that._onGrnsUpdate(data, "onGRNUpdate")
            },
            error(error) {
                Console.error(`Unable to get updated GRN status details form network, Reson: ${error}`);
            },
        });

        let transactionUpdateOption = {
            query: gql`
               subscription onTransactionUpdate($paramId: [String]){
                    onTransactionUpdate(ReceiptOwnerID: $paramId){
                        contractAddress,
                        caller,
                        rId,
                        txnID,
                        status,
                        step
                    }
                }
                `,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(transactionUpdateOption).subscribe({
            next(data) {
                that._onTransactionUpdate(data)
            },
            error(error) {
                Console.error(`Unable to get onTransactionUpdate details form network, Reson: ${error}`);
            },
        });


        Console.log(`Registered Events for Receipt - ${paramId}`)

    }

    _onReceiptStatusUpdate(subscriptionDataJSON, event) {
        Console.debug(subscriptionDataJSON)
        let dataJSON = { args: {} }
        // dataJSON.args = { ...subscriptionDataJSON.data[event], ...dataJSON.args }
        dataJSON.args = subscriptionDataJSON.data[event]
        dataJSON.args.rId = NetworkUtils.getTransactionData(subscriptionDataJSON.data[event].receiptID || subscriptionDataJSON.data[event].rId).id
        if (dataJSON.args.rId === "0x0000000000000000000000000000000000000000000000000000000000000000") {
            dataJSON.args.rId = dataJSON.args.pId;
        }
        const dbReceiptId = NetworkUtils.getTransactionId(dataJSON.args.rId, dataJSON.args.contractAddress);
        // dataJSON.args.pId = NetworkUtils.getTransactionId(dataJSON.args.pId, dataJSON.args.contractAddress);
        dataJSON.args.status = dataJSON.args.status.toString();
        dataJSON.args.step = dataJSON.args.step.toString();
        //Bosch Push data to DB --> Change receiptId without contract address
        let receiptDataObject = {
            receiptId: dbReceiptId,
            contractAddress: dataJSON.args.contractAddress,
            status: dataJSON.args.status,
            step: dataJSON.args.step,
            pId: dataJSON.args.pId,
            event: event === "onReceiptStatusUpdated" ? "onReceipStatusUpdateV1" : dataJSON.event
        }
        return RestoreReceipts.restoreReceipt([receiptDataObject]).then(() => {
            Console.log(`[MongoDB] Updated ${dbReceiptId} with status ${dataJSON.args.step}`);
            // this.dbInstance.updateLastSync();
            this._emitOnReceipt(dataJSON.args.rId, dataJSON.args.pId, dataJSON.args.step);
        }).catch(e => {
            Console.error(`[Error] Unable to update receipt status for ${dbReceiptId}, Reason: ${e.toString()}`);
        }).finally(() => {
            this.restoreCatalog(dataJSON)
        }).catch(error => {
            Console.error(`Unable to add transaction for ${dbReceiptId}, Reason: ${error}`)
        })
    }

    restoreCatalog(dataJSON) {
        const dbReceiptId = NetworkUtils.getTransactionId(dataJSON.args.rId, dataJSON.args.contractAddress);
        let result;
        let grapQlInstance = GraphQL.getInstance()
        return grapQlInstance.getEventDetails(dbReceiptId, NetworkUtils.getEventType('receipt')).then(res => {
            result = NetworkUtils.getLatestEventDataByName(res)
            if (dataJSON.args.step === "2")
                return this.dbInstance.receipts.getReceipt(dbReceiptId);
            return Promise.resolve({});
        }).then(receipt => {
            let selfParamId = NetworkUtils.getParamId();
            if (receipt.disputeInfo === 0) {
                NetworkBridge.getGRNManager().closeAllGRNs(dbReceiptId);
            }
            if (receipt.step === "2" && receipt.provider && selfParamId === receipt.provider.identifier) {
                let catalogueManager = NetworkBridge.getItemManager();
                let fromPage = "Invoice";
                catalogueManager.updateInventories(receipt, fromPage, receipt.provider.identifier).catch(e => {
                    Console.error(`[Error] Unable to update inventory for ${dbReceiptId}, Reason: ${e.toString()}`);
                })
            }
            if (!result || !result.from) {
                Console.error(`Unable get transaction details for ${dbReceiptId}.`)
                return;
            }
            let eventMetaData = NetworkUtils.extractEventData(result)
            dataJSON = { ...dataJSON, ...eventMetaData }
            let metaData = NetworkDataFormat.getMetaInfo(dbReceiptId, dataJSON, "Receipt status changed");
            metaData.step = dataJSON.args.step;
            metaData.buyer = dataJSON.args.buyer;
            metaData.status = dataJSON.args.status
            metaData.seller = dataJSON.args.seller;

            let owner = result.from;
            return this.dbInstance.transactionsDB.addTransaction(dbReceiptId, owner, metaData).then(() => {
                // let paramId = NetworkUtils.getParamId();
                // if (!(dataJSON.args.seller === paramId && dataJSON.args.buyer === paramId)) {
                //     return this.dbInstance.notifications.addNotification(dbReceiptId, "receiptStatusUpdate", "receipt", [dataJSON.args.seller, dataJSON.args.buyer]).then(() => {
                //         this.dbInstance.emitEvent("notifications", dbReceiptId, null);
                //     });
                // }
            }).catch(error => {
                Console.error(`Unable to add transaction for ${dbReceiptId}, Reason: ${error}`)
            })
        });
    }

    _onDocumentUpdate(subscriptionDataJSON) {
        Console.debug(subscriptionDataJSON)
        // getMetaInfo (step)
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data.onDocumentUpdate
        dataJSON.args.docType = dataJSON.args.docType.toString()
        dataJSON.args.docStatus = dataJSON.args.docStatus.toString()

        let functionName = "restoreGrn"
        if (dataJSON.args.docType === "1") {
            functionName = "restoreAcceptNote";
        } else if (dataJSON.args.docType === "5") {
            functionName = "restorePartPO";
        } else if (dataJSON.args.docType === "3") {
            functionName = "restoreeLR"
        } else if (dataJSON.args.docType === "6") {
            functionName = "restoreCreditNote"
        }
        let buyerId;
        let grnId = dataJSON.args.docId
        let transactionsData = NetworkUtils.getTransactionData(dataJSON.args.docId)
        dataJSON.args.docId = transactionsData.id
        return RestoreGrn[functionName](dataJSON.args.docId, dataJSON.args.contractAddress, dataJSON.args.docStatus).then((res) => {
            const networkId = NetworkUtils.getTransactionData(res.docId).id;
            this.dbInstance.emitEvent("OnDocumentUpdate", dataJSON.args.docId, networkId, null);
            buyerId = res.buyerId;
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(grnId, NetworkUtils.getEventType('receipt'))
        }).then(res => {
            let result = NetworkUtils.getLatestEventDataByName(res)
            let eventMetaData = NetworkUtils.extractEventData(result)
            dataJSON = { ...dataJSON, ...eventMetaData }
            let metaData = NetworkDataFormat.getMetaInfo(grnId, dataJSON, "on Document Update");
            metaData.step = dataJSON.args.step;
            metaData.buyer = dataJSON.args.buyer;
            metaData.status = dataJSON.args.docStatus
            metaData.seller = dataJSON.args.seller;
            metaData.docType = dataJSON.args.docType;
            return this.dbInstance.transactionsDB.addTransaction(grnId, result.from, metaData)
        }).then(() => {
            return this.dbInstance.grn.getGrn(grnId);
        }).then(receipt => {
            let catalogueManager = NetworkBridge.getItemManager();
            let fromPage = "GRN";
            let selfParamId = NetworkUtils.getParamId();
            if (selfParamId === buyerId) {
                catalogueManager.updateInventories(receipt, fromPage, buyerId).catch(e => {
                    console.error(`[Error] Unable to update inventory for ${grnId}, Reason: ${e.toString()}`);
                });
            }
            return;
        })
    }

    _onGrnsUpdate(subscriptionDataJSON, event) {
        Console.debug(subscriptionDataJSON)
        let dataJSON = {}
        dataJSON.args = subscriptionDataJSON.data[event]
        dataJSON.args.docId = dataJSON.args.rID //temp
        return this.dbInstance.grn.updateGrnsStatus(dataJSON.args.docIds, dataJSON.args.contractAddress, dataJSON.args.status.toString()).then(response => {
            this.dbInstance.emitEvent("OnDocumentUpdate", dataJSON.args.docId, null);
        }).catch(error => {
            Console.error(`Unable to restore grn ${dataJSON.args.docIds}, reason: ${error}`);
        });
    }
    _onTransactionUpdate(dataJSON) {
        Console.debug(dataJSON)
        return this.dbInstance.receipts.doesExist(dataJSON.data.onTransactionUpdate.rId).then(doesExist => {
            if (!doesExist) {
                return;
            }
            dataJSON.data.onTransactionUpdate.ekId = "-1";
            this._onReceiptStatusUpdate(dataJSON, "onTransactionUpdate");
        }).catch(error => {
            Console.error(`[Error] Unable to get transaction update for ${dataJSON.data.onTransactionUpdate.rId}, Reason: ${error}`);
        });
    }
    _emitOnReceipt(docId, parentDocId, step) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.receipts.getSellerAndBuyer(docId).then(res => {
            return this.dbInstance.emitEvent("OnReceiptStatus", docId, parentDocId, res);
        })
    }

    _emitOnDocumentUpdate(grnId, parentDocId) {
        if (!this.dbInstance.events) {
            return;
        }
        return this.dbInstance.grn.getSellerAndBuyer(grnId).then(res => {
            return this.dbInstance.emitEvent("OnDocumentUpdate", grnId, parentDocId, res);
        })
    }
}

export default ReceiptEvents;