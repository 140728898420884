import ParserUtils from './ParserUtils';

class InventoryParser {

    static getDateInCorrectFormat(dateString, key, jsonLd) {
        if (!dateString) {
            return jsonLd;
        }
        if (typeof dateString === "string") {
            let date = new Date(dateString)
            if (!date.getTime())
                dateString = dateString.replace(/(\d{2})[-/.](\d{2})[-/.](\d+)/, "$2/$1/$3")
        }
        jsonLd[key] = dateString;
        return jsonLd;
    }

    static parseInventory(inventoryDetails) {
        let jsonLd = {
            "@type": "Offer",
        }
        let jsonLdKeyArray = ["sku", "lotNumber", "price", "costPerItem", "priceCurrency", "volume", "sgst", "cgst", "igst", "discount"];
        let jsonValueArray = ["sku", "lotNumber", "unitPrice", "costPerItem", "priceCurrency", "volume", "sgst", "cgst", "igst", "discount"];
        jsonLdKeyArray.forEach((jsonLdKey, index) => {
            jsonLd = ParserUtils.getCorrectData(inventoryDetails[jsonValueArray[index]], jsonLdKey, jsonLd);
        })
        jsonLd = this.getDateInCorrectFormat(inventoryDetails["productionDate"], "productionDate", jsonLd);
        jsonLd = this.getDateInCorrectFormat(inventoryDetails["expiryDate"], "expiryDate", jsonLd);
        jsonLd = InventoryParser.getAvailableAt(inventoryDetails.putAwayLocation, jsonLd);
        jsonLd = InventoryParser.getAvailableFrom(inventoryDetails.whLocation, inventoryDetails.whLatLng, jsonLd);
        jsonLd = InventoryParser.getInventoryLevel(inventoryDetails.maxInventoryLevel, inventoryDetails.minInventoryLevel, inventoryDetails.quantity, inventoryDetails.incomingValue, jsonLd);
        console.log("Inventory JSON-Ld", jsonLd);
        //debugger;
        return JSON.stringify(jsonLd);
    }

    static getAvailableAt(place, jsonLd) {
        let availableAt = {
            "@type": "place",
            "address": place
        }
        jsonLd["availableAt"] = availableAt;
        return jsonLd;
    }

    static getAvailableFrom(place, latLng, jsonLd) {
        let availableFrom = {
            "@type": "place",
            "address": place,
            "latLng": latLng
        }
        jsonLd["availableFrom"] = availableFrom;
        return jsonLd;
    }

    static getInventoryLevel(maxValue, minValue, currentValue, incomingValue, jsonLd) {
        let inventoryLevel = {
            "@type": "QuantitativeValue",
            "maxValue": maxValue,
            "minValue": minValue,
            "currentValue": currentValue,
            "incomingValue": incomingValue
        }
        jsonLd["inventoryLevel"] = inventoryLevel;
        return jsonLd;
    }

}

export default InventoryParser;