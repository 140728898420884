import { gql } from '@apollo/client';

class Subscriber {

    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }

    getReceiptSubscriber = (sId) => {
        let options = {
            query: gql`
            query getReceiptSubscriber ($sId: String!){
                getReceiptSubscriber(sID: $sId){
                    payload,
                    receiptID,
                    receiptType,
                    subscriber,
                    owner,
                    sID,
                    docContractAddress
                }                   
            }`,
            variables: { sId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getReceiptSubscriber from graphQL"
                throw new Error(error)
            }
            return graphQLResult.data.getReceiptSubscriber
        })
    }

    getReceiptsBySubscriberAndStep = (address, step) => {
        // return {
        //     "count": 0,
        //     "subscribedReceipts": []
        // }
        let queryOptions = {
            query: gql`{
                getSubscriberReceipts(sender:${address}){
                    totalRecords,
                    IDs
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let subscribedReceiptResult = {
                count: 0,
                subscribedReceipts: []
            }
            if (!graphQLResult || !graphQLResult.data) {
                return subscribedReceiptResult;
            }
            subscribedReceiptResult["count"] = graphQLResult.data["getSubscriberReceipts"].totalRecords
            subscribedReceiptResult["subscribedReceipts"] = graphQLResult.data["getSubscriberReceipts"].IDs
            return subscribedReceiptResult;
        })
    }

    getConsentsForReceipt = (receiptId) => {
        // return {
        //     consent: [
        //         {
        //             "address": "",
        //             "consentType": "seller"
        //         }
        //     ],
        //     "_id": ""
        // }
        let queryOptions = {
            query: gql`{
                getConsentsForReceipt(receiptID:${receiptId}){
                    seller,
                    buyer,
                    receiptID,
                    receiptType,
                    docContractAddress
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let consentResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return consentResult
            }
            // index 0seller
            // index 1 buyer
            consentResult.push(graphQLResult.data.getConsentsForReceipt.seller)
            consentResult.push(graphQLResult.data.getConsentsForReceipt.buyer)
            return consentResult;
        })
    }

    getSubscribers(receiptId) {
        let queryOptions = {
            query: gql`{
                getSubscribers(receiptID:${receiptId}){
                    subscriber
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let allSubscribersResult = {
                rId: receiptId,
                subscribers: []
            };
            if (!graphQLResult || !graphQLResult.data) {
                return allSubscribersResult;
            }
            let subscriberIds = [];
            for (let index in graphQLResult.data.getSubscribers) {
                subscriberIds.push(graphQLResult.data.getSubscribers[index].subscriber) //sID
            }
            allSubscribersResult["subscribers"] = subscriberIds;
            return allSubscribersResult;
        })
    }

    getSubscriber(sId) {
        let queryOptions = {
            query: gql`{
                getSubscriber(sID:${sId}){
                    subscriberParamID,
                    shareKey,
                    status
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let subscriberResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return subscriberResult
            }
            subscriberResult = graphQLResult.data.getSubscriber
            // TODO
            return subscriberResult;
        })
    }

    getSubscriberReceipts(owner) {
        let queryOptions = {
            query: gql`{
                getSubscriberReceipts(sender:${owner}){
                    totalRecords,
                    IDs
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let subscribedReceiptResult = {
                count: 0,
                subscribedReceipts: []
            }
            if (!graphQLResult || !graphQLResult.data) {
                return subscribedReceiptResult;
            }
            subscribedReceiptResult["count"] = graphQLResult.data["getSubscriberReceipts"].totalRecords
            subscribedReceiptResult["subscribedReceipts"] = graphQLResult.data["getSubscriberReceipts"].IDs
            return subscribedReceiptResult;
        })
    }

    isSubscriberExists(receiptId, subscriberAddress) {
        let options = {
            query: gql`
            query isSubscriberExists($receiptId: String!, $subscriberAddress: String!){
                isSubscriberExists(receiptID: $receiptId, subscriberAddress: $subscriberAddress)
                }
            `,
            variables: { receiptId, subscriberAddress }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.isSubscriberExists
        })
    }


    // Mutation
    addSellerConsent(receiptId, receiptTxnType, docContractAddress) {
        let queryOptions = {
            mutation: gql`
                mutation addSellerConsent($receiptId: String!, $receiptTxnType: TRANSACTION_TYPE!, $docContractAddress: String!){
                    addSellerConsent(receiptId: $receiptId, receiptTxnType: $receiptTxnType, docContractAddress: $docContractAddress)
                }
                `,
            variables: { receiptId, receiptTxnType, docContractAddress }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSellerConsent
        })
    }

    addBuyerConsent(receiptId, receiptTxnType, docContractAddress) {
        let queryOptions = {
            mutation: gql`
                mutation addBuyerConsent($receiptId: String!, $receiptTxnType: TRANSACTION_TYPE!, $docContractAddress: String!){
                    addBuyerConsent(receiptId: $receiptId, receiptTxnType: $receiptTxnType, docContractAddress: $docContractAddress)
                }
                `,
            variables: { receiptId, receiptTxnType, docContractAddress }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addBuyerConsent
        })
    }

    addSubscriber(payload, receiptId, receiptTxnType, subscriber, docContractAddress) {
        let queryOptions = {
            mutation: gql`
                mutation addSubscriber($payload:payload, $receiptId: String!, $receiptTxnType: TRANSACTION_TYPE!,$subscriber: String!, $docContractAddress: String!){
                    addSubscriber(payload:$payload, receiptId: $receiptId, receiptTxnType: $receiptTxnType, subscriber:$subscriber, docContractAddress: $docContractAddress)
                }
                `,
            variables: { payload, receiptId, receiptTxnType, subscriber, docContractAddress }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSubscriber
        })
    }

    addSubscriberForCons(payload, receiptId, receiptTxnType, subscriberAddress, docContractAddress, seller, buyer) {
        let queryOptions;
        queryOptions = {
            mutation: gql`
            mutation addSubscriberForCons($payload: String!,$receiptId: String!,$receiptTxnType: TRANSACTION_TYPE!,$subscriberAddress: String!,$docContractAddress: String!,$seller: String!,$buyer: String!) {
                addSubscriberForCons(payload:$payload,receiptId:$receiptId,receiptTxnType:$receiptTxnType,subscriberAddress:$subscriberAddress,docContractAddress:$docContractAddress, seller:$seller,buyer:$buyer)            
            }`,
            variables: { payload, receiptId, receiptTxnType, subscriberAddress, docContractAddress, seller, buyer }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addSubscriberForCons
        });
    }

}
export default Subscriber;
