import React, { PureComponent } from "react";
import { Input, Icon, Button, Card, Divider, Row, Col, message } from "antd";
import CircularProgress from '../../components/CircularProgress';
import Wallet from '../../wallet/index';
import * as Utils from '../../util/utils';
import "../CreatePassword/index.less";

class RestoreWallet extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      text: false
    }
  }
  componentDidMount() {
    if (this.props && this.props.location && this.props.location.state && this.props.location.state.account) {
      this.setState({
        text: true
      })
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  handleSubmit = () => {
    this.setState({ showLoader: true })
    var input = "";
    var id;
    for (let i = 0; i < 12; i++) {
      id = "field" + i;
      var text = document.getElementById(id).value;
      input += text.trim() + ' ';
    }
    input = input.trim();
    let mnemonic = input;
    if (!Wallet.validateMnemonic(mnemonic)) {
      message.error("Pass phrase is invalid")
      this.setState({ showLoader: false })
      return;
    }
    Utils.setLocalStorage('encryptedMnemonic', mnemonic);
    this.setState({ showLoader: false })
    this.props.history.push({ pathname: "/password", state: { source: "restore" } })
  }

  handlePaste(event) {
    event.preventDefault()
    var mnemonic = event.clipboardData.getData('Text').split(' ')
    var len = mnemonic.length
    var id = event.target.id
    var idNo
    for (var i = 0; i < len; i++) {
      if (id != "field12") {
        var inpField = document.getElementById(id)
        inpField.focus()
        inpField.value = mnemonic[i]

        if (id.length == 7) {
          idNo = parseInt(id[5] + id[6])
        }
        else {
          idNo = parseInt(id[5])
        }
        id = "field" + (idNo + 1)
      }
    }
  }

  handleKeyPress(event) {
    if (event.which == 32) {
      var id = event.target.id
      var value = document.getElementById(id).value
      var idNo
      if (id != "field11") {
        if (id.length == 7) {
          idNo = parseInt(id[5] + id[6])
        }
        else {
          idNo = parseInt(id[5])
        }
      }
      else {
        return
      }
      id = "field" + (idNo + 1)
      var nextInput = document.getElementById(id)
      nextInput.focus()
    }
  }

  redirectToLogin = () => {
    this.props.history.push('/login');
  }

  splittedInputBox() {
    let column = []
    for (let i = 0; i < 12; i++) {
      var temp = "field" + i
      if (i == 0) {
        var first = <Input addonBefore={i + 1} autoFocus className="splitted-input" autoComplete="off" id={temp} onPaste={this.handlePaste} onKeyPress={this.handleKeyPress} />
      }
      else {
        var first = <Input addonBefore={i + 1} className="splitted-input" autoComplete="off" id={temp} onPaste={this.handlePaste} onKeyPress={this.handleKeyPress} />
      }
      column.push(<Col className="splitted-input-gutter-row" xl={6} lg={6} md={12} sm={12} xs={12} align="center" style={{ marginBottom: '1rem' }}>{first}</Col>)
    }
    return <Row>{column}</Row>
  }


  render() {
    return (
      <Card
        className="password phraseCard"
        title={<h2 className="gx-mb-0"><Icon type="arrow-left" onClick={this.goBack} />
          &nbsp;&nbsp; {this.props && this.props.location && this.props.location.state && this.props.location.state.account ? 'Switch Account' : 'Forgot your Password?'}
        </h2>}>
        {
          this.state.showLoader &&
          <CircularProgress />
        }
        < div className="password__inner" >

          <p className=" gx-mb-4 password__description ">
            To ensure uncompromised security we don't allow resetting password.
            Don't worry you can restore your account using your 12 phrase
            password.
          </p>

          {this.splittedInputBox()}
          <hr />
        </div >

        <Divider />
        <Row>
          <Col xl={12} lg={12} md={12} sm={24}>
            {
              Utils.getParamId() && <p className="gx-pointer" style={{ color: "#6236ff", marginTop: '0.5rem' }} onClick={this.redirectToLogin}>Have an account? Login</p>
            }
          </Col>
          <Col xl={12} lg={12} md={12} sm={24}>
            <Button className="password__nextBtn" onClick={this.handleSubmit}>RESTORE MY ACCOUNT</Button>
          </Col>
        </Row>

      </Card >
    );
  }
}
export default RestoreWallet;
