import SaveAndReadFile from "../../../param-libs/save-read-file";
import * as Utils from '../../../util/utils';
import { defaultReceiptTemplate } from './config/default-receipt';
import AppUtils from "../../../app.utils";
class DocumentTheme {

    constructor() {
        if (DocumentTheme.instance) {
            throw new Error("Cannot create instance. Please use DocumentTheme.getInstance()")
        }
        this.themeMap = DocumentTheme.initThemeMap();
        this.callback = {
            onFileRead: (fileData) => {
                let type = fileData.name.split('.')[0].split('-')[1];
                this.themeMap[type] = {
                    data: fileData.data,
                    fetchStatus: 1
                }
                this.isProcessCompleted();
            },
            onFileError: (fileData) => {
                let type = fileData.name.split('.')[0].split('-')[1];
                this.themeMap[type] = {
                    data: fileData.data,
                    fetchStatus: 3
                }
                this.isProcessCompleted();
            }
        }
        if (AppUtils.isDesktop())
            SaveAndReadFile.getInstance().registerReadFile(this.callback);
    }

    // fetchStatus int, 0 -> don't fetch, 1 -> completed, 2 -> inProgress, 3 -> error
    static initThemeMap() {
        let themeMap = {};
        let customThemeSetting = Utils.getFromLocalStorage("customThemeSetting");
        if (!customThemeSetting) {
            customThemeSetting = Utils.setDefaultCustomTheme();
        }
        for (let [key, value] of Object.entries(customThemeSetting)) {
            themeMap[key] = {
                data: defaultReceiptTemplate,
                fetchStatus: value ? 2 : 0
            }
        }
        return themeMap;
    }

    static getInstance() {
        if (!DocumentTheme.instance) {
            DocumentTheme.instance = new DocumentTheme();
        }
        return DocumentTheme.instance;
    }

    readAndSaveTheme(type) {
        if (AppUtils.isDesktop()) {
            let fileInfoObject = {
                screenName: "Themes",
                fileName: `${Utils.getParamId()}-${type}.ejs.zip`,
                password: Utils.getFromLocalStorage('hashedPassword'),
                paramId: Utils.getParamId()
            }
            SaveAndReadFile.getInstance().readFile(fileInfoObject);
        } else {
            this.themeMap[type] = {
                data: Utils.getFromLocalStorage(type).template,
                fetchStatus: 1
            }
        }
    }

    onReady = (uiCallback) => {
        this.uiCallback = uiCallback;
        let customThemeSetting = Utils.getFromLocalStorage('customThemeSetting');
        for (let [key, value] of Object.entries(customThemeSetting)) {
            if (value.isCustomTheme) {
                this.readAndSaveTheme(key);
            }
        }
    }

    isProcessCompleted = () => {
        let isCompleted = true;
        for (let [screenType, value] of Object.entries(this.themeMap)) {
            if (value.fetchStatus === 2) {
                isCompleted = false;
                break;
            }
        }
        if (isCompleted) {
            this.uiCallback.onFinished(this.themeMap);
        }
    }

    updateTheme = (type, ejsContent) => {
        this.themeMap[type] = {
            fetchStatus: 1,
            data: ejsContent || defaultReceiptTemplate
        }
    }

    getTheme = (type) => {
        if (type != "contract" && type != "purchaseOrder" && type != "invoice" && type != "payment") {
            return { data: defaultReceiptTemplate };
        }
        return this.themeMap[type];
    }

}

export default DocumentTheme;