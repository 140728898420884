import * as Utils from "../../../util/utils";
import { gql } from '@apollo/client';

class PublicBook {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }

    getPublic(paramId) {
        let options = {
            query: gql`
            query getPublic($paramId: String!){
                getPublic(paramID: $paramId) {
                        accountPublicKey
                    }
            }`,
            variables: { paramId }
        }
        return this.apolloClient.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data || !graphQLResult.data.getPublic || graphQLResult.data.getPublic.accountPublicKey === '') {
                let error = graphQLResult.errors ? graphQLResult.errors[0].message : "Unable to getPublic from graphQL"
                console.error('getPublic error:', error)
                return undefined
                // throw new Error(error)
            }
            return graphQLResult.data.getPublic.accountPublicKey;
        })
    }


    setPublic(publicKey) {
        let options;
        options = {
            mutation: gql`
            mutation setPublic($publicKey: String!) {
                setPublic(publicKey: $publicKey)         
            }`,
            variables: { publicKey }
        }
        return this.apolloClient.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.setPublic
        });
    }

}
export default PublicBook;