import ECIES from '../../../../src/util/ecies';
import * as NetworkUtils from '../../../../src/util/utils';
import Console from '../../../logger';
import ParamConnector from '../../../../src/param-connector';
import GraphQL from '../index';
import NetworkDataFormator from '../../../database/nosql/Utils/network-data-format';
import { ContactEvents } from '../../../param-network/utils/event-names';

class RestoreContacts {
    static restoreContact(contactId, contractAddress, owner, paramId) {
        let contactsDBInstance = ParamConnector.getInstance().getDB().contacts;
        let contactsInstance = GraphQL.getInstance().contacts;
        return contactsInstance.getContact(contactId).then(contact => {
            let privateKey = NetworkUtils.getFromLocalStorage("privateKey")
            if (contact.txnType === "2") {
                contact.JSONLD = ECIES.decrypt(privateKey, contact.JSONLD);
            }
            return contactsDBInstance.updateContact(contactId, contact.JSONLD, owner, contact.status, paramId);
        })
    }

    static restoreAllContacts(contractAddress, owner, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("contact", `Trying to get all the contacts from ${contractAddress}`);
        }
        let graphQLInstance = GraphQL.getInstance(), contactIds;
        return graphQLInstance.contacts.getAllContacts(owner).then(_contactIds => {
            contactIds = _contactIds
            Console.log(`[MongoDB] Got contacts from ${contractAddress} metainfo from network.`);
            let contactPromises = [];
            for (let index = 0; index < contactIds.length; index++) {
                let contactId = contactIds[index].toString();
                contractAddress = NetworkUtils.getTransactionData(contactId).address
                // contactId = NetworkUtils.getTransactionId(contactId, contractAddress);
                let contactPromise = RestoreContacts.restoreContact(contactId, contractAddress, owner)
                contactPromises.push(contactPromise);
            }
            return Promise.all(contactPromises);
        }).then(res => {
            return contactIds
        })
    }
    static restoreAllContactEvents(contractAddress, owner, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("contact", `Trying to get all the events from ${contractAddress}`);
        }
        let grapQlInstance = GraphQL.getInstance()
        let transactionsDB = ParamConnector.getInstance().getDB().transactionsDB;
        let selfParamId = NetworkUtils.getParamId();
        let promiseArray = []
        return grapQlInstance.contacts.getAllContacts(owner).then(contactIds => {
            for (let contactIndex in contactIds) {
                contractAddress = NetworkUtils.getTransactionData(contactIds[contactIndex]).address
                promiseArray.push(grapQlInstance.getEventDetails(contactIds[contactIndex], NetworkUtils.getEventType('contact')).then(contactEvents => {
                    return RestoreContacts.restoreContactIdEvents(transactionsDB, selfParamId, contractAddress, contactEvents);
                }))
            }
            return Promise.all(promiseArray);
        })
    }
    static restoreContactIdEvents(transactionsDB, selfParamId, contractAddress, contactEvents) {
        let addTransactionsPromiseArray = [];
        for (let index in contactEvents) {
            let event = contactEvents[index];
            let dataJSON = NetworkUtils.getSubscriptionDataFromEventData(event)
            let eventMetaData = NetworkUtils.extractEventData(event)
            dataJSON = { ...dataJSON, ...eventMetaData }
            let label = ContactEvents[event.type].label;
            dataJSON.args.contactId = NetworkUtils.getTransactionId(dataJSON.args.contactId, contractAddress);
            let metaData = NetworkDataFormator.getMetaInfo(dataJSON.args.contactId, dataJSON, label);
            let owner = dataJSON.args.owner;
            if (!owner) {
                if (selfParamId === dataJSON.args.sender || selfParamId === dataJSON.args.receiver) {
                    owner = selfParamId;
                }
            }
            let transactionPromise = transactionsDB.addTransaction(dataJSON.args.contactId, owner, metaData).catch(error => {
                Console.error(`Unable to restore contact dataJSON for ${dataJSON.args.contactId}, Reason: ${error}`)
            })
            addTransactionsPromiseArray.push(transactionPromise);
        }
        return Promise.all(addTransactionsPromiseArray);
    }
}
export default RestoreContacts;