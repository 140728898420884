import DonutFormatter from './donut-formatter';
import PieChartFormatter from './piechart-formatter';
import BarchartFormatter from './barchart-formatter';
import LineChartFormatter from './linechart-formatter'
import TableChartFormatter from './tablechart-formatter';
class DataFormatters {
    static getDonotFormatter() {
        return DonutFormatter;
    }
    static getPieChartFormatter() {
        return PieChartFormatter;
    }
    static getBarchartFormatter() {
        return BarchartFormatter;
    }
    static getLineChartFormatter() {
        return LineChartFormatter;
    }
    static getTableChartFormatter() {
        return TableChartFormatter;
    }
}
export default DataFormatters;