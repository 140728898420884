// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';


//SideBar
export const GET_IRP_MODE = 'GET_IRP_MODE';

//Customization Settings
export const TOGGLE_PURCHASE_VISIBILITY = 'TOGGLE_PURCAHSE_VISIBILITY';
export const TOGGLE_SALES_VISIBILITY = 'TOGGLE_SALES_VISIBILITY';
export const TOGGLE_LSALES_VISIBILITY = 'TOGGLE_LSALES_VISIBILITY';
export const TOGGLE_LPURCHASES_VISIBILITY = 'TOGGLE_LPURCHASES_VISIBILITY';
export const TOGGLE_DRAFT_INVOICE_VISIBILITY = 'TOGGLE_DRAFT_INVOICE_VISIBILITY';
export const TOGGLE_VIEW_WORKFLOW_VISIBILITY = 'TOGGLE_VIEW_WORKFLOW_VISIBILITY';
export const TOGGLE_REPORTS_VISIBILITY = 'TOGGLE_REPORTS_VISIBILITY';
export const TOGGLE_OPERATIONS_VISIBILITY = 'TOGGLE_OPERATIONS_VISIBILITY';
export const TOGGLE_SUBDOCUMENTS_VISIBILITY = 'TOGGLE_SUBDOCUMENTS_VISIBILITY';
export const TOGGLE_CATALOGUE_VISIBILITY = 'TOGGLE_CATALOGUE_VISIBILITY'
export const SHOW_SALES = true;
export const HIDE_SALES = false;
export const SHOW_PURCHASES = true;
export const HIDE_PURCHASES = false;
export const SHOW_LSALES = true;
export const HIDE_LSALES = false;
export const SHOW_LPURCHASES = true;
export const HIDE_LPURCHASES = false;
export const SHOW_DRAFT_INVOICE = true;
export const HIDE_DRAFT_INVOICE = false;
export const SHOW_VIEW_WORKFLOW = true;
export const HIDE_VIEW_WORKFLOW = false;
export const SHOW_REPORTS = true;
export const HIDE_REPORTS = false;
export const SHOW_SUBDOCUMENTS = true;
export const HIDE_SUBDOCUMENTS = false;
export const SHOW_OPERATIONS = true;
export const HIDE_OPERATIONS = false;
export const SHOW_CATALOGUE = true;
export const HIDE_CATALOGUE = false;
export const SHOW_CONTACTS = true;
export const HIDE_CONTACTS = false;
export const SHOW_CREDIT_NOTE_TEMPLATE = true;
export const HIDE_CREDIT_NOTE_TEMPLATE = false;
export const TOGGLE_CONTACTS_VISIBILITY = 'TOGGLE_CONTACTS_VISIBILITY';
export const TOGGLE_CREDIT_NOTE_TEMPLATE_VISIBILITY = 'TOGGLE_CREDIT_NOTE_TEMPLATE_VISIBILITY';
