import React, { Component } from 'react';
import './index.less';
// import { Input, InputNumber} from 'antd';
import * as Utils from '../../../util/utils'
import { Input, Select } from 'antd';
const { Option } = Select;

class TotalTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            taxAmount: 0,
            totalAmount: 0,
            subTotal: 0,
            cgstTotal: 0,
            igstTotal: 0,
            sgstTotal: 0,
            globalDiscount: 0,
            discountUnit: "value"
        }

    }

    componentDidMount() {
        this.computeTotalSummary(this.props);
    }

    componentWillReceiveProps(nextProps) {
        // console.debug('TotalTable props',nextProps)
        // this.calculateTaxAmount(nextProps);
        this.computeTotalSummary(nextProps)
    }

    computeTotalSummary = (data) => {
        let items = data.dataSource;
        let subTotal = 0, grandTotal = 0, sgstTotal = 0, cgstTotal = 0, igstTotal = 0, totalDiscount = 0;
        for (let index = 0; index < items.length; index++) {
            const currentItem = items[index];
            currentItem.subTotal = Number(currentItem.subTotal);
            sgstTotal += currentItem.subTotal * (Number(currentItem.sgst) / 100)
            cgstTotal += currentItem.subTotal * (Number(currentItem.cgst) / 100)
            igstTotal += currentItem.subTotal * (Number(currentItem.igst) / 100)
            totalDiscount += currentItem.subTotal * (Number(currentItem.discount) / 100)
            grandTotal += Number(currentItem.amount)
            subTotal += currentItem.subTotal ? Number(currentItem.subTotal) : 0
        }

        let globalDiscount = Number(this.props.globalDiscountInputValue);
        if (this.props.globalDiscountUnit === "value") {
            globalDiscount = (globalDiscount * 100) / (subTotal - totalDiscount);
        }
        if (this.props.setStateFromChild) {
            this.props.setStateFromChild({
                globalDiscount: globalDiscount,
                totalAmount: grandTotal - ((subTotal - totalDiscount) * 0.01 * globalDiscount),
            });
        }
        globalDiscount = (subTotal - totalDiscount) * 0.01 * globalDiscount;
        // totalDiscount += globalDiscount;
        const totalAmount = grandTotal - globalDiscount;
        // this.props.setStateFromChild({ totalAmount: totalAmount });

        this.setState({
            totalAmount,
            subTotal,
            taxAmount: Number(cgstTotal + sgstTotal + igstTotal),
            sgstTotal: isNaN(sgstTotal) ? 0 : sgstTotal,
            cgstTotal: isNaN(cgstTotal) ? 0 : cgstTotal,
            igstTotal: isNaN(igstTotal) ? 0 : igstTotal,
            totalDiscount,
            globalDiscount

        })
        // console.log('computeTotalSummary', subTotal, grandTotal);
        // console.log('sgst csgt', sgstTotal, cgstTotal);
    }

    handleDiscountChange = (e) => {
        const value = e.target.value;
        this.props.setGlobalDiscount(value);
    }

    handleDiscountUnitChange = (key) => {
        this.props.setGlobalDiscountUnit(key);
    }

    render() {

        const addonBefore = <Select
            defaultValue="percent"
            style={{ width: "10em" }}
            onChange={this.handleDiscountUnitChange}
            disabled={this.props.disableDiscountInput}
        >
            <Option value="value">{`Discount (${this.props.currency})`}</Option>
            <Option value="percent">Discount (%)</Option>
        </Select>
        let grandTotalStyle = {
            backgroundColor: "#f5f5f5"
        }
        let currentTheme = Utils.getFromLocalStorage('theme');
        if (currentTheme === 'THEME_TYPE_DARK') {
            grandTotalStyle = {
                backgroundColor: "transparent"
            }
        }


        return (
            <div className="total-table" style={{ ...this.props.style }}>
                {!this.props.hideTable &&
                    <table>
                        <tr>
                            <td className="total-table__left">Sub Total</td>
                            <td className="total-table__right">{Utils.getCurrency(this.state.subTotal, this.props.currency)}</td>
                        </tr>
                        <tr>
                            {this.props.disableDiscountInput ?
                                <>
                                    <td className="total-table__left">Discount (%) </td>
                                    <td className="total-table__right">{Utils.numberWithCommas(this.props.globalDiscountInputValue)}</td>
                                </>
                                :
                                <td className="total-table__right" colSpan={2}>
                                    <Input
                                        min={0}
                                        style={{ textAlign: 'right' }}
                                        addonBefore={addonBefore}
                                        onChange={this.handleDiscountChange}
                                        // onFocus={(e) => e.target.select()}
                                        value={this.props.globalDiscountInputValue}
                                        disabled={this.props.disableDiscountInput}
                                    />
                                </td>
                            }
                        </tr>
                        {!this.props.hideTaxComponent &&
                            <>
                                <tr>
                                    <td className="total-table__left">SGST </td>
                                    <td className="total-table__right">{Utils.getCurrency(this.state.sgstTotal, this.props.currency)}</td>
                                </tr>
                                <tr>
                                    <td className="total-table__left">CGST</td>
                                    <td className="total-table__right">{Utils.getCurrency(this.state.cgstTotal, this.props.currency)}</td>
                                </tr>
                                <tr>
                                    <td className="total-table__left">IGST</td>
                                    <td className="total-table__right">{Utils.getCurrency(this.state.igstTotal, this.props.currency)}</td>
                                </tr>
                            </>
                        }

                        <tr>
                            <td className="total-table__left">Total Tax </td>
                            <td className="total-table__right">{Utils.getCurrency(this.state.taxAmount, this.props.currency)}</td>
                        </tr>
                        <tr>
                            <td className="total-table__left">Discount on Items</td>
                            <td className="total-table__right">{Utils.getCurrency(this.state.totalDiscount, this.props.currency)}</td>
                        </tr>
                        <tr>
                            <td className="total-table__left">Discount on Receipt</td>
                            <td className="total-table__right">{Utils.getCurrency(this.state.globalDiscount, this.props.currency)}</td>
                        </tr>
                        <tr>
                            <td className="total-table__left">Total Discount</td>
                            <td className="total-table__right">{Utils.getCurrency(this.state.totalDiscount + this.state.globalDiscount, this.props.currency)}</td>
                        </tr>
                        {!this.props.hideTaxComponent &&
                            <tr style={grandTotalStyle}>
                                <td className="total-table__left">Grand Total</td>
                                <td className="total-table__right">
                                    {Utils.getCurrency(this.state.totalAmount, this.props.currency)}
                                </td>
                            </tr>
                        }
                    </table>
                }
                <br />
            </div>
        )
    }
}

export default TotalTable;