import Charts from '../charts'
import ParamConnector from '../../../param-connector'
import SellerOverView from './receipt/seller-overview'
import SalesCustomerInsights from './receipt/sales-customerinsights'
import * as networkUtils from '../../../util/utils';

import BuyerOverView from './receipt/buyer-overview'
import PurchaseSupplierDelivery from './receipt/purchase-supplierdelivery'

import { chartConfig } from '../config/defaultGraphConfig';
// import PurchasesOverview from '../charts/purchases/overview';
import Console from '../../../logger'
import Finances from './finance/overview'

class ReceiptEvents {

    constructor(paramId) {
        this.charts = Charts.getInstance();
        this.paramID = paramId;
        this.buyerOverView = new BuyerOverView(paramId);
        this.sellerOverView = new SellerOverView(paramId);
        this.salesCustomerInsights = new SalesCustomerInsights(paramId);
        this.purchaseSupplierDelivery = new PurchaseSupplierDelivery(paramId);
        this.financeOverview = new Finances(paramId);
    }

    registerEvents() {
        const that = this;
        ParamConnector.getInstance().getDB().receiptDBEvents.registerOnReceiptStatusUpdate((eventData) => {
            Console.info("Got the registerOnReceiptStatusUpdate events")
            let docId = eventData[0];
            let eventJson = eventData[2];
            if (!docId) {
                Console.error("[Receipt]-registerOnReceiptStatusUpdate Unable to get the docId from events");
                return
            }
            that.onReceiptStatusUpdate(eventJson);
        })

        ParamConnector.getInstance().getDB().receiptDBEvents.registerOnDocumentUpdate((eventData) => {
            Console.info("Got the registerOnDocumentUpdate events")
            let docId = eventData[0];
            let eventJson = eventData[2];
            if (!docId) {
                Console.error("[Receipt]-registerOnDocumentUpdate Unable to get the docId from events");
                return;
            }
            that.onGRNAdded(eventJson);
        })

        /* 
        We are not doing any chart with subscriber data

        ParamConnector.getInstance().getDB().receiptDBEvents.registerOnSubscriber((data, err) => {
            Console.info("Got the registerOnSubscriber events")
            if (err) {
                Console.error("[Receipt]-registerOnSubscriber Unable to get the data from events", err);
                return;
            }
            that.onReceiptSubsriberAdded(data);
        })

        ParamConnector.getInstance().getDB().receiptDBEvents.registerOnConsent((data, err) => {
            Console.info("Got the registerOnConsent events")
            if (err) {
                Console.error("[Receipt]-registerOnConsent Unable to get the data from events", err);
                return;
            }
            that.onConsentAdded(data);
        }) */
    }

    unRegisterEvents() {

    }

    registerEventsAndInitData() {
        Console.info("Got the registerEventsAndInitData events")
        this.registerEvents();
        this.buyerOverView.publishAllOverView();
        this.sellerOverView.publishAllOverView();
        this.salesCustomerInsights.publishAllCustomerInsights();
        this.purchaseSupplierDelivery.publishAllSupplierDelivery();
        this.financeOverview.publishAllOverView();
    }

    onReceiptStatusUpdate(data) {
        if (!data) {
            return;
        }

        if (data.seller === this.paramID) {
            this.publishSellerReports(data);
        }

        if (data.buyer === this.paramID) {
            this.publishBuyerReports(data);
        }
        this.publishFinanceReports(data);
    }

    onGRNAdded(data) {
        if (!data) {
            return;
        }

        if (data.buyer === this.paramID) {
            this.buyerOverView.getGRNCount(true);
            this.buyerOverView.getDisputes(true);
            this.buyerOverView.getGoodsReceivedButNotInvoiced(true);
            this.buyerOverView.overView(true);
            this.buyerOverView.getTopQuantityDisputes(true);
            this.buyerOverView.getAveragePurchaseCycle(true);

            this.purchaseSupplierDelivery.getOnTimeDelivery(true);
            this.purchaseSupplierDelivery.getDefectRate(true);
            this.purchaseSupplierDelivery.supplierDelivery(true);
            this.purchaseSupplierDelivery.getReorderItemsDefect(undefined, true);
            this.purchaseSupplierDelivery.getReorderItemsRejection(undefined, true)
            this.purchaseSupplierDelivery.getSupplierDefectRate(true);
            this.purchaseSupplierDelivery.topSupplierWithShortSupply(true);
        }

        if (data.seller === this.paramID) {
            this.sellerOverView.getGrnAcknowledged(true);
            this.sellerOverView.getGoodsSentButNotAcknowledged(true);
            this.sellerOverView.getDisputes(true);
            this.sellerOverView.getOverview(true);
            this.sellerOverView.getTopQuantityDisputes(true);
            this.sellerOverView.getAverageSalesCycle(true);

            this.salesCustomerInsights.getCustomerShipment(true)
            this.salesCustomerInsights.getOverview(true)
        }

        this.publishFinanceReports(data);
    }

    /* onReceiptSubsriberAdded(data) {
        return;
    }

    onConsentAdded(data) {
        return;
    } */

    publishSellerReports(eventData) {
        if (!eventData || eventData.step === null || eventData.step === undefined) {
            return;
        }

        if (eventData.step === "0") {
            this.sellerOverView.getCustomers(true);
            this.sellerOverView.getContractDueForRenewal(true);

            this.salesCustomerInsights.getContractDetails(true)
        }

        if (eventData.step === "1") {
            this.sellerOverView.getSummary(true);
            this.sellerOverView.getOverview(true)
            this.sellerOverView.getGrnAcknowledged(true);

            this.salesCustomerInsights.getFillRate(true)
            this.salesCustomerInsights.getOverview(true)
            this.salesCustomerInsights.getNewCustomers(true)
            this.salesCustomerInsights.getNewAndReturnCustomers(true);
        }

        if (eventData.step === "2") {
            this.sellerOverView.getSummary(true);
            this.sellerOverView.getSales(true)
            this.sellerOverView.getOverview(true);
            this.sellerOverView.getGrnAcknowledged(true);

            this.sellerOverView.getGoodsSentButNotAcknowledged(true);
            this.sellerOverView.getDisputes(true);
            this.sellerOverView.getTopSales(undefined, undefined, undefined, undefined, true);
            this.sellerOverView.getAllOpenOrPartialInvoices(true);

            this.salesCustomerInsights.getCustomerShipment(true)
            this.salesCustomerInsights.getOverview(true)
        }

        if (eventData.step === '3') {
            this.sellerOverView.getDisputes(true);
            this.sellerOverView.getAllOpenOrPartialInvoices(true);
        }

        if (eventData.step === '4') {
            this.sellerOverView.getAllOpenOrPartialInvoices(true);
        }
        this.sellerOverView.getAverageSalesCycle(true);
        this.salesCustomerInsights.getRepeatCustomers(true)
        this.salesCustomerInsights.getReorderRatio(true)
        this.salesCustomerInsights.getActiveCustomers(true)
        this.salesCustomerInsights.getTopCustomers(true)
    }

    publishBuyerReports(eventData) {
        if (!eventData || eventData.step === null || eventData.step === undefined) {
            return;
        }

        if (eventData.step === "0") {
            this.buyerOverView.getSuppliers(true);
            this.buyerOverView.getContractDueForRenewal(true);
            this.buyerOverView.getTopSavingOpportunity(true);
        }

        if (eventData.step === "1") {
            this.buyerOverView.getSummary(true);
            this.buyerOverView.getPurchases(true)
            this.buyerOverView.overView(true);
            this.buyerOverView.getGRNCount(true);
            this.buyerOverView.getTopPurchases(undefined, undefined, undefined, undefined, true);

            this.purchaseSupplierDelivery.getRejectionRate(true)
            this.purchaseSupplierDelivery.getFillRate(true)
            this.purchaseSupplierDelivery.getReorderItemsRejection(undefined, true)
        }

        if (eventData.step === "2") {
            this.buyerOverView.getSummary(true);
            this.buyerOverView.getGRNCount(true);
            this.buyerOverView.overView(true);
            this.buyerOverView.getGoodsReceivedButNotInvoiced(true);
            this.buyerOverView.getDisputes(true);
            this.buyerOverView.getTopSavingOpportunity(true);

            this.purchaseSupplierDelivery.getOnTimeDelivery(true)
            this.purchaseSupplierDelivery.getDefectRate(true)
            this.purchaseSupplierDelivery.supplierDelivery(true)
            this.purchaseSupplierDelivery.getUpcomingDelivery(true)
        }
        if (eventData.step === '3') {
            this.buyerOverView.getDisputes(true);
            this.buyerOverView.getAllOpenOrPartialInvoices(true);
        }
        if (eventData.step === '4') {
            this.sellerOverView.getAllOpenOrPartialInvoices(true);
        }
        this.buyerOverView.getAveragePurchaseCycle(true);
    }

    publishFinanceReports(eventData) {
        if (!eventData || eventData.step === null || eventData.step === undefined) {
            return;
        }

        this.financeOverview.getSalesPurchasesSummaryAmount(undefined, undefined, true);
        this.financeOverview.getDisputes(true);

        if (eventData.step === "0") {
            this.financeOverview.getSavings(true);
        }

        if (eventData.step === "1") {
            this.financeOverview.getSummary(true);
            this.financeOverview.getTopPayables(true);
            this.financeOverview.getTopReceivables(true);
            this.financeOverview.getTotalOrderVsTotalPayment(true)
        }

        if (eventData.step === "2") {
            this.financeOverview.getSummary(true);
            this.financeOverview.getInvoice(true);
            this.financeOverview.getSavings(true);
            this.financeOverview.getMonthlyPayables(true)
            this.financeOverview.getMonthlyReceivables(true)
            this.financeOverview.getOutstandingReceivableOrPayable(true);
            this.financeOverview.getAverageFinancePaymentCycle(true);
            this.financeOverview.getAverageFinanceReceivableCycle(true);
            this.financeOverview.getTotalInvoiceVsTotalCollections(true)
        }

        if (eventData.step === "3") {
            this.financeOverview.getSummary(true);
            this.financeOverview.getAverageFinancePaymenCycle(true);
            this.financeOverview.getAverageFinanceReceivableCycle(true);
            this.financeOverview.getUnreconciledPayment(true);
            this.financeOverview.getUnreconciledReceivable(true)
            this.financeOverview.getSavings(true);
            this.financeOverview.getMonthlyReceivables(true)
            this.financeOverview.getMonthlyPayables(true)
        }

        if (eventData.step === "4") {
            this.financeOverview.getSummary(true);
            this.financeOverview.getInvoice(true);
            this.financeOverview.getSavings(true);
            this.financeOverview.getMonthlyReceivables(true)
            this.financeOverview.getMonthlyPayables(true)
            this.financeOverview.getTotalInvoiceVsTotalCollections(true)
            this.financeOverview.getTotalOrderVsTotalPayment(true)
        }
    }
}
export default ReceiptEvents;