import AppUtils from '../app.utils';
import Caller from 'caller-id';
import DateUtils from '../util/timeutils';
import packageJson from '../../package.json';

class Console {

    static initLogger() {
        Console.isDesktop = AppUtils.isDesktop();
        if (!Console.isDesktop || Console.desktopLogger) {
            return;
        }
        Console.desktopLogger = window.require('electron-log');
        const os = window.require('os');
        const path = window.require('path');
        const fs = window.require('fs');
        const homedir = os.homedir();
        const tempHomeDir = path.join(homedir, ".param");
        const rootFolder = path.join(tempHomeDir, `${packageJson.DBVersion}`);
        let paramFolderPath = path.join(rootFolder, "logs");
        const mkdirSync = function (dir) {
            if (fs.existsSync(dir)) {
                return
            }

            try {
                fs.mkdirSync(dir)
            } catch (err) {
                if (err.code == 'ENOENT') {
                    mkdirSync(path.dirname(dir)) //create parent dir
                    mkdirSync(dir) //create dir
                }
            }
        }
        mkdirSync(paramFolderPath)
        Console.desktopLogger.transports.file.level = true //'info';
        let filePath = path.join(paramFolderPath, `${DateUtils.getLogDateFormat(new Date())}-param.log`);
        Console.desktopLogger.transports.file.file = filePath;
    }

    static log(...args) {
        let caller = Caller.getString(Console.log);
        if (!Console.isDesktop) {
            console.log(`[Log] ${caller} -`, ...args);
            return;
        }
        Console.desktopLogger.info(`${caller} -`, ...args);
    }

    static warn(...args) {
        let caller = Caller.getString(Console.warn);
        if (!Console.isDesktop) {
            console.warn(`[Warn] ${caller} -`, ...args);
            return;
        }
        Console.desktopLogger.warn(` ${caller} -`, ...args);
    }

    static info(...args) {
        let caller = Caller.getString(Console.info);
        if (!Console.isDesktop) {
            console.info(`[Info] ${caller} -`, ...args);
            return;
        }
        Console.desktopLogger.info(` ${caller} -`, ...args);
    }

    static error(...args) {
        let caller = Caller.getString(Console.error);
        if (!Console.isDesktop) {
            console.error(`[Error] ${caller} -`, ...args);
            return;
        }
        Console.desktopLogger.error(`${caller}`, ...args);
    }

    static debug(...args) {
        let caller = Caller.getString(Console.debug);
        if (!Console.isDesktop) {
            console.debug(`[Debug] ${caller} -`, ...args);
            return;
        }
        // Console.desktopLogger.debug(`${caller} -`,...args);
    }
}

export default Console;