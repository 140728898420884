import ParamConnector from '../../param-connector';
import * as Utils from '../utils';
import NetworkBridge from '.';
import TemplateCons from './template-cons';
import ECIES from '../ecies';
import NetworkUtils from './network-utils';
import * as DBUtils from '../../database/nosql/Utils/utils';
import Catalogue from './catalogue';
import VendorManager from './vendor-manager'
import Web3Utils from 'web3-utils';
import GraphQL from '../../param-libs/graph-ql';
import RestoreReceipts from '../../param-libs/graph-ql/sync/receipts';
import * as Config from '../../config.json'
import Analytics from '../../analytics/index'

class QualityManager {

    static getDocIdFromTxnHash(txnHash, receiptManager, event) {
        return NetworkUtils.getTransactionInfo(receiptManager.connection, txnHash).then(data => {
            if (event) {
                return receiptManager.parseNetworkLog(event, data)
            }
            return receiptManager.parseNetworkLog('onStatusUpdateV1', data)
        })

    }

    static attachTemplateToCreateDocument(txnHash, buyerId, sellerId, templateObj) {
        //temporary
        if (!templateObj) {
            return txnHash;
        }
        let { templateCons, step, txnType } = templateObj;
        return QualityManager.getConfig(buyerId, sellerId).then(config => {
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig(config);
            let receiptManager = paramNetwork.getReceiptManager(config.address);
            return QualityManager.getDocIdFromTxnHash(receiptManager, txnHash);
        }).then(docId => {
            let receiptId = docId["3"];
            let contractAddress = docId["0"];
            receiptId = receiptId + "-" + contractAddress;
            return QualityManager.getAttachTemplate(receiptId, templateCons, step, txnType)
        });
    }

    static getUserId(options, userId) {
        if (!userId) {
            userId = Utils.getParamId();
        }
        if (options && options.type && options.type.includes("anx")) {
            userId += "_" + options.type;
        }
        return userId;
    }
    static addReceipt(receiptId, jsonLd, options) {
        return QualityManager.getReceiptsGraphDB(options).addReceipt(receiptId, jsonLd, undefined, QualityManager.getUserId(options));
    }
    static updateReceipt(receiptId, jsonLd, options) {
        return QualityManager.getReceiptsGraphDB(options).updateReceipt(receiptId, jsonLd, undefined, QualityManager.getUserId(options));
    }

    static deleteReceipt(receiptId) {
        let options = {
            "type": "irn"
        }
        return QualityManager.getReceiptsGraphDB(options).deleteReceipt(receiptId);
    }

    static addBulkReceipts(receipts, options) {
        return QualityManager.getReceiptsGraphDB(options).addBulkReceipts(receipts, QualityManager.getUserId(options));
    }

    static getAllReceipts(owner, options, status, fromDate, toDate) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceipts(QualityManager.getUserId(options, owner), status, fromDate, toDate);
    }

    static getAllReceiptsByParent(parentDocId, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceiptsByParent(parentDocId);
    }
    static getAllReceiptsByParentV1(parentDocId, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceiptsByParentV1(parentDocId);
    }
    static getAllReceiptsByParentAndSubType(parentDocId, subType, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceiptsByParentAndSubType(parentDocId, subType);
    }

    static getAllReceiptsByStep(from, to, step, type, isLogistics, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceiptsByStep(from, to, step, type, isLogistics, undefined);
    }

    static getAllOrphanReceipts(paramId, role, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllOrphanReceipts(paramId, role);
    }
    static getAllCurrencies() {
        return QualityManager.getReceiptsGraphDB().getAllCurrencies();
    }
    static getParentDocId(childDocId, options) {
        return QualityManager.getReceiptsGraphDB(options).getParentDocId(childDocId);
    }

    static getValidationData(receiptId, step, options) {
        return QualityManager.getReceiptsGraphDB(options).getValidationData(receiptId, step);
    }
    static getSellerAndBuyer(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getSellerAndBuyer(receiptId);
    }

    static isDocumentRejected(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).isDocumentRejected(receiptId);
    }

    static getRootReceiptId(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getRootReceiptId(receiptId);
    }
    static getRootReceiptIdFromGrn(grnId, options) {
        return QualityManager.getReceiptsGraphDB(options).getRootReceiptIdFromGrn(grnId);
    }
    static getItemQuantitySummary(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getItemQuantitySummary(receiptId);
    }
    static doesChildExist(parentDocId, internalChildId, childStep, options) {
        return QualityManager.getReceiptsGraphDB(options).doesChildExist(parentDocId, internalChildId, childStep);
    }
    static getInvoiceFromPoAndInvoiceInternalId(poId, invoiceInternalId, options) {
        // return Promise.resolve(false);
        return QualityManager.getReceiptsGraphDB(options).getInvoiceFromPoAndInvoiceInternalId(poId, invoiceInternalId);
    }
    static getReceiptsGraphDB(options) {
        if (!options || !options.type) {
            return ParamConnector.getInstance().getDB().quality;
        }
        if (options.type === 'irn') {
            return ParamConnector.getInstance().getdatabase(options).receipts;
        }
        return ParamConnector.getInstance().getdatabase(options).receipts;
    }

    static getReceipt(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getReceipt(receiptId, QualityManager.getUserId(options));
    }

    static getReceiptByItem(itemId, step, options) {
        return QualityManager.getReceiptsGraphDB(options).getReceiptsByItem(itemId, step);
    }

    static getLinkedInvoices(receiptId) {
        return ParamConnector.getInstance().getDB().linkedInvoices.getLinkedInvoice(receiptId);
    }

    static getReceiptMetaData(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getReceiptMetaData(receiptId);
    }
    static getReceiptInfoByNode(receiptId, node, options) {
        return QualityManager.getReceiptsGraphDB(options).getReceiptInfoByNode(receiptId, node);
    }
    static getSummary(receiptId, nodes, options) {
        return QualityManager.getReceiptsGraphDB(options).getSummary(receiptId, nodes);
    }
    static getSummaryInBulk(receiptIds, nodes, options) {
        return QualityManager.getReceiptsGraphDB(options).getSummaryInBulk(receiptIds, nodes);
    }
    static getReceiptInternalId(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getReceiptInternalId(receiptId);
    }
    static search(searchString, owner, options) {
        return QualityManager.getReceiptsGraphDB(options).search(searchString, owner);
    }

    static filterReceiptsByDate(startDate, endDate, owner, options) {
        return QualityManager.getReceiptsGraphDB(options).filterReceiptsByDate(startDate, endDate, owner);
    }

    static getReceiptByIRN(irn, options) {
        return QualityManager.getReceiptsGraphDB(options).getReceiptByIRN(irn);
    }

    static getIrnByReceiptId(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getIrnByReceiptId(receiptId);
    }

    static getAllReceiptsWithIRNByOwner(owner, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceiptsWithIRNByOwner(QualityManager.getUserId(options, owner));
    }
    static getInvoicesByDisputeKey(owner, disputeKey, type, options) {
        return QualityManager.getReceiptsGraphDB(options).getInvoicesByDisputeKey(QualityManager.getUserId(options, owner), disputeKey, type);
    }

    static getAllReceiptIdsInPath(source, destination, receiptArray, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceiptIdsInPath(source, destination, receiptArray);
    }

    static editDisputedInfoAndState(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).editDisputedInfoAndState(receiptId);
    }
    static getAllItemsSummaryForReceipt(receiptId, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllItemsSummaryForReceipt(receiptId);
    }

    static getAllReceiptsByTypeForExport(type, step, options) {
        return QualityManager.getReceiptsGraphDB(options).getAllReceiptsByTypeForExport(type, step);
    }

    static getAttachedInvoices(creditNoteId, options) {
        return QualityManager.getReceiptsGraphDB(options).getAttachedInvoices(creditNoteId);
    }

    static getConfig(transactionInit, transactionReceiver) {
        return Utils.getConfig("ParamContract");
        let graphDB = ParamConnector.getInstance().getDB();
        let ePhoneBook = graphDB.ePhoneBook;
        let promiseArray = [ePhoneBook.isEnterpriseNode(transactionInit), ePhoneBook.isEnterpriseNode(transactionReceiver)]
        return Promise.all(promiseArray).then(result => {
            //Non enterprise nodes.
            if (!result || (!result[0] && !result[1])) {
                // return Utils.getConfig("ParamContract");
                return Promise.resolve(Utils.getConfig("ParamContract"));
            }
            return QualityManager.getConfigPrivateFor(graphDB, transactionInit, transactionReceiver, "ParamContract")
        })
    }

    static getConfigPrivateFor(graphDB, transactionInit, transactionReceiver, contractType) {
        return graphDB.versionControl.getNodePublicFor(transactionInit, transactionReceiver, contractType).then(privateForInfo => {
            //hard coded index 0
            let promiseArray = [];
            promiseArray.push(privateForInfo)
            for (let contractId in privateForInfo) {
                for (let index in privateForInfo[contractId][0]) {
                    promiseArray.push(graphDB.ePhoneBook.getNodeDetailsById(privateForInfo[contractId][0][index]));
                }
            }
            return Promise.all(promiseArray)
        }).then(res => {
            let privateForInfo = res[0];
            res.shift(1);
            let nodeInfos = res;
            let contractRootAddress = Object.keys(privateForInfo);
            contractRootAddress = contractRootAddress[0];
            return Promise.all([graphDB.versionControl.getLatestContractByRoot(contractRootAddress), nodeInfos]);
        }).then(([contractAddress, nodeInfos]) => {
            let config = JSON.parse(nodeInfos[0].config);
            config.address = contractAddress;
            config.privateFor = [nodeInfos[0].organizationId, nodeInfos[1].organizationId];
            console.log(contractAddress, nodeInfos)
            return config;
        })
    }

    static isEnterpriseNode(paramId) {
        let graphDB = ParamConnector.getInstance().getDB();
        return graphDB.ePhoneBook.isEnterpriseNode(paramId);
    }

    static getPrivateFor(sellerId, buyerId) {
        let graphDB = ParamConnector.getInstance().getDB();
        return graphDB.versionControl.getNodePublicFor(sellerId, buyerId, "ParamContract");
    }

    static encryptJsonLd(jsonLd, receiptId, receiptManager, extendedKnowledge, isDirectPO) {
        let privateKey = Utils.getPrivateKey();
        let receiptKey, rootId;
        jsonLd = JSON.parse(jsonLd);
        let publicKey = jsonLd.customer.publicKey;
        if (isDirectPO) {
            publicKey = jsonLd.provider.publicKey;
        }
        if (jsonLd.transactionType !== '2') {
            return Promise.resolve({ receiptJson: JSON.stringify(jsonLd), extendedKnowledge: extendedKnowledge, rootDetails: { receiptKey, rootId } });
        }
        jsonLd = JSON.stringify(jsonLd);
        if (receiptId) {
            return QualityManager.getRootReceiptId(receiptId).then(res => {
                let transactionData = Utils.getTransactionData(res);
                rootId = res
                return receiptManager.getReceipt(res); //transactionData.id
                // }).then(receiptData => {
                //     return DBUtils.getFromIpfsHash(receiptData.jsonLd);
            }).then(receipt => {
                let cipherText = receipt.jsonLD;
                publicKey = ECIES.getPublicKey(cipherText);
                receiptKey = ECIES.getReceiptKey(privateKey, cipherText);
                if (extendedKnowledge) {
                    extendedKnowledge = ECIES.encrypt(privateKey, publicKey, extendedKnowledge);
                }
                let receiptJson = ECIES.encrypt(privateKey, publicKey, jsonLd, receiptKey);
                return { receiptJson: receiptJson, extendedKnowledge: extendedKnowledge, rootDetails: { receiptKey, rootId } };
            })
        }
        else {
            let receiptJson = ECIES.encrypt(privateKey, publicKey, jsonLd, receiptKey);
            receiptKey = ECIES.getReceiptKey(privateKey, receiptJson);
            return Promise.resolve({ receiptJson: receiptJson, extendedKnowledge: extendedKnowledge, rootDetails: { receiptKey, rootId } });
        }

    }

    static getTimeline(receiptId) {
        let graphDb = ParamConnector.getInstance().getDB();
        let transactionsDB = graphDb.transactionsDB;
        let receiptIds = [];
        return graphDb.receipts.getAllReceiptIdsInTree(receiptId, receiptIds).then(res => {
            receiptIds = res;
            return graphDb.receipts.getRootReceiptId(receiptId);
        }).then((rootReceiptId) => {
            return QualityManager.getAllReceiptIdsInPath(receiptId, rootReceiptId, []);
        }).then((res) => {
            receiptIds = [...receiptIds, ...res];
            let promise, promiseArray = [];
            let result = [];
            for (let index in receiptIds) {
                promise = transactionsDB.getTransactions(receiptIds[index]).then(transactions => {
                    let formattedTransactions = [];
                    for (let index in transactions) {
                        const transactionEvent = transactions[index] ? transactions[index].event : "";
                        if (transactionEvent === "onSubscriber" || transactionEvent === "onSubscriberReceiptUpdateV1") {
                            continue;
                        }
                        formattedTransactions.push(transactions[index]);
                    }
                    let promiseArray = [];
                    // let transactions = receiptData.transactions;
                    promiseArray = formattedTransactions;
                    promiseArray.splice(0, 0, QualityManager.getWorkflowTimeline(receiptIds[index]));
                    return Promise.all(promiseArray);
                }).then(result => {
                    let workflowSteps = result.shift();
                    if (workflowSteps.templateInfo.length !== 0)
                        result = result.concat(workflowSteps);
                    result = [].concat.apply([], result);
                    result.sort(function (object1, object2) {
                        return object1.blockNumber - object2.blockNumber;
                    });
                    return result

                }).then(res => {
                    result = result.concat(res);
                    return result;
                })
                // .then(res => {
                //     return res;
                // });
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray).then(() => {
                return result;
            });
        });

    }

    static getWorkflowTimeline(receiptId) {
        return ParamConnector.getInstance().getDB().templateConsensusDB.getTemplateConsensusByDocId(receiptId).then(result => {
            let promiseArray = [];
            if (!result || !result.templateConsensusId || result.templateConsensusId.length == 0) {
                return Promise.all(promiseArray);
            }
            for (let index in result.templateConsensusId) {
                promiseArray.push(
                    TemplateCons.getTimeline(result.templateConsensusId[index])
                )
            }
            return Promise.all(promiseArray);
            // return TemplateCons.getTimeline(result.templateConsensusId[result.templateConsensusId.length - 1]);
        }).then((result => {
            return { receiptId, "templateInfo": result }
        }));
    }

    static assignTemplateReceipt(templateConsId, receiptId, contractAddress, step) {
        // let transactionData = Utils.getTransactionData(receiptId);
        let receiptManager = GraphQL.getInstance().receipts;
        return receiptManager["assignTemplate"](templateConsId, receiptId, contractAddress, step);
        // return Utils.getConfig("ParamContract", true).then(config => {
        //     let options = Utils.getNetworkOptions();
        //     let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
        //     paramNetwork.setConfig({ receipt: transactionData.address });
        //     let receiptManager = paramNetwork.getReceiptManager();
        //     return receiptManager["assignTemplate"](templateConsId, transactionData.id, contractAddress, step, options);
        // })
    }

    static getTransactionFromNetwork(receiptId) {
        let transactionData = Utils.getTransactionData(receiptId);
        return Utils.getConfig("ParamContract", true).then(config => {
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig({ receipt: transactionData.address });
            let receiptManager = paramNetwork.getReceiptManager();
            return Promise.all([receiptManager["getTransactions"](transactionData.id), receiptManager])
        }).then(([txns, receiptManager]) => {
            let promiseArray = [];
            for (let index in txns) {
                promiseArray.push(receiptManager['getTransaction'](txns[index]));
            }
            return Promise.all(promiseArray);
        }).then(res => {
            let statusToNote = {};
            for (let index in res) {
                statusToNote[`${res[index][5]}_note`] = res[index][6];
            }
            console.log(statusToNote);
            return statusToNote;
        })
    }

    static sendInvoice(receiverEmail, receiptDetails, emailType) {
        let emailManager = NetworkBridge.getEmailManager();
        let jsonLd = {
            email: receiverEmail,
            emailType: emailType,
            receipt: receiptDetails
        }
        jsonLd = JSON.stringify(jsonLd);
        jsonLd = btoa(jsonLd);
        return emailManager.sendMail("2", jsonLd, "");
    }

    static getReceiptRootIdFromNetwork(receiptId, receiptManager) {
        let contractAddress = Utils.getTransactionData(receiptId).address
        return receiptManager.getReceiptChild(receiptId).then(res => {
            let pId = res.pId;
            if (pId === "0x0000000000000000000000000000000000000000000000000000000000000000") {
                return receiptId;
            }
            pId = Utils.getTransactionId(pId, contractAddress)
            return this.getReceiptRootIdFromNetwork(pId, receiptManager);
        })

        // return receiptManager.getReceiptChild(receiptId, receiptManager).then(res => {
        //     let pId = res.pId;
        //     if (pId === "0x0000000000000000000000000000000000000000000000000000000000000000") {
        //         return receiptId;
        //     }
        //     return this.getReceiptRootIdFromNetwork(pId, receiptManager);
        // })
    }

    static getAllReceiptIdsInTree(receiptId, receiptManager) {
        return QualityManager.getReceiptRootIdFromNetwork(receiptId, receiptManager).then(rootId => {
            let receiptIds = [Utils.getTransactionData(receiptId).id];
            return QualityManager.getTree(rootId, receiptManager, receiptIds).then((res) => {
                console.log("res", res);
                receiptIds = [].concat.apply([], receiptIds)
                return { receiptIds, rootId: receiptId };
            })
        })
    }

    static getTree(receiptId, receiptManager, returnResult) {
        console.log("receiptId", receiptId)
        let contractAddress = Utils.getTransactionData(receiptId).address
        return receiptManager.getReceiptChild(receiptId).then((node) => {
            // delete node[0];
            // delete node['pId'];
            let promiseArray = [];
            for (let index in node.docMapIds) {
                let _receiptId = Utils.getTransactionId(node.docMapIds[index], contractAddress)
                let promise = QualityManager.getTree(_receiptId, receiptManager, returnResult)
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray).then((children) => {
                returnResult.push(node.docMapIds);
                node.docMapIds = children;
                console.log('children', children);
                return node;
            })
        });
    }

    static getTimelineV1(receiptId) {
        receiptId = receiptId.split("-")[0]
        let receiptManager;
        return QualityManager.getConfig().then(config => {
            let paramNetwork = ParamConnector.getInstance(config).getNetwork(config);
            paramNetwork.setConfig(config);
            receiptManager = paramNetwork.getReceiptManager(config.address);
            return this.getAllReceiptIdsInTree(receiptId, receiptManager)
        }).then(data => {
            let ProArray = [];
            data.receiptIds.forEach(element => {
                ProArray.push(receiptManager.getAllEventsByReceiptId(element));
            });
            return Promise.all(ProArray);
        }).then(data => {
            let res = [];
            data.forEach(element => {
                element.forEach(rData => {
                    let formatedTimelineInfo = QualityManager.getFormatedTimelineData(data, rData)
                    res.push(formatedTimelineInfo);
                });
            })
            return Promise.all(res);
        })
    }
    static getFormatedTimelineData(data, rData) {
        const stepData = ["INIT_PROPOSAL", "CREATE_PO", "SEND_INVOICE", "MAKE_PAYMENT", "CREATE_RECEIPT", "GRN_ATTACHED"];
        const docData = ["GRN", "NOTE"]
        let pId = rData.args.pId;
        let rId = rData.args.rId;
        let aRes = {
            "pDocId": pId,
            "currentDocId": rId,
            "childrens": [
            ],
            "blockInfo": {
                "number": rData.blockNumber,
                "txHash": rData.transactionHash,
                "created": rData.dateAndTime
            },
            "seller": rData.args.seller,
            "buyer": rData.args.buyer,
            "step": rData.args.step,
            "status": rData.args.status,
            "event": rData.event,
            "docType": rData.args.step ? stepData[rData.args.step] : docData[rData.args.docType],
            "contractAddress": rData.contractAddress,
            "docId": rData.args.docId
        }
        aRes.childrens = QualityManager.getChild(data, rData.args.rId);
        console.log(rData.from)
        return VendorManager.getContactSummaryByParamId(Web3Utils.toChecksumAddress(rData.from)).then(contact => {
            aRes["from"] = { "address": rData.from, contactName: contact.name }
            return aRes;
        })
    }
    static getChild(data, rId) {
        let cRes = [];
        data.forEach(element => {
            element.forEach(rData => {
                if (rId == rData.args.pId) {
                    cRes.push(rData.args.rId);
                }
            });
        });
        return cRes;
    }

    //V1 functionality support metaData (edit option in business approval)
    static stateGraphV1(receiptID, state, subState, customer, owner, jsonLd, parentDocId) {
        let transactionData = Utils.getTransactionData(receiptID)
        let docID = transactionData.id
        let contractAddress = transactionData.address
        //Currently going with public transactions
        jsonLd.transactionType = "1"
        let txnType = jsonLd.transactionType
        jsonLd = JSON.stringify(jsonLd)
        let receiptManager = GraphQL.getInstance()

        return this.encryptJsonLd(jsonLd, parentDocId, receiptManager, null, true, true).then(res => {
            let inputObject = {}
            if (txnType !== "2") {
                inputObject.data = res.receiptJson
            }
            else {
                inputObject.data = res.receiptJson
            }
            txnType = Utils.getTxnTypeForGraphQL(jsonLd.transactionType)
            return GraphQL.getInstance().stateGraphV1(docID, contractAddress, state, subState, customer, inputObject, txnType)
        }).then(res => {
            let signedData = Utils.codeSignPaylaod(JSON.parse(res))
            return GraphQL.getInstance().sendRawTxn(signedData)
        }).catch(err => {
            console.log("error in stateGraphV1, Reason: ", err)
        })
    }

}

export default QualityManager;