class FormatData {
    static replaceAll(target, search, replacement) {
        return target.replace(new RegExp(search, 'g'), replacement);
    }
    static removeAngular(string) {
        if (!string) {
            return string;
        }
        if (typeof string == "string")
            if (string.charAt(0) === '<' && string.charAt(string.length - 1) === '>') {
                return string.substring(1, string.length - 1);
            }
        return string;
    }
    static replaceWithPrimaryKey(data) {
        let object = JSON.stringify(data);
        object = object.replace(/"(@id)"\s*:\s*"([\W]*[^,|}]*)"/ig, '"primaryKey":"<$2>","@id":"$2"');
        return JSON.parse(object);
    }
}

export default FormatData;