import * as Utils from '../../../../util/utils';
import NetworkBridge from '../../../../util/network-bridge';
import ParseTimelineData from './ParseTimelineData';
import Wrapper from '../../create/Helper/wrapper';
import { logisticsTemplate } from '../../Setting/config/bosch-receipt'
import { debitAdviceTemplate } from '../../Setting/config/debit-advice'
import * as Config from "../../../../config.json"
class ReceiptUtils {
    static loadTimelineData(id) {
        let timelineArr = [];
        let skippedSteps = 0;
        let receiptManager = NetworkBridge.getReceiptManager();
        for (let index = 0; index < 5; index++) {
            let timelineObj = {}
            timelineObj["stepName"] = Utils.fetchStatus(index.toString());
            timelineObj["step"] = index.toString();
            timelineObj["status"] = null;
            timelineObj["block"] = null;
            timelineObj["timestamp"] = null;
            timelineObj["signer"] = null;
            timelineObj["showCross"] = false;
            timelineArr.push(timelineObj);
        }
        return receiptManager.getTimeline(id).then(timelineData => {
            timelineData.transactions = Utils.getArrayOfData(timelineData);
            console.log("testtt", timelineData.transactions)
            console.log("RECEIPT DATA", timelineData.transactions);
            if (timelineData.transactions && timelineData.transactions.length) {
                for (let index = 0; index < timelineData.transactions[0].step; index++) {
                    timelineArr[index]["showCross"] = true;
                    skippedSteps++;
                }
            }
            for (let i = 0; i < timelineData.transactions.length; i++) {
                let timelineObj = {
                    "stepName": Utils.fetchStatus(timelineData.transactions[i].step),
                    "step": timelineData.transactions[i].step,
                    "block": timelineData.transactions[i] ? timelineData.transactions[i].blockNumber : null,
                    "timestamp": timelineData.transactions[i] ? timelineData.transactions[i].time : null,
                    "signer": timelineData.transactions[i] ? timelineData.transactions[i].sender : null,
                    "status": timelineData.transactions[i] ? timelineData.transactions[i].status : null,
                    "index": timelineData.transactions[i] ? timelineData.transactions[i].index : null,
                    "showCross": false
                }
                if (timelineObj.step === undefined) {
                    timelineObj["stepName"] = timelineData.transactions[i].title ? timelineData.transactions[i].title : "Request For Consent";
                    if (timelineObj.status)
                        timelineObj["transInfo"] = timelineData.transactions[i].transInfo;
                    if (timelineData && timelineData.transactions[i]) {
                        let event = timelineData.transactions[i].event;
                        // if (event === 'onStepAssinged' || event === 'onStepSigned' || event === 'onTemplateCompleted' || event === 'onSubscriberAdded' || event === 'onTemplateAttached') {
                        if (event === 'onStepSigned' || event === 'onTemplateAttached') {
                            timelineObj['document'] = 'View Document';
                            timelineObj['id'] = timelineData.transactions[i]['@id'];
                        }
                    }
                    timelineArr.splice(i + skippedSteps, 0, timelineObj);
                }
                else {
                    let indexToInserData = ReceiptUtils.getSearchedIndex(timelineArr, timelineData.transactions[i].step);
                    // timelineArr[timelineData.transactions[i].step] = timelineObj;
                    if (indexToInserData) {
                        timelineArr[indexToInserData] = timelineObj;
                    }

                }
            }
            return Promise.all([timelineArr, timelineData.transactions.length + skippedSteps]);
        })
    }

    static getSearchedIndex = (arr, step) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].step == step) {
                return i.toString();
            }
        }
        return null;
    }

    static getTimelineTree = (receiptId, receiptSummary, currentTab) => {
        let receiptManager = NetworkBridge.getReceiptManager();
        let grnManager = NetworkBridge.getGRNManager();
        let timelineData, unformattedTimelineData;
        let rootReceiptIdPromise;
        if (currentTab === "grn") {
            rootReceiptIdPromise = receiptManager.getRootReceiptIdFromGrn(receiptId);
        }
        else {
            rootReceiptIdPromise = Promise.resolve(receiptId);
        }
        return rootReceiptIdPromise.then((rootReceiptId) => {
            return receiptManager.getTimeline(rootReceiptId)
        }).then((unformattedTimeline) => {
            console.log("UnformattedTimeline", unformattedTimeline);
            unformattedTimelineData = unformattedTimeline;
            let grnTimelinePromise = [];
            unformattedTimelineData.forEach((timelineData) => {
                if (timelineData.docId && timelineData.docId.length > 0) {
                    timelineData.docId.forEach((grnId) => {
                        grnTimelinePromise.push(grnManager.getTimeline(grnId));
                    })
                }
            })
            return Promise.all(grnTimelinePromise)
        }).then((grnTimelineData) => {
            console.log("GRN TimelineData", grnTimelineData);
            grnTimelineData.forEach((data) => {
                unformattedTimelineData.push(...data);
            })
            unformattedTimelineData = Utils.getUnique(unformattedTimelineData, '@id');
            if (currentTab === "grn") {
                let invoiceId = Utils.getInvoiceId(unformattedTimelineData, receiptId);
                timelineData = ParseTimelineData.getFormattedTimeline(unformattedTimelineData, receiptSummary.step, invoiceId);
            } else {
                timelineData = ParseTimelineData.getFormattedTimeline(unformattedTimelineData, receiptSummary.step, receiptId);
            }
            return receiptManager.getWorkflowTimeline(receiptId);
        }).then((res) => {
            if (res) {
                timelineData = ParseTimelineData.getTemplateAttached(res, timelineData);
            }
            return timelineData;
        })
    }

    static viewTemplate(data, template) {
        let accountSettings = Utils.getFromLocalStorage('settings')[Utils.getFromLocalStorage("param_id")]
        let ejs = require('ejs');
        let str = template || Utils.getDocumentTemplate(data.currentTab)
        let shouldUseAccountSettings = Config["customSettings"] ? accountSettings.creditNoteTemplate : true
        if (shouldUseAccountSettings && data.currentTab === 'draftinvoices')
            str = logisticsTemplate
        if (shouldUseAccountSettings && data.currentTab === 'debitadvice')
            str = debitAdviceTemplate
        let html = ejs.render(str, data);
        return html;
    }

    static getContractRootId(nameSpace) {
        let rootId, contractId;
        const receiptData = nameSpace.state.receiptData;
        let receiptId = receiptData["@id"];
        let refManager = Wrapper.getRefManager();
        if (receiptData['@subType'] === "returnRequest" || receiptData['@subType'] === "returnOrder") {
            refManager = Wrapper.getRefManager(receiptData['@subType']);
        }
        let nextTab = Utils.getAttachedDocumentText(nameSpace.state.currentTab, receiptData['@subType'])
        if (nextTab === "Grn" || nextTab === "An") {
            receiptId = receiptData.poId;
        }
        return refManager.getRootReceiptId(receiptId).then(res => {
            rootId = res;
            return refManager.getReceiptInfoByNode(rootId, 'step').then(step => {
                if (step === '0') {
                    return rootId;
                }
                return;
            }).then((res) => {
                let promiseArray = [];
                contractId = res;
                if (receiptData['@subType'] !== "returnOrder") {
                    refManager = Wrapper.getRefManager();
                }
                promiseArray.push(
                    refManager.getReceiptInternalId(contractId),
                    refManager.getReceiptInternalId(receiptData.parentDocId),
                    refManager.getReceiptInternalId(receiptId)
                )
                return Promise.all(promiseArray);
            }).then(([contractInternalId, parentInternalDocId, receiptInternalId]) => {
                nameSpace.setState({ contractId, contractInternalId, parentInternalDocId, receiptInternalId });
            })
        })
    }

}

export default ReceiptUtils;