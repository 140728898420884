import { gql } from '@apollo/client';
import * as NetworkUtils from '../../../../src/util/utils';
import * as DBUtils from '../../../database/nosql/Utils/utils'

class VendorManagement {

    constructor(apolloClientConnector) {
        this.apolloClientConnector = apolloClientConnector;
    }

    getAllContacts = (address, startIndex = 0, pageSize = 10) => {
        let queryOptions = {
            query: gql`{
                getAllContacts(startIndex:${startIndex},pageSize:${pageSize}){
                    totalRecords,
                    result
                    # currentPage
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let contactResult = {
                contacts: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                return contactResult
            }
            contactResult["contacts"] = graphQLResult.data["result"]
            contactResult["count"] = graphQLResult.data["totalRecords"]
            return contactResult;
        })
    }

    getAllBuyers = (vendorAddress, startIndex = 0, pageSize = 10) => {
        // return {"invitations": [], "count": 1  }
        let queryOptions = {
            query: gql`{
                    getAllCustomers(startIndex:${startIndex},pageSize:${pageSize}){
                      result,
                      totalRecords
                    }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let buyerResult = {
                invitations: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                return buyerResult
            }
            buyerResult["invitations"] = graphQLResult.data["result"]
            buyerResult["count"] = graphQLResult.data["totalRecords"]
            return buyerResult;
        })

    }

    getAllCountByAddressAndIdentifier = (address, identifier) => {
        // return count;
    }

    getAllVendors = (buyerAddress, startIndex = 0, pageSize = 10) => {
        // return { "invitations": [], "count": 1 }
        let queryOptions = {
            query: gql`{
                    getAllSuppliers(startIndex:${startIndex},pageSize:${pageSize}){
                      result,
                      totalRecords
                    }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let vendorResult = {
                invitations: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                return vendorResult
            }
            vendorResult["invitations"] = graphQLResult.data["result"]
            vendorResult["count"] = graphQLResult.data["totalRecords"]
            return vendorResult;
        })
    }

    /**
     * 
     * @param {*} templateType - "0" - customer template
     *                           "1" - supplier template   
     */
    getAllInvitationTemplate = (owner, templateType, startIndex = 0, pageSize = 10) => {
        // return {   "count": 0, "invitationTemplates": [] }
        let queryOptions = {
            query: gql`{
                getAllInvitationTemplate(filterOptions:{dbSource:OFF_CHAIN,startIndex:${startIndex},pageSize:${pageSize}}){
                    totalRecords,
                    IDs
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let allInvitationTemplateResult = {
                invitationTemplates: [],
                count: 0
            }
            if (!graphQLResult || !graphQLResult.data) {
                return allInvitationTemplateResult
            }
            allInvitationTemplateResult["invitationTemplates"] = graphQLResult.data["getAllInvitationTemplate"].IDs
            allInvitationTemplateResult["count"] = graphQLResult.data["getAllInvitationTemplate"].totalRecords
            return allInvitationTemplateResult;
        })
    }

    getVendorByAddress = (vendor) => {
    }

    getBuyerByAddress = (buyer) => {
    }

    getContactSummaryByParamId = (paramId) => {
        return {
            "contactId": "",
            "contactType": "",
            "email": "",
            "identifier": "",
            "name": "",
            "organisation": "",
            "owner": "",
            "publicKey": "",
            "status": "",
            "telephone": "",
        }
    }

    getContactSummary = (contactId) => { //contactId =  invitationId
        return {
            "contactId": "",
            "contactType": "",
            "email": "",
            "identifier": "",
            "name": "",
            "organisation": "",
            "owner": "",
            "publicKey": "",
            "status": "",
            "telephone": "",
        }
    }
    getContactByParamId = (paramId) => {
        return {
            "contactId": "",
            "contactType": "",
            "email": "",
            "identifier": "",
            "name": "",
            "organisation": "",
            "owner": "",
            "publicKey": "",
            "status": "",
            "telephone": "",
        }
    }

    getContact = (invitationId) => {
        let queryOptions = {
            query: gql`{
                    getInvitation(invitationId:${invitationId}){
                        invitationID,
                        templateID,
                        status,
                        step,
                        initatorJSONLD,
                        receiverJSONLD,
                        buyerID,
                        vendorID,
                        owner,
                        txnMode,
                        evalRequest,
                        shareKey,
                        templateType
                   }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let invitationResult;
            if (!graphQLResult || !graphQLResult.data) {
                return invitationResult
            }
            invitationResult = VendorManagement.getContactTemplateSummary(graphQLResult.data["getInvitation"].templateID)
            let formJsonLd = {
                cardsInvolved: JSON.parse(graphQLResult.data["getInvitation"].initatorJSONLD).cardsInvolved
            }
            let publicKey = JSON.parse(graphQLResult.data["getInvitation"].initatorJSONLD).publicKey
            invitationResult["@type"] = ""
            invitationResult["recordAdded"] = ""
            invitationResult["publicKey"] = publicKey
            invitationResult["@id"] = graphQLResult.data["getInvitation"].templateID
            invitationResult["status"] = graphQLResult.data["getInvitation"].status
            invitationResult["step"] = graphQLResult.data["getInvitation"].step
            invitationResult["owner"] = graphQLResult.data["getInvitation"].owner
            invitationResult["shareKey"] = graphQLResult.data["getInvitation"].shareKey
            invitationResult["buyerAddress"] = graphQLResult.data["getInvitation"].buyerID
            invitationResult["vendorAddress"] = graphQLResult.data["getInvitation"].vendorID
            invitationResult["identifier"] = graphQLResult.data["getInvitation"].owner
            invitationResult["templateType"] = graphQLResult.data["getInvitation"].templateType
            invitationResult["formJsonLd"] = formJsonLd
            return invitationResult;
        })
    }

    getContactTemplateSummary = (templateId) => {
        let queryOptions = {
            query: gql`{
                getInvitationTemplate(templateId:${templateId}){
                    templateID,
                    name,
                    jsonLd,
                    metaData,
                    owner,
                    txnMode,
                    isEnabled,
                    isPublic,
                    templateType
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let summaryResult;
            if (!graphQLResult || !graphQLResult.data) {
                return summaryResult
            }
            let jsonLd = JSON.parse(graphQLResult.data["getInvitationTemplate"].jsonLd)
            summaryResult["templateId"] = graphQLResult.data["getInvitationTemplate"].templateID
            summaryResult["templateName"] = graphQLResult.data["getInvitationTemplate"].name
            summaryResult["templateType"] = graphQLResult.data["getInvitationTemplate"].templateType
            summaryResult["templateDescription"] = jsonLd.description
            summaryResult["cardsInvolved"] = jsonLd.cardsInvolved
            summaryResult["invitationType"] = graphQLResult.data["getInvitationTemplate"].templateType
            return summaryResult
        })
    }

    getInvitationProfile = (invitationId) => {
        let queryOptions = {
            query: gql`{
                    getInvitation(invitationId:"${invitationId}"){
                        invitationID,
                        templateID,
                        status,
                        step,
                        initatorJSONLD,
                        receiverJSONLD,
                        buyerID,
                        vendorID,
                        owner,
                        txnMode,
                        evalRequest,
                        shareKey,
                        templateType
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let invitationResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return invitationResult
            }
            // receiverJsonLd = res;
            invitationResult.owner = graphQLResult.data["getInvitation"].owner
            invitationResult.vendorAddress = graphQLResult.data["getInvitation"].vendorID
            invitationResult.buyerAddress = graphQLResult.data["getInvitation"].buyerID
            invitationResult.txnMode = NetworkUtils.convertGraphQLTnxType(graphQLResult.data["getInvitation"].txnMode)
            invitationResult.step = graphQLResult.data["getInvitation"].step.toString()
            invitationResult.status = graphQLResult.data["getInvitation"].status.toString()
            invitationResult.shareKey = graphQLResult.data["getInvitation"].shareKey
            invitationResult.templateId = graphQLResult.data["getInvitation"].templateID
            invitationResult.initiatorJsonLd = graphQLResult.data["getInvitation"].initatorJSONLD
            invitationResult.receiverJsonLd = graphQLResult.data["getInvitation"].receiverJSONLD
            return invitationResult;
        })
    }

    // getInvitaionTemplate = (templateId) => {
    //     let queryOptions = {
    //         query: gql`{
    //             getInvitationTemplate(templateId:${templateId}){
    //                 templateID,
    //                 name,
    //                 jsonLd,
    //                 metaData,
    //                 owner,
    //                 txnMode,
    //                 isEnabled,
    //                 isPublic,
    //                 templateType
    //             }
    //         }`
    //     }
    //     return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
    //         let invitationTemplateResult;
    //         if (!graphQLResult || !graphQLResult.data) {
    //             return invitationTemplateResult
    //         }
    //         invitationTemplateResult = JSON.parse(graphQLResult.data["getInvitationTemplate"].jsonLd)
    //         invitationTemplateResult["sections"] = invitationTemplateResult.cardsInvolved.length
    //         invitationTemplateResult["owner"] = graphQLResult.data["getInvitationTemplate"].owner
    //         invitationTemplateResult["enabled"] = graphQLResult.data["getInvitationTemplate"].isEnabled
    //         invitationTemplateResult["templateId"] = graphQLResult.data["getInvitationTemplate"].templateID
    //         invitationTemplateResult["templateType"] = graphQLResult.data["getInvitationTemplate"].templateType
    //         return invitationTemplateResult;
    //     })
    // }

    getInvitationTemplate = (templateId) => {
        let queryOptions = {
            query: gql`{
                getInvitationTemplate(templateId:"${templateId}"){
                    templateID,
                    name,
                    jsonLd,
                    metaData,
                    owner,
                    txnMode,
                    isEnabled,
                    isPublic,
                    templateType
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let invitationTemplateResult = {};
            if (!graphQLResult || !graphQLResult.data) {
                return invitationTemplateResult
            }
            invitationTemplateResult = Object.assign({}, graphQLResult.data["getInvitationTemplate"])
            invitationTemplateResult['txnMode'] = NetworkUtils.convertGraphQLTnxType(invitationTemplateResult.txnMode)
            return invitationTemplateResult
        });
    }

    getInvitaionTemplateSummary = (templateId) => {
        let queryOptions = {
            query: gql`{
                getInvitationTemplate(templateId:${templateId}){
                    templateID,
                    name,
                    jsonLd,
                    metaData,
                    owner,
                    txnMode,
                    isEnabled,
                    isPublic,
                    templateType
                }
            }`
        }
        return this.apolloClientConnector.query(queryOptions).then(graphQLResult => {
            let invitationTemplateResult = []
            if (!graphQLResult || !graphQLResult.data) {
                return invitationTemplateResult
            }
            invitationTemplateResult = JSON.parse(graphQLResult.data["getInvitationTemplate"].jsonLd)
            invitationTemplateResult["sections"] = invitationTemplateResult.cardsInvolved.length
            invitationTemplateResult["owner"] = graphQLResult.data["getInvitationTemplate"].owner
            invitationTemplateResult["enabled"] = graphQLResult.data["getInvitationTemplate"].isEnabled
            invitationTemplateResult["templateId"] = graphQLResult.data["getInvitationTemplate"].templateID
            invitationTemplateResult["templateType"] = graphQLResult.data["getInvitationTemplate"].templateType
            return invitationTemplateResult;
        })
    }

    getEvalRequestStatus = (documentId) => {

    }

    getContactNameByParamId = (paramId) => {
        return ""
    }

    getAllVendorsByStatus = (buyerId, status, startIndex = 0, pageSize = 10) => {
        let options = {
            query: gql`
            query getAllVendorsByStatus($filterOptions: InputFilterOptions!) {
                getAllVendorsByStatus(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: buyerId } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getAllVendorsByStatus
        })
    }
    getEvalRequest = (evalRequestId) => {
        let options = {
            query: gql`
            query getEvalRequest($evalRequestId: String!) {
                getEvalRequest(evalRequestId: $evalRequestId){
                    contractAddress,
                    documentId,
                    invitationId,
                    evalRequestId
                }
            }
            `,
            variables: { evalRequestId }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            return graphQLResult.data.getEvalRequest
        })
    }

    getAllEvalRequestsByInvitation = (invitationId, startIndex = 0, pageSize = 10, owner) => {
        let totalRecords = []
        let options = {
            query: gql`
            query getAllEvalRequestsByInvitation($filterOptions: InputFilterOptions!) {
                getAllEvalRequestsByInvitation(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, id: invitationId } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllEvalRequestsByInvitation['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllEvalRequestsByInvitation.totalRecords) {
                return totalRecords
            }
            return this.getAllEvalRequestsByInvitation(invitationId, startIndex + 1, pageSize)
        })
    }

    getAllVendorsByBuyer = (buyer, startIndex = 0, pageSize = 10) => {
        let totalRecords = []
        let options = {
            query: gql`
            query getAllVendorsByBuyer($filterOptions: InputFilterOptions!) {
                getAllVendorsByBuyer(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: buyer } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllVendorsByBuyer['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllVendorsByBuyer.totalRecords) {
                return totalRecords
            }
            return this.getAllVendorsByBuyer(buyer, startIndex + 1, pageSize)
        })
    }
    getAllInvitationsByBuyer = (buyer, startIndex = 0, pageSize = 10) => {
        let totalRecords = []
        let options = {
            query: gql`
            query getAllInvitationsByBuyer($filterOptions: InputFilterOptions!) {
                getAllInvitationsByBuyer(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: buyer } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllInvitationsByBuyer['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllInvitationsByBuyer.totalRecords) {
                return totalRecords
            }
            return this.getAllInvitationsByBuyer(buyer, startIndex + 1, pageSize)
        })
    }
    getAllInvitationsByVendor = (vendor, startIndex = 0, pageSize = 10) => {
        let totalRecords = []
        let options = {
            query: gql`
            query getAllInvitationsByVendor($filterOptions: InputFilterOptions!) {
                getAllInvitationsByVendor(filterOptions: $filterOptions){
                    totalRecords,
                    currentPageSize,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner: vendor } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllInvitationsByVendor['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllInvitationsByVendor.totalRecords) {
                return totalRecords
            }
            return this.getAllInvitationsByVendor(vendor, startIndex + 1, pageSize)
        })
    }

    getAllInvitationTemplates = (owner, startIndex = 0, pageSize = 10) => {
        let totalRecords = []
        let options = {
            query: gql`
            query getAllInvitationTemplate($filterOptions: InputFilterOptions!) {
                getAllInvitationTemplate(filterOptions: $filterOptions){
                    totalRecords,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            totalRecords = totalRecords.concat(graphQLResult.data.getAllInvitationTemplate['IDs'])
            if (totalRecords.length === graphQLResult.data.getAllInvitationTemplate.totalRecords) {
                return totalRecords
            }
            return this.getAllInvitationTemplates(owner, startIndex + 1, pageSize)
        })
    }
    getAllPublicInviteTemplates = (owner, startIndex = 0, pageSize = 10) => {
        let options = {
            query: gql`
            query getAllPublicInviteTemplates($filterOptions: InputFilterOptions!) {
                getAllPublicInviteTemplates(filterOptions: $filterOptions){
                    totalRecords,
                    IDs
                    }
                }
            `,
            variables: { filterOptions: { dbSource: 'OFF_CHAIN', startIndex, pageSize, owner } }
        }
        return this.apolloClientConnector.query(options).then(graphQLResult => {
            let vendorResult = []
            if (!graphQLResult || !graphQLResult.data) {
                throw new Error(graphQLResult.errors[0].message)
            }
            vendorResult = graphQLResult.data.getAllPublicInviteTemplates["IDs"]
            return vendorResult
        })
    }
    // mutation

    addVendorContact = (vendorAddress, templateId, initiatorJsonLd, receiverJsonLd, txnMode) => {
        let queryOptions
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
            mutation addVendorContact($vendorAddress: String!,$templateId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!,$txnType: TRANSACTION_TYPE!){
                addVendorContact(vendorAddress: $vendorAddress, templateID: $templateId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, txnType: $txnType)
            }`,
            variables: { vendorAddress, templateId, inputObjectInitiator, inputObjectReceiver, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions)
            .then(res => {
                if (!res.data) {
                    throw new Error(res.errors[0].message)
                }
                return res.data.addVendorContact
            })
    }

    addBuyerContact = (buyerAddress, templateId, initiatorJsonLd, receiverJsonLd, txnMode) => {
        let queryOptions;
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
            mutation addBuyerContact($buyerAddress: String!, $templateId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!,$txnType: TRANSACTION_TYPE!){
                addBuyerContact(buyerAddress: $buyerAddress, templateID:$templateId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, txnType: $txnType)
            }`,
            variables: { buyerAddress, templateId, inputObjectInitiator, inputObjectReceiver, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.addBuyerContact
        })
    }

    inviteVendor = (vendorAddress, templateId, initiatorJsonLd, receiverJsonLd, txnMode) => {
        let queryOptions;
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
            mutation inviteVendor($vendorAddress: String!, $templateId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!, $txnType: TRANSACTION_TYPE!){
                inviteVendor(vendorAddress: $vendorAddress, templateID: $templateId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, txnType: $txnType)
            }`,
            variables: { vendorAddress, templateId, inputObjectInitiator, inputObjectReceiver, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.inviteVendor
        })
    }

    inviteBuyer = (buyerAddress, templateId, initiatorJsonLd, receiverJsonLd, txnMode) => {
        let queryOptions;
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
            mutation inviteBuyer($buyerAddress: String!, $templateId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!, $txnType: TRANSACTION_TYPE!){
                inviteBuyer(buyerAddress: $buyerAddress, templateID: $templateId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, txnType: $txnType)
            }`,
            variables: { buyerAddress, templateId, inputObjectInitiator, inputObjectReceiver, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.inviteBuyer
        })
    }

    draft = (templateId, initiatorJsonLd, receiverJsonLd, txnMode, type) => {
        if (type === 'vendor') {
            return this.draftVendor(templateId, initiatorJsonLd, receiverJsonLd, txnMode)
        } else {
            return this.draftBuyer(templateId, initiatorJsonLd, receiverJsonLd, txnMode)
        }
    }

    draftVendor = (templateId, initiatorJsonLd, receiverJsonLd, txnMode) => {
        let queryOptions;
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
                mutation draftVendor($templateId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!, $txnType: TRANSACTION_TYPE!){
                    draftVendor(templateID: $templateId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, txnType: $txnType)
                }`,
            variables: { templateId, inputObjectInitiator, inputObjectReceiver, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.draftVendor
        })
    }

    draftBuyer = (templateId, initiatorJsonLd, receiverJsonLd, txnMode) => {
        let queryOptions, inputOption = txnMode === "2" ? "data" : "Vendor";
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
                mutation draftBuyer($templateId: String!,$inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!, $txnType: TRANSACTION_TYPE!){
                    draftBuyer(templateID: $templateId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, txnType: $txnType)
                }`,
            variables: { templateId, inputObjectInitiator, inputObjectReceiver, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.draftBuyer
        })
    }

    acceptBuyerInvite = (invitationId, initiatorJsonLd, receiverJsonLd, shareKey, txnMode) => {
        let queryOptions;
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
              mutation acceptBuyerInvite($invitationId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!, $shareKey: String!, $txnType: TRANSACTION_TYPE!){
                acceptBuyerInvite(invitationID: $invitationId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, shareKey: $shareKey, txnType: $txnType)
              }`,
            variables: { invitationId, inputObjectInitiator, inputObjectReceiver, shareKey, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.acceptBuyerInvite
        })
    }

    acceptVendorInvite = (invitationId, initiatorJsonLd, receiverJsonLd, shareKey, txnMode) => {
        let queryOptions;
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
              mutation acceptVendorInvite($invitationId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!, $shareKey: String!, $txnType: TRANSACTION_TYPE!){
                acceptVendorInvite(invitationID: $invitationId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, shareKey: $shareKey, txnType: $txnType)
              }`,
            variables: { invitationId, inputObjectInitiator, inputObjectReceiver, shareKey, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.acceptVendorInvite
        })
    }

    rejectBuyerInvite = (invitationId) => {
        let queryOptions;
        queryOptions = {
            mutation: gql`
              mutation rejectBuyerInvite($invitationId: String!) {
                rejectBuyerInvite(InvitationID: $invitationId)
              }`,
            variables: { invitationId }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectBuyerInvite
        })
    }

    rejectVendorInvite = (invitationId) => {
        let queryOptions;
        queryOptions = {
            mutation: gql`
              mutation rejectVendorInvite($invitationId: String!) {
                rejectVendorInvite(InvitationID: $invitationId)
              }`,
            variables: { invitationId }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectVendorInvite
        })
    }

    updateInvite = (invitationId, templateId, initiatorJsonLd, receiverJsonLd, txnMode) => {
        let queryOptions;
        let txnType = NetworkUtils.getTxnTypeForGraphQL(txnMode);
        let inputObjectInitiator = {}, inputObjectReceiver = {}
        if (txnMode === "2") {
            inputObjectInitiator.data = initiatorJsonLd
            inputObjectReceiver.data = receiverJsonLd
        }
        else {
            inputObjectInitiator.Vendor = JSON.parse(initiatorJsonLd)
            inputObjectReceiver.Vendor = JSON.parse(receiverJsonLd)
        }
        queryOptions = {
            mutation: gql`
              mutation updateInvite($invitationId: String!, $templateId: String!, $inputObjectInitiator: InputInitiatorVendorInfo!, $inputObjectReceiver: InputReceiverVendorInfo!, $txnType: TRANSACTION_TYPE!) {
                updateInvite(invitationID: $invitationId, templateID: $templateId, inputInitiator: $inputObjectInitiator, inputReceiver: $inputObjectReceiver, txnType: $txnType)
              }`,
            variables: { invitationId, templateId, inputObjectInitiator, inputObjectReceiver, txnType }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.updateInvite
        })

    }

    approve = (type, invitationId) => {
        if (type === "supplier") {
            return this.approveVendor(invitationId)
        } else {
            return this.approveBuyer(invitationId)
        }
    }

    reject = (type, invitationId) => {
        if (type === "supplier") {
            return this.rejectVendor(invitationId)
        } else {
            return this.rejectBuyer(invitationId)
        }
    }

    rejectBuyer = (invitationId) => {
        let queryOptions;
        queryOptions = {
            mutation: gql`
              mutation rejectBuyer($invitationId: String!) {
                rejectBuyer(InvitationID: $invitationId)
              }`,
            variables: { invitationId }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectBuyer
        })
    }

    rejectVendor = (invitationId) => {
        let queryOptions;
        queryOptions = {
            mutation: gql`
              mutation rejectVendor($invitationId: String!) {
                rejectVendor(InvitationID: $invitationId)
              }`,
            variables: { invitationId }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.rejectVendor
        })
    }

    approveVendor = (invitationId) => {
        let queryOptions;
        queryOptions = {
            mutation: gql`
              mutation approveVendor($invitationId: String!) {
                approveVendor(InvitationID: $invitationId)
              }`,
            variables: { invitationId }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.approveVendor
        })
    }

    approveBuyer = (invitationId) => {
        let queryOptions;
        queryOptions = {
            mutation: gql`
              mutation approveBuyer($invitationId: String!) {
                approveBuyer(InvitationID: $invitationId)
              }`,
            variables: { invitationId }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.approveBuyer
        })
    }

    // approveReject = (invitationId, functionName) => {
    //     let queryOptions;
    //     queryOptions = {
    //         mutation: gql`
    //           mutation approveReject($invitationId: String!, $functionName: String!) {
    //             approveReject(InvitationID: $invitationId, functionName: $functionName)
    //           }`,
    //         variables: { invitationId, functionName }
    //     }
    //     return this.apolloClientConnector.mutate(queryOptions).then(res => {
    //         if (!res.data) {
    //             throw new Error(res.errors[0].message)
    //         }
    //         return res.data.approveReject
    //     })
    // }

    evalRequest = (invitationId, contractAddress, documentId) => {
        let queryOptions;
        queryOptions = {
            mutation: gql`
            mutation evalRequest($invitationId: String!, $contractAddress: String!, $documentId: String!){
                evalRequest(invitationID: $invitationId, contractAddress: $contractAddress, documenID: $documentId)
            }`,
            variables: { invitationId, contractAddress, documentId }
        }
        return this.apolloClientConnector.mutate(queryOptions).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.evalRequest
        })
    }

    createInvitationTemplate = (name, inputObject, metaData, txnType, templateType) => {
        let options = {
            mutation: gql`
            mutation createInvitationTemplate($name: String!, $inputObject: InputInitiatorVendorInfo!, $metaData:String, $txnType: TRANSACTION_TYPE!, $templateType: TEMPLATE_TYPE!) {
                createInvitationTemplate(name: $name, inputObject: $inputObject,metaData:$metaData, txnType: $txnType, templateType:$templateType)            
            }`,
            variables: { name, inputObject, metaData, txnType, templateType }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            return res.data.createInvitationTemplate
        })
    }

    enableDisableInvitationTemplate = (templateId, enableOrDisable) => {
        let options;
        if (enableOrDisable) {
            options = {
                mutation: gql`
                mutation EnableInvitationTemplate($templateId:String!){
                    EnableInvitationTemplate(templateID:$templateId)
                }`,
                variables: { templateId }
            }
        } else {
            options = {
                mutation: gql`
                mutation DisableInvitationTemplate($templateId:String!) {
                    DisableInvitationTemplate(templateID:$templateId)
                }`,
                variables: { templateId }
            }
        }
        return this.apolloClientConnector.mutate(options).then(res => {
            if (!res.data) {
                throw new Error(res.errors[0].message)
            }
            if (enableOrDisable) {
                return res.data.EnableInvitationTemplate
            } else {
                return res.data.DisableInvitationTemplate
            }
        })
    }


}
export default VendorManagement;