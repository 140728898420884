import './setting.less'
import React from 'react';
import * as Utils from '../../../util/utils';
import { Row, Col, Button, Card, Icon, message, Modal, Select, Input, Switch, Radio, Upload, Checkbox } from 'antd';
import { Checkboard } from 'react-color';


class TabSettings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            subscribers: false,
            graph: false,
            scm: false,
            jsonLd: false,
            documentHistory: false
        }
    }

    componentDidMount() {
        let settings = Utils.getFromLocalStorage('settings')[Utils.getFromLocalStorage("param_id")];
        if (settings) {
            this.setState({
                subscribers: settings.subscribers ? settings.subscribers : false,
                graph: settings.graph ? settings.graph : false,
                scm: settings.scm ? settings.scm : false,
                jsonLd: settings.jsonLd ? settings.jsonLd : false,
                documentHistory: settings.documentHistory ? settings.documentHistory : false

            })
        }
    }

    onChecked = (event) => {
        let stateObject = {}
        let settings = Utils.getFromLocalStorage('settings')[Utils.getFromLocalStorage("param_id")];
        stateObject[event.target.name] = event.target.checked
        this.setState(stateObject);
        settings[event.target.name] = event.target.checked
        let accountSettings = {}
        accountSettings[Utils.getFromLocalStorage("param_id")] = settings
        Utils.setLocalStorage('settings', accountSettings)
    }

    render() {
        return (
            <>
                <Card className="setting__card" title={<h2 className="setting__nomargin">Tab Settings</h2>}>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>Subscribers</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <Checkbox checked={this.state.subscribers} name={"subscribers"} onChange={this.onChecked}></Checkbox>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>Graph</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <Checkbox checked={this.state.graph} name={"graph"} onChange={this.onChecked}></Checkbox>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>SCM</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <Checkbox checked={this.state.scm} name={"scm"} onChange={this.onChecked}></Checkbox>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>JSON-LD</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <Checkbox checked={this.state.jsonLd} name={"jsonLd"} onChange={this.onChecked}></Checkbox>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>Document History</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <Checkbox checked={this.state.documentHistory} name={"documentHistory"} onChange={this.onChecked}></Checkbox>
                            </div>

                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}
export default TabSettings