import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Operations = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
    <Route path={`${match.url}/home`} component={asyncComponent(() => import('./Default/index'))} />
    <Route path={`${match.url}/invitations`} component={asyncComponent(() => import('./Invitations/index'))} />
    <Route path={`${match.url}/templates`} component={asyncComponent(() => import('./Templates/index'))} />
    <Route path={`${match.url}/consensus`} component={asyncComponent(() => import('./Consensus/index'))} />
    <Route path={`${match.url}/steps`} component={asyncComponent(() => import('./StepsListing/index'))} />
    <Route path={`${match.url}/step`} component={asyncComponent(() => import('./Step/index'))} />
    {/* <Route path={`${match.url}/reports/:id`} component={asyncComponent(() => import('./WorkflowReport/index'))} /> */}
    <Route path={`${match.url}/reports/:id`} component={asyncComponent(() => import('./WorkflowReport/oldreport'))} />


    <Route
      path={`${match.url}/grn`}
      component={asyncComponent(() => import("../finance/Grn/index.js"))}
    />

    <Route path={`${match.url}/workflowBuilder`} component={asyncComponent(() => import('./WorkflowBuilderLandingPage'))} />
    <Route path={`${match.url}/workflowDetails`} component={asyncComponent(() => import('./WorkflowDetails/index'))} />
    <Route path={`${match.url}/workflow`} component={asyncComponent(() => import('./WorkflowBuilder'))} />
    <Route path={`${match.url}/preview/:id`} component={asyncComponent(() => import('./WorkflowPreview'))} />
    <Route path={`${match.url}/stepform`} component={asyncComponent(() => import('./TemplateStepForm'))} />

    <Route path={`${match.url}/queryConsole`} component={asyncComponent(() => import('./QueryConsole'))} />
    <Route path={`${match.url}/contacts`} component={asyncComponent(() => import("../Contacts/index"))}
    />
  </Switch>
);

export default Operations;