import React from 'react';
import { Row, Col, Button, Card, Icon, message, Modal, Select, Input, Switch, Radio, Upload } from 'antd';
import { BgColorsOutlined, FontSizeOutlined } from '@ant-design/icons'
import * as Utils from '../../../util/utils';
import PreviewTemplate from './preview';
import './setting.less'
import DocumentTheme from './document-theme';

class DocumentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editDocumentTemplateConfig: false,
        }
    }

    setDocumentTemplate = (type = "contract") => {
        let templateType = Utils.getDocumentTemplateType(type)
        if (templateType !== 'default') {
            templateType = "custom"
        }
        this.setState({ editDocumentTemplateConfig: true, modalHeader: type, selectedMode: templateType })
    }

    directPreiew = (type) => {
        let templateType = Utils.getDocumentTemplateType(type)
        if (templateType === "default") {
            this.setState({ modalHeader: type, preview: true, previewFile: undefined, disableOk: true })
            return
        }
        let template = Utils.getDocumentTemplate(type)
        this.setState({ modalHeader: type, preview: true, previewFile: template, disableOk: true })
    }

    saveFileConfig = () => {
        if (this.state.selectedMode === "default") {
            //editDocumentTemplateConfig: false,
            this.setState({ preview: true, previewFile: undefined })
            return
        }
        if (!this.state.documentFile || !this.state.documentFile.name) {
            message.warning('Please select the template')
            return
        }
        const file = this.state.documentFile
        let that = this
        let reader = new FileReader();
        reader.onload = function (e) {
            //editDocumentTemplateConfig: false,
            that.setState({ preview: true, previewFile: reader.result })
        }
        reader.onerror = function (e) {
            message.error('Unable to read file')
            this.discardFileConfig()
        }
        reader.readAsText(file);
    }

    onBack = () => {
        this.setState({ preview: false, previewFile: undefined })
    }

    readDocument = (e) => {
        e.preventDefault();
        this.setState({
            documentFile: e.target.files[0],
            importFile: e.target.files[0].name
        }, () => {
            console.log("Document File", this.state.documentFile);
        })
    }

    importDocument = () => {
        document.getElementById("importDocument").click();
    }

    discardFileConfig = () => {
        if (document.getElementById("importDocument")) {
            document.getElementById("importDocument").value = ''
        }
        this.setState({
            documentFile: undefined,
            importFile: '',
            editDocumentTemplateConfig: false

        })
    }

    onChange = (e) => {
        if (e.target.value == this.state.selectedMode) {
            return
        }
        this.setState({ selectedMode: e.target.value })
    }

    renderFileUploadConfig = () => {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <>
                <Radio.Group onChange={this.onChange} value={this.state.selectedMode}>
                    <Radio style={radioStyle} value={'default'}>
                        Default Theme
                    </Radio>
                    <Radio style={radioStyle} value={'custom'}>
                        Custom Theme
                    </Radio>
                </Radio.Group>
                {this.state.selectedMode === 'custom' &&
                    <Row style={{ marginLeft: "10px", marginTop: '5px' }}>
                        <Col span={14}>
                            <Input disabled={true} placeholder="Path" id="uploadFile" value={this.state.importFile} />
                        </Col>
                        <Col span={4}>
                            <input accept=".ejs" type="file" style={{ "display": "none" }} onChange={this.readDocument} id="importDocument" />
                            <Button type="primary" onClick={() => this.importDocument()}>Select Document Theme</Button>
                        </Col>
                    </Row>}
            </>
        )
    }

    getVersionAndAdditionalProperties = (template) => {
        if (!template) {
            return {
                "versionInfo": { "buildMinVersion": 12, "buildMaxVersion": 999, "name": "DEFAULT THEME" },
                "additionalProperties": {},
                "dynamicFormDataType": []
            }
        }
        let templateVersionInfo = template.split('\n')[0];
        templateVersionInfo = templateVersionInfo.replace(/<%#|%>/g, "").trim();
        return JSON.parse(templateVersionInfo);
    }

    onOk = (type, template) => {
        console.log('onOkay')
        let templateType = "custom"
        if (!template) {
            templateType = "default"
        }
        let templateVersionInfo = this.getVersionAndAdditionalProperties(template);
        if (Utils.isVersionCompatible(templateVersionInfo.versionInfo.buildMinVersion, templateVersionInfo.versionInfo.buildMaxVersion)) {
            let themeObj = {
                type: templateType,
                template,
                templateVersionInfo: templateVersionInfo.versionInfo,
                additionalProperties: templateVersionInfo.additionalProperties,
                dynamicFormDataType: templateVersionInfo.dynamicFormDataType
            }
            Utils.saveTheme(type, themeObj);
            DocumentTheme.getInstance().updateTheme(type, template);
            message.success('Document Theme Applied Successfully!')
            this.onClose()
        } else {
            message.error("Document Theme Incompatible");
        }
    }

    onClose = () => {
        if (document.getElementById("importDocument")) {
            document.getElementById("importDocument").value = ''
        }
        this.setState({
            editDocumentTemplateConfig: false,
            preview: false,
            previewFile: undefined,
            documentFile: undefined,
            importFile: '',
            disableOk: false
        })
    }

    render() {
        return (
            <>
                <Card className="setting__card" title={<h2 className="setting__nomargin">Document Theme</h2>}>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>Contract</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <span><a onClick={() => this.directPreiew("contract")}>View {Utils.camelCaseToString(Utils.getDocumentTemplateType("contract"))} Theme</a></span>&nbsp;
                                    &nbsp;
                                <span>
                                    <Icon type="edit" onClick={() => this.setDocumentTemplate("contract")} className="iconStyle" />
                                </span>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>Purchase Order</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <span><a onClick={() => this.directPreiew("purchaseOrder")}>View {Utils.camelCaseToString(Utils.getDocumentTemplateType("purchaseOrder"))} Theme</a></span>&nbsp;
                               &nbsp;
                                <span>
                                    <Icon type="edit" onClick={() => this.setDocumentTemplate("purchaseOrder")} className="iconStyle" />
                                </span>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>Invoice</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <span><a onClick={() => this.directPreiew("invoice")}>View {Utils.camelCaseToString(Utils.getDocumentTemplateType("invoice"))} Theme</a></span>&nbsp;
                                &nbsp;
                                <span>
                                    <Icon type="edit" onClick={() => this.setDocumentTemplate("invoice")} className="iconStyle" />
                                </span>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <h4>Payment</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                                <span><a onClick={() => this.directPreiew("payment")}>View {Utils.camelCaseToString(Utils.getDocumentTemplateType("payment"))} Theme</a></span>&nbsp;
                                &nbsp;
                                <span>
                                    <Icon type="edit" onClick={() => this.setDocumentTemplate("payment")} className="iconStyle" />
                                </span>
                            </div>

                        </Col>
                    </Row>
                    {/* <Row>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24} >
                            <p className="setting__label">Digital Receipt</p>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                            <div>
                            <span><a onClick={() => this.directPreiew("digitalReceipt")}>View {Utils.camelCaseToString(Utils.getDocumentTemplateType("digitalReceipt"))} Theme</a></span>&nbsp;
                                &nbsp;
                                <span>
                                <Icon type="edit" onClick={() => this.setDocumentTemplate("payment")} />
                            </span>
                            </div>

                        </Col>
                    </Row> */}
                </Card>
                <Modal centered
                    width={650}
                    title={`Configure ${Utils.camelCaseToString(this.state.modalHeader)} Templates`}
                    visible={this.state.editDocumentTemplateConfig}
                    closable={true}
                    onCancel={this.discardFileConfig}
                    footer={[
                        <Button key="back" onClick={this.discardFileConfig} >Cancel</Button>,
                        <Button key="submit" type="primary" onClick={this.saveFileConfig}>Next</Button>,
                    ]}
                >
                    {this.renderFileUploadConfig()}
                </Modal>
                {
                    this.state.preview &&
                    <PreviewTemplate previewFile={this.state.previewFile} type={this.state.modalHeader} disableOk={this.state.disableOk} onBack={this.onBack} onOk={this.onOk} onClose={this.onClose} />
                }

            </>
        )
    }
}
export default DocumentTemplate;