import React, { Component } from 'react';
import * as Utils from '../../../util/utils';
import './index.less';
import { Input } from 'antd';

class MismatchTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            taxAmount: 0,
            cgstTotal: 0,
            igstTotal: 0,
            sgstTotal: 0,
            totalDiscount: 0,
            globalDiscount: 0,
            totalInvoiceValue: 0,
            noteValue: 0,
            adjustedValue: 0,
            amountReceivedInInvoice: 0,
            amountPaid: 0,
            amountToBePaid: 0,
            currency: "INR",
            inputValue: 0,
            noteValuesInfo: {
                creditNote: 0,
                debitNote: 0
            }
        }
    }

    componentDidMount() {
        if (this.props.createPayment) {
            const { amountToBePaid } = this.getAmountToBePaid(this.props);
            this.setState({ inputValue: amountToBePaid }, () => {
                this.setData(this.props);
            });
            return;
        }
        this.setData(this.props);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.isDispute)
            this.setData(newProps);
    }


    getTaxInfo = (items) => {
        let subTotal = 0, grandTotal = 0, sgstTotal = 0, cgstTotal = 0, igstTotal = 0, totalDiscount = 0;
        for (let index = 0; index < items.length; index++) {
            const currentItem = items[index];
            currentItem.subTotal = Number(currentItem.subTotal);
            sgstTotal += currentItem.subTotal * (Number(currentItem.sgst) / 100)
            cgstTotal += currentItem.subTotal * (Number(currentItem.cgst) / 100)
            igstTotal += currentItem.subTotal * (Number(currentItem.igst) / 100)
            totalDiscount += currentItem.subTotal * (Number(currentItem.discount) / 100)
            grandTotal += Number(currentItem.amount)
            subTotal += currentItem.subTotal ? Number(currentItem.subTotal) : 0
        }
        let globalDiscount = Number(this.props.globalDiscountInputValue);
        if (this.props.globalDiscountUnit === "value") {
            globalDiscount = (globalDiscount * 100) / (subTotal - totalDiscount);
        }
        if (this.props.setStateFromChild) {
            this.props.setStateFromChild({
                globalDiscount: globalDiscount,
                totalAmount: grandTotal - ((subTotal - totalDiscount) * 0.01 * globalDiscount),
            });
        }

        globalDiscount = (subTotal - totalDiscount) * 0.01 * globalDiscount;

        return { cgstTotal, igstTotal, sgstTotal, totalDiscount, taxAmount: Number(cgstTotal + sgstTotal + igstTotal), globalDiscount };
    }

    updateTableData = (dataSource) => {

        let items = dataSource;
        let subTotal = 0, grandTotal = 0, totalDiscount = 0;
        for (let index = 0; index < items.length; index++) {
            const currentItem = items[index];
            currentItem.subTotal = Number(currentItem.subTotal);
            totalDiscount += currentItem.subTotal * (Number(currentItem.discount) / 100)
            grandTotal += Number(currentItem.amount)
            subTotal += currentItem.subTotal ? Number(currentItem.subTotal) : 0
        }

        let globalDiscount = Number(this.props.globalDiscountInputValue);
        if (this.props.globalDiscountUnit === "value") {
            globalDiscount = (globalDiscount * 100) / (subTotal - totalDiscount);
        }
        const totalInvoiceValue = grandTotal - ((subTotal - totalDiscount) * 0.01 * globalDiscount);
        this.props.setAmountToBePaid(totalInvoiceValue)
        this.setState({ inputValue: totalInvoiceValue }, () => {
            this.setData(this.props);
        });
    }

    setData = (props) => {
        let { totalInvoiceValue, amountPaid, currency, noteValuesInfo, amountReceivedInInvoice, dataSource } = props;
        const noteValue = noteValuesInfo.creditNote + noteValuesInfo.debitNote;
        const { adjustedValue, amountToBePaid } = this.getAmountToBePaid(props);
        const { cgstTotal, igstTotal, sgstTotal, totalDiscount, taxAmount, globalDiscount } = this.getTaxInfo(dataSource);
        this.setState({
            totalInvoiceValue,
            noteValue,
            adjustedValue,
            amountPaid,
            amountReceivedInInvoice,
            amountToBePaid,
            amountDue: (amountToBePaid - this.state.inputValue),
            currency,
            noteValuesInfo,
            cgstTotal, igstTotal, sgstTotal, totalDiscount, taxAmount, globalDiscount
        })
    }

    getAmountToBePaid = (props) => {
        let { totalInvoiceValue, amountPaid, noteValuesInfo, amountReceivedInInvoice } = props;
        const noteValue = noteValuesInfo.creditNote + noteValuesInfo.debitNote;
        let adjustedValue = this.getNumber(totalInvoiceValue) + this.getNumber(noteValue);
        let amountToBePaid = this.getNumber(adjustedValue) - this.getNumber(amountPaid) - this.getNumber(amountReceivedInInvoice);
        return { adjustedValue, amountToBePaid };
    }

    getNumber = (value) => {
        if (!value) {
            return 0;
        }
        return Number(value);
    }

    handleOnChange = (e) => {
        this.setState({
            amountDue: Math.max(0, this.state.amountToBePaid - Number(e.target.value)),
            inputValue: e.target.value
        })
    }

    render() {
        let amountDueStyle = {
            backgroundColor: "#f5f5f5"
        }
        let currentTheme = Utils.getFromLocalStorage('theme');
        if (currentTheme === 'THEME_TYPE_DARK') {
            amountDueStyle = {
                backgroundColor: "transparent"
            }
        }
        return <div className="mismatch-table">
            <table>
                <tr>
                    <td className="mismatch-table__right" colSpan="2">
                        {this.props.editAmountToBePaid &&
                            <Input style={{ textAlign: 'right' }}
                                addonBefore="Pay Amount: "
                                onChange={this.handleOnChange}
                                value={Utils.getPrice(this.state.inputValue)}
                                onBlur={(e) => this.props.setAmountToBePaid(e.target.value)}
                                type="number"
                                min="0"
                            />
                        }
                    </td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">SGST </td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.sgstTotal, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">CGST</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.cgstTotal, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">IGST</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.igstTotal, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">Total Tax </td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.taxAmount, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">Discount on Items</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.totalDiscount, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">Discount on Receipt</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.globalDiscount, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">Total Discount</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.totalDiscount + this.state.globalDiscount, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">Total Invoice Value</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.totalInvoiceValue, this.props.currency)}</td>
                </tr>
                {this.state.noteValuesInfo.creditNote !== 0 &&
                    <tr>
                        <td className="mismatch-table__left">Credit Note Value </td>
                        <td className="mismatch-table__right">{Utils.getCurrency(this.state.noteValuesInfo.creditNote, this.props.currency)}</td>
                    </tr>
                }
                {this.state.noteValuesInfo.debitNote !== 0 &&
                    <tr>
                        <td className="mismatch-table__left">Debit Note Value </td>
                        <td className="mismatch-table__right">{Utils.getCurrency(this.state.noteValuesInfo.debitNote, this.props.currency)}</td>
                    </tr>
                }
                <tr>
                    <td className="mismatch-table__left">Adjusted Value</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.adjustedValue, this.props.currency)}</td>
                </tr>
                <tr>
                    <td className="mismatch-table__left">Amount Paid</td>
                    <td className="mismatch-table__right">{Utils.getCurrency(this.state.amountPaid, this.props.currency)}</td>
                </tr>
                {this.props.fromPaymentConfirmPage &&
                    <tr>
                        <td className="mismatch-table__left">Amount Received in Doc</td>
                        <td className="mismatch-table__right">{Utils.getCurrency(this.state.amountReceivedInInvoice, this.props.currency)}</td>
                    </tr>
                }
                <tr style={amountDueStyle}>
                    <td className="mismatch-table__left">Total Amount</td>
                    <td className="mismatch-table__right">
                        {Utils.getCurrency(this.state.amountDue, this.props.currency)}
                    </td>
                </tr>
            </table><br />
        </div>
    }
}

export default MismatchTable;