import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

const DashboardMain = ({ match }) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/purchases/overview`} />
        <Route path={`${match.url}/purchases/overview`} component={asyncComponent(() => import('./PurchaseOverview'))} />
        <Route path={`${match.url}/purchases/delivery`} component={asyncComponent(() => import('./SupplierDelivery'))} />
        <Route path={`${match.url}/sales/overview`} component={asyncComponent(() => import('./SalesOverview'))} />
        <Route path={`${match.url}/sales/customerInsights`} component={asyncComponent(() => import('./CustomerInsights'))} />
        <Route path={`${match.url}/finance/overview`} component={asyncComponent(() => import('./FinanceOverview'))} />
        <Route path={`${match.url}/inventory/overview`} component={asyncComponent(() => import('./Inventory'))} />
        <Route path={`${match.url}/inventory/insights`} component={asyncComponent(() => import('./Inventory'))} />
        <Route path={`${match.url}/inventory/history`} component={asyncComponent(() => import('./Inventory'))} />
        <Route path={`${match.url}/purchases/gallery`} component={asyncComponent(() => import('./Gallery'))} />

    </Switch>
);

export default DashboardMain;
