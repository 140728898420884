import NetworkBridge from "../../../util/network-bridge";

class Grn {
    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;

    }

    getAllGrnByDocId(docId, docStep) {
        return this.mongoDBConnector.invoke('grn', "getAllGrnByDocId", docId, docStep);
    }

    getAllGrns(address) {
        return this.mongoDBConnector.invoke('grn', "getAllGrns", address);
    }

    getGrnInternalId(grnId) {
        return this.mongoDBConnector.invoke('grn', "getGrnInternalId", grnId);
    }
    getSummary(grnId, predicates) {
        return this.mongoDBConnector.invoke('grn', "getSummary", grnId, predicates);
    }

    getSummaryInBulk(grnIds, predicates) {
        return this.mongoDBConnector.invoke('grn', "getSummaryInBulk", grnIds, predicates);
    }

    getGrn(grnId) {
        return this.mongoDBConnector.invoke('grn', "getGrn", grnId);
    }

    getGrnStatus(grnId) {
        return this.mongoDBConnector.invoke('grn', "getGrnStatus", grnId);
    }

    getGrnIdsByInternalPOId(docId, invoiceNumber, docStep) {
        return this.mongoDBConnector.invoke('grn', "getGrnIdsByInternalPOId", docId, invoiceNumber, docStep);
    }

    getGrnParentId(grnId) {
        return this.mongoDBConnector.invoke('grn', "getGrnParentId", grnId);
    }
    getAllItemsSummaryForReceipt(grnId) {
        return this.mongoDBConnector.invoke('grn', "getAllItemsSummaryForReceipt", grnId);
    }
    getAllReceiptsByParent(grnId) {
        return this.mongoDBConnector.invoke('grn', "getAllReceiptsByParent", grnId);
    }
    getAllReceiptsByParentV1(grnId) {
        return this.mongoDBConnector.invoke('grn', "getAllReceiptsByParentV1", grnId);
    }
    getItemQuantitySummary(grnId) {
        return this.mongoDBConnector.invoke('grn', "getItemQuantitySummary", grnId);
    }
    getValidationData(grnId) {
        return this.mongoDBConnector.invoke('grn', "getValidationData", grnId);
    }
    getSellerAndBuyer(grnId) {
        return this.mongoDBConnector.invoke('grn', "getSellerAndBuyer", grnId);
    }
    getAllReceiptsByStep = (from, to, step, type, isClosed) => {
        return this.mongoDBConnector.invoke('grn', "getAllReceiptsByStep", from, to, step, type, isClosed)
    }

    getAllReceiptsByTypeForExport(){
        return this.mongoDBConnector.invoke('grn', "getAllReceiptsByTypeForExport");
    }

    addGrn(grnId, docId, jsonLd, status, owner, buyerId) {
        return this.mongoDBConnector.invoke('grn', "addGrn", grnId, docId, jsonLd, status, owner, buyerId).then(metaData => {
            if (metaData && metaData.closeGrns) {
                return NetworkBridge.getGRNManager().closeAllGRNs(metaData.receiptId);
            }
        });
    }

    updateGrn(grnId, docId, jsonLd, status, owner, buyerId) {
        return this.mongoDBConnector.invoke('grn', "updateGrn", grnId, docId, jsonLd, status, owner, buyerId).then(metaData => {
            if (metaData && metaData.closeGrns) {
                return NetworkBridge.getGRNManager().closeAllGRNs(metaData.receiptId);
            }
        });
    }

    //1 to skip doesExist- required in case attach() is called from addGrn()
    attachGrn(docId, docInternalId, grnId, docStep, skipDoesExist, parentDocId) {
        return this.mongoDBConnector.invoke('grn', "getAllGrnByDocId", docId, docStep);
    }

    detachGrn(grnId, ids, invoiceId) {
        return this.mongoDBConnector.invoke('grn', "detachGrn", grnId, ids, invoiceId);
    }

    updateGrnsStatus(grnIds, contractAddress, newStatus) {
        return this.mongoDBConnector.invoke('grn', "updateGrnsStatus", grnIds, contractAddress, newStatus);
    }

    updateStatus(grnId, newStatus) {
        return this.mongoDBConnector.invoke('grn', "updateStatus", grnId, newStatus);
    }

    writeStatus(grnId, status) {
        return this.mongoDBConnector.invoke('grn', "writeStatus", grnId, status);
    }

    doesExistByDocId(docId, grnId) {
        return this.mongoDBConnector.invoke('grn', "doesExistByDocId", docId, grnId);
    }

    updateInvoiceInfoForGRN(docId, parentDocId, resolveMatchedGRNs) {
        return this.mongoDBConnector.invoke('grn', "updateInvoiceInfoForGRN", docId, parentDocId, resolveMatchedGRNs);
    }
    getAllReceipts(participant, options, fromDate, toDate) {
        return this.mongoDBConnector.invoke('grn', "getAllReceipts", participant, options, fromDate, toDate);
    }
}
export default Grn;