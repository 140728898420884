import Parser from "./index";
import CSV2JSONLd from "../parsers/csv-jsonld";
import FileErrors from './errors';
class CSVFileParser extends Parser {
    parse(pathObject, callback) {
        return new Promise((resolve, reject) => {
            return super.parse(pathObject, {
                onProgress: (progress) => {
                    callback.onProgress(progress);
                },
                onData: (data) => {
                    this.validate(data, callback, resolve, reject);
                },
                onStatus: (statusObject) => {
                    callback.onStatus(statusObject);
                },
                onFileReading: (fileName) => {

                },
                onFileError: (errorObject) => {
                    callback.onAbort(errorObject);
                    reject(errorObject);
                    callback.onFileError(errorObject);
                },
                onFileAbort: (errorObject) => {
                    callback.onAbort(errorObject);
                    reject(errorObject);
                },
                onError: (errorObject) => {
                    callback.onError(errorObject);
                    reject(errorObject);
                }
            })
        })

    }

    validate(csvText, callback, resolve, reject) {
        callback.onStatus({ msg: `Validating data ${csvText.fileName}..` });

        CSV2JSONLd.convert(csvText.data).then(jsonLd => {
            callback.onStatus({ msg: `Imported the data ${csvText.fileName}..` });
            callback.onData({ fileName: csvText.fileName, jsonObjects: [jsonLd], resolve, reject })
        }).catch(error => {
            callback.onAbort({ fileName: csvText.fileName, error: FileErrors.INVALID_FILE_FORMAT });
        });
    }
}
export default CSVFileParser;