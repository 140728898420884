class NetworkDataFormator {
    static getMetaInfo(txId, dataJSON, type, effectedBy) {
        let transactionHash = dataJSON.transactionHash;
        let metaInfo = {
            "@id": `${txId}#${transactionHash}`,
            blockNumber: dataJSON.blockNumber,
            blockHash: dataJSON.blockHash,
            transInfo: type,
            documentId: txId,
            event: dataJSON.event,
            dateAndTime: dataJSON.dateAndTime,
            transactionHash: transactionHash,
            pDocId: dataJSON.args.pId,
            contractAddress: dataJSON.args.contractAddress,
            docId: dataJSON.args.docId
        }
        if (effectedBy) {
            metaInfo.effectedBy = effectedBy;
        }
        return metaInfo;
    }
}
export default NetworkDataFormator;