class EInvoiceErrors {

    static NO_NETWORK_ERROR = { ErrorMessage: "Unable to connect IRP, Please check your internet connection.", ErrorCode: 404 };

    static NO_CALL_BACK = { ErrorMessage: "Unable to import", ErrorCode: 10001 };
    static UNKNOWN_ERROR = { ErrorMessage: "Unable to import", ErrorCode: 10002 };

    static NO_CALL_BACK_ON_ERROR = { ErrorMessage: "Unable to import", ErrorCode: 10003 };
    static NO_CALL_BACK_ON_PROGRESS = { ErrorMessage: "Unable to import", ErrorCode: 10004 };
    static NO_CALL_BACK_ON_SUCCESS = { ErrorMessage: "Unable to import", ErrorCode: 10005 };
    static NO_CALL_BACK_ON_STATUS = { ErrorMessage: "Unable to import", ErrorCode: 10006 };

    static NO_CALL_BACK_ON_DONE = { ErrorMessage: "Unable to import", ErrorCode: 10007 };
    static NO_CALL_BACK_ON_ABORT = { ErrorMessage: "Unable to import", ErrorCode: 10008 };
    static NO_CALL_BACK_ON_FILE_SKIP_ERROR = { ErrorMessage: "Unable to find, onFileSkip(). Please implement onFileSkip()", ErrorCode: 10009 };
    static NO_CALL_BACK_ON_FILES_ERROR = { ErrorMessage: "Unable to find, onFiles(). Please implement onFiles()", ErrorCode: 10010 };
    static NO_CALL_BACK_ON_CONFLICT = { ErrorMessage: "Unable to import", ErrorCode: 10011 };
    static NO_CALL_BACK_ON_FAIL = { ErrorMessage: "Unable to import", ErrorCode: 10012 };
    static FILE_ON_NOT_FOUNT = { ErrorMessage: "Unable to import", ErrorCode: 10013 };
    static INVALID_FILE_FORMAT = { ErrorMessage: "Unable to import", ErrorCode: 10014 };
    static NO_FILES_SELECTED = { ErrorMessage: "Unable to import", ErrorCode: 10015 };
    static UNABLE_TO_FETCH_RECOED = { ErrorMessage: "Unable to fetch record", ErrorCode: 10016 };

    static RECORD_EXISTS = { ErrorMessage: "Record exists, Unable to replace.", ErrorCode: 10017 };
}

export default EInvoiceErrors;