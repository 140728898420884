class AppUtils{
    
    static isDesktop(){
       return false//process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'production'? true:false
    }

    static desktopQuits() {
        if(this.isDesktop) {
            const electron = window.require('electron');
            const remote = electron.remote;
            if (process.platform !== 'darwin') { 
                remote.app.exit(); 
            }
        }
    }
}
export default AppUtils;