import ParserUtils from './ParserUtils';
import NetworkBridge from '../util/network-bridge';
import InventoryParser from './InventoryParser';

class ItemParser {

    static getAggregateRating(itemData, jsonLd) {
        let aggregateRating = {
            "@type": "AggregateRating",
        }
        aggregateRating = ParserUtils.getCorrectData(itemData.ratingValue, 'ratingValue', aggregateRating);
        aggregateRating = ParserUtils.getCorrectData(itemData.reviewCount, 'reviewCount', aggregateRating);
        jsonLd['aggregateRating'] = aggregateRating;
        return jsonLd;
    }

    static getProductReturnPolicy(itemData, jsonLd) {
        let hasProductReturnPolicy = {
            "@type": "ProductReturnPolicy"
        }
        let hasProductReturnPolicyKeys = ["inStoreReturnsOffered", "productReturnDays", "productReturnLink", "refundType", "returnFees", "returnPolicyCategory"]
        let hasProductReturnPolicyValues = [itemData.inStoreReturnsOffered, itemData.productReturnDays, itemData.productReturnLink, itemData.refundType, itemData.returnFees, itemData.returnPolicyCategory];
        for (let i = 0; i < 6; i++) {
            hasProductReturnPolicy = ParserUtils.getCorrectData(hasProductReturnPolicyValues[i], hasProductReturnPolicyKeys[i], hasProductReturnPolicy);
        }
        jsonLd['hasProductReturnPolicy'] = hasProductReturnPolicy;
        return jsonLd;
    }

    static getItemCondition(itemCondition, jsonLd) {
        switch (itemCondition) {
            case 0:
                jsonLd['itemCondition'] = 'Damaged Condition';
                break;
            case 1:
                jsonLd['itemCondtion'] = 'New Condition';
                break;
            case 2:
                jsonLd['itemCondition'] = 'Refurbished Condition';
                break;
            case 3:
                jsonLd['itemCondition'] = 'Used Condition';
                break;
            default:
                jsonLd['itemCondition'] = undefined;
                break;
        }
        return jsonLd;
    }

    static getType(type, jsonLd) {
        let isRelatedTo = {};
        isRelatedTo['type'] = type;
        jsonLd['isRelatedTo'] = isRelatedTo;
        return jsonLd;
    }

    static getMaterials(materialArray, jsonLd) {
        jsonLd['material'] = materialArray || [];
        return jsonLd;
    }

    static getOffers(itemData, jsonLd) {
        let offers = {
            '@type': 'Offer',
            "availability": "http://schema.org/InStock"
        }
        jsonLd['offers'] = offers;
        return jsonLd;
    }

    static getReview(itemData, jsonLd) {
        let review = [];
        if (itemData.review) {
            for (let i = 0; i < itemData.review.length; i++) {
                review.push({
                    '@type': 'Review',
                    "author": itemData.review[i].author ? itemData.review[i].author : undefined,
                    "datePublished": itemData.review[i].date ? itemData.review[i].date : undefined,
                    "description": itemData.review[i].description ? itemData.review[i].description : undefined,
                    "name": itemData.review[i].author ? itemData.review[i].author : undefined,
                    "reviewRating": {
                        "@type": "Rating",
                        "bestRating": "5",
                        "ratingValue": itemData.review[i].rating ? itemData.review[i].rating : undefined,
                        "worstRating": "1"
                    }
                })
            }
        }
        jsonLd['review'] = review;
        return jsonLd;
    }

    static parseItem(itemData) {
        let jsonLd = {
            "@context": "http://schema.org",
            "@type": "Product",
        }
        jsonLd['name'] = itemData.itemName;
        jsonLd = ItemParser.getAggregateRating(itemData, jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.description, 'description', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.purpose, 'purpose', jsonLd)
        jsonLd = ParserUtils.getCorrectData(itemData.category, 'category', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.subCategory, 'subCategory', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.itemType || "goods", 'itemType', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.color, 'color', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.gtin, 'gtin', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.gtin12, 'gtin12', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.gtin13, 'gtin13', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.gtin14, 'gtin14', jsonLd);
        jsonLd = ItemParser.getProductReturnPolicy(itemData, jsonLd);
        jsonLd = ItemParser.getItemCondition(itemData.itemCondition, jsonLd);
        jsonLd = ItemParser.getType(itemData.type, jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.image, 'image', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.logo, 'logo', jsonLd);
        jsonLd = ItemParser.getMaterials(itemData.material, jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.model, 'model', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.mpn, 'mpn', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.nsn, 'nsn', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.productId, 'productId', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.from, 'from', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.to, 'to', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.internalId, 'internalId', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.truckName, 'truckName', jsonLd)
        jsonLd = ParserUtils.getCorrectData(itemData.truckType, 'truckType', jsonLd)
        jsonLd = ParserUtils.getCorrectData(itemData.isLogistics, 'isLogistics', jsonLd)
        jsonLd = ParserUtils.getCorrectData(itemData.truckCapacity, 'truckCapacity', jsonLd)
        jsonLd = ParserUtils.getCorrectData(itemData.txnType, 'catalogueTxnType', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.inventoryTxnType, 'inventoryTxnType', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.volume, 'volume', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.barCode, 'barCode', jsonLd);
        jsonLd = ItemParser.getOffers(itemData, jsonLd);
        jsonLd = ItemParser.getReview(itemData, jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.albumSharableLink, 'albumSharableLink', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.additionalProperties, 'additionalProperties', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.additionalItemFormDataType, 'additionalItemFormDataType', jsonLd);
        jsonLd = ParserUtils.getCorrectData(itemData.imageURL, 'imageURL', jsonLd);
        console.log('ItemParser JSONLD:', jsonLd)
        jsonLd = JSON.stringify(jsonLd);
        return jsonLd;
    }

    static addCatalogue(itemData) {
        let catalogueJsonLd = ItemParser.parseItem(itemData);
        let inventoryJsonLd = InventoryParser.parseInventory(itemData.inventoryDetails);
        //debugger;
        return NetworkBridge.getItemManager().addCatalogue(catalogueJsonLd, itemData.purpose, itemData.txnType, inventoryJsonLd, itemData.inventoryTxnType);
    }

    static updateCatalogue(catalogueId, itemData, isUpdateInventory, inventoryId) {
        if (!catalogueId) {
            return Promise.reject("Error: CatalogueId not found");
        }
        if (isUpdateInventory && !inventoryId) {
            return Promise.reject("Error: InventoryId not found");
        }
        let catalogueJsonLd = ItemParser.parseItem(itemData);
        let inventoryJsonLd = InventoryParser.parseInventory(itemData.inventoryDetails);

        return NetworkBridge.getItemManager().updateCatalogue(catalogueId, catalogueJsonLd, itemData.purpose, inventoryJsonLd, itemData.txnType, itemData.inventoryTxnType, isUpdateInventory, inventoryId);
    }

}

export default ItemParser;