import Database from '../../index';
import ParamConnector from '../../../param-connector'
import TemplateRepository from './template-repo';
import * as NetworkUtils from '../../../util/utils';
import RestoreReceipts from './receipts';
import ECIES from '../../../util/ecies'
import Console from '../../../logger/index';
import TemplateConsensusDBEvents from '../events/template-cons';
class TemplateConsensusRestore {

    static restoreAllTemplateConsensus(contractAddress, owner) {
        return ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress).getAllSubsribeTemplateConsensus(owner).then(templates => {
            let promiseArray = [];
            for (let index in templates) {
                let templateConsPromise = TemplateConsensusRestore.restoreTemplateConsensus(templates[index], contractAddress);
                templateConsPromise.then(result => {

                })
                promiseArray.push(templateConsPromise);
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreAllTemplateConsensus`);
        });
    }

    static restoreTemplateConsensus(templateConsId, contractAddress, assignEvent) {
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let isExists;
        let promise;
        return templateConsensusDB.doesTemplateConsExists(dbTemplateConsId).then(res => {
            isExists = res;
            if (!isExists) {
                promise = TemplateConsensusRestore.hardRestoreTemplateConsensus(templateConsId, contractAddress);
            }
            else {
                let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
                promise = templateConManager.getTemplateConsensusV2(templateConsId);
            }
            return promise.then(templateCons => {
                let dbtemplateId = NetworkUtils.getTransactionId(templateCons.templateId, templateCons.templateContractAddress);
                let restoreTemplateConsPromises = [];

                //Restore template if not exists.
                restoreTemplateConsPromises.push(TemplateRepository.restoreNotExists(dbtemplateId));

                //Restore Document
                let docIds = NetworkUtils.getArrayOfData(templateCons.docId);
                if (assignEvent === "onAssign") {
                    for (let index in docIds) {
                        const docId = NetworkUtils.getTransactionId(docIds[index], templateCons.docContracAddress);
                        const restoreReceiptObj = [{
                            receiptId: docId,
                            contractAddress: templateCons.docContracAddress,
                            event: "onAssign"
                        }]
                        restoreTemplateConsPromises.push(RestoreReceipts.restoreReceipt(restoreReceiptObj));
                    }
                }
                if (!isExists) {
                    let stepSigners = NetworkUtils.getArrayOfData(templateCons.stepSigners);

                    for (let index in stepSigners) {
                        restoreTemplateConsPromises.push(TemplateConsensusRestore.hardRestoreStepConsensus(templateConsId, stepSigners[index], contractAddress, false));
                    }
                }
                return Promise.all(restoreTemplateConsPromises);
            })
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreTemplateConsensus`);
        });
    }

    static hardRestoreTemplateConsensus(templateConsId, contractAddress) {
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let templateConsDetails, sendTemplateConsDetails;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);

        return templateConManager.getTemplateConsensusV2(templateConsId).then(res => {
            sendTemplateConsDetails = JSON.stringify(res);
            templateConsDetails = res;
            let dbTemplateId = NetworkUtils.getTransactionId(templateConsDetails[4], templateConsDetails[3]);
            let stepSigners = templateConsDetails[6];
            for (let index in stepSigners) {
                stepSigners[index] = NetworkUtils.getTransactionId(stepSigners[index], contractAddress);
            }
            let docIds = NetworkUtils.getArrayOfData(templateConsDetails[2]);
            docIds = docIds.map((docId) => {
                return NetworkUtils.getTransactionId(docId, templateConsDetails[1]);
            })
            // let docId = NetworkUtils.getTransactionId(templateConsDetails[2], templateConsDetails[1]);
            let docContractAddress = templateConsDetails.docContracAddress;
            let owner = templateConsDetails[0];
            let currentStep = templateConsDetails[5];
            let isCompleted = templateConsDetails.isCompleted;
            let transactionType = templateConsDetails[8];
            let docStep = templateConsDetails.docStep;
            let mergeId;
            if (templateConsDetails[9] !== "0x0000000000000000000000000000000000000000000000000000000000000000") {
                mergeId = templateConsDetails[9];
            }
            return templateConsensusDB.addTemplateConsensus(dbTemplateConsId, owner, docIds, docContractAddress, dbTemplateId, currentStep, stepSigners, isCompleted, transactionType, mergeId, docStep);
        }).then(() => {
            return JSON.parse(sendTemplateConsDetails);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/hardRestoreTemplateConsensus`);
            return JSON.parse(sendTemplateConsDetails);
        });
    }
    static restoreStepConsensus(templateConsId, stepConsId, contractAddress, isCompleted) {
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);

        return templateConsensusDB.doesStepConsExists(dbTemplateConsId, dbStepConsId).then(isExists => {
            if (isExists) {
                return TemplateConsensusRestore.updateStepConsensusStatus(templateConsId, stepConsId, contractAddress, true);
            }
            return TemplateConsensusRestore.hardRestoreTemplateConsensus(templateConsId, contractAddress);
        })
    }

    static hardRestoreStepConsensus(templateConsId, stepConsId, contractAddress, isExists) {
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);

        if (!isExists) {
            return TemplateConsensusRestore.addStepConsensus(templateConsId, stepConsId, contractAddress).then(data => {
                return templateConManager.isStepCompleted(stepConsId);
            }).then(status => {
                return templateConsensusDB.updateStepConsensusStatus(templateConsId, dbStepConsId, status);
            });
        }
        return TemplateConsensusRestore.updateStepConsensusStatus(templateConsId, stepConsId, contractAddress, true);
    }

    static restoreSubscribers(templateConsId, stepConsId, subscribersIds, contractAddress) {
        let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;

        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let promise;
        let promiseArray = [];
        for (let index in subscribersIds) {
            promise = templateConManager.getSubscriber(subscribersIds[index]).then(data => {
                return templateConsensusDB.addStepConsensusSubscriber(dbTemplateConsId, dbStepConsId, data.subscriber, data.stepKey).then(() => {
                    return TemplateConsensusRestore.addSubsriberForTemplate(templateConsId, data.subscriber, contractAddress, stepConsId);
                })
            }).catch(error => {
                Console.log(error);
            });
            promiseArray.push(promise);
        }
        return Promise.all(promiseArray).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreSubscribers`);
        });
    }

    static addSubsriber(templateConsId, address, subscribers, stepConsId, secondaryOwner, assignEvent) {
        return TemplateConsensusRestore.restoreTemplateConsensus(templateConsId, address, assignEvent).then(() => {
            if (assignEvent) {
                return TemplateConsensusRestore.addSubsriberForStepCons(templateConsId, subscribers, address, stepConsId, secondaryOwner);
            }
            return TemplateConsensusRestore.addSubsriberForTemplate(templateConsId, subscribers, address, stepConsId);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addSubsriber`);
        });
    }

    static addSubsriberForTemplate(templateConsId, subscribers, contractAddress, stepConsId, assignEvent) {
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
        let templateRepoDB = Database.getInstance().getDB().templateRepoDB;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateIdByConsId(dbTemplateConsId).then(templateId => {
            let promiseArray = [];
            promiseArray.push(templateRepoDB.addSubscribers(templateId, subscribers));
            if (assignEvent) {
                promiseArray.push(templateConsensusDB.addStepConsensusSubscriber(dbTemplateConsId, dbStepConsId, subscribers, ""));
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addSubsriberForTemplate`);
        });
    }
    static addSubsriberForStepCons(templateConsId, subscribers, contractAddress, stepConsId, secondaryOwner) {
        let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
        let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);

        let templateRepoDB = Database.getInstance().getDB().templateRepoDB;
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        return templateConsensusDB.getTemplateIdByConsId(dbTemplateConsId).then(templateId => {
            return templateConsensusDB.addStepConsensusSubscriberOnAssign(dbTemplateConsId, dbStepConsId, subscribers, secondaryOwner).then(() => {
                return templateRepoDB.addSubscribers(templateId, subscribers);
            })
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addSubsriberForStepCons`);
        });
    }

    static addStepConsensus(templateConsId, stepConsId, contractAddress) {
        let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;

        return templateConManager.getStepConsensus(stepConsId).then(dataJSON => {
            let dbStepConsId = NetworkUtils.getTransactionId(stepConsId, contractAddress);
            let dbTemplateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);
            let owner = dataJSON.owner;
            let jsonLd = dataJSON.jsonLd;
            let metaData = dataJSON.metaData;
            let primaryOwner = dataJSON.primaryOwner;
            let secondaryOwner = dataJSON.secondaryOwner;
            let transactionType = dataJSON.transactionType;
            let subscriberDetailPromiseArray = [];

            let privateKey = NetworkUtils.getPrivateKey();

            let subscribers = dataJSON.subscribers;
            for (let subsId in subscribers) {
                let subscriberDetailPromise = templateConManager.getSubscriber(subscribers[subsId]);
                subscriberDetailPromiseArray.push(subscriberDetailPromise);
            }
            return Promise.all(subscriberDetailPromiseArray).then(subscriberDetailArray => {
                let subscribersPromises = TemplateConsensusRestore.restoreSubscribers(templateConsId, stepConsId, dataJSON.subscribers, contractAddress);
                if (transactionType === '2') {
                    let selfParamId = NetworkUtils.getParamId();
                    let isSubscriber = false;
                    let stepKey;
                    for (let index in subscribers) {
                        if (subscribers[index].subscriber === selfParamId) {
                            stepKey = ECIES.decrypt(privateKey, subscribers[index].stepKey);
                            try {
                                jsonLd = ECIES.decrypt(stepKey, jsonLd);
                            } catch (e) {
                                Console.error("Error in syncing step consensus", dbStepConsId, owner, jsonLd, metaData, primaryOwner, dbTemplateConsId, secondaryOwner, transactionType, subscribers)
                            }
                            isSubscriber = true;
                            break;
                        }
                    }
                    if (!isSubscriber) {
                        jsonLd = { restrictAccess: true };
                        jsonLd = JSON.stringify(jsonLd);
                    }
                }
                return templateConsensusDB.addStepConsensus(dbStepConsId, owner, jsonLd, metaData, primaryOwner, dbTemplateConsId, secondaryOwner, transactionType, subscriberDetailArray).then(result => {
                    return subscribersPromises;
                });
            })

        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/addStepConsensus`);
        });
    }
    static updateStepConsensusStatus(templateConsId, stepId, contractAddress, isRestoreStep) {
        let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let txId = NetworkUtils.getTransactionId(stepId, contractAddress);
        templateConsId = NetworkUtils.getTransactionId(templateConsId, contractAddress);

        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let promise;
        if (isRestoreStep) {
            promise = templateConManager.getStepConsensus(stepId).then(dataJSON => {
                let stepConsensusJSONLd = dataJSON.jsonLd;
                let metaData = dataJSON.metaData;
                let secondaryOwner = dataJSON.secondaryOwner;
                let transactionType = dataJSON.transactionType.toString();
                let owner = dataJSON.owner;
                let subscribers = dataJSON.subscribers;
                let subscriberDetailPromiseArray = [];
                let privateKey = NetworkUtils.getPrivateKey();

                for (let subsId in subscribers) {
                    let subscriberDetailPromise = templateConManager.getSubscriber(subscribers[subsId].toString());
                    subscriberDetailPromiseArray.push(subscriberDetailPromise);
                }
                return Promise.all(subscriberDetailPromiseArray).then(subscriberDetailArray => {

                    if (transactionType === '2') {
                        let selfParamId = NetworkUtils.getParamId();
                        let isSubscriber = false;
                        let stepKey;
                        for (let index in subscriberDetailArray) {
                            if (subscriberDetailArray[index].subscriber === selfParamId) {
                                stepKey = ECIES.decrypt(privateKey, subscriberDetailArray[index].stepKey);
                                stepConsensusJSONLd = ECIES.decrypt(stepKey, stepConsensusJSONLd);
                                isSubscriber = true;
                                break;
                            }
                        }
                        if (!isSubscriber) {
                            stepConsensusJSONLd = { restrictAccess: true };
                            stepConsensusJSONLd = JSON.stringify(stepConsensusJSONLd);
                        }
                    }
                    return templateConsensusDB.updateStepConsensus(txId, stepConsensusJSONLd, metaData, secondaryOwner, subscriberDetailArray, transactionType, owner, templateConsId);
                })
            }).then(data => {
                return templateConManager.isStepCompleted(stepId);
            }).then(status => {
                return templateConsensusDB.updateStepConsensusStatus(templateConsId, txId, status);
            });
        } else {
            promise = TemplateConsensusRestore.restoreStepConsensus(templateConsId, stepId, contractAddress);
        }
        return promise;
    }

    static updateTemplateCompleted(templateId, contractAddress, isCompleted) {
        // let templateConManager = ParamConnector.getInstance().getNetwork().getTemplateRepoManager(contractAddress);
        let txId = NetworkUtils.getTransactionId(templateId, contractAddress);
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        // return templateConManager.isTemplateCompleted(templateId).then(dataJSON => {
        //     return templateConsensusDB.updateTemplateCompleted(txId, dataJSON[0]);
        // });
        return templateConsensusDB.updateTemplateConsensusStatus(txId, isCompleted).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/updateTemplateCompleted`);
        });
    }

    static restoreAllTemplateConsensusEvents(contractAddress, owner, callback) {
        if (callback && callback.onProgressText) {
            callback.onProgressText("Template consensus", `Trying to get all the events from ${contractAddress}`);
        }
        let templateConsManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let templateConsensusDB = Database.getInstance().getDB().templateConsensusDB;
        let templateConsIds;
        return templateConsManager.getAllSubsribeTemplateConsensus(owner).then(templates => {
            templateConsIds = templates;
            let promiseArray = [];
            for (let index in templates) {
                promiseArray.push(TemplateConsensusRestore.restoreAllTransactions(templates[index], contractAddress));
            }
            return Promise.all(promiseArray)
        }).then(() => {
            let promiseArray = [];
            for (let mergeConsIndex in templateConsIds) {
                promiseArray.push(templateConsensusDB.getMergeIdByTemplateConsId(NetworkUtils.getTransactionId(templateConsIds[mergeConsIndex], contractAddress)));
            }
            return Promise.all(promiseArray)
        }).then((res) => {
            let uniqueMergeIds = [], checkExisting = {};
            for (let index in res) {
                if (!checkExisting[res[index]]) {
                    checkExisting[res[index]] = true;
                    uniqueMergeIds.push(res[index]);
                }
            }
            return TemplateConsensusRestore.restoreAllMergeTransactions(uniqueMergeIds, contractAddress);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreAllTemplateConsensusEvents`);
        });
    }


    static restoreAllTransactions(templateConsId, contractAddress) {
        let templateConsManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        return templateConsManager.getAllEventsByConsId(templateConsId).then(transactions => {
            let promiseArray = []
            for (let index in transactions) {
                debugger;
                let txn = transactions[index];
                let templateConsensusDBEvents = new TemplateConsensusDBEvents()
                if (!templateConsensusDBEvents[`write${txn.event}`]) {
                    Console.error("Unable to write transaction, No method found.")
                    continue;
                }
                promiseArray.push(templateConsensusDBEvents[`write${txn.event}`](null, txn));
            }
            return Promise.all(promiseArray);
        }).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreAllTransactions`);
        });
    }

    static restoreAllMergeTransactions(mergeIds, contractAddress) {
        let templateConsManager = ParamConnector.getInstance().getNetwork().getTemplateConsManager(contractAddress);
        let promiseArray = [];
        for (let index in mergeIds) {
            if (mergeIds[index]) {
                promiseArray.push(templateConsManager.getAllMergeEventsByMergeId(mergeIds[index]).then(transaction => {
                    let templateConsensusDBEvents = new TemplateConsensusDBEvents()
                    return templateConsensusDBEvents.writeonTemplateConsensusMerged(null, transaction);
                }))
            }
        }
        return Promise.all(promiseArray).catch(e => {
            Console.error(`[Database] Error in restoring template consenssus [Reason] ${e} [Module] : Sync/template-cons/restoreAllMergeTransactions`);
        });
    }
}

export default TemplateConsensusRestore;