import React, { Component } from 'react';
import { Modal, Collapse, Icon, Button, Row, Col, Divider } from 'antd';
import qrLoader from '../../../assets/images/qr-code-scanning.gif';
import IRNError from '../../../assets/images/IRN_Errors.svg';
import './qrloader.less';
const { Panel } = Collapse;

class QRLoader extends Component {
    constructor(props) {
        super(props);
        this.flag = 0;
        this.state = {
            showQRLoader: props.showQRLoader,
            progressPercent: 0,
            invoiceCount: props.invoiceCount ? props.invoiceCount : 1,
            showError: false
        }
    }

    componentDidMount() {
        this.getProgress()
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            showQRLoader: newProps.showQRLoader,
            showError: newProps.showError,
            errorMessage: newProps.errorMessage,
            errorCode: newProps.errorCode
        }, () => {
            this.displayErrorMessages()
        })
        // this.getProgress()
    }

    getProgress = () => {
        if (this.flag == 0) {
            this.flag = 1;
            var width = 1;
            var id = setInterval(() => {
                if (width >= 100) {
                    clearInterval(id);
                    this.flag = 0;
                } else {
                    width++;
                    this.setState({ progressPercent: width })
                }
            }, 10 * this.state.invoiceCount);
        }
    }

    closeModal = () => {
        this.setState({
            showQRLoader: false
        }, () => {
            this.props.closeQRLoader()
        })
    }

    displayErrorMessages = () => {
        let errorMessage = this.state.errorMessage;
        let errorCode = this.state.errorCode;
        let errorDetails = [];
        for (let i in errorMessage) {
            errorDetails.push(<Row>
                <Col xl={5} lg={5} md={5}>
                    <span className="error_details__message">{errorCode[i]}</span>
                </Col>
                <Col xl={19} lg={19} md={19}>
                    <span className="error_details__message">{errorMessage[i]}</span>
                </Col>
                {
                    i != errorMessage.length - 1 && <Divider className="error_details__divider" />
                }
            </Row>);
        }
        this.setState({ errorDetails });
    }

    render() {
        return <Modal
            visible={this.state.showQRLoader}
            closable={false}
            width={300}
            title={this.state.showError ? "Error" : "Validating..."}
            maskClosable={false}
            centered
            className="loading-modal"
            footer={
                this.state.showError
                    ?
                    [<Button onClick={this.closeModal}>Cancel</Button>, <Button type="primary" icon="redo" onClick={this.props.retryGenerateIRN}>Retry</Button>]
                    : null
            }
        >
            <div className="loader-container">
                {
                    this.state.showError
                        ?
                        <div>
                            <div className="error_details_qrImg">
                                <img src={IRNError} alt="loader" width={125} />
                            </div>
                            <Collapse expandIconPosition={'right'} className='error_details loading-modal__collapse' bordered={false} defaultActiveKey={['1']}>
                                <Panel key="1"
                                    header={
                                        <span className="error_details__redIcon">
                                            <Icon type="close-circle" />&nbsp;&nbsp;
                                            Request Failed</span>
                                    }
                                >
                                    {this.state.errorDetails}
                                </Panel >
                            </Collapse>
                        </div>
                        :
                        <img src={qrLoader} alt="loader" width={125} style={{ margin: "2rem" }} />
                }
            </div>

        </Modal>
    }
}

export default QRLoader;