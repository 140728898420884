import NoSQLDatabase from "./nosql";
import Charts from "../param-libs/param-dashboard/charts";

class Database {

    constructor(address) {
        if (Database.instance) {
            throw new Error("You can't create object. Use Database.getInstance()");
        }
        this.receiptsDatabase = NoSQLDatabase.getInstance(address)
        this.chartsDatabase = Charts.getInstance(address);
    }

    static getInstance(address) {
        if (!Database.instance) {
            Database.instance = new Database(address)
        }
        return Database.instance;
    }

    initDatabase(URL, paramNetwork, serverPort) {
        return this.receiptsDatabase.initDatabase(URL, paramNetwork, serverPort).then((res) => {
            this.mongoClient = res;
            return this.chartsDatabase.initDatabase(URL, serverPort);
        })
    }

    setParamId(paramId) {
        this.paramId = paramId;
        this.receiptsDatabase.setParamId(paramId);
        this.chartsDatabase.setParamId(paramId);
    }


    getDB(dbType) {
        if (dbType === "charts") {
            return this.chartsDatabase;
        }
        return this.receiptsDatabase
    }

    resetDB() {
        if (Database.instance) {
            this.receiptsDatabase.resetConnection();
            Database.instance = null
        }
    }
}

export default Database;