import Charts from '../../charts'
import { finances } from '../../channel-config.json'
import Console from '../../../../logger'
import RedisClientSocket from '../../client-lib/redis-client';
import EventUtils from '../utils/eventUtils';

class Finances {

    constructor(paramID) {
        this.paramID = paramID;
    }

    publishAllOverView() {
        return this.getSummary().then(() => {
            return this.getInvoice();
        }).then(() => {
            return this.getAverageFinancePaymentReceivableCycle();
        }).then(() => {
            return this.getSalesPurchasesSummaryAmount();
        }).then(() => {
            return this.getOutstandingReceivableOrPayable();
        }).then(() => {
            return this.getTopPayables();
        }).then(() => {
            return this.getTopReceivables();
        }).then(() => {
            return this.getUnreconciledPayment();
        }).then(() => {
            return this.getUnreconciledReceivable();
        }).then(() => {
            return this.getDisputes();
        }).then(() => {
            return this.getInvoice()
        }).then(() => {
            return this.getTotalOrderVsTotalPayment()
        }).then(() => {
            return this.getTotalInvoiceVsTotalCollections()
        }).then(() => {
            return this.getSavings()
        }).then(() => {
            return this.getMonthlyPayables()
        }).then(() => {
            return this.getMonthlyReceivables()
        })
    }

    getSummary(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.dashboard}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getSummary();
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getInvoice(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.invoice}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.invoice();
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getAverageFinancePaymentCycle(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.averagePaymentCycle}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.averageFinancePaymentCycle();
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }
    getAverageFinanceReceivableCycle(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.averageReceivableCycle}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.averageFinanceReceivableCycle();
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getSalesPurchasesSummaryAmount(fromDate, toDate, isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.salesMonthwise}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getSalesPurchasesSummaryAmount(fromDate, toDate);
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getOutstandingReceivableOrPayable(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.outstandingReceivableOrPayable}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.outstandingReceivableOrPayable()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch((err) => {
            Console.error("Error", err);
        })
    }

    getTopPayables(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.topPayables}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.topPayables();
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch((err) => {
            Console.error("Error", err);
        })
    }

    getTopReceivables(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.topReceivables}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.topReceivables();
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult);
        }).catch((err) => {
            Console.error("Error", err);
        })
    }

    getUnreconciledPayment(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.unreconcilledPayment}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getUnreconciledPayment();
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getUnreconciledReceivable(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.unreconcilledReceivable}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getUnreconciledReceivable()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getDisputes(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.disputes}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts', 'disputes'], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.disputes()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getTotalInvoiceVsTotalCollections(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.totalInvoiceVsTotalCollections}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getTotalInvoiceVsTotalCollections()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getTotalOrderVsTotalPayment(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.totalOrderVsTotalPayment}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getTotalOrderVsTotalPayment()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getSavings(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.savings}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getSavings()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getMonthlyPayables(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.monthlyPayables}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getMonthlyPayment()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }

    getMonthlyReceivables(isFromEvent) {
        let channelName = `${this.paramID}/${finances.overview.monthlyReceivables}`
        if (!EventUtils.checkCanRefresh(channelName, ['receipts',], isFromEvent)) {
            return;
        }
        return RedisClientSocket.getInstance().connect().then(data => {
            return Charts.getInstance().financesOverview.getMonthlyReceivables()
        }).then(graphDBResult => {
            return RedisClientSocket.getInstance().sendGraphDBResult(channelName, graphDBResult)
        }).catch(err => {
            Console.error("Error", err);
        })
    }
}

export default Finances;