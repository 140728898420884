import React from 'react';
import { Button, Select, message, Table, AutoComplete, Checkbox } from 'antd';
import BaseComponent from '../../main/BaseComponent';
import * as Utils from '../../../util/utils';
import NetworkBridge from '../../../util/network-bridge';
import { EditableCell, EditableFormRow } from '../../components/editable-table';
import CircularProgress from '../../../components/CircularProgress';

const { Option } = Select;

class ItemMapping extends BaseComponent {

    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Items Found in Document',
                dataIndex: 'item',
                width: '40%'
            },
            {
                title: 'Link With Catalogue',
                dataIndex: 'linkItem',
                // editable: true,
                render: (value, row, index) => {
                    let itemSuggestionList = this.state.itemSuggestionList || [];
                    const addSameItem = {
                        identifier: 'ADD_SAME_ITEM',
                        name: 'Add Same Item'
                    }
                    // itemSuggestionList.push(addSameItem);
                    return (

                        <AutoComplete
                            style={{ width: 200 }}
                            onSelect={(val) => this.onSelectItem(val, row)}
                            onSearch={this.handleItemAutoCompleteSearch}
                            onBlur={() => {
                                this.handleItemChange(row, value);
                            }}
                            placeholder="Enter Item name"
                            notFoundContent={
                                <div
                                    onClick={() => this.addSameItem(row)}
                                    style={{ cursor: "pointer" }}
                                >
                                    Add Same Item
                                </div>
                            }
                        >
                            {
                                this.state.itemSuggestionList.map((value, index) => (
                                    <Option key={value.identifier} value={index.toString()}>
                                        {value.name}
                                    </Option>
                                ))
                            }
                        </AutoComplete>
                    );
                }
            }
        ]
    }

    initStates() {
        this.state = {
            showLoader: false,
            originalItemData: this.props.item,
            linkableItems: [],
            dataSource: [],
            itemSuggestionList: [],
            addSameItemForUnselectedFields: false
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getAllItemsInDoc()
    }

    getAllItemsInDoc = () => {
        const { originalItemData } = this.state;
        let item = []
        for (let index in originalItemData) {
            item.push({
                key: index,
                item: originalItemData[index].item,
                itemId: originalItemData[index].internalId,
                itemType: originalItemData[index].type,
                linkItem: ''
            })
        }
        this.setState({ dataSource: item })
    }

    validateLinkCatalogue = () => {
        const { dataSource, addSameItemForUnselectedFields } = this.state;
        let unSelectedItems = [];
        for (let elem in dataSource) {
            if (!dataSource[elem].linkItem) {
                unSelectedItems.push({ item: dataSource[elem], originalIndex: elem });
            }
        }
        if (unSelectedItems.length > 0) {
            if (addSameItemForUnselectedFields) {
                let itemManager = NetworkBridge.getItemManager();
                this.setState({ showInnerSpinner: true });
                const addSameItems = (index) => {
                    return itemManager.addSameItem(unSelectedItems[index].item).then((res) => {
                        dataSource[unSelectedItems[index].originalIndex].linkItem = res
                        if (index + 1 === unSelectedItems.length) {
                            return;
                        }
                        return addSameItems(index + 1);
                    })
                }
                return addSameItems(0).then(() => {
                    this.linkCatalogue();
                }).catch(err => {
                    message.error("Some error occured while adding same items");
                    console.error("Some error occured while adding same items");
                }).finally(() => {
                    this.setState({ showInnerSpinner: false });
                })
            }
            message.error("Please select all items with existing catalogue or enable the checkbox to add same item");
            return;
        }
        return this.linkCatalogue();
    }

    linkCatalogue = () => {
        const { dataSource } = this.state;
        console.log("dataSource", dataSource)
        let itemManager = NetworkBridge.getItemManager();
        const { providerParamId, customerParamId } = this.props;
        this.setState({ showLoader: true })
        let promiseArray = [];
        for (let index = 0; index < dataSource.length; index++) {
            promiseArray.push(
                itemManager.mapItemsV1(dataSource[index].linkItem, dataSource[index].itemId, customerParamId, providerParamId, "-1", dataSource[index].linkItemName, dataSource[index].item)
            )
        }

        return Promise.all(promiseArray).then(res => {
            message.success('Catalogue linked successfully');
            return this.props.afterLinkCatalogue(this.state.originalItemData);
        }).catch(err => {
            console.error(err);
            message.error('Failed to link catalogue')
        }).finally(() => {
            this.setState({ showLoader: false })
        })
    }

    handleItemAutoCompleteSearch = (searchText) => {
        if (searchText && searchText.length >= 3) {
            const type = ['Items']
            const catalogueManager = NetworkBridge.getItemManager();
            const owner = Utils.getParamId();
            this.setState({ itemSearchInProgress: true });
            catalogueManager.searchItem(searchText, owner, type).then(res => {
                console.log(res);
                this.setState({ itemSuggestionList: res.Items, itemSearchInProgress: false, searchResultEmpty: (res.Items.length === 0) });
            })
        }
        else {
            this.setState({ itemSuggestionList: [], searchResultEmpty: false });
        }
    }

    onSelectItem = (value, record) => {
        let { originalItemData } = this.state;
        const selectedRecord = this.state.itemSuggestionList[value];
        if (selectedRecord) {
            record.linkItem = selectedRecord.identifier;
            record.linkItemName = selectedRecord.name;
            for (let index in originalItemData) {
                if (originalItemData[index].internalId === record.itemId) {
                    originalItemData[index].buyerItem = {
                        "@id": selectedRecord.identifier,
                        "name": selectedRecord.name
                    };
                    originalItemData[index].sellerItem = {
                        "@id": record.itemId,
                        "name": record.name
                    };
                    if (!originalItemData[index].orderItemMapping) {
                        originalItemData[index].orderItemMapping = {};
                    }

                    originalItemData[index].orderItemMapping.mappings = {
                        "@id": selectedRecord,
                        "name": selectedRecord.name
                    }
                }
            }

            this.setState({ originalItemData });
        }
    }

    addSameItem = (record) => {
        this.setState({ showInnerSpinner: true });
        return NetworkBridge.getItemManager().addSameItem(record).then((itemId) => {
            message.success("Same item added successfully");
        }).catch(err => {
            console.error("Error in adding same item", err)
        }).finally(() => {
            this.setState({ showInnerSpinner: false })
        })
    }

    handleItemChange = (row, value) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        this.setState({ dataSource: newData });
    };

    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    };

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };

    handleAddSameCheckBox = (e) => {
        this.setState({ addSameItemForUnselectedFields: e.target.checked });
    }

    renderMainContent() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        return (
            <div style={{ marginTop: '1rem' }}>
                {/* {this.state.showInnerSpinner && <CircularProgress type="spinner" />} */}
                <Checkbox onChange={this.handleAddSameCheckBox}>
                    Add same items for all empty fields
                </Checkbox>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    loading={this.state.showInnerSpinner}
                />
                <div style={{ position: 'absolute', bottom: 0 }}>
                    <Button type="primary" onClick={this.validateLinkCatalogue}>Map</Button>
                </div>
            </div>
        )
    }

}
export default ItemMapping;