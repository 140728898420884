import React, { Component } from 'react';
import { Row, Col, Radio } from 'antd';

class LinkPurchaseCard extends Component {
    render() {
        // console.log('-LINKKKKK', this.props)
        return (
            <div>
                <div className="linkpurchase">
                    <Row className="linkpurchase__card" style={{marginTop: '1em'}}>
                        <Row style={{ width: '100%' }}>
                            <Col span={2}><Radio value={this.props.radioValue} style={{ display: "inlineBlock", marginLeft: '1rem' }} /></Col>
                            <Col span={16}>
                                <div>
                                    <div>
                                        <span>Invoice Id :  </span>
                                        <span>{this.props.internalId}</span>
                                    </div>
                                    <div>
                                        <span >Type :  </span>
                                        <span>{this.props.type}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6} offset={2} style={{ float: 'right' }}>
                                <div style={{ color: 'green', textAlign: 'right' }}>{this.props.price}</div>
                                <p style={{ textAlign: 'right' }}>{this.props.time}</p>
                            </Col>
                        </Row>


                    </Row>
                </div>
                <hr className='dotted' style={{ margin: "0" }}></hr>
            </div>

        )
    }
}

export default LinkPurchaseCard;