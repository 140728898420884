class VersionControl {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    // Discuss
    getNodePublicFor(from, to, className) {
        return this.mongoDBConnector.invoke('versionControl', 'getNodePublicFor', from, to, className);
    }

    getContractToNodeMapping(contractRootAddress) {
        return this.mongoDBConnector.invoke('versionControl', 'getContractToNodeMapping', contractRootAddress);
    }

    getNodeToContractMapping(paramId, contractName) {
        return this.mongoDBConnector.invoke('versionControl', 'getNodeToContractMapping', paramId, contractName);
    }

    getParticipants(contractAddress) {
        return this.mongoDBConnector.invoke('versionControl', 'getParticipants', contractAddress);
    }

    getLatestContractByRoot(contractRootAddress) {
        return this.mongoDBConnector.invoke('versionControl', 'getLatestContractByRoot', contractRootAddress);
    }

    getLatestContractAddressFromName(paramId, contractName) {
        return this.mongoDBConnector.invoke('versionControl', 'getLatestContractAddressFromName', paramId, contractName);
    }

    getLatestContractAddressFromNameAndNodeId(contractName, nodeId) {
        return this.mongoDBConnector.invoke('versionControl', 'getLatestContractAddressFromNameAndNodeId', contractName, nodeId);
    }

    getContractAddressFromName(contractName) {
        return this.mongoDBConnector.invoke('versionControl', 'getContractAddressFromName', contractName);
    }

    //One condition missing, change the status of the previous active contract to 1 instead of 0
    addContractAddress(nodeId, contractRootAddress, contractName, contractAddress, type, status, nodeIds, dateTime, metaInfo) {
        return this.mongoDBConnector.invoke('versionControl', 'addContractAddress', nodeId, contractRootAddress, contractName, contractAddress, type, status, nodeIds, dateTime, metaInfo);
    }

    // Need more info about this function
    addContractRootAddressForNodes(contractRootAddress, nodeIds) {
        return this.mongoDBConnector.invoke('versionControl', 'addContractRootAddressForNodes', contractRootAddress, nodeIds);
    }

    // If status is 0 then change all the other status as 1
    updateStatus(contractAddress, status, contractRootAddress, contractName) {
        return this.mongoDBConnector.invoke('versionControl', 'updateStatus', contractAddress, status, contractRootAddress, contractName);
    }

    contractStatus(contractAddress) {
        return this.mongoDBConnector.invoke('versionControl', 'contractStatus', contractAddress);
    }

    updateContract(nodeId, contractRootAddress, contractName, contractAddress, type, status, nodeIds, dateTime, metaInfo, forSync) {
        return this.mongoDBConnector.invoke('versionControl', 'updateContract', nodeId, contractRootAddress, contractName, contractAddress, type, status, nodeIds, dateTime, metaInfo, forSync);
    }

    getVersionJSON(contractRootAddress, contractName, contractAddress, type, status, dateTime, metaInfo) {
        return this.mongoDBConnector.invoke('versionControl', 'getVersionJSON', contractRootAddress, contractName, contractAddress, type, status, dateTime, metaInfo);
    }

    doesExist(contractRootAddress, contractAddress) {
        return this.mongoDBConnector.invoke('versionControl', 'doesExist', contractRootAddress, contractAddress);
    }

    getContractRootAddressByNodeId(nodeId) {
        return this.mongoDBConnector.invoke('versionControl', 'getContractRootAddressByNodeId', nodeId);
    }

}

export default VersionControl;