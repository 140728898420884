import React from "react";
import { Row, Col, Icon } from "antd";
import { ReactComponent as WorkflowIcon } from '../../assets/images/workflow.svg';
import { Link } from 'react-router-dom';
import Widget from "../../components/Widget/index";
import './FlyingBird.less';


const FlyingBird = ({ count, logo, title, description, path, item, className, callBack, logoStyle }) => {
  return (
    <Widget noBorder={true} onclickCallback={callBack} item={item} styleName={className}>
      <div className="gx-media gx-align-items-center gx-mb-4">

        <span className="gx-widget-badge-style">{count}</span>

        <Row className="flyingBird">
          <Col className="flyingBird-logo"><Icon component={WorkflowIcon} style={{ fontSize: '30px' }} /></Col>
          <Col className="flyingBird-title" align="left" >
            <h2>
              {title}
            </h2>
          </Col>
        </Row>
        {/* <div className="gx-media-body">
          <h2 className="gx-mb-1">
            {title}
          </h2>
          <p className="gx-text-grey gx-mb-0"></p> 
        </div> */}
      </div>
      <p className="gx-mb-1 flyingBird-description">{description}</p>

    </Widget>
  );
};

export default FlyingBird;
