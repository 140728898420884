import React from "react";
import { Icon, Tooltip } from "antd";
import * as Utils from '../../util/utils';
import { ReactComponent as image } from '../../assets/images/CircleNotification.svg';
import './NotificationItem.less';

const NotificationItem = (props) => {
  const { notification, onClick, onClickDelete, onClickRead, markAsRead } = props;
  let fontStyle, backgroundStyle = {
    padding: '16px'
  };
  if (Array.isArray(notification.owner)) {
    for (let index in notification.owner) {
      if (notification.owner[index] !== Utils.getParamId()) {
        notification.owner = notification.owner[index];
        break;
      }
    }
  }

  return (
    <li className="gx-media" style={backgroundStyle} >
      {
        !markAsRead ?
          <Icon type="star" className="gx-mt-2 gx-mr-3" />
          :
          null
      }
      <Icon className="gx-mr-3 gx-pointer" component={image} style={{ fontSize: '40px' }} onClick={onClick} />
      <div className="gx-media-body gx-align-self-center">
        <span className="gx-font-weight-bold gx-mb-1 gx-pointer" onClick={onClick} >{notification.title}</span>
        <div style={{ float: "right" }}>
          {
            !markAsRead && <Tooltip title="Mark As Read">
              <span className="gx-mb-1 gx-mr-2 gx-pointer" onClick={onClickRead} ><Icon type="check" /></span>
            </Tooltip>
          }
          <Tooltip title="Delete Notification">
            <span className="gx-mb-1 gx-pointer" onClick={onClickDelete} ><Icon type="delete" /></span>
          </Tooltip>

        </div>
        <div>
          <span className="gx-fs-sm gx-mb-1 gx-text-grey gx-pointer}" onClick={onClick}>By {Utils.trimNumbers(notification.owner.toString())}...</span>
          <span className="gx-fs-xs gx-mb-1 gx-mt-1" style={{ float: "right" }}>{notification.time}</span>
        </div>
      </div>
    </li >
  );
};

export default NotificationItem;
