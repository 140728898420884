import Console from '../../../logger/index';
import { gql } from '@apollo/client';
import GraphQl from '../index'
import Database from '../../../database/index';
import RestoreReturns from '../sync/returns';
import TemplateConsensusRestore from '../sync/template-cons'
import * as NetworkUtils from '../../../../src/util/utils';
import NetworkFormat from '../../../database/nosql/Utils/network-data-format';
import GraphQL from '../index';

class TemplateConsensusEvents {

    constructor(apolloClient) {
        this.apolloClient = apolloClient;
        this.dbInstance = Database.getInstance().getDB()
    }

    registerEvents(paramId) {
        //creator
        let onTemplateOptions = {
            query: gql`
               subscription onTemplateAttached($paramId: [String]){
                    onTemplateAttached(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        templateConsID,
                        creator,
                        docStep,
                        docId,
                        docIds
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        let that = this
        this.apolloClient.subscribe(onTemplateOptions).subscribe({
            next(data) {
                that._onTemplateAttached(data)
            },
            error(error) {
                Console.error(`Unable to get create TemplateCons details form network, Reson: ${error}`);
            },
        });

        let onTemplateSubscriberAddedOptions = {
            query: gql`
               subscription onTemplateSubscriberAdded($paramId: [String]){
                    onTemplateSubscriberAdded(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        templateConsId,
                        creator,
                        subscribers,
                        docId,
                        docStep
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(onTemplateSubscriberAddedOptions).subscribe({
            next(data) {
                that._onTemplateSubscriberAdded(data)
            },
            error(error) {
                Console.error(`Unable to get TemplateSubscriberAdded details form network, Reson: ${error}`);
            },
        });

        let onStepSignedOptions = {
            query: gql`
                subscription onStepSignedConsensus($paramId: [String]){
                    onStepSignedConsensus(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        stepConID,
                        templateConsID,
                        subscribers,
                        owner,
                        assigned,
                        currentStep
                    }
                }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(onStepSignedOptions).subscribe({
            next(data) {
                that._onStepSigned(data)
            },
            error(error) {
                Console.error(`Unable to get stepSigned details form network, Reson: ${error}`);
            },
        });

        let templateCompletedOptions = {
            query: gql`
                subscription onTemplateCompleted($paramId: [String]){
                    onTemplateCompleted(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        templateConsID,
                        subscribers,
                        owner
                    }
            }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(templateCompletedOptions).subscribe({
            next(data) {
                debugger
                that._onTemplateCompleted(data)
            },
            error(error) {
                Console.error(`Unable to get onTemplateCompleted details form network, Reson: ${error}`);
            },
        });

        let onStepAssignedOptions = {
            query: gql`
                subscription onStepAssignedConsensus($paramId: [String]){
                    onStepAssignedConsensus(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        stepConID,
                        templateConsID,
                        subscribers,
                        owner,
                        assigned,
                        currentStep
                    }
            }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(onStepAssignedOptions).subscribe({
            next(data) {
                that._onStepAssinged(data)
            },
            error(error) {
                Console.error(`Unable to get stepAssigned details form network, Reson: ${error}`);
            },
        });

        let onTemplateConsensusResetOptions = {
            query: gql`
                subscription onTemplateConsensusReset($paramId: [String]){
                    onTemplateConsensusReset(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        templateConsID,
                        subscribers,
                        owner
                      }
            }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(onTemplateConsensusResetOptions).subscribe({
            next(data) {
                that._onTemplateConsensusReset(data)
            },
            error(error) {
                Console.error(`Unable to get templateConsensusReset details form network, Reson: ${error}`);
            },
        });

        let templateConsMergedOptions = {
            query: gql`
                subscription onTemplateConsensusMerged($paramId: [String]){
                    onTemplateConsensusMerged(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        mergeId,
                        creator,
                        templateConsIds,
                        templateContractAddress,
                        subscribers
                      }
            }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(templateConsMergedOptions).subscribe({
            next(data) {
                that._onTemplateConsensusMerged(data)
            },
            error(error) {
                Console.error(`Unable to get templateConsensusReset details form network, Reson: ${error}`);
            },
        });

        let templateAddForDocIdsOptions = {
            query: gql`
                subscription onTemplateAttachedForDocIds($paramId: [String]){
                    onTemplateAttachedForDocIds(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        templateConsID,
                        creator,
                        docStep,
                        docId,
                        docIds
                      }
            }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(templateAddForDocIdsOptions).subscribe({
            next(data) {
                that._onTemplateAttachedForDocIds(data)
            },
            error(error) {
                Console.error(`Unable to get templateConsensusReset details form network, Reson: ${error}`);
            },
        });

        //onTemplateSubscriberAddedForDocIds
        let templateSubAddForDocIdsOptions = {
            query: gql`
                subscription onTemplateSubscriberAddedForDocIds($paramId: [String]){
                    onTemplateSubscriberAddedForDocIds(TemplateConsOwnerIDs: $paramId){
                        contractAddress,
                        templateConsId,
                        creator,
                        subscribers,
                        docStep,
                        docIds
                      }
            }`,
            variables: { paramId: [paramId] }
        }
        this.apolloClient.subscribe(templateSubAddForDocIdsOptions).subscribe({
            next(data) {
                that._onSubscriberAddedForDocIds(data)
            },
            error(error) {
                Console.error(`Unable to get templateConsensusReset details form network, Reson: ${error}`);
            },
        });

        let onMissingNonce = {
            query: gql`
            subscription onMissingNonce($paramID: String!){
                onMissingNonce(paramID: $paramID){
                    ParamID,
                    NonceJSON
                }
            }`,
            variables: { paramID: paramId }
        }
        this.apolloClient.subscribe(onMissingNonce).subscribe({
            next(data) {
                that._onMissingNonce(data.data.onMissingNonce);
            },
            error(error) {
                Console.error(`Unable to get MissingNonce details from network, Reason: ${error}`);
            },
        });

        Console.log("Registered events for template cons ", paramId)
    }

    _onMissingNonce(subscriptionDataJson) {
        Console.info(subscriptionDataJson);
        if (!subscriptionDataJson) {
            return;
        }
        let arr = subscriptionDataJson.NonceJSON;
        let promiseArray = [];
        for (let index in arr) {
            let value = arr[index];
            let signedData = NetworkUtils.codeSignPaylaod(JSON.parse(value));
            promiseArray.push(GraphQL.getInstance().sendRawTxn(signedData, null));
        }
        return Promise.all(promiseArray);
    }

    _onTemplateAttached(subscriptionDataJson) {
        Console.info(`[GraphQL] Got _onTemplateAttached event`, subscriptionDataJson);
        debugger
        let dataJSON = { args: {} }
        let txId = subscriptionDataJson.data.onTemplateAttached.templateConsID
        let transactionData = NetworkUtils.getTransactionData(subscriptionDataJson.data.onTemplateAttached.templateConsID)
        dataJSON.args.templateConsId = transactionData.id
        dataJSON.args.docId = subscriptionDataJson.data.onTemplateAttached.docId
        dataJSON.args.contractAddress = subscriptionDataJson.data.onTemplateAttached.contractAddress
        dataJSON.args.creator = subscriptionDataJson.data.onTemplateAttached.creator
        dataJSON.args.docStep = subscriptionDataJson.data.onTemplateAttached.docStep
        dataJSON.args.docIds = subscriptionDataJson.data.onTemplateAttached.docIds
        this.dbInstance.emitEvent("onTemplateAttached", dataJSON.args.templateConsId, dataJSON.args.docId, dataJSON.args);
        let grapQlInstance = GraphQL.getInstance()
        return grapQlInstance.getEventDetails(txId, NetworkUtils.getEventType('templateCons'), "RecordID", ["onTemplateAttached"]).then(res => {
            let result = NetworkUtils.getLatestEventDataByName(res)
            let eventMetaData = NetworkUtils.extractEventData(result)
            dataJSON = { ...dataJSON, ...eventMetaData }
            return this.writeonTemplateAttached(dataJSON)
        })
    }

    _onTemplateAttachedForDocIds(subscriptionDataJson) {
        Console.info(`[GraphQL] Got _onTemplateAttachedForDocIds event`, subscriptionDataJson);
        debugger;
        let dataJSON = { args: {} }
        let txId = subscriptionDataJson.data.onTemplateAttachedForDocIds.templateConsID
        let transactionData = NetworkUtils.getTransactionData(subscriptionDataJson.data.onTemplateAttachedForDocIds.templateConsID)
        dataJSON.args.contractAddress = subscriptionDataJson.data.onTemplateAttachedForDocIds.contractAddress
        dataJSON.args.templateConsId = transactionData.id
        dataJSON.args.creator = subscriptionDataJson.data.onTemplateAttachedForDocIds.creator
        dataJSON.args.docStep = subscriptionDataJson.data.onTemplateAttachedForDocIds.docStep
        dataJSON.args.docIds = subscriptionDataJson.data.onTemplateAttachedForDocIds.docIds
        dataJSON.args.docId = subscriptionDataJson.data.onTemplateAttachedForDocIds.docId
        this.dbInstance.emitEvent("onTemplateAttachedForDocIds", dataJSON.args.templateConsId, dataJSON.args.docId, dataJSON.args);
        let grapQlInstance = GraphQL.getInstance()
        return grapQlInstance.getEventDetails(txId, NetworkUtils.getEventType('templateCons'), "RecordID", ["onTemplateAttachedForDocIds"]).then(res => {
            let result = NetworkUtils.getLatestEventDataByName(res)
            let eventMetaData = NetworkUtils.extractEventData(result)
            dataJSON = { ...dataJSON, ...eventMetaData }
            return this.writeonTemplateAttachedForDocIds(dataJSON)
        })
    }

    _onStepAssinged(subscriptionDataJson) {
        Console.info(`[GraphQL] Got _onStepAssinged event`);
        debugger;
        let dataJSON = { args: {} }
        dataJSON.args.templateConsId = subscriptionDataJson.data.onStepAssignedConsensus.templateConsID
        dataJSON.args.contractAddress = subscriptionDataJson.data.onStepAssignedConsensus.contractAddress
        dataJSON.args.stepConId = NetworkUtils.getTransactionData(subscriptionDataJson.data.onStepAssignedConsensus.stepConID).id
        dataJSON.args.subscribers = subscriptionDataJson.data.onStepAssignedConsensus.subscribers
        dataJSON.args.owner = subscriptionDataJson.data.onStepAssignedConsensus.owner
        dataJSON.args.assigned = subscriptionDataJson.data.onStepAssignedConsensus.assigned

        return TemplateConsensusRestore.addSubsriber(dataJSON.args.templateConsId, dataJSON.args.contractAddress, dataJSON.args.subscribers, dataJSON.args.stepConId, dataJSON.args.assigned, "onAssign").then(() => {
            return this.dbInstance.emitEvent("onStepAssinged", dataJSON.args.templateConsId, dataJSON.args);
        })
            .then(() => {
                // let { id } = NetworkUtils.getTransactionData(dataJSON.args.templateConsId);
                return TemplateConsensusRestore.restoreAllTransactions(dataJSON.args.templateConsId, dataJSON.args.contractAddress).then(() => {
                    return this.dbInstance.emitEvent("onStepAssigned", dataJSON.args.templateConsId);
                })
            })
    }

    _onStepSigned(subscriptionDataJson) {
        Console.info(`[GraphQL] Got _onStepSigned event`, subscriptionDataJson);
        debugger;
        let dataJSON = { args: {} }
        dataJSON.args.templateConsId = subscriptionDataJson.data.onStepSignedConsensus.templateConsID
        dataJSON.args.stepConId = NetworkUtils.getTransactionData(subscriptionDataJson.data.onStepSignedConsensus.stepConID).id
        dataJSON.args.contractAddress = subscriptionDataJson.data.onStepSignedConsensus.contractAddress
        dataJSON.args.currentStep = subscriptionDataJson.data.onStepSignedConsensus.currentStep
        dataJSON.args.subscribers = subscriptionDataJson.data.onStepSignedConsensus.subscribers
        dataJSON.args.owner = subscriptionDataJson.data.onStepSignedConsensus.owner
        dataJSON.args.assigned = subscriptionDataJson.data.onStepSignedConsensus.assigned
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress)

        return TemplateConsensusRestore.updateStepConsensusStatus(dataJSON.args.templateConsId, dataJSON.args.stepConId, dataJSON.args.contractAddress, true).then(() => {
            let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
            return this.dbInstance.templateConsensusDB.updateTemplateConsensusCurrentStep(txId, dataJSON.args.currentStep);
        }).then(response => {
            return this.dbInstance.emitEvent("onStepSigned", dataJSON.args.templateConsId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to update onStepSigned ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(txId, NetworkUtils.getEventType('templateCons'), "RecordID", ["onStepSigned"]).then(res => {
                let result = NetworkUtils.getLatestEventDataByName(res)
                let eventMetaData = NetworkUtils.extractEventData(result)
                dataJSON = { ...dataJSON, ...eventMetaData }
                return this.writeonStepSigned(dataJSON);
            })
        })
    }

    _onTemplateSubscriberAdded(subscriptionDataJson) {
        Console.info(`[GraphQL] Got _onTemplateSubscriberAdded event`, subscriptionDataJson);
        debugger
        let dataJSON = { args: {} }
        let txId = subscriptionDataJson.data.onTemplateSubscriberAdded.templateConsId
        let transactionData = NetworkUtils.getTransactionData(subscriptionDataJson.data.onTemplateSubscriberAdded.templateConsId)
        dataJSON.args.templateConsId = transactionData.id
        dataJSON.args.contractAddress = subscriptionDataJson.data.onTemplateSubscriberAdded.contractAddress
        dataJSON.args.subscribers = subscriptionDataJson.data.onTemplateSubscriberAdded.subscribers
        dataJSON.args.docId = subscriptionDataJson.data.onTemplateSubscriberAdded.docId
        dataJSON.args.docStep = subscriptionDataJson.data.onTemplateSubscriberAdded.docStep
        dataJSON.args.creator = subscriptionDataJson.data.onTemplateSubscriberAdded.creator

        return TemplateConsensusRestore.addSubsriber(dataJSON.args.templateConsId, dataJSON.args.contractAddress, dataJSON.args.subscribers).then(() => {
            this.dbInstance.emitEvent("onSubscriberAdded", dataJSON.args.templateConsId, dataJSON.args.docId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to add template consensus ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(txId, NetworkUtils.getEventType('templateCons'), "RecordID", ["onSubscriberAdded"]).then(res => {
                let result = NetworkUtils.getLatestEventDataByName(res)
                let eventMetaData = NetworkUtils.extractEventData(result)
                dataJSON = { ...dataJSON, ...eventMetaData }
                return this.writeonSubscriberAdded(dataJSON);
            })
        })
    }

    _onSubscriberAddedForDocIds(subscriptionDataJson) {
        // contractAddress, templateConsId, creator, subscribers, docStep, docIds
        Console.info(subscriptionDataJson);
        debugger
        let dataJSON = { args: {} }
        let txId = subscriptionDataJson.data.onTemplateSubscriberAddedForDocIds.templateConsId
        let transactionData = NetworkUtils.getTransactionData(subscriptionDataJson.data.onTemplateSubscriberAddedForDocIds.templateConsId)
        dataJSON.args.templateConsId = transactionData.id
        dataJSON.args.contractAddress = subscriptionDataJson.data.onTemplateSubscriberAddedForDocIds.contractAddress
        dataJSON.args.creator = subscriptionDataJson.data.onTemplateSubscriberAddedForDocIds.creator
        dataJSON.args.subscribers = subscriptionDataJson.data.onTemplateSubscriberAddedForDocIds.subscribers
        dataJSON.args.docStep = subscriptionDataJson.data.onTemplateSubscriberAddedForDocIds.docStep
        dataJSON.args.docIds = subscriptionDataJson.data.onTemplateSubscriberAddedForDocIds.docIds

        return TemplateConsensusRestore.addSubsriber(dataJSON.args.templateConsId, dataJSON.args.contractAddress, dataJSON.args.subscribers).then(() => {
            this.dbInstance.emitEvent("onSubscriberAdded", dataJSON.args.templateConsId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to add template consensus ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(txId, NetworkUtils.getEventType('templateCons'), "RecordID", ["onSubscriberAddedForDocIds"]).then(res => {
                let result = NetworkUtils.getLatestEventDataByName(res)
                let eventMetaData = NetworkUtils.extractEventData(result)
                dataJSON = { ...dataJSON, ...eventMetaData }
                return this.writeonSubscriberAddedForDocIds(dataJSON);
            })
        })
    }

    _onTemplateCompleted(subscriptionDataJson) {
        Console.info(subscriptionDataJson)
        debugger
        Console.debug(subscriptionDataJson)
        let dataJSON = { args: {} };
        let txId = subscriptionDataJson.data.onTemplateCompleted.templateConsID
        dataJSON.args.templateConsId = NetworkUtils.getTransactionData(subscriptionDataJson.data.onTemplateCompleted.templateConsID).id
        dataJSON.args.contractAddress = subscriptionDataJson.data.onTemplateCompleted.contractAddress
        dataJSON.args.owner = subscriptionDataJson.data.onTemplateCompleted.owner
        dataJSON.args.subscribers = subscriptionDataJson.data.onTemplateCompleted.subscribers
        return TemplateConsensusRestore.updateTemplateCompleted(dataJSON.args.templateConsId, dataJSON.args.contractAddress, true).then(response => {
            this.dbInstance.emitEvent("onTemplateCompleted", dataJSON.args.templateConsId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to update onTemplateCompleted ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(txId, NetworkUtils.getEventType('templateCons'), "RecordID", ["onTemplateCompleted"]).then(res => {
                let result = NetworkUtils.getLatestEventDataByName(res)
                let eventMetaData = NetworkUtils.extractEventData(result)
                dataJSON = { ...dataJSON, ...eventMetaData }
                return this.writeonTemplateCompleted(dataJSON);
            })
        })
    }

    _onTemplateConsensusMerged(subscriptionDataJson) {
        // contractAddress, mergeId, creator, templateConsIds, templateContractAddress, subscribers
        Console.info(subscriptionDataJson)
        debugger
        let dataJSON = { args: {} }
        dataJSON.args.templateConsIds = subscriptionDataJson.data.onTemplateConsensusMerged.templateConsIds
        dataJSON.args.contractAddress = subscriptionDataJson.data.onTemplateConsensusMerged.contractAddress
        dataJSON.args.mergeId = subscriptionDataJson.data.onTemplateConsensusMerged.mergeId
        dataJSON.args.creator = subscriptionDataJson.data.onTemplateConsensusMerged.creator
        dataJSON.args.templateContractAddress = subscriptionDataJson.data.onTemplateConsensusMerged.templateContractAddress
        dataJSON.args.subscribers = subscriptionDataJson.data.onTemplateConsensusMerged.subscribers

        let templateConsIds = dataJSON.args.templateConsIds;
        for (let index in dataJSON.args.templateConsIds) {
            templateConsIds[index] = NetworkUtils.getTransactionId(templateConsIds[index], dataJSON.address);
        }
        return this.dbInstance.templateConsensusDB.addMergeId(dataJSON.args.mergeId, templateConsIds).then(response => {
            this.dbInstance.emitEvent("onTemplateConsensusMerged", dataJSON.args.mergeId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to add mergeId ${templateConsIds}, reason: ${error}`);
        }).finally(() => {
            return this.writeonTemplateConsensusMerged(dataJSON);
        })
    }

    _onTemplateConsensusReset(subscriptionDataJson) {
        Console.info(`[GraphQL] Got _onTemplateConsensusReset event`);
        let dataJSON = { args: {} }
        debugger;
        let txId = subscriptionDataJson.data.onTemplateConsensusReset.templateConsID
        dataJSON.args.templateConsId = NetworkUtils.getTransactionData(subscriptionDataJson.data.onTemplateConsensusReset.templateConsID).id
        dataJSON.args.contractAddress = subscriptionDataJson.data.onTemplateConsensusReset.contractAddress
        dataJSON.args.owner = subscriptionDataJson.data.onTemplateConsensusReset.owner
        dataJSON.args.subscribers = subscriptionDataJson.data.onTemplateConsensusReset.subscribers

        let templateConsId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        return this.dbInstance.templateConsensusDB.resetTemplateConsensus(templateConsId).then(response => {
            this.dbInstance.emitEvent("onTemplateConsensusReset", dataJSON.args.templateConsId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to reset templateConsId ${templateConsId}, reason: ${error}`);
        }).finally(() => {
            let grapQlInstance = GraphQL.getInstance()
            return grapQlInstance.getEventDetails(txId, NetworkUtils.getEventType('templateCons'), "RecordID", ["onTemplateConsensusReset"]).then(res => {
                let result = NetworkUtils.getLatestEventDataByName(res)
                let eventMetaData = NetworkUtils.extractEventData(result)
                dataJSON = { ...dataJSON, ...eventMetaData }
                return this.writeonTemplateConsensusReset(dataJSON);
            })
        })
    }

    writeonTemplateAttachedForDocIds(dataJSON) {
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template attached");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        metaInfo.currentStep = "0";
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }

    writeonTemplateAttached(dataJSON) {
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template attached");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress); //templateContractAddress, templateId
        metaInfo.currentStep = "0";
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }

    writeonStepAssinged(dataJSON) {
        debugger
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.contractAddress);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Step assinged");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        metaInfo.stepConsId = NetworkUtils.getTransactionId(dataJSON.args.stepConId, dataJSON.contractAddress);
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.owner, metaInfo, dataJSON.args.assigned)
    }

    writeonStepSigned(dataJSON) {
        let templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        return this.getStepId(templateId, dataJSON.args.currentStep).then(step => {
            let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Step signed");
            metaInfo.templateId = templateId;
            metaInfo.currentStep = dataJSON.args.currentStep;
            metaInfo.stepId = step ? step.stepId : null;
            metaInfo.stepNo = step ? step.stepNo : null;
            metaInfo.stepConsId = NetworkUtils.getTransactionId(dataJSON.args.stepConId, dataJSON.args.contractAddress);
            return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.owner, metaInfo);
        }).catch(error => {
            Console.log(`Unable to add ${templateId} onStep Signed, Reason: ${error}`);
        })

    }

    getStepId(templateId, stepIndex) {
        return this.dbInstance.templateRepoDB.getTemplate(templateId).then(templateInfo => {
            let stepId, stepNo;
            for (let index in templateInfo.stepIds) {
                if (Number(templateInfo.stepIds[index].stepNo) === Number(stepIndex)) {
                    stepId = templateInfo.stepIds[index]['@id'];
                    stepNo = templateInfo.stepIds[index]['stepNo'];
                    break;
                }
            }
            return { stepId, stepNo };
        }).catch(error => {
            return null;
        })
    }

    writeonSubscriberAddedForDocIds(dataJSON) {
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Subscriber added");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        this.dbInstance.templateConsensusDB.writeDocStep(txId, dataJSON.args.docStep);
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }

    writeonSubscriberAdded(dataJSON) {
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Subscriber added");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        this.dbInstance.templateConsensusDB.writeDocStep(txId, dataJSON.args.docStep);
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }

    writeonTemplateCompleted(dataJSON) {
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template completed");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        metaInfo.isCompleted = true;
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }


    writeonTemplateConsensusMerged(dataJSON) {
        let transactionsDB = this.dbInstance.transactionsDB;
        let promiseArray = [];
        for (let index in dataJSON.args.templateConsIds) {
            let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsIds[index], dataJSON.args.contractAddress);
            let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template Merged");
            promiseArray.push(transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo));
        }
        return Promise.all(promiseArray);
    }

    writeonTemplateConsensusReset(dataJSON) {
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.args.contractAddress);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template Reset");
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.owner, metaInfo);
    }
}

export default TemplateConsensusEvents;