import React from 'react'
import { Card, Checkbox, Divider, Switch, Row, Col, Button, message } from 'antd';
import BaseComponent from '../BaseComponent';
import * as Utils from '../../../util/utils';
import { connect } from "react-redux";
import { setSalesVisibility, setLSalesVisibility, setLPurchasesVisibility, setPurchasesVisibility, setDraftInvoicesVisibility, setViewWorkflowVisibility, setReportsVisibility, setOperationsVisibility, setSubscribedDocumentsVisibility, setCatalogueVisibility, setContactsVisibility, setCreditNoteTemplateVisibility } from "../../../appRedux/actions/Setting";
import { settings } from 'nprogress';
class Customizations extends BaseComponent {
    constructor(props) {
        super(props)
    }
    initStates() {
        console.log('init States')
        let settingsObject = Utils.getFromLocalStorage('settings')[Utils.getFromLocalStorage("param_id")]
        let salesToggle = settingsObject ? settingsObject["sales"] : true
        let purchaseToggle = settingsObject ? settingsObject["purchases"] : true
        let lSalesToggle = settingsObject ? settingsObject["lsales"] : false
        let lPurchasesToggle = settingsObject ? settingsObject["lpurchases"] : false
        let draftInvoicesToggle = settingsObject ? settingsObject["draftInvoice"] : false
        let autorefresh = Utils.getFromLocalStorage('autoRefresh')
        let viewWorkflow = Utils.getFromLocalStorage('viewWorkflow')
        let reports = settingsObject ? settingsObject["reports"] : true
        let subscribedDocuments = settingsObject ? settingsObject["subscribedDocuments"] : true
        let operations = settingsObject ? settingsObject["operations"] : true
        let catalogue = settingsObject ? settingsObject["catalogue"] : true
        let contacts = settingsObject ? settingsObject["contacts"] : true
        let creditNoteTemplate = settingsObject ? settingsObject["creditNoteTemplate"] : false

        console.log(
            'sales', Utils.getFromLocalStorage('salesToggle'),
            'purchases', Utils.getFromLocalStorage('purchasesToggle'),
            'lsales', Utils.getFromLocalStorage('lSalesToggle'),
            'lpurchases', Utils.getFromLocalStorage('lPurchasesToggle'),
            'draftInvoices', Utils.getFromLocalStorage('draftInvoicesToggle')
        )
        this.state = {
            showSales: salesToggle ? salesToggle : true,
            showPurchases: purchaseToggle ? purchaseToggle : true,
            showLSales: lSalesToggle !== "" ? lSalesToggle : false,
            showLPurchases: lPurchasesToggle !== "" ? lPurchasesToggle : false,
            showDraftInvoices: draftInvoicesToggle != "" ? draftInvoicesToggle : false,
            autorefresh: autorefresh !== "" ? autorefresh : false,
            viewWorkflow: viewWorkflow !== "" ? viewWorkflow : true,
            reports: reports !== "" ? reports : true,
            subscribedDocuments: subscribedDocuments !== "" ? subscribedDocuments : true,
            operations: operations !== "" ? operations : true,
            catalogue: catalogue !== "" ? catalogue : true,
            contacts: contacts !== "" ? contacts : true,
            creditNoteTemplate: creditNoteTemplate !== "" ? creditNoteTemplate : false

        }
        this.props.setSalesVisibility(this.state.showSales)
        this.props.setPurchasesVisibility(this.state.showPurchases)
        this.props.setLSalesVisibility(this.state.showLSales)
        this.props.setLPurchasesVisibility(this.state.showLPurchases)
        this.props.setDraftInvoicesVisibility(this.state.showDraftInvoices)
        this.props.setViewWorkflowVisibility(this.state.viewWorkflow)
    }
    onSalesCheck = () => {
        let toggle = this.state.showSales
        this.setState({
            showSales: !toggle
        })
    }
    onPurchasesCheck = () => {
        let toggle = this.state.showPurchases
        this.setState({
            showPurchases: !toggle
        })
    }
    onLSalesCheck = () => {
        let toggle = this.state.showLSales
        this.setState({
            showLSales: !toggle
        })
    }
    onLPurchasesCheck = () => {
        let toggle = this.state.showLPurchases
        this.setState({
            showLPurchases: !toggle
        })
    }
    onSwitched = () => {
        let toggle = this.state.showDraftInvoices
        this.setState({
            showDraftInvoices: !toggle
        })
    }
    onAutoRefresh = () => {
        let toggle = this.state.autorefresh
        this.setState({
            autorefresh: !toggle
        })
    }

    onOperations = () => {
        let toggle = this.state.operations
        this.setState({
            operations: !toggle
        })
    }
    onReports = () => {
        let toggle = this.state.reports
        this.setState({
            reports: !toggle
        })
    }
    onSubscribedDocuments = () => {
        let toggle = this.state.subscribedDocuments
        this.setState({
            subscribedDocuments: !toggle
        })
    }
    onViewWorkflow = () => {
        let toggle = this.state.viewWorkflow
        this.setState({
            viewWorkflow: !toggle
        })
    }
    onCatalogue = () => {
        let toggle = this.state.catalogue
        this.setState({
            catalogue: !toggle
        })
    }
    onContacts = () => {
        let toggle = this.state.contacts
        this.setState({
            contacts: !toggle
        })
    }
    onCreditNoteTemplate = () => {
        let toggle = this.state.creditNoteTemplate
        this.setState({
            creditNoteTemplate: !toggle
        })
    }

    onSave = () => {
        Utils.setLocalStorage('salesToggle', this.state.showSales)
        Utils.setLocalStorage('purchasesToggle', this.state.showPurchases)
        Utils.setLocalStorage('lSalesToggle', this.state.showLSales)
        Utils.setLocalStorage('lPurchasesToggle', this.state.showLPurchases)
        Utils.setLocalStorage('draftInvoicesToggle', this.state.showDraftInvoices)
        Utils.setLocalStorage('autoRefresh', this.state.autorefresh)
        Utils.setLocalStorage('viewWorkflow', this.state.viewWorkflow)
        Utils.setLocalStorage('reportsVisibility', this.state.reports)
        Utils.setLocalStorage('subscribedDocumentsVisibility', this.state.subscribedDocuments)
        Utils.setLocalStorage('operationsVisibility', this.state.operations)
        Utils.setLocalStorage('catalogueVisibility', this.state.catalogue)
        console.log('Customizations made Successfully', this.state.showSales, this.state.showPurchases, this.state.showLSales, this.state.showLPurchases, this.state.showDraftInvoices, this.state.viewWorkflow)
        this.props.setSalesVisibility(this.state.showSales)
        this.props.setPurchasesVisibility(this.state.showPurchases)
        this.props.setLSalesVisibility(this.state.showLSales)
        this.props.setLPurchasesVisibility(this.state.showLPurchases)
        this.props.setDraftInvoicesVisibility(this.state.showDraftInvoices)
        this.props.setViewWorkflowVisibility(this.state.viewWorkflow)
        this.props.setReportsVisibility(this.state.reports)
        this.props.setOperationsVisibility(this.state.operations)
        this.props.setSubscribedDocumentsVisibility(this.state.subscribedDocuments)
        this.props.setCatalogueVisibility(this.state.catalogue)
        this.props.setContactsVisibility(this.state.contacts)
        this.props.setCreditNoteTemplateVisibility(this.state.creditNoteTemplate)

        let settingsObject = Utils.getFromLocalStorage("settings")[Utils.getFromLocalStorage("param_id")]
        settingsObject["sales"] = this.state.showSales
        settingsObject["purchases"] = this.state.showPurchases
        settingsObject["lsales"] = this.state.showLSales
        settingsObject["lpurchases"] = this.state.showLPurchases
        settingsObject["draftInvoice"] = this.state.showDraftInvoices
        settingsObject["reports"] = this.state.reports
        settingsObject["subscribedDocuments"] = this.state.subscribedDocuments
        settingsObject["operations"] = this.state.operations
        settingsObject["catalogue"] = this.state.catalogue
        settingsObject["contacts"] = this.state.contacts
        settingsObject["creditNoteTemplate"] = this.state.creditNoteTemplate
        let accountSettings = Utils.getFromLocalStorage('settings')
        accountSettings[Utils.getFromLocalStorage("param_id")] = settingsObject
        Utils.setLocalStorage("settings", accountSettings)

        message.success('Changes made successfully')
    }

    renderMainContent() {

        return (
            <Card className="setting__card" title={<h2 className="setting__nomargin">Customization</h2>}
                extra={
                    <Button type='primary' className='setting__nomargin' onClick={this.onSave}>Save</Button>
                }>
                <div>
                    <Checkbox checked={this.state.showSales} onChange={this.onSalesCheck}>Sales</Checkbox>
                    <Checkbox checked={this.state.showPurchases} onChange={this.onPurchasesCheck}>Purchases</Checkbox>
                    <Checkbox checked={this.state.showLSales} onChange={this.onLSalesCheck}>Logistics Sales</Checkbox>
                    <Checkbox checked={this.state.showLPurchases} onChange={this.onLPurchasesCheck}>Logistics Purchases</Checkbox>
                    <Checkbox checked={this.state.autorefresh} onChange={this.onAutoRefresh}>Auto Refresh</Checkbox>
                    <Checkbox checked={this.state.viewWorkflow} onChange={this.onViewWorkflow}>View workflow</Checkbox>
                    <Checkbox checked={this.state.reports} onChange={this.onReports}>Reports</Checkbox>
                    <Checkbox checked={this.state.subscribedDocuments} onChange={this.onSubscribedDocuments}>Subscribed Documents</Checkbox>
                    <Checkbox checked={this.state.operations} onChange={this.onOperations}>Operations</Checkbox>
                    <Checkbox checked={this.state.catalogue} onChange={this.onCatalogue}>Catalogue</Checkbox>
                    <Checkbox checked={this.state.contacts} onChange={this.onContacts}>Contacts</Checkbox>
                    <Checkbox checked={this.state.creditNoteTemplate} onChange={this.onCreditNoteTemplate}>Credit Note Template</Checkbox>

                    <Divider />
                    <Row>
                        <Col xl={4} lg={12} md={12} sm={8} xs={24}>
                            <h4>Draft invoice</h4>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={8} xs={24}>
                            <Switch checked={this.state.showDraftInvoices} onChange={this.onSwitched} />
                        </Col>
                    </Row>
                </div>
            </Card>
        )
    }
}
const mapStateToProps = ({ settings }) => {
    const { salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility, draftInvoicesVisibility, viewWorkflowVisibility, reportsVisibility, operationsVisibility, subscribedDocumentsVisibility, catalogueVisibility, contactsVisibility, creditNoteTemplateVisibility } = settings;
    return { salesVisibility, purchasesVisibility, lSalesVisibility, lPurchasesVisibility, draftInvoicesVisibility, viewWorkflowVisibility, reportsVisibility, operationsVisibility, subscribedDocumentsVisibility, catalogueVisibility, contactsVisibility, creditNoteTemplateVisibility }
};
export default connect(mapStateToProps, { setSalesVisibility, setPurchasesVisibility, setLPurchasesVisibility, setLSalesVisibility, setDraftInvoicesVisibility, setViewWorkflowVisibility, setReportsVisibility, setOperationsVisibility, setSubscribedDocumentsVisibility, setCatalogueVisibility, setContactsVisibility, setCreditNoteTemplateVisibility })(Customizations)