import React from 'react';
import { Drawer, Button, Icon, Row, Col, Empty, message, Modal, Tooltip } from 'antd';
import BaseComponent from '../../main/BaseComponent';
import * as Utils from '../../../util/utils';
import * as IpfsConfig from '../../../param-network/ipfs-config.json'
import './index.less';
import { MessageOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import CircularProgress from '../../../components/CircularProgress';
import IpfsManager from './ipfs-manager';
const { confirm } = Modal;
const isDesktop = false //process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? true : false

class FileAttachment extends BaseComponent {

    constructor(props) {
        super(props);
        this.initStates();
        this.ipfsConfig = IpfsConfig.ipfsNodes;
        this.fileCallback = this.getCallBack();
    }

    initStates() {
        this.state = {
            showLoader: false,
            isDrawerVisible: false,
            isDisabled: false,
            uploadedFileData: this.props.data,
            statusMessage: [],
            type: this.props.type == '1' ? '2' : '1'
        }
    }

    componentDidMount() {
        super.componentDidMount()
        if (isDesktop) {
            IpfsManager.getInstance().registerFileUploadCallback(this.fileCallback);
            IpfsManager.getInstance().registerFileDownloadCallback(this.fileCallback);
        }
        console.log("Is Desktop", isDesktop);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isDrawerVisible !== prevState.isDrawerVisible) {
            if (nextProps.isDisabled !== prevState.isDisabled) {
                return { isDrawerVisible: nextProps.isDrawerVisible, isDisabled: nextProps.isDisabled }
            } else {
                return { isDrawerVisible: nextProps.isDrawerVisible }
            }
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    componentWillUnmount() {
        this.setState({ uploadedFileData: [] })
        if (isDesktop) {
            IpfsManager.getInstance().unRegisterFileUploadCallback(this.fileCallback);
            IpfsManager.getInstance().unRegisterFileDownloadCallback(this.fileCallback);
        }
    }

    getCallBack = () => {
        return {
            onFileSelected: (error, files) => {
                if (error) {
                    console.log(error);
                    message.error("Error in selecting file");
                } else {
                    let uploadedFileData = this.props.data;
                    files.forEach((file) => {
                        file['type'] = this.state.type
                    })
                    this.setState({
                        isNewAdded: true,
                        uploadedFileData: [...uploadedFileData, ...files]
                    }, () => {
                        console.log(this.state.uploadedFileData);
                        this.props.onFileUploadSave(this.state.uploadedFileData);
                    })
                }
            },

            onBatchStarted: (batchData) => {
                console.log("Batch Started");
                console.log(batchData);
                this.setState({
                    showSpinner: true
                })
            },

            onFileCompressionStarted: (fileData) => {
                console.log("onFileCompressionStarted", fileData);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileData.name);
                statusMessage[indexToChange] = `Compressing ${fileData.name}`;
                this.setState({
                    statusMessage
                })
            },

            onFileCompressionCompleted: (fileData) => {
                console.log("onFileCompressionCompleted", fileData);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileData.name);
                statusMessage[indexToChange] = `Compression of ${fileData.name} completed`;
                this.setState({
                    statusMessage
                })
            },

            onFileUploadStarted: (fileData) => {
                console.log("onFileUploadStarted", fileData);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileData.name);
                statusMessage[indexToChange] = `Uploading ${fileData.name}`;
                this.setState({
                    statusMessage
                })
            },

            onFileUploadProgress: (fileData) => {
                console.log("onFileUploadProgress", fileData);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileData.name);
                statusMessage[indexToChange] = `Uploading ${fileData.name}`;
                this.setState({
                    statusMessage
                })
            },

            onFileUploadCompleted: (fileData) => {
                console.log("onFileUploadCompleted", fileData);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileData.name);
                uploadedData[indexToChange] = fileData;
                if (fileData.status === 1) {
                    delete uploadedData[indexToChange]["path"];
                    statusMessage[indexToChange] = `Upload complete for ${fileData.name}`;
                } else {
                    statusMessage[indexToChange] = `Error in uploading ${fileData.name}`;
                }
                this.setState({
                    statusMessage
                }, () => {
                    this.props.onFileUploadSave(uploadedData);
                })
            },

            onBatchCompleted: (batchData) => {
                console.log("onBatchCompleted", batchData);
                if (batchData.status === 1) {
                    let failedCount = 0;
                    batchData.fileInfo.forEach((fileObject) => {
                        if (fileObject.status == 0) {
                            message.error(`Error in uploading ${fileObject.name}, ${fileObject.message}`)
                            failedCount++;
                        }
                    })
                    if (failedCount === 0) {
                        message.success("All files uploaded successfully");
                    } else {
                        message.warning(`${batchData.fileInfo.length - failedCount} files uploaded. ${failedCount} files failed`);
                    }
                } else {
                    message.error("Error in uploading files");
                }
                this.setState({
                    showSpinner: false,
                    isNewAdded: false
                }, () => {
                    this.forceUpdate();
                })
            },

            onFolderSelected: (error, folderPath) => {
                if (error) {
                    console.log("Error: ", error);
                } else {
                    console.log("Folder selected for download", folderPath);
                }
            },

            onBatchDownloadStarted: (batchInfo) => {
                console.log("onBatchDownloadStarted", batchInfo);
                this.setState({
                    showSpinner: true
                })
            },

            onFileDownloadStarted: (fileInfo) => {
                console.log("onFileDownloadStarted", fileInfo);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileInfo.name);
                statusMessage[indexToChange] = `Downloading ${fileInfo.name}`;
                this.setState({
                    statusMessage
                })
            },

            onFileDownloadProgress: (fileInfo) => {
                console.log("onFileDownloadProgress", fileInfo);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileInfo.name);
                statusMessage[indexToChange] = `Downloading ${fileInfo.name}`;
                this.setState({
                    statusMessage
                })
            },

            onFileDownloadCompleted: (fileInfo) => {
                console.log("onFileDownloadCompleted", fileInfo);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileInfo.name);
                if (fileInfo.status === 1) {
                    statusMessage[indexToChange] = `${fileInfo.name} downloaded successfully`;
                } else {
                    statusMessage[indexToChange] = `Error in downloading ${fileInfo.name}`;
                    console.log(fileInfo.message);
                }
                this.setState({
                    statusMessage
                })
            },

            onFileDecompressionStarted: (fileInfo) => {
                console.log("onFileDecompressionStarted", fileInfo);
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileInfo.name);
                statusMessage[indexToChange] = `Extracting ${fileInfo.name}`;
                this.setState({
                    statusMessage
                })
            },

            onFileDecompressionCompleted: (fileInfo) => {
                console.log("onFileDecompressionCompleted", fileInfo)
                let uploadedData = this.props.data;
                let statusMessage = this.state.statusMessage;
                let indexToChange = uploadedData.findIndex(x => x.name === fileInfo.name);
                statusMessage[indexToChange] = `Extraction complete for ${fileInfo.name}`;
                this.setState({
                    statusMessage
                })
            },

            onBatchDownloadCompleted: (fileInfo) => {
                console.log("onBatchDownloadCompleted", fileInfo);
                message.success("Decompression completed successfully");
                this.setState({
                    showSpinner: false
                })
            },
        }
    }

    hideDrawer = () => {
        let that = this;
        let currentData = that.props.data, isHeadsUpVisible = false;
        for (let index in currentData) {
            if (!currentData[index].hasOwnProperty("uploadedFileHash")) {
                isHeadsUpVisible = true
                break;
            }
        }
        if (isHeadsUpVisible) {
            return (
                confirm({
                    title: 'Heads up!',
                    content: `Are you sure, There are some files which are not uploaded?`,
                    onOk() {
                        that.setState({ isDrawerVisible: false }, () => {
                            that.props.onClose();
                        })
                    },
                    onCancel() {
                    },
                })
            )
        }
        that.setState({ isDrawerVisible: false }, () => {
            that.props.onClose();
        })
    }

    onFileUpload = () => {
        document.getElementById('upload').click();
    }

    convertBytesToSize = (bytes) => {
        if (isNaN(Number(bytes))) {
            return bytes
        }
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        let fileSize;
        if (bytes === 0)
            fileSize = '0 Byte';
        let index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        fileSize = Math.round(bytes / Math.pow(1024, index), 2) + ' ' + sizes[index];
        return fileSize;
    }

    uploadFiles = () => {
        IpfsManager.getInstance().uploadAllFiles(this.state.uploadedFileData);
    }

    getFileDescription = (fileName) => {
        let description;
        let data = this.props.data;
        for (let index in data) {
            if (fileName === data[index].name) {
                description = data[index].description ? data[index].description : ""
            }
        }
        return description;
    }

    removeUploadedFile = (index) => {
        let uploadedFileData = this.props.data;
        uploadedFileData.splice(index, 1)
        this.setState({ uploadedFileData }, () => {
            this.props.onFileUploadSave(uploadedFileData)
        })
    }

    downloadFile = (data) => {
        IpfsManager.getInstance().reqDownloadFiles(data);
    }

    downloadAllFiles = (fileArray) => {
        IpfsManager.getInstance().reqDownloadFiles(fileArray);
    }

    editFileDescription = () => {
        let { description, indexOfFileDescToBeEdited } = this.state;
        if (!description || indexOfFileDescToBeEdited === undefined) {
            this.clearEditField();
            return;
        }
        let attachedFiles = this.props.data
        for (let index in attachedFiles) {
            if (Number(index) === indexOfFileDescToBeEdited) {
                attachedFiles[index].description = description;
            }
        }
        this.setState({ editDescription: false }, () => {
            this.props.onFileUploadSave(attachedFiles);
            this.clearEditField();
        })
    }

    openEditDescription = (index, desc) => {
        this.setState({
            description: desc || "",
            indexOfFileDescToBeEdited: index,
            editDescription: true,
        })
    }

    clearEditField = () => {
        this.setState({
            description: "",
            indexOfFileDescToBeEdited: undefined,
            editDescription: false
        })
    }

    openViewDescription = (desc) => {
        this.setState({
            description: desc,
            editDescription: true
        });
    }

    isFileUploaded = (item) => {
        if (item.hasOwnProperty("status")) {
            if (item.status == 1) {
                return <Tooltip title="Uploaded">
                    <Icon type="check-circle" style={{ color: "green", marginRight: '0.5rem' }} />
                </Tooltip>
            }
            else {
                return <Tooltip title="Error in uploading">
                    <Icon type="exclamation-circle" style={{ color: "red", marginRight: '0.5rem' }} />
                </Tooltip>
            }
        } else {
            return <Tooltip title="Not Uploaded">
                <Icon type="exclamation-circle" style={{ marginRight: '0.5rem' }} />
            </Tooltip>
        }
    }

    chooseFile = () => {
        IpfsManager.getInstance().reqOnFileSelect();
    }

    renderMainContent() {
        const { isDrawerVisible } = this.state;
        let uploadedFileData = this.props.data ? this.props.data : [];
        const fileConfig = Utils.getFromLocalStorage('fileUploadConfig');
        let extension = fileConfig ? fileConfig.extension : "";
        if (this.props.isWorkFlow) {
            if (this.props.workflowExt) {
                extension = this.props.workflowExt
            }
        }
        let drawerTitle = <Row>
            <Col span={16}>
                <h2 style={{ margin: 0 }}>{uploadedFileData.length > 0 ? `${uploadedFileData.length} Attachments` : `Attachments`}</h2>
            </Col>
            <Col span={8} >
                {
                    !this.state.isDisabled &&
                    < Button onClick={this.chooseFile}>Attach File</Button>
                }
                {
                    uploadedFileData.length > 0 && this.state.isDisabled &&
                    <Tooltip title="Download All">
                        <Icon type="download" className="fileAttachmentDrawer__download-all" onClick={() => this.downloadAllFiles(uploadedFileData)} />
                    </Tooltip>
                }
            </Col>
        </Row >
        return (
            <Drawer className="fileAttachmentDrawer"
                title={drawerTitle}
                onClose={this.hideDrawer}
                visible={isDrawerVisible}
            >
                <br />
                {uploadedFileData.length === 0 && <Empty className="fileAttachment__empty" />}
                {this.state.showSpinner === true && <CircularProgress type="spinner" />}
                {
                    uploadedFileData.map((item, index) => (
                        <>
                            <Row key={index} className="fileAttachmentDrawer__list">
                                <Col span={20}>
                                    <div>
                                        <span>{this.isFileUploaded(item)}</span>
                                        <span>{item.name}</span>&nbsp;&nbsp;
                                    <span>{this.convertBytesToSize(item.size)}</span>
                                    </div>
                                    <div style={{
                                        fontSize: "0.8rem",
                                        opacity: "0.8",
                                        marginTop: "0.2rem",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}>{item.description}</div>
                                    {
                                        this.state.statusMessage.length > 0 && this.state.statusMessage[index] &&
                                        <p style={{ marginBottom: '0.5rem', fontSize: '0.7rem', marginTop: '0.5rem' }}>Status: {this.state.statusMessage[index]}</p>
                                    }
                                </Col>
                                <Col span={4} align="right">
                                    {
                                        this.state.isDisabled ?
                                            <>
                                                <MessageOutlined onClick={() => { this.openViewDescription(item.description) }} />
                                                {item && item.uploadedFileHash &&
                                                    <Icon style={{ marginLeft: '0.5rem' }} type="download" onClick={() => { this.downloadFile(item) }} />
                                                }
                                            </>
                                            :
                                            <>
                                                <MessageOutlined onClick={() => { this.openEditDescription(index, item.description) }} />
                                                {item && item.uploadedFileHash &&
                                                    < Icon style={{ marginLeft: '0.5rem' }} type="download" onClick={() => { this.downloadFile(item) }} />
                                                }
                                                <Icon style={{ marginLeft: '0.5rem' }} type="delete" onClick={() => { this.removeUploadedFile(index) }} />
                                            </>
                                    }
                                </Col>
                            </Row>
                        </>
                    ))
                }
                {
                    this.state.isNewAdded &&
                    <Button type="primary" onClick={this.uploadFiles} style={{ position: 'absolute', bottom: 0 }} >Start Upload</Button>
                }

                <Modal
                    visible={this.state.editDescription}
                    onOk={this.editFileDescription}
                    onCancel={this.clearEditField}
                    title="Edit file description"
                >
                    <TextArea placeholder="Type here..." onChange={(e) => {
                        this.setState({
                            description: e.target.value
                        })
                    }}
                        value={this.state.description}
                        disabled={this.state.isDisabled}
                    />
                </Modal>
            </Drawer >
        )
    }
}
export default FileAttachment;