
class Items {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    /**
     * 
     * @param {object} owner     {
     *                          primaryOwner/secondaryOwner:""
     *                      }
     * @param {strting} purpose 
     * @param {string} type 
     */
    // map
    getAllItemsByFilter(owner, purpose, itemType, category, subCategory) {
        return this.mongoDBConnector.invoke('items', "getAllItemsByFilter", owner, purpose, itemType, category, subCategory);
    }

    getAllItems(primaryOwner, isLogistics) {
        return this.mongoDBConnector.invoke('items', "getAllItems", primaryOwner, isLogistics);
    }

    getAllCategories(owner) {
        return this.mongoDBConnector.invoke('items', "getAllCategories", owner);
    }

    getAllSubCategories(owner) {
        return this.mongoDBConnector.invoke('items', "getAllSubCategories", owner);
    }

    getAllItemsByCategory(primaryOwner, category) {
        return this.mongoDBConnector.invoke('items', "getAllItemsByCategory", primaryOwner, category);
    }

    getAllItemsBySubCategory(primaryOwner, subCategory) {
        return this.mongoDBConnector.invoke('items', "getAllItemsBySubCategory", primaryOwner, subCategory);

    }

    getAllItemsByCategoryAndSubCategory(primaryOwner, category, subCategory) {
        return this.mongoDBConnector.invoke('items', "getAllItemsByCategoryAndSubCategory", primaryOwner, category, subCategory);

    }

    getCreatedItems(primaryOwner) {
        return this.mongoDBConnector.invoke('items', "getCreatedItems", primaryOwner);


    }

    getAllGoods(primaryOwner) {
        return this.mongoDBConnector.invoke('items', "getAllGoods", primaryOwner);

    }

    getAllServices(primaryOwner) {
        return this.mongoDBConnector.invoke('items', "getAllServices", primaryOwner);

    }

    getAllRawMaterials(primaryOwner) {
        return this.mongoDBConnector.invoke('items', "getAllRawMaterials", primaryOwner);

    }

    getAllReceivedItems(primaryOwner) {
        return this.mongoDBConnector.invoke('items', "getAllReceivedItems", primaryOwner);

    }

    getMappedItemsV1(sellerAddress, buyerAddress) {
        return this.mongoDBConnector.invoke('items', "getMappedItemsV1", sellerAddress, buyerAddress);


    }

    getAllSubscribedItems(owner, subscriber) {
        return this.mongoDBConnector.invoke('items', "getAllSubscribedItems", owner, subscriber);
    }

    // Review
    getAllMappedItemByItemId(itemId) {
        return this.mongoDBConnector.invoke('items', "getAllMappedItemByItemId", itemId);
    }

    getSummary(itemId, predicates) {
        return this.mongoDBConnector.invoke('items', "getSummary", itemId, predicates);


    }

    // Review
    getMappedItemByItemId(itemId, address, idType, addressType) {
        return this.mongoDBConnector.invoke('items', "getMappedItemByItemId", itemId, address, idType, addressType);


    }

    getMappedItemInfo(mappingId) {
        return this.mongoDBConnector.invoke('items', "getMappedItemInfo", mappingId);


    }

    // don't call internal function
    // Review
    getMappedItemInfoSummary(mappingId) {
        return this.mongoDBConnector.invoke('items', "getMappedItemInfoSummary", mappingId);


    }

    // projection ,don't call internal function
    getMappedItemFormattedData(mappingInfo, index) {
        return this.mongoDBConnector.invoke('items', "getMappedItemFormattedData", mappingInfo, index);
    }

    getItemName(itemId) {
        return this.mongoDBConnector.invoke('items', "getItemName", itemId);


    }

    getItem(itemId) {
        return this.mongoDBConnector.invoke('items', "getItem", itemId);

    }

    getItemMaterial(itemId) {
        return this.mongoDBConnector.invoke('items', "getItemMaterial", itemId);


    }

    getItemV1(itemId, address, idType, addressType) {
        return this.mongoDBConnector.invoke('items', "getItemV1", itemId, address, idType, addressType);
    }

    getAllItemsForExport() {
        return this.mongoDBConnector.invoke('items', "getAllItemsForExport");
    }

    searchItemWithMappingInfo(searchString, address, isLogistics) {
        return this.mongoDBConnector.invoke('items', "searchItemWithMappingInfo", searchString, address, isLogistics);
    }

    getInventoryByItemId(itemId) {
        return this.mongoDBConnector.invoke('items', "getInventoryByItemId", itemId);

    }

    getInventory(inventoryId) {
        return this.mongoDBConnector.invoke('items', "getInventory", inventoryId);

    }

    // query needs to update
    getInventoryLevelDetails(itemId, address, idType, addressType) {
        return this.mongoDBConnector.invoke('items', "getInventoryLevelDetails", itemId, address, idType, addressType);


    }

    getFilteredRelatedPoItemQuantity(itemId, filter, filterType) {
        return this.mongoDBConnector.invoke('items', "getFilteredRelatedPoItemQuantity", itemId, filter, filterType);


    }

    getInventoryUpdateHistory(inventoryId) {
        return this.mongoDBConnector.invoke('items', "getInventoryUpdateHistory", inventoryId);

    }

    getItemByInternalId(internalId, owner) {
        return this.mongoDBConnector.invoke('items', "getItemByInternalId", internalId, owner);


    }

    // TODO: join
    getSharedKey(itemId) {
        return this.mongoDBConnector.invoke('items', "getSharedKey", itemId);


    }

    getInventoryIdFromItemId(itemId) {
        return this.mongoDBConnector.invoke('items', "getInventoryIdFromItemId", itemId);


    }


    doesItemMappingExist(itemId, address, idType, addressType) {
        return this.mongoDBConnector.invoke('items', "doesItemMappingExist", itemId, address, idType, addressType);


    }

    doesItemMappingExistByMappingId(sellerItemId, buyerItemId) {
        return this.mongoDBConnector.invoke('items', "doesItemMappingExistByMappingId", sellerItemId, buyerItemId);


    }

    doesExist(itemId) {
        return this.mongoDBConnector.invoke('items', "doesExist", itemId);


    }

    doesInventoryExist(inventoryId) {
        return this.mongoDBConnector.invoke('items', "doesInventoryExist", inventoryId);


    }

    doesInternalIdExist(internalId, owner) {
        return this.mongoDBConnector.invoke('items', "doesInternalIdExist", internalId, owner);
    }

    searchItem(searchText) {
        return this.mongoDBConnector.invoke('items', "searchItem", searchText);
    }

    addItem(itemId, jsonLd, primaryOwner, secondaryOwner, itemShareKey, forSubscriber) {
        return this.mongoDBConnector.invoke('items', "addItem", itemId, jsonLd, primaryOwner, secondaryOwner, itemShareKey, forSubscriber);


    }

    addInventory(inventoryId, jsonLd, itemId, inventoryShareKey, forSubscriber) {
        return this.mongoDBConnector.invoke('items', "addInventory", inventoryId, jsonLd, itemId, inventoryShareKey, forSubscriber);

    }

    addInventoryUpdatableHistory(inventoryId, historyIndex, metaData) {
        return this.mongoDBConnector.invoke('items', "addInventoryUpdatableHistory", inventoryId, historyIndex, metaData);


    }

    /**
     * status - 0 - rejected
     *          1 - approved
     */
    mapItemsV1(buyerItemId, sellerItemId, buyerAddress, sellerAddress, status, buyerItemName, sellerItemName) {
        return this.mongoDBConnector.invoke('items', "mapItemsV1", buyerItemId, sellerItemId, buyerAddress, sellerAddress, status, buyerItemName, sellerItemName);


    }

    // insertMany and call getItemJsonLd to make P1
    addItemForReceipt(jsonLd) {
        return this.mongoDBConnector.invoke('items', "addItemForReceipt", jsonLd);


    }

    addSubscriber(itemId, subscriber) {
        return this.mongoDBConnector.invoke('items', "addSubscriber", itemId, subscriber);


    }

    deleteSubscriber(itemId, subscriber) {
        return this.mongoDBConnector.invoke('items', "deleteSubscriber", itemId, subscriber);


    }

    // TODO: check with shubham
    updateMappedItemStatus(buyerItemId, sellerItemId, buyerAddress, sellerAddress, status) {
        return this.mongoDBConnector.invoke('items', "updateMappedItemStatus", buyerItemId, sellerItemId, buyerAddress, sellerAddress, status);


    }

    updateInventory(inventoryId, jsonLd, itemId, inventoryShareKey, forSubscriber) {
        return this.mongoDBConnector.invoke('items', "updateInventory", inventoryId, jsonLd, itemId, inventoryShareKey, forSubscriber);


    }

    updateInventoryHistory(inventoryId, historyIndex, metaData) {
        return this.mongoDBConnector.invoke('items', "updateInventoryHistory", inventoryId, historyIndex, metaData);


    }

    updateItem(itemId, jsonLd, primaryOwner, secondaryOwner, itemShareKey, forSubscriber) {
        return this.mongoDBConnector.invoke('items', "updateItem", itemId, jsonLd, primaryOwner, secondaryOwner, itemShareKey, forSubscriber);


    }

    getItemJSONLd(itemId, jsonLd, primaryOwner, secondaryOwner, itemShareKey, forSubscriber) {
        return this.mongoDBConnector.invoke('items', "getItemJSONLd", itemId, jsonLd, primaryOwner, secondaryOwner, itemShareKey, forSubscriber);


    }

    getInventoryJSONLd(inventoryId, jsonLd, itemId, inventoryShareKey, forSubscriber) {
        return this.mongoDBConnector.invoke('items', "getInventoryJSONLd", inventoryId, jsonLd, itemId, inventoryShareKey, forSubscriber);


    }

    getInventoryUpdate(inventoryId, historyIndex, metaData) {
        return this.mongoDBConnector.invoke('items', "getInventoryUpdate", inventoryId, historyIndex, metaData);


    }

    getCatagoryJsonLd(item_id, parent_category, category) {
        return this.mongoDBConnector.invoke('items', "getCatagoryJsonLd", item_id, parent_category, category);
    }

}
export default Items;