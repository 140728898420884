import ParamConnector from '../../param-connector';

class NodeInfoManager {

    static isEnterpriseNode(paramId) {
        let graphDB = ParamConnector.getInstance().getDB();
        return graphDB.ePhoneBook.isEnterpriseNode(paramId);
    }

}

export default NodeInfoManager;