/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import * as Utils from '../../../../util/utils';
import { message, Form, Popconfirm, Row, Select, Col, AutoComplete, Input } from 'antd';
import Console from '../../../../logger';
import MetaInfoUtils from '../../AddMetaInfo/utils/utils';
import IconComponent from '../../../../components/IconComponent';
import Skeleton from 'react-loading-skeleton';
import Wrapper from './wrapper';

const { Option } = Select;

class InitHelper extends Component {

    static initCreatePage(namespace, setStateFromChild) {
        // InitHelper.initTableColumns(namespace, setStateFromChild);
        InitHelper.fetchContactsAndItems(namespace, setStateFromChild);

    }

    static initTableColumns(namespace, setStateFromChild) {
        const isRecordLoading = (index) => {
            return namespace.state.itemFetching && namespace.state.itemFetchingIndex === index
        }
        const { logistics, heading, subType } = namespace.state;

        const onlyQuantityEdit = (heading === "GRN" || heading === "Return Order" || heading === "Return Request" || heading === "Payment");

        const bosch = heading === "Invoice" && subType === " logistics"
        // namespace.
        let columns = !logistics ? [
            {
                key: "delete",
                dataIndex: "operation",
                render: (text, record) =>
                    namespace.state.dataSource.length >= 1 ? (
                        <Popconfirm
                            title="Sure to delete?"
                            onConfirm={() => namespace.handleDelete(record.key)}
                        >
                            <a>
                                <IconComponent actionIcon={true} hoverText="Delete" type="delete" />
                            </a>
                        </Popconfirm>
                    ) : null
            },
            {
                title: "Item",
                dataIndex: "item",
                key: "item",
                render: (value, row, index) => (
                    <Row>
                        <>
                            {
                                (namespace.state.parentDocId) ?
                                    <Col span={24}>
                                        {(namespace.state.subType === 'evalRequest' && namespace.state.invitationId) ?
                                            row.item :
                                            Utils.getItemNameWithIcon(row)
                                        }
                                    </Col>
                                    :
                                    <Col span={24}>
                                        <AutoComplete
                                            style={{ width: 200 }}
                                            onSelect={(val) => namespace.onSelectItem(val, row, index)}
                                            onSearch={namespace.handleItemAutoCompleteSearch}
                                            onFocus={namespace.handleItemAutoCompleteSearch}
                                            placeholder="Enter Item name"
                                            defaultValue={value}
                                            optionLabelProp="text"
                                            dataSource={
                                                namespace.state.itemSuggestionList.map((value, index) => (
                                                    <Option key={value.identifier} value={index.toString()} text={value.name}>
                                                        {Utils.getItemNameWithIcon(value)}
                                                    </Option>
                                                ))
                                            }
                                            notFoundContent={
                                                <div className="notFoundContent">
                                                    {
                                                        namespace.state.itemSearchInProgress ?
                                                            <div>
                                                                Searching... <IconComponent type="loading" />
                                                            </div>
                                                            :
                                                            namespace.state.searchResultEmpty ?
                                                                `Search doesn't match any criteria`
                                                                :
                                                                'Enter 3 or more characters to search'
                                                    }
                                                </div>
                                            }
                                        >

                                            <Input
                                                prefix={row.item &&
                                                    <div className="item_select_input_prefix" >
                                                        {Utils.getItemStatusIcon(row)}
                                                    </div>
                                                }
                                            />
                                        </AutoComplete>
                                    </Col>
                            }
                            {namespace.state.heading === "Invoice" &&
                                <a style={{ padding: '0.5rem 1rem 0 1rem' }} onClick={() => namespace.showLinkPurchaseModal(row)}>
                                    {row.linkPurchaseText || "Link Purchase"}
                                </a>
                            }
                        </>
                    </Row>
                )
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                render: (text, row, index) => (
                    isRecordLoading(index)
                        ?
                        <Skeleton />
                        :
                        text
                )
            },
            {
                title: "HSN Code",
                dataIndex: "productId",
                key: "productId",
                render: (text, row, index) => (
                    isRecordLoading(index)
                        ?
                        <Skeleton />
                        :
                        text
                )
            },
            {
                title: `Unit Price (${Utils.checkCurrencyType(namespace.props.currency)})`,
                dataIndex: "unitPrice",
                key: "unitPrice",
                render: (value, row, index) => (

                    isRecordLoading(index) ?
                        <Skeleton /> :
                        Utils.numberWithCommas(value)
                ),
                editable: !onlyQuantityEdit,
                type: 'number'
            },
            {
                title: 'Quantity',
                dataIndex: "quantity",
                key: "quantity",
                render: (value, row, index) => (

                    isRecordLoading(index) ?
                        <Skeleton /> :
                        Utils.numberWithCommas(value)
                ),
                editable: true,
                type: 'number'
            },
            {
                title: "SGST(%)",
                dataIndex: "sgst",
                key: "sgst",
                editable: !onlyQuantityEdit,
                type: 'number',
                render: (value, row, index) => (

                    isRecordLoading(index) ?
                        <Skeleton /> :
                        Utils.numberWithCommas(value)
                ),
            },
            {
                title: "CGST(%)",
                dataIndex: "cgst",
                key: "cgst",
                editable: !onlyQuantityEdit,
                type: 'number',
                render: (value, row, index) => (

                    isRecordLoading(index) ?
                        <Skeleton /> :
                        Utils.numberWithCommas(value)
                ),
            },
            {
                title: "IGST(%)",
                dataIndex: "igst",
                key: "igst",
                editable: !onlyQuantityEdit,
                type: 'number',
                render: (value, row, index) => (

                    isRecordLoading(index) ?
                        <Skeleton /> :
                        Utils.numberWithCommas(value)
                ),
            },
            {
                title: "Discount(%)",
                dataIndex: "discount",
                key: "discount",
                editable: !namespace.state.parentDocId && !onlyQuantityEdit,
                type: 'number',
                render: (value, row, index) => (

                    isRecordLoading(index) ?
                        <Skeleton /> :
                        Utils.numberWithCommas(value)
                ),
            },
            {
                title: "Amount",
                dataIndex: "amount",
                key: "amount",
                render: (value, row, index) => (

                    isRecordLoading(index) ?
                        <Skeleton /> :
                        Utils.numberWithCommas(value)
                ),
                editable: false
            }
        ] :
            [
                // !bosch && {
                //     key: "delete",
                //     dataIndex: "operation",
                //     render: (text, record) =>
                //         namespace.state.dataSource.length >= 1 ? (
                //             <Popconfirm
                //                 title="Sure to delete?"
                //                 onConfirm={() => namespace.handleDelete(record.key)}
                //             >
                //                 <a>
                //                     <IconComponent actionIcon={true} hoverText="Delete" type="delete" />
                //                 </a>
                //             </Popconfirm>
                //         ) : null
                // },
                {
                    title: "From-To Location",
                    dataIndex: "item",
                    key: "item",
                    render: (value, row, index) => (
                        <Row>
                            <>
                                {
                                    (namespace.state.parentDocId) ?
                                        <Col span={24}>
                                            {(namespace.state.subType === 'evalRequest' && namespace.state.invitationId) ?
                                                row.item :
                                                Utils.getItemNameWithIcon(row)
                                            }
                                        </Col>
                                        :
                                        <Col span={24}>
                                            <AutoComplete
                                                style={{ width: 200 }}
                                                onSelect={(val) => namespace.onSelectItem(val, row, index)}
                                                onSearch={namespace.handleItemAutoCompleteSearch}
                                                onFocus={namespace.handleItemAutoCompleteSearch}
                                                placeholder="Enter From-To Location"
                                                defaultValue={value}
                                                optionLabelProp="text"
                                                dataSource={
                                                    namespace.state.itemSuggestionList.map((value, index) => (
                                                        <Option key={value.identifier} value={index.toString()} text={value.name}>
                                                            {Utils.getItemNameWithIcon(value)}
                                                        </Option>
                                                    ))
                                                }
                                                notFoundContent={
                                                    <div className="notFoundContent">
                                                        {
                                                            namespace.state.itemSearchInProgress ?
                                                                <div>
                                                                    Searching... <IconComponent type="loading" />
                                                                </div>
                                                                :
                                                                namespace.state.searchResultEmpty ?
                                                                    `Search doesn't match any criteria`
                                                                    :
                                                                    'Enter 3 or more characters to search'
                                                        }
                                                    </div>
                                                }
                                            >

                                                <Input
                                                    prefix={row.item &&
                                                        <div className="item_select_input_prefix" >
                                                            {Utils.getItemStatusIcon(row)}
                                                        </div>
                                                    }
                                                />
                                            </AutoComplete>
                                        </Col>
                                }
                                {/* {namespace.state.heading === "Invoice" && !bosch &&
                                    <a style={{ padding: '0.5rem 1rem 0 1rem' }} onClick={() => namespace.showLinkPurchaseModal(row)}>
                                        {row.linkPurchaseText || "Link Purchase"}
                                    </a>
                                } */}
                            </>
                        </Row>
                    )
                },
                {
                    title: "Type",
                    dataIndex: "truckType",
                    key: "truckType",
                    render: (text, row, index) => (
                        isRecordLoading(index)
                            ?
                            <Skeleton />
                            :
                            text
                    )
                },
                {
                    title: 'Load (kgs)',
                    dataIndex: "load",
                    key: "load",
                    render: (value, row, index) => (

                        isRecordLoading(index) ?
                            <Skeleton /> :
                            Utils.numberWithCommas(value)
                    ),
                    editable: true,
                    type: 'number'
                },
                // {
                //     title: 'Invoice',
                //     dataIndex: "invoice",
                //     key: "invoice",
                //     type: 'text',
                //     render: (text, record, index) => (

                //         isRecordLoading(index) ?
                //             <Skeleton /> :
                //             text
                //     ),
                //     editable: true,

                // },
                {
                    title: `Price (${Utils.checkCurrencyType(namespace.props.currency)})`,
                    dataIndex: "unitPrice",
                    key: "unitPrice",
                    render: (value, row, index) => (

                        isRecordLoading(index) ?
                            <Skeleton /> :
                            Utils.numberWithCommas(value)
                    ),
                    editable: !onlyQuantityEdit,
                    type: 'number'
                },
                {
                    title: "SGST(%)",
                    dataIndex: "sgst",
                    key: "sgst",
                    editable: !onlyQuantityEdit,
                    type: 'number',
                    render: (value, row, index) => (

                        isRecordLoading(index) ?
                            <Skeleton /> :
                            Utils.numberWithCommas(value)
                    ),
                },
                {
                    title: "CGST(%)",
                    dataIndex: "cgst",
                    key: "cgst",
                    editable: !onlyQuantityEdit,
                    type: 'number',
                    render: (value, row, index) => (

                        isRecordLoading(index) ?
                            <Skeleton /> :
                            Utils.numberWithCommas(value)
                    ),
                },
                {
                    title: "IGST(%)",
                    dataIndex: "igst",
                    key: "igst",
                    editable: !onlyQuantityEdit,
                    type: 'number',
                    render: (value, row, index) => (

                        isRecordLoading(index) ?
                            <Skeleton /> :
                            Utils.numberWithCommas(value)
                    ),
                },
                {
                    title: "Discount(%)",
                    dataIndex: "discount",
                    key: "discount",
                    editable: !namespace.state.parentDocId && !onlyQuantityEdit,
                    type: 'number',
                    render: (value, row, index) => (

                        isRecordLoading(index) ?
                            <Skeleton /> :
                            Utils.numberWithCommas(value)
                    ),
                },
                {
                    title: "Amount",
                    dataIndex: "amount",
                    key: "amount",
                    render: (value, row, index) => (

                        isRecordLoading(index) ?
                            <Skeleton /> :
                            Utils.numberWithCommas(value)
                    ),
                    editable: false
                }
            ];

        let itemProperties;
        if (namespace.state.parentDocId) {
            try {
                itemProperties = JSON.parse(namespace.state.parentReceiptData.referencesOrder.additionalColumns);
            } catch (e) {

            }
        }
        else {
            itemProperties = Utils.getFromLocalStorage('itemProperties');
        }
        if (itemProperties && itemProperties.length > 0) {
            let newColumns = itemProperties.map((itemProperty) => {
                return {
                    title: itemProperty,
                    dataIndex: `additionalProperties.${itemProperty}`,
                    key: itemProperty,
                    render: (value) => value || '---'
                }
            })
            columns.splice(4, 0, ...newColumns);
        }
        namespace.columns = columns;
        setStateFromChild({ showLoader: false });
    }

    static fetchContactsAndItems(namespace, setStateFromChild) {
        let paramId = Utils.getParamId();
        const { heading, isUpdate, subType } = namespace.state;
        console.log('current tab for preFetch:', Utils.stringToCamelCase(heading))
        let currentTab = Utils.stringToCamelCase(heading.toLowerCase());
        if (!isUpdate) {
            if (currentTab === 'invoice' && subType === 'logistics')
                currentTab = 'draftinvoices'
            else
                currentTab = Utils.getParentTab(currentTab, subType);
        }
        let refManager = Wrapper.getRefManager(currentTab);
        let contactsContactData = [];
        return Utils.getAllContacts(paramId).then(contactDetails => {
            contactDetails.push(...contactsContactData);
            let { suggestionDetails, suggestionList } = Utils.createSuggestionList(contactDetails, "contacts");
            setStateFromChild({ contacts: contactDetails, selectContactOptionDetails: suggestionDetails, contactSuggestionList: suggestionList })
            return refManager.getReceipt(namespace.state.parentDocId);
        }).then((parentReceiptData) => {
            let docSubType, buyer;

            let formData = Utils.getTemplateFormData(Utils.stringToCamelCase(heading));
            let selfParamId = Utils.getParamId();
            let additionalProperties = Utils.getTemplateAdditionalProperties(Utils.stringToCamelCase(heading));
            if (parentReceiptData) {
                if (parentReceiptData["@subType"]) {
                    if (parentReceiptData["@subType"] === 'draftInvoice')
                        docSubType = 'logistics'
                    else
                        docSubType = parentReceiptData["@subType"];
                }

                buyer = parentReceiptData.customer;

                if (parentReceiptData.formData) {
                    formData = JSON.parse(parentReceiptData.formData);
                    formData.push(Utils.getTemplateFormData(Utils.stringToCamelCase(heading)))
                }
                try {
                    additionalProperties = { ...additionalProperties, ...JSON.parse(parentReceiptData.additionalProperties) }
                }
                catch (e) {
                    additionalProperties = { ...additionalProperties, ...parentReceiptData.additionalProperties };
                }
            }
            formData = formData.map((data) => {
                if (data.dataType !== "radio" && data.dataType !== "dropdown" && additionalProperties) {
                    data.defaultValue = additionalProperties[data.name];
                }
                return data;
            })
            let formItem = formData.map((data, index) => {
                return <Form.Item label={MetaInfoUtils.getLabel(data)} key={index} className="form-item">
                    {MetaInfoUtils.getInputBox(data, index, namespace.onChangeDynamicFormData)}
                </Form.Item>
            })
            if (!buyer) {
                setStateFromChild({ dynamicFormDataType: formData, dynamicFormItem: formItem, dynamicFormData: additionalProperties });
                return Promise.resolve();
            }
            if (buyer.identifier === selfParamId) {
                buyer = parentReceiptData.provider;
            }
            let selectedContract;
            const parentTabName = currentTab === 'draftinvoices' ? 'transportOrder' : Utils.getParentTab(Utils.stringToCamelCase(heading), subType);
            let totalInvoiceValue = parentReceiptData.totalPaymentDue.price;
            if (parentReceiptData && parentReceiptData['@id']) {
                selectedContract = {
                    receiptId: parentReceiptData['@id'],
                    internalId: parentReceiptData[`${parentTabName}Number`] || parentReceiptData['invoiceNumber']
                }
            }
            let globalDiscountInputValue = 0;
            if (parentReceiptData.referencesOrder) {
                globalDiscountInputValue = parentReceiptData.referencesOrder.discount || 0;
            }
            setStateFromChild({
                totalInvoiceValue, buyer,
                subType: docSubType, parentReceiptData,
                globalDiscountInputValue,
                dynamicFormDataType: formData,
                dynamicFormItem: formItem,
                dynamicFormData: additionalProperties,
                address: parentReceiptData.address,
                latLng: parentReceiptData.latLng,
                expiryDate: parentReceiptData.expiryDate,
                txnType: parentReceiptData.transactionType || '2',
                selectedContract,
                grnManager: { identifier: parentReceiptData.grnManager }
            });
            if (currentTab === 'draftinvoices') {
                let dataToPrefill = parentReceiptData.referencesOrder.orderedItem;
                let items = Utils.getItemTableData(dataToPrefill).items;
                setStateFromChild({
                    dataSource: items,
                    dataToValidate: dataToPrefill,
                    toID: parentReceiptData.poId
                });
                return
            }
            return InitHelper.fetchChildrenDetails(parentReceiptData, namespace, setStateFromChild);
        }).catch(err => {
            Console.error(err);
            message.error('Error in fetching initial fields');
        }).finally(() => {
            InitHelper.initTableColumns(namespace, setStateFromChild);
        })
    }

    static fetchChildrenDetails(parentReceiptData, namespace, setStateFromChild) {
        const originalReceiptData = parentReceiptData;
        const { heading, isUpdate, attachedInvoiceId, subType } = namespace.state;
        let parentReceiptStep = namespace.state.parentReceiptStep;
        let parentDocId, currentDocId = parentReceiptData["@id"].includes("-") ? parentReceiptData["@id"] : parentReceiptData["_id"];
        let promiseArray = [];
        let promise = Promise.resolve(parentReceiptData);
        let functionCallToGetChildren, refManager, outData, inData;
        let currentTab = Utils.stringToCamelCase(heading);

        if (isUpdate) {
            switch (currentTab) {
                case "gRN":
                    parentDocId = parentReceiptData.poId || parentReceiptData.invoiceId;
                    parentReceiptStep = parentReceiptData.poId ? "1" : "2";
                    break;
                default:
                    parentDocId = parentReceiptData.parentDocId;
            }
            refManager = Wrapper.getRefManager(Utils.getParentTab(currentTab), subType);
            promise = refManager.getReceipt(parentDocId);

            setStateFromChild({
                parentDocId,
                currentDocId
            })
        }
        else {
            parentDocId = currentDocId;
        }

        return promise.then(res => {
            parentReceiptData = res;
            if (heading === "GRN" || heading === "Accept Note") {
                InitHelper.getInvoiceNoList(namespace, setStateFromChild, parentReceiptData);
            }

            switch (currentTab) {
                case "gRN":
                case "grn":
                case "ePoD":
                    refManager = Wrapper.getRefManager('grn');
                    functionCallToGetChildren = refManager.getAllGrnByDocId(parentDocId, parentReceiptStep).then(res => { return res.grns });
                    break;
                case "acceptNote":
                    refManager = Wrapper.getRefManager('acceptNote');
                    functionCallToGetChildren = refManager.getAllAcceptNotesByDocId(parentDocId, parentReceiptStep).then(res => { return res.acceptNotes });
                    break;
                case "returnRequest":
                    refManager = Wrapper.getRefManager('invoice');
                    functionCallToGetChildren = refManager.getAllReceiptsByParentAndSubType(parentDocId, 'returnRequest').then(res => { return res.returns });
                    //now fetch the returns summary from returns collection
                    refManager = Wrapper.getRefManager('returnRequest');
                    break;
                case "returnOrder":
                    refManager = Wrapper.getRefManager('returnRequest');
                    functionCallToGetChildren = refManager.getAllReceiptsByParent(parentDocId).then(res => { return res.receipts });
                    break;
                default:
                    refManager = Wrapper.getRefManager('invoice');
                    functionCallToGetChildren = refManager.getAllReceiptsByParent(parentDocId).then(res => { return res.receipts });
                    break;
            }

            return functionCallToGetChildren;
        }).then(res => {
            for (let index in res) {
                if (isUpdate && res[index] === currentDocId) {
                    continue;
                }
                promise = refManager.getValidationData(res[index]);
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray)
        }).then(res => {
            outData = res;
            if (isUpdate) {
                refManager = Wrapper.getRefManager(Utils.getParentTab(currentTab, subType));
                return Promise.all([
                    refManager.getValidationData(parentDocId)
                ])
            }
            switch (currentTab) {
                case "returnRequest":
                    refManager = Wrapper.getRefManager('grn');
                    return refManager.getAllGrnByDocId(currentDocId, "2").then(res => {
                        let inDataReceiptIds = [];
                        promiseArray = [];
                        if (res.count === 0) {
                            message.warning("Selected Invoice doesn't have any grn, taking values from invoice", 4);
                            inDataReceiptIds.push(currentDocId);
                            refManager = Wrapper.getRefManager('invoice');
                        }
                        else {
                            inDataReceiptIds = res.grns;
                        }
                        for (let index in inDataReceiptIds) {
                            promiseArray.push(
                                refManager.getValidationData(inDataReceiptIds[index])
                            )
                        }
                        return Promise.all(promiseArray);
                    })
                case "ePoD":
                    refManager = Wrapper.getRefManager("transportOrder");
                    return Promise.all([
                        refManager.getValidationData(currentDocId)
                    ])
                default:
                    const parentTab = Utils.getParentTab(currentTab, subType)
                    refManager = Wrapper.getRefManager(parentTab);
                    let queryId = currentDocId, stepNoForAN = undefined;
                    if (attachedInvoiceId) {
                        queryId = attachedInvoiceId;
                    }
                    if (currentTab === "invoice") {
                        stepNoForAN = Utils.getStepNoFromTabName(parentTab);
                    }
                    return Promise.all([
                        refManager.getValidationData(queryId, stepNoForAN)
                    ])
            }
            // promiseArray = [];
            // for (let index in inData) {
            //     promise = refManager.getValidationData(inData[index].receiptId);
            //     promiseArray.push(promise);
            // }
            // return Promise.all(promiseArray);
        }).then(res => {
            inData = res;
            let dataToPrefill = parentReceiptData.referencesOrder.orderedItem, items;
            if (namespace.state.heading !== "Payment") {
                dataToPrefill = Utils.getDataToValidate(dataToPrefill, namespace.state.heading, namespace.state.noGrnForReturnRequest, inData, outData);
            }
            if (isUpdate) {
                items = Utils.getItemTableData(originalReceiptData.referencesOrder.orderedItem).items;
            }
            else {
                items = Utils.getItemTableData(dataToPrefill).items;
            }
            if (items.length === 0) {
                message.info(`All Quantity is already raised for selected ${namespace.state.heading}`);
            }
            setStateFromChild({
                dataToValidate: dataToPrefill,
                dataSource: items,
                outData, inData
            });
            return promise;
        })
    }

    static getGRNOutData(docId, docStep) {
        let refManager = Wrapper.getRefManager('grn');
        return refManager.getAllGrnByDocId(docId, docStep).then(res => {
            res = res.grns;
            let promise, promiseArray = [];
            for (let index in res) {
                promise = refManager.getValidationData(res[index]);
                promiseArray.push(promise);
            }
            return Promise.all(promiseArray)
        })
    }

    static updateValidationData(namespace, setStateFromChild, docId) {
        let refManager = Wrapper.getRefManager('invoice');
        let { parentReceiptData, inData } = namespace.state;
        let outData;
        return InitHelper.getGRNOutData(docId, '2').then(res => {
            outData = res;
            return refManager.getValidationData(docId);
        }).then(res => {
            if (res) {
                inData = [res];
            }
            let dataToPrefill = Utils.getDataToValidate(parentReceiptData.referencesOrder.orderedItem,
                namespace.state.heading, namespace.state.noGrnForReturnRequest, inData, outData);
            let items = Utils.getItemTableData(dataToPrefill).items;
            if (items.length === 0) {
                message.info(`All Quantity is already raised for selected ${namespace.state.heading}`);
            }
            setStateFromChild({
                dataToValidate: dataToPrefill,
                dataSource: items
            })
        })
    }

    static getInvoiceNoList(namespace, setStateFromChild, parentReceiptData) {
        let poId = parentReceiptData['@id'];
        let invoiceNoSuggestionDetails = [], invoiceNoSuggestionList = [], promiseArray = [];
        let refManager = Wrapper.getRefManager(namespace.state.parentDataRef);
        return refManager.getAllReceiptsByParent(poId).then(attachedInvoices => {
            attachedInvoices = attachedInvoices.receipts;
            for (let index in attachedInvoices) {
                promiseArray.push(refManager.getSummary(attachedInvoices[index], ['step', 'docInternalId']));
            }
            return Promise.all(promiseArray)
        }).then(res => {
            let invoiceNos = [], invoiceIds = [];
            for (let index in res) {
                if (res[index].step === "2") {
                    invoiceNos.push(res[index].docInternalId);
                    invoiceIds.push(res[index].receiptId)
                }
            }
            let suggestionInfo = Utils.createSuggestionList(invoiceNos, "flatArray");
            invoiceNoSuggestionDetails = suggestionInfo.suggestionDetails;
            invoiceNoSuggestionList = suggestionInfo.suggestionList;
            setStateFromChild({
                invoiceNoSuggestionDetails, invoiceNoSuggestionList,
                initialInvoiceNoSuggestionDetails: invoiceNoSuggestionDetails,
                initialInvoiceNoSuggestionList: invoiceNoSuggestionList,
                initialInvoiceIdSuggestion: invoiceIds
            })
        })
    }

}

export default InitHelper;