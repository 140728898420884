import Database from '../../index'
import TemplateConsensusRestore from '../sync/template-cons';
import * as NetworkUtils from '../../../util/utils';
import NetworkFormat from '../Utils/network-data-format';
import Console from '../../../logger/index'

class TemplateConsensusDBEvents {

    constructor() {
        this.dbInstance = Database.getInstance().getDB();
    }

    // registerEvents(contractAddress, paramId) {

    //     let templateConsParamObject = this.dbInstance.getTemplateConsManager(contractAddress);

    //     templateConsParamObject.registerOnTemplateAttached(this.onTemplateAttached.bind(this), { address: paramId });
    //     templateConsParamObject.registerOnStepAssinged(this.onStepAssinged.bind(this), { address: paramId });
    //     templateConsParamObject.registerOnSubscriberAdded(this.onSubscriberAdded.bind(this), { address: paramId });
    //     templateConsParamObject.registerOnTemplateCompleted(this.onTemplateCompleted.bind(this), { address: paramId });
    //     templateConsParamObject.registerOnStepSigned(this.onStepSigned.bind(this), { addres: paramId });
    //     templateConsParamObject.registerOnTemplateConsensusMerged(this.onTemplateConsensusMerged.bind(this), { addres: paramId });
    //     templateConsParamObject.registerOnTemplateConsensusReset(this.onTemplateConsensusReset.bind(this), { addres: paramId });
    //     templateConsParamObject.registerOnTemplateAttachedForDocIds(this.onTemplateAttachedForDocIds.bind(this), { addres: paramId });
    //     templateConsParamObject.registerOnSubscriberAddedForDocIds(this.onSubscriberAddedForDocIds.bind(this), { addres: paramId });

    //     Console.log("Registered events for template cons ", contractAddress)
    // }

    registerOnTemplateAttached(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onTemplateAttached", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnTemplateAttached(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onTemplateAttached", callback);
        Console.info(`[GraphDB] register event`);
    }

    registerOnTemplateCompleted(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onTemplateCompleted", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnTemplateCompleted(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onTemplateCompleted", callback);
        Console.info(`[GraphDB] register event`);
    }

    registerOnStepSigned(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onStepSigned", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnStepSigned(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onStepSigned", callback);
    }

    registerOnStepAssigned(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onStepAssigned", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnStepAssigned(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onStepAssigned", callback);
    }

    registerOnTemplateConsensusMerged(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onTemplateConsensusMerged", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnTemplateConsensusMerged(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onTemplateConsensusMerged", callback);
    }

    registerOnTemplateConsensusReset(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onTemplateConsensusReset", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnTemplateConsensusReset(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onTemplateConsensusReset", callback);
    }

    registerOnSubscriberAdded(callback) {
        if (!this.dbInstance.events) {
            this.dbInstance.initEvents();
        }
        this.dbInstance.events.addListener("onSubscriberAdded", callback);
        Console.info(`[GraphDB] register event`);
    }

    unRegisterOnSubscriberAdded(callback) {
        if (!this.dbInstance.events) {
            return;
        }
        this.dbInstance.events.removeListener("onSubscriberAdded", callback);
    }

    onTemplateAttached(error, dataJSON) {
        this.dbInstance.emitEvent("onTemplateAttached", dataJSON.args.templateConsId, dataJSON.args.docId, dataJSON.args);
        return this.writeonTemplateAttached(error, dataJSON)
    }

    onTemplateAttachedForDocIds(error, dataJSON) {
        this.dbInstance.emitEvent("onTemplateAttachedForDocIds", dataJSON.args.templateConsId, dataJSON.args.docId, dataJSON.args);
        return this.writeonTemplateAttached(error, dataJSON)
    }

    onStepAssinged(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let subscribers = dataJSON.subscribers || dataJSON.args.subscribers;
        let assigned = dataJSON.assigned || dataJSON.args.assigned;

        return TemplateConsensusRestore.addSubsriber(dataJSON.args.templateConsId, dataJSON.address, subscribers, dataJSON.args.stepConId, assigned, "onAssign").then(() => {
            let templateConsId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
            return this.dbInstance.emitEvent("onStepAssinged", dataJSON.args.templateConsId, dataJSON.args);
            // return this.dbInstance.notifications.addNotification(templateConsId, "onStepAssigned", "template-consensus", subscribers).then(() => {
            //     this.dbInstance.emitEvent("notification", dataJSON.args.templateConsId, dataJSON.args);
            // })
        }).then(() => {
            let { id } = NetworkUtils.getTransactionData(dataJSON.args.templateConsId);
            return TemplateConsensusRestore.restoreAllTransactions(id, dataJSON.args.contractAddress).then(() => {
                return this.dbInstance.emitEvent("onStepAssigned", dataJSON.args.templateConsId);
            })
        })
    }

    onStepSigned(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return TemplateConsensusRestore.updateStepConsensusStatus(dataJSON.args.templateConsId, dataJSON.args.stepConId, dataJSON.address, true).then(() => {
            let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
            return this.dbInstance.templateConsensusDB.updateTemplateConsensusCurrentStep(txId, dataJSON.args.currentStep);
        }).then(response => {
            return this.dbInstance.emitEvent("onStepSigned", dataJSON.args.templateConsId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to update onStepSigned ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            return this.writeonStepSigned(null, dataJSON);
        })
    }

    onSubscriberAdded(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return TemplateConsensusRestore.addSubsriber(dataJSON.args.templateConsId, dataJSON.address, dataJSON.args.subscribers).then(() => {
            let templateConsId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
            this.dbInstance.emitEvent("onSubscriberAdded", dataJSON.args.templateConsId, dataJSON.args.docId, dataJSON.args);
            // return this.dbInstance.notifications.addNotification(templateConsId, "onSubscriberAdded", "template-consensus", dataJSON.args.subscribers).then(() => {
            //     this.dbInstance.emitEvent("notification", dataJSON.args.templateConsId, dataJSON.args);
            // })
        }).catch(error => {
            Console.error(`Unable to add template consensus ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            return this.writeonSubscriberAdded(error, dataJSON);
        });
    }

    onSubscriberAddedForDocIds(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return TemplateConsensusRestore.addSubsriber(dataJSON.args.templateConsId, dataJSON.address, dataJSON.args.subscribers).then(() => {
            let templateConsId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
            this.dbInstance.emitEvent("onSubscriberAdded", dataJSON.args.templateConsId, dataJSON.args);
            // return this.dbInstance.notifications.addNotification(templateConsId, "onSubscriberAdded", "template-consensus", dataJSON.args.subscribers).then(() => {
            //     this.dbInstance.emitEvent("notification", dataJSON.args.templateConsId, dataJSON.args);
            // })
        }).catch(error => {
            Console.error(`Unable to add template consensus ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            return this.writeonSubscriberAdded(error, dataJSON);
        });
    }

    onTemplateCompleted(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        return TemplateConsensusRestore.updateTemplateCompleted(dataJSON.args.templateConsId, dataJSON.address, true).then(response => {
            this.dbInstance.emitEvent("onTemplateCompleted", dataJSON.args.templateConsId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to update onTemplateCompleted ${dataJSON.args.templateConsId}, reason: ${error}`);
        }).finally(() => {
            return this.writeonTemplateCompleted(error, dataJSON);
        });
    }

    onTemplateConsensusMerged(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let templateConsIds = dataJSON.args.templateConsIds;
        for (let index in dataJSON.args.templateConsIds) {
            templateConsIds[index] = NetworkUtils.getTransactionId(templateConsIds[index], dataJSON.address);
        }
        return this.dbInstance.templateConsensusDB.addMergeId(dataJSON.args.mergeId, templateConsIds).then(response => {
            this.dbInstance.emitEvent("onTemplateConsensusMerged", dataJSON.args.mergeId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to add mergeId ${templateConsIds}, reason: ${error}`);
        }).finally(() => {
            return this.writeonTemplateConsensusMerged(error, dataJSON);
        })
    }

    onTemplateConsensusReset(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let templateConsId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
        return this.dbInstance.templateConsensusDB.resetTemplateConsensus(templateConsId).then(response => {
            this.dbInstance.emitEvent("onTemplateConsensusReset", dataJSON.args.templateConsId, dataJSON.args);
        }).catch(error => {
            Console.error(`Unable to reset templateConsId ${templateConsId}, reason: ${error}`);
        }).finally(() => {
            return this.writeonTemplateConsensusReset(error, dataJSON);
        })
    }

    writeonTemplateAttached(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);

        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template attached");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        metaInfo.currentStep = "0";
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }

    writeonStepAssinged(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);

        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Step assinged");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        metaInfo.stepConsId = NetworkUtils.getTransactionId(dataJSON.args.stepConId, dataJSON.address);
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.owner, metaInfo, dataJSON.args.assigned)
    }

    writeonStepSigned(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        return this.getStepId(templateId, dataJSON.args.currentStep).then(step => {
            let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
            let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Step signed");
            metaInfo.templateId = templateId;
            metaInfo.currentStep = dataJSON.args.currentStep;
            metaInfo.stepId = step.stepId;
            metaInfo.stepNo = step.stepNo;
            metaInfo.stepConsId = NetworkUtils.getTransactionId(dataJSON.args.stepConId, dataJSON.address);
            return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.owner, metaInfo);
        }).catch(error => {
            Console.log(`Unable to add ${templateId} onStep Signed, Reason: ${error}`);
        })

    }

    getStepId(templateId, stepIndex) {
        return this.dbInstance.templateRepoDB.getTemplate(templateId).then(templateInfo => {
            let stepId, stepNo;
            for (let index in templateInfo.stepIds) {
                if (Number(templateInfo.stepIds[index].stepNo) === Number(stepIndex)) {
                    stepId = templateInfo.stepIds[index]['@id'];
                    stepNo = templateInfo.stepIds[index]['stepNo'];
                    break;
                }
            }
            return { stepId, stepNo };
        }).catch(error => {
            return null;
        })
    }

    writeonSubscriberAdded(error, dataJSON) {
        let graphDB = this.dbInstance;
        if (error) {
            return Promise.resolve();
        }
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Subscriber added");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        graphDB.templateConsensusDB.writeDocStep(txId, dataJSON.args.docStep);
        return graphDB.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }

    writeonTemplateCompleted(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template completed");
        metaInfo.templateId = NetworkUtils.getTransactionId(dataJSON.args.templateId, dataJSON.args.templateContractAddress);
        metaInfo.isCompleted = true;
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo);
    }

    writeonTemplateConsensusMerged(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let transactionsDB = this.dbInstance.transactionsDB;
        let promiseArray = [];
        for (let index in dataJSON.args.templateConsIds) {
            let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsIds[index], dataJSON.address);
            let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template Merged");
            promiseArray.push(transactionsDB.addTransaction(txId, dataJSON.args.creator, metaInfo));
        }
        return Promise.all(promiseArray);
    }

    writeonTemplateConsensusReset(error, dataJSON) {
        if (error) {
            return Promise.resolve();
        }
        let txId = NetworkUtils.getTransactionId(dataJSON.args.templateConsId, dataJSON.address);
        let metaInfo = NetworkFormat.getMetaInfo(txId, dataJSON, "Template Reset");
        return this.dbInstance.transactionsDB.addTransaction(txId, dataJSON.args.owner, metaInfo);
    }
}

export default TemplateConsensusDBEvents;