import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import Landing from "../Landing";
import OTPLogin from "../NewLanding/index";
import AdminLogin from "../NewLanding/adminLogin"
import SelectAccount from "../NewLanding/selectAccount"
import Login from "../Login";
import Password from "../CreatePassword";
import Restore from "../RestorePassword";
import Phrase from "../PhraseGeneration";
import AddProfile from "../../components/profile/AddProfile";
import AcceptInvitation from "../AcceptInvitation";
import NetworkBridge from "../../util/network-bridge/index"
import { setInitUrl } from "../../appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "../../appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import Analytics from "../../analytics";
import Config from '../../config.json';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.param_id ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: props.location.pathname,
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {

  // constructor(props) {
  //   super(props);
  //   Analytics.getInstance().initMotomo(Config['siteID'], Config['url']);
  // }

  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };
  isLoggedOut = () => {
    return NetworkBridge.getKeyStoreManager().isValidToken()
  }
  componentWillMount() {

    if (window.location.href.split('?')[1] && window.location.href.split('?')[1].startsWith('code')) {
      let code = window.location.href.split('code=')[1].split('&')[0];
      window.location.href = `http://localhost:3000/#/main/finance/items/uploadimage?${code}`;
      return;
    }

    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }
  }

  render() {
    const {
      match,
      location,
      layoutType,
      navStyle,
      locale,
      authUser,
      initURL
    } = this.props;
    const loggedOut = this.isLoggedOut();
    if (location.pathname === "/") {
      if (location.pathname === "/admin")
        return <Redirect to={"/admin"} />
      if (!loggedOut)
        return <Redirect to={"/landing"} />
      else
        return <Redirect to={"/main/landing?init"} />
      return <Redirect to={"/main/landing"} />

      if (!localStorage.param_id) {
        return <Redirect to={"/landing"} />;
      } else if (initURL === "/" || initURL === "") {
        return <Redirect to={"/login"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/otplogin" component={OTPLogin} />
            <Route exact path="/admin" component={OTPLogin} />
            <Route exact path="/selectaccount" component={SelectAccount} />
            <Route exact path="/addaccount" component={AdminLogin} />
            <Route exact path="/landing" component={Landing} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/password" component={Password} />
            <Route exact path="/restore" component={Restore} />
            <Route exact path="/phrase" component={Phrase} />
            <Route exact path="/addprofile" component={AddProfile} />
            <Route exact path="/acceptinvitation" component={AcceptInvitation} />

            <RestrictedRoute
              path={`${match.url}`}
              authUser={authUser}
              component={MainApp}
            />
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL } = auth;
  return { locale, navStyle, layoutType, authUser, initURL };
};
export default connect(mapStateToProps, {
  setInitUrl,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange
})(App);