/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './index.less';
import OnSelectHelper from '../../main/create/Helper/onSelectHelper';
import { Form, Row, Col, Select, Radio, Input, DatePicker, Checkbox, Table, Divider, message, Button, Drawer, Modal, Tooltip } from 'antd';
import IconComponent from '../../../components/IconComponent';
import moment from 'moment';
import AutoCompletePlaces from '../AutoCompletePlaces';
import MismatchTable from '../MismatchTable';
import TotalTable from '../TotalTable';
import * as Utils from '../../../util/utils';
import { shareCatalogueText, priceLessText } from '../../../constants/AppConstants';
import { EditableFormRow, EditableCell } from '../editable-table';
import InitHelper from '../../main/create/Helper/initHelper';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CreateFormHelper from '../../main/create/Helper/createFormHelper';
import Console from '../../../logger';
import NetworkBridge from '../../../util/network-bridge';
import BaseComponent from '../../main/BaseComponent';
import CircularProgress from '../../../components/CircularProgress';
import ShowIRNQR from '../../main/create/Helper/irnQR';
import AttatchWorkFlow from '../../main/AttachWorkFlow';
import AddMetaInfo from '../../main/AddMetaInfo';
import ItemMapping from '../ItemMapping';
import SCM from '../../main/create/scm';
import QRLoader from '../../main/create/qrloader';
import FileUploadComponent from '../FileAttachment';
import { withRouter } from 'react-router-dom';
import * as Config from '../../../config.json';
import Analytics from '../../../analytics';

const { warning } = Modal;

let HEPL_TEXT = require('./helptext.json')
const { Option } = Select;
const { TextArea } = Input;

const FormData = require('form-data');
const BLOCK_OPTIONS = [
    { label: 'Price', value: 1 },
    { label: 'Quantity', value: 2 },
];

// function uploadFiles(e, state) {
//     let form = new FormData()
//     let file = e.target.files[0]
//     let cnId = Utils.getFromLocalStorage('draftInvoiceNumber')
//     console.log('Uploaded File', file)
//     console.log('State Object', state)
//     form.append('files', file);
//     console.log('Draft Invoice Number', cnId)
//     form.append('cnID', cnId);
//     return fetch("http://140.238.244.124:5001/add/invoice", {
//         method: "POST",
//         mode: "cors",
//         cache: "no-cache",
//         headers: {
//             'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.MhQjMm2TlY0uesawWXiQ9dIisMx5yAB7voGCWTeDkO8'
//         },
//         body: form
//     }).then(res => {
//         console.log("Upload result", res.json())
//         if (res.status === 200) {
//             message.success('File Uploaded Successfully')
//         } else {
//             // return res.text().then((text) => {
//             //     let response = JSON.parse(text)
//             //     message.error('Error in Uploading file, reason: ' + res.message)
//             // })
//             message.error('Error in Uploading file')
//         }
//     })
// }
class CreateForm extends BaseComponent {

    componentDidMount() {
        super.componentDidMount();
        this.todayDate = moment(new Date());
        this.analytics = Analytics.getInstance();
        this.config = Config['analytics'];
        InitHelper.initCreatePage(this, this.setStateFromChild);
        this.fileSelector = this.buildFileSelector(this);
    }

    buildFileSelector = (that) => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('multiple', 'multiple');
        fileSelector.setAttribute('accept', 'application/pdf')
        fileSelector.onchange = function (e) { that.selectFile(e) } //uploadFiles(e, state);
        return fileSelector;
    }

    initStates() {
        const { type, searchParams, heading, createText, subType } = this.props;
        let attachments = [];
        // if (heading === "GRN") {
        //     if (propsState && propsState.grnData) {
        //         if (propsState.grnData.fileAttachments) {
        //             attachments = Utils.getArrayOfData(propsState.grnData.fileAttachments)
        //         }
        //     }
        // }
        let noteValuesInfo = {};
        try {
            noteValuesInfo = JSON.parse(searchParams.get('noteValuesInfo'));
        }
        catch (e) {

        }

        this.state = {
            customerDetails: [],
            parentReceiptData: {},
            dataSource: [],
            customerSuggestionList: [],
            count: 1,
            contacts: [],
            contractTypeList: HEPL_TEXT.contractType[1],
            itemSuggestionList: [],
            selectItemOptionDetails: [],
            buyer: {},
            item: {},
            showLoader: true,
            txnType: '2',
            date: "",
            customNote: "",
            internalId: "",
            materials: [],
            displayCustomerText: '\u00A0',
            displayCustomerId: "",
            heading,
            subType,
            supportPrivate: false,
            supportProtected: true,
            selectParentDocOptionDetails: [],
            contractAddressList: [],
            selectedContract: {},
            attachWorkFlow: false,
            customerSelected: false,
            toolTipCustomer: 'Select a customer!',
            irn: "Generate IRN",
            irnGenerated: false,
            qrLoader: false,
            contractSuggestionList: [],
            parentDocId: searchParams.get('parentDocId'),
            fromDetailsPage: searchParams.get('fromDetailsPage'),
            invitationId: searchParams.get('invitationId'),
            noteValuesInfo,
            amountReceived: searchParams.get('amountReceived'),
            parentReceiptStep: searchParams.get('parentReceiptStep'),
            isUpdate: searchParams.get('isUpdate'),
            isEditable: searchParams.get('isEditable'),
            attachedInvoiceId: searchParams.get('attachedInvoiceId'),
            invoiceInternalIdForGrn: searchParams.get('invoiceInternalIdForGrn'),
            isPartPO: searchParams.get('isPartPO'),
            parentPOId: searchParams.get('parentPOId'),
            draftInvoiceNumber: Utils.getFromLocalStorage('draftInvoiceNumber'),
            debitAdviceNumber: Utils.getFromLocalStorage('debitAdviceNumber'),
            createText,
            type,
            dynamicFormData: {},
            showMetaDrawer: false,
            allowExceedingFields: 0,
            shareCatalogue: 0,
            contractList: [],
            linkedReceipts: {},
            showFileUploadDrawer: false,
            uploadedFiles: attachments ? attachments : [],
            globalDiscount: 0,
            globalDiscountUnit: "percent",
            globalDiscountInputValue: 0,
            metainfoForm: {},
            itemSearchInProgress: false,
            searchResultEmpty: false,
            logistics: subType ? true : false,
            fileName: "Upload File",
            inventoryQuantity: []
            // selectedFiles: []
        };
    }
    // getLogisticsStatus = () => {
    //     switch (this.props.heading) {
    //         case "Rate Card":
    //         case "TO":
    //         case "LR":
    //         case "Draft Invoices":
    //         case "Invoices":
    //         case "Payment Init":
    //         case "Receipts": {
    //             this.setState({
    //                 logistics: true
    //             })
    //             break;
    //         }
    //         default: {
    //             break;
    //         }
    //     }
    // }
    onSelectCustomer = (value, option) => {
        OnSelectHelper.onSelectCustomer(value, option, this, this.setStateFromChild);
    }

    onSelectParentDocAddress = (value) => {
        return OnSelectHelper.onSelectParentDocAddress(value, this, this.setStateFromChild);
    }

    onSelectInvoiceNumber = (e) => {
        this.setState({
            showInnerSpinner: true
        })
        InitHelper.updateValidationData(this, this.setStateFromChild, this.state.initialInvoiceIdSuggestion[e]).finally(() => {
            this.setState({ showInnerSpinner: false });
        })
        return this.state.invoiceNoSuggestionList[e];
    }

    onSelectItem = (value, row, index) => {
        const selectedItem = this.state.itemSuggestionList[value];
        if (value === 'addnewItem') {
            this.props.history.push('/main/finance/items/itemDetails')
            return;
        }
        console.log(row)
        const itemManager = NetworkBridge.getItemManager();
        this.setState({ itemFetching: true, itemFetchingIndex: index });
        return itemManager.getItemV1(selectedItem.identifier, this.state.displayCustomerId).then(selectedItemdetail => {
            selectedItemdetail.mappingInfo = selectedItem.mappingInfo;
            let newData = OnSelectHelper.onSelectItem(selectedItemdetail, row, this);
            this.setState({ dataSource: newData, itemSuggestionList: [], itemFetching: false, itemFetchingIndex: null });
            const { inventoryQuantity, dataSource } = this.state;
            if (!inventoryQuantity[index]) {
                let inventoryQuantity = [...this.state.inventoryQuantity];
                inventoryQuantity[index] = dataSource[index].quantity;
                this.setState({ inventoryQuantity })
            }
        })

    }

    onSelectGRNManager = (value, option) => {
        this.setState({ grnManager: this.state.contactSuggestionList[value] });
    }


    setCustomerNote = (e) => {
        this.setState({ customNote: e.target.value });
    }

    handleInvoiceNoSearch = (value) => {
        if (value) {
            value = value.toUpperCase();
            let currentOptionsLength = this.state.initialInvoiceNoSuggestionList.length;
            this.setState({
                invoiceNoSuggestionList: [...this.state.initialInvoiceNoSuggestionList, value],
                invoiceNoSuggestionDetails: [...this.state.initialInvoiceNoSuggestionDetails, <Option key={currentOptionsLength} label={value}>{value}</Option>]
            })
        }
    }

    handleBlockOptionsChange = (arrVal) => {
        let allowExceedingFields = arrVal.reduce((a, b) => a + b, 0)
        this.setState({ allowExceedingFields })
    }

    handleShareCatalogue = (e) => {
        this.setState({
            shareCatalogue: e.target.value
        })
    }

    handleSelectPlace = address => {
        geocodeByAddress(address)
            .then(results => {
                console.log(results)
                this.setState({
                    address: results[0].formatted_address
                })
                return getLatLng(results[0]);
            })
            .then(latLng => {
                console.log('Success', latLng)
                this.setState({
                    latLng
                })
            })
            .catch(error => console.error('Error', error));
    };

    handleChangePlace = address => {
        this.setState({ address });
    };

    handleItemAutoCompleteSearch = (searchText) => {
        if (searchText && searchText.length >= 3) {
            const catalogueManager = NetworkBridge.getItemManager();
            const address = this.state.displayCustomerId;
            this.setState({ itemSearchInProgress: true });
            return catalogueManager.searchItemWithMappingInfo(searchText, address, this.state.logistics).then(res => {
                console.log(res);
                this.setState({ itemSuggestionList: res, itemSearchInProgress: false, searchResultEmpty: (res.length === 0) });
            })
        }
        else {
            this.setState({ itemSuggestionList: [], searchResultEmpty: false });
        }
    }

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const { selectedContract, heading, dataSource, inventoryQuantity } = this.state;
        if (heading === 'Contract' || Object.keys(selectedContract).length === 0) {
            if (row.quantity > inventoryQuantity[index]) {
                message.error("Quantity should not be greater than inventory");
                return;
            }
        }
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        let amt = (newData[index].logistics ? newData[index].load : newData[index].quantity) * newData[index].unitPrice
        const totalTax = newData[index].sgst + newData[index].cgst + newData[index].igst
        const discount = newData[index].discount;
        const discountValue = discount / 100 * amt;
        const taxValue = totalTax / 100 * amt;
        newData[index].amount = amt + taxValue - discountValue;
        newData[index].subTotal = amt;

        this.setState({ dataSource: newData });
        if (this.state.heading === "Payment") {
            this.refs.mismatchTable.updateTableData(newData);
        }
    };

    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    }

    handleAdd = e => {
        e.preventDefault();
        const { count, dataSource, heading, subType } = this.state;
        const boschSettings = heading == "Invoice" && subType === "logistics"

        const newData = {
            key: count,
            item: "",
            type: "---",
            productId: "---",
            unitPrice: "0",
            quantity: "0",
            sgst: 0,
            cgst: 0,
            igst: 0,
            subTotal: 0,
            discount: 0,
            amount: "0",
            internalId: "",
            materials: [],
            linkPurchaseText: !boschSettings && "Link Purchase"
        };
        this.setState({
            dataSource: [...dataSource, newData],
            itemSuggestionList: [],
            count: count + 1
        });
    };

    amountFormat = (text) => {
        if (typeof text === 'string')
            text = Number(text)
        return (text).toLocaleString("en-IN", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        })
    }

    confirmCreation = (that, values, type) => {
        let amountComponent = <p style={{ fontSize: "1.2rem", fontWeight: "800" }}>{Utils.checkCurrencyType(that.props.currency) + this.amountFormat(that.state.totalAmount)}</p>
        let referenceNumber = values["invoiceNumber"]
        if (this.state.heading == "QCreditNote" && this.state.subType === "quality")
            referenceNumber = values['qCreditNoteNumber']
        warning({
            title: `Please confirm ${that.state.heading} amount`,
            content: amountComponent,
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk() {
                if (that.state.heading === "QCreditNote" && that.state.subType === "quality") {
                    values['status'] = 1
                    values['step'] = 1
                    // return CreateFormHelper.parsePayloadData(values, false, that, that.setStateFromChild, type);
                }
                return that.uploadFile(referenceNumber).then(data => {
                    if (!data) {
                        throw new Error()
                    }
                    console.log("File Upload data", data);
                    values['fileAttachments'].push(data)
                    return CreateFormHelper.parsePayloadData(values, false, that, that.setStateFromChild, type);
                }).catch(err => {
                    console.error("Error in creating document " + err)
                })
            },
            onCancel() { that.stopInnerSpinner() }
        })

    }
    handleSubmit = (e) => {
        const that = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            const { metainfoForm } = this.state
            if (!err) {
                if (metainfoForm.err) {
                    message.error('Please fill the additional fields that are marked as mandatory')
                    return;
                }
                if (!this.state.selectedFile) {
                    return message.error('Please upload File Attachment');
                }
                values["fileAttachments"] = this.state.uploadedFiles;
                if (this.state.heading === "Invoice" && this.state.subType === 'logistics') {
                    values["draftInvoiceNumber"] = this.state.draftInvoiceNumber
                    values["creditNoteId"] = this.state.parentDocId
                    // .then(() => {
                    //     console.log("After file upload")
                    //     return CreateFormHelper.parsePayloadData(values, false, that, that.setStateFromChild, this.props.type);
                    // })
                    return this.confirmCreation(that, values, this.props.type).catch(err => {
                        message.error("Error in creating document")
                    })
                }

                if (this.state.heading === "QCreditNote" && this.state.subType === 'quality') {

                    // values["qCreditNoteNumber"] = this.state.qCreditNoteNumber
                    values["debitadviceId"] = this.state.selectedContract.receiptId ? this.state.selectedContract.receiptId : "" //this.state.parentDocId
                    values["debitAdviceNumber"] = this.state.selectedContract.internalId ? this.state.selectedContract.internalId : ""
                    values["parentDocId"] = this.state.selectedContract.receiptId ? this.state.selectedContract.receiptId : ""
                    // .then(() => {
                    //     console.log("After file upload")
                    //     return CreateFormHelper.parsePayloadData(values, false, that, that.setStateFromChild, this.props.type);
                    // })
                    return this.confirmCreation(that, values, this.props.type).catch(err => {
                        message.error("Error in creating document")
                    })

                }
                return CreateFormHelper.parsePayloadData(values, false, that, that.setStateFromChild, this.props.type);
            }
        });
    }


    sendTransaction = (type, generateIRN, payload, jsonLd, templateObj) => {
        let promise = CreateFormHelper.sendTransaction(type, generateIRN, payload, jsonLd, templateObj, this);
        this.setState({ showLoader: true });
        promise.then(receiptId => {
            console.log('After Create ====', receiptId)
            // data.rId, data.contractAddress
            if (receiptId && this.state.invitationId) {
                const transactionData = Utils.getTransactionData(receiptId);
                return this.sendEvalRequest(this.state.invitationId, transactionData.address, transactionData.id);
            }
            this.analytics.trackEvent(type, this.state.isUpdate ? this.config.update : this.state.isEditable ? this.config.edit : this.config.create, this.config.click, 1)
            message.success(`${type} ${this.state.isUpdate ? "Updated" : this.state.isEditable ? "Edited" : "Created"} successfully`);
            this.props.onSuccess();
        }).catch(exception => {
            Console.error(`create ${type}`, exception);
            this.analytics.trackEvent(type, this.state.isUpdate ? this.config.update : this.state.isEditable ? this.config.edit : this.config.create, this.config.click, 0)
            message.error(`Failed to create ${type}. Reason: ${exception.message}`)
        }).finally(() => {
            this.setState({ showLoader: false, showInnerSpinner: false });
        })
    }

    onChangeAmountLess = (e) => {
        this.setState({
            isAmountLess: e.target.value,
            allowExceedingFields: e.target.value ? 3 : 0
        })
    }

    onChangeContractType = (e) => {
        let selectedValue = e.target.value;
        this.setState({
            contractTypeList: HEPL_TEXT.contractType[selectedValue - 1],
            txnType: selectedValue
        });
    }


    sendEvalRequest = (invitationId, contractAddress, rId) => {
        let vendorManager = NetworkBridge.getVendorManager()
        return vendorManager.evalRequest(invitationId, contractAddress, rId).then(res => {
            this.setState({ showLoader: false })
            message.success(`Evaluation Request created successfully`);
            this.props.onSuccess();
        }).catch(err => {
            this.setState({ showLoader: false })
            this.props.onSuccess();
            Console.error(`Eval Request`, err);
            message.error(`Failed to send Eval request. Reason: ${err.message}`)
        })
    }

    checkCreds = () => {
        if (!Utils.isCredentialExists()) {
            this.setState({
                showCredModal: true
            })
        }
        else {
            this.generateIRN();
        }
    }

    saveCreds = () => {
        Utils.setLocalStorage('gstinUsername', this.state.gstinUsername);
        Utils.setLocalStorage('gstinPassword', this.state.gstinPassword);
        this.setState({
            showCredModal: false
        }, () => {
            this.generateIRN()
        })
    }

    cancelCreds = () => {
        this.setState({
            showCredModal: false
        })
    }

    onAttachWorkFlowClosed = (workflowDetails, workflowTxnType, selectedState) => {
        console.log("WORKFLOW", workflowDetails, workflowTxnType, selectedState)
        if (workflowDetails && workflowDetails.metaData && workflowDetails.metaData.title) {
            this.setState({
                workflowDetails,
                workflowAttached: true,
                workflowTxnType,
                selectedState
            })
        }
        this.setState({ attachWorkFlow: false })
    }

    detachWorkflow = () => {
        this.setState({
            workflowDetails: {},
            workflowAttached: false,
            workflowTxnType: undefined,
            selectedState: undefined
        })
    }

    attachWorkFlow = () => {
        this.setState({ attachWorkFlow: true })
    }

    showItemMapping = (item) => {
        this.setState({ linkItemDrawer: true, selectedItemToMap: item })
    }

    hideMappingDrawer = () => {
        this.setState({ linkItemDrawer: false })
    }

    addMetaInfo = () => {
        this.setState({
            showMetaDrawer: true
        })
    }

    hideMetaInfo = () => {
        this.setState({
            showMetaDrawer: false
        })
    }

    showFileUploadDrawer = () => {
        this.setState({ showFileUploadDrawer: true })
    }

    selectFile = (event) => {
        console.log("Files", event.target.files[0])
        try {
            this.setState({ selectedFile: event.target.files[0], fileName: event.target.files[0].name })
        } catch (e) {
            this.setState({ selectedFile: {}, fileName: 'Upload File' })
        }

    }

    uploadFile = (invoiceNumber) => {
        let url = Config["bosch-middleware-url"]
        // return Promise.resolve("done")
        if (this.state.fileName === 'Upload File') {
            message.info('Please select a file to upload')
            Promise.reject("No file was selected")
        }
        let form = new FormData()
        let file = this.state.selectedFile
        let cnId = Utils.getFromLocalStorage('draftInvoiceNumber')
        if (this.state.heading == "QCreditNote" && this.state.subType === "quality")
            cnId = Utils.getFromLocalStorage('debitAdviceNumber')
        console.log('Uploaded File', file)
        form.append('files', file);
        if (this.state.heading == "QCreditNote" && this.state.subType === "quality")
            form.append('cnID', invoiceNumber)
        else
            form.append('invoiceNumber', invoiceNumber)
        console.log('Draft Invoice Number', cnId)
        if (this.state.heading == "QCreditNote" && this.state.subType === "quality") {
            form.append('daID', cnId);
            url = Config["upload-cn-url"];
        }
        else
            form.append('cnID', cnId);
        // form.append('fromParamID', Utils.getParamId())
        // form.append('toParamID', this.state.parentReceiptData.customer.identifier)
        return fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                version: "1.0"
                //'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.MhQjMm2TlY0uesawWXiQ9dIisMx5yAB7voGCWTeDkO8'
            },
            body: form
        }).then(res => {
            if (res.status === 200) {
                return res.json().then((response) => {
                    console.log("Upload result", response)
                    message.success('File Uploaded Successfully')
                    return response
                }).catch(jsonError => {
                    Promise.reject()
                    message.error(jsonError)
                })
            } else {
                // return res.text().then((text) => {
                //     let response = JSON.parse(text)
                message.error('Error in Uploading file')
                throw new Error()
                // })
            }
        }).catch(err => {
            if (typeof err === "object") {
                message.error("Unable to processs")
                console.error(err)
            } else if (typeof err === "string") {
                message.error(err)
            }
        })
    }
    hideFileUploadDrawer = () => {
        const { uploadedFiles } = this.state;
        let uploadedData = []
        for (let index in uploadedFiles) {
            if (uploadedFiles[index].hasOwnProperty("uploadedFileHash")) {
                uploadedData.push(uploadedFiles[index])
            }
        }
        this.setState({ uploadedFiles: uploadedData, showFileUploadDrawer: false })
    }

    onFileUploadSave = (data) => {
        console.log('OnFileSaveData', data)
        this.setState({ uploadedFiles: data }, () => {
            console.log('uploaded data', this.state.uploadedFiles)
        })
    }

    getAttachmentsCount = () => {
        const { uploadedFiles } = this.state;
        let count = 0;
        for (let index in uploadedFiles) {
            if (uploadedFiles[index].hasOwnProperty("uploadedFileHash")) {
                count++;
            }
        }
        if (!count) { return; }
        else if (count > 1) { return `(${count} files attached)` }
        else { return `(${count} file attached)` }
    }

    saveDynamicFormItem = (formItem, formDataType, metainfoForm) => {
        let dynamicFormData = this.state.dynamicFormData;
        debugger;
        for (let i in formDataType) {
            dynamicFormData[formDataType[i].name] = formDataType[i].defaultValue;
        }
        this.setState({
            metainfoForm,
            dynamicFormItem: formItem,
            dynamicFormDataType: formDataType,
            dynamicFormData,
            defaultDynamicFormData: dynamicFormData
        })
    }

    onChangeDynamicFormData = (value, label) => {
        let dynamicFormData = this.state.dynamicFormData;
        dynamicFormData[label] = value
        this.setState({
            dynamicFormData
        })
    }

    resetDynamicFormDataValues = () => {
        let defaultDynamicFormData = this.state.defaultDynamicFormData;
        this.setState({
            dynamicFormData: defaultDynamicFormData
        })
    }

    setStateFromChild = (setObj) => {
        this.setState({ ...this.state, ...setObj });
    }


    afterLinkCatalogue = (linkedDataSource) => {
        let { dataSource } = this.state;
        for (let linkedIndex in linkedDataSource) {
            const index = dataSource.findIndex(item => item.internalId === linkedDataSource[linkedIndex].internalId);
            dataSource[index] = linkedDataSource[index];
        }
        this.setState({
            dataSource,
            linkItemDrawer: false
        })
    }

    generateIRN = () => {
        const that = this;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                CreateFormHelper.parsePayloadData(values, true, that, that.setStateFromChild, this.props.type);
            }
        })
    }

    stopInnerSpinner = () => {
        this.setState({ showInnerSpinner: false });
    }

    handleLinkPurchase = (currentLinkedReceipts, itemId) => {
        const { dataSource, heading, subType } = this.state;
        const newData = [...dataSource];
        const attachedReceipts = currentLinkedReceipts.length;
        let index = newData.findIndex(item => item.internalId === itemId);
        let item = newData[index];
        item.linkedReceipts = currentLinkedReceipts;
        newData[index].linkPurchaseText = attachedReceipts ? `${currentLinkedReceipts.length} Invoice(s) attached` : "Link Purchase";
        this.setState({ dataSource: newData });
    }

    showLinkPurchaseModal = (item) => {
        this.setState({ showInnerSpinner: true });
        this.refs.linkPurchaseModal.showLinkPurchaseModal(item);
    }

    setGlobalDiscount = (value) => {
        if (isNaN(value) || Number(value) < 0)
            value = 0
        this.setState({ globalDiscountInputValue: value }, () => {
            this.forceUpdate()
        });
    }

    setGlobalDiscountUnit = (value) => {

        this.setState({ globalDiscountUnit: value }, () => {
            this.forceUpdate()
        })
    }

    getWorkFlowIcon = () => {
        const { heading, subType } = this.props;
        const boschSettings = heading == "Invoice" && subType === "logistics"
        return !boschSettings && <a onClick={this.attachWorkFlow} className="gx-mr-2 gx-mt-2"><IconComponent type="paper-clip" />Attach Work Flow</a>
    }

    renderMainContent() {
        const { heading, currency, type, showExtraOptions, subType } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { parentReceiptData, dataSource, amountReceived, disableInvoiceDropDown, noteValuesInfo, totalInvoiceValue } = this.state;
        const parentTabName = Utils.getParentTab(Utils.stringToCamelCase(heading), subType);
        const buttonText = `${this.state.createText} ${heading}`;
        const boschSettings = heading == "Invoice" && subType === "logistics"
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell
            },
        };
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                    type: col.type
                })
            };
        });
        const txnTypeText = <div>
            Transaction Type:
            <ul>
                <li>Private: Between two private nodes securely</li>
                <li>Protected: Encrypted documents are stored on the public nodes</li>
                <li>Public: All nodes sync this data</li>
            </ul>
        </div>

        let grnManagerLabel = heading === "Transport Order" ? "eLR Manager" : "GRN Manager"

        const formatDocumentNo = (e) => {
            return e.target.value.toUpperCase();
        }

        const showGenerateIRN = () => {
            const irpConfig = Utils.getFromLocalStorage("irpConfig") || {};
            return (heading === "Invoice" && irpConfig.irpMode);
        }
        const internalIdLabel = `${Utils.stringToCamelCase(heading)}Number`;
        const grnManagerIndex = this.state.contactSuggestionList.findIndex(val => val.identifier === this.state.parentReceiptData.grnManager);
        const repalceText = (type === 'lsales' || type === 'lpurchases') ? 'Transaction' : heading;
        console.log('Heading: ', heading, "Logistics:", this.state.logistics)
        console.log("this.state.selectedContract", this.state.selectedContract)
        return (
            <div>
                {this.state.showInnerSpinner &&
                    <CircularProgress type="spinner" />
                }
                <Form  {...formItemLayout} onSubmit={this.handleSubmit} >
                    <Row>
                        <Col xl={12} lg={12}>
                            <Form.Item label={type === "purchases" ? "Seller Name" : "Customer Name"}>
                                {getFieldDecorator('cname', {
                                    rules: [
                                        {
                                            required: true, message: 'Please input the customer name'
                                        }],
                                    initialValue: this.state.buyer.name
                                })(
                                    <Select
                                        style={{ width: '15rem' }}
                                        className="suggestion-box"
                                        mode="default"
                                        showSearch
                                        placeholder="Please select"
                                        onChange={(value) => this.onSelectCustomer(value)}
                                        disabled={this.state.fromDetailsPage}
                                        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {this.state.selectContactOptionDetails}
                                    </Select>
                                )}
                                <IconComponent type="info-circle" hoverText={this.state.buyer.identifier ? this.state.buyer.identifier : this.state.toolTipCustomer} />
                            </Form.Item>
                            {(heading !== "Contract" && heading !== "Rate Card" && heading !== "Receipt") &&
                                <Form.Item label="Parent Document">
                                    {/* {getFieldDecorator('parentDocAddress', {
                                        initialValue: getInternalId() || `Witht ${Utils.camelCaseToString(parentTabName)}`
                                    })( */}
                                    <Select
                                        style={{ width: '15rem' }}
                                        className="suggestion-box"
                                        mode="default"
                                        showSearch
                                        placeholder="Please select"
                                        onChange={(value) => this.onSelectParentDocAddress(value)}
                                        filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        disabled={this.state.fromDetailsPage}
                                        value={this.state.selectedContract.internalId || `Without ${Utils.camelCaseToString(parentTabName)}`}
                                    >
                                        {this.state.contractSuggestionList}
                                    </Select>
                                    {/* )} */}
                                </Form.Item>
                            }
                            {
                                <Form.Item label={`${repalceText} Type`}>
                                    <Radio.Group onChange={this.onChangeContractType}
                                        disabled={this.state.fromDetailsPage}
                                        value={this.state.txnType} defaultValue={this.state.txnType}
                                    >
                                        <Radio.Button disabled={!this.state.supportPrivate} value="3">Private</Radio.Button>
                                        <Radio.Button disabled={!this.state.supportProtected || this.state.supportPrivate} value="2">Protected</Radio.Button>
                                        <Radio.Button disabled={this.state.supportPrivate} value="1">Public</Radio.Button>
                                    </Radio.Group>
                                    <IconComponent type="info-circle" hoverText={txnTypeText} />
                                </Form.Item>
                            }
                            {
                                (heading === "GRN") &&
                                <Form.Item label="Invoice Number">
                                    {getFieldDecorator('invoiceNumber', {
                                        rules: [{
                                            required: true, message: 'Please input the invoice Number'
                                        }],
                                        initialValue: this.state.invoiceInternalIdForGrn,
                                        getValueFromEvent: this.onSelectInvoiceNumber
                                    })(
                                        <Select
                                            style={{ width: '15rem' }}
                                            className="suggestion-box grnSelect"
                                            mode="default"
                                            showSearch
                                            onSearch={this.handleInvoiceNoSearch}
                                            placeholder="Select or Enter Invoice No"
                                            onChange={(value) => this.onSelectInvoiceNumber(value)}
                                            filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            disabled={disableInvoiceDropDown}
                                        >
                                            {this.state.invoiceNoSuggestionDetails}
                                        </Select>
                                    )}
                                </Form.Item>
                            }
                            <Form.Item label={`${heading} Number`}>
                                {getFieldDecorator(internalIdLabel, {
                                    rules: [{
                                        required: true, message: `Please input ${heading} Number`
                                    }],
                                    getValueFromEvent: formatDocumentNo,
                                    initialValue: this.state.parentReceiptData[internalIdLabel]
                                })(
                                    <Input
                                        style={{ width: '15rem', textTransform: "uppercase" }}
                                        onChange={formatDocumentNo}
                                    />
                                )}
                            </Form.Item>

                            {(heading === "Purchase Order" || heading === "Transport Order") &&
                                <Form.Item label={grnManagerLabel}>
                                    {getFieldDecorator('grnManager', {
                                        rules: [
                                            {
                                                required: true, message: 'Please select ' + grnManagerLabel
                                            }],
                                        initialValue: grnManagerIndex !== -1 ? grnManagerIndex : undefined
                                    })(
                                        <Select
                                            style={{ width: '15rem' }}
                                            className="suggestion-box"
                                            mode="default"
                                            showSearch
                                            placeholder="Please select"
                                            onChange={(value) => this.onSelectGRNManager(value)}
                                            filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {/* GRN Mangers options */}
                                            {this.state.selectContactOptionDetails}
                                        </Select>
                                    )}
                                </Form.Item>
                            }
                            <Form.Item label="Date">
                                {getFieldDecorator('date', {
                                    rules: [{
                                        required: true, message: 'Please input date'
                                    }],
                                    initialValue: this.todayDate
                                })(
                                    <DatePicker className="datePicker" />
                                )}
                            </Form.Item>
                            {
                                subType !== "logistics" &&
                                <Form.Item label="Delivery Location">
                                    <AutoCompletePlaces
                                        value={this.state.address}
                                        onChange={this.handleChangePlace}
                                        onSelect={this.handleSelectPlace}
                                    />
                                </Form.Item>

                            }
                            {
                                heading === "Contract" &&
                                <div>
                                    <Form.Item label="Expiry Date">
                                        {getFieldDecorator('expiryDate', {
                                            rules: [{
                                                required: true, message: 'Please input expiry date'
                                            }],
                                            initialValue: this.todayDate
                                        })(
                                            <DatePicker className="datePicker" />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Price Less">
                                        {getFieldDecorator('amountLess', {
                                            initialValue: false
                                        })(

                                            <Radio.Group
                                                onChange={this.onChangeAmountLess}
                                                value={this.state.isAmountLess}
                                                defaultValue={false}
                                            >
                                                <Radio.Button value={true}>Yes</Radio.Button>
                                                <Radio.Button value={false}>No</Radio.Button>
                                            </Radio.Group>
                                        )}
                                        <IconComponent type="info-circle" hoverText={this.state.isAmountLess ? priceLessText.priceLessEnabled : priceLessText.priceLessDisabled} />
                                    </Form.Item>
                                    {this.state.isAmountLess &&
                                        <Form.Item label="Allow">
                                            <Checkbox.Group options={BLOCK_OPTIONS} defaultValue={this.state.isAmountLess ? [1, 2] : []} onChange={this.handleBlockOptionsChange} />
                                        </Form.Item>
                                    }
                                    {type === 'sales' &&
                                        <Form.Item label="Share Catalogue">
                                            <Radio.Group
                                                onChange={this.handleShareCatalogue}
                                                value={this.state.supportProtected ? this.state.shareCatalogue : 0}
                                                defaultValue={false}
                                                disabled={!this.state.supportProtected}
                                            >
                                                <Radio.Button value={1}>Yes</Radio.Button>
                                                <Radio.Button value={0}>No</Radio.Button>
                                            </Radio.Group>
                                            <IconComponent type="info-circle" hoverText={this.state.supportProtected ? shareCatalogueText.protectedEnabled : shareCatalogueText.protectedDisabled} />
                                        </Form.Item>
                                    }
                                </div>
                            }
                            {
                                (heading !== "GRN") && !boschSettings &&
                                <Form.Item label="Payment Terms">
                                    {getFieldDecorator('paymentTerms', {
                                        rules: [{
                                            required: true, message: 'Please input payment terms'
                                        }],
                                        initialValue: (parentReceiptData && parentReceiptData.paymentTerms) ? parentReceiptData.paymentTerms : 0
                                    })(
                                        <Input type="number" min={0} style={{ width: '15rem' }} onFocus={(e) => e.target.select()} />
                                    )}
                                </Form.Item>
                            }
                            {
                                heading === "Invoice" && !boschSettings &&
                                <Form.Item label="Expected Delivery Date">
                                    {getFieldDecorator('expectedDeliveryDate', {
                                        rules: [{
                                            required: true, message: 'Please input expected delivery date'
                                        }],
                                        initialValue: this.todayDate
                                    })(
                                        <DatePicker className="datePicker" />
                                    )}
                                </Form.Item>
                            }
                        </Col>
                        <Col xl={12} lg={12}>
                            {
                                this.state.dynamicFormItem && this.state.dynamicFormItem.length > 4
                                    ?
                                    <div>
                                        {this.state.dynamicFormItem.slice(0, 4)}
                                        <div class="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
                                            <a className="gx-ml-5" onClick={() => {
                                                this.setState({
                                                    showAllDynamicFormItems: true
                                                })
                                            }}>Show More</a>
                                        </div>
                                    </div>
                                    :
                                    this.state.dynamicFormItem
                            }
                        </Col>
                    </Row>
                </Form>
                <Row className="bottomsection">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            bordered
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            style={{ overflow: "auto" }}
                        // scroll={{x: 1500}}
                        />
                    </Col>
                </Row>
                <Row className="row-5 totalrow">
                    <Col className="row-5__col-1" xl={16} lg={14} md={14} sm={10} xs={8} >
                        {showExtraOptions &&
                            <div>
                                {
                                    (this.state.subType !== 'evalRequest' && !this.state.parentDocId) &&
                                    <a href="#" className="links addtext" onClick={this.handleAdd}>
                                        {(dataSource === undefined || dataSource.length === 0) ? '+Add item' : '+Add another item'}
                                    </a>
                                }
                                <Divider type="vertical" />
                                {this.state.workflowAttached ?
                                    <Tooltip title="Click to Detach this workflow">
                                        <a onClick={this.detachWorkflow}><IconComponent type="paper-clip" /> &nbsp;{this.state.workflowDetails.metaData.title} Attached</a>
                                    </Tooltip>
                                    :
                                    this.getWorkFlowIcon()
                                }
                                <Divider type="vertical" />
                                {!boschSettings && <a className="gx-mr-2 gx-mt-2" onClick={this.addMetaInfo}>+Add More Fields</a>}
                                <Divider type="vertical" />
                                <a className="gx-mr-2 gx-mt-2" onClick={() => { this.fileSelector.click() }}>
                                    <IconComponent type="paper-clip" />
                                    <span>{this.state.fileName}</span>&nbsp;&nbsp;
                                    {<span>{this.getAttachmentsCount()}</span>}
                                </a>
                            </div>
                        }
                        <Row style={{ marginTop: '1.5rem' }}>
                            <Col xl={2} lg={2} md={2} sm={12} xs={24} style={{ paddingTop: '0.6rem' }}>
                                <label className="label" id="cnote">Notes:</label>
                            </Col>
                            <Col xl={18} lg={18} md={18} sm={12} xs={24}>
                                <TextArea className="row-6___input" onChange={this.setCustomerNote} rows={2} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={8} lg={10} md={10} sm={14} xs={16}>
                        {heading === "Payment" ?
                            <MismatchTable
                                setAmountToBePaid={(value) => {
                                    this.setState({ amountToBePaid: Number(value || 0) })
                                }}
                                ref="mismatchTable"
                                editAmountToBePaid={true}
                                totalInvoiceValue={totalInvoiceValue}
                                noteValuesInfo={noteValuesInfo}
                                amountPaid={amountReceived}
                                currency={currency}
                                createPayment={true}
                                globalDiscountInputValue={this.state.globalDiscountInputValue}
                                globalDiscountUnit={this.state.globalDiscountUnit}
                                dataSource={dataSource}
                                setStateFromChild={this.setStateFromChild}

                            />
                            :
                            <TotalTable
                                ref="totalTableRef"
                                dataSource={dataSource}
                                // style={heading === "Payment" && { display: 'none' }}
                                hideTable={heading === "Payment"}
                                currency={currency}
                                setGlobalDiscount={this.setGlobalDiscount}
                                setGlobalDiscountUnit={this.setGlobalDiscountUnit}
                                globalDiscount={this.state.globalDiscount}
                                globalDiscountUnit={this.state.globalDiscountUnit}
                                globalDiscountInputValue={this.state.globalDiscountInputValue}
                                disableDiscountInput={this.state.parentDocId}
                                setStateFromChild={this.setStateFromChild}
                            />
                        }
                    </Col>
                </Row>
                {showExtraOptions &&
                    <>
                        <Divider />
                        <Row className="row-6">
                            <Col xl={15} lg={13} md={13} sm={9} xs={7}>
                            </Col>
                            <Col xl={8} lg={10} md={10} sm={14} xs={16} style={{ marginTop: '0.5rem', right: '-4%' }}>
                                {
                                    showGenerateIRN() &&
                                    <ShowIRNQR irnGenerated={this.state.irnGenerated} irn={this.state.irn} generateIRN={this.generateIRN} />
                                }
                            </Col>
                        </Row>
                        <Row className="row-6" >
                            <Col xl={15} lg={14} md={12} sm={12} xs={24} style={{ marginTop: '1rem' }}>
                                {
                                    this.state.invitationId ?
                                        <Button onClick={this.handleSubmit} type="primary" style={{ backgroundColor: '#6236ff', margin: '0' }}>
                                            {this.state.isShareCatalogue ?
                                                'Create'
                                                : 'Create Eval Request'}
                                        </Button>
                                        :
                                        <Button onClick={this.handleSubmit} disabled={this.state.showInnerSpinner || this.state.fileName === 'Upload File' && this.state.currentTab === "draftinvoice"} type="primary" style={{ backgroundColor: '#6236ff', margin: '0' }}>{buttonText.toLowerCase() === "create invoice" ? "Upload Invoice" : buttonText}</Button>
                                }
                            </Col>
                        </Row>
                    </>
                }
                {
                    this.state.attachWorkFlow &&
                    <AttatchWorkFlow onWorkflowButtonClicked={this.onAttachWorkFlowClosed} onClose={this.onAttachWorkFlowClosed} contacts={this.state.contacts} docId={this.state.receiptId} currentState={this.state.heading} />
                }
                <AddMetaInfo isDrawerVisible={this.state.showMetaDrawer} onClose={this.hideMetaInfo} getDynamicFormItem={this.saveDynamicFormItem} onChangeDynamicFormData={this.onChangeDynamicFormData} getFieldDecorator={getFieldDecorator} dataSource={this.state.dynamicFormDataType} />
                <Drawer
                    title={<><h2 style={{ margin: 0 }}>Item Mapping ⇔ Catalogue Master Table</h2>
                        <p style={{ margin: 0 }}>Some text here about explanation of Item Mapping</p></>}
                    placement="right"
                    closable={false}
                    visible={this.state.linkItemDrawer}
                    onClose={this.hideMappingDrawer}
                    style={{ paddingLeft: 0 }}
                >
                    <ItemMapping
                        providerParamId={this.state.providerParamId}
                        customerParamId={this.state.customerParamId}
                        item={this.state.selectedItemToMap}
                        parentReceiptData={this.state.parentReceiptData}
                        closeDrawer={this.hideMappingDrawer}
                        afterLinkCatalogue={this.afterLinkCatalogue}
                    />
                </Drawer>
                {
                    this.state.showFileUploadDrawer &&
                    <FileUploadComponent
                        isDrawerVisible={this.state.showFileUploadDrawer}
                        onClose={this.hideFileUploadDrawer}
                        data={this.state.uploadedFiles}
                        type={this.state.txnType}
                        onFileUploadSave={this.onFileUploadSave} />
                }
                <Modal
                    title="GSTN Credentials"
                    visible={this.state.showCredModal}
                    onOk={this.saveCreds}
                    onCancel={this.cancelCreds}
                >
                    <Input placeholder="Enter GSTN Username" onChange={(e) => { this.setState({ gstinUsername: e.target.value }) }} />
                    <br />
                    <Input.Password placeholder="Enter GSTN Password" style={{ marginTop: '1rem' }} onChange={(e) => { this.setState({ gstinPassword: e.target.value }) }} />
                </Modal>
                <Modal
                    title="Additional Info"
                    visible={this.state.showAllDynamicFormItems}
                    onOk={() => {
                        this.setState({
                            showAllDynamicFormItems: false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            showAllDynamicFormItems: false
                        })
                    }}
                >
                    <Form {...formItemLayout}>
                        {this.state.dynamicFormItem}
                    </Form>
                </Modal>
                <SCM ref="linkPurchaseModal" handleLinkPurchase={this.handleLinkPurchase} stopInnerSpinner={this.stopInnerSpinner} />
                <QRLoader showQRLoader={this.state.showQRLoader} />
            </div>
        )
    }
}
const CreateFormComponent = Form.create()(withRouter(CreateForm));
export default CreateFormComponent;