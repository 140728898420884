import React from "react";
import { ReactComponent as NoInvitations } from '../../../assets/images/noInvitations.svg';
import { withRouter } from 'react-router';
import { Button, Icon } from 'antd';
import * as Utils from '../../../util/utils.js';
import RestoreReceipts from "../../../database/nosql/sync/receipts";
import ParamConnector from "../../../param-connector";
import NetworkBridge from '../../../util/network-bridge';
import { ApolloClient, InMemoryCache, gql, createHttpLink, split, HttpLink } from '@apollo/client';
import Analytics from '../../../analytics';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import GraphQL from "../../../param-libs/graph-ql";
import { resetWarningCache } from "prop-types";

const info = {
    "data": "f202767325ff940b4ecc0d57001822c7decabfddd120f82167c1bbb34b1a4b0bde6076c19271291a9dfe844f6c420e5cf13de89b565fbe060b44a8351594147212d8U2FsdGVkX19JOjQ/RPCpBvUA2VrsVHC8kLBouO7hCof2pdCkfDvjd3a6h1PjRXtkdm+3emylUY8pHZKwWENNrwCMP/WWWtb+TFwPW+HBnAD1w9PQoxemLkXWQFgLkNRfyQ6CUbPZ5xiUg7c4lIvOBpbuVNRqxngoMQELuH0IHrQ6xHQgLda/Ce8AlZ5v1IGzmTDt/OP/4T+R3AXJhLc2VMh0xcV2ws3nmj6t6+XcpjU9Zetb6r/4EjLRvYZe39CaVtfj1LAX0XchEeiWrBJXLQbTMv6pAq8bdFxQbZp4jbWOHnQn3tu1B4x9bX864ra+flJ9Yo7UwjV3qnNJN/PyZaOmqt4fBlyQppL0hwy3P6YSYIZJFb/r76VBn+XRZJQ9VdDKUOEL5boe5GQD1zopuRcoYXLSCXF5oQq+/ZAld7IdqaIGlDshcSFePRoS+YCdv2oKirfDUeVMdT3uOW3cKusJ4ncyIcykE8yCvmPL6kP7xXi0oLaeQKqEIoFCXyEfS46dAAcVDlSHY/rVCXYxKDiuhTF2spcQZ1DWXqRH9cq/5hhSIQciFgL4YkYVcsmxmXwHH/wU6bdbVO82tJsS3713xgJuMclZb9b2VNQ+khSbRQMDogC20czm6I5W6/z6actVlullOJl9WkeRr+kdYHEymrMSO7Hwm2VF3XMa1dQSFHds1JDE7ZCk4XA6d0WQYg01iJMhprE0/W2Pc/wPRw==U2FsdGVkX19rbO2y0WRttsz26Je2Nf5H9XigPfRkeOJQf+q+2Vz6QxDowOynvzoMF1TLU3eLjPWbtINHnc/xDKREGoRP76Mc+tu81qNqP2L9Fet4K0hopOZPbX+TDevfDRbTidcLgQFelLBbYkYgbG2m6013a9/g4g/5tu1WB7y+5dMN/gytVIl9wlXsQ6khfHPmrKUy/SgSuqZejr5nSQfhM7/m1RHjZ7c1XI46aw/hDkXX1R62J/ycRQ1tJCrhSZ4OEIdcZq4L2+D2lye37Sj5y81sVxUAwYA4XkOV8Y8PRbevGrFg+WucamzXGWVNlYazfLOF3xEyI1BwK/40LMMKLyamkx3HT4tZWIDDQNn5nTdqbXwn90FhiQi+52sjeajhlCBAiaSondgCB0Mjti6YbBRmJ1mYhuKJMD4Mqo/DrXXN3oNwNzO36JuMSW5G5u1KtS2ZZW6WP6UFJ440eF+h3knbDB5BENego+LwfecKcn+NYmFyZul7teQ90ZpStL6BHpaC+eJJi0E2WhVr0rbSUhWhkHWYjFYEAnx8wEaOUtf6nFhBXOKQoBUVRjX2MS3IDfS15cTmfa/TQXoTxp9okDyqJzEaQ1QSqVX1SvmPnShKmHLRLMA/QsVK0p72t99hzNXr8iBDs7qDuNxQjiQv6qrp+iDdaBqNav1fnVOXE+NChvSEHi0EMM/fAquGGv8MLFOTumeVrlzpW7gOegNee2yabEdRjvPnmiFugy8iFpm5PVqUJn2Dclzq6yN141RY7hcUUriTFu8snJ1KfzUeHnEhlUA9mPDsbPS+1tb0ZI0AnpC6na2q50YE5WCv2on8cgzBU9m34tygBbloUSKzXB6kEkkJ2LEOzvy6pAM=f202767325ff940b4ecc0d57001822c7decabfddd120f82167c1bbb34b1a4b0bde6076c19271291a9dfe844f6c420e5cf13de89b565fbe060b44a83515941472"
}
const txnType = 'PROTECTED'
const address = "0xC7D3e3FC759f42d9dC0f72386DfE582593b0979a"

class Test extends React.Component {
    constructor(props) {
        super(props);
        Analytics.getInstance().trackPageview();
        this.state = {
            result: []
        }
        this.graphQl = GraphQL.getInstance()
    }
    componentDidMount() {



    }

    // getContactQuery = () =>{
    //     const cache = new InMemoryCache();
    //     const httpLink = new HttpLink({
    //         uri: "http://localhost:3001/query",
    //         options: {
    //             reconnect: true
    //         }
    //     })

    //     this.apolloClientConnector = new ApolloClient({
    //         cache: cache,
    //         link: httpLink,
    //         // Provide some optional constructor fields
    //         name: 'react-web-client',
    //         version: '1.3',
    //         queryDeduplication: false,
    //         defaultOptions: {
    //             watchQuery: {
    //                 fetchPolicy: 'cache-and-network',
    //             },
    //             query: {
    //                 fetchPolicy: 'network-only',
    //                 errorPolicy: 'all',
    //             },
    //             mutate: {
    //                 errorPolicy: 'all',
    //             },
    //         },
    //     });
    //     let queryOption = {
    //         query:gql`{
    //                 getAllContacts(filterOptions:{dbSource:OFF_CHAIN,startIndex:0,pageSize:10,owner:"0xb20875Ed07f39DB5eB13489C0E353Afa158eDdd9"}){
    //                     totalRecords,
    //                     IDs
    //                 }
    //         }`
    //     }
    //     this.apolloClientConnector.query(queryOption).then((res)=>{
    //         console.log('====getAllContacts res', res)
    //     }).catch(err=>{
    //         console.error('====getAllContacts Error', err)
    //     })
    // }

    registerContactEvents = (data) => {
        console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeevvvvvvvvvvvvveeeeeeeeennnnnnnnnnnnnttttttt')
    }




    getGQL = () => {
        debugger
        return this.graphQl.contacts.getAllContacts(Utils.getParamId()).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })

    }
    getPostGQL = () => {
        let add = '0xdcEa6bEdcC01114E3a5dd8e7FD85d8c3BC229686'
        let info = {
            "data": "cff23832504f4dd3c4f81e496ade8b9041f446cd21df9d264d2a9ea26b87e9b829a50552fcbf44e762b2a9dfa90fd96aa79412d72cf4e7c9764524b7b2eb0b2a12d8U2FsdGVkX1/GshH8VrhVHc7SGY/P5XvOWxvt1+PU6rTbj51+I+JbdHnL5Mj5fklyrF/w7zOUjoPTZpFW0al6vxfm0I3CVcNKPeqpzeiRptUVA0h/a10/9wBdZ3wSGOj2zkcIc5aBPD7hH+VbI5iz+b+gZkxm4v6Pu1PjuNhoUjG4mcTfjxZV1KvK9xGgUfTB+8ENMDTvamo0DBICT85sqj+lJggx0Rs47rnpa9JPqb9WbPTx8q/NTGP2IS5eo9F19bDL5Q8HSEPuA6BWzthB6L57KhQf8TCeZ8hVpJa3jx9CCLDD55nXdrVmk2/HTYXDaY+TmbQLyye+SaxJo2pYiyR6AvB6A9GGG71UB0e7g8MmMulKxbWhcLqghzegwU6HUVBmN/YdbRwWPPTuuNLZRMLYgWBGCHLSgfraaQkPDFZ+OyKA6uIh1JmL+x5NuZNHj1NixFYZwHwnOEOO6tdMfx3V3hFYF6jfYyUnPtJ4gO4IXv+sIo0NpQHbh5RYgFdg+i3GaAj3Jm5lRytLEXgk/0UPDGbl6xSq50XJjYO/RER5mk4VDJcnkwD9oFTO9JCB1hyST0Xw2vnzFY0OOsn4v0Qt5FH9ongLnFFsjfldOB+5OmMeiarzVlMqbE2qmcMQqdHnnyAycvmgTaE7GYEfxXGPHzVXkTvXs7OWoK7jgnxei6EiEBFyeIXMXCTDVh5Biiq7wAE+Mco9kq5ny1jwAw==U2FsdGVkX1/Poq3ju5/7KaeTaXVziJKWPO+Rc5GJlQb0C8Ow2ZJX9E07U+HQqTCtBInzHOQ7NQ4bQbCXJ8g9h6MOL2a9FbihyftkaLuY2H6AnKSXzel2Lb7A4htBMxA0garF6cQ1CPuD9kIazKFM82Qe+A4NluUg8BwtYsNDDcWnjBe6H/Yug967+wp+fkHSh+UwJxwmVNUaWGfI6xAIWaWWGksOU68J3RO6Dwtk4Bc4cXAiK3gGs6RYoF0mDJD7PyFys45EgksnM3mVltY5mOrItXaQrG5pJLT56LmqFjNBcCMPYEa8N1Jn/j2hCyCLlNCaxhhjKoUr0v44Pzpu0p9uDXxV4PoPgKJxz9gNps05eNsmDrSYe7rHVtjSfo/R7mR0adrLZJcMho0CEF1UN5//kAnL5DVei8jNOjEjMbkj36sRfz9f7ccPSXxsA9GDCI+jvtuUdB7QuGS501hQJMDIfVh/a6p0Sx6xzslqp44ji/SwsGC+ihpMM50rR2hrtrXqztDZ0KmEmKSbHVfMBnBg2cdklIw8nNsCZqvUHMQbpZJkc+VSRpuwYk4LWFf2AHPpKKlSpn774J3WVVtSW+mF+gBYA89uGPq3ZnfFOcONt6f2BHFd5mTAf9HZ7UP/SPv+K/kTPpneFTD5jE8BBVRdo3BdUHFvQSNy4ArbhjAQUAdCeVK0MhBRtDo4oKzfPqvUy7acLBKEiPY+EdAtm7wYvSzz3l55OiNoXokRQ+vgi7LA01KxXV8j/F59HnLnMsaxbAAzGMF6ZH3EMN1IUw==cff23832504f4dd3c4f81e496ade8b9041f446cd21df9d264d2a9ea26b87e9b829a50552fcbf44e762b2a9dfa90fd96aa79412d72cf4e7c9764524b7b2eb0b2a"
        }
        let type = "PROTECTED"
        for (let index = 0; index < 10; index++) {
            return NetworkBridge.getContactManager().addContact(add, info, type)

        }
    }





    testCase = () => {
        let docId = "0xd281f43a2dfd9ce24b37dede7b196f5afac9e5d4b9a2cc220136bc17d2326caf-0x4A65da1Ce71BD34864Db6F8eB5389Cf6a03D2815";
        const receiptIdInfo = Utils.getTransactionData(docId);
        let contactData = {
            "@context": "http://schema.org/",
            "@type": "Person",
            "name": "prahlad",
            "identifier": "0x44cD4Ab2d30C7632F6BaD840BE7CEb80aA534B6a",
            "address": {
                "@type": "PostalAddress"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "91"
            },
            "email": "a@a.aa",
            "telephone": "91",
            "internalId": "23432",
            "hasOccupation": [],
            "jobTitle": {
                "@type": "DefinedTerm"
            },
            "worksFor": {
                "@type": "Organization"
            },
            "nationality": {
                "@type": "Country",
                "name": "IN"
            },
            "publicKey": "d251001b0886f80e9ec1f8f93f4ed1acfea99197760a5466e6a4c260a3e6a196ddc5adfde02fbf0d33c4d12ad42e0eb1ebd3e03cb64896aff38074643af513ab",
            "@id": "0x7957bef41b54be519272936f7dd5c40a6f6c757265456645dd0da48da91efb7a-0xDe3D2d87C02657A53fC379b4d45eeD9188266856",
            "owner": "0x0Adad153D4654bc4dF0e15dFAEdf0882E96C6d22",
            "status": "0"
        };
        const postFunction = (index) => {
            contactData.name = `prahlad-${index}`;
            const options = Utils.getNetworkOptions();
            return ParamConnector.getInstance().getNetwork().getContactManager("0xDe3D2d87C02657A53fC379b4d45eeD9188266856").addContact("0x44cD4Ab2d30C7632F6BaD840BE7CEb80aA534B6a",
                JSON.stringify(contactData), "1", options
            ).then(() => {
                if (index === 30) {
                    return;
                }
                return postFunction(++index);
            }).catch((err) => {
                console.error(err);
                if (index === 30) {
                    return;
                }
                return postFunction(++index);
            })
        }

        postFunction(0);
    }



    render() {
        return (
            <div>
                <Icon component={NoInvitations} />
                <Button onClick={this.syncData}>create custom graph</Button>
                <Button onClick={this.testRoot}>getGraphDataset</Button>
                <Button onClick={this.testCase}>getTestCaseRes</Button>
                <Button onClick={this.getGQL}>get graphql data</Button>
                <Button onClick={this.getPostGQL}>Post graphql data</Button>

            </div >
        )
    }

}

export default withRouter(Test);
