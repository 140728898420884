class Subscriber {

    constructor(mongoDBConnector) {
        this.mongoDBConnector = mongoDBConnector;
    }

    addSubscriber = (receiptId, receipt, subscriber) => {
        return this.mongoDBConnector.invoke("subscribers", "addSubscriber", receiptId, receipt, subscriber)
    }

    addSubscriberForParticipant = (receiptId, subscriber) => {
        return this.mongoDBConnector.invoke("subscribers", "addSubscriberForParticipant", receiptId, subscriber)
    }

    addConsent(receiptId, consent) {
        return this.mongoDBConnector.invoke("subscribers", "addConsent", receiptId, consent)
    }

    _addInternalConsent = (receiptId, key, value) => {
        return this.mongoDBConnector.invoke("subscribers", "_addInternalConsent", receiptId, key, value)
    }

    getConsentsForReceipt = (receiptId) => {
        return this.mongoDBConnector.invoke("subscribers", "getConsentsForReceipt", receiptId)
    }

    isValidConsent = (receiptId) => {
        return this.mongoDBConnector.invoke("subscribers", "isValidConsent", receiptId)
    }

    getAllSubscribers = (receiptId) => {
        return this.mongoDBConnector.invoke("subscribers", "getAllSubscribers", receiptId)
    }

    getReceiptsBySubscriberAndStep = (address, step) => {
        return this.mongoDBConnector.invoke("subscribers", "getReceiptsBySubscriberAndStep", address, step)
    }

    getReceiptsBySubscriber = (address) => {
        return this.mongoDBConnector.invoke("subscribers", "getReceiptsBySubscriber", address)
    }

}
export default Subscriber;
