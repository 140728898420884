import React, { Component } from 'react';
import BaseComponent from '../BaseComponent';
import * as Utils from '../../../util/utils';
import EventManager from '../../../event-manager/event-manager';
import { Avatar, Divider, Icon } from 'antd';
import { UserOutlined, CheckCircleTwoTone, CheckCircleFilled } from '@ant-design/icons';
import IconComponent from '../../../components/IconComponent';
import Web3Utils from 'web3-utils';
import './index.less';

class AccountListing extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            profileData: {},
            accounts: []
        }
    }

    componentDidMount() {
        console.log("accountListing mounted")
        let profileData = {};
        let accounts = Utils.getFromLocalStorage('accounts');
        if (accounts === '') {
            accounts = []
            let accountObj = {
                'paramId': "",
                'nick_name': ""
            };
            accountObj.paramId = Utils.getFromLocalStorage('param_id');
            accountObj.nick_name = Utils.getFromLocalStorage('nick_name');
            accounts.push(accountObj)
        }
        let profileObj = Utils.getFromLocalStorage('profile');
        if (profileObj) {
            profileData = JSON.parse(profileObj);
        }
        this.setState({ profileData, accounts })
        EventManager.getInstance().registerEvent('onProfileUpdate', this.profileUpdated);
    }

    profileUpdated = (data) => {
        let accounts = Utils.getFromLocalStorage('accounts');
        if (accounts === '') {
            accounts = []
            let accountObj = {
                'paramId': "",
                'nick_name': ""
            };
            accountObj.paramId = Utils.getFromLocalStorage('param_id');
            accountObj.nick_name = Utils.getFromLocalStorage('nick_name');
            accounts.push(accountObj)
        }
        this.setState({ profileData: data, accounts })
    }

    onAccountSelected = (account) => {
        this.setState({ showLoader: true })
        let accountsArray = this.state.accounts;
        console.log("selected-account", account, accountsArray)
        let index = accountsArray.indexOf(account);
        accountsArray.splice(index, 1)
        accountsArray.unshift(account)
        console.log("Rearranged array", accountsArray);
        Utils.setLocalStorage("encryptedMnemonic", accountsArray[0].mnemonic);
        Utils.setLocalStorage("privateKey", accountsArray[0].privateKey);
        Utils.setLocalStorage("publicKey", accountsArray[0].publicKey);
        Utils.setLocalStorage("nick_name", accountsArray[0].nick_name);
        Utils.setLocalStorage("param_id", Web3Utils.toChecksumAddress(accountsArray[0].paramId));
        let profile = JSON.parse(Utils.getFromLocalStorage('profile'))
        profile.identifier = accountsArray[0].paramId
        profile.name = accountsArray[0].nick_name
        Utils.setLocalStorage('profile', JSON.stringify(profile))
        Utils.setLocalStorage('accounts', accountsArray)
        this.setState({ accounts: accountsArray, currentAccount: accountsArray[0], showLoader: false, accountSelectionVisible: false })
        EventManager.getInstance().emitEvent('onProfileUpdate', profile)
        window.location.replace('/#/main/finance/sales/draftinvoices')
    }

    listAccounts = () => {
        const { accounts } = this.state;
        console.log("accountListing", accounts)
        return (
            accounts && accounts !== '' &&
            accounts.map((account, key) => (
                <div className="topbar__select-account" onClick={() => this.onAccountSelected(account)} >
                    {
                        key === 0 ?

                            <div>
                                <div className="gx-contact-item" style={{ padding: "0" }}>
                                    <div className="gx-module-list-icon">
                                        <div className="gx-ml-4 gx-d-none gx-d-sm-flex">
                                            <Avatar size="large" icon={<UserOutlined />} />
                                        </div>
                                    </div>
                                    <div className="gx-module-list-info gx-contact-list-info">
                                        <div className="gx-module-contact-content">
                                            <p className="gx-mb-1">
                                                <span className="gx-text-truncate gx-contact-name" style={{ fontSize: "1rem" }}>{account.nick_name} </span>
                                            </p>
                                            <div className="gx-text-muted">
                                                <span style={{ display: "block", textOverflow: "ellipsis" }}>{account.paramId} </span>
                                            </div>
                                        </div>
                                        <div className="gx-module-contact-right">
                                            <CheckCircleFilled style={{ color: "MediumSlateBlue" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :

                            <div>
                                <div className="gx-contact-item" style={{ padding: "0" }}>
                                    <div className="gx-module-list-icon">
                                        <div className="gx-ml-4 gx-d-none gx-d-sm-flex">
                                            <Avatar size="large" icon={<UserOutlined />} />
                                        </div>
                                    </div>
                                    <div className="gx-module-list-info gx-contact-list-info">
                                        <div className="gx-module-contact-content">
                                            <p className="gx-mb-1">
                                                <span className="gx-text-truncate gx-contact-name" style={{ fontSize: "1rem" }}>{account.nick_name} </span>
                                            </p>
                                            <div className="gx-text-muted">
                                                <span style={{ display: "block", textOverflow: "ellipsis" }}>{account.paramId} </span>
                                            </div>
                                        </div>
                                        <div className="gx-module-contact-right">
                                            <Icon type="check-circle" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    <Divider />
                </div>
            )
            )
        )
    }

    render() {
        return (
            <div className="gx-main-content">
                {this.listAccounts()}
            </div>
        );
    }
}

export default AccountListing;