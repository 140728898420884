import React, { Component } from 'react';
import { Select, Modal } from 'antd';
import * as Utils from '../../../../util/utils';
import NetworkBridge from '../../../../util/network-bridge';
import Console from '../../../../logger';
import InitHelper from './initHelper';
import Wrapper from './wrapper';

const HEPL_TEXT = require('../../../components/CreateFormComponent/helptext.json')
const { Option } = Select;
const { confirm } = Modal;
class OnSelectHelper extends Component {

    static onSelectItem = (selectedItemdetail, row, namespace) => {
        let itemType = selectedItemdetail.itemType;

        if (!selectedItemdetail || !selectedItemdetail.name) {
            return;
        }
        const newData = [...namespace.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        const inventory = selectedItemdetail.inventory;
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        if (inventory && inventory.inventoryLevel) {
            newData[index].quantity = inventory.inventoryLevel.currentValue || 0;
            newData[index].unitPrice = inventory.price || 0;
            newData[index].sgst = inventory.sgst || 0;
            newData[index].cgst = inventory.cgst || 0;
            newData[index].igst = inventory.igst || 0;
            newData[index].discount = inventory.discount || 0;
            newData[index].subTotal = Number(newData[index].quantity) * Number(newData[index].unitPrice);
        }
        newData[index].type = itemType;
        newData[index].item = selectedItemdetail.name;
        newData[index].internalId = selectedItemdetail["@id"];
        newData[index].materials = selectedItemdetail.material;
        newData[index].productId = selectedItemdetail["productId"];

        newData[index].additionalProperties = selectedItemdetail["additionalProperties"];

        newData[index].status = selectedItemdetail.status;
        newData[index].mappingInfo = selectedItemdetail.mappingInfo;


        //Logistics newData components
        newData[index].truckType = selectedItemdetail.truckType;
        newData[index].load = Number(selectedItemdetail.truckCapacity);
        newData[index].logistics = selectedItemdetail.isLogistics;

        const amt = (selectedItemdetail.isLogistics ? parseInt(newData[index].load) : Number(newData[index].quantity)) * Number(newData[index].unitPrice)
        const totalTax = Number(newData[index].sgst) + Number(newData[index].cgst) + Number(newData[index].igst)
        const taxValue = totalTax / 100 * amt;
        const discount = newData[index].discount ? newData[index].discount / 100 * amt : 0
        newData[index].amount = amt + taxValue - discount;
        return newData;
    }

    static onSelectCustomer = (value, option, namespace, setStateFromChild) => {
        let selfParamid = Utils.getParamId(), type = namespace.state.type;
        let customerParamId = namespace.state.contactSuggestionList[value]["identifier"];
        let supportPrivate = false, supportProtected = false, txnType = "1";
        let publicKey = namespace.state.contactSuggestionList[value].publicKey;
        const subType = namespace.state.subType;
        let refManager = Wrapper.getRefManager();
        // let customerIsEnterprise = receiptManager.isEnterpriseNode(customerParamId);
        // let selfIsEnterprise = receiptManager.isEnterpriseNode(selfParamid);
        let customerIsEnterprise = Promise.resolve();
        let selfIsEnterprise = Promise.resolve();
        let contractSuggestionList = Promise.resolve([]);
        let logistics = namespace.state.logistics
        if (!namespace.state.fromDetailsPage && namespace.state.heading !== "Contract" && namespace.state.heading !== "Rate Card") {
            let step;
            switch (namespace.state.heading) {
                case "Transport Order":
                case "Purchase Order":
                    step = "1";
                    break;
                case "Invoices":
                case "Invoice":
                    step = "3";
                    break;
                case "Payment Init":
                case "Payment":
                    step = "5";
                    break;
                case "Return Request":
                    step = "5";
                    break;
                default:
                    step = "1";
                    break;
            }
            let internalIdPromiseArray = [];
            contractSuggestionList = refManager.getAllReceiptsByStep(customerParamId, selfParamid, step, type, logistics).then(receiptIds => {
                let response = []
                for (let index in receiptIds) {
                    response[index] = {
                        receiptId: receiptIds[index]
                    }
                    internalIdPromiseArray.push(
                        refManager.getSummary(receiptIds[index], ['docInternalId']).then(summary => {
                            response[index].internalId = summary.docInternalId;
                        })
                    )
                }
                return Promise.all(internalIdPromiseArray).then(internalIds => {
                    return response;
                })
            })
        }

        namespace.setState({
            showInnerSpinner: true,
            childDataRef: namespace.state.childDataRef || "contract" || "rateCard"
        });

        return Promise.all([customerIsEnterprise, selfIsEnterprise, contractSuggestionList]).then((res) => {
            customerIsEnterprise = res[0];
            selfIsEnterprise = res[1];
            contractSuggestionList = res[2];
            supportPrivate = customerIsEnterprise && selfIsEnterprise;
            if (publicKey) {
                supportProtected = true;
                txnType = "2";
            }
            if (supportPrivate) {
                txnType = "3";
            }
            return refManager.getReceipt(contractSuggestionList[0] && contractSuggestionList[0].receiptId);
        }).then(selectedReceiptDetails => {
            selectedReceiptDetails = selectedReceiptDetails || {}
            let promise = Promise.resolve();
            let globalDiscountInputValue = 0;
            if (contractSuggestionList[0] && contractSuggestionList[0].receiptId) {
                if (selectedReceiptDetails.transactionType !== '2') {
                    supportProtected = false;
                    txnType = '1';
                }
                globalDiscountInputValue = selectedReceiptDetails.referencesOrder.discount || 0;
            }

            let contractResponse = Utils.createSuggestionList(contractSuggestionList, "documents");
            contractSuggestionList = contractResponse.suggestionDetails;
            const parentTabName = Utils.camelCaseToString(Utils.getParentTab(Utils.stringToCamelCase(namespace.state.heading), subType))
            const label = `Without ${parentTabName}`;
            contractSuggestionList.push(
                <Option key={contractSuggestionList.length + 1} label={label}>
                    {label}
                </Option>
            )
            let contractList = contractResponse.suggestionList;
            contractList.push({ receiptId: "", internalId: "" });
            setStateFromChild({
                parentReceiptData: selectedReceiptDetails,
                globalDiscountInputValue,
                contractList,
                parentDocId: contractList[0] && contractList[0].receiptId,
                selectedContract: contractList[0],
            })
            if (selectedReceiptDetails && selectedReceiptDetails.referencesOrder) {
                promise = InitHelper.fetchChildrenDetails(selectedReceiptDetails, namespace, setStateFromChild);
            }
            else {
                setStateFromChild({
                    dataToValidate: [], dataSource: []
                });
            }
            return promise;
        }).then(() => {
            setStateFromChild({
                buyer: namespace.state.contactSuggestionList[value],
                customerSelected: true,
                toolTipCustomer: namespace.state.contactSuggestionList[value].identifier,
                displayCustomerText: "",
                displayCustomerId: customerParamId,
                supportPrivate: supportPrivate,
                supportProtected: supportProtected,
                txnType: txnType,
                contractTypeList: HEPL_TEXT.contractType[0],
                contractSuggestionList,
                showInnerSpinner: false,
                noGrnForReturnRequest: false
            })
        }).catch(err => {
            Console.error("some error occured", err);
            setStateFromChild({
                showInnerSpinner: false
            })
        })
    }

    static onSelectParentDocAddress(value, namespace, setStateFromChild) {
        let selectedContract = {};
        if (!value || !namespace.state.contractList[value]) {
            setStateFromChild({ dataSource: [], parentDocId: "", dataToValidate: [], globalDiscountInputValue: 0, selectedContract });
            namespace.props.form.setFieldsValue({ paymentTerms: 0 })
            return;
        }
        selectedContract = namespace.state.contractList[value];
        let receiptId = namespace.state.contractList[value].receiptId;
        let receiptManager = NetworkBridge.getReceiptManager();
        setStateFromChild({ showInnerSpinner: true, noGrnForReturnRequest: false, selectedContract });
        return receiptManager.getReceipt(receiptId).then(selectedReceiptDetails => {
            let supportProtected = true, txnType = '2';
            if (selectedReceiptDetails.transactionType !== '2') {
                supportProtected = false;
                txnType = '1';
            }
            setStateFromChild({ parentReceiptData: selectedReceiptDetails, globalDiscountInputValue: selectedReceiptDetails.referencesOrder.discount || 0, supportProtected, txnType });
            return InitHelper.fetchChildrenDetails(selectedReceiptDetails, namespace, setStateFromChild)
        }).then(() => {
            setStateFromChild({ parentDocId: receiptId, showInnerSpinner: false });
        }).catch(err => {
            setStateFromChild({ dataSource: [], showInnerSpinner: false, parentDocId: "" });
        })
    }

    static fetchChildren(receiptId, parentReceiptData, childDataRef, namespace, setStateFromChild, subType) {
        let refManager;
        let grnManager = NetworkBridge.getGRNManager();
        let inData = [{ receiptId: parentReceiptData["@id"] }];
        let outData, outDataPromise;
        let functionCall;
        refManager = Wrapper.getRefManager(childDataRef);
        if (childDataRef === "gRN") {
            functionCall = grnManager.getAllGrnByDocId(receiptId, "2");
        }
        else {
            functionCall = refManager.getAllReceiptsByParent(receiptId, subType);
        }

        const formatData = (input) => {
            input = input.grns || input.receipts;
            for (let index in input) {
                input[index] = {
                    receiptId: input[index]
                }
            }
            return input;
        }

        return functionCall.then(res => {
            res = formatData(res);
            outDataPromise = Promise.resolve(res);
            if (namespace.state.heading === "Return Request") {
                refManager = Wrapper.getRefManager("returnRequest");
                outDataPromise = refManager.getAllReceiptsByParent(receiptId).then(res => {
                    return formatData(res);
                })
                if (childDataRef === "gRN") {
                    inData = res;
                    if (inData.length === 0) {
                        confirm({
                            title: "Heads UP",
                            content: "Selected invoice doesn't have any GRN, so taking values from the invoice",
                            okText: 'Continue Anyway',
                            cancelText: 'Cancel',
                            onOk() {
                                setStateFromChild({ noGrnForReturnRequest: true, parentDataRef: 'invoice' });
                                return OnSelectHelper.fetchChildren(receiptId, parentReceiptData, "invoice", namespace, setStateFromChild, "returnRequest")
                            },
                            onCancel() {
                                return outDataPromise.then(res => {
                                    outData = res;
                                    return InitHelper.fetchChildrenDetails(res, parentReceiptData, undefined, undefined, inData, outData, namespace, setStateFromChild);
                                })
                            },
                        });
                        return;
                    }
                }
            }
            return outDataPromise.then(res => {
                outData = res;
                return InitHelper.fetchChildrenDetails(res, parentReceiptData, undefined, undefined, inData, outData, namespace, setStateFromChild);
            })
        })
    }
}

export default OnSelectHelper;