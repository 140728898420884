import PurchasesOverview from './purchases/overview';
import SalesOverView from './sales/overview';
import FinanceOverview from './finance/overview';
import Graphs from './init/graphs';
import CommonCharts from './common/commonCharts'
import CustomerInsights from './sales/customerInsights';
import SupplierDelivery from './purchases/supplierDelivery';
import HTTPMongoConnector from '../../../database/nosql/connector/http-mongo-connector';
import Database from '../../../database/index';

class Charts {

    constructor(paramId) {
        if (this.instance) {
            throw new Error("Instance already exists");
        }
        this.isDBOpened = false;
        this.setParamID(paramId);
        this.mongoConnector = new HTTPMongoConnector();
    }

    static getInstance(paramId) {
        if (!Charts.instance) {
            Charts.instance = new Charts(paramId);
        }
        return Charts.instance;
    }

    setParamID(paramId) {
        this.paramId = paramId;
    }

    initDatabase(URL, serverPort) {
        if (this.isDBOpened || this.mongoClient || Database.getInstance().mongoClient) {
            this.isDBOpened = true;
            this.initObjects(this.mongoConnector);
            return Promise.resolve();
        }

        return this.mongoConnector.initDatabase(URL, this.paramId, serverPort).then((res) => {
            this.isDBOpened = true;
            this.mongoClient = res;
            console.log("DATABASE INITIALIZED", res);
            this.initObjects(this.mongoConnector);
            return res;
        }).catch((err) => {
            console.error(err);
        })
    }

    initObjects(mongoConnector) {
        this.purchasesOverview = new PurchasesOverview(mongoConnector);
        this.salesOverview = new SalesOverView(mongoConnector);
        this.financesOverview = new FinanceOverview(mongoConnector);
        this.customerInsights = new CustomerInsights(mongoConnector);
        this.supplierDelivery = new SupplierDelivery(mongoConnector);
        this.commonCharts = new CommonCharts(mongoConnector);
        // this.graphs = new Graphs(mongoConnector);
    }
}
export default Charts;