import ParamConnector from '../../../param-connector'
import Charts from '../charts'
import SellerOverView from './receipt/seller-overview'
import BuyerOverView from './receipt/buyer-overview';
import SupplierDelivery from './receipt/purchase-supplierdelivery';
import CustomerInsights from './receipt/sales-customerinsights';
import * as networkUtils from '../../../util/utils';
import Console from '../../../logger'

class VendorEvents {

    constructor(paramId) {
        this.charts = Charts.getInstance();
        this.paramID = paramId;
        this.buyerOverView = new BuyerOverView(paramId);
        this.sellerOverView = new SellerOverView(paramId);
        this.supplierDelivery = new SupplierDelivery(paramId);
        this.customerInsights = new CustomerInsights(paramId);
    }

    enableRealTimeUpdateForVendor(enable) {
        this.realTimeVendor = enable;
    }

    registerEvents() {
        const that = this;
        ParamConnector.getInstance().getDB().vendorDBEvents.registerOnAddInvited((data, err) => {
            Console.info("Got the registerOnAddInvited events")
            if (err) {
                Console.error("[Returns]-registerOnAddInvited Unable to get the data from events");
                return;
            }
            that.onVendorUpdate(data);
        })

        ParamConnector.getInstance().getDB().vendorDBEvents.registerOnInvited((data, err) => {
            Console.info("Got the registerOnInvited events")
            if (err) {
                Console.error("[Returns]-registerOnInvited Unable to get the data from events");
                return;
            }
            that.onVendorUpdate(data);
        })

        ParamConnector.getInstance().getDB().vendorDBEvents.registerOnInviteUpdated((data, err) => {
            Console.info("Got the registerOnInviteUpdated events")
            if (err) {
                Console.error("[Returns]-registerOnInviteUpdated Unable to get the data from events");
                return;
            }
            that.onVendorUpdate(data);
        })

        /*
        ParamConnector.getInstance().getDB().vendorDBEvents.registerOnBuyerInvited((data, err) => {
            that.onVendorUpdate(data);
        })

        ParamConnector.getInstance().getDB().vendorDBEvents.registerOnVendorInvited((data, err) => {
            Console.info("Got the registerOnReceiptStatusUpdate events")
            that.onVendorUpdate(data);
        })

         ParamConnector.getInstance().getDB().vendorDBEvents.registerOnEvalRequestAdded((data, err) => {
            that.onVendorUpdate(data);
        })

        ParamConnector.getInstance().getDB().vendorDBEvents.registerOnInvitationTemplateAdded((data, err) => {
            that.onVendorUpdate(data);
        })

        ParamConnector.getInstance().getDB().vendorDBEvents.registerOnPublicInvitationTemplateAdded((data, err) => {
            that.onVendorUpdate(data);
        }) */
    }

    unRegisterEvents() {

    }

    onVendorUpdate(data) {
        this.supplierDelivery.supplierDelivery(true);
        this.buyerOverView.overView(true);
        this.sellerOverView.getCustomers(true);
        this.sellerOverView.getOverview(true);
    }
}
export default VendorEvents;